import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../../../core/services/GQL.service";
import { LineChart } from "../../../../../common/components/Charts/LinearCharts/LineChart";
import { NoData } from "../../../../../common/components/NoData/NoData";
import { COLORS } from "../../../../../common/styles/colors";
import moment from "moment";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import { useFloorState } from "../../../../../core/context/containers/Floor.container";
import { DashboardDeviceType, Floor } from "../../../../../types";
import Loader from "react-loader-spinner";
import * as _ from "lodash";
import { useUserState } from "../../../../../core/context/containers/User.container";
import { LineChartAPDashboard } from "../../../../../common/components/Charts/LinearCharts/LineChartAPDashboard";
import { LineChartDTimeDashboard } from "../../../../../common/components/Charts/LinearCharts/LineChartDTimeDashboard";
import { LineChartCameraDashboard } from "../../../../../common/components/Charts/LinearCharts/LineChartCameraDashboard";

const useStyles = makeStyles((theme) => ({
  noData: {
    marginTop: theme.spacing(10),
  },
  greyText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(20),
    color: "#E2E2E2",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(10),
  },
  loader: {
    minHeight: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
  },
}));

interface DevicesTypesProps {
  timeFrom?: string;
  timeTo?: string;
  selectedTime?: string;
  selectedFloor: Floor | undefined;
  selectedSpace?: any;
  statisticsSelect?: any;
  selectedTimeZone: string;
  offHoursIsActive?: boolean;
}

export function DevicesTypes({
  timeFrom,
  timeTo,
  selectedTime,
  selectedFloor,
  selectedSpace,
  statisticsSelect,
  selectedTimeZone,
  offHoursIsActive,
}: DevicesTypesProps) {
  const { floors } = useFloorState();
  const { selectedProperty, selectedPropertyTimeZone } = usePropertiesState();
  const classes = useStyles();
  const [dataFromRequest, setDataFromRequest] = useState<any>(null);
  const [cameradataFromRequest, setCameraDataFromRequest] = useState<any>(null);
  const [timeFormat, setTimeFormat] = useState("");
  const [timeSetter, setTimeSetter] = useState<number>(15);
  const [connectedDevices, setConnectedDevices] = useState([]);
  const [inConnectedPeople, setInConnectedPeople] = useState([]);
  const [outConnectedPeople, setOutConnectedPeople] = useState([]);
  const [netFlow, setNetFlow] = useState([]);
  const [resetTime, setResetTime] = useState("");
  const [noData, setNoData] = useState<boolean>(false);
  const [dwellTimeDataFromRequest, setDwellTimeDataFromRequest] =
    useState<any>(null);
  const [averageDwellTime, setAverageDwellTime] = useState([]);
  const { userEmail } = useUserState();

  const timeRange = useMemo(() => {
    switch (selectedTime) {
      case "15m":
        setTimeFormat("hh:mm A");
        setTimeSetter(15);
        return "MINUTE";
      case "1h":
        setTimeFormat("hh:mm A");
        setTimeSetter(60);
        return "FIVE_MINUTES";
      case "24h":
        setTimeFormat("hh A");
        setTimeSetter(1440);
        return "HOUR";
      case "7d":
        setTimeFormat("DD MMM ddd");
        setTimeSetter(10080);
        return "DAY";
      case "30d":
        setTimeFormat("DD MMM ddd");
        setTimeSetter(43200);
        return "DAY";
      default:
        setTimeFormat("hh A");
        return "HOUR";
    }
  }, [selectedTime]);

  const getTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === "7d" || selectedTime === "30d") {
      if (selectedTimeZone) {
        newTimeTo = moment.tz(selectedTimeZone).startOf("day").format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedTimeZone)
          .subtract(timeSetter, "minutes")
          .format();
      } else {
        newTimeTo = moment.utc().startOf("day").format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(timeSetter, "minutes")
          .format();
      }
    } else {
      if (selectedTimeZone) {
        newTimeTo = moment
          .tz(selectedTimeZone)
          .startOf(selectedTime === "24h" ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedTimeZone)
          .subtract(timeSetter, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf(selectedTime === "24h" ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(timeSetter, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, selectedTimeZone, timeSetter]);

  const get15minTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === "15m") {
      if (selectedTimeZone) {
        newTimeTo = moment
          .tz(selectedTimeZone)
          .startOf("minute")
          .subtract(3, "minutes")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedTimeZone)
          .subtract(timeSetter, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf("minute")
          .subtract(3, "minutes")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(timeSetter, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, selectedTimeZone, timeSetter]);

  const requestObject = useMemo(() => {
    if (selectedFloor) {
      return {
        request: {
          floorId: selectedFloor?.id!,
          spaceId: selectedSpace,
          from: getTimeParams().newTimeFrom,
          to: getTimeParams().newTimeTo,
          timeRangeType: timeRange,
          deviceType: statisticsSelect.toUpperCase(),
        },
      };
    }
  }, [
    selectedFloor,
    selectedSpace,
    getTimeParams,
    timeRange,
    statisticsSelect,
  ]);

  const request15minObject = useMemo(() => {
    if (selectedFloor) {
      return {
        request: {
          floorId: selectedFloor?.id!,
          spaceId: selectedSpace,
          from: get15minTimeParams().newTimeFrom,
          to: get15minTimeParams().newTimeTo,
          timeRangeType: timeRange,
          deviceType: statisticsSelect.toUpperCase(),
        },
      };
    }
  }, [
    selectedFloor,
    selectedSpace,
    get15minTimeParams,
    timeRange,
    statisticsSelect,
  ]);

  const showTime = (time: number) => {
    return time === 0
      ? `12:00 AM`
      : time < 12
      ? `${time}:00 AM`
      : time === 12
      ? `12:00 PM`
      : `${time - 12}:00 PM`;
  };

  // const { loading: devicesLoading, error, data } = useQuery(
  //   GQLService.queries.devicesTypesByFloorId,
  //   {
  //     variables: requestObject,
  //     skip: !selectedFloor,
  //   }
  // );

  // const { loading: devicesLoading, error, data } = useQuery(
  //   GQLService.queries.densityTimelineByFloor,
  //   {
  //     variables: requestObject,
  //     skip: !selectedFloor,
  //   }
  // );

  const [
    getStatisticTimelineByFloor,
    { loading: devicesLoading, error, data },
  ] = useLazyQuery(GQLService.queries.statisticTimelineByFloor, {
    fetchPolicy: "no-cache",
  });

  const [
    getStatisticTimelineForAnyTripwires,
    {
      loading: flowThroughLoading,
      error: flowThroughError,
      data: flowThroughData,
    },
  ] = useLazyQuery(GQLService.queries.statisticTimelineForAnyTripwires, {
    fetchPolicy: "no-cache",
  });

  const [
    getDwellTimeTimeline,
    { loading: dwellTimeLoading, error: dwellTimeError, data: dwellTimeData },
  ] = useLazyQuery(GQLService.queries.dwelltimeTimeline, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (statisticsSelect === "CAMERA") {
      getStatisticTimelineForAnyTripwires({
        variables: {
          from: getTimeParams().newTimeFrom,
          spaceId: selectedSpace,
          timeRangeType: timeRange,
          to: getTimeParams().newTimeTo,
          floorId: selectedFloor?.id!,
          deviceType: DashboardDeviceType.CAMERA,
        },
      });
    } else if (statisticsSelect === "Dwell time") {
      getDwellTimeTimeline({
        variables: {
          request: {
            from: getTimeParams().newTimeFrom,
            spaceId: selectedSpace,
            timeRangeType: timeRange,
            to: getTimeParams().newTimeTo,
            floorId: selectedFloor?.id!,
            deviceType: DashboardDeviceType.CAMERA,
            userEmail: userEmail,
          },
        },
      });
    } else {
      if (statisticsSelect === "ACCESSPOINT" && selectedTime === "15m") {
        request15minObject &&
          getStatisticTimelineByFloor({ variables: request15minObject });
      } else {
        requestObject &&
          getStatisticTimelineByFloor({ variables: requestObject });
      }
    }
  }, [
    statisticsSelect,
    selectedSpace,
    timeRange,
    getStatisticTimelineForAnyTripwires,
    selectedFloor?.id,
    getStatisticTimelineByFloor,
    requestObject,
    getDwellTimeTimeline,
    userEmail,
    getTimeParams,
    selectedProperty,
    selectedTime,
    request15minObject,
  ]);

  useEffect(() => {
    if (error) {
      setNoData(true);
    }
    if (
      statisticsSelect === "AIRQUALITY" &&
      data?.statisticTimelineByFloor?.airQualitytimeline?.length === 0
    ) {
      setNoData(true);
    }
    if (
      statisticsSelect === "ACCESSPOINT" &&
      data?.statisticTimelineByFloor?.densityTimeline?.length === 0
    ) {
      setNoData(true);
    }
    if (!devicesLoading && data && selectedFloor) {
      setDataFromRequest(data?.statisticTimelineByFloor);
      setNoData(false);
    }
  }, [devicesLoading, data, error, selectedFloor, statisticsSelect]);

  useEffect(() => {
    if (flowThroughError) {
      setNoData(true);
    }
    if (
      statisticsSelect === "CAMERA" &&
      !flowThroughLoading &&
      flowThroughData
    ) {
      setCameraDataFromRequest(
        flowThroughData?.statisticTimelineForAnyTripwires!
      );
      setNoData(false);
    }
    if (
      (statisticsSelect === "CAMERA" &&
        flowThroughData?.statisticTimelineForAnyTripwires === null) ||
      flowThroughData?.statisticTimelineForAnyTripwires?.tripwires === null
    ) {
      setNoData(true);
    }
  }, [flowThroughLoading, flowThroughData, flowThroughError]);

  useEffect(() => {
    if (dwellTimeError) {
      setNoData(true);
    }
    if (
      statisticsSelect === "Dwell time" &&
      !dwellTimeLoading &&
      dwellTimeData
    ) {
      setDwellTimeDataFromRequest(
        dwellTimeData?.dwelltimeTimelineForFloorsOrSpaces!
      );
      setNoData(false);
    }
    if (
      statisticsSelect === "Dwell time" &&
      dwellTimeData?.dwelltimeTimelineForFloorsOrSpaces?.length === 0
    ) {
      setNoData(true);
    }
  }, [dwellTimeLoading, dwellTimeData, dwellTimeError]);

  useEffect(() => {
    if (dataFromRequest && timeFormat && selectedTimeZone) {
      if (dataFromRequest?.densityTimeline.length > 0) {
        //setNoData(false);
        const device = dataFromRequest?.densityTimeline?.map(
          (v: { from: moment.MomentInput; connectedDevicesCount: any }) => {
            //v.connectedDevicesCount > 0 && setNoData(false);
            return {
              date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
              devices: v.connectedDevicesCount,
            };
          }
        );
        setConnectedDevices(device);
      }
      if (dataFromRequest?.airQualitytimeline.length > 0) {
        //setNoData(false);
        const device = dataFromRequest?.airQualitytimeline?.map(
          (v: { from: moment.MomentInput; score: any }) => {
            //v.score > 0 && setNoData(false);
            return {
              date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
              devices: v.score,
            };
          }
        );
        setConnectedDevices(device);
      }
    }
  }, [dataFromRequest, timeFormat, selectedTimeZone]);

  useEffect(() => {
    if (flowThroughData?.statisticTimelineForAnyTripwires?.tripwires!) {
      let inDataArray: any = [];
      let outDataArray: any = [];

      var filteredDataIn = _.map(
        cameradataFromRequest?.tripwires,
        "tripwireInData"
      );
      var filteredDataOut = _.map(
        cameradataFromRequest?.tripwires,
        "tripwireOutData"
      );
      if (
        cameradataFromRequest?.netflowTimeLine?.length! > 0 &&
        cameradataFromRequest?.netflowTimeLine! !== null
      ) {
        const inData = cameradataFromRequest?.netflowTimeLine?.map(
          (v: { from: moment.MomentInput; netFlow: any }) => {
            return {
              date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
              people: v.netFlow.in,
            };
          }
        );
        const outData = cameradataFromRequest?.netflowTimeLine?.map(
          (v: { from: moment.MomentInput; netFlow: any }) => {
            return {
              date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
              people: v.netFlow.out,
            };
          }
        );
        setInConnectedPeople(inData);
        setOutConnectedPeople(outData);
      } else {
        for (let i = 0; i < filteredDataIn?.length!; i++) {
          if (filteredDataIn[i] !== null) {
            const inData = filteredDataIn[i]?.map(
              (v: { from: moment.MomentInput; value: any }) => {
                return {
                  date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
                  people: v.value,
                };
              }
            );
            inDataArray.push(inData);
          }
        }
        for (let i = 0; i < filteredDataOut?.length!; i++) {
          if (filteredDataIn[i] !== null) {
            const outData = filteredDataOut[i]?.map(
              (v: { from: moment.MomentInput; value: any }) => {
                return {
                  date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
                  people: v.value,
                };
              }
            );
            outDataArray.push(outData);
          }
        }
        const newInDataArray: any = [];
        const newOutDataArrray: any = [];
        const flattenInDataArray: any = _.flatten(inDataArray);
        const flattenOutDataArray: any = _.flatten(outDataArray);

        flattenInDataArray.forEach((item: any) => {
          let newArrayIndex = newInDataArray.findIndex(
            (element: any) => element.date === item.date
          );
          if (newArrayIndex !== -1) {
            newInDataArray[newArrayIndex].people += item.people;
          } else {
            newInDataArray.push({ date: item.date, people: item.people });
          }
        });

        flattenOutDataArray.forEach((item: any) => {
          let newArrayIndex = newOutDataArrray.findIndex(
            (element: any) => element.date === item.date
          );
          if (newArrayIndex !== -1) {
            newOutDataArrray[newArrayIndex].people += item.people;
          } else {
            newOutDataArrray.push({ date: item.date, people: item.people });
          }
        });

        setInConnectedPeople(newInDataArray);
        setOutConnectedPeople(newOutDataArrray);
      }
      const Netflow = cameradataFromRequest?.netflowTimeLine?.map(
        (v: { from: moment.MomentInput; netFlow: any }) => {
          return {
            date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
            people: v.netFlow.correctedNetOccupancy,
          };
        }
      );
      setNetFlow(Netflow);

      const resetTime = cameradataFromRequest?.spaceDevice?.resetTime;
      setResetTime(resetTime);
    }
  }, [
    cameradataFromRequest,
    flowThroughData?.statisticTimelineForAnyTripwires,
    selectedTimeZone,
    timeFormat,
  ]);

  useEffect(() => {
    if (dwellTimeDataFromRequest?.length > 0) {
      const averageDwellTimeArray = dwellTimeDataFromRequest?.map(
        (v: { from: moment.MomentInput; avgDwellTime: any }) => {
          return {
            date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
            avgDwellTime: v.avgDwellTime,
          };
        }
      );
      setAverageDwellTime(averageDwellTimeArray);
    }
  }, [dwellTimeDataFromRequest, timeFormat, selectedTimeZone]);

  if (devicesLoading || flowThroughLoading || dwellTimeLoading) {
    return (
      <Grid className={classes.loader}>
        <Loader type="Oval" color={COLORS.funBlue} height={100} width={100} />
      </Grid>
    );
  }

  if (noData) {
    return (
      <Grid container={true}>
        <Grid
          container={true}
          item={true}
          md={12}
          justify="center"
          className={classes.noData}
        >
          <NoData />
        </Grid>
        <Grid container={true} item={true} md={12} justify="center">
          <Typography className={classes.greyText}>No Data</Typography>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container={true}>
        {statisticsSelect === "CAMERA" ? (
          <LineChartCameraDashboard
            devices={true}
            selectedTime={selectedTime}
            inConnectedPeople={inConnectedPeople}
            outConnectedPeople={outConnectedPeople}
            netFlow={netFlow}
            resetTime={resetTime}
            statisticsSelect={statisticsSelect}
          />
        ) : //averageDwellTime
        statisticsSelect === "Dwell time" ? (
          <LineChartDTimeDashboard
            devices={true}
            selectedTime={selectedTime}
            averageDwellTime={averageDwellTime}
            statisticsSelect={statisticsSelect}
          />
        ) : (
          <LineChartAPDashboard
            devices={true}
            selectedTime={selectedTime}
            connectedDevices={connectedDevices}
            statisticsSelect={statisticsSelect}
            offHoursIsActive={offHoursIsActive}
          />
        )}
        {/* <HourSlider /> */}
      </Grid>
    );
  }
}
