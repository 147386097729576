import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { Door } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useDoorBase() {
  const [doors, setDoors] = useState<Door[]>([]);
  const [doorsFromResponse, setDoorsFromResponse] = useState<Door[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    state: selectedDoor,
    setState: setSelectedDoor,
  } = usePersistedState<Door | null>("Door", null);

  const { search } = useSearch();

  const searchDoors = useCallback(
    (searchValue: string) => {
      setDoors(search(doorsFromResponse, searchValue) as Door[]);
    },
    [doorsFromResponse]
  );

  return {
    searchDoors,
    doors,
    setDoors,
    doorsFromResponse,
    setDoorsFromResponse,
    selectedDoor,
    setSelectedDoor,
    loading,
    setLoading,
  };
}

const { Provider, useContainer } = createContainer(useDoorBase);

export const useDoorState = useContainer;
export const DoorProvider = Provider;
