import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";

import { ROUTES } from "../../common/constants/Routing";
import { AddElevatorBay } from "../../pages/Dashboard/ElevatorBays/Add/Add";
import { EditElevatorBay } from "../../pages/Dashboard/ElevatorBays/Edit/Edit";
import { ElevatorBayView } from "../../pages/Dashboard/ElevatorBays/View/View";
import { Role } from "../../types";
import { useAuth } from "../context/containers/Auth.container";

export const ELEVATOR_BAYS_ROUTES = {
  ELEVATOR_BAYS: "/dashboard/properties/:uid/elevatorBays",
  ADD_ELEVATOR_BAY: "/dashboard/properties/:uid/elevatorBays/add",
  EDIT_ELEVATOR_BAYS:
    "/dashboard/properties/:uid/elevatorBays/:elevatorBayId/edit",
  ELEVATOR_BAYS_VIEW:
    "/dashboard/properties/:uid/elevatorBays/:elevatorBayId/view",
};

const ElevatorBays = lazy(
  () => import("../../pages/Dashboard/ElevatorBays/List/List")
);

export default function ElevatorBaysRouter() {
  const auth = useAuth();

  return (
    <Route path={ROUTES.ELEVATOR_BAYS}>
      <Route
        exact={true}
        path={ROUTES.ELEVATOR_BAYS}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "elevatorBays:list",
              <ElevatorBays />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.ELEVATOR_BAYS_VIEW}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "elevatorBays:view",
              <ElevatorBayView />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.EDIT_ELEVATOR_BAYS}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "elevatorBays:edit",
              <EditElevatorBay />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.ADD_ELEVATOR_BAY}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "elevatorBays:create",
              <AddElevatorBay />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
    </Route>
  );
}
