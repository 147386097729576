import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import {
  Grid,
  makeStyles,
  Input,
  InputLabel,
  AccordionActions,
  Tooltip,
  Typography,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { ReactComponent as PlanYes } from "../../../../../../common/assets/images/FloorPlanIndicator/PlanYes.svg";
import { ReactComponent as PlanNo } from "../../../../../../common/assets/images/FloorPlanIndicator/PlanNo.svg";
import {
  ActivitySwitch,
  CustomModal,
  StatusIndicator,
} from "../../../../../../common/components/index";
import { TripWireMenu } from "./TripWireMenu";
import { ImagesSensor, TripWire } from "../../../../../../types";
import { RemoveModal } from "../RemoveModal";
import { DeleteModal } from "../DeleteModal";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    margin: "20px 0 10px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
  },
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
  },
  readOnlyInput: {
    color: theme.palette.common.inputGrey,
  },
  errorLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.cinnabar,
    marginLeft: 10,
  },
  errorInput: {
    borderColor: theme.palette.common.cinnabar,
  },
  arrow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  toolTipDiv: {
    backgroundColor: "#F2F2F2",
    border: "1px solid #81D3E0",
    borderRadius: "3px",
    boxShadow: "0px 6px 6px 2px rgba(0, 0, 0, 0.25)",
    paddingTop: "14px",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  toolTipTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkBlue,
    marginBottom: "16px",
  },
  toolTipContent: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkBlue,
    marginBottom: "12px",
  },
  infoIcon: {
    fontSize: 15,
    marginLeft: "5px",
    color: "#2F56A0",
    marginTop: "12px",
  },
  wrapperGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
  },
}));

const FIELD_NAMES = {
  name: "name",
  id: "id",
  area: "area",
  tripWire: "tripWire",
};

export interface TripWireDetailsProps {
  values: any;
  setFieldValue: any;
  errors: any;
  submitCount: any;
  initialValues: any;
  index: number;
  imageSensor: ImagesSensor;
  tripWire: TripWire;
}

export const TripWireDetails = ({
  values,
  setFieldValue,
  errors,
  submitCount,
  initialValues,
  index,
  imageSensor,
  tripWire,
}: TripWireDetailsProps) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [active, setActive] = useState<boolean>(tripWire?.active!);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showdltmodal, setShowdltModal] = useState<boolean>(false);

  const toggleSwitchButton = useCallback(
    (setFieldValue) => {
      setFieldValue(`tripWires[${index}].active`, !active);
      setActive(!active);
    },
    [active] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => setShowModal(false), []);

  const rejectdltChanges = useCallback(() => setShowdltModal(false), []);

  return (
    <>
      <Grid container spacing={2} key={"ID"+tripWire?.id}>
        <Grid item sm={4} md={6}>
          <InputLabel className={classes.inputLabel}>
            TripWire ID
            {(submitCount > 0 ||
              values[FIELD_NAMES.id] !== initialValues[FIELD_NAMES.id]) &&
              errors[FIELD_NAMES.id] && (
                <span className={classes.errorLabel}>
                  {errors[FIELD_NAMES.id]}
                </span>
              )}
          </InputLabel>
          <Input
            className={clsx(
              classes.input,
              classes.readOnlyInput,
              (submitCount > 0 ||
                values[FIELD_NAMES.id] !== initialValues[FIELD_NAMES.id]) &&
                errors[FIELD_NAMES.id]
                ? classes.errorInput
                : ""
            )}
            disableUnderline={true}
            fullWidth={true}
            placeholder={"TripWire ID"}
            name={`tripWires[${index}].id`}
            type={"input"}
            readOnly
            value={values.tripWire}
          />
        </Grid>
        <Grid item sm={4} md={3} key={"name"+tripWire?.id}>
          <InputLabel className={classes.inputLabel}>
            TripWire Name
            {(submitCount > 0 ||
              values[FIELD_NAMES.name] !== initialValues[FIELD_NAMES.name]) &&
              errors[FIELD_NAMES.name] && (
                <span className={classes.errorLabel}>
                  {errors[FIELD_NAMES.name]}
                </span>
              )}
          </InputLabel>
          <Input
            className={clsx(
              classes.input,
              (submitCount > 0 ||
                values[FIELD_NAMES.name] !== initialValues[FIELD_NAMES.name]) &&
                errors[FIELD_NAMES.name]
                ? classes.errorInput
                : ""
            )}
            disableUnderline={true}
            fullWidth={true}
            placeholder={"TripWire Name"}
            name={`tripWires[${index}].name`}
            type={"input"}
            defaultValue={values.name}
            onChange={(e) =>
              setFieldValue(`tripWires[${index}].name`, e.target.value)
            }
          />
        </Grid>
        <Grid item sm={4} md={3} key={"length"+tripWire?.id}>
          <InputLabel className={classes.inputLabel}>Covered Length</InputLabel>
          <Input
            className={clsx(
              classes.input,
              (submitCount > 0 ||
                values[FIELD_NAMES.area] !== initialValues[FIELD_NAMES.area]) &&
                errors[FIELD_NAMES.area]
                ? classes.errorInput
                : ""
            )}
            disableUnderline={true}
            fullWidth={true}
            placeholder={"Covered Length"}
            name={`tripWires[${index}].area`}
            type={"input"}
            defaultValue={values.area}
            onChange={(e) => {
              const value = e.target.value ? e.target.value : 0;
              setFieldValue(`tripWires[${index}].area`, value);
            }}
          />
          <div>
            {(submitCount > 0 ||
              values[FIELD_NAMES.area] !== initialValues[FIELD_NAMES.area]) &&
              errors[FIELD_NAMES.area] && (
                <span className={classes.errorLabel}>
                  {errors[FIELD_NAMES.area]}
                </span>
              )}
          </div>
        </Grid>
        <Grid item sm={4} md={2} >
          <InputLabel className={classes.inputLabel}>No of Spaces</InputLabel>
          <Grid style={{ display: "flex", flexDirection: "row" }} key={"spaces"+tripWire?.id}>
            <Input
              className={clsx(
                classes.input,
                (submitCount > 0 ||
                  values[FIELD_NAMES.name] !==
                    initialValues[FIELD_NAMES.name]) &&
                  errors[FIELD_NAMES.name]
                  ? classes.errorInput
                  : ""
              )}
              disableUnderline={true}
              fullWidth={true}
              placeholder={"No of Spaces"}
              type={"input"}
              value={
                tripWire?.spaces?.length! > 0 ? tripWire?.spaces?.length! : "--"
              }
              readOnly
            />
            {tripWire?.spaces?.length! > 0 ? (
              <Tooltip
                enterNextDelay={500}
                placement="top-start"
                title={
                  <div>
                    <Typography className={classes.toolTipTitle}>
                      Space Name
                    </Typography>
                    {tripWire?.spaces?.map((item: any) => {
                      return (
                        <Typography className={classes.toolTipContent}>
                          {item.space.name}
                        </Typography>
                      );
                    })}
                  </div>
                }
                classes={{ tooltip: classes.toolTipDiv }}
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </Tooltip>
            ) : (
              <InfoOutlinedIcon
                className={classes.infoIcon}
                style={{ opacity: 0.5 }}
              />
            )}
          </Grid>
        </Grid>
        <Grid item sm={4} md={2} key={"plan"+tripWire?.id} className={classes.wrapperGrid}>
          {tripWire?.placement! === null ||
          tripWire?.placement! === undefined ? (
            <PlanNo height={32} width={32} />
          ) : Object.keys(tripWire?.placement!).length === 0 ? (
            <PlanNo height={32} width={32} />
          ) : (
            <Tooltip
              enterNextDelay={500}
              placement="top-start"
              title={
                <Typography className={classes.toolTipContent}>
                  Configured on floor plan
                </Typography>
              }
              classes={{ tooltip: classes.toolTipDiv }}
            >
              <PlanYes height={32} width={32} />
            </Tooltip>
          )}
        </Grid>
        <Grid item sm={4} md={3} key={"status"+tripWire?.id}>
          <InputLabel className={classes.inputLabel}>Status</InputLabel>
          <Grid style={{ marginTop: "16px" }}>
            <ActivitySwitch
              name={"tripWireStatus"}
              defaultValue={active ? true : false}
              disabled={false}
              value={active ? true : false}
              onChange={(e: any) => toggleSwitchButton(setFieldValue)}
              onBlur={(e: any) => toggleSwitchButton(setFieldValue)}
              activeToggleText="Active"
              inactiveToggleText="Inactive"
            />
          </Grid>
        </Grid>
        <Grid
          item
          sm={4}
          md={1}
          key={"indicator"+tripWire?.id}
          className={classes.wrapperGrid}
          style={{ marginTop: "35px" }}
        >
          <StatusIndicator uid={""} isActive={active} />
        </Grid>
        <Grid item sm={4} md={1} key={"actions"+tripWire?.id} className={classes.wrapperGrid}>
          <AccordionActions
            id={values.id}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            className={classes.arrow}
          >
            <TripWireMenu
              imageSensor={imageSensor!}
              tripWire={tripWire!}
              setShowModal={setShowModal}
              setShowdltModal={setShowdltModal}
            />
          </AccordionActions>
        </Grid>
      </Grid>
      <CustomModal open={showModal}>
        <RemoveModal
          headerText="Unlink Tripwire"
          no={rejectChanges}
          data={tripWire}
          imageSensor={imageSensor}
        />
      </CustomModal>
      <CustomModal open={showdltmodal}>
        <DeleteModal
          headerText="Delete Tripwire"
          no={rejectdltChanges}
          data={tripWire}
          imageSensor={imageSensor}
        />
      </CustomModal>
    </>
  );
};
