import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0 0 0 0",
  },
  root: {
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: "auto",
      margin: 0,
    },
    "& .MuiTab-root": {
      padding: "0 0",
      minWidth: "auto",
    },
  },
  tabBar: {
    backgroundColor: "transparent",
    borderBottom: `1px solid ${theme.palette.common.ultralightGrey}`,
  },
  tabIndicator: {
    backgroundColor: theme.palette.common.lightBlue,
    height: 3.5,
    width: "100%",
  },
  tab: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    width: "auto",
    justifyContent: "flex-start",
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blue,
    textTransform: "none",
    opacity: 1,
    marginRight: 39,
    lineHeight: "24px",
  },
  active: {
    color: `${theme.palette.common.textBlack} !important`,
  },
}));
