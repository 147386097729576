import {
  Input,
  SelectWithTags,
} from "../../../../../common/components/Form/FormFields";
import * as Yup from "yup";

import { CreateFormWizardSteps } from "../../../../../types/form";
import I18n from "../../../../../core/services/I18n.service";
import {
  ActivitySwitch,
  Select,
  Password,
} from "../../../../../common/components/Form/FormFields";

const FIELD_NAMES = {
  name: "name",
  email: "email",
  active: "active",
  floorsIds: "floorIds",
  propertiesIds: "propertiesIds",
  role: "role",
  password: "password",
  repeat: "repeat",
};

export interface addUserType {
  name: string;
  email: string;
  role: string;
  active: boolean;
  password: string;
  propertiesIds: string[];
  floorsIds: string[];
}

export const createPropertyAdminFormWizard: CreateFormWizardSteps = (data: {
  [name: string]: [];
}) => {
  return [
    {
      name: "Add New User",
      fieldset: [
        {
          label: "Main Informations",
          fields: [
            {
              name: FIELD_NAMES.name,
              label: "Name",
              placeholder: "Type here",
              required: true,
              gridStyle: { sm: 12, md: 12 },
              Component: Input,
            },
            {
              name: FIELD_NAMES.email,
              label: "Address E-mail",
              placeholder: "Type here",
              required: false,
              gridStyle: { sm: 12, md: 12 },
              Component: Input,
            },
            {
              name: FIELD_NAMES.active,
              label: "Active",
              required: false,
              gridStyle: { sm: 12, md: 12 },
              Component: ActivitySwitch,
            },
            {
              name: FIELD_NAMES.password,
              label: "",
              required: false,
              gridStyle: { sm: 12, md: 12 },
              Component: Password,
            },
            {
              name: FIELD_NAMES.repeat,
              label: "Repeat Password",
              required: false,
              gridStyle: { sm: 6, md: 6 },
              Component: Input,
              placeholder: "Repeat Password",
            },
          ],
          validationSchema: mainInformationsValidationSchema(),
          defaultValues: { [FIELD_NAMES.active]: false },
        },
      ],
    },
    {
      name: "Assigned Properties",
      fieldset: [
        {
          label: "Assigned Properties",
          fields: [
            {
              name: FIELD_NAMES.propertiesIds,
              label: "Select Properties",
              placeholder: "Type here",
              required: false,
              gridStyle: { sm: 12, md: 12 },
              Component: SelectWithTags,
              data: data.properties,
            },
          ],
          validationSchema: propertiesValidationSchema(),
        },
      ],
    },
  ];
};

const mainInformationsValidationSchema = () =>
  Yup.object().shape({
    [FIELD_NAMES.name]: Yup.string()
      .min(
        3,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.name.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.name.long",
          defaultMessage: "Too long!",
        })
      )
      .required(" "),
    [FIELD_NAMES.email]: Yup.string()
      .email()
      .min(
        1,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.name.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.name.long",
          defaultMessage: "Too long!",
        })
      )
      .required(" "),
    [FIELD_NAMES.password]: Yup.string()
      .min(
        8,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.name.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.name.long",
          defaultMessage: "Too long!",
        })
      )
      .required(" "),
    [FIELD_NAMES.repeat]: Yup.mixed().test(
      "match",
      "Passwords do not match",
      function (repeat: any) {
        return repeat === this.parent.password;
      }
    ),
    [FIELD_NAMES.active]: Yup.boolean().nullable(),
  });

const propertiesValidationSchema = () =>
  Yup.object().shape({
    [FIELD_NAMES.propertiesIds]: Yup.array(),
  });
