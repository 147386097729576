import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Evolve, evolve, map } from "ramda";
import {
  AccessPointWithSpaceLoad,
  SensorDetailsAirQualityForFloor,
} from "../../../types";
import { ViewType } from "../../../pages/NetworkAnalytics/Occupancy/Occupancy";

declare var smplr: any;

interface ViewerListProps {
  smplrId: string;
  accessPointsWithSpaceLoadToRender?: AccessPointWithSpaceLoad[];
  radius?: any;
  viewType: ViewType;
  sensor?: SensorDetailsAirQualityForFloor[];
  // spaceToken: string;
  // containerId: string;
}
export default function SmplrViewer({
  smplrId,
  accessPointsWithSpaceLoadToRender,
  radius,
  viewType,
  sensor,
}: ViewerListProps) {
  const [dataForAP, setDataForAP] = useState<any>([]);
  const [dataForAQ, setDataForAQ] = useState<any>([]);
  const [spaceState, setSpaceState] = useState<any>();
  const autoElevation = map(
    evolve({
      position: { elevation: (value: any) => value },
    })
  );
  useEffect(() => {
    let isMounted = true;
    const space = new smplr.Space({
      spaceId: smplrId, //"272d12db-14cf-438d-8786-124f6703fa81", // prod
      // spaceId: '96eae952-ef60-4058-aba1-6ace322506e7', // dev
      spaceToken: "X",
      containerId: "smplr-container",
    });
    space.startViewer({
      preview: true,
      mode: "3d",
      allowModeChange: true,
      onReady: () => onReady(space),
      onError: (error: any) => console.error("Could not start viewer", error),
    });
    return () => {
      isMounted = false;
    };
  }, [smplrId]);

  useEffect(() => {
    if (viewType === ViewType.ACCESS_POINT) {
      if (accessPointsWithSpaceLoadToRender) {
        const data = accessPointsWithSpaceLoadToRender.map(
          (item: any, index) => {
            return {
              id: item.accessPoint.name,
              range: radius,
              position: {
                levelIndex: 0,
                x: parseInt(item.accessPoint.coordinateX),
                z: parseInt(item.accessPoint.coordinateY),
                elevation: 0.5708123636711104,
              },
              spaceLoad: item.spaceLoad,
            };
          }
        );
        setDataForAP(data);
      }
    } else if (viewType === ViewType.SENSORS) {
      if (sensor) {
        const dataAQ = sensor.map((item: any, index) => {
          return {
            id: item.airSensor.name,
            position: {
              levelIndex: 0,
              x: parseInt(item.airSensor.coordinateX),
              z: parseInt(item.airSensor.coordinateY),
              elevation: 4,
            },
            score: item?.details?.score
              ? Math.round(item.details.score).toFixed()
              : 0,
            airQuality: item?.details?.airQuality,
          };
        });
        setDataForAQ(dataAQ);
      }
    } else {
      return;
    }
  }, [accessPointsWithSpaceLoadToRender, radius, viewType, sensor]);

  useEffect(() => {
    if (!spaceState) {
      return;
    }
    if (viewType === ViewType.ACCESS_POINT) {
      if (dataForAP.length > 0) {
        spaceState.addDataLayer({
          id: "beacons",
          type: "point",
          data: autoElevation(dataForAP),
          tooltip: (d: { id: any; range: any; spaceLoad: any }) => `${d.id}`,
          color: (d: any) =>
            d.spaceLoad === "HIGH"
              ? "#B0001F"
              : d.spaceLoad === "LOW"
              ? "#4F9E53"
              : d.spaceLoad === "MEDIUM"
              ? "#EEAA46"
              : d.spaceLoad === "NOT_CONFIGURED"
              ? "#0E1D33"
              : "#A0A0A0",
          diameter: 3,
        });
        spaceState.addDataLayer({
          id: "beacons-range",
          type: "point",
          data: autoElevation(dataForAP),
          color: (d: any) =>
            d.spaceLoad === "HIGH"
              ? "#B0001F"
              : d.spaceLoad === "LOW"
              ? "#4F9E53"
              : d.spaceLoad === "MEDIUM"
              ? "#EEAA46"
              : d.spaceLoad === "NOT_CONFIGURED"
              ? "#0E1D33"
              : "#A0A0A0",
          alpha: 0.3,
          diameter: (d: { range: any }) => d.range,
        });
      }
    } else if (viewType === ViewType.SENSORS) {
      if (dataForAQ.length > 0) {
        spaceState.addDataLayer({
          id: "sensors",
          type: "point",
          data: autoElevation(dataForAQ),
          tooltip: (d: any) => `${d.score} ${d.id}`,
          color: (d: any) =>
            d.airQuality === "POOR"
              ? "#B0001F"
              : d.airQuality === "HEALTHY"
              ? "#4F9E53"
              : d.airQuality === "MODERATE"
              ? "#EEAA46"
              : d.airQuality === "NOT_CONFIGURED"
              ? "#0E1D33"
              : d.airQuality === "NO_DATA"
              ? "#81D3E0"
              : "#A0A0A0",
          diameter: 3,
        });
      }
    } else {
      return;
    }
  }, [autoElevation, dataForAP, spaceState, viewType]);

  const onReady = useCallback((space: any) => {
    setSpaceState(space);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "60%",
      }}
    >
      <div
        id="smplr-container"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: "whitesmoke",
        }}
      />
    </div>
  );
}
