import React, { useState, useEffect, useCallback } from "react";
import { Divider, Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { useStyles } from "./DataUsage.styles";
import { VictoryPieChart } from "../../../../../common/components/Charts/PieCharts/VictoryPieChart/VictoryPieChart";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import GQLService from "../../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import { VLAN } from "../../../../../types";
import { useLazyQuery } from "@apollo/client";

export const dataTypeColors = [
  "#2F56A1",
  "#81D3E0",
  "#08B055",
  "#D74141",
  "#FF9A3D",
];

export const appTypeColors = [
  "#2F56A1",
  "#BE81DB",
  "#D74141",
  "#81D3E0",
  "#FF9A3D",
];

type ResponseData = {
  category: string;
  percentageUsage: number;
};

type PieChartData = {
  x: string;
  y: number;
};

interface DataUsageProps {
  timeFrom?: string;
  timeTo?: string;
  selectedTime?: string;
}

export const DataUsage = ({
  timeFrom,
  timeTo,
  selectedTime,
}: DataUsageProps) => {
  const classes = useStyles();

  const [dataType, setDataType] = useState<PieChartData[]>([]);
  const [appType, setAppType] = useState<PieChartData[]>([]);
  const [VLANs, setVLANs] = useState<VLAN[]>([]);
  const [selectedVlan, setSelectedVlan] = useState<VLAN>();
  const { selectedProperty } = usePropertiesState();

  const [
    getVLANsByPropertyId,
    { loading: loadingV, error: errorV, data: dataV },
  ] = useLazyQuery(GQLService.queries.vlanByProperty);

  const [
    getDataType,
    { loading: loadingDataType, error: errorDataType, data: resDataType },
  ] = useLazyQuery(GQLService.queries.applicationType);

  const [
    getApplicationTypeByPropertyId,
    {
      loading: loadingApplicationType,
      error: errorApplicationType,
      data: resApplicationType,
      called: applicationTypeByPropertyIdCalled,
    },
  ] = useLazyQuery(GQLService.queries.getApplicationTypeByPropertyId);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (dataV && !loadingV) {
        setVLANs(dataV?.vlansByPropertyId || []);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [dataV]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (selectedProperty) {
        getVLANsByPropertyId({
          variables: { propertyId: selectedProperty?.id },
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [selectedProperty]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (selectedProperty && !loadingApplicationType) {
        getApplicationTypeByPropertyId({
          variables: {
            propertyId: selectedProperty?.id!,
            from: timeFrom?.valueOf(),
            to: timeTo?.valueOf(),
          },
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [selectedProperty, timeFrom, timeTo]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (resApplicationType && !loadingApplicationType) {
        setAppType(
          resApplicationType?.applicationTypeByPropertyId.map(
            (type: ResponseData) => ({
              x: type.category,
              y: type.percentageUsage,
            })
          )
        );
      }
    }
    return () => {
      isMounted = false;
    };
  }, [loadingApplicationType, resApplicationType]);

  const handleNetworkChange = useCallback(
    (e) => {
      setSelectedVlan(VLANs?.find((v) => v.id === e.target.value));
    },
    [VLANs]
  );

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (!resDataType && !loadingDataType && errorDataType) {
        setDataType([]);
      }
      if (resDataType && !loadingDataType) {
        setDataType([
          ...resDataType.applicationUsageInfoForProperty.map(
            (data: ResponseData) => {
              return { x: data.category, y: data.percentageUsage };
            }
          ),
        ]);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [loadingDataType, resDataType]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (selectedProperty && !loadingDataType) {
        getDataType({
          variables: {
            propertyId: selectedProperty?.id!,
            from: timeFrom?.valueOf(),
            to: timeTo?.valueOf(),
            vlanId: selectedVlan?.id,
          },
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [selectedVlan, selectedProperty, timeFrom, timeTo, selectedTime]);

  return (
    <>
      <Typography className={classes.blackLabel}>Network Usage</Typography>
      <Grid container md={12}>
        <Grid item md={4}>
          <Typography className={classes.blackTextBold}>
            Application Type
          </Typography>
          <VictoryPieChart
            title="Application Type"
            data={appType}
            colors={appTypeColors}
            loading={loadingApplicationType}
          />
        </Grid>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Grid item md={4}>
          <Typography className={classes.blackTextBold}>Data Type</Typography>
          <VictoryPieChart
            title="Data Type"
            data={dataType}
            colors={dataTypeColors}
            loading={loadingDataType}
            error={errorDataType}
          />
        </Grid>
        <Grid item md={2}>
          <Grid
            container={true}
            alignItems="baseline"
            justify="flex-end"
            style={{
              flexWrap: "inherit",
              position: "relative",
              bottom: "35px",
              right: "18px",
            }}
          >
            <Typography className={classes.blackTextBold}>Network</Typography>
            <Select
              classes={{
                root: classes.select,
                select: classes.blackText,
                icon: classes.icon,
              }}
              MenuProps={{
                classes: {
                  paper: classes.menu,
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              IconComponent={ExpandMoreSharpIcon}
              name="floorFilter"
              value={selectedVlan?.id || "All"}
              disableUnderline={true}
              onChange={handleNetworkChange}
            >
              <MenuItem
                key={"All"}
                value={"All"}
                classes={{
                  root: classes.option,
                  selected: classes.selected,
                }}
              >
                {"Common Area Network"}
              </MenuItem>
              {/* commented for future use 
                {VLANs &&
                VLANs.map((v) => (
                  <MenuItem
                    key={v.id}
                    value={v.id}
                    classes={{
                      root: classes.option,
                      selected: classes.selected,
                    }}
                  >
                  {v.name}
                </MenuItem>
              ))} */}
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
