export const Rules = {
  TENANT: {
    static: ["dashboard:list"],
  },
  PROPERTY_ADMIN: {
    static: [
      "properties:list",
      "properties:view",
      "ssids:list",
      "ssids:view",
      "access_points:list",
      "floors:list",
      "dashboard:list",
      "floor_overview:list",
      "vlans:list",
      "access_points:create",
      "access_points:view",
      "access_points:edit",
      "vlans:view",
      "elevators:list",
      "beacons:view",
      "beacons:list",
      "cardReaders:view",
      "cardReaders:edit",
      "cardReaders:deactivate",
      "sensors:view",
      "sensors:list",
      "elevators:view",
      "elevators:list",
      "elevatorBays:list",
      "elevatorBays:view",
      "devices:list",
      "devices:view",
      "upnext:view",
      "upnext:edit",
      "imageSensor:view",
      "imageSensor:edit",
      "imageSensor:delete",
      "zone:view",
      "zone:edit",
      "tripWire:view",
      "tripWire:edit",
      "doors:create",
      "doors:list",
      "doors:delete",
      "admin:list",
      "group:list",
      "group:add",
      "userGroup:create",
      "permission:create",
      "doorUserGroup:create",
      "spaces:list",
      "spaces:edit",
      "spaces:view",
      "notifications:create",
      "my_pins:list",
      "portfolio:list",
    ],
  },
  JOIN_ADMIN: {
    static: [
      "properties:list",
      "properties:view",
      "properties:create",
      "properties:edit",
      "properties:delete",
      "ssids:list",
      "ssids:create",
      "ssids:edit",
      "ssids:delete",
      "ssids:view",
      "ssids:protected",
      "access_points:list",
      "access_points:create",
      "access_points:edit",
      "access_points:delete",
      "access_points:view",
      "floors:list",
      "floors:edit",
      "floors:create",
      "floors:delete",
      "users:create",
      "users:edit",
      "users:delete",
      "users:list",
      "users:view",
      "dashboard:list",
      "vlans:list",
      "vlans:create",
      "vlans:edit",
      "vlans:delete",
      "vlans:view",
      "vlans:protected",
      "network:list",
      "sensors:create",
      "sensors:edit",
      "sensors:delete",
      "sensors:list",
      "sensors:view",
      "beacons:create",
      "beacons:edit",
      "beacons:delete",
      "beacons:list",
      "beacons:view",
      "cardReaders:view",
      "cardReaders:edit",
      "cardReaders:deactivate",
      "elevators:create",
      "elevators:edit",
      "elevators:delete",
      "elevators:list",
      "elevators:view",
      "elevatorBays:create",
      "elevatorBays:edit",
      "elevatorBays:delete",
      "elevatorBays:list",
      "elevatorBays:view",
      "application_type:list",
      "floor_overview:list",
      "devices:list",
      "devices:view",
      "devices:edit",
      "upnext:view",
      "upnext:edit",
      "imageSensor:view",
      "imageSensor:edit",
      "imageSensor:delete",
      "zone:view",
      "zone:edit",
      "tripWire:view",
      "tripWire:edit",
      "doors:create",
      "doors:list",
      "doors:edit",
      "doors:delete",
      "admin:list",
      "group:list",
      "group:add",
      "userGroup:create",
      "permission:create",
      "doorUserGroup:create",
      "spaces:list",
      "spaces:create",
      "spaces:edit",
      "spaces:view",
      "notifications:create",
      "my_pins:list",
      "portfolio:list",
      "access_control_system:list",
    ],
  },
};

export default Rules;
