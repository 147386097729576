import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Button,
  Checkbox,
} from "@material-ui/core";
import { useStyles } from "./Summary.styles";
import { NetworkAnalyticsAccessPoints } from "./AccessPoints/AccessPoints";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";
import moment from "moment";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import clsx from "clsx";
import { useAuth } from "../../../core/context/containers/Auth.container";
import { DashboardDeviceType, Property } from "../../../types";
import { useFloorState } from "../../../core/context/containers/Floor.container";
import { CustomModal } from "../../../common/components";
import { FilterSaveModal } from "./Statistics/Summary/FilterSaveModal";
import { COLORS } from "../../../common/styles/colors";
import Loader from "react-loader-spinner";
import { SwapHoriz } from "@material-ui/icons";
import { useUserState } from "../../../core/context/containers/User.container";
import _ from "lodash";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../../common/constants/DummyProperty";

export function Summary({
  userPreferenceFilterSummary,
  loadingUserFilterPrefernceSummary,
}: any) {
  const { setProperties, selectedProperty, setSelectedProperty } =
    usePropertiesState();

  const [selectedTime, setSelectedTime] = useState<
    "15m" | "1h" | "24h" | "7d" | "30d"
  >("24h");
  const [offHoursIsActive, setOffHoursIsActive] = useState<boolean>(false);
  const [statisticsValue, setStatisticsValue] = useState<string>("");

  const {
    loading: loadingP,
    error: errorP,
    data: dataP,
  } = useQuery(GQLService.queries.allProperties, {
    fetchPolicy: "network-only",
  });

  const [timeFrom, setTimeFrom] = useState("");
  const [timeTo, setTimeTo] = useState("");
  const { floors, selectedFloorSummary, setSelectedFloorSummary } =
    useFloorState();
  const auth = useAuth();
  const [selectedSpace, setSelectedSpace] = useState<string>("none");
  const [spacesForFloor, setSpacesForFloor] = useState<string[]>([]);
  const classes = useStyles();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [userPreferenceFilter, setUserPreferenceFilter] = useState<
    any | undefined
  >();
  const [isViewSaved, setIsViewSaved] = useState(false);
  const [isSwitchActive, setIsSwitchActive] = useState(false);
  const [isFloorchanged, setIsFloorchanged] = useState(true);
  const [dropDownValue, setDropDownValue] = useState();
  const [isPinned, setIsPinned] = useState<any>();
  const { userEmail } = useUserState();

  const [
    getSpacesByFloorId,
    { data: spacesResponse, loading: loadingSpaces, error: spacesError },
  ] = useLazyQuery(GQLService.queries.spaces, {
    fetchPolicy: "cache-and-network",
  });

  const [
    getDevicesByFloorId,
    {
      loading: loadingDataWithDevicesFloor,
      error: errorDataWithDevicesFloor,
      data: connectedDevicesWithFloor,
    },
  ] = useLazyQuery(GQLService.queries.devicesByFloorId, {
    fetchPolicy: "network-only",
  });

  const [
    getDashboardCardAverageAndCount,
    {
      loading: loadingDashboardCardAverageAndCount,
      error: errorDashboardCardAverageAndCount,
      data: dataDashboardCardAverageAndCount,
    },
  ] = useLazyQuery(GQLService.queries.dashboardCardAverageAndCount, {
    fetchPolicy: "network-only",
  });

  const [
    getDashboardCardAverageAndCountTripwire,
    {
      loading: loadingDashboardCardAverageAndCountTripwire,
      error: errorDashboardCardAverageAndCountTripwire,
      data: dataDashboardCardAverageAndCountTripwire,
    },
  ] = useLazyQuery(GQLService.queries.dashboardCardAverageAndCountTripwire, {
    fetchPolicy: "network-only",
  });

  const [
    getDashboardCardAverageAndCountZone,
    {
      loading: loadingDashboardCardAverageAndCountZone,
      error: errorDashboardCardAverageAndCountZone,
      data: dataDashboardCardAverageAndCountZone,
    },
  ] = useLazyQuery(GQLService.queries.dashboardCardAverageAndCountZone, {
    fetchPolicy: "network-only",
  });

  const [
    updateUserFilterPreference,
    {
      data: userFilterPrefernceResponseT,
      loading: loadingUserFilterPrefernceT,
      error: userFilterPrefernceErrorT,
    },
  ] = useMutation(GQLService.mutations.updateUserFilterPreference);

  const timeRange = useMemo(() => {
    if (selectedTime === "15m") return "MINUTE";
    if (selectedTime === "1h") return "QUARTER";
    if (selectedTime === "24h") return "HOUR";
    if (selectedTime === "7d") return "DAY";
    if (selectedTime === "30d") return "DAY";
  }, [selectedTime]);

  const get15minTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === "15m") {
      if (selectedProperty?.timeZoneName) {
        newTimeTo = moment
          .tz(selectedProperty?.timeZoneName)
          .startOf("minute")
          .subtract(3, "minutes")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedProperty?.timeZoneName)
          .subtract(15, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf("minute")
          .subtract(3, "minutes")
          .format();
        newTimeFrom = moment.utc(newTimeTo).subtract(15, "minutes").format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, selectedProperty?.timeZoneName]);

  const requestObjectForDevices = useMemo(() => {
    if (selectedFloorSummary) {
      return {
        request: {
          floorId: selectedFloorSummary?.id!,
          from: timeFrom?.valueOf(),
          to: timeTo?.valueOf(),
          timeRangeType: timeRange,
          spaceId: selectedSpace,
          userEmail: userEmail,
        },
      };
    }
  }, [
    selectedFloorSummary,
    timeFrom,
    timeTo,
    timeRange,
    selectedSpace,
    userEmail,
  ]);

  const requestObjectForDashboardDevices = useMemo(() => {
    if (selectedFloorSummary) {
      return {
        request: {
          floorId: selectedFloorSummary?.id!,
          deviceType: DashboardDeviceType.DASHBOARD_ACCESSPOINT,
          from:
            selectedTime === "15m"
              ? get15minTimeParams().newTimeFrom
              : timeFrom?.valueOf(),
          to:
            selectedTime === "15m"
              ? get15minTimeParams().newTimeTo
              : timeTo?.valueOf(),
          timeRangeType: timeRange,
          spaceId: selectedSpace,
          userEmail: userEmail,
          excludeOffHours: offHoursIsActive,
        },
      };
    }
  }, [
    selectedFloorSummary,
    timeFrom,
    timeTo,
    timeRange,
    selectedSpace,
    userEmail,
    offHoursIsActive,
    selectedTime,
    get15minTimeParams,
  ]);

  const requestObjectForDashboardDevicesTripwire = useMemo(() => {
    if (selectedFloorSummary) {
      return {
        request: {
          floorId: selectedFloorSummary?.id!,
          deviceType: DashboardDeviceType.DASHBOARD_TRIPWIRE,
          from: timeFrom?.valueOf(),
          to: timeTo?.valueOf(),
          timeRangeType: timeRange,
          spaceId: selectedSpace,
          userEmail: userEmail,
          excludeOffHours: offHoursIsActive,
        },
      };
    }
  }, [
    selectedFloorSummary,
    selectedTime,
    timeFrom,
    timeTo,
    timeRange,
    selectedSpace,
    userEmail,
    offHoursIsActive,
  ]);

  const requestObjectForDashboardDevicesZone = useMemo(() => {
    if (selectedFloorSummary) {
      return {
        request: {
          floorId: selectedFloorSummary?.id!,
          deviceType: DashboardDeviceType.DASHBOARD_ZONE,
          from: timeFrom?.valueOf(),
          to: timeTo?.valueOf(),
          timeRangeType: timeRange,
          spaceId: selectedSpace,
          userEmail: userEmail,
          excludeOffHours: offHoursIsActive,
        },
      };
    }
  }, [
    selectedFloorSummary,
    timeFrom,
    timeTo,
    timeRange,
    selectedSpace,
    userEmail,
    offHoursIsActive,
  ]);

  useEffect(() => {
    setIsFloorchanged(true);
    setOffHoursIsActive(false);
  }, [selectedProperty]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && dataP?.properties.length && auth?.user) {
      if (userEmail === dummyUserEmail) {
        let clonedData = _.cloneDeep(dataP?.properties);
        const newProperties = clonedData?.map((item: any) => {
          if (item.id === DummyPropertyA.id) {
            const propA = {
              ...item,
              ownerCompanyName: DummyPropertyA.ownerCompanyName,
              siteName: DummyPropertyA.siteName,
              siteServiceAddress: DummyPropertyA.siteServiceAddress,
            };
            return propA;
          } else if (item.id === DummyPropertyB.id) {
            const propB = {
              ...item,
              ownerCompanyName: DummyPropertyB.ownerCompanyName,
              siteName: DummyPropertyB.siteName,
              siteServiceAddress: DummyPropertyB.siteServiceAddress,
            };
            return propB;
          } else if (item.id === DummyPropertyC.id) {
            const propC = {
              ...item,
              ownerCompanyName: DummyPropertyC.ownerCompanyName,
              siteName: DummyPropertyC.siteName,
              siteServiceAddress: DummyPropertyC.siteServiceAddress,
            };
            return propC;
          } else if (item.id === DummyPropertyD.id) {
            const propD = {
              ...item,
              ownerCompanyName: DummyPropertyD.ownerCompanyName,
              siteName: DummyPropertyD.siteName,
              siteServiceAddress: DummyPropertyD.siteServiceAddress,
            };
            return propD;
          } else {
            return item;
          }
        });
        const demoUserProps = newProperties?.filter(
          (item: any) =>
            item.id === DummyPropertyA.id ||
            item.id === DummyPropertyB.id ||
            item.id === DummyPropertyC.id ||
            item.id === DummyPropertyD.id
        );
        const property =
          demoUserProps.find(
            (property: Property) =>
              property.id === auth?.user?.defaultProperty?.id
          ) || dataP?.properties[0];
        setSelectedProperty(selectedProperty ? selectedProperty : property);
      } else {
        const property =
          dataP?.properties.find(
            (property: Property) =>
              property.id === auth?.user?.defaultProperty?.id
          ) || dataP?.properties[0];
        setSelectedProperty(selectedProperty ? selectedProperty : property);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [dataP, auth]);

  useEffect(() => {
    if (userPreferenceFilterSummary) {
      setUserPreferenceFilter(userPreferenceFilterSummary.userFilterPreference);
      setIsViewSaved(true);
    } else {
      setUserPreferenceFilter(null);
    }
  }, [userPreferenceFilterSummary, selectedProperty]);

  useEffect(() => {
    if (!loadingUserFilterPrefernceT && userFilterPrefernceResponseT) {
      setUserPreferenceFilter(
        userFilterPrefernceResponseT?.updateUserFilterPreference
      );
      setIsViewSaved(true);
    } else {
      setUserPreferenceFilter(null);
    }
  }, [userFilterPrefernceResponseT]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (userPreferenceFilter && isFloorchanged) {
        const userPreferenceFloor = floors.find(
          (v) => v.id === userPreferenceFilter.dataValue.preferences.floor
        );
        setSelectedFloorSummary(userPreferenceFloor);
      } else {
        setSelectedFloorSummary(
          floors.find((v) => v.floorNumber === 1) || floors[0]
        );
        setIsViewSaved(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [floors, selectedProperty, userPreferenceFilter]);

  const handleFloorChange = useCallback(
    (e) => {
      setIsFloorchanged(false);
      setSelectedFloorSummary(floors.find((v) => v.id === e.target.value));
      setIsSwitchActive(true);
      setIsViewSaved(false);
    },
    [floors]
  );

  useEffect(() => {
    if (userPreferenceFilter && isFloorchanged) {
      const findSpace = spacesForFloor?.find(
        (item: any) =>
          item.id === userPreferenceFilter.dataValue.preferences.spaces
      );
      if (findSpace) {
        setSelectedSpace(userPreferenceFilter.dataValue.preferences.spaces);
      } else {
        setSelectedSpace("none");
      }
      setIsViewSaved(true);
    } else {
      setSelectedSpace("none");
    }
  }, [
    selectedProperty,
    selectedFloorSummary,
    setSelectedSpace,
    userPreferenceFilter,
    isFloorchanged,
    spacesForFloor,
  ]);

  useEffect(() => {
    if (userPreferenceFilter) {
      const duration = userPreferenceFilter.dataValue.preferences.duration;
      if (duration === "Last 15 minutes") {
        setSelectedTime("15m");
      } else if (duration === "Last hour") {
        setSelectedTime("1h");
      } else if (duration === "Last 24 hours") {
        setSelectedTime("24h");
      } else if (duration === "Last 7 days") {
        setSelectedTime("7d");
      } else {
        setSelectedTime("30d");
      }
    } else {
      setSelectedTime("24h");
    }
  }, [userPreferenceFilter, setSelectedTime, selectedProperty]);

  useEffect(() => {
    getDevicesByFloorId({
      variables: requestObjectForDevices,
    });
    getDashboardCardAverageAndCount({
      variables: requestObjectForDashboardDevices,
    });
    getDashboardCardAverageAndCountTripwire({
      variables: requestObjectForDashboardDevicesTripwire,
    });
    getDashboardCardAverageAndCountZone({
      variables: requestObjectForDashboardDevicesZone,
    });
    setIsPinned(false);
  }, [
    requestObjectForDevices,
    selectedSpace,
    isPinned,
    getDevicesByFloorId,
    requestObjectForDashboardDevices,
    getDashboardCardAverageAndCount,
    getDashboardCardAverageAndCountTripwire,
    requestObjectForDashboardDevicesTripwire,
    getDashboardCardAverageAndCountZone,
    requestObjectForDashboardDevicesZone,
  ]);

  const handleSpaceChange = (e: any) => {
    setSelectedSpace(e.target.value);
    setIsSwitchActive(true);
    setIsViewSaved(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getSpacesByFloorId({
        variables: {
          floorId: selectedFloorSummary?.id!,
        },
      });
    }
    return () => {
      isMounted = false;
    };
  }, [getSpacesByFloorId, selectedFloorSummary?.id]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const spaceName = [
        ...new Set(
          spacesResponse?.spaces.map((el: any) => ({
            id: el.id,
            name: el.name,
          }))
        ),
      ];
      setSpacesForFloor(spaceName as string[]);
    }
    return () => {
      isMounted = false;
    };
  }, [spacesResponse, loadingSpaces]);

  const handleTimeChange = useCallback(
    (e) => {
      setSelectedTime(e.target.value);
      setIsSwitchActive(true);
      setIsViewSaved(false);
      setOffHoursIsActive(false);
    },
    [selectedTime]
  );

  useMemo(() => {
    let timeSetter;
    let newTimeTo;
    let newTimeFrom;
    switch (selectedTime) {
      case "15m":
        timeSetter = 15;
        break;
      case "1h":
        timeSetter = 60;
        break;
      case "24h":
        timeSetter = 1440;
        break;
      case "7d":
        timeSetter = 10080;
        break;
      case "30d":
        timeSetter = 43200;
        break;
      default:
        timeSetter = 60;
        break;
    }

    if (selectedTime === "7d" || selectedTime === "30d") {
      if (selectedProperty?.timeZoneName) {
        newTimeTo = moment
          .tz(selectedProperty?.timeZoneName)
          .startOf("day")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedProperty?.timeZoneName)
          .subtract(timeSetter, "minutes")
          .format();
      } else {
        newTimeTo = moment.utc().startOf("day").format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(timeSetter, "minutes")
          .format();
      }
    } else {
      if (selectedProperty?.timeZoneName) {
        newTimeTo = moment
          .tz(selectedProperty?.timeZoneName)
          .startOf(selectedTime === "24h" ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedProperty?.timeZoneName)
          .subtract(timeSetter, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf(selectedTime === "24h" ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(timeSetter, "minutes")
          .format();
      }
    }
    setTimeTo(newTimeTo);
    setTimeFrom(newTimeFrom);
  }, [selectedProperty?.timeZoneName, selectedTime]);

  const handleSaveFilter = useCallback((userPref: any) => {
    updateUserFilterPreference({
      variables: userPref,
    });
  }, []);

  const handleState = () => {
    if (isFloorchanged === false) {
      setIsFloorchanged(true);
    }
  };

  const timeLable = () => {
    if (selectedTime === "15m") {
      return "Last 15 minutes";
    } else if (selectedTime === "1h") {
      return "Last hour";
    } else if (selectedTime === "24h") {
      return "Last 24 hours";
    } else if (selectedTime === "7d") {
      return "Last 7 days";
    } else {
      return "Last 30 days";
    }
  };

  const handleSwitch = () => {
    const userPreferenceFloor = floors.find(
      (v) => v.id === userPreferenceFilter.dataValue.preferences.floor
    );
    setSelectedFloorSummary(userPreferenceFloor);
    setTimeout(() => {
      const findSpace = spacesForFloor?.find(
        (item: any) =>
          item.id === userPreferenceFilter.dataValue.preferences.spaces
      );
      if (findSpace) {
        setSelectedSpace(userPreferenceFilter.dataValue.preferences.spaces);
      } else {
        setSelectedSpace("none");
      }
    }, 1000);

    const duration = userPreferenceFilter.dataValue.preferences.duration;
    if (duration === "Last 15 minutes") {
      setSelectedTime("15m");
    } else if (duration === "Last hour") {
      setSelectedTime("1h");
    } else if (duration === "Last 24 hours") {
      setSelectedTime("24h");
    } else if (duration === "Last 7 days") {
      setSelectedTime("7d");
    } else {
      setSelectedTime("30d");
    }
    setDropDownValue(userPreferenceFilter.dataValue.preferences.statistics);
    setStatisticsValue(userPreferenceFilter.dataValue.preferences.statistics);
    setIsSwitchActive(false);
    setIsViewSaved(true);
  };

  // UseEffect to show Saved View button if all filters are selected as saved preference
  useEffect(() => {
    if (userPreferenceFilter) {
      const duration = userPreferenceFilter.dataValue.preferences.duration;
      let timeLabel;
      if (selectedTime === "15m") {
        timeLabel = "Last 15 minutes";
      } else if (selectedTime === "1h") {
        timeLabel = "Last hour";
      } else if (selectedTime === "24h") {
        timeLabel = "Last 24 hours";
      } else if (selectedTime === "7d") {
        timeLabel = "Last 7 days";
      } else {
        timeLabel = "Last 30 days";
      }
      if (selectedFloorSummary && selectedSpace && selectedTime) {
        let spaceCopy: any;
        spaceCopy = userPreferenceFilter.dataValue.preferences.spaces;
        const findSpace = spacesForFloor?.find(
          (item: any) => item.id === spaceCopy
        );
        if (findSpace) {
          spaceCopy = userPreferenceFilter.dataValue.preferences.spaces;
        } else {
          spaceCopy = "none";
        }
        if (
          selectedFloorSummary?.id ===
            userPreferenceFilter.dataValue.preferences.floor &&
          selectedSpace === spaceCopy &&
          timeLabel === duration &&
          dropDownValue ===
            userPreferenceFilter.dataValue.preferences.statistics
        ) {
          setIsSwitchActive(false);
          setIsViewSaved(true);
        }
      }
    }
  }, [
    selectedFloorSummary,
    selectedSpace,
    selectedTime,
    userPreferenceFilter,
    dropDownValue,
  ]);

  const handleSelectedValue = (value: any) => {
    setDropDownValue(value);
    setStatisticsValue(value);
    setIsSwitchActive(true);
    setIsViewSaved(false);
  };

  const handleIsPinned = (value: any) => {
    setIsPinned(value);
  };

  useEffect(() => {
    if (selectedTime === "15m" || selectedTime === "1h") {
      setOffHoursIsActive(false);
    }
    if (statisticsValue !== "Occupancy") {
      setOffHoursIsActive(false);
    }
  }, [selectedTime, statisticsValue]);

  const handleChange = useCallback(
    (e) => {
      setOffHoursIsActive(!offHoursIsActive);
    },
    [offHoursIsActive]
  );

  return (
    <Grid container={true} style={{ overflow: "hidden" }}>
      {loadingUserFilterPrefernceSummary ? (
        <Loader type="Oval" color={COLORS.funBlue} height={40} width={40} />
      ) : (
        <Grid
          className={classes.filters}
          container={true}
          item={true}
          justify="space-between"
          alignItems="flex-start"
          md={12}
          sm={12}
        >
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div className={classes.summaryDiv}>
              <Typography className={classes.blackLabel}>Summary</Typography>
            </div>
            <div className={classes.selectContainer}>
              <Typography className={classes.label}>Floor</Typography>
              <Select
                classes={{
                  root: classes.select,
                  select: classes.blackText,
                  icon: classes.icon,
                }}
                MenuProps={{
                  classes: {
                    paper: classes.menu,
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                IconComponent={ExpandMoreSharpIcon}
                name="floorFilter"
                value={selectedFloorSummary?.id || "All"}
                disableUnderline={true}
                onChange={handleFloorChange}
              >
                {floors &&
                  floors.map((v) => (
                    <MenuItem
                      key={v.id}
                      value={v.id}
                      classes={{
                        root: classes.option,
                        selected: classes.selected,
                      }}
                    >
                      {v.name || v.floorNumber}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div className={classes.selectContainer}>
              <Typography className={classes.label}>Spaces</Typography>
              <Select
                classes={{
                  root: classes.select,
                  select: classes.blackText,
                  icon: classes.icon,
                }}
                MenuProps={{
                  classes: {
                    paper: classes.menu,
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                IconComponent={ExpandMoreSharpIcon}
                name="spaceFilter"
                value={selectedSpace}
                disableUnderline={true}
                onChange={(e) => handleSpaceChange(e)}
              >
                <MenuItem
                  value="none"
                  classes={{
                    root: classes.option,
                    selected: classes.selected,
                  }}
                >
                  Whole Floor
                </MenuItem>
                {spacesForFloor &&
                  spacesForFloor.map((v: any) => (
                    <MenuItem
                      key={v.id}
                      value={v.id}
                      classes={{
                        root: classes.option,
                        selected: classes.selected,
                      }}
                    >
                      {v.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div
              className={classes.selectContainer}
              style={{ flexDirection: "column" }}
            >
              <div className={classes.durationDiv}>
                <Typography className={classes.label}>Duration</Typography>
                <Select
                  classes={{
                    root: clsx(classes.select, classes.durationSelect),
                    select: classes.blackText,
                    icon: classes.icon,
                  }}
                  MenuProps={{
                    classes: {
                      paper: classes.menu,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  IconComponent={ExpandMoreSharpIcon}
                  name="timeFilter"
                  disableUnderline={true}
                  onChange={(e) => handleTimeChange(e)}
                  value={selectedTime}
                >
                  <MenuItem
                    value={"15m"}
                    classes={{
                      root: classes.option,
                      selected: classes.selected,
                    }}
                  >
                    Last 15 minutes
                  </MenuItem>
                  <MenuItem
                    value={"1h"}
                    classes={{
                      root: classes.option,
                      selected: classes.selected,
                    }}
                  >
                    Last hour
                  </MenuItem>
                  <MenuItem
                    value={"24h"}
                    classes={{
                      root: classes.option,
                      selected: classes.selected,
                    }}
                  >
                    Last 24 hours
                  </MenuItem>
                  <MenuItem
                    value={"7d"}
                    classes={{
                      root: classes.option,
                      selected: classes.selected,
                    }}
                  >
                    {" "}
                    Last 7 days
                  </MenuItem>
                  <MenuItem
                    value={"30d"}
                    classes={{
                      root: classes.option,
                      selected: classes.selected,
                    }}
                  >
                    Last 30 days
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.excludeHrDiv}>
                <Grid className={classes.angleIcon}></Grid>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Checkbox
                    value={offHoursIsActive}
                    checked={offHoursIsActive}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    classes={{ root: classes.checkbox }}
                    disabled={
                      selectedTime === "15m" ||
                      selectedTime === "1h" ||
                      statisticsValue !== "Occupancy"
                        ? true
                        : false
                    }
                    onChange={handleChange}
                    disableRipple={true}
                  />
                  <Typography
                    className={clsx(
                      classes.excludeText,
                      selectedTime === "15m" ||
                        selectedTime === "1h" ||
                        statisticsValue !== "Occupancy"
                        ? classes.disabledText
                        : classes.enableText
                    )}
                  >
                    Exclude OFF hours
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            {isSwitchActive && !isViewSaved && userPreferenceFilter && (
              <Grid className={classes.singleFilter}>
                <Button className={classes.switchButton} onClick={handleSwitch}>
                  <SwapHoriz style={{ marginRight: "5px" }} />
                  Switch
                </Button>
              </Grid>
            )}
            {loadingUserFilterPrefernceSummary ? (
              <Loader
                type="Oval"
                color={COLORS.funBlue}
                height={40}
                width={40}
              />
            ) : isViewSaved ? (
              <Button className={classes.savedViewBtn} disableRipple={true}>
                Saved View !
              </Button>
            ) : (
              <Button
                className={classes.saveButton}
                onClick={() => toggleModal(true)}
              >
                Save This View
              </Button>
            )}
          </div>
        </Grid>
      )}
      <NetworkAnalyticsAccessPoints
        timeFrom={timeFrom}
        timeTo={timeTo}
        selectedTime={selectedTime}
        connectedDevicesWithFloor={connectedDevicesWithFloor?.devicesByFloorId}
        selectedFloorNew={selectedFloorSummary}
        selectedSpace={selectedSpace}
        handleSelectedValue={handleSelectedValue}
        userPreferenceFilterSummary={userPreferenceFilter}
        loadingUserFilterPrefernceSummary={loadingUserFilterPrefernceSummary}
        dropDownValue={dropDownValue}
        loadingUserFilterPrefernceT={loadingUserFilterPrefernceT}
        handleIsPinned={handleIsPinned}
        dataDashboardCardAverageAndCount={
          dataDashboardCardAverageAndCount?.dashboardCardAverageAndCount
        }
        dataDashboardCardAverageAndCountTripwire={
          dataDashboardCardAverageAndCountTripwire?.dashboardCardAverageAndCount
        }
        dataDashboardCardAverageAndCountZone={
          dataDashboardCardAverageAndCountZone?.dashboardCardAverageAndCount
        }
        offHoursIsActive={offHoursIsActive}
      />
      <CustomModal open={showModal}>
        <FilterSaveModal
          toggleModal={toggleModal}
          selectedLabel={timeLable()}
          handleSaveFilter={handleSaveFilter}
          selectedSpace={selectedSpace}
          floor={selectedFloorSummary}
          handleState={handleState}
          spacesForFloor={spacesForFloor}
          dropDownValue={dropDownValue}
        />
      </CustomModal>
    </Grid>
  );
}
