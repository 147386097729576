import { Input } from "@material-ui/core";
import { FloorSelect } from "../../../../common/components/Form/FormFields/FloorSelect/FloorSelect.v2";
import { CreateFormWizardSteps, FormFieldInputType } from "../../../../types";
import { DayDragAndDropSelect } from "./DayDragAndDropSelect";

import { ActivitySwitch, Select } from "../../../../common/components/Form/FormFields";
import { HourSelect } from "../../../../common/components/Form/FormFields/HourSelect/HourSelectWIP";
import { DoorUserGroupList } from "../components/DoorUserGroupList";
import * as Yup from "yup";
import I18n from "../../../../core/services/I18n.service";

const FIELD_NAMES = {
  name: "name",
  doorUuid: "doorUuid",
  floorId: "floorId",
  workingFrom: "workingFrom",
  workingTo: "workingTo",
  active: "active",
  availableDays: "availableDays",
  doorAccessControlVendorId: "doorAccessControlVendorId",
  groups: "groups",
};

export const addDoorFormWizard: CreateFormWizardSteps = (data: {
  [name: string]: [];
}) => {

  return [
    {
      name: "Door Details",
      fieldset: [
        {
          label: "",
          fields: [
            {
              name: FIELD_NAMES.name,
              label: "Door Name",
              placeholder: "Type here",
              required: true,
              gridStyle: { sm: 7, md: 7 },
              type: FormFieldInputType.string,
              Component: Input,
            },
            {
              name: FIELD_NAMES.doorUuid,
              label: "Door UUID",
              placeholder: "Type here",
              required: true,
              type: FormFieldInputType.string,
              gridStyle: { sm: 7, md: 7 },
              Component: Input,
            },
            {
              name: FIELD_NAMES.floorId,
              label: "Floor",
              required: true,
              gridStyle: { sm: 7, md: 7 },
              data: data.floors,
              placeholder: "Select floor",
              Component: FloorSelect,
            },
            {
              name: FIELD_NAMES.availableDays,
              label: "Available Days",
              required: false,
              gridStyle: { sm: 12, md: 12 },
              Component: DayDragAndDropSelect,
              data: [
                { id: "MONDAY" },
                { id: "TUESDAY" },
                { id: "WEDNESDAY" },
                { id: "THURSDAY" },
                { id: "FRIDAY" },
                { id: "SATURDAY" },
                { id: "SUNDAY" },
              ],
            },
            {
              name: FIELD_NAMES.workingFrom,
              label: "Hour From:",
              required: true,
              gridStyle: { sm: 2, md: 2 },
              Component: HourSelect,
              type: FormFieldInputType.string,
              placeholder: "Hour From",
              customProps: {
                min: parseInt(data.workingFrom as unknown as string),
                max: parseInt(data.workingTo as unknown as string) + 1,
              },
            },
            {
              name: FIELD_NAMES.workingTo,
              label: "Hour To:",
              required: true,
              gridStyle: { sm: 7, md: 7 },
              Component: HourSelect,
              type: FormFieldInputType.string,
              placeholder: "Hour To",
              customProps: {
                min: parseInt(data.workingFrom as unknown as string),
                max: parseInt(data.workingTo as unknown as string) + 1,
              },
            },
            {
              name: FIELD_NAMES.doorAccessControlVendorId,
              label: "Access Control Technology",
              required: true,
              gridStyle: { sm: 7, md: 7 },
              Component: Select,
              data: [{ id: "1", name: "Doordeck" }, { id: "2", name: "OpenPath" }],
              placeholder: "Select Access Control Technology",
            },
            {
              name: FIELD_NAMES.active,
              label: "Active",
              required: false,
              gridStyle: { sm: 7, md: 7 },
              Component: ActivitySwitch,
            },
          ],
          validationSchema: addDoorValidationSchema(),
          defaultValues: {
            [FIELD_NAMES.active]: true,
          }
        },
      ],
    },
    {
      name: "Assigned User Groups",
      fieldset: [
        {
          fields: [
            {
              name: FIELD_NAMES.groups,
              label: "",
              Component: DoorUserGroupList,
            }
          ],
        },
      ],
    },
  ]
}

function addDoorValidationSchema() {
  return Yup.object().shape({
    [FIELD_NAMES.name]: Yup.string()
      .min(
        3,
        I18n.formatMessage({
          id: "addDoor.validation.name.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addDoor.validation.name.long",
          defaultMessage: "Too long!",
        })
      )
      .required("Required"),
    [FIELD_NAMES.doorUuid]: Yup.string()
    .min(
      3,
      I18n.formatMessage({
        id: "addDoor.validation.doorUuid.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "addDoor.validation.doorUuid.long",
        defaultMessage: "Too long!",
      })
    )
    .required("Required"),
    [FIELD_NAMES.floorId]: Yup.string().required("Required"),
    [FIELD_NAMES.workingFrom]: Yup.string().required("Required"),
    [FIELD_NAMES.workingTo]: Yup.string().required("Required"),
    [FIELD_NAMES.doorAccessControlVendorId]: Yup.string().required("Required"),
  });
}