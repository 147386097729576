import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, makeStyles, Button, Typography, Input } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import * as _ from "lodash";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { FloorResponse } from "../../../../types/notification";
import { Band, SSID } from "../../../../types";
import * as csvConstants from "../../../../types/csvConstants";
import Loader from "react-loader-spinner";

const useStyles = makeStyles((theme) => ({
  okBtn: {
    color: theme.palette.common.funBlue,
    width: 120,
    height: 42,
    borderRadius: 20,
    border: `1px solid ${theme.palette.common.funBlue}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "none",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.common.mediumBlack,
    marginBottom: "20px",
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginBottom: "20px",
  },
  content: {
    display: "flex",
    minWidth: "1100px",
    height: "650px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    paddingTop: "32px",
    paddingLeft: "23px",
    paddingRight: "20px",
    paddingBottom: "16px",
    borderRadius: "20px",
    boxShadow: "0px 4px 21px rgba(0, 0, 0, 0.38)",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "",
    marginRight: "16px",
    marginTop: "16px",
  },
  loaderGrid: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  input: {
    width: 368,
    height: 40,
    border: `1px solid ${theme.palette.common.inputGrey}`,
    borderRadius: 10,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: 20,
    marginRight: "30px",
  },
  templateDescription: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginBottom: "30px",
  },
  cancel: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 100,
    height: 42,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  anchorTag: {
    textDecoration: "none",
    color: "#2056a6",
    fontWeight: 500,
  },
  tableTH: {
    padding: "10px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
  },
  table: {
    borderLeft: `1px solid #E0EBF1`,
    borderRight: `1px solid #E0EBF1`,
    borderCollapse: "collapse",
  },
  tableTD: {
    padding: "14px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderBottom: `1px solid #E0EBF1`,
    borderCollapse: "collapse",
  },
  tableDiv: {
    overflowY: "scroll",
    height: "420px",
  },
}));

interface ImportBulkAPModalProps {
  yes: Function;
  data?: any;
  importButtonClick?: Function;
}

export function ImportBulkAPModal({
  yes,
  data,
  importButtonClick,
}: ImportBulkAPModalProps) {
  const classes = useStyles();
  const { selectedProperty } = usePropertiesState();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(false);

  const { error: floorError, loading: floorLoading, data: floors } = useQuery(
    GQLService.queries.allFloors,
    {
      variables: { propertyId: selectedProperty?.id },
      fetchPolicy: "network-only",
    }
  );

  const { error: ssidError, loading: ssidLoading, data: ssids } = useQuery(
    GQLService.queries.allSsid,
    {
      variables: { propertyId: selectedProperty?.id },
      fetchPolicy: "network-only",
    }
  );

  const [createMultipleAccessPoint] = useMutation(
    GQLService.mutations.createMultipleAccessPoint,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allAccessPoints,
          variables: { propertyId: selectedProperty?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const confirmCancel = () => {
    yes();
  };

  const handleImport = async () => {
    try {
      setLoading(true);
      const formattedData = data.map((v: any) => {
        let filterFloor = floors?.floorsByPropertyId?.find(
          (el: FloorResponse) =>
            el.floorNumber === parseInt(v[csvConstants.PHYSICAL_LOCATION])
        );

        const ssidForRadio1 = v[csvConstants.SSID_RADIO_1]
          .split(";")
          .join(",")
          .split(",");
        const ssidForRadio2 = v[csvConstants.SSID_RADIO_2]
          .split(";")
          .join(",")
          .split(",");

        const filterRadio1 = ssids?.ssidsByPropertyId?.filter((item: SSID) => {
          return ssidForRadio1.find((e: any) => {
            if (item.name.toLowerCase() === e.toLowerCase()) {
              return item.id;
            }
          });
        });
        const ssidRadio1Ids = filterRadio1.map((i: any) => i.id);

        const filterRadio2 = ssids?.ssidsByPropertyId?.filter((item: SSID) => {
          return ssidForRadio2.find((e: any) => {
            if (item.name.toLowerCase() === e.toLowerCase()) {
              return item.id;
            }
          });
        });
        const ssidRadio2Ids = filterRadio2.map((i: any) => i.id);

        return {
          active:
            v[csvConstants.STATUS].toLowerCase() === "active" ? true : false,
          radio1: {
            band:
              v[csvConstants.RADIO_1_BAND] === "2.4"
                ? Band.G2_4
                : v[csvConstants.RADIO_1_BAND] === "5"
                ? Band.G5
                : Band.NONE,
            ssids: ssidRadio1Ids.length > 0 ? ssidRadio1Ids : [],
          },
          radio2: {
            band:
              v[csvConstants.RADIO_2_BAND] === "2.4"
                ? Band.G2_4
                : v[csvConstants.RADIO_2_BAND] === "5"
                ? Band.G5
                : Band.NONE,
            ssids: ssidRadio2Ids.length > 0 ? ssidRadio2Ids : [],
          },
          name: v[csvConstants.AP_NAME],
          floorId: filterFloor?.id!,
          ipAddress: v[csvConstants.IPADDRESS],
          baseMacAddress: v[csvConstants.BASE_MAC_ADDRESS].toLowerCase(),
          inventoryNumber: v[csvConstants.INVENTORY]
            ? parseInt(v[csvConstants.INVENTORY])
            : 0,
        };
      });

      await createMultipleAccessPoint({
        variables: { request: { accesspoints: formattedData } },
      }).then(() =>
        enqueueSnackbar(
          formatMessage({
            id: "accessPoints.add.snackbar.success",
            defaultMessage: `${formattedData.length} Access Points added successfully`,
          }),
          {
            variant: "success",
          }
        )
      );
      importButtonClick!();
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: "error",
      });
      setLoading(false);
      console.log(e);
    }
  };

  const tableHeader = Object.keys(data[0]);

  return (
    <Grid className={classes.content}>
      {loading ? (
        <Grid className={classes.loaderGrid}>
          <Loader type="Oval" height={150} width={150} color="#2F56A0" />
        </Grid>
      ) : (
        <>
          <Grid>
            <Typography className={classes.header}>
              Add Multiple Access Points
            </Typography>
          </Grid>
          <Grid>
            <Typography className={classes.description}>
              Data from the CSV file is as follows
            </Typography>
            <div className={classes.tableDiv}>
              <table className={classes.table}>
                <tr style={{ backgroundColor: "#E0EBF1" }}>
                  {tableHeader.map((e: any) => {
                    return <th className={classes.tableTH}>{e}</th>;
                  })}
                </tr>
                {data.map((val: any, key: any) => {
                  return (
                    <tr key={key}>
                      <td className={classes.tableTD}>
                        {val[csvConstants.AP_NAME]}
                      </td>
                      <td className={classes.tableTD}>
                        {val[csvConstants.PHYSICAL_LOCATION]}
                      </td>
                      <td className={classes.tableTD}>
                        {val[csvConstants.IPADDRESS]}
                      </td>
                      <td className={classes.tableTD}>
                        {val[csvConstants.BASE_MAC_ADDRESS].toLowerCase()}
                      </td>
                      <td className={classes.tableTD}>
                        {val[csvConstants.INVENTORY]}
                      </td>
                      <td className={classes.tableTD}>
                        {val[csvConstants.RADIO_1_BAND]}GHz
                      </td>
                      <td className={classes.tableTD}>
                        {val[csvConstants.RADIO_2_BAND]}GHz
                      </td>
                      <td className={classes.tableTD}>
                        {val[csvConstants.STATUS].toLowerCase() === "active"
                          ? "Active"
                          : "Inactive"}
                      </td>
                      <td className={classes.tableTD}>
                        {val[csvConstants.SSID_RADIO_1]}
                      </td>
                      <td className={classes.tableTD}>
                        {val[csvConstants.SSID_RADIO_2]}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </Grid>
          <Grid>
            <Grid item className={classes.buttonsWrapper}>
              <Button
                id="confirmBtn"
                className={classes.cancel}
                onClick={() => confirmCancel()}
              >
                Cancel
              </Button>
              <Button
                id="confirmBtn"
                className={classes.okBtn}
                onClick={() => handleImport()}
                style={{
                  backgroundColor: "#2056a6",
                  color: "#ffffff",
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
