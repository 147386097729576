import { makeStyles } from "@material-ui/core/styles";

import {
  DRAWER_WIDTH,
  DRAWER_WIDTH_SM,
} from "./components/DrawerNav/DrawerNav";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.common.white,
  },
  gridWrapper: {
    display: "flex",
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.solidGray}`,
    borderRadius: theme.typography.pxToRem(12),
    padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(
      3
    )} ${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(12)}`,
  },
  toolbar: {
    backgroundColor: "transparent",
    color: theme.palette.common.blackPearl,
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "8px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    height: "80px",
    backgroundColor: theme.palette.common.white,

    [theme.breakpoints.down("md")]: {
      width: `calc(100% - ${DRAWER_WIDTH_SM}px)`,
    },
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
  },
  appBarSpacer: theme.mixins.toolbar,
  divider: {
    backgroundColor: theme.palette.common.switchBlue,
  },
  content: {
    flexGrow: 1,
    // height: "100vh",
    // overflow: "auto",
  },
  container: {
    padding: theme.spacing(6),
    paddingTop: "25px",
  },
  breadcrumbs: {
    marginBottom: 8,
    fontSize: "12px",
    textTransform: "capitalize",
    cursor: "pointer",
    color: theme.palette.common.switchBlue,
    fontWeight: 500,
  },
  separator: {
    width: 14,
  },
  headerWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  propertyContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.typography.pxToRem(16),
  },
  pageHeader: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.common.mediumBlack,
    lineHeight: "36px",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkBlue,
    marginRight: 12,
  },
  select: {
    height: 13,
    minWidth: 100,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  propertySelect: {
    minWidth: 275,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: 9,
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    width: 135,
    borderRadius: "0 0 8px 8px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
}));
