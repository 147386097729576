import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  outerCircle: {
    width: 14,
    height: 14,
    border: `1px solid ${theme.palette.common.chateauGreen}`,
    borderRadius: 7,
  },
  outerCircleGreen: {
    borderColor: theme.palette.common.chateauGreen,
  },
  outerCircleRed: {
    borderColor: theme.palette.common.cinnabar,
  },
  innerCircle: {
    width: 8,
    height: 8,
    borderRadius: 5,
  },
  innerCircleGreen: {
    backgroundColor: theme.palette.common.chateauGreen,
  },
  innerCircleRed: {
    backgroundColor: theme.palette.common.cinnabar,
  },
  statusLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    margin: "0 16px",
  },
  deactivateBtn: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.cinnabar,
    textTransform: "none",
    textDecoration: "underline",
  },
}));
