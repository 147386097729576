import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Grid, makeStyles } from "@material-ui/core";

import { PageHeader, SwipePanel } from "../../../../common/components";
import { LocationDetails } from "./components/LocationDetails/LocationDetails";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { WorkplaceSetings } from "./components/WorkplaceSettings/WorkplaceSettings";
import { BusinessEntity } from "./components/BusinessEntity/LocationDetails";
import { useProperties } from "../../../../core/context/functions/Properties.functions";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";

const useStyles = makeStyles((theme: any) => ({
  editBtn: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    textTransform: "none",
    textDecoration: "underline",
    marginBottom: 30,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  swipePanel: {
    marginBottom: 100,
  },
}));

export function Property() {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { selectedProperty } = usePropertiesState();
  const { selectProperty } = useProperties();
  const history = useHistory();

  const activeUpNext = (tabNames: any) => (
    <SwipePanel tabNames={tabNames}>
      <BusinessEntity property={selectedProperty!} />
      <LocationDetails property={selectedProperty!} />
      <WorkplaceSetings property={selectedProperty!} />
    </SwipePanel>
  );

  const inactiveUpNext = (tabNames: any) => (
    <SwipePanel tabNames={tabNames}>
      <BusinessEntity property={selectedProperty!} />
      <LocationDetails property={selectedProperty!} />
      <WorkplaceSetings property={selectedProperty!} />
    </SwipePanel>
  );

  const tabNames = [
    formatMessage({
      id: "property.tabNames.businessEntity",
      defaultMessage: "Business Entity",
      description: "Business entity tab label",
    }),
    formatMessage({
      id: "property.tabNames.locationDetails",
      defaultMessage: "Location Details",
      description: "Location Details tab label",
    }),
    formatMessage({
      id: "property.tabNames.workplaceSettings",
      defaultMessage: "Workplace Settings",
      description: "Workplace Settings tab label",
    }),
  ];

  const withUpnextTabNames = [...tabNames];

  const location = useLocation();

  const renderTabs = (
    upNext: boolean,
    tabNames: string[],
    withUpnextTabNames: string[]
  ) => (upNext ? activeUpNext(withUpnextTabNames) : inactiveUpNext(tabNames));

  useEffect(() => {
    const pathnames = location.pathname.split("/");
    if (pathnames[pathnames.length - 1] !== selectedProperty?.id) {
      history.push(ROUTES.PROPERTIES);
    }
    if (!selectedProperty) {
      selectProperty(location.pathname.replace("/dashboard/properties/", ""));
    }
  }, []); // eslint-disable-line

  return (
    selectedProperty && (
      <div>
        <Grid container={true} direction="row" alignItems="center">
          <Grid>
            <PageHeader
              title={formatMessage({
                id: "property.header",
                defaultMessage: "Property Details",
                description: "propery details header",
              })}
            />
          </Grid>
        </Grid>
        {renderTabs(selectedProperty?.upNext!, tabNames, withUpnextTabNames)}
      </div>
    )
  );
}
