import { useCallback, useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { createContainer } from "unstated-next";

function useSearchBase() {
  const [searchValue, setSearchValue] = useState<string>("");
  // const history = useHistory()

  // useEffect(()=> {
  //   console.log(history?.location)
  //   setSearchValue("")
  // }, [history?.location?.pathname])

  return {
    searchValue,
    setSearchValue,
  };
}

const { Provider, useContainer } = createContainer(useSearchBase);

export const useSearchState = useContainer;
export const SearchProvider = Provider;
