import React, { useState, useCallback } from "react";
import { Menu, MenuItem, MenuList, makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useDoorState } from "../../../../core/context/containers/Door.container";
import { Door } from "../../../../types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { ReactComponent as HamburgerMenu } from "../../../../common/assets/images/hamburger.svg";
import { CanSee } from "../../../../common/components/canSee/canSee";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 15,
    minHeight: 15,
    maxWidth: 15,
    maxHeight: 15,
    color: theme.palette.common.funBlue,
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    borderBottom: `1px solid ${theme.palette.common.nobel}`,
    "&:last-child": {
      border: "none",
    },
  },
}));

interface DoorMenuProps {
  door: Door;
  index: string;
}

export function DoorMenu({ door, index }: DoorMenuProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState();

  const { selectedProperty } = usePropertiesState();
  const { setSelectedDoor } = useDoorState();

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const open = Boolean(anchorEl);

  const handleEditClick = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedDoor(door);
      history.push(
        ROUTES.EDIT_DOORS.replace(":uid", selectedProperty?.id!).replace(
          ":doorname",
          door.id.split(":")[1]
        )
      );
    },
    [setSelectedDoor]
  );

  const createDoorEditButton = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={handleEditClick}>
        {formatMessage({
          id: "door.contextMenu.editDoor",
          defaultMessage: "Edit Door",
          description: "door context menu edit door label",
        })}
      </MenuItem>
    );
  }, []);

  return (
    <>
      <HamburgerMenu
        id={index}
        className={classes.icon}
        onClick={handleToggleMenu}
      />
      <Menu
        id="properties-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        style={{ zIndex: 500 }}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          <CanSee action="doors:edit">{createDoorEditButton()}</CanSee>
        </MenuList>
      </Menu>
    </>
  );
}
