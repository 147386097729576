import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: any) => ({
  table: {
    borderLeft: `1px solid #E0EBF1`,
    borderRight: `1px solid #E0EBF1`,
    borderCollapse: "collapse",
  },
  tableTH: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
  },
  tableRowData: {
    "&.MuiTableCell-root": {
      borderBottom: "1px solid #E0EBF1",
    },
  },
  tableTD: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
  },
  switch: {
    color: `${theme.palette.common.switchBlue} !important`,
    border: `1px solid ${theme.palette.common.switchBlue}`,
    borderRadius: "30px;",
    height: 17,
    width: 52,
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `#2F56A1 !important`,
    width: 12,
    height: 12,
  },
  checked: {
    color: "transparent !important",
  },
  base: {
    height: 22,
  },
  error: {
    border: `1px solid ${theme.palette.common.cinnabar}`,
    borderRadius: "30px;",
    color: `${theme.palette.common.switchBlue} !important`,
    height: 17,
    width: 52,
  },
  status: {
    color: "#122036",
    marginLeft: 6,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 3,
    marginRight: 6,
    width: "57px",
  },
  outerCircle: {
    width: 14,
    height: 14,
    border: `1px solid ${theme.palette.common.chateauGreen}`,
    borderRadius: 7,
  },
  outerCircleGreen: {
    borderColor: theme.palette.common.chateauGreen,
  },
  outerCircleRed: {
    borderColor: theme.palette.common.cinnabar,
  },
  innerCircle: {
    width: 8,
    height: 8,
    borderRadius: 5,
  },
  innerCircleGreen: {
    backgroundColor: theme.palette.common.chateauGreen,
  },
  innerCircleRed: {
    backgroundColor: theme.palette.common.cinnabar,
  },
  loaderGrid: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  saveButtonGrid: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  saveButton: {
    backgroundColor: theme.palette.common.funBlue,
    borderRadius: 30,
    textTransform: "none",
    width: "240px",
  },
  modalGrid: {
    display: "flex",
    width: "450px",
    height: "320px",
    justifyContent: "space-between",
    position: "absolute",
    backgroundColor: "#ffffff",
    flexDirection: "column",
    padding: "24px 16px 16px 20px",
    borderRadius: "20px",
    boxShadow: "5px 8px 20px 2px rgba(0, 0, 0, 0.5);",
    zIndex: 1000,
    top: "25%",
    left: "37%",
  },
  dropDownGrid: {
    display: "flex",
    marginTop: "20px",
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.mediumBlack,
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    paddingRight: "16px",
  },
  modalButtonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "16px",
  },
  cancelButton: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 44,
    height: 18,
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: "8px",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  associateButton: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 120,
    height: 32,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  select: {
    height: 13,
    minWidth: 200,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    borderRadius: "0 0 8px 8px",
    height: "150px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: 9,
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  placeholder: {
    color: "#92ACBF",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: "14px",
  },
}));
