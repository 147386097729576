import React, { useCallback, useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import ReactDOM from "react-dom";
import { Button, ClickAwayListener, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./RemoveEntityModal.styles";

interface ModalContentProps {
  no: Function;
  yes: Function | ((value: string) => Promise<void>);
  id: string;
  UI: {
    subheader: string;
    type: string;
    name: string;
  };
}

export function RemoveEntityModal({ no, yes, id, UI }: ModalContentProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const modal = useRef<any>(null);

  useEffect(() => {
    document.addEventListener("click", outSideClick, false);
    return () => {
      document.removeEventListener("click", outSideClick, false);
    };
  }, []);

  const outSideClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (modal.current && !modal?.current?.contains(event.target)) {
      no();
    }
  }, []);

  const onCancel = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    no();
  }, []);

  const removeEntity = useCallback(() => {
    yes(id);
  }, [id]);

  return (
    <Grid ref={modal} className={classes.content}>
      <Grid className={classes.alignLeft}>
        <Typography id="modalTitle" className={classes.header}>
          {formatMessage({
            id: "removeEntity.settings",
            defaultMessage: "Remove from the list",
            description: "Remove from the list",
          })}
        </Typography>
        <Grid>
          <Typography className={classes.subheader}>
            {formatMessage({
              id: `${UI.type}.settings`,
              defaultMessage: UI.subheader,
              description: UI.subheader,
            })}
          </Typography>
          <Typography className={classes.hostName}>{UI.name}</Typography>
        </Grid>
      </Grid>
      <Grid className={classes.buttonsWrapper}>
        <Button id="cancelBtnEditModal" className={classes.cancel} onClick={onCancel}>
          {formatMessage({
            id: "addGroup.label.settings",
            defaultMessage: "Cancel",
            description: "group name label",
          })}
        </Button>
        <Button id="yesBtnEditModal" className={classes.confirm} onClick={removeEntity}>
          {formatMessage({
            id: "addGroup.label.settings",
            defaultMessage: "Yes, Confirm",
            description: "group name label",
          })}
        </Button>
      </Grid>
    </Grid>
  );
}
