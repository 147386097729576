import { isObject } from "../../../common/utils/General.utils";

export const useSearch = () => {
  const search = (
    arrayOfElements: { [key: string]: any }[],
    searchValue: string
  ) => {
    const exclude = [
      "id",
      "coordinateX",
      "coordinateY",
      "floorId",
      "siteNrOfFloors",
      "accessPointsRadius",
      "propertyId",
      "vlanId",
      "__typename",
      "active",
      "properties",
      "floors",
    ];

    const wihoutExludedValues = (base: { [key: string]: any }) =>
      exclude.reduce((a, e) => {
        const { [e]: omit, ...rest } = a;
        return rest;
      }, base) as object;

    const exludeRecursivly = (object: object): any[] =>
      Object.values(wihoutExludedValues(object)).map((element: any) =>
        isObject(element) ? exludeRecursivly(element as object) : element
      );

    return arrayOfElements.filter((element) => {
      const values = Object.values(exludeRecursivly(element))
        .filter((val) => val !== null)
        .join("")
        .toLowerCase();

      if (searchValue.length === 0) {
        return true;
      }
      return values.indexOf(searchValue.toLowerCase()) > -1;
    });
  };

  return {
    search,
  };
};
