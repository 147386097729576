import { gql } from "@apollo/client";

export const VLAN_MUTATIONS = {
  createVlan: gql`
    mutation createVlan(
      $active: Boolean!
      $description: String
      $name: String!
      $ifId: String!
      $propertyId: String!
      $vlanNumber: String!
      $inCommonNetwork: Boolean!
    ) {
      createVlan(
        request: {
          active: $active
          description: $description
          name: $name
          propertyId: $propertyId
          ifId: $ifId
          vlanNumber: $vlanNumber
          inCommonNetwork: $inCommonNetwork
        }
      ) {
        id
      }
    }
  `,
  updateVlan: gql`
    mutation updateVlan(
      $active: Boolean!
      $description: String
      $id: String!
      $name: String
      $propertyId: String!
      $ifId: String
      $vlanNumber: String!
      $inCommonNetwork: Boolean!
    ) {
      updateVlan(
        request: {
          active: $active
          description: $description
          id: $id
          name: $name
          propertyId: $propertyId
          ifId: $ifId
          vlanNumber: $vlanNumber
          inCommonNetwork: $inCommonNetwork
        }
      )
    }
  `,
  deleteVlan: gql`
    mutation($vlanId: String!) {
      deleteVlan(vlanId: $vlanId)
    }
  `,
};

export const VLAN_QUERIES = {
  vlanByProperty: gql`
    query($propertyId: String!) {
      vlansByPropertyId(propertyId: $propertyId) {
        description
        id
        name
        active
        ifId
        vlanNumber
        inCommonNetwork
        ssids {
          id
        }
      }
    }
  `,
};
