import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import GQLService from "../../../../../../core/services/GQL.service";
import { Property } from "../../../../../../types";
import { dummyUserEmail } from "../../../../../../common/constants/DummyProperty";
import { useUserState } from "../../../../../../core/context/containers/User.container";
import DummyBGImage from "../../../../../../common/assets/images/grandSquareBG.png";

interface CoverPhotoProps {
  currentUrl?: string | undefined;
  property: Property | null;
}

export function CoverPhoto({ currentUrl, property }: CoverPhotoProps) {
  const { userEmail } = useUserState();
  const {
    selectedProperty,
    setCurrentCoverPhotoObject,
    currentCoverPhotoObject,
  } = usePropertiesState();

  useEffect(
    () => {
      if (selectedProperty) {
        (async () => {
          const response = await GQLService.rest.coverPhoto.getCoverPhoto(
            selectedProperty!.id
          );
          if (response.source !== undefined) {
            setCurrentCoverPhotoObject({
              ...currentCoverPhotoObject,
              url: response.source,
            });
          } else {
            setCurrentCoverPhotoObject({
              url: undefined,
              data: undefined,
              name: undefined,
            });
          }
        })();
      }
    },
    [property, selectedProperty] // eslint-disable-line
  );

  return currentCoverPhotoObject?.url ? (
    <Grid
      style={{
        width: "100%",
        height: 200,
        marginBottom: 36,
      }}
    >
      {userEmail === dummyUserEmail ? (
        <img
          src={DummyBGImage}
          alt={"cover"}
          style={{
            height: 200,
          }}
        />
      ) : (
        <img
          src={currentUrl || currentCoverPhotoObject?.url}
          alt={currentCoverPhotoObject.name || "cover photo"}
          style={{
            height: 200,
          }}
        />
      )}
    </Grid>
  ) : (
    <div style={{ height: 236, minHeight: 236 }}></div>
  );
}
