import React from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.funBlue,
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
  },
  content: {
    display: "flex",
    minWidth: "350px",
    minHeight: "250px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    padding: "15px",
    alignItems: "center",
    borderRadius: "30px",
    boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
  },
}));
interface ModalContentProps {
  no: Function;
  yes: Function;
}

export function ModalContent({ no, yes }: ModalContentProps) {
  const classes = useStyles();

  return (
    <Grid className={classes.content}>
      <Typography className={classes.header}>Update SSID Settings ?</Typography>
      <Typography className={classes.description}>
        All data will be updated. Do you confirm that process ?
      </Typography>
      <Grid item className={classes.buttonsWrapper}>
        <Button className={classes.cancel} onClick={() => no()}>
          Cancel
        </Button>
        <Button className={classes.editBtn} onClick={() => yes()}>
          Yes, confirm
        </Button>
      </Grid>
    </Grid>
  );
}
