import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "column",
    flexBasis: 0,
    alignContent: "left",
    alignItems: "left",
    position: "relative",
  },
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    width: 50,
    fontSize: "14px",
    marginBottom: 14,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
    "&:before": {
      content: "none",
    },
  },
  inputField: {
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  label: {
    margin: "20px 0 5px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    lineHeight: "18px",
    whiteSpace: "nowrap",
  },
  labelWrapper: {
    display: "flex",
  },
  errorInput: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: 14,
    borderColor: theme.palette.common.cinnabar,
  },
  switch: {
    color: `${theme.palette.common.switchBlue} !important`,
    border: `1px solid ${theme.palette.common.switchBlue}`,
    borderRadius: "30px;",
    height: 17,
    width: 52,
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `#2F56A1 !important`,
    width: 12,
    height: 12,
  },
  checked: {
    color: "transparent !important",
  },
  base: {
    height: 22,
  },
  error: {
    border: `1px solid ${theme.palette.common.cinnabar}`,
    borderRadius: "30px;",
    color: `${theme.palette.common.switchBlue} !important`,
    height: 17,
    width: 52,
  },
  status: {
    color: "#122036",
    marginLeft: 8,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 3,
  },
  requiredFields: {
    color: `${theme.palette.common.red}`,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "18px",
    margin: "15px 0 5px 0",
  },
}));
