import React, { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Grid, Button, Typography } from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

import { FormField as FormFieldType } from "../../../../../../types/form";
import { Property } from "../../../../../../types";
import { useStyles } from "./ImageInput.styles";
import GQLService from "../../../../../../core/services/GQL.service";
import { COLORS } from "../../../../../../common/styles/colors";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import { ROUTES } from "../../../../../../common/constants/Routing";
import { useAuth } from "../../../../../../core/context/functions/Auth.functions";
import { ReactComponent as ChangeIcon } from "../../../../../../common/assets/images/change-icon.svg";
import { ReactComponent as TrashIcon } from "../../../../../../common/assets/images/trash-icon.svg";
import NoCoverPhoto from "../../../../../../common/assets/images/no-image.png";
import { dummyUserEmail } from "../../../../../../common/constants/DummyProperty";
import { useUserState } from "../../../../../../core/context/containers/User.container";
import DummyBGImage from "../../../../../../common/assets/images/grandSquareBG.png";

interface EditPhotosProps {
  field?: FormFieldType;
  name: string;
  previewStyle?: string;
  label: string;
  property: Property | null;
  currentUrl: string | undefined;
}

export function EditCoverPhoto({
  name,
  field,
  previewStyle,
  label,
  property,
  currentUrl,
}: EditPhotosProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const { userEmail } = useUserState();
  const {
    selectedProperty,
    currentCoverPhotoObject,
    setCurrentCoverPhotoObject,
  } = usePropertiesState();
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);

  useEffect(
    () => {
      (async () => {
        if (currentUrl) {
          setImageLoading(true);
          const response = await GQLService.rest.coverPhoto.getCoverPhoto(
            selectedProperty?.id!
          );
          setImageLoading(false);
          if (response.source) {
            setCurrentCoverPhotoObject({
              ...currentCoverPhotoObject,
              url: response?.source,
            });
          }
        }
      })();
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "dragover",
        (e) => {
          e.preventDefault();
        },
        false
      );
      window.removeEventListener(
        "drop",
        (e) => {
          e.preventDefault();
        },
        false
      );
    };
  }, []);
  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setError(false);
      if (e.target.files) {
        if (e.target.files[0].size / (1024 * 1024) >= 2) {
          setError(true);
          return;
        }
        setCurrentCoverPhotoObject({
          url: URL.createObjectURL(e.target.files[0]),
          data: e.target.files[0],
          name: e.target.files[0].name,
        });
      }
    },
    [] //eslint-disable-line
  );

  const handleImageChange = useCallback(
    async (e: any) => {
      setCurrentCoverPhotoObject({
        name: undefined,
        url: undefined,
        data: undefined,
      });
    },
    [property] //eslint-disable-line
  );

  const handleRemoveImage = useCallback(
    async (e: any) => {
      await GQLService.rest.coverPhoto.removeCoverPhoto(selectedProperty?.id!);

      setCurrentCoverPhotoObject({
        name: undefined,
        url: undefined,
        data: undefined,
      });
    },
    [property, currentCoverPhotoObject] //eslint-disable-line
  );
  const handleFileDrop = useCallback((e: React.DragEvent<HTMLInputElement>) => {
    setError(false);

    if (e.dataTransfer.files?.length) {
      if (e.dataTransfer.files[0].size / (1024 * 1024) >= 2) {
        setError(true);
        setCurrentCoverPhotoObject({
          url: URL.createObjectURL(e.dataTransfer.files[0]),
          name: e.dataTransfer.files[0].name,
          data: e.dataTransfer.files[0],
        });
        return;
      }
    }
  }, []);

  useEffect(
    () => {
      (async () => {
        if (currentCoverPhotoObject?.data) {
          const formData = new FormData();
          formData.append("data", currentCoverPhotoObject?.data);
          const logoResponse =
            await GQLService.rest.coverPhoto.uploadCoverPhoto(
              formData,
              selectedProperty?.id!
            );
          if (logoResponse.status !== 200) {
            setError(true);
          }
        }
      })();
    },
    [history, currentCoverPhotoObject] //eslint-disable-line
  );

  return (
    <Grid>
      <Grid container>
        <Typography className={classes.header}>
          {formatMessage({
            id: "companylogo.header.text",
            defaultMessage: "Cover Photo",
            description: "company cover photo",
          })}
        </Typography>
        <Typography className={classes.description}>
          {formatMessage({
            id: "company.description.textFirst",
            defaultMessage:
              "Add your company logo to help identify your Join Network.",
            description: "company description",
          })}
        </Typography>
        <Typography className={classes.description}>
          {formatMessage({
            id: "company.description.textSecond",
            defaultMessage:
              " It will only be visible to members you have invited to use your network.",
            description: "company description",
          })}
        </Typography>
      </Grid>
      {currentCoverPhotoObject?.url || imageLoading ? (
        imageLoading ? (
          <Grid className={classes.floorPlanLoader}>
            <Loader
              type="Oval"
              color={COLORS.funBlue}
              height={100}
              width={100}
            />
          </Grid>
        ) : (
          <Grid item className={classes.logo}>
            {userEmail === dummyUserEmail ? (
              <img
                src={DummyBGImage}
                alt={"cover"}
                className={clsx(classes.coverPhotoPreview, previewStyle)}
              />
            ) : (
              <img
                src={currentCoverPhotoObject?.url}
                alt={currentCoverPhotoObject.name}
                className={clsx(classes.coverPhotoPreview, previewStyle)}
              />
            )}
            <Grid item className={classes.flex}>
              <Grid item>
                <span
                  onClick={handleImageChange}
                  className={classes.changeButton}
                >
                  {formatMessage({
                    id: "company.description.change",
                    defaultMessage: "Change",
                    description: "change description",
                  })}
                </span>
                <ChangeIcon />
              </Grid>
              <Grid item>
                <span
                  onClick={handleRemoveImage}
                  className={classes.removeButton}
                >
                  {formatMessage({
                    id: "company.description.remove",
                    defaultMessage: "Remove",
                    description: "remove description",
                  })}
                </span>
                <TrashIcon />
              </Grid>
            </Grid>
          </Grid>
        )
      ) : (
        <Grid item={true} md={6}>
          <input
            type="file"
            accept="image/jpeg, image/png"
            className={classes.input}
            id={field?.name || name}
            onChange={handleFileSelect}
            name={field?.name}
          />
          <label htmlFor={field?.name || name}>
            <Button
              className={clsx(
                classes.coverPhotoButton,
                error ? classes.error : ""
              )}
              component="span"
              fullWidth={true}
              onDrop={handleFileDrop}
            >
              <Grid
                container={true}
                md={6}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <FileCopyOutlined style={{ color: COLORS.inputGrey }} />
                <Typography className={classes.buttonLabel}>
                  {formatMessage({
                    id: "imageInput.dragAndDrop",
                    defaultMessage: "Select File or Drag and Drop",
                    description: "Image input drag and drop label",
                  })}
                </Typography>
              </Grid>
            </Button>
          </label>
          <Typography className={classes.note}>
            {formatMessage({
              id: "imageInput.dragAndDropRules",
              defaultMessage:
                "Your upload must be: JPG or PNG format, less than 2 MB.",
              description: "Image input drag and drop help text",
            })}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
