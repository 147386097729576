export const AuthTypesBase = [
  "WPA2 + AES",
  "WPA2 Enterprise (AES)",
  "WPA2 Enterprise (TKIP)",
  "WPA2-PSK (AES)",
  "WPA2-PSK (TKIP)",
  "WPA + AES",
  "WPA + TKIP/AES (TKIP as a fallback method)",
  "WPA + TKIP",
  "WEP",
  "Open Network (no security at all)",
];

export const userRoles = [
  { id: "JOIN_ADMIN", name: "Admin" },
  { id: "PROPERTY_ADMIN", name: "Property admin" },
  { id: "TENANT", name: "Tenant" },
];
