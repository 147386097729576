import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { makeStyles, Grid, Typography, Divider, Checkbox, Button } from "@material-ui/core";
import { PageHeader } from "../../../../../../common/components";
import { DetailLabel } from "../DetailLabel";
import { Role } from "../../../../../../types";
import { useAuth } from "../../../../../../core/context/containers/Auth.container";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../../../../core/services/GQL.service";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamily,
    borderBottomWidth: "4px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.common.lightBlue,
    width: "fit-content"
  },
  blackHeader: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    marginBottom: theme.spacing(5),
    lineHeight: theme.typography.pxToRem(24),
  },
  marginTop45: {
    marginTop: theme.typography.pxToRem(45),
  },  
  checkboxAlign: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    right: "12px",
    marginBottom: "20px",
  },
  checkboxLabel: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
  },
  button: {
    backgroundColor: theme.palette.common.matisse,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: 115,
    height: 42,
    alignSelf: "flex-start",
  },
}));

export function UpNextAppView() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty, setSelectedProperty } = usePropertiesState();

  const auth = useAuth();
  const history = useHistory();

  const [getPropertyById, { data }] = useLazyQuery(
    GQLService.queries.propertyById, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !data) {
      getPropertyById({ variables: { propertyId: selectedProperty?.id! } })
    } else {
      setSelectedProperty(data?.propertyById)
    }
    return () => {
      isMounted = false;
    };
  }, [data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEditClick = useCallback(() => {
    history.push(
      ROUTES.EDIT_UPNEXT_APP.replace(":uid", selectedProperty?.id!));
  }, [history, selectedProperty?.id]);

  const createSettingsEditButton = useCallback(() => {
    return (
      <Button className={classes.button} onClick={handleEditClick}>
        {formatMessage({
          id: "beacons.view.button.edit",
          defaultMessage: "Edit",
        })}
      </Button>
    );
  }, [classes.button, formatMessage, handleEditClick]);

  return (
    <div>
      <Grid container={true} direction="row" alignItems="center">
        <Grid>
          <PageHeader
            title={formatMessage({
              id: "upnextapp.header",
              defaultMessage: "UpNext Application",
              description: "upnext app header",
            })}
          />
        </Grid>
      </Grid>
      <Typography component="div" className={classes.subTitle}>
        {formatMessage({
          id: "upnextapp.generalSettings",
          defaultMessage: "General Settings",
          description: "upnext general settings",
        })}
      </Typography>
      <Divider />
      <Grid container={true} spacing={2} className={classes.marginTop45}>
        <Grid item sm={6}>
          <Typography component="div" className={classes.blackHeader}>
            {formatMessage({
              id: "upnextapp.generalSettings.timeDateLabel",
              defaultMessage: "Time and Date",
              description: "time and date label",
            })}
          </Typography>
          <DetailLabel
            label={formatMessage({
              id: "upnextapp.generalSettings.dateFormatLabel",
              defaultMessage: "Date Format",
              description: "date format label",
            })}
            value={selectedProperty?.upNextConfigurations?.dateFormat ? ( selectedProperty?.upNextConfigurations?.dateFormat === "US"
            ? formatMessage({
                id: "companyInfo.property.US.label",
                defaultMessage: "US (Jan-31-2021)",
                description: "US (Jan-31-2021)",
              })
            : formatMessage({
                id: "companyInfo.proprty.International.label",
                defaultMessage: "International (31.1.2021)",
                description: "International (31.1.2021)",
              })) : ""}
          />
          <DetailLabel
            label={formatMessage({
              id: "upnextapp.generalSettings.timeFormatLabel",
              defaultMessage: "Use 24 Hour Clock",
              description: "time format label",
            })}
            value={selectedProperty?.upNextConfigurations?.use24HourClock === undefined ? "" : ( selectedProperty?.upNextConfigurations?.use24HourClock ? formatMessage({
              id: "companyInfo.property.yes.label",
              defaultMessage: "Yes",
              description: "Yes",
            })
            : formatMessage({
              id: "companyInfo.proprty.no.label",
              defaultMessage: "No",
              description: "No",
            }))}
          />
        </Grid>
        <Grid item sm={6}>
          <Typography component="div" className={classes.blackHeader}>
            {formatMessage({
              id: "upnextapp.generalSettings.healthSurveyLabel",
              defaultMessage: "Health Survey",
              description: "health survey",
            })}
          </Typography>
          <Grid item className={classes.checkboxAlign}>
            <Checkbox
              value={'yes'}
              color="primary"
              checked={selectedProperty?.upNextConfigurations?.surveyActive}
            />
            <Typography>
              {formatMessage({
                id: "upnextapp.generalSettings.fillSurvey",
                defaultMessage: "Survey to be filled by every tenant",
                description: "Survey to be filled by every tenant",
              })}
            </Typography>
          </Grid>
          <DetailLabel
            label={formatMessage({
              id: "upnextapp.generalSettings.frequency",
              defaultMessage: "Frequency",
              description: "frequency label",
            })}
            value={selectedProperty?.upNextConfigurations?.healthSurveyFrequency}
          />
        </Grid>
      </Grid>
      {auth?.canSee(
        auth?.user?.role?.toUpperCase() as Role,
        "upnext:edit",
        createSettingsEditButton()
      )}
    </div>
  );
}