import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";

import { ROUTES } from "../../common/constants/Routing";
import { AddBeacon } from "../../pages/Dashboard/Beacons/Add/Add";
import { EditBeacon } from "../../pages/Dashboard/Beacons/Edit/Edit";
import { BeaconView } from "../../pages/Dashboard/Beacons/View/View";
import { Role } from "../../types";
import { useAuth } from "../context/containers/Auth.container";

export const BEACON_ROUTES = {
  BEACONS: "/dashboard/properties/:uid/beacons",
  ADD_BEACON: "/dashboard/properties/:uid/beacons/add",
  EDIT_BEACON: "/dashboard/properties/:uid/beacons/:beaconId/edit",
  BEACON_VIEW: "/dashboard/properties/:uid/beacons/:beaconId/view",
};

const Beacons = lazy(() => import("../../pages/Dashboard/Beacons/List/List"));

export default function BeaconsRouter() {
  const auth = useAuth();

  return (
    <Route path={ROUTES.BEACONS}>
      <Route
        exact={true}
        path={ROUTES.BEACONS}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "beacons:list",
              <Beacons />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.BEACON_VIEW}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "beacons:view",
              <BeaconView />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.EDIT_BEACON}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "beacons:edit",
              <EditBeacon />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.ADD_BEACON}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "beacons:create",
              <AddBeacon />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
    </Route>
  );
}
