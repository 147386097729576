import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Checkbox,
} from "@material-ui/core";
import { StatusIndicator } from "../../../../common/components";
import { ExpandLess, ExpandMore, Room } from "@material-ui/icons";
import { Property } from "../../../../types";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import GQLService from "../../../../core/services/GQL.service";
import { useQuery } from "@apollo/client";
import { useUserState } from "../../../../core/context/containers/User.container";
import _ from "lodash";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../../../common/constants/DummyProperty";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: "#2F56A1",
    textTransform: "initial",
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(28),
    color: "#2F56A1",
    marginBottom: "28px",
    width: "100%",
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: "0E1D33",
    fontWeight: 600,
    alignSelf: "left",
    width: "100%",
  },
  line: {
    marginBottom: "15px",
  },
  content: {
    display: "flex",
    minWidth: "1400px",
    height: "700px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    padding: "29px 37px",
    alignItems: "center",
    borderRadius: "20px",
    boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
    overflow: "scroll",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  name: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.funBlue,
    marginLeft: "5px",
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  container: {
    margin: "10px 44px",
    justifyContent: "space-between",
  },
  cell: {
    minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  ssidInfo: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.blackPearl,
  },
  subheader: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.dustyGray,
  },
  accordionDetails: {
    paddingBottom: "40px",
    flexDirection: "column",
  },
  accordion: {
    width: "100%",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  ssidInfoGrid: {
    display: "flex",
    width: "50%",
    justifyContent: "space-between",
  },
  status: {
    flexDirection: "column",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
  },
  pin: { color: theme.palette.common.funBlue },
  detailsContainer: {
    margin: "0 44px",
  },
  item: {
    margin: 0,
    "&:hover": {
      cursor: "pointer",
    },
    height: "40px",
  },
  arrow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

interface ModalContentProps {
  no: Function;
  yes: Function | ((values: any) => Promise<void>);
}

export function PropertiesModal({ no, yes }: ModalContentProps) {
  const classes = useStyles();

  const [expanded, setIsExpanded] = useState<any>({});
  const { selectedUser, userEmail } = useUserState();
  const [selectedValues, setSelectedValues] = useState<Property[]>([]);
  const { error, loading, data } = useQuery(GQLService.queries.allProperties, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!loading && data) {
        if (userEmail === dummyUserEmail) {
          const userProperties = _.cloneDeep(selectedUser?.properties);
          const userNewProperties = userProperties?.map((item: any) => {
            if (item.id === DummyPropertyA.id) {
              const propA = {
                ...item,
                ownerCompanyName: DummyPropertyA.ownerCompanyName,
                siteName: DummyPropertyA.siteName,
                siteServiceAddress: DummyPropertyA.siteServiceAddress,
              };
              return propA;
            } else if (item.id === DummyPropertyB.id) {
              const propB = {
                ...item,
                ownerCompanyName: DummyPropertyB.ownerCompanyName,
                siteName: DummyPropertyB.siteName,
                siteServiceAddress: DummyPropertyB.siteServiceAddress,
              };
              return propB;
            } else if (item.id === DummyPropertyC.id) {
              const propC = {
                ...item,
                ownerCompanyName: DummyPropertyC.ownerCompanyName,
                siteName: DummyPropertyC.siteName,
                siteServiceAddress: DummyPropertyC.siteServiceAddress,
              };
              return propC;
            } else if (item.id === DummyPropertyD.id) {
              const propD = {
                ...item,
                ownerCompanyName: DummyPropertyD.ownerCompanyName,
                siteName: DummyPropertyD.siteName,
                siteServiceAddress: DummyPropertyD.siteServiceAddress,
              };
              return propD;
            } else {
              return item;
            }
          });

          let clonedData = _.cloneDeep(data?.properties);

          const newProperties = clonedData?.map((item: any) => {
            if (item.id === DummyPropertyA.id) {
              const propA = {
                ...item,
                ownerCompanyName: DummyPropertyA.ownerCompanyName,
                siteName: DummyPropertyA.siteName,
                siteServiceAddress: DummyPropertyA.siteServiceAddress,
              };
              return propA;
            } else if (item.id === DummyPropertyB.id) {
              const propB = {
                ...item,
                ownerCompanyName: DummyPropertyB.ownerCompanyName,
                siteName: DummyPropertyB.siteName,
                siteServiceAddress: DummyPropertyB.siteServiceAddress,
              };
              return propB;
            } else if (item.id === DummyPropertyC.id) {
              const propC = {
                ...item,
                ownerCompanyName: DummyPropertyC.ownerCompanyName,
                siteName: DummyPropertyC.siteName,
                siteServiceAddress: DummyPropertyC.siteServiceAddress,
              };
              return propC;
            } else if (item.id === DummyPropertyD.id) {
              const propD = {
                ...item,
                ownerCompanyName: DummyPropertyD.ownerCompanyName,
                siteName: DummyPropertyD.siteName,
                siteServiceAddress: DummyPropertyD.siteServiceAddress,
              };
              return propD;
            } else {
              return item;
            }
          });

          const alreadySelected =
            userNewProperties?.map((property) => property.id) || [];
          setSelectedValues(
            newProperties?.map((property: Property) => {
              if (alreadySelected?.indexOf(property.id) >= 0) {
                return {
                  ...property,
                  checked: true,
                };
              }
              return {
                ...property,
                checked: false,
              };
            })
          );
        } else {
          const alreadySelected =
            selectedUser?.properties?.map((property) => property.id) || [];
          setSelectedValues(
            data.properties.map((property: Property) => {
              if (alreadySelected?.indexOf(property.id) >= 0) {
                return {
                  ...property,
                  checked: true,
                };
              }
              return {
                ...property,
                checked: false,
              };
            })
          );
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [data, loading, selectedUser, userEmail]);

  const handleSelect = useCallback(
    (e: any, v: any) => {
      e.preventDefault();
      e.stopPropagation();
      const newValues = selectedValues.map(
        (item: Property & { checked?: boolean }) => {
          if (v === item.id) {
            return {
              ...item,
              checked: !item.checked,
            };
          }
          return item;
        }
      );
      setSelectedValues(newValues);
    },
    [selectedValues]
  );

  const handleExpand = useCallback((id: string) => {
    setIsExpanded({
      ...expanded,
      [id]: !expanded[id],
    });
  }, []);

  return (
    <Grid container direction="row">
      <Grid className={classes.content}>
        <Typography className={classes.header}>
          Add properties to this user
        </Typography>
        <Typography className={classes.description}>
          Pick properties from list
        </Typography>
        {selectedValues.map((item: any) => (
          <Accordion
            key={item.id}
            className={classes.accordion}
            expanded={expanded[item.id]}
            onChange={() => handleExpand(item.id)}
          >
            <AccordionSummary>
              <Grid
                container={true}
                alignItems="center"
                justify="space-between"
                classes={{ root: classes.item }}
              >
                <Grid
                  container={true}
                  item={true}
                  md={1}
                  sm={3}
                  justify="space-around"
                  className={classes.iconWrapper}
                >
                  <Checkbox
                    value={item.id}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    checked={item.checked === undefined ? false : item.checked}
                    onClick={(e) => handleSelect(e, item.id)}
                  />
                  {expanded[item.id] ? <ExpandLess /> : <ExpandMore />}
                  <Room className={classes.pin} />
                </Grid>
                <Grid container={true} item={true} md={10} sm={7}>
                  <Grid item={true} md={3} sm={12}>
                    <Typography className={classes.name}>
                      {item.siteName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container={true}
                  item={true}
                  md={1}
                  sm={2}
                  alignItems="center"
                  justify="space-around"
                >
                  <StatusIndicator
                    uid={item.id}
                    isActive={item.companyStatus}
                    canShowStatusLabel={false}
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container={true}
                className={classes.detailsContainer}
                spacing={2}
              >
                <Cell label="LEGAL COMPANY NAME" value={item.companyName} />
                <Cell
                  label="PHONE NUMBER"
                  value={item.primaryContactPhoneNumber || ""}
                />
                <Cell label="STATUS">
                  <Grid container={true} alignItems="center">
                    <StatusIndicator
                      uid={item.id}
                      isActive={item.companyStatus}
                      canShowStatusLabel={false}
                    />
                  </Grid>
                </Cell>
                <Cell label="WEBSITE" value={item.companyWebsite || ""} />
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
        <Grid item className={classes.buttonsWrapper}>
          <Button className={classes.cancel} onClick={() => no()}>
            Cancel
          </Button>
          <Button
            className={classes.editBtn}
            onClick={() => yes(selectedValues)}
          >
            Save and Edit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

interface CellProps {
  label: string;
  value?: string | number;
  children?: React.ReactNode;
}

function Cell({ label, value, children }: CellProps) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.cell}
      container={true}
      item={true}
      md={4}
      sm={12}
      direction="column"
    >
      <Grid>
        <Typography className={classes.cellLabel}>{label}</Typography>
        {children ? children : <Typography>{value ? value : "-"}</Typography>}
      </Grid>
    </Grid>
  );
}
