import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { AccessPoint } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useAccessPointBase() {
  const [accessPoints, setAccessPoints] = useState<AccessPoint[]>([]);
  const [accessPointsFromResponse, setAccessPointsFromResponse] = useState<
    AccessPoint[]
  >([]);

  const {
    state: selectedAccessPoint,
    setState: setSelectedAccessPoint,
  } = usePersistedState<AccessPoint | null>("accessPoint", null);

  const { search } = useSearch();

  const searchAccessPoints = useCallback(
    (searchValue: string) => {
      setAccessPoints(
        search(accessPointsFromResponse, searchValue) as AccessPoint[]
      );
    },
    [accessPointsFromResponse]
  );

  return {
    searchAccessPoints,
    accessPoints,
    accessPointsFromResponse,
    setAccessPointsFromResponse,
    setAccessPoints,
    selectedAccessPoint,
    setSelectedAccessPoint,
  };
}

const { Provider, useContainer } = createContainer(useAccessPointBase);

export const useAccessPointState = useContainer;
export const AccessPointProvider = Provider;
