import { FieldAttributes } from "formik";
import { ObjectSchema } from "yup";

export type FormField = {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  Component: FieldAttributes<any>;
  gridStyle?: { sm: Size; md: Size };
  type?: FormFieldInputType;
  isSwitch?: boolean;
  data?: any;
  customProps?: {};
  resetField?: boolean;
  title?: string;
  fullWidth?: boolean;
};

type Size = boolean | "auto" | 5 | 12 | 1 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 10 | 11;

export enum FormFieldInputType {
  string = "string",
  number = "number",
  file = "file",
  password = "password",
}

export type FormWizardSteps = Fieldset[];
export type CreateFormWizardSteps = (...args: any[]) => Fieldset[];
export type CreateFormField = (disabled?: boolean, ...args: any) => FormField[];
export type Fieldset = {
  name: string;
  renderCustom?: boolean;
  renderForm?: React.ReactElement;
  fieldset: FieldSubset[];
  styles?: Object;
};

export type FieldSubset = {
  label?: string;
  fields: FormField[];
  defaultValues?: Object;
  validationSchema?: ObjectSchema;
};

export type ISelectData = {
  id: string;
  name: string;
}[];
