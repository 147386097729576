import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  ClickAwayListener,
  Select,
  MenuItem,
} from "@material-ui/core";
import { LinearChartForDensity } from "../../../common/components/Charts/LinearCharts/LinearChartForDensity";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import moment from "moment";
import { BarChartForDensity } from "../../../common/components/Charts/BarCharts/BarChartForDensity";
import { ReactComponent as PinBlack } from "../../../common/assets/images/pin_black.svg";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";
import { useUserState } from "../../../core/context/containers/User.container";
import { TIMEZONES } from "../../../common/constants/Timezones";
import { UnpinModal } from "./UnpinModal";
import { DashboardDeviceType } from "../../../types";
import { CustomModal, SwipePanel } from "../../../common/components";
import { useIntl } from "react-intl";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { CollapsibleMyPin } from "./CollapsibleMyPin";

const useStyles = makeStyles((theme) => ({
  displayGrid: {
    display: "flex",
    marginLeft: "16px",
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "4px",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "8px",
  },
  statisticContainer: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0px 4px 13px rgba(135, 135, 135, 0.3)",
    paddingBottom: "24px",
    paddingRight: "10px",
  },
  statisticContainerTitleGrid: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "14px",
  },
  statisticContainerTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginLeft: "14px",
    marginBottom: "16px",
  },
  pinIcon: {
    transform: "rotate(45deg)",
    height: "16px",
  },
  densityTimeGrid: {
    marginBottom: "16px",
    marginTop: "20px",
  },
  dwellTime: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: "14px",
  },
  swipePanel: {
    display: "flex",
    width: "50%",
    justifyContent: "center",
    backgroundColor: `${theme.palette.common.ultralightGrey}`,
    color: `${theme.palette.common.mediumBlack}`,
    marginRight: 0,
    fontSize: theme.typography.pxToRem(14),
    "&.Mui-selected": {
      backgroundColor: `${theme.palette.common.regularBlue}`,
      color: "#FFF !important",
    },
  },
  item: {
    "& MuiTabs-indicator": {
      display: "none",
    },
  },
  tabBar: {
    backgroundColor: "transparent",
    height: 32,
    borderBottom: `1px solid ${theme.palette.common.ultralightGrey}`,
  },
  tabIndicator: {
    backgroundColor: theme.palette.common.lightBlue,
    height: 1,
    width: "100%",
  },
  root: {
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: "auto",
      margin: 0,
    },
    "& .MuiTab-root": {
      padding: "0 0",
      minWidth: "auto",
      minHeight: 32,
      "&:first-child": {
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
      },
      "&:last-child": {
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
      },
    },
    "& .MuiTabs-scroller": {
      flex: 1,
      position: "static",
      display: "inline-block",
    },
  },
  dropDownContainer: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
  },
  dropDownTitle: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    margin: "5px 8px 8px 0",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  newMenu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    width: 135,
    borderRadius: "0 0 8px 8px",
  },
  select: {
    height: 13,
    minWidth: 120,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
    paddingLeft: theme.typography.pxToRem(6),
  },
  icon: {
    width: 18,
    height: 18,
  },
  dwellTimeLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
    margin: "10px 0px -16px 10px",
  },
  timeIcon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  timeSelect: {
    height: 16,
    minWidth: 100,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    borderRadius: "0 0 8px 8px",
  },
  durationGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
}));

export function CameraZoneMyPin({
  item,
  request,
  duration,
  timeZone,
  removedCard,
}: any) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty, selectedPropertyTimeZone } = usePropertiesState();
  const [cameraDensityData, setCameraDensityData] = useState<any>();
  const { userEmail } = useUserState();
  const [newTimeZone, setNewTimeZone] = useState<any>();
  const [toggleUnpinModal, setToggleUnpinModal] = useState<boolean>(false);
  const [selectedDataType, setSelectedDataType] = useState<any>("");
  const [zoneDataTypeDropDownList, setZoneDataTypeDropDownList] = useState<any>(
    []
  );

  let parsedRequest = JSON.parse(request).request;
  const [selectedTime, setSelectedTime] = useState(parsedRequest.selectedTime);

  const [
    getOccupancyDuringTimeForCameraZone,
    { loading, error, data: responseDataCam },
  ] = useLazyQuery(GQLService.queries.occupancyDuringTimeForCameraZone, {
    fetchPolicy: "network-only",
  });

  const timeRange = useMemo(() => {
    if (selectedTime! <= 15) return "MINUTE";
    if (selectedTime! <= 60) return "QUARTER";
    if (selectedTime! <= 1440) return "HOUR";
    return "DAY";
  }, [selectedTime, request]);

  const timeInMinutesFilter = [
    { value: 15, label: "Last 15 minutes" },
    { value: 60, label: "Last hour" },
    { value: 24 * 60, label: "Last 24 hours" },
    { value: 7 * 24 * 60, label: "Last 7 days" },
    { value: 30 * 24 * 60, label: "Last 30 days" },
  ];

  const getTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === 10080 || selectedTime === 43200) {
      if (timeZone) {
        newTimeTo = moment.tz(timeZone).startOf("day").format();
        newTimeFrom = moment
          .tz(newTimeTo, timeZone)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment.utc().startOf("day").format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    } else {
      if (timeZone) {
        newTimeTo = moment
          .tz(timeZone)
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, timeZone)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, timeZone]);

  useEffect(() => {
    if (selectedTime!) {
      getOccupancyDuringTimeForCameraZone({
        variables: {
          request: {
            zoneId: parsedRequest.deviceId,
            floorId: parsedRequest.floorId,
            cameraId: parsedRequest.cameraId,
            from: getTimeParams().newTimeFrom,
            to: getTimeParams().newTimeTo,
            timeRangeType: timeRange,
            userEmail: userEmail,
          },
        },
      });
    }
  }, [
    request,
    getOccupancyDuringTimeForCameraZone,
    selectedProperty,
    timeRange,
    selectedTime,
    parsedRequest.deviceId,
    parsedRequest.floorId,
    parsedRequest.cameraId,
    getTimeParams,
    userEmail,
  ]);

  useEffect(() => {
    if (responseDataCam) {
      setCameraDensityData(responseDataCam?.occupancyDuringTimeForCameraZone!);
    }
  }, [responseDataCam]);

  useEffect(() => {
    const findTimeZone = TIMEZONES.find((item) => item.abbr === timeZone);
    setNewTimeZone(findTimeZone);
  }, [timeZone]);

  useEffect(() => {
    if (parsedRequest) {
      if (parsedRequest?.subType === "Census") {
        setSelectedDataType("Census");
      } else if (parsedRequest?.subType === "Census_Accumulation") {
        setSelectedDataType("Census_Accumulation");
      } else {
        setSelectedDataType("Dwell_Time");
      }
    }
  }, []);

  const handleDataTypeChange = useCallback((e) => {
    setSelectedDataType(e.target.value);
  }, []);

  const handleTimeChange = useCallback(
    (e) => {
      setSelectedTime(e.target.value);
    },
    [setSelectedTime]
  );

  useEffect(() => {
    if (cameraDensityData) {
      if (cameraDensityData?.dataType === "BOTH") {
        setZoneDataTypeDropDownList([
          { value: "Census", label: "Census" },
          { value: "Census_Accumulation", label: "Census Accumulation" },
          { value: "Dwell_Time", label: "Dwell Time" },
        ]);
      } else if (cameraDensityData?.dataType === "CENSUS") {
        setZoneDataTypeDropDownList([
          { value: "Census", label: "Census" },
          { value: "Dwell_Time", label: "Dwell Time" },
        ]);
      } else {
        setZoneDataTypeDropDownList([
          { value: "Census_Accumulation", label: "Census Accumulation" },
          { value: "Dwell_Time", label: "Dwell Time" },
        ]);
      }
    }
  }, [cameraDensityData]);

  const dwellTimeCalculation = () => {
    if (cameraDensityData?.dwellTime) {
      if (cameraDensityData?.dwellTime >= 3600) {
        const hours =
          Math.floor(cameraDensityData?.dwellTime! / 3600) < 10
            ? `0${Math.floor(cameraDensityData?.dwellTime! / 3600)}`
            : Math.floor(cameraDensityData?.dwellTime! / 3600);
        const minutes =
          parseInt(((cameraDensityData?.dwellTime! - 3600) / 60).toFixed(0)) <
          10
            ? `0${((cameraDensityData?.dwellTime! - 3600) / 60).toFixed(0)}`
            : ((cameraDensityData?.dwellTime! - 3600) / 60).toFixed(0);
        return parseInt(minutes) > 0
          ? `${hours} hr ${minutes} min`
          : `${hours} hr`;
      } else if (cameraDensityData?.dwellTime >= 60) {
        const minutes =
          Math.floor(cameraDensityData?.dwellTime! / 60) < 10
            ? `0${Math.floor(cameraDensityData?.dwellTime! / 60)}`
            : Math.floor(cameraDensityData?.dwellTime! / 60);
        const seconds =
          parseInt((cameraDensityData?.dwellTime! - 60).toFixed(0)) < 10
            ? `0${(cameraDensityData?.dwellTime! - 60).toFixed(0)}`
            : (cameraDensityData?.dwellTime! - 60).toFixed(0);
        return parseInt(seconds) > 0
          ? `${minutes} min ${seconds} sec`
          : `${minutes} min`;
      } else {
        return `${cameraDensityData?.dwellTime.toFixed(0)} sec`;
      }
    }
    return "";
  };

  return (
    <>
      <Grid>
        {!loading && <CollapsibleMyPin item={item} />}
        <ClickAwayListener onClickAway={(e) => setToggleUnpinModal(false)}>
          <Grid className={classes.statisticContainer}>
            <Grid className={classes.statisticContainerTitleGrid}>
              <Typography className={classes.statisticContainerTitle}>
                Zone: Density during time
              </Typography>
              <PinBlack
                fill="#3f51b5"
                className={classes.pinIcon}
                onClick={() => setToggleUnpinModal(true)}
              />
            </Grid>
            <Grid className={classes.durationGrid}>
              <Grid className={classes.displayGrid}>
                <Typography
                  className={classes.title}
                  style={{ marginTop: "4px" }}
                >
                  Duration:
                </Typography>
                <Select
                  classes={{
                    root: classes.timeSelect,
                    select: classes.blackText,
                    icon: classes.timeIcon,
                  }}
                  name="duration"
                  value={selectedTime}
                  disableUnderline={true}
                  onChange={handleTimeChange}
                  IconComponent={ExpandMoreSharpIcon}
                  MenuProps={{
                    classes: {
                      paper: classes.menu,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {timeInMinutesFilter.map((v) => (
                    <MenuItem
                      key={v.value}
                      value={v.value}
                      classes={{
                        root: classes.option,
                        selected: classes.selected,
                      }}
                    >
                      {v.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid className={classes.dropDownContainer}>
                <Typography className={classes.dropDownTitle}>
                  Data Type
                </Typography>
                <Select
                  classes={{
                    root: classes.select,
                    select: classes.blackText,
                    icon: classes.icon,
                  }}
                  id="menu-MyPinZoneDataTypeFilter"
                  name="MyPinZoneDataTypeFilter"
                  value={selectedDataType}
                  disableUnderline={true}
                  onChange={handleDataTypeChange}
                  IconComponent={ExpandMoreSharpIcon}
                  MenuProps={{
                    classes: {
                      paper: classes.newMenu,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disablePortal: true,
                  }}
                >
                  {zoneDataTypeDropDownList?.map((v: any) => (
                    <MenuItem
                      key={v.value}
                      value={v.value}
                      classes={{
                        root: classes.option,
                        selected: classes.selected,
                      }}
                    >
                      {v.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid style={{ display: "flex" }} justify="space-between">
              <Grid></Grid>
              {!loading && selectedDataType === "Dwell_Time" ? (
                <Grid>
                  <Typography
                    className={classes.statisticContainerTitle}
                    style={{ marginBottom: "0px" }}
                  >
                    Avg:{" "}
                    {cameraDensityData?.dwellTime
                      ? dwellTimeCalculation()
                      : "N/A"}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            {selectedDataType === "Census" ? (
              <>
                <LinearChartForDensity
                  data={
                    cameraDensityData?.censusOccupacyTimeline?.map(
                      (d: any, i: number) => {
                        return {
                          x:
                            cameraDensityData?.timeRangeType! === "DAY"
                              ? moment.tz(d.from, timeZone).format("DD MMM ddd")
                              : moment.tz(d.from, timeZone).format("LT"),
                          y: d.connectedPeopleCount,
                        };
                      }
                    )!
                  }
                  spaceLoad={{
                    low: cameraDensityData?.censusOccupancyMediumMinThreShold!,
                    high: cameraDensityData?.censusOccupancyMediumMaxThreShold!,
                    limit: cameraDensityData?.censusOccupancyMaxThreShold!,
                  }}
                  loading={loading}
                  error={error}
                />
                <Typography className={classes.statisticContainerTitle}>
                  Threshold Distribution
                </Typography>
                <BarChartForDensity
                  data={[
                    {
                      x: "Low",
                      y: cameraDensityData?.lowDensityTimeForCensus!,
                      fill: "rgba(79, 158, 83, 0.15)",
                    },
                    {
                      x: "Medium",
                      y: cameraDensityData?.mediumDensityTimeForCensus!,
                      fill: "rgba(238, 170, 70, 0.2)",
                    },
                    {
                      x: "High",
                      y: cameraDensityData?.highDensityTimeForCensus!,
                      fill: "rgba(176, 0, 31, 0.2)",
                    },
                  ]}
                  loading={loading}
                  error={error}
                />
              </>
            ) : (
              <></>
            )}
            {selectedDataType === "Census_Accumulation" ? (
              <>
                <LinearChartForDensity
                  data={
                    cameraDensityData?.censusAccOccupacyTimeline?.map(
                      (d: any, i: number) => {
                        return {
                          x:
                            cameraDensityData?.timeRangeType! === "DAY"
                              ? moment.tz(d.from, timeZone).format("DD MMM ddd")
                              : moment.tz(d.from, timeZone).format("LT"),
                          y: d.connectedPeopleCount,
                        };
                      }
                    )!
                  }
                  spaceLoad={{
                    low: cameraDensityData?.censusAccOccupancyMediumMinThreShold!,
                    high: cameraDensityData?.censusAccOccupancyMediumMaxThreShold!,
                    limit: cameraDensityData?.censusAccOccupancyMaxThreShold!,
                  }}
                  loading={loading}
                  error={error}
                />
                <Typography className={classes.statisticContainerTitle}>
                  Threshold Distribution
                </Typography>
                <BarChartForDensity
                  data={[
                    {
                      x: "Low",
                      y: cameraDensityData?.lowDensityTimeForCensusAcc!,
                      fill: "rgba(79, 158, 83, 0.15)",
                    },
                    {
                      x: "Medium",
                      y: cameraDensityData?.mediumDensityTimeForCensusAcc!,
                      fill: "rgba(238, 170, 70, 0.2)",
                    },
                    {
                      x: "High",
                      y: cameraDensityData?.highDensityTimeForCensusAcc!,
                      fill: "rgba(176, 0, 31, 0.2)",
                    },
                  ]}
                  loading={loading}
                  error={error}
                />
              </>
            ) : (
              <></>
            )}
            {selectedDataType === "Dwell_Time" ? (
              <>
                <Grid>
                  <Typography className={classes.dwellTimeLabel}>
                    Secs
                  </Typography>
                </Grid>
                <LinearChartForDensity
                  data={
                    cameraDensityData?.dwellTimeTimeLine?.map(
                      (d: any, i: number) => {
                        return {
                          x:
                            cameraDensityData?.timeRangeType! === "DAY"
                              ? moment.tz(d.from, timeZone).format("DD MMM ddd")
                              : moment.tz(d.from, timeZone).format("LT"),
                          y: d.connectedPeopleCount,
                        };
                      }
                    )!
                  }
                  spaceLoad={{
                    low: cameraDensityData?.dwellTimeMinThreshold!,
                    high: cameraDensityData?.dwellTimeMediumThreshold!,
                    limit: cameraDensityData?.dwellTimeMaxThreshold!,
                  }}
                  loading={loading}
                  error={error}
                />
                <Typography className={classes.statisticContainerTitle}>
                  Threshold Distribution
                </Typography>
                <BarChartForDensity
                  data={[
                    {
                      x: "Low",
                      y: cameraDensityData?.lowDensityTimeForDwellTimeAcc!,
                      fill: "rgba(79, 158, 83, 0.15)",
                    },
                    {
                      x: "Medium",
                      y: cameraDensityData?.mediumDensityTimeForDwellTimeAcc!,
                      fill: "rgba(238, 170, 70, 0.2)",
                    },
                    {
                      x: "High",
                      y: cameraDensityData?.highDensityTimeForDwellTimeAcc!,
                      fill: "rgba(176, 0, 31, 0.2)",
                    },
                  ]}
                  loading={loading}
                  error={error}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </ClickAwayListener>
      </Grid>
      <CustomModal open={toggleUnpinModal}>
        <UnpinModal
          setToggleModal={setToggleUnpinModal}
          deviceType={DashboardDeviceType.ZONE}
          deviceId={parsedRequest.deviceId}
          Type="MyPins"
          removedCard={removedCard}
        />
      </CustomModal>
    </>
  );
}
