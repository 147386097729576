import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  subheader: {
    fontSize: "14px",
    lineHeight: "21px",
    textAlign: "left",
    color: "#292929",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  confirm: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: "#2F56A1",
    textTransform: "initial",
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(28),
    color: "#2F56A1",
    marginBottom: "28px",
  },
  alignLeft: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    display: "flex",
    minWidth: "425px",
    minHeight: "315px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    padding: "29px 37px",
    alignItems: "center",
    borderRadius: "20px",
    boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
    zIndex: 1000,
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  hostName: {
    textAlign: "left",
    color: theme.palette.common.funBlue,
    fontSize: "12px",
    fontWeight: "bold",
  },
}));
