import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    position: "relative",
  },
  submitBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 180,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(5),
    outline: "none",
    border: "none",
  },
  progress: {
    color: theme.palette.common.white,
  },
  switch: {
    color: `${theme.palette.common.funBlue} !important`,
    border: `1px solid ${theme.palette.common.funBlue}`,
    borderRadius: "30px;",
  },
  switchInner: {
    color: `${theme.palette.common.funBlue} !important`,
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `${theme.palette.common.funBlue} !important`,
  },
  switchLabel: {
    margin: "10px 0 10px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.funBlue,
  },
}));
