import { Grid } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { PageHeader } from "../../../../../../common/components";
import { EditZoneForm } from "./ZoneEdit.form";

export function ZoneEdit() {
  const { formatMessage } = useIntl();
  
  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "editZone.header",
          defaultMessage: "Edit Zone",
          description: "edit zone label",
        })}
      />
      <Grid item={true} md={12}>
        <EditZoneForm />
      </Grid>
    </div>
  );
}
