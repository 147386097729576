import React, { useState, useCallback } from "react";
import { Menu, MenuItem, MenuList, makeStyles } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { Role, Sensor } from "../../../../types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { useSensorsState } from "../../../../core/context/containers/Sensors.contrainer";
import { useSnackbar } from "notistack";
import { RemoveEntityModal } from "../../../../common/components/RemoveEntityModal/RemoveEntityModal";
import { ReactComponent as HamburgerMenu } from "../../../../common/assets/images/hamburger.svg";
import { useModal } from "../../../../core/hooks/Modal";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 15,
    minHeight: 15,
    maxWidth: 15,
    maxHeight: 15,
    color: theme.palette.common.funBlue,
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    borderBottom: `1px solid ${theme.palette.common.nobel}`,

    "&:nth-child(3)": {
      border: "none",
    },
  },
}));

interface PropertyMenuProps {
  sensor: Sensor;
  index: string;
}

export function SensorsMenu({ sensor, index }: PropertyMenuProps) {
  const classes = useStyles();
  const [modal, showModal] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();
  const { setSelectedSensor } = useSensorsState();
  const [anchorEl, setAnchorEl] = useState();

  const auth = useAuth();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    show: showDeleteModal,
    hide: hideDeleteModal,
    RenderModal: RenderDeleteModal,
  } = useModal();

  const [deleteSensor] = useMutation(GQLService.mutations.deleteSensor, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allSensors,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const history = useHistory();

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const handleViewSensorClick = () => {
    setSelectedSensor(sensor);
    history.push(
      ROUTES.SENSORS_VIEW.replace(":uid", selectedProperty!.id).replace(
        ":sensorId",
        sensor.id.split(":")[1]
      )
    );
  };

  const handleEditSensorClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedSensor(sensor);
      history.push(
        ROUTES.SENSORS_EDIT.replace(":uid", selectedProperty?.id!).replace(
          ":sensorId",
          sensor.id.split(":")[1]
        )
      );
    },
    [sensor]
  );

  const handleRemoveSensorClick = useCallback(
    async (ev: any) => {
      ev.preventDefault();
      ev.stopPropagation();
      try {
        await deleteSensor({ variables: { id: sensor.id } }).then(() =>
          enqueueSnackbar(
            formatMessage({
              id: "airQuality.list.remove.snackbar.success",
              defaultMessage: "Sensor removed successfully",
            }),
            {
              variant: "success",
            }
          )
        );
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error", persist: true });
      }
    },
    [sensor] //eslint-disable-line
  );

  const renderEditMenuItem = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={handleEditSensorClick}>
        {formatMessage({
          id: "ssids.contextMenu.editSSID",
          defaultMessage: "Edit Sensor",
          description: "ssids context menu edit ssid label",
        })}
      </MenuItem>
    );
  }, [sensor]);

  const renderRemoveMenuItem = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={openDeleteModal}>
        {formatMessage({
          id: "ssids.contextMenu.removeSSID",
          defaultMessage: "Remove Sensor",
          description: "ssids context menu remove ssid label",
        })}
      </MenuItem>
    );
  }, [sensor]);

  const openDeleteModal = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    try {
      showDeleteModal();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const removeSensorConfirmed = useCallback(
    async (id: string) => {
      try {
        await deleteSensor({ variables: { id: sensor.id } }).then(() => {
          enqueueSnackbar(
            formatMessage({
              id: "sensor.list.remove.snackbar.success",
              defaultMessage: "Sensor removed successfully",
            }),
            {
              variant: "success",
            }
          );
          hideDeleteModal();
        });
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error", persist: true });
      }
    },
    [sensor]
  );

  const removeSensorRejected = useCallback(() => {
    hideDeleteModal();
  }, []);

  const open = Boolean(anchorEl);

  return (
    <>
      <HamburgerMenu
        id={index}
        className={classes.icon}
        onClick={handleToggleMenu}
      />
      <Menu
        id="properties-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ zIndex: 500 }}
        keepMounted={true}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          <MenuItem
            className={classes.menuItem}
            onClick={handleViewSensorClick}
          >
            {formatMessage({
              id: "ssids.contextMenu.detailsSSID",
              defaultMessage: "Details",
              description: "sensors context menu duplicate ssid label",
            })}
          </MenuItem>
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "sensors:edit",
            renderEditMenuItem()
          )}
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "sensors:delete",
            renderRemoveMenuItem()
          )}
        </MenuList>
      </Menu>
      <RenderDeleteModal>
        <RemoveEntityModal
          no={removeSensorRejected}
          yes={removeSensorConfirmed}
          id={sensor.id}
          UI={{
            subheader: "Sensor",
            type: "Sensor",
            name: sensor.name,
          }}
        />
      </RenderDeleteModal>
    </>
  );
}
