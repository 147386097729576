import React, { useState, useCallback } from "react";
import {
  Grid,
  makeStyles,
  Button,
  Input,
  InputLabel,
  Select,
  Switch,
  TextareaAutosize,
  MenuItem,
  Typography,
  AccordionActions,
} from "@material-ui/core";
import {
  ActivitySwitch,
  CustomModal,
  StatusIndicator,
} from "../../../../../../common/components/index";
import { useIntl } from "react-intl";
import { ImagesSensor } from "../../../../../../types";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import { ZoneDetails } from "./ZoneDetails";
import { ZoneMenu } from "./ZoneMenu";
import { useStyles } from "../../EditImageSensor.styles";
import clsx from "clsx";

interface EditZoneGridProps {
  imageSensor: ImagesSensor;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  submitCount: number;
  errors: any;
}

export function EditZoneGrid({
  imageSensor,
  setFieldValue,
  submitCount,
  errors,
}: EditZoneGridProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return (
    <Grid container={true} className={classes.childrenGrid}>
      <FieldArray
        name="zones"
        render={(arrayHelpers) => (
          <div>
            {imageSensor?.zones?.map((zone, index) => (
              <Grid container className={classes.childGrid} key={"grid"+zone?.id}>
                <Grid item={true} sm={12} md={12} key={"details"+zone?.id}>
                  <ZoneDetails
                    values={zone}
                    index={index}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    submitCount={submitCount}
                    initialValues={zone}
                    imageSensor={imageSensor}
                    zone={zone}
                  />
                </Grid>
              </Grid>
            ))}
          </div>
        )}
      ></FieldArray>
    </Grid>
  );
}
