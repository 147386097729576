import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";

import { Form, PageHeader } from "../../../../common/components";
import { useMutation, useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { ElevatorBay } from "../../../../types";
import { ROUTES } from "../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { createFormFields } from "./Form";
import { elevatorBayValidationRules } from "./Form";
import { useElevatorBaysState } from "../../../../core/context/containers/ElevatorBays.container";
import { CustomModal } from "../../../../common/components/index";
import { Modal } from "./Modal";
import { useSnackbar } from "notistack";

export function EditElevatorBay() {
  const { formatMessage } = useIntl();

  const { selectedProperty } = usePropertiesState();
  const { selectedElevatorBay } = useElevatorBaysState();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [showModal, toggleModal] = useState<boolean>(false);
  const history = useHistory();

  const { loading: loadingFloors, data: floors } = useQuery(
    GQLService.queries.allFloors,
    {
      variables: { propertyId: selectedProperty?.id },
      fetchPolicy: "network-only",
    }
  );

  const { loading: loadingBeacons, data: beacons } = useQuery(
    GQLService.queries.beaconsByPropertyId,
    {
      variables: { propertyId: selectedProperty?.id },
      fetchPolicy: "network-only",
    }
  );

  const { loading: loadingElevators, data: elevators } = useQuery(
    GQLService.queries.elevatorsByPropertyId,
    {
      variables: { propertyId: selectedProperty?.id },
      fetchPolicy: "cache-first",
    }
  );

  const [formDataState, setFormDataState] = useState<ElevatorBay | undefined>(
    undefined
  );

  const [updateElevatorBay] = useMutation(
    GQLService.mutations.updateElevatorBay,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.elevatorBaysByPropertyId,
          variables: { propertyId: selectedProperty?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const rejectChanges = useCallback(() => {
    toggleModal(false);
  }, []);

  const handleSubmit = useCallback(
    (formData) => {
      toggleModal(true);
      setFormDataState({ ...formData });
    },
    [formDataState, selectedProperty] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      if (formDataState) {
        try {
          await updateElevatorBay({
            variables: { ...formDataState, id: selectedElevatorBay?.id! },
          });
          enqueueSnackbar("Elevator Bay changed successfully", {
            variant: "success",
          });
          //   reset();
          history.push(
            ROUTES.ELEVATOR_BAYS.replace(":uid", selectedProperty?.id!)
          );
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          console.log(e);
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "editElevatorBay.header",
          defaultMessage: "Edit ElevatorBay",
          description: "edit ElevatorBay label",
        })}
      />
      <Grid item={true} md={6} sm={12}>
        {floors && beacons && (
          <Form
            defaultValues={{
              ...selectedElevatorBay,
              floorId: selectedElevatorBay?.floor.id,
              beaconId: selectedElevatorBay?.beacon.id,
            }}
            fields={createFormFields({
              floors: floors?.floorsByPropertyId,
              beacons: beacons.beaconsByPropertyId,
              elevators: elevators?.elevatorsByPropertyId,
            })}
            validationSchema={elevatorBayValidationRules}
            onSubmit={handleSubmit}
          />
        )}
      </Grid>
      <CustomModal open={showModal}>
        <Modal yes={async () => await confirmChanges()} no={rejectChanges} />
      </CustomModal>
    </div>
  );
}
