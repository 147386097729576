import React, { useCallback } from "react";
import {
  Accordion,
  AccordionSummary,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";

import { User } from "../../../../../types";
import { StatusIndicator } from "../../../../../common/components";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import { listStyles } from "../../../../../common/styles/lists";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
  extendedListSubheader: {
    ...listStyles.extendedListSubheader,
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  container: {
    margin: "10px 30px",
  },
  cell: {
    minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  groupInfo: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.regularBlue,
  },
  content: {},
  accordionDetails: {
    paddingBottom: "40px",
    flexDirection: "column",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
}));

interface UserListItemProps {
  item: User;
}

export function UserListItem({ item }: UserListItemProps) {
  const classes = useStyles();
  const { selectedProperty } = usePropertiesState();

  const handleViewClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
      e.preventDefault();
      e.stopPropagation();
    },
    []
  );

  return (
    <Accordion key={item.id}>
      <AccordionSummary onClick={(e) => handleViewClick(e, item.id!)}>
        <Grid
          container={true}
          alignItems="center"
          justify="space-between"
          style={{ margin: 0 }}
        >
          <Grid
            container={true}
            item={true}
            md={3}
            sm={3}
            justify="flex-start"
            className={classes.headerWrapper}
          >
            <Person className={classes.icon} />
            <Typography className={classes.name}>{item.fullName}</Typography>
          </Grid>
          <Grid item={true} md={4} sm={3}>
            <Typography className={classes.subheader}>{item.email}</Typography>
          </Grid>
          <Grid item={true} md={4} sm={3}>
            <Typography className={classes.subheader}>{item.role}</Typography>
          </Grid>
        </Grid>

        <Grid item className={classes.flex}>
          {selectedProperty && (
            <StatusIndicator
              uid={selectedProperty?.id}
              isActive={item.enabled!}
              canShowStatusLabel={false}
            />
          )}
        </Grid>
      </AccordionSummary>
    </Accordion>
  );
}
