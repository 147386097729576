import React, { useMemo } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { DoorsListItem } from "./DoorsListItem";
import { Floor, Door } from "../../../../types";

const useStyles = makeStyles((theme) => ({
  sideList: {
    display: "flex",
    borderRight: `1px solid ${theme.palette.common.lightGray}`,
    flexDirection: "column",
  },
  title: {
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    padding: theme.typography.pxToRem(20),
    color: theme.palette.common.darkBlue,
    borderBottom: `1px solid ${theme.palette.common.lightGray}`,
  },
}));

type DoorsSideListPropType = {
  selectedTime: number;
  data?: Door[];
  floor: Floor;
  toggleActive: (el: Door) => void;
};

export const DoorsSideList = ({
  data,
  selectedTime,
  floor,
  toggleActive,
}: DoorsSideListPropType) => {
  const classes = useStyles();

  const timeRange = useMemo(() => {
    if (selectedTime <= 15) return "MINUTE";
    if (selectedTime <= 60) return "QUARTER";
    if (selectedTime <= 1440) return "HOUR";
    return "DAY";
  }, [selectedTime]);

  return (
    <Grid className={classes.sideList}>
      <Typography className={classes.title}>
        Doors {`(${data?.length})`}
      </Typography>
      <DoorsListItem
        item={data}
        selectedTime={selectedTime}
        floor={floor}
        timeRange={timeRange}
        toggleActive={toggleActive}
      />
    </Grid>
  );
};
