import React, { useCallback, useEffect, useState } from "react";
import { Space } from "../../../../types";
import { ReactComponent as HamburgerMenu } from "../../../../common/assets/images/hamburger.svg";
import { Menu, MenuItem, MenuList, makeStyles } from "@material-ui/core";
import { ROUTES } from "../../../../common/constants/Routing";
import { useSpacesState } from "../../../../core/context/containers/Spaces.container";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import GQLService from "../../../../core/services/GQL.service";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CustomModal } from "../../../../common/components";
import { useSnackbar } from "notistack";
import { RemoveSpaceModal } from "./RemoveSpaceModal";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 15,
    minHeight: 15,
    maxWidth: 15,
    maxHeight: 15,
    color: theme.palette.common.funBlue,
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    borderBottom: `1px solid ${theme.palette.common.nobel}`,

    "&:nth-child(3)": {
      border: "none",
    },
  },
}));

interface PropertyMenuProps {
  Space: Space;
  index: string;
}

export function SpacesMenu({ Space, index }: PropertyMenuProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  const { selectedSpace, setSelectedSpace } = useSpacesState();
  const { selectedProperty } = usePropertiesState();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [showModal, toggleModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedItem, setSelectedItem] = useState<Space>();

  const [getSpaceById, { data, loading }] = useLazyQuery(
    GQLService.queries.spaceById,
    {
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (data?.spaceById) {
      setSelectedItem(data.spaceById);
    }
  }, [data]);

  const [removeSpace] = useMutation(GQLService.mutations.deleteSpace, {
    refetchQueries: () => [
      {
        query: GQLService.queries.spaces,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const handleSpaceDetailsClick = () => {
    setSelectedSpace(Space);
    history.push(
      ROUTES.SPACE_VIEW.replace(":uid", selectedProperty!.id).replace(
        ":spaceId",
        Space.id.split(":")[1]
      )
    );
  };

  const handleEditSpaceClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedSpace(Space);
      history.push(
        ROUTES.SPACE_EDIT.replace(":uid", selectedProperty?.id!).replace(
          ":spaceId",
          Space.id.split(":")[1]
        )
      );
    },
    [Space]
  );

  const handleRemoveSpaceClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedSpace(Space);
      getSpaceById({ variables: { spaceId: Space.id } });
      toggleModal(true);
      setAnchorEl(undefined);
    },
    [Space]
  );

  const confirmChanges = useCallback(async () => {
    toggleModal(false);
    try {
      removeSpace({ variables: { id: Space.id } });
      enqueueSnackbar(
        formatMessage({
          id: "space.remove.mainSettings.snackbar.success",
          defaultMessage: "Space deleted successfully",
        }),
        {
          variant: "success",
        }
      );
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error", persist: true });
    }
  }, []);

  const rejectChanges = useCallback(() => toggleModal(false), []);

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <HamburgerMenu
        id={index}
        className={classes.icon}
        onClick={handleToggleMenu}
      />
      <Menu
        id="properties-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ zIndex: 500 }}
        keepMounted={true}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          <MenuItem
            className={classes.menuItem}
            onClick={handleSpaceDetailsClick}
          >
            {formatMessage({
              id: "spaces.contextMenu.details.space",
              defaultMessage: "Details",
              description: "This option gives details about space.",
            })}
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleEditSpaceClick}>
            {formatMessage({
              id: "spaces.contextMenu.edit.space",
              defaultMessage: "Edit Space",
              description: "This option edit space details.",
            })}
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={handleRemoveSpaceClick}
          >
            {formatMessage({
              id: "spaces.contextMenu.remove.space",
              defaultMessage: "Delete Space",
              description: "This option delete space details.",
            })}
          </MenuItem>
        </MenuList>
      </Menu>
      <CustomModal open={showModal}>
        <RemoveSpaceModal
          headerText="Delete Space"
          yes={async () => await confirmChanges()}
          no={rejectChanges}
          data={selectedItem}
          loading={loading}
        />
      </CustomModal>
    </React.Fragment>
  );
}
