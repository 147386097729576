import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.turquoiseBlue30}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.turquoiseBlue15,
    paddingLeft: 10,
  },
  textArea: {
    minHeight: "200px",
    border: `1px solid ${theme.palette.common.turquoiseBlue30}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.turquoiseBlue15,
    paddingLeft: 10,
    color: "inherit",
    fontFamily: "inherit",
    fontWeight: "inherit",
    fontSize: theme.typography.pxToRem(16),
    width: "100%",
  },
  inputLabel: {
    margin: "20px 0 10px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.funBlue,
  },
  errorLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.cinnabar,
    marginLeft: 10,
  },
  errorInput: {
    borderColor: theme.palette.common.cinnabar,
  },
  form: {
    width: "100%",
    position: "relative",
  },
  submitBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 180,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(5),
    outline: "none",
    border: "none",
  },
  switch: {
    color: `${theme.palette.common.funBlue} !important`,
    border: `1px solid ${theme.palette.common.funBlue}`,
    borderRadius: "30px;",
  },
  switchInner: {
    color: `${theme.palette.common.funBlue} !important`,
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `${theme.palette.common.funBlue} !important`,
  },
  progress: {
    color: `${theme.palette.common.funBlue} !important`,
  },
  img: {
    width: 860,
    height: 657,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    width: "100% !important",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
    marginTop: theme.spacing(1),
  },
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.blackPearl,
    marginBottom: theme.spacing(2),
  },
  select: {
    width: "100% !important",
    backgroundColor: theme.palette.common.turquoiseBlue15,
    marginBottom: "10px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.turquoiseBlue15,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 28,
    border: `1px solid ${theme.palette.common.turquoiseBlue30}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: "15px",
  },
}));
