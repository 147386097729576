import React, { useState, useCallback, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  AccordionActions,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Router } from "@material-ui/icons";

import { AccessPoint } from "../../../../types";
import { StatusIndicator } from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { AccessPointMenu } from "./components/AccessPointMenu/AccessPointMenu";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { Radio } from "../../../../types";
import { ReactComponent as Wifi } from "../../../../common/assets/images/wifi-24px.svg";
import { ROUTES } from "../../../../common/constants/Routing";
import { useAccessPointState } from "../../../../core/context/containers/AccessPoints.container";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { listStyles } from "../../../../common/styles/lists";
import { SSID } from "../../../../types";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
  extendedListSubheader: {
    ...listStyles.extendedListSubheader,
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  container: {
    margin: "10px 44px",
    justifyContent: "space-between",
  },
  cell: {
    minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  ssidInfo: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.blackPearl,
  },
  ssidItem: {
    marginRight: 24,
    display: "flex",
    alignItems: "center",
  },
  wifi: {
    width: 18,
  },
  ssidName: {
    marginLeft: 5,
    color: theme.palette.common.textBlack,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
  },
  accordionDetails: {
    paddingBottom: "40px",
    flexDirection: "column",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  ssidInfoGrid: {
    display: "flex",
    width: "50%",
    justifyContent: "space-between",
  },
  status: {
    flexDirection: "column",
  },
}));

interface AccessPointListItemProps {
  item: AccessPoint;
}

export function AccessPointListItem({ item }: AccessPointListItemProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [ssids, setSsids] = useState<SSID[]>([]);
  const { setSelectedAccessPoint } = useAccessPointState();
  const history = useHistory();
  const [updateAccessPoint] = useMutation(
    GQLService.mutations.editAccessPoint,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allAccessPoints,
          variables: { propertyId: selectedProperty?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleExpand = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const toggleActiveStatus = useCallback(
    async () => {
      await updateAccessPoint({ variables: { ...item, active: !item.active } });
    },
    [item] //eslint-disable-line
  );

  useEffect(() => {
    let newSsids: any[] = [];
    newSsids = [item?.radio1, item?.radio2].reduce(
      (acc: string[], radio: Radio) => {
        return Array.isArray(radio?.ssids) ? [...acc, ...radio?.ssids] : acc;
      },
      []
    );
    newSsids = [...new Set(newSsids)];
    setSsids(newSsids);
  }, [item]);

  const handleNameClick = useCallback(() => {
    setSelectedAccessPoint(item);
    history.push(
      ROUTES.ACCESS_POINT_VIEW.replace(":uid", selectedProperty!.id).replace(
        ":accesspoint",
        item.id.split(":")[1]
      )
    );
  }, []);

  const handleViewClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedAccessPoint(item);
      history.push(
        ROUTES.ACCESS_POINT_VIEW.replace(":uid", selectedProperty!.id).replace(
          ":accesspoint",
          item.id.split(":")[1]
        )
      );
    },
    [item]
  );

  return (
    <Accordion key={item.id} expanded={isExpanded}>
      <AccordionSummary onClick={(e) => handleViewClick(e, item.id!)}>
        <Grid
          container={true}
          alignItems="center"
          justify="space-between"
          style={{ margin: 0 }}
        >
          <Grid
            container={true}
            item={true}
            md={4}
            sm={3}
            justify="flex-start"
            onClick={handleExpand}
            className={classes.headerWrapper}
          >
            {isExpanded ? (
              <ExpandLess
                id={`expandLess-${item.id}`}
                className={classes.expandIcon}
              />
            ) : (
              <ExpandMore
                id={`expandMore-${item.id}`}
                className={classes.expandIcon}
              />
            )}
            <Router className={classes.icon} />
            <Typography className={classes.name}>{item.name}</Typography>
          </Grid>
          <Grid
            container={true}
            item={true}
            md={7}
            sm={7}
            // onClick={handleExpand}
          >
            <Grid item={true} md={2} sm={12}>
              <Typography className={classes.subheader}>
                {item.dataCenter}
              </Typography>
            </Grid>
            <Grid item={true} md={2} sm={12}>
              <Typography className={classes.subheader}>
                {item.ipAddress}
              </Typography>
            </Grid>
            <Grid item={true} md={2} sm={12}>
              <Typography className={classes.subheader}>
                {item.deviceType}
              </Typography>
            </Grid>
            <Grid item={true} md={2} sm={12}>
              <Typography className={classes.subheader}>
                {item.baseMacAddress.toLowerCase()}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container={true}
            item={true}
            md={1}
            sm={2}
            alignItems="center"
            justify="space-around"
          >
            <Grid item className={classes.flex}>
              {selectedProperty && (
                <StatusIndicator
                  uid={item?.id}
                  isActive={item.active}
                  canShowStatusLabel={false}
                />
              )}
            </Grid>
            <AccordionActions
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <AccessPointMenu accessPoint={item} index={item.id} />
            </AccordionActions>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container={true} className={classes.container} spacing={2}>
          <Grid item className={classes.ssidInfoGrid}>
            <Grid item className={classes.status}>
              <Typography className={classes.subheader}>
                {formatMessage({
                  id: "accessPoint.listItem.status",
                  defaultMessage: "Status",
                })}
              </Typography>
              <Grid className={classes.flex}>
                {selectedProperty && (
                  <StatusIndicator
                    uid={selectedProperty?.id}
                    isActive={item.active}
                    canShowStatusLabel={true}
                    onChangeFn={toggleActiveStatus}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.subheader}>
                {formatMessage({
                  id: "accessPoint.listItem.radio1",
                  defaultMessage: "RADIO 1",
                })}
              </Typography>
              <Typography className={classes.ssidInfo}>
                {item.radio1?.macAddress}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.subheader}>
                {formatMessage({
                  id: "accessPoint.listItem.radio2",
                  defaultMessage: "RADIO 2",
                })}
              </Typography>
              <Typography className={classes.ssidInfo}>
                {item.radio2?.macAddress}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.container}>
          <Typography className={classes.subheader}>
            {" "}
            {formatMessage({
              id: "accessPoint.listItem.ssids",
              defaultMessage: "SSIDs",
            })}
          </Typography>
          <Grid container={true}>
            {ssids &&
              ssids.map((ssid) => (
                <Grid item={true} classes={{ root: classes.ssidItem }}>
                  <Wifi className={classes.wifi} fill="#2F56A0" />
                  <Typography classes={{ root: classes.ssidName }}>
                    {ssid?.name}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
