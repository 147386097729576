import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  Input,
  InputLabel,
  Grid,
  Button,
  Link,
  CircularProgress,
} from "@material-ui/core";

import { Credentials } from "../../../../../types";
import { ROUTES } from "../../../../../common/constants/Routing";
import { useStyles } from "./SignInForm.styles";
import clsx from "clsx";

interface SignInFormProps {
  onSubmit: (credentials: Credentials) => Promise<void>;
}

export function SignInForm({ onSubmit }: SignInFormProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const [credentials, setCredentials] = useState<Credentials>({
    username: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const inputData = { [e.target.name]: e.target.value } as Credentials;
      setCredentials({ ...credentials, ...inputData });
    },
    [credentials]
  );

  const handleSubmit = useCallback(
    (e) => {
      let isMounted = true;
      e.preventDefault();
      if (isMounted) {
        setError(null);
        setIsLoading(true);
        onSubmit({
          username: credentials.username.toLowerCase(),
          password: credentials.password,
        })
          .catch((e) => {
            isMounted && setError(e.message);
          })
          .finally(() => {
            isMounted && setIsLoading(false);
          });
      }
      return () => {
        isMounted = false;
      };
    },
    [credentials]
  );

  const handleForgotPasswordButtonClick = () => {
    history.push(ROUTES.PASSWORD_RECOVERY);
  };

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <>
        <InputLabel className={classes.inputLabel}>
          {formatMessage({
            id: "signInForm.emailAddress.input.label",
            defaultMessage: "Email Address",
            description: "Signin email address input label",
          })}
        </InputLabel>
        <Input
          className={clsx(classes.input, error ? classes.errorBorder : "")}
          margin="dense"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          autoFocus={true}
          placeholder={formatMessage({
            id: "signInForm.emailAddress.input.placeholder",
            defaultMessage: "name@company.com",
            description: "Signin email address input placeholder",
          })}
          name="username"
          autoComplete="username"
          onChange={handleInputChange}
        />
      </>
      <>
        <InputLabel className={classes.inputLabel}>
          {formatMessage({
            id: "signInForm.password.input.label",
            defaultMessage: "Password",
            description: "Signin password input label",
          })}
        </InputLabel>
        <Input
          className={clsx(classes.input, error ? classes.errorBorder : "")}
          margin="dense"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          placeholder={formatMessage({
            id: "signInForm.password.input.placeholder",
            defaultMessage: "Your password",
            description: "Signin password input placeholder",
          })}
          name="password"
          type="password"
          onChange={handleInputChange}
        />
        <InputLabel error={Boolean(error)} className={classes.errorStyle}>{`${
          error ? `Invalid Credentials` : ""
        }`}</InputLabel>
      </>
      <Grid container={true} justify="flex-end">
        <Link
          className={classes.forgotPasswordButton}
          variant="subtitle2"
          onClick={handleForgotPasswordButtonClick}
          id="forgotPasswordLink"
        >
          {formatMessage({
            id: "signInForm.forgotPassword.link.label",
            defaultMessage: "Forgot password?",
            description: "Forgot password link label",
          })}
        </Link>
      </Grid>
      <Button
        className={classes.submit}
        type="submit"
        variant="contained"
        disabled={isLoading}
        id="signInBtn"
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          formatMessage({
            id: "signInForm.signIn.button.label",
            defaultMessage: "Sign in",
            description: "Sign in button label",
          })
        )}
      </Button>
    </form>
  );
}
