import { createContainer } from "unstated-next";
import { usePersistedState } from "../../hooks/Storage";

function useNotificationsBase() {
  const { state: notificationIsSeen, setState: setNotificationIsSeen } =
    usePersistedState<any | null>("fiveNotificationIsSeen", null);

  return {
    notificationIsSeen,
    setNotificationIsSeen,
  };
}

const { Provider, useContainer } = createContainer(useNotificationsBase);

export const useNotificationsState = useContainer;
export const NotificationsProvider = Provider;
