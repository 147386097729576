import { PROPERTY_MODULE_ROUTES } from "../../core/router/PropertyRotuer";
import { USER_ROUTES } from "../../core/router/UserRouter";
import { BEACON_ROUTES } from "../../core/router/BeaconsRouter";
import { ELEVATORS_ROUTES } from "../../core/router/ElevatorsRouter";
import { ELEVATOR_BAYS_ROUTES } from "../../core/router/ElevatorBaysRouter";
import { DEVICES_ROUTES } from "../../core/router/DevicesRouter";

export const ROUTES = {
  ROOT: "/",
  SIGN_IN: "/signin",
  PASSWORD_RECOVERY: "/passwordrecovery",
  PASSWORD_RECOVERY_SUCCESS: "/passwordrecovery-success",
  PASSWORD_RESET: "/reset-password/:token",
  DASHBOARD: "/dashboard",
  ...PROPERTY_MODULE_ROUTES,
  ...USER_ROUTES,
  ...BEACON_ROUTES,
  ...ELEVATORS_ROUTES,
  ...ELEVATOR_BAYS_ROUTES,
  ...DEVICES_ROUTES,
  FLOOR_OVERVIEW: "/dashboard/floor-overview",
  FLOOR_PLAN_EDITOR: "/dashboard/floor-plan-editor",
  NETWORK_GROUPS: "/dashboard/application-classification",
  ALL_NOTIFICATIONS: "/dashboard/all-notifications",
  MY_PINS: "/dashboard/my-pins",
  PORTFOLIO: "/dashboard/portfolio",
  ACCESS_CONTROL_SYSTEM: "/dashboard/access-control-system",
};
