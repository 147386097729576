import { gql } from "@apollo/client";

export const SENSORS_MUTATIONS = {
  createSensor: gql`
    mutation createSensor(
      $active: Boolean!
      $deviceUuid: String!
      $floorId: String!
      $name: String!
      $commonArea: Boolean!
    ) {
      createSensor(
        request: {
          active: $active
          deviceUuid: $deviceUuid
          floorId: $floorId
          name: $name
          commonArea: $commonArea
        }
      ) {
        id
      }
    }
  `,
  updateSensor: gql`
    mutation updateSensor(
      $active: Boolean!
      $deviceUuid: String
      $floorId: String!
      $name: String
      $id: String!
      $coordinateX: Int
      $coordinateY: Int
      $commonArea: Boolean!
    ) {
      updateSensor(
        request: {
          active: $active
          deviceUuid: $deviceUuid
          floorId: $floorId
          name: $name
          id: $id
          coordinateY: $coordinateY
          coordinateX: $coordinateX
          commonArea: $commonArea
        }
      )
    }
  `,
  deleteSensor: gql`
    mutation ($id: String!) {
      deleteSensorById(id: $id)
    }
  `,
};

export const SENSORS_QUERIES = {
  allSensors: gql`
    query ($propertyId: String!) {
      sensorsByPropertyId(propertyId: $propertyId) {
        id
        active
        deviceUuid
        name
        commonArea
        floor {
          id
          name
          floorNumber
        }
      }
    }
  `,
  sensorsByFloorId: gql`
    query ($floorId: String!) {
      sensorsByFloorId(floorId: $floorId) {
        id
        active
        deviceUuid
        name
        commonArea
        floor {
          id
          name
          floorNumber
        }
        coordinateX
        coordinateY
      }
    }
  `,
  averageAirQualityForProperty: gql`
    query ($from: Instant!, $to: Instant!, $propertyId: String!) {
      averageAirQualityForProperty(
        request: { from: $from, to: $to, propertyId: $propertyId }
      ) {
        airQuality
        fairStatusCount
        healthyStatusCount
        moderateStatusCount
        noDataStatusCount
        poorStatusCount
        score
        unhealthyStatusCount
      }
    }
  `,
  detailedAirQualityForFloor: gql`
    query (
      $from: Instant!
      $to: Instant!
      $floorId: String!
      $userEmail: String!
    ) {
      detailedAirQualityForFloor(
        request: {
          from: $from
          to: $to
          floorId: $floorId
          userEmail: $userEmail
        }
      ) {
        airSensor {
          active
          coordinateX
          coordinateY
          coordinateZ
          deviceUuid
          floor {
            id
          }

          id
          name
        }
        details {
          offlineFromInMinutes
          airQuality
          isPinned
          co2 {
            value
            sensorHealthIndicator
          }
          from
          humidity {
            value
            sensorHealthIndicator
          }
          illuminance {
            value
            sensorHealthIndicator
          }
          particulateMatter {
            value
            sensorHealthIndicator
          }
          score
          soundLevel {
            value
            sensorHealthIndicator
          }
          temperature {
            value
            sensorHealthIndicator
          }
          to
          volatileOrganicCompounds {
            value
            sensorHealthIndicator
          }
        }
      }
    }
  `,
  airQualityWellnessDuringTimeForSensor: gql`
    query ($request: TimeRangedDashboardSensorWellnessRequestInput) {
      airQualityWellnessDuringTimeForSensor(request: $request) {
        healthyTime
        moderateTime
        poorTime
        sensorId
        isPinned
        timeline {
          quality
          from
          score
          to
        }
      }
    }
  `,
};
