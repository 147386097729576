import { Grid, makeStyles } from "@material-ui/core";
import { Form } from "../../../../common/components";
import { Input } from "../../../../common/components/Form/FormFields";
import { User } from "../../../../types";
import React from "react";
import { FormField, FormFieldInputType } from "../../../../types/form";
import * as Yup from "yup";
import I18n from "../../../../core/services/I18n.service";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: "0",
  },
}));

const FIELD_NAMES = {
  fullName: "fullName",
  oldPassword: "oldPassword",
  newPassword: "newPassword",
  confirmNewPassword: "confirmNewPassword",
};

export interface editUserType {
  account: User | null;
  updateUser: (formData: any) => Promise<void>;
}

export const personalInfoFormFields: FormField[] = [
  {
    name: FIELD_NAMES.fullName,
    label: "Full name",
    placeholder: "Name and Surname",
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
];

export const UpdateUserForm = (props: editUserType) => {
  const classes = useStyles();
  return (
    <Grid>
      <Form
        defaultValues={{ fullName: props.account?.fullName }}
        fields={personalInfoFormFields}
        // validationSchema={undefined}
        onSubmit={props.updateUser}
        style={{ spacing: 0, button: classes.button }}
      />
    </Grid>
  );
};

export const resetPasswordFormFields: FormField[] = [
  {
    name: FIELD_NAMES.oldPassword,
    label: "Current password",
    placeholder: "Current Password",
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
    type: FormFieldInputType.password,
  },
  {
    name: FIELD_NAMES.newPassword,
    label: "New Password",
    placeholder: "New Password",
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
    type: FormFieldInputType.password,
  },
  {
    name: FIELD_NAMES.confirmNewPassword,
    label: "Confirm New Password",
    placeholder: "Confirm New Password",
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
    type: FormFieldInputType.password,
  },
];

export const resetPasswordValidationSchema = () =>
  Yup.object().shape({
    [FIELD_NAMES.oldPassword]: Yup.string()
      .min(
        3,
        I18n.formatMessage({
          id: "mainUserInformationFormFields.validation.oldPassword.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "mainUserInformationFormFields.validation.oldPassword.long",
          defaultMessage: "Too long!",
        })
      )
      .required(" "),
    [FIELD_NAMES.newPassword]: Yup.string()
      .min(
        1,
        I18n.formatMessage({
          id: "mainUserInformationFormFields.validation.newPassword.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "mainUserInformationFormFields.validation.newPassword.long",
          defaultMessage: "Too long!",
        })
      )
      .required(" "),
    [FIELD_NAMES.confirmNewPassword]: Yup.mixed().test(
      "match",
      "Passwords do not match",
      function (repeat: any) {
        return repeat === this.parent.newPassword;
      }
    ),
  });
