import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Formik, Form as FormikForm } from "formik";
import { useMutation } from "@apollo/client";
import {
  Grid,
  Button,
  Input,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useZoneState } from "../../../../../../core/context/containers/Zone.container";
import {
  ActivitySwitch,
  CustomModal,
} from "../../../../../../common/components/index";
import { ModalContent } from "../../ModalContent";
import { Zone, dataTypeMap } from "../../../../../../types";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import GQLService from "../../../../../../core/services/GQL.service";
import { ROUTES } from "../../../../../../common/constants/Routing";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { SensorRange } from "../../../../Properties/components/SensorRange/SensorRange";
import { ZonePhoto } from "./ZonePhoto";
import { scrollsTop } from "../../../../../../common/utils/scrollbar.utils";
import { useStyles } from "../../EditImageSensor.styles";
import { useIntl } from "react-intl";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { useImagesSensorsState } from "../../../../../../core/context/containers/ImageSensor.container";

const FIELD_NAMES = {
  name: "name",
  id: "id",
  area: "area",
  zone: "zone",
  active: "active",
  imageKey: "imageKey",
  dataType: "dataType",
  censusOccupancyMediumMinThreShold: "censusOccupancyMediumMinThreShold",
  censusOccupancyMediumMaxThreShold: "censusOccupancyMediumMaxThreShold",
  censusOccupancyMaxThreShold: "censusOccupancyMaxThreShold",
  censusAccOccupancyMediumMinThreShold: "censusAccOccupancyMediumMinThreShold",
  censusAccOccupancyMediumMaxThreShold: "censusAccOccupancyMediumMaxThreShold",
  censusAccOccupancyMaxThreShold: "censusAccOccupancyMaxThreShold",
  dwellTimeMinThreshold: "dwellTimeMinThreshold",
  dwellTimeMediumThreshold: "dwellTimeMediumThreshold",
  dwellTimeMaxThreshold: "dwellTimeMaxThreshold",
};

export interface zoneFile {
  name: string | undefined;
  url: string | undefined;
  data: File | undefined;
}

export function EditZoneForm() {
  const { selectedProperty } = usePropertiesState();
  const classes = useStyles();
  const { selectedZone, setSelectedZone } = useZoneState();
  const [showModal, toggleModal] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [updateZone] = useMutation(GQLService.mutations.editZone);
  const history = useHistory();
  const [formDataState, setFormDataState] = useState<Zone | false>(false);
  const [selectedDataType] = useState<string>();
  const { selectedImagesSensor } = useImagesSensorsState();
  const [removeImage, setRemoveImage] = useState<boolean>(false);
  const [formImageKey, setFormImageKey] = useState<string>("");

  const editZoneSchema = Yup.object().shape({
    [FIELD_NAMES.zone]: Yup.string().nullable(),
    [FIELD_NAMES.name]: Yup.string().nullable(),
    [FIELD_NAMES.area]: Yup.number()
      .typeError(
        formatMessage({
          id: "ZoneEdit.validation.area.number",
          defaultMessage: "You must specify a number",
          description: "Only numbers allowed",
        })
      )
      .nullable(),
    [FIELD_NAMES.active]: Yup.boolean().nullable(),
  });

  const setSensorRangeCensus = useCallback(
    async (
      values: number[],
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue(`censusOccupancyMediumMinThreShold`, values[1]);
      setFieldValue(`censusOccupancyMediumMaxThreShold`, values[2]);
    },
    []
  );

  const handleSubmit = useCallback(
    async (formData) => {
      toggleModal(true);
      if (formData.dataType === "CENSUS_ACCUMULATION") {
        const censusFormData = { ...formData };
        censusFormData.censusOccupancyMediumMinThreShold = 0;
        censusFormData.censusOccupancyMediumMaxThreShold = 0;
        censusFormData.censusOccupancyMaxThreShold = 0;
        censusFormData.censusAccOccupancyMaxThreShold = Math.round(
          censusFormData.censusAccOccupancyMaxThreShold
        );
        setFormDataState({ ...censusFormData, id: selectedZone?.id });
      } else if (formData.dataType === "CENSUS") {
        const censusAccFormData = { ...formData };
        censusAccFormData.censusAccOccupancyMediumMinThreShold = 0;
        censusAccFormData.censusAccOccupancyMediumMaxThreShold = 0;
        censusAccFormData.censusAccOccupancyMaxThreShold = 0;
        censusAccFormData.censusOccupancyMaxThreShold = Math.round(
          censusAccFormData.censusOccupancyMaxThreShold
        );
        setFormDataState({ ...censusAccFormData, id: selectedZone?.id });
      } else {
        const data = { ...formData };
        data.censusAccOccupancyMaxThreShold = Math.round(
          data.censusAccOccupancyMaxThreShold
        );
        data.censusOccupancyMaxThreShold = Math.round(
          data.censusOccupancyMaxThreShold
        );
        setFormDataState({ ...data, id: selectedZone?.id });
      }
    },
    [formDataState] // eslint-disable-line
  );

  const setSensorRangeCensusAccumulation = useCallback(
    async (
      values: number[],
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue(`censusAccOccupancyMediumMinThreShold`, values[1]);
      setFieldValue(`censusAccOccupancyMediumMaxThreShold`, values[2]);
    },
    []
  );

  const setSensorRangeDwellTime = useCallback(
    async (
      values: number[],
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue(`dwellTimeMinThreshold`, values[1]);
      setFieldValue(`dwellTimeMediumThreshold`, values[2]);
    },
    []
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      if (formDataState) {
        if (removeImage) {
          formDataState!.imageKey = null;
          setRemoveImage(false);
        }
        if (formImageKey.length > 0) {
          formDataState!.imageKey = formImageKey;
          setFormImageKey("");
        }
        try {
          await updateZone({
            variables: {
              ...formDataState,
              imageKey: selectedZone?.imageKey,
              id: selectedZone?.id,
            },
          }).then((data) => {
            setSelectedZone(formDataState);
            history.push(
              ROUTES.ZONE.replace(":uid", selectedProperty?.id!)
                .replace(
                  ":imagesensor",
                  selectedImagesSensor?.id.split(":")[1]!
                )
                .replace(":zoneid", selectedZone?.id.split(":")[1]!)
            );
          });
          enqueueSnackbar("Zone changed successfully", {
            variant: "success",
          });
          scrollsTop();
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          console.log(e);
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => toggleModal(false), []);

  const [active, toggleActiveState] = useState<boolean>(selectedZone?.active!);

  const toggleSwitchButton = useCallback(
    (setFieldValue) => {
      setFieldValue(FIELD_NAMES.active, !active);
      toggleActiveState(!active);
    },
    [selectedZone, active] // eslint-disable-line
  );

  const handleChangeMaxRangeCensus = useCallback(
    async (
      value: number,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue(
        `censusOccupancyMediumMinThreShold`,
        Math.round((90 / 100) * value)
      );
      setFieldValue(
        `censusOccupancyMediumMaxThreShold`,
        Math.round((95 / 100) * value)
      );
      setFieldValue(`censusOccupancyMaxThreShold`, +value);
    },
    []
  );

  const handleChangeMaxRangeCensusAccumulation = useCallback(
    async (
      value: number,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue(
        `censusAccOccupancyMediumMinThreShold`,
        Math.round((90 / 100) * value)
      );
      setFieldValue(
        `censusAccOccupancyMediumMaxThreShold`,
        Math.round((95 / 100) * value)
      );
      setFieldValue(`censusAccOccupancyMaxThreShold`, +value);
    },
    []
  );

  const handleChangeMaxRangeDwellTime = useCallback(
    async (
      value: number,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue(`dwellTimeMinThreshold`, Math.round((90 / 100) * value));
      setFieldValue(`dwellTimeMediumThreshold`, Math.round((95 / 100) * value));
      setFieldValue(`dwellTimeMaxThreshold`, +value);
    },
    []
  );

  return (
    selectedZone && (
      <Grid item={true} md={12}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={Object.keys(FIELD_NAMES).reduce(
            (acc: { [key: string]: string }, curr: any) => {
              if (
                (curr === "censusOccupancyMaxThreShold" ||
                  curr === "censusAccOccupancyMaxThreShold" ||
                  curr === "dwellTimeMaxThreshold") &&
                (selectedZone as any)[curr] === 0
              ) {
                acc[curr] = "100";
              } else if (
                (curr === "censusOccupancyMediumMinThreShold" ||
                  curr === "censusAccOccupancyMediumMinThreShold" ||
                  curr === "dwellTimeMinThreshold") &&
                (selectedZone as any)[curr] === 0
              ) {
                acc[curr] = "90";
              } else if (
                (curr === "censusOccupancyMediumMaxThreShold" ||
                  curr === "censusAccOccupancyMediumMaxThreShold" ||
                  curr === "dwellTimeMediumThreshold") &&
                (selectedZone as any)[curr] === 0
              ) {
                acc[curr] = "95";
              } else {
                acc[curr] = (selectedZone as any)[curr];
              }
              return acc;
            },
            {}
          )}
          validationSchema={editZoneSchema}
        >
          {({ values, setFieldValue, errors, submitCount, initialValues }) => (
            <FormikForm className={classes.form}>
              <Grid container={true} spacing={3}>
                <Grid item={true} sm={4} md={2}>
                  <InputLabel className={classes.inputLabel}>
                    Zone Id
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.zone] !==
                        initialValues[FIELD_NAMES.zone]) &&
                      errors[FIELD_NAMES.zone] && (
                        <span className={classes.errorLabel}>
                          {errors[FIELD_NAMES.zone]}
                        </span>
                      )}
                  </InputLabel>
                  <Input
                    className={clsx(
                      classes.input,
                      classes.readOnlyInput,
                      (submitCount > 0 ||
                        values[FIELD_NAMES.zone] !==
                          initialValues[FIELD_NAMES.zone]) &&
                        errors[FIELD_NAMES.zone]
                        ? classes.errorInput
                        : ""
                    )}
                    disableUnderline={true}
                    fullWidth={true}
                    placeholder={"Zone Id"}
                    name={FIELD_NAMES.zone}
                    type={"input"}
                    defaultValue={values.zone}
                    readOnly
                  />
                </Grid>
                <Grid item={true} sm={4} md={2}>
                  <InputLabel className={classes.inputLabel}>
                    Zone Name
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.name] !==
                        initialValues[FIELD_NAMES.name]) &&
                      errors[FIELD_NAMES.name] && (
                        <span className={classes.errorLabel}>
                          {errors[FIELD_NAMES.name]}
                        </span>
                      )}
                  </InputLabel>
                  <Input
                    className={clsx(
                      classes.input,
                      (submitCount > 0 ||
                        values[FIELD_NAMES.name] !==
                          initialValues[FIELD_NAMES.name]) &&
                        errors[FIELD_NAMES.name]
                        ? classes.errorInput
                        : ""
                    )}
                    disableUnderline={true}
                    fullWidth={true}
                    placeholder={"Zone Name"}
                    name={FIELD_NAMES.name}
                    type={"input"}
                    defaultValue={values.name}
                    onChange={(e) =>
                      setFieldValue(FIELD_NAMES.name, e.target.value)
                    }
                  />
                </Grid>
                <Grid item={true} sm={4} md={3}>
                  <InputLabel className={classes.inputLabel}>
                    Covered Area (sq. ft.)
                  </InputLabel>
                  <Input
                    className={clsx(
                      classes.input,
                      (submitCount > 0 ||
                        values[FIELD_NAMES.area] !==
                          initialValues[FIELD_NAMES.area]) &&
                        errors[FIELD_NAMES.area]
                        ? classes.errorInput
                        : ""
                    )}
                    disableUnderline={true}
                    fullWidth={true}
                    placeholder={"Covered Area (sq. ft.)"}
                    name={FIELD_NAMES.area}
                    type={"input"}
                    defaultValue={values.area}
                    onChange={(e) => {
                      const value = e.target.value ? e.target.value : 0;
                      setFieldValue(FIELD_NAMES.area, value);
                    }}
                  />
                  <div>
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.area] !==
                        initialValues[FIELD_NAMES.area]) &&
                      errors[FIELD_NAMES.area] && (
                        <span className={classes.errorLabel}>
                          {errors[FIELD_NAMES.area]}
                        </span>
                      )}
                  </div>
                </Grid>

                <Grid item={true} sm={4} md={3}>
                  <InputLabel className={classes.inputLabel}>
                    {formatMessage({
                      id: "ZoneeEdit.label.dataType",
                      defaultMessage: "Data Type",
                      description: "Data type ",
                    })}
                  </InputLabel>
                  <Select
                    classes={{
                      root: classes.select,
                      select: classes.blackText,
                      icon: classes.icon,
                    }}
                    MenuProps={{
                      classes: {
                        paper: classes.menu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    value={selectedDataType || values.dataType}
                    disableUnderline={true}
                    IconComponent={ExpandMoreSharpIcon}
                    name="dataTypeFilter"
                    onChange={(e) => {
                      setFieldValue(FIELD_NAMES.dataType, e.target.value);
                    }}
                  >
                    {Array.from(dataTypeMap.keys()).map((key, i) => {
                      return (
                        <MenuItem
                          key={i + 1}
                          value={key}
                          classes={{
                            root: classes.option,
                            selected: classes.selected,
                          }}
                        >
                          {dataTypeMap.get(key)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
              {(values.dataType === "CENSUS" || values.dataType === "BOTH") && (
                <Grid container={true} spacing={3}>
                  <Grid item={true} sm={12} className={classes.itemSpace}>
                    <InputLabel className={classes.inputLabel}>
                      Occupancy Threshold for Census
                    </InputLabel>
                    <SensorRange
                      range={[
                        0,
                        parseInt(values.censusOccupancyMediumMinThreShold),
                        parseInt(values.censusOccupancyMediumMaxThreShold),
                        parseInt(values.censusOccupancyMaxThreShold) || 100,
                      ]}
                      indicators={["HEALTHY", "MODERATE", "POOR"]}
                      hideMinHandle={true}
                      min={0}
                      className={classes.zoneSliderWidth}
                      onChange={(values) =>
                        setSensorRangeCensus(values, setFieldValue)
                      }
                      max={parseInt(values.censusOccupancyMaxThreShold)}
                      maxLabel={values.censusOccupancyMaxThreShold}
                      onMaxLabelChange={(e) =>
                        handleChangeMaxRangeCensus(
                          e.target.value,
                          setFieldValue
                        )
                      }
                      minValue={5}
                    />
                  </Grid>
                </Grid>
              )}
              <br />
              <br />
              {(values.dataType === "CENSUS_ACCUMULATION" ||
                values.dataType === "BOTH") && (
                <Grid container={true} spacing={3}>
                  <Grid item={true} sm={12} className={classes.itemSpace}>
                    <InputLabel className={classes.inputLabel}>
                      Occupancy Threshold for Census Accumulation
                    </InputLabel>
                    <SensorRange
                      range={[
                        0,
                        parseInt(values.censusAccOccupancyMediumMinThreShold),
                        parseInt(values.censusAccOccupancyMediumMaxThreShold),
                        parseInt(values.censusAccOccupancyMaxThreShold) || 100,
                      ]}
                      indicators={["HEALTHY", "MODERATE", "POOR"]}
                      hideMinHandle={true}
                      min={0}
                      className={classes.zoneSliderWidth}
                      onChange={(values) =>
                        setSensorRangeCensusAccumulation(values, setFieldValue)
                      }
                      max={parseInt(values.censusAccOccupancyMaxThreShold)}
                      maxLabel={values.censusAccOccupancyMaxThreShold}
                      onMaxLabelChange={(e) =>
                        handleChangeMaxRangeCensusAccumulation(
                          e.target.value,
                          setFieldValue
                        )
                      }
                      minValue={5}
                    />
                  </Grid>
                </Grid>
              )}
              <br />
              <br />
              <Grid container={true} spacing={3}>
                <Grid item={true} sm={12} className={classes.itemSpace}>
                  <InputLabel className={classes.inputLabel}>
                    Dwell Time Threshold (In minutes)
                  </InputLabel>
                  <SensorRange
                    range={[
                      0,
                      parseInt(values.dwellTimeMinThreshold),
                      parseInt(values.dwellTimeMediumThreshold),
                      parseInt(values.dwellTimeMaxThreshold) || 100,
                    ]}
                    indicators={["HEALTHY", "MODERATE", "POOR"]}
                    hideMinHandle={true}
                    min={0}
                    className={classes.zoneSliderWidth}
                    onChange={(values) =>
                      setSensorRangeDwellTime(values, setFieldValue)
                    }
                    max={parseInt(values.dwellTimeMaxThreshold)}
                    maxLabel={values.dwellTimeMaxThreshold}
                    onMaxLabelChange={(e) =>
                      handleChangeMaxRangeDwellTime(
                        e.target.value,
                        setFieldValue
                      )
                    }
                    minValue={3}
                  />
                </Grid>
              </Grid>
              <Grid container={true} spacing={3}>
                <Grid item={true} sm={12} className={classes.itemSpace}>
                  <ZonePhoto
                    name={"imageKey"}
                    label={"zonePhoto"}
                    zone={selectedZone}
                    currentUrl={selectedZone?.imageKey}
                    setRemoveImage={setRemoveImage}
                    setFormImageKey={setFormImageKey}
                  />
                </Grid>
              </Grid>
              <Grid container={true} spacing={3}>
                <Grid item={true} sm={12} className={classes.itemSpace}>
                  <InputLabel className={classes.inputLabel}>Status</InputLabel>
                  <ActivitySwitch
                    name={"zoneStatus"}
                    defaultValue={active}
                    disabled={false}
                    value={active}
                    onChange={(_: any) => toggleSwitchButton(setFieldValue)}
                    onBlur={(_: any) => toggleSwitchButton(setFieldValue)}
                    activeToggleText="Active"
                    inactiveToggleText="Inactive"
                  />
                </Grid>
              </Grid>
              <Button
                id="update-btn-zoneEdit"
                type="submit"
                className={classes.submitBtn}
              >
                Update
              </Button>
            </FormikForm>
          )}
        </Formik>
        <CustomModal open={showModal}>
          <ModalContent
            headerText={"Update Zone Settings ?"}
            yes={confirmChanges}
            no={rejectChanges}
          />
        </CustomModal>
      </Grid>
    )
  );
}
