import React, { useState } from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { listStyles } from "../../../../../common/styles/lists";
import { useGroupState } from "../../../../../core/context/containers/Groups.container";
import { Datepicker } from "../../../../../common/components";


const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.common.mediumBlack,
    lineHeight: "36px",
  },
  content: {
    display: "flex",
    minWidth: "900px",
    height: "600px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    padding: "20px",
    borderRadius: "20px",
    boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  selectAll: {
    color: theme.palette.common.regularBlue,
    textTransform: "capitalize",
    paddingLeft: 0,
  },
  divider: {
    marginRight: "10px",
    borderRight: `1px solid ${theme.palette.common.black}`,
    minHeight: "10px",
  },
  filter: {
    paddingLeft: "10px",
  },
  searchText: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontSize: "14px",
    },
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  filterRow: {
    justifyContent: "space-between",
  },
  scrollContent: {
    height: "400px",
    overflowY: "scroll",
    marginTop: "16px",
  },
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
    "&:before": {
      content: "none",
    },
  },
  inputField: {
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  label: {
    margin: "20px 0 5px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    lineHeight: "18px",
    whiteSpace: "nowrap",
  },
}));

interface PermissionGroupModalProps {
  no: Function;
}

export function PermissionGroupModal({ no }: PermissionGroupModalProps) {
  const classes = useStyles();
  const [selectedStartDate, setSelectedStartDate] = useState<null | Date>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<null | Date>(null);

  const { startDate, endDate, setStartDate, setEndDate } = useGroupState();

  const handleStartDateChange = (date: Date | null) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setSelectedEndDate(date);
  };

  const addPermission = () => {
    setStartDate(selectedStartDate?.toISOString() || "");
    setEndDate(selectedEndDate?.toISOString() || "");
    no();
  };

  return (
    <Grid className={classes.content}>
      <div>
        <Typography className={classes.header}>
          Add Permission to the Group
        </Typography>
        <Grid container={true}>
          <Grid item={true} md={4}>
            <Datepicker
              name="startDay"
              label="Start Day"
              placeholder="--/--/----"
              onChange={handleStartDateChange}
              value={selectedStartDate}
            />
          </Grid>
          <Grid item={true} md={4}>
            <Datepicker
              name="endDay"
              label="End Day"
              placeholder="--/--/----"
              onChange={handleEndDateChange}
              value={selectedEndDate}
            />
          </Grid>
        </Grid>
      </div>
      <Grid item className={classes.buttonsWrapper}>
        <Button id="cancelBtn" className={classes.cancel} onClick={() => no()}>
          Cancel
        </Button>
        <Button id="addBtn" className={classes.editBtn} onClick={() => addPermission()}>
          Add Permission
        </Button>
      </Grid>
    </Grid>
  );
}
