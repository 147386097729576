import React, { useCallback, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { PageHeader, FormWizard } from "../../../../common/components";
import { Group } from "../../../../types";
import { AddGroupsFormWizardFieldset } from "./Add.form";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useGroupState } from "../../../../core/context/containers/Groups.container";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { useFormWizard } from "../../../../core/context/functions/FormWizard.functions";
import { useFormWizardState } from "../../../../core/context/containers/FormWizard.container";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { useUserState } from "../../../../core/context/containers/User.container";
import GQLService from "../../../../core/services/GQL.service";
import { User } from "../../../../types";

export function AddGroups() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedUsers, setSelectedUsers } = useUserState();
  const { selectedProperty } = usePropertiesState();
  const { startDate, endDate, setStartDate, setEndDate } = useGroupState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [formDataState, setFormDataState] = useState<Group | undefined>(
    undefined
  );

  const [addGroup] = useMutation(GQLService.mutations.addGroup, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allProperties,
      },
    ],
    awaitRefetchQueries: true,
  });

  const { reset } = useFormWizard();
  const { setFormData } = useFormWizardState();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      reset();
      setFormData({});
      setStartDate("");
      setEndDate("");
      setSelectedUsers([]);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = useCallback(
    async (formData: any) => {
      const { groupName, groupStatus } = formData;
      let groups;
      try {
        groups = await addGroup({
          variables: {
            name: groupName,
            active: groupStatus,
            users: (selectedUsers as User[]).map((user) => user.id!),
            propertyId: selectedProperty?.id,
            startDate,
            lastDate: endDate,
          },
        });
        enqueueSnackbar(
          formatMessage({
            id: "addGroup.snackbar.success",
            defaultMessage: "Group added successfully",
          }),
          {
            variant: "success",
          }
        );
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: "error",
          persist: true,
        });
      }
      history.push(ROUTES.GROUPS);
    },
    [
      addGroup,
      endDate,
      enqueueSnackbar,
      formatMessage,
      selectedProperty?.id,
      selectedUsers,
      startDate,
    ]
  );

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addGroup.header",
          defaultMessage: "Add New Group",
          description: "add group label",
        })}
      />
      <FormWizard
        steps={AddGroupsFormWizardFieldset}
        onFulfill={handleSubmit}
      />
    </div>
  );
}
