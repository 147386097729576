import React, { Fragment, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { ROUTES } from "../../../../common/constants/Routing";
import GQLService from "../../../../core/services/GQL.service";
import { PageHeader } from "../../../../common/components";
import { AddPropertyFormWizardFieldset } from "./Add.form";
import { FormWizard } from "../../../../common/components";
import { useFormWizard } from "../../../../core/context/functions/FormWizard.functions";
import { useFormWizardState } from "../../../../core/context/containers/FormWizard.container";
import { useSnackbar } from "notistack";
import API from "../../../../core/api/api";
import { DEFAULT_SENSOR_RANGES } from "../components/SensorRange/SensorRange";
import { toCelcius } from "../../../../common/utils/General.utils";
import { useSensorsState } from "../../../../core/context/containers/Sensors.contrainer";

export function AddProperty() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [createProperty] = useMutation(GQLService.mutations.addProperty, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allProperties,
      },
    ],
    awaitRefetchQueries: true,
  });
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { reset } = useFormWizard();
  const { setFormData } = useFormWizardState();
  const { tempUnit, celsius } = useSensorsState();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      reset();
      setFormData({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(
    () => {
      return () => {
        reset();
      };
    },
    [] //eslint-disable-line
  );

  const handleSubmit = useCallback(
    async (formData: any) => {
      const {
        photoCoverUrl,
        logoUrl,
        sensorConfigurations,
        ...formValues
      } = formData;
      let properties;
      const reservationConfiguration = {
        passExpirationGracePeriodMinutes:
          formValues.passExpirationGracePeriodMinutes,
        passGracePeriodMinutes: formValues.passGracePeriodMinutes,
        schedulingSlotMinutes: formValues.schedulingSlotMinutes,
        maxAheadTimeOfReservationHours:
          formValues.maxAheadTimeOfReservationHours,
        maxSchedulability: formValues.maxSchedulability,
        maxReservationsPerUser: formValues.maxReservationsPerUser,
      };

      let spaceLoadMediumMin = sensorConfigurations.OCCUPANCY[1];
      let spaceLoadMediumMax = sensorConfigurations.OCCUPANCY[2];
      const { OCCUPANCY, ...allSensorConfigs } = sensorConfigurations;
      // map sensor configuration to request object.
      const configs = Object.keys(allSensorConfigs).map((attribute) => {
        let values = allSensorConfigs[attribute] as number[];
        if (attribute === "TEMPERATURE" && tempUnit === "farenheit") {
          values = values.map((value) => toCelcius(value));
        }

        const indicators = DEFAULT_SENSOR_RANGES[attribute].indicators;
        const results = values.map((value, index) => {
          if (index < values.length - 1) {
            return {
              minVal: value,
              maxVal: values[index + 1],
              attribute,
              sensorHealthIndicator: indicators[index],
              isCelsius: attribute === "TEMPERATURE" ? celsius : null,
            };
          }
          return undefined;
        });
        results.pop();
        return results.filter(
          (result) => result?.sensorHealthIndicator !== "NONE"
        );
      });
      const elevatorReservationConfiguration = formValues.upNext
        ? null //reservationConfiguration
        : null;

      let siteNrOfUndergroundFloors;
      if (formData.hasOwnProperty("siteNrOfUndergroundFloors")) {
        siteNrOfUndergroundFloors = formValues.siteNrOfUndergroundFloors;
      } else {
        siteNrOfUndergroundFloors = 0;
      }

      try {
        properties = await createProperty({
          variables: {
            ...formValues,
            elevatorReservationConfiguration,
            sensorConfigurations: configs.flat(),
            upNext: formValues.upNext,
            siteNrOfUndergroundFloors: siteNrOfUndergroundFloors,
            spaceLoadMediumMax,
            spaceLoadMediumMin,
          },
        });
        enqueueSnackbar(
          formatMessage({
            id: "addProperty.snackbar.success",
            defaultMessage: "Property added successfully",
          }),
          {
            variant: "success",
          }
        );
      } catch (e: any) {
        enqueueSnackbar(e.message, {
          variant: "error",
          persist: true,
        });
      }
      const fdLogo = new FormData();
      const fdCover = new FormData();
      if (logoUrl) {
        fdLogo.append("data", logoUrl.data);
        await API.post(
          `properties/${properties?.data.createProperty.id}/logo`,
          fdLogo
        );
      }
      if (photoCoverUrl) {
        fdCover.append("data", photoCoverUrl.data);
        await API.post(
          `properties/${properties?.data.createProperty.id}/image`,
          fdCover
        );
      }

      history.push(ROUTES.PROPERTIES);
    },
    [createProperty]
  );

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addProperty.header",
          defaultMessage: "Add a property",
          description: "add property label",
        })}
      />
      <FormWizard
        steps={AddPropertyFormWizardFieldset}
        onFulfill={handleSubmit}
      />
    </div>
  );
}
