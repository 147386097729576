import React from "react";
import {
  ActivitySwitch,
  Input,
} from "../../../../common/components/Form/FormFields";

import { FormField } from "../../../../types/form";

const FIELD_NAMES = {
  description: "description",
  ignore: "ignore",
};

export const createFormFields = (): FormField[] => [
  {
    name: FIELD_NAMES.description,
    label: "Device Description",
    placeholder: "Type here",
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.ignore,
    label: "Ignore",
    placeholder: "Type here",
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: ActivitySwitch,
  },
];
