import * as Yup from "yup";
import { FormFieldInputType, FormWizardSteps } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";
import {
  ActivitySwitch,
  PropertyDateFormatSelect,
  HourSelectTZ,
  Input,
  Select,
  SpaceLoad,
} from "../../../../common/components/Form/FormFields";
import { SkipFloorCheckbox } from "../../../../common/components/Form/FormFields/SkipFloorCheckbox/SkipFloorCheckbox";
import moment from "moment";
import "moment-timezone";

enum FIELD_NAMES {
  ownerCompanyName = "ownerCompanyName",
  // accountNumber = "companyAccountNumber",
  companyStatus = "companyStatus",
  primaryContactFullName = "primaryContactFullName",
  email = "primaryContactEmailAddress",
  phone = "primaryContactPhoneNumber",
  siteName = "siteName",
  serviceAddress = "siteServiceAddress",
  numberOfFloors = "siteNrOfFloors",
  sitePropertyType = "sitePropertyType",
  // accountStatus = "accountStatus",
  // accountBusinessEntity = "accountBusinessEntity",
  isBusinessEntityActive = "isBusinessEntityActive",
  // companyWebsite = "companyWebsite",
  siteServiceAddress = "siteServiceAddress",
  logoUrl = "logoUrl",
  photoCoverUrl = "photoCoverUrl",
  spaceLoadMediumMax = "spaceLoadMediumMax",
  spaceLoadMediumMin = "spaceLoadMediumMin",
  workingFrom = "workingFrom",
  workingTo = "workingTo",
  timeZone = "timeZone",
  propertyDateFormat = "propertyDateFormat",
  use24HourClock = "use24HourClock",
  ntopngDatabaseName = "ntopngDatabaseName",
  // minimalProbesCount = "minimalProbesCount",
  schedulingSlotMinutes = "schedulingSlotMinutes",
  passGracePeriodMinutes = "passGracePeriodMinutes",
  passExpirationGracePeriodMinutes = "passExpirationGracePeriodMinutes",
  maxSchedulability = "maxSchedulability",
  maxAheadTimeOfReservationHours = "maxAheadTimeOfReservationHours",
  elevatorReservationConfiguration = "elevatorReservationConfiguration",
  maxReservationsPerUser = "maxReservationsPerUser",
  upNext = "upNext",
  numberOfUndergroundFloors = "siteNrOfUndergroundFloors",
  floor0Excluded = "floor0Excluded",
  sensorConfigurations = "sensorConfigurations",
}

const slotLengths = [
  { id: 5, name: 5 },
  { id: 10, name: 10 },
  { id: 15, name: 15 },
  { id: 20, name: 20 },
  { id: 30, name: 30 },
  { id: 60, name: 60 },
];

export interface PhotoFile {
  name: string | undefined;
  url: string | undefined;
  data: File | undefined;
}

export const AddPropertyFormWizardFieldset: FormWizardSteps = [
  {
    name: "Business Entity",
    fieldset: [
      {
        label: "Company Information",
        fields: [
          {
            name: FIELD_NAMES.ownerCompanyName,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.legalCompanyName",
              defaultMessage: "Owner Company Name",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.legalCompanyName",
              defaultMessage: "Owner Company name",
            }),
            required: true,
            gridStyle: { sm: 12, md: 9 },
            Component: Input,
            resetField: true,
          },
          // {
          //   name: FIELD_NAMES.accountNumber,
          //   label: I18n.formatMessage({
          //     id: "addPropertyFormFields.label.accountNumber",
          //     defaultMessage: "Account number",
          //   }),
          //   placeholder: I18n.formatMessage({
          //     id: "addPropertyFormFields.placeholder.accountNumber",
          //     defaultMessage: "Type here",
          //   }),
          //   gridStyle: { sm: 12, md: 9 },
          //   Component: Input,
          //   required: false,
          //   resetField: true,
          // },
          // {
          //   name: FIELD_NAMES.accountBusinessEntity,
          //   label: I18n.formatMessage({
          //     id: "addPropertyFormFields.label.accountBusinessEntity",
          //     defaultMessage: "Account Business Entity",
          //   }),
          //   placeholder: I18n.formatMessage({
          //     id: "addPropertyFormFields.placeholder.accountBusinessEntity",
          //     defaultMessage: "Type here",
          //   }),
          //   gridStyle: { sm: 12, md: 9 },
          //   Component: Input,
          //   required: false,
          //   resetField: true,
          // },
          {
            name: FIELD_NAMES.companyStatus,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.companyStatus",
              defaultMessage: "Status",
            }),
            required: false,
            Component: ActivitySwitch,
          },
        ],
        validationSchema: getCompanyInfoValidationSchema(),
        defaultValues: {
          [FIELD_NAMES.companyStatus]: true,
          // [FIELD_NAMES.accountStatus]: true,
          [FIELD_NAMES.use24HourClock]: true,
          [FIELD_NAMES.upNext]: false,
          [FIELD_NAMES.floor0Excluded]: false,
          [FIELD_NAMES.sensorConfigurations]: {
            OCCUPANCY: [0, 5, 10, 50],
            TEMPERATURE: [0, 54.7, 65.5, 79.9, 88.7, 200],
            HUMIDITY: [0, 15.5, 39.5, 50.5, 80.5, 200],
            CO2: [400, 600.5, 2500.5, 5000],
            TVOCS: [0, 333.5, 8332.5, 12000],
            PM: [0, 15.5, 75.5, 200],
            LIGHT: [0, 100, 200, 1000, 2000, 2200],
            NOISE: [0, 60, 85, 200],
          },
        },
      },
      {
        label: "Primary Contact",
        fields: [
          {
            name: FIELD_NAMES.primaryContactFullName,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.fullName",
              defaultMessage: "Full Name",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.fullName",
              defaultMessage: "Type here",
            }),
            required: false,
            gridStyle: { sm: 8, md: 7 },
            Component: Input,
            resetField: true,
          },
          {
            name: FIELD_NAMES.email,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.email",
              defaultMessage: "Email Address",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.email",
              defaultMessage: "Type here",
            }),
            required: false,
            gridStyle: { sm: 8, md: 7 },
            Component: Input,
            resetField: true,
          },
          // {
          //   name: FIELD_NAMES.companyWebsite,
          //   label: I18n.formatMessage({
          //     id: "addPropertyFormFields.label.website",
          //     defaultMessage: "Website",
          //   }),
          //   placeholder: I18n.formatMessage({
          //     id: "addPropertyFormFields.placeholder.website",
          //     defaultMessage: "Type here",
          //   }),
          //   required: false,
          //   gridStyle: { sm: 8, md: 7 },
          //   Component: Input,
          //   resetField: true,
          // },
          {
            name: FIELD_NAMES.workingFrom,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.working",
              defaultMessage: "Working Hours",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.website",
              defaultMessage: "Timezone",
            }),
            required: true,
            gridStyle: { sm: 8, md: 8 },
            //timeZoneOptions : moment.tz.names().map((i) => ({title: i, name: i})),
            Component: HourSelectTZ,
          },
          {
            name: FIELD_NAMES.propertyDateFormat,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.propertyDateFormat",
              defaultMessage: "Property Date Format",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.propertyDateFormat",
              defaultMessage: "None",
            }),
            required: true,
            gridStyle: { sm: 8, md: 7 },
            Component: PropertyDateFormatSelect,
          },
          {
            name: FIELD_NAMES.use24HourClock,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.use24HourClock",
              defaultMessage: "Use 24 Hour Clock",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.use24HourClock",
              defaultMessage: "None",
            }),
            required: true,
            gridStyle: { sm: 8, md: 7 },
            Component: ActivitySwitch,
          },
          {
            name: FIELD_NAMES.phone,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.phone",
              defaultMessage: "Phone Number",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.phone",
              defaultMessage: "Type here",
            }),
            required: false,
            gridStyle: { sm: 8, md: 7 },
            Component: Input,
            resetField: true,
          },
          // {
          //   name: FIELD_NAMES.accountStatus,
          //   label: I18n.formatMessage({
          //     id: "addPropertyFormFields.label.accountStatus",
          //     defaultMessage: "Status",
          //   }),
          //   required: false,
          //   Component: ActivitySwitch,
          // },
        ],
        validationSchema: getPrimaryContactValidationSchema(),
        defaultValues: {
          // [FIELD_NAMES.accountStatus]: true,
          [FIELD_NAMES.companyStatus]: true,
          [FIELD_NAMES.use24HourClock]: true,
        },
      },
    ],
  },
  {
    name: "Location Details",
    fieldset: [
      {
        label: "Site Information",
        fields: [
          {
            name: FIELD_NAMES.siteName,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.siteName",
              defaultMessage: "Site name",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.siteName",
              defaultMessage: "Type here",
            }),
            required: true,
            Component: Input,
            resetField: true,
          },
          {
            name: FIELD_NAMES.sitePropertyType,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.propertyType",
              defaultMessage: "Property type",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.propertyType",
              defaultMessage: "Type here",
            }),
            required: true,
            Component: Input,
            resetField: true,
          },
          {
            name: FIELD_NAMES.serviceAddress,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.serviceAddress",
              defaultMessage: "Service Address",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.serviceAddress",
              defaultMessage: "Type here",
            }),
            required: false,
            Component: Input,
            resetField: true,
          },
          {
            name: FIELD_NAMES.numberOfFloors,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.numberOfFloors",
              defaultMessage: "Number of floors above ground level",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.numberOfFloors",
              defaultMessage: "Type here",
            }),
            required: true,
            type: FormFieldInputType.number,
            gridStyle: { sm: 12, md: 2 },
            Component: Input,
            fullWidth: false,
          },
          {
            name: FIELD_NAMES.floor0Excluded,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.numberOfFloors",
              defaultMessage: " ",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.numberOfFloors",
              defaultMessage: " ",
            }),
            required: false,
            gridStyle: { sm: 12, md: 12 },
            Component: SkipFloorCheckbox,
            fullWidth: false,
          },
          {
            name: FIELD_NAMES.numberOfUndergroundFloors,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.numberOfFloors",
              defaultMessage: "Number of underground floors",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.numberOfFloors",
              defaultMessage: "Type here",
            }),
            type: FormFieldInputType.number,
            gridStyle: { sm: 4, md: 2 },
            Component: Input,
            fullWidth: false,
          },
          {
            name: FIELD_NAMES.upNext,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.companyStatus",
              defaultMessage: "UpNext User",
            }),
            required: false,
            Component: ActivitySwitch,
          },
        ],
        validationSchema: getSiteInfoValidationSchema(),
        defaultValues: {
          [FIELD_NAMES.upNext]: false,
        },
      },
    ],
  },
  {
    name: "Workplace Settings",
    fieldset: [
      {
        label: "Company Logo",
        fields: [
          {
            name: FIELD_NAMES.logoUrl,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.logo",
              defaultMessage: "Upload Your Logo",
            }),
            type: FormFieldInputType.file,
            Component: Input,
          },
        ],

        validationSchema: getImagesValidationSchema(),
        defaultValues: {
          [FIELD_NAMES.logoUrl]: {
            url: undefined,
            data: undefined,
            name: undefined,
          },
        },
      },
      {
        label: "Cover Photo",
        fields: [
          {
            name: FIELD_NAMES.photoCoverUrl,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.coverPhoto",
              defaultMessage: "Upload Your Cover Photo",
            }),
            type: FormFieldInputType.file,
            Component: Input,
          },
        ],

        validationSchema: getImagesValidationSchema(),
        defaultValues: {
          [FIELD_NAMES.photoCoverUrl]: {
            url: undefined,
            data: undefined,
            name: undefined,
          },
        },
      },
    ],
  },
  {
    name: "Sensor Thresholds",
    fieldset: [
      {
        fields: [
          {
            name: FIELD_NAMES.sensorConfigurations,
            label: "",
            Component: SpaceLoad,
          },
          {
            name: FIELD_NAMES.ntopngDatabaseName,
            label: I18n.formatMessage({
              id: "addPropertyFormFields.label.ntopngDatabaseName",
              defaultMessage: "Ntopng database name",
            }),
            placeholder: I18n.formatMessage({
              id: "addPropertyFormFields.placeholder.ntopngDatabaseName",
              defaultMessage: "Ntopng database name",
            }),
            gridStyle: { sm: 8, md: 7 },
            Component: Input,
          },
          // {
          //   name: FIELD_NAMES.minimalProbesCount,
          //   label: I18n.formatMessage({
          //     id: "addPropertyFormFields.label.minimalProbesCount",
          //     defaultMessage: "Minimal Probes Count",
          //   }),
          //   placeholder: I18n.formatMessage({
          //     id: "addPropertyFormFields.placeholder.minimalProbesCount",
          //     defaultMessage: "Type here",
          //   }),
          //   gridStyle: { sm: 8, md: 7 },
          //   Component: Input,
          //   required: true,
          // },
        ],
        // validationSchema: getSpaceLoadValidationSchema(),
      },
    ],
    styles: { spacing: 1 },
  },
  // Removed as mentioned in NET-2873
  // {
  //   name: "Reservations Settings",
  //   fieldset: [
  //     {
  //       label: "",
  //       validationSchema: getReservationSettingsSchema(),
  //       fields: [
  //         {
  //           name: FIELD_NAMES.schedulingSlotMinutes,
  //           label: I18n.formatMessage({
  //             id: "addPropertyFormFields.label.schedulingSlotMinutes",
  //             defaultMessage: "Slot time in minutes",
  //           }),
  //           placeholder: I18n.formatMessage({
  //             id: "addPropertyFormFields.placeholder.schedulingSlotMinutes",
  //             defaultMessage: "Slot time in minutes",
  //           }),
  //           type: FormFieldInputType.number,
  //           required: true,
  //           gridStyle: { sm: 8, md: 7 },
  //           Component: Select,
  //           data: slotLengths,
  //         },
  //         {
  //           name: FIELD_NAMES.passExpirationGracePeriodMinutes,
  //           label: I18n.formatMessage({
  //             id:
  //               "addPropertyFormFields.label.passExpirationGracePeriodMinutes",
  //             defaultMessage:
  //               "Pass expiration grace period before entering elevator bay",
  //           }),
  //           placeholder: I18n.formatMessage({
  //             id:
  //               "addPropertyFormFields.placeholder.passExpirationGracePeriodMinutes",
  //             defaultMessage:
  //               "Pass expiration grace period before entering elevator bay",
  //           }),
  //           type: FormFieldInputType.number,
  //           required: true,
  //           gridStyle: { sm: 8, md: 7 },
  //           Component: Input,
  //         },
  //         {
  //           name: FIELD_NAMES.passGracePeriodMinutes,
  //           label: I18n.formatMessage({
  //             id: "addPropertyFormFields.label.passGracePeriodMinutes",
  //             defaultMessage:
  //               "Additional expiration time after entering elevator bay",
  //           }),
  //           placeholder: I18n.formatMessage({
  //             id: "addPropertyFormFields.placeholder.passGracePeriodMinutes",
  //             defaultMessage:
  //               "Additional expiration time after entering elevator bay",
  //           }),
  //           type: FormFieldInputType.number,
  //           required: true,
  //           gridStyle: { sm: 8, md: 7 },
  //           Component: Input,
  //         },
  //         {
  //           name: FIELD_NAMES.maxSchedulability,
  //           label: I18n.formatMessage({
  //             id: "addPropertyFormFields.label.maxSchedulability",
  //             defaultMessage: "Max schedulability (%) for reservations",
  //           }),
  //           placeholder: I18n.formatMessage({
  //             id: "addPropertyFormFields.placeholder.maxSchedulability",
  //             defaultMessage: "Max schedulability",
  //           }),
  //           type: FormFieldInputType.number,
  //           required: true,
  //           gridStyle: { sm: 8, md: 7 },
  //           Component: Input,
  //         },
  //         {
  //           name: FIELD_NAMES.maxAheadTimeOfReservationHours,
  //           label: I18n.formatMessage({
  //             id: "addPropertyFormFields.label.maxAheadTimeOfReservationHours",
  //             defaultMessage:
  //               "Max ahead time of reservation avaialable for claiming (in hours)",
  //           }),
  //           placeholder: I18n.formatMessage({
  //             id:
  //               "addPropertyFormFields.placeholder.maxAheadTimeOfReservationHours",
  //             defaultMessage: "Max ahead time of reservation",
  //           }),
  //           type: FormFieldInputType.number,
  //           required: true,
  //           gridStyle: { sm: 8, md: 7 },
  //           Component: Input,
  //         },
  //         {
  //           name: FIELD_NAMES.maxReservationsPerUser,
  //           label: I18n.formatMessage({
  //             id: "addPropertyFormFields.label.maxAheadTimeOfReservationHours",
  //             defaultMessage: "Maximum Number Of Reservations Per User",
  //           }),
  //           placeholder: I18n.formatMessage({
  //             id:
  //               "addPropertyFormFields.placeholder.maxAheadTimeOfReservationHours",
  //             defaultMessage: "Maximum Number Of Reservations Per User",
  //           }),
  //           type: FormFieldInputType.number,
  //           required: true,
  //           gridStyle: { sm: 8, md: 7 },
  //           Component: Input,
  //         },
  //       ],
  //     },
  //   ],
  //   styles: { spacing: 1 },
  // },
];

function getCompanyInfoValidationSchema() {
  return Yup.object().shape({
    [FIELD_NAMES.ownerCompanyName]: Yup.string()
      .min(
        6,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.legalCompanyName.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.legalCompanyName.long",
          defaultMessage: "Too long!",
        })
      )
      .required(" "),
    // [FIELD_NAMES.accountNumber]: Yup.string().optional(),
    // [FIELD_NAMES.accountBusinessEntity]: Yup.string(),
    [FIELD_NAMES.companyStatus]: Yup.boolean(),
  });
}

function getImagesValidationSchema() {
  return Yup.object().shape({
    [FIELD_NAMES.logoUrl]: Yup.mixed().test(
      "fileSize",
      "File is too large",
      (value) => {
        if (value?.data?.size >= 2097152) {
          return false;
        }
        return true;
      }
    ),
  });
}

function getPrimaryContactValidationSchema() {
  return Yup.object().shape({
    [FIELD_NAMES.primaryContactFullName]: Yup.string()
      .min(
        6,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.fullName.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.fullName.long",
          defaultMessage: "Too long!",
        })
      )
      .optional(),
    [FIELD_NAMES.email]: Yup.string()
      .email(
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.email.notValid",
          defaultMessage: "Email address must be a valid email",
        })
      )
      .min(
        6,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.email.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.email.long",
          defaultMessage: "Too long!",
        })
      )
      .optional(),
    // [FIELD_NAMES.companyWebsite]: Yup.string()
    //   .min(
    //     6,
    //     I18n.formatMessage({
    //       id: "addPropertyFormFields.validation.email.short",
    //       defaultMessage: "Too short!",
    //     })
    //   )
    //   .max(
    //     50,
    //     I18n.formatMessage({
    //       id: "addPropertyFormFields.validation.email.long",
    //       defaultMessage: "Too long!",
    //     })
    //   )
    //   .optional(),
    [FIELD_NAMES.phone]: Yup.string()
      .min(
        6,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.phone.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.phone.long",
          defaultMessage: "Too long!",
        })
      )
      .optional(),
    // [FIELD_NAMES.accountStatus]: Yup.boolean(),
    [FIELD_NAMES.workingFrom]: Yup.string().test(
      "oneOfRequired",
      I18n.formatMessage({
        id: "workingHoursSelect.validation.fields.required",
        defaultMessage: "Working hours and Timezone have to be filled.",
      }),
      function () {
        return (
          this.parent.workingFrom &&
          this.parent.workingTo &&
          this.parent.timeZone
        );
      }
    ),
    [FIELD_NAMES.propertyDateFormat]: Yup.string().test(
      "oneOfRequired",
      I18n.formatMessage({
        id: "workingHoursSelect.validation.fields.required",
        defaultMessage: "Property Date Format have to be filled.",
      }),
      function () {
        return this.parent.propertyDateFormat;
      }
    ),
    [FIELD_NAMES.use24HourClock]: Yup.boolean(),
  });
}

function getSiteInfoValidationSchema() {
  return Yup.object().shape({
    [FIELD_NAMES.siteName]: Yup.string()
      .min(
        1,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.siteName.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.siteName.long",
          defaultMessage: "Too long!",
        })
      )
      .required(" "),
    [FIELD_NAMES.sitePropertyType]: Yup.string().required(" "),
    [FIELD_NAMES.serviceAddress]: Yup.string()
      .min(
        6,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.serviceAddress.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.serviceAddress.long",
          defaultMessage: "Too long!",
        })
      )
      .optional(),
    [FIELD_NAMES.numberOfUndergroundFloors]: Yup.number()
      .typeError("You must specify a number")
      .min(
        0,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.undergroundFloorsNumber.nr",
          defaultMessage: "Number of floors can't be lower than 0",
        })
      ),
    [FIELD_NAMES.numberOfFloors]: Yup.number()
      .typeError("You must specify a number")
      .min(
        1,
        I18n.formatMessage({
          id: "addPropertyFormFields.validation.nrOfFloors.nr",
          defaultMessage: "Floors number has to be greater or equal 1",
        })
      )
      .required(" "),
    [FIELD_NAMES.sitePropertyType]: Yup.string().min(1).max(50).required(" "),
  });
}

// function getSpaceLoadValidationSchema() {
//   return Yup.object().shape({
//     [FIELD_NAMES.spaceLoadMediumMin]: Yup.number()
//       .min(
//         1,
//         I18n.formatMessage({
//           id: "addPropertyFormFields.validation.spaceLoadMediumMin.zero",
//           defaultMessage: "Low must be more than 0!",
//         })
//       )
//       .lessThan(
//         Yup.ref(FIELD_NAMES.spaceLoadMediumMax),
//         "High must be more than Low!"
//       )
//       .required(" "),
//     [FIELD_NAMES.spaceLoadMediumMax]: Yup.number()
//       .moreThan(
//         Yup.ref(FIELD_NAMES.spaceLoadMediumMin),
//         "High must be more than Low!"
//       )
//       .required(" "),
//     // [FIELD_NAMES.minimalProbesCount]: Yup.number().min(1).required(" "),
//   });
// }

// Removed as mentioned in NET-2873
// function getReservationSettingsSchema() {
//   return Yup.object().shape({
//     [FIELD_NAMES.schedulingSlotMinutes]: Yup.number().test(
//       "oneOfRequired",
//       I18n.formatMessage({
//         id: "workingHoursSelect.validation.fields.required",
//         defaultMessage: "All fields have to be filled",
//       }),
//       function () {
//         if (this.parent.upNext) {
//           return this.parent.schedulingSlotMinutes > 0;
//         } else {
//           return true;
//         }
//       }
//     ),
//     [FIELD_NAMES.passGracePeriodMinutes]: Yup.number().test(
//       "oneOfRequired",
//       I18n.formatMessage({
//         id: "workingHoursSelect.validation.fields.required",
//         defaultMessage: "All fields have to be filled",
//       }),
//       function () {
//         if (this.parent.upNext) {
//           return this.parent.passGracePeriodMinutes > 0;
//         } else {
//           return true;
//         }
//       }
//     ),
//     [FIELD_NAMES.maxAheadTimeOfReservationHours]: Yup.number().test(
//       "oneOfRequired",
//       I18n.formatMessage({
//         id: "workingHoursSelect.validation.fields.required",
//         defaultMessage: "All fields have to be filled",
//       }),
//       function () {
//         if (this.parent.upNext) {
//           return this.parent.maxAheadTimeOfReservationHours > 0;
//         } else {
//           return true;
//         }
//       }
//     ),
//     [FIELD_NAMES.passExpirationGracePeriodMinutes]: Yup.number().test(
//       "oneOfRequired",
//       I18n.formatMessage({
//         id: "workingHoursSelect.validation.fields.required",
//         defaultMessage: "All fields have to be filled",
//       }),
//       function () {
//         if (this.parent.upNext) {
//           return this.parent.passExpirationGracePeriodMinutes > 0;
//         } else {
//           return true;
//         }
//       }
//     ),
//     [FIELD_NAMES.maxSchedulability]: Yup.number().test(
//       "oneOfRequired",
//       I18n.formatMessage({
//         id: "workingHoursSelect.validation.fields.required",
//         defaultMessage: "All fields have to be filled",
//       }),
//       function () {
//         if (this.parent.upNext) {
//           return this.parent.maxSchedulability > 0;
//         } else {
//           return true;
//         }
//       }
//     ),
//     [FIELD_NAMES.maxReservationsPerUser]: Yup.number().test(
//       "oneOfRequired",
//       I18n.formatMessage({
//         id: "workingHoursSelect.validation.fields.required",
//         defaultMessage: "All fields have to be filled",
//       }),
//       function () {
//         if (this.parent.upNext) {
//           return this.parent.maxReservationsPerUser > 0;
//         } else {
//           return true;
//         }
//       }
//     ),
//   });
// }
