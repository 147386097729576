import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useSpacesState } from "../../../../core/context/containers/Spaces.container";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import {
  Button,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import { PageHeader } from "../../../../common/components";
import { Role, Space } from "../../../../types";
import { ElementView } from "./elementView";
import GQLService from "../../../../core/services/GQL.service";
import { useLazyQuery } from "@apollo/client";
import Loader from "react-loader-spinner";
import { COLORS } from "../../../../common/styles/colors";
import { ROUTES } from "../../../../common/constants/Routing";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  statusLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    margin: "0 16px",
  },
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 90,
    height: 40,
    borderRadius: 30,
    display: "flex",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.common.dustyGray,
      textDecoration: "none",
    },
  },
  swipePanel: {
    marginBottom: 100,
  },
  fullWidth: {
    width: "100%",
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  activeIndicator: {
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  activeLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.dustyGray,
    padding: "5px 0",
  },
  blackHeader: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    marginBottom: theme.spacing(5),
    lineHeight: theme.typography.pxToRem(24),
  },
  marginTop: {
    marginTop: theme.typography.pxToRem(65),
  },
  detailsLabel: {
    color: theme.palette.common.solidGray,
  },
  headerInfo: {
    marginTop: theme.typography.pxToRem(25),
  },
  switch: {
    color: `${theme.palette.common.switchBlue} !important`,
    border: `1px solid ${theme.palette.common.switchBlue}`,
    borderRadius: "30px;",
    height: 17,
    width: 52,
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `#2F56A1 !important`,
    width: 12,
    height: 12,
  },
  checked: {
    color: "transparent !important",
  },
  base: {
    height: 22,
  },
  error: {
    border: `1px solid ${theme.palette.common.cinnabar}`,
    borderRadius: "30px;",
    color: `${theme.palette.common.switchBlue} !important`,
    height: 17,
    width: 52,
  },
  status: {
    color: "#122036",
    marginLeft: 8,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 3,
  },
}));

export function SpacesView() {
  const classes = useStyles();
  const { selectedSpace } = useSpacesState();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const auth = useAuth();
  const [selectedItem, setSelectedItem] = useState<Space>();
  const { selectedProperty } = usePropertiesState();

  const handleEditClick = useCallback(() => {
    history.push(
      ROUTES.SPACE_EDIT.replace(":uid", selectedProperty?.id!).replace(
        ":spaceId",
        selectedSpace?.id.split(":")[1]!
      )
    );
  }, [selectedSpace]);

  const createSpaceButton = useCallback(() => {
    return (
      <Button className={classes.editBtn} onClick={handleEditClick}>
        Edit
      </Button>
    );
  }, []);

  const [getSpaceById, { data }] = useLazyQuery(GQLService.queries.spaceById, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    getSpaceById({ variables: { spaceId: selectedSpace?.id } });
  }, [selectedSpace, getSpaceById]);

  useEffect(() => {
    if (data?.spaceById) {
      setSelectedItem(data?.spaceById);
    }
  }, [data, selectedItem]);

  const _assignAccessPoints =
    selectedItem?.accessPoints.length == 0
      ? " - "
      : selectedItem?.accessPoints.reduce((acc, val) => {
          if (val.name) {
            acc = `${acc} ${val.name},`;
          }
          return acc;
        }, "");

  const _assignZones =
    selectedItem?.zones.length == 0
      ? " - "
      : selectedItem?.zones.reduce((acc, val) => {
          if (val.name) {
            acc = acc + (val.name ? `${val.name}, ` : `${val.zone}, `);
          }
          return acc;
        }, "");

  const _assignAQSensors =
    selectedItem?.airSensors.length == 0
      ? " - "
      : selectedItem?.airSensors.reduce((acc, val) => {
          if (val.name) {
            acc = `${acc} ${val.name},`;
          }
          return acc;
        }, "");

  const _assignTripwires =
    selectedItem?.tripWires.length == 0
      ? " - "
      : selectedItem?.tripWires.reduce((acc, val) => {
          if (val.name || val.tripWire) {
            acc = acc + (val.name ? `${val.name}, ` : `${val.tripWire}, `);
          }
          return acc;
        }, "");

  const _assignDoors =
    selectedItem?.doors.length == 0
      ? " - "
      : selectedItem?.doors.reduce((acc, val) => {
          if (val.name) {
            acc = `${acc} ${val.name},`;
          }
          return acc;
        }, "");

  const _assignSsids = () => {
    if (selectedItem?.ssids?.length === 0) {
      return "-";
    } else {
      const filteredData = selectedItem?.ssids?.filter(
        (item) => item.active === true
      );
      return filteredData?.length === 0
        ? "-"
        : filteredData?.reduce((acc, val) => {
            if (val.name) {
              acc = `${acc} ${val.name},`;
            }
            return acc;
          }, "");
    }
  };

  const _getTimeIn24Hour = () => {
    if (selectedItem?.resetTime) {
      return moment(selectedItem?.resetTime, "HH:mm").format("hh:mm A");
    } else {
      return "-";
    }
  };
  if (selectedItem) {
    return (
      <Grid container={true} direction="row" alignItems="center">
        <Grid item={true} className={classes.fullWidth}>
          <Grid className={classes.header}>
            <PageHeader
              title={formatMessage({
                id: "space.details.header",
                defaultMessage: `${selectedSpace?.name}`,
                description: "Space details header",
              })}
            />
          </Grid>
          <Grid container>
            <Grid item md={6}>
              <ElementView
                content={selectedItem?.name ? selectedItem?.name : "-"}
                name={"Space Name"}
              />
              <ElementView
                content={
                  selectedItem?.floor?.name
                    ? selectedItem?.floor?.name
                    : selectedItem?.floor?.floorNumber
                    ? `${selectedItem?.floor?.floorNumber}`
                    : "-"
                }
                name={"Floor"}
              />
            </Grid>
            <Grid item md={6}>
              <ElementView
                content={selectedItem?.area ? `${selectedItem?.area}` : "-"}
                name={"Space Capacity"}
              />
              <ElementView
                content={
                  selectedItem?.commonSpace?.name
                    ? `${selectedItem?.commonSpace?.name}`
                    : "None"
                }
                name={"Space Type"}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={6}>
              <ElementView
                content={_assignAccessPoints}
                name={"Assign Access Points"}
              />
              <ElementView
                content={_assignAQSensors}
                name={"Assign Air Quality Sensors"}
              />
            </Grid>
            <Grid item md={6}>
              <ElementView content={_assignSsids!()} name={"Assign SSIDs"} />
              <ElementView content={_assignDoors} name={"Assign Doors"} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={6}>
              <ElementView content={_assignZones} name={"Assign Zones"} />
            </Grid>
            <Grid item md={6}>
              <ElementView
                content={_assignTripwires}
                name={"Assign Tripwires"}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={6}>
              <ElementView
                content={selectedItem?.isNetflowActive ? "Tripwires" : "-"}
                name={"Space is Fully Covered by"}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={6} className={classes.activeIndicator}>
              <ElementView content={_getTimeIn24Hour()} name={"Reset at"} />
              <Typography className={classes.activeLabel}>Status</Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Switch
                  disabled={true}
                  name={"Status"}
                  checked={selectedItem?.active}
                  classes={{
                    root: classes.switch,
                    checked: classes.checked,
                    thumb: classes.thumb,
                    track: classes.track,
                    switchBase: classes.base,
                  }}
                />
                <Typography className={classes.statusLabel}>
                  {selectedItem?.active
                    ? formatMessage({
                        id: "statusIndicator.active",
                        defaultMessage: "Active",
                        description: "status indicator active",
                      })
                    : formatMessage({
                        id: "statusIndicator.inactive",
                        defaultMessage: "Inactive",
                        description: "status indicator inactive",
                      })}
                </Typography>
              </div>
              <br />
              {auth?.canSee(
                auth?.user?.role?.toUpperCase() as Role,
                "spaces:edit",
                createSpaceButton()
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Loader type="Oval" color={COLORS.funBlue} height={100} width={100} />
    );
  }
}
