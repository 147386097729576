import React, { useState, useCallback, Fragment } from "react";
import {
  Menu,
  MenuItem,
  MenuList,
  makeStyles,
  Button,
} from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { useProperties } from "../../../../../core/context/functions/Properties.functions";
import { Property, Role } from "../../../../../types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../common/constants/Routing";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../../core/services/GQL.service";
import { useAuth } from "../../../../../core/context/containers/Auth.container";
import { useSnackbar } from "notistack";
import { RemoveEntityModal } from "../../../../../common/components/RemoveEntityModal/RemoveEntityModal";
import { ReactComponent as HamburgerMenu } from "../../../../../common/assets/images/hamburger.svg";
import { CanSee } from "../../../../../common/components/canSee/canSee";
import { useLeftPaneState } from "../../../../../core/context/containers/LeftPane.container";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 15,
    minHeight: 15,
    maxWidth: 15,
    maxHeight: 15,
    color: theme.palette.common.funBlue,
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    borderBottom: `1px solid ${theme.palette.common.nobel}`,

    "&:last-child": {
      border: "none",
    },
  },
}));

interface PropertyMenuProps {
  property: Property;
  index: string;
}

export function PropertyMenu({ property, index }: PropertyMenuProps) {
  const classes = useStyles();

  const [modal, showModal] = useState<boolean>(false);

  const { formatMessage } = useIntl();
  const history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const auth = useAuth();

  const { setIsExpanded, setPanelExpanded } = useLeftPaneState();
  const [removeProperty] = useMutation(GQLService.mutations.removeProperty, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allProperties,
      },
    ],
    awaitRefetchQueries: true,
  });

  const [anchorEl, setAnchorEl] = useState();

  const { selectProperty } = useProperties();

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const open = Boolean(anchorEl);

  const handleViewClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    selectProperty(property.id);
    history.push(ROUTES.PROPERTY.replace(":uid", property.id));
  };

  const handleEditClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    selectProperty(property.id);
    setIsExpanded(true);
    setPanelExpanded("panel1");
    history.push(ROUTES.EDIT_PROPERTY.replace(":uid", property.id));
  };

  const handlePropertyDelete = useCallback(
    async (event: any) => {
      // history.push(ROUTES.PROPERTIES);
      event.preventDefault();
      event.stopPropagation();
      try {
        await removeProperty({ variables: { propertyId: property.id } });
        enqueueSnackbar("Property removed successfully", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error", persist: true });
      }
    },
    [property]
  ); // eslint-disable-line

  const createPropertyViewButton = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={handleViewClick}>
        {formatMessage({
          id: "properties.contextMenu.viewDetails",
          defaultMessage: "View Property Details",
          description: "properties context menu view details label",
        })}
      </MenuItem>
    );
  }, []);

  const createPropertyEditButton = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={handleEditClick}>
        {formatMessage({
          id: "properties.contextMenu.editProperty",
          defaultMessage: "Edit Property",
          description: "properties context menu edit property label",
        })}
      </MenuItem>
    );
  }, []);

  const createPropertyDeleteButton = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={openDeleteModal}>
        {formatMessage({
          id: "properties.contextMenu.removeProperty",
          defaultMessage: "Remove Property",
          description: "properties context menu remove property label",
        })}
      </MenuItem>
    );
  }, []);

  const createPropertyActiveButton = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem}>
        {formatMessage({
          id: `properties.contextMenu.${
            property.companyStatus ? "setAsNotActive" : "setAsActive"
          }`,
          defaultMessage: `Set as not ${
            property.companyStatus ? "active" : "inactive"
          }`,
          description: `properties context menu set as ${
            property.companyStatus ? "not " : ""
          }active label`,
        })}
      </MenuItem>
    );
  }, []);

  const openDeleteModal = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    try {
      showModal(true);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const removePropertyConfirmed = useCallback(
    async (id: string) => {
      try {
        await removeProperty({ variables: { propertyId: property.id } });
        enqueueSnackbar("Property removed successfully", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error", persist: true });
      }
    },
    [property]
  );

  const removePropertyRejected = useCallback(() => {
    showModal(false);
  }, []);

  return (
    <>
      <HamburgerMenu
        id={`PROPERTY:${index}`}
        className={classes.icon}
        onClick={handleToggleMenu}
      />
      <Menu
        id="properties-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        style={{ zIndex: 500 }}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          <CanSee action="properties:view">{createPropertyViewButton()}</CanSee>
          <CanSee action="properties:edit">{createPropertyEditButton()}</CanSee>
          <CanSee action="properties:delete">
            {createPropertyDeleteButton()}
          </CanSee>
        </MenuList>
      </Menu>
      {modal && (
        <RemoveEntityModal
          no={removePropertyRejected}
          yes={removePropertyConfirmed}
          id={property.id}
          UI={{
            subheader: "Property",
            type: "Property",
            name: property.siteName,
          }}
        />
      )}
    </>
  );
}
