import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  errorLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.cinnabar,
    marginLeft: 5,
  },
  errorInput: {
    borderColor: theme.palette.common.cinnabar,
  },
  addButton: {
    marginRight: "15px",
    border: "1px solid black",
    maxWidth: "30px",
    maxHeight: "30px",
    minWidth: "30px",
    minHeight: "30px",
  },
  headerTextForSetRule: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "18px",
  },
  inputLabel: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineSeight: "18px",
  },
  select: {
    height: 13,
    width: "150px",
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    paddingRight: "8px !important",
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: 9,
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    borderRadius: "0 0 8px 8px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  dropDownTitle: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    margin: "0 8px 8px 0",
  },
  listSubHeaderRoot: {
    backgroundColor: "#E5E5E5",
    color: "#252525",
    /* To change the font, use the fontFamily rule */
  },
  input: {
    height: 36,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: 14,
    width: "150px",
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  errorInputTimer: {
    height: 36,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    // marginBottom: 14,
    borderColor: theme.palette.common.cinnabar,
    width: "150px",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  inputField: {
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  incDecButton: {
    width: 35,
    height: 36,
    backgroundColor: "#ECF8FA",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    marginLeft: "4px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  incDecIcon: {
    color: theme.palette.common.mediumBlack,
    width: "16px",
    height: "20px",
  },
  inputAdornment: {
    color: theme.palette.common.textBlack,
    fontSize: 12,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
  },
  errorMSg: {
    color: theme.palette.common.red,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    marginLeft: "4px",
  },
}));
