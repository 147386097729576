import React from "react";
import ReactDOM from "react-dom";
import { makeStyles, Modal } from "@material-ui/core";

interface ModalProps {
  open: boolean;
  children: React.ReactElement;
}

interface RootModalProps {
  open: boolean;
  children: React.ReactElement;
}

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    overflow: "scroll",
    height: "100%",
  },
}));

export const CustomModal = ({ open, children }: ModalProps) => (
  <Modal
    open={open}
    aria-labelledby="modal"
    aria-describedby="modal-description"
  >
    {children}
  </Modal>
);

export const RootModal = ({ open, children }: RootModalProps) => {
  const root = document.getElementById("root");
  const classes = useStyles();
  if (!root) return null;
  return ReactDOM.createPortal(
    <Modal
      className={classes.modalContainer}
      open={true}
      aria-labelledby="modal"
      aria-describedby="modal-description"
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Modal>,
    root
  );
};
