import React, { useState, useCallback, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  AccordionActions,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { Space } from "../../../../types";
import { StatusIndicator } from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useFloorState } from "../../../../core/context/containers/Floor.container";
import GQLService from "../../../../core/services/GQL.service";
import { useMutation, useLazyQuery } from "@apollo/client";
import { ReactComponent as SpaceIcon } from "../../../../common/assets/images/Spaces.svg";
import { useHistory } from "react-router-dom";
import { useSpacesState } from "../../../../core/context/containers/Spaces.container";
import { ROUTES } from "../../../../common/constants/Routing";
import { useIntl } from "react-intl";
import { listStyles } from "../../../../common/styles/lists";
import { SpacesMenu } from "./Menu";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
  extendedListSubheader: {
    ...listStyles.extendedListSubheader,
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  container: {
    margin: "10px 44px",
    "&.MuiGrid-spacing-xs-2 > .MuiGrid-item": {
      padding: 0,
    },
  },
  cell: {
    minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  blueContent: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.funBlue,
  },
  darkContent: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.blackPearl,
  },
  content: {},
  accordionDetails: {
    paddingBottom: "40px",
    flexDirection: "column",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    flexDirection: "column",
  },
  wrapper: {
    marginBottom: 10,
  },
}));

interface SpacesListItemProps {
  item: Space;
}

export function SpacesListItem({ item }: SpacesListItemProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();
  const { selectedFloor } = useFloorState();
  const { selectedSpace } = useSpacesState();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Space>();

  const [getSpaceById, { data }] = useLazyQuery(GQLService.queries.spaceById, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.spaceById) {
      setSelectedItem(data.spaceById);
    }
  }, [data]);

  const handleExpand = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, itemId: string) => {
      ev.preventDefault();
      ev.stopPropagation();
      getSpaceById({ variables: { spaceId: itemId } });
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const history = useHistory();

  const [updateSpace] = useMutation(GQLService.mutations.updateSpace, {
    refetchQueries: () => [
      {
        query: GQLService.queries.spaces,
        variables: {
          propertyId: selectedProperty?.id,
          floorId: selectedFloor?.id,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const toggleActiveStatus = useCallback(
    async () => {
      let isMounted = true;
      if (isMounted) {
        await updateSpace({
          variables: {
            ...selectedItem,
            accessPoints: selectedItem?.accessPoints.map((i) => i.id),
            airSensors: selectedItem?.airSensors.map((i) => i.id),
            zones: selectedItem?.zones.map((i) => i.id),
            tripWires: selectedItem?.tripWires.map((i) => i.id),
            doors: selectedItem?.doors.map((i) => i.id),
            active: !selectedItem?.active,
            propertyId: selectedProperty?.id,
            floorId: selectedItem?.floor?.id,
            commonSpaceId: selectedItem?.commonSpace?.id,
            ssids: selectedItem?.ssids?.map((i) => i.id),
          },
        }).then(() => {
          getSpaceById({ variables: { spaceId: item?.id } });
        });
      }
      return () => {
        isMounted = false;
      };
    },
    [selectedItem] //eslint-disable-line
  );

  return (
    <Accordion key={item.id} expanded={isExpanded}>
      <AccordionSummary onClick={() => {}}>
        <Grid container={true} alignItems="center">
          <Grid
            container={true}
            item={true}
            md={3}
            sm={3}
            justify="flex-start"
            onClick={(e) => handleExpand(e, item?.id)}
            className={classes.headerWrapper}
          >
            {isExpanded ? (
              <ExpandLess
                id={`expandLess:${item.id}`}
                className={classes.expandIcon}
              />
            ) : (
              <ExpandMore
                id={`expandMore:${item.id}`}
                className={classes.expandIcon}
              />
            )}
            <SpaceIcon className={classes.icon} />
            <Typography className={classes.name}>{item.name}</Typography>
          </Grid>
          <Grid container={true} item={true} md={4} sm={4}>
            <Typography className={classes.subheader}>
              {item.floor?.name || item.floor?.floorNumber}
            </Typography>
          </Grid>
          <Grid item>
            {item.isNetflowActive && (
              <Typography className={classes.subheader}>
                Net Flow enabled
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.flex}>
          {selectedProperty && (
            <StatusIndicator
              uid={selectedProperty?.id}
              isActive={item.active}
              canShowStatusLabel={false}
            />
          )}
        </Grid>
        <AccordionActions
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <SpacesMenu Space={item} index={item.id} />
        </AccordionActions>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container={true} className={classes.container}>
          <Grid item md={3} sm={3} className={classes.wrapper}>
            <Typography className={classes.subheader}>
              {formatMessage({
                id: "spaces.listItem.label.floor",
                defaultMessage: "Floor",
              })}
            </Typography>
            <Typography className={classes.blueContent}>
              {item.floor?.name || item.floor?.floorNumber}
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} className={classes.wrapper}>
            <Typography className={classes.subheader}>
              {formatMessage({
                id: "spaces.listItem.label.area",
                defaultMessage: "Capacity",
              })}
            </Typography>
            <Typography className={classes.blueContent}>{item.area}</Typography>
          </Grid>
          <Grid item md={3} sm={6} className={classes.wrapper}>
            <Typography className={classes.subheader}>
              {formatMessage({
                id: "spaces.listItem.label.spaceType",
                defaultMessage: "Space Type",
              })}
            </Typography>
            <Typography className={classes.blueContent}>
              {item?.commonSpace?.name ? item?.commonSpace?.name : "None"}
            </Typography>
          </Grid>
          <Grid item md={3} sm={3} className={classes.wrapper}>
            <Typography className={classes.subheader}>
              {formatMessage({
                id: "elevators.listItem.label.active",
                defaultMessage: "Status",
              })}
            </Typography>
            <Grid item className={classes.flex}>
              {selectedProperty && (
                <StatusIndicator
                  uid={selectedProperty?.id}
                  isActive={item.active}
                  canShowStatusLabel={true}
                  onChangeFn={toggleActiveStatus}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container={true} className={classes.container}>
          <Grid item md={3} sm={3} className={classes.wrapper}>
            <Typography className={classes.subheader}>
              {formatMessage({
                id: "spaces.listItem.label.assignedAirQuality",
                defaultMessage: "Assigned Air Quality Sensors",
              })}
            </Typography>
            <Typography className={classes.blueContent}>
              {selectedItem?.airSensors.map((i) => i.name).join(", ")}
            </Typography>
          </Grid>
          <Grid item md={3} sm={3} className={classes.wrapper}>
            <Typography className={classes.subheader}>
              {formatMessage({
                id: "spaces.listItem.label.assignedAccessPoint",
                defaultMessage: "Assigned Access Points",
              })}
            </Typography>
            <Typography className={classes.blueContent}>
              {selectedItem?.accessPoints.map((i) => i.name).join(", ")}
            </Typography>
          </Grid>
          <Grid item md={3} sm={3} className={classes.wrapper}>
            <Typography className={classes.subheader}>
              {formatMessage({
                id: "spaces.listItem.label.assignedZones",
                defaultMessage: "Assigned Zones",
              })}
            </Typography>
            <Typography className={classes.blueContent}>
              {selectedItem?.zones.map((i) => i.name || i.zone).join(", ")}
            </Typography>
          </Grid>
          <Grid item md={2} sm={2} className={classes.wrapper}>
            <Typography className={classes.subheader}>
              {formatMessage({
                id: "spaces.listItem.label.assignedTripwires",
                defaultMessage: "Assigned Tripwires",
              })}
            </Typography>
            <Typography className={classes.blueContent}>
              {selectedItem?.tripWires
                .map((i) => i.name || i.tripWire)
                .join(", ")}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
