import { useMutation, useLazyQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import { Form, PageHeader } from "../../../../common/components";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import GQLService from "../../../../core/services/GQL.service";
import {
  ReservationSettingsFields,
  ReservationValidationRules,
} from "./ReservationSettings.form";
import { useIntl } from "react-intl";
import { scrollsTop } from "../../../../common/utils/scrollbar.utils";

export const ReservationSettings = () => {
  const [updateReservationSettings] = useMutation(
    GQLService.mutations.updateReservationSettings
  );
  const history = useHistory();
  const { selectedProperty, setSelectedProperty } = usePropertiesState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [getPropertyById, { data }] = useLazyQuery(
    GQLService.queries.propertyById
  );

  useEffect(() => {
    if (data?.propertyById) {
      setSelectedProperty(data.propertyById);
    }
  }, [data, selectedProperty]);

  const handleSubmit = useCallback(
    async (formData: any) => {
      const reservationConfiguration = {
        passExpirationGracePeriodMinutes:
          formData.passExpirationGracePeriodMinutes,
        passGracePeriodMinutes: formData.passGracePeriodMinutes,
        schedulingSlotMinutes: formData.schedulingSlotMinutes,
        maxAheadTimeOfReservationHours: formData.maxAheadTimeOfReservationHours,
        maxSchedulability: formData.maxSchedulability,
        maxReservationsPerUser: formData.maxReservationsPerUser,
      };
      const elevatorReservationConfiguration = selectedProperty?.upNext
        ? reservationConfiguration
        : null;
      try {
        updateReservationSettings({
          variables: {
            elevatorReservationConfiguration,
            propertyId: selectedProperty?.id!,
          },
        })
          .then(() => {
            getPropertyById({
              variables: { propertyId: selectedProperty?.id! },
            });
          })
          .then(() => {
            enqueueSnackbar("Reservation Settings changed successfully", {
              variant: "success",
            });
            scrollsTop();
            history.push(
              ROUTES.RESERVATION_SETTINGS.replace(":uid", selectedProperty?.id!)
            );
          });
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: "error",
          persist: true,
        });
      }
    },
    [history, setSelectedProperty] //eslint-disable-line
  );
  const reservationConfiguration =
    selectedProperty?.reservationConfigurations[0];
  return (
    <Grid item={true} md={6} sm={12}>
      <Form
        defaultValues={{
          ...reservationConfiguration,
        }}
        fields={ReservationSettingsFields}
        validationSchema={ReservationValidationRules}
        onSubmit={handleSubmit}
      />
    </Grid>
  );
};

export function EditReservationSettings() {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageHeader
        title={formatMessage({
          id: "sensors.main.header",
          defaultMessage: "Edit Reservation Settings",
          description: "Edit Reservation Settings header",
        })}
      />
      <ReservationSettings />
    </>
  );
}
