import React from "react";
import clsx from "clsx";
import { Box, makeStyles } from "@material-ui/core";
import { ReactComponent as ADD_AP_ACTIVE } from "../../../../../common/assets/images/FloorPlanEditor/add_ap_active.svg";
import { ReactComponent as ADD_AP_INACTIVE } from "../../../../../common/assets/images/FloorPlanEditor/add_ap_inactive.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 45,
    width: 45,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  active: {
    backgroundColor: theme.palette.common.funBlue,
  },
  inactive: {
    backgroundColor: theme.palette.common.nobel,
  },
  icon: {
    width: "75%",
    height: "75%",
  },
}));

interface ToolbarItemProps {
  isActive: boolean;
  onClick?: () => void;
}

export function ToolbarItem({ isActive, onClick }: ToolbarItemProps) {
  const classes = useStyles();

  return (
    <Box
      className={clsx(
        classes.root,
        isActive ? classes.active : classes.inactive
      )}
      onClick={onClick}
    >
      {isActive ? (
        <ADD_AP_ACTIVE className={classes.icon} />
      ) : (
        <ADD_AP_INACTIVE className={classes.icon} />
      )}
    </Box>
  );
}
