import React, { useState } from "react";
import {
  DataList,
  EmptyListView,
  CustomModal,
} from "../../../../../common/components";
import { useUserState } from "../../../../../core/context/containers/User.container";
import { Button, makeStyles } from "@material-ui/core";
import { UserListItem } from "./UserListItem";
import { UserGroupModal } from "./UserGroupModal";
import { User } from "../../../../../types";

const useStyles = makeStyles((theme) => ({
  floorsHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    minWidth: 225,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export function UserList() {

  const classes = useStyles();
  const [showModal, toggleModal] = useState<boolean>(false);
  const rejectChanges = () => toggleModal(false);

  const { selectedUsers, setSelectedUsers } = useUserState();


  return (
    <div>
      {(selectedUsers && selectedUsers.length === 0) ||
      selectedUsers === null ? (
        <EmptyListView viewType="userGroup" />
      ) : (
        <div>
          <CustomModal open={showModal}>
            <UserGroupModal
              no={rejectChanges}
            />
          </CustomModal>
          <DataList
            data={selectedUsers}
            loading={false}
            ListItem={UserListItem}
          />
          <Button className={classes.button} onClick={() => toggleModal(true)}>
            Add Next Users
          </Button>
        </div>
      )}
    </div>
  );
}
