import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import {
  Button,
  ClickAwayListener,
  Grid,
  Input,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../NetworkSettings.styles";

interface ModalContentProps {
  no: Function;
  yes: Function | ((value: string) => Promise<void>);
}

export default function Modal({ no, yes }: ModalContentProps) {
  const [modal, showModal] = useState<boolean>(false);
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const placeholder = formatMessage({
    id: "groupname.placeholder.settings",
    defaultMessage: "Type in group name",
    description: "Group name",
  });
  const [groupName, setGroupName] = useState<string>("");

  const outSideClick = useCallback(() => {
    setGroupName(placeholder);
    no();
  }, [modal, groupName]);

  const onCancel = useCallback(() => {
    setGroupName(placeholder);
    no();
  }, [modal, groupName]);

  const addNewGroup = useCallback(() => {
    yes(groupName);
  }, [groupName]);

  return (
    <ClickAwayListener onClickAway={outSideClick}>
      <Grid className={classes.content}>
        <Grid className={classes.alignLeft}>
          <Typography className={classes.header}>
            {formatMessage({
              id: "addGroup.settings",
              defaultMessage: "Add New Group",
              description: "add new group",
            })}
          </Typography>
          <InputLabel className={classes.inputLabel}>
            {formatMessage({
              id: "addGroup.label.settings",
              defaultMessage: "Group Name",
              description: "group name label",
            })}
          </InputLabel>
          <Input
            value={groupName}
            className={classes.input}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder={placeholder}
          />
        </Grid>
        <Grid className={classes.buttonsWrapper}>
          <Button className={classes.cancel} onClick={onCancel}>
            {formatMessage({
              id: "addGroup.label.settings",
              defaultMessage: "Cancel",
              description: "group name label",
            })}
          </Button>
          <Button className={classes.confirm} onClick={addNewGroup}>
            {formatMessage({
              id: "addGroup.label.settings",
              defaultMessage: "Yes, Confirm",
              description: "group name label",
            })}
          </Button>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
}
