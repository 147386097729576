import React, { useCallback, useState } from "react";
import { FieldInputProps, useFormikContext } from "formik";
import { Checkbox, Grid, Switch, Typography } from "@material-ui/core";
import { ReactComponent as CheckboxArrow } from "../../../../assets/images/CheckboxArrow.svg";
import { useStyles } from "./SkipFloorCheckbox.styles";
import { useIntl } from "react-intl";

interface SkipFloorCheckboxProps extends FieldInputProps<any> {
  defaultValue: boolean;
  disabled?: boolean;
}

export function SkipFloorCheckbox({
  name,
  defaultValue = false,
  disabled = false,
  onChange,
}: SkipFloorCheckboxProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { values, initialValues, isSubmitting } = useFormikContext<{
    [key: string]: string;
  }>();
  const isTouched = isSubmitting || values[name] !== initialValues[name];
  const [isSwitchActive, setIsSwitchActive] = useState<boolean>(defaultValue);

  const handleChange = useCallback(
    (e) => {
      setIsSwitchActive(!isSwitchActive);
      onChange!({
        ...e,
        target: { name, value: !isSwitchActive },
      });
    },
    [name, isSwitchActive]
  );

  return (
    <Grid key={name} container={true} item={true} direction="row">
      <Grid item className={classes.root}>
        <Checkbox
          value={isSwitchActive}
          checked={isSwitchActive}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          classes={{ root: classes.checkbox }}
          disabled={disabled}
          onChange={handleChange}
        />
        <Typography className={classes.skipFloorParagraph}>
          {formatMessage({
            id: "skipFloor.label",
            defaultMessage: 'skip floor "0"',
            description: 'skip floor "0"',
          })}
        </Typography>
      </Grid>
    </Grid>
  );
}
