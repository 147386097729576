import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import {
  MenuItem,
  Grid,
  Button,
  List,
  ListSubheader,
  Typography,
  ClickAwayListener,
  Tooltip,
} from "@material-ui/core";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import { PROPERTY_MODULE_ROUTES } from "../../../../../core/router/PropertyRotuer";
import { ROUTES } from "../../../../../common/constants/Routing";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AddIcon from "@material-ui/icons/Add";
import { useIntl } from "react-intl";
import { useStyles } from "../DrawerNav.styles";
import { CanSee } from "../../../../../common/components/canSee/canSee";
import { useFiltersState } from "../../../../../core/context/containers/Filters.container";

export function PropertySelectDropdown() {
  const classes = useStyles();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { properties, selectedProperty, setSelectedProperty } =
    usePropertiesState();
  const { setSelectedFloor } = useFiltersState();

  const handleAllPropertiesClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(ROUTES.PROPERTIES);
    setIsOpen(false);
  }, []);

  const handleAddClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(ROUTES.ADD_PROPERTY);
    setIsOpen(false);
  }, []);

  const handlePropertyClick = useCallback(
    (e, property) => {
      if (selectedProperty?.id === property.id) {
        console.log("same property selected");
      } else {
        setSelectedProperty(property);
        setSelectedFloor(undefined);
      }
      setIsOpen(false);
      if (history.location.pathname.includes("dashboard/properties")) {
        history.push(
          PROPERTY_MODULE_ROUTES.PROPERTY.replace(":uid", property?.id!)
        );
      }
    },
    [history, selectedProperty?.id, setSelectedFloor, setSelectedProperty]
  );

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickAway = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid container={true} className={classes.mainContainer}>
        <Grid item={true} md={12} sm={12}>
          <div className={clsx(classes.listHead, isOpen ? classes.open : "")}>
            <Typography id="site-name" className={classes.name}>
              {selectedProperty?.siteName}
              {isOpen ? (
                <ExpandLessIcon
                  onClick={handleToggle}
                  className={classes.arrowPointer}
                />
              ) : (
                <ExpandMoreIcon
                  onClick={handleToggle}
                  className={classes.arrowPointer}
                />
              )}
            </Typography>
            <Typography className={classes.address}>
              {selectedProperty?.siteServiceAddress}
            </Typography>
          </div>
        </Grid>
        {isOpen && (
          <Grid item={true} md={12} sm={12}>
            <List className={classes.listContainer}>
              <ListSubheader>
                <Button
                  className={classes.allPropertiesButton}
                  onClick={handleAllPropertiesClick}
                >
                  {formatMessage({
                    id: "drawer.propertyMenu.button.allProperties",
                    defaultMessage: "All Properties",
                    description:
                      "drawer property menu all properties button label",
                  })}
                </Button>
              </ListSubheader>
              <List
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "auto",
                  maxWidth: 185,
                  maxHeight: 180,
                }}
              >
                {properties?.map((property) => (
                  <>
                    <Tooltip
                      enterNextDelay={500}
                      placement="top"
                      title={property.siteName}
                      classes={{ tooltip: classes.customWidth }}
                    >
                      <MenuItem
                        id={`property-${property.id}`}
                        key={property.id}
                        value={property.id}
                        classes={{
                          root: classes.option,
                          selected: classes.selected,
                        }}
                        onClick={(e) => handlePropertyClick(e, property)}
                      >
                        {property.siteName}
                      </MenuItem>
                    </Tooltip>
                  </>
                ))}
              </List>
              <CanSee action="properties:create">
                <ListSubheader
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    className={classes.addPropertyButton}
                    onClick={handleAddClick}
                  >
                    <AddIcon />
                    {formatMessage({
                      id: "drawer.propertyMenu.button.addProperties",
                      defaultMessage: "Add Property",
                      description:
                        "drawer property menu add properties button label",
                    })}
                  </Button>
                </ListSubheader>
              </CanSee>
            </List>
          </Grid>
        )}
      </Grid>
    </ClickAwayListener>
  );
}
