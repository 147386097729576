import React from "react";
import { useAuth } from "../../../core/context/containers/Auth.container";
import { Role } from "../../../types";
import { availableScopes } from "../../../types/roles";

interface Props {
  action: availableScopes[number];
  children: JSX.Element;
}

export function CanSee(props: Props) {
  const auth = useAuth();
  const role = auth?.user?.role?.toUpperCase() as Role;
  const component = auth?.canSee(role, props.action, props.children) || null;
  return component;
}

export function IsJoinAdmin({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const role = auth?.user?.role?.toUpperCase() as Role;
  return role === Role.ADMIN ? children : null;
}
