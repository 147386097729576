import React from "react";
import { Chip, Grid, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./ChipList.styles";
import { CloseSharp } from "@material-ui/icons";

type elementToRender = {
  name: string;
  id: string;
};
interface ChipList {
  list: elementToRender[];
  defaultMessage: string;
  onDelete?: (element: elementToRender) => {};
}

export function ChipList({ list, defaultMessage, onDelete }: ChipList) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Grid className={classes.chipWrapper}>
      {list.length > 0 ? (
        list.map((listElement) => (
          <Chip
            label={listElement?.name}
            disabled={false}
            onDelete={(e: React.ChangeEvent<HTMLInputElement>) =>
              onDelete ? onDelete(listElement) : () => {}
            }
            className={classes.chip}
            deleteIcon={
              onDelete ? (
                <CloseSharp className={classes.chipIcon} />
              ) : (
                <Grid></Grid>
              )
            }
          />
        ))
      ) : (
        <Typography className={classes.inputLabel}>
          {formatMessage({
            id: "chiplist.settings",
            defaultMessage: defaultMessage,
            description: "Chip list",
          })}
        </Typography>
      )}
    </Grid>
  );
}
