import React, { useState, useCallback } from "react";
import {
  Grid,
  Input,
  Typography,
  Menu,
  MenuList,
  MenuItem,
  Select,
  ClickAwayListener,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { ReactComponent as HamburgerMenu } from "../../../common/assets/images/hamburger.svg";
import clsx from "clsx";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { useMutation } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";
import { useStyles } from "./CollapsibleContent.styles";
import { useUserState } from "../../../core/context/containers/User.container";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../../common/constants/DummyProperty";

interface DashboardCollapsibleMyPinProps {
  item?: any;
  timeChange?: any;
}

export function DashboardCollapsibleMyPin({
  item,
  timeChange,
}: DashboardCollapsibleMyPinProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { userEmail } = useUserState();
  let parsedRequest = JSON.parse(item.queryData).request;
  const [selectedTime, setSelectedTime] = useState(parsedRequest.selectedTime);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState();
  const [userPin, setUserPin] = useState<string>(
    item?.title ? item?.title : "Assign Card Name"
  );
  const [newTitle, setNewTitle] = useState<string>();
  const open = Boolean(anchorEl);

  const timeInMinutesFilter = [
    { value: 15, label: "Last 15 minutes" },
    { value: 60, label: "Last hour" },
    { value: 24 * 60, label: "Last 24 hours" },
    { value: 7 * 24 * 60, label: "Last 7 days" },
    { value: 30 * 24 * 60, label: "Last 30 days" },
  ];

  const [updateUserPin] = useMutation(GQLService.mutations.updateUserPin);

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const handleTimeChange = useCallback(
    (e) => {
      setSelectedTime(e.target.value);
      timeChange(e.target.value);
    },
    [timeChange]
  );

  const toggleContent = (e: any) => {
    setIsExpanded(!isExpanded);
  };

  const handleEditClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleToggleMenu(e);
    setIsEditing(true);
  };

  const handleNameChange = (event: any) => {
    setNewTitle(event.target.value);
  };

  const outSideClick = () => {
    setIsEditing(false);
    if (newTitle) {
      updateUserPin({
        variables: {
          title: newTitle,
          id: item.id,
        },
      }).then((data: any) => {
        setUserPin(data?.data?.updateUserPin?.title);
      });
    }
  };

  const getPropertyName = (id: string) => {
    if (userEmail === dummyUserEmail) {
      if (id === DummyPropertyA.id) {
        return DummyPropertyA.siteName;
      } else if (id === DummyPropertyB.id) {
        return DummyPropertyB.siteName;
      } else if (id === DummyPropertyC.id) {
        return DummyPropertyC.siteName;
      } else {
        return DummyPropertyD.siteName;
      }
    } else {
      return item?.property?.siteName!;
    }
  };

  return (
    <>
      <Grid className={classes.titleGrid}>
        <Grid className={classes.header}>
          <Grid style={{ display: "flex", alignItems: "center" }}>
            {isExpanded ? (
              <ExpandLess
                id={`expandLess-${item.id}`}
                className={classes.iconStyle}
                onClick={toggleContent}
              />
            ) : (
              <ExpandMore
                id={`expandMore-${item.id}`}
                className={classes.iconStyle}
                onClick={toggleContent}
              />
            )}
            {isEditing ? (
              <Grid>
                <ClickAwayListener onClickAway={outSideClick}>
                  <Input
                    classes={{
                      root: classes.newInput,
                    }}
                    placeholder={userPin}
                    onChange={handleNameChange}
                    inputProps={{ maxLength: "15" }}
                  />
                </ClickAwayListener>
                <Typography className={classes.charError}>
                  {~~Math.round(15 - newTitle?.length!)}/15 characters are
                  remaining
                </Typography>
              </Grid>
            ) : (
              <Typography className={classes.title}>{userPin}</Typography>
            )}
          </Grid>
          <HamburgerMenu
            className={classes.iconStyle}
            onClick={handleToggleMenu}
          />
          <Menu
            id="properties-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted={true}
            open={open}
            onClose={handleToggleMenu}
          >
            <MenuList className={classes.menuList}>
              <MenuItem className={classes.menuItem} onClick={handleEditClick}>
                {formatMessage({
                  id: "mypins.contextMenu.editName",
                  defaultMessage: "Edit name",
                  description: "MyPins custom name edit menu",
                })}
              </MenuItem>
            </MenuList>
          </Menu>
        </Grid>
        <Grid
          className={clsx(
            isExpanded ? classes.showContent : classes.hideContent
          )}
        >
          <Grid
            className={classes.labelGrid}
            style={{ paddingTop: "14px", borderTop: "1px solid #BDCED7" }}
          >
            <Typography className={classes.title}>Property:</Typography>
            <Typography className={classes.label}>
              {getPropertyName(item?.property?.id!)}
            </Typography>
          </Grid>
          <Grid className={classes.labelGrid}>
            <Typography className={classes.title}>Floor:</Typography>
            <Typography
              className={classes.label}
              style={{ marginLeft: "22px" }}
            >
              {item?.floor?.name!
                ? item?.floor?.name!
                : item?.floor?.floorNumber!}
            </Typography>
          </Grid>
          <Grid className={classes.labelGrid}>
            <Typography className={classes.title}>Space:</Typography>
            <Typography
              className={classes.label}
              style={{ marginLeft: "13px" }}
            >
              {item.space === null ? "Whole Floor" : item?.space?.name!}
            </Typography>
          </Grid>
          <Grid className={classes.labelGrid} style={{ marginBottom: "8px" }}>
            <Typography className={classes.title} style={{ marginTop: "5px" }}>
              Duration:{" "}
            </Typography>
            <Select
              classes={{
                root: classes.select,
                select: classes.blackText,
                icon: classes.icon,
              }}
              name="duration"
              value={selectedTime}
              disableUnderline={true}
              onChange={handleTimeChange}
              IconComponent={ExpandMoreSharpIcon}
              MenuProps={{
                classes: {
                  paper: classes.menu,
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {timeInMinutesFilter.map((v) => (
                <MenuItem
                  key={v.value}
                  value={v.value}
                  classes={{
                    root: classes.option,
                    selected: classes.selected,
                  }}
                >
                  {v.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
