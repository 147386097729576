import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useLazyQuery, useQuery } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";
import { useUserState } from "../../../core/context/containers/User.container";
import { APMyPin } from "./APMyPin";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import { DashboardDeviceType } from "../../../types";
import { AQMyPin } from "./AQMyPin";
import { CameraZoneMyPin } from "./CameraZoneMyPin";
import { CameraTripWireMyPin } from "./CameraTripWireMyPin";
import { DashboardAPMyPin } from "./DashboardAPMyPin";
import { DashboardAQMyPin } from "./DashboardAQMyPin";
import { DashboardZoneMyPin } from "./DashboardZoneMyPin";
import { DashboardTripwireMyPin } from "./DashboardTripwireMyPin";
import { CollapsibleMyPin } from "./CollapsibleMyPin";
import Loader from "react-loader-spinner";
import { COLORS } from "../../../common/styles/colors";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  innerWrapper: {
    marginRight: "24px",
    // width: 432,
    width: 490,
    marginBottom: "20px",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0px 10px 8px",
    paddingTop: "8px",
  },
  displayGrid: {
    display: "flex",
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(28),
    color: theme.palette.common.mediumBlack,
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "4px",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "8px",
  },
  statisticContainer: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0px 4px 13px rgba(135, 135, 135, 0.3)",
    paddingBottom: "32px",
    paddingRight: "10px",
  },
  statisticContainerTitleGrid: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "14px",
  },
  statisticContainerTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginLeft: "14px",
    marginBottom: "16px",
  },
  pinIcon: {
    transform: "rotate(45deg)",
    height: "16px",
  },
  densityTimeGrid: {
    marginBottom: "16px",
    marginTop: "20px",
  },
  headerGrid: {
    backgroundColor: "#ECF8FA",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  box: {
    border: "1px solid",
    borderColor: "#E0EBF1",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",
  },
  boxContent: {
    paddingRight: "19px",
    paddingLeft: "19px",
    paddingBottom: "20px",
    paddingTop: "5px",
  },
  dashboradBoxContent: {
    // paddingLeft:"10px",
    // paddingRight:"10px"
  },
  pinGrid: {
    marginRight: "4px",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5px",
  },
  number: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(40),
    color: theme.palette.common.darkBlue,
    // marginTop: "-8px",
    marginBottom: theme.spacing(1),
    // padding: "9px 0",
  },
  blackText: {
    display: "inline-block",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: "9px 9px 9px 0",
  },
  textValue: {
    color: "#2F56A0",
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(28),
  },
  loaderGrid: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
}));

export function MyPins() {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const [userPinsData, setUserPinsData] = useState<any>();
  const { selectedProperty } = usePropertiesState();
  const [cardRemoved, setCardRemoved] = useState<boolean>(false);

  const [getUserPinsByUserId, { loading, data }] = useLazyQuery(
    GQLService.queries.userPinsByUserId,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    getUserPinsByUserId({
      variables: {
        userEmail: userEmail,
      },
    });
  }, [userEmail, selectedProperty, cardRemoved === true]);

  useEffect(() => {
    if (!loading && data) {
      setUserPinsData(data?.userPinsByUserId);
      setCardRemoved(false);
    }
  }, [loading, data]);

  const removedCard = () => {
    setCardRemoved(true);
  };

  return (
    <>
      {loading ? (
        <Grid className={classes.loaderGrid}>
          <Loader type="Oval" color={COLORS.alto} height={150} width={150} />
        </Grid>
      ) : (
        <>
          <Grid className={classes.wrapper}>
            {userPinsData && !loading
              ? userPinsData?.map((item: any) => (
                  <>
                    {item.deviceType ===
                    DashboardDeviceType.DASHBOARD_ACCESSPOINT ? (
                      <DashboardAPMyPin
                        item={item}
                        loadingMyPin={loading}
                        removedCard={removedCard}
                      />
                    ) : item.deviceType ===
                      DashboardDeviceType.DASHBOARD_AIRQUALITY ? (
                      <DashboardAQMyPin
                        item={item}
                        loadingMyPin={loading}
                        removedCard={removedCard}
                      />
                    ) : item.deviceType ===
                      DashboardDeviceType.DASHBOARD_ZONE ? (
                      <DashboardZoneMyPin
                        item={item}
                        loadingMyPin={loading}
                        removedCard={removedCard}
                      />
                    ) : null}
                  </>
                ))
              : null}
          </Grid>
          <Grid className={classes.wrapper}>
            {userPinsData && !loading
              ? userPinsData?.map((item: any) => (
                  <>
                    {item.deviceType ===
                    DashboardDeviceType.DASHBOARD_TRIPWIRE ? (
                      <DashboardTripwireMyPin
                        item={item}
                        loadingMyPin={loading}
                        removedCard={removedCard}
                      />
                    ) : null}
                  </>
                ))
              : null}
          </Grid>
          <Grid className={classes.wrapper}>
            {userPinsData && !loading
              ? userPinsData?.map((item: any) => (
                  <>
                    {item.deviceType === DashboardDeviceType.ACCESSPOINT ||
                    item.deviceType === DashboardDeviceType.AIRQUALITY ||
                    item.deviceType === DashboardDeviceType.ZONE ||
                    item.deviceType === DashboardDeviceType.TRIPWIRE ? (
                      <Grid className={classes.innerWrapper}>
                        {item.deviceType === DashboardDeviceType.ACCESSPOINT ? (
                          <APMyPin
                            item={item}
                            timeZone={item.property.timeZoneName}
                            loadingMyPin={loading}
                            removedCard={removedCard}
                          />
                        ) : item.deviceType ===
                          DashboardDeviceType.AIRQUALITY ? (
                          <AQMyPin
                            item={item}
                            request={item.queryData}
                            duration={item.duration}
                            sensorConfigurationData={
                              item.property.sensorConfigurations
                            }
                            timeZone={item.property.timeZoneName}
                            removedCard={removedCard}
                          />
                        ) : item.deviceType === DashboardDeviceType.ZONE ? (
                          <CameraZoneMyPin
                            item={item}
                            request={item.queryData}
                            duration={item.duration}
                            timeZone={item.property.timeZoneName}
                            removedCard={removedCard}
                          />
                        ) : item.deviceType === DashboardDeviceType.TRIPWIRE ? (
                          <CameraTripWireMyPin
                            item={item}
                            request={item.queryData}
                            duration={item.duration}
                            timeZone={item.property.timeZoneName}
                            loadingMyPin={loading}
                            removedCard={removedCard}
                          />
                        ) : null}
                      </Grid>
                    ) : null}
                  </>
                ))
              : null}
          </Grid>
        </>
      )}
    </>
  );
}
