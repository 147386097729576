import { gql } from "@apollo/client";

export const NETWORK_SETTINGS_MUTATIONS = {
  createNetworkGgroup: gql`
    mutation createNetworkGroup($name: String!) {
      createNetworkGroup(name: $name) {
        id
      }
    }
  `,
  createNetworkHost: gql`
    mutation createNetworkHost(
      $groupId: String
      $hostName: String!
      $ignore: Boolean!
    ) {
      createNetworkHost(
        request: { hostName: $hostName, groupId: $groupId, ignore: $ignore }
      ) {
        id
      }
    }
  `,
  deleteNetworkHost: gql`
    mutation deleteNetworkHost($id: String!) {
      deleteNetworkHost(id: $id)
    }
  `,
  deleteNetworkGroup: gql`
    mutation deleteNetworkGroup($id: String!) {
      deleteNetworkGroup(id: $id)
    }
  `,
  updateNetworkGroup: gql`
    mutation updateNetworkGroup($id: String!, $name: String!) {
      updateNetworkGroup(request: { id: $id, name: $name })
    }
  `,
};

export const NETWORK_SETTINGS_QUERIES = {
  allNetworkGroups: gql`
    query {
      networkGroups {
        id
        name
        hosts {
          hostName
          id
          ignore
        }
      }
    }
  `,
  ignoredNetworkHosts: gql`
    query {
      ignoreNetworkHosts {
        hostName
        id
        ignore
      }
    }
  `,
};
