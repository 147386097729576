import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";

import { StatusIndicator } from "../../../../common/components";
import { ElementView } from "./elementView";
import { ROUTES } from "../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useUserState } from "../../../../core/context/containers/User.container";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 90,
    height: 40,
    borderRadius: 30,
    display: "flex",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.common.dustyGray,
      textDecoration: "none",
    },
  },
  swipePanel: {
    marginBottom: 100,
  },
  fullWidth: {
    width: "100%",
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  activeIndicator: {
    display: "flex",
    alignItems: "center",
  },
  activeLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.dustyGray,
    padding: "5px 0",
  },
  blackHeader: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    marginBottom: theme.spacing(5),
    lineHeight: theme.typography.pxToRem(24),
  },
  marginTop: {
    marginTop: theme.typography.pxToRem(65),
  },
  detailsLabel: {
    color: theme.palette.common.solidGray,
  },
  switch: {
    color: `${theme.palette.common.switchBlue} !important`,
    border: `1px solid ${theme.palette.common.switchBlue}`,
    borderRadius: "30px;",
    height: 17,
    width: 52,
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `#2F56A1 !important`,
    width: 12,
    height: 12,
  },
  checked: {
    color: "transparent !important",
  },
  base: {
    height: 22,
  },
  error: {
    border: `1px solid ${theme.palette.common.cinnabar}`,
    borderRadius: "30px;",
    color: `${theme.palette.common.switchBlue} !important`,
    height: 17,
    width: 52,
  },
  status: {
    color: "#122036",
    marginLeft: 8,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 3,
  },
  statusWrapper: {
    display: "flex",
    borderBottom: `1px solid ${theme.palette.common.dustyGray}`,
    marginBottom: "25px",
    width: "40%",
    flexDirection: "column",
  },
  statusHeader: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.dustyGray,
    padding: "5px 0",
  },
}));

export function MainInformations() {
  const classes = useStyles();
  const { selectedUser, setSelectedUser } = useUserState();
  const history = useHistory();

  const [editUser] = useMutation(GQLService.mutations.editUser, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allUsersInfo,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleEditUserClick = () => {
    history.push(ROUTES.EDIT_USER.replace(":id", selectedUser?.id!));
  };

  const toggleActiveStatus = useCallback(
    async () => {
      let isMounted = true;
      if (isMounted) {
        const user = await editUser({
          variables: { ...selectedUser, active: !selectedUser?.enabled },
        });
        setSelectedUser(user.data.updateUser);
      }
      return () => {
        isMounted = false;
      };
    },
    [selectedUser] //eslint-disable-line
  );

  return (
    <Grid container={true} direction="row" alignItems="center">
      <Grid item={true} className={classes.fullWidth}>
        <ElementView content={selectedUser?.fullName!} name={"Name"} />
        <ElementView content={selectedUser?.email!} name={"E-mail address"} />
        <ElementView content={selectedUser?.role!} name={"Role"} />
        <Grid item className={classes.statusWrapper}>
          <Typography className={classes.statusHeader}>Status</Typography>
          <Grid item className={classes.activeIndicator}>
            <StatusIndicator
              uid={selectedUser?.id!}
              isActive={selectedUser?.enabled!}
              canShowStatusLabel={true}
              onChangeFn={toggleActiveStatus}
            />
          </Grid>
        </Grid>
        <ElementView
          content={new Date(selectedUser?.creationDate!).toUTCString()}
          name={"Date Created"}
        />
        <Button className={classes.editBtn} onClick={handleEditUserClick}>
          Edit
        </Button>
      </Grid>
    </Grid>
  );
}
