import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  textArea: {
    height: 118,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    width: "100%",
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  error: {
    borderColor: theme.palette.common.cinnabar,
  },
}));
