import { gql, ApolloClient } from "@apollo/client";

const typeDefs = gql`
  enum Band {
    NONE
    G2_4
    G5
  }
  type CreateRadioRequestInput {
    macAddress: String
    band: Band
    channel: String
    channelWidth: String
    txPower: Int
    ssids: [String]
  }
`;

export const ACCESSPOINTS_QUERIES = {
  allAccessPoints: gql`
    query ($propertyId: String!) {
      accessPointsByPropertyId(propertyId: $propertyId) {
        id
        active
        baseMacAddress
        coordinateX
        coordinateY
        dataCenter
        deviceType
        ipAddress
        name
        serialNumber
        modelNumber
        floorId
        notes
        inventoryNumber
        radio1 {
          id
          macAddress
          band
          channel
          channelWidth
          txPower
          ssids {
            id
            name
          }
        }
        radio2 {
          id
          macAddress
          band
          channel
          channelWidth
          txPower
          ssids {
            id
            name
          }
        }
      }
    }
  `,
  connectedDevicesByFloorIdAverageAndCurrentWorkingHours: gql`
    query ($request: TimeRangedDashboardFloorRequestInput) {
      connectedDevicesByFloorIdAverageAndCurrentWorkingHours(
        request: $request
      ) {
        accessPointName
        averageConnectedDevicesCount
        currentConnectedDevicesCount
      }
    }
  `,
  connectedDevicesByPropertyIdAverageAndCurrentWorkingHours: gql`
    query ($request: TimeRangedDashboardPropertyRequestInput) {
      connectedDevicesByPropertyIdAverageAndCurrentWorkingHours(
        request: $request
      ) {
        accessPointName
        averageConnectedDevicesCount
        currentConnectedDevicesCount
      }
    }
  `,
  connectedDevicesByFloorIdAverageAndCurrent: gql`
    query ($request: TimeRangedDashboardFloorRequestInput) {
      connectedDevicesByFloorIdAverageAndCurrent(request: $request) {
        accessPointName
        averageConnectedDevicesCount
        currentConnectedDevicesCount
      }
    }
  `,
  connectedDevicesByPropertyIdAverageAndCurrent: gql`
    query ($request: TimeRangedDashboardPropertyRequestInput) {
      connectedDevicesByPropertyIdAverageAndCurrent(request: $request) {
        accessPointName
        averageConnectedDevicesCount
        currentConnectedDevicesCount
      }
    }
  `,
  averageConnectedDevicesTimelineForProperty: gql`
    query ($request: TimeRangedDashboardPropertyRequestInput) {
      averageConnectedDevicesTimelineForProperty(request: $request) {
        accessPointName
        averageConnectedDevicesCount
        timeRangeType
        timeline {
          connectedDevicesCount
          from
          to
        }
      }
    }
  `,
  averageConnectedDevicesTimelineForFloor: gql`
    query ($request: TimeRangedDashboardFloorRequestInput) {
      averageConnectedDevicesTimelineForFloor(request: $request) {
        accessPointName
        averageConnectedDevicesCount
        timeRangeType
        timeline {
          connectedDevicesCount
          from
          to
        }
      }
    }
  `,
  connectedDevicesToAccessPoints: gql`
    query ($request: TimeRangedDashboardFloorRequestInput) {
      connectedDevicesByFloorId(request: $request) {
        connectedDevicesTimeRanges {
          accessPointId
          from
          to
          desktopDevicesCount
          mobileDevicesCount
          otherDevicesCount
        }
      }
    }
  `,
  connectedDevicesTypesCountGroupedByDate: gql`
    query ($request: DashboardRequestInput) {
      connectedDevicesTypesCountGroupedByDate(request: $request) {
        desktopDevicesCount {
          count
          date
        }
        mobileDevicesCount {
          count
          date
        }
        otherDevicesCount {
          count
          date
        }
      }
    }
  `,
  floorAccessPoints: gql`
    query ($floorId: String!) {
      accessPointsByFloorId(floorId: $floorId) {
        id
        active
        baseMacAddress
        coordinateX
        coordinateY
        dataCenter
        deviceType
        ipAddress
        name
        serialNumber
        modelNumber
        notes
        inventoryNumber
        radio1 {
          macAddress
          band
          channel
          channelWidth
          id
          txPower
          ssids {
            id
            name
            active
          }
        }
        radio2 {
          macAddress
          band
          channel
          channelWidth
          id
          txPower
          ssids {
            id
            name
            active
          }
        }
      }
    }
  `,
  accessPointById: gql`
    query ($accessPointId: String!) {
      accessPointById(accessPointId: $accessPointId) {
        id
        active
        baseMacAddress
        coordinateX
        coordinateY
        dataCenter
        deviceType
        floorId
        ipAddress
        name
        serialNumber
        modelNumber
        notes
        inventoryNumber
        radio1 {
          macAddress
          band
          channel
          channelWidth
          id
          txPower
          ssids {
            id
            name
          }
        }
        radio2 {
          macAddress
          band
          channel
          channelWidth
          id
          txPower
          ssids {
            id
            name
          }
        }
      }
    }
  `,
  densityTimelineByFloor: gql`
    query densityTimelineByFloor(
      $request: TimeRangedDashboardDeviceRequestInput
    ) {
      densityTimelineByFloor(request: $request) {
        densityTimeline {
          connectedDevicesCount
          from
          to
        }
        timeRangeType
      }
    }
  `,
  statisticTimelineByFloor: gql`
    query statisticTimelineByFloor(
      $request: TimeRangedDashboardDeviceRequestInput
    ) {
      statisticTimelineByFloor(request: $request) {
        timeRangeType
        densityTimeline {
          connectedDevicesCount
          from
          to
        }
        airQualitytimeline {
          from
          to
          score
          quality
        }
      }
    }
  `,
};

export const ACCESSPOINTS_MUTATIONS = {
  addAccessPoint: gql`
    mutation createAccessPoint(
      $active: Boolean!
      $baseMacAddress: String!
      $coordinateX: Int
      $coordinateY: Int
      $dataCenter: String
      $deviceType: String
      $floorId: String!
      $ipAddress: String
      $name: String!
      $serialNumber: String
      $modelNumber: String
      $notes: String
      $radio1: CreateRadioRequestInput!
      $radio2: CreateRadioRequestInput!
      $inventoryNumber: Int
    ) {
      createAccessPoint(
        request: {
          active: $active
          baseMacAddress: $baseMacAddress
          coordinateX: $coordinateX
          coordinateY: $coordinateY
          dataCenter: $dataCenter
          deviceType: $deviceType
          floorId: $floorId
          ipAddress: $ipAddress
          name: $name
          serialNumber: $serialNumber
          modelNumber: $modelNumber
          notes: $notes
          radio1: $radio1
          radio2: $radio2
          inventoryNumber: $inventoryNumber
        }
      ) {
        id
      }
    }
  `,
  editAccessPoint: gql`
    mutation updateAccessPoint(
      $active: Boolean
      $baseMacAddress: String
      $coordinateX: Int
      $coordinateY: Int
      $dataCenter: String
      $deviceType: String
      $floorId: String!
      $id: String!
      $ipAddress: String
      $name: String!
      $serialNumber: String
      $modelNumber: String
      $notes: String
      $inventoryNumber: Int
    ) {
      updateAccessPoint(
        request: {
          active: $active
          baseMacAddress: $baseMacAddress
          coordinateX: $coordinateX
          coordinateY: $coordinateY
          dataCenter: $dataCenter
          deviceType: $deviceType
          floorId: $floorId
          id: $id
          ipAddress: $ipAddress
          name: $name
          serialNumber: $serialNumber
          modelNumber: $modelNumber
          notes: $notes
          inventoryNumber: $inventoryNumber
        }
      )
    }
  `,
  editRadio: gql`
    mutation updateRadio(
      $radioId: String!
      $accessPointId: String!
      $channel: String
      $channelWidth: String
      $ssidsToAdd: [String]
      $ssidsToDelete: [String]
      $txPower: Int
      $macAddress: String
      $band: Band!
    ) {
      updateRadio(
        request: {
          radioId: $radioId
          accessPointId: $accessPointId
          channel: $channel
          channelWidth: $channelWidth
          ssidsToAdd: $ssidsToAdd
          ssidsToDelete: $ssidsToDelete
          txPower: $txPower
          macAddress: $macAddress
          band: $band
        }
      )
    }
  `,
  removeAccessPoint: gql`
    mutation ($accessPointId: String!) {
      deleteAccessPoint(accessPointId: $accessPointId)
    }
  `,
  deleteAccessPointFromFloorPlan: gql`
    mutation ($accessPointId: String!) {
      deleteAccessPointFromFloorPlan(accessPointId: $accessPointId)
    }
  `,
  createMultipleAccessPoint: gql`
    mutation createMultipleAccessPoint(
      $request: CreateMultipleAccessPointsRequestInput
    ) {
      createMultipleAccessPoint(request: $request) {
        id
      }
    }
  `,
};
