import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { FieldInputProps, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import { useStyles } from "./ResetNetFlow.styles";
import moment from "moment";
import { useSpacesState } from "../../../../core/context/containers/Spaces.container";

interface ResetNetFlowProps extends FieldInputProps<any> {
  defaultValue: any;
  disabled?: boolean;
  customProps: {
    isNetflowActive?: boolean;
  };
}

export function ResetNetFlowTimeForEdit({
  name,
  defaultValue,
  disabled = false,
  onChange,
  customProps,
}: ResetNetFlowProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [selectedTime, setSelectedTime] = useState(defaultValue);
  const { isNetFlowIsChecked, setIsNetFlowIsChecked } = useSpacesState();
  const { errors, values, initialValues, isSubmitting } = useFormikContext<{
    [key: string]: string;
  }>();
  const isTouched =
    isSubmitting ||
    values[name as keyof typeof values] !==
      initialValues[name as keyof typeof values];

  useEffect(() => {
    setIsNetFlowIsChecked(customProps.isNetflowActive);
  }, []);

  useEffect(() => {
    if (defaultValue || defaultValue == 0) {
      setSelectedTime(defaultValue);
      handleChange(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (isNetFlowIsChecked == false) {
      setSelectedTime(null);
      onChange({
        target: { name: "resetTime", value: null },
      });
    }
  }, [isNetFlowIsChecked]);

  const timeValues: any[] = [];
  useMemo(() => {
    for (let i = 0; i < 24; i++) {
      const label =
        i === 0
          ? `12:00 AM`
          : i < 12
          ? `${i}:00 AM`
          : i === 12
          ? `12:00 PM`
          : `${i - 12}:00 PM`;
      timeValues.push(label);
    }
  }, [timeValues]);
  const convertValueToTime = (_hr: any) => {
    return moment(`${_hr}:00`, `hh:mm`).format("HH:mm");
  };

  const handleChange = useCallback(
    (val) => {
      setSelectedTime(val);
      let _timeTo24hr = convertValueToTime(val);
      onChange({
        target: { name: "resetTime", value: _timeTo24hr },
      });
    },
    [selectedTime] // eslint-disable-line
  );
  return (
    <Grid key={name} container={true} direction="row">
      {errors[name] && (
        <span className={classes.errorLabel}>{errors[name]}</span>
      )}
      <Grid item md={6} className={classes.root}>
        <Grid container={true}>
          <Grid item md={5}>
            <Typography className={classes.text}>
              {formatMessage({
                id: "resetNetFlow.netFlow.label",
                defaultMessage: "Net Flow Using Tripwires",
                description: "Net Flow Using Tripwires",
              })}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography className={classes.text}>
              {formatMessage({
                id: "resetNetFlow.reset.label",
                defaultMessage: "Reset at",
                description: "Reset at",
              })}
            </Typography>
            <span className={classes.textRed}>
              <sup>*</sup>
            </span>
          </Grid>
          <Grid item md={2}>
            <Select
              id="resetNetFlow.netFlow.select"
              value={selectedTime}
              onChange={(e) => handleChange(e.target.value)}
              autoWidth
              disabled={!isNetFlowIsChecked}
              classes={{
                root: `${
                  isTouched && errors[name as keyof typeof values]
                    ? classes.error
                    : classes.select
                }`,
                select: classes.blackText,
                icon: classes.icon,
              }}
            >
              {timeValues.map((name, i) => (
                <MenuItem key={name} value={i}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
