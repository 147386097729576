import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import {
  PageHeader,
  DataList,
  LinkButton,
  EmptyListView,
} from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";

import { Grid, makeStyles } from "@material-ui/core";
import { ROUTES } from "../../../../common/constants/Routing";
import { useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { Role } from "../../../../types";
import { useSpacesState } from "../../../../core/context/containers/Spaces.container";
import { SpacesListItem } from "./ListItem";
import { useSearchState } from "../../../../core/context/containers/Search.container";

const useStyles = makeStyles(() => ({
  floorsHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function Spaces() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();
  const { searchValue } = useSearchState();
  const auth = useAuth();
  const {
    searchSpaces,
    spaces,
    setSpaces,
    setSelectedSpace,
    spacesFromResponse,
    setSpacesFromResponse,
  } = useSpacesState();

  const { loading, data } = useQuery(GQLService.queries.spaces, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "cache-first",
  });

  useEffect(
    () => {
      let isMounted = true;

      if (isMounted) {
        setSpaces(data?.spaces || []);
        setSpacesFromResponse(data?.spaces || []);
      }
      return () => {
        isMounted = false;
      };
    },
    [data] // eslint-disable-line
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      searchSpaces(searchValue);
    }
    return () => {
      isMounted = false;
    };
  }, [searchValue, spacesFromResponse]);

  const createSpacesButton = useCallback(() => {
    return (
      <LinkButton
        to={ROUTES.ADD_SPACE.replace(":uid", selectedProperty?.id!)}
        label={formatMessage({
          id: "spaces.button.label",
          defaultMessage: "Add New Space",
          description: "add a Space button label",
        })}
      />
    );
  }, []);

  return (
    <div>
      <Grid className={classes.floorsHeader}>
        <PageHeader
          title={formatMessage({
            id: "spaces.header",
            defaultMessage: "Spaces",
            description: "Spaces header",
          })}
        />
        {spaces.length !== 0
          ? auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "spaces:create",
              createSpacesButton()
            )
          : null}
      </Grid>
      {spaces.length === 0 ? (
        <EmptyListView viewType="spaces" />
      ) : (
        <DataList data={spaces} loading={loading} ListItem={SpacesListItem} />
      )}
    </div>
  );
}
