import React from "react";
import { useFormikContext, FieldInputProps } from "formik";
import { Grid, Input as InputUI, makeStyles } from "@material-ui/core";
import { Floor } from "../../../../../types";
import { useSpacesState } from "../../../../../core/context/containers/Spaces.container";

interface SelectProps extends FieldInputProps<any> {
  defaultValue: Floor;
  disabled?: boolean;
  placeholder: string;
}

const useStyles = makeStyles((theme) => ({
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: 14,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  inputField: {
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  errorInput: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: 14,
    borderColor: theme.palette.common.cinnabar,
  },
}));

export function FloorSelectForEdit({
  name,
  defaultValue,
  disabled = false,
}: SelectProps) {
  const classes = useStyles();
  const { errors, values, initialValues, isSubmitting } = useFormikContext<{
    [key: string]: string;
  }>();
  const { selectedSpace } = useSpacesState();
  const isTouched = isSubmitting || values[name] !== initialValues[name];

  return (
    <Grid key={name} container={true} item={true} direction="column">
      <InputUI
        classes={{
          root: `${
            isTouched && errors[name] ? classes.errorInput : classes.input
          }`,
          input: classes.inputField,
        }}
        value={
          selectedSpace?.floor?.name ||
          `Floor nr: ${selectedSpace?.floor?.floorNumber}`
        }
        name={name}
        disabled={disabled}
        disableUnderline={true}
        fullWidth={true}
        style={{ marginTop: "8px" }}
      />
    </Grid>
  );
}
