import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Avatar, Grid, makeStyles, Typography } from "@material-ui/core";

import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import GQLService from "../../../../../../core/services/GQL.service";
import { Property } from "../../../../../../types";
import { useUserState } from "../../../../../../core/context/containers/User.container";
import DummyLogo from "../../../../../../common/assets/images/grandSquareLogo.png";
import { dummyUserEmail } from "../../../../../../common/constants/DummyProperty";

const useStyles = makeStyles((theme) => ({
  appIcon: {
    marginLeft: "50px",
    marginBottom: "100px",
    marginTop: "50px",
  },
  large: {
    marginTop: "-72px",
    marginLeft: theme.typography.pxToRem(16),
    marginBottom: "10px",
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
}));

interface CompanyLogoProps {
  currentUrl?: string | undefined;
  property: Property | null;
}

export function CompanyLogo({ currentUrl, property }: CompanyLogoProps) {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const { selectedProperty, currentLogoObject, setCurrentLogoObject } =
    usePropertiesState();
  useEffect(
    () => {
      if (selectedProperty) {
        (async () => {
          const response = await GQLService.rest.logo.getLogo(
            selectedProperty!.id
          );
          if (response.source !== undefined) {
            setCurrentLogoObject({
              ...currentLogoObject,
              url: response.source,
            });
          } else {
            setCurrentLogoObject({
              url: undefined,
              data: undefined,
              name: undefined,
            });
          }
        })();
      }
    },
    [property, selectedProperty] // eslint-disable-line
  );

  return (
    <Grid style={{ width: "100%" }}>
      {userEmail === dummyUserEmail ? (
        <Avatar
          src={DummyLogo}
          alt={"company logo"}
          className={clsx(classes.large)}
        />
      ) : currentLogoObject?.url ? (
        <Avatar
          src={currentUrl || currentLogoObject?.url}
          alt={currentLogoObject.name || "company logo"}
          className={clsx(classes.large)}
        />
      ) : (
        <div></div>
      )}
    </Grid>
  );
}
