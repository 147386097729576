import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  Switch,
} from "@material-ui/core";

import { ActivitySwitch, PageHeader } from "../../../../common/components";
import { useSSIDState } from "../../../../core/context/containers/SSID.container";
import { ElementView } from "./elementView";
import { ROUTES } from "../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { StatusIndicator } from "../../../../common/components";
import { useVLANState } from "../../../../core/context/containers/VLAN.container";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { Role } from "../../../../types";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 90,
    height: 40,
    borderRadius: 30,
    display: "flex",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.common.dustyGray,
      textDecoration: "none",
    },
  },
  swipePanel: {
    marginBottom: 100,
  },
  fullWidth: {
    width: "100%",
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  activeIndicator: {
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  activeLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.dustyGray,
    padding: "5px 0",
  },
  blackHeader: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    marginBottom: theme.spacing(5),
    lineHeight: theme.typography.pxToRem(24),
  },
  marginTop: {
    marginTop: theme.typography.pxToRem(65),
  },
  detailsLabel: {
    color: theme.palette.common.solidGray,
  },
  headerInfo: {
    marginTop: theme.typography.pxToRem(25),
  },
  switch: {
    color: `${theme.palette.common.switchBlue} !important`,
    border: `1px solid ${theme.palette.common.switchBlue}`,
    borderRadius: "30px;",
    height: 17,
    width: 52,
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `#2F56A1 !important`,
    width: 12,
    height: 12,
  },
  checked: {
    color: "transparent !important",
  },
  base: {
    height: 22,
  },
  error: {
    border: `1px solid ${theme.palette.common.cinnabar}`,
    borderRadius: "30px;",
    color: `${theme.palette.common.switchBlue} !important`,
    height: 17,
    width: 52,
  },
  status: {
    color: "#122036",
    marginLeft: 8,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 3,
  },
}));

export function VLanView() {
  const classes = useStyles();
  const { selectedVLAN } = useVLANState();
  const { selectedProperty } = usePropertiesState();
  const { formatMessage } = useIntl();

  const history = useHistory();

  const auth = useAuth();

  const handleEditVlanClick = useCallback(() => {
    history.push(
      ROUTES.V_LAN_EDIT.replace(":uid", selectedProperty?.id!).replace(
        ":vlanId",
        selectedVLAN?.id.split(":")[1]!
      )
    );
  }, []);

  const createVLANButton = useCallback(() => {
    return (
      <Button className={classes.editBtn} onClick={handleEditVlanClick}>
        Edit
      </Button>
    );
  }, []);

  return (
    <Grid container={true} direction="row" alignItems="center">
      <Grid item={true} className={classes.fullWidth}>
        <Grid className={classes.header}>
          <PageHeader
            title={formatMessage({
              id: "property.header",
              defaultMessage: `Network: ${selectedVLAN?.name}`,
              description: "propery details header",
            })}
          />
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "vlans:edit",
            createVLANButton()
          )}
        </Grid>
        <Grid container>
          <Grid item md={12} className={classes.headerInfo}>
            <ElementView content={selectedVLAN?.name} name={"Network Name"} />
          </Grid>
          <Grid item md={12} className={classes.headerInfo}>
            <ElementView
              content={selectedVLAN?.vlanNumber}
              name={"VLAN Number"}
            />
          </Grid>
        </Grid>
        <Grid item className={classes.headerInfo}>
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "vlans:protected",
            <ElementView content={selectedVLAN?.ifId} name={"IfID"} />
          )}
          <ElementView
            content={selectedVLAN?.description}
            name={"Description"}
          />
          <Grid item className={classes.activeIndicator}>
            <Typography className={classes.activeLabel}>
              Part Of Common Network
            </Typography>
            <Switch
              disabled={true}
              name={"Part Of Common Network"}
              onChange={() => {}}
              checked={selectedVLAN?.inCommonNetwork}
              classes={{
                root: classes.switch,
                checked: classes.checked,
                thumb: classes.thumb,
                track: classes.track,
                switchBase: classes.base,
              }}
            />
          </Grid>
          <Grid item className={classes.activeIndicator}>
            <Typography className={classes.activeLabel}>Active</Typography>
            <Switch
              disabled={true}
              name={"Status"}
              onChange={() => {}}
              checked={selectedVLAN?.active}
              classes={{
                root: classes.switch,
                checked: classes.checked,
                thumb: classes.thumb,
                track: classes.track,
                switchBase: classes.base,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
