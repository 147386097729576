export const TIMEZONES = [
  { abbr: "ACT", name: "Australia/Darwin" },
  { abbr: "AET", name: "Australia/Sydney" },
  { abbr: "AGT", name: "America/Argentina/Buenos_Aires" },
  { abbr: "ART", name: "Africa/Cairo" },
  { abbr: "AST", name: "America/Anchorage" },
  { abbr: "BET", name: "America/Sao_Paulo" },
  { abbr: "BST", name: "Asia/Dhaka" },
  { abbr: "CAT", name: "Africa/Harare" },
  { abbr: "CNT", name: "America/St_Johns" },
  { abbr: "CST", name: "America/Chicago" },
  { abbr: "CTT", name: "Asia/Shanghai" },
  { abbr: "EAT", name: "Africa/Addis_Ababa" },
  { abbr: "ECT", name: "Europe/Paris" },
  { abbr: "IET", name: "America/Indiana/Indianapolis" },
  { abbr: "IST", name: "Asia/Kolkata" },
  { abbr: "JST", name: "Asia/Tokyo" },
  { abbr: "MIT", name: "Pacific/Apia" },
  { abbr: "NET", name: "Asia/Yerevan" },
  { abbr: "NST", name: "Pacific/Auckland" },
  { abbr: "PLT", name: "Asia/Karachi" },
  { abbr: "PNT", name: "America/Phoenix" },
  { abbr: "PRT", name: "America/Puerto_Rico" },
  { abbr: "PST", name: "America/Los_Angeles" },
  { abbr: "SST", name: "Pacific/Guadalcanal" },
  { abbr: "VST", name: "Asia/Ho_Chi_Minh" },
  { abbr: "EST", name: "America/New_York" },
  { abbr: "MST", name: "America/Phoenix" },
  { abbr: "HST", name: "Pacific/Honolulu" },
];
