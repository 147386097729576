import React, { useState, useCallback } from "react";
import { useIntl } from "react-intl";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  AccordionActions,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";

import { ReactComponent as DoorIcon } from "../../../../common/assets/images/door.svg";
import { Door } from "../../../../types";
import { useDoorState } from "../../../../core/context/containers/Door.container";
import { StatusIndicator } from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { listStyles } from "../../../../common/styles/lists";
import { DoorMenu } from "../components/DoorMenu";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
  extendedListSubheader: {
    ...listStyles.extendedListSubheader,
  },
  icon: { color: theme.palette.common.funBlue },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  rightContainer: {
    width: "100%",
  },
  floorPlan: {
    width: 434,
    height: 369,
    objectFit: "contain",
  },
  floorInfo: {
    padding: "10px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    padding: "10px",
    flexDirection: "column",
  },
  item: {
    margin: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  space20Left: {
    marginLeft: 20,
  },
  space10Left: {
    marginLeft: 10,
  },
  largeName: {
    whiteSpace: "nowrap",
    width: "75%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

interface DoorsListItemProps {
  item: Door;
}

export function DoorsListItem({ item }: DoorsListItemProps) {
  const classes = useStyles();
  const { selectedProperty } = usePropertiesState();
  const { setSelectedDoor } = useDoorState();

  const { formatMessage } = useIntl();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpand = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const [updateDoor] = useMutation(GQLService.mutations.editDoor, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allDoors,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const toggleActiveStatus = useCallback(
    async () => {
      let isMounted = true;
      if (isMounted) {
        await updateDoor({
          variables: {
            ...item,
            active: !item.active,
            groups: item.groups.map((group) => group.id!),
          },
        });
      }
      return () => {
        isMounted = false;
      };
    },
    [item] //eslint-disable-line
  );

  const handleViewClick = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>,
      id: string
    ) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedDoor(item);
    },
    [item, setSelectedDoor]
  );

  const showTime = (time: number) => {
    return time === 0
      ? `12:00 AM`
      : time < 12
      ? `${time}:00 AM`
      : time === 12
      ? `12:00 PM`
      : `${time - 12}:00 PM`;
  };
  return (
    <Accordion key={item.id} expanded={isExpanded}>
      <AccordionSummary onClick={handleExpand}>
        <Grid
          container={true}
          alignItems="center"
          justify="space-between"
          classes={{ root: classes.item }}
        >
          <Grid
            container={true}
            item={true}
            md={4}
            sm={4}
            justify="flex-start"
            onClick={handleExpand}
            className={classes.headerWrapper}
          >
            {isExpanded ? (
              <ExpandLess
                id={`expandLess-${item.id}`}
                className={classes.expandIcon}
              />
            ) : (
              <ExpandMore
                id={`expandLess-${item.id}`}
                className={classes.expandIcon}
              />
            )}
            <DoorIcon className={classes.icon} />
            <Typography
              className={clsx(classes.name, classes.largeName)}
              onClick={(e) => handleViewClick(e, item.id)}
            >
              {item.name}
            </Typography>
          </Grid>
          <Grid
            container={true}
            item={true}
            md={3}
            sm={3}
            justify="flex-start"
            className={classes.headerWrapper}
          >
            <Typography
              className={clsx(classes.subheader, classes.space10Left)}
            >
              {item.floorName ? item.floorName : item.floorNumber}
            </Typography>
          </Grid>
          <Grid container={true} item={true} md={5} sm={5}>
            <AccordionActions
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              classes={{ root: classes.rightContainer }}
            >
              <Grid item className={classes.flex}>
                {selectedProperty && (
                  <StatusIndicator
                    uid={item?.id}
                    isActive={item.active}
                    canShowStatusLabel={false}
                  />
                )}
              </Grid>
              <DoorMenu door={item} index={item.id} />
            </AccordionActions>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container={true} classes={{ root: classes.item }}>
          <Grid item className={classes.floorInfo} md={4} sm={4}>
            <Typography
              className={clsx(classes.subheader, classes.space20Left)}
            >
              {formatMessage({
                id: "doorListItem.label.floor",
                defaultMessage: "Floor",
              })}
            </Typography>
            <Typography className={clsx(classes.name, classes.space20Left)}>
              {item.floorName ? item.floorName : item.floorNumber}
            </Typography>
          </Grid>
          <Grid item className={classes.floorInfo} md={3} sm={3}>
            <Typography className={classes.subheader}>
              {formatMessage({
                id: "doorListItem.label.workingHour",
                defaultMessage: "Door Hours",
              })}
            </Typography>
            <Typography>
              {showTime(parseInt(item.workingFrom))} -{" "}
              {showTime(parseInt(item.workingTo))}
            </Typography>
          </Grid>
          <Grid item className={classes.status} md={5} sm={5}>
            <Typography className={classes.subheader}>
              {formatMessage({
                id: "doorListItem.label.active",
                defaultMessage: "Status",
              })}
            </Typography>
            <Grid className={classes.flex}>
              {selectedProperty && (
                <StatusIndicator
                  uid={item?.id}
                  isActive={item.active}
                  canShowStatusLabel={true}
                  onChangeFn={toggleActiveStatus}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
