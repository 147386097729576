import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";

import { PageHeader } from "../../../../common/components";
import { createAccessPointFormWizard } from "./AddAccessPointWizard.form";
import { FormWizard } from "../../../../common/components";
import { ROUTES } from "../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { Fieldset } from "../../../../types/form";
import { CustomModal } from "../../../../common/components/index";
import { ModalContent } from "./ModalContent";
import { AccessPoint, SSID } from "../../../../types";
import { useFormWizard } from "../../../../core/context/functions/FormWizard.functions";
import { useSnackbar } from "notistack";
import { useFormWizardState } from "../../../../core/context/containers/FormWizard.container";

const radio5GHzChannels: { id: number; name: string }[] = [];
for (let i = 32; i <= 68; i += 2) {
  radio5GHzChannels.push({ id: i, name: i.toString() });
}
radio5GHzChannels.push({ id: 96, name: "96" });
for (let i = 100; i <= 144; i += 2) {
  if (i !== 130) {
    radio5GHzChannels.push({ id: i, name: i.toString() });
  }
}
for (let i = 149; i <= 161; i += 2) {
  radio5GHzChannels.push({ id: i, name: i.toString() });
}
const radio24GHzChannels: { id: number; name: string }[] = [];
for (let i = 0; i < 11; i++) {
  radio24GHzChannels.push({ id: i + 1, name: (i + 1).toString() });
}
const radio5GHzWidth = [
  { id: 1, name: "20MHz" },
  { id: 2, name: "40MHz" },
  { id: 3, name: "80MHz" },
  { id: 4, name: "160MHz" },
];
const radio24GHzWidth = [
  { id: 1, name: "20MHz" },
  { id: 2, name: "40MHz" },
];

const formatToSend = (formData: any): AccessPoint => {
  if (formData.radio2_txPower === "") {
    formData.radio2_txPower = null;
  }
  if (formData.radio1_txPower === "") {
    formData.radio1_txPower = null;
  }
  return Object.keys(formData).reduce((acc: any, curr) => {
    if (curr.includes("_")) {
      const objToNest: string[] = curr.split("_");
      const [newRadio, newKey] = objToNest;
      return {
        ...acc,
        [newRadio]: {
          ...acc[newRadio],
          [newKey]: formData[curr as keyof typeof formData],
        },
      };
    } else {
      return { ...acc, [curr]: formData[curr as keyof typeof formData] };
    }
  }, {});
};

export function Add() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const [steps, setWizardSteps] = useState<Fieldset[] | undefined>(undefined);
  const [showModal, toggleModal] = useState<boolean>(false);
  const [formDataState, setFormDataState] = useState<AccessPoint | undefined>(
    undefined
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    error,
    loading,
    data: ssids,
  } = useQuery(GQLService.queries.allSsid, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const {
    error: floorError,
    loading: floorLoading,
    data: floors,
  } = useQuery(GQLService.queries.allFloors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const { reset } = useFormWizard();
  const { setFormData, setIsLoading } = useFormWizardState();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      reset();
      setFormData({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!loading && !floorLoading) {
        let activeSSIDS: SSID[] = [];
        if (ssids?.ssidsByPropertyId) {
          ssids?.ssidsByPropertyId?.map((i: SSID) => {
            if (i.active === true) {
              activeSSIDS.push(i);
            }
          });
        }
        const steps = createAccessPointFormWizard({
          ssids: activeSSIDS,
          floors: floors?.floorsByPropertyId,
          property: [{ name: selectedProperty?.ownerCompanyName }],
          channel: [radio5GHzChannels, radio24GHzChannels],
          channelWidth: [radio5GHzWidth, radio24GHzWidth],
        });
        setWizardSteps(steps);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [loading, floorLoading, ssids, floors]);

  const [addAccessPoint] = useMutation(GQLService.mutations.addAccessPoint, {
    refetchQueries: () => [
      {
        query: GQLService.queries.floorAccessPoints,
        variables: { floorId: formDataState?.floorId! },
      },
      {
        query: GQLService.queries.allAccessPoints,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = useCallback(
    (formData) => {
      const dataToSend = formatToSend(formData);
      toggleModal(true);
      setFormDataState(dataToSend);
    },
    [formDataState, selectedProperty] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);

      if (formDataState) {
        try {
          await addAccessPoint({
            variables: {
              ...formDataState,
              inventoryNumber:
                formDataState.inventoryNumber === ""
                  ? 0
                  : formDataState.inventoryNumber,
            },
          }).then(() =>
            enqueueSnackbar(
              formatMessage({
                id: "accessPoints.add.snackbar.success",
                defaultMessage: "Access Point added successfully",
              }),
              {
                variant: "success",
              }
            )
          );
          reset();
          history.push(
            ROUTES.ACCESS_POINTS.replace(":uid", selectedProperty?.id!)
          );
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          setIsLoading(false);
          console.log(e);
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => {
    toggleModal(false);
    setIsLoading(false);
  }, []);

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addAccessPoint.header",
          defaultMessage: "Add New Access Point",
          description: "add access point label",
        })}
      />
      <Grid item={true} md={12} sm={12}>
        {steps && <FormWizard steps={steps} onFulfill={handleSubmit} />}
      </Grid>
      <CustomModal open={showModal}>
        <ModalContent
          yes={async () => await confirmChanges()}
          no={rejectChanges}
        />
      </CustomModal>
    </div>
  );
}
