import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { VLAN } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useVLANBase() {
  const [VLANs, setVLANs] = useState<VLAN[]>([]);
  const [VLANsFromResponse, setVLANsFromResponse] = useState<VLAN[]>([]);
  const {
    state: selectedVLAN,
    setState: setSelectedVLAN,
  } = usePersistedState<VLAN | null>("vlan", null);

  const { search } = useSearch();

  const searchVLANs = useCallback(
    (searchValue: string) => {
      setVLANs(search(VLANsFromResponse, searchValue) as VLAN[]);
    },
    [VLANsFromResponse]
  );

  return {
    searchVLANs,
    VLANs,
    setVLANs,
    VLANsFromResponse,
    setVLANsFromResponse,
    selectedVLAN,
    setSelectedVLAN,
  };
}

const { Provider, useContainer } = createContainer(useVLANBase);

export const useVLANState = useContainer;
export const VLANProvider = Provider;
