import React from "react";
import {
  ActivitySwitch,
  FloorMultiSelect,
  Input,
  TextareaAutosize,
  FloorDragAndDropSelect,
} from "../../../../common/components/Form/FormFields";
import * as Yup from "yup";

import { FormField } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";
import { FloorSelect } from "../../../../common/components/Form/FormFields/FloorSelect/FloorSelect.v2";

const FIELD_NAMES = {
  name: "name",
  floorsIds: "floorsIds",
  active: "active",
  startingFloor: "startingFloorId",
};

export const createFormFields = (data: any): FormField[] => [
  {
    name: FIELD_NAMES.name,
    label: I18n.formatMessage({
      id: "elevators.edit.label.name",
      defaultMessage: "Name",
    }),
    placeholder: I18n.formatMessage({
      id: "elevators.edit.placeholder.name",
      defaultMessage: "Type here",
    }),
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.floorsIds,
    label: I18n.formatMessage({
      id: "elevators.edit.label.floorId",
      defaultMessage: "",
    }),
    placeholder: I18n.formatMessage({
      id: "elevators.edit.placeholder.floorId",
      defaultMessage: "Type here",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: FloorDragAndDropSelect,
    data: data,
  },
  {
    name: FIELD_NAMES.startingFloor,
    label: I18n.formatMessage({
      id: "elevators.edit.label.startingFloor",
      defaultMessage: "Starting Floor",
    }),
    placeholder: I18n.formatMessage({
      id: "elevators.edit.placeholder.startingFloor",
      defaultMessage: "Type here",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: FloorSelect,
    data: data,
  },
  {
    name: FIELD_NAMES.active,
    label: I18n.formatMessage({
      id: "elevators.edit.label.active",
      defaultMessage: "Active",
    }),
    placeholder: I18n.formatMessage({
      id: "elevators.edit.placeholder.active",
      defaultMessage: "Type here",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: ActivitySwitch,
  },
];

export const ElevatorValidationRules = Yup.object().shape({
  [FIELD_NAMES.name]: Yup.string()
    .min(
      3,
      I18n.formatMessage({
        id: "elevators.edit.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "elevators.edit.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
});
