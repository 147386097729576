import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  nodata: {
    width: "100%",
    height: 300,
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid #BDCED7",
  },
  message: {
    color: theme.palette.common.dustyGray,
    fontSize: "16px",
    lineHeight: "24px",
    paddingBottom: "15px",
    whiteSpace: "pre-line",
    textAlign: "center",
  },
  icon: {
    width: 124,
    height: 124,
  },
  button: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    minWidth: 225,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
  },
  buttonHover: {
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
    },
  },
}));
