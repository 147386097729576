import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { Button, Grid, Typography } from "@material-ui/core";

import { useStyles } from "./EmptyListView.styles";
import { ROUTES } from "../../constants/Routing";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import { useFloorState } from "../../../core/context/containers/Floor.container";

import { ReactComponent as NoVlanSVG } from "../../assets/images/no-vlan.svg";
import { ReactComponent as NoUsersSVG } from "../../assets/images/no-users.svg";
import { ReactComponent as NoSensorsSVG } from "../../assets/images/no-sensors.svg";
import { ReactComponent as NoDevicesSVG } from "../../assets/images/no-devices.svg";
import { ReactComponent as NoSsidsSVG } from "../../assets/images/no-ssids.svg";
import { ReactComponent as NoImageSensorSVG } from "../../assets/images/no-ssids.svg";
import { ReactComponent as NoAccessPointsSVG } from "../../assets/images/no-accesspoints.svg";
import { ReactComponent as NoFloorsSVG } from "../../assets/images/no-floors.svg";
import { ReactComponent as NoSpaceSVG } from "../../assets/images/no-space.svg";
import { ReactComponent as NoPropertiesSVG } from "../../assets/images/no-properties.svg";
import { ReactComponent as NoElevatorBaysSVG } from "../../assets/images/no-elevatorbays.svg";
import { ReactComponent as NoElevatorsSVG } from "../../assets/images/no-elevators.svg";
import { ReactComponent as NoBeaconsSVG } from "../../assets/images/no-beacons.svg";
import { ReactComponent as NoDoorsSVG } from "../../assets/images/no-doors.svg";
import { ReactComponent as NoUserGroupSVG } from "../../assets/images/no-userGroup.svg";
import { ReactComponent as NoPermissionGroupSVG } from "../../assets/images/no-permission.svg";
import { useAuth } from "../../../core/context/containers/Auth.container";
import { availableScopes } from "../../../types/roles";
import { CustomModal, LinkButton } from "..";
import { UserGroupModal } from "../../../pages/Dashboard/Groups/components/Users/UserGroupModal";
import { PermissionGroupModal } from "../../../pages/Dashboard/Groups/components/Permission/PermissionGroupModal";
import { DoorUserGroupModal } from "../../../pages/Dashboard/Doors/components/DoorUserGroupModal";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import ExpandLessSharpIcon from "@material-ui/icons/ExpandLessSharp";
import { BulkUploadAPModal } from "../../../pages/Dashboard/AccessPoints/List/BulkUploadAPModal";
interface NoDataProps {
  viewType:
    | "vlans"
    | "floors"
    | "users"
    | "properties"
    | "ssids"
    | "imageSensor"
    | "access_points"
    | "sensors"
    | "beacons"
    | "elevators"
    | "elevatorBays"
    | "devices"
    | "doors"
    | "group"
    | "userGroup"
    | "permission"
    | "doorUserGroup"
    | "spaces";
  button?: JSX.Element;
}

const pickSVG = (
  type: NoDataProps["viewType"]
): React.FunctionComponent<React.SVGProps<SVGSVGElement>> => {
  switch (type) {
    case "users":
      return NoUsersSVG;
    case "sensors":
      return NoSensorsSVG;
    case "beacons":
      return NoBeaconsSVG;
    case "devices":
      return NoDevicesSVG;
    case "elevators":
      return NoElevatorsSVG;
    case "elevatorBays":
      return NoElevatorBaysSVG;
    case "ssids":
      return NoSsidsSVG;
    case "imageSensor":
      return NoImageSensorSVG;
    case "access_points":
      return NoAccessPointsSVG;
    case "properties":
      return NoPropertiesSVG;
    case "floors":
      return NoFloorsSVG;
    case "doors":
      return NoDoorsSVG;
    case "group":
      return NoUserGroupSVG;
    case "userGroup":
      return NoUserGroupSVG;
    case "permission":
      return NoPermissionGroupSVG;
    case "doorUserGroup":
      return NoUserGroupSVG;
    case "spaces":
      return NoSpaceSVG;
    default:
      return NoVlanSVG;
  }
};

type typographyMessage = {
  message: string;
  linkMessage: string;
};

const createTypography = (type: NoDataProps["viewType"]): typographyMessage => {
  switch (type) {
    case "properties":
      return {
        message: "There are no Properties \n",
        linkMessage: "Add New Property",
      };
    case "users":
      return {
        message: "There are no Users \n",
        linkMessage: "Add New User",
      };
    case "sensors":
      return {
        message: "There are no Sensors\n",
        linkMessage: "Add New Sensor",
      };
    case "beacons":
      return {
        message: "There are no Beacons \n",
        linkMessage: "Add New Beacon",
      };
    case "elevators":
      return {
        message: "There are no Elevators \n",
        linkMessage: "Add New Elevator",
      };
    case "elevatorBays":
      return {
        message: "There are no Elevator Bays \n",
        linkMessage: "Add New Elevator Bay",
      };
    case "ssids":
      return {
        message: "There are no SSIDs \n",
        linkMessage: "Add New SSID",
      };
    case "imageSensor":
      return {
        message: "There are no Image Sensors \n",
        linkMessage: "",
      };
    case "access_points":
      return {
        message: "There are no Access Points \n",
        linkMessage: "Add New Access Point",
      };
    case "devices":
      return {
        message: "There are no Devices detected \n",
        linkMessage: "",
      };
    case "floors":
      return {
        message: "There are no Floors \n",
        linkMessage: "Add New Floor",
      };
    case "vlans":
      return {
        message: "There are no Networks \n",
        linkMessage: "Add New Network",
      };
    case "doors":
      return {
        message: "There are no doors \n",
        linkMessage: "Add New Door",
      };
    case "group":
      return {
        message: "There are no Groups. \n",
        linkMessage: "Add New Group",
      };
    case "userGroup":
      return {
        message:
          "There are no Users. \n You can skip this step and add Users later",
        linkMessage: "Add New User",
      };
    case "permission":
      return {
        message:
          "There are no Permissions right now. \n You can skip this step and add it later",
        linkMessage: "Add New Permission",
      };
    case "doorUserGroup":
      return {
        message:
          "There are no User Groups. \n You can skip this step and add User Groups later",
        linkMessage: "Add User Group",
      };
    case "spaces":
      return {
        message: "There are no Spaces.",
        linkMessage: "Add New Space",
      };
    default:
      return {
        message: "There is no Users \n",
        linkMessage: "Add New User",
      };
  }
};

const createRedirect = (type: NoDataProps["viewType"], id: string) => {
  switch (type) {
    case "users":
      return ROUTES.ADD_USER.replace(":uid", id);
    case "sensors":
      return ROUTES.SENSORS_ADD.replace(":uid", id);
    case "beacons":
      return ROUTES.ADD_BEACON.replace(":uid", id);
    case "elevators":
      return ROUTES.ADD_ELEVATOR.replace(":uid", id);
    case "elevatorBays":
      return ROUTES.ADD_ELEVATOR_BAY.replace(":uid", id);
    case "ssids":
      return ROUTES.PROPERTY_ADD_SSID.replace(":uid", id);
    case "vlans":
      return ROUTES.V_LAN_ADD.replace(":uid", id);
    case "access_points":
      return ROUTES.PROPERTY_ADD_ACCESS_POINT.replace(":uid", id);
    case "floors":
      return ROUTES.PROPERTY_ADD_FLOORS.replace(":uid", id);
    case "properties":
      return ROUTES.ADD_PROPERTY.replace(":uid", id);
    case "doors":
      return ROUTES.FLOOR_ADD_DOORS.replace(":uid", id);
    case "spaces":
      return ROUTES.ADD_SPACE.replace(":uid", id);
    default:
      return ROUTES.PROPERTIES;
  }
};

export function EmptyListView({ viewType, button }: NoDataProps) {
  const classes = useStyles();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const auth = useAuth();
  const SVGcomponent = pickSVG(viewType);
  const [showModal, toggleModal] = useState<boolean>(false);
  const [showPermissionModal, togglePermissionModal] = useState<boolean>(false);
  const [showDoorUserGroupModal, toggleDoorUserGroupModal] = useState<boolean>(
    false
  );
  const [dropdownShow, setDropdownShow] = useState<boolean>(false);
  const [showAPModal, setShowAPModal] = useState<boolean>(false);
  const { message, linkMessage } = createTypography(viewType);

  const rejectChanges = () => toggleModal(false);
  const rejectPermissionChanges = () => togglePermissionModal(false);
  const rejectDoorUserGroupChanges = () => toggleDoorUserGroupModal(false);
  const rejectAPChanges = useCallback(() => setShowAPModal(false), []);

  const handleAPButtonChange = (value: boolean) => {
    setDropdownShow(value);
  };

  const handleSingleAP = () => {
    setDropdownShow(false);
    history.push(
      ROUTES.PROPERTY_ADD_ACCESS_POINT.replace(":uid", selectedProperty?.id!)
    );
  };

  const handleBulkAP = () => {
    setDropdownShow(false);
    setShowAPModal(true);
  };
  return (
    <Grid className={clsx(classes.nodata)} container>
      <CustomModal open={showModal}>
        <UserGroupModal no={rejectChanges} />
      </CustomModal>
      <CustomModal open={showPermissionModal}>
        <PermissionGroupModal no={rejectPermissionChanges} />
      </CustomModal>
      <CustomModal open={showDoorUserGroupModal}>
        <DoorUserGroupModal no={rejectDoorUserGroupChanges} />
      </CustomModal>
      <CustomModal open={showAPModal}>
        <BulkUploadAPModal yes={rejectAPChanges} />
      </CustomModal>
      <Grid item>
        <SVGcomponent className={classes.icon} />
      </Grid>
      <Grid item>
        <Typography className={classes.message}>{message}</Typography>
      </Grid>
      <Grid item>
        {auth?.canSee(
          auth?.user?.role!,
          `${viewType}:create` as availableScopes[number],
          button ? (
            button
          ) : viewType === "userGroup" ? (
            <Button
              id="userGroupBtn"
              className={classes.button}
              onClick={() => toggleModal(true)}
            >
              {linkMessage}
            </Button>
          ) : viewType === "permission" ? (
            <Button
              id="permissionBtn"
              className={classes.button}
              onClick={() => togglePermissionModal(true)}
            >
              {linkMessage}
            </Button>
          ) : viewType === "doorUserGroup" ? (
            <Button
              id="doorUserGroupBtn"
              className={classes.button}
              onClick={() => toggleDoorUserGroupModal(true)}
            >
              {linkMessage}
            </Button>
          ) : viewType === "access_points" ? (
            <Grid direction="column">
              <Button
                className={clsx(classes.button, classes.buttonHover)}
                onClick={() => handleAPButtonChange(!dropdownShow)}
                disableRipple={true}
                style={{
                  borderRadius: dropdownShow ? "21px 21px 0px 0px" : "35px",
                  justifyContent: "initial",
                }}
              >
                Add New Access Point
                {dropdownShow ? (
                  <ExpandLessSharpIcon style={{ marginLeft: "5px" }} />
                ) : (
                  <ExpandMoreSharpIcon style={{ marginLeft: "5px" }} />
                )}
              </Button>
              {dropdownShow ? (
                <>
                  <Button
                    className={clsx(classes.button, classes.buttonHover)}
                    onClick={() => handleSingleAP()}
                    disableRipple={true}
                    style={{ borderRadius: "0px", justifyContent: "initial" }}
                    disableFocusRipple={true}
                  >
                    Single Access Point
                  </Button>
                  <Button
                    className={clsx(classes.button, classes.buttonHover)}
                    onClick={() => handleBulkAP()}
                    disableRipple={true}
                    style={{
                      borderRadius: dropdownShow ? "0px 0px 21px 21px" : "0px",
                      justifyContent: "initial",
                    }}
                  >
                    Multiple Access Points
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Grid>
          ) : (
            <LinkButton
              to={createRedirect(viewType, selectedProperty?.id!)}
              label={linkMessage}
            />
          )
        )}
      </Grid>
    </Grid>
  );
}
