import { AxiosResponse } from "axios";
import { parseImageResponse } from "../../../common/utils/Http.utils";
import API from "../../api/api";

const AvatarRestServiceCreate = () => {
  const uploadUserAvatar = async (avatar: FormData, email: string) => {
    const response = await API.post(`users/${email}/avatar`, avatar);
    return response;
  };

  const removeUserAvatar = async (email: string): Promise<AxiosResponse> => {
    const response = await API.delete(`users/${email}/avatar`);
    return response;
  };

  const getUserAvatar = async (email: string) => {
    try {
      const response = await API.get(`users/${email}/avatar`, {
        responseType: "blob",
      });
      return await parseImageResponse(response);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    uploadUserAvatar,
    removeUserAvatar,
    getUserAvatar,
  };
};

export const UserAvatarRestService = AvatarRestServiceCreate();
