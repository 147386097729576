import { gql } from "@apollo/client";

const typeDefs = gql`
  enum TimeRangeType {
    DAY
    FIVE_MINUTES
    HOUR
    MINUTE
    QUARTER
    WEEK
  }
`;

export const DASHBOARD_QUERIES = {
  connectedDevicesAndPeopleByFloorId: gql`
    query ($request: DashboardRequestInput) {
      connectedDevicesAndPeopleByFloorId(request: $request) {
        actuallyConnectedPeopleCount
        offlinePeopleCount
        desktopDevicesCount
        mobileDevicesCount
        otherDevicesCount
        sensedDevicesCount
      }
    }
  `,
  devicesTypesByFloorId: gql`
    query ($request: TimeRangedDashboardFloorRequestInput) {
      devicesTypesByFloorId(request: $request) {
        devicesCountWithTimeRange {
          from
          desktopDevicesCount
          mobileDevicesCount
          otherDevicesCount
        }
      }
    }
  `,
  averageLoadByFloorId: gql`
    query ($request: TimeRangedDashboardFloorRequestInput) {
      averageLoadByFloorId(request: $request) {
        averageLoadByDate {
          from
          accessPointId
          averageLoad
        }
      }
    }
  `,
  connectedDevicesAndPeopleByPropertyId: gql`
    query ($request: PropertyDashboardRequestInput) {
      connectedDevicesAndPeopleByPropertyId(request: $request) {
        actuallyConnectedPeopleCount
        offlinePeopleCount
        desktopDevicesCount
        mobileDevicesCount
        otherDevicesCount
        sensedDevicesCount
      }
    }
  `,
  devicesTypesByPropertyId: gql`
    query ($request: TimeRangedDashboardPropertyRequestInput) {
      devicesTypesByPropertyId(request: $request) {
        devicesCountWithTimeRange {
          from
          desktopDevicesCount
          mobileDevicesCount
          otherDevicesCount
        }
      }
    }
  `,
  averageLoadByPropertyId: gql`
    query ($request: TimeRangedDashboardPropertyRequestInput) {
      averageLoadByPropertyId(request: $request) {
        averageLoadByDate {
          from
          accessPointId
          averageLoad
        }
      }
    }
  `,
  networkRealTimeOccupancy: gql`
    query ($floorId: String!) {
      networkRealTimeOccupancy(request: { floorId: $floorId }) {
        accessPoint {
          id
          active
          baseMacAddress
          coordinateX
          coordinateY
          dataCenter
          deviceType
          ipAddress
          name
          serialNumber
          floorId
          radio1 {
            band
            macAddress
            ssids {
              name
              id
            }
          }
          radio2 {
            band
            macAddress
            ssids {
              name
              id
            }
          }
        }
        spaceLoad
        applicationUsage {
          category
          percentageUsage
        }
      }
    }
  `,
  networkOccupancyByFloorId: gql`
    query ($request: TimeRangedDashboardFloorRequestInput) {
      networkOccupancyByFloorId(request: $request) {
        accessPoint {
          id
          active
          baseMacAddress
          coordinateX
          coordinateY
          dataCenter
          deviceType
          ipAddress
          name
          serialNumber
          floorId
          offlineFromInMinutes
          radio1 {
            id
            band
            macAddress
            ssids {
              name
              id
            }
          }
          radio2 {
            id
            band
            macAddress
            ssids {
              name
              id
            }
          }
        }
        spaceLoad
        isPinned
        applicationUsage {
          category
          percentageUsage
        }
      }
    }
  `,
  networkOccupancyByFloorIdTimeline: gql`
    query ($request: TimeRangedDashboardFloorRequestInput) {
      networkOccupancyByFloorIdTimeline(request: $request) {
        accessPoint {
          id
          active
          baseMacAddress
          coordinateX
          coordinateY
          dataCenter
          deviceType
          ipAddress
          name
          serialNumber
          floorId
          radio1 {
            band
            macAddress
            ssids {
              name
              id
            }
          }
          radio2 {
            band
            macAddress
            ssids {
              name
              id
            }
          }
        }
        timeRangeType
        timeline {
          from
          to
          spaceLoad
        }
        isPinned
      }
    }
  `,
  applicationType: gql`
    query (
      $propertyId: String!
      $from: Instant!
      $to: Instant!
      $vlanId: String
    ) {
      applicationUsageInfoForProperty(
        request: { propertyId: $propertyId, from: $from, to: $to }
        vlanId: $vlanId
      ) {
        category
        percentageUsage
      }
    }
  `,
  getApplicationTypeByPropertyId: gql`
    query ($propertyId: String!, $from: Instant!, $to: Instant!) {
      applicationTypeByPropertyId(
        request: { propertyId: $propertyId, from: $from, to: $to }
      ) {
        category
        percentageUsage
      }
    }
  `,
  densityTime: gql`
    query ($request: TimeRangedDashboardAccessPointRequestInput) {
      densityTimelineByAccessPointId(request: $request) {
        accessPointName
        densityTimeline {
          connectedDevicesCount
          from
          to
        }
        isPinned
        highDensityTime
        lowDensityTime
        mediumDensityTime
        timeRangeType
        averageDwellTime
      }
    }
  `,
  devicesByFloorId: gql`
    query ($request: TimeRangedDashboardDeviceRequestInput) {
      devicesByFloorId(request: $request) {
        apCount
        apDensity
        aqCount
        aqDensity
        cameraCount
        zoneCount
        zoneCensus
        tripwireCount
        tripwireIn
        tripwireOut
        isNetflowActive
        netflow
        netFlowSpacesCount
        startCount
        isAPPinned
        isAQPinned
        isZonePinned
        isTripwirePinned
      }
    }
  `,
  statisticTimelineForAnyTripwires: gql`
    query (
      $from: Instant
      $spaceId: String
      $timeRangeType: TimeRangeType
      $to: Instant
      $floorId: String
      $deviceType: DashboardDeviceType
    ) {
      statisticTimelineForAnyTripwires(
        request: {
          from: $from
          spaceId: $spaceId
          timeRangeType: $timeRangeType
          to: $to
          floorId: $floorId
          deviceType: $deviceType
        }
      ) {
        spaceDevice {
          id
          resetTime
          netflowActive
          name
        }
        netflowTimeLine {
          from
          to
          netFlow {
            correctedNetOccupancy
            currentOccupancy
            errorCorrection
            in
            out
            synthIns
            synthOuts
            time_id
          }
        }
        tripwires {
          cameraZone {
            id
            name
            zone
            active
            flowType
          }
          tripwireInData {
            value
            from
            field
          }
          tripwireOutData {
            value
            from
            field
          }
        }
      }
    }
  `,
  dwelltimeTimeline: gql`
    query dwelltimeTimelineForFloorsOrSpaces(
      $request: TimeRangedDashboardDeviceRequestInput
    ) {
      dwelltimeTimelineForFloorsOrSpaces(request: $request) {
        avgDwellTime
        to
        from
      }
    }
  `,
  dashboardCardAverageAndCount: gql`
    query dashboardCardAverageAndCount(
      $request: TimeRangedDashboardDeviceRequestInput
    ) {
      dashboardCardAverageAndCount(request: $request) {
        apCount
        apDensity
        isAPPinned
      }
    }
  `,
  dashboardCardAverageAndCountTripwire: gql`
    query dashboardCardAverageAndCount(
      $request: TimeRangedDashboardDeviceRequestInput
    ) {
      dashboardCardAverageAndCount(request: $request) {
        tripwireCount
        startCount
        tripwireIn
        tripwireOut
        endCount
        isNetflowActive
        isTripwirePinned
        netFlowSpacesCount
        cameraCount
      }
    }
  `,
  dashboardCardAverageAndCountZone: gql`
    query dashboardCardAverageAndCount(
      $request: TimeRangedDashboardDeviceRequestInput
    ) {
      dashboardCardAverageAndCount(request: $request) {
        cameraCount
        zoneCount
        zoneCensus
        isZonePinned
      }
    }
  `,
};
