export interface NotificationDropdownItemProps {
  notification: CurrentNotificationsResponse;
}

export interface CurrentNotificationsResponse {
  active: Boolean;
  conditionType: NotificationConditionType;
  dataType: NotificationDataType;
  deviceName: String;
  floor: FloorResponse;
  id: string;
  property: PropertyEntity;
  lastAvailableCondition: String;
  notificationReceivedMinutesAgo: Number;
  resourceType: NotificationResourceType;
  triggerDevice: String;
  maxDwellTime: String;
}

interface PropertyEntity {
  siteName: String;
  id: String;
}

export enum NotificationConditionType {
  HEALTHY = "HEALTHY",
  MODERATE = "MODERATE",
  POOR = "POOR",
}

export enum NotificationDataType {
  CO2 = "CO2",
  HUMIDITY = "HUMIDITY",
  LIGHT = "LIGHT",
  NOISE = "NOISE",
  PM = "PM",
  SCORE = "SCORE",
  TEMPERATURE = "TEMPERATURE",
  TVOCS = "TVOCS",
  DWELL_TIME = "DWELL_TIME",
}

export enum NotificationResourceType {
  ACCESSPOINT = "ACCESSPOINT",
  AIRQUALITY = "AIRQUALITY",
  CAMERA = "CAMERA",
  DOOR = "DOOR",
  SPACE = "SPACE",
  TRIPWIRE = "TRIPWIRE",
  ZONE = "ZONE",
}

export interface FloorResponse {
  accessPointsRadius: Number;
  active: Boolean;
  capacity: Number;
  floorConfigured: Boolean;
  floorNumber: Number;
  floorPlanUploaded: Boolean;
  id: String;
  name: String;
  propertyId: String;
  signalStrength: Number;
}
