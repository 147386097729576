import { gql } from "@apollo/client";

export const ELEVATOR_BAYS_MUTATIONS = {
  createElevatorBay: gql`
    mutation createElevatorBay(
      $active: Boolean!
      $name: String!
      $floorId: String!
      $beaconId: String!
      $capacity: Int
      $elevatorsIds: [String]
    ) {
      createElevatorBay(
        request: {
          active: $active
          name: $name
          floorId: $floorId
          beaconId: $beaconId
          capacity: $capacity
          elevatorsIds: $elevatorsIds
        }
      ) {
        name
      }
    }
  `,
  updateElevatorBay: gql`
    mutation updateElevatorBay(
      $active: Boolean!
      $name: String!
      $floorId: String!
      $beaconId: String!
      $capacity: Int
      $elevatorsIds: [String]
      $id: String!
    ) {
      updateElevatorBay(
        request: {
          active: $active
          name: $name
          floorId: $floorId
          beaconId: $beaconId
          capacity: $capacity
          elevatorsIds: $elevatorsIds
          id: $id
        }
      )
    }
  `,
  deleteElevatorBay: gql`
    mutation($id: String!) {
      deleteElevatorBay(id: $id)
    }
  `,
};

export const ELEVATOR_BAYS_QUERIES = {
  elevatorBaysByPropertyId: gql`
    query($propertyId: String!) {
      elevatorBaysByPropertyId(propertyId: $propertyId) {
        id
        name
        active
        capacity
        floor {
          id
          name
          floorNumber
        }
        beacon {
          id
          name
        }
        elevators {
          id
          name
        }
      }
    }
  `,
};
