import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Formik, Form as FormikForm } from "formik";
import {
  Grid,
  Button,
  Input,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

import {
  PageHeader,
  FormField,
  ActivitySwitch,
} from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { AddFloorPlan } from "./AddFloorPlan";
import { useStyles } from "./AddFloor.styles";
import { FormFieldInputType } from "../../../../types";
import { useMutation, useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import I18n from "../../../../core/services/I18n.service";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { useFloorState } from "../../../../core/context/containers/Floor.container";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const FIELD_NAMES = {
  name: "name",
  floorNumber: "floorNumber",
  floorPlanUrl: "floorPlanUrl",
  capacity: "capacity",
  active: "active",
  signalStrength: "signalStrength",
};

export interface floorPlanFile {
  name: string | undefined;
  url: string | undefined;
  data: File | undefined;
}

export function AddFloor() {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { selectedProperty } = usePropertiesState();
  const { selectedFloor, floors, setFloors } = useFloorState();
  const [possibleFloors, setPossibleFloors] = useState<number[]>([]);
  const { loading, data } = useQuery(GQLService.queries.allFloors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingAllowedFloors,
    data: allowedFloorNumbersData,
    error,
  } = useQuery(GQLService.queries.allowedFloorNumbers, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    !loadingAllowedFloors &&
      setPossibleFloors(allowedFloorNumbersData.allowedFloorNumbers);
  }, [loadingAllowedFloors, allowedFloorNumbersData]);

  useEffect(
    () => {
      !loading && setFloors(data?.floorsByPropertyId || []);
    },
    [data, loading] //eslint-disable-line
  );

  const history = useHistory();

  const [floorPlanUrl, setFloorPlanUrl] = useState<floorPlanFile | undefined>(
    undefined
  );
  const [addFloor] = useMutation(GQLService.mutations.addFloor);

  const [floorNumber, setNewFloorNumber] = useState<number | string>("Select");

  const handleSelectFloor = useCallback(
    (e, setFieldValue) => {
      setNewFloorNumber(+e.target.value);
      setFieldValue(FIELD_NAMES.floorNumber, +e.target.value);
    },
    [selectedProperty, selectedFloor] // eslint-disable-line
  );

  const handleSubmit = useCallback(
    (formData) => {
      addFloor({
        variables: {
          ...formData,
          propertyId: selectedProperty?.id!,
          accessPointsRadius: 0,
        },
      }).then(async (response) => {
        if (floorPlanUrl?.data) {
          const formData = new FormData();
          formData.append("data", floorPlanUrl?.data);
          await GQLService.rest.images.uploadFloorPlan(
            formData,
            selectedProperty?.id!,
            response.data.createFloor.id
          );
          enqueueSnackbar(
            formatMessage({
              id: "floors.addFloor.withFloorPlan.snackbar.success",
              defaultMessage: "Floor added successfully",
            }),
            {
              variant: "success",
            }
          );
          history.push(ROUTES.FLOORS.replace(":uid", selectedProperty?.id!));
        } else {
          enqueueSnackbar(
            formatMessage({
              id: "floors.addFloor.withoutFloorPlan.snackbar.success",
              defaultMessage: "Floor added successfully",
            }),
            {
              variant: "success",
            }
          );
          history.push(ROUTES.FLOORS.replace(":uid", selectedProperty?.id!));
        }
      });
    },
    [floorPlanUrl] // eslint-disable-line
  );

  const handleImageRemove = useCallback(
    () => {
      setFloorPlanUrl({ name: undefined, url: undefined, data: undefined });
    },
    [selectedProperty] // eslint-disable-line
  );

  const handleImageAdd = useCallback(
    async (floorPlanUrl: floorPlanFile, setFieldValue) => {
      setFloorPlanUrl(floorPlanUrl);
      setFieldValue(FIELD_NAMES.floorPlanUrl, floorPlanUrl.url);
    },
    [selectedProperty] // eslint-disable-line
  );

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addFloor.header",
          defaultMessage: `${selectedFloor ? "Edit floor" : "Add a floor"}`,
          description: "add floor header",
        })}
      />
      <Grid item={true} md={6} sm={12}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            [FIELD_NAMES.floorNumber]: selectedProperty!.siteNrOfFloors + 1,
            [FIELD_NAMES.signalStrength]: -75,
            [FIELD_NAMES.active]: true,
          }}
          validationSchema={addFloorSchema}
        >
          {({ values, setFieldValue }) => (
            <FormikForm className={classes.form}>
              <Grid container={true} spacing={1}>
                <Grid item={true} sm={6} md={6}>
                  <InputLabel className={classes.inputLabel}>
                    {formatMessage({
                      id: "floors.addFloor.label.floorNumber",
                      defaultMessage: "Floor Number",
                    })}
                  </InputLabel>
                  <Select
                    key={FIELD_NAMES.floorNumber}
                    classes={{
                      root: classes.select,
                      select: classes.blackText,
                    }}
                    fullWidth={true}
                    value={floorNumber}
                    disableUnderline={true}
                    onChange={(e) => handleSelectFloor(e, setFieldValue)}
                  >
                    {possibleFloors?.map((nr, i) => (
                      <MenuItem
                        key={i}
                        value={nr}
                        classes={{
                          root: classes.option,
                          selected: classes.selected,
                        }}
                      >
                        {nr}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <FormField
                  key={FIELD_NAMES.name}
                  field={{
                    name: FIELD_NAMES.name,
                    label: I18n.formatMessage({
                      id: "floors.addFloor.form.label.floorName",
                      defaultMessage: "Floor Name",
                    }),
                    placeholder: I18n.formatMessage({
                      id: "floors.addFloor.form.placeholder.floorName",
                      defaultMessage: "Type here",
                    }),
                    Component: Input,
                    type: FormFieldInputType.string,
                    gridStyle: { sm: 8, md: 8 },
                  }}
                />
                <div style={{ width: "100%" }} />
                <FormField
                  key={FIELD_NAMES.capacity}
                  field={{
                    name: FIELD_NAMES.capacity,
                    label: I18n.formatMessage({
                      id: "floors.addFloor.form.label.floorCapacity",
                      defaultMessage: "Floor Capacity",
                    }),
                    placeholder: I18n.formatMessage({
                      id: "floors.addFloor.form.placeholder.floorCapacity",
                      defaultMessage: "0",
                    }),
                    required: true,
                    Component: Input,
                    type: FormFieldInputType.number,
                    gridStyle: { sm: 4, md: 4 },
                  }}
                />
                <FormField
                  key={FIELD_NAMES.signalStrength}
                  field={{
                    name: FIELD_NAMES.signalStrength,
                    label: I18n.formatMessage({
                      id: "floors.addFloor.form.label.signalStrength",
                      defaultMessage: "Signal Strength",
                    }),
                    placeholder: I18n.formatMessage({
                      id: "floors.addFloor.form.placeholder.signalStrength",
                      defaultMessage: "Type here",
                    }),
                    Component: Input,
                    type: FormFieldInputType.number,
                    gridStyle: { sm: 4, md: 4 },
                  }}
                />
                <FormField
                  key={FIELD_NAMES.active}
                  field={{
                    name: FIELD_NAMES.active,
                    label: I18n.formatMessage({
                      id: "floors.addFloor.form.label.active",
                      defaultMessage: "Active Floor",
                    }),
                    placeholder: I18n.formatMessage({
                      id: "floors.addFloor.form.placeholder.active",
                      defaultMessage: "Type here",
                    }),
                    Component: ActivitySwitch,
                    type: FormFieldInputType.number,
                  }}
                />

                <AddFloorPlan
                  name={FIELD_NAMES.floorPlanUrl}
                  currentUrl={undefined}
                  onSaveComplete={(floorPlanUrl) =>
                    handleImageAdd(floorPlanUrl, setFieldValue)
                  }
                  onRemoveComplete={handleImageRemove}
                  previewStyle={classes.img}
                  currentFileObject={floorPlanUrl}
                  label={formatMessage({
                    id: "floors.addFloor.uploadPlan",
                    defaultMessage: "Upload floor plan",
                  })}
                />
              </Grid>
              <Button type="submit" className={classes.submitBtn}>
                {formatMessage({
                  id: "form.button.update",
                  defaultMessage: "Save and add",
                  description: "form save button label",
                })}
              </Button>
            </FormikForm>
          )}
        </Formik>
      </Grid>
    </div>
  );
}

export const addFloorSchema = Yup.object().shape({
  [FIELD_NAMES.name]: Yup.string()
    .min(
      6,
      I18n.formatMessage({
        id: "floors.addFloor.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "floors.addFloor.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .nullable(),
  [FIELD_NAMES.floorNumber]: Yup.number().nullable(),
  [FIELD_NAMES.capacity]: Yup.number()
    .min(
      0,
      I18n.formatMessage({
        id: "floors.addFloor.validation.capacity.small",
        defaultMessage: "Too small!",
      })
    )
    .required(" "),
  [FIELD_NAMES.active]: Yup.boolean(),
});
