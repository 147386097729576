import { AxiosResponse } from "axios";
import { parseImageResponse } from "../../../common/utils/Http.utils";
import API from "../../api/api";

const CoverPhotoRestServiceCreate = () => {
  const uploadCoverPhoto = async (
    coverPhoto: FormData,
    propertyId: string
  ): Promise<AxiosResponse> => {
    const response = await API.post(
      `properties/${propertyId}/image`,
      coverPhoto
    );
    return response;
  };

  const removeCoverPhoto = async (propertyId: string): Promise<void> => {
    await API.delete(`properties/${propertyId}/image`);
    return;
  };

  const getCoverPhoto = async (propertyId: string): Promise<any> => {
    try {
      const response = await API.get(`properties/${propertyId}/image`, {
        responseType: "blob",
      });
      return await parseImageResponse(response);
    } catch (err) {
      return {
        exists: false,
        source: undefined,
        status: 404,
      };
    }
  };

  return {
    uploadCoverPhoto,
    removeCoverPhoto,
    getCoverPhoto,
  };
};

export const CoverPhotoRestService = CoverPhotoRestServiceCreate();
