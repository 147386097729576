import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  ClickAwayListener,
  Select,
  MenuItem,
} from "@material-ui/core";
import { LinearChartForDensity } from "../../../common/components/Charts/LinearCharts/LinearChartForDensity";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import moment from "moment";
import { BarChartForDensity } from "../../../common/components/Charts/BarCharts/BarChartForDensity";
import { ReactComponent as PinBlack } from "../../../common/assets/images/pin_black.svg";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";
import { useUserState } from "../../../core/context/containers/User.container";
import { TIMEZONES } from "../../../common/constants/Timezones";
import { UnpinModal } from "./UnpinModal";
import { DashboardDeviceType } from "../../../types";
import { CustomModal } from "../../../common/components";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { CollapsibleMyPin } from "./CollapsibleMyPin";

const useStyles = makeStyles((theme) => ({
  displayGrid: {
    display: "flex",
    marginLeft: "16px",
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "4px",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "8px",
  },
  statisticContainer: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0px 4px 13px rgba(135, 135, 135, 0.3)",
    paddingBottom: "24px",
    paddingRight: "10px",
  },
  statisticContainerTitleGrid: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "14px",
  },
  statisticContainerTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginLeft: "14px",
    marginBottom: "16px",
  },
  pinIcon: {
    transform: "rotate(45deg)",
    height: "16px",
  },
  densityTimeGrid: {
    marginBottom: "16px",
    marginTop: "20px",
  },
  dwellTime: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: "14px",
  },
  select: {
    height: 16,
    minWidth: 100,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  menu: {
    boxShadow: "none !important",
    borderRadius: "0 0 8px 8px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  blackText: {
    display: "inline-block",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: 9,
  },
  list: {
    backgroundColor: theme.palette.common.InputBg,
  },
}));

export function APMyPin({ item, timeZone, loadingMyPin, removedCard }: any) {
  const classes = useStyles();
  const { selectedProperty, selectedPropertyTimeZone } = usePropertiesState();
  const [apDensityData, setAPDensityData] = useState<any>();
  const { userEmail } = useUserState();
  const [newTimeZone, setNewTimeZone] = useState<any>();
  const [toggleUnpinModal, setToggleUnpinModal] = useState<boolean>(false);

  let parsedRequest = JSON.parse(item.queryData).request;
  const [selectedTime, setSelectedTime] = useState(parsedRequest.selectedTime);

  const [
    getDensityTimeForAccessPoint,
    { loading: loadingAP, error: errorAP, data: responseDataAP },
  ] = useLazyQuery(GQLService.queries.densityTime, {
    fetchPolicy: "network-only",
  });

  const timeRange = useMemo(() => {
    if (selectedTime! <= 15) return "MINUTE";
    if (selectedTime! <= 60) return "QUARTER";
    if (selectedTime! <= 1440) return "HOUR";
    return "DAY";
  }, [selectedTime]);

  const timeInMinutesFilter = [
    { value: 15, label: "Last 15 minutes" },
    { value: 60, label: "Last hour" },
    { value: 24 * 60, label: "Last 24 hours" },
    { value: 7 * 24 * 60, label: "Last 7 days" },
    { value: 30 * 24 * 60, label: "Last 30 days" },
  ];

  const getTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === 10080 || selectedTime === 43200) {
      if (timeZone) {
        newTimeTo = moment.tz(timeZone).startOf("day").format();
        newTimeFrom = moment
          .tz(newTimeTo, timeZone)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment.utc().startOf("day").format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    } else {
      if (timeZone) {
        newTimeTo = moment
          .tz(timeZone)
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, timeZone)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, timeZone]);

  const get15minTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === 15) {
      if (timeZone) {
        newTimeTo = moment
          .tz(timeZone)
          .startOf("minute")
          .subtract(3, "minutes")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, timeZone)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf("minute")
          .subtract(3, "minutes")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, timeZone]);

  useEffect(() => {
    if (selectedTime!) {
      getDensityTimeForAccessPoint({
        variables: {
          request: {
            accessPointId: parsedRequest.deviceId,
            floorId: parsedRequest.floorId,
            from:
              selectedTime === 15
                ? get15minTimeParams().newTimeFrom
                : getTimeParams().newTimeFrom,
            to:
              selectedTime === 15
                ? get15minTimeParams().newTimeTo
                : getTimeParams().newTimeTo,
            timeRangeType: timeRange,
            userEmail: userEmail,
          },
        },
      });
    }
  }, [
    item,
    getDensityTimeForAccessPoint,
    selectedProperty,
    timeRange,
    selectedTime,
    parsedRequest.deviceId,
    parsedRequest.floorId,
    getTimeParams,
    userEmail,
    get15minTimeParams,
  ]);

  useEffect(() => {
    if (!loadingAP && responseDataAP) {
      setAPDensityData(responseDataAP?.densityTimelineByAccessPointId!);
    }
  }, [responseDataAP, loadingAP]);

  useEffect(() => {
    const findTimeZone = TIMEZONES.find((item) => item.abbr === timeZone);
    setNewTimeZone(findTimeZone);
  }, [timeZone]);

  const dwellTimeFormat = useMemo(() => {
    if (apDensityData?.averageDwellTime) {
      if (apDensityData?.averageDwellTime > 3600) {
        return "HH [hrs] mm [min]";
      } else {
        return "mm [min]";
      }
    }
    return "";
  }, [apDensityData]);

  const handleTimeChange = useCallback(
    (e) => {
      setSelectedTime(e.target.value);
    },
    [setSelectedTime]
  );

  return (
    <>
      <Grid>
        {!loadingMyPin && <CollapsibleMyPin item={item} />}
        <ClickAwayListener onClickAway={(e) => setToggleUnpinModal(false)}>
          <Grid className={classes.statisticContainer}>
            <Grid className={classes.statisticContainerTitleGrid}>
              <Typography className={classes.statisticContainerTitle}>
                Access Point: Occupancy during time
              </Typography>
              <PinBlack
                fill="#3f51b5"
                className={classes.pinIcon}
                onClick={() => setToggleUnpinModal(true)}
              />
            </Grid>
            <Grid className={classes.displayGrid}>
              <Typography
                className={classes.title}
                style={{ marginTop: "8px" }}
              >
                Duration:
              </Typography>
              <Select
                classes={{
                  root: classes.select,
                  select: classes.blackText,
                  icon: classes.icon,
                }}
                name="duration"
                value={selectedTime}
                disableUnderline={true}
                onChange={handleTimeChange}
                IconComponent={ExpandMoreSharpIcon}
                MenuProps={{
                  classes: {
                    paper: classes.menu,
                    list: classes.list,
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {timeInMinutesFilter.map((v) => (
                  <MenuItem
                    key={v.value}
                    value={v.value}
                    classes={{
                      root: classes.option,
                      selected: classes.selected,
                    }}
                  >
                    {v.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <LinearChartForDensity
              data={apDensityData?.densityTimeline?.map((d: any, i: number) => {
                return {
                  x:
                    apDensityData?.timeRangeType! === "DAY"
                      ? moment.tz(d.from, timeZone).format("DD MMM ddd")
                      : moment.tz(d.from, timeZone).format("LT"),
                  y: d.connectedDevicesCount,
                };
              })}
              spaceLoad={{
                low: item.property.spaceLoadMediumMin,
                high: item.property.spaceLoadMediumMax,
              }}
              loading={loadingAP}
              error={errorAP}
            />
            <Grid className={classes.densityTimeGrid}>
              <Typography className={classes.statisticContainerTitle}>
                Occupancy time
              </Typography>
            </Grid>
            <BarChartForDensity
              data={[
                {
                  x: "Low",
                  y: apDensityData?.lowDensityTime!,
                  fill: "rgba(79, 158, 83, 0.15)",
                },
                {
                  x: "Medium",
                  y: apDensityData?.mediumDensityTime!,
                  fill: "rgba(238, 170, 70, 0.2)",
                },
                {
                  x: "High",
                  y: apDensityData?.highDensityTime!,
                  fill: "rgba(176, 0, 31, 0.2)",
                },
              ]}
              loading={loadingAP}
              error={errorAP}
            />
            {!loadingAP ? (
              <Grid className={classes.densityTimeGrid}>
                <Typography
                  className={classes.statisticContainerTitle}
                  style={{ marginBottom: "0px" }}
                >
                  Dwell Time
                </Typography>
                <Grid>
                  <Typography className={classes.dwellTime}>
                    {apDensityData?.averageDwellTime
                      ? moment()
                          .startOf("day")
                          .seconds(apDensityData?.averageDwellTime)
                          .format(dwellTimeFormat)
                      : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </ClickAwayListener>
      </Grid>
      <CustomModal open={toggleUnpinModal}>
        <UnpinModal
          setToggleModal={setToggleUnpinModal}
          deviceType={DashboardDeviceType.ACCESSPOINT}
          deviceId={parsedRequest.deviceId}
          Type="MyPins"
          removedCard={removedCard}
        />
      </CustomModal>
    </>
  );
}
