import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";

import { ROUTES } from "../../common/constants/Routing";
import { AccessPoints } from "../../pages/Dashboard/AccessPoints/List/AccessPoints";
import {
  EditProperty,
  AddProperty,
} from "../../pages/Dashboard/Properties/index";
import { Property } from "../../pages/Dashboard/Properties/View/Property";
import { AccessPointDetails } from "../../pages/Dashboard/AccessPoints/View/AccessPointDetails";
import { AccessPointEditView } from "../../pages/Dashboard/AccessPoints/Edit/Edit";
import { Add } from "../../pages/Dashboard/AccessPoints/Add/Add";
import { VLans } from "../../pages/Dashboard/VLAN/List/List";
import { AddVlan } from "../../pages/Dashboard/VLAN/Add/Add";
import { VLanView } from "../../pages/Dashboard/VLAN/View/View";
import { EditVlan } from "../../pages/Dashboard/VLAN/Edit/Edit";
import { SSIDs } from "../../pages/Dashboard/SSID/List/List";
import { SSIDView } from "../../pages/Dashboard/SSID/View/View";
import { SSIDEditView } from "../../pages/Dashboard/SSID/Edit/Edit";
import { AddSSID } from "../../pages/Dashboard/SSID/Add/Add";
import { Floors } from "../../pages/Dashboard/Floors/Floors/Floors";
import { AddFloor } from "../../pages/Dashboard/Floors/AddFloor/AddFloor";
import { EditFloorView } from "../../pages/Dashboard/Floors/EditViewFloor/EditViewFloor";
import { useAuth } from "../context/containers/Auth.container";
import { Role } from "../../types";
import { AirQualitySensors } from "../../pages/Dashboard/AirQualitySensors/List/List";
import { AddSensor } from "../../pages/Dashboard/AirQualitySensors/Add/Add";
import { SensorView } from "../../pages/Dashboard/AirQualitySensors/View/View";
import { EditSensor } from "../../pages/Dashboard/AirQualitySensors/Edit/Edit";
import BeaconsRouter from "./BeaconsRouter";
import ElevatorsRouter from "./ElevatorsRouter";
import ElevatorBaysRouter from "./ElevatorBaysRouter";
import DevicesRouter from "./DevicesRouter";
import { UpNextAppView } from "../../pages/Dashboard/Properties/View/components/UpNextAppSettings/UpNextAppSettings";
import { EditUpNextApp } from "../../pages/Dashboard/Properties/Edit/UpNextAppSettings/UpNextAppSettings";
import { ImagesSensors } from "../../pages/Dashboard/ImagesSensors/List/List";
import { ImageSensorEditView } from "../../pages/Dashboard/ImagesSensors/Edit/Edit";
import { ZoneEdit } from "../../pages/Dashboard/ImagesSensors/Edit/components/Zone/ZoneEdit";
import { TripWireEdit } from "../../pages/Dashboard/ImagesSensors/Edit/components/TripWire/TripWireEdit";
import { AddDoor } from "../../pages/Dashboard/Doors/AddDoor/Add";
import { Doors } from "../../pages/Dashboard/Doors/Doors/Doors";
import { EditDoor } from "../../pages/Dashboard/Doors/EditDoor/Edit";
import { AddGroups } from "../../pages/Dashboard/Groups/Add/Add";
import Groups from "../../pages/Dashboard/Groups/List/Groups";
import { SpaceLoadSettings } from "../../pages/Dashboard/Properties/View/components/SpaceloadSettings/SpaceloadSettings";
import { EditSensorThreshold } from "../../pages/Dashboard/Properties/Edit/EditSensorThreshold";
import { ReservationSettings } from "../../pages/Dashboard/Properties/View/components/ReservationsSettings/ReservationsSettings";
import { EditReservationSettings } from "../../pages/Dashboard/Properties/Edit/EditReservationSettings";
import Spaces from "../../pages/Dashboard/Spaces/List/List";
import { AddSpaces } from "../../pages/Dashboard/Spaces/Add/Add";
import { SpacesView } from "../../pages/Dashboard/Spaces/View/View";
import { EditSpace } from "../../pages/Dashboard/Spaces/Edit/Edit";
import Notifications from "../../pages/Dashboard/Notifications/Add/Notifications";
import { ZoneView } from "../../pages/Dashboard/ImagesSensors/View/components/Zone/ZoneView";
import { TripWireView } from "../../pages/Dashboard/ImagesSensors/View/components/TripWire/TripWireView";
import { CardReaders } from "../../pages/Dashboard/CardReaders/CardReaders";

const Properties = lazy(
  () => import("../../pages/Dashboard/Properties/List/Properties")
);

export const PROPERTY_MODULE_ROUTES = {
  NOTIFICATIONS: "/dashboard/properties/:uid/notification",
  PROPERTIES: "/dashboard/properties",
  ADD_PROPERTY: "/dashboard/properties/property/add",
  PROPERTY: "/dashboard/properties/:uid",
  EDIT_PROPERTY: "/dashboard/properties/:uid/edit",
  FLOORS: "/dashboard/properties/:uid/floors",
  PROPERTY_FLOOR: "/dashboard/properties/:uid/floors/:floorUid/view",
  PROPERTY_EDIT_FLOOR: "/dashboard/properties/:uid/floors/:floorUid/edit",
  PROPERTY_ADD_FLOORS: "/dashboard/properties/:uid/floors/add",
  SSIDS: "/dashboard/properties/:uid/ssids",
  SSID_VIEW: "/dashboard/properties/:uid/ssids/:ssid/view",
  SSID_EDIT: "/dashboard/properties/:uid/ssids/:ssid/edit",
  ACCESS_POINTS: "/dashboard/properties/:uid/access-points",
  ACCESS_POINT_VIEW:
    "/dashboard/properties/:uid/access-points/:accesspoint/view",
  ACCESS_POINT_EDIT:
    "/dashboard/properties/:uid/access-points/:accesspoint/edit",
  PROPERTY_ADD_SSID: "/dashboard/properties/:uid/ssids/add",
  PROPERTY_ADD_ACCESS_POINT: "/dashboard/properties/:uid/access-points/add",
  V_LANS: "/dashboard/properties/:uid/network",
  V_LAN_ADD: "/dashboard/properties/:uid/network/add",
  V_LAN_EDIT: "/dashboard/properties/:uid/network/:vlanId/edit",
  V_LAN_VIEW: "/dashboard/properties/:uid/network/:vlanId/view",
  SENSORS: "/dashboard/properties/:uid/air-quality-sensors",
  SENSORS_MAIN: "/dashboard/properties/:uid/sensor-main",
  EDIT_SENSOR_THRESHOLD: "/dashboard/properties/:uid/air-quality-sensors/edit",
  SENSORS_ADD: "/dashboard/properties/:uid/air-quality-sensors/add",
  SENSORS_EDIT: "/dashboard/properties/:uid/air-quality-sensors/:sensorId/edit",
  SENSORS_VIEW: "/dashboard/properties/:uid/air-quality-sensors/:sensorId/view",
  DEVICES: "/dashboard/properties/:uid/devices",
  UPNEXT_APP: "/dashboard/properties/:uid/upnext-application",
  RESERVATION_SETTINGS:
    "/dashboard/properties/:uid/upnext-application/reservation-settings",
  RESERVATION_SETTINGS_EDIT:
    "/dashboard/properties/:uid/upnext-application/reservation-settings/edit",
  EDIT_UPNEXT_APP: "/dashboard/properties/:uid/edit-upnext-application",
  IMAGES_SENSORS: "/dashboard/properties/:uid/image-sensors",
  IMAGE_SENSOR_EDIT:
    "/dashboard/properties/:uid/image-sensors/:imagesensor/edit",
  ZONE: "/dashboard/properties/:uid/image-sensors/:imagesensor/zone/:zoneid",
  ZONE_EDIT:
    "/dashboard/properties/:uid/image-sensors/:imagesensor/zone/:zoneid/edit",
  TRIPWIRE:
    "/dashboard/properties/:uid/image-sensors/:imagesensor/tripWire/:tripWireid",
  TRIPWIRE_EDIT:
    "/dashboard/properties/:uid/image-sensors/:imagesensor/tripWire/:tripWireid/edit",
  DOORS: "/dashboard/properties/:uid/doors",
  FLOOR_ADD_DOORS: "/dashboard/properties/:uid/doors/add",
  EDIT_DOORS: "/dashboard/properties/:uid/doors/:doorname/edit",
  GROUPS: "/dashboard/properties/:uid/groups",
  GROUPS_ADD: "/dashboard/properties/:uid/groups/add",
  SPACES: "/dashboard/properties/:uid/spaces",
  ADD_SPACE: "/dashboard/properties/:uid/spaces/add",
  SPACE_VIEW: "/dashboard/properties/:uid/spaces/:spaceId/view",
  SPACE_EDIT: "/dashboard/properties/:uid/spaces/:spaceId/edit",
  CARD_READERS: "/dashboard/properties/:uid/card-readers",
};

export default function PropertyRouter() {
  const auth = useAuth();

  return (
    <Route path={ROUTES.PROPERTIES}>
      <Route exact={true} path={ROUTES.PROPERTIES} component={Properties} />
      <Route path={ROUTES.PROPERTY}>
        <Route
          exact={true}
          path={ROUTES.PROPERTY}
          render={(props) => {
            return (
              auth?.canSee(
                auth?.user?.role?.toUpperCase() as Role,
                "properties:view",
                <Property />
              ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
            );
          }}
        />
        <Route
          exact={true}
          path={ROUTES.ADD_PROPERTY}
          render={(props) => {
            return (
              auth?.canSee(
                auth?.user?.role?.toUpperCase() as Role,
                "properties:create",
                <AddProperty />
              ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
            );
          }}
        />
        <Route
          exact={true}
          path={ROUTES.EDIT_PROPERTY}
          render={(props) => {
            return (
              auth?.canSee(
                auth?.user?.role?.toUpperCase() as Role,
                "properties:edit",
                <EditProperty />
              ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
            );
          }}
        />

        <Route path={ROUTES.FLOORS}>
          <Route
            exact={true}
            path={ROUTES.FLOORS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "floors:list",
                  <Floors />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.PROPERTY_ADD_FLOORS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "floors:create",
                  <AddFloor />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.PROPERTY_FLOOR}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "floors:edit",
                  <EditFloorView />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.DOORS}>
          <Route
            exact={true}
            path={ROUTES.DOORS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "doors:list",
                  <Doors />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />

          <Route
            exact={true}
            path={ROUTES.FLOOR_ADD_DOORS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "doors:create",
                  <AddDoor />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />

          <Route
            exact={true}
            path={ROUTES.EDIT_DOORS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "doors:edit",
                  <EditDoor />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.SSIDS}>
          <Route
            exact={true}
            path={ROUTES.SSIDS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "ssids:list",
                  <SSIDs />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.SSID_VIEW}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "ssids:view",
                  <SSIDView />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.SSID_EDIT}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "ssids:edit",
                  <SSIDEditView />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.PROPERTY_ADD_SSID}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "ssids:create",
                  <AddSSID />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.ACCESS_POINTS}>
          <Route
            exact={true}
            path={ROUTES.ACCESS_POINTS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "access_points:list",
                  <AccessPoints />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.ACCESS_POINT_VIEW}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "access_points:view",
                  <AccessPointDetails />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.ACCESS_POINT_EDIT}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "access_points:edit",
                  <AccessPointEditView />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.PROPERTY_ADD_ACCESS_POINT}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "access_points:create",
                  <Add />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.V_LANS}>
          <Route
            exact={true}
            path={ROUTES.V_LANS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "vlans:list",
                  <VLans />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.V_LAN_VIEW}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "vlans:view",
                  <VLanView />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.V_LAN_EDIT}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "vlans:edit",
                  <EditVlan />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.V_LAN_ADD}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "vlans:create",
                  <AddVlan />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.SENSORS_MAIN}>
          <Route
            exact={true}
            path={ROUTES.SENSORS_MAIN}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "properties:view",
                  <SpaceLoadSettings />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.SENSORS}>
          <Route
            exact={true}
            path={ROUTES.EDIT_SENSOR_THRESHOLD}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "properties:view",
                  <EditSensorThreshold />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.SENSORS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "sensors:list",
                  <AirQualitySensors />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.SENSORS_EDIT}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "sensors:edit",
                  <EditSensor />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.SENSORS_ADD}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "sensors:create",
                  <AddSensor />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.SENSORS_VIEW}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "sensors:view",
                  <SensorView />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.UPNEXT_APP}>
          <Route
            exact={true}
            path={ROUTES.UPNEXT_APP}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "upnext:view",
                  <UpNextAppView />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.RESERVATION_SETTINGS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "upnext:view",
                  <ReservationSettings />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.RESERVATION_SETTINGS_EDIT}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "upnext:view",
                  <EditReservationSettings />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.EDIT_UPNEXT_APP}>
          <Route
            exact={true}
            path={ROUTES.EDIT_UPNEXT_APP}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "upnext:edit",
                  <EditUpNextApp />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.IMAGES_SENSORS}>
          <Route
            exact={true}
            path={ROUTES.IMAGES_SENSORS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "imageSensor:view",
                  <ImagesSensors />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.IMAGE_SENSOR_EDIT}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "imageSensor:edit",
                  <ImageSensorEditView />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.ZONE}>
          <Route
            exact={true}
            path={ROUTES.ZONE}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "zone:view",
                  <ZoneView />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.ZONE_EDIT}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "zone:edit",
                  <ZoneEdit />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.TRIPWIRE}>
          <Route
            exact={true}
            path={ROUTES.TRIPWIRE}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "tripWire:view",
                  <TripWireView />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.TRIPWIRE_EDIT}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "tripWire:edit",
                  <TripWireEdit />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.GROUPS}>
          <Route
            exact={true}
            path={ROUTES.GROUPS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "group:list",
                  <Groups />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.GROUPS_ADD}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "group:add",
                  <AddGroups />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.SPACES}>
          <Route
            exact={true}
            path={ROUTES.SPACES}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "spaces:list",
                  <Spaces />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.ADD_SPACE}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "spaces:create",
                  <AddSpaces />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.SPACE_VIEW}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "spaces:view",
                  <SpacesView />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
          <Route
            exact={true}
            path={ROUTES.SPACE_EDIT}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "spaces:edit",
                  <EditSpace />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
        <Route path={ROUTES.NOTIFICATIONS}>
          <Route
            exact={true}
            path={ROUTES.NOTIFICATIONS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "notifications:create",
                  <Notifications />
                ) || <Redirect from="*" to={ROUTES.NOTIFICATIONS} />
              );
            }}
          />
        </Route>
        <Route>
          <Route
            exact={true}
            path={ROUTES.CARD_READERS}
            render={(props) => {
              return (
                auth?.canSee(
                  auth?.user?.role?.toUpperCase() as Role,
                  "cardReaders:view",
                  <CardReaders />
                ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
              );
            }}
          />
        </Route>
      </Route>
      <BeaconsRouter />
      <ElevatorsRouter />
      <ElevatorBaysRouter />
      <DevicesRouter />
    </Route>
  );
}
