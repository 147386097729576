import React, { useCallback, useState, useEffect, ChangeEvent } from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import {
  Drawer as MuiDrawer,
  MenuItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
} from "@material-ui/core";
import { ReactComponent as ManageAccounts } from "../../../../common/assets/images/manage_accounts.svg";
import { ReactComponent as ManageAccountsSelected } from "../../../../common/assets/images/manage_accounts_selected.svg";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import {
  DASHBOARD_DRAWER_ROUTES,
  DASHBOARD_DRAWER_ADMIN_ROUTES,
  createDashboardPropertyRoutes,
  createDashboardNetworkRoutes,
  createDashboardSensorsRoutes,
  createDashboardUpnextRoutes,
} from "../../../../common/constants/Dashboard";
import { DrawerAdminItems, DrawerItems } from "../../../../types/app";
import { PROPERTY_MODULE_ROUTES } from "../../../../core/router/PropertyRotuer";
import { useStyles } from "./DrawerNav.styles";
import { AppLogoWhite } from "../../../../common/components/index";
import { CoverPhoto } from "./components/CoverPhoto/CoverPhoto";
import { CompanyLogo } from "./components/CompanyLogo/CompanyLogo";
import { PropertySelectDropdown } from "./components/PropertySelectDropdown";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { Role } from "../../../../types";
import { availableScopes } from "../../../../types/roles";
import { CanSee } from "../../../../common/components/canSee/canSee";
import { ExpandMore } from "@material-ui/icons";
import { useLeftPaneState } from "../../../../core/context/containers/LeftPane.container";

export const DRAWER_WIDTH = 200;
export const DRAWER_WIDTH_SM = 240;

export function DrawerNav() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { isPanelExpanded, setPanelExpanded, isExpanded, setIsExpanded } =
    useLeftPaneState();

  useEffect(() => {
    if (
      location.pathname.split("/")[location.pathname.split("/").length - 1] ===
        "dashboard" ||
      location.pathname.split("/")[location.pathname.split("/").length - 1] ===
        "floor-overview" ||
      location.pathname.split("/")[location.pathname.split("/").length - 1] ===
        "my-pins"
    ) {
      setIsExpanded(false);
    }
  }, [location]);

  const handleExpand = useCallback((e: ChangeEvent<{}>, expanded: boolean) => {
    setPanelExpanded(false);
    setIsExpanded(expanded);
  }, []);

  const handleChange =
    (panel: string, route: string) =>
    (event: ChangeEvent<{}>, expanded: boolean) => {
      setPanelExpanded(expanded ? panel : false);
      if (expanded) {
        history.push(route);
      }
    };

  const auth = useAuth();
  const handleRouteChange = useCallback(
    (route: string, submenu) => {
      let pathName = route.split("/").length - 1;
      if (
        route.split("/")[pathName] === "dashboard" ||
        route.split("/")[pathName] === "floor-overview" ||
        route.split("/")[pathName] === "my-pins"
      ) {
        setIsExpanded(false);
      }
      if (
        route.split("/")[pathName] === "users" ||
        route.split("/")[pathName] === "application-classification"
      ) {
        setPanelExpanded(false);
      }
      !submenu && history.push(route);
    },
    [history]
  );

  const handleNestedRouteChange = useCallback(
    (e: React.MouseEvent, route: string) => {
      e.preventDefault();
      e.stopPropagation();
      history.push(route);
    },
    []
  ); // eslint-disable-line

  const { selectedProperty } = usePropertiesState();

  const isPropertyRoute = (path: string) => {
    return Object.values(PROPERTY_MODULE_ROUTES)
      .map((key) => key.replace(":uid", selectedProperty?.id!))
      .filter(
        (v) =>
          ![
            PROPERTY_MODULE_ROUTES.PROPERTIES,
            PROPERTY_MODULE_ROUTES.ADD_PROPERTY,
          ].includes(v)
      )
      .includes(path);
  };
  const isActiveRoute = (path: any) => {
    return location.pathname === path;
  };
  const isAdminRoute = (path: any) => {
    return (
      path.split("/")[path.split("/").length - 1] === "access-control-system" ||
      path.split("/")[path.split("/").length - 1] === "users" ||
      path.split("/")[path.split("/").length - 1] ===
        "application-classification"
    );
  };

  const renderMenuItem = useCallback(
    (key, i, routes) => {
      return (
        <MenuItem
          className={clsx(
            isActiveRoute(
              routes[key as keyof (DrawerAdminItems | DrawerItems)].pathname
            )
              ? classes.menuItem
              : classes.menuItemPadding,
            isAdminRoute(
              routes[key as keyof (DrawerAdminItems | DrawerItems)].pathname
            )
              ? classes.nestedMenuItemPadding
              : ""
          )}
          button={true}
          disableGutters={true}
          onClick={() =>
            handleRouteChange(
              routes[key as keyof (DrawerAdminItems | DrawerItems)].pathname,
              isPropertyRoute(location.pathname) && key === "properties"
            )
          }
          id={`menuitem-${key}`}
          key={i}
        >
          {isActiveRoute(
            routes[key as keyof (DrawerAdminItems | DrawerItems)].pathname
          ) && <div className={classes.selectedItemIndicator} />}
          <ListItemText
            className={clsx(
              classes.text,
              classes.label,
              isActiveRoute(
                routes[key as keyof (DrawerAdminItems | DrawerItems)].pathname
              )
                ? classes.itemActive
                : ""
            )}
            primary={
              routes[key as keyof (DrawerAdminItems | DrawerItems)].label
            }
          />
        </MenuItem>
      );
    },
    [history, location]
  );

  return (
    <MuiDrawer
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.appIcon}>
        <AppLogoWhite />
      </div>
      <CoverPhoto property={selectedProperty} />
      <CompanyLogo property={selectedProperty} />
      <PropertySelectDropdown />
      {Object.keys(DASHBOARD_DRAWER_ROUTES).map((key, i) =>
        auth?.canSee(
          auth?.user?.role?.toUpperCase() as Role,
          `${key}:list` as availableScopes[number],
          renderMenuItem(key, i, DASHBOARD_DRAWER_ROUTES)
        )
      )}
      <CanSee action="admin:list">
        <Accordion
          square
          className={clsx(
            classes.drawerPaper,
            isExpanded ? classes.expandedStyle : ""
          )}
          expanded={isExpanded}
          onChange={handleExpand}
        >
          <AccordionSummary>
            {isExpanded ? (
              <ManageAccountsSelected className={classes.expandAdminIcon} />
            ) : (
              <ManageAccounts className={classes.expandAdminIcon} />
            )}
          </AccordionSummary>
          <AccordionDetails className={classes.menu}>
            {/* PROPERTY SETTINGS */}
            <Accordion
              square
              className={clsx(
                classes.nestedAccordionPaper,
                isExpanded ? classes.expandedStyle : ""
              )}
              expanded={isPanelExpanded === "panel1"}
              onChange={handleChange(
                "panel1",
                PROPERTY_MODULE_ROUTES.PROPERTY.replace(
                  ":uid",
                  selectedProperty?.id!
                )
              )}
            >
              <AccordionSummary
                expandIcon={<ExpandMore className={classes.iconColor} />}
                aria-controls="property-setting"
                id="property-setting"
              >
                <Typography>Property Settings</Typography>
              </AccordionSummary>
              <AccordionDetails id="property-setting-body">
                <List>
                  {Object.keys(createDashboardPropertyRoutes).map((key) => {
                    const routeKey = key.replace(":uid", selectedProperty?.id!);
                    const isRouteActive = routeKey === location.pathname;
                    return (
                      <MenuItem
                        id={key}
                        key={key}
                        className={clsx(
                          isRouteActive ? classes.nestedMenuActive : ""
                        )}
                        onClick={(e) => {
                          handleNestedRouteChange(e, routeKey);
                        }}
                      >
                        <span className={classes.nestedMenutext}>
                          {createDashboardPropertyRoutes[key].name}
                        </span>
                      </MenuItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            {/* NETWORK */}
            <Accordion
              square
              className={clsx(
                classes.nestedAccordionPaper,
                isExpanded ? classes.expandedStyle : ""
              )}
              expanded={isPanelExpanded === "panel2"}
              onChange={handleChange(
                "panel2",
                PROPERTY_MODULE_ROUTES.V_LANS.replace(
                  ":uid",
                  selectedProperty?.id!
                )
              )}
            >
              <AccordionSummary
                expandIcon={<ExpandMore className={classes.iconColor} />}
                aria-controls="network"
                id="network"
              >
                <Typography>Network</Typography>
              </AccordionSummary>
              <AccordionDetails id="network-body">
                <List>
                  {Object.keys(createDashboardNetworkRoutes).map((key) => {
                    const routeKey = key.replace(":uid", selectedProperty?.id!);
                    const isRouteActive = routeKey === location.pathname;
                    return (
                      <MenuItem
                        id={key}
                        key={key}
                        className={clsx(
                          isRouteActive ? classes.nestedMenuActive : ""
                        )}
                        onClick={(e) => {
                          handleNestedRouteChange(e, routeKey);
                        }}
                      >
                        <span className={classes.nestedMenutext}>
                          {createDashboardNetworkRoutes[key].name}
                        </span>
                      </MenuItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            {/* SENSORS */}
            <Accordion
              square
              className={clsx(
                classes.nestedAccordionPaper,
                isExpanded ? classes.expandedStyle : ""
              )}
              expanded={isPanelExpanded === "panel3"}
              onChange={handleChange(
                "panel3",
                PROPERTY_MODULE_ROUTES.SENSORS_MAIN.replace(
                  ":uid",
                  selectedProperty?.id!
                )
              )}
            >
              <AccordionSummary
                aria-label="sensors-menu"
                expandIcon={<ExpandMore className={classes.iconColor} />}
                aria-controls="sensors"
                id="sensors"
              >
                <Typography>Sensors</Typography>
              </AccordionSummary>
              <AccordionDetails aria-label="sensors-body" id="sensors-body">
                <List>
                  {Object.keys(createDashboardSensorsRoutes).map((key) => {
                    const routeKey = key.replace(":uid", selectedProperty?.id!);
                    const isRouteActive = routeKey === location.pathname;
                    return (
                      <MenuItem
                        id={key}
                        key={key}
                        className={clsx(
                          isRouteActive ? classes.nestedMenuActive : ""
                        )}
                        onClick={(e) => {
                          handleNestedRouteChange(e, routeKey);
                        }}
                      >
                        <span className={classes.nestedMenutext}>
                          {createDashboardSensorsRoutes[key].name}
                        </span>
                      </MenuItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
            {/* UPNEXT */}
            {selectedProperty?.upNext && (
              <Accordion
                square
                className={clsx(
                  classes.nestedAccordionPaper,
                  isExpanded ? classes.expandedStyle : ""
                )}
                expanded={isPanelExpanded === "panel4"}
                onChange={handleChange(
                  "panel4",
                  PROPERTY_MODULE_ROUTES.UPNEXT_APP.replace(
                    ":uid",
                    selectedProperty?.id!
                  )
                )}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore className={classes.iconColor} />}
                  aria-controls="upnext"
                  id="upnext"
                >
                  <Typography>Upnext</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {Object.keys(createDashboardUpnextRoutes).map((key) => {
                      const routeKey = key.replace(
                        ":uid",
                        selectedProperty?.id!
                      );
                      const isRouteActive = routeKey === location.pathname;
                      return (
                        <MenuItem
                          id={key}
                          key={key}
                          className={clsx(
                            isRouteActive ? classes.nestedMenuActive : ""
                          )}
                          onClick={(e) => {
                            handleNestedRouteChange(e, routeKey);
                          }}
                        >
                          <span className={classes.nestedMenutext}>
                            {createDashboardUpnextRoutes[key].name}
                          </span>
                        </MenuItem>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            )}
            {Object.keys(DASHBOARD_DRAWER_ADMIN_ROUTES).map((key, i) =>
              auth?.canSee(
                auth?.user?.role?.toUpperCase() as Role,
                `${key}:list` as availableScopes[number],
                renderMenuItem(key, i, DASHBOARD_DRAWER_ADMIN_ROUTES)
              )
            )}
          </AccordionDetails>
        </Accordion>
      </CanSee>
    </MuiDrawer>
  );
}
