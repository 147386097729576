import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    minWidth: 225,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "capitalize",
  },
  table: {
    borderLeft: `1px solid #E0EBF1`,
    borderRight: `1px solid #E0EBF1`,
    borderCollapse: "collapse",
    marginTop: "30px",
  },
  tableTH: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
  },
  tableRowData: {
    "&.MuiTableCell-root": {
      borderBottom: "1px solid #E0EBF1",
    },
  },
  tableTD: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
  },
  switch: {
    color: `${theme.palette.common.switchBlue} !important`,
    border: `1px solid ${theme.palette.common.switchBlue}`,
    borderRadius: "30px;",
    height: 17,
    width: 52,
  },
  status: {
    color: "#122036",
    marginLeft: 6,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 3,
    marginRight: 6,
    width: "57px",
  },
  outerCircle: {
    width: 14,
    height: 14,
    border: `1px solid ${theme.palette.common.chateauGreen}`,
    borderRadius: 7,
  },
  outerCircleGreen: {
    borderColor: theme.palette.common.chateauGreen,
  },
  outerCircleRed: {
    borderColor: theme.palette.common.cinnabar,
  },
  innerCircle: {
    width: 8,
    height: 8,
    borderRadius: 5,
  },
  innerCircleGreen: {
    backgroundColor: theme.palette.common.chateauGreen,
  },
  innerCircleRed: {
    backgroundColor: theme.palette.common.cinnabar,
  },
  loaderGrid: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `#2F56A1 !important`,
    width: 12,
    height: 12,
  },
  checked: {
    color: "transparent !important",
  },
  base: {
    height: 22,
  },
}));
