import API from "../../api/api";

const CreateAuthRestSerivce = () => {
  const sendRecoveryEmail = async (email: string) => {
    const response = await API.post(
      `users/${email.replace("+", "%2b")}/reset-password`
    );
    return response;
  };

  const setNewUserPassword = async (
    newPassword: string,
    token: string
  ): Promise<void> => {
    try {
      await API.post("users/reset-password", { newPassword, token });
      return;
    } catch (err) {
      console.log(err);
      return;
    }
  };

  return {
    sendRecoveryEmail,
    setNewUserPassword,
  };
};

export const AuthRestSerivce = CreateAuthRestSerivce();
