import { FormWizardSteps } from "../../../../types/form"; //FormFieldInputType,
import I18n from "../../../../core/services/I18n.service";
import {
  ActivitySwitch,
  Input,
} from "../../../../common/components/Form/FormFields";
import { UserList } from "../components/Users/UserList";
import { PermissionList } from "../components/Permission/PermissionList";
import * as Yup from "yup";

enum FIELD_NAMES {
  groupName = "groupName",
  groupStatus = "groupStatus",
  assignedUsers = "assignedUsers",
  startDate = "startDate",
  endDate = "endDate",
  permission = "permission",
}

export const AddGroupsFormWizardFieldset: FormWizardSteps = [
  {
    name: "Main Information",
    fieldset: [
      {
        fields: [
          {
            name: FIELD_NAMES.groupName,
            label: I18n.formatMessage({
              id: "addGroupFormFields.label.groupName",
              defaultMessage: "Name",
            }),
            placeholder: I18n.formatMessage({
              id: "addGroupFormFields.placeholder.groupName",
              defaultMessage: "Name",
            }),
            required: true,
            gridStyle: { sm: 12, md: 4 },
            Component: Input,
            resetField: true,
          },
          {
            name: FIELD_NAMES.groupStatus,
            label: I18n.formatMessage({
              id: "addGroupFormFields.label.groupStatus",
              defaultMessage: "Status",
            }),
            required: false,
            Component: ActivitySwitch,
          },
        ],
        validationSchema: addGroupValidationSchema(),
        defaultValues: {
          [FIELD_NAMES.groupStatus]: true,
        },
      },
    ],
  },
  {
    name: "Assigned Users",
    fieldset: [
      {
        fields: [
          {
            name: FIELD_NAMES.assignedUsers,
            label: "",
            Component: UserList,
          },
        ],
      },
    ],
  },
  {
    name: "Permission",
    fieldset: [
      {
        fields: [
          {
            name: FIELD_NAMES.permission,
            label: "",
            Component: PermissionList,
          },
        ],
      },
    ],
  },
];

function addGroupValidationSchema() {
  return Yup.object().shape({
    [FIELD_NAMES.groupName]: Yup.string()
      .min(
        3,
        I18n.formatMessage({
          id: "addGroup.validation.name.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addGroup.validation.name.long",
          defaultMessage: "Too long!",
        })
      )
      .required("Group Name is required"),
  });
}