import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import { Form, PageHeader } from "../../../../common/components";
import { useSnackbar } from "notistack";
import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { toCelcius } from "../../../../common/utils/General.utils";
import { mapSensorConfigurationsResponse } from "../../../../common/utils/Property.utils";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import GQLService from "../../../../core/services/GQL.service";
import { DEFAULT_SENSOR_RANGES } from "../components/SensorRange/SensorRange";
import { SpaceLoadSettingsFields } from "./SpaceLoadSettings.form";
import { useIntl } from "react-intl";
import { scrollsTop } from "../../../../common/utils/scrollbar.utils";
import { useSensorsState } from "../../../../core/context/containers/Sensors.contrainer";
import { Property } from "../../../../types";

export const SpaceLoadSettings = () => {
  const { selectedProperty, setSelectedProperty } = usePropertiesState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    sensorConfigurations,
    spaceLoadMediumMax,
    spaceLoadMediumMin,
    ntopngDatabaseName,
  } = selectedProperty!;
  const { tempUnit, celsius, setTempUnit, setCelsius } = useSensorsState();

  const [updateSensorConfiguration] = useMutation(
    GQLService.mutations.updateSensorConfiguration
  );

  const [updateNetworkSettings] = useMutation(
    GQLService.mutations.updateNetworkSettings
  );

  const [sensorConfigurationData] = useState(() => {
    const sensorRangeData =
      mapSensorConfigurationsResponse(sensorConfigurations);
    sensorRangeData.OCCUPANCY = [0, spaceLoadMediumMin, spaceLoadMediumMax, 50];
    return sensorRangeData;
  });

  useEffect(() => {
    if (selectedProperty) {
      const findUnit = selectedProperty?.sensorConfigurations?.filter(
        (el: any) => el.attribute === "TEMPERATURE"
      );
      if (findUnit![0].isCelsius) {
        setTempUnit("celcius");
        setCelsius(true);
      } else {
        setTempUnit("farenheit");
        setCelsius(false);
      }
    }
  }, [selectedProperty, setCelsius, setTempUnit]);

  const handleSubmit = async (formData: any) => {
    try {
      const { OCCUPANCY, ...allSensorConfigs } = formData.sensorConfigurations;
      let spaceLoadMediumMin = OCCUPANCY[1];
      let spaceLoadMediumMax = OCCUPANCY[2];
      // map sensor configuration to request object.
      const configs = Object.keys(allSensorConfigs).map((attribute) => {
        let values = allSensorConfigs[attribute] as number[];
        if (attribute === "TEMPERATURE" && tempUnit === "farenheit") {
          values = values.map((value) => toCelcius(value));
        }
        const indicators = DEFAULT_SENSOR_RANGES[attribute].indicators;
        const results = values.map((value, index) => {
          if (index < values.length - 1) {
            return {
              minVal: value,
              maxVal: values[index + 1],
              attribute,
              sensorHealthIndicator: indicators[index],
              isCelsius: attribute === "TEMPERATURE" ? celsius : null,
            };
          }
          return undefined;
        });
        results.pop();
        return results.filter(
          (result) => result?.sensorHealthIndicator !== "NONE"
        );
      });
      await updateNetworkSettings({
        variables: {
          propertyId: selectedProperty?.id!,
          spaceLoadMediumMax,
          spaceLoadMediumMin,
          ntopngDatabaseName: formData.ntopngDatabaseName,
        },
      });
      await updateSensorConfiguration({
        variables: {
          sensorConfigurations: configs.flat(),
          propertyId: selectedProperty?.id!,
        },
      }).then(() => {
        const updatedSensorConfig = {
          ...selectedProperty,
          sensorConfigurations: configs.flat(),
        };
        setSelectedProperty(updatedSensorConfig as Property);
        history.push(
          ROUTES.SENSORS_MAIN.replace(":uid", selectedProperty?.id!)
        );
        setTempUnit("");
      });
      enqueueSnackbar("Sensor Threshold changed successfully", {
        variant: "success",
      });
      scrollsTop();
    } catch (e: any) {
      enqueueSnackbar(e.message, {
        variant: "error",
        persist: true,
      });
    }
  };
  return (
    <Grid item={true} md={12} sm={12}>
      <Form
        defaultValues={{
          sensorConfigurations: sensorConfigurationData,
          ntopngDatabaseName,
        }}
        fields={SpaceLoadSettingsFields}
        // validationSchema={VALIDATION_RULES}
        onSubmit={handleSubmit}
      />
    </Grid>
  );
};

export function EditSensorThreshold() {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageHeader
        title={formatMessage({
          id: "sensors.main.header",
          defaultMessage: "Edit Sensors Thresholds",
          description: "Edit Sensors Thresholds header",
        })}
      />
      <SpaceLoadSettings />
    </>
  );
}
