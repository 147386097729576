import {
  Input,
  Select,
} from "../../../../../common/components/Form/FormFields";
import * as Yup from "yup";
import { CreateFormField } from "../../../../../types/form";
import I18n from "../../../../../core/services/I18n.service";

const FIELD_NAMES = {
  channel: "channel",
  channelWidth: "channelWidth",
  txPower: "txPower",
};

export const RadioConfiguration: CreateFormField = (
  disabled,
  data: {
    [name: string]: [];
  }
) => [
  {
    name: FIELD_NAMES.channel,
    label: I18n.formatMessage({
      id: "editAccessPoint.radioConfiguration.label.channel",
      defaultMessage: "Channel",
    }),
    placeholder: I18n.formatMessage({
      id: "editAccessPoint.radioConfiguration.placeholder.channel",
      defaultMessage: "Select",
    }),
    required: false,
    Component: Select,
    data: data.channel,
    disabled: disabled,
  },
  {
    name: FIELD_NAMES.channelWidth,
    label: I18n.formatMessage({
      id: "editAccessPoint.radioConfiguration.label.channelWidth",
      defaultMessage: "Channel Width",
    }),
    placeholder: I18n.formatMessage({
      id: "editAccessPoint.radioConfiguration.placeholder.channelWidth",
      defaultMessage: "Select",
    }),
    required: false,
    Component: Select,
    data: data.width,
    disabled: disabled,
  },
  {
    name: FIELD_NAMES.txPower,
    label: I18n.formatMessage({
      id: "editAccessPoint.radioConfiguration.label.txPower",
      defaultMessage: "Tx Power",
    }),
    placeholder: I18n.formatMessage({
      id: "editAccessPoint.radioConfiguration.placeholder.txPower",
      defaultMessage: "Type here",
    }),
    required: false,
    Component: Input,
    disabled: disabled,
  },
];

export const radioConfigurationSchema = Yup.object().shape({
  [FIELD_NAMES.channel]: Yup.string().nullable(),
  [FIELD_NAMES.channelWidth]: Yup.string().nullable(),
});
