import React, { useState, useCallback } from "react";
import { Menu, MenuItem, MenuList, makeStyles } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { Floor, Role } from "../../../../types";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useHistory } from "react-router-dom";
import { useFloorState } from "../../../../core/context/containers/Floor.container";
import { ROUTES } from "../../../../common/constants/Routing";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useFloorPlanEditorState } from "../../../../core/context/containers/FloorPlanEditor/FloorPlanEditor.container";
import { useAuth } from "../../../../core/context/containers/Auth.container";
// import { useAuth } from "../../../../core/context/functions/Auth.functions";
import { useSnackbar } from "notistack";
import { RemoveEntityModal } from "../../../../common/components/RemoveEntityModal/RemoveEntityModal";
import { ReactComponent as HamburgerMenu } from "../../../../common/assets/images/hamburger.svg";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 15,
    minHeight: 15,
    maxWidth: 15,
    maxHeight: 15,
    color: theme.palette.common.funBlue,
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    borderBottom: `1px solid ${theme.palette.common.nobel}`,

    "&:nth-child(2)": {
      border: "none",
    },
  },
}));

interface PropertyMenuProps {
  floor: Floor;
  index: string;
}

export function FloorsMenu({ floor, index }: PropertyMenuProps) {
  const classes = useStyles();
  const [modal, showModal] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const { setSelectedFloor, floors, setFloors } = useFloorState();
  const { setFloor } = useFloorPlanEditorState();
  const [anchorEl, setAnchorEl] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [removeFloor] = useMutation(GQLService.mutations.removeFloor, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allFloors,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });
  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const auth = useAuth();

  const open = Boolean(anchorEl);

  const handleEditFloorClick = () => {
    setSelectedFloor(floor);
    setFloor(floor);

    history.push(
      ROUTES.PROPERTY_FLOOR.replace(":uid", selectedProperty?.id!).replace(
        ":floorUid",
        floor.id
      )
    );
  };

  const handleRemoveFloorClick = useCallback(
    async (ev: any) => {
      ev.preventDefault();
      ev.stopPropagation();
      try {
        await removeFloor({ variables: { floorId: floor.id } });
        enqueueSnackbar(
          formatMessage({
            id: "floors.menuItem.remove.snackbar.success",
            defaultMessage: "Floor removed successfully",
          }),
          {
            variant: "success",
          }
        );
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error", persist: true });
      }
    },
    [floor] // eslint-disable-line
  );

  const renderEditMenuItem = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={handleEditFloorClick}>
        {formatMessage({
          id: "properties.contextMenu.editFloor",
          defaultMessage: "Edit Floor",
          description: "properties context menu edit floor label",
        })}
      </MenuItem>
    );
  }, [floor]);

  const renderRemoveMenuItem = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={openDeleteModal}>
        {formatMessage({
          id: "properties.contextMenu.removeFloor",
          defaultMessage: "Remove Floor",
          description: "properties context menu remove floor label",
        })}
      </MenuItem>
    );
  }, [floor]);

  const openDeleteModal = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    try {
      showModal(true);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const removeFloorConfirmed = useCallback(
    async (id: string) => {
      try {
        await removeFloor({ variables: { floorId: floor.id } });
        enqueueSnackbar(
          formatMessage({
            id: "floors.menuItem.remove.snackbar.success",
            defaultMessage: "Floor removed successfully",
          }),
          {
            variant: "success",
          }
        );
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error", persist: true });
      }
    },
    [floor]
  );

  const removeFloorRejected = useCallback(() => {
    showModal(false);
  }, []);

  return (
    <>
      <HamburgerMenu
        id={`FLOOR:${index}`}
        className={classes.icon}
        onClick={handleToggleMenu}
      />
      <Menu
        id="properties-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ zIndex: 500 }}
        keepMounted={true}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "floors:edit",
            renderEditMenuItem()
          )}
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "floors:delete",
            renderRemoveMenuItem()
          )}
        </MenuList>
      </Menu>
      {modal && (
        <RemoveEntityModal
          no={removeFloorRejected}
          yes={removeFloorConfirmed}
          id={floor.id}
          UI={{
            subheader: "Floor",
            type: "Floor",
            name: floor.name,
          }}
        />
      )}
    </>
  );
}
