import React, { useCallback, useMemo, useState } from "react";
import { useFormikContext, FieldInputProps, FormikState } from "formik";
import { useIntl } from "react-intl";
import { Grid, Select as SelectUI } from "@material-ui/core";
import { useStyles } from "./FloorSelectBasedOnPreviousSelect.styles";
import { MenuItem } from "@material-ui/core";
import { ISelectData } from "../../../../../types";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";

interface SelectProps extends FieldInputProps<any> {
  defaultValue: boolean;
  disabled?: boolean;
  data?: any;
  placeholder: string;
}

export function FloorSelectBasedOnPreviousSelect({
  name,
  defaultValue = false,
  disabled = false,
  onChange,
  placeholder,
  data,
}: SelectProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { errors, values, initialValues, isSubmitting } = useFormikContext<
    FormikState<{ [key: string]: string }>
  >();
  const [selectValue, setSelectValue] = useState(
    values[name as keyof typeof values] || placeholder
  );
  const isTouched =
    isSubmitting ||
    values[name as keyof typeof values] !==
      initialValues[name as keyof typeof values];

  const handleSelectOptClick = useCallback(
    (e) => {
      setSelectValue(e.target.value);
      onChange({ ...e, target: { name, value: e.target.value } });
    },
    [selectValue] // eslint-disable-line
  );

  const floorsToRender = useMemo(() => {
    const propertyName = data?.propName as keyof typeof values;

    const allIds = values[propertyName];
    return data?.floors?.filter((el: any) => {
      return allIds?.indexOf(el.id) > -1;
    });
  }, [values]);

  const createFloorNr = useCallback((nr) => {
    return (
      formatMessage({
        id: "FormFields.FloorSelect.FloorNumber",
        defaultMessage: "Floor nr:",
        description: "Form Field Floor Select Floor Number label",
      }) +
      " " +
      nr
    );
  }, []);

  return (
    <Grid key={name} container={true} item={true} direction="column">
      <SelectUI
        classes={{
          root: `${
            isTouched && errors[name as keyof typeof values]
              ? classes.error
              : classes.select
          }`,
          select: classes.blackText,
          icon: classes.icon,
        }}
        MenuProps={{
          classes: {
            paper: classes.menu,
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        IconComponent={ExpandMoreSharpIcon}
        value={selectValue}
        disableUnderline={true}
        onChange={handleSelectOptClick}
        name={name}
        renderValue={
          selectValue !== placeholder
            ? undefined
            : () => <Placeholder>{placeholder}</Placeholder>
        }
      >
        <MenuItem
          key={-1}
          value={placeholder}
          disabled
          classes={{
            root: classes.option,
            selected: classes.selected,
          }}
        >
          {placeholder}
        </MenuItem>
        {floorsToRender?.map((data: any, i: number) => (
          <MenuItem
            key={i + 1}
            value={data.id}
            disabled={false}
            classes={{
              root: classes.option,
              selected: classes.selected,
            }}
          >
            {data.name || createFloorNr(data.floorNumber)}
          </MenuItem>
        ))}
      </SelectUI>
    </Grid>
  );
}

function Placeholder({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return <div className={classes.placeholder}>{children}</div>;
}
