import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";

import { Form, PageHeader } from "../../../../common/components";
import { ROUTES } from "../../../../common/constants/Routing";
import GQLService from "../../../../core/services/GQL.service";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useHistory } from "react-router-dom";
import { createFormFields } from "./Form.steps";
import { SSIDValidationSchema } from "./Form.steps";
import { SSID } from "../../../../types";
import { useModal } from "../../../../core/hooks/Modal";
import { Modal } from "./Modal";

export function AddSSID() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const { show, hide, RenderModal } = useModal();
  const [formDataState, setFormDataState] = useState<SSID | undefined>(
    undefined
  );

  const { data } = useQuery(GQLService.queries.vlanByProperty, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [addSSID] = useMutation(GQLService.mutations.addSsid, {
    refetchQueries: () => [
      {
        query: GQLService.queries.vlanByProperty,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = useCallback(
    (formData) => {
      show();
      setFormDataState({ ...formData, propertyId: selectedProperty?.id });
    },
    [formDataState, selectedProperty] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      hide();
      if (formDataState) {
        try {
          await addSSID({ variables: { ...formDataState } }).then(() =>
            enqueueSnackbar("SSID added successfully", {
              variant: "success",
            })
          );
          //   reset();
          history.push(ROUTES.SSIDS.replace(":uid", selectedProperty?.id!));
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          console.log(e);
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => {
    hide();
  }, []);

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addSSID.header",
          defaultMessage: "Add new SSID",
          description: "add ssid label",
        })}
      />
      <Grid item={true} md={5} sm={12}>
        {data && (
          <Form
            defaultValues={{ active: false }}
            fields={createFormFields(data.vlansByPropertyId)}
            validationSchema={SSIDValidationSchema}
            onSubmit={handleSubmit}
          />
        )}
      </Grid>
      <RenderModal>
        <Modal yes={async () => await confirmChanges()} no={rejectChanges} />
      </RenderModal>
    </div>
  );
}
