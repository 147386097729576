import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 50,
    maxHeight: 500,
  },
  welcomeText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.common.funBlue,
  },
  catchline: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
  },
  img: {
    height: "100vh",
  },
  logo: {
    marginBottom: 77,
  },
}));
