import React from "react";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";

import { PageHeader } from "../../../../common/components";
import { EditSSIDForm } from "./Edit.form";

export function SSIDEditView() {
  const { formatMessage } = useIntl();

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addSSID.header",
          defaultMessage: "Edit SSID",
          description: "edit ssid label",
        })}
      />
      <Grid item={true} md={6} sm={12}>
        <EditSSIDForm />
      </Grid>
    </div>
  );
}
