import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Button,
  Checkbox,
} from "@material-ui/core";

import { useStyles } from "./Occupancy.styles";
import { ViewType } from "./Occupancy";
import { useFiltersState } from "../../../core/context/containers/Filters.container";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import moment from "moment";
import { Floor } from "../../../types";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import clsx from "clsx";
import { useQuery } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";
import { useFloorState } from "../../../core/context/containers/Floor.container";
import { CustomModal } from "../../../common/components";
import { ModalContent } from "./ModalContent";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import { COLORS } from "../../../common/styles/colors";
import Loader from "react-loader-spinner";

interface FiltersProps {
  viewType: ViewType;
  SSIDs: any[];
  spaces?: any[];
  userPreferenceFilter?: any;
  handleSaveFilter?: any;
  loadingUserFilterPrefernce?: any;
  offHoursIsActive?: boolean;
  setOffHoursIsActive?: any;
}

export function Filters({
  viewType,
  SSIDs,
  spaces,
  userPreferenceFilter,
  handleSaveFilter,
  loadingUserFilterPrefernce,
  offHoursIsActive,
  setOffHoursIsActive,
}: FiltersProps) {
  const classes = useStyles();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [SSIDsData, setSSIDsData] = useState<any>([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const {
    properties,
    loading: loadingProperties,
    selectedProperty,
    setSelectedProperty,
  } = usePropertiesState();

  const {
    selectedFloor: selectedFloorFromFloorState,
    setSelectedFloor: setSelectedFloorInFloorState,
    floors,
    loading: loadingFloors,
    setFloors,
    setLoading,
  } = useFloorState();

  const {
    selectedTime,
    setSelectedTime,
    selectedFloor,
    setSelectedFloor,
    selectedSSID,
    setSelectedSSID,
    selectedSpace,
    setSelectedSpace,
  } = useFiltersState();

  // First time setting Floor Changed to true
  const [isFloorchanged, setIsFloorchanged] = useState(true);
  const [isSwitchActive, setIsSwitchActive] = useState(false);
  const [isViewSaved, setIsViewSaved] = useState(false);

  const {
    loading: floorsLoading,
    error: floorsError,
    data: floorsData,
  } = useQuery(GQLService.queries.allActiveFloors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const last7InMinutes = useMemo(() => {
    return 7 * 24 * 60;
  }, []);

  const timeInMinutesFilter = [
    { value: 15, label: "Last 15 minutes" },
    { value: 60, label: "Last hour" },
    { value: 24 * 60, label: "Last 24 hours" },
    { value: last7InMinutes, label: "Last 7 days" },
    { value: 30 * 24 * 60, label: "Last 30 days" },
  ];

  useEffect(() => {
    if (SSIDs?.length === 1) {
      setSSIDsData(SSIDs!);
      setSelectedSSID(SSIDs[0]?.id);
    } else if (SSIDs?.length > 1) {
      const tempData = [{ name: "All", id: "all" }].concat(SSIDs!);
      setSSIDsData(tempData!);
      if (selectedSSID?.length === 0) {
        setSelectedSSID("all");
      }
    } else {
      setSSIDsData([]);
    }
  }, [SSIDs, setSelectedSSID, selectedProperty, selectedFloor]);

  const handlePropertyChange = useCallback(
    (e) => {
      setSelectedProperty(
        properties.find((v) => v.id === e.target.value) || null
      );
    },
    [properties, setSelectedProperty]
  );

  useEffect(() => {
    setLoading(floorsLoading);
    floorsData && setFloors(floorsData?.floorsByPropertyId);
    if (userPreferenceFilter) {
      setSelectedLabel(userPreferenceFilter.dataValue.preferences.duration);
      const userPreferenceTime = timeInMinutesFilter.find((v) => {
        if (v.label === userPreferenceFilter.dataValue.preferences.duration) {
          return v.value;
        }
      });
      setSelectedTime(userPreferenceTime?.value!);
      setIsViewSaved(true);
      setIsFloorchanged(true);
    } else {
      setSelectedTime(last7InMinutes);
      setSelectedLabel("Last 7 days");
    }
  }, [
    floorsLoading,
    floorsData,
    setLoading,
    setFloors,
    setSelectedTime,
    last7InMinutes,
    userPreferenceFilter,
  ]);

  useEffect(() => {
    if (userPreferenceFilter === null) {
      setSelectedSSID("all");
    } else if (userPreferenceFilter && isFloorchanged) {
      setSelectedSSID(userPreferenceFilter.dataValue.preferences.ssid);
      setIsViewSaved(true);
    } else {
      // will remove code once NET-2777's testing is done
      // setSelectedSSID("");
      // if (SSIDs?.length === 1) {
      //   setSSIDsData(SSIDs!);
      //   setSelectedSSID(SSIDs[0]?.id);
      // } else if (SSIDs?.length > 1) {
      //   const tempData = [{ name: "All", id: "all" }].concat(SSIDs!);
      //   setSSIDsData(tempData!);
      //   if (selectedSSID?.length === 0) {
      //     setSelectedSSID("all");
      //   }
      // } else {
      //   setSSIDsData([]);
      // }
      return;
    }
  }, [
    selectedProperty,
    selectedFloor,
    setSelectedSSID,
    userPreferenceFilter,
    isFloorchanged,
  ]);

  useEffect(() => {
    if (spaces && userPreferenceFilter && isFloorchanged) {
      const findSpace = spaces?.find(
        (item) =>
          item.id === userPreferenceFilter?.dataValue?.preferences?.spaces
      );
      if (findSpace) {
        setSelectedSpace(userPreferenceFilter?.dataValue?.preferences?.spaces);
      } else {
        setSelectedSpace("none");
      }
      setIsViewSaved(true);
    } else {
      setSelectedSpace("none");
    }
  }, [
    selectedProperty,
    // selectedFloor,
    // setSelectedSpace,
    userPreferenceFilter,
    spaces,
    // isFloorchanged,
  ]);

  useEffect(() => {
    if (loadingFloors === false) {
      if (userPreferenceFilter && isFloorchanged) {
        const userPreferenceFloor = floors.find(
          (v) => v.id === userPreferenceFilter.dataValue.preferences.floor
        );
        setSelectedFloor(userPreferenceFloor);
        setIsViewSaved(true);
      } else {
        setSelectedFloor(floors.find((v) => v.floorNumber === 1) || floors[0]);
        setIsViewSaved(false);
      }
    }
  }, [
    selectedProperty,
    loadingFloors,
    floors,
    setSelectedFloor,
    // selectedFloorFromFloorState?.id,
    userPreferenceFilter,
  ]);

  useEffect(() => {
    if (selectedTime === 15 || selectedTime === 60 || selectedTime === 1440) {
      setOffHoursIsActive(false);
    }
    if (viewType !== ViewType.ACCESS_POINT) {
      setOffHoursIsActive(false);
    }
  }, [selectedTime, viewType]);

  const handleFloorChange = useCallback(
    (e) => {
      setIsFloorchanged(false);
      setSelectedFloorInFloorState(
        floors.find((v) => v.id === e.target.value) || null
      );
      setSelectedFloor(floors.find((v) => v.id === e.target.value));
      setSelectedSpace("none");
      setSelectedSSID("");
      setIsSwitchActive(true);
      setIsViewSaved(false);
    },
    [floors, setSelectedFloor, setSelectedFloorInFloorState]
  );

  const handleTimeChange = useCallback(
    (e) => {
      setSelectedTime(e.target.value);
      setSelectedLabel(e.nativeEvent.target.outerText);
      setIsSwitchActive(true);
      setIsViewSaved(false);
      setOffHoursIsActive(false);
    },
    [setSelectedTime, setSelectedLabel, toggleModal]
  );

  const handleSSIDChange = useCallback((e) => {
    setSelectedSSID(e.target.value);
    setIsFloorchanged(false);
    setIsSwitchActive(true);
    setIsViewSaved(false);
  }, []);

  const handleSpaceChange = useCallback(
    (e) => {
      setSelectedSpace(e.target.value);
      if (e.target.value === "none") {
        setSelectedSSID("all");
      }
      setIsSwitchActive(true);
      setIsFloorchanged(false);
      setIsViewSaved(false);
    },
    [setSelectedSSID, setSelectedSpace]
  );

  const handleSwitch = () => {
    const userPreferenceFloor = floors.find(
      (v) => v.id === userPreferenceFilter.dataValue.preferences.floor
    );
    setSelectedFloor(userPreferenceFloor);
    setTimeout(() => {
      setSelectedSpace(userPreferenceFilter.dataValue.preferences.spaces);
    }, 1000);

    setTimeout(() => {
      setSelectedSSID(userPreferenceFilter.dataValue.preferences.ssid);
    }, 1500);

    setSelectedLabel(userPreferenceFilter.dataValue.preferences.duration);
    const userPreferenceTime = timeInMinutesFilter.find((v) => {
      if (v.label === userPreferenceFilter.dataValue.preferences.duration) {
        return v.value;
      }
    });
    setSelectedTime(userPreferenceTime?.value!);
    setIsSwitchActive(false);
    setIsViewSaved(true);
  };

  const handleState = () => {
    if (isFloorchanged === false) {
      setIsFloorchanged(true);
    }
  };

  // UseEffect to show Saved View button if all filters are selected as saved preference
  useEffect(() => {
    if (userPreferenceFilter) {
      if (selectedFloor && selectedSpace && selectedSSID && selectedLabel) {
        let spaceCopy: any;
        spaceCopy = userPreferenceFilter.dataValue.preferences.spaces;
        const findSpace = spaces?.find((item) => item.id === spaceCopy);
        if (findSpace) {
          spaceCopy = userPreferenceFilter.dataValue.preferences.spaces;
        } else {
          spaceCopy = "none";
        }
        if (
          selectedFloor?.id ===
            userPreferenceFilter.dataValue.preferences.floor &&
          selectedSpace === userPreferenceFilter.dataValue.preferences.spaces &&
          selectedSSID === userPreferenceFilter.dataValue.preferences.ssid &&
          selectedLabel === userPreferenceFilter.dataValue.preferences.duration
        ) {
          setIsSwitchActive(false);
          setIsViewSaved(true);
        }
      }
    }
  }, [
    selectedFloor,
    selectedSpace,
    selectedSSID,
    selectedLabel,
    userPreferenceFilter,
    spaces,
  ]);

  const handleChange = useCallback(
    (e) => {
      setOffHoursIsActive(!offHoursIsActive);
    },
    [offHoursIsActive]
  );

  return (
    <>
      <Grid className={classes.filtersWrapper}>
        <Grid className={clsx(classes.singleFilter, classes.firstSingleFilter)}>
          <Typography
            className={clsx(classes.blackText, classes.firstBlackText)}
          >
            Floor
          </Typography>
          <Select
            classes={{
              root: classes.select,
              select: classes.blackText,
              icon: classes.icon,
            }}
            name="floorFilter"
            value={selectedFloor?.id || ""}
            disableUnderline={true}
            onChange={handleFloorChange}
            IconComponent={ExpandMoreSharpIcon}
            MenuProps={{
              classes: {
                paper: classes.menu,
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {floors?.map((v) => (
              <MenuItem
                key={v.id}
                value={v.id}
                classes={{
                  root: classes.option,
                  selected: classes.selected,
                }}
              >
                {v.name || v.floorNumber}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {viewType !== ViewType.DOORS && (
          <Grid className={classes.singleFilter}>
            <Typography className={classes.blackText}>Spaces</Typography>
            <Select
              classes={{
                root: classes.select,
                select: classes.blackText,
                icon: classes.icon,
              }}
              name="spaceFilter"
              value={selectedSpace}
              disableUnderline={true}
              onChange={handleSpaceChange}
              IconComponent={ExpandMoreSharpIcon}
              MenuProps={{
                classes: {
                  paper: classes.menu,
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem
                value="none"
                classes={{
                  root: classes.option,
                  selected: classes.selected,
                }}
              >
                Whole Floor
              </MenuItem>
              {spaces?.map((v, i) => (
                <MenuItem
                  key={v.name + i}
                  value={v.id}
                  classes={{
                    root: classes.option,
                    selected: classes.selected,
                  }}
                >
                  {v.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        {viewType === ViewType.ACCESS_POINT && (
          <Grid className={classes.singleFilter}>
            <Typography className={classes.blackText}>SSIDs</Typography>
            <Select
              classes={{
                root: classes.select,
                select: classes.blackText,
                icon: classes.icon,
              }}
              name="ssidFilter"
              value={selectedSSID}
              disableUnderline={true}
              onChange={handleSSIDChange}
              IconComponent={ExpandMoreSharpIcon}
              MenuProps={{
                classes: {
                  paper: classes.menu,
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {SSIDsData?.map((v: any) => (
                <MenuItem
                  key={v?.id}
                  value={v?.id}
                  classes={{
                    root: classes.option,
                    selected: classes.selected,
                  }}
                >
                  {v?.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}

        <Grid className={classes.durationWrapper}>
          <Grid style={{ display: "flex" }}>
            <Typography className={classes.blackText}>Duration</Typography>
            <Select
              classes={{
                root: classes.select,
                select: classes.blackText,
                icon: classes.icon,
              }}
              name="duration"
              value={selectedTime}
              disableUnderline={true}
              onChange={handleTimeChange}
              IconComponent={ExpandMoreSharpIcon}
              MenuProps={{
                classes: {
                  paper: classes.menu,
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {timeInMinutesFilter.map((v, index) => (
                <MenuItem
                  key={v.value + index}
                  value={v.value}
                  classes={{
                    root: classes.option,
                    selected: classes.selected,
                  }}
                >
                  {v.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid className={classes.excludeOffhrsGrid}>
            <Grid className={classes.angleIcon}></Grid>
            <Grid className={classes.checkBoxGrid}>
              <Checkbox
                value={offHoursIsActive}
                checked={offHoursIsActive}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                classes={{ root: classes.checkbox }}
                disabled={
                  selectedTime === 15 ||
                  selectedTime === 60 ||
                  selectedTime === 1440 ||
                  viewType !== ViewType.ACCESS_POINT
                    ? true
                    : false
                }
                onChange={handleChange}
                disableRipple={true}
              />
              <Typography
                className={clsx(
                  classes.excludeText,
                  selectedTime === 15 ||
                    selectedTime === 60 ||
                    selectedTime === 1440 ||
                    viewType !== ViewType.ACCESS_POINT
                    ? classes.disabledText
                    : classes.enableText
                )}
              >
                Exclude OFF hours
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.buttonWrapper}>
        {isSwitchActive && userPreferenceFilter && !isViewSaved && (
          <Grid className={classes.singleFilter}>
            <Button className={classes.switchButton} onClick={handleSwitch}>
              <SwapHoriz style={{ marginRight: "5px" }} />
              Switch
            </Button>
          </Grid>
        )}
        <Grid className={classes.singleFilter}>
          {loadingUserFilterPrefernce ? (
            <Loader type="Oval" color={COLORS.funBlue} height={40} width={40} />
          ) : isViewSaved ? (
            <Button className={classes.savedViewBtn} disableRipple={true}>
              Saved View !
            </Button>
          ) : (
            <Button
              className={classes.saveButton}
              onClick={() => toggleModal(true)}
            >
              Save This View
            </Button>
          )}
        </Grid>
        <CustomModal open={showModal}>
          <ModalContent
            toggleModal={toggleModal}
            selectedLabel={selectedLabel}
            viewType={viewType}
            handleSaveFilter={handleSaveFilter}
            spaces={spaces}
            SSIDs={SSIDs}
            handleState={handleState}
          />
        </CustomModal>
      </Grid>
    </>
  );
}
