import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Grid, Typography, Button } from "@material-ui/core";
import { AccessPoint, Floor, Property, Role } from "../../../../../types";
import { useStyles } from "./MainSettings.styles";
import { DetailLabel } from "../../../Properties/View/components/DetailLabel";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../common/constants/Routing";
import { useQuery } from "@apollo/client";
import GQLService from "../../../../../core/services/GQL.service";
import { useAuth } from "../../../../../core/context/containers/Auth.container";

interface MainSettingsProps {
  accessPoint: AccessPoint;
  property: Property;
}

export function MainSettings({ accessPoint, property }: MainSettingsProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [floor, setFloor] = useState<Floor>();

  const { loading, data } = useQuery(GQLService.queries.floorById, {
    variables: { floorId: accessPoint?.floorId },
    fetchPolicy: "cache-first",
  });

  const auth = useAuth();

  useEffect(() => {
    setFloor(data?.floorById);
  }, [loading]);

  const handleEditClick = useCallback(() => {
    history.push(
      ROUTES.ACCESS_POINT_EDIT.replace(":uid", property?.id!).replace(
        ":accesspoint",
        accessPoint?.id.split(":")[1]!
      ) + "#0"
    );
  }, []);

  const createAPButton = useCallback(() => {
    return (
      <Button className={classes.button} onClick={handleEditClick}>
        {formatMessage({
          id: "propertyWorkplace.button.edit",
          defaultMessage: "Edit",
          description: "edit button label",
        })}
      </Button>
    );
  }, []);

  return (
    <Grid container md={12} sm={12} className={classes.main}>
      <Grid item={true} md={6} sm={12}>
        <Typography className={classes.blackLabel}>
          {formatMessage({
            id: "mainSettings.informationLabel",
            defaultMessage: "Access Point Informations",
            description: "Main settings access point label",
          })}
        </Typography>
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.name",
            defaultMessage: "ACCESS POINT NAME",
            description: "access point name",
          })}
          value={accessPoint.name || ""}
        />
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.dataCenter",
            defaultMessage: "DATA CENTER",
            description: "access point data center",
          })}
          value={accessPoint.dataCenter || ""}
        />
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.property",
            defaultMessage: "PROPERTY",
            description: "access point property",
          })}
          value={property?.siteName || ""}
        />
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.inventoryNumber",
            defaultMessage: "Inventory Number",
            description: "Inventory Number",
          })}
          value={accessPoint?.inventoryNumber?.toString() || ""}
        />
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.physicalLocation",
            defaultMessage: "PHYSICAL LOCATION",
            description: "access point physical location",
          })}
          value={floor?.name || floor?.floorNumber.toString() || ""}
        />
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.modelNumber",
            defaultMessage: "MODEL NUMBER",
            description: "access point modelNumber",
          })}
          value={accessPoint.serialNumber || ""}
        />
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.active",
            defaultMessage: "ACTIVE",
            description: "access point active",
          })}
          value={accessPoint.active ? "Yes" : "No"}
        />
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.notes",
            defaultMessage: "NOTES",
            description: "access point notes",
          })}
          value={accessPoint.notes || ""}
        />
      </Grid>
      <Grid item={true} md={6} sm={12}>
        <Typography className={classes.blackLabel}>
          {formatMessage({
            id: "mainSettings.deviceLabel",
            defaultMessage: "Device Identifitiers",
            description: "Main settings label",
          })}
        </Typography>
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.name",
            defaultMessage: "BASE MAC ADDRESS",
            description: "access point base mac address",
          })}
          value={accessPoint.baseMacAddress.toLowerCase() || ""}
        />
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.ipAddress",
            defaultMessage: "IP ADDRESS",
            description: "access point ip address",
          })}
          value={accessPoint.ipAddress || ""}
        />
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.radio1",
            defaultMessage: "RADIO 1",
            description: "access point radio1",
          })}
          value={accessPoint.radio1?.macAddress || ""}
        />
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.radio2",
            defaultMessage: "RADIO 2",
            description: "access point radio2",
          })}
          value={accessPoint.radio2?.macAddress || ""}
        />
        <DetailLabel
          label={formatMessage({
            id: "mainSettings.serialNumber",
            defaultMessage: "SERIAL NUMBER",
            description: "access point serial number",
          })}
          value={accessPoint.serialNumber || ""}
        />
      </Grid>
      {auth?.canSee(
        auth?.user?.role?.toUpperCase() as Role,
        "access_points:edit",
        createAPButton()
      )}
    </Grid>
  );
}
