import React, { useState, useCallback, Fragment } from "react";
import {
  Menu,
  MenuItem,
  MenuList,
  makeStyles,
  Button,
} from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { Role, SSID } from "../../../../types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useSSIDState } from "../../../../core/context/containers/SSID.container";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { useSnackbar } from "notistack";
import { RemoveEntityModal } from "../../../../common/components/RemoveEntityModal/RemoveEntityModal";
import { ReactComponent as HamburgerMenu } from "../../../../common/assets/images/hamburger.svg";
import { useModal } from "../../../../core/hooks/Modal";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 15,
    minHeight: 15,
    maxWidth: 15,
    maxHeight: 15,
    color: theme.palette.common.funBlue,
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    borderBottom: `1px solid ${theme.palette.common.nobel}`,

    "&:nth-child(3)": {
      border: "none",
    },
  },
}));

interface PropertyMenuProps {
  ssid: SSID;
  index: string;
}

export function SSIDsMenu({ ssid, index }: PropertyMenuProps) {
  const classes = useStyles();
  const [modal, showModal] = useState<boolean>(false);

  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();
  const { setSelectedSSID } = useSSIDState();
  const [anchorEl, setAnchorEl] = useState();

  const auth = useAuth();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    show: showDeleteModal,
    hide: hideDeleteModal,
    RenderModal: RenderDeleteModal,
  } = useModal();

  const [removeSsid] = useMutation(GQLService.mutations.removeSsid, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allSsid,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const history = useHistory();

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const handleViewSSIDClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedSSID(ssid);
      history.push(
        ROUTES.SSID_VIEW.replace(":uid", selectedProperty!.id).replace(
          ":ssid",
          ssid?.id.split(":")[1]
        )
      );
    },
    [ssid]
  );

  const handleEditSSIDClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedSSID(ssid);
      history.push(
        ROUTES.SSID_EDIT.replace(":uid", selectedProperty?.id!).replace(
          ":ssid",
          ssid?.id.split(":")[1]
        )
      );
    },
    [ssid]
  );

  const handleRemoveSSIDClick = useCallback(
    async (ev: any) => {
      ev.preventDefault();
      ev.stopPropagation();
      try {
        await removeSsid({ variables: { ssidId: ssid.id } });
        enqueueSnackbar("SSID removed successfully", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error", persist: true });
      }
    },
    [ssid] //eslint-disable-line
  );

  const renderEditMenuItem = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={handleEditSSIDClick}>
        {formatMessage({
          id: "ssids.contextMenu.editSSID",
          defaultMessage: "Edit SSID",
          description: "ssids context menu edit ssid label",
        })}
      </MenuItem>
    );
  }, [ssid]);

  const renderRemoveMenuItem = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={openDeleteModal}>
        {formatMessage({
          id: "ssids.contextMenu.removeSSID",
          defaultMessage: "Remove SSID",
          description: "ssids context menu remove ssid label",
        })}
      </MenuItem>
    );
  }, [ssid]);

  const open = Boolean(anchorEl);

  const openDeleteModal = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    try {
      showDeleteModal();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const removeSSIDConfirmed = useCallback(
    async (id: string) => {
      try {
        await removeSsid({ variables: { ssidId: id } });
        enqueueSnackbar("SSID removed successfully", {
          variant: "success",
        });
        hideDeleteModal();
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error", persist: true });
      }
    },
    [ssid]
  );

  const removeSSIDRejected = useCallback(() => {
    hideDeleteModal();
  }, []);

  return (
    <>
      <HamburgerMenu
        id={index}
        className={classes.icon}
        onClick={handleToggleMenu}
      />
      <Menu
        id="ssid-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ zIndex: 500 }}
        keepMounted={true}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          <MenuItem className={classes.menuItem} onClick={handleViewSSIDClick}>
            {formatMessage({
              id: "ssids.contextMenu.detailsSSID",
              defaultMessage: "Details",
              description: "ssids context menu duplicate ssid label",
            })}
          </MenuItem>
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "ssids:edit",
            renderEditMenuItem()
          )}
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "ssids:delete",
            renderRemoveMenuItem()
          )}
        </MenuList>
      </Menu>
      <RenderDeleteModal>
        <RemoveEntityModal
          no={removeSSIDRejected}
          yes={removeSSIDConfirmed}
          id={ssid.id}
          UI={{
            subheader: "SSID",
            type: "SSID",
            name: ssid.name,
          }}
        />
      </RenderDeleteModal>
    </>
  );
}
