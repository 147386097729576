import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  formNav: {},
  stepIndex: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.white,
    width: 24,
    height: 24,
    borderRadius: 12,
    textAlign: "center",
    marginRight: 10,
  },
  stepInactive: {
    backgroundColor: theme.palette.common.darkGrey,
  },
  stepActive: {
    backgroundColor: theme.palette.common.blue,
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackText,
  },
  fieldsLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.textBlack,
    marginTop: 25,
  },
  labelActive: {
    color: theme.palette.common.blue,
  },
  labelInactive: {
    color: theme.palette.common.darkGrey,
  },
  stepSeparator: {
    width: 15,
    height: 1,
    backgroundColor: theme.palette.common.blackPearl,
    alignSelf: "center",
    margin: "0 10px",
  },
  blueButton: {
    backgroundColor: theme.palette.common.funBlue,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: 90,
    height: 42,
    margin: "0 0 0 20px",
  },
  blueOutlineButton: {
    textTransform: "none",
    color: theme.palette.common.funBlue,
    border: `1px solid ${theme.palette.common.funBlue}`,
    borderRadius: 30,
    width: 90,
    height: 42,
  },
  content: {
    marginTop: 20,
  },
  navigationButtons: {
    minWidth: 150,
    position: "relative",
    right: 70,
  },
}));
