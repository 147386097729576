import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { useStyles } from "./MyPinsModal.styles";
import { useUserState } from "../../../core/context/containers/User.container";
import { useMutation } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";

interface UnpinModalProps {
  setToggleModal?: any;
  deviceType?: any;
  deviceId?: any;
  handleIsPinned?: any;
  Type?: string;
  title?: string;
  selectedSpace?: any;
  selectedFloor?: any;
  removedCard?: any;
}

export function UnpinModal({
  setToggleModal,
  deviceType,
  deviceId,
  handleIsPinned,
  Type,
  title,
  selectedSpace,
  selectedFloor,
  removedCard,
}: UnpinModalProps) {
  const classes = useStyles();
  const { userEmail } = useUserState();

  const [removeUserpin] = useMutation(GQLService.mutations.deleteUserPin);
  //   , {
  //   refetchQueries: () => [
  //     {
  //       query: GQLService.queries.userPinsByUserId,
  //       variables: { userEmail: userEmail },
  //     },
  //   ],
  //   awaitRefetchQueries: true,
  // });

  const confirmPinIt = () => {
    removeUserpin({
      variables: {
        deviceId: deviceId,
        userEmail: userEmail,
        floor_id: selectedFloor?.id!,
        space_id: selectedSpace === "none" ? null : selectedSpace,
        deviceType: deviceType,
      },
    }).then((dt) => {
      if (Type === "MyPins" && title !== "Dashboard") {
        removedCard();
      }
    });
    setToggleModal(false);
    if (Type === "FloorOverview") {
      handleIsPinned(true);
    }
    if (title === "Dashboard") {
      handleIsPinned(true);
    }
  };

  return (
    <Grid
      className={classes.unpinItModal}
      style={{
        marginTop: Type === "FloorOverview" ? "0px" : "50px",
        left: "45%",
        top: "20%",
      }}
    >
      <Grid>
        <Typography className={classes.pinItModalHeader}>Unpin</Typography>
        <Grid>
          <Typography className={classes.pinItModalDescription}>
            The selected card will be removed from My Pins Page.
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.pinItModalButtonsWrapper}>
        <Button
          id="cancelBtnEditModal"
          onClick={() => setToggleModal(false)}
          className={classes.pinItModalButtonCancel}
        >
          Cancel
        </Button>
        <Button
          id="yesBtnEditModal"
          onClick={() => confirmPinIt()}
          className={classes.pinItModalButtonPinIt}
        >
          Unpin
        </Button>
      </Grid>
    </Grid>
  );
}
