import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { CameraListItem } from "./CameraListItem";
import {
  Floor,
  ImageSensorWithOccupancy,
  ImagesSensor,
  ZoneWithOccupancy,
  TripWireWithOccupancy,
} from "../../../../types";

const useStyles = makeStyles((theme) => ({
  sideList: {
    display: "flex",
    borderRight: `1px solid ${theme.palette.common.lightGray}`,
    flexDirection: "column",
  },
  title: {
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    padding: theme.typography.pxToRem(20),
    color: theme.palette.common.darkBlue,
    borderBottom: `1px solid ${theme.palette.common.lightGray}`,
  },
}));

type CamerasSideListPropType = {
  dataWithSpaceLoad: ImageSensorWithOccupancy[];
  selectedTime: number;
  data?: ImagesSensor[];
  floor: Floor;
  toggleActive: (el: ImagesSensor) => void;
  clickZone: (camera: ImageSensorWithOccupancy, zoneId: string) => void;
  clickTripWire: (camera: ImageSensorWithOccupancy, tripWireId: string) => void;
  handleIsPinned?: (el: any) => void;
};

export const CamerasSideList = ({
  dataWithSpaceLoad,
  data,
  selectedTime,
  floor,
  toggleActive,
  clickZone,
  clickTripWire,
  handleIsPinned,
}: CamerasSideListPropType) => {
  const classes = useStyles();
  return (
    <Grid className={classes.sideList}>
      <Typography className={classes.title}>
        Image Sensors {`(${data?.length})`}
      </Typography>
      {data?.map((el: ImagesSensor, i) => (
        <CameraListItem
          key={i}
          data={el}
          dataWithSpaceLoad={
            dataWithSpaceLoad?.find((it) => {
              return it.cameraUuid === el.cameraUuid;
            })!
          }
          floor={floor}
          selectedTime={selectedTime}
          toggleActive={toggleActive}
          clickZone={clickZone}
          clickTripWire={clickTripWire}
          handleIsPinned={handleIsPinned}
        />
      ))}
    </Grid>
  );
};
