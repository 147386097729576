import React, { ChangeEvent, useCallback, useState } from "react";

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../NetworkSettings.styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Host from "./Host";
import { NetworkMenu } from "./Menu";
import EditGroupNameModal from "./EditGroupNameModal";
import GQLService from "../../../core/services/GQL.service";
import { useMutation } from "@apollo/client";

interface GroupProps {
  item: any;
}

export default function Group({ item }: GroupProps) {
  const classes = useStyles();

  const [updateNetworkName] = useMutation(
    GQLService.mutations.updateNetworkGroup,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allNetworkGroups,
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpand = useCallback((e: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(expanded);
  }, []);

  const [editModal, showEditModal] = useState<boolean>(false);

  const editName = useCallback(async (id: string, name: string) => {
    await updateNetworkName({ variables: { name, id } });
    showEditModal(false);
  }, []);

  const showModal = useCallback(() => {
    showEditModal(true);
  }, [editModal]);

  return (
    <Grid className={classes.marginTop}>
      {editModal && (
        <EditGroupNameModal
          no={() => showEditModal(false)}
          yes={(id: string, name: string) => editName(id, name)}
          group={item}
        />
      )}
      <Accordion key={item.id} expanded={isExpanded} onChange={handleExpand}>
        <AccordionSummary>
          <Grid container={true} alignItems="center" justify="space-between">
            <Grid
              container={true}
              item={true}
              md={4}
              sm={4}
              justify="flex-start"
            >
              {isExpanded ? (
                <ExpandLess classes={{ root: classes.expandIcon }} />
              ) : (
                <ExpandMore classes={{ root: classes.expandIcon }} />
              )}
              <Typography className={classes.groupHeader}>
                {item.name}
              </Typography>
            </Grid>
            <AccordionActions>
              <NetworkMenu group={item} showModal={showModal} />
            </AccordionActions>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Host item={item} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
