import React, { useState, useCallback } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  AccordionActions,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useIntl } from "react-intl";

import { Sensor } from "../../../../types";
import { StatusIndicator } from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import GQLService from "../../../../core/services/GQL.service";
import { useMutation } from "@apollo/client";
import { SensorsMenu } from "./Menu";
import { ReactComponent as SensorIcon } from "../../../../common/assets/images/sensor.svg";
import { useHistory } from "react-router-dom";
import { useSensorsState } from "../../../../core/context/containers/Sensors.contrainer";
import { ROUTES } from "../../../../common/constants/Routing";
import { listStyles } from "../../../../common/styles/lists";
import { ReactComponent as HamburgerMenu } from "../../../../common/assets/images/hamburger.svg";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
  extendedListSubheader: {
    ...listStyles.extendedListSubheader,
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  container: {
    margin: "10px 44px",
    justifyContent: "space-between",
    "&.MuiGrid-spacing-xs-2 > .MuiGrid-item": {
      padding: 0,
    },
  },
  cell: {
    minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  blueContent: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.funBlue,
  },
  darkContent: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.blackPearl,
  },
  content: {},
  accordionDetails: {
    paddingBottom: "40px",
    flexDirection: "column",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  ssidInfoGrid: {
    display: "flex",
    width: "75%",
    justifyContent: "space-between",
  },
  status: {
    flexDirection: "column",
  },
  marginRight: {
    marginRight: 15,
  },
}));

interface SensorListItemProps {
  item: Sensor;
}

export function SensorsListItem({ item }: SensorListItemProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { setSelectedSensor } = useSensorsState();

  const handleExpand = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const history = useHistory();

  const [updateSensor] = useMutation(GQLService.mutations.updateSensor, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allSensors,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const toggleActiveStatus = useCallback(
    async () => {
      let isMounted = true;
      if (isMounted) {
        await updateSensor({
          variables: { ...item, active: !item.active, floorId: item.floor?.id },
        });
      }
      return () => {
        isMounted = false;
      };
    },
    [item] //eslint-disable-line
  );

  const toggleCommonAreaStatus = useCallback(
    async () => {
      let isMounted = true;
      if (isMounted) {
        await updateSensor({
          variables: {
            ...item,
            commonArea: !item.commonArea,
            floorId: item.floor?.id,
          },
        });
      }
      return () => {
        isMounted = false;
      };
    },
    [item] //eslint-disable-line
  );

  const handleViewClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedSensor(item);
      history.push(
        ROUTES.SENSORS_VIEW.replace(":uid", selectedProperty!.id).replace(
          ":sensorId",
          item.id
        )
      );
    },
    [item]
  );

  return (
    <Accordion key={item.id} expanded={isExpanded}>
      <AccordionSummary onClick={(e) => handleViewClick(e, item.id)}>
        <Grid container={true} alignItems="center" justify="space-between">
          <Grid
            container={true}
            item={true}
            md={3}
            sm={3}
            justify="flex-start"
            onClick={handleExpand}
            className={classes.headerWrapper}
          >
            {isExpanded ? (
              <ExpandLess id={`expandLess:${item.id}`} className={classes.expandIcon} />
            ) : (
              <ExpandMore id={`expandMore:${item.id}`} className={classes.expandIcon} />
            )}
            <SensorIcon className={classes.icon} />
            <Typography className={classes.name}>{item.name}</Typography>
          </Grid>
          <Grid item={true} md={3} sm={3}>
            <Typography className={classes.blueContent}>
              {item.floor.name ? item.floor.name : item.floor.floorNumber}
            </Typography>
          </Grid>
          <Grid container={true} item={true} md={6} sm={4}>
            <Typography className={classes.subheader}></Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.flex}>
          {selectedProperty && (
            <StatusIndicator
              uid={selectedProperty?.id}
              isActive={item.active}
              canShowStatusLabel={false}
            />
          )}
        </Grid>
        <AccordionActions
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <SensorsMenu sensor={item} index={item.id}/>
        </AccordionActions>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container={true} className={classes.container} spacing={2}>
          <Grid item className={classes.ssidInfoGrid}>
            <Grid item className={classes.marginRight}>
              <Typography className={classes.subheader}>
                {formatMessage({
                  id: "airQuality.listItem.label.floor",
                  defaultMessage: "Floor",
                })}
              </Typography>
              <Typography className={classes.blueContent}>
                {item.floor.name ? item.floor.name : item.floor.floorNumber}
              </Typography>
            </Grid>
            <Grid item className={classes.marginRight}>
              <Typography className={classes.subheader}>
                {formatMessage({
                  id: "airQuality.listItem.label.deviceUuid",
                  defaultMessage: "Device UUID",
                })}
              </Typography>
              <Typography className={classes.darkContent}>
                {item.deviceUuid}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.subheader}>
                {formatMessage({
                  id: "airQuality.listItem.label.active",
                  defaultMessage: "Active",
                })}
              </Typography>
              <Grid item className={classes.flex}>
                {selectedProperty && (
                  <StatusIndicator
                    uid={selectedProperty?.id}
                    isActive={item.active}
                    canShowStatusLabel={true}
                    onChangeFn={toggleActiveStatus}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.subheader}>
                {formatMessage({
                  id: "airQuality.listItem.label.commonArea",
                  defaultMessage: "Common Area",
                })}
              </Typography>
              <Grid item className={classes.flex}>
                {selectedProperty && (
                  <StatusIndicator
                    uid={selectedProperty?.id}
                    isActive={item.commonArea}
                    canShowStatusLabel={true}
                    onChangeFn={toggleCommonAreaStatus}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
