import { DrawerItems, DrawerAdminItems } from "../../types/app";
import { ROUTES } from "./Routing";

import I18n from "../../core/services/I18n.service";
import { PROPERTY_MODULE_ROUTES } from "../../core/router/PropertyRotuer";
import { BEACON_ROUTES } from "../../core/router/BeaconsRouter";
import { ELEVATORS_ROUTES } from "../../core/router/ElevatorsRouter";
import { ELEVATOR_BAYS_ROUTES } from "../../core/router/ElevatorBaysRouter";

export const DASHBOARD_DRAWER_ROUTES: DrawerItems = {
  portfolio: {
    label: I18n.formatMessage({
      id: "portfolio.root.label",
      defaultMessage: "Portfolio",
      description: "portfolio root label",
    }),
    items: [],
    pathname: ROUTES.PORTFOLIO,
  },
  dashboard: {
    label: I18n.formatMessage({
      id: "dashboard.root.label",
      defaultMessage: "Dashboard",
      description: "dashboard root label",
    }),
    items: [],
    pathname: ROUTES.DASHBOARD,
  },
  floor_overview: {
    label: I18n.formatMessage({
      id: "floorOverview.root.label",
      defaultMessage: "Floor Overview",
      description: "floorOverview root label",
    }),
    items: [],
    pathname: ROUTES.FLOOR_OVERVIEW,
  },
  my_pins: {
    label: I18n.formatMessage({
      id: "myPins.root.label",
      defaultMessage: "My Pins",
      description: "myPins root label",
    }),
    items: [],
    pathname: ROUTES.MY_PINS,
  },
  all_notifications: {
    label: I18n.formatMessage({
      id: "allNotifications.root.label",
      defaultMessage: "All Notifications",
      description: "allNotifications root label",
    }),
    items: [],
    pathname: ROUTES.ALL_NOTIFICATIONS,
  },
};

export const DASHBOARD_DRAWER_ADMIN_ROUTES: DrawerAdminItems = {
  access_control_system: {
    label: I18n.formatMessage({
      id: "dashboard.acs.label",
      defaultMessage: "Access Control",
      description: "access control system label",
    }),
    items: [],
    pathname: ROUTES.ACCESS_CONTROL_SYSTEM,
  },
  users: {
    label: I18n.formatMessage({
      id: "dashboard.users.label",
      defaultMessage: "User",
      description: "user menu manage label",
    }),
    items: [],
    pathname: ROUTES.USERS,
  },
  application_type: {
    label: I18n.formatMessage({
      id: "dashboard.appType.label",
      defaultMessage: "Application Type",
      description: "network groups label",
    }),
    items: [],
    pathname: ROUTES.NETWORK_GROUPS,
  },
};

export const createDashboardPropertyRoutes = {
  [PROPERTY_MODULE_ROUTES.PROPERTY]: { name: "Property Main" },
  [PROPERTY_MODULE_ROUTES.FLOORS]: { name: "Floors" },
  [PROPERTY_MODULE_ROUTES.SPACES]: { name: "Spaces" },
  [PROPERTY_MODULE_ROUTES.DOORS]: { name: "Doors" },
  [PROPERTY_MODULE_ROUTES.GROUPS]: { name: "Groups" },
  [PROPERTY_MODULE_ROUTES.NOTIFICATIONS]: { name: "Notifications" },
};

export const createDashboardNetworkRoutes = {
  [PROPERTY_MODULE_ROUTES.V_LANS]: { name: "Network Main" },
  [PROPERTY_MODULE_ROUTES.SSIDS]: { name: "SSID" },
  [PROPERTY_MODULE_ROUTES.ACCESS_POINTS]: { name: "Access Points" },
  [PROPERTY_MODULE_ROUTES.DEVICES]: { name: "Devices" },
};

export const createDashboardSensorsRoutes = {
  [PROPERTY_MODULE_ROUTES.SENSORS_MAIN]: { name: "Sensor Main" },
  [PROPERTY_MODULE_ROUTES.SENSORS]: { name: "Air Quality" },
  [PROPERTY_MODULE_ROUTES.IMAGES_SENSORS]: { name: "Image" },
  // [PROPERTY_MODULE_ROUTES.CARD_READERS]: { name: "Card Readers" }, Commented For future use
  [BEACON_ROUTES.BEACONS]: { name: "Beacons" },
};

export const createDashboardUpnextRoutes = {
  [PROPERTY_MODULE_ROUTES.UPNEXT_APP]: { name: "Upnext Main" },
  [ELEVATORS_ROUTES.ELEVATORS]: { name: "Elevator" },
  [ELEVATOR_BAYS_ROUTES.ELEVATOR_BAYS]: { name: "Elevator Bays" },
  [PROPERTY_MODULE_ROUTES.RESERVATION_SETTINGS]: {
    name: "Reservation Settings",
  },
};
