import React, { useCallback, useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
  TableBody,
  Switch,
  Grid,
} from "@material-ui/core";
import { useStyles } from "./AccessControl.styles";
import clsx from "clsx";
import GQLService from "../../core/services/GQL.service";
import { useLazyQuery } from "@apollo/client";
import Loader from "react-loader-spinner";
import { COLORS } from "../../common/styles/colors";
import moment from "moment";

interface UnAssociatedProps {
  setCheckedData: Function;
  setTabType: Function;
}

export function UnAssociated({
  setCheckedData,
  setTabType,
}: UnAssociatedProps) {
  const classes = useStyles();
  const [cardReaderData, setCardReaderData] = useState<any>();
  const [isSwitchActive, setIsSwitchActive] = useState<boolean>(true);
  const [selected, setSelected] = useState<readonly string[]>([]);

  const [getUnAssociatedCardReaders, { loading, data, error }] = useLazyQuery(
    GQLService.queries.unAssociatedCardReaders,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    getUnAssociatedCardReaders();
    setCheckedData([]);
  }, []);

  useEffect(() => {
    if (!loading && data) {
      setCardReaderData(data?.unassociatedCardReaders);
      setSelected([]);
    }
  }, [loading, data]);

  const handleChange = useCallback(
    (e) => {
      setIsSwitchActive(!isSwitchActive);
    },
    [isSwitchActive]
  );

  const getLastSeen = (time: any) => {
    let currentUTCTime = moment.utc();
    let lastSeenUTCTime = moment(time).utc();
    let secondsDiff = currentUTCTime.diff(lastSeenUTCTime, "seconds");

    if (secondsDiff >= 86400) {
      let days = currentUTCTime.diff(lastSeenUTCTime, "days");
      return days === 1 ? `${days} day ago` : `${days} days ago`;
    } else if (secondsDiff >= 3600) {
      let hours = currentUTCTime.diff(lastSeenUTCTime, "hours");
      return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
    } else if (secondsDiff >= 60) {
      let minutes = currentUTCTime.diff(lastSeenUTCTime, "minutes");
      return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
    } else {
      return secondsDiff === 1
        ? `${secondsDiff} second ago`
        : `${secondsDiff} seconds ago`;
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = cardReaderData?.cardReaderResponse?.map(
        (n: any) => n.id
      );
      const newData = cardReaderData?.cardReaderResponse?.map((n: any) => {
        return {
          id: n.id,
        };
      });
      setSelected(newSelected);
      setTabType("UnAssociated");
      setCheckedData(newData);
      return;
    }
    setSelected([]);
    setCheckedData([]);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleClick = (event: any, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    const resultArray: any = [];
    newSelected.forEach((item: any) => {
      const obj = cardReaderData?.cardReaderResponse.find(
        (findObj: any) => findObj.id === item
      );
      if (obj) {
        const newData = {
          id: obj.id,
        };
        resultArray.push(newData);
      }
    });

    setSelected(newSelected);
    setTabType("UnAssociated");
    setCheckedData(resultArray);
  };

  return loading ? (
    <Grid className={classes.loaderGrid}>
      <Loader type="Oval" color={COLORS.alto} height={150} width={150} />
    </Grid>
  ) : cardReaderData?.cardReaderResponse?.length > 0 ? (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead style={{ backgroundColor: "#E0EBF1" }}>
          <TableRow className={classes.tableRowData}>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                inputProps={{
                  "aria-label": "Select All",
                }}
                onChange={handleSelectAllClick}
                checked={
                  cardReaderData?.cardReaderResponse?.length > 0 &&
                  selected.length === cardReaderData?.cardReaderResponse?.length
                }
              />
            </TableCell>
            <TableCell>
              <Typography className={classes.tableTH}>
                Card Reader ID
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableTH}>
                Card Reader Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableTH}>Vendor Name</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableTH}>Status</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableTH}>Last Seen</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cardReaderData?.cardReaderResponse?.map(
            (item: any, index: number) => {
              const isItemSelected = isSelected(item.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  aria-checked={isItemSelected}
                  role="checkbox"
                  tabIndex={-1}
                  key={item.id}
                >
                  <TableCell
                    padding="checkbox"
                    className={classes.tableRowData}
                  >
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onChange={(event) => handleClick(event, item.id)}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.tableRowData}>
                    <Typography className={classes.tableTD}>
                      {item?.id}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableRowData}>
                    <Typography className={classes.tableTD}>
                      {item?.name}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableRowData}>
                    <Typography className={classes.tableTD}>
                      {item?.vendorName}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableRowData}>
                    <div style={{ display: "flex" }}>
                      <Switch
                        name={"acsStatus"}
                        value={item?.active}
                        checked={item?.active}
                        classes={{
                          root: classes.switch,
                          checked: classes.checked,
                          thumb: classes.thumb,
                          track: classes.track,
                          switchBase: classes.base,
                        }}
                        disabled={true}
                        onChange={handleChange}
                      />
                      <div className={classes.status}>
                        {item?.active ? "Active" : "Inactive"}
                      </div>
                      <div
                        className={clsx(
                          classes.outerCircle,
                          item?.active
                            ? classes.outerCircleGreen
                            : classes.outerCircleRed
                        )}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "5px",
                        }}
                      >
                        <div
                          className={clsx(
                            classes.innerCircle,
                            item?.active
                              ? classes.innerCircleGreen
                              : classes.innerCircleRed
                          )}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableRowData}>
                    <Typography className={classes.tableTD}>
                      {getLastSeen(item?.lastSeen)}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <></>
  );
}
