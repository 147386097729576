import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { Space } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";
import { useSearchState } from "./Search.container";

function useSpacesBase() {
  const [spaces, setSpaces] = useState<Space[]>([]);
  const [spacesFromResponse, setSpacesFromResponse] = useState<Space[]>([]);
  const [selectedSpaceSummary, setSelectedSpaceSummary] = useState<Space>();
  const [isNetFlowIsChecked, setIsNetFlowIsChecked] = useState<boolean>();
  const { search } = useSearch();

  const searchSpaces = useCallback(
    (searchValue: string) => {
      setSpaces(search(spacesFromResponse, searchValue) as Space[]);
    },
    [spacesFromResponse]
  );

  const {
    state: selectedSpace,
    setState: setSelectedSpace,
  } = usePersistedState<Space | null>("space", null);

  return {
    searchSpaces,
    spaces,
    setSpaces,
    spacesFromResponse,
    setSpacesFromResponse,
    selectedSpace,
    setSelectedSpace,
    selectedSpaceSummary,
    setSelectedSpaceSummary,
    isNetFlowIsChecked,
    setIsNetFlowIsChecked,
  };
}

const { Provider, useContainer } = createContainer(useSpacesBase);

export const useSpacesState = useContainer;
export const SpacesProvider = Provider;
