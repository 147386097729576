import React, { useCallback, useEffect, useState } from "react";
import { FieldInputProps, useFormikContext } from "formik";
import { Button, MenuItem, Grid, Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import { Select } from "@material-ui/core";
import { useIntl } from "react-intl";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { useQuery } from "@apollo/client";

import { useStyles } from "./SelectFloorFromProperty.styles";
import { useFormWizardState } from "../../../../../core/context/containers/FormWizard.container";
import GQLService from "../../../../../core/services/GQL.service";
import { Floor } from "../../../../../types";

type renderElement = {
  id: string;
  name: string;
};

type Tag = {
  name: string;
  floorId: string;
  propertyId: string;
};

interface SelectWithTagsProps extends FieldInputProps<any> {
  defaultValue: any;
  placeholder?: string | undefined;
  disabled?: boolean;
  data: renderElement[];
  customProps: {
    title: string;
    noTagsMessage: string;
    selectFloor: string;
    selectProperty: string;
    assigned?: {
      properties: any[];
      floors: any[];
    };
  };
}

export function SelectFloorFromProperty({
  defaultValue,
  name,
  placeholder,
  disabled,
  onChange,
  data,
  customProps,
}: SelectWithTagsProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { values, setFieldValue, initialValues } = useFormikContext<{
    [key: string]: any;
  }>();
  const { formData, setFormData } = useFormWizardState();
  const defaultTags =
    customProps.assigned?.floors?.map((floor) => ({
      floorId: floor.id,
      propertyId: floor.propertyId,
      name: `Floor ${floor.name || floor.floorNumber} from ${
        customProps.assigned?.properties?.find(
          (property) => property.id === floor.propertyId
        )?.siteName
      }`,
    })) || [];

  const [tags, setTags] = useState<Tag[]>(defaultTags);
  const [dataToRender, setDataToRender] = useState<Floor[]>();
  const [selectedProperty, setSelectedProperty] = useState<{
    propertyId: string;
    name: string;
  }>({ propertyId: data[0].id, name: data[0].name });

  const [selectedFloor, setSelectedFloor] = useState<{
    floorId: string;
    name: string;
  }>({ floorId: "", name: placeholder || "" });

  const { loading, data: floors } = useQuery(GQLService.queries.allFloors, {
    variables: { propertyId: selectedProperty.propertyId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setDataToRender(floors?.floorsByPropertyId);
    }
    return () => {
      isMounted = false;
    };
  }, [floors]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setFieldValue(
        name,
        tags.map((tag) => tag.floorId)
      );
      setFieldValue("propertiesIds", [
        ...new Set(tags.map((tag) => tag.propertyId)),
      ]);
      setFormData({
        ...formData,
        [name]: tags.map((tag) => tag.floorId),
        propertiesIds: [...new Set(tags.map((tag) => tag.propertyId))],
      });
    }
    return () => {
      isMounted = false;
    };
  }, [tags]);

  const handleClose = useCallback(
    (e: any, id: string) => {
      const newTags = tags?.filter((tag) => tag.floorId !== id);
      setTags(newTags);
    },
    [tags, selectedFloor]
  );

  const handleSelect = useCallback(
    (e: any, v: any) => {
      const floor = dataToRender?.find((floor) => floor.id === e.target.value);

      floor &&
        setSelectedFloor({
          floorId: floor?.id,
          name: floor?.name || floor?.floorNumber.toString(),
        });
    },
    [selectedFloor, dataToRender]
  );

  const handleSelectedPropertyId = useCallback(
    (e: any, v: any) => {
      const property = data.find((property) => property.id === e.target.value);
      setSelectedProperty({
        propertyId: property?.id!,
        name: property?.name!,
      });
    },
    [selectedProperty]
  );

  const handleAddTag = useCallback(
    (e: any) => {
      let newTags: Tag[] = [...tags];
      let tagIds = tags.map((tag) => tag.floorId);
      if (
        selectedFloor.floorId &&
        selectedFloor.name !== placeholder &&
        tagIds.indexOf(selectedFloor.floorId) === -1
      ) {
        newTags = [
          ...tags,
          {
            floorId: selectedFloor.floorId,
            propertyId: selectedProperty.propertyId,
            name: `Floor ${selectedFloor.name} from ${selectedProperty.name}`,
          },
        ];
      }
      setTags(newTags);
    },
    [tags, selectedFloor]
  );

  return (
    <Grid container direction="row" className={classes.mainContainer}>
      <Grid item sm={12} className={classes.generalSelectWrapper}>
        <Typography className={classes.subTitle}>
          {customProps.selectProperty}
        </Typography>

        <Grid item sm={8}>
          <Select
            onChange={(e, v) => handleSelectedPropertyId(e, v)}
            classes={{
              root: classes.select,
              select: classes.blackText,
              icon: classes.icon,
            }}
            MenuProps={{
              classes: {
                paper: classes.menu,
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            IconComponent={ExpandMoreSharpIcon}
            value={selectedProperty.propertyId}
            fullWidth={true}
            placeholder={placeholder}
            disableUnderline={true}
            disabled={disabled}
          >
            {data?.map((option, i) => (
              <MenuItem
                key={i}
                value={option.id}
                classes={{
                  root: classes.option,
                  selected: classes.selected,
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid item sm={12} className={classes.generalSelectWrapper}>
        <Typography className={classes.subTitle}>
          {customProps.selectFloor}
        </Typography>

        <Grid item sm={6} className={classes.selectFloorWrapper}>
          <Select
            onChange={(e, v) => handleSelect(e, v)}
            classes={{
              root: classes.select,
              select: classes.blackText,
              icon: classes.icon,
            }}
            MenuProps={{
              classes: {
                paper: classes.menu,
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            IconComponent={ExpandMoreSharpIcon}
            value={selectedFloor.floorId}
            fullWidth={true}
            placeholder={placeholder}
            disableUnderline={true}
            disabled={disabled}
            renderValue={
              selectedFloor.name !== placeholder
                ? undefined
                : () => <Placeholder>{placeholder}</Placeholder>
            }
          >
            {dataToRender?.map((option, i) => (
              <MenuItem
                key={i}
                value={option.id}
                classes={{
                  root: classes.option,
                  selected: classes.selected,
                }}
              >
                {option.name || option.floorNumber}
              </MenuItem>
            ))}
          </Select>
          <Grid item sm={2}>
            <Button className={classes.submitBtn} onClick={handleAddTag}>
              {formatMessage({
                id: "FormField.SelectFloorFromProperty.Button.Add",
                defaultMessage: "Add",
                description:
                  "Form field Select Floor From Property Button Label",
              })}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.root}>
        <div className={classes.title}>{customProps.title}</div>
        <div className={classes.chipContainer}>
          {tags?.length !== 0 ? (
            tags?.map((el, index) => {
              return (
                <li key={index}>
                  <Chip
                    label={el.name}
                    disabled={false}
                    onDelete={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleClose(e, el.floorId)
                    }
                    className={classes.chip}
                    deleteIcon={<CloseSharpIcon className={classes.chipIcon} />}
                  />
                </li>
              );
            })
          ) : (
            <div className={classes.noTagsMessage}>
              {customProps.noTagsMessage}
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
}

type PlaceholderProps = {
  children: React.ReactNode;
};

function Placeholder({ children }: PlaceholderProps) {
  const classes = useStyles();
  return <div className={classes.placeholder}>{children}</div>;
}
