import React, { useCallback, useEffect, useState } from "react";
import { FieldInputProps, useFormikContext } from "formik";
import { Button, MenuItem, Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import Chip from "@material-ui/core/Chip";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import { Select } from "@material-ui/core";
import { useStyles } from "./SelectWithTags.styles";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { useFormWizardState } from "../../../../../core/context/containers/FormWizard.container";

type renderElement = {
  id: string;
  name: string;
  zone?: string;
  tripWire?: string;
  cameraName?: string;
  cameraUuid?: string;
};

interface SelectWithTagsProps extends FieldInputProps<any> {
  defaultValue: any;
  placeholder?: string | undefined;
  disabled?: boolean;
  data: renderElement[];
  customProps: {
    title: string;
    noTagsMessage: string;
    existingSSIDs: [];
    addBtnId?: string;
    selectedAPids?: (el: any) => any;
  };
}

export function SelectWithTags({
  defaultValue,
  name,
  placeholder,
  disabled,
  onChange,
  data,
  customProps,
}: SelectWithTagsProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { values, setFieldValue, initialValues } = useFormikContext<{
    [key: string]: any;
  }>();
  const { formData, setFormData } = useFormWizardState();
  const [tags, setTags] = useState<string[]>(values[name] || []);
  const [selectValue, setSelectValue] = useState(placeholder);

  // Add this conditionally in case of edit space should not empty tags
  useEffect(() => {
    if (
      customProps?.existingSSIDs &&
      customProps?.existingSSIDs?.length !== 0
    ) {
      setTags([...customProps?.existingSSIDs]);
    } else {
      setTags([]);
    }
  }, [data]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setFieldValue(name, tags);
      setFormData({
        ...formData,
        [name]: tags,
      });
    }
    return () => {
      isMounted = false;
    };
  }, [tags]);

  const handleClose = useCallback(
    (e: any, name: string) => {
      const newTags = tags?.filter((tag) => tag !== name);
      setTags(newTags);
      customProps?.selectedAPids!(newTags);
    },
    [tags, selectValue]
  );

  const handleSelect = useCallback(
    (e: any, v: any) => {
      setSelectValue(e.target.value);
    },
    [selectValue]
  );

  const handleAddTag = useCallback(
    (e: any) => {
      let newTags: string[] = [...tags];
      if (
        selectValue &&
        selectValue !== placeholder &&
        tags.indexOf(selectValue) === -1
      ) {
        newTags = [...tags, selectValue];
      }
      setTags(newTags);
      customProps?.selectedAPids!(newTags);
    },
    [tags, selectValue]
  );

  const showZone = (option: any) => {
    if (option.zone) {
      return (option.cameraName || option.cameraUuid) + " : " + option.zone;
    }
  };

  const showTripWire = (option: any) => {
    if (option.tripWire) {
      return (option.cameraName || option.cameraUuid) + " : " + option.tripWire;
    }
  };

  return (
    <Grid container direction="row" className={classes.mainContainer}>
      <Grid item sm={6}>
        <Select
          onChange={(e, v) => handleSelect(e, v)}
          classes={{
            root: classes.select,
            select: classes.blackText,
            icon: classes.icon,
          }}
          MenuProps={{
            classes: {
              paper: classes.menu,
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          IconComponent={ExpandMoreSharpIcon}
          value={selectValue}
          fullWidth={true}
          placeholder={placeholder}
          disableUnderline={true}
          disabled={disabled}
          renderValue={
            selectValue !== placeholder
              ? undefined
              : () => <Placeholder>{placeholder}</Placeholder>
          }
        >
          {data.map((option, i) => (
            <MenuItem
              key={i}
              value={option.id}
              classes={{
                root: classes.option,
                selected: classes.selected,
              }}
            >
              {option.name || showZone(option) || showTripWire(option)}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item sm={6}>
        <Button
          id={customProps.addBtnId ? customProps.addBtnId : "addBtn"}
          className={classes.submitBtn}
          onClick={handleAddTag}
        >
          {formatMessage({
            id: "FormFields.SelectWithTags.Add.Button",
            defaultMessage: "Add",
            description: "Form Fields Select With Tags Add Button",
          })}
        </Button>
      </Grid>
      <div className={classes.root}>
        <div className={classes.title}>{customProps.title}</div>
        <div className={classes.chipContainer}>
          {tags?.length !== 0 ? (
            data?.map((el, index) => {
              if (tags?.includes(el.id)) {
                return (
                  <li key={index}>
                    <Chip
                      label={el?.name || showZone(el) || showTripWire(el)}
                      disabled={false}
                      onDelete={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleClose(e, el?.id)
                      }
                      className={classes.chip}
                      deleteIcon={
                        <CloseSharpIcon className={classes.chipIcon} />
                      }
                    />
                  </li>
                );
              }
            })
          ) : (
            <div className={classes.noTagsMessage}>
              {customProps.noTagsMessage}
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
}

type PlaceholderProps = {
  children: React.ReactNode;
};

function Placeholder({ children }: PlaceholderProps) {
  const classes = useStyles();
  return <div className={classes.placeholder}>{children}</div>;
}
