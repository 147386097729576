import { gql } from "@apollo/client";

export const USER_FILTER_PREFERENCE_QUERIES = {
  getUserFilterPrefernce: gql`
    query userFilterPreference(
      $dataType: UserFilterPreferenceType!
      $email: String!
      $propertyId: String!
    ) {
      userFilterPreference(
        request: { dataType: $dataType, email: $email, propertyId: $propertyId }
      ) {
        dataType
        dataValue
        id
        propertyId
      }
    }
  `,
  userPinsByUserId: gql`
    query userPinsByUserId($userEmail: String) {
      userPinsByUserId(userEmail: $userEmail) {
        id
        deviceId
        deviceName
        deviceType
        duration
        floor {
          id
          name
          floorNumber
        }
        property {
          id
          siteName
          ownerCompanyName
          spaceLoadMediumMax
          spaceLoadMediumMin
          timeZone
          timeZoneName
          sensorConfigurations {
            attribute
            maxVal
            minVal
            sensorHealthIndicator
            isCelsius
          }
        }
        queryData
        space {
          id
          name
        }
        title
      }
    }
  `,
};

export const USER_FILTER_PREFERENCE_MUTATIONS = {
  updateUserFilterPreference: gql`
    mutation updateUserFilterPreference(
      $dataType: UserFilterPreferenceType!
      $dataValue: ObjectScalar!
      $email: String!
      $propertyId: String!
    ) {
      updateUserFilterPreference(
        request: {
          dataType: $dataType
          dataValue: $dataValue
          email: $email
          propertyId: $propertyId
        }
      ) {
        dataType
        dataValue
        id
        propertyId
      }
    }
  `,
  createUserPin: gql`
    mutation createUserPin(
      $deviceId: String!
      $deviceType: DashboardDeviceType!
      $duration: String!
      $floorId: String!
      $propertyId: String!
      $queryData: ObjectScalar!
      $spaceId: String
      $userEmail: String!
      $title: String!
    ) {
      createUserPin(
        request: {
          deviceId: $deviceId
          deviceType: $deviceType
          duration: $duration
          floorId: $floorId
          propertyId: $propertyId
          queryData: $queryData
          spaceId: $spaceId
          userEmail: $userEmail
          title: $title
        }
      ) {
        msg
      }
    }
  `,
  deleteUserPin: gql`
    mutation deleteUserPinById(
      $deviceId: String!
      $userEmail: String!
      $deviceType: DashboardDeviceType
      $floor_id: String
      $space_id: String
    ) {
      deleteUserPinById(
        request: {
          deviceId: $deviceId
          userEmail: $userEmail
          deviceType: $deviceType
          floor_id: $floor_id
          space_id: $space_id
        }
      ) {
        msg
      }
    }
  `,
  updateUserPin: gql`
    mutation updateUserPin($title: String!, $id: String!) {
      updateUserPin(request: { title: $title, id: $id }) {
        title
      }
    }
  `,
};
