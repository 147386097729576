import { usePropertiesState } from "../containers/Properties.container";
import { Property, Floor, SSID } from "../../../types";

export const useProperties = () => {
  const {
    properties,
    selectedProperty,
    setProperties,
    setSelectedProperty,
  } = usePropertiesState();

  const getProperties = () => {
    return Promise.resolve();
  };

  const selectProperty = (uid: string) => {
    setSelectedProperty(properties.find((v) => v.id === uid) as Property);
  };

  const addProperty = (property: Property) => {
    return Promise.resolve(property);
  };

  const editProperty = (property: Property) => {
    return Promise.resolve(property);
  };

  const editFloor = (property: Property, floor: Floor) => {
    return Promise.resolve(property);
  };

  const addFloor = (floor: Floor) => {
    // return FirebaseService.api
    //   .addFloor(selectedProperty?.id!, floor)
    //   .then((floor) =>
    //     setSelectedProperty({
    //       ...selectedProperty!,
    //       // floors: [...selectedProperty?.floors!, floor],
    //     })
    //   );
  };

  const addSSID = (ssid: SSID) => {
    // return FirebaseService.api
    //   .addSSID(selectedProperty?.id!, ssid)
    //   .then((ssid) =>
    //     setSelectedProperty({
    //       ...selectedProperty!,
    //       ssids: [...selectedProperty?.ssids!, ssid],
    //     })
    //   );
  };

  const editSSID = (ssid: SSID) => {
    // return FirebaseService.api
    //   .editSSID(selectedProperty?.uid!, ssid)
    //   .then((ssid) =>
    //     setSelectedProperty({
    //       ...selectedProperty!,
    //       ssids: [...selectedProperty?.ssids!, ssid],
    //     })
    //   );
  };

  const changePropertyStatus = async (
    propertyUid: string,
    isActive: boolean
  ) => {
    setSelectedProperty({ ...selectedProperty!, companyStatus: isActive });
  };

  const deleteProperty = (uid: string) => {
    setProperties(properties.filter((v) => v.id !== uid));
  };

  const clearPropertiesContext = () => {
    setSelectedProperty(null);
  };

  return {
    getProperties,
    selectProperty,
    addProperty,
    editProperty,
    editFloor,
    addFloor,
    changePropertyStatus,
    deleteProperty,
    clearPropertiesContext,
    addSSID,
    editSSID,
  };
};
