import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";

import { DetailLabel } from "../../DetailLabel";
import { Property, Role } from "../../../../../../../types";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../../../../core/context/containers/Auth.container";

const useStyles = makeStyles((theme) => ({
  blackHeader: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    marginBottom: theme.spacing(5),
    lineHeight: theme.typography.pxToRem(24),
  },
  secondHeader: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    lineHeight: theme.typography.pxToRem(24),
  },
  marginTop: {
    marginTop: theme.typography.pxToRem(45),
  },
  detailsLabel: {
    color: theme.palette.common.solidGray,
  },
  button: {
    background: "#FFFFFF",
    border: `1px solid ${theme.palette.common.regularBlue}`,
    boxSizing: "border-box",
    borderRadius: "35px",
    height: "26px",
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    textTransform: "capitalize",
    color: theme.palette.common.regularBlue,
    width: "82px",
    marginBottom: "1.875rem",
    alignSelf: "flex-end",
    marginLeft: "10px",
  },
  copyWrapper: {
    display: "flex",
    width: "90%",
  },
}));

interface AccountInfoProps {
  property: Property;
}

export function AccountInfo({ property }: AccountInfoProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const dateFormat = moment(property.dateCreated).toLocaleString();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const auth = useAuth();

  const copy = useCallback(() => {
    const textField = document.createElement("textarea");
    textField.innerText = property.id;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    enqueueSnackbar(
      formatMessage({
        id: "copyId.snackbar.success",
        defaultMessage: "Property Id Copied",
      }),
      {
        variant: "success",
      }
    );

    setTimeout(() => {
      closeSnackbar();
    }, 1000);
  }, [property]);

  const createPropertyIsDetails = useCallback(() => {
    return (
      <Grid className={classes.copyWrapper}>
        <DetailLabel
          label={formatMessage({
            id: "accountInfo.businessEntity.header",
            defaultMessage: "Property Id",
            description: "business entity header",
          })}
          value={property.id}
        />
        <Button className={classes.button} onClick={copy}>
          {formatMessage({
            id: "accountInfo.propertyId.copyButton",
            defaultMessage: "Copy",
            description: "Copy property ID",
          })}
        </Button>
      </Grid>
    );
  }, []);

  return (
    <Grid className={classes.marginTop}>
      <Typography className={classes.blackHeader}>
        {formatMessage({
          id: "accountInfo.header",
          defaultMessage: "Account Information",
          description: "account information header",
        })}
      </Typography>

      {auth?.canSee(
        auth?.user?.role?.toUpperCase() as Role,
        "properties:edit",
        createPropertyIsDetails()
      )}
      {/* <DetailLabel
        label={formatMessage({
          id: "accountInfo.businessEntity.header",
          defaultMessage: "Business Entity",
          description: "business entity header",
        })}
        value={property.accountBusinessEntity}
      /> */}
      {/* <DetailLabel
        label={formatMessage({
          id: "accountInfo.businessEntity.header",
          defaultMessage: "Date Created",
          description: "date created header",
        })}
        value={dateFormat}
      /> */}

      <Typography className={classes.secondHeader}>
        {formatMessage({
          id: "primaryContact.header",
          defaultMessage: "Primary Contact",
          description: "primary contact header",
        })}
      </Typography>
      <DetailLabel
        label={formatMessage({
          id: "primaryContact.label.fullName",
          defaultMessage: "Full Name",
          description: "primary contact full name label",
        })}
        value={property.primaryContactFullName || undefined}
      />
      <DetailLabel
        label={formatMessage({
          id: "primaryContact.label.phoneNumber",
          defaultMessage: "Phone Number",
          description: "primary contact phone number label",
        })}
        value={property.primaryContactPhoneNumber || undefined}
      />
      <DetailLabel
        label={formatMessage({
          id: "primaryContact.label.email",
          defaultMessage: "Email",
          description: "primary contact email label",
        })}
        value={property.primaryContactEmailAddress || undefined}
      />
    </Grid>
  );
}
