import React, { useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { Input, InputLabel, Button, CircularProgress } from "@material-ui/core";
import { useStyles } from "./RecoveryForm.styles";

interface SignInFormProps {
  onSubmit: (email: string) => Promise<void>;
}

export function PasswordRecoveryForm({ onSubmit }: SignInFormProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [mail, setMail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setMail(e.target.value.toLowerCase());
    },
    []
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);
      setIsLoading(true);
      onSubmit(mail)
        .catch((e) => {
          setError(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [mail] // eslint-disable-line
  );

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <>
        <InputLabel className={classes.inputLabel} error={Boolean(error)}>
          {formatMessage({
            id: "passwordRecoveryForm.label.emailAddress",
            defaultMessage: "Email Address",
            description: "email address input field label",
          })}{" "}
          {`${error ? `- ${error}` : ""}`}
        </InputLabel>
        <Input
          className={classes.input}
          margin="dense"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          autoFocus={true}
          placeholder="name@company.com"
          name="email"
          autoComplete="email"
          onChange={handleInputChange}
        />
      </>
      <Button
        className={classes.submit}
        type="submit"
        variant="contained"
        disabled={isLoading}
        id="resetPasswordBtn"
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          formatMessage({
            id: "passwordRecoveryForm.button.reset",
            defaultMessage: "Reset Password",
            description: "reset password button label",
          })
        )}
      </Button>
    </form>
  );
}
