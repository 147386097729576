import RestAuthClient, { IRestAuthEndpoints } from "../client/RestApiClient";
import Rules from "../../common/constants/Rules";
import { availableScopes } from "../../types/roles";
import {
  AccessToken,
  AuthResponse,
  LoginWithEmailAndPasswordParams,
} from "../../types";
import { APP_DEFAULTS } from "../../common/constants/App";
import API from "../api/api";

export const endpointsMapping: IRestAuthEndpoints = {
  loginWithEmailAndPassword: async ({ username, password }) => {
    try {
      delete API.defaults.headers.Authorization;

      const response = await API.post("login", {
        username,
        password,
      });
      const data = await response.data;
      const {
        access_token,
        expires_in,
        refresh_token,
        token_type,
        roles,
        username: usernameFromResponse,
      }: AuthResponse = data;
      return {
        data: {
          access_token,
          refresh_token,
          username,
          roles,
          expires_in,
          token_type,
        },
      };
    } catch (ex) {
      return { data: null, error: ex };
    }
  },
  refreshToken: async (refreshToken) => {
    try {
      delete API.defaults.headers.Authorization;

      const response = await API.post("oauth/access_token", {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      });
      const {
        access_token,
        expires_in,
        refresh_token,
        token_type,
        username,
        roles,
      }: AuthResponse = await response.data;
      API.defaults.headers.Authorization = `${token_type} ${access_token}`;

      return {
        data: {
          access_token,
          refresh_token,
          username,
          roles,
          expires_in,
          token_type,
        },
      };
    } catch (ex) {
      return { data: null };
    }
  },
};

export class CustomRestApiClient extends RestAuthClient {
  instance: number;
  shouldAutoRefresh: boolean;
  constructor(mappings: IRestAuthEndpoints, storage: Storage) {
    super(mappings, storage);
    const couldAutoRefresh = !!storage.getItem("accessToken");
    this.instance = couldAutoRefresh ? Date.now() : 0;
    this.shouldAutoRefresh = true;
    storage.setItem("instance", this.instance.toString());

    window.addEventListener("beforeunload", () => {
      this.shouldAutoRefresh = true;
      storage.setItem("instance", "0");
    });

    window.addEventListener("storage", ({ key, newValue }) => {
      if (key === "instance") {
        if (+newValue! > this.instance) {
          this.shouldAutoRefresh = true;
        } else {
          this.shouldAutoRefresh = true;
          storage.setItem("instance", this.instance.toString());
        }
      }
      if (key === "accessToken") {
        if (newValue) {
          API.defaults.headers.Authorization = `Bearer ${newValue}`;
        } else {
          this.logout();
        }
      }
      if (key === "authUser") {
        if (!newValue) {
          this.logout();
        }
      }
    });
  }

  shouldRefreshTokens() {
    return this.shouldAutoRefresh && super.shouldRefreshTokens();
  }
  async logout() {
    this.shouldAutoRefresh = true;
    this.instance = 0;
    return super.logout();
  }
  async loginWithEmailAndPassword(params: LoginWithEmailAndPasswordParams) {
    await super.loginWithEmailAndPassword(params);
    this.instance = Date.now();
    this.shouldAutoRefresh = true;
    this.storage.setItem("instance", this.instance.toString());
  }
}

const authClient = new CustomRestApiClient(
  endpointsMapping,
  window.localStorage
);

export default authClient;
