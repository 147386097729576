import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  switch: {
    color: `${theme.palette.common.switchBlue} !important`,
    border: `1px solid ${theme.palette.common.switchBlue}`,
    borderRadius: "30px;",
    height: 17,
    width: 52,
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `#2F56A1 !important`,
    width: 12,
    height: 12,
  },
  checked: {
    color: "transparent !important",
  },
  base: {
    height: 22,
  },
  error: {
    border: `1px solid ${theme.palette.common.cinnabar}`,
    borderRadius: "30px;",
    color: `${theme.palette.common.switchBlue} !important`,
    height: 17,
    width: 52,
  },
  status: {
    color: "#122036",
    marginLeft: 6,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 3,
  },
}));
