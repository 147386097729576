import {
  ActivitySwitch,
  Input,
  Select,
  SelectWithTagsForEdit,
} from "../../../../common/components/Form/FormFields";
import * as Yup from "yup";
import { FormField } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";
import { FullyCoveredSpaceCheckbox } from "../../../../common/components/Form/FullyCoveredSpaceCheckbox/FullyCoveredSpaceCheckbox";
import { EmptyComponent } from "./EmptyComponent";
import { FloorSelectForEdit } from "../../../../common/components/Form/FormFields/FloorSelect/FloorSelectForEdit";
import { ResetNetFlowTimeForEdit } from "../../../../common/components/Form/ResetNetFlow/ResetNetflowForEdit";
import { SelectCommonSpace } from "../../../../common/components/Form/FormFields/SelectCommonSpace/SelectCommonSpace";

const FIELD_NAMES = {
  name: "name",
  floorId: "floorId",
  area: "area",
  airSensors: "airSensors",
  accessPoints: "accessPoints",
  zones: "zones",
  tripWires: "tripWires",
  doors: "doors",
  isNetflowActive: "isNetflowActive",
  active: "active",
  resetTime: "resetTime",
  commonSpaceId: "commonSpaceId",
  ssids: "ssids",
};

export const editFormFields = (
  airSensorData: any[] = [],
  accessPointData: any[] = [],
  selectedAPids: (el: any) => any,
  removeAPids: () => any,
  zonesData: any[] = [],
  tripWireData: any[] = [],
  doorData: any[] = [],
  isNetFlowActive: any,
  commonSpacesData: any[] = [],
  ssidData: any[] = [],
  tempData: any[] = []
): FormField[] => [
  {
    name: FIELD_NAMES.name,
    label: I18n.formatMessage({
      id: "spaces.add.label.name",
      defaultMessage: "Space Name",
    }),
    placeholder: I18n.formatMessage({
      id: "spaces.add.placeholder.name",
      defaultMessage: "Space Name",
    }),
    required: true,
    gridStyle: { sm: 8, md: 4 },
    Component: Input,
  },
  {
    name: "",
    label: "",
    gridStyle: { sm: 4, md: 2 },
    Component: EmptyComponent,
  },
  {
    name: FIELD_NAMES.area,
    label: I18n.formatMessage({
      id: "spaces.add.label.area",
      defaultMessage: "Space Capacity",
    }),
    placeholder: I18n.formatMessage({
      id: "spaces.add.placeholder.area",
      defaultMessage: "0",
    }),
    required: false,
    gridStyle: { sm: 2, md: 1 },
    Component: Input,
  },
  {
    name: "",
    label: "",
    gridStyle: { sm: 2, md: 4 },
    Component: EmptyComponent,
  },
  {
    name: FIELD_NAMES.floorId,
    label: I18n.formatMessage({
      id: "spaces.add.label.floorId",
      defaultMessage: "Floor",
    }),
    gridStyle: { sm: 7, md: 3 },
    Component: FloorSelectForEdit,
    disabled: true,
  },
  {
    name: "",
    label: "",
    gridStyle: { sm: 6, md: 3 },
    Component: EmptyComponent,
  },
  {
    name: FIELD_NAMES.commonSpaceId,
    label: I18n.formatMessage({
      id: "spaces.add.label.spaceType",
      defaultMessage: "Space Type",
    }),
    placeholder: I18n.formatMessage({
      id: "spaces.add.placeholder.spaceType",
      defaultMessage: "None",
    }),
    required: false,
    gridStyle: { sm: 1, md: 3 },
    data: commonSpacesData,
    Component: SelectCommonSpace,
  },
  {
    name: FIELD_NAMES.accessPoints,
    label: I18n.formatMessage({
      id: "spaces.add.label.assignedAccessPoints",
      defaultMessage: "Assign Access Points",
    }),
    placeholder: I18n.formatMessage({
      id: "spaces.add.placeholder.assignedAccessPoints",
      defaultMessage: "Pick from the list",
    }),
    required: false,
    gridStyle: { sm: 12, md: 6 },
    Component: SelectWithTagsForEdit,
    data: accessPointData,
    customProps: {
      selectedAPids: selectedAPids,
      removeAPids: removeAPids,
      onlyForAP: true,
      title: I18n.formatMessage({
        id: "spaces.customProps.assignedAccessPoints.title",
        defaultMessage: "Assigned Access Points to this space:",
      }),
      noTagsMessage: I18n.formatMessage({
        id: "spaces.customProps.assignedAccessPoints.noTags",
        defaultMessage:
          "There are no Access Points assigned to this Space. Please add a new one",
      }),
      addBtnId: "apAddBtn",
    },
  },
  {
    name: FIELD_NAMES.ssids,
    label: I18n.formatMessage({
      id: "spaces.add.label.assignedSsids",
      defaultMessage: "Assign SSIDs",
    }),
    placeholder: I18n.formatMessage({
      id: "spaces.add.placeholder.assignedSsids",
      defaultMessage: "Pick from the list",
    }),
    required: false,
    gridStyle: { sm: 12, md: 6 },
    Component: SelectWithTagsForEdit,
    data: ssidData,
    customProps: {
      onlyForSSID: true,
      tempDataSSID: tempData,
      title: I18n.formatMessage({
        id: "spaces.customProps.assignedSsids.title",
        defaultMessage: "Assigned SSIDs to this space:",
      }),
      noTagsMessage: I18n.formatMessage({
        id: "spaces.customProps.assignedSsids.noTags",
        defaultMessage:
          "There are no SSIDs assigned to this Space. Please add a new one",
      }),
      addBtnId: "ssidAddBtn",
    },
  },
  {
    name: FIELD_NAMES.airSensors,
    label: I18n.formatMessage({
      id: "spaces.add.label.assignedAirQualitySensors",
      defaultMessage: "Assign Air Quality Sensors",
    }),
    placeholder: I18n.formatMessage({
      id: "spaces.add.placeholder.assignedAirQualitySensors",
      defaultMessage: "Pick from the list",
    }),
    required: false,
    gridStyle: { sm: 12, md: 6 },
    Component: SelectWithTagsForEdit,
    data: airSensorData,
    customProps: {
      title: I18n.formatMessage({
        id: "spaces.customProps.assignedAirQuality.title",
        defaultMessage: "Assigned Air Quality Sensors to this space:",
      }),
      noTagsMessage: I18n.formatMessage({
        id: "spaces.customProps.assignedAirQuality.noTags",
        defaultMessage:
          "There are no Air Quality Sensors assigned to this Space. Please add a new one",
      }),
      addBtnId: "aqAddBtn",
    },
  },
  {
    name: FIELD_NAMES.doors,
    label: I18n.formatMessage({
      id: "spaces.add.label.assignedDoors",
      defaultMessage: "Assign Doors",
    }),
    placeholder: I18n.formatMessage({
      id: "spaces.add.placeholder.assignedDoors",
      defaultMessage: "Pick from the list",
    }),
    required: false,
    gridStyle: { sm: 12, md: 6 },
    Component: SelectWithTagsForEdit,
    data: doorData,
    customProps: {
      title: I18n.formatMessage({
        id: "spaces.customProps.assignedDoors.title",
        defaultMessage: "Assigned Doors to this space:",
      }),
      noTagsMessage: I18n.formatMessage({
        id: "spaces.customProps.assignedDoors.noTags",
        defaultMessage:
          "There are no Doors assigned to this Space. Please add a new one",
      }),
      addBtnId: "doorAddBtn",
    },
  },
  {
    name: FIELD_NAMES.zones,
    label: I18n.formatMessage({
      id: "spaces.add.label.assignedZones",
      defaultMessage: "Assign Zones",
    }),
    placeholder: I18n.formatMessage({
      id: "spaces.add.placeholder.assignedZones",
      defaultMessage: "Pick from the list",
    }),
    required: false,
    gridStyle: { sm: 12, md: 6 },
    Component: SelectWithTagsForEdit,
    data: zonesData,
    customProps: {
      title: I18n.formatMessage({
        id: "spaces.customProps.assignedZones.title",
        defaultMessage: "Assigned Zones to this space:",
      }),
      noTagsMessage: I18n.formatMessage({
        id: "spaces.customProps.assignedZones.noTags",
        defaultMessage:
          "There are no Zones assigned to this Space. Please add a new one",
      }),
      addBtnId: "zoneAddBtn",
    },
  },
  {
    name: FIELD_NAMES.tripWires,
    label: I18n.formatMessage({
      id: "spaces.add.label.assignedTripwires",
      defaultMessage: "Assign Tripwires",
    }),
    placeholder: I18n.formatMessage({
      id: "spaces.add.placeholder.assignedTripwires",
      defaultMessage: "Pick from the list",
    }),
    required: false,
    gridStyle: { sm: 12, md: 6 },
    Component: SelectWithTagsForEdit,
    data: tripWireData,
    customProps: {
      title: I18n.formatMessage({
        id: "spaces.customProps.assignedTripwires.title",
        defaultMessage: "Assigned Tripwires to this space:",
      }),
      noTagsMessage: I18n.formatMessage({
        id: "spaces.customProps.assignedTripwires.noTags",
        defaultMessage:
          "There are no Tripwires assigned to this Space. Please add a new one",
      }),
      addBtnId: "tripWireAddBtn",
    },
  },
  {
    name: FIELD_NAMES.isNetflowActive,
    label: I18n.formatMessage({
      id: "spaces.add.label.isNetflowActive",
      defaultMessage: " ",
    }),
    placeholder: I18n.formatMessage({
      id: "spaces.add.placeholder.isNetflowActive",
      defaultMessage: " ",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: FullyCoveredSpaceCheckbox,
    fullWidth: false,
  },
  {
    name: FIELD_NAMES.resetTime,
    label: "",
    placeholder: "Select time",
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: ResetNetFlowTimeForEdit,
    fullWidth: false,
    customProps: {
      isNetflowActive: isNetFlowActive,
    },
  },
  {
    name: FIELD_NAMES.active,
    label: I18n.formatMessage({
      id: "spaces.add.label.active",
      defaultMessage: "Active",
    }),
    placeholder: I18n.formatMessage({
      id: "spaces.add.placeholder.active",
      defaultMessage: "Type here",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: ActivitySwitch,
  },
];

export const SpaceValidationRules = Yup.object().shape({
  [FIELD_NAMES.name]: Yup.string()
    .min(
      3,
      I18n.formatMessage({
        id: "spaces.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "spaces.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
  [FIELD_NAMES.resetTime]: Yup.string()
    .when(FIELD_NAMES.isNetflowActive, {
      is: true,
      then: Yup.string().required("Netflow is enabled. Please select time"),
    })
    .nullable(true),
  [FIELD_NAMES.floorId]: Yup.string()
    .test(
      "oneOfRequired",
      I18n.formatMessage({
        id: "spaces.validation.floorId.required",
        defaultMessage: "FloorId is required.",
      }),
      function () {
        return this.parent.floorId;
      }
    )
    .required(" "),
});
