import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Grid } from "@material-ui/core";
import { PageHeader } from "../../../../common/components";
import { VLanFormFields, VLanValidationRules } from "./Form.steps";
import { Form } from "../../../../common/components";
import { ROUTES } from "../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { CustomModal } from "../../../../common/components/index";
import { Modal } from "./Modal";
import { VLAN } from "../../../../types";
import { useVLANState } from "../../../../core/context/containers/VLAN.container";
import { useSnackbar } from "notistack";

export function EditVlan() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const { selectedVLAN, setSelectedVLAN } = useVLANState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [showModal, toggleModal] = useState<boolean>(false);
  const [formDataState, setFormDataState] = useState<VLAN | null>(selectedVLAN);

  const [updateVlan] = useMutation(GQLService.mutations.updateVlan, {
    refetchQueries: () => [
      {
        query: GQLService.queries.vlanByProperty,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });
  const handleSubmit = useCallback(
    (formData) => {
      toggleModal(true);
      setFormDataState({ ...formData, propertyId: selectedProperty?.id });
    },
    [formDataState, selectedProperty] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      if (formDataState) {
        try {
          await updateVlan({ variables: { ...formDataState } }).then(() =>
            enqueueSnackbar("Network changed successfully", {
              variant: "success",
            })
          );
          //   reset();
          setSelectedVLAN(formDataState);
          history.push(
            ROUTES.V_LAN_VIEW.replace(":uid", selectedProperty!.id).replace(
              ":vlanId",
              formDataState.id
            )
          );
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          console.log(e);
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => {
    toggleModal(false);
  }, []);

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addVLan.header",
          defaultMessage: `Edit Network ${selectedVLAN?.name}`,
          description: "edit Network label",
        })}
      />
      <Grid item={true} md={5} sm={12}>
        <Form
          defaultValues={selectedVLAN}
          fields={VLanFormFields}
          validationSchema={VLanValidationRules}
          onSubmit={handleSubmit}
        />
      </Grid>
      <CustomModal open={showModal}>
        <Modal yes={async () => await confirmChanges()} no={rejectChanges} />
      </CustomModal>
    </div>
  );
}
