import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  search: {
    justifyContent: "left",
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.palette.common.solidGray}`,
    borderRadius: theme.typography.pxToRem(12),
    padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(12)}`,
    marginRight: theme.typography.pxToRem(16),
  },
  searchInput: {
    // width: 210,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
  },
  searchIcon: {
    width: 24,
    height: 24,
    position: "relative",
    bottom: 0,
    color: "#B2B2B2",
  },
}));
