import React, { useCallback } from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { Space } from "../../../../types";
import Loader from "react-loader-spinner";
import { COLORS } from "../../../../common/styles/colors";
import { ROUTES } from "../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useSpacesState } from "../../../../core/context/containers/Spaces.container";

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 120,
    height: 42,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 100,
    height: 42,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(28),
    color: theme.palette.common.mediumBlack,
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    paddingTop: "20px",
    paddingRight: "16px",
  },
  content: {
    display: "flex",
    width: "740px",
    height: "484px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    paddingTop: "32px",
    paddingLeft: "23px",
    paddingBottom: "16px",
    borderRadius: "20px",
    boxShadow: "0px 4px 21px rgba(0, 0, 0, 0.38)",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: "16px",
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
  },
  assignments: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
  },
  wrapperGrid: {
    display: "flex",
    marginRight: "23px",
    marginTop: "-24px",
  },
  loaderGrid: { alignSelf: "center", marginTop: "20%" },
}));

interface ModalContentProps {
  headerText?: string;
  no: Function;
  yes: Function;
  data: Space | undefined;
  loading?: any;
}

export function RemoveSpaceModal({
  headerText = "",
  no,
  yes,
  data,
  loading,
}: ModalContentProps) {
  const classes = useStyles();
  const history = useHistory();
  const { selectedSpace, setSelectedSpace } = useSpacesState();
  const { selectedProperty } = usePropertiesState();
  const noDelete =
    data?.accessPoints?.length! > 0 ||
    data?.airSensors?.length! > 0 ||
    data?.zones?.length! > 0 ||
    data?.tripWires?.length! > 0 ||
    data?.doors?.length! > 0;

  const handleEditSpaceClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(
      ROUTES.SPACE_EDIT.replace(":uid", selectedProperty?.id!).replace(
        ":spaceId",
        data?.id!
      )
    );
  };

  return (
    <Grid className={classes.content}>
      {loading ? (
        <Grid className={classes.loaderGrid}>
          <Loader type="Oval" color={COLORS.funBlue} height={100} width={100} />
        </Grid>
      ) : (
        <>
          <Grid>
            <Typography className={classes.header}>{headerText}</Typography>
            <Typography className={classes.description}>
              {noDelete
                ? "The following resources are associated with the selected space."
                : "There are no resources associated with the selected space."}
            </Typography>
          </Grid>
          <Grid className={classes.wrapperGrid} style={{ marginTop: "10px" }}>
            <Grid md={2}>
              <Typography className={classes.title}>Access Points:</Typography>
            </Grid>
            <Grid md={8}>
              <Typography className={classes.assignments}>
                {data?.accessPoints?.length! > 0
                  ? data?.accessPoints.map((v) => v.name).join(", ")
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <br></br>
          <Grid className={classes.wrapperGrid}>
            <Grid md={2}>
              <Typography className={classes.title}>
                Air Quality Sensors:
              </Typography>
            </Grid>
            <Grid md={8}>
              <Typography className={classes.assignments}>
                {data?.airSensors?.length! > 0
                  ? data?.airSensors.map((v) => v.name).join(", ")
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <br></br>
          <Grid className={classes.wrapperGrid}>
            <Grid md={2}>
              <Typography className={classes.title}>Zones:</Typography>
            </Grid>
            <Grid md={8}>
              <Typography className={classes.assignments}>
                {data?.zones?.length! > 0
                  ? data?.zones.map((v) => v.name || v.zone).join(", ")
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <br></br>
          <Grid className={classes.wrapperGrid}>
            <Grid md={2}>
              <Typography className={classes.title}>Tripwires:</Typography>
            </Grid>
            <Grid md={8}>
              <Typography className={classes.assignments}>
                {data?.tripWires?.length! > 0
                  ? data?.tripWires.map((v) => v.name || v.tripWire).join(", ")
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <br></br>
          <Grid className={classes.wrapperGrid}>
            <Grid md={2}>
              <Typography className={classes.title}>Doors:</Typography>
            </Grid>
            <Grid md={8}>
              <Typography className={classes.assignments}>
                {data?.doors?.length! > 0
                  ? data?.doors.map((v) => v.name).join(", ")
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <br></br>
          <Grid>
            <Typography className={classes.description}>
              {noDelete
                ? "You need to unlink those manually and then return to list page to delete the same space."
                : ""}
              {noDelete ? <br /> : ""}
              Do you want to proceed?
            </Typography>
          </Grid>
          <Grid item className={classes.buttonsWrapper}>
            <Button
              id="cancelBtn"
              className={classes.cancel}
              onClick={() => no()}
            >
              Cancel
            </Button>
            {noDelete ? (
              <Button
                id="confirmBtn"
                className={classes.deleteBtn}
                onClick={(e) => handleEditSpaceClick(e)}
              >
                Proceed
              </Button>
            ) : (
              <Button
                id="confirmBtn"
                className={classes.deleteBtn}
                onClick={() => yes()}
              >
                Delete
              </Button>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}
