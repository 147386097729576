import React, { useCallback, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Tooltip,
} from "@material-ui/core";
import { ImagesSensor, TripWire, Zone } from "../../../../../types";
import {
  ExpandLess,
  ExpandMore,
  Videocam,
  HighlightOff,
  Edit,
  FilterCenterFocus,
} from "@material-ui/icons";
import { ReactComponent as InTripWire } from "../../../../../common/assets/images/Downward.svg";
import { ReactComponent as OutTripWire } from "../../../../../common/assets/images/Upward.svg";
import { ReactComponent as InOutTripWire } from "../../../../../common/assets/images/InOut.svg";

export const useStyles = makeStyles((theme) => ({
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
  },
  icon: {
    width: 18,
    height: 18,
  },
  textSmall: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    padding: "0 8px 0 4px",
  },
  expandIcon: {
    color: theme.palette.common.inputGrey,
    width: 22,
  },
  zoneNameText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.mediumBlack,
    wordBreak: "break-all",
  },
  setZoneText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.regularBlue,
    cursor: "pointer",
  },
  zoneGridStyle: {
    borderTop: "1px solid #E0EBF1",
    padding: 8,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  width100: {
    width: "100%",
  },
  noPadding: {
    padding: 0,
  },
  iconStyle: {
    fontSize: "18px",
    width: "18px",
    height: "18px",
    color: theme.palette.common.darkBlue,
  },
}));

export function ImageSensorListItem({
  item,
  drawZone,
  clearZone,
  drawTripwire,
  clearTripwire,
}: {
  item: ImagesSensor;
  drawZone?: (camera: ImagesSensor, zone: Zone) => void;
  clearZone?: (camera: ImagesSensor, zone: Zone) => void;
  drawTripwire?: (camera: ImagesSensor, tripWire: TripWire) => void;
  clearTripwire?: (camera: ImagesSensor, tripWire: TripWire) => void;
}) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const [selectedZone, setSelectedZone] = useState<Zone | null>(null);
  const [selectedTripwire, setSelectedTripwire] = useState<TripWire | null>(
    null
  );

  const handleExpand = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const handleClearZone = useCallback((zone: Zone) => {
    setShowConfirmation(true);
    setSelectedZone(zone);
  }, []);

  const handleClearTripwire = useCallback((tripWire: TripWire) => {
    setShowConfirmation(true);
    setSelectedTripwire(tripWire);
  }, []);

  const clearConfirmation = useCallback(() => {
    setShowConfirmation(false);
    if (selectedZone) {
      setSelectedZone(null);
      clearZone && clearZone(item, selectedZone);
    }
    if (selectedTripwire) {
      setSelectedTripwire(null);
      clearTripwire && clearTripwire(item, selectedTripwire);
    }
  }, [clearZone, item, selectedZone, clearTripwire, selectedTripwire]);

  return (
    <Grid container style={{ display: "inline-block" }}>
      <Accordion square key={item.id} expanded={isExpanded}>
        <AccordionSummary className={classes.content}>
          <Grid
            container={true}
            alignItems="center"
            justify="space-between"
            style={{ margin: 0 }}
          >
            <Grid item sm={1} className={classes.alignCenter}>
              <Videocam className={classes.icon} />
            </Grid>
            <Grid item sm={7}>
              <Typography className={classes.blackText}>
                <b>{item.name ? item.name : item.cameraUuid}</b>
              </Typography>
            </Grid>
            <Grid
              item
              sm={4}
              className={classes.alignCenter}
              onClick={handleExpand}
            >
              <Typography className={classes.textSmall}>Zones</Typography>
              {isExpanded ? (
                <ExpandLess className={classes.expandIcon} />
              ) : (
                <ExpandMore className={classes.expandIcon} />
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <div className={classes.width100}>
            {item.zones.map((zone, index) => (
              <Grid
                container
                item
                sm={12}
                key={index}
                className={classes.zoneGridStyle}
              >
                <Grid item sm={1} md={1}>
                  <Tooltip enterTouchDelay={0} placement="top" title="Zone">
                    <FilterCenterFocus className={classes.iconStyle} />
                  </Tooltip>
                </Grid>
                <Grid item sm={8}>
                  <Typography className={classes.zoneNameText}>
                    {zone.name ? zone.name : zone.zone}
                  </Typography>
                </Grid>
                <Grid item sm={2} md={2}>
                  {zone.placement && Object.keys(zone.placement).length > 0 ? (
                    <HighlightOff
                      className={classes.iconStyle}
                      onClick={() => handleClearZone!(zone)}
                    />
                  ) : (
                    <Tooltip
                      enterTouchDelay={0}
                      placement="top"
                      title="Set Zone"
                    >
                      <Edit
                        className={classes.iconStyle}
                        onClick={() => drawZone!(item, zone)}
                      />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            ))}
            {item.tripWires.map((tripWire, index) => (
              <Grid
                container
                item
                sm={12}
                key={index}
                className={classes.zoneGridStyle}
              >
                <Grid item sm={1} md={1}>
                  {tripWire.flowType === "BOTH" ? (
                    <Tooltip
                      enterTouchDelay={0}
                      placement="top"
                      title="TripWire for In and Out"
                    >
                      <InOutTripWire />
                    </Tooltip>
                  ) : tripWire.flowType === "IN" ? (
                    <Tooltip
                      enterTouchDelay={0}
                      placement="top"
                      title="TripWire for In"
                    >
                      <InTripWire />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      enterTouchDelay={0}
                      placement="top"
                      title="TripWire for Out"
                    >
                      <OutTripWire />
                    </Tooltip>
                  )}
                </Grid>
                <Grid item sm={8}>
                  <Typography className={classes.zoneNameText}>
                    {tripWire.name ? tripWire.name : tripWire.tripWire}
                  </Typography>
                </Grid>
                <Grid item sm={2} md={2}>
                  {tripWire.placement &&
                  Object.keys(tripWire.placement).length > 0 ? (
                    <HighlightOff
                      className={classes.iconStyle}
                      onClick={() => handleClearTripwire!(tripWire)}
                    />
                  ) : (
                    <Tooltip
                      enterTouchDelay={0}
                      placement="top"
                      title="Set Tripwire"
                    >
                      <Edit
                        className={classes.iconStyle}
                        onClick={() => drawTripwire!(item, tripWire)}
                      />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            ))}
          </div>
          <Dialog
            open={showConfirmation && (!!selectedZone || !!selectedTripwire)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {formatMessage({
                id: "floorsettings.imagesensor.clearZone.confirmation",
                defaultMessage: "Clear location",
                description: "Option to clear placement.",
              })}
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => setShowConfirmation(false)}>
                {formatMessage({
                  id: "button.discard",
                  defaultMessage: "Discard",
                  description: "Discard changes.",
                })}
              </Button>
              <Button onClick={clearConfirmation} color="primary" autoFocus>
                {formatMessage({
                  id: "button.confirm",
                  defaultMessage: "Yes, Confirm",
                  description: "Confirm and clear zone placement.",
                })}
              </Button>
            </DialogActions>
          </Dialog>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
