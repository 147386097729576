import React, { useEffect } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  elementView: {
    borderBottom: `1px solid ${theme.palette.common.dustyGray}`,
    marginBottom: "25px",
    width: "40%",
  },
  name: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.dustyGray,
    padding: "5px 0",
  },
  content: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.blackPearl,
    paddingBottom: "5px",
    paddingRight: "25px",
  },
}));

type elementViewProps = {
  name: string | undefined;
  content: string | undefined;
};

export function ElementView({ name, content }: elementViewProps) {
  const classes = useStyles();

  useEffect(() => {}, []); // eslint-disable-line

  return (
    <Grid item className={classes.elementView}>
      <Typography className={classes.name}>{name}</Typography>
      <Typography className={classes.content}>{content}</Typography>
    </Grid>
  );
}
