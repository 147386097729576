import React, { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Grid, Button, Typography, InputLabel } from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";
import { ReactComponent as AddFloor } from "../../../../../common/assets/images/add-floor.svg";

import { FormField as FormFieldType } from "../../../../../types/form";

import { Floor, Property } from "../../../../../types";
import { useStyles } from "./EditFloorPlan.styles";
import GQLService from "../../../../../core/services/GQL.service";
import Loader from "react-loader-spinner";
import { COLORS } from "../../../../../common/styles/colors";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../../../../core/context/containers/Auth.container";

interface EditFloorPlanProps {
  field?: FormFieldType;
  name: string;
  previewStyle?: string;
  label: string;
  floor: Floor;
  property: Property | null;
  currentUrl: string | undefined;
  isExpanded?: boolean;
  handleImageRemoveCallback?: () => void;
}

export function EditFloorPlan({
  name,
  field,
  previewStyle,
  label,
  floor,
  property,
  currentUrl,
  isExpanded,
  handleImageRemoveCallback,
}: EditFloorPlanProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const { selectedProperty } = usePropertiesState();
  const auth = useAuth();

  const [error, setError] = useState<boolean>(false);
  const [currentFileObject, setCurrentFileObject] = useState<{
    url: string | undefined;
    data: File | undefined;
    name: string | undefined;
  }>({
    url: currentUrl,
    data: undefined,
    name: undefined,
  });
  useEffect(
    () => {
      let isMounted = true;
      if (isMounted === true) {
        (async () => {
          if (
            isExpanded &&
            !currentFileObject.url &&
            floor.floorPlanUploaded === true
          ) {
            setImageLoading(true);
            const response = await GQLService.rest.images.getFloorPlan(
              selectedProperty?.id!,
              floor.id
            );
            if (response.status === 401) {
              auth?.logout();
            }
            setImageLoading(false);
            setCurrentFileObject({
              ...currentFileObject,
              url: response?.source,
            });
          }
        })();
      }
      return () => {
        isMounted = false;
      };
    },
    [isExpanded, currentFileObject] // eslint-disable-line
  );

  useEffect(() => {
    if (!floor.floorPlanUploaded) {
      setCurrentFileObject({
        url: undefined,
        data: undefined,
        name: undefined,
      });
    }
  }, [floor]);

  useEffect(() => {
    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "dragover",
        (e) => {
          e.preventDefault();
        },
        false
      );
      window.removeEventListener(
        "drop",
        (e) => {
          e.preventDefault();
        },
        false
      );
    };
  }, []);
  const handleFileSelect = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setError(false);
      if (e.target.files) {
        if (e.target.files[0].size / (1024 * 1024) >= 2) {
          setError(true);
          return;
        }
        const formData = new FormData();
        formData.append("data", e.target.files[0]);
        setCurrentFileObject({
          url: URL.createObjectURL(e.target.files[0]),
          data: e.target.files[0],
          name: e.target.files[0].name,
        });

        await GQLService.rest.images.uploadFloorPlan(
          formData,
          property?.id!,
          floor.id
        );
      }
    },
    [] //eslint-disable-line
  );
  const handleRemoveImage = useCallback(
    async (e: any) => {
      handleImageRemoveCallback && handleImageRemoveCallback();
    },
    [property, floor] //eslint-disable-line
  );

  const handleFileDrop = useCallback((e: React.DragEvent<HTMLInputElement>) => {
    setError(false);

    if (e.dataTransfer.files?.length) {
      if (e.dataTransfer.files[0].size / (1024 * 1024) >= 2) {
        setError(true);
        setCurrentFileObject({
          url: URL.createObjectURL(e.dataTransfer.files[0]),
          name: e.dataTransfer.files[0].name,
          data: e.dataTransfer.files[0],
        });
        return;
      }
    }
  }, []);
  return (
    <Grid>
      {currentFileObject?.url || currentUrl || imageLoading ? (
        imageLoading ? (
          <Grid className={classes.floorPlanLoader}>
            <Loader type="Oval" color={COLORS.alto} height={100} width={100} />
          </Grid>
        ) : (
          <Grid item className={classes.floorPlan}>
            {auth?.canSee(
              auth?.user?.role!,
              "floors:edit",
              <span
                className={classes.removeButton}
                onClick={handleRemoveImage}
              >
                &#10005;
              </span>
            )}
            <img
              src={currentUrl || currentFileObject?.url}
              alt={currentFileObject.name}
              className={clsx(classes.preview, previewStyle)}
            />
          </Grid>
        )
      ) : (
        <Grid className={classes.floorPlan}>
          <InputLabel className={classes.inputLabel}>{label}</InputLabel>
          <input
            type="file"
            accept="image/jpeg, image/png"
            className={classes.input}
            id={floor.id}
            onChange={handleFileSelect}
            name={field?.name}
          />
          <label htmlFor={floor.id}>
            <Button
              className={clsx(classes.button, error ? classes.error : "")}
              component="span"
              fullWidth={true}
              onDrop={handleFileDrop}
            >
              <Grid
                container={true}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <AddFloor className={classes.addFloorIcon} />
                <Typography className={classes.buttonLabel}>
                  {formatMessage({
                    id: "imageInput.dragAndDrop",
                    defaultMessage:
                      "There is no floor plans related with this floor.",
                    description: "Image input drag and drop label",
                  })}
                </Typography>
                <Typography className={classes.buttonLabel}>
                  {formatMessage({
                    id: "imageInput.dragAndDrop.please",
                    defaultMessage: "Please",
                  })}
                  <span className={classes.blueText}>
                    {formatMessage({
                      id: "imageInput.dragAndDrop.uploadPlan",
                      defaultMessage: "Upload plan",
                    })}
                  </span>
                </Typography>
              </Grid>
            </Button>
          </label>
          <Typography className={classes.note}>
            {formatMessage({
              id: "imageInput.dragAndDropRules",
              defaultMessage:
                "Your upload must be: JPG or PNG format, less than 2 MB, and 160px\n(w) by 80px (h).",
              description: "Image input drag and drop help text",
            })}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
