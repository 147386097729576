import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import {
  Grid,
  makeStyles,
  Button,
  Input,
  InputLabel,
  Select,
  Switch,
  TextareaAutosize,
  MenuItem,
  Typography,
  AccordionActions,
} from "@material-ui/core";

import { useImagesSensorsState } from "../../../../core/context/containers/ImageSensor.container";
import {
  ActivitySwitch,
  CustomModal,
  StatusIndicator,
} from "../../../../common/components/index";
import { ModalContent } from "./ModalContent";
import { ImagesSensor } from "../../../../types";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import GQLService from "../../../../core/services/GQL.service";
import { ROUTES } from "../../../../common/constants/Routing";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { EditZoneGrid } from "./components/Zone/EditZoneGrid";
import { EditTripWireGrid } from "./components/TripWire/EditTripWireGrid";
import { SensorRange } from "../../Properties/components/SensorRange/SensorRange";
import { useIntl } from "react-intl";
import { ZoneMenu } from "./components/Zone/ZoneMenu";
import { useStyles } from "./EditImageSensor.styles";

const FIELD_NAMES = {
  name: "name",
  floor: "floor",
  cameraUuid: "cameraUuid",
  description: "description",
  active: "active",
  zones: "zones",
  tripWires: "tripWires",
};

export function EditImageSensorForm() {
  const { selectedProperty } = usePropertiesState();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedImagesSensor } = useImagesSensorsState();
  const [showModal, toggleModal] = useState<boolean>(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateImagesSensors] = useMutation(
    GQLService.mutations.editImagesSensors
  );
  const history = useHistory();
  const [formDataState, setFormDataState] = useState<ImagesSensor | false>(
    false
  );

  const editImageSensorSchema = Yup.object().shape({
    [FIELD_NAMES.name]: Yup.string().nullable(),
    [FIELD_NAMES.description]: Yup.string().nullable(),
    [FIELD_NAMES.active]: Yup.boolean().nullable(),
    zones: Yup.array().nullable(),
    tripWires: Yup.array().nullable(),
  });

  const handleSubmit = useCallback(
    async (formData) => {
      toggleModal(true);
      setFormDataState({ ...formData, id: selectedImagesSensor?.id });
    },
    [formDataState] // eslint-disable-line
  );

  const setZoneSensorRange = useCallback(
    async (
      zoneIndex: number,
      values: number[],
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue(
        `zones[${zoneIndex}].occupancyMediumMinThreShold`,
        values[1]
      );
      setFieldValue(
        `zones[${zoneIndex}].occupancyMediumMaxThreShold`,
        values[2]
      );
    },
    []
  );

  const setTripWireSensorRange = useCallback(
    async (
      zoneIndex: number,
      values: number[],
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue(
        `tripWires[${zoneIndex}].occupancyMediumMinThreShold`,
        values[1]
      );
      setFieldValue(
        `tripWires[${zoneIndex}].occupancyMediumMaxThreShold`,
        values[2]
      );
    },
    []
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      if (formDataState) {
        try {
          //remove imageKey from the zones and spaces
          const zones = formDataState.zones?.map((zone) => {
            const zoneCopy = { ...zone };
            delete zoneCopy.imageKey;
            delete zoneCopy.spaces;
            return zoneCopy;
          });
          const tripWires = formDataState.tripWires?.map((tripWire) => {
            const tripWireCopy = { ...tripWire };
            delete tripWireCopy.imageKey;
            delete tripWireCopy.spaces;
            return tripWireCopy;
          });
          await updateImagesSensors({
            variables: {
              ...formDataState,
              zones,
              tripWires,
              propertyId: selectedProperty?.id,
            },
          });
          enqueueSnackbar("Image Sensor changed successfully", {
            variant: "success",
          });
          history.push(
            ROUTES.IMAGES_SENSORS.replace(":uid", selectedProperty?.id!)
          );
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => toggleModal(false), []);

  const [active, toggleActiveState] = useState<boolean>(
    selectedImagesSensor?.active!
  );

  const toggleSwitchButton = useCallback(
    (setFieldValue) => {
      setFieldValue(FIELD_NAMES.active, !active);
      toggleActiveState(!active);
    },
    [selectedProperty, active] // eslint-disable-line
  );

  return (
    selectedImagesSensor && (
      <Grid item={true} md={12}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={Object.keys(FIELD_NAMES).reduce(
            (acc: { [key: string]: string }, curr: any) => {
              acc[curr] = (selectedImagesSensor as any)[curr];
              return acc;
            },
            {}
          )}
          validationSchema={editImageSensorSchema}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, errors, submitCount, initialValues }) => (
            <FormikForm className={classes.form}>
              <Grid container={true} direction="column" spacing={1}>
                <Grid item={true} sm={12} md={5}>
                  <InputLabel className={classes.inputLabel}>
                    Sensor Name
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.name] !==
                        initialValues[FIELD_NAMES.name]) &&
                      errors[FIELD_NAMES.name] && (
                        <span className={classes.errorLabel}>
                          {errors[FIELD_NAMES.name]}
                        </span>
                      )}
                  </InputLabel>
                  <Input
                    className={clsx(
                      classes.input,
                      (submitCount > 0 ||
                        values[FIELD_NAMES.name] !==
                          initialValues[FIELD_NAMES.name]) &&
                        errors[FIELD_NAMES.name]
                        ? classes.errorInput
                        : ""
                    )}
                    disableUnderline={true}
                    fullWidth={true}
                    placeholder={"Sensor Name"}
                    name={FIELD_NAMES.name}
                    type={"input"}
                    defaultValue={values.name}
                    onChange={(e) =>
                      setFieldValue(FIELD_NAMES.name, e.target.value)
                    }
                  />
                </Grid>
                <Grid item={true} sm={12} md={5}>
                  <InputLabel className={classes.inputLabel}>
                    Space Name
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.floor] !==
                        initialValues[FIELD_NAMES.floor]) &&
                      errors[FIELD_NAMES.floor] && (
                        <span className={classes.errorLabel}>
                          {errors[FIELD_NAMES.floor]}
                        </span>
                      )}
                  </InputLabel>
                  <Input
                    className={clsx(
                      classes.input,
                      classes.readOnlyInput,
                      (submitCount > 0 ||
                        values[FIELD_NAMES.floor] !==
                          initialValues[FIELD_NAMES.floor]) &&
                        errors[FIELD_NAMES.floor]
                        ? classes.errorInput
                        : ""
                    )}
                    disableUnderline={true}
                    fullWidth={true}
                    placeholder={"Space Name"}
                    name={FIELD_NAMES.floor}
                    type={"input"}
                    defaultValue={selectedImagesSensor?.floor?.name}
                    readOnly
                  />
                </Grid>
                <Grid item={true} sm={12} md={5}>
                  <InputLabel className={classes.inputLabel}>
                    Camera ID
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.cameraUuid] !==
                        initialValues[FIELD_NAMES.cameraUuid]) &&
                      errors[FIELD_NAMES.cameraUuid] && (
                        <span className={classes.errorLabel}>
                          {errors[FIELD_NAMES.cameraUuid]}
                        </span>
                      )}
                  </InputLabel>
                  <Input
                    className={clsx(
                      classes.input,
                      classes.readOnlyInput,
                      (submitCount > 0 ||
                        values[FIELD_NAMES.cameraUuid] !==
                          initialValues[FIELD_NAMES.cameraUuid]) &&
                        errors[FIELD_NAMES.cameraUuid]
                        ? classes.errorInput
                        : ""
                    )}
                    disableUnderline={true}
                    fullWidth={true}
                    placeholder={"Camera ID"}
                    name={FIELD_NAMES.cameraUuid}
                    type={"input"}
                    defaultValue={values.cameraUuid}
                    readOnly
                  />
                </Grid>
                <Grid item={true} sm={12} md={5}>
                  <InputLabel className={classes.inputLabel}>
                    Description
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.description] !==
                        initialValues[FIELD_NAMES.description]) &&
                      errors[FIELD_NAMES.description] && (
                        <span className={classes.errorLabel}>
                          {errors[FIELD_NAMES.description]}
                        </span>
                      )}
                  </InputLabel>
                  <TextareaAutosize
                    className={clsx(
                      classes.textArea,
                      (submitCount > 0 ||
                        values[FIELD_NAMES.description] !==
                          initialValues[FIELD_NAMES.description]) &&
                        errors[FIELD_NAMES.description]
                        ? classes.errorInput
                        : ""
                    )}
                    placeholder={"Description"}
                    name={FIELD_NAMES.description}
                    defaultValue={values.description}
                    onChange={(e) =>
                      setFieldValue(FIELD_NAMES.description, e.target.value)
                    }
                  />
                </Grid>
                <Grid item={true} sm={12} md={2}>
                  <InputLabel className={classes.inputLabel}>
                    Number of zones
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.zones] !==
                        initialValues[FIELD_NAMES.zones]) &&
                      errors[FIELD_NAMES.zones] && (
                        <span className={classes.errorLabel}>
                          {errors[FIELD_NAMES.zones]}
                        </span>
                      )}
                  </InputLabel>
                  <Input
                    className={clsx(
                      classes.input,
                      classes.readOnlyInput,
                      (submitCount > 0 ||
                        values[FIELD_NAMES.zones] !==
                          initialValues[FIELD_NAMES.zones]) &&
                        errors[FIELD_NAMES.zones]
                        ? classes.errorInput
                        : ""
                    )}
                    disableUnderline={true}
                    fullWidth={true}
                    placeholder={"No. Of Zones"}
                    name={FIELD_NAMES.zones}
                    type={"input"}
                    value={values?.zones?.length}
                    readOnly
                  />
                </Grid>
                <Grid item={true} sm={12} md={2}>
                  <InputLabel className={classes.inputLabel}>
                    Number of TripWires
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.tripWires] !==
                        initialValues[FIELD_NAMES.tripWires]) &&
                      errors[FIELD_NAMES.tripWires] && (
                        <span className={classes.errorLabel}>
                          {errors[FIELD_NAMES.tripWires]}
                        </span>
                      )}
                  </InputLabel>
                  <Input
                    className={clsx(
                      classes.input,
                      classes.readOnlyInput,
                      (submitCount > 0 ||
                        values[FIELD_NAMES.tripWires] !==
                          initialValues[FIELD_NAMES.tripWires]) &&
                        errors[FIELD_NAMES.tripWires]
                        ? classes.errorInput
                        : ""
                    )}
                    disableUnderline={true}
                    fullWidth={true}
                    placeholder={"No. Of TripWires"}
                    name={FIELD_NAMES.tripWires}
                    type={"input"}
                    value={values?.tripWires?.length}
                    readOnly
                  />
                </Grid>
              </Grid>
              <EditZoneGrid
                imageSensor={selectedImagesSensor}
                setFieldValue={setFieldValue}
                submitCount={submitCount}
                errors={errors}
              />
              <EditTripWireGrid
                imageSensor={selectedImagesSensor}
                setFieldValue={setFieldValue}
                submitCount={submitCount}
                errors={errors}
              />
              <Grid container item md={12} className={classes.marginTop16}>
                <InputLabel className={classes.inputLabel}>Status</InputLabel>
                <ActivitySwitch
                  name={"cameraStatus"}
                  defaultValue={active}
                  disabled={false}
                  value={active}
                  onChange={(_: any) => toggleSwitchButton(setFieldValue)}
                  onBlur={(_: any) => toggleSwitchButton(setFieldValue)}
                  activeToggleText="Active"
                  inactiveToggleText="Inactive"
                />
              </Grid>
              <Button
                id="update-btn-editForm"
                type="submit"
                className={classes.submitBtn}
              >
                Update
              </Button>
            </FormikForm>
          )}
        </Formik>
        <CustomModal open={showModal}>
          <ModalContent yes={confirmChanges} no={rejectChanges} />
        </CustomModal>
      </Grid>
    )
  );
}
