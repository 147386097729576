import React, { useCallback, useState } from "react";
import { FieldInputProps, useFormikContext } from "formik";
import { Grid, TextField as TextareaAutosizeUI } from "@material-ui/core";

import { useStyles } from "./TextareaAutosize.styles";
import clsx from "clsx";

interface TextareaAutosizeProps extends FieldInputProps<any> {
  defaultValue: string;
  disabled?: boolean;
  placeholder?: string;
}

export function TextareaAutosize({
  name,
  defaultValue = "",
  disabled = false,
  onChange,
  placeholder,
}: TextareaAutosizeProps) {
  const classes = useStyles();
  const [fieldValue, setFieldValue] = useState<string>(defaultValue);
  const { errors, values, initialValues, isSubmitting } = useFormikContext<{
    [key: string]: string;
  }>();

  const isTouched = isSubmitting || values[name] !== initialValues[name];

  const handleChange = useCallback(
    (e) => {
      setFieldValue(e.target.value);
      onChange!({
        ...e,
        target: { name, value: e.target.value },
      });
    },
    [name, fieldValue, onChange]
  );

  return (
    <Grid key={name} container={true} item={true} direction="column">
      <TextareaAutosizeUI
        className={clsx(
          classes.textArea,
          isTouched && errors[name] ? classes.error : ""
        )}
        value={fieldValue}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        onChange={handleChange}
        multiline
        InputProps={{ disableUnderline: true }}
      />
    </Grid>
  );
}
