import React, { useEffect, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";
import {
  CustomModal,
  Form,
  PageHeader,
  SwipePanel,
} from "../../../../common/components";
import { useDoorState } from "../../../../core/context/containers/Door.container";
import { useGroupState } from "../../../../core/context/containers/Groups.container";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import GQLService from "../../../../core/services/GQL.service";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { DoorDetails, editDoorSchema } from "./DoorDetails";
import { ModalContent } from "../../ImagesSensors/Edit/ModalContent";
import { Door, FormField, Group } from "../../../../types";
import { useMutation, useQuery } from "@apollo/client";
import { DoorUserGroupList } from "../components/DoorUserGroupList";
import { useSnackbar } from "notistack";
import { ROUTES } from "../../../../common/constants/Routing";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    textTransform: "none",
    textDecoration: "underline",
    marginBottom: 30,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
}));

export const DoorDetailsForm = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const { selectedDoor } = useDoorState();
  const { selectedEditUsersGroup, setSelectedEditUsersGroup } = useGroupState();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [steps, setWizardSteps] = useState<FormField[]>();
  const [formDataState, setFormDataState] = useState<Door>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    error: floorError,
    loading: floorLoading,
    data: floors,
  } = useQuery(GQLService.queries.allFloors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const [updateDoor] = useMutation(GQLService.mutations.editDoor, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allDoors,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setSelectedEditUsersGroup([]);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!floorLoading) {
        const steps = DoorDetails(false, {
          workingFrom: selectedDoor?.workingFrom,
          workingTo: selectedDoor?.workingTo,
          floors: floors?.floorsByPropertyId,
          groups: selectedDoor?.groups,
          availableDays: (
            selectedDoor?.availableDays as unknown as string
          ).split(","),
          propertyWorkingFrom: selectedProperty?.workingFrom,
          propertyWorkingTo: selectedProperty?.workingTo,
          doorAccessControlVendorId: selectedDoor?.doorAccessControlVendorId,
        });
        setWizardSteps(steps);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [floorLoading, floors, selectedProperty]);

  const handleSubmit = useCallback(
    (formData) => {
      toggleModal(true);
      setFormDataState({
        ...formData,
        availableDays: formData.availableDays.toString(),
        doorAccessControlVendorId: formData?.doorAccessControlVendorId,
        id: selectedDoor?.id,
      });
    },
    [formDataState, selectedProperty] // eslint-disable-line
  );

  const confirmChanges = useCallback(async () => {
    toggleModal(false);
    try {
      formDataState &&
        (await updateDoor({
          variables: {
            ...formDataState,
            groups: (selectedEditUsersGroup as Group[]).map(
              (group) => group.id!
            ),
            id: selectedDoor?.id,
          },
        }));
      enqueueSnackbar(
        formatMessage({
          id: "door.edit.mainSettings.snackbar.success",
          defaultMessage: "Door changed successfully",
        }),
        {
          variant: "success",
        }
      );
      history.push(ROUTES.DOORS.replace(":uid", selectedProperty?.id!));
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error", persist: true });
    }
  }, [formDataState]);

  const rejectChanges = useCallback(() => toggleModal(false), []);

  return (
    <>
      <Grid item={true} md={12} sm={12}>
        {steps && (
          <Form
            defaultValues={{
              ...selectedDoor,
              workingFrom: selectedDoor?.workingFrom,
              workingTo: selectedDoor?.workingTo,
              availableDays: (
                selectedDoor?.availableDays as unknown as string
              ).split(","),
              doorAccessControlVendorId:
                selectedDoor?.doorAccessControlVendorId!,
            }}
            fields={steps}
            validationSchema={editDoorSchema}
            onSubmit={handleSubmit}
            submitButtonTitle="Update"
            style={{ spacing: 0 }}
          />
        )}
      </Grid>
      <CustomModal open={showModal}>
        <ModalContent
          headerText="Update Door?"
          yes={async () => await confirmChanges()}
          no={rejectChanges}
        />
      </CustomModal>
    </>
  );
};

export function EditDoor() {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { selectedDoor } = useDoorState();

  const renderSecondTab = useCallback(() => {
    return <DoorUserGroupList />;
  }, []);

  return (
    selectedDoor && (
      <div>
        <Grid container={true} direction="row" alignItems="center">
          <Grid>
            <PageHeader
              title={formatMessage({
                id: "editDoor.header",
                defaultMessage: "Edit Door",
                description: "edit door details header",
              })}
            />
          </Grid>
        </Grid>
        <SwipePanel
          tabNames={[
            formatMessage({
              id: "editDoor.tabNames.doorDetails",
              defaultMessage: "Door Details",
              description: "Door details tab label",
            }),
            formatMessage({
              id: "editDoor.tabNames.assignedUserGroups",
              defaultMessage: "Assigned User Groups",
              description: "Assigned User Groups tab label",
            }),
          ]}
        >
          <DoorDetailsForm />
          {renderSecondTab()}
        </SwipePanel>
      </div>
    )
  );
}
