import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Grid, makeStyles } from "@material-ui/core";

import { SwipePanel } from "../../../../common/components";
import { MainInformations } from "./MainInformations";
import { PropertyList } from "./PropertiesList";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    textTransform: "none",
    textDecoration: "underline",
    marginBottom: 30,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
}));

export function UserView() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <Grid>
      <SwipePanel
        tabNames={[
          formatMessage({
            id: "user.tabNames.mainInformations",
            defaultMessage: "Main Informations",
            description: "Main Informations details tab label",
          }),
          formatMessage({
            id: "user.tabNames.assignedProperties",
            defaultMessage: "Assigned Properties",
            description: "Assigned Properties tab label",
          }),
        ]}
      >
        <MainInformations />
        <PropertyList />
      </SwipePanel>
    </Grid>
  );
}
