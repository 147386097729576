import React, { useState, useCallback } from "react";
import { Menu, MenuItem, MenuList, makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { ReactComponent as HamburgerMenu } from "../../../common/assets/images/hamburger.svg";
import { CanSee } from "../../../common/components/canSee/canSee";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 15,
    minHeight: 15,
    maxWidth: 15,
    maxHeight: 15,
    color: theme.palette.common.funBlue,
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    borderBottom: `1px solid ${theme.palette.common.nobel}`,
    "&:last-child": {
      border: "none",
    },
  },
}));

interface CardReaderProps {
  //   cardReader: CardReader;
  index: string;
}

export function CardReaderMenu({ index }: CardReaderProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState();

  const { selectedProperty } = usePropertiesState();

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const open = Boolean(anchorEl);

  return (
    <>
      <HamburgerMenu
        id={index}
        className={classes.icon}
        onClick={handleToggleMenu}
      />
      <Menu
        id="properties-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        style={{ zIndex: 500 }}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          <CanSee action="cardReaders:view">
            <MenuItem className={classes.menuItem}>
              {formatMessage({
                id: "card.reader.contextMenu.detailsCard",
                defaultMessage: "Details",
                description:
                  "Card Reader context menu details Card Reader label",
              })}
            </MenuItem>
          </CanSee>
          <CanSee action="cardReaders:edit">
            <MenuItem className={classes.menuItem}>
              {formatMessage({
                id: "card.reader.contextMenu.editCard",
                defaultMessage: "Edit Card Reader",
                description: "Card Reader context menu edit Card Reader label",
              })}
            </MenuItem>
          </CanSee>
          <CanSee action="cardReaders:deactivate">
            <MenuItem className={classes.menuItem}>
              {formatMessage({
                id: "card.reader.contextMenu.deactivateCard",
                defaultMessage: "Deactivate",
                description:
                  "Card Reader context menu deactivate Card Reader label",
              })}
            </MenuItem>
          </CanSee>
        </MenuList>
      </Menu>
    </>
  );
}
