import { createContainer } from "unstated-next";

import { useState } from "react";

import { Floor, Property } from "../../../types";

function useFiltersBase() {
  // const [selectedProperty, setSelectedProperty] = useState<Property>();
  const [selectedFloor, setSelectedFloor] = useState<Floor>();
  const [selectedTime, setSelectedTime] = useState<number>(15);
  const [selectedSSID, setSelectedSSID] = useState<string>("all");
  const [selectedSpace, setSelectedSpace] = useState<string>("none");
  return {
    selectedTime,
    setSelectedTime,
    // selectedProperty,
    // setSelectedProperty,
    selectedFloor,
    setSelectedFloor,
    selectedSSID,
    setSelectedSSID,
    selectedSpace,
    setSelectedSpace,
  };
}

const { Provider, useContainer } = createContainer(useFiltersBase);

export const useFiltersState = useContainer;
export const FiltersProvider = Provider;
