import React, { useRef } from "react";
import { IntlProvider } from "react-intl";
import "typeface-poppins";
import AppContextProvider from "./core/context/AppContextProvider";
import AppRouter from "./core/router/AppRouter";
import { AppTheme } from "./common/styles/AppTheme";
import { APP_DEFAULTS } from "./common/constants/App";
import { DEFAULT_LOCALE } from "./core/services/I18n.service";
import en_US from "./common/assets/locales/en_US.json";
import { ApolloProvider } from "@apollo/client";
import GQLService from "../src/core/services/GQL.service";
import { SnackbarProvider } from "notistack";
import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { AuthProvider } from "./core/context/containers/Auth.container";
import authClient from "./core/services/Auth.service";
import TagManager from "react-gtm-module";
import { getOS, getBrowser } from "./common/utils/Http.utils";
import useSmplrJs from "./common/hooks/useSmplrJs";

function App() {
  const notistackRef: React.RefObject<any> = React.createRef();
  const onClickDismiss = (key: any) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };
  useSmplrJs();

  const tagManagerArgs = {
    gtmId: APP_DEFAULTS.gtmKey,
    dataLayerName: "DeviceInfo",
    dataLayer: {
      OperatingSystem: getOS(window),
      Browser: getBrowser(window),
      navigatorInfo: window?.navigator,
      platform: window?.navigator.platform,
      vendor: window?.navigator.vendor,
    },
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <div className="App" id="App">
      <AuthProvider client={authClient}>
        <ApolloProvider client={GQLService.client}>
          <IntlProvider
            locale={DEFAULT_LOCALE}
            defaultLocale={DEFAULT_LOCALE}
            messages={{ en_US } as any}
          >
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={2000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              ref={notistackRef}
              action={(key: any) => (
                <Button onClick={onClickDismiss(key)}>
                  <CloseIcon fontSize="inherit" style={{ color: "white" }} />
                </Button>
              )}
            >
              <AppContextProvider>
                <AppTheme>
                  <AppRouter />
                </AppTheme>
              </AppContextProvider>
            </SnackbarProvider>
          </IntlProvider>
        </ApolloProvider>
      </AuthProvider>
    </div>
  );
}
export default App;
