import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { useAuth } from "../context/containers/Auth.container";

interface PrivateRouteProps {
  path: string;
  component?: any;
  children?: any;
  rest?: RouteProps;
}

export const ProtectedRoute = ({
  component: Component,
  children,
  ...rest
}: PrivateRouteProps) => {
  const auth = useAuth();
  return auth?.isAuthenticated && auth?.user ? (
    <Route
      {...rest}
      render={(props) =>
        Component ? <Component {...rest} {...props} /> : children
      }
    />
  ) : (
    <Redirect to="/signin" />
  );
};
