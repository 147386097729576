import { gql } from "@apollo/client";

export const IMAGE_SENSOR_QUERIES = {
  allCameras: gql`
    query camerasByPropertyId($propertyId: String!) {
      camerasByPropertyId(propertyId: $propertyId) {
        description
        lastConnectionTime
        id
        active
        type
        name
        cameraUuid
        floor {
          name
        }
        zones {
          id
          name
          zone
          area
          active
          occupancyMediumMinThreShold
          occupancyMediumMaxThreShold
          imageKey
          dataType
          censusOccupancyMediumMinThreShold
          censusOccupancyMediumMaxThreShold
          censusOccupancyMaxThreShold
          censusAccOccupancyMediumMinThreShold
          censusAccOccupancyMediumMaxThreShold
          censusAccOccupancyMaxThreShold
          dwellTimeMinThreshold
          dwellTimeMediumThreshold
          dwellTimeMaxThreshold
          placement
          spaces {
            space {
              id
              name
              netflowActive
            }
          }
        }
        tripWires {
          id
          name
          tripWire
          area
          active
          inOccupancyMediumMinThreShold
          inOccupancyMediumMaxThreShold
          inOccupancyMaxThreShold
          outOccupancyMediumMinThreShold
          outOccupancyMediumMaxThreShold
          outOccupancyMaxThreShold
          imageKey
          flowType
          placement
          spaces {
            space {
              id
              name
              netflowActive
            }
          }
        }
      }
    }
  `,
  camerasByFloorId: gql`
    query cameras($floorId: String!) {
      cameras(floorId: $floorId) {
        id
        name
        coordinateX
        coordinateY
        cameraUuid
        floor {
          id
        }
        zones {
          id
          name
          zone
          placement
          occupancyMediumMinThreShold
          occupancyMediumMaxThreShold
          active
        }
        tripWires: tripWires {
          id
          name
          active
          tripWire: tripWire
          placement
          flowType
        }
      }
    }
  `,
  occupancyDuringTimeForCameraZone: gql`
    query occupancyDuringTimeForCameraZone(
      $request: TimeRangedDashboardCameraRequestInput
    ) {
      occupancyDuringTimeForCameraZone(request: $request) {
        name
        zone
        isPinned
        cameraOccupacyTimeline {
          connectedPeopleCount
          from
          to
        }
        censusOccupacyTimeline {
          connectedPeopleCount
          from
          to
          spaceLoad
        }
        censusAccOccupacyTimeline {
          connectedPeopleCount
          from
          to
          spaceLoad
        }
        dwellTimeTimeLine {
          connectedPeopleCount
          from
          to
        }
        dataType
        censusOccupancyMediumMinThreShold
        censusOccupancyMediumMaxThreShold
        censusOccupancyMaxThreShold
        censusAccOccupancyMediumMinThreShold
        censusAccOccupancyMediumMaxThreShold
        censusAccOccupancyMaxThreShold
        occupancyMediumMaxThreShold
        occupancyMediumMinThreShold
        highDensityTime
        lowDensityTime
        mediumDensityTime
        timeRangeType
        lowDensityTimeForCensus
        mediumDensityTimeForCensus
        highDensityTimeForCensus
        lowDensityTimeForCensusAcc
        mediumDensityTimeForCensusAcc
        highDensityTimeForCensusAcc
        dwellTime
        dwellTimeMinThreshold
        dwellTimeMediumThreshold
        dwellTimeMaxThreshold
        lowDensityTimeForDwellTimeAcc
        mediumDensityTimeForDwellTimeAcc
        highDensityTimeForDwellTimeAcc
      }
    }
  `,
  occupancyDuringTimeForTripWire: gql`
    query occupancyDuringTimeForTripWire(
      $request: TimeRangedDashboardTripWireRequestInput
    ) {
      occupancyDuringTimeForTripWire(request: $request) {
        id
        name
        tripWire
        isPinned
        cameraOccupacyTimelineFlowIN {
          connectedPeopleCount
          from
          to
          spaceLoad
        }
        cameraOccupacyTimelineFlowOUT {
          connectedPeopleCount
          from
          to
          spaceLoad
        }
        flowType
        inOccupancyMediumMinThreShold
        inOccupancyMediumMaxThreShold
        inOccupancyMaxThreShold
        outOccupancyMediumMinThreShold
        outOccupancyMediumMaxThreShold
        outOccupancyMaxThreShold
        highDensityTime
        lowDensityTime
        mediumDensityTime
        timeRangeType
        lowDensityTimeForIn
        mediumDensityTimeForIn
        highDensityTimeForIn
        lowDensityTimeForOut
        mediumDensityTimeForOut
        highDensityTimeForOut
      }
    }
  `,
  cameraOccupancyByFloorId: gql`
    query cameraOccupancyByFloorId(
      $request: TimeRangedDashboardFloorRequestInput
    ) {
      cameraOccupancyByFloorId(request: $request) {
        name
        id
        cameraUuid
        coordinateX
        coordinateY
        active
        zones {
          id
          name
          dataType
          active
          zone
          placement
          spaceLoad
          connectedPeopleCount
          offlineFromInMinutes
          isPinned
        }
        tripWires {
          id
          name
          flowType
          active
          tripWire
          placement
          spaceLoad
          connectedPeopleCount
          offlineFromInMinutes
          isPinned
        }
      }
    }
  `,
  cameraById: gql`
    query cameraById($cameraId: String!) {
      cameraById(cameraId: $cameraId) {
        description
        lastConnectionTime
        id
        active
        type
        name
        cameraUuid
        floor {
          name
        }
        zones {
          id
          name
          zone
          area
          active
          occupancyMediumMinThreShold
          occupancyMediumMaxThreShold
          imageKey
          dataType
          censusOccupancyMediumMinThreShold
          censusOccupancyMediumMaxThreShold
          censusOccupancyMaxThreShold
          censusAccOccupancyMediumMinThreShold
          censusAccOccupancyMediumMaxThreShold
          censusAccOccupancyMaxThreShold
          dwellTimeMinThreshold
          dwellTimeMediumThreshold
          dwellTimeMaxThreshold
          placement
          spaces {
            space {
              id
              name
              netflowActive
            }
          }
        }
        tripWires {
          id
          name
          tripWire
          area
          active
          inOccupancyMediumMinThreShold
          inOccupancyMediumMaxThreShold
          inOccupancyMaxThreShold
          outOccupancyMediumMinThreShold
          outOccupancyMediumMaxThreShold
          outOccupancyMaxThreShold
          imageKey
          flowType
          placement
          spaces {
            space {
              id
              name
              netflowActive
            }
          }
        }
      }
    }
  `,
};

export const IMAGE_SENSOR_MUTATIONS = {
  editImagesSensors: gql`
    mutation updateCamera(
      $id: String!
      $description: String
      $active: Boolean
      $name: String
      $zones: [UpdateCameraZoneRequestInput]
      $tripWires: [UpdateTripWireRequestInput]
    ) {
      updateCamera(
        request: {
          id: $id
          description: $description
          active: $active
          name: $name
          zones: $zones
          tripWires: $tripWires
        }
      )
    }
  `,
  editZone: gql`
    mutation updateCameraZone(
      $id: String!
      $name: String
      $imageKey: String
      $area: Int
      $active: Boolean
      $dataType: String!
      $censusOccupancyMediumMinThreShold: Int
      $censusOccupancyMediumMaxThreShold: Int
      $censusOccupancyMaxThreShold: Int
      $censusAccOccupancyMediumMinThreShold: Int
      $censusAccOccupancyMediumMaxThreShold: Int
      $censusAccOccupancyMaxThreShold: Int
      $dwellTimeMinThreshold: Int
      $dwellTimeMediumThreshold: Int
      $dwellTimeMaxThreshold: Int
    ) {
      updateCameraZone(
        request: {
          id: $id
          name: $name
          imageKey: $imageKey
          area: $area
          active: $active
          dataType: $dataType
          censusOccupancyMediumMinThreShold: $censusOccupancyMediumMinThreShold
          censusOccupancyMediumMaxThreShold: $censusOccupancyMediumMaxThreShold
          censusOccupancyMaxThreShold: $censusOccupancyMaxThreShold
          censusAccOccupancyMediumMinThreShold: $censusAccOccupancyMediumMinThreShold
          censusAccOccupancyMediumMaxThreShold: $censusAccOccupancyMediumMaxThreShold
          censusAccOccupancyMaxThreShold: $censusAccOccupancyMaxThreShold
          dwellTimeMinThreshold: $dwellTimeMinThreshold
          dwellTimeMediumThreshold: $dwellTimeMediumThreshold
          dwellTimeMaxThreshold: $dwellTimeMaxThreshold
        }
      )
    }
  `,
  editTripWire: gql`
    mutation updateTripWire(
      $id: String!
      $name: String
      $imageKey: String
      $flowType: String!
      $tripWire: String
      $area: Int
      $inOccupancyMediumMinThreShold: Int
      $inOccupancyMediumMaxThreShold: Int
      $inOccupancyMaxThreShold: Int
      $outOccupancyMediumMinThreShold: Int
      $outOccupancyMediumMaxThreShold: Int
      $outOccupancyMaxThreShold: Int
      $active: Boolean
    ) {
      updateTripWire(
        request: {
          id: $id
          name: $name
          imageKey: $imageKey
          tripWire: $tripWire
          area: $area
          flowType: $flowType
          inOccupancyMediumMinThreShold: $inOccupancyMediumMinThreShold
          inOccupancyMediumMaxThreShold: $inOccupancyMediumMaxThreShold
          inOccupancyMaxThreShold: $inOccupancyMaxThreShold
          outOccupancyMediumMinThreShold: $outOccupancyMediumMinThreShold
          outOccupancyMediumMaxThreShold: $outOccupancyMediumMaxThreShold
          outOccupancyMaxThreShold: $outOccupancyMaxThreShold
          active: $active
        }
      )
    }
  `,
  addImageSensorToFloorPlan: gql`
    mutation updateCamera($id: String!, $coordinateX: Int, $coordinateY: Int) {
      updateCamera(
        request: {
          id: $id
          coordinateX: $coordinateX
          coordinateY: $coordinateY
        }
      )
    }
  `,
  addImageSensorZoneToFloorPlan: gql`
    mutation updateCamera(
      $id: String!
      $zones: [UpdateCameraZoneRequestInput]
    ) {
      updateCamera(request: { id: $id, zones: $zones })
    }
  `,
  addImageSensorTripwireToFloorPlan: gql`
    mutation updateCamera(
      $id: String!
      $tripWires: [UpdateTripWireRequestInput]
    ) {
      updateCamera(request: { id: $id, tripWires: $tripWires })
    }
  `,
  deleteSpacesFromTripwires: gql`
    mutation deleteSpacesFromTripwires(
      $deviceId: String!
      $spaceIds: [String]!
    ) {
      deleteSpacesFromTripwires(
        request: { deviceId: $deviceId, spaceIds: $spaceIds }
      )
    }
  `,
  deleteCameraZone: gql`
    mutation deleteCameraZone($cameraZoneId: String!) {
      deleteCameraZone(cameraZoneId: $cameraZoneId)
    }
  `,
};
