import React, { useMemo } from "react";
import { useStyles } from "./NotificationDropdown.styles";
import { CheckCircle, Warning, Error } from "@material-ui/icons";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import {
  NotificationConditionType,
  NotificationDropdownItemProps,
} from "../../../types/notification";
import { useUserState } from "../../../core/context/containers/User.container";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../constants/DummyProperty";

const NotificationDropDownItem = ({
  notification,
}: NotificationDropdownItemProps) => {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const RenderNotificationIcon = useMemo(() => {
    if (notification.conditionType === NotificationConditionType.HEALTHY)
      return <CheckCircle className={classes.notificationIconSuccess} />;
    else if (notification.conditionType === NotificationConditionType.MODERATE)
      return <Warning className={classes.notificationIconAlert} />;
    else return <Error className={classes.notificationIconFault} />;
  }, [
    classes.notificationIconAlert,
    classes.notificationIconFault,
    classes.notificationIconSuccess,
    notification.conditionType,
  ]);

  const getPropertyName = (id: String, name: String) => {
    if (userEmail === dummyUserEmail) {
      if (id === DummyPropertyA.id) {
        return DummyPropertyA.siteName;
      } else if (id === DummyPropertyB.id) {
        return DummyPropertyB.siteName;
      } else if (id === DummyPropertyC.id) {
        return DummyPropertyC.siteName;
      } else {
        return DummyPropertyD.siteName;
      }
    } else {
      return name!;
    }
  };

  const secondaryMessage = useMemo(() => {
    if (notification.floor) {
      if (notification.dataType === "DWELL_TIME") {
        return `Dwell time exceeds ${notification.maxDwellTime} minutes in ${
          notification.deviceName
        }, Floor ${notification.floor.floorNumber} at ${
          getPropertyName(
            notification.property.id,
            notification.property.siteName
          )
          // eslint-disable-next-line no-useless-concat
        }`;
      } else if (notification.dataType === "SCORE") {
        return `Wellness Index shows ${notification.conditionType.toLowerCase()} rating in ${
          notification.deviceName
        }, Floor ${notification.floor.floorNumber} of ${getPropertyName(
          notification.property.id,
          notification.property.siteName
        )}`;
      } else {
        return (
          `${notification.dataType.charAt(0).toUpperCase()}` +
          `${notification.dataType
            .slice(1)
            .toLowerCase()} shows ${notification.conditionType.toLowerCase()} rating ${
            notification.deviceName ? "in" : "on"
          } ${
            notification.deviceName ? notification.deviceName + "," : ""
          } Floor ${notification.floor.floorNumber} of ${getPropertyName(
            notification.property.id,
            notification.property.siteName
          )}`
        );
      }
    }
  }, [
    notification.conditionType,
    notification.dataType,
    notification.deviceName,
    notification.floor,
    notification.property.siteName,
  ]);

  return (
    <MenuItem divider={true}>
      <ListItemIcon>{RenderNotificationIcon}</ListItemIcon>
      <div className={classes.notificationMessageStack}>
        <p className={classes.notificationSecondaryText}>{secondaryMessage}</p>
        <span className={classes.notificationTime}>
          {notification.notificationReceivedMinutesAgo < 60
            ? `${notification.notificationReceivedMinutesAgo} minutes ago`
            : Math.floor(
                Number(notification.notificationReceivedMinutesAgo) / 60
              ) === 1
            ? `1 hour ago`
            : `${Math.floor(
                Number(notification.notificationReceivedMinutesAgo) / 60
              )} hours ago`}
        </span>
      </div>
    </MenuItem>
  );
};

export default NotificationDropDownItem;
