import React, {
  useState,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import {
  Formik,
  Form as FormikForm,
  FormikProps,
  useFormikContext,
} from "formik";
import { useIntl } from "react-intl";
import * as Yup from "yup";

import { FormField as FormFieldType } from "../../../types";
import { useStyles } from "./Form.styles";
import { FormField } from "./FormFields";
import clsx from "clsx";

interface FormProps {
  fields: FormFieldType[];
  submitButtonTitle?: string;
  defaultValues?: any;
  validationSchema?: Yup.ObjectSchema<Yup.Shape<object | undefined, any>>;
  type?: FormType;
  onSubmit?: ((formData: any) => Promise<void>) | ((formData: any) => void);
  onExternalSubmit?: (formData: any) => Promise<void>;
  style?: {
    spacing?: 0 | 1 | 5 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 10 | undefined;
    button?: string;
  };
  children?: React.ReactNode;
}

export enum FormType {
  Default = "Default",
  WizardPartialForm = "WizardPartialForm",
}

function FormComponent(
  {
    fields,
    submitButtonTitle,
    defaultValues = {},
    validationSchema,
    style = {
      spacing: 1,
    },
    type = FormType.Default,
    onSubmit,
  }: FormProps,
  ref: any
) {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formikRef = useRef<FormikProps<{}>>();

  const initialValues = Object.keys(defaultValues).length
    ? defaultValues
    : Object.fromEntries(fields.map((item) => [item.name]));

  const renderFields = useCallback(
    (field: FormFieldType) => {
      return <FormField key={field.name} field={field} />;
    },
    [initialValues]
  );

  const handleSubmit = useCallback(
    (values) => {
      setIsLoading(true);
      if (onSubmit) {
        Promise.resolve(onSubmit!(values)).then(() => {
          setIsLoading(false);
        });
      }
    },
    [onSubmit]
  );

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      formikRef.current?.setSubmitting(true);

      return formikRef.current?.validateForm().then(() => {
        return formikRef.current?.isValid
          ? Promise.resolve()
              .then(() => {
                formikRef.current?.setSubmitting(false);
                formikRef.current?.submitForm();

                return formikRef.current?.values;
              })
              .catch(console.log)
          : Promise.reject().then(() => {
              formikRef.current?.setSubmitting(false);
            });
      });
    },
  }));
  return (
    <Formik
      innerRef={(innerRef) => {
        if (innerRef) {
          formikRef.current = innerRef;
        }
      }}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm className={classes.form}>
        <Grid container={true} spacing={style.spacing}>
          {fields.map((field) => renderFields(field))}
        </Grid>
        {type === FormType.Default ? (
          <Button
            type="submit"
            id="submitBtn"
            className={clsx(classes.submitBtn, style.button)}
          >
            {isLoading ? (
              <CircularProgress size={24} className={classes.progress} />
            ) : submitButtonTitle ? (
              submitButtonTitle
            ) : (
              formatMessage({
                id: "form.button.save",
                defaultMessage: "Save",
                description: "form save button label",
              })
            )}
          </Button>
        ) : null}
      </FormikForm>
    </Formik>
  );
}

export const Form = forwardRef(FormComponent);
