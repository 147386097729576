import React, { useEffect } from "react";
import { useIntl } from "react-intl";

import {
  PageHeader,
  DataList,
  EmptyListView,
} from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { ImagesSensorsListItem } from "./ListItem";
import { Grid, makeStyles } from "@material-ui/core";
// import { ROUTES } from "../../../../common/constants/Routing";
import { useQuery, useLazyQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useImagesSensorsState } from "../../../../core/context/containers/ImageSensor.container";
// import { useAuth } from "../../../../core/context/containers/Auth.container";
// import { Role } from "../../../../types";
import { useSearchState } from "../../../../core/context/containers/Search.container";

const useStyles = makeStyles(() => ({
  floorsHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export function ImagesSensors() {
  const { selectedProperty } = usePropertiesState();
  const {
    searchImagesSensors,
    ImagesSensors,
    setImagesSensors,
    setImagesSensorsFromResponse,
    ImagesSensorsFromResponse,
  } = useImagesSensorsState();


  const [getCamerasByPropertyId, { data, loading }] = useLazyQuery(
    GQLService.queries.allCameras, {
     fetchPolicy: "network-only", 
    }
  );

  const { searchValue } = useSearchState();

  useEffect(
    () => {
      let isMounted = true;
      if (isMounted && !data) {
        getCamerasByPropertyId({ variables: { propertyId: selectedProperty?.id } })
      } else {
        setImagesSensors(data?.camerasByPropertyId || []);
        setImagesSensorsFromResponse(data?.camerasByPropertyId || []);
      }
      return () => {
        isMounted = false;
      };
    },
    [data] // eslint-disable-line
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      searchImagesSensors(searchValue);
    }
    return () => {
      isMounted = false;
    };
  }, [searchValue, ImagesSensorsFromResponse]);

  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div>
      <Grid className={classes.floorsHeader}>
        <PageHeader
          title={formatMessage({
            id: "imagesensors.header",
            defaultMessage: "Image Sensors",
            description: "Image Sensors header",
          })}
        />
      </Grid>
      {ImagesSensors.length === 0 ? (
        <EmptyListView viewType="imageSensor" />
      ) : (
        <DataList
          data={ImagesSensors}
          loading={loading}
          ListItem={ImagesSensorsListItem}
        />
      )}
    </div>
  );
}
