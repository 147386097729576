import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
} from "@material-ui/core";

import { useFloorPlanEditorState } from "../../../core/context/containers/FloorPlanEditor/FloorPlanEditor.container";
import { ContextMenu } from "./components/ContextMenu/ContextMenu";
import { AddForm } from "./components/Forms/AddAccessPointForm/AddForm";
import { Toolbar } from "./components/Toolbar/Toolbar";
import { useToolbarState } from "../../../core/context/containers/FloorPlanEditor/Toolbar.container";
import { Tool } from "../../../common/constants/FloorPlanEditor";
import { SideList } from "./components/SideList/SideList";
import { useAccessPointState } from "../../../core/context/containers/AccessPoints.container";
import GQLService from "../../../core/services/GQL.service";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import {
  Sensor,
  ImagesSensor,
  Zone,
  Floor,
  TripWire,
  Door,
  Space,
} from "../../../types";
import { Layer, Stage, Image } from "react-konva";
import { useIntl } from "react-intl";
import {
  AddCameraProps,
  AddCameraZoneProps,
  ShapeType,
  CameraShape,
  CameraZone,
  AirQualitySensor,
  AddAirSensorProps,
  AccessPointShape,
  AccessPointSolidShape,
  AccessPointProps,
  AddCameraTripwireProps,
  CameraTripwire,
  DoorShape,
  AddDoorProps,
  SpaceShape,
  AddSpaceProps,
} from "../components/SensorShapes";
import useImage from "use-image";
import { useStyles } from "./FloorPlanEditor.styles";
import clsx from "clsx";
import { ViewType } from "../Occupancy/Occupancy";

function AddNewShapeLayer({
  shapeToAdd,
  floor,
  onChange,
  zoomLevel,
}: {
  floor: Floor | null;
  shapeToAdd?:
    | AddCameraProps
    | AddCameraZoneProps
    | AddCameraTripwireProps
    | AddAirSensorProps
    | AccessPointProps
    | AddDoorProps
    | AddSpaceProps;
  onChange?: (
    shapeToAdd:
      | AddCameraProps
      | AddCameraZoneProps
      | AddCameraTripwireProps
      | AddDoorProps
      | AddSpaceProps
  ) => void;
  zoomLevel?: number;
}) {
  let shape = null;
  if (shapeToAdd) {
    switch (shapeToAdd.shapeType) {
      case ShapeType.ACCESS_POINT:
        shape = (
          <Layer>
            <AccessPointShape
              radius={floor?.accessPointsRadius}
              coordinateX={shapeToAdd.shape.coordinateX!}
              coordinateY={shapeToAdd.shape.coordinateY!}
            />
            <AccessPointSolidShape
              coordinateX={shapeToAdd.shape.coordinateX!}
              coordinateY={shapeToAdd.shape.coordinateY!}
              clickable={false}
            />
          </Layer>
        );
        break;
      case ShapeType.AIR_SENSOR:
        shape = (
          <Layer>
            <AirQualitySensor
              coordinateX={shapeToAdd.shape.coordinateX!}
              coordinateY={shapeToAdd.shape.coordinateY!}
            />
          </Layer>
        );
        break;
      case ShapeType.CAMERA:
        shape = (
          <Layer>
            <CameraShape
              coordinateX={shapeToAdd.shape.coordinateX!}
              coordinateY={shapeToAdd.shape.coordinateY!}
            ></CameraShape>
          </Layer>
        );
        break;
      case ShapeType.ZONE:
        shape = (
          <Layer>
            <CameraZone
              onChange={onChange}
              editable={true}
              shape={shapeToAdd}
              clickable={false}
            />
          </Layer>
        );
        break;
      case ShapeType.TRIPWIRE:
        shape = (
          <Layer>
            <CameraTripwire
              zoomLevel={zoomLevel}
              onChange={onChange}
              editable={true}
              shape={shapeToAdd}
              clickable={false}
            />
          </Layer>
        );
        break;
      case ShapeType.DOOR:
        shape = (
          <Layer>
            <DoorShape
              coordinateX={shapeToAdd.shape.coordinateX!}
              coordinateY={shapeToAdd.shape.coordinateY!}
            />
          </Layer>
        );
        break;
      case ShapeType.SPACE:
        shape = (
          <Layer>
            <SpaceShape
              onChange={onChange}
              editable={true}
              shape={shapeToAdd}
            />
          </Layer>
        );
        break;
      default:
        break;
    }
  }
  return shape;
}

export function FloorPlanEditor({ src }: { src: string }) {
  const { floor } = useFloorPlanEditorState();
  const { formatMessage } = useIntl();

  const konvaStageRef = useRef<any>(null);

  const { accessPoints, setAccessPoints } = useAccessPointState();

  const [sensors, setSensors] = useState<Sensor[]>([]);

  const [imagesSensors, setImagesSensors] = useState<ImagesSensor[]>([]);

  const [spaces, setSpaces] = useState<Space[]>([]);

  const [doors, setDoors] = useState<Door[]>([]);

  const [shapeToAdd, setShapeToAdd] = useState<
    | AddCameraProps
    | AddCameraZoneProps
    | AddCameraTripwireProps
    | AddAirSensorProps
    | AccessPointProps
    | AddDoorProps
    | AddSpaceProps
    | null
  >(null);
  const [floorMapImage] = useImage(src || "");

  const { selectedProperty } = usePropertiesState();
  const { selectedTool, setSelectedTool, zoomLevel } = useToolbarState();
  const classes = useStyles();
  const [viewType, setViewType] = useState<ViewType>(ViewType.ACCESS_POINT);

  const [getAccessPointByFloorId, { data: accessPointsResponse }] =
    useLazyQuery(GQLService.queries.floorAccessPoints, {
      variables: { floorId: floor?.id! },
      fetchPolicy: "network-only",
    });

  const { data: sensorsResponse, error } = useQuery(
    GQLService.queries.sensorsByFloorId,
    {
      variables: { floorId: floor?.id! },
      fetchPolicy: "network-only",
    }
  );

  const { data: cameraResponse } = useQuery(
    GQLService.queries.camerasByFloorId,
    {
      variables: { floorId: floor?.id! },
      fetchPolicy: "network-only",
    }
  );

  const { data: spaceResponse } = useQuery(GQLService.queries.spaces, {
    variables: { floorId: floor?.id! },
    fetchPolicy: "network-only",
  });

  const { data: doorResponse } = useQuery(GQLService.queries.doorsByFloor, {
    variables: { floorId: floor?.id! },
    fetchPolicy: "network-only",
  });

  const [editAccessPoint] = useMutation(GQLService.mutations.editAccessPoint, {
    refetchQueries: () => [
      {
        query: GQLService.queries.floorAccessPoints,
        variables: { floorId: floor?.id },
      },
      {
        query: GQLService.queries.allAccessPoints,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [editSensor] = useMutation(GQLService.mutations.updateSensor, {
    refetchQueries: () => [
      {
        query: GQLService.queries.sensorsByFloorId,
        variables: { floorId: floor?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [editImagesSensors] = useMutation(
    GQLService.mutations.addImageSensorToFloorPlan,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.camerasByFloorId,
          variables: { floorId: floor?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [editImagesSensorZone] = useMutation(
    GQLService.mutations.addImageSensorZoneToFloorPlan,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.camerasByFloorId,
          variables: { floorId: floor?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [editImagesSensorTripwire] = useMutation(
    GQLService.mutations.addImageSensorTripwireToFloorPlan,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.camerasByFloorId,
          variables: { floorId: floor?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [editSpace] = useMutation(GQLService.mutations.updateSpaceCoordinates, {
    refetchQueries: () => [
      {
        query: GQLService.queries.spaces,
        variables: { floorId: floor?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [editDoor] = useMutation(GQLService.mutations.updateDoorCoordinate, {
    refetchQueries: () => [
      {
        query: GQLService.queries.doorsByFloor,
        variables: { floorId: floor?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    getAccessPointByFloorId();
  }, []);

  useEffect(() => {
    setSelectedTool(Tool.ADD_ACCESS_POINT);
  }, [setSelectedTool]);

  useEffect(() => {
    if (accessPointsResponse) {
      setAccessPoints(accessPointsResponse.accessPointsByFloorId);
    }
  }, [accessPointsResponse, setAccessPoints]);

  useEffect(() => {
    if (sensorsResponse) {
      setSensors(sensorsResponse.sensorsByFloorId);
    }
  }, [sensorsResponse]);

  useEffect(() => {
    if (cameraResponse) {
      setImagesSensors(cameraResponse.cameras);
    }
  }, [cameraResponse]);

  useEffect(() => {
    if (spaceResponse) {
      setSpaces(spaceResponse.spaces);
    }
  }, [spaceResponse]);

  useEffect(() => {
    if (doorResponse) {
      setDoors(doorResponse.doorsByFloorId);
    }
  }, [doorResponse]);

  const [anchor, setAnchor] = useState<any>(null);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handleCloseContextMenu = useCallback(
    (e) => {
      e.preventDefault();

      if (anchor) {
        setAnchor(null);
        setShapeToAdd(null);

        return;
      }
    },
    [anchor]
  );

  const openAddShapeContextMenu = useCallback(
    (event: any) => {
      if (!shapeToAdd) {
        const { x, y } = konvaStageRef?.current?.getRelativePointerPosition();
        event.evt.preventDefault();
        event.evt.stopPropagation();
        let shapeType: ShapeType = ShapeType.ACCESS_POINT;
        switch (selectedTool) {
          case Tool.ADD_ACCESS_POINT:
            shapeType = ShapeType.ACCESS_POINT;
            break;
          case Tool.ADD_IMAGE_SENSOR:
            shapeType = ShapeType.CAMERA;
            break;
          case Tool.ADD_SENSOR:
            shapeType = ShapeType.AIR_SENSOR;
            break;
          case Tool.ADD_DOOR:
            shapeType = ShapeType.DOOR;
            break;
        }
        const addShapeProps = {
          shapeType: shapeType,
          shape: {
            coordinateX: x,
            coordinateY: y,
          },
        };
        setShapeToAdd(addShapeProps);

        setAnchor({
          anchorEl: event.evt.target,
          x: event.evt.layerX,
          y: event.evt.layerY,
        });
      }
    },
    [selectedTool, shapeToAdd]
  );

  const addEntityToFloorPlan = useCallback(
    (data) => {
      if (shapeToAdd) {
        if (shapeToAdd!.shapeType === ShapeType.ACCESS_POINT) {
          editAccessPoint({
            variables: {
              ...data,
              floorId: floor?.id,
              coordinateX: Math.round(shapeToAdd!.shape!.coordinateX!),
              coordinateY: Math.round(shapeToAdd!.shape!.coordinateY!),
            },
          });
        } else if (shapeToAdd!.shapeType === ShapeType.CAMERA) {
          editImagesSensors({
            variables: {
              ...data,
              floorId: floor?.id,
              coordinateX: Math.round(shapeToAdd!.shape!.coordinateX!),
              coordinateY: Math.round(shapeToAdd!.shape!.coordinateY!),
            },
          });
        } else if (shapeToAdd!.shapeType === ShapeType.ZONE) {
          editImagesSensorZone({
            variables: {
              id: shapeToAdd!.cameraId,
              zones: [shapeToAdd!.shape],
            },
          });
        } else if (shapeToAdd!.shapeType === ShapeType.TRIPWIRE) {
          editImagesSensorTripwire({
            variables: {
              id: shapeToAdd!.cameraId,
              tripWires: [shapeToAdd!.shape],
            },
          });
        } else if (shapeToAdd!.shapeType === ShapeType.SPACE) {
          editSpace({
            variables: {
              id: shapeToAdd!.spaceId,
              placement: shapeToAdd!.shape.placement,
              floorId: floor?.id,
            },
          });
        } else if (selectedTool === Tool.ADD_SENSOR) {
          editSensor({
            variables: {
              ...data,
              floorId: floor?.id,
              coordinateX: Math.round(shapeToAdd!.shape!.coordinateX!),
              coordinateY: Math.round(shapeToAdd!.shape!.coordinateY!),
            },
          });
        } else if (shapeToAdd!.shapeType === ShapeType.DOOR) {
          editDoor({
            variables: {
              ...data,
              floorId: floor?.id,
              coordinateX: Math.round(shapeToAdd!.shape!.coordinateX!),
              coordinateY: Math.round(shapeToAdd!.shape!.coordinateY!),
            },
          });
        }
      }

      setAnchor(null);
      setShapeToAdd(null);
      setShowConfirmation(false);
    },
    [
      selectedTool,
      editAccessPoint,
      floor?.id,
      editSensor,
      editImagesSensors,
      editImagesSensorZone,
      editImagesSensorTripwire,
      editSpace,
      editDoor,
      shapeToAdd,
    ]
  );

  const drawZone = useCallback<(camera: ImagesSensor, zone: Zone) => void>(
    (camera, zone) => {
      // here we need to add a zone into temp layer and make it resizable
      const addShapeProps = {
        shapeType: ShapeType.ZONE,
        cameraId: camera.id,
        shape: {
          ...zone,
          placement: {
            x: 10,
            y: 10,
            width: 100,
            height: 100,
          },
        },
        isSelected: true,
      } as AddCameraZoneProps;
      setShapeToAdd(addShapeProps);
    },
    []
  );

  const clearZone = useCallback<(camera: ImagesSensor, zone: Zone) => void>(
    (camera, zone) => {
      const updatedZone = { ...zone, placement: {} } as Zone;
      editImagesSensorZone({
        variables: {
          id: camera.id,
          zones: [updatedZone],
        },
      });
    },
    [editImagesSensorZone]
  );

  const drawTripwire = useCallback<
    (camera: ImagesSensor, tripWire: TripWire) => void
  >((camera, tripWire) => {
    // here we need to add a tripWire into temp layer and make it resizable
    const addShapeProps = {
      shapeType: ShapeType.TRIPWIRE,
      cameraId: camera.id,
      shape: {
        ...tripWire,
        flowType: tripWire.flowType,
        placement: {
          x: 10,
          y: 60,
          width: 50,
          height: 17,
          rotation: 0,
        },
      },
      isSelected: true,
    } as AddCameraTripwireProps;
    setShapeToAdd(addShapeProps);
  }, []);

  const clearTripwire = useCallback<
    (camera: ImagesSensor, tripWire: TripWire) => void
  >(
    (camera, tripWire) => {
      const updatedTripwire = { ...tripWire, placement: {} } as TripWire;
      editImagesSensorTripwire({
        variables: {
          id: camera.id,
          tripWires: [updatedTripwire],
        },
      });
    },
    [editImagesSensorTripwire]
  );

  const drawSpace = useCallback<(space: Space) => void>((space) => {
    // here we need to add a tripWire into temp layer and make it resizable
    const addShapeProps = {
      shapeType: ShapeType.SPACE,
      spaceId: space.id,
      shape: {
        ...space,
        placement: {
          x: 10,
          y: 60,
          width: 50,
          height: 17,
        },
      },
      isSelected: true,
    } as AddSpaceProps;
    setShapeToAdd(addShapeProps);
  }, []);

  const clearSpace = useCallback<(space: Space) => void>(
    (space) => {
      editSpace({
        variables: {
          id: space.id,
          placement: {},
        },
      });
    },
    [editSpace]
  );

  const onStageClickAway = useCallback(() => {
    shapeToAdd && setShowConfirmation(true);
  }, [shapeToAdd]);

  const cancelZoneCreation = useCallback(() => {
    setShowConfirmation(false);
    setShapeToAdd(null);
  }, []);

  const renderImageSensors =
    selectedTool === Tool.ADD_IMAGE_SENSOR &&
    imagesSensors &&
    imagesSensors.map((imageSensor, index) => (
      <>
        <Layer key={`zone-${index}`}>
          {imageSensor.zones &&
            imageSensor.zones
              .filter(
                (zone) =>
                  zone.placement && Object.keys(zone.placement).length > 0
              )
              .map(
                (zone) =>
                  ({
                    shape: zone,
                    shapeType: ShapeType.ZONE,
                  } as AddCameraZoneProps)
              )
              .map((zone, index) => <CameraZone key={index} shape={zone} />)}
          <CameraShape
            coordinateX={imageSensor.coordinateX}
            coordinateY={imageSensor.coordinateY}
          ></CameraShape>
        </Layer>
        <Layer key={`tripwire-${index}`}>
          {imageSensor.tripWires &&
            imageSensor.tripWires
              .filter(
                (tripWire) =>
                  tripWire.placement &&
                  Object.keys(tripWire.placement).length > 0
              )
              .map(
                (tripWire) =>
                  ({
                    shape: tripWire,
                    shapeType: ShapeType.TRIPWIRE,
                  } as AddCameraTripwireProps)
              )
              .map((tripWire, index) => (
                <CameraTripwire key={index} shape={tripWire} editable={true} />
              ))}
          <CameraShape
            coordinateX={imageSensor.coordinateX}
            coordinateY={imageSensor.coordinateY}
          ></CameraShape>
        </Layer>
      </>
    ));
  const renderAirSensors = selectedTool === Tool.ADD_SENSOR && sensors && (
    <Layer>
      {sensors.map((sensor, index) => (
        <AirQualitySensor
          key={index}
          coordinateX={sensor.coordinateX}
          coordinateY={sensor.coordinateY}
          clickable={false}
        />
      ))}
    </Layer>
  );
  const renderAccessPoints = selectedTool === Tool.ADD_ACCESS_POINT &&
    accessPoints && (
      <Layer>
        {accessPoints.map((sensor, index) => (
          <>
            <AccessPointShape
              key={index}
              radius={floor?.accessPointsRadius}
              coordinateX={
                sensor.coordinateX !== "null" ? sensor.coordinateX : undefined
              }
              coordinateY={
                sensor.coordinateY !== "null" ? sensor.coordinateY : undefined
              }
            />
            <AccessPointSolidShape
              key={index}
              coordinateX={
                sensor.coordinateX !== "null" ? sensor.coordinateX : undefined
              }
              coordinateY={
                sensor.coordinateY !== "null" ? sensor.coordinateY : undefined
              }
              clickable={false}
            />
          </>
        ))}
      </Layer>
    );

  const renderSpaces = selectedTool === Tool.ADD_SPACE && spaces && (
    <Layer>
      {spaces &&
        spaces
          .filter(
            (space) =>
              space.placement && Object.keys(space.placement).length > 0
          )
          .map(
            (space) =>
              ({
                shape: space,
                shapeType: ShapeType.SPACE,
              } as AddSpaceProps)
          )
          .map((space, index) => <SpaceShape key={index} shape={space} />)}
    </Layer>
  );
  const renderDoors = selectedTool === Tool.ADD_DOOR && doors && (
    <Layer>
      {doors.map((door, index) => (
        <DoorShape
          key={index}
          coordinateX={
            door.coordinateX != "null" ? door.coordinateX : undefined
          }
          coordinateY={
            door.coordinateY != "null" ? door.coordinateY : undefined
          }
          toggleActive={() => {}}
        />
      ))}
    </Layer>
  );

  return (
    <div onContextMenu={handleCloseContextMenu}>
      <Grid
        container={true}
        style={{ border: "1px solid", borderColor: "#E0EBF1" }}
      >
        <Grid item={true} md={9} sm={12}>
          <Grid item={true} md={12} sm={12} className={classes.buttonContainer}>
            <Button
              id="accessPointBtn"
              className={clsx(
                classes.toolSwitch,
                viewType === ViewType.ACCESS_POINT ? classes.active : {}
              )}
              onClick={() => {
                setViewType(ViewType.ACCESS_POINT);
                setSelectedTool(Tool.ADD_ACCESS_POINT);
                setShapeToAdd(null);
              }}
            >
              Access Points
            </Button>
            <Button
              id="airSensorBtn"
              className={clsx(
                classes.toolSwitch,
                viewType === ViewType.SENSORS ? classes.active : {}
              )}
              onClick={() => {
                setViewType(ViewType.SENSORS);
                setSelectedTool(Tool.ADD_SENSOR);
                setShapeToAdd(null);
              }}
            >
              Air Quality Sensors
            </Button>
            <Button
              id="imageSensorBtn"
              className={clsx(
                classes.toolSwitch,
                viewType === ViewType.IMG_SENSORS ? classes.active : {}
              )}
              onClick={() => {
                setViewType(ViewType.IMG_SENSORS);
                setSelectedTool(Tool.ADD_IMAGE_SENSOR);
                setShapeToAdd(null);
              }}
            >
              Image sensors
            </Button>
            <Button
              id="spaceBtn"
              className={clsx(
                classes.toolSwitch,
                viewType === ViewType.SPACES ? classes.active : {}
              )}
              onClick={() => {
                setViewType(ViewType.SPACES);
                setSelectedTool(Tool.ADD_SPACE);
                setShapeToAdd(null);
              }}
            >
              Spaces
            </Button>
            <Button
              id="doorBtn"
              className={clsx(
                classes.toolSwitch,
                viewType === ViewType.DOORS ? classes.active : {}
              )}
              onClick={() => {
                setViewType(ViewType.DOORS);
                setSelectedTool(Tool.ADD_DOOR);
                setShapeToAdd(null);
              }}
            >
              Doors
            </Button>
          </Grid>
          <Grid container={true}>
            <Grid item={true} md={1} sm={1}>
              <Toolbar />
            </Grid>
            {src && (
              <Grid
                item={true}
                md={11}
                sm={11}
                style={{ position: "relative" }}
              >
                <div id="konva-container" style={{ overflow: "hidden" }}>
                  <Stage
                    ref={konvaStageRef}
                    width={870}
                    height={675}
                    onContextMenu={
                      viewType === ViewType.SPACES
                        ? () => {}
                        : openAddShapeContextMenu
                    }
                    onClick={onStageClickAway}
                    scaleX={zoomLevel}
                    scaleY={zoomLevel}
                    draggable
                  >
                    <Layer>
                      {floorMapImage && (
                        <Image
                          x={0}
                          y={0}
                          width={870}
                          height={675}
                          image={floorMapImage}
                        />
                      )}
                    </Layer>
                    {renderAccessPoints}
                    {renderImageSensors}
                    {renderAirSensors}
                    {renderSpaces}
                    {renderDoors}
                    {shapeToAdd && (
                      <AddNewShapeLayer
                        floor={floor}
                        onChange={(shape) => {
                          shape && setShapeToAdd(shape);
                        }}
                        shapeToAdd={shapeToAdd}
                        zoomLevel={zoomLevel}
                      ></AddNewShapeLayer>
                    )}
                  </Stage>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container={true} item={true} md={3} sm={12}>
          <SideList
            accessPoints={accessPoints}
            sensors={sensors}
            imageSensors={imagesSensors}
            spaces={spaces}
            doors={doors}
            drawZone={drawZone}
            clearZone={clearZone}
            drawTripwire={drawTripwire}
            clearTripwire={clearTripwire}
            drawSpace={drawSpace}
            clearSpace={clearSpace}
            selectedTab={selectedTool}
          />
        </Grid>
        {selectedTool !== Tool.ADD_SPACE ? (
          <ContextMenu anchor={anchor} onClose={handleCloseContextMenu}>
            <AddForm
              onCancel={handleCloseContextMenu}
              onSave={addEntityToFloorPlan}
              dataToRender={
                selectedTool === Tool.ADD_ACCESS_POINT
                  ? accessPoints
                  : selectedTool === Tool.ADD_SENSOR
                  ? sensors
                  : selectedTool === Tool.ADD_IMAGE_SENSOR
                  ? imagesSensors
                  : doors
              }
              title={
                selectedTool === Tool.ADD_IMAGE_SENSOR
                  ? "Select Camera ID from the list"
                  : selectedTool === Tool.ADD_DOOR
                  ? "Select Door from the list"
                  : "Select from list"
              }
            />
          </ContextMenu>
        ) : (
          ""
        )}
        <Dialog
          open={showConfirmation}
          onClose={cancelZoneCreation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {formatMessage({
              id: "floorsettings.imagesensor.setZone.confirmation",
              defaultMessage: "Confirm saving location",
              description: "Option to enable setting zone/tripwire.",
            })}
          </DialogTitle>
          <DialogActions>
            <Button onClick={cancelZoneCreation}>
              {formatMessage({
                id: "button.discard",
                defaultMessage: "Discard",
                description: "Discard changes.",
              })}
            </Button>
            <Button onClick={addEntityToFloorPlan} color="primary" autoFocus>
              {formatMessage({
                id: "button.confirm",
                defaultMessage: "Yes, Confirm",
                description: "Confirm and save changes.",
              })}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
}
