import React, { useState, useCallback, Fragment } from "react";
import { useIntl } from "react-intl";
import {
  Menu,
  MenuItem,
  MenuList,
  makeStyles,
  Button,
} from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";

import { AccessPoint, Role } from "../../../../../../types";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../../../core/services/GQL.service";
import { useAccessPointState } from "../../../../../../core/context/containers/AccessPoints.container";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../common/constants/Routing";
import { useAuth } from "../../../../../../core/context/containers/Auth.container";
import { useSnackbar } from "notistack";
import { RemoveEntityModal } from "../../../../../../common/components/RemoveEntityModal/RemoveEntityModal";
import { ReactComponent as HamburgerMenu } from "../../../../../../common/assets/images/hamburger.svg";
import { useModal } from "../../../../../../core/hooks/Modal";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 15,
    minHeight: 15,
    maxWidth: 15,
    maxHeight: 15,
    color: theme.palette.common.funBlue,
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    borderBottom: `1px solid ${theme.palette.common.nobel}`,

    "&:last-child": {
      border: "none",
    },
  },
}));

interface AccessPointMenuProps {
  accessPoint: AccessPoint;
  index: string;
}

export function AccessPointMenu({ accessPoint, index }: AccessPointMenuProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const [modal, showModal] = useState<boolean>(false);
  const { selectedProperty } = usePropertiesState();
  const { setSelectedAccessPoint } = useAccessPointState();
  const [anchorEl, setAnchorEl] = useState();

  const {
    show: showDeleteModal,
    hide: hideDeleteModal,
    RenderModal: RenderDeleteModal,
  } = useModal();

  const [removeAccessPoint] = useMutation(
    GQLService.mutations.removeAccessPoint,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allAccessPoints,
          variables: { propertyId: selectedProperty?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const history = useHistory();

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const open = Boolean(anchorEl);

  const auth = useAuth();

  const handleViewClick = () => {
    setSelectedAccessPoint(accessPoint);
    history.push(
      ROUTES.ACCESS_POINT_VIEW.replace(":uid", selectedProperty!.id).replace(
        ":accesspoint",
        accessPoint?.id.split(":")[1]
      )
    );
  };

  const handleEditAccessPointClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedAccessPoint(accessPoint);
      history.push(
        ROUTES.ACCESS_POINT_EDIT.replace(":uid", selectedProperty?.id!).replace(
          ":accesspoint",
          accessPoint?.id.split(":")[1]
        )
      );
    },
    [accessPoint]
  );

  const renderDeleteMenuItem = useCallback(
    () => {
      return (
        <MenuItem className={classes.menuItem} onClick={openDeleteModal}>
          {formatMessage({
            id: "accessPoints.contextMenu.removeAccessPoint",
            defaultMessage: "Remove Access point",
            description: "access points context menu remove label",
          })}
        </MenuItem>
      );
    },
    [accessPoint] // eslint-disable-line
  );

  const renderEditMenuItem = useCallback(() => {
    return (
      <MenuItem
        className={classes.menuItem}
        onClick={handleEditAccessPointClick}
      >
        {formatMessage({
          id: "accessPoints.contextMenu.editAccessPoint",
          defaultMessage: "Edit Access Point",
          description: "access points context menu edit label",
        })}
      </MenuItem>
    );
  }, [accessPoint]);

  const openDeleteModal = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    try {
      showDeleteModal();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const removeAPConfirmed = useCallback(
    async (id: string) => {
      try {
        await removeAccessPoint({
          variables: { accessPointId: accessPoint.id },
        });
        enqueueSnackbar("Access Point removed successfully", {
          variant: "success",
        });
        hideDeleteModal();
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error", persist: true });
      }
    },
    [accessPoint]
  );

  const removeAPRejected = useCallback(() => {
    hideDeleteModal();
  }, []);

  return (
    <>
      <HamburgerMenu
        id={index}
        className={classes.icon}
        onClick={handleToggleMenu}
      />
      <Menu
        id="properties-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ zIndex: 500 }}
        keepMounted={true}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          <MenuItem className={classes.menuItem} onClick={handleViewClick}>
            {formatMessage({
              id: "accessPoints.contextMenu.viewDetails",
              defaultMessage: "Details",
              description: "access points context menu details label",
            })}
          </MenuItem>
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "access_points:edit",
            renderEditMenuItem()
          )}
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "access_points:delete",
            renderDeleteMenuItem()
          )}
        </MenuList>
      </Menu>
      <RenderDeleteModal>
        <RemoveEntityModal
          no={removeAPRejected}
          yes={removeAPConfirmed}
          id={accessPoint.id}
          UI={{
            subheader: "Access Point",
            type: "Access Point",
            name: accessPoint.name,
          }}
        />
      </RenderDeleteModal>
    </>
  );
}
