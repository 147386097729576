import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Grid, LinearProgress, Typography } from "@material-ui/core";

interface DataListProps {
  data: any[];
  ListItem: React.ComponentType<{ item: any }>;
  loading: boolean;
}

export function DataList({ loading, data, ListItem }: DataListProps) {
  const { formatMessage } = useIntl();

  const [lazyLoaded, setLazyLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true;
    let timeout: NodeJS.Timeout;
    if (isMounted) {
      if (data?.length || !loading) {
         setLazyLoaded(true);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [loading, data?.length]);

  return lazyLoaded ? (
    data?.length ? (
      <Grid>
        {data.map((item, i) => (
          <ListItem key={item.id} item={item} />
        ))}
      </Grid>
    ) : (
      <Grid>
        <Typography>
          {formatMessage({
            id: "dataList.noData",
            defaultMessage: "No Data",
            description: "datalist no data info",
          })}
        </Typography>
      </Grid>
    )
  ) : (
    <Grid>
      <LinearProgress />
    </Grid>
  );
}
