import React, { useEffect, useMemo, useState } from "react";
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryLabel,
  VictoryLegend,
} from "victory";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { ReactComponent as ClockIcon } from "../../../../../common/assets/images/clock.svg";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import * as _ from "lodash";
import { NoData } from "../../../../../common/components/NoData/NoData";

const useStyles = makeStyles((theme: any) => ({
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.common.textBlack,
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    position: "relative",
    top: -60,
  },
  keyPosition: {},
  radioBarGreen: {
    backgroundColor: "#79CC38",
  },
  radioBarBlue: {
    backgroundColor: "#2056a6",
  },
  radioBarRed: {
    backgroundColor: "#EC8888",
  },
  radioBarTurquoise: {
    backgroundColor: theme.palette.common.turquoiseBlue,
  },
  rectangle: {
    height: 15,
    width: 4,
    borderRadius: 14,
    margin: "0 24px 0 8px",
  },
  timeZone: {
    backgroundColor: theme.palette.common.InputBg,
    padding: "5px",
    fontSize: "11px",
    height: "25px",
    borderRadius: "2px",
    lineHeight: "11px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  victoryChart: {
    width: "95%",
  },
  timeZoneWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  noData: {
    marginTop: theme.spacing(10),
  },
  greyText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(20),
    color: "#E2E2E2",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(10),
  },
}));

interface LineChartAnalyzeProps {
  selectedTime?: string;
  inConnectedPeople?: any[];
  outConnectedPeople?: any[];
  statisticsTimelineData?: any;
  syntheticIn?: any[];
  syntheticOut?: any[];
  netOccupancy?: any[];
  colorSyntheticIn?: string;
  colorSyntheticOut?: string;
  colorNetOccupancy?: string;
  resetTime?: string;
}

export function LineChartAnalyze({
  selectedTime,
  inConnectedPeople,
  outConnectedPeople,
  statisticsTimelineData,
  syntheticIn,
  syntheticOut,
  netOccupancy,
  colorSyntheticIn,
  colorSyntheticOut,
  colorNetOccupancy,
  resetTime,
}: LineChartAnalyzeProps) {
  const classes = useStyles();
  const { selectedProperty } = usePropertiesState();
  const [lineName, setLineName] = useState<any>([]);
  const [resetTimeArray, setResetTimeArray] = useState<any>(null);

  const xAxisTime = (time: number) => {
    if (selectedTime === "24h") {
      return time === 0
        ? `12 AM`
        : time < 12
        ? `0${time} AM`
        : time === 12
        ? `12 PM`
        : `0${time - 12} PM`;
    } else {
      return time === 0
        ? `12:00 AM`
        : time < 12
        ? `0${time}:00 AM`
        : time === 12
        ? `12:00 PM`
        : `0${time - 12}:00 PM`;
    }
  };

  const xAxis = useMemo(() => {
    switch (selectedTime) {
      case "15m":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 15 minutes"
            axisLabelComponent={
              <VictoryLabel
                y={260}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "1h":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last hour"
            axisLabelComponent={
              <VictoryLabel
                y={260}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "24h":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 24 hours"
            axisLabelComponent={
              <VictoryLabel
                y={260}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "7d":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 7 days"
            axisLabelComponent={
              <VictoryLabel
                y={260}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    if (tArray.length > 2)
                      return (
                        tArray[0] +
                        "\n" +
                        tArray[1] +
                        "\n" +
                        tArray[2]?.charAt(0)
                      );
                    else return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  if (tArray.length > 2)
                    return (
                      tArray[0] + "\n" + tArray[1] + "\n" + tArray[2]?.charAt(0)
                    );
                  else return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "30d":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 30 days"
            axisLabelComponent={
              <VictoryLabel
                y={260}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    if (tArray.length > 2)
                      return (
                        tArray[0] +
                        "\n" +
                        tArray[1] +
                        "\n" +
                        tArray[2]?.charAt(0)
                      );
                    else return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  if (tArray.length > 2)
                    return (
                      tArray[0] + "\n" + tArray[1] + "\n" + tArray[2]?.charAt(0)
                    );
                  else return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
    }
  }, [selectedTime]);

  const highestInPeopleCount =
    inConnectedPeople?.map((v) => v.map((item: any) => item.people)) || [];
  var flattenInPeopleArray = _.flatten(highestInPeopleCount);

  const highestOutPeopleCount =
    outConnectedPeople?.map((v) => v.map((item: any) => item.people)) || [];
  var flattenOutPeopleArray = _.flatten(highestOutPeopleCount);

  const highestSyntheticIn = syntheticIn?.map((v) => v.people) || [];
  const highestSyntheticOut = syntheticOut?.map((v) => v.people) || [];
  const highestNetOccupancy = netOccupancy?.map((v) => v.people) || [];

  const highestValue = Math.max.apply(null, [
    ...flattenInPeopleArray!,
    ...flattenOutPeopleArray!,
    ...highestSyntheticIn!,
    ...highestSyntheticOut!,
    ...highestNetOccupancy!,
  ]);

  useEffect(() => {
    if (resetTime) {
      const newTime = xAxisTime(+resetTime.split(/[.:]/)[0]);
      const index = netOccupancy?.findIndex((item) => item.date === newTime);
      if (index! > -1) {
        const clonedArray = _.cloneDeep(netOccupancy);
        clonedArray?.map((item: any) => (item.people = 0));
        clonedArray?.splice(index!, 0, { date: newTime, people: 0 });
        clonedArray?.splice(index! + 2, 0, {
          date: newTime,
          people: highestValue + 5,
        });
        clonedArray?.splice(index! + 3, 0, { date: newTime, people: 0 });
        setResetTimeArray(clonedArray!);
      } else {
        setResetTimeArray([]);
      }
    }
  }, [highestValue, netOccupancy, resetTime]);

  return highestInPeopleCount?.length! > 0 ||
    highestOutPeopleCount?.length! > 0 ? (
    <Grid container={true} item={true}>
      <Grid className={classes.victoryChart}>
        <VictoryChart
          height={270}
          padding={{ top: 50, bottom: 50, left: 40, right: 20 }}
          containerComponent={<VictoryVoronoiContainer />}
        >
          <VictoryLegend
            x={35}
            y={-4}
            centerTitle
            colorScale={_.flatten(
              statisticsTimelineData
                .map((v: any) => {
                  return [v?.inColor!, v?.outColor!];
                })
                .concat(colorSyntheticIn, colorSyntheticOut)
            )}
            orientation="horizontal"
            gutter={{ left: 0, right: 12 }}
            itemsPerRow={4}
            style={{ labels: { fontSize: 6, marginTop: 4 } }}
            data={_.flatten(
              statisticsTimelineData
                .map((v: any) => {
                  return [
                    {
                      name:
                        v.flowType === "IN" || v.flowType === "BOTH"
                          ? "IN " + v?.name!
                          : null,
                    },
                    {
                      name:
                        v.flowType === "OUT" || v.flowType === "BOTH"
                          ? "OUT " + v?.name!
                          : null,
                    },
                  ];
                })
                .concat({ name: "Synthetic IN" }, { name: "Synthetic OUT" })
            )}
            events={[
              {
                target: "labels",
                eventHandlers: {
                  onClick: () => {
                    return [
                      {
                        target: "data",
                        mutation: (props) => {
                          if (lineName.length > 0) {
                            const removeElement = _.remove(
                              lineName,
                              function (n) {
                                return n === props.datum.name;
                              }
                            );
                            if (removeElement.length > 0) {
                              setLineName((oldData: any) => [...oldData]);
                            } else {
                              setLineName((oldData: any) => [
                                ...oldData,
                                props.datum.name,
                              ]);
                            }
                          } else {
                            setLineName((oldData: any) => [
                              ...oldData,
                              props.datum.name,
                            ]);
                          }
                          return {
                            style: {
                              fill: props.style.fill,
                              stroke:
                                props.style.stroke === "#000000"
                                  ? props.style.fill
                                  : "#000000",
                            },
                          };
                        },
                      },
                      {
                        target: "labels",
                        mutation: (props) => {
                          return {
                            style: {
                              fill:
                                props.style.fill === "#2056a6"
                                  ? "#000000"
                                  : "#2056a6",
                              fontSize: 6,
                              fontWeight:
                                props.style.fill === "#2056a6" ? 400 : 600,
                            },
                          };
                        },
                      },
                    ];
                  },
                },
              },
            ]}
          />
          {xAxis}
          <VictoryAxis
            dependentAxis={true}
            domain={{ y: [0, highestValue + 5] }}
            tickFormat={(t) => t}
            offsetX={40}
            axisLabelComponent={
              <VictoryLabel
                style={{ fontSize: 6, fontFamily: "Poppins", color: "#ccc" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
          />

          {statisticsTimelineData?.length! > 0 ? (
            statisticsTimelineData?.map((v: any, index: any) =>
              lineName.map((ele: any) =>
                ele === `IN ${v.name}` ? (
                  <VictoryLine
                    data={v?.inData! ? v?.inData! : []}
                    x="date"
                    y="people"
                    style={{
                      data: { stroke: v?.inColor!, strokeWidth: 1 },
                    }}
                    labels={(d) => Math.round(d.datum.people)}
                    labelComponent={
                      <VictoryTooltip
                        cornerRadius={1}
                        flyoutWidth={15}
                        flyoutHeight={15}
                        pointerWidth={5}
                        flyoutStyle={{
                          stroke: v?.inColor!,
                          fill: "#E6F8FA",
                        }}
                        style={{ fontSize: 8 }}
                      />
                    }
                  />
                ) : (
                  <></>
                )
              )
            )
          ) : (
            <></>
          )}

          {statisticsTimelineData?.length! > 0 ? (
            statisticsTimelineData?.map((v: any, index: any) =>
              lineName.map((ele: any) =>
                ele === `OUT ${v.name}` ? (
                  <VictoryLine
                    data={v?.outData! ? v?.outData! : []}
                    x="date"
                    y="people"
                    style={{
                      data: {
                        stroke: v?.outColor!,
                        strokeWidth: 1,
                        strokeDasharray: 2,
                      },
                    }}
                    labels={(d) => Math.round(d.datum.people)}
                    labelComponent={
                      <VictoryTooltip
                        cornerRadius={1}
                        flyoutWidth={15}
                        flyoutHeight={15}
                        pointerWidth={5}
                        flyoutStyle={{
                          stroke: v?.outColor!,
                          fill: "#E6F8FA",
                        }}
                        style={{ fontSize: 8 }}
                      />
                    }
                  />
                ) : (
                  <></>
                )
              )
            )
          ) : (
            <></>
          )}
          {lineName.map((ele: any) =>
            ele === "Synthetic IN" ? (
              <VictoryLine
                data={syntheticIn ? syntheticIn : []}
                x="date"
                y="people"
                style={{
                  data: {
                    stroke: colorSyntheticIn,
                    strokeWidth: 1,
                  },
                }}
                labels={(d) => Math.round(d.datum.people)}
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={1}
                    flyoutWidth={15}
                    flyoutHeight={15}
                    pointerWidth={5}
                    flyoutStyle={{
                      stroke: colorSyntheticIn,
                      fill: "#E6F8FA",
                    }}
                    style={{ fontSize: 8 }}
                  />
                }
              />
            ) : (
              <></>
            )
          )}
          {lineName.map((ele: any) =>
            ele === "Synthetic OUT" ? (
              <VictoryLine
                data={syntheticOut ? syntheticOut : []}
                x="date"
                y="people"
                style={{
                  data: {
                    stroke: colorSyntheticOut,
                    strokeWidth: 1,
                    strokeDasharray: 2,
                  },
                }}
                labels={(d) => Math.round(d.datum.people)}
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={1}
                    flyoutWidth={15}
                    flyoutHeight={15}
                    pointerWidth={5}
                    flyoutStyle={{
                      stroke: colorSyntheticOut,
                      fill: "#E6F8FA",
                    }}
                    style={{ fontSize: 8 }}
                  />
                }
              />
            ) : (
              <></>
            )
          )}
          {(selectedTime === "24h" ||
            selectedTime === "15m" ||
            selectedTime === "1h") &&
          resetTime ? (
            <VictoryLine
              data={resetTimeArray}
              x="date"
              y="people"
              style={{
                data: { stroke: "#ACACAC", strokeWidth: 1.5 },
              }}
            />
          ) : (
            <></>
          )}
          <VictoryLine
            data={netOccupancy ? netOccupancy : []}
            x="date"
            y="people"
            style={{
              data: {
                stroke: colorNetOccupancy,
                strokeWidth: 1,
              },
            }}
            labels={(d) => Math.round(d.datum.people)}
            labelComponent={
              <VictoryTooltip
                cornerRadius={1}
                flyoutWidth={15}
                flyoutHeight={15}
                pointerWidth={5}
                flyoutStyle={{
                  stroke: colorNetOccupancy,
                  fill: "#E6F8FA",
                }}
                style={{ fontSize: 8 }}
              />
            }
          />
        </VictoryChart>
      </Grid>
      <Grid className={classes.timeZoneWrapper}>
        <Typography className={classes.timeZone}>
          <ClockIcon style={{ marginRight: 2, width: 11, height: 11 }} />
          {selectedProperty?.timeZoneName}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid container={true}>
      <Grid
        container={true}
        item={true}
        md={12}
        justify="center"
        className={classes.noData}
      >
        <NoData />
      </Grid>
      <Grid container={true} item={true} md={12} justify="center">
        <Typography className={classes.greyText}>No Data</Typography>
      </Grid>
    </Grid>
  );
}
