import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { Zone } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useZoneBase() {
  const [Zone, setZone] = useState<Zone[]>([]);
  const [ZoneFromResponse, setZoneFromResponse] = useState<
    Zone[]
  >([]);

  const {
    state: selectedZone,
    setState: setSelectedZone,
  } = usePersistedState<Zone | null>("imageSensor", null);

  const { search } = useSearch();

  const searchZone = useCallback(
    (searchValue: string) => {
      setZone(
        search(ZoneFromResponse, searchValue) as Zone[]
      );
    },
    [ZoneFromResponse, search]
  );

  return {
    searchZone,
    Zone,
    setZone,
    ZoneFromResponse,
    setZoneFromResponse,
    selectedZone,
    setSelectedZone,
  };
}

const { Provider, useContainer } = createContainer(useZoneBase);

export const useZoneState = useContainer;
export const ZoneProvider = Provider;
