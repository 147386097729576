import { useState } from "react";
import { createContainer } from "unstated-next";

function useLeftPaneBase() {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isPanelExpanded, setPanelExpanded] = useState<string | boolean>(false);

  return {
    isExpanded,
    setIsExpanded,
    isPanelExpanded,
    setPanelExpanded,
  };
}

const { Provider, useContainer } = createContainer(useLeftPaneBase);

export const useLeftPaneState = useContainer;
export const LeftPaneProvider = Provider;
