import React from "react";
import { Popover, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translate(12px, 6px)",
  },
  paper: {
    backgroundColor: theme.palette.common.blackPearl,
    color: theme.palette.common.white,
    borderRadius: 10,
  },
}));

interface ContextMenuProps {
  anchor: any;
  children?: React.ReactNode;
  onClose: (e: any) => void;
}

export function ContextMenu({ anchor, onClose, children }: ContextMenuProps) {
  const classes = useStyles();

  return (
    anchor && (
      <Popover
        className={classes.root}
        PaperProps={{ className: classes.paper }}
        open={Boolean(anchor.anchorEl)}
        anchorEl={anchor.anchorEl}
        anchorOrigin={{ horizontal: anchor.x, vertical: anchor.y }}
        onClose={onClose}
      >
        {children}
      </Popover>
    )
  );
}
