import React, { useCallback, useState } from "react";
import { FieldInputProps, useFormikContext } from "formik";
import { Grid, Switch } from "@material-ui/core";

import { useStyles } from "./ActivitySwitch.styles";

interface ActivitySwitchProps extends FieldInputProps<any> {
  defaultValue: boolean;
  disabled?: boolean;
  activeToggleText?: string;
  inactiveToggleText?: string;
}

export function ActivitySwitch({
  name,
  defaultValue = false,
  disabled = false,
  onChange,
  activeToggleText = "Yes",
  inactiveToggleText = "No",
}: ActivitySwitchProps) {
  const classes = useStyles();
  const {
    errors,
    values,
    initialValues,
    isSubmitting,
    setFieldValue,
  } = useFormikContext<{ [key: string]: string }>();
  const isTouched = isSubmitting || values[name] !== initialValues[name];
  const [isSwitchActive, setIsSwitchActive] = useState<boolean>(defaultValue);

  const handleChange = useCallback(
    (e) => {
      setIsSwitchActive(!isSwitchActive);
      onChange!({
        ...e,
        target: { name, value: !isSwitchActive },
      });
    },
    [name, isSwitchActive]
  );

  return (
    <Grid key={name} container={true} item={true} direction="row">
      <Switch
        name={name}
        value={isSwitchActive}
        checked={isSwitchActive}
        classes={{
          root: `${isTouched && errors[name] ? classes.error : classes.switch}`,
          checked: classes.checked,
          thumb: classes.thumb,
          track: classes.track,
          switchBase: classes.base,
        }}
        disabled={disabled}
        onChange={handleChange}
      />
      <div className={classes.status}>
        {isSwitchActive === false && inactiveToggleText}
      </div>
      <div className={classes.status}>
        {isSwitchActive === true && activeToggleText}
      </div>
    </Grid>
  );
}
