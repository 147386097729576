import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.common.white,
    backgroundColor: "#82D3E0",
    width: 24,
    height: 24,
    fontWeight: 700,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
  },
  name: {
    marginLeft: 7,
    whiteSpace: "nowrap",
    fontWeight: 500,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
  },
  avatarImage: {
    width: 24,
  },
}));