import React, { useCallback, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  ClickAwayListener,
  Popper,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { ReactComponent as LineChart } from "../../../../common/assets/images/line-chart 2.svg";
import {
  ImageSensorWithOccupancy,
  Floor,
  TripWireWithOccupancy,
  ImageDensityDataTripWire,
  DashboardDeviceType,
} from "../../../../types";
import { useStyles } from "./CameraListItem";
import clsx from "clsx";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { colorSpaceLoad } from "../../../../common/utils/Canvas.utils";
import { LinearChartForDensity } from "../../../../common/components/Charts/LinearCharts/LinearChartForDensity";
import moment from "moment";
import { BarChartForDensity } from "../../../../common/components/Charts/BarCharts/BarChartForDensity";
import CancelIcon from "@material-ui/icons/Cancel";
import { SwipePanel } from "../../../../common/components";
import { useIntl } from "react-intl";
import { ReactComponent as InTripWire } from "../../../../common/assets/images/Downward.svg";
import { ReactComponent as OutTripWire } from "../../../../common/assets/images/Upward.svg";
import { ReactComponent as InOutTripWire } from "../../../../common/assets/images/InOut.svg";
import { MyPinsModal } from "../../MyPins/MyPinsModal";
import { UnpinModal } from "../../MyPins/UnpinModal";
import { useFiltersState } from "../../../../core/context/containers/Filters.container";
import { ReactComponent as Pin } from "../../../../common/assets/images/pin.svg";
import { ReactComponent as PinBlack } from "../../../../common/assets/images/pin_black.svg";
import { useUserState } from "../../../../core/context/containers/User.container";
import { SwipePanelTP } from "../../../Dashboard/ImagesSensors/Edit/components/TripWire/SwipePanelTP";

type TripWireProps = {
  dataWithSpaceLoad: ImageSensorWithOccupancy;
  floor: Floor;
  timeFrom: any;
  timeTo: any;
  timeRange: any;
  clickTripWire: (camera: ImageSensorWithOccupancy, tripWireId: string) => void;
  handleIsPinned?: (el: any) => void;
};

type classNameIndicator =
  | "highIndicator"
  | "lowIndicator"
  | "mediumIndicator"
  | "notConfiguredIndicator"
  | "offlineIndicator";

export function TripWirePopup({
  dataWithSpaceLoad,
  floor,
  clickTripWire,
  timeFrom,
  timeTo,
  timeRange,
  handleIsPinned,
}: TripWireProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();

  const [densityTripWireData, setDensityTripWireData] =
    useState<ImageDensityDataTripWire>();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [className, setClassName] = useState<classNameIndicator | null>(null);
  const [tripWireDetails, setTripWireDetails] =
    useState<TripWireWithOccupancy>();
  const { selectedFloor, selectedTime, selectedSpace } = useFiltersState();
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [toggleUnpinModal, setToggleUnpinModal] = useState<boolean>(false);
  const [swipeIndex, setSwipeIndex] = useState<any>(0);
  const { userEmail } = useUserState();
  const id = open ? "simple-popper" : undefined;

  const handleTripWireOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>, tripWire: TripWireWithOccupancy) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setOpen(true);
      setTripWireDetails(tripWire);
      clickTripWire(dataWithSpaceLoad, tripWire?.id);
      event.stopPropagation();
    },
    [anchorEl, clickTripWire, dataWithSpaceLoad]
  );

  const toggleTripWireActive = useCallback(
    (event: React.MouseEvent<HTMLElement>, tripWire: TripWireWithOccupancy) => {
      event.preventDefault();
      event.stopPropagation();
      clickTripWire(dataWithSpaceLoad, tripWire?.id);
    },
    [clickTripWire, dataWithSpaceLoad]
  );

  const handleClose = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(null);
    setOpen(false);
    event.stopPropagation();
  }, []);

  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  const [
    getOccupancyDuringTimeForTripWire,
    { loading: tripWireLoading, error: tripWireError, data: tripWireData },
  ] = useLazyQuery(GQLService.queries.occupancyDuringTimeForTripWire, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (tripWireDetails) {
      getOccupancyDuringTimeForTripWire({
        variables: {
          request: {
            tripWireId: tripWireDetails?.id,
            floorId: floor.id,
            cameraId: dataWithSpaceLoad.id,
            from: timeFrom,
            to: timeTo,
            timeRangeType: timeRange,
            userEmail: userEmail,
          },
        },
      });
    }
  }, [
    floor,
    dataWithSpaceLoad.id,
    getOccupancyDuringTimeForTripWire,
    timeFrom,
    timeRange,
    timeTo,
    tripWireDetails,
    userEmail,
  ]);

  useEffect(() => {
    setDensityTripWireData(tripWireData?.occupancyDuringTimeForTripWire);
  }, [tripWireData]);

  useEffect(() => {
    if (!tripWireLoading) {
      setClassName(
        colorSpaceLoad(tripWireDetails?.spaceLoad!)
          ?.className as classNameIndicator
      );
    }
  }, [tripWireLoading, tripWireDetails]);

  return (
    <div>
      {dataWithSpaceLoad.tripWires.map((tripWire: any) => (
        <Grid
          container
          item
          sm={12}
          key={tripWire.id}
          onClick={(event) => toggleTripWireActive(event, tripWire)}
          className={clsx({
            [classes.zoneGridStyle]: true,
            [classes.selectedZoneGridStyle]: tripWire.isSelected,
          })}
        >
          <Grid style={{ flexBasis: "100%" }}>
            {tripWire.isPinned ? (
              <Grid className={classes.pinIconGrid}>
                <PinBlack
                  style={{
                    transform: "rotate(45deg)",
                    opacity: "0.6",
                    height: "16px",
                  }}
                />
              </Grid>
            ) : null}
            <Grid style={{ display: "flex" }}>
              <Grid item sm={2}>
                {tripWire.flowType === "BOTH" ? (
                  <Tooltip
                    enterTouchDelay={0}
                    placement="top"
                    title="TripWire for In and Out"
                  >
                    <InOutTripWire />
                  </Tooltip>
                ) : tripWire.flowType === "IN" ? (
                  <Tooltip
                    enterTouchDelay={0}
                    placement="top"
                    title="TripWire for In"
                  >
                    <InTripWire />
                  </Tooltip>
                ) : (
                  <Tooltip
                    enterTouchDelay={0}
                    placement="top"
                    title="TripWire for Out"
                  >
                    <OutTripWire />
                  </Tooltip>
                )}
              </Grid>
              <Grid item sm={8}>
                <Typography className={classes.zoneNameText}>
                  {tripWire.name ? tripWire.name : tripWire.tripWire}
                </Typography>
              </Grid>
              <Grid
                sm={2}
                item={true}
                aria-describedby={id}
                className={classes.showStatisticsContainer}
              >
                <Button
                  onClick={(event) => handleTripWireOpen(event, tripWire)}
                  color="primary"
                >
                  <LineChart width={11} id="open" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}
        placement="bottom-end"
        popperOptions={{
          modifiers: {
            flip: { enabled: true },
            offset: {
              enabled: true,
            },
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper classes={{ root: classes.paper }}>
            <CancelIcon
              color="primary"
              onClick={(e) => handleClose(e)}
              classes={{ root: classes.closeIcon }}
            />
            <Grid className={classes.chartsContainer}>
              <Grid className={classes.popperContainer}>
                <Typography className={classes.popperName}>
                  {densityTripWireData?.name
                    ? densityTripWireData?.name
                    : densityTripWireData?.tripWire!}{" "}
                  : {densityTripWireData?.flowType}
                </Typography>
              </Grid>
              <Grid className={classes.currentStatusIndicatorContainer}>
                <Grid>
                  <Typography className={classes.chartsTitle}>
                    Flow through time
                  </Typography>
                </Grid>
                <Grid style={{ display: "flex" }}>
                  <Typography className={classes.statusLabel}>
                    Status:
                  </Typography>
                  <Typography className={classes.status}>
                    {tripWireDetails?.spaceLoad}
                  </Typography>
                  {className && (
                    <span
                      className={clsx(
                        classes[className],
                        classes.popperIndicator
                      )}
                    />
                  )}
                </Grid>
              </Grid>
              {(densityTripWireData?.flowType as unknown as string) ===
              "BOTH" ? (
                <SwipePanelTP
                  className={classes.swipePanel}
                  stylesNew={classes}
                  tabNames={[
                    formatMessage({
                      id: "tripWire.showStastistics.tabs.in",
                      defaultMessage: "In",
                    }),
                    formatMessage({
                      id: "tripWire.showStastistics.tabs.out",
                      defaultMessage: "Out",
                    }),
                  ]}
                  setSwipeIndex={setSwipeIndex}
                >
                  <>
                    <LinearChartForDensity
                      data={
                        densityTripWireData?.cameraOccupacyTimelineFlowIN?.map(
                          (d, i: number) => {
                            return {
                              x:
                                timeRange === "DAY"
                                  ? moment
                                      .tz(
                                        d.from,
                                        selectedProperty?.timeZoneName!
                                      )
                                      .format("DD MMM ddd")
                                  : moment
                                      .tz(
                                        d.from,
                                        selectedProperty?.timeZoneName!
                                      )
                                      .format("LT"),
                              y: d.connectedPeopleCount,
                            };
                          }
                        )!
                      }
                      spaceLoad={{
                        low: densityTripWireData?.inOccupancyMediumMinThreShold!,
                        high: densityTripWireData?.inOccupancyMediumMaxThreShold!,
                        limit: densityTripWireData?.inOccupancyMaxThreShold!,
                      }}
                      loading={tripWireLoading}
                      error={tripWireError}
                    />
                    <Typography className={classes.chartsTitle}>
                      Flow through time
                    </Typography>
                    <BarChartForDensity
                      data={[
                        {
                          x: "Low",
                          y: densityTripWireData?.lowDensityTimeForIn!,
                          fill: "rgba(79, 158, 83, 0.15)",
                        },
                        {
                          x: "Medium",
                          y: densityTripWireData?.mediumDensityTimeForIn!,
                          fill: "rgba(238, 170, 70, 0.2)",
                        },
                        {
                          x: "High",
                          y: densityTripWireData?.highDensityTimeForIn!,
                          fill: "rgba(176, 0, 31, 0.2)",
                        },
                      ]}
                      loading={tripWireLoading}
                      error={tripWireError}
                    />
                  </>
                  <>
                    <LinearChartForDensity
                      data={
                        densityTripWireData?.cameraOccupacyTimelineFlowOUT?.map(
                          (d, i: number) => {
                            return {
                              x:
                                timeRange === "DAY"
                                  ? moment
                                      .tz(
                                        d.from,
                                        selectedProperty?.timeZoneName!
                                      )
                                      .format("DD MMM ddd")
                                  : moment
                                      .tz(
                                        d.from,
                                        selectedProperty?.timeZoneName!
                                      )
                                      .format("LT"),
                              y: d.connectedPeopleCount,
                            };
                          }
                        )!
                      }
                      spaceLoad={{
                        low: densityTripWireData?.outOccupancyMediumMinThreShold!,
                        high: densityTripWireData?.outOccupancyMediumMaxThreShold!,
                        limit: densityTripWireData?.outOccupancyMaxThreShold!,
                      }}
                      loading={tripWireLoading}
                      error={tripWireError}
                    />
                    <Typography className={classes.chartsTitle}>
                      Flow through time
                    </Typography>
                    <BarChartForDensity
                      data={[
                        {
                          x: "Low",
                          y: densityTripWireData?.lowDensityTimeForOut!,
                          fill: "rgba(79, 158, 83, 0.15)",
                        },
                        {
                          x: "Medium",
                          y: densityTripWireData?.mediumDensityTimeForOut!,
                          fill: "rgba(238, 170, 70, 0.2)",
                        },
                        {
                          x: "High",
                          y: densityTripWireData?.highDensityTimeForOut!,
                          fill: "rgba(176, 0, 31, 0.2)",
                        },
                      ]}
                      loading={tripWireLoading}
                      error={tripWireError}
                    />
                  </>
                </SwipePanelTP>
              ) : (
                <>
                  <LinearChartForDensity
                    data={
                      densityTripWireData?.flowType === "IN"
                        ? densityTripWireData?.cameraOccupacyTimelineFlowIN?.map(
                            (d, i: number) => {
                              return {
                                x:
                                  timeRange === "DAY"
                                    ? moment
                                        .tz(
                                          d.from,
                                          selectedProperty?.timeZoneName!
                                        )
                                        .format("DD MMM ddd")
                                    : moment
                                        .tz(
                                          d.from,
                                          selectedProperty?.timeZoneName!
                                        )
                                        .format("LT"),
                                y: d.connectedPeopleCount,
                              };
                            }
                          )!
                        : densityTripWireData?.cameraOccupacyTimelineFlowOUT?.map(
                            (d, i: number) => {
                              return {
                                x:
                                  timeRange === "DAY"
                                    ? moment
                                        .tz(
                                          d.from,
                                          selectedProperty?.timeZoneName!
                                        )
                                        .format("DD MMM ddd")
                                    : moment
                                        .tz(
                                          d.from,
                                          selectedProperty?.timeZoneName!
                                        )
                                        .format("LT"),
                                y: d.connectedPeopleCount,
                              };
                            }
                          )!
                    }
                    spaceLoad={{
                      low:
                        densityTripWireData?.flowType === "IN"
                          ? densityTripWireData?.inOccupancyMediumMinThreShold!
                          : densityTripWireData?.outOccupancyMediumMinThreShold!,
                      high:
                        densityTripWireData?.flowType === "IN"
                          ? densityTripWireData?.inOccupancyMediumMaxThreShold!
                          : densityTripWireData?.outOccupancyMediumMaxThreShold!,
                      limit:
                        densityTripWireData?.flowType === "IN"
                          ? densityTripWireData?.inOccupancyMaxThreShold!
                          : densityTripWireData?.outOccupancyMaxThreShold!,
                    }}
                    loading={tripWireLoading}
                    error={tripWireError}
                  />
                  <Typography className={classes.chartsTitle}>
                    Flow through time
                  </Typography>
                  <BarChartForDensity
                    data={[
                      {
                        x: "Low",
                        y:
                          densityTripWireData?.flowType === "IN"
                            ? densityTripWireData?.lowDensityTimeForIn!
                            : densityTripWireData?.lowDensityTimeForOut!,
                        fill: "rgba(79, 158, 83, 0.15)",
                      },
                      {
                        x: "Medium",
                        y:
                          densityTripWireData?.flowType === "IN"
                            ? densityTripWireData?.mediumDensityTimeForIn!
                            : densityTripWireData?.mediumDensityTimeForOut!,
                        fill: "rgba(238, 170, 70, 0.2)",
                      },
                      {
                        x: "High",
                        y:
                          densityTripWireData?.flowType === "IN"
                            ? densityTripWireData?.highDensityTimeForIn!
                            : densityTripWireData?.highDensityTimeForOut!,
                        fill: "rgba(176, 0, 31, 0.2)",
                      },
                    ]}
                    loading={tripWireLoading}
                    error={tripWireError}
                  />
                </>
              )}
            </Grid>
            {!tripWireLoading &&
              (densityTripWireData?.isPinned! ? (
                <Grid
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    marginBottom: "20px",
                    marginRight: "20px",
                  }}
                >
                  <Button
                    className={classes.pinItButton}
                    onClick={() => setToggleUnpinModal(true)}
                  >
                    <div style={{ marginLeft: "8px" }}></div>
                    Unpin It
                  </Button>
                </Grid>
              ) : (
                <Grid
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    marginBottom: "20px",
                    marginRight: "20px",
                  }}
                >
                  <Button
                    className={classes.pinItButton}
                    onClick={() => setToggleModal(true)}
                  >
                    <Pin />
                    <div style={{ marginLeft: "8px" }}></div>
                    Pin It
                  </Button>
                </Grid>
              ))}
            {toggleModal && (
              <MyPinsModal
                setToggleModal={setToggleModal}
                selectedTime={selectedTime}
                selectedSpace={selectedSpace}
                selectedFloor={selectedFloor}
                deviceType={DashboardDeviceType.TRIPWIRE}
                deviceId={tripWireDetails?.id}
                timeRange={timeRange}
                handleIsPinned={handleIsPinned}
                subType={swipeIndex}
                cameraId={dataWithSpaceLoad.id}
              />
            )}
            <Grid style={{ left: "17%", position: "absolute", top: "20%" }}>
              {toggleUnpinModal && (
                <UnpinModal
                  setToggleModal={setToggleUnpinModal}
                  deviceType={DashboardDeviceType.TRIPWIRE}
                  deviceId={tripWireDetails?.id}
                  handleIsPinned={handleIsPinned}
                  Type="FloorOverview"
                />
              )}
            </Grid>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}
