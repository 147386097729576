import React, { useCallback, useEffect, useState } from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import Loader from "react-loader-spinner";
import { COLORS } from "../../../../../common/styles/colors";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../../core/services/GQL.service";
import { ImagesSensor } from "../../../../../types";

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 120,
    height: 42,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 100,
    height: 42,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(28),
    color: theme.palette.common.mediumBlack,
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    paddingTop: "20px",
    paddingRight: "16px",
  },
  content: {
    display: "flex",
    minWidth: "740px",
    minHeight: "484px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    paddingTop: "32px",
    paddingLeft: "23px",
    paddingBottom: "16px",
    borderRadius: "20px",
    boxShadow: "0px 4px 21px rgba(0, 0, 0, 0.38)",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: "16px",
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginBottom: "40px",
  },
  assignments: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
  },
  wrapperGrid: {
    display: "flex",
    marginRight: "23px",
    marginTop: "40px",
  },
  loaderGrid: { alignSelf: "center", marginTop: "20%" },
  spaceItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.white,
  },
  chipIcon: {
    color: "white",
    marginLeft: "10px",
    "&:hover": {
      color: "white",
    },
  },
  warningMsg: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginTop: "40px",
  },
  spaceItemGrid: {
    padding: "5px 10px 5px 10px",
    backgroundColor: "#2F56A0",
    borderRadius: "6px",
    display: "flex",
    marginBottom: "5px",
  },
  enclosedSpace: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.darkGrey,
  },
  enclosedSpaceGrid: {
    padding: "5px 10px 5px 10px",
    marginBottom: "5px",
    marginLeft: "16px",
  },
}));

interface ModalContentProps {
  headerText?: string;
  no: Function;
  yes?: Function;
  data?: any;
  loading?: any;
  imageSensor?: ImagesSensor;
}

export function RemoveModal({
  headerText = "",
  no,
  yes,
  data,
  loading,
  imageSensor,
}: ModalContentProps) {
  const classes = useStyles();
  const { selectedProperty } = usePropertiesState();
  const [tags, setTags] = useState<any>([]);
  const [spaceArray, setSpaceArray] = useState<any>([]);

  const [deleteSpacesFromTripwires] = useMutation(
    GQLService.mutations.deleteSpacesFromTripwires,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.cameraById,
          variables: { cameraId: imageSensor?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  useEffect(() => {
    setTags(data.spaces);
  }, [data]);


  const handleClose = useCallback((e: any, id: any) => {
    const newTags = tags?.filter((tag: any) => tag.space.id !== id);
    setTags(newTags);
    setSpaceArray([...spaceArray, id]);
  }, [tags]);

  const confirmDelete = () => {
    deleteSpacesFromTripwires({
      variables: {
        deviceId: data.id,
        spaceIds: spaceArray,
      },
    });
    no();
  };

  return (
    <Grid className={classes.content}>
      {/* {loading ? (
        <Grid className={classes.loaderGrid}>
          <Loader type="Oval" color={COLORS.funBlue} height={100} width={100} />
        </Grid>
      ) : ( */}
      <Grid>
        <Grid>
          <Typography className={classes.header}>{headerText}</Typography>
          <Typography className={classes.description}>
            The following Spaces are associated with the selected{" "}
            {headerText === "Unlink Tripwire" ? "Tripwire" : "Zone"}. You need
            to unlink these spaces mentioned below by clicking the cross icon.
          </Typography>
        </Grid>
        <Grid className={classes.wrapperGrid}>
          <Typography className={classes.title}>Spaces</Typography>
        </Grid>
        <Grid>
          {tags !== null && tags.length > 0
            ? tags.map((item: any) => (
                <Grid style={{ display: "flex" }}>
                  <Grid className={classes.spaceItemGrid}>
                    <Typography className={classes.spaceItem}>
                      {item.space.name}
                    </Typography>
                    <CloseSharpIcon
                      className={classes.chipIcon}
                      onClick={(e) => handleClose(e, item.space.id)}
                    />
                  </Grid>
                  <Grid className={classes.enclosedSpaceGrid}>
                    {item.space.netflowActive ? (
                      <Typography className={classes.enclosedSpace}>
                        Fully Enclosed Space
                      </Typography>
                    ) : (
                      <Typography className={classes.enclosedSpace}>
                        --
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ))
            : null}
        </Grid>
        <Grid style={{ marginTop: "5px" }}>
          <Typography className={classes.warningMsg}>
            When the selected{" "}
            {headerText === "Unlink Tripwire" ? "Tripwire" : "Zone"} is
            unlinked, these spaces will no longer be fully enclosed.
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <Grid item className={classes.buttonsWrapper}>
          <Button
            id="cancelBtn"
            className={classes.cancel}
            onClick={() => no()}
          >
            Cancel
          </Button>
          <Button
            id="confirmBtn"
            className={classes.deleteBtn}
            onClick={() => confirmDelete()}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
      {/* )} */}
    </Grid>
  );
}
