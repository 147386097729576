import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  ClickAwayListener,
  MenuItem,
  Select,
} from "@material-ui/core";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import moment from "moment";
import { BarChartForDensity } from "../../../common/components/Charts/BarCharts/BarChartForDensity";
import { ReactComponent as PinBlack } from "../../../common/assets/images/pin_black.svg";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";
import { useUserState } from "../../../core/context/containers/User.container";
import { TIMEZONES } from "../../../common/constants/Timezones";
import { UnpinModal } from "./UnpinModal";
import { DashboardDeviceType, SensorAttributeDBName } from "../../../types";
import { LinearChartForSensors } from "../Occupancy/SensorsSideList/Charts/LineChartSensors";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { CustomModal } from "../../../common/components";
import { CollapsibleMyPin } from "./CollapsibleMyPin";

const useStyles = makeStyles((theme) => ({
  displayGrid: {
    display: "flex",
    marginLeft: "16px",
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "4px",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "8px",
  },
  statisticContainer: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0px 4px 13px rgba(135, 135, 135, 0.3)",
    paddingBottom: "24px",
    paddingRight: "10px",
  },
  statisticContainerTitleGrid: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "14px",
  },
  statisticContainerTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginLeft: "14px",
    marginBottom: "16px",
  },
  pinIcon: {
    transform: "rotate(45deg)",
    height: "16px",
  },
  densityTimeGrid: {
    marginBottom: "16px",
    marginTop: "20px",
  },
  dwellTime: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: "14px",
  },
  selectedBox: {
    height: 25,
    backgroundColor: "#ECF8FA",
    marginBottom: "0px",
    border: `1px solid #D9F2F6`,
    borderRadius: 4,
    marginLeft: "10px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    justifyContent: "center",
    alignItems: "center",
    width: 150,
  },
  YAxisLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    display: "flex",
    justifyContent: "center",
    width: 45,
  },
  select: {
    height: 13,
    minWidth: 100,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    marginTop: "-5px",
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: 9,
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
    marginTop: "-2px",
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    borderRadius: "0 0 8px 8px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  timeIcon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  timeSelect: {
    height: 13,
    minWidth: 100,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  durationGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

type AQMyPinPropType = {
  item: any;
  request?: any;
  duration?: any;
  timeZone?: any;
  sensorConfigurationData?: any;
  removedCard?: any;
};

export function AQMyPin({
  item,
  request,
  duration,
  timeZone,
  sensorConfigurationData,
  removedCard,
}: AQMyPinPropType) {
  const classes = useStyles();
  const { selectedProperty, selectedPropertyTimeZone } = usePropertiesState();
  const [aqDensityData, setAQDensityData] = useState<any>();
  const { userEmail } = useUserState();
  const [newTimeZone, setNewTimeZone] = useState<any>();
  const [toggleUnpinModal, setToggleUnpinModal] = useState<boolean>(false);
  const [dropdwnSelectedAQ, setDropdwnSelectedAQ] = useState<any>();

  let parsedRequest = JSON.parse(request).request;
  const [selectedTime, setSelectedTime] = useState(parsedRequest.selectedTime);

  const [
    airQualityWellnessDuringTimeForSensor,
    {
      error: errorWellness,
      loading: loadingWellness,
      data: responseDataWellness,
    },
  ] = useLazyQuery(GQLService.queries.airQualityWellnessDuringTimeForSensor);

  const temperatureUnit = useCallback(() => {
    const findUnit = sensorConfigurationData?.filter(
      (el: any) => el.attribute === "TEMPERATURE"
    );
    return findUnit![0].isCelsius;
  }, [sensorConfigurationData]);

  const AQDropDownList = [
    { value: SensorAttributeDBName.SCORE, label: "Wellness Index" },
    {
      value: SensorAttributeDBName.TEMPERATURE,
      label: `Temperature in ${temperatureUnit() ? "°C" : "°F"}`,
    },
    { value: SensorAttributeDBName.HUMIDITY, label: "Humidity in %" },
    { value: SensorAttributeDBName.CO2, label: "CO2 in ppm" },
    { value: SensorAttributeDBName.TVOCS, label: "VOC in ppb" },
    { value: SensorAttributeDBName.PM, label: "PM in ugm3" },
    { value: SensorAttributeDBName.LIGHT, label: "Light in lux" },
    { value: SensorAttributeDBName.NOISE, label: "Noise in db" },
  ];

  const timeRange = useMemo(() => {
    if (selectedTime! <= 15) return "MINUTE";
    if (selectedTime! <= 60) return "QUARTER";
    if (selectedTime! <= 1440) return "HOUR";
    return "DAY";
  }, [selectedTime, request]);

  const timeInMinutesFilter = [
    { value: 15, label: "Last 15 minutes" },
    { value: 60, label: "Last hour" },
    { value: 24 * 60, label: "Last 24 hours" },
    { value: 7 * 24 * 60, label: "Last 7 days" },
    { value: 30 * 24 * 60, label: "Last 30 days" },
  ];

  useEffect(() => {
    for (let i = 0; i < AQDropDownList.length; i++) {
      if (AQDropDownList[i].value === parsedRequest.subType) {
        setDropdwnSelectedAQ(AQDropDownList[i].value);
      }
    }
  }, []);

  const getTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === 10080 || selectedTime === 43200) {
      if (timeZone) {
        newTimeTo = moment.tz(timeZone).startOf("day").format();
        newTimeFrom = moment
          .tz(newTimeTo, timeZone)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment.utc().startOf("day").format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    } else {
      if (timeZone) {
        newTimeTo = moment
          .tz(timeZone)
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, timeZone)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, timeZone]);

  useEffect(() => {
    if (selectedTime!) {
      if (dropdwnSelectedAQ) {
        airQualityWellnessDuringTimeForSensor({
          variables: {
            request: {
              sensorId: parsedRequest.deviceId,
              floorId: parsedRequest.floorId,
              from: getTimeParams().newTimeFrom,
              to: getTimeParams().newTimeTo,
              timeRangeType: timeRange,
              sensorAttributeName: dropdwnSelectedAQ,
            },
          },
        });
      }
    }
  }, [
    request,
    airQualityWellnessDuringTimeForSensor,
    selectedProperty,
    dropdwnSelectedAQ,
    timeRange,
    selectedTime,
    parsedRequest.deviceId,
    parsedRequest.floorId,
    getTimeParams,
  ]);

  useEffect(() => {
    if (responseDataWellness) {
      setAQDensityData(
        responseDataWellness?.airQualityWellnessDuringTimeForSensor
      );
    }
  }, [responseDataWellness]);

  useEffect(() => {
    const findTimeZone = TIMEZONES.find((item) => item.abbr === timeZone);
    setNewTimeZone(findTimeZone);
  }, [timeZone]);

  const renderLabel = (selectedAQ: any) => {
    if (selectedAQ === SensorAttributeDBName.TEMPERATURE) {
      const unit = temperatureUnit();
      return unit ? "C" : "F";
    } else if (selectedAQ === SensorAttributeDBName.HUMIDITY) {
      return "%";
    } else if (selectedAQ === SensorAttributeDBName.CO2) {
      return "ppm";
    } else if (selectedAQ === SensorAttributeDBName.TVOCS) {
      return "ppb";
    } else if (selectedAQ === SensorAttributeDBName.PM) {
      return "ugm3";
    } else if (selectedAQ === SensorAttributeDBName.LIGHT) {
      return "lux";
    } else if (selectedAQ === SensorAttributeDBName.NOISE) {
      return "db";
    } else {
      return "";
    }
  };

  const handleAQChange = useCallback((e) => {
    setDropdwnSelectedAQ(e.target.value);
  }, []);

  const handleTimeChange = useCallback(
    (e) => {
      setSelectedTime(e.target.value);
    },
    [setSelectedTime]
  );

  return (
    <>
      <Grid>
        {!loadingWellness && <CollapsibleMyPin item={item} />}
        <ClickAwayListener onClickAway={(e) => setToggleUnpinModal(false)}>
          <Grid className={classes.statisticContainer}>
            <Grid className={classes.statisticContainerTitleGrid}>
              <Typography className={classes.statisticContainerTitle}>
                {dropdwnSelectedAQ === "SCORE"
                  ? "Wellness Index"
                  : dropdwnSelectedAQ === "TVOCS"
                  ? "VOC"
                  : dropdwnSelectedAQ}
              </Typography>
              <PinBlack
                fill="#3f51b5"
                className={classes.pinIcon}
                onClick={() => setToggleUnpinModal(true)}
              />
            </Grid>
            <Grid className={classes.durationGrid}>
              <Grid className={classes.displayGrid}>
                <Typography
                  className={classes.title}
                  style={{ marginTop: "4px" }}
                >
                  Duration:
                </Typography>
                <Select
                  classes={{
                    root: classes.timeSelect,
                    select: classes.blackText,
                    icon: classes.timeIcon,
                  }}
                  name="duration"
                  value={selectedTime}
                  disableUnderline={true}
                  onChange={handleTimeChange}
                  IconComponent={ExpandMoreSharpIcon}
                  MenuProps={{
                    classes: {
                      paper: classes.menu,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {timeInMinutesFilter.map((v) => (
                    <MenuItem
                      key={v.value}
                      value={v.value}
                      classes={{
                        root: classes.option,
                        selected: classes.selected,
                      }}
                    >
                      {v.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid style={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  className={classes.statisticContainerTitle}
                  style={{
                    marginBottom: "0px",
                    marginRight: "16px",
                    marginTop: "4px",
                  }}
                >
                  Selected
                </Typography>
                <Select
                  classes={{
                    root: classes.select,
                    select: classes.blackText,
                    icon: classes.icon,
                  }}
                  id="menu-AQFilter"
                  name="AQFilter"
                  value={
                    dropdwnSelectedAQ
                      ? dropdwnSelectedAQ
                      : parsedRequest.subType
                  }
                  disableUnderline={true}
                  onChange={handleAQChange}
                  IconComponent={ExpandMoreSharpIcon}
                  MenuProps={{
                    classes: {
                      paper: classes.menu,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    disablePortal: true,
                  }}
                >
                  {AQDropDownList?.map((v) => (
                    <MenuItem
                      key={v.value}
                      value={v.value}
                      classes={{
                        root: classes.option,
                        selected: classes.selected,
                      }}
                    >
                      {v.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid>
              <Typography className={classes.YAxisLabel}>
                {renderLabel(dropdwnSelectedAQ)}
              </Typography>
            </Grid>
            {aqDensityData?.timeline.length > 0 && !loadingWellness ? (
              <LinearChartForSensors
                data={aqDensityData?.timeline?.map((d: any, i: number) => {
                  return {
                    x:
                      timeRange === "DAY"
                        ? moment.tz(d.from, timeZone).format("DD MMM ddd")
                        : moment.tz(d.from, timeZone).format("LT"),
                    y:
                      dropdwnSelectedAQ === SensorAttributeDBName.TEMPERATURE
                        ? d.score
                        : Math.round(d.score),
                    quality: d.quality,
                  };
                })}
                loading={loadingWellness}
                error={errorWellness}
                selectedAQ={
                  dropdwnSelectedAQ ? dropdwnSelectedAQ : parsedRequest.subType
                }
                sensorConfigurations={sensorConfigurationData}
              />
            ) : loadingWellness ? null : (
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ height: 164 }}
              >
                <Typography>No data</Typography>
              </Grid>
            )}
            <Grid className={classes.densityTimeGrid}>
              <Typography className={classes.statisticContainerTitle}>
                {dropdwnSelectedAQ === "SCORE"
                  ? "Wellness Index"
                  : dropdwnSelectedAQ === "TVOCS"
                  ? "VOC"
                  : dropdwnSelectedAQ}
              </Typography>
            </Grid>
            <BarChartForDensity
              data={[
                {
                  x: "Low",
                  y: aqDensityData?.healthyTime!,
                  fill: "rgba(79, 158, 83, 0.15)",
                },
                {
                  x: "Medium",
                  y: aqDensityData?.moderateTime!,
                  fill: "rgba(238, 170, 70, 0.2)",
                },
                {
                  x: "High",
                  y: aqDensityData?.poorTime!,
                  fill: "rgba(176, 0, 31, 0.2)",
                },
              ]}
              loading={loadingWellness}
              error={errorWellness}
            />
          </Grid>
        </ClickAwayListener>
      </Grid>
      <CustomModal open={toggleUnpinModal}>
        <UnpinModal
          setToggleModal={setToggleUnpinModal}
          deviceType={DashboardDeviceType.AIRQUALITY}
          deviceId={parsedRequest.deviceId}
          Type="MyPins"
          removedCard={removedCard}
        />
      </CustomModal>
    </>
  );
}
