import { COLORS } from "./colors";

export const listStyles = {
  overrides: {
    MuiAccordion: {
      root: {
        boxShadow: "0 0 0 0",
        border: "1px solid #BDCED7",
        marginBottom: "8px 0 !important",
      },
      expanded: {
        marginBottom: "8px",
        margin: "8px 0",
      },
      rounded: {
        marginBottom: "8px",
        margin: "8px 0",
      },
    },
    MuiAccordionDetails: {},
    MuiAccordionSummary: {
      content: {
        margin: "0 0 0 0",
      },
    },
  },
  expandIcon: {
    color: COLORS.inputGrey,
    width: 26,
  },
  header: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    color: COLORS.regularBlue,
    marginLeft: 10,
  },
  subheader: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    color: COLORS.darkGrey,
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
  },
  extendedListSubheader: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "16px",
    color: COLORS.darkGrey,
  },
};
