import { gql } from "@apollo/client";

export const DEVICES_QUERIES = {
  devicesByPropertyId: gql`
    query($propertyId: String!) {
      devicesByPropertyId(propertyId: $propertyId) {
        id
        mac
        deviceOs
        deviceType
        description
        ignore
        lastConnectionTime
      }
    }
  `,
};

export const DEVICES_MUTATIONS = {
  updateDevice: gql`
    mutation updateDevice(
      $ignore: Boolean
      $description: String
      $id: String!
    ) {
      updateDevice(
        request: { ignore: $ignore, description: $description, id: $id }
      )
    }
  `,
};
