import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  blueLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.funBlue,
    marginLeft: "5px",
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
  },
  button: {
    backgroundColor: theme.palette.common.matisse,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: 115,
    height: 42,
  },
  wrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexBasis: 0,
    alignContent: "center",
    alignItems: "center",
  },
  main: {
    marginTop: theme.spacing(3),
  },
  mediumIndicator: {
    backgroundColor: "#FCA642",
    width: "8px",
    display: "block",
    height: "35px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  lowIndicator: {
    backgroundColor: "#9BCA60",
    width: "8px",
    display: "block",
    height: "35px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  highIndicator: {
    backgroundColor: "#E25353",
    width: "8px",
    display: "block",
    height: "35px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  indicatorWrapper: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    // width: "30%",
  },
  numberWrapper: {
    display: "flex",
    height: "fit-content",
    alignItems: "center",
    width: "40%",
  },
  buttonEdit: {
    backgroundColor: theme.palette.common.matisse,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: 115,
    height: 42,
    alignSelf: "flex-start",
  },
  valueLabel: {
    borderBottom: "1px solid #D2D2D2",
    paddingRight: "20px",
    width: "fit-content",
    marginLeft: "5px",
    fontSize: "14px",
  },
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    width: 65,
    fontSize: "14px",
    marginBottom: 14,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
    "&:before": {
      content: "none",
    },
  },
  inputField: {
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  infoWrapper: {
    width: "30%",
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "flex-start",
  },
  healthy: {
    backgroundColor: "rgba(79, 158, 83, 0.15)",
    borderRadius: "4px",
    padding: "4px 16px",
    marginRight: "16px",
  },
  green: {
    backgroundColor: "#4F9E53",
    width: "5px",
    height: "30px",
    borderRadius: "4px",
    display: "inline-block",
  },
  moderate: {
    backgroundColor: "rgba(238, 170, 70, 0.2)",
    borderRadius: "4px",
    padding: "4px 16px",
    marginRight: "16px",
  },
  orange: {
    backgroundColor: "#EEAA46",
    width: "5px",
    height: "30px",
    borderRadius: "4px",
    display: "inline-block",
  },
  poor: {
    backgroundColor: "rgba(176, 0, 31, 0.2)",
    borderRadius: "4px",
    padding: "4px 16px",
    marginRight: "16px",
  },
  red: {
    backgroundColor: "#B0001F",
    width: "5px",
    height: "30px",
    borderRadius: "4px",
    display: "inline-block",
  },
  notCovered: {
    backgroundColor: "#81D3E0",
    width: "5px",
    height: "30px",
    borderRadius: "4px",
    display: "inline-block",
    marginRight: "8px",
  },
  airSensorContainer: {
    border: "1px solid #E0EBF1",
    padding: "16px 20px",
  },
  verticalSpaing: {
    margin: "4px 0",
  },
  marginTop: {
    marginTop: "20px",
  },
  sliderWrapper: {
    width: "700px",

    "& .rc-slider-tooltip-inner": {
      backgroundColor: "transparent !important",
      color: "#464646",
      boxShadow: "none",
      fontSize: "14px",
    },

    "& .rc-slider-handle.rc-slider-handle-dragging": {
      boxShadow: "0 0 0 5px #464646",
    },
  },
  tempUnitSelect: {
    fontSize: "14px",
    marginLeft: "10px",
    paddingRight: "20px",

    "&  MuiSelect-select": {
      padding: "2px",
    },
  },
  select: {
    height: 13,
    width: 15,
    minWidth: 15,
    padding: 5,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    borderRadius: "0 0 8px 8px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
}));
