import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { PageHeader } from "../../../../../../common/components";
import {
  Button,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import { useTripWireState } from "../../../../../../core/context/containers/TripWire.container";
import { useAuth } from "../../../../../../core/context/containers/Auth.container";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import { ElementView } from "../../elementView";
import { SensorRange } from "../../../../Properties/components/SensorRange/SensorRange";
import { Role } from "../../../../../../types";
import { ROUTES } from "../../../../../../common/constants/Routing";
import { useImagesSensorsState } from "../../../../../../core/context/containers/ImageSensor.container";
import { TripWirePhoto } from "../../../Edit/components/TripWire/TripWirePhoto";
import { useLazyQuery, useQuery } from "@apollo/client";
import GQLService from "../../../../../../core/services/GQL.service";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  marginTop: {
    marginTop: "40px",
  },
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  statusLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    margin: "0 16px",
  },
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 90,
    height: 40,
    borderRadius: 30,
    display: "flex",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.common.dustyGray,
      textDecoration: "none",
    },
  },
  activeIndicator: {
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  activeLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.dustyGray,
    padding: "5px 0",
  },
  switch: {
    color: `${theme.palette.common.switchBlue} !important`,
    border: `1px solid ${theme.palette.common.switchBlue}`,
    borderRadius: "30px;",
    height: 17,
    width: 52,
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `#2F56A1 !important`,
    width: 12,
    height: 12,
  },
  checked: {
    color: "transparent !important",
  },
  base: {
    height: 22,
  },
  noPhoto: {
    height: 300,
    width: 300,
    border: `1px solid ${theme.palette.common.darkGrey}`,
    margin: "10px 0 20px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function TripWireView() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedTripWire } = useTripWireState();
  const history = useHistory();
  const auth = useAuth();
  const { selectedProperty } = usePropertiesState();
  const {
    selectedImagesSensor,
    setSelectedImagesSensor,
  } = useImagesSensorsState();

  const handleEditClick = useCallback(() => {
    history.push(
      ROUTES.TRIPWIRE_EDIT.replace(":uid", selectedProperty?.id!)
        .replace(":imagesensor", selectedImagesSensor?.id.split(":")[1]!)
        .replace(":tripWireid", selectedTripWire?.id.split(":")[1]!)
    );
  }, [
    history,
    selectedImagesSensor?.id,
    selectedProperty?.id,
    selectedTripWire?.id,
  ]);

  const createEditButton = useCallback(() => {
    return (
      <Button className={classes.editBtn} onClick={handleEditClick}>
        Edit
      </Button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useQuery(GQLService.queries.cameraById, {
    variables: { cameraId: selectedImagesSensor?.id! },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      setSelectedImagesSensor(data?.cameraById!);
    }
  }, [data]);

  return (
    <Grid container={true} direction="row" alignItems="center">
      <PageHeader
        title={formatMessage({
          id: "viewTripWire.header",
          defaultMessage: `${
            selectedTripWire?.name
              ? selectedTripWire?.name
              : selectedTripWire?.tripWire
          }`,
          description: "view tripWire label",
        })}
      />
      <Grid
        sm={12}
        md={12}
        container={true}
        justify="space-between"
        alignItems="center"
      >
        <ElementView
          content={
            selectedTripWire?.tripWire ? selectedTripWire?.tripWire : "-"
          }
          name={"TripWire Id"}
        />
        <ElementView
          content={selectedTripWire?.name ? selectedTripWire?.name : "-"}
          name={"TripWire Name"}
        />
        <ElementView
          content={selectedTripWire?.area ? selectedTripWire?.area : "-"}
          name={"Covered Length (ft.)"}
        />
        <ElementView
          content={
            selectedTripWire?.flowType ? selectedTripWire?.flowType : "-"
          }
          name={"Flow"}
        />
      </Grid>
      {(selectedTripWire?.flowType.toUpperCase() === "IN" ||
        selectedTripWire?.flowType.toUpperCase() === "BOTH") && (
        <Grid item xs={12}>
          <Grid container item xs={12} className={classes.marginTop}>
            <Typography className={classes.blackLabel}>
              {formatMessage({
                id: "viewTripWire.inOccupancyThreshold",
                defaultMessage: "In Occupancy Threshold",
                description: "In Occupancy Threshold indicators",
              })}
            </Typography>
          </Grid>
          <SensorRange
            min={0}
            max={selectedTripWire?.inOccupancyMaxThreShold!}
            range={[
              0,
              selectedTripWire?.inOccupancyMediumMinThreShold!,
              selectedTripWire?.inOccupancyMediumMaxThreShold!,
              selectedTripWire?.inOccupancyMaxThreShold!,
            ]}
            indicators={["HEALTHY", "MODERATE", "POOR"]}
            disabled
          />
        </Grid>
      )}
      {(selectedTripWire?.flowType.toUpperCase() === "OUT" ||
        selectedTripWire?.flowType.toUpperCase() === "BOTH") && (
        <Grid item xs={12}>
          <Grid container item xs={12} className={classes.marginTop}>
            <Typography className={classes.blackLabel}>
              {formatMessage({
                id: "viewTripWire.outOccupancyThreshold",
                defaultMessage: "Out Occupancy Threshold",
                description: "Out Occupancy Threshold indicators",
              })}
            </Typography>
          </Grid>
          <SensorRange
            min={0}
            max={selectedTripWire?.outOccupancyMaxThreShold!}
            range={[
              0,
              selectedTripWire?.outOccupancyMediumMinThreShold!,
              selectedTripWire?.outOccupancyMediumMaxThreShold!,
              selectedTripWire?.outOccupancyMaxThreShold!,
            ]}
            indicators={["HEALTHY", "MODERATE", "POOR"]}
            disabled
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container item xs={12} className={classes.marginTop}>
          <Typography className={classes.blackLabel}>
            {formatMessage({
              id: "viewTripWire.TripWireView",
              defaultMessage: "TripWire View",
              description: "tripWire View",
            })}
          </Typography>
        </Grid>
        {selectedTripWire?.imageKey ? (
          <TripWirePhoto
            name={"imageKey"}
            label={"tripWirePhoto"}
            tripWire={selectedTripWire}
            currentUrl={selectedTripWire?.imageKey}
            disabled={true}
          />
        ) : (
          <div className={classes.noPhoto}>
            <span>No Image Attached</span>
          </div>
        )}
      </Grid>
      <Grid item md={6} className={classes.activeIndicator}>
        <Typography className={classes.activeLabel}>Status</Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Switch
            disabled={true}
            name={"Status"}
            checked={selectedTripWire?.active}
            classes={{
              root: classes.switch,
              checked: classes.checked,
              thumb: classes.thumb,
              track: classes.track,
              switchBase: classes.base,
            }}
          />
          <Typography className={classes.statusLabel}>
            {selectedTripWire?.active
              ? formatMessage({
                  id: "statusIndicator.active",
                  defaultMessage: "Active",
                  description: "status indicator active",
                })
              : formatMessage({
                  id: "statusIndicator.inactive",
                  defaultMessage: "Inactive",
                  description: "status indicator inactive",
                })}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.marginTop}>
        {auth?.canSee(
          auth?.user?.role?.toUpperCase() as Role,
          "tripWire:edit",
          createEditButton()
        )}
      </Grid>
    </Grid>
  );
}
