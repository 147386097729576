import { SensorDetailsAirQualityForFloor } from "./airQuality";
import { User } from "./auth";

export type Property = {
  accountBusinessEntity?: string;
  // accountPropertyId?: string;
  // accountStatus: boolean;
  companyAccountNumber?: string;
  ownerCompanyName: string;
  companyStatus: boolean;
  companyWebsite: string;
  primaryContactEmailAddress?: string;
  primaryContactFullName?: string;
  primaryContactPhoneNumber?: string;
  siteName: string;
  siteNrOfFloors: number;
  sitePropertyType: string;
  siteServiceAddress?: string;
  spaceLoadMediumMax: number;
  spaceLoadMediumMin: number;
  id: string;
  imageKey: string;
  logoKey: string;
  workingFrom: string;
  workingTo: string;
  timeZone: string;
  timeZoneName: string;
  ntopngDatabaseName: string;
  floor0Excluded: boolean;
  // minimalProbesCount: number;
  schedulingSlotMinutes: number;
  passGracePeriodMinutes: number;
  passExpirationGracePeriodMinutes: number;
  maxSchedulability: number;
  maxAheadTimeOfReservationHours: number;
  reservationConfigurations: [
    {
      schedulingSlotMinutes: number;
      passGracePeriodMinutes: number;
      passExpirationGracePeriodMinutes: number;
      maxSchedulability: number;
      maxAheadTimeOfReservationHours: number;
      maxReservationsPerUser: number;
    }
  ];
  upNextConfigurations: UpNextConfigurations;
  sensorConfigurations: SensorConfiguration[];
  upNext: boolean;
  dateCreated: Date;
  siteNrOfUndergroundFloors: number;
  propertyDateFormat: PropertyDateFormat;
  use24HourClock: boolean;
};

export type UpNextConfigurations = {
  dateFormat: PropertyDateFormat;
  healthSurveyFrequency: HealthSurveyFrequency;
  use24HourClock: boolean;
  surveyActive: boolean;
};

export type SensorConfiguration = {
  attribute: string;
  isCelsius: boolean;
  maxVal: number;
  minVal: number;
  sensorHealthIndicator: string;
};

export type Floor = {
  id: string;
  name: string;
  floorNumber: number;
  floorPlanUploaded: boolean;
  accessPoints?: any[];
  accessPointsRadius?: number;
  capacity: number;
  floorPlanUrl?: any;
  active: boolean;
  signalStrength: number;
  floorConfigured: boolean;
  smplrId?: string;
};

export type Space = {
  id: string;
  name: string;
  floor: Floor;
  area: number;
  airSensors: any[];
  accessPoints: AccessPoint[];
  zones: Zone[];
  tripWires: TripWire[];
  doors: Door[];
  active: boolean;
  placement?: { [key: string]: string | number };
  isNetflowActive: boolean;
  resetTime: string;
  commonSpace?: commonSpace;
  ssids?: SSID[];
  commonSpaceId?: string | null;
};

export type commonSpace = {
  id: string;
  name: string;
};

export type Door = {
  id: string;
  name: string;
  floorId: string;
  hourFrom: number;
  hourTo: number;
  doorAccessControlVendorId: string;
  availableDays: any[];
  active: boolean;
  workingFrom: string;
  workingTo: string;
  floorNumber: number;
  floorName: string;
  groups: Group[];
  coordinateX?: number | "null";
  coordinateY?: number | "null";
  doorUuid: string;
};

export type FloorIndex = {
  name: string;
  floorNumber: number;
  floorPlanUrl?: string;
  capacity: number;
};

export type SSID = {
  id: string;
  name: string;
  propertyId: string;
  description: string;
  authenticationType: string;
  active: boolean;
  vlanId: string;
};

export type ImagesSensor = {
  id: string;
  active: boolean;
  type: string;
  name: string;
  coordinateX?: number;
  coordinateY?: number;
  floor: Floor;
  cameraUuid: string;
  description: string;
  lastConnectionTime: string;
  zones: Zone[];
  tripWires: TripWire[];
};

export enum dataType {
  CENSUS = "CENSUS",
  CENSUS_ACCUMULATION = "CENSUS_ACCUMULATION",
  BOTH = "BOTH",
}

export const dataTypeMap = new Map()
  .set(dataType.CENSUS, "Census")
  .set(dataType.CENSUS_ACCUMULATION, "Census Accumulation")
  .set(dataType.BOTH, "Both");

export type Zone = {
  id: string;
  name: string;
  zone: string;
  area: string;
  active: boolean;
  dataType: dataType;
  imageKey?: string | null;
  occupancyMediumMinThreShold: number;
  occupancyMediumMaxThreShold: number;
  offlineFromInMinutes: number;
  placement?: { [key: string]: string | number };
  spaces?: any;
  censusOccupancyMediumMinThreShold: number;
  censusOccupancyMediumMaxThreShold: number;
  censusOccupancyMaxThreShold: number;
  censusAccOccupancyMediumMinThreShold: number;
  censusAccOccupancyMediumMaxThreShold: number;
  censusAccOccupancyMaxThreShold: number;
  dwellTimeMinThreshold: number;
  dwellTimeMediumThreshold: number;
  dwellTimeMaxThreshold: number;
};

export enum flowType {
  IN = "IN",
  OUT = "OUT",
  BOTH = "BOTH",
}

export const flowTypeMap = new Map()
  .set(flowType.IN, "In")
  .set(flowType.OUT, "Out")
  .set(flowType.BOTH, "Both");

export type TripWire = {
  id: string;
  name: string;
  tripWire: string;
  area: number;
  active: boolean;
  flowType: flowType;
  imageKey?: string | null;
  inOccupancyMediumMinThreShold: number;
  inOccupancyMediumMaxThreShold: number;
  inOccupancyMaxThreShold: number;
  outOccupancyMediumMinThreShold: number;
  outOccupancyMediumMaxThreShold: number;
  outOccupancyMaxThreShold: number;
  offlineFromInMinutes: number;
  placement?: { [key: string]: string | number };
  spaces?: any;
};

export type Group = {
  id: string;
  name: string;
  active: boolean;
  startDate: Date;
  lastDate: Date;
  dateCreated: Date;
  users: User[];
};

export type AccessPoint = {
  active: boolean;
  baseMacAddress: string;
  coordinateX: number | "null";
  coordinateY: number | "null";
  dataCenter: string;
  deviceType: string;
  id: string;
  ipAddress: string;
  name: string;
  serialNumber: string;
  floorId: string;
  radio1: Radio;
  radio2: Radio;
  notes: string;
  modelNumber: string;
  inventoryNumber: number | "";
  offlineFromInMinutes: number;
};

export type Radio = {
  id: string;
  accessPointId: string;
  band: RadioBand;
  macAddress: string;
  radioId: string;
  ssids?: string[];
  ssidsToAdd?: string[];
  ssidsToDelete?: string[];
  txPower?: number;
  channel?: string;
  channelWidth?: string;
};

export type spaceLoad =
  | "HIGH"
  | "LOW"
  | "MEDIUM"
  | "NOT_CONFIGURED"
  | "OFFLINE";

export type timeRangeType =
  | "DAY"
  | "FIVE_MINUTES"
  | "HOUR"
  | "MINUTE"
  | "QUARTER"
  | "WEEK";

export type Timeline = {
  from: string;
  to: string;
  spaceLoad: spaceLoad;
};

export type AccessPointWithSpaceLoadTimeline = {
  accessPoint: AccessPoint;
  timeline: Timeline[];
  active?: boolean;
  timeRangeType: timeRangeType;
  isPinned?: boolean;
};

export type AccessPointWithSpaceLoad = {
  accessPoint: AccessPoint;
  spaceLoad: spaceLoad;
  active?: boolean;
  applicationUsage: any[];
  isPinned?: boolean;
};

export type DensityData = {
  accessPointName: string;
  densityTimeline: {
    connectedDevicesCount: number;
    from: string;
    to: string;
  }[];
  highDensityTime: number;
  lowDensityTime: number;
  mediumDensityTime: number;
  timeRangeType: string;
  averageDwellTime?: number;
};

export type ImageDensityData = {
  cameraOccupacyTimeline: {
    connectedPeopleCount: number;
    from: string;
    to: string;
  }[];
  highDensityTime: number;
  lowDensityTime: number;
  mediumDensityTime: number;
  timeRangeType: string;
  occupancyMediumMinThreShold: number;
  occupancyMediumMaxThreShold: number;
  dataType: string;
  censusOccupancyMediumMinThreShold: number;
  censusOccupancyMediumMaxThreShold: number;
  censusOccupancyMaxThreShold: number;
  censusAccOccupancyMediumMinThreShold: number;
  censusAccOccupancyMediumMaxThreShold: number;
  censusAccOccupancyMaxThreShold: number;
  dwellTimeMinThreshold: number;
  dwellTimeMediumThreshold: number;
  dwellTimeMaxThreshold: number;
  name: string;
  dwellTime: number;
  censusOccupacyTimeline: {
    connectedPeopleCount: number;
    from: string;
    to: string;
  }[];
  censusAccOccupacyTimeline: {
    connectedPeopleCount: number;
    from: string;
    to: string;
  }[];
  dwellTimeTimeLine: {
    connectedPeopleCount: number;
    from: string;
    to: string;
  }[];
  lowDensityTimeForCensus: number;
  mediumDensityTimeForCensus: number;
  highDensityTimeForCensus: number;
  lowDensityTimeForCensusAcc: number;
  mediumDensityTimeForCensusAcc: number;
  highDensityTimeForCensusAcc: number;
  lowDensityTimeForDwellTimeAcc: number;
  mediumDensityTimeForDwellTimeAcc: number;
  highDensityTimeForDwellTimeAcc: number;
  isPinned: boolean;
};

export type ImageDensityDataTripWire = {
  cameraOccupacyTimelineFlowIN: {
    connectedPeopleCount: number;
    from: string;
    to: string;
  }[];
  cameraOccupacyTimelineFlowOUT: {
    connectedPeopleCount: number;
    from: string;
    to: string;
  }[];
  highDensityTime: number;
  lowDensityTime: number;
  mediumDensityTime: number;
  timeRangeType: string;
  inOccupancyMediumMinThreShold: number;
  inOccupancyMediumMaxThreShold: number;
  inOccupancyMaxThreShold: number;
  outOccupancyMediumMinThreShold: number;
  outOccupancyMediumMaxThreShold: number;
  outOccupancyMaxThreShold: number;
  flowType: string;
  name: string;
  tripWire: string;
  lowDensityTimeForIn: number;
  mediumDensityTimeForIn: number;
  highDensityTimeForIn: number;
  lowDensityTimeForOut: number;
  mediumDensityTimeForOut: number;
  highDensityTimeForOut: number;
  isPinned: boolean;
};

export type DashboardCardAverageAndCount = {
  apCount: number;
  apDensity: number;
  isAPPinned: boolean;
};

export type DashboardCardAverageAndCountTripwire = {
  tripwireCount: number;
  startCount: number;
  tripwireIn: number;
  tripwireOut: number;
  endCount: number;
  isNetflowActive: boolean;
  isTripwirePinned: boolean;
  netFlowSpacesCount: number;
  cameraCount: number;
};
export type DashboardCardAverageAndCountZone = {
  zoneCount: number;
  zoneCensus: number;
  isZonePinned: boolean;
  cameraCount: number;
};

export enum RadioBand {
  NONE = "NONE",
  G2_4 = "G2_4",
  G5 = "G5",
}

export enum Frequency {
  G5 = "5 GHz",
  G2_4 = "2.4 GHz",
  NONE = "",
}
export const radioBandMap = new Map()
  .set(RadioBand.NONE, "none")
  .set(RadioBand.G2_4, "2.4 GHz")
  .set(RadioBand.G5, "5 GHz");

export enum PropertyDateFormat {
  US = "US",
  INTERNATIONAL = "INTERNATIONAL",
}

export const propertyDateFormatMap = new Map()
  .set(PropertyDateFormat.US, "US (Jan-31-2021)")
  .set(PropertyDateFormat.INTERNATIONAL, "International (31.1.2021)");

export enum HealthSurveyFrequency {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export const HEALTHSURVEYFREQUENCYMAP = new Map()
  .set(HealthSurveyFrequency.DAILY, "DAILY")
  .set(HealthSurveyFrequency.WEEKLY, "WEEKLY")
  .set(HealthSurveyFrequency.MONTHLY, "MONTHLY");

export enum UserFilterPreferenceType {
  DASHBOARD = "DASHBOARD",
  FLOOR = "FLOOR",
}

export enum DashboardDeviceType {
  ACCESSPOINT = "ACCESSPOINT",
  AIRQUALITY = "AIRQUALITY",
  CAMERA = "CAMERA",
  DOOR = "DOOR",
  ENCLOSED_SPACES = "ENCLOSED_SPACES",
  TRIPWIRE = "TRIPWIRE",
  ZONE = "ZONE",
  DASHBOARD_ACCESSPOINT = "DASHBOARD_ACCESSPOINT",
  DASHBOARD_AIRQUALITY = "DASHBOARD_AIRQUALITY",
  DASHBOARD_DOOR = "DASHBOARD_DOOR",
  DASHBOARD_TRIPWIRE = "DASHBOARD_TRIPWIRE",
  DASHBOARD_ZONE = "DASHBOARD_ZONE",
}

export enum DashboardTimeRangeType {
  DAY = "DAY",
  FIVE_MINUTES = "FIVE_MINUTES",
  HOUR = "HOUR",
  MINUTE = "MINUTE",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  WEEK = "WEEK",
}

export enum Band {
  NONE = "NONE",
  G2_4 = "G2_4",
  G5 = "G5",
}

export type PortFolio = {
  apCount: number;
  apDensity: number;
  spaceLoad: spaceLoad;
  aqCount: number;
  aqDensity: number;
  spaceAirQuality: string;
  property: Property;
};

export type PortFolioOccupancy = {
  apCount: number;
  apDensity: number;
  spaceLoad: spaceLoad;
  property: Property;
};

export type PortFolioAirQuality = {
  aqCount: number;
  aqDensity: number;
  spaceAirQuality: string;
  property: Property;
};
