import React from "react";
import { Grid, Typography } from "@material-ui/core";

export function EmptyComponent() {
  return (
    <Grid>
      <Grid>
        <Typography></Typography>
      </Grid>
    </Grid>
  );
}
