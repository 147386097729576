import React, { useCallback } from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import { Property, Role } from "../../../../../../types";
import { CompanyInfo } from "./components/CompanyInfo";
import { PrimaryContact } from "./components/PrimaryContact";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../common/constants/Routing";
import { useAuth } from "../../../../../../core/context/containers/Auth.container";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  button: {
    backgroundColor: theme.palette.common.matisse,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: 115,
    height: 42,
    alignSelf: "flex-start",
  },
}));

interface BusinessEntityProps {
  property: Property;
}

export function BusinessEntity({ property }: BusinessEntityProps) {
  const classes = useStyles();
  const history = useHistory();

  const auth = useAuth();

  const handleEditClick = () => {
    history.push(ROUTES.EDIT_PROPERTY.replace(":uid", property.id) + "#0");
  };

  const createPropertyButton = useCallback(() => {
    return (
      <Button className={classes.button} onClick={handleEditClick}>
        Update
      </Button>
    );
  }, []);

  return (
    <Grid className={classes.container} container={true}>
      <Grid item={true} md={6} sm={12}>
        <CompanyInfo property={property} />
      </Grid>
      <Grid item={true} md={6} sm={12}>
        {/* <PrimaryContact property={property} /> */}
      </Grid>
      {auth?.canSee(
        auth?.user?.role?.toUpperCase() as Role,
        "properties:edit",
        createPropertyButton()
      )}
    </Grid>
  );
}
