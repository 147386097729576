import { gql } from "@apollo/client";

export const SPACE_MUTATIONS = {
  createSpace: gql`
    mutation createSpace(
      $active: Boolean!
      $name: String!
      $area: Int
      $propertyId: String!
      $floorId: String!
      $accessPoints: [String]
      $airSensors: [String]
      $zones: [String]
      $tripWires: [String]
      $doors: [String]
      $isNetflowActive: Boolean
      $resetTime: String
      $commonSpaceId: String
      $ssids: [String]
    ) {
      createSpace(
        request: {
          active: $active
          name: $name
          area: $area
          propertyId: $propertyId
          floorId: $floorId
          accessPoints: $accessPoints
          airSensors: $airSensors
          zones: $zones
          tripWires: $tripWires
          doors: $doors
          isNetflowActive: $isNetflowActive
          resetTime: $resetTime
          commonSpaceId: $commonSpaceId
          ssids: $ssids
        }
      ) {
        id
      }
    }
  `,
  updateSpace: gql`
    mutation updateSpace(
      $id: String!
      $active: Boolean
      $name: String
      $area: Int
      $floorId: String
      $placement: ObjectScalar
      $accessPoints: [String]
      $airSensors: [String]
      $zones: [String]
      $tripWires: [String]
      $doors: [String]
      $isNetflowActive: Boolean
      $resetTime: String
      $commonSpaceId: String
      $ssids: [String]
    ) {
      updateSpace(
        request: {
          id: $id
          active: $active
          name: $name
          area: $area
          floorId: $floorId
          placement: $placement
          accessPoints: $accessPoints
          airSensors: $airSensors
          zones: $zones
          tripWires: $tripWires
          doors: $doors
          isNetflowActive: $isNetflowActive
          resetTime: $resetTime
          commonSpaceId: $commonSpaceId
          ssids: $ssids
        }
      )
    }
  `,
  updateSpaceCoordinates: gql`
    mutation updateSpaceCoordinates($id: String!, $placement: ObjectScalar!) {
      updateSpaceCoordinates(request: { id: $id, placement: $placement })
    }
  `,
  deleteSpace: gql`
    mutation deleteSpace($id: String!) {
      deleteSpace(request: { id: $id }) {
        msg
      }
    }
  `,
};

export const SPACE_QUERIES = {
  spaces: gql`
    query spaces($propertyId: String, $floorId: String) {
      spaces(propertyId: $propertyId, floorId: $floorId) {
        id
        name
        area
        floor {
          id
          name
          floorNumber
        }
        active
        placement
        isNetflowActive
        commonSpace {
          id
          name
        }
      }
    }
  `,
  spaceById: gql`
    query ($spaceId: String!) {
      spaceById(spaceId: $spaceId) {
        id
        name
        area
        active
        isNetflowActive
        resetTime
        commonSpace {
          id
          name
        }
        floor {
          id
          name
          floorNumber
        }
        accessPoints {
          active
          baseMacAddress
          coordinateX
          coordinateY
          dataCenter
          deviceType
          floorId
          id
          inventoryNumber
          ipAddress
          modelNumber
          name
          notes
          serialNumber
          radio1 {
            id
            macAddress
            band
            channel
            channelWidth
            txPower
            ssids {
              id
              name
              active
            }
          }
          radio2 {
            macAddress
            band
            channel
            channelWidth
            id
            txPower
            ssids {
              id
              name
              active
            }
          }
        }
        airSensors {
          id
          name
        }
        zones {
          id
          name
          zone
        }
        tripWires {
          id
          name
          tripWire
        }
        doors {
          id
          name
        }
        ssids {
          id
          name
          active
        }
        placement
      }
    }
  `,
  commonSpaces: gql`
    query allCommonSpace {
      allCommonSpace {
        id
        name
      }
    }
  `,
  portFolioOccupancyByCommonSpaceId: gql`
    query occupancyByCommonSpaceId($request: TimeRangedPortfolioRequestInput) {
      occupancyByCommonSpaceId(request: $request) {
        apCount
        apDensity
        spaceLoad
        property {
          id
          siteName
        }
      }
    }
  `,
  portFolioAirQualityByCommonSpaceId: gql`
    query airQualityByCommonSpaceId($request: TimeRangedPortfolioRequestInput) {
      airQualityByCommonSpaceId(request: $request) {
        aqCount
        aqDensity
        spaceAirQuality
        property {
          id
          siteName
        }
      }
    }
  `,
};
