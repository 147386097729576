import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { FunctionComponent } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Input,
  InputLabel,
} from "@material-ui/core";
import clsx from "clsx";
import { useIntl } from "react-intl";

import { SensorHealthIndicator } from "../../../../../types";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const useStyles = makeStyles((theme) => ({
  sensorLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  airSensorContainer: {
    border: "1px solid #E0EBF1",
    padding: "16px 20px",
    alignItems: "center",
  },
  verticalSpaing: {
    margin: "4px 0",
  },
  sliderWrapper: {
    width: "700px",
    "& .rc-slider": {
      padding: 0,
    },
    "& .rc-slider-rail": {
      height: 30,
      border: "1px solid rgb(129, 211, 224)",
      backgroundColor: "transparent",
    },
    "& .rc-slider-tooltip-inner": {
      backgroundColor: "transparent !important",
      color: "#464646",
      boxShadow: "none",
      fontSize: "14px",
    },

    "& .rc-slider-handle.rc-slider-handle-dragging": {
      boxShadow: "0 0 0 5px #464646",
    },

    "& .rc-slider-handle": {
      top: "5px",
    },

    "& .rc-slider-disabled": {
      backgroundColor: "transparent",
      minHeight: "30px",
      padding: "0",
    },
  },
  input: {
    height: 40,
    width: 100,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    fontSize: "14px",
  },
  pos: {
    paddingLeft: 10,
    marginLeft: "755px",
    position: "absolute",
    marginTop: "-60px",
  },
  inputLabel: {
    margin: "20px 0 10px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
  },
  errorLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.cinnabar,
    marginLeft: -10,
  },
}));

export interface SensorRangeProps {
  range: number[];
  indicators: SensorHealthIndicator[];
  disabled?: boolean;
  min: number;
  max: number;
  maxLabel?: string;
  hideMinHandle?: boolean;
  className?: string;
  onChange?: (value: number[]) => void;
  onMaxLabelChange?: (e: any) => void;
  minValue?: number;
}
const HandleColor = {
  HEALTHY: "rgb(79, 158, 83)",
  MODERATE: "rgb(238, 170, 70)",
  POOR: "rgb(176, 0, 31)",
  NONE: "rgb(129, 211, 224)",
};

const TrackColor = {
  HEALTHY: "rgba(79, 158, 83, 0.15)",
  MODERATE: "rgba(238, 170, 70, 0.2)",
  POOR: "rgba(176, 0, 31, 0.2)",
  NONE: "rgba(129, 211, 224, 0.2)",
};

export const DEFAULT_SENSOR_RANGES = {
  OCCUPANCY: {
    range: [0, 5, 10, 50],
    min: 0,
    max: 50,
    indicators: ["HEALTHY", "MODERATE", "POOR"],
  },
  TEMPERATURE: {
    range: [0, 54.7, 65.5, 79.9, 88.7, 200],
    min: 0,
    max: 200,
    indicators: ["POOR", "MODERATE", "HEALTHY", "MODERATE", "POOR"],
  },
  HUMIDITY: {
    range: [0, 15.5, 39.5, 50.5, 80.5, 100],
    min: 0,
    max: 100,
    indicators: ["POOR", "MODERATE", "HEALTHY", "MODERATE", "POOR"],
  },
  CO2: {
    range: [400, 600.5, 2500.5, 5000],
    min: 400,
    max: 5000,
    indicators: ["HEALTHY", "MODERATE", "POOR"],
  },
  TVOCS: {
    range: [0, 333.5, 8332.5, 12000],
    min: 0,
    max: 12000,
    indicators: ["HEALTHY", "MODERATE", "POOR"],
  },
  PM: {
    range: [0, 15.5, 75.5, 200],
    min: 0,
    max: 200,
    indicators: ["HEALTHY", "MODERATE", "POOR"],
  },
  LIGHT: {
    range: [0, 100, 200, 1000, 2000, 2200],
    min: 0,
    max: 2200,
    indicators: ["POOR", "MODERATE", "HEALTHY", "MODERATE", "POOR"],
  },
  NOISE: {
    range: [0, 60, 85, 200],
    min: 0,
    max: 200,
    indicators: ["HEALTHY", "MODERATE", "POOR"],
  },
} as {
  [key: string]: {
    range: number[];
    min: number;
    max: number;
    indicators: SensorHealthIndicator[];
  };
};
export const SensorRange = ({
  range,
  min,
  max,
  maxLabel,
  hideMinHandle = false,
  indicators,
  disabled,
  onChange,
  onMaxLabelChange,
  className,
  minValue = 5,
}: SensorRangeProps) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const handleStyles: React.CSSProperties[] = indicators.map(
    (indicator, index) => {
      if (hideMinHandle && index === 0) {
        return {
          backgroundColor: "transparent",
          height: "30px",
          width: 0,
          border: "none",
        };
      } else {
        return {
          backgroundColor: HandleColor[indicator],
          height: "30px",
          width: "6px",
          borderRadius: "4px",
          border: "none",
        };
      }
    }
  );
  handleStyles.push({
    backgroundColor: "transparent",
    height: "30px",
    width: 0,
    border: "none",
  });
  return (
    <div className={clsx(classes.sliderWrapper, className)}>
      {range ? (
        <>
          <Range
            count={range ? range.length - 1 : 0}
            min={min}
            max={max}
            value={range} // 0-5, 5-10, 10-15
            pushable
            trackStyle={indicators.map((indicator) => ({
              backgroundColor: TrackColor[indicator],
              height: "30px",
              borderRadius: "0",
            }))}
            handleStyle={handleStyles}
            tipProps={{ placement: "right", visible: true }}
            disabled={disabled}
            onChange={onChange}
          />
          {onMaxLabelChange && (
            <div className={classes.pos}>
              <InputLabel className={classes.inputLabel}>
                {formatMessage({
                  id: "SensorRange.label.maxThreshold",
                  defaultMessage: "Max Range",
                  description: "Max Threshold label for threshold",
                })}
              </InputLabel>
              <Input
                className={classes.input}
                disableUnderline={true}
                fullWidth={false}
                name={"MaxLabel"}
                type={"input"}
                onChange={onMaxLabelChange}
                defaultValue={maxLabel}
              />
              {!maxLabel && maxLabel !== undefined && parseInt(maxLabel) !== 0 && (
                <span className={classes.errorLabel}>
                  <br />
                  {formatMessage({
                    id: "SensorRange.validation.shouldNumber",
                    defaultMessage: "Should be number",
                    description: "should be numbers ",
                  })}
                </span>
              )}
              {maxLabel !== undefined &&
                !isNaN(parseInt(maxLabel)) &&
                parseInt(maxLabel) < minValue! && (
                  <span className={classes.errorLabel}>
                    <br />
                    {formatMessage({
                      id: "SensorRange.validation.shouldGreaterThan5",
                      defaultMessage: `Should not be less than ${minValue}`,
                      description: `should be greater than ${minValue}`,
                    })}
                  </span>
                )}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export const SensorGrid: FunctionComponent<{
  label: string;
  range: number[];
  indicators: SensorHealthIndicator[];
  disabled?: boolean;
  hideMinHandle?: boolean;
  min: number;
  max: number;
  onChange?: (value: number[]) => void;
}> = ({
  label,
  min,
  max,
  range,
  indicators,
  hideMinHandle,
  disabled,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={clsx(classes.airSensorContainer, classes.verticalSpaing)}
      container
      item
      xs={12}
    >
      <Grid item md={3}>
        <Typography className={classes.sensorLabel}>{label}</Typography>
      </Grid>
      <Grid item md={9}>
        <Grid item>
          <SensorRange
            range={range}
            indicators={indicators}
            disabled={disabled}
            hideMinHandle={hideMinHandle}
            onChange={onChange}
            min={min}
            max={max}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
