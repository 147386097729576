import React, { useCallback, useMemo, useState } from "react";
import { useFormikContext, FieldInputProps, FormikState } from "formik";
import {
  Grid,
  Select as SelectUI,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./HourSelect.styles";
import { MenuItem } from "@material-ui/core";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { useFormWizardState } from "../../../../../core/context/containers/FormWizard.container";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import "moment-timezone";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
interface WorkingHours {
  workingFrom: string;
  workingTo: string;
  timeZoneName: string;
  timeZone: string;
}

interface SelectProps extends FieldInputProps<any> {
  defaultValue: boolean;
  disabled?: boolean;
  data?: WorkingHours;
  placeholder: string;
}

export function HourSelectTZ({
  name,
  defaultValue = false,
  disabled = false,
  onChange,
  placeholder,
  data,
}: SelectProps) {
  const classes = useStyles();
  const [value, setValue] = useState<string | null>(null);
  //const timeZoneOptions: timeZoneArray[] = moment.tz.names().map((i) => ({ title: i, name: i }));
  const timeZoneOptions: string[] = moment.tz.names();
  const { formatMessage } = useIntl();
  const { formData, setFormData } = useFormWizardState();
  const { errors, values, initialValues, isSubmitting } = useFormikContext<
    FormikState<{ [key: string]: string }>
  >();

  const [selectValueFrom, setSelectValueFrom] = useState(
    values["workingFrom" as keyof typeof values] ||
      data?.workingFrom ||
      placeholder
  );
  const [selectValueTo, setSelectValueTo] = useState(
    values["workingTo" as keyof typeof values] || data?.workingTo || placeholder
  );
  const [selectValueTimezone, setSelectValueTimezone] = useState(
    values["timeZone" as keyof typeof values] || data?.timeZone || placeholder
  );

  const [selectValueTimezoneName, setSelectValueTimezoneName] = useState(
    values["timeZoneName" as keyof typeof values] || data?.timeZoneName
  );

  const isTouched =
    isSubmitting ||
    values[name as keyof typeof values] !==
      initialValues[name as keyof typeof values];

  const handleSelectOptClickFrom = useCallback(
    (e) => {
      setFormData({ ...formData, workingFrom: e.target.value });
      setSelectValueFrom(e.target.value);
      onChange({
        ...e,
        target: { name: "workingFrom", value: e.target.value },
      });
    },
    [selectValueFrom, formData] // eslint-disable-line
  );
  const handleSelectOptClickTo = useCallback(
    (e) => {
      setFormData({ ...formData, workingTo: e.target.value });
      setSelectValueTo(e.target.value);
      onChange({
        ...e,
        target: { name: "workingTo", value: e.target.value },
      });
    },
    [selectValueTo, formData] // eslint-disable-line
  );
  const handleSelectOptClickTimezoneName = useCallback(
    (e, value: string) => {
      if (value !== null) {
        setFormData({
          ...formData,
          timeZoneName: value,
          timeZone: moment.tz(value).zoneAbbr(),
        });
        setSelectValueTimezoneName(value);
        onChange({
          ...e,
          target: { name: "timeZoneName", value: value },
        });
        onChange({
          ...e,
          target: { name: "timeZone", value: moment.tz(value).zoneAbbr() },
        });
      } else {
        delete formData["timeZoneName"];
        delete formData["timeZone"];
        setFormData({
          ...formData,
          timeZoneName: value,
          timeZone: null,
        });
        setSelectValueTimezoneName(value);
        onChange({
          ...e,
          target: { name: "timeZoneName", value: value },
        });
        onChange({
          ...e,
          target: { name: "timeZone", value: null },
        });
      }
    },
    [selectValueTimezoneName, formData] // eslint-disable-line
  );

  const timeValues: any[] = [];

  useMemo(() => {
    for (let i = 0; i < 24; i++) {
      const label =
        i === 0
          ? `12:00 AM`
          : i < 12
          ? `${i}:00 AM`
          : i === 12
          ? `12:00 PM`
          : `${i - 12}:00 PM`;
      timeValues.push(label);
    }
  }, [timeValues]);

  return (
    <Grid>
      <Tooltip
        enterNextDelay={500}
        placement="right"
        title={
          <div>
            <Typography className={classes.toolTipContent}>
              Off hours will start after the working hours{" "}
            </Typography>
          </div>
        }
        classes={{ tooltip: classes.toolTipDiv }}
        className={classes.alignment}
      >
        <InfoOutlinedIcon className={classes.infoIcon} />
      </Tooltip>
      <Grid
        key={name}
        container={true}
        item={true}
        direction="row"
        alignItems="center"
        md={8}
      >
        <Grid>
          <SelectUI
            classes={{
              root: `${
                isTouched && errors[name as keyof typeof values]
                  ? classes.error
                  : classes.select
              }`,
              select: classes.blackText,
              icon: classes.icon,
            }}
            MenuProps={{
              classes: {
                paper: classes.menu,
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            IconComponent={ExpandMoreSharpIcon}
            value={selectValueFrom}
            disableUnderline={true}
            onChange={handleSelectOptClickFrom}
            name="workingFrom"
            renderValue={
              selectValueFrom !== placeholder
                ? undefined
                : () => (
                    <Placeholder>
                      {formatMessage({
                        id: "FormFields.HourSelect.Placeholder.From",
                        defaultMessage: "From",
                        description: "Form Fields Hour Select From Placeholder",
                      })}
                    </Placeholder>
                  )
            }
          >
            <MenuItem
              key={-1}
              value={placeholder}
              disabled
              classes={{
                root: classes.option,
                selected: classes.selected,
              }}
            >
              {/* {placeholder} */}
              From
            </MenuItem>
            {timeValues.map((v: any, i) => (
              <MenuItem
                key={i + 1}
                value={i < 10 ? `0${i}:00` : `${i}:00`}
                disabled={false}
                classes={{
                  root: classes.option,
                  selected: classes.selected,
                }}
              >
                {v}
              </MenuItem>
            ))}
          </SelectUI>
        </Grid>
        <Grid>
          <Typography className={classes.dash}> - </Typography>
        </Grid>
        <Grid>
          <SelectUI
            classes={{
              root: `${
                isTouched && errors[name as keyof typeof values]
                  ? classes.error
                  : classes.select
              }`,
              select: classes.blackText,
              icon: classes.icon,
            }}
            MenuProps={{
              classes: {
                paper: classes.menu,
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            IconComponent={ExpandMoreSharpIcon}
            value={selectValueTo}
            disableUnderline={true}
            onChange={handleSelectOptClickTo}
            name="workingTo"
            renderValue={
              selectValueTo !== placeholder
                ? undefined
                : () => (
                    <Placeholder>
                      {formatMessage({
                        id: "FormFields.HourSelect.Placeholder.To",
                        defaultMessage: "To",
                        description: "Form Fields Hour Select To Placeholder",
                      })}
                    </Placeholder>
                  )
            }
          >
            <MenuItem
              key={-1}
              value={placeholder}
              disabled
              classes={{
                root: classes.option,
                selected: classes.selected,
              }}
            >
              {/* {placeholder} */}
              To
            </MenuItem>
            {timeValues.map((v: any, i) => (
              <MenuItem
                key={i + 1}
                value={i < 10 ? `0${i}:00` : `${i}:00`}
                disabled={false}
                classes={{
                  root: classes.option,
                  selected: classes.selected,
                }}
              >
                {v}
              </MenuItem>
            ))}
          </SelectUI>
        </Grid>
        <Grid md={6}>
          {console.log(
            errors[name as keyof typeof values],
            "errors[name as keyof typeof values]"
          )}
          <Autocomplete
            classes={{
              root: `${
                isTouched && errors[name as keyof typeof values]
                  ? classes.error
                  : classes.select
              }`,
              input: classes.inputField,
            }}
            id="combo-box-demo"
            options={timeZoneOptions}
            // getOptionLabel={(option) => option.title}
            value={selectValueTimezoneName}
            onChange={handleSelectOptClickTimezoneName} //(event: any, newValue: string | null) => setValue(newValue)}
            style={{
              width: 35,
              height: 43,
              marginLeft: "16px",
              marginTop: "10px",
              padding: 0,
              border: "none",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Timezone"
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                InputLabelProps={{ required: true }}
                classes={{
                  root: `${
                    isTouched && errors[name as keyof typeof values]
                      ? classes.errorAutoComplete
                      : classes.textField
                  }`,
                }}
              />
            )}
          />
        </Grid>
        {/* <Grid className={classes.status}>
        {selectValueTimezoneName
          ? moment.tz(selectValueTimezoneName).zoneAbbr()
          : ""}
      </Grid> */}
      </Grid>
    </Grid>
  );
}

function Placeholder({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return <div className={classes.placeholder}>{children}</div>;
}
