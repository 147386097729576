import { useLazyQuery } from "@apollo/client";
import {
  Badge,
  IconButton,
  ListSubheader,
  Menu,
  MenuList,
} from "@material-ui/core";
import { NotificationsNone, Notifications } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useUserState } from "../../../core/context/containers/User.container";
import GQLService from "../../../core/services/GQL.service";
import { CurrentNotificationsResponse } from "../../../types/notification";
import { ROUTES } from "../../constants/Routing";
import { useStyles } from "./NotificationDropdown.styles";
import NotificationDropDownItem from "./NotificationDropDownItem";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../constants/DummyProperty";
import { useNotificationsState } from "../../../core/context/containers/Notification.continer";

const MAX_NUMBER_OF_NOTIFICATION = 5;
const POLLING_INTERVAL = 1000 * 60 * 5; // 5 MINS

const NotificationDropdown = () => {
  const classes = useStyles();
  const history = useHistory();
  const { userEmail } = useUserState();

  const [isSeen, setIsSeen] = useState(false);
  const { notificationIsSeen, setNotificationIsSeen } = useNotificationsState();
  const [anchorEl, setAnchorEl] = useState();
  const [isMounted, setIsMounted] = useState(false);
  const [notifications, setNotifications] = useState<
    CurrentNotificationsResponse[]
  >([]);
  const [noOfNotifications, setnoOfNotifications] = useState<number>(0);
  const [fiveNotifications, setFiveNotifications] = useState<
    CurrentNotificationsResponse[]
  >([]);

  const handleToggleNotification = useCallback(
    (event: any) => {
      setIsSeen(true);
      setAnchorEl(anchorEl ? null : event.currentTarget);
      let dummyNotificationSeen: any = {};
      fiveNotifications?.map((item: any) => {
        return (dummyNotificationSeen[item?.id] = item?.id);
      });
      setNotificationIsSeen(dummyNotificationSeen);
    },
    [anchorEl, fiveNotifications, setNotificationIsSeen]
  );
  const open = Boolean(anchorEl);

  const [getAllNotifications, { data, error }] = useLazyQuery(
    GQLService.queries.getAllNotifications,
    {
      fetchPolicy: "cache-and-network",
      pollInterval: POLLING_INTERVAL,
    }
  );

  useEffect(() => {
    setIsMounted(true);
    if (isMounted && userEmail) {
      getAllNotifications({ variables: { email: userEmail } });
    }
    return () => {
      setIsMounted(false);
    };
  }, [userEmail, isMounted, getAllNotifications]);

  useEffect(() => {
    if (data) {
      // Setting only top 5 Responses in Notification.
      let responseNotification = _.cloneDeep(data.currentNotifications).sort(
        (a: CurrentNotificationsResponse, b: CurrentNotificationsResponse) =>
          Number(a.notificationReceivedMinutesAgo) -
          Number(b.notificationReceivedMinutesAgo)
      );
      let top5Notification =
        responseNotification.length > MAX_NUMBER_OF_NOTIFICATION
          ? responseNotification.slice(0, MAX_NUMBER_OF_NOTIFICATION)
          : responseNotification;
      setFiveNotifications(top5Notification);

      if (userEmail === dummyUserEmail) {
        const demoUserNotification = top5Notification.filter(
          (item: any) =>
            item.property.id === DummyPropertyA.id ||
            item.property.id === DummyPropertyB.id ||
            item.property.id === DummyPropertyC.id ||
            item.property.id === DummyPropertyD.id
        );
        setNotifications(demoUserNotification);
      } else {
        setNotifications(top5Notification);
      }
      setnoOfNotifications(data?.currentNotifications?.length);
    }
  }, [data, userEmail]);

  useEffect(() => {
    if (notificationIsSeen !== null || !_.isEmpty(notificationIsSeen)) {
      let notSeenArray = fiveNotifications.filter(
        (item) => !notificationIsSeen[item?.id]
      );
      setnoOfNotifications(notSeenArray?.length);
      if (notSeenArray?.length > 0) {
        setIsSeen(false);
      } else {
        setIsSeen(true);
      }
    }
  }, [fiveNotifications, notificationIsSeen, setNotificationIsSeen]);

  const handleViewAll = (e: any) => {
    handleToggleNotification(e);
    history.push(ROUTES.ALL_NOTIFICATIONS);
  };

  return (
    <>
      <IconButton
        aria-label="Notification"
        className={classes.notificationBell}
        onClick={handleToggleNotification}
      >
        <Badge
          badgeContent={isSeen ? 0 : noOfNotifications}
          color="primary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          max={MAX_NUMBER_OF_NOTIFICATION}
          classes={{ badge: classes.customBadge }}
        >
          {isSeen ? (
            <NotificationsNone fontSize="default" />
          ) : (
            <Notifications fontSize="default" color="primary" />
          )}
        </Badge>
      </IconButton>
      <Menu
        id="notification-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        open={open}
        className={classes.notificationDropDown}
        onClose={handleToggleNotification}
      >
        <MenuList className={classes.notificationList}>
          <ListSubheader className={classes.notificationListHeader}>
            <p className={classes.notificationHeaderTitle}>Notification</p>

            <p
              onClick={(e) => handleViewAll(e)}
              className={classes.notificationHeaderLink}
            >
              View all
            </p>
          </ListSubheader>

          {notifications.length > 0 ? (
            notifications.map(
              (notification: CurrentNotificationsResponse, i_) => {
                return (
                  <NotificationDropDownItem
                    key={i_}
                    notification={notification}
                  />
                );
              }
            )
          ) : (
            <ListSubheader className={classes.notificationListHeader}>
              <p className={classes.notificationPrimaryText}>
                No New Notifications
              </p>
            </ListSubheader>
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export default NotificationDropdown;
