import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import {
  PageHeader,
  LinkButton,
  DataList,
  EmptyListView,
} from "../../../../common/components";
import { ROUTES } from "../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { PropertyListItem } from "./PropertyListItem";
import { useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { Role } from "../../../../types";
import { useSearchState } from "../../../../core/context/containers/Search.container";
import _ from "lodash";
import { useUserState } from "../../../../core/context/containers/User.container";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../../../common/constants/DummyProperty";

export default function Properties() {
  const { formatMessage } = useIntl();
  const {
    searchProperties,
    properties,
    propertiesFromResponse,
    setProperties,
    setPropertiesFromResponse,
    setSelectedProperty,
  } = usePropertiesState();
  const { userEmail } = useUserState();
  const { loading, data } = useQuery(GQLService.queries.allProperties, {
    fetchPolicy: "network-only",
  });
  const auth = useAuth();

  const { searchValue } = useSearchState();

  useEffect(
    () => {
      let isMounted = true;
      if (isMounted && data) {
        if (userEmail === dummyUserEmail) {
          let clonedData = _.cloneDeep(data?.properties);
          const newProperties = clonedData?.map((item: any) => {
            if (item.id === DummyPropertyA.id) {
              const propA = {
                ...item,
                ownerCompanyName: DummyPropertyA.ownerCompanyName,
                siteName: DummyPropertyA.siteName,
                siteServiceAddress: DummyPropertyA.siteServiceAddress,
              };
              return propA;
            } else if (item.id === DummyPropertyB.id) {
              const propB = {
                ...item,
                ownerCompanyName: DummyPropertyB.ownerCompanyName,
                siteName: DummyPropertyB.siteName,
                siteServiceAddress: DummyPropertyB.siteServiceAddress,
              };
              return propB;
            } else if (item.id === DummyPropertyC.id) {
              const propC = {
                ...item,
                ownerCompanyName: DummyPropertyC.ownerCompanyName,
                siteName: DummyPropertyC.siteName,
                siteServiceAddress: DummyPropertyC.siteServiceAddress,
              };
              return propC;
            } else if (item.id === DummyPropertyD.id) {
              const propD = {
                ...item,
                ownerCompanyName: DummyPropertyD.ownerCompanyName,
                siteName: DummyPropertyD.siteName,
                siteServiceAddress: DummyPropertyD.siteServiceAddress,
              };
              return propD;
            } else {
              return item;
            }
          });
          const demoUserProps = newProperties?.filter(
            (item: any) =>
              item.id === DummyPropertyA.id ||
              item.id === DummyPropertyB.id ||
              item.id === DummyPropertyC.id ||
              item.id === DummyPropertyD.id
          );
          setProperties(demoUserProps || []);
          setPropertiesFromResponse(demoUserProps || []);
        } else {
          setProperties(data?.properties || []);
          setPropertiesFromResponse(data?.properties || []);
        }
      }
      return () => {
        isMounted = false;
      };
    },
    [data, userEmail] // eslint-disable-line
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      searchProperties(searchValue);
    }
    return () => {
      isMounted = false;
    };
  }, [searchValue, propertiesFromResponse]);

  const createPropertyButton = useCallback(() => {
    return (
      <LinkButton
        id="propertyLinkBtn"
        to={ROUTES.ADD_PROPERTY}
        label={formatMessage({
          id: "properties.button.label",
          defaultMessage: "Add New Property",
          description: "add new property button label",
        })}
      />
    );
  }, []);

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "properties.header",
          defaultMessage: "All Properties",
          description: "manage properties header",
        })}
      >
        {properties.length !== 0
          ? auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "properties:create",
              createPropertyButton()
            )
          : null}
      </PageHeader>
      {properties.length === 0 ? (
        <EmptyListView viewType="properties" />
      ) : (
        <DataList
          data={properties}
          loading={loading}
          ListItem={PropertyListItem}
        />
      )}
    </div>
  );
}
