import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";

import { PageHeader } from "../../../../common/components";
import { createFormFields, elevatorBayValidationRules } from "./Form.steps";
import { Form } from "../../../../common/components";
import { ROUTES } from "../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { CustomModal } from "../../../../common/components/index";
import { Modal } from "./Modal";
import { ElevatorBay } from "../../../../types";
import { useSnackbar } from "notistack";

export function AddElevatorBay() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [formDataState, setFormDataState] = useState<ElevatorBay | undefined>(
    undefined
  );
  const { loading: loadingFloors, data: floors } = useQuery(
    GQLService.queries.allFloors,
    {
      variables: { propertyId: selectedProperty?.id },
      fetchPolicy: "network-only",
    }
  );

  const { loading: loadingBeacons, data: beacons } = useQuery(
    GQLService.queries.beaconsByPropertyId,
    {
      variables: { propertyId: selectedProperty?.id },
      fetchPolicy: "network-only",
    }
  );

  const { loading: loadingElevators, data: elevators } = useQuery(
    GQLService.queries.elevatorsByPropertyId,
    {
      variables: { propertyId: selectedProperty?.id },
      fetchPolicy: "cache-first",
    }
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [createElevatorBay] = useMutation(
    GQLService.mutations.createElevatorBay,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.elevatorBaysByPropertyId,
          variables: { propertyId: selectedProperty?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleSubmit = useCallback(
    (formData) => {
      toggleModal(true);
      setFormDataState({ ...formData });
    },
    [formDataState, selectedProperty] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      if (formDataState) {
        try {
          await createElevatorBay({ variables: { ...formDataState } });
          enqueueSnackbar("Elevator Bay added successfully", {
            variant: "success",
          });
          //   reset();
          history.push(
            ROUTES.ELEVATOR_BAYS.replace(":uid", selectedProperty?.id!)
          );
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          console.log(e);
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => {
    toggleModal(false);
  }, []);
  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addBay.header",
          defaultMessage: "Add New Elevator Bay",
          description: "add ElevatorBay label",
        })}
      />
      <Grid item={true} md={5} sm={12}>
        {floors && beacons && elevators && (
          <Form
            defaultValues={{ active: false }}
            fields={createFormFields({
              floors: floors?.floorsByPropertyId,
              beacons: beacons.beaconsByPropertyId,
              elevators: elevators.elevatorsByPropertyId,
            })}
            validationSchema={elevatorBayValidationRules}
            onSubmit={handleSubmit}
          />
        )}
      </Grid>
      <CustomModal open={showModal}>
        <Modal yes={async () => await confirmChanges()} no={rejectChanges} />
      </CustomModal>
    </div>
  );
}
