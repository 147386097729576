import React, { useState, useCallback, ChangeEvent } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  AccordionActions,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Wifi } from "@material-ui/icons";

import { Role, SSID } from "../../../../types";
import { StatusIndicator } from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import GQLService from "../../../../core/services/GQL.service";
import { useMutation } from "@apollo/client";
import { SSIDsMenu } from "./Menu";
import { useSSIDState } from "../../../../core/context/containers/SSID.container";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { listStyles } from "../../../../common/styles/lists";
import { useAuth } from "../../../../core/context/containers/Auth.container";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
  extendedListSubheader: {
    ...listStyles.extendedListSubheader,
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  container: {
    margin: "10px 44px",
    justifyContent: "space-between",
    "&.MuiGrid-spacing-xs-2 > .MuiGrid-item": {
      padding: 0,
    },
  },
  cell: {
    minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  ssidInfo: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.blackPearl,
  },
  content: {},
  accordionDetails: {
    paddingBottom: "40px",
    flexDirection: "column",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  ssidInfoGrid: {
    display: "flex",
    width: "50%",
    justifyContent: "space-between",
  },
  status: {
    flexDirection: "column",
  },
}));

interface SSIDListItemProps {
  item: SSID;
}

export function SSIDListItem({ item }: SSIDListItemProps) {
  const classes = useStyles();
  const { selectedProperty } = usePropertiesState();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const auth = useAuth();
  const handleExpand = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const { setSelectedSSID } = useSSIDState();
  const history = useHistory();

  const [updateSsid] = useMutation(GQLService.mutations.editSsid, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allSsid,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const toggleActiveStatus = useCallback(
    async () => {
      let isMounted = true;
      if (isMounted) {
        await updateSsid({ variables: { ...item, active: !item.active } });
      }
      return () => {
        isMounted = false;
      };
    },
    [item] //eslint-disable-line
  );

  const handleViewClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
      e.preventDefault();
      e.stopPropagation();
      console.log(e.target);
      setSelectedSSID(item);
      history.push(
        ROUTES.SSID_VIEW.replace(":uid", selectedProperty!.id).replace(
          ":ssid",
          item.name
        )
      );
    },
    [item]
  );

  return (
    <Accordion key={item.id} expanded={isExpanded}>
      <AccordionSummary onClick={(e) => handleViewClick(e, item.id)}>
        <Grid container={true} alignItems="center" justify="space-between">
          <Grid
            container={true}
            item={true}
            md={3}
            sm={3}
            justify="flex-start"
            onClick={handleExpand}
            className={classes.headerWrapper}
          >
            {isExpanded ? (
              <ExpandLess id={`expandLess:${item.id}`} className={classes.expandIcon} />
            ) : (
              <ExpandMore id={`expandMore:${item.id}`} className={classes.expandIcon} />
            )}
            <Wifi className={classes.icon} />
            <Typography className={classes.name}>{item.name}</Typography>
          </Grid>
          <Grid container={true} item={true} md={6} sm={4}>
            <Typography className={classes.subheader}>
              {item.authenticationType}
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.flex}>
          {selectedProperty && (
            <StatusIndicator
              uid={selectedProperty?.id}
              isActive={item.active}
              canShowStatusLabel={false}
            />
          )}
        </Grid>
        <AccordionActions
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <SSIDsMenu ssid={item} index={item.id}/>
        </AccordionActions>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container={true} className={classes.container} spacing={2}>
          <Grid item className={classes.ssidInfoGrid}>
            <Grid item className={classes.status}>
              <Typography className={classes.subheader}>STATUS</Typography>
              <Grid className={classes.flex}>
                {selectedProperty && (
                  <StatusIndicator
                    uid={item?.id}
                    isActive={item.active}
                    canShowStatusLabel={true}
                    onChangeFn={toggleActiveStatus}
                  />
                )}
              </Grid>
            </Grid>
            {auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "ssids:protected",
              <Grid item>
                <Typography className={classes.subheader}>VLAN ID</Typography>
                <Typography className={classes.ssidInfo}>
                  {item.vlanId}
                </Typography>
              </Grid>
            )}

            <Grid item>
              <Typography className={classes.subheader}>
                Authentication Type
              </Typography>
              <Typography className={classes.ssidInfo}>
                {item.authenticationType}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.container}>
          <Typography className={classes.subheader}>Description:</Typography>
          <Typography className={classes.content}>
            {item.description}
          </Typography>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
