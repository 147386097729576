import React, { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Grid, Button, Typography } from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";

import { FormField as FormFieldType } from "../../../../../../types/form";
import { Property } from "../../../../../../types";
import { useStyles } from "./ImageInput.styles";
import GQLService from "../../../../../../core/services/GQL.service";
import { COLORS } from "../../../../../../common/styles/colors";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import { ReactComponent as ChangeIcon } from "../../../../../../common/assets/images/change-icon.svg";
import { ReactComponent as TrashIcon } from "../../../../../../common/assets/images/trash-icon.svg";
import { dummyUserEmail } from "../../../../../../common/constants/DummyProperty";
import { useUserState } from "../../../../../../core/context/containers/User.container";
import DummyLogo from "../../../../../../common/assets/images/grandSquareLogo.png";

interface EditPhotosProps {
  field?: FormFieldType;
  name: string;
  previewStyle?: string;
  label: string;
  property: Property | null;
  currentUrl: string | undefined;
}

export function EditLogo({
  name,
  field,
  previewStyle,
  label,
  property,
  currentUrl,
}: EditPhotosProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const { userEmail } = useUserState();
  const { selectedProperty, setCurrentLogoObject, currentLogoObject } =
    usePropertiesState();
  const history = useHistory();

  const [error, setError] = useState<boolean>(false);

  useEffect(
    () => {
      (async () => {
        if (currentUrl) {
          setImageLoading(true);
          const response = await GQLService.rest.logo.getLogo(
            selectedProperty?.id!
          );
          setImageLoading(false);
          setCurrentLogoObject({ ...currentLogoObject, url: response?.source });
        }
      })();
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "dragover",
        (e) => {
          e.preventDefault();
        },
        false
      );
      window.removeEventListener(
        "drop",
        (e) => {
          e.preventDefault();
        },
        false
      );
    };
  }, []);
  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setError(false);
      if (e.target.files) {
        if (e.target.files[0].size / (1024 * 1024) >= 2) {
          setError(true);
          return;
        }
        setCurrentLogoObject({
          url: URL.createObjectURL(e.target.files[0]),
          data: e.target.files[0],
          name: e.target.files[0].name,
        });
      }
    },
    [] //eslint-disable-line
  );

  const handleImageChange = useCallback(
    async (e: any) => {
      setCurrentLogoObject({
        name: undefined,
        url: undefined,
        data: undefined,
      });
    },
    [property, currentLogoObject] //eslint-disable-line
  );

  const handleRemoveImage = useCallback(
    async (e: any) => {
      await GQLService.rest.logo.removeLogo(selectedProperty?.id!);
      setCurrentLogoObject({
        name: undefined,
        url: undefined,
        data: undefined,
      });
    },
    [property, currentLogoObject] //eslint-disable-line
  );

  const handleFileDrop = useCallback((e: React.DragEvent<HTMLInputElement>) => {
    setError(false);

    if (e.dataTransfer.files?.length) {
      if (e.dataTransfer.files[0].size / (1024 * 1024) >= 2) {
        setError(true);
        setCurrentLogoObject({
          url: URL.createObjectURL(e.dataTransfer.files[0]),
          name: e.dataTransfer.files[0].name,
          data: e.dataTransfer.files[0],
        });
        return;
      }
    }
  }, []);

  useEffect(
    () => {
      (async () => {
        if (currentLogoObject?.data) {
          const formData = new FormData();
          formData.append("data", currentLogoObject?.data);
          const logoResponse = await GQLService.rest.logo.uploadLogo(
            formData,
            selectedProperty?.id!
          );
          if (logoResponse.status !== 200) {
            setError(true);
          }
        }
      })();
    },
    [history, currentLogoObject] //eslint-disable-line
  );

  return (
    <Grid style={{ height: 320 }}>
      <Grid container>
        <Typography className={classes.header}>
          {formatMessage({
            id: "companyphoto.header.text",
            defaultMessage: "Company Logo",
            description: "Company Logo",
          })}
        </Typography>
        <Typography className={classes.description}>
          {formatMessage({
            id: "company.description.textFirst",
            defaultMessage:
              "Customize your captive portal login with your own cover photo.",
            description: "company logo description",
          })}
        </Typography>
        <Typography className={classes.description}>
          {formatMessage({
            id: "company.description.textSecond",
            defaultMessage:
              "It will only be visible to members you have invited to use your network.",
            description: "company description",
          })}
        </Typography>
      </Grid>
      {currentLogoObject?.url || imageLoading ? (
        imageLoading ? (
          <Grid className={classes.floorPlanLoader}>
            <Loader
              type="Oval"
              color={COLORS.funBlue}
              height={100}
              width={100}
            />
          </Grid>
        ) : (
          <Grid item className={classes.logo}>
            {userEmail === dummyUserEmail ? (
              <img
                src={DummyLogo}
                alt={"company logo"}
                className={clsx(classes.preview, previewStyle)}
              />
            ) : (
              <img
                src={currentLogoObject?.url}
                alt={currentLogoObject.name}
                className={clsx(classes.preview, previewStyle)}
              />
            )}
            <Grid item className={classes.flex}>
              <Grid item>
                <span
                  onClick={handleImageChange}
                  className={classes.changeButton}
                >
                  {formatMessage({
                    id: "company.description.change",
                    defaultMessage: "Change",
                    description: "change description",
                  })}
                </span>
                <ChangeIcon />
              </Grid>
              <Grid item>
                <span
                  onClick={handleRemoveImage}
                  className={classes.removeButton}
                >
                  {formatMessage({
                    id: "company.description.remove",
                    defaultMessage: "Remove",
                    description: "remove description",
                  })}
                </span>
                <TrashIcon />
              </Grid>
            </Grid>
          </Grid>
        )
      ) : (
        <Grid item={true} md={10}>
          <input
            type="file"
            accept="image/jpeg, image/png"
            className={classes.input}
            id={field?.name || name}
            onChange={handleFileSelect}
            name={field?.name}
          />
          <label htmlFor={field?.name || name}>
            <Button
              className={clsx(classes.button, error ? classes.error : "")}
              component="span"
              fullWidth={true}
              onDrop={handleFileDrop}
            >
              <Grid
                container={true}
                md={10}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <FileCopyOutlined style={{ color: COLORS.inputGrey }} />
                <Typography className={classes.buttonLabel}>
                  {formatMessage({
                    id: "imageInput.dragAndDrop",
                    defaultMessage: "Select File or Drag and Drop",
                    description: "Image input drag and drop label",
                  })}
                </Typography>
              </Grid>
            </Button>
          </label>
          <Typography className={classes.note}>
            {formatMessage({
              id: "imageInput.dragAndDropRules",
              defaultMessage:
                "Your upload must be: JPG or PNG format, less than 2 MB.",
              description: "Image input drag and drop help text",
            })}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
