import React, { useState, useCallback } from "react";
import {
  Grid,
  makeStyles,
  Button,
  Input,
  InputLabel,
  Select,
  Switch,
  TextareaAutosize,
  MenuItem,
  Typography,
  AccordionActions,
} from "@material-ui/core";
import {
  ActivitySwitch,
  CustomModal,
  StatusIndicator,
} from "../../../../../../common/components/index";
import { useIntl } from "react-intl";
import { ImagesSensor } from "../../../../../../types";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import { TripWireDetails } from "./TripWireDetails";
import { SensorRange } from "../../../../Properties/components/SensorRange/SensorRange";
import { TripWireMenu } from "./TripWireMenu";
import { useStyles } from "../../EditImageSensor.styles";
import clsx from "clsx";

interface EditTripWireGridProps {
  imageSensor: ImagesSensor;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  submitCount: number;
  errors: any;
}

export function EditTripWireGrid({
  imageSensor,
  setFieldValue,
  submitCount,
  errors,
}: EditTripWireGridProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return (
    <Grid container={true} className={classes.rootGrid}>
      <FieldArray
        name="tripWires"
        render={(arrayHelpers) => (
          <div>
            {imageSensor?.tripWires?.map((tripWire, index) => {
              return (
                <Grid container className={classes.childGrid} key={"grid"+tripWire?.id}>
                  <Grid item={true} sm={12} md={12} key={"details"+tripWire?.id}>
                    <TripWireDetails
                      values={tripWire}
                      index={index}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      submitCount={submitCount}
                      initialValues={tripWire}
                      imageSensor={imageSensor}
                      tripWire={tripWire}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </div>
        )}
      ></FieldArray>
    </Grid>
  );
}
