import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
  },
  readOnlyInput: {
    color: theme.palette.common.inputGrey,
  },
  textArea: {
    minHeight: "200px",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: "inherit",
    fontFamily: "inherit",
    fontWeight: "inherit",
    fontSize: theme.typography.pxToRem(16),
    width: "100%",
  },
  inputLabel: {
    margin: "20px 0 10px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
  },
  errorLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.cinnabar,
    marginLeft: 10,
  },
  errorInput: {
    borderColor: theme.palette.common.cinnabar,
  },
  form: {
    width: "100%",
    position: "relative",
  },
  submitBtn: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
    width: 180,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(5),
    outline: "none",
    border: "none",
  },
  switch: {
    color: `${theme.palette.common.blue} !important`,
    border: `1px solid ${theme.palette.common.blue}`,
    borderRadius: "30px",
    height: 17,
    width: 34,
    padding: 8,
    marginRight: 5,
  },
  switchInner: {
    color: `${theme.palette.common.blue} !important`,
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `${theme.palette.common.blue} !important`,
    width: 8,
    height: 8,
  },
  checked: {
    color: "transparent !important",
  },
  base: {
    padding: 3,
  },
  status: {
    color: theme.palette.common.textBlack,
    marginLeft: 8,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
  },
  progress: {
    color: `${theme.palette.common.blue} !important`,
  },
  img: {
    width: 860,
    height: 657,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    width: "100% !important",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.textBlack,
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  select: {
    width: "100% !important",
    backgroundColor: theme.palette.common.InputBg,
    marginBottom: "5px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 28,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
  childrenGrid: {
    marginTop: "2rem",
    "& div.MuiGrid-container": {
      flexWrap: "nowrap",
    },
  },
  sliderWidth: {
    width: "500px !important",
  },
  zoneSliderWidth: {
    width: "700px !important",
    minHeight: "30px",
  },
  tripWireSliderWidth: {
    width: "700px !important",
    minHeight: "30px",
  },
  itemSpace: {
    paddingLeft: "10px",
  },
  marginTop16: {
    marginTop: "16px",
  },
  childGrid: {
    flexWrap: "nowrap",
    border: "1px solid #E0EbF1",
    marginTop: "1rem",
    paddingBottom: "8px",
    paddingLeft: "2px",
  },
  subheader: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    color: theme.palette.common.darkGrey,
    paddingRight: "5px",
  },
  accordionAction: {
    display: "flex",
    alignItems: "center",
    justifyContetn: "center",
    paddingTop: "25px",
  },
  arrow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  menu: {
    backgroundColor: theme.palette.common.InputBg,
    boxShadow: "none !important",
    paddingTop: 0,
  },
  option: {
    // width: 275,
    padding: 12,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackText,
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  placeholder: {
    color: "#92ACBF",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: "14px",
  },
  icon: {
    color: "#2F56A1",
    right: 7,
    width: 18,
  },
  rootGrid: {
    "& div.MuiGrid-container": {
      flexWrap: "nowrap",
    },
  },
}));
