import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { Button, Grid } from "@material-ui/core";

import { FormWizard, PageHeader } from "../../../../../common/components";
import { createTenantFormWizard } from "./Form.tenant.steps";
import { ROUTES } from "../../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import GQLService from "../../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import { Fieldset } from "../../../../../types/form";
import { CustomModal } from "../../../../../common/components/index";
import { Modal } from "../Modal";
import { Property, Role, User } from "../../../../../types";
import { userRoles } from "../../../../../common/constants/AuthTypes";
import { useFormWizard } from "../../../../../core/context/functions/FormWizard.functions";
import { useFormWizardState } from "../../../../../core/context/containers/FormWizard.container";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { useUserState } from "../../../../../core/context/containers/User.container";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../../../../common/constants/DummyProperty";

export function AddTenant() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const { userEmail } = useUserState();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [formDataState, setFormDataState] = useState<User | undefined>(
    undefined
  );
  const [steps, setWizardSteps] = useState<Fieldset[] | undefined>(undefined);
  const role = Role.TENANT;
  const { error, loading, data } = useQuery(GQLService.queries.allProperties, {
    fetchPolicy: "network-only",
  });

  const { setFormData, setIsLoading } = useFormWizardState();

  const { reset } = useFormWizard();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setFormData({});
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!loading && data) {
        if (userEmail === dummyUserEmail) {
          let clonedData = _.cloneDeep(data?.properties);
          const newProperties = clonedData?.map((item: any) => {
            if (item.id === DummyPropertyA.id) {
              const propA = {
                ...item,
                ownerCompanyName: DummyPropertyA.ownerCompanyName,
                siteName: DummyPropertyA.siteName,
                siteServiceAddress: DummyPropertyA.siteServiceAddress,
              };
              return propA;
            } else if (item.id === DummyPropertyB.id) {
              const propB = {
                ...item,
                ownerCompanyName: DummyPropertyB.ownerCompanyName,
                siteName: DummyPropertyB.siteName,
                siteServiceAddress: DummyPropertyB.siteServiceAddress,
              };
              return propB;
            } else if (item.id === DummyPropertyC.id) {
              const propC = {
                ...item,
                ownerCompanyName: DummyPropertyC.ownerCompanyName,
                siteName: DummyPropertyC.siteName,
                siteServiceAddress: DummyPropertyC.siteServiceAddress,
              };
              return propC;
            } else if (item.id === DummyPropertyD.id) {
              const propD = {
                ...item,
                ownerCompanyName: DummyPropertyD.ownerCompanyName,
                siteName: DummyPropertyD.siteName,
                siteServiceAddress: DummyPropertyD.siteServiceAddress,
              };
              return propD;
            } else {
              return item;
            }
          });
          const demoUserProps = newProperties?.filter(
            (item: any) =>
              item.id === DummyPropertyA.id ||
              item.id === DummyPropertyB.id ||
              item.id === DummyPropertyC.id ||
              item.id === DummyPropertyD.id
          );
          const steps = createTenantFormWizard({
            properties: demoUserProps?.map((property: Property) => ({
              id: property.id,
              name: property.siteName,
            })),
            roles: userRoles,
          });
          setWizardSteps(steps);
        } else {
          const steps = createTenantFormWizard({
            properties: data?.properties?.map((property: Property) => ({
              id: property.id,
              name: property.siteName,
            })),
            roles: userRoles,
          });
          setWizardSteps(steps);
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [data, loading, userEmail]);

  const [createUser] = useMutation(GQLService.mutations.createUser, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allUsersInfo,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = useCallback(
    (formData) => {
      toggleModal(true);
      setFormDataState({
        ...formData,
        role: role,
      });
    },
    [formDataState, selectedProperty] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      if (formDataState) {
        try {
          await createUser({
            variables: {
              ...formDataState,
              email: formDataState.email?.toLowerCase(),
            },
          });
          enqueueSnackbar("User added successfully", {
            variant: "success",
          });
          reset();

          history.push(ROUTES.USERS);
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          console.log(e);
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => {
    toggleModal(false);
    setIsLoading(false);
  }, []);

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addUser.header",
          defaultMessage: "Add New User",
          description: "add user label",
        })}
      />
      <Grid item={true} md={12} sm={12}>
        {steps && <FormWizard steps={steps} onFulfill={handleSubmit} />}
      </Grid>
      <CustomModal open={showModal}>
        <Modal yes={async () => await confirmChanges()} no={rejectChanges} />
      </CustomModal>
    </div>
  );
}
