import React from "react";
import { Grid, Typography, makeStyles, Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  cell: {
    width: "90%",
    height: 60,
    borderBottom: `1px solid ${theme.palette.common.solidGray}`,
    [theme.breakpoints.up("sm")]: {
      height: "auto",
    },
    marginBottom: theme.typography.pxToRem(30),
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.dustyGray,
    textTransform: "uppercase",
    height: 27,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: theme.typography.pxToRem(14),
    },
  },
  cellValue: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.blackPearl,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(14),
      width: "100%",
    },
  },
  infoIcon: {
    fontSize: 16,
    marginLeft: "16px",
    color: "#2F56A0",
    cursor: "pointer",
    zIndex: 9999,
  },
  toolTipDiv: {
    backgroundColor: "#F2F2F2 !important",
    border: "1px solid #81D3E0",
    borderRadius: "3px !important",
    boxShadow: "0px 6px 6px 2px rgba(0, 0, 0, 0.25)",
    paddingLeft: "12px !important",
    width: "180px",
  },
  toolTipContent: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkBlue,
  },
  flex: {
    display: "flex",
  },
}));

interface DetailLabelProps {
  label: string;
  value?: string;
  children?: React.ReactNode;
}

export function DetailLabel({ label, value, children }: DetailLabelProps) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.cell}
      container={true}
      item={true}
      direction="column"
    >
      <div
        className={label.toLowerCase() === "working hours" ? classes.flex : ""}
      >
        <Typography className={classes.cellLabel}>{label}</Typography>
        {label.toLowerCase() === "working hours" ? (
          <Tooltip
            enterNextDelay={500}
            placement="right"
            title={
              <div>
                <Typography className={classes.toolTipContent}>
                  Off hours will start after the working hours{" "}
                </Typography>
              </div>
            }
            classes={{ tooltip: classes.toolTipDiv }}
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
      {children ? (
        children
      ) : (
        <Typography className={classes.cellValue}>{value || "-"}</Typography>
      )}
    </Grid>
  );
}
