import React, { useState, useCallback, ChangeEvent } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Room } from "@material-ui/icons";
import { useIntl } from "react-intl";

import { Property } from "../../../../../types";
import { StatusIndicator } from "../../../StatusIndicator/StatusIndicator";
import { listStyles } from "../../../../styles/lists";

const useStyles = makeStyles((theme) => ({
  name: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.funBlue,
    marginLeft: "5px",
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  container: {
    margin: "10px 44px",
    justifyContent: "space-between",
  },
  cell: {
    minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  ssidInfo: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.blackPearl,
  },
  subheader: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.dustyGray,
  },
  content: {},
  accordionDetails: {
    paddingBottom: "40px",
    flexDirection: "column",
  },
  accordion: {
    width: "100%",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  ssidInfoGrid: {
    display: "flex",
    width: "50%",
    justifyContent: "space-between",
  },
  status: {
    flexDirection: "column",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
  },
  pin: { color: theme.palette.common.funBlue },
  detailsContainer: {
    margin: "0 44px",
  },
  item: {
    margin: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  arrow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
}));

interface PropertyListItemProps {
  item: Property & { checked?: boolean };
  cb: (e: any, id: string) => void;
}

export function PropertyListItemSelect({ item, cb }: PropertyListItemProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpand = useCallback((e: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(expanded);
  }, []);
  return (
    <Accordion
      key={item.id}
      className={classes.accordion}
      expanded={isExpanded}
      onChange={handleExpand}
    >
      <AccordionSummary>
        <Grid
          container={true}
          alignItems="center"
          justify="space-between"
          classes={{ root: classes.item }}
        >
          <Grid
            container={true}
            item={true}
            md={1}
            sm={3}
            justify="space-around"
            className={classes.iconWrapper}
          >
            <Checkbox
              value={item.id}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
              checked={item.checked === undefined ? false : item.checked}
              onClick={(e) => cb(e, item.id)}
            />
            {isExpanded ? (
              <ExpandLess className={classes.expandIcon} />
            ) : (
              <ExpandMore className={classes.expandIcon} />
            )}
            <Room className={classes.pin} />
          </Grid>
          <Grid container={true} item={true} md={10} sm={7}>
            <Grid item={true} md={3} sm={12}>
              <Typography className={classes.name}>{item.siteName}</Typography>
            </Grid>
          </Grid>
          <Grid
            container={true}
            item={true}
            md={1}
            sm={2}
            alignItems="center"
            justify="space-around"
          >
            <StatusIndicator
              uid={item.id}
              isActive={item.companyStatus}
              canShowStatusLabel={false}
            />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container={true} className={classes.detailsContainer} spacing={2}>
          <Cell
            label={formatMessage({
              id: "FormFields.PropertyListItem.LegalCompanyName.label",
              defaultMessage: "LEGAL COMPANY NAME",
              description:
                "Form Fields Property List Item Legal Company Name Label",
            })}
            value={item.ownerCompanyName}
          />
          <Cell
            label={formatMessage({
              id: "FormFields.PropertyListItem.PhoneNumber.label",
              defaultMessage: "PHONE NUMBER",
              description: "Form Fields Property List Item Phone Number Label",
            })}
            value={item.primaryContactPhoneNumber || ""}
          />
          <Cell
            label={formatMessage({
              id: "FormFields.PropertyListItem.Status.label",
              defaultMessage: "STATUS",
              description: "Form Fields Property List Item Status Label",
            })}
          >
            <Grid container={true} alignItems="center">
              <StatusIndicator
                uid={item.id}
                isActive={item.companyStatus}
                canShowStatusLabel={false}
              />
            </Grid>
          </Cell>
          <Cell
            label={formatMessage({
              id: "FormFields.PropertyListItem.Website.label",
              defaultMessage: "WEBSITE",
              description: "Form Fields Property List Item Website Label",
            })}
            value={item.companyWebsite || ""}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

interface CellProps {
  label: string;
  value?: string | number;
  children?: React.ReactNode;
}

function Cell({ label, value, children }: CellProps) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.cell}
      container={true}
      item={true}
      md={4}
      sm={12}
      direction="column"
    >
      <Grid>
        <Typography className={classes.cellLabel}>{label}</Typography>
        {children ? children : <Typography>{value ? value : "-"}</Typography>}
      </Grid>
    </Grid>
  );
}
