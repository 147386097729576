import React from "react";
import { Grid, Typography } from "@material-ui/core";

import { useStyles } from "./PageHeader.styles";

interface PageHeaderProps {
  title: string;
  children?: React.ReactNode;
  id?: string;
}

export function PageHeader({ title, children, id="pageTitle" }: PageHeaderProps) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container={true}
      alignItems="center"
      justify="space-between"
    >
      <Typography className={classes.title} id={id}>{title}</Typography>
      {children}
    </Grid>
  );
}
