import React, { useState, useCallback, useMemo, ChangeEvent } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Room } from "@material-ui/icons";
import { MoreHoriz } from "@material-ui/icons";
import { Property } from "../../../../types";
import { StatusIndicator } from "../../../../common/components";
import { useUserState } from "../../../../core/context/containers/User.container";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useIntl } from "react-intl";
import { listStyles } from "../../../../common/styles/lists";

const useStyles = makeStyles((theme) => ({
  name: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.funBlue,
    marginLeft: "5px",
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  container: {
    margin: "10px 44px",
    justifyContent: "space-between",
  },
  cell: {
    minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  ssidInfo: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.blackPearl,
  },
  subheader: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.dustyGray,
  },
  content: {},
  accordionDetails: {
    paddingBottom: "40px",
    flexDirection: "column",
  },
  accordion: {
    width: "100%",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  ssidInfoGrid: {
    display: "flex",
    width: "50%",
    justifyContent: "space-between",
  },
  status: {
    flexDirection: "column",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
  },
  pin: { color: theme.palette.common.funBlue },
  detailsContainer: {
    margin: "0 44px",
  },
  item: {
    margin: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  arrow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  menuIcon: {
    minWidth: 30,
    minHeight: 30,
    maxWidth: 30,
    maxHeight: 30,
    color: theme.palette.common.funBlue,

    "&:hover": {
      borderRadius: 15,
      backgroundColor: theme.palette.common.nobel,
    },
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    borderBottom: `1px solid ${theme.palette.common.nobel}`,

    "&:last-child": {
      border: "none",
    },
  },
}));

interface PropertyListItemProps {
  item: Property;
}

export function PropertyList() {
  const { selectedUser } = useUserState();

  if (selectedUser?.properties?.length === 0) {
    return <Typography>No assiged properties</Typography>;
  }

  return (
    <Grid>
      {selectedUser?.properties?.map((property) => (
        <PropertyListItem item={property} />
      ))}
    </Grid>
  );
}

export function PropertyListItem({ item }: PropertyListItemProps) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpand = useCallback((e: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(expanded);
  }, []);

  return (
    <Accordion
      key={item.id}
      className={classes.accordion}
      expanded={isExpanded}
      onChange={handleExpand}
    >
      <AccordionSummary>
        <Grid
          container={true}
          alignItems="center"
          justify="space-between"
          classes={{ root: classes.item }}
        >
          <Grid
            container={true}
            item={true}
            md={1}
            sm={3}
            justify="space-around"
            className={classes.iconWrapper}
          >
            {isExpanded ? (
              <ExpandLess className={classes.expandIcon} />
            ) : (
              <ExpandMore className={classes.expandIcon} />
            )}
            <Room className={classes.pin} />
          </Grid>
          <Grid container={true} item={true} md={10} sm={7}>
            <Grid item={true} md={3} sm={12}>
              <Typography className={classes.name}>{item.siteName}</Typography>
            </Grid>
          </Grid>
          <Grid
            container={true}
            item={true}
            md={1}
            sm={2}
            alignItems="center"
            justify="space-around"
          >
            <StatusIndicator
              uid={item.id}
              isActive={item.companyStatus}
              canShowStatusLabel={false}
            />
            <PropertyMenu item={item} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container={true} className={classes.detailsContainer} spacing={2}>
          <Cell label="LEGAL COMPANY NAME" value={item.ownerCompanyName} />
          <Cell
            label="PHONE NUMBER"
            value={item.primaryContactPhoneNumber || ""}
          />
          <Cell label="STATUS">
            <Grid container={true} alignItems="center">
              <StatusIndicator
                uid={item.id}
                isActive={item.companyStatus}
                canShowStatusLabel={false}
              />
            </Grid>
          </Cell>
          <Cell label="WEBSITE" value={item.companyWebsite || ""} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

interface CellProps {
  label: string;
  value?: string | number;
  children?: React.ReactNode;
}

function Cell({ label, value, children }: CellProps) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.cell}
      container={true}
      item={true}
      md={4}
      sm={12}
      direction="column"
    >
      <Grid>
        <Typography className={classes.cellLabel}>{label}</Typography>
        {children ? children : <Typography>{value ? value : "-"}</Typography>}
      </Grid>
    </Grid>
  );
}

function PropertyMenu({ item }: { item: Property }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState();
  const { formatMessage } = useIntl();
  const { selectedUser, setSelectedUser } = useUserState();

  const [editUser] = useMutation(GQLService.mutations.editUser, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allUsers,
        variables: {
          fetchPolicy: "network-only",
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const removeProperty = useCallback(
    async (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      const filteredProperties = selectedUser?.properties?.filter(
        (property) => property.id != item.id
      );
      try {
        const response = await editUser({
          variables: {
            ...selectedUser,
            active: selectedUser?.enabled,
            propertiesIds: filteredProperties?.map((property) => property.id),
          },
        });
        setSelectedUser(response.data.updateUser);
        // history.push()
      } catch (e) {
        console.log(e);
      }
    },
    [selectedUser, item]
  );

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const open = Boolean(anchorEl);
  return (
    <>
      <MoreHoriz className={classes.menuIcon} onClick={handleToggleMenu} />
      <Menu
        id="properties-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          <MenuItem className={classes.menuItem} onClick={removeProperty}>
            {formatMessage({
              id: "accessPoints.contextMenu.viewDetails",
              defaultMessage: "Remove",
              description: "access points context menu details label",
            })}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
