import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: 10,
  },
  root: {
    flexGrow: 1,
    float: "right",
    border: "2px solid",
    borderColor: theme.palette.common.concrete,
    padding: theme.spacing(2),
    margin: `${theme.spacing(1)}px`,
    borderRadius: "11px",
    width: "50%",
    height: 300,
  },
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.darkBlue,
    marginBottom: theme.spacing(1),
  },
  selectContainer: {
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  select: {
    height: 13,
    minWidth: 100,
    borderRadius: 8,
    marginLeft: 9,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  textValue: {
    color: "#2F56A0",
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(28),
  },
  blackText: {
    display: "inline-block",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: "9px 9px 9px 0",
  },
  blackTextBold: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.darkBlue,
    marginBottom: theme.spacing(1),
    padding: "9 0",
  },
  number: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(30),
    color: theme.palette.common.darkBlue,
    marginTop: "-8px",
    marginBottom: theme.spacing(1),
    padding: "9px 0",
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    // width: 135,
    borderRadius: "0 0 8px 8px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  divider: {
    marginBottom: "-10px",
    overflow: "hidden",
    marginRight: "16px",
  },
  paddingLeft0: {
    paddingLeft: 0,
  },
  paddingLeft5: {
    paddingLeft: "5px",
  },
  marginRight10: {
    marginLeft: "56px",
  },
  blankSpace: {
    // marginTop: "10px",
  },
  box: {
    border: "1px solid",
    borderColor: "#E0EBF1",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",
  },
  boxContent: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingBottom: "20px",
    paddingTop: "5px",
  },
  cancel: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 100,
    height: 42,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  analyzeButton: {
    display: "flex",
    alignItems: "flex-end",
  },
  pinIcon: {
    transform: "rotate(45deg)",
    height: "16px",
  },
  unpinIcon: {
    height: "16px",
    opacity: 0.6,
  },
  pinGrid: {
    marginRight: "4px",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5px",
  },
}));
