import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0 0 16px 0",
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.common.mediumBlack,
    lineHeight: "36px",
  },
}));
