import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { APP_DEFAULTS } from "../../common/constants/App";

import { PROPERTIES_QUERIES, PROPERTIES_MUTATIONS } from "./GQL/Properties";
import { SSIDS_QUERIES, SSID_MUTATIONS } from "./GQL/Ssid";
import {
  IMAGE_SENSOR_QUERIES,
  IMAGE_SENSOR_MUTATIONS,
} from "./GQL/ImageSensors";
import { FLOORS_QUERIES, FLOORS_MUTATIONS } from "./GQL/Floor";
import { DOORS_QUERIES, DOORS_MUTATIONS } from "./GQL/Door";
import { DASHBOARD_QUERIES } from "./GQL/Dashboard";
import { VLAN_QUERIES, VLAN_MUTATIONS } from "./GQL/Vlan";
import { USER_QUERIES, USER_MUTATIONS } from "./GQL/User";
import { SENSORS_MUTATIONS, SENSORS_QUERIES } from "./GQL/AirQuality";
import { BEACONS_MUTATIONS, BEACONS_QUERIES } from "./GQL/Beacon";
import { ELEVATORS_MUTATIONS, ELEVATORS_QUERIES } from "./GQL/Elevator";
import {
  ELEVATOR_BAYS_MUTATIONS,
  ELEVATOR_BAYS_QUERIES,
} from "./GQL/ElevatorBay";

import {
  NETWORK_SETTINGS_MUTATIONS,
  NETWORK_SETTINGS_QUERIES,
} from "./GQL/NetworkSettings";

import {
  ACCESSPOINTS_QUERIES,
  ACCESSPOINTS_MUTATIONS,
} from "./GQL/AccessPoints";
import { Agent } from "https";
import { ImagesRestService } from "./Rest/FloorPlan";
import { LogoRestService } from "./Rest/Logo";
import { CoverPhotoRestService } from "./Rest/CoverPhoto";
import { UserAvatarRestService } from "./Rest/Avatar";
import { AuthRestSerivce } from "./Rest/Auth";
import { ZoneRestService } from "./Rest/Zone";
import { TripWireRestService } from "./Rest/TripWire";

import { TokenRefreshLink } from "apollo-link-token-refresh";
import API from "../api/api";
import authClient from "./Auth.service";
import { parseJwt } from "../../common/utils/Auth.utils";
import { DEVICES_MUTATIONS, DEVICES_QUERIES } from "./GQL/Device";
import { GROUP_MUTATIONS, GROUP_QUERIES } from "./GQL/Group";
import { SPACE_MUTATIONS, SPACE_QUERIES } from "./GQL/Space";
import {
  USER_FILTER_PREFERENCE_MUTATIONS,
  USER_FILTER_PREFERENCE_QUERIES,
} from "./GQL/UserFilterPreference";
import {
  NOTIFICATION_MUTATIONS,
  NOTIFICATION_QUERIES,
} from "./GQL/Notifications";
import {
  ACCESS_CONTROL_MUTATIONS,
  ACCESS_CONTROL_QUERIES,
} from "./GQL/AccessControl";

interface GQLServiceInterface {
  client: ApolloClient<NormalizedCacheObject>;
  httpLink: ApolloLink;
  authLink: ApolloLink;
  onError: ApolloLink;
  tokenRefreshLink: ApolloLink;
  omitTypenameLink: ApolloLink;
  queries: typeof PROPERTIES_QUERIES &
    typeof SSIDS_QUERIES &
    typeof IMAGE_SENSOR_QUERIES &
    typeof FLOORS_QUERIES &
    typeof DASHBOARD_QUERIES &
    typeof ACCESSPOINTS_QUERIES &
    typeof VLAN_QUERIES &
    typeof USER_QUERIES &
    typeof NETWORK_SETTINGS_QUERIES &
    typeof SENSORS_QUERIES &
    typeof BEACONS_QUERIES &
    typeof ELEVATORS_QUERIES &
    typeof ELEVATOR_BAYS_QUERIES &
    typeof DEVICES_QUERIES &
    typeof DOORS_QUERIES &
    typeof NOTIFICATION_QUERIES &
    typeof GROUP_QUERIES &
    typeof SPACE_QUERIES &
    typeof USER_FILTER_PREFERENCE_QUERIES &
    typeof ACCESS_CONTROL_QUERIES;

  mutations: typeof PROPERTIES_MUTATIONS &
    typeof SSID_MUTATIONS &
    typeof IMAGE_SENSOR_MUTATIONS &
    typeof FLOORS_MUTATIONS &
    typeof ACCESSPOINTS_MUTATIONS &
    typeof VLAN_MUTATIONS &
    typeof USER_MUTATIONS &
    typeof NETWORK_SETTINGS_MUTATIONS &
    typeof SENSORS_MUTATIONS &
    typeof BEACONS_MUTATIONS &
    typeof ELEVATORS_MUTATIONS &
    typeof ELEVATOR_BAYS_MUTATIONS &
    typeof DOORS_MUTATIONS &
    typeof NOTIFICATION_MUTATIONS &
    typeof DEVICES_MUTATIONS &
    typeof GROUP_MUTATIONS &
    typeof SPACE_MUTATIONS &
    typeof USER_FILTER_PREFERENCE_MUTATIONS &
    typeof ACCESS_CONTROL_MUTATIONS;

  rest: {
    images: typeof ImagesRestService;
    logo: typeof LogoRestService;
    coverPhoto: typeof CoverPhotoRestService;
    userAvatar: typeof UserAvatarRestService;
    auth: typeof AuthRestSerivce;
    zone: typeof ZoneRestService;
    tripWire: typeof TripWireRestService;
  };
}

class GQLService {
  client: ApolloClient<NormalizedCacheObject>;
  httpLink: ApolloLink;
  authLink: ApolloLink;
  onError: ApolloLink;
  tokenRefreshLink: ApolloLink;
  omitTypenameLink: ApolloLink;
  queries: typeof PROPERTIES_QUERIES;
  mutations: typeof PROPERTIES_MUTATIONS;
  rest: {
    images: typeof ImagesRestService;
    logo: typeof LogoRestService;
    coverPhoto: typeof CoverPhotoRestService;
    userAvatar: typeof UserAvatarRestService;
    auth: typeof AuthRestSerivce;
    zone: typeof ZoneRestService;
    tripWire: typeof TripWireRestService;
  };
  constructor() {
    this.onError = onError(({ graphQLErrors, networkError }) => {});
    this.httpLink = createHttpLink({
      uri: APP_DEFAULTS.graphQlApiUrl,
      fetchOptions: { agent: new Agent({ rejectUnauthorized: true }) },
    });
    this.authLink = setContext((_, { headers }) => {
      const token = localStorage.getItem("accessToken") as string;
      const auth = token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {};
      return {
        headers: {
          ...headers,
          ...auth,
        },
      };
    });
    this.tokenRefreshLink = new TokenRefreshLink({
      accessTokenField: "accessToken",
      isTokenValidOrUndefined: () => {
        const accessToken = localStorage.getItem("accessToken");
        return (
          !accessToken ||
          (parseJwt(accessToken) as any)?.exp > Date.now() / 1000
        );
      },
      fetchAccessToken: () => {
        const refreshToken = localStorage.getItem("refreshToken");
        delete API.defaults.headers.Authorization;
        return API.post("oauth/access_token", {
          grant_type: "refresh_token",
          refresh_token: refreshToken,
        });
      },
      handleFetch: (accessToken) => {
        API.defaults.headers.Authorization = `Bearer ${accessToken}`;
        localStorage.setItem("accessToken", accessToken);
      },
      handleError: (_) => {
        authClient.logout();
      },
    });
    const omitTypename = (key: string, value: any) => {
      return key === "__typename" ? undefined : value;
    };

    this.omitTypenameLink = new ApolloLink((operation, forward) => {
      if (operation.variables) {
        operation.variables = JSON.parse(
          JSON.stringify(operation.variables),
          omitTypename
        );
      }
      return forward(operation);
    });
    this.client = new ApolloClient({
      link: ApolloLink.from([
        this.omitTypenameLink,
        this.onError,
        this.authLink,
        this.tokenRefreshLink,
        this.httpLink,
      ]),
      cache: new InMemoryCache({
        typePolicies: {
          UserResponse: {
            fields: {
              properties: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
            },
          },
        },
      }),
    });
    this.queries = {
      ...PROPERTIES_QUERIES,
      ...SSIDS_QUERIES,
      ...IMAGE_SENSOR_QUERIES,
      ...FLOORS_QUERIES,
      ...DASHBOARD_QUERIES,
      ...ACCESSPOINTS_QUERIES,
      ...VLAN_QUERIES,
      ...USER_QUERIES,
      ...NETWORK_SETTINGS_QUERIES,
      ...SENSORS_QUERIES,
      ...BEACONS_QUERIES,
      ...ELEVATORS_QUERIES,
      ...ELEVATOR_BAYS_QUERIES,
      ...DEVICES_QUERIES,
      ...DOORS_QUERIES,
      ...NOTIFICATION_QUERIES,
      ...GROUP_QUERIES,
      ...SPACE_QUERIES,
      ...USER_FILTER_PREFERENCE_QUERIES,
      ...ACCESS_CONTROL_QUERIES,
    };

    this.mutations = {
      ...PROPERTIES_MUTATIONS,
      ...SSID_MUTATIONS,
      ...IMAGE_SENSOR_MUTATIONS,
      ...FLOORS_MUTATIONS,
      ...ACCESSPOINTS_MUTATIONS,
      ...VLAN_MUTATIONS,
      ...USER_MUTATIONS,
      ...NETWORK_SETTINGS_MUTATIONS,
      ...SENSORS_MUTATIONS,
      ...BEACONS_MUTATIONS,
      ...ELEVATORS_MUTATIONS,
      ...ELEVATOR_BAYS_MUTATIONS,
      ...DEVICES_MUTATIONS,
      ...DOORS_MUTATIONS,
      ...NOTIFICATION_MUTATIONS,
      ...GROUP_MUTATIONS,
      ...SPACE_MUTATIONS,
      ...USER_FILTER_PREFERENCE_MUTATIONS,
      ...ACCESS_CONTROL_MUTATIONS,
    };

    this.rest = {
      images: ImagesRestService,
      logo: LogoRestService,
      coverPhoto: CoverPhotoRestService,
      userAvatar: UserAvatarRestService,
      auth: AuthRestSerivce,
      zone: ZoneRestService,
      tripWire: TripWireRestService,
    };
  }
}

export default new GQLService() as GQLServiceInterface;
