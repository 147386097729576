import React, { useState, useCallback, Fragment } from "react";
import { Menu, MenuItem, MenuList, makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import { ImagesSensor, Role } from "../../../../types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useImagesSensorsState } from "../../../../core/context/containers/ImageSensor.container";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { ReactComponent as HamburgerMenu } from "../../../../common/assets/images/hamburger.svg";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 15,
    minHeight: 15,
    maxWidth: 15,
    maxHeight: 15,
    color: theme.palette.common.funBlue,
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    borderBottom: `1px solid ${theme.palette.common.nobel}`,

    "&:nth-child(3)": {
      border: "none",
    },
  },
}));

interface PropertyMenuProps {
  imageSensor: ImagesSensor;
  index: string;
}

export function ImagesSensorsMenu({ imageSensor, index }: PropertyMenuProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();
  const { setSelectedImagesSensor } = useImagesSensorsState();
  const [anchorEl, setAnchorEl] = useState();

  const auth = useAuth();

  const history = useHistory();

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const handleEditImagesSensorsClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedImagesSensor(imageSensor);
      history.push(
        ROUTES.IMAGE_SENSOR_EDIT.replace(":uid", selectedProperty?.id!).replace(
          ":imagesensor",
          imageSensor?.id.split(":")[1]
        )
      );
    },
    [history, imageSensor, selectedProperty?.id, setSelectedImagesSensor]
  );

  const renderEditMenuItem = useCallback(() => {
    return (
      <MenuItem
        className={classes.menuItem}
        onClick={handleEditImagesSensorsClick}
      >
        {formatMessage({
          id: "imagesSensors.contextMenu.editImagesSensors",
          defaultMessage: "Edit Images Sensors",
          description: "images sensors context menu edit images sensors label",
        })}
      </MenuItem>
    );
  }, [classes.menuItem, formatMessage, handleEditImagesSensorsClick]);

  const open = Boolean(anchorEl);

  return (
    <>
      <HamburgerMenu
        id={index}
        className={classes.icon}
        onClick={handleToggleMenu}
      />
      <Menu
        id="imagesSensors-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ zIndex: 500 }}
        keepMounted={true}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "imageSensor:edit",
            renderEditMenuItem()
          )}
        </MenuList>
      </Menu>
    </>
  );
}
