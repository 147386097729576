import React, { useCallback, useEffect } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { Property, Role } from "../../../../../../types";
import { SiteInfo } from "./components/SiteInfo";
import { AccountInfo } from "./components/AccountInfo";
import { ROUTES } from "../../../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../../../core/context/containers/Auth.container";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../../../../core/services/GQL.service";
import _ from "lodash";
import { useUserState } from "../../../../../../core/context/containers/User.container";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../../../../../common/constants/DummyProperty";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  button: {
    backgroundColor: theme.palette.common.matisse,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: 115,
    height: 42,
    alignSelf: "flex-start",
  },
}));

interface LocationDetailsProps {
  property: Property;
}

export function LocationDetails({ property }: LocationDetailsProps) {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const history = useHistory();
  const { selectedProperty, setSelectedProperty } = usePropertiesState();
  const auth = useAuth();

  const [getPropertyById, { data }] = useLazyQuery(
    GQLService.queries.propertyById,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !data) {
      getPropertyById({ variables: { propertyId: selectedProperty?.id! } });
    } else {
      if (data?.propertyById) {
        if (userEmail === dummyUserEmail) {
          let clonedData = _.cloneDeep(data?.propertyById);
          if (clonedData?.id === DummyPropertyA.id) {
            const propA = {
              ...clonedData,
              ownerCompanyName: DummyPropertyA.ownerCompanyName,
              siteName: DummyPropertyA.siteName,
              siteServiceAddress: DummyPropertyA.siteServiceAddress,
            };
            setSelectedProperty(propA);
          } else if (clonedData?.id === DummyPropertyB.id) {
            const propB = {
              ...clonedData,
              ownerCompanyName: DummyPropertyB.ownerCompanyName,
              siteName: DummyPropertyB.siteName,
              siteServiceAddress: DummyPropertyB.siteServiceAddress,
            };
            setSelectedProperty(propB);
          } else if (clonedData?.id === DummyPropertyC.id) {
            const propC = {
              ...clonedData,
              ownerCompanyName: DummyPropertyC.ownerCompanyName,
              siteName: DummyPropertyC.siteName,
              siteServiceAddress: DummyPropertyC.siteServiceAddress,
            };
            setSelectedProperty(propC);
          } else if (clonedData?.id === DummyPropertyD.id) {
            const propD = {
              ...clonedData,
              ownerCompanyName: DummyPropertyD.ownerCompanyName,
              siteName: DummyPropertyD.siteName,
              siteServiceAddress: DummyPropertyD.siteServiceAddress,
            };
            setSelectedProperty(propD);
          } else {
            setSelectedProperty(clonedData);
          }
        } else {
          setSelectedProperty(data?.propertyById);
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [data, userEmail]);

  const handleEditClick = () => {
    history.push(ROUTES.EDIT_PROPERTY.replace(":uid", property.id) + "#1");
  };

  const createPropertyButton = useCallback(() => {
    return (
      <Button className={classes.button} onClick={handleEditClick}>
        Update
      </Button>
    );
  }, []);

  return (
    <Grid className={classes.container} container={true}>
      <Grid item={true} md={6} sm={12}>
        <SiteInfo property={property} />
      </Grid>
      <Grid item={true} md={6} sm={12}>
        <AccountInfo property={property} />
      </Grid>
      {auth?.canSee(
        auth?.user?.role?.toUpperCase() as Role,
        "properties:edit",
        createPropertyButton()
      )}
    </Grid>
  );
}
