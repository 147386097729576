import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../common/styles/colors";

export const useStyles = makeStyles((theme) => ({
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.blackPearl,
    marginBottom: theme.spacing(2),
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkBlue,
    marginRight: 12,
  },
  container: {
    flexGrow: 1,
    float: "right",
    border: "2px solid",
    borderColor: theme.palette.common.ultralightGrey,
    padding: "15px",
    margin: "10px",
    borderRadius: "11px",
  },
  selectContainer: {
    marginRight: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  select: {
    height: 13,
    minWidth: 100,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  durationSelect: {
    minWidth: 142,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: 9,
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    width: 135,
    borderRadius: "0 0 8px 8px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  buttonTabsWrapper: {
    position: "relative",
    top: -40,
  },
  filters: {
    margin: "0",
  },
  saveButton: {
    backgroundColor: theme.palette.common.funBlue,
    borderRadius: 30,
    color: COLORS.white,
    textTransform: "none",
    padding: "5px 8px",
  },
  savedViewBtn: {
    backgroundColor: theme.palette.common.aquamarineBlue,
    borderRadius: 4,
    color: COLORS.blackText,
    textTransform: "none",
    width: 160,
    "&:hover": {
      backgroundColor: theme.palette.common.aquamarineBlue,
    },
  },
  singleFilter: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    margin: "0 16px 0 5px",
  },
  switchButton: {
    border: `1px solid ${theme.palette.common.funBlue}`,
    color: theme.palette.common.funBlue,
    borderRadius: 30,
    textTransform: "none",
    padding: "5px 8px",
  },
  checkbox: {
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "&.Mui-disabled": {
      pointerEvents: "auto",
      "&:hover": {
        backgroundColor: "transparent",
      },
      cursor: "not-allowed",
    },
  },
  excludeText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
    marginTop: "11px",
  },
  disabledText: {
    color: theme.palette.common.dustyGray,
  },
  enableText: {
    color: theme.palette.common.blackPearl,
  },
  angleIcon: {
    border: "1px solid #a6a6a6",
    borderRight: "1px solid #FFFFFF",
    borderTop: "1px solid #FFFFFF",
    height: 20,
    width: 50,
    marginRight: "14px",
  },
  excludeHrDiv: {
    display: "flex",
    marginRight: "16px",
  },
  durationDiv: {
    display: "flex",
    alignItems: "center",
  },
  summaryDiv: {
    marginRight: "35px",
    marginTop: "3px",
  },
}));
