import { createContainer } from "unstated-next";
import { useCallback, useState } from "react";

import { Tool } from "../../../../common/constants/FloorPlanEditor";

const MIN_ZOOMLEVEL = 1;
const ZOOMLEVEL_DELTA = 0.5;

function useToolbarBase() {
  const [selectedTool, setSelectedTool] = useState<Tool | null>(null);

  const [zoomLevel, setZoomLevel] = useState<number>(MIN_ZOOMLEVEL);

  const zoomIn = useCallback(() => {
    setZoomLevel(zoomLevel + ZOOMLEVEL_DELTA);
  }, [zoomLevel]);

  const zoomOut = useCallback(() => {
    if (zoomLevel - ZOOMLEVEL_DELTA >= MIN_ZOOMLEVEL) {
      setZoomLevel(zoomLevel - ZOOMLEVEL_DELTA);
    }
  }, [zoomLevel]);

  return {
    selectedTool,
    setSelectedTool,
    zoomLevel,
    zoomIn,
    zoomOut,
  };
}

const { Provider, useContainer } = createContainer(useToolbarBase);

export const useToolbarState = useContainer;
export const ToolbarProvider = Provider;
