import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";
import { useUserState } from "../../../core/context/containers/User.container";
import { ReactComponent as PinBlack } from "../../../common/assets/images/pin_black.svg";
import { ReactComponent as SecurityCamera } from "../../../common/assets/images/security-camera.svg";
import { UnpinModal } from "./UnpinModal";
import { CustomModal } from "../../../common/components";
import moment from "moment";
import Loader from "react-loader-spinner";
import { DashboardCollapsibleMyPin } from "./DashboardCollapsibleMyPin";
import { DashboardDeviceType } from "../../../types";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginRight: "16px",
    width: "472px",
  },
  statisticContainerTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginLeft: "14px",
    marginBottom: "16px",
  },
  pinIcon: {
    transform: "rotate(45deg)",
    height: "16px",
  },
  box: {
    border: "1px solid",
    borderColor: "#E0EBF1",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",
  },
  dashboradBoxContent: {
    // paddingLeft:"10px",
    // paddingRight:"10px"
  },
  pinGrid: {
    marginRight: "4px",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5px",
  },
  number: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(30),
    color: "#2F56A0",
    marginBottom: theme.spacing(1),
    marginLeft: "10px",
  },
  blackText: {
    display: "inline-block",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: "9px 9px 9px 0",
  },
  textValue: {
    color: "#2F56A0",
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(28),
  },
  bottomGrid: {
    display: "flex",
    marginLeft: "10px",
    marginBottom: "20px",
  },
  securityIcon: {
    marginLeft: "48px",
  },
  dwellTimeGrid: {
    // marginRight: "20px",
  },
  bottomWrapperGrid: {
    display: "flex",
    marginBottom: "8px",
  },
  loaderGrid: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
}));

interface DashboardTripwireMyPinProps {
  item?: any;
  loadingMyPin?: any;
  removedCard?: any;
}

export function DashboardTripwireMyPin({
  item,
  loadingMyPin,
  removedCard,
}: DashboardTripwireMyPinProps) {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const [toggleUnpinModal, setToggleUnpinModal] = useState<boolean>(false);
  const [tripwireData, setTripwireData] = useState<any>();

  let parsedRequest = JSON.parse(item.queryData).request;
  const [selectedTime, setSelectedTime] = useState(parsedRequest.selectedTime);

  const [
    getDashboardCardAverageAndCountTripwire,
    {
      loading: loadingDashboardCardAverageAndCountTripwire,
      error: errorDashboardCardAverageAndCountTripwire,
      data: dataDashboardCardAverageAndCountTripwire,
    },
  ] = useLazyQuery(GQLService.queries.dashboardCardAverageAndCountTripwire, {
    fetchPolicy: "network-only",
  });

  const timeRange = useMemo(() => {
    if (selectedTime! === 15) return "MINUTE";
    if (selectedTime! === 60) return "QUARTER";
    if (selectedTime! === 1440) return "HOUR";
    if (selectedTime! === 10080) return "DAY";
    if (selectedTime! === 43200) return "WEEK";
  }, [selectedTime, item]);

  const getTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === 10080 || selectedTime === 43200) {
      if (item?.property?.timeZoneName) {
        newTimeTo = moment
          .tz(item?.property?.timeZoneName)
          .startOf("day")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, item?.property?.timeZoneName)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment.utc().startOf("day").format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    } else {
      if (item?.property?.timeZoneName) {
        newTimeTo = moment
          .tz(item?.property?.timeZoneName)
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, item?.property?.timeZoneName)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, item]);

  useEffect(() => {
    getDashboardCardAverageAndCountTripwire({
      variables: {
        request: {
          floorId: item?.floor?.id!,
          from: getTimeParams().newTimeFrom,
          to: getTimeParams().newTimeTo,
          timeRangeType: timeRange,
          spaceId: item?.space?.id!,
          userEmail: userEmail,
          deviceType: DashboardDeviceType.DASHBOARD_TRIPWIRE,
        },
      },
    });
  }, [
    userEmail,
    item,
    timeRange,
    selectedTime,
    getDashboardCardAverageAndCountTripwire,
    getTimeParams,
  ]);

  useEffect(() => {
    if (dataDashboardCardAverageAndCountTripwire) {
      setTripwireData(
        dataDashboardCardAverageAndCountTripwire?.dashboardCardAverageAndCount!
      );
    }
  }, [dataDashboardCardAverageAndCountTripwire]);

  const timeChange = (time: any) => {
    setSelectedTime(time);
  };

  return (
    <>
      <Grid container={true} direction="column" className={classes.wrapper}>
        {!loadingMyPin && (
          <DashboardCollapsibleMyPin item={item} timeChange={timeChange} />
        )}
        {!loadingDashboardCardAverageAndCountTripwire ? (
          <Grid
            className={classes.box}
            style={{
              borderRadius: "8px",
              marginTop: "-8px",
              backgroundColor: "#ffffff",
            }}
          >
            <Grid direction="column" className={classes.dashboradBoxContent}>
              <Grid className={classes.pinGrid}>
                <PinBlack
                  fill="#3f51b5"
                  className={classes.pinIcon}
                  onClick={() => setToggleUnpinModal(true)}
                />
              </Grid>
              <Grid
                container
                direction="row"
                // justify="space-between"
                // style={{ paddingRight: "20px" }}
              >
                <Grid>
                  <Typography className={classes.statisticContainerTitle}>
                    Tripwires
                  </Typography>
                  <Typography className={classes.number}>
                    {~~Math.round(tripwireData?.tripwireCount)}
                  </Typography>
                </Grid>
                {tripwireData?.isNetflowActive && (
                  <Grid className={classes.dwellTimeGrid}>
                    <Typography className={classes.statisticContainerTitle}>
                      Start Count
                    </Typography>
                    <Typography className={classes.number}>
                      {~~Math.round(tripwireData?.startCount)}
                    </Typography>
                  </Grid>
                )}
                <Grid className={classes.dwellTimeGrid}>
                  <Typography className={classes.statisticContainerTitle}>
                    In
                  </Typography>
                  <Typography className={classes.number}>
                    {~~Math.round(tripwireData?.tripwireIn)}
                  </Typography>
                </Grid>
                <Grid className={classes.dwellTimeGrid}>
                  <Typography className={classes.statisticContainerTitle}>
                    Out
                  </Typography>
                  <Typography className={classes.number}>
                    {~~Math.round(tripwireData?.tripwireOut)}
                  </Typography>
                </Grid>
                {tripwireData?.isNetflowActive && (
                  <Grid className={classes.dwellTimeGrid}>
                    <Typography className={classes.statisticContainerTitle}>
                      End Count
                    </Typography>
                    <Typography className={classes.number}>
                      {~~Math.round(tripwireData?.endCount)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid
                className={classes.bottomWrapperGrid}
                justify="space-between"
              >
                <Grid>
                  <Grid style={{ marginLeft: "10px" }}>
                    <Typography
                      className={classes.blackText}
                      style={{ paddingTop: "0px" }}
                    >
                      Image Sensors
                    </Typography>
                  </Grid>
                  <Grid className={classes.bottomGrid}>
                    <Typography className={classes.textValue}>
                      {~~Math.round(tripwireData?.cameraCount)}
                    </Typography>
                    <SecurityCamera
                      className={classes.securityIcon}
                      width={36}
                      height={36}
                    />
                  </Grid>
                </Grid>
                {tripwireData?.netFlowSpacesCount ? (
                  <Grid style={{ marginTop: "-5px" }}>
                    <Typography className={classes.blackText}>
                      Fully Enclosed Spaces
                    </Typography>
                    <Typography className={classes.number}>
                      {~~Math.round(tripwireData?.netFlowSpacesCount)}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid></Grid>
                )}
                <Grid></Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.loaderGrid}>
            <Loader type="Oval" height={50} width={50} color="#2F56A0" />
          </Grid>
        )}
      </Grid>
      <CustomModal open={toggleUnpinModal}>
        <UnpinModal
          setToggleModal={setToggleUnpinModal}
          deviceType={item?.deviceType!}
          deviceId={parsedRequest?.deviceId!}
          selectedSpace={item?.space?.id!}
          selectedFloor={item?.floor!}
          Type="MyPins"
          removedCard={removedCard}
        />
      </CustomModal>
    </>
  );
}
