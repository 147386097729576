import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  CustomModal,
  DataList,
  EmptyListView,
  LinkButton,
  PageHeader,
} from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";

import { Button, Grid, makeStyles } from "@material-ui/core";
import { ROUTES } from "../../../../common/constants/Routing";
import { AccessPointListItem } from "./AccessPointListItem";
import { useQuery, useLazyQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { Role } from "../../../../types";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { useAccessPointState } from "../../../../core/context/containers/AccessPoints.container";
import { useSearchState } from "../../../../core/context/containers/Search.container";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import ExpandLessSharpIcon from "@material-ui/icons/ExpandLessSharp";
import { useHistory } from "react-router-dom";
import { BulkUploadAPModal } from "./BulkUploadAPModal";

const useStyles = makeStyles((theme) => ({
  floorsHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 250,
    height: 42,
    borderRadius: 30,
    display: "flex",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "initial",
    paddingLeft: "10px",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
    },
  },
}));

export function AccessPoints() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const { selectedProperty } = usePropertiesState();
  const {
    searchAccessPoints,
    accessPoints,
    setAccessPoints,
    accessPointsFromResponse,
    setAccessPointsFromResponse,
  } = useAccessPointState();

  const auth = useAuth();
  const [dropdownShow, setDropdownShow] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  // const [getAccessPointsById, { loading, data }] = useLazyQuery(
  //   GQLService.queries.allAccessPoints,
  //   {
  //     variables: { propertyId: selectedProperty?.id },
  //     fetchPolicy: "no-cache",
  //   }
  // );

  const { loading, data } = useQuery(GQLService.queries.allAccessPoints, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "no-cache",
  });

  const { searchValue } = useSearchState();

  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted && !data) {
  //     getAccessPointsById();
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  useEffect(() => {
    // let isMounted = true;
    // if (isMounted) {
    setAccessPoints(data?.accessPointsByPropertyId || []);
    setAccessPointsFromResponse(data?.accessPointsByPropertyId || []);
    // }
    // return () => {
    //   isMounted = false;
    // };
  }, [data, setAccessPoints, setAccessPointsFromResponse, loading]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      searchAccessPoints(searchValue);
    }
    return () => {
      isMounted = false;
    };
  }, [searchValue, accessPointsFromResponse]);

  const rejectChanges = useCallback(() => setShowModal(false), []);

  const handleAPButtonChange = (value: boolean) => {
    setDropdownShow(value);
  };

  const handleSingleAP = () => {
    setDropdownShow(false);
    history.push(
      ROUTES.PROPERTY_ADD_ACCESS_POINT.replace(":uid", selectedProperty?.id!)
    );
  };

  const handleBulkAP = () => {
    setDropdownShow(false);
    setShowModal(true);
  };

  const renderAddButton = useCallback(() => {
    return (
      <Grid direction="column">
        {/* <LinkButton
        to={ROUTES.PROPERTY_ADD_ACCESS_POINT.replace(
          ":uid",
          selectedProperty?.id!
        )}
        label={formatMessage({
          id: "accessPoints.button.label",
          defaultMessage: "Add New Access Point",
          description: "add a access point button label",
        })}
      /> */}
        <Button
          className={classes.button}
          onClick={() => handleAPButtonChange(!dropdownShow)}
          disableRipple={true}
          style={{ borderRadius: dropdownShow ? "21px 21px 0px 0px" : "35px" }}
        >
          Add New Access Point
          {dropdownShow ? (
            <ExpandLessSharpIcon style={{ marginLeft: "5px" }} />
          ) : (
            <ExpandMoreSharpIcon style={{ marginLeft: "5px" }} />
          )}
        </Button>
        {dropdownShow ? (
          <>
            <Button
              className={classes.button}
              onClick={() => handleSingleAP()}
              disableRipple={true}
              style={{ borderRadius: "0px" }}
              disableFocusRipple={true}
            >
              Single Access Point
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleBulkAP()}
              disableRipple={true}
              style={{
                borderRadius: dropdownShow ? "0px 0px 21px 21px" : "0px",
              }}
            >
              Multiple Access Points
            </Button>
          </>
        ) : (
          <></>
        )}
      </Grid>
    );
  }, [dropdownShow]);

  return (
    <div>
      <Grid className={classes.floorsHeader}>
        <PageHeader
          title={formatMessage({
            id: "accessPoints.header",
            defaultMessage: "Access Points",
            description: "access points header",
          })}
        />
        {accessPoints.length !== 0
          ? auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "access_points:create",
              renderAddButton()
            )
          : null}
      </Grid>
      {accessPoints.length === 0 ? (
        <EmptyListView viewType="access_points" />
      ) : (
        <DataList
          data={accessPoints}
          loading={loading}
          ListItem={AccessPointListItem}
        />
      )}
      <CustomModal open={showModal}>
        <BulkUploadAPModal yes={rejectChanges} />
      </CustomModal>
    </div>
  );
}
