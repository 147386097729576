import { airQuality } from "../../types/airQuality";

type spaceLoad = "HIGH" | "LOW" | "MEDIUM" | "NOT_CONFIGURED" | "OFFLINE";

export const colorSpaceLoad = (type?: spaceLoad | airQuality | string) => {
  switch (type) {
    case "HIGH":
      return {
        main: "#B0001F",
        shadow: "rgba(176, 0, 31, 0.2)",
        title: "High",
        className: "highIndicator",
      };
    case "LOW":
      return {
        main: "#4F9E53",
        shadow: "rgba(79, 158, 83, 0.15)",
        title: "Low",
        className: "lowIndicator",
      };
    case "MEDIUM":
      return {
        main: "#EEAA46",
        shadow: "rgba(238, 170, 70, 0.2)",
        title: "Medium",
        className: "mediumIndicator",
      };
    case "NOT_CONFIGURED":
      return {
        main: "#0E1D33",
        shadow: "rgba(105, 105, 105, 0.1)",
        title: "Not configured",
        className: "notConfiguredIndicator",
      };
    case "OFFLINE":
      return {
        main: "#A0A0A0",
        shadow: "rgba(231, 231, 231, 0.4)",
        title: "Offline",
        className: "offlineIndicator",
      };
    case "HEALTHY":
      return {
        main: "#4F9E53",
        shadow: "transparent",
        title: "Healthy",
        className: "healthy",
      };
    case "POOR":
      return {
        main: "#B0001F",
        shadow: "transparent",
        title: "Poor",
        className: "poor",
      };
    case "MODERATE":
      return {
        main: "#EEAA46",
        shadow: "transparent",
        title: "Moderate",
        className: "moderate",
      };
    case "NO_DATA":
      return {
        main: "#81D3E0",
        shadow: "transparent",
        title: "Not Defined",
        className: "noData",
      };
    default:
      return {
        main: "#81D3E0",
        shadow: "rgba(129, 211, 224, 0.2)",
        title: "Not Defined",
        className: "noData",
      };
  }
};

export function Canvas() {
  const drawAP = (
    ctxt: CanvasRenderingContext2D,
    spaceLoad: spaceLoad | airQuality,
    radius: number,
    x: number,
    y: number
  ) => {
    ctxt.beginPath();
    ctxt.arc(x - 5, y - 5, 10, 0, 2 * Math.PI);
    ctxt.fillStyle = colorSpaceLoad(spaceLoad)?.main!;
    ctxt.fill();
    ctxt.closePath();
    ctxt.beginPath();
    ctxt.arc(x - 5, y - 5, radius * 3, 0, 2 * Math.PI);
    ctxt.setLineDash([10, 10]);
    ctxt.fillStyle = colorSpaceLoad(spaceLoad)?.shadow!;
    ctxt.strokeStyle = colorSpaceLoad(spaceLoad)?.main!;
    ctxt.fill();
    ctxt.stroke();
    ctxt.closePath();
  };
  const drawActiveAP = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    name: string,
    names: string[]
  ) => {
    ctx.save();
    const height = ctx.canvas.height;
    const width = ctx.canvas.width;
    const overHeight = +y + (30 + names.length * 30 + 5) > height;
    const overWidth = +x + 80 > width || +x - 80 < 0;
    let startY = +y;
    let startX = +x;
    if (overHeight) {
      startY = +y - (30 + names.length * 30 + 5) - 15;
    }
    if (overWidth) {
      startX = +x - 80 < 0 ? +x + 80 : +x - 80;
    }
    ctx.font = "600 14px Poppins";

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.lineWidth = 10;
    ctx.lineJoin = "round";
    ctx.setLineDash([]);

    ctx.strokeStyle = "#B5E9EF";
    ctx.fillStyle = "#E6F8FA";
    // ctx.strokeRect(x+(10/2), y+(10/2), 150-10, 50-10);
    ctx.fillRect(+startX - 80, +startY + 5, 160, 30 + names.length * 30 + 5);
    ctx.fillStyle = "black";
    ctx.fillText(name, +startX, +startY + 20);
    ctx.font = "normal 12px Poppins";
    ctx.fillText("SSID:", +startX, +startY + 40);
    ctx.font = "600 12px Poppins";
    names.map((ssid: string, index: number) =>
      ctx.fillText(ssid, +startX, +startY + 60 + 20 * index)
    );
    ctx.restore();
  };

  const drawSensorsDetails = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    name: string,
    details: any
  ) => {
    ctx.save();
    const height = ctx.canvas.height;
    const width = ctx.canvas.width;
    const overHeight = y + 30 + 6 * 30 + 15 > height;
    const overWidth = x + 80 > width || x - 80 < 0;
    let startY = y;
    let startX = x;
    if (overHeight) {
      startY = y - (30 + 6 * 30 + 15 + 5);
    }
    if (overWidth) {
      startX = x - 80 < 0 ? x + 80 : x - 80;
    }
    ctx.font = "600 14px Poppins";

    ctx.textAlign = "start";
    ctx.textBaseline = "top";
    ctx.lineWidth = 10;
    ctx.lineJoin = "round";
    ctx.setLineDash([]);

    ctx.strokeStyle = "#B5E9EF";
    ctx.fillStyle = "#E6F8FA";
    // ctx.strokeRect(x+(10/2), y+(10/2), 150-10, 50-10);
    ctx.fillRect(+startX - 80, +startY - 5, 160, 30 + 6 * 30 + 15);
    ctx.fillStyle = "black";
    ctx.fillText(name, +startX - 60, +startY + 20);
    ctx.font = "normal 12px Poppins";
    ctx.font = "600 12px Poppins";
    ctx.fillText(`Temperature: `, +startX - 65, +startY + 40 + 20);
    ctx.fillText(
      `${Math.round((details.temperature.value * 9) / 5 + 32)}°F`,
      +startX + 20,
      +startY + 40 + 20
    );
    ctx.fillText(`Humidity: `, +startX - 65, +startY + 40 + 20 * 2);
    ctx.fillText(
      `${Math.round(details.humidity.value)}%`,
      +startX + 20,
      +startY + 40 + 20 * 2
    );
    ctx.fillText(`CO2: `, +startX - 65, +startY + 40 + 20 * 3);
    ctx.fillText(
      `${Math.round(details.co2.value)} ppm`,
      +startX + 20,
      +startY + 40 + 20 * 3
    );
    ctx.fillText(`VOC: `, +startX - 65, +startY + 40 + 20 * 4);
    ctx.fillText(
      `${Math.round(details.volatileOrganicCompounds.value)} ppb`,
      +startX + 20,
      +startY + 40 + 20 * 4
    );
    ctx.fillText(`PM: `, +startX - 65, +startY + 40 + 20 * 5);
    ctx.fillText(
      `${Math.round(details.particulateMatter.value)} ugm3`,
      +startX + 20,
      +startY + 40 + 20 * 5
    );
    ctx.fillText(`Light: `, +startX - 65, +startY + 40 + 20 * 6);
    ctx.fillText(
      `${Math.round(details.illuminance.value)} lux`,
      +startX + 20,
      +startY + 40 + 20 * 6
    );
    ctx.fillText(`Noise: `, +startX - 65, +startY + 40 + 20 * 7);
    ctx.fillText(
      `${Math.round(details.soundLevel.value)} db`,
      +startX + 20,
      +startY + 40 + 20 * 7
    );
    ctx.restore();
  };

  const drawSensor = (
    ctxt: CanvasRenderingContext2D,
    x: number,
    y: number,
    radius: number,
    airQuality: airQuality,
    value: number
  ) => {
    ctxt.beginPath();
    ctxt.arc(x - 5, y - 5, 20, 0, 3 * Math.PI);
    ctxt.fillStyle = colorSpaceLoad(airQuality)?.main!;
    ctxt.fill();
    ctxt.closePath();
    ctxt.beginPath();
    ctxt.arc(x - 5, y - 5, radius * 3, 0, 2 * Math.PI);
    ctxt.setLineDash([10, 10]);
    ctxt.fillStyle = colorSpaceLoad(airQuality)?.shadow!;
    ctxt.strokeStyle = colorSpaceLoad(airQuality)?.main!;
    ctxt.fill();
    ctxt.stroke();
    ctxt.fillStyle = "white";
    ctxt.font = "normal 20px Poppins";
    ctxt.textAlign = "center";
    ctxt.fillText(String(value), x - 4, y + 2);
    ctxt.closePath();
  };

  const drawFloorPlan = (
    ctxt: CanvasRenderingContext2D,
    imageUrl: string,
    width: number,
    height: number
  ) => {
    return new Promise<void>((resolve) => {
      const base_image = new Image();
      base_image.src = imageUrl;
      base_image.onload = function () {
        ctxt.drawImage(base_image, 0, 0, width, height);
        resolve();
      };
    });
  };
  return {
    drawAP,
    drawActiveAP,
    drawSensorsDetails,
    drawSensor,
    drawFloorPlan,
  };
}
