import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../common/styles/colors";

export const useStyles = makeStyles((theme) => ({
  occupanyWrapper: {
    border: `1px solid ${theme.palette.common.lightGray}`,
    borderRadius: "11px",
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: 9,
  },
  firstBlackText: {
    paddingLeft: 0,
  },
  select: {
    height: 13,
    minWidth: 100,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  loader: {
    minHeight: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    borderRadius: "0 0 8px 8px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  img: {
    width: 860,
    height: 657,
  },
  editButton: {
    backgroundColor: theme.palette.common.funBlue,
    width: 120,
    height: 40,
    borderRadius: 30,
  },
  saveButton: {
    backgroundColor: theme.palette.common.funBlue,
    borderRadius: 30,
    color: COLORS.white,
    textTransform: "none",
  },
  switchButton: {
    border: `1px solid ${theme.palette.common.funBlue}`,
    color: theme.palette.common.funBlue,
    borderRadius: 30,
    textTransform: "none",
  },
  floorName: {
    fontSize: "18px",
    lineHeight: "27px",
    paddingLeft: "20px",
    paddingTop: "20px",
    fontWeight: 600,
  },
  singleFilter: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    margin: "0 0 0 5px",
  },
  firstSingleFilter: {
    margin: 0,
  },
  filters: {
    marginBottom: "10px",
    justifyContent: "space-between",
  },
  filtersWrapper: {
    display: "flex",
    alignItems: "flex-start",
  },
  buttonWrapper: {
    display: "flex",
    "& div button": {
      height: 34,
    },
  },
  canvasWrapper: {
    position: "relative",
    maxWidth: "100%",
    width: "100%",
    flexBasis: "initial",
  },
  nameAndLegendContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
    position: "relative",
  },
  toolSwitch: {
    textTransform: "capitalize",
    color: theme.palette.common.regularBlue,
    background: "transparent",
    border: 0,
    marginRight: theme.typography.pxToRem(16),
    borderBottom: `4px solid transparent`,
    marginBottom: theme.typography.pxToRem(16),
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  active: {
    color: theme.palette.common.mediumBlack,
    borderBottom: `4px solid ${theme.palette.common.lightBlue}`,
    borderRadius: 0,
  },
  switchWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "25px",
    marginBottom: "25px",
  },
  paddingLeft20: {
    paddingLeft: "20px",
  },
  savedViewBtn: {
    backgroundColor: theme.palette.common.aquamarineBlue,
    borderRadius: 4,
    color: COLORS.blackText,
    textTransform: "none",
    width: 160,
    "&:hover": {
      backgroundColor: theme.palette.common.aquamarineBlue,
    },
  },
  checkbox: {
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "&.Mui-disabled": {
      pointerEvents: "auto",
      "&:hover": {
        backgroundColor: "transparent",
      },
      cursor: "not-allowed",
    },
  },
  excludeText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
  },
  disabledText: {
    color: theme.palette.common.dustyGray,
  },
  enableText: {
    color: theme.palette.common.blackPearl,
  },
  angleIcon: {
    border: "1px solid #a6a6a6",
    borderRight: "1px solid #FFFFFF",
    borderTop: "1px solid #FFFFFF",
    height: 20,
    width: 50,
    marginRight: "8px",
  },
  durationWrapper: {
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    margin: "0 0 0 5px",
  },
  checkBoxGrid: {
    alignItems: "center",
    display: "flex",
  },
  excludeOffhrsGrid: {
    display: "flex",
    marginLeft: "11px",
  },
}));
