import React, { useState, useCallback } from "react";
import clsx from "clsx";
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import { AccessPoint, Sensor } from "../../../../../../types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: 20,
    flexDirection: "column",
    alignContent: "center",
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
    textTransform: "none",
  },
  button: {
    width: 56,
    height: 25,
    borderRadius: 43,
  },
  buttonBlue: {
    backgroundColor: theme.palette.common.funBlue,
  },
  input: {
    width: "100%",
    height: 29,
    backgroundColor: theme.palette.common.polar,
    borderRadius: 8,
    paddingLeft: 10,
  },
  hint: {
    marginRight: 2,
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.common.chambray,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    width: "100% !important",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
    marginTop: theme.spacing(1),
  },
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.blackPearl,
    marginBottom: theme.spacing(2),
  },
  select: {
    width: "100% !important",
    backgroundColor: theme.palette.common.polar,
    marginBottom: "10px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.polar,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 28,
    border: `1px solid ${theme.palette.common.turquoiseBlue30}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: "15px",
  },
}));

type elementToRender = {
  name: string;
  active?: boolean;
  id: string;
  commonArea?: boolean;
  cameraUuid?: string;
};

interface AddFormProps {
  onCancel: (e: any) => void;
  onSave: (ap?: any) => void;
  dataToRender: elementToRender[];
  title?: string;
}

export function AddForm({
  onCancel,
  onSave,
  dataToRender,
  title = "Select from list",
}: AddFormProps) {
  const classes = useStyles();

  const [data, setData] = useState({
    id: "Select",
    name: "",
    active: false,
    commonArea: false,
  });

  const handleInputChange = useCallback(
    (e) => {
      setData({ ...data, [e.target.name]: e.target.value });
    },
    [data]
  );

  const handleAccessPointChange = useCallback(
    (e) => {
      const name =
        dataToRender?.find((el: any) => el.id === e.target.value)?.name || "";
      const active =
        dataToRender?.find((el: any) => el.id === e.target.value)?.active ||
        false;
      const commonArea =
        dataToRender?.find((el: any) => el.id === e.target.value)?.commonArea ||
        false;
      setData({ ...data, id: e.target.value, name: name, active, commonArea });
    },
    [data]
  );

  const handleSave = () => {
    onSave(data);
  };
  return (
    <Grid className={classes.root} container={true} alignItems="center">
      <Typography className={classes.text}>{title}</Typography>
      <Select
        classes={{
          root: classes.select,
          select: classes.blackText,
        }}
        value={data.id}
        disableUnderline={true}
        required={true}
        onChange={(e) => handleAccessPointChange(e)}
        name={"baseMacAddress"}
      >
        <MenuItem key={-1} value={"Select"}>
          Select:
        </MenuItem>
        {(dataToRender || []).map((AP: elementToRender, i) => (
          <MenuItem key={i + 1} value={AP.id} disabled={false}>
            Name: {AP.name ? AP.name : AP.cameraUuid}
          </MenuItem>
        ))}
      </Select>
      <Grid container={true} justify="center">
        <Button
          className={clsx(classes.button, classes.text)}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className={clsx(classes.button, classes.buttonBlue, classes.text)}
          disabled={data.id === "Select"}
          onClick={handleSave}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
}
