import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useDebouncedCallback } from "use-debounce";

import { useStyles } from "./FormWizard.styles";
import { FormWizardSteps } from "../../../../types";
import { Form, FormType } from "../Form";
import { useFormWizardState } from "../../../../core/context/containers/FormWizard.container";
import { useFormWizard } from "../../../../core/context/functions/FormWizard.functions";

interface FormWizardProps {
  steps: FormWizardSteps;
  onFulfill: ((data: any) => Promise<void>) | ((data: any) => void);
}

export const FormWizard = ({ steps, onFulfill }: FormWizardProps) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { activeIndex, currentStepFormRefs, formData, isLoading, setIsLoading } = useFormWizardState();
  const { next, prev, submitFormWizard, reset } = useFormWizard();
  const handleFormSubmit = useCallback(() => {
    setIsLoading(true);
    if (!isLoading) {
      submitFormWizard(onFulfill);
    }
  }, [isLoading, onFulfill, submitFormWizard]);

  const handleSubmit = useDebouncedCallback(() => {
    submitFormWizard(handleFormSubmit);
  }, 200);

  const createDefaultValues = useCallback(
    (defaultValues) => {
      if (!formData) {
        return {
          ...defaultValues,
        };
      }
      if (Object.values(formData).every((value) => value === undefined)) {
        return {
          ...formData,
          ...defaultValues,
        };
      }
      return formData;
    },
    [formData]
  );

  useEffect(
    () => {
      return () => {
        reset();
      };
    },
    [] //eslint-disable-line
  );
  return (
    <>
      <Grid container={true} alignItems="center" justify="space-between">
        <Grid container={true} item={true} md={9} sm={12}>
          {steps.map((v, i) => (
            <React.Fragment key={i}>
              <Typography
                className={clsx([
                  classes.stepIndex,
                  activeIndex === i ? classes.stepActive : classes.stepInactive,
                ])}
              >
                {i + 1}
              </Typography>
              <Typography
                className={clsx([
                  classes.label,
                  activeIndex === i
                    ? classes.labelActive
                    : classes.labelInactive,
                ])}
              >
                {v.name}
              </Typography>
              {i < steps.length - 1 ? (
                <div className={classes.stepSeparator} />
              ) : null}
            </React.Fragment>
          ))}
        </Grid>
        <Grid
          container={true}
          item={true}
          md={3}
          sm={12}
          justify="flex-end"
          className={classes.navigationButtons}
        >
          {activeIndex > 0 ? (
            <Button id="prevBtn" className={classes.blueOutlineButton} onClick={prev}>
              {formatMessage({
                id: "Form.Wizard.Previous.Button",
                defaultMessage: "Prev",
                description: "Form Wizard Previous Button",
              })}
            </Button>
          ) : null}
          {activeIndex < steps.length - 1 ? (
            <Button id="nextBtn" className={classes.blueButton} onClick={next}>
              {formatMessage({
                id: "Form.Wizard.Next.Button",
                defaultMessage: "Next",
                description: "Form Wizard Next Button",
              })}
            </Button>
          ) : (
            <Button id="saveStepperBtn" className={classes.blueButton} onClick={handleSubmit}>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                formatMessage({
                  id: "Form.Wizard.Save.Button",
                  defaultMessage: "Save",
                  description: "Form Wizard Save Button",
                })
              )}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container={true} item={true} md={12} sm={12} direction="column">
        {steps[activeIndex].renderCustom ? (
          <div></div>
        ) : (
          steps[activeIndex].fieldset.map((v, i) => (
            <Grid key={i} className={classes.content}>
              <Typography className={classes.fieldsLabel}>{v.label}</Typography>
              <Form
                ref={(ref) => (currentStepFormRefs.current[i] = ref)}
                fields={v.fields}
                defaultValues={createDefaultValues(v.defaultValues)}
                validationSchema={v.validationSchema}
                type={FormType.WizardPartialForm}
                style={
                  steps[activeIndex].styles
                    ? { spacing: 0, ...steps[activeIndex].styles }
                    : { spacing: 0 }
                }
              />
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};
