import React, { useCallback, useMemo, useState, useEffect } from "react";
import clsx from "clsx";
import { FieldInputProps, useFormikContext } from "formik";
import {
  makeStyles,
  TextField,
  Grid,
  Input,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useStyles } from "./SpaceLoad.styles";
import countries from "../../../../assets/geolocation/countries.json";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  DEFAULT_SENSOR_RANGES,
  SensorGrid,
  SensorRange,
} from "../../../../../pages/Dashboard/Properties/components/SensorRange/SensorRange";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { useSensorsState } from "../../../../../core/context/containers/Sensors.contrainer";
import { toCelcius, toFahrenheit } from "../../../../utils/General.utils";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
interface SpaceLoadProps extends FieldInputProps<any> {
  placeholder?: string | undefined;
  defaultValue: any;
  name: string;
}

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const sensorIndicators = [
  {
    classNameBg: "green",
    className: "healthy",
    label: "Healthy",
  },
  {
    classNameBg: "orange",
    className: "moderate",
    label: "Moderate",
  },
  {
    classNameBg: "red",
    className: "poor",
    label: "Poor",
  },
];

export function SpaceLoad({
  name,
  placeholder,
  defaultValue,
  onChange,
}: SpaceLoadProps) {
  const classes = useStyles();
  const { selectedProperty } = usePropertiesState();
  const { errors, values, initialValues, isSubmitting } = useFormikContext<{
    [key: string]: string;
  }>();
  const [sensorConfigurations, setSensorConfigurations] = useState(
    values[name as keyof typeof values] || defaultValue
  );

  const { formatMessage } = useIntl();
  const { tempUnit, setTempUnit, setCelsius } = useSensorsState();
  const [temperatureRange, setTemperatureRange] = useState<number[]>(
    sensorConfigurations?.TEMPERATURE
      ? sensorConfigurations?.TEMPERATURE
      : [0, 54.7, 65.5, 79.9, 88.7, 200]
  );
  useEffect(() => {
    const findUnit = selectedProperty?.sensorConfigurations?.filter(
      (el) => el.attribute === "TEMPERATURE"
    );
    if (findUnit![0].isCelsius) {
      setTempUnit("celcius");
      setCelsius(true);
    } else {
      setTempUnit("farenheit");
      setCelsius(false);
    }
  }, [selectedProperty]);

  const tempUnitList = [
    { value: "farenheit", label: "°F" },
    { value: "celcius", label: "°C" },
  ];

  const handleRangeChange = useCallback(
    (sensorConfiguration, isTemp?) => {
      if (isTemp) {
        setTemperatureRange(sensorConfiguration.TEMPERATURE);
      }
      setSensorConfigurations({
        ...sensorConfigurations,
        ...sensorConfiguration,
      });
      onChange({
        target: {
          name: name,
          value: { ...sensorConfigurations, ...sensorConfiguration },
        },
      });
    },
    [sensorConfigurations, name, onChange]
  );

  const handleOccupancyChange = useCallback(
    (value) => {
      handleRangeChange({ OCCUPANCY: value });
    },
    [handleRangeChange]
  );

  const handleTemperatureChange = useCallback(
    (value) => {
      const newValues = value.map((item: any) => {
        if (item === 93) {
          return 93.3333;
        } else {
          return item;
        }
      });
      handleRangeChange({ TEMPERATURE: newValues }, true);
    },
    [handleRangeChange]
  );

  const handleHumidityChange = useCallback(
    (value) => {
      handleRangeChange({ HUMIDITY: value });
    },
    [handleRangeChange]
  );

  const handleCo2Change = useCallback(
    (value) => {
      handleRangeChange({ CO2: value });
    },
    [handleRangeChange]
  );

  const handleTvocsChange = useCallback(
    (value) => {
      handleRangeChange({ TVOCS: value });
    },
    [handleRangeChange]
  );

  const handlePmChange = useCallback(
    (value) => {
      handleRangeChange({ PM: value });
    },
    [handleRangeChange]
  );

  const handleLightChange = useCallback(
    (value) => {
      handleRangeChange({ LIGHT: value });
    },
    [handleRangeChange]
  );

  const handleNoiseChange = useCallback(
    (value) => {
      handleRangeChange({ NOISE: value });
    },
    [handleRangeChange]
  );

  const handleChange = (event: any) => {
    setTempUnit(event.target.value);
    if (event.target.value === "farenheit") {
      let tempData: any = temperatureRange.map(
        (temp: number, index: number) => {
          if (index === 0) {
            if (temp === 0) {
              return 0;
            }
          } else {
            return toFahrenheit(temp);
          }
        }
      );
      setTemperatureRange(tempData);
      handleRangeChange({ TEMPERATURE: tempData }, true);
      setCelsius(false);
    } else {
      setTemperatureRange(
        temperatureRange.map((temp: number) => toCelcius(temp))
      );
      setCelsius(true);
    }
  };

  return (
    <Grid container>
      <Grid container>
        <Grid container item xs={12}>
          <Typography className={classes.blackLabel}>
            {formatMessage({
              id: "propertySpaceLoad.spaceLoad",
              defaultMessage: "Indicators",
              description: "Sensor threshold indicators",
            })}
          </Typography>
          {sensorIndicators.map((item, index) => (
            <Grid item key={index} className={classes.indicatorWrapper}>
              <span
                className={classes[item.classNameBg as keyof typeof classes]}
              ></span>
              <div className={classes[item.className as keyof typeof classes]}>
                <Typography className={classes.blackText}>
                  {formatMessage({
                    id: `propertySpaceLoad.spaceLoad.${item.className}`,
                    defaultMessage: item.label,
                    description: `Sensor ${item.label} label`,
                  })}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.occupancy",
            defaultMessage: "Occupancy",
            description: "occupancy indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.OCCUPANCY.min}
          max={DEFAULT_SENSOR_RANGES.OCCUPANCY.max}
          hideMinHandle={true}
          range={sensorConfigurations.OCCUPANCY}
          indicators={DEFAULT_SENSOR_RANGES.OCCUPANCY.indicators}
          onChange={handleOccupancyChange}
        />
        <Grid container item xs={12} className={classes.marginTop}>
          <Typography className={classes.blackLabel}>
            {formatMessage({
              id: "propertySpaceLoad.airSensorThresholds",
              defaultMessage: "Air sensors thresholds",
              description: "Air sensors thresholds indicators",
            })}
          </Typography>
        </Grid>
        <Grid
          className={clsx(classes.airSensorContainer, classes.marginTop)}
          container
          item
          xs={12}
        >
          <Grid container item md={3}>
            <Typography className={classes.blackLabel}>
              {formatMessage({
                id: "propertySpaceLoad.temperature",
                defaultMessage: "Temperature",
                description: "temperature indicators",
              })}
            </Typography>
            <Select
              classes={{
                root: classes.select,
                select: classes.blackText,
                icon: classes.icon,
              }}
              name="tempUnit"
              value={tempUnit}
              disableUnderline={true}
              onChange={handleChange}
              IconComponent={ExpandMoreSharpIcon}
              MenuProps={{
                classes: {
                  paper: classes.menu,
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {tempUnitList.map((v) => (
                <MenuItem
                  key={v.value}
                  value={v.value}
                  classes={{
                    root: classes.option,
                    selected: classes.selected,
                  }}
                >
                  {v.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={9}>
            <Grid item className={classes.indicatorWrapper}>
              <SensorRange
                range={temperatureRange}
                hideMinHandle={true}
                min={0}
                max={
                  tempUnit === "farenheit"
                    ? DEFAULT_SENSOR_RANGES.TEMPERATURE.max
                    : toCelcius(DEFAULT_SENSOR_RANGES.TEMPERATURE.max)
                }
                indicators={DEFAULT_SENSOR_RANGES.TEMPERATURE.indicators}
                onChange={handleTemperatureChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.humidity",
            defaultMessage: "Humidity (RH%)",
            description: "humidity indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.HUMIDITY.min}
          max={DEFAULT_SENSOR_RANGES.HUMIDITY.max}
          range={sensorConfigurations.HUMIDITY}
          indicators={DEFAULT_SENSOR_RANGES.HUMIDITY.indicators}
          onChange={handleHumidityChange}
        />
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.co2",
            defaultMessage: "CO2 (ppm)",
            description: "co2 indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.CO2.min}
          max={DEFAULT_SENSOR_RANGES.CO2.max}
          range={sensorConfigurations.CO2}
          indicators={DEFAULT_SENSOR_RANGES.CO2.indicators}
          onChange={handleCo2Change}
        />
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.tvocs",
            defaultMessage: "VOC (ppb)",
            description: "voc indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.TVOCS.min}
          max={DEFAULT_SENSOR_RANGES.TVOCS.max}
          range={sensorConfigurations.TVOCS}
          indicators={DEFAULT_SENSOR_RANGES.TVOCS.indicators}
          onChange={handleTvocsChange}
        />
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.pm25",
            defaultMessage: "PM 2.5 (µ/m3)",
            description: "pm2.5 indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.PM.min}
          max={DEFAULT_SENSOR_RANGES.PM.max}
          range={sensorConfigurations.PM}
          indicators={DEFAULT_SENSOR_RANGES.PM.indicators}
          onChange={handlePmChange}
        />
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.light",
            defaultMessage: "Light (lux)",
            description: "light indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.LIGHT.min}
          max={DEFAULT_SENSOR_RANGES.LIGHT.max}
          range={sensorConfigurations.LIGHT}
          indicators={DEFAULT_SENSOR_RANGES.LIGHT.indicators}
          onChange={handleLightChange}
        />
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.noise",
            defaultMessage: "Noise (dbA)",
            description: "noise indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.NOISE.min}
          max={DEFAULT_SENSOR_RANGES.NOISE.max}
          range={sensorConfigurations.NOISE}
          indicators={DEFAULT_SENSOR_RANGES.NOISE.indicators}
          onChange={handleNoiseChange}
        />
      </Grid>
    </Grid>
  );
}
