import { gql } from "@apollo/client";

export const GROUP_MUTATIONS = {
  addGroup: gql`
    mutation createGroup(
      $name: String!
      $active: Boolean!
      $startDate: Instant
      $lastDate: Instant
      $propertyId: String!
      $users: [String]
    ) {
      createGroup(
        request: {
          name: $name
          active: $active
          startDate: $startDate
          lastDate: $lastDate
          propertyId: $propertyId
          users: $users
        }
      ) {
        id
      }
    }
  `,
};

export const GROUP_QUERIES = {
  groupsByPropertyId: gql`
    query groupsByPropertyId ($propertyId: String!) {
      groupsByPropertyId (
        propertyId: $propertyId
        pageDetails: { sort: { sortBy: "id", sortType: ASC } }
      ) {
        id
        name
        active
        startDate
        lastDate
        dateCreated
        users {
          fullName
        }
      }
    }
  `,
  availableGroupsByDoor: gql`
    query availableGroupsByDoor($doorId: String!, $propertyId: String!) {
      availableGroupsByDoor (
        doorId: $doorId
        propertyId:$propertyId
      ) {
        id
        name
        active
      }
    }
  `
};
