import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  mediumIndicator: {
    backgroundColor: theme.palette.common.yellow,
    width: "5px",
    display: "block",
    height: "16px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  lowIndicator: {
    backgroundColor: theme.palette.common.green,
    width: "5px",
    display: "block",
    height: "16px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  notConfiguredIndicator: {
    backgroundColor: "#0E1D33",
    width: "5px",
    display: "block",
    height: "16px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  offlineIndicator: {
    backgroundColor: "#A0A0A0",
    width: "5px",
    display: "block",
    height: "16px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  highIndicator: {
    backgroundColor: theme.palette.common.red,
    width: "5px",
    display: "block",
    height: "16px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  indicatorWrapper: {
    display: "flex",
    marginRight: "15px",
  },
  indicatorText: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
    marginRight: theme.typography.pxToRem(5),
    alignItems: "center",
    textAlign: "center",
    display: "flex",
  },
  indicatorHeader: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
    marginRight: theme.typography.pxToRem(15),
    alignItems: "center",
    textAlign: "center",
    display: "flex",
  },
  indicatorRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.typography.pxToRem(10),
  },
  legend: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: "25px",
    marginTop: "20px",
  },
  deviceLegend: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "55px"
  },
  spaceLoadLegend: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "50px",
  },
}));

export const OccupancyLegend = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <Grid className={classes.legend}>
      <Grid item className={classes.spaceLoadLegend}>
        <Typography className={classes.indicatorHeader}>
          {formatMessage({
            id: "legend.occupancy.key",
            defaultMessage: "Occupancy:",
            description: "Occupancy legend for chart",
          })}
        </Typography>
        <Grid item className={classes.indicatorWrapper}>
          <Typography className={classes.indicatorText}>
            {formatMessage({
              id: "propertySpaceLoad.spaceLoad.lowIndicator",
              defaultMessage: "Low",
              description: "Space load low label",
            })}
          </Typography>
          <span className={classes.lowIndicator}></span>
        </Grid>
        <Grid item className={classes.indicatorWrapper}>
          <Typography className={classes.indicatorText}>
            {formatMessage({
              id: "propertySpaceLoad.spaceLoad.mediumIndicator",
              defaultMessage: "Medium",
              description: "Space load medium label",
            })}
          </Typography>
          <span className={classes.mediumIndicator}></span>
        </Grid>
        <Grid item className={classes.indicatorWrapper}>
          <Typography className={classes.indicatorText}>
            {formatMessage({
              id: "propertySpaceLoad.spaceLoad.highIndicator",
              defaultMessage: "High",
              description: "Space load high label",
            })}
          </Typography>
          <span className={classes.highIndicator}></span>
        </Grid>
      </Grid>
      <Grid item className={classes.deviceLegend}>
        <Typography className={classes.indicatorHeader}>
          {formatMessage({
            id: "legend.occupancy.key",
            defaultMessage: "Device:",
            description: "Space load legend for chart",
          })}
        </Typography>
        <Grid item className={classes.indicatorWrapper}>
          <Typography className={classes.indicatorText}>
            {formatMessage({
              id: "propertySpaceLoad.spaceLoad.offline",
              defaultMessage: "Offline",
              description: "Space load offline label",
            })}
          </Typography>
          <span className={classes.offlineIndicator}></span>
        </Grid>
      </Grid>
    </Grid>
  );
};
