import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";

import { PageHeader } from "../../../../common/components";
import { createFormFields, BeaconValidationRules } from "./Form.steps";
import { Form } from "../../../../common/components";
import { ROUTES } from "../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { CustomModal } from "../../../../common/components/index";
import { Modal } from "./Modal";
import { Beacon } from "../../../../types";
import { useSnackbar } from "notistack";

export function AddBeacon() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [formDataState, setFormDataState] = useState<Beacon | undefined>(
    undefined
  );
  const { loading, data } = useQuery(GQLService.queries.allFloors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [createBeacon] = useMutation(GQLService.mutations.createBeacon, {
    refetchQueries: () => [
      {
        query: GQLService.queries.beaconsByPropertyId,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = useCallback(
    (formData) => {
      toggleModal(true);
      setFormDataState({ ...formData, propertyId: selectedProperty?.id });
    },
    [formDataState, selectedProperty] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      if (formDataState) {
        try {
          await createBeacon({ variables: { ...formDataState } });
          enqueueSnackbar(
            formatMessage({
              id: "beacons.add.snackbar.success",
              defaultMessage: "Beacon added successfully",
            }),
            {
              variant: "success",
            }
          );
          history.push(ROUTES.BEACONS.replace(":uid", selectedProperty?.id!));
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          console.log(e);
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => {
    toggleModal(false);
  }, []);

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addSensor.header",
          defaultMessage: "Add New Beacon",
          description: "add Beacon label",
        })}
      />
      <Grid item={true} md={5} sm={12}>
        {data && (
          <Form
            defaultValues={{ active: false }}
            fields={createFormFields()}
            validationSchema={BeaconValidationRules}
            onSubmit={handleSubmit}
          />
        )}
      </Grid>
      <CustomModal open={showModal}>
        <Modal yes={async () => await confirmChanges()} no={rejectChanges} />
      </CustomModal>
    </div>
  );
}
