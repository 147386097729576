import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
  },
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.darkBlue,
    marginBottom: theme.spacing(1),
  },
  marker: {
    width: 5,
    height: 15,
    borderRadius: 14,
    marginRight: theme.spacing(1),
  },
  labelsContainer: {
    flexDirection: "column",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    marginLeft: "20%",
    padding: "5px 0",
  },
  labelButton: {
    display: "flex",
    width: "100%",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    marginLeft: "20%",
    padding: "5px 0",
  },
  label: {
    flex: 1,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
  },
  button: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.regularBlue,
    cursor: "pointer",
  },
  percent: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    flex: 0,
  },
  victoryLabel: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
  },
  total: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(42),
    fontWeight: 600,
  },
}));
