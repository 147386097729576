import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryLabel,
  VictoryLegend,
  VictoryArea,
} from "victory";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import clsx from "clsx";
import { ReactComponent as ClockIcon } from "../../../../common/assets/images/clock.svg";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.common.textBlack,
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    position: "relative",
    top: -60,
  },
  keyPosition: {},
  radioBarGreen: {
    backgroundColor: "#79CC38",
  },
  radioBarBlue: {
    backgroundColor: "#2056a6",
  },
  radioBarRed: {
    backgroundColor: "#EC8888",
  },
  radioBarTurquoise: {
    backgroundColor: theme.palette.common.turquoiseBlue,
  },
  rectangle: {
    height: 15,
    width: 4,
    borderRadius: 14,
    margin: "0 24px 0 8px",
  },
  timeZone: {
    backgroundColor: theme.palette.common.InputBg,
    padding: "5px",
    fontSize: "11px",
    height: "25px",
    borderRadius: "2px",
    lineHeight: "11px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  victoryChart: {
    width: "95%",
  },
  timeZoneWrapper: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "25px",
  },
}));

interface LineChartAPDashboardProps {
  devices?: boolean;
  selectedTime?: string;
  connectedDevices?: any[];
  statisticsSelect?: any;
  offHoursIsActive?: boolean;
}

export function LineChartAPDashboard({
  devices,
  selectedTime,
  connectedDevices,
  statisticsSelect,
  offHoursIsActive,
}: LineChartAPDashboardProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();
  const [offHoursArray, setOffHoursArray] = useState<any>(null);

  const offHoursTime = (time: number) => {
    if (selectedTime === "24h") {
      return time === 0
        ? `12 AM`
        : time < 12 && time < 10
        ? `0${time} AM`
        : time < 12 && time > 9
        ? `${time} AM`
        : time === 12
        ? `12 PM`
        : time > 12 && time - 12 > 9
        ? `${time - 12} PM`
        : `0${time - 12} PM`;
    }
    // commneted for future use
    // else {
    //   return time === 0
    //     ? `12:00 AM`
    //     : time < 12
    //     ? `0${time}:00 AM`
    //     : time === 12
    //     ? `12:00 PM`
    //     : `0${time - 12}:00 PM`;
    // }
  };

  const YAxisLabel =
    statisticsSelect === "ACCESSPOINT" ? "Occupancy" : "Average Wellness Index";

  const xAxis = useMemo(() => {
    switch (selectedTime) {
      case "15m":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 15 minutes"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "1h":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last hour"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "24h":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 24 hours"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "7d":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 7 days"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    if (tArray.length > 2)
                      return (
                        tArray[0] +
                        "\n" +
                        tArray[1] +
                        "\n" +
                        tArray[2]?.charAt(0)
                      );
                    else return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  if (tArray.length > 2)
                    return (
                      tArray[0] + "\n" + tArray[1] + "\n" + tArray[2]?.charAt(0)
                    );
                  else return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "30d":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 30 days"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    if (tArray.length > 2)
                      return (
                        tArray[0] +
                        "\n" +
                        tArray[1] +
                        "\n" +
                        tArray[2]?.charAt(0)
                      );
                    else return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  if (tArray.length > 2)
                    return (
                      tArray[0] + "\n" + tArray[1] + "\n" + tArray[2]?.charAt(0)
                    );
                  else return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
    }
  }, [selectedTime]);

  const highestMobileCount = connectedDevices?.map((v) => v.devices) || [];

  const highestValue = Math.max.apply(null, [...highestMobileCount!]);

  useEffect(() => {
    if (selectedTime === "24h" && offHoursIsActive) {
      const newTimeWorkingFrom = offHoursTime(
        +selectedProperty?.workingFrom?.split(/[.:]/)[0]!
      );
      const newTimeWorkingTo = offHoursTime(
        +selectedProperty?.workingTo?.split(/[.:]/)[0]!
      );

      const clonedArray = _.cloneDeep(connectedDevices);
      clonedArray?.map((item) => (item.devices = 0));

      const indexWorkingFrom = connectedDevices?.findIndex(
        (item) => item.date === newTimeWorkingFrom
      );
      const indexWorkingTo = connectedDevices?.findIndex(
        (item) => item.date === newTimeWorkingTo
      );
      if (indexWorkingFrom! > -1 && indexWorkingTo! > -1) {
        if (indexWorkingTo! < indexWorkingFrom!) {
          clonedArray?.splice(indexWorkingFrom!, 0, {
            date: newTimeWorkingFrom,
            devices: highestValue + 5,
          });
          clonedArray?.splice(indexWorkingFrom! + 2, 0, {
            date: newTimeWorkingFrom,
            devices: 0,
          });
          clonedArray?.splice(indexWorkingFrom! + 3, 0, {
            date: newTimeWorkingFrom,
            devices: 0,
          });

          const newIndexWorkingTo = clonedArray?.findIndex(
            (item) => item.date === newTimeWorkingTo
          );

          clonedArray?.splice(newIndexWorkingTo!, 0, {
            date: newTimeWorkingTo,
            devices: 0,
          });
          clonedArray?.splice(newIndexWorkingTo! + 2, 0, {
            date: newTimeWorkingTo,
            devices: 0,
          });
          clonedArray?.splice(newIndexWorkingTo! + 3, 0, {
            date: newTimeWorkingTo,
            devices: highestValue + 5,
          });
        } else {
          clonedArray?.splice(indexWorkingFrom!, 0, {
            date: newTimeWorkingFrom,
            devices: highestValue + 5,
          });
          clonedArray?.splice(indexWorkingFrom! + 2, 0, {
            date: newTimeWorkingFrom,
            devices: highestValue + 5,
          });
          clonedArray?.splice(indexWorkingFrom! + 3, 0, {
            date: newTimeWorkingFrom,
            devices: 0,
          });

          const newIndexWorkingTo = clonedArray?.findIndex(
            (item) => item.date === newTimeWorkingTo
          );

          clonedArray?.splice(newIndexWorkingTo!, 0, {
            date: newTimeWorkingTo,
            devices: 0,
          });
          clonedArray?.splice(newIndexWorkingTo! + 2, 0, {
            date: newTimeWorkingTo,
            devices: highestValue + 5,
          });
          clonedArray?.splice(newIndexWorkingTo! + 3, 0, {
            date: newTimeWorkingTo,
            devices: highestValue + 5,
          });
        }

        const newArray = clonedArray?.map((ele: any) => {
          if (
            ele.date === newTimeWorkingFrom ||
            ele.date === newTimeWorkingTo
          ) {
            return {
              ...ele,
            };
          } else {
            return {
              date: ele.date,
              devices: highestValue + 5,
            };
          }
        });

        const indexForFrom = newArray?.findIndex(
          (item) => item.date === newTimeWorkingFrom
        );
        const indexForTo = newArray?.findIndex(
          (item) => item.date === newTimeWorkingTo
        );

        const newOffHoursArray = newArray?.map((ele: any, index) => {
          if (indexForFrom! < indexForTo!) {
            if (
              (index > indexForFrom! + 3 && index < indexForTo!) ||
              (index > indexForTo! + 3 && index < indexForFrom!)
            ) {
              return {
                date: ele.date,
                devices: 0,
              };
            } else {
              return {
                ...ele,
              };
            }
          } else {
            if (
              (index < indexForFrom! + 3 && index > indexForTo!) ||
              (index < indexForTo! + 3 && index > indexForFrom!)
            ) {
              return {
                ...ele,
              };
            } else {
              return {
                date: ele.date,
                devices: 0,
              };
            }
          }
        });
        setOffHoursArray(newOffHoursArray!);
      } else {
        setOffHoursArray([]);
      }
    }
  }, [
    connectedDevices,
    highestValue,
    selectedProperty,
    selectedTime,
    offHoursIsActive,
  ]);

  return (
    <Grid container={true} item={true}>
      <Grid className={classes.victoryChart}>
        <VictoryChart
          height={180}
          padding={{ top: 10, bottom: 50, left: 40, right: 20 }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => `${Math.round(datum.devices)}`}
              voronoiBlacklist={["offHours"]}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={1}
                  constrainToVisibleArea
                  flyoutStyle={{
                    stroke: "#B5E9EF",
                    fill: "#E6F8FA",
                  }}
                  flyoutWidth={15}
                  flyoutHeight={12}
                  pointerWidth={5}
                  style={{ fontSize: 8 }}
                />
              }
            />
          }
        >
          {statisticsSelect === "ACCESSPOINT" &&
          offHoursIsActive &&
          selectedTime === "24h" ? (
            <VictoryLegend
              x={310}
              y={-4}
              centerTitle
              colorScale={["#E2E2E2", "#FFFFFF"]}
              orientation="horizontal"
              gutter={5}
              style={{
                labels: { fontSize: 6 },
                data: { stroke: "#E2E2E2", strokeWidth: 0.9 },
              }}
              data={[
                {
                  name: "OFF hours",
                  symbol: { fill: "#E2E2E2", type: "square" },
                },
                {
                  name: "Business hours",
                  symbol: { fill: "#FFFFFF", type: "square" },
                },
              ]}
            />
          ) : (
            <></>
          )}
          {xAxis}
          <VictoryAxis
            dependentAxis={true}
            domain={{ y: [0, highestValue + 5] }}
            tickFormat={(t) => t}
            offsetX={40}
            label={YAxisLabel}
            axisLabelComponent={
              <VictoryLabel
                style={{ fontSize: 6, fontFamily: "Poppins", color: "#ccc" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
          />
          {statisticsSelect === "ACCESSPOINT" ? (
            selectedTime === "24h" && offHoursIsActive ? (
              [
                <VictoryArea
                  name="offHours"
                  style={{ data: { fill: "#E2E2E2" } }}
                  data={offHoursArray?.map((value: any) => {
                    return {
                      x: value.date,
                      y: value.devices > 0 ? highestValue + 5 : 0,
                    };
                  })}
                />,
                <VictoryLine
                  data={connectedDevices}
                  x="date"
                  y="devices"
                  style={{
                    data: { stroke: "#81D3E0", strokeWidth: 1 },
                  }}
                />,
              ]
            ) : (
              <VictoryLine
                data={connectedDevices}
                x="date"
                y="devices"
                style={{
                  data: { stroke: "#81D3E0", strokeWidth: 1 },
                }}
              />
            )
          ) : (
            <VictoryLine
              data={connectedDevices}
              x="date"
              y="devices"
              style={{
                data: { stroke: "#81D3E0", strokeWidth: 1 },
              }}
            />
          )}
        </VictoryChart>
      </Grid>
      <Grid md={4} className={classes.timeZoneWrapper}>
        <Typography className={classes.timeZone}>
          <ClockIcon style={{ marginRight: 2, width: 11, height: 11 }} />
          {selectedProperty?.timeZoneName || selectedProperty?.timeZone}
        </Typography>
      </Grid>
    </Grid>
  );
}
