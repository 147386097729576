import {
  Input,
  RadioSelect,
  ActivitySwitch,
  TextareaAutosize,
} from "../../../../../common/components/Form/FormFields";
import { FloorSelect } from "../../../../../common/components/Form/FormFields/FloorSelect/FloorSelect.v2";
import * as Yup from "yup";
import { CreateFormField } from "../../../../../types/form";
import I18n from "../../../../../core/services/I18n.service";

const FIELD_NAMES = {
  name: "name",
  dataCenter: "dataCenter",
  property: "property",
  physicalLocation: "floorId",
  deviceType: "deviceType",
  active: "active",
  notes: "notes",
  baseMacAddress: "baseMacAddress",
  ipAddress: "ipAddress",
  radio1_macAddress: "radio1_macAddress",
  radio1_band: "radio1_band",
  radio2_macAddress: "radio2_macAddress",
  radio2_band: "radio2_band",
  serialNumber: "serialNumber",
  modelNumber: "modelNumber",
  comment: "comment",
  inventoryNumber: "inventoryNumber",
};

export const MainSettings: CreateFormField = (
  disabled,
  data: { [name: string]: [] }
) => {
  return [
    {
      name: FIELD_NAMES.name,
      label: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.label.name",
        defaultMessage: "Access Point Name",
      }),
      placeholder: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.placeholder.name",
        defaultMessage: "Type here",
      }),
      required: false,
      Component: Input,
    },
    {
      name: FIELD_NAMES.physicalLocation,
      label: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.label.physicalLocation",
        defaultMessage: "Physical Location",
      }),
      placeholder: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.placeholder.physicalLocation",
        defaultMessage: "Select floor",
      }),
      required: false,
      Component: FloorSelect,
      data: data?.floors,
    },
    {
      name: FIELD_NAMES.inventoryNumber,
      label: I18n.formatMessage({
        id: "addAccessPointWizard.label.inventoryNumber",
        defaultMessage: "Inventory Number",
      }),
      placeholder: I18n.formatMessage({
        id: "addAccessPointWizard.placeholder.inventoryNumber",
        defaultMessage: "Type here",
      }),
      required: false,
      gridStyle: { sm: 12, md: 6 },
      Component: Input,
    },
    {
      name: FIELD_NAMES.ipAddress,
      label: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.label.ipAddress",
        defaultMessage: "IP Address",
      }),
      placeholder: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.placeholder.ipAddress",
        defaultMessage: "Type here",
      }),
      required: false,
      gridStyle: { sm: 12, md: 6 },
      Component: Input,
    },
    {
      name: FIELD_NAMES.baseMacAddress,
      label: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.label.baseMacAddress",
        defaultMessage: "Base MAC Address",
      }),
      placeholder: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.placeholder.baseMacAddress",
        defaultMessage: "Type here",
      }),
      required: false,
      gridStyle: { sm: 12, md: 6 },
      Component: Input,
      data: true,
    },
    {
      name: FIELD_NAMES.radio1_band,
      label: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.label.radio1Band",
        defaultMessage: "Radio 1 Band",
      }),
      placeholder: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.placeholder.radio1Band",
        defaultMessage: "None",
      }),
      required: false,
      gridStyle: { sm: 12, md: 6 },
      data: data.radio,
      Component: RadioSelect,
    },
    {
      name: FIELD_NAMES.radio2_band,
      label: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.label.radio2Band",
        defaultMessage: "Radio 2 Band",
      }),
      placeholder: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.placeholder.radio2Band",
        defaultMessage: "None",
      }),
      required: false,
      gridStyle: { sm: 12, md: 6 },
      data: data.radio,
      Component: RadioSelect,
    },
    {
      name: FIELD_NAMES.active,
      label: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.label.active",
        defaultMessage: "Active",
      }),
      required: false,
      Component: ActivitySwitch,
    },
    {
      name: FIELD_NAMES.notes,
      label: I18n.formatMessage({
        id: "editAccessPoint.mainSettings.label.notes",
        defaultMessage: "Comment",
      }),
      required: false,
      Component: TextareaAutosize,
    },
  ];
};

export const editAccessPointSchema = Yup.object().shape({
  [FIELD_NAMES.name]: Yup.string()
    .min(
      1,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
  [FIELD_NAMES.dataCenter]: Yup.string().nullable(),
  [FIELD_NAMES.property]: Yup.string()
    .max(
      50,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.property.long",
        defaultMessage: "Too long!",
      })
    )
    .nullable(),
  [FIELD_NAMES.deviceType]: Yup.string()
    .max(
      50,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.deviceType.long",
        defaultMessage: "Too long!",
      })
    )
    .nullable(),
  [FIELD_NAMES.active]: Yup.boolean(),
  [FIELD_NAMES.notes]: Yup.string()
    .max(
      140,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.notes.long",
        defaultMessage: "Too long!",
      })
    )
    .nullable(),
  [FIELD_NAMES.baseMacAddress]: Yup.string()
    .max(
      50,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.baseMacAddress.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
  [FIELD_NAMES.ipAddress]: Yup.string().nullable(),
  [FIELD_NAMES.radio1_macAddress]: Yup.string().nullable(),
  [FIELD_NAMES.radio1_band]: Yup.string().nullable(),
  [FIELD_NAMES.radio2_macAddress]: Yup.string().nullable(),
  [FIELD_NAMES.radio2_band]: Yup.string().nullable(),
  [FIELD_NAMES.inventoryNumber]: Yup.number()
    .typeError("You must specify a number")
    .nullable(),
  [FIELD_NAMES.serialNumber]: Yup.string().nullable(),
});
