import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import {
  PageHeader,
  DataList,
  LinkButton,
  EmptyListView,
} from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { VLANListItem } from "./ListItem";

import { Grid, makeStyles } from "@material-ui/core";
import { ROUTES } from "../../../../common/constants/Routing";
import { useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useVLANState } from "../../../../core/context/containers/VLAN.container";
import { NoVlans } from "../../../../common/components/NoVLANs/NoVlans";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { Role } from "../../../../types";
import { useSearchState } from "../../../../core/context/containers/Search.container";

const useStyles = makeStyles(() => ({
  floorsHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export function VLans() {
  const { selectedProperty } = usePropertiesState();
  const {
    searchVLANs,
    VLANs,
    setVLANs,
    VLANsFromResponse,
    setVLANsFromResponse,
  } = useVLANState();
  const { loading, data } = useQuery(GQLService.queries.vlanByProperty, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });
  const auth = useAuth();

  const { searchValue } = useSearchState();

  useEffect(
    () => {
      let isMounted = true;
      if (isMounted) {
        setVLANs(data?.vlansByPropertyId || []);
        setVLANsFromResponse(data?.vlansByPropertyId || []);
      }
      return () => {
        isMounted = false;
      };
    },
    [data] // eslint-disable-line
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      searchVLANs(searchValue);
    }
    return () => {
      isMounted = false;
    };
  }, [searchValue, VLANsFromResponse]);

  const classes = useStyles();
  const { formatMessage } = useIntl();

  const createVLANButton = useCallback(() => {
    return (
      <LinkButton
        to={ROUTES.V_LAN_ADD.replace(":uid", selectedProperty?.id!)}
        label={formatMessage({
          id: "ssids.button.label",
          defaultMessage: "Add New Network",
          description: "add a vlan button label",
        })}
      />
    );
  }, []);

  return (
    <div>
      <Grid className={classes.floorsHeader}>
        <PageHeader
          title={formatMessage({
            id: "network.header",
            defaultMessage: "Network",
            description: "manage network header",
          })}
        />
        {VLANs.length !== 0
          ? auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "vlans:create",
              createVLANButton()
            )
          : null}
      </Grid>
      {VLANs.length === 0 ? (
        <EmptyListView viewType={"vlans"} />
      ) : (
        <DataList data={VLANs} loading={loading} ListItem={VLANListItem} />
      )}
    </div>
  );
}
