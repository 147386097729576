import moment from "moment";
import { useCallback, useState, useMemo } from "react";
import { createContainer } from "unstated-next";

import { Property } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";
import { TIMEZONES } from "../../../common/constants/Timezones";

function usePropertiesBase() {
  const [propertiesFromResponse, setPropertiesFromResponse] = useState<
    Property[]
  >([]);
  const [properties, setProperties] = useState<Property[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [currentLogoObject, setCurrentLogoObject] = useState<{
    url: string | undefined;
    data: File | undefined;
    name: string | undefined;
  }>({
    url: undefined,
    data: undefined,
    name: undefined,
  });

  const [currentCoverPhotoObject, setCurrentCoverPhotoObject] = useState<{
    url: string | undefined;
    data: File | undefined;
    name: string | undefined;
  }>({
    url: undefined,
    data: undefined,
    name: undefined,
  });
  const {
    state: selectedProperty,
    setState: setSelectedProperty,
  } = usePersistedState<Property | null>("property", null);

  const { search } = useSearch();

  const searchProperties = useCallback(
    (searchValue: string) => {
      setProperties(search(propertiesFromResponse, searchValue) as Property[]);
    },
    [propertiesFromResponse, search]
  );

  const selectedPropertyTimeZone = useMemo(() => {
    if (selectedProperty?.timeZoneName) {
      return {
        abbr: moment.tz(selectedProperty?.timeZoneName).zoneAbbr(),
        name: selectedProperty?.timeZoneName,
      };
    }
    return TIMEZONES.find((v) => v.abbr === selectedProperty?.timeZone!);
  }, [selectedProperty?.timeZone]);

  return {
    searchProperties,
    loading,
    setLoading,
    currentLogoObject,
    setCurrentLogoObject,
    currentCoverPhotoObject,
    setCurrentCoverPhotoObject,
    properties,
    propertiesFromResponse,
    setPropertiesFromResponse,
    setProperties,
    selectedProperty,
    setSelectedProperty,
    selectedPropertyTimeZone,
  };
}

const { Provider, useContainer } = createContainer(usePropertiesBase);

export const usePropertiesState = useContainer;
export const PropertiesProvider = Provider;
