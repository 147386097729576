import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    border: `2px solid ${theme.palette.common.concrete}`,
    borderTop: "0px",
    // margin: theme.spacing(1),
  },
  listItem: {
    borderTop: `2px solid ${theme.palette.common.concrete}`,
    borderBottom: `2px solid ${theme.palette.common.concrete}`,
    cursor: "pointer",
  },
  sublistItem: {
    borderTop: `1px solid ${theme.palette.common.concrete}`,
    borderBottom: `1px solid ${theme.palette.common.concrete}`,
    width: "100%",
  },
  sublistItemActive: {
    borderTop: `1px solid ${theme.palette.common.concrete}`,
    borderBottom: `1px solid ${theme.palette.common.concrete}`,
    width: "100%",
    backgroundColor: "#E6F8FA",
  },
  listItemEditMode: {
    borderTop: `2px solid ${theme.palette.common.concrete}`,
    borderBottom: `2px solid ${theme.palette.common.concrete}`,
    backgroundColor: theme.palette.common.polar,
    cursor: "pointer",
  },
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.blackPearl,
    margin: theme.spacing(2),
  },
  statusIndicator: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  doorNameText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  blackDescription: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
    marginLeft: "30px",
    marginBottom: theme.spacing(1),
  },
  greyText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.dustyGray,
    margin: theme.spacing(2),
  },
  spaceLoadBarGreen: {
    fill: "#9BCA60",
  },
  spaceLoadBarOrange: {
    fill: "#F7812C",
  },
  spaceLoadBarRed: {
    fill: "#E25353",
  },
  range: {
    width: "60%",
    left: "3%",
    top: "-20%",
  },
  rangeRail: {
    height: 4,
    borderRadius: 4,
  },
  rangeThumb: {
    marginTop: -4,
  },
  innerCircle: {
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  innerCircleGreen: {
    backgroundColor: theme.palette.common.chateauGreen,
  },
  innerCircleRed: {
    backgroundColor: theme.palette.common.cinnabar,
  },
  deleteIcon: {
    marginRight: 20,
    marginTop: 10,
    cursor: "pointer",
    width: 16,
    height: 16,
  },
  editModeRoot: {
    padding: theme.spacing(2, 0),
  },
  input: {
    width: "100%",
    height: 29,
    backgroundColor: theme.palette.common.polar,
    borderRadius: 8,
    paddingLeft: 10,
    margin: theme.spacing(1),
  },
  saveButton: {
    width: 56,
    height: 25,
    borderRadius: 43,
    backgroundColor: theme.palette.common.funBlue,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
    textTransform: "none",
    margin: theme.spacing(1),
  },
  toolSwitch: {
    background: "#F0F0F0",
    textTransform: "capitalize",
  },
  active: {
    background: theme.palette.common.funBlue,
    color: "#fff",
  },
  radiusLeft: {
    borderRadius: "6px 0px 0px 6px",
  },
  radiusRight: {
    borderRadius: "0px 6px 6px 0px",
  },
  switchWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "25px",
    marginBottom: "25px",
  },
  sideListTitle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.mediumBlack,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(18),
  },
  doorIcon: { color: theme.palette.common.funBlue },
  iconStyle: {
    fontSize: "18px",
    width: "18px",
    height: "18px",
    color: theme.palette.common.darkBlue,
  },
  popper: {
    zIndex: 10001,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EBEBEB",
    boxSizing: "border-box",
    boxShadow: "0px 4px 13px rgba(135, 135, 135, 0.3)",
    width: 432,
    borderRadius: 5,
    position: "relative",
  },
  paper: {
    boxShadow: "none",
  },
  closeIcon: {
    position: "absolute",
    top: -5,
    right: -5,
    color: "blue",
    width: 22,
    "&:hover": {
      cursor: "pointer",
    },
  },
  chartsContainer: {
    padding: "0 30px 30px",
  },
  popperContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  popperTitle: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    marginTop: "20px",
  },
  popperName: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    margin: "16px 0 14px 0",
  },
  chartsTitle: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    margin: "12px 0 0 0",
  },
  popperTenats: {
    color: theme.palette.common.mediumBlack,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    margin: "5px 0 0 0",
  },
  popperButton: {
    color: theme.palette.common.regularBlue,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    margin: "17px 0 0 0",
  },
}));
