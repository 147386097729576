import { SelectWithTags } from "../../../../../common/components/Form/FormFields";
import * as Yup from "yup";
import { CreateFormField } from "../../../../../types/form";
import I18n from "../../../../../core/services/I18n.service";

const FIELD_NAMES = {
  ssids: "ssids",
};

export const SSIDsForm: CreateFormField = (
  disabled,
  data: { [name: string]: [] },
  existingSSIDs
) => {
  return [
    {
      name: FIELD_NAMES.ssids,
      label: I18n.formatMessage({
        id: "editAccessPoint.SSIDs.label",
        defaultMessage: "SSIDs",
      }),
      placeholder: I18n.formatMessage({
        id: "editAccessPoint.SSIDs.placeholder",
        defaultMessage: "Choose from list",
      }),
      required: false,
      data: data.ssids,
      disabled: disabled,
      customProps: {
        existingSSIDs: existingSSIDs,
      },
      Component: SelectWithTags,
    },
  ];
};

export const SSIDsSchema = Yup.object().shape({
  [FIELD_NAMES.ssids]: Yup.array().nullable(),
});
