import React, { useCallback, useEffect } from "react";
import clsx from "clsx";
import { Grid, Typography } from "@material-ui/core";

import { ReactComponent as NoFloorPlanSVG } from "../../assets/images/no-floor-plan.svg";
import { useStyles } from "./NoFloorPlan.styles";
import { ROUTES } from "../../constants/Routing";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Floor, Property } from "../../../types";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import { useFloorState } from "../../../core/context/containers/Floor.container";

interface NoDataProps {
  style?: string;
  property?: Property | null;
  floor?: Floor;
}

export function NoFloorPlan({ style = "", floor, property }: NoDataProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { setSelectedProperty } = usePropertiesState();
  const { setSelectedFloor } = useFloorState();

  useEffect(() => {
    setSelectedProperty(property || null);
    setSelectedFloor(floor || null);
  }, []);

  const getLink = () => {
    if (floor && property) {
      return ROUTES.PROPERTY_FLOOR.replace(":uid", property.id).replace(
        ":floorUid",
        floor.id
      );
    } else if (!property) {
      return ROUTES.PROPERTIES;
    } else {
      return ROUTES.FLOORS.replace(":uid", property.id);
    }
  };

  const redirect = useCallback(() => {
    history.push(getLink());
  }, []);

  return (
    <Grid className={clsx(classes.nodata, style)}>
      <NoFloorPlanSVG className={classes.icon} />
      <Typography className={classes.message}>
        {formatMessage({
          id: "FormFields.NoFloorPlan.message",
          defaultMessage:
            "There is no plan related with this floor Please upload a plan in the",
          description: "Form Fields No Floor Plan message",
        })}{" "}
        <span onClick={redirect} className={classes.settingsLink}>
          {formatMessage({
            id: "FormFields.NoFloorPlan.message.link",
            defaultMessage: "floor settings",
            description: "Form Fields No Floor Plan message link",
          })}
        </span>
      </Typography>
    </Grid>
  );
}
