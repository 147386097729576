import {
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
  Paper,
} from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import { ClickAwayListener } from "@material-ui/core";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  AccessPoint,
  DensityData,
  SensorDetailsAirQualityForFloor,
  AccessPointWithSpaceLoad,
  AccessPointWithSpaceLoadTimeline,
} from "../../../../types";
import CancelIcon from "@material-ui/icons/Cancel";
import { useStyles } from "./SideListItem.styles";
import clsx from "clsx";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { BarChartForDensity } from "../../../../common/components/Charts/BarCharts/BarChartForDensity";
import { LinearChartForDensity } from "../../../../common/components/Charts/LinearCharts/LinearChartForDensity";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useTimeSliderState } from "../../../../core/context/containers/TimeSlider.container";
import { colorSpaceLoad } from "../../../../common/utils/Canvas.utils";
import { useIntl } from "react-intl";
import { ReactComponent as LineChart } from "../../../../common/assets/images/line-chart 2.svg";
import { APStatisticsPopUp } from "./APStatisticsPopUp";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { ReactComponent as PinBlack } from "../../../../common/assets/images/pin_black.svg";
import { useUserState } from "../../../../core/context/containers/User.container";

const appTypeData = [
  { x: "Engineering", y: 0.5 },
  { x: "Sales", y: 0.3 },
  { x: "Admin", y: 0.1 },
  { x: "Marketing", y: 0.05 },
  { x: "Other", y: 0.05 },
];

type spaceLoad = "HIGH" | "LOW" | "MEDIUM" | "NOT_CONFIGURED" | "OFFLINE";

type classNameIndicator =
  | "highIndicator"
  | "lowIndicator"
  | "mediumIndicator"
  | "notConfiguredIndicator"
  | "offlineIndicator";

type timeInMinutes = 15 | 60 | 1440 | 10080 | 43200;

const timeInMinutesFilter = [
  { value: 15, label: "Last 15 minutes" },
  { value: 60, label: "Last hour" },
  { value: 1440, label: "Last day" },
  { value: 10080, label: "Last 7 days" },
  { value: 43200, label: "Last 30 days" },
];

type SideListItemProps = {
  data?: AccessPointWithSpaceLoad;
  //dataT?: AccessPointWithSpaceLoadTimeline;
  selectedTime: number;
  toggleActive?: (el: string) => void;
  toggleActiveT?: (el: string) => void;
  handleIsPinned?: (el: any) => void;
  offHoursIsActive?: boolean;
};

export function SideListItem(
  this: any,
  {
    data,
    //dataT,
    selectedTime,
    toggleActive,
    toggleActiveT,
    handleIsPinned,
    offHoursIsActive,
  }: SideListItemProps
) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dataToRender, setDataToRender] = useState<any>();
  //const [selectedTime, setSelectedTime] = useState<timeInMinutes>(15);
  const { selectedProperty, selectedPropertyTimeZone } = usePropertiesState();
  const [className, setClassName] = useState<classNameIndicator | null>(null);
  const [SSIDs, setSSIDs] = useState<any[]>([]);
  const [accessPointDetails, setAccessPointDetails] = useState<any>();
  //const { selectedTimeSlider, setSelectedTimeSlider } = useTimeSliderState();
  const { userEmail } = useUserState();

  const getTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === 10080 || selectedTime === 43200) {
      if (selectedProperty?.timeZoneName) {
        newTimeTo = moment
          .tz(selectedProperty?.timeZoneName)
          .startOf("day")
          .format(); //ml
        newTimeFrom = moment
          .tz(newTimeTo, selectedProperty?.timeZoneName)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment.utc().startOf("day").format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    } else {
      if (selectedProperty?.timeZoneName) {
        newTimeTo = moment
          .tz(selectedProperty?.timeZoneName)
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedProperty?.timeZoneName)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, selectedProperty?.timeZoneName]);

  const get15minTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === 15) {
      if (selectedProperty?.timeZoneName) {
        newTimeTo = moment
          .tz(selectedProperty?.timeZoneName)
          .startOf("minute")
          .subtract(3, "minutes")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedProperty?.timeZoneName)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf("minute")
          .subtract(3, "minutes")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, selectedProperty?.timeZoneName]);

  const timeRange = useMemo(() => {
    if (selectedTime <= 15) return "MINUTE";
    if (selectedTime <= 60) return "QUARTER";
    if (selectedTime <= 1440) return "HOUR";
    return "DAY";
  }, [selectedTime]);

  //API call to fetch statistics data for graph
  const [getDensityTimeForAccessPoint, { loading, error, data: responseData }] =
    useLazyQuery(GQLService.queries.densityTime, {
      fetchPolicy: "network-only",
    });

  /* will remove this code once testing is done with new code */
  // useEffect(() => {
  //   if (accessPointDetails) {
  //     getDensityTimeForAccessPoint({
  //       variables: {
  //         request: {
  //           accessPointId: data?.accessPoint?.id!,
  //           // selectedTime === 1440
  //           //   ? dataT?.accessPoint?.id!
  //           //   : data?.accessPoint?.id!,
  //           floorId: data?.accessPoint?.floorId!,
  //           // selectedTime === 1440
  //           //   ? dataT?.accessPoint?.floorId!
  //           //   : data?.accessPoint?.floorId!,
  //           from: getTimeParams().newTimeFrom,
  //           to: getTimeParams().newTimeTo,
  //           timeRangeType: timeRange,
  //           userEmail: userEmail,
  //           excludeOffHours: offHoursIsActive,
  //         },
  //       },
  //     });
  //   }
  // }, [
  //   data,
  //   //dataT,
  //   getDensityTimeForAccessPoint,
  //   timeRange,
  //   accessPointDetails,
  //   userEmail,
  //   getTimeParams,
  //   offHoursIsActive
  // ]);

  useEffect(() => {
    !loading &&
      setDataToRender(responseData?.densityTimelineByAccessPointId || []);
  }, [responseData, loading]);

  const handleOpen = (
    event: React.MouseEvent<HTMLElement>,
    accessPoint: any
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(true);
    setAccessPointDetails(accessPoint);
    getDensityTimeForAccessPoint({
      variables: {
        request: {
          accessPointId: data?.accessPoint?.id!,
          floorId: data?.accessPoint?.floorId!,
          from:
            selectedTime === 15
              ? get15minTimeParams().newTimeFrom
              : getTimeParams().newTimeFrom,
          to:
            selectedTime === 15
              ? get15minTimeParams().newTimeTo
              : getTimeParams().newTimeTo,
          timeRangeType: timeRange,
          userEmail: userEmail,
          excludeOffHours: offHoursIsActive,
        },
      },
    });
    event.stopPropagation();
  };
  const id = open ? "simple-popper" : undefined;

  const handleClose = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(null);
    setOpen(false);
    event.stopPropagation();
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  useEffect(() => {
    if (!loading) {
      setClassName(
        // selectedTime === 1440
        //   ? (colorSpaceLoad(dataT?.timeline[selectedTimeSlider!]?.spaceLoad!)
        //       ?.className as classNameIndicator)
        //   :
        colorSpaceLoad(data?.spaceLoad!)?.className as classNameIndicator
      );

      const SSIDs =
        // selectedTime === 1440
        //   ? [
        //       ...new Set(
        //         [dataT?.accessPoint.radio1, dataT?.accessPoint?.radio2]
        //           .reduce((acc, val) => acc.concat(val?.ssids as any), [])
        //           .map((el: any) => el?.name)
        //       ),
        //     ]
        //   :
        [
          ...new Set(
            [data?.accessPoint?.radio1, data?.accessPoint?.radio2]
              .reduce((acc, val) => acc.concat(val?.ssids as any), [])
              .map((el: any) => el?.name)
          ),
        ];
      setSSIDs(SSIDs);
    }
  }, [loading, data /*dataT*/]);

  const dwellTimeFormat = useMemo(() => {
    if (dataToRender?.averageDwellTime) {
      if (dataToRender?.averageDwellTime > 3600) {
        return "HH [hrs] mm [min]";
      } else {
        return "mm [min]";
      }
    }
    return "";
  }, [dataToRender]);

  return (
    <Grid
      id="listItem"
      className={
        // selectedTime === 1440
        //   ? dataT?.active
        //     ? classes.sideListElementActive
        //     : classes.sideListElement
        //   :
        data?.active ? classes.sideListElementActive : classes.sideListElement
      }
      key={
        // selectedTime === 1440 ? dataT?.accessPoint.id :
        data?.accessPoint?.id
      }
    >
      {/* {selectedTime === 1440 ? (
        dataT?.isPinned ? (
          <Grid className={classes.pinIconGrid}>
            <PinBlack
              style={{
                transform: "rotate(45deg)",
                opacity: "0.6",
                height: "16px",
              }}
            />
          </Grid>
        ) : null
      ) : */}
      {data?.isPinned ? (
        <Grid className={classes.pinIconGrid}>
          <PinBlack
            style={{
              transform: "rotate(45deg)",
              opacity: "0.6",
              height: "16px",
            }}
          />
        </Grid>
      ) : null}
      <Grid item className={classes.indicatorRow}>
        <Grid className={classes.indicatorAndTitleContainer}>
          <span className={className ? classes[className] : undefined} />
          <Typography
            className={classes.name}
            onClick={(ev) => !open && toggleActive!(data?.accessPoint?.id!)}
          >
            {/* {selectedTime === 1440
              ? dataT?.accessPoint.name :  */}
            {data?.accessPoint?.name}
          </Typography>
          <Grid className={classes.iconGrid}>
            {/* {selectedTime === 1440 ? (
              dataT?.active ? (
                <Grid style={{ display: "flex" }}>
                  <Grid
                    onClick={(event) => handleOpen(event, dataT?.accessPoint)}
                  >
                    <LineChart
                      width={11}
                      id="open"
                      className={classes.lineChartIcon}
                    />
                  </Grid>
                  <ExpandLess
                    className={classes.expandIcon}
                    onClick={(ev) =>
                      !open && toggleActiveT!(dataT?.accessPoint.id!)
                    }
                  />
                </Grid>
              ) : (
                <Grid style={{ display: "flex" }}>
                  <Grid
                    onClick={(event) => handleOpen(event, dataT?.accessPoint)}
                  >
                    <LineChart
                      width={11}
                      id="open"
                      className={classes.lineChartIcon}
                    />
                  </Grid>
                  <ExpandMore
                    className={classes.expandIcon}
                    onClick={(ev) =>
                      !open && toggleActiveT!(dataT?.accessPoint.id!)
                    }
                  />
                </Grid>
              )
            ) : */}
            {data?.active ? (
              <Grid style={{ display: "flex" }}>
                <Grid onClick={(event) => handleOpen(event, data?.accessPoint)}>
                  <LineChart
                    width={11}
                    id="open"
                    className={classes.lineChartIcon}
                  />
                </Grid>
                <ExpandLess
                  className={classes.expandIcon}
                  onClick={(ev) =>
                    !open && toggleActive!(data?.accessPoint?.id!)
                  }
                />
              </Grid>
            ) : (
              <Grid style={{ display: "flex" }}>
                <Grid onClick={(event) => handleOpen(event, data?.accessPoint)}>
                  <LineChart
                    width={11}
                    id="open"
                    className={classes.lineChartIcon}
                  />
                </Grid>
                <ExpandMore
                  className={classes.expandIcon}
                  onClick={(ev) =>
                    !open && toggleActive!(data?.accessPoint?.id!)
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* {selectedTime === 1440
        ? dataT?.active && (
            <Grid item>
              {SSIDs.map((name: any, i: number) => (
                <Grid className={classes.ssid} key={i}>
                  SSID:
                  <Typography className={classes.ssidName}>{name}</Typography>
                </Grid>
              ))}
            </Grid>
          )
        : */}
      {data?.active && (
        <Grid item>
          {SSIDs.map((name: any, i: number) => (
            <Grid className={classes.ssid} key={i}>
              SSID:
              <Typography className={classes.ssidName}>{name}</Typography>
            </Grid>
          ))}
        </Grid>
      )}
      <APStatisticsPopUp
        open={open}
        id={id}
        anchorEl={anchorEl}
        handleClickAway={handleClickAway}
        handleClose={handleClose}
        data={data}
        // dataT={dataT}
        className={className}
        dataToRender={dataToRender}
        timeRange={timeRange}
        loading={loading}
        error={error}
        selectedTime={selectedTime}
        handleIsPinned={handleIsPinned}
      />
    </Grid>
  );
}
