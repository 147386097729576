import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  switch: {
    color: `${theme.palette.common.switchBlue} !important`,
    border: `1px solid ${theme.palette.common.switchBlue}`,
    borderRadius: "30px;",
    height: 17,
    width: 52,
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `#2F56A1 !important`,
    width: 12,
    height: 12,
  },
  root: {
    display: "flex",
    alignItems: "center",
  },
  fullyCoveredParagraph: {
    whiteSpace: "nowrap",
    color: theme.palette.common.black,
    fontSize: 12,
    fontWeight: 600,
  },
  base: {
    height: 22,
  },
  checkbox: {
    paddingLeft: 0,
  },
  error: {
    border: `1px solid ${theme.palette.common.cinnabar}`,
    borderRadius: "30px;",
    color: `${theme.palette.common.switchBlue} !important`,
    height: 17,
    width: 52,
  },
  status: {
    color: "#122036",
    marginLeft: 8,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 3,
  },
}));
