import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { LinearProgress } from "@material-ui/core";

import { ROUTES } from "../../common/constants/Routing";
import { Dashboard } from "../../pages/Dashboard";
import NetworkAnalytics from "../../pages/NetworkAnalytics/NetworkAnalytics";
import { FloorPlanEditor } from "../../pages/NetworkAnalytics/FloorPlanEditor/FloorPlanEditor";
import { useAuth } from "../context/containers/Auth.container";
import { ProtectedRoute } from "./ProtectedRoute";

import {
  SignIn,
  PasswordRecovery,
  PasswordRecoverySuccess,
  PasswordReset,
} from "../../pages";

import PropertyRouter from "./PropertyRotuer";

import UsersRouter from "./UserRouter";
import NetworkGroups from "../../pages/NetworkSettings/NetworkSettings";
import { Occupancy } from "../../pages/NetworkAnalytics/Occupancy/Occupancy";
import { AllNotifications } from "../../pages/Dashboard/AllNotifications";
import { MyPins } from "../../pages/NetworkAnalytics/MyPins/MyPins";
import { Portfolio } from "../../pages/Portfolio/Portfolio";
import { AccessControl } from "../../pages/AccessControl/AccessControl";

export default function AppRouter() {
  const auth = useAuth();

  React.useEffect(() => {
    if (!auth?.isInitialized) {
      (async () => {
        await auth?.init();
      })();
    }
  }, [auth]);

  return (
    <Router>
      <Switch>
        {(!auth?.isAuthenticated || !auth.user) && (
          <>
            <Route exact={true} path={ROUTES.SIGN_IN} component={SignIn} />
            <Route
              exact={true}
              path={ROUTES.PASSWORD_RECOVERY}
              component={PasswordRecovery}
            />
            <Route
              exact={true}
              path={ROUTES.PASSWORD_RECOVERY_SUCCESS}
              component={PasswordRecoverySuccess}
            />
            <Route
              exact
              sensitive={false}
              render={(props: any) => {
                if (props.location.pathname.indexOf("reset-password") > -1) {
                  return <PasswordReset {...props} />;
                }
                return <Redirect from="*" to={ROUTES.SIGN_IN} />;
              }}
            />
          </>
        )}
        <ProtectedRoute path={ROUTES.DASHBOARD}>
          <Dashboard>
            <Suspense fallback={<LinearProgress />}>
              <PropertyRouter />
              <UsersRouter />
              <Route
                exact={true}
                path={ROUTES.DASHBOARD}
                component={NetworkAnalytics}
              />
              <Route
                exact={true}
                path={ROUTES.FLOOR_OVERVIEW}
                component={Occupancy}
              />
              <Route
                exact={true}
                path={ROUTES.FLOOR_PLAN_EDITOR}
                component={FloorPlanEditor}
              />
              <Route
                exact={true}
                path={ROUTES.NETWORK_GROUPS}
                component={NetworkGroups}
              />
              <Route
                exact={true}
                path={ROUTES.ALL_NOTIFICATIONS}
                component={AllNotifications}
              />
              <Route exact={true} path={ROUTES.MY_PINS} component={MyPins} />
              <Route
                exact={true}
                path={ROUTES.PORTFOLIO}
                component={Portfolio}
              />
              <Route
                exact={true}
                path={ROUTES.ACCESS_CONTROL_SYSTEM}
                component={AccessControl}
              />
            </Suspense>
          </Dashboard>
        </ProtectedRoute>
        {auth?.isAuthenticated && auth?.user && (
          <Redirect from="*" to={ROUTES.DASHBOARD} />
        )}
        <Redirect from="*" to={ROUTES.SIGN_IN} />
      </Switch>
    </Router>
  );
}
