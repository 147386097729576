import React, { useCallback, useState } from "react";
import { useFormikContext, FieldInputProps, FormikState } from "formik";
import { Grid, Select as SelectUI } from "@material-ui/core";
import { useStyles } from "./Select.styles";
import { MenuItem } from "@material-ui/core";
import { ISelectData } from "../../../../../types";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";

interface SelectProps extends FieldInputProps<any> {
  defaultValue: any;
  disabled?: boolean;
  data?: ISelectData;
  placeholder: string;
}

export function Select({
  name,
  defaultValue,
  disabled = false,
  onChange,
  placeholder,
  data,
}: SelectProps) {
  const classes = useStyles();
  const { errors, values, initialValues, isSubmitting } = useFormikContext<
    FormikState<{ [key: string]: string }>
  >();

  const [selectValue, setSelectValue] = useState(
    values[name as keyof typeof values] || defaultValue || placeholder
  );
  const isTouched =
    isSubmitting ||
    values[name as keyof typeof values] !==
      initialValues[name as keyof typeof values];

  const handleSelectOptClick = useCallback(
    (e) => {
      setSelectValue(e.target.value);
      onChange({ ...e, target: { name, value: e.target.value } });
    },
    [selectValue] // eslint-disable-line
  );
  return (
    <Grid key={name} container={true} item={true} direction="column">
      <SelectUI
        classes={{
          root: `${
            isTouched && errors[name as keyof typeof values]
              ? classes.error
              : classes.select
          }`,
          select: classes.blackText,
          icon: classes.icon,
          disabled: classes.disabled,
        }}
        MenuProps={{
          classes: {
            paper: classes.menu,
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        disabled={disabled}
        IconComponent={ExpandMoreSharpIcon}
        value={selectValue}
        disableUnderline={true}
        onChange={handleSelectOptClick}
        name={name}
        renderValue={
          selectValue !== placeholder
            ? undefined
            : () => <Placeholder>{placeholder}</Placeholder>
        }
      >
        <MenuItem
          key={-1}
          value={placeholder}
          disabled={true}
          classes={{
            root: classes.option,
            selected: classes.selected,
          }}
        >
          {placeholder}
        </MenuItem>
        {data?.map((data: any, i) => (
          <MenuItem
            key={i + 1}
            value={data.id}
            disabled={false}
            classes={{
              root: classes.option,
              selected: classes.selected,
            }}
          >
            {data.name || data.id}
          </MenuItem>
        ))}
      </SelectUI>
    </Grid>
  );
}

type PlaceholderProps = {
  children: React.ReactNode;
};

function Placeholder({ children }: PlaceholderProps) {
  const classes = useStyles();
  return <div className={classes.placeholder}>{children}</div>;
}
