import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import { Property } from "../../../../../../../types";
import { DetailLabel } from "../../DetailLabel";
import { StatusIndicator } from "../../../../../../../common/components";
// import { WorkingHoursDetailLabel } from "../../WorkingHoursDetailLabel";
import moment from "moment";
import { useLazyQuery, useMutation } from "@apollo/client";
import GQLService from "../../../../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../../../../core/context/containers/Properties.container";
import { useUserState } from "../../../../../../../core/context/containers/User.container";
import _ from "lodash";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../../../../../../common/constants/DummyProperty";

const useStyles = makeStyles((theme) => ({
  blackHeader: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    marginBottom: theme.spacing(5),
    lineHeight: theme.typography.pxToRem(24),
  },
  marginTop: {
    marginTop: theme.typography.pxToRem(45),
  },
  detailsLabel: {
    color: theme.palette.common.solidGray,
  },
}));

interface CompanyInfoProps {
  property: Property;
}

export function CompanyInfo({ property }: CompanyInfoProps) {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const { formatMessage } = useIntl();
  const dateFormat = moment(property.dateCreated).toLocaleString();
  const [propertyData, setPropertyData] = useState<any>({});
  const { setSelectedProperty } = usePropertiesState();

  const [updateBusinessEntity] = useMutation(
    GQLService.mutations.updateBusinessEntity,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allProperties,
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [getPropertyById, { data }] = useLazyQuery(
    GQLService.queries.propertyById
  );

  useEffect(() => {
    if (data?.propertyById) {
      if (userEmail === dummyUserEmail) {
        let clonedData = _.cloneDeep(data?.propertyById);
        if (clonedData?.id === DummyPropertyA.id) {
          const propA = {
            ...clonedData,
            ownerCompanyName: DummyPropertyA.ownerCompanyName,
            siteName: DummyPropertyA.siteName,
            siteServiceAddress: DummyPropertyA.siteServiceAddress,
          };
          setSelectedProperty(propA);
        } else if (clonedData?.id === DummyPropertyB.id) {
          const propB = {
            ...clonedData,
            ownerCompanyName: DummyPropertyB.ownerCompanyName,
            siteName: DummyPropertyB.siteName,
            siteServiceAddress: DummyPropertyB.siteServiceAddress,
          };
          setSelectedProperty(propB);
        } else if (clonedData?.id === DummyPropertyC.id) {
          const propC = {
            ...clonedData,
            ownerCompanyName: DummyPropertyC.ownerCompanyName,
            siteName: DummyPropertyC.siteName,
            siteServiceAddress: DummyPropertyC.siteServiceAddress,
          };
          setSelectedProperty(propC);
        } else if (clonedData?.id === DummyPropertyD.id) {
          const propD = {
            ...clonedData,
            ownerCompanyName: DummyPropertyD.ownerCompanyName,
            siteName: DummyPropertyD.siteName,
            siteServiceAddress: DummyPropertyD.siteServiceAddress,
          };
          setSelectedProperty(propD);
        } else {
          setSelectedProperty(clonedData);
        }
      } else {
        setSelectedProperty(data?.propertyById);
      }
    }
  }, [data, userEmail]);

  useEffect(() => {
    if (property) {
      if (userEmail === dummyUserEmail) {
        let clonedData = _.cloneDeep(property);
        if (clonedData?.id === DummyPropertyA.id) {
          const propA = {
            ...clonedData,
            ownerCompanyName: DummyPropertyA.ownerCompanyName,
            siteName: DummyPropertyA.siteName,
            siteServiceAddress: DummyPropertyA.siteServiceAddress,
          };
          setPropertyData(propA);
        } else if (clonedData?.id === DummyPropertyB.id) {
          const propB = {
            ...clonedData,
            ownerCompanyName: DummyPropertyB.ownerCompanyName,
            siteName: DummyPropertyB.siteName,
            siteServiceAddress: DummyPropertyB.siteServiceAddress,
          };
          setPropertyData(propB);
        } else if (clonedData?.id === DummyPropertyC.id) {
          const propC = {
            ...clonedData,
            ownerCompanyName: DummyPropertyC.ownerCompanyName,
            siteName: DummyPropertyC.siteName,
            siteServiceAddress: DummyPropertyC.siteServiceAddress,
          };
          setPropertyData(propC);
        } else if (clonedData?.id === DummyPropertyD.id) {
          const propD = {
            ...clonedData,
            ownerCompanyName: DummyPropertyD.ownerCompanyName,
            siteName: DummyPropertyD.siteName,
            siteServiceAddress: DummyPropertyD.siteServiceAddress,
          };
          setPropertyData(propD);
        } else {
          setPropertyData(property);
        }
      } else {
        setPropertyData(property);
      }
    }
  }, [property, userEmail]);

  const toggleActiveStatus = useCallback(async () => {
    let isMounted = true;
    if (isMounted) {
      try {
        await updateBusinessEntity({
          variables: {
            ...property,
            propertyId: property?.id!,
            companyStatus: !property?.companyStatus!,
          },
        });
        getPropertyById({ variables: { propertyId: property?.id! } });
      } catch (err) {
        console.log(err);
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Grid className={classes.marginTop}>
      <Typography className={classes.blackHeader}>
        {formatMessage({
          id: "companyInfo.header",
          defaultMessage: "Company Information",
          description: "company information header",
        })}
      </Typography>
      <DetailLabel
        label={formatMessage({
          id: "companyInfo.property.legalCompanyName.label",
          defaultMessage: "Owner Company name",
          description: "Owner company information legal company name label",
        })}
        value={propertyData.ownerCompanyName || ""}
      />
      <DetailLabel
        label={formatMessage({
          id: "accountInfo.status.header",
          defaultMessage: "Status",
          description: "status header",
        })}
      >
        <Grid container={true} alignItems="center">
          <StatusIndicator
            uid={propertyData.id}
            isActive={propertyData?.companyStatus!}
            canShowStatusLabel={true}
            onChangeFn={toggleActiveStatus}
          />
        </Grid>
      </DetailLabel>
      <DetailLabel
        label={formatMessage({
          id: "accountInfo.businessEntity.header",
          defaultMessage: "Date Created",
          description: "date created header",
        })}
        value={dateFormat}
      />
      {/* <DetailLabel
        label={formatMessage({
          id: "companyInfo.property.website.label",
          defaultMessage: "Website",
          description: "company information website label",
        })}
        value={property.companyWebsite || ""}
      />
      {/* <DetailLabel
        label={formatMessage({
          id: "accountInfo.status.header",
          defaultMessage: "Status",
          description: "status header",
        })}
      >
        <Grid container={true} alignItems="center">
          <StatusIndicator
            uid={property.id}
            isActive={property.companyStatus}
            canShowStatusLabel={true}
          />
        </Grid>
      </DetailLabel> */}
    </Grid>
  );
}
