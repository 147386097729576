import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Grid, Typography } from "@material-ui/core";
import {
  AccessPoint,
  Property,
  RadioBand,
  radioBandMap,
  Role,
} from "../../../../../types";
import { useStyles } from "./SSIDs.styles";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../common/constants/Routing";
import clsx from "clsx";
import { useAuth } from "../../../../../core/context/containers/Auth.container";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../../../core/services/GQL.service";
import { useAccessPointState } from "../../../../../core/context/containers/AccessPoints.container";
import { SSIDDetailLabel } from "./SSIDDetailLabel";

interface SSIDsProps {
  accessPoint: AccessPoint;
  property: Property;
}

export function SSIDs({ accessPoint, property }: SSIDsProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedAccessPoint, setSelectedAccessPoint } = useAccessPointState();
  const [radioData, setRadioData] = useState<AccessPoint>();

  const auth = useAuth();

  const [
    getaAccessPointById,
    { loading: loadingAP, data: dataAP, error: errorAP },
  ] = useLazyQuery(GQLService.queries.accessPointById, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getaAccessPointById({
      variables: { accessPointId: selectedAccessPoint?.id },
    });
  }, []); //selectedAccessPoint

  useEffect(() => {
    if (dataAP) {
      setRadioData(dataAP?.accessPointById);
      setSelectedAccessPoint(dataAP?.accessPointById);
    }
  }, [dataAP]);

  const handleEditClick = useCallback(() => {
    history.push(
      ROUTES.ACCESS_POINT_EDIT.replace(":uid", property?.id!).replace(
        ":accesspoint",
        accessPoint?.id.split(":")[1]!
      ) + "#1"
    );
  }, []);

  const createAPButton = useCallback(() => {
    return (
      <Button className={classes.button} onClick={handleEditClick}>
        {formatMessage({
          id: "propertyWorkplace.button.edit",
          defaultMessage: "Edit",
          description: "edit button label",
        })}
      </Button>
    );
  }, []);

  const radio1Ssids = radioData?.radio1?.ssids!; //accessPoint.radio1?.ssids;
  const radio2Ssids = radioData?.radio2?.ssids!; //accessPoint.radio2?.ssids;

  return (
    <Grid container md={12} sm={12} className={classes.main}>
      <Grid container md={12} sm={12} classes={{ root: classes.container }}>
        <Typography className={classes.blackLabel}>
          {formatMessage({
            id: "accessPoints.SSIDs.radio1",
            defaultMessage: "SSIDs for RADIO 1 ",
          })}
          (
          {accessPoint?.radio1?.band !== RadioBand.NONE &&
            radioBandMap.get(accessPoint?.radio1?.band)}
          )
        </Typography>
        <SSIDDetailLabel
          label=""
          children={radio1Ssids?.map((ssid: any) => (
            <Grid item classes={{ root: classes.ssid }}>
              {ssid?.name || ""}
            </Grid>
          ))}
        />
      </Grid>
      <Grid
        container
        md={12}
        sm={12}
        classes={{ root: classes.container }}
        style={{ margin: "0px" }}
      >
        <Typography className={clsx(classes.blackLabel, classes.radio2Label)}>
          {formatMessage({
            id: "accessPoints.SSIDs.radio2",
            defaultMessage: "SSIDs for RADIO 2 ",
          })}
          (
          {accessPoint?.radio2?.band !== RadioBand.NONE &&
            radioBandMap.get(accessPoint?.radio2?.band)}
          )
        </Typography>
        <SSIDDetailLabel
          label=""
          children={radio2Ssids?.map((ssid: any) => (
            <Grid item classes={{ root: classes.ssid }}>
              {ssid?.name || ""}
            </Grid>
          ))}
        />
      </Grid>
      {auth?.canSee(
        auth?.user?.role?.toUpperCase() as Role,
        "access_points:edit",
        createAPButton()
      )}
    </Grid>
  );
}
