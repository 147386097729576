import React, { useCallback, useState } from "react";
import { FieldInputProps, useFormikContext } from "formik";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./FullyCoveredSpaceCheckbox.styles";
import { useIntl } from "react-intl";
import { useSpacesState } from "../../../../core/context/containers/Spaces.container";

interface FullyCoveredSpaceCheckboxProps extends FieldInputProps<any> {
  defaultValue: boolean;
  disabled?: boolean;
}

export function FullyCoveredSpaceCheckbox({
  name,
  defaultValue = false,
  disabled = false,
  onChange,
}: FullyCoveredSpaceCheckboxProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { values, initialValues, isSubmitting } = useFormikContext<{
    [key: string]: string;
  }>();
  const isTouched = isSubmitting || values[name] !== initialValues[name];
  const [isSwitchActive, setIsSwitchActive] = useState<boolean>(defaultValue);
  const { setIsNetFlowIsChecked } = useSpacesState();
  const handleChange = useCallback(
    (e) => {
      setIsSwitchActive(!isSwitchActive);
      setIsNetFlowIsChecked(!isSwitchActive);
      onChange!({
        ...e,
        target: { name, value: !isSwitchActive },
      });
      //If netflow is unchecked, reset time will be null.
      if (isSwitchActive == true) {
        onChange!({
          ...e,
          target: { name: "resetTime", value: undefined },
        });
      }
    },
    [name, isSwitchActive]
  );

  return (
    <Grid key={name} container={true} item={true} direction="row">
      <Grid item className={classes.root}>
        <Checkbox
          value={isSwitchActive}
          checked={isSwitchActive}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          classes={{ root: classes.checkbox }}
          disabled={disabled}
          onChange={handleChange}
        />
        <Typography className={classes.fullyCoveredParagraph}>
          {formatMessage({
            id: "fullyCoveredSpace.label",
            defaultMessage:
              "Space is Fully Enclosed by Tripwires (Enabled Net Flow)",
            description:
              "Space is Fully Enclosed by Tripwires (Enabled Net Flow)",
          })}
        </Typography>
      </Grid>
    </Grid>
  );
}
