import Rules from "../../common/constants/Rules";
import { parseJwt } from "../../common/utils/Auth.utils";
import {
  IAuthClient,
  LoginWithEmailAndPasswordParams,
  IUser,
  CheckAccessOptions,
  OnUpdateHandler,
  AuthResponse,
} from "../../types/auth";
import { availableScopes } from "../../types/roles";
import API from "../api/api";

export interface IApiResponse<T, E = Error> {
  data: T | null;
  error?: E;
}

export type RefreshTokenOptions = {
  checkIntervalSeconds: number;
  remainingTimeLimitSeconds: number;
};

export type IdentityResponse = IUser;
export type LoginWithEmailAndPasswordResponse = {
  access_token: string;
  refresh_token: string;
};
export interface RefreshTokenError extends Error {
  isExpired: boolean;
}

export interface IRestAuthEndpoints {
  loginWithEmailAndPassword: (
    params: LoginWithEmailAndPasswordParams
  ) => Promise<IApiResponse<AuthResponse>>;

  refreshToken: (
    refreshToken: string
  ) => Promise<IApiResponse<AuthResponse, RefreshTokenError>>;
}

export default class RestAuthClient implements IAuthClient {
  private endpoints: IRestAuthEndpoints;
  private refreshTokenOptions: RefreshTokenOptions;
  private refreshIntervalHandle: NodeJS.Timeout | null = null;
  private onUpdateHandler: OnUpdateHandler = () => {};

  storage: Storage;
  isInitialized: boolean = false;
  isAuthenticated: boolean = false;
  user: IUser | null = null;

  constructor(
    endpoints: IRestAuthEndpoints,
    storage: Storage,
    refreshTokenOptions: Partial<RefreshTokenOptions> = {}
  ) {
    this.endpoints = endpoints;
    this.storage = storage;
    this.refreshTokenOptions = {
      remainingTimeLimitSeconds:
        refreshTokenOptions.remainingTimeLimitSeconds || 10,
      checkIntervalSeconds: refreshTokenOptions.checkIntervalSeconds || 5,
    };
  }

  get refreshToken() {
    return this.storage.getItem("refreshToken") ?? "";
  }

  set refreshToken(newValue: string) {
    if (newValue) {
      this.storage.setItem("refreshToken", newValue);
    } else {
      this.storage.removeItem("refreshToken");
    }
  }

  get token() {
    return this.storage.getItem("accessToken") ?? "";
  }

  set token(newValue: string) {
    if (newValue) {
      this.storage.setItem("accessToken", newValue);
    } else {
      this.storage.removeItem("accessToken");
    }
  }
  get accessTokenObject() {
    return this.parseAccessToken(this.token);
  }

  private setAutheticatedUser(user: IUser) {
    this.user = user;
    this.storage.setItem("authUser", JSON.stringify(user));
  }

  private parseAccessToken(accessToken: string) {
    const parsedToken = parseJwt(accessToken);
    return parsedToken;
  }

  private setTokens(accessToken: string, refreshToken: string) {
    // console.log(parseJwt(accessToken))
    // this.refreshTokenOptions.checkIntervalSeconds = expiresIn || this.refreshTokenOptions.checkIntervalSeconds;
    this.token = accessToken;
    this.refreshToken = refreshToken;
  }

  private clearTokens() {
    this.token = "";
    this.refreshToken = "";
    delete API.defaults.headers.Authorization;
  }

  private retrieveTokens() {
    const accessToken = this.storage.getItem("accessToken") || "";

    const refreshToken = this.storage.getItem("refreshToken") || "";
    const authUser = this.storage.getItem("authUser") || "";
    this.setAutheticatedUser(JSON.parse(authUser));
    this.setTokens(accessToken, refreshToken);
    this.isAuthenticated = true;
  }

  async init() {
    if (
      this.storage.getItem("refreshToken") &&
      this.storage.getItem("accessToken") &&
      this.storage.getItem("authUser")
    ) {
      this.retrieveTokens();
    }
    if (this.refreshToken && this.token && this.user) {
      try {
        if (this.shouldRefreshTokens()) {
          await this.refreshTokens();
        }
        this.startCheckingTokens();
      } catch (ex) {
        await this.logout();
      }
    } else {
      await this.logout();
    }

    this.isInitialized = true;
    this.onUpdateHandler();
  }

  async loginWithEmailAndPassword(params: LoginWithEmailAndPasswordParams) {
    const { username, password } = params;

    const { data, error } = await this.endpoints.loginWithEmailAndPassword({
      username,
      password,
    });

    if (error) {
      throw error;
    }

    this.isAuthenticated = true;
    const user = {
      email: data?.username,
      fullName: parseJwt(data?.access_token!).fullName,
      role: data?.roles[0],
      defaultProperty: {
        id: parseJwt(data?.access_token!).defaultProperty,
      },
    };
    //   this.user = data;
    this.setTokens(data!.access_token, data!.refresh_token);
    this.setAutheticatedUser(user);
    this.startCheckingTokens();
    this.onUpdateHandler();
  }

  async logout() {
    this.isAuthenticated = false;
    this.user = null;
    localStorage.removeItem("authUser");
    this.clearTokens();
    this.stopCheckingTokens();
    this.onUpdateHandler();
  }

  shouldRefreshTokens() {
    // JWT tokens have 'exp' field set in seconds, but Date.now() returns milliseconds, so we need to adjust.
    const currentTimeInSeconds = Date.now() / 1000;
    const remainingTimeSeconds =
      this.accessTokenObject.exp - currentTimeInSeconds;
    return (
      remainingTimeSeconds <= this.refreshTokenOptions.remainingTimeLimitSeconds
    );
  }

  async refreshTokens() {
    if (this.refreshToken) {
      const { data, error } = await this.endpoints.refreshToken(
        this.refreshToken
      );
      if (error) {
        if (error.isExpired) {
          this.logout();
        }
        throw error;
      }
      this.isAuthenticated = true;
      this.isInitialized = true;
      this.user = JSON.parse(this.storage.getItem("authUser") || "");
      this.setTokens(data!.access_token, data!.refresh_token);
      this.startCheckingTokens();
      this.onUpdateHandler();
    }
  }

  startCheckingTokens() {
    this.stopCheckingTokens();
    this.refreshIntervalHandle = setInterval(async () => {
      if (this.shouldRefreshTokens()) {
        try {
          await this.refreshTokens();
        } catch (ex) {
          // Ignore any errors, because we will retry if necessary after a few seconds
        }
      }
    }, this.refreshTokenOptions.checkIntervalSeconds * 1000);
  }

  stopCheckingTokens() {
    if (this.refreshIntervalHandle) {
      clearInterval(this.refreshIntervalHandle);
      this.refreshIntervalHandle = null;
    }
  }

  getIsInitialized() {
    return this.isInitialized;
  }

  getIsAuthenticated() {
    return this.isAuthenticated;
  }

  getToken() {
    return this.token;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  getUser() {
    return this.user;
  }

  updateUser(user: IUser | null) {
    this.user = user;
    user && this.setAutheticatedUser(user);
  }

  async getFreshToken() {
    if (this.shouldRefreshTokens()) {
      await this.refreshTokens();
    }

    return this.token;
  }

  canSee = (
    role: keyof typeof Rules,
    action: availableScopes[number],
    Component: JSX.Element
  ) => {
    if (!Rules[role as keyof typeof Rules]) {
      return null;
    }
    const availableScopes = Rules[role as keyof typeof Rules].static;
    if (availableScopes.includes(action)) {
      return Component;
    }
    return null;
  };

  isAnyScopeAvaialable = (
    role: keyof typeof Rules,
    actions: availableScopes[number][]
  ) => {
    if (!Rules[role as keyof typeof Rules]) {
      return false;
    }
    const availableScopes = Rules[role as keyof typeof Rules].static;
    return actions.some((action) => availableScopes.includes(action));
  };

  checkAccess(options: CheckAccessOptions) {
    console.warn(
      "RestApiClient.checkAccess should be implemented by extending this class in your project."
    );
    return this.isAuthenticated;
  }

  registerOnUpdateHandler(handler: OnUpdateHandler) {
    this.onUpdateHandler = handler;
  }
}
