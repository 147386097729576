import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useIntl } from "react-intl";

import { AccessPointsStatistics } from "../Statistics/AccessPointsStatistics";
import { useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useFloorState } from "../../../../core/context/containers/Floor.container";
import { DataUsage } from "../Statistics/DataUsage/DataUsage";
import clsx from "clsx";
import AirQuality from "../Statistics/AirQuality/AirQuality";
import { useStyles } from "./AccessPoints.styles";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { DevicesUsageSummary } from "../Statistics/Summary/Summary2";
import {
  DashboardCardAverageAndCount,
  DashboardCardAverageAndCountTripwire,
  DashboardCardAverageAndCountZone,
  Floor,
} from "../../../../types";

interface NetworkAnalyticsAccessPointsProps {
  timeFrom?: string;
  timeTo?: string;
  selectedTime?: string;
  connectedDevicesWithFloor?: any;
  selectedFloorNew?: Floor | undefined;
  selectedSpace?: any;
  handleSelectedValue?: any;
  userPreferenceFilterSummary?: any;
  loadingUserFilterPrefernceSummary?: any;
  dropDownValue?: any;
  loadingUserFilterPrefernceT?: any;
  handleIsPinned?: (el: any) => void;
  dataDashboardCardAverageAndCount?: DashboardCardAverageAndCount;
  dataDashboardCardAverageAndCountTripwire?: DashboardCardAverageAndCountTripwire;
  dataDashboardCardAverageAndCountZone?: DashboardCardAverageAndCountZone;
  offHoursIsActive?: boolean;
}

export function NetworkAnalyticsAccessPoints({
  timeFrom,
  timeTo,
  selectedTime,
  connectedDevicesWithFloor,
  selectedFloorNew,
  selectedSpace,
  handleSelectedValue,
  userPreferenceFilterSummary,
  loadingUserFilterPrefernceSummary,
  dropDownValue,
  loadingUserFilterPrefernceT,
  handleIsPinned,
  dataDashboardCardAverageAndCount,
  dataDashboardCardAverageAndCountTripwire,
  dataDashboardCardAverageAndCountZone,
  offHoursIsActive,
}: NetworkAnalyticsAccessPointsProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { setLoading, setFloors } = useFloorState();
  const { selectedProperty } = usePropertiesState();

  const {
    loading: loadingF,
    error: errorF,
    data: dataF,
  } = useQuery(GQLService.queries.allActiveFloors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loadingF);
  }, [loadingF]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setFloors(dataF?.floorsByPropertyId || []);
    }
    return () => {
      isMounted = false;
    };
  }, [dataF, selectedProperty]);

  return (
    <Grid container={true} md={12} sm={12}>
      <Grid container={true} md={12} sm={12} wrap="nowrap">
        <Grid
          container={true}
          md={12}
          className={clsx(classes.containerSummary, classes.containerLeft)}
        >
          <DevicesUsageSummary
            timeFrom={timeFrom}
            timeTo={timeTo}
            selectedTime={selectedTime}
            connectedDevicesWithFloor={connectedDevicesWithFloor}
            selectedSpace={selectedSpace}
            handleIsPinned={handleIsPinned}
            dataDashboardCardAverageAndCount={dataDashboardCardAverageAndCount}
            dataDashboardCardAverageAndCountTripwire={
              dataDashboardCardAverageAndCountTripwire
            }
            dataDashboardCardAverageAndCountZone={
              dataDashboardCardAverageAndCountZone
            }
          />
        </Grid>
      </Grid>
      <Grid
        container={true}
        md={12}
        sm={9}
        className={clsx(
          classes.container,
          classes.containerLeft,
          classes.accessPointStatisticsContainer
        )}
      >
        <AccessPointsStatistics
          timeFrom={timeFrom}
          timeTo={timeTo}
          selectedTime={selectedTime}
          selectedFloorNew={selectedFloorNew}
          selectedSpace={selectedSpace}
          handleSelectedValue={handleSelectedValue}
          userPreferenceFilterSummary={userPreferenceFilterSummary}
          loadingUserFilterPrefernceSummary={loadingUserFilterPrefernceSummary}
          dropDownValue={dropDownValue}
          loadingUserFilterPrefernceT={loadingUserFilterPrefernceT}
          offHoursIsActive={offHoursIsActive}
        />
      </Grid>
      <Grid
        container={true}
        className={clsx(classes.container, classes.containerLeft)}
        md={12}
        sm={12}
      >
        <DataUsage
          timeFrom={timeFrom}
          timeTo={timeTo}
          selectedTime={selectedTime}
        />
      </Grid>
    </Grid>
  );
}
