import React, { useState, useCallback } from "react";
import { useIntl } from "react-intl";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  AccordionActions,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import LayersIcon from "@material-ui/icons/Layers";
import { Floor } from "../../../../types";
import {
  ImageUploadedIndicator,
  StatusIndicator,
} from "../../../../common/components";
import { EditFloorPlan } from "./EditFloorPlan/EditFloorPlan";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import GQLService from "../../../../core/services/GQL.service";

import { FloorsMenu } from "./FloorsMenuItem";
import { useFloorState } from "../../../../core/context/containers/Floor.container";
import { useMutation } from "@apollo/client";

import { useHistory } from "react-router-dom";
import { useFloorPlanEditorState } from "../../../../core/context/containers/FloorPlanEditor/FloorPlanEditor.container";
import { ROUTES } from "../../../../common/constants/Routing";
import { useModal } from "../../../../core/hooks/Modal";
import { FloorPlanRemoveModal } from "./FloorPlanRemoveModal";
import { listStyles } from "../../../../common/styles/lists";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../../core/context/containers/Auth.container";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
  extendedListSubheader: {
    ...listStyles.extendedListSubheader,
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  icon: { color: theme.palette.common.funBlue },
  container: {
    margin: "0 44px",
    justifyContent: "space-between",
  },
  cell: {
    minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  rightContainer: {
    width: "100%",
  },
  floorPlan: {
    width: 434,
    height: 369,
    objectFit: "contain",
  },
  floorInfo: {
    padding: "10px",
  },
  content: {},
  accordionDetails: {
    paddingBottom: "40px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    padding: "10px",
    flexDirection: "column",
  },
}));

interface FloorsListItemProps {
  item: Floor;
}

export function FloorsListItem({ item }: FloorsListItemProps) {
  const classes = useStyles();
  const { selectedProperty } = usePropertiesState();
  const { floors, setSelectedFloor } = useFloorState();
  const { setFloor } = useFloorPlanEditorState();

  const { formatMessage } = useIntl();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const history = useHistory();
  const { show, hide, RenderModal } = useModal();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const auth = useAuth();

  const [updateFloor] = useMutation(GQLService.mutations.editFloor, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allFloors,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const toggleActiveStatus = useCallback(
    async () => {
      try {
        await updateFloor({
          variables: { ...item, active: !item.active, floorId: item.id },
        });
      } catch (err) {
        enqueueSnackbar(err.message, {
          variant: "error",
          persist: true,
        });
      }
    },
    [item] //eslint-disable-line
  );
  const handleImageRemoveCallback = useCallback(
    async () => {
      show();
    },
    [floors] //eslint-disable-line
  );

  const handleExpand = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const handleViewClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>, id: string) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedFloor(item);
      setFloor(item);

      history.push(
        ROUTES.PROPERTY_FLOOR.replace(":uid", selectedProperty?.id!).replace(
          ":floorUid",
          item.floorNumber.toString()
        )
      );
    },
    [item]
  );

  return (
    <Accordion key={item.id} expanded={isExpanded}>
      <AccordionSummary onClick={handleExpand}>
        <Grid
          container={true}
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <Grid
            container={true}
            item={true}
            md={1}
            sm={3}
            justify="space-around"
            wrap="nowrap"
            onClick={handleExpand}
            className={classes.headerWrapper}
          >
            {isExpanded ? (
              <ExpandLess id={`expandLess-FLOOR:${item.id}`}  className={classes.expandIcon} />
            ) : (
              <ExpandMore id={`expandMore-FLOOR:-${item.id}`} className={classes.expandIcon} />
            )}
            <LayersIcon className={classes.icon} />
            <Typography className={classes.name} onClick={(e) => handleViewClick(e, item.id)}>{item.floorNumber}</Typography>
          </Grid>
          <Grid
            container={true}
            item={true}
            md={3}
            sm={3}
            justify="flex-end"
            className={classes.headerWrapper}>
            <AccordionActions
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              classes={{ root: classes.rightContainer }}
            >
              <ImageUploadedIndicator
                plan={item.floorPlanUploaded ? true : false}
                configured={item.floorConfigured}
              />
              <Grid item className={classes.flex}>
                {selectedProperty && (
                  <StatusIndicator
                    uid={item?.id}
                    isActive={item.active}
                    canShowStatusLabel={false}
                  />
                )}
              </Grid>
              {auth?.isAnyScopeAvaialable(auth.user?.role!, [
                "floors:edit",
                "floors:delete",
                "floors:view",
              ]) && <FloorsMenu floor={item} index={item.id}/>}
            </AccordionActions>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container={true} className={classes.container} spacing={2}>
          <Grid>
            <Grid item className={classes.floorInfo}>
              <Typography className={classes.subheader}>
                {formatMessage({
                  id: "floorListItem.label.floor",
                  defaultMessage: "Floor",
                })}
              </Typography>
              <Typography className={classes.content}>
                {item.floorNumber}
              </Typography>
            </Grid>
            {/* <Grid item className={classes.floorInfo}>
              <Typography className={classes.subheader}>
                {formatMessage({
                  id: "floorListItem.label.capacity",
                  defaultMessage: "Floor Capacity",
                })}
              </Typography>
              <Typography className={classes.content}>
                {item.capacity}
              </Typography>
            </Grid> */}
            <Grid item className={classes.floorInfo}>
              <Typography className={classes.subheader}>
                {formatMessage({
                  id: "floorListItem.label.name",
                  defaultMessage: "Floor Name",
                })}
              </Typography>
              <Typography className={classes.content}>{item.name}</Typography>
            </Grid>
            {/* <Grid item className={classes.floorInfo}>
              <Typography className={classes.subheader}>
                {formatMessage({
                  id: "floorListItem.label.signalStrength",
                  defaultMessage: "Signal Strength",
                })}
              </Typography>
              <Typography className={classes.content}>
                {item.signalStrength}
              </Typography>
            </Grid> */}
            <Grid item className={classes.status}>
              <Typography className={classes.subheader}>
                {formatMessage({
                  id: "floorListItem.label.status",
                  defaultMessage: "Status",
                })}
              </Typography>
              <Grid className={classes.flex}>
                {selectedProperty && (
                  <StatusIndicator
                    uid={item?.id}
                    isActive={item.active}
                    canShowStatusLabel={true}
                    onChangeFn={toggleActiveStatus}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Cell
              label={formatMessage({
                id: "floorListItem.label",
                defaultMessage: "FLOOR PLAN",
                description: "floor plan label",
              })}
              value={item.name}
            >
              <EditFloorPlan
                floor={item}
                property={selectedProperty}
                currentUrl={item.floorPlanUrl}
                name={"string"}
                label={""}
                isExpanded={isExpanded}
                handleImageRemoveCallback={handleImageRemoveCallback}
              />
            </Cell>
          </Grid>
        </Grid>
      </AccordionDetails>
      <RenderModal>
        <FloorPlanRemoveModal
          propertyId={selectedProperty?.id!}
          floorId={item.id}
          hide={hide}
        />
      </RenderModal>
    </Accordion>
  );
}

interface CellProps {
  label: string;
  value?: string | number;
  children?: React.ReactNode;
}

function Cell({ label, value, children }: CellProps) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.cell}
      container={true}
      item={true}
      md={4}
      sm={12}
      direction="column"
    >
      <Grid>
        <Typography className={classes.cellLabel}>{label}</Typography>
        {children ? children : <Typography>{value ? value : "-"}</Typography>}
      </Grid>
    </Grid>
  );
}
