import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { PageHeader } from "../../common/components/PageHeader/PageHeader";
import { useIntl } from "react-intl";
import { CustomModal, SwipePanel } from "../../common/components";
import { Associated } from "./Associated";
import { UnAssociated } from "./UnAssociated";
import { useStyles } from "./AccessControl.styles";
import { ACSModal } from "./ACSModal";

export function AccessControl() {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [checkedData, setCheckedData] = useState([]);
  const [tabType, setTabType] = useState<string>("");

  return (
    <Grid container>
      <PageHeader
        title={formatMessage({
          id: "access.control.header",
          defaultMessage: "Access Control System",
          description: "Access Control System",
        })}
      />
      <Grid className={classes.saveButtonGrid}>
        <Button
          className={classes.saveButton}
          onClick={() => setToggleModal(true)}
          disabled={checkedData?.length > 0 ? false : true}
          style={{
            color: "#FFFFFF",
            opacity: checkedData?.length > 0 ? 1 : 0.6,
          }}
        >
          Associate with Property
        </Button>
      </Grid>
      <Grid style={{ width: "100%" }}>
        <SwipePanel
          tabNames={[
            formatMessage({
              id: "access.control.tab.associated",
              defaultMessage: "Associated",
              description: "Associated tab label",
            }),
            formatMessage({
              id: "access.control.tab.Unassociated",
              defaultMessage: "Un-Associated",
              description: "Unassociated tab label",
            }),
          ]}
        >
          <Associated setCheckedData={setCheckedData} setTabType={setTabType} />
          <UnAssociated
            setCheckedData={setCheckedData}
            setTabType={setTabType}
          />
        </SwipePanel>
      </Grid>
      <CustomModal open={toggleModal}>
        <ACSModal
          setToggleModal={setToggleModal}
          checkedData={checkedData}
          tabType={tabType}
          setCheckedData={setCheckedData}
        />
      </CustomModal>
    </Grid>
  );
}
