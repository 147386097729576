import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  select: {
    width: "100% !important",
    backgroundColor: theme.palette.common.solidGray,
    marginBottom: "5px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.solidGray,
      borderRadius: 5,
    },
    alignItems: "center",
    height: 5,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    justifyContent: "left",
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    width: "100% !important",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
    marginTop: theme.spacing(1),
  },
  icon: {
    color: "#2F56A1",
    right: 7,
    width: 18,
  },
  error: {
    width: "100% !important",
    backgroundColor: theme.palette.common.InputBg,
    marginBottom: "10px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 28,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
    borderColor: theme.palette.common.cinnabar,
  },
  root: {
    padding: 16,
    backgroundColor: `${theme.palette.common.concrete}`,
  },
  errorLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.cinnabar,
    marginLeft: 10,
    position: "absolute",
    top: "87%",
    right: "50%",
  },
  text: {
    fontSize: 12,
    fontWeight: 500,
    color: `${theme.palette.common.mediumBlack}`,
    display: "inline-block",
    paddingTop: "15px",
  },
  textRed: {
    fontSize: 12,
    fontWeight: 500,
    color: `${theme.palette.common.red}`,
    display: "inline-block",
    paddingLeft: 3,
  },
  inputStyle: {
    width: "50%",
    border: `1px solid ${theme.palette.common.solidGray}`,
  },
  errorInput: {
    width: "50%",
    border: `1px solid ${theme.palette.common.red}`,
  },
  switch: {
    "& span.MuiSwitch-root": {
      height: "15px !important",
      width: 30,
      padding: 0,
      marginTop: 4,
      "& span.MuiButtonBase-root": {
        height: "12px !important",
        padding: "0 3px",
      },
      "& span.MuiSwitch-thumb": {
        width: 9,
        height: 9,
      },
      "& span.MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(13px)",
      },
    },
    "& div": {
      fontSize: 12,
    },
  },
}));
