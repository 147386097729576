import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import { ROUTES } from "../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { Property } from "../../../../types";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { useFiltersState } from "../../../../core/context/containers/Filters.container";
import { useNotificationsState } from "../../../../core/context/containers/Notification.continer";
// import { useAuth } from "../../../../core/context/containers/Auth.container2";

const useStyles = makeStyles((theme) => ({
  menuList: {
    width: 238,
    outline: "none",
    paddingBottom: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
  },
  versionNo: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.common.nobel,
    borderTop: `1px solid ${theme.palette.common.nobel}`,
  },
  button: {
    color: theme.palette.common.switchBlue,
    padding: theme.typography.pxToRem(4),
  },
}));

export function ContextMenu() {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const auth = useAuth();
  const { properties, setSelectedProperty } = usePropertiesState();
  const { setNotificationIsSeen } = useNotificationsState();
  // const {
  //   setSelectedProperty: setSelectedPropertyInFilters,
  // } = useFiltersState();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState();

  const handleToggleMenu = useCallback(
    (event: any) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );
  const open = Boolean(anchorEl);

  const goToAccount = useCallback(
    () => {
      history.replace(ROUTES.ACCOUNT);
    },
    [history] // eslint-disable-line
  );

  const handleSignOut = useCallback(
    () => {
      auth?.logout();
      const property = properties.find(
        (property: Property) => property.id === auth?.user?.defaultProperty?.id
      );
      setSelectedProperty(property || null);
      setNotificationIsSeen(null);
      // setSelectedPropertyInFilters(undefined);
      history.replace(ROUTES.SIGN_IN);
    },
    [history] // eslint-disable-line
  );

  const openSupportEmailWindow = useCallback((e) => {
    e.preventDefault();
    window.open(`mailto:support@joindigital.com`);
  }, []);

  return (
    <>
      <IconButton
        aria-owns={open ? "menu-appbar" : ""}
        aria-haspopup="true"
        className={classes.button}
        onClick={handleToggleMenu}
      >
        <ExpandMore />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          <MenuItem className={classes.menuItem} onClick={goToAccount}>
            {formatMessage({
              id: "contextMenu.myAccount",
              defaultMessage: "My Account",
              description: "context menu my account label",
            })}
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={openSupportEmailWindow}
          >
            {formatMessage({
              id: "contextMenu.support",
              defaultMessage: "Support",
              description: "context menu support label",
            })}
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={handleSignOut}>
            {formatMessage({
              id: "contextMenu.logOut",
              defaultMessage: "Log Out",
              description: "context menu log out label",
            })}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
