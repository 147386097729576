import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { ChipList } from "../../../common/components";

import { Button, Grid, Input, InputLabel, Typography } from "@material-ui/core";
import { useStyles } from "../NetworkSettings.styles";
import GQLService from "../../../core/services/GQL.service";
import { useMutation, useQuery } from "@apollo/client";
import clsx from "clsx";
import RemoveHostModal from "./RemoveHostModal";

export default function IgnoredList() {
  const [modal, showModal] = useState<boolean>(false);
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [networkGroups, setNetworkGroups] = useState<any[]>([]);

  const { data, loading, error } = useQuery(
    GQLService.queries.ignoredNetworkHosts,
    {
      fetchPolicy: "cache-first",
    }
  );

  const [createHost] = useMutation(GQLService.mutations.createNetworkHost, {
    refetchQueries: () => [
      {
        query: GQLService.queries.ignoredNetworkHosts,
      },
    ],
    awaitRefetchQueries: true,
  });

  const [removeHost] = useMutation(GQLService.mutations.deleteNetworkHost, {
    refetchQueries: () => [
      {
        query: GQLService.queries.ignoredNetworkHosts,
      },
    ],
    awaitRefetchQueries: true,
  });

  const placeholder = formatMessage({
    id: "groupname.placeholder.settings",
    defaultMessage: "Host name",
    description: "Host name",
  });

  const [hostName, setHostName] = useState<string>("");
  const [hostToDelete, setHostToDelete] = useState<any>({});

  const addHost = useCallback(async () => {
    try {
      await createHost({
        variables: {
          hostName,
          groupId: null,
          ignore: true,
        },
      });
    } catch (err) {
      console.log(err);
    }
  }, [hostName]);

  useEffect(() => {
    if (!loading) {
      const netWorkGroups = data.ignoreNetworkHosts.map((group: any) => ({
        ...group,
        name: group.hostName,
      }));
      setNetworkGroups(netWorkGroups);
    }
  }, [loading, data]);

  const openDeleteModal = useCallback(
    async (host: any) => {
      try {
        setHostToDelete(host);
        showModal(true);
      } catch (err) {
        console.log(err);
      }
    },
    [hostToDelete]
  );

  const removeHostConfirmed = useCallback(
    async (id: string) => {
      try {
        await removeHost({ variables: { id } });
        showModal(false);
        setHostToDelete(undefined);
      } catch (err) {
        console.log(err);
      }
    },
    [hostToDelete]
  );

  const removeHostRejected = useCallback(() => {
    showModal(false);
    setHostToDelete(undefined);
  }, [hostToDelete]);

  return (
    <Grid sm={4} md={4}>
      <Typography className={clsx(classes.subheader, classes.marginLeft)}>
        {formatMessage({
          id: "groups.settings",
          defaultMessage: "Ignored List",
          description: "manage groups settings",
        })}
      </Typography>
      <Grid className={classes.ignoreListWrapper}>
        <Typography className={classes.info}>
          {formatMessage({
            id: "addNewToListLabel.settings",
            defaultMessage: "Add new to ignored list",
            description: "add new to list label",
          })}
        </Typography>
        <InputLabel className={classes.inputLabel}>
          {formatMessage({
            id: "addHost.label.settings",
            defaultMessage: "Hosts or IP Address added to ignored list",
            description: "host name label",
          })}
        </InputLabel>
        <Grid className={classes.addHostWrapper}>
          <Input
            value={hostName}
            className={classes.input}
            onChange={(e) => setHostName(e.target.value)}
            placeholder={placeholder}
          />
          <Button className={classes.addHost} onClick={addHost}>
            {formatMessage({
              id: "addGroup.settings",
              defaultMessage: "Add",
              description: "add new host",
            })}
          </Button>
        </Grid>
        <ChipList
          list={networkGroups}
          onDelete={openDeleteModal}
          defaultMessage={"There is no Host or Addresses, please add new one."}
        />
        {modal && (
          <RemoveHostModal
            host={hostToDelete}
            yes={(id) => removeHostConfirmed(id)}
            no={() => removeHostRejected()}
          />
        )}
      </Grid>
    </Grid>
  );
}
