import { useQuery } from "@apollo/client";
import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import {
  PageHeader,
  LinkButton,
  EmptyListView,
  DataList,
} from "../../../../common/components";
import { ROUTES } from "../../../../common/constants/Routing";
import { useGroupState } from "../../../../core/context/containers/Groups.container";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useSearchState } from "../../../../core/context/containers/Search.container";
import GQLService from "../../../../core/services/GQL.service";
import { GroupListItem } from "./GroupListItem";

export default function Groups() {
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();
  const {
    searchGroup,
    group,
    setGroup,
    setGroupFromResponse,
    groupFromResponse,
  } = useGroupState();
  const { loading, data } = useQuery(GQLService.queries.groupsByPropertyId, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const { searchValue } = useSearchState(); 

  useEffect(
    () => {
      let isMounted = true;
      if (isMounted) {
        setGroup(data?.groupsByPropertyId || []);
        setGroupFromResponse(data?.groupsByPropertyId || []);
      }
      return () => {
        isMounted = false;
      };
    },
    [data] // eslint-disable-line
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      searchGroup(searchValue);
    }
    return () => {
      isMounted = false;
    };
  }, [searchValue, groupFromResponse]); // eslint-disable-line
  
  const createGroupButton = useCallback(() => {
    return (
      <LinkButton
        to={ROUTES.GROUPS_ADD.replace(
          ":uid",
          selectedProperty?.id!
        )}
        label={formatMessage({
          id: "groups.button.label",
          defaultMessage: "Add New Group",
          description: "add new group button label",
        })}
      />
    );
  }, [formatMessage, selectedProperty?.id]);

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "groups.header",
          defaultMessage: "Groups",
          description: "manage groups header",
        })}
      >
        {createGroupButton()}
      </PageHeader>
      {group.length === 0 ? (
        <EmptyListView viewType="group" />
      ) : (
        <DataList
          data={group}
          loading={loading}
          ListItem={GroupListItem}
        />
      )}
    </div>
  );
}
