import React from "react";
import {
  ActivitySwitch,
  FloorMultiSelect,
  Input,
  Select,
  TextareaAutosize,
} from "../../../../common/components/Form/FormFields";
import * as Yup from "yup";

import { FormField, FormFieldInputType } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";
import { FloorSelect } from "../../../../common/components/Form/FormFields/FloorSelect/FloorSelect.v2";
import { ElevatorDragAndDropSelect } from "../../../../common/components/Form/FormFields/ElevatorDragAndDropSelect/ElevatorDragAndDropSelect";

const FIELD_NAMES = {
  name: "name",
  active: "active",
  floorId: "floorId",
  beaconId: "beaconId",
  capacity: "capacity",
  elevatorsIds: "elevatorsIds",
};

type elevatorBayTypes = {
  capacity: Number;
};

export const createFormFields = (data: any): FormField[] => [
  {
    name: FIELD_NAMES.name,
    label: "Elevator Bay Name",
    placeholder: "Type here",
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.floorId,
    label: "Floor",
    placeholder: "Type here",
    required: false,
    gridStyle: { sm: 3, md: 3 },
    Component: FloorSelect,
    data: data.floors,
  },
  {
    name: FIELD_NAMES.beaconId,
    label: "Beacon",
    placeholder: "Type here",
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: Select,
    data: data.beacons,
  },
  {
    name: FIELD_NAMES.capacity,
    label: "Elevator Bay Capacity",
    placeholder: "Type here",
    required: false,
    gridStyle: { sm: 3, md: 3 },
    Component: Input,
    type: FormFieldInputType.number,
  },
  {
    name: FIELD_NAMES.active,
    label: "Active",
    placeholder: "Type here",
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: ActivitySwitch,
  },
  {
    name: FIELD_NAMES.elevatorsIds,
    label: "",
    placeholder: "Type here",
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: ElevatorDragAndDropSelect,
    data: data.elevators,
  },
];

export const elevatorBayValidationRules = Yup.object().shape({
  [FIELD_NAMES.name]: Yup.string()
    .min(
      3,
      I18n.formatMessage({
        id: "addElevatorBay.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "addElevatorBay.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
  [FIELD_NAMES.capacity]: Yup.number().min(0),
});
