import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  input: { display: "none" },
  button: {
    height: "160px",
    width: "160px",
    backgroundColor: theme.palette.common.InputBg,
    color: theme.palette.common.darkGrey,
    border: `1px dashed ${theme.palette.common.alto}`,
  },
  coverPhotoButton: {
    height: "160px",
    width: "320px",
    backgroundColor: theme.palette.common.InputBg,
    color: theme.palette.common.darkGrey,
    border: `1px dashed ${theme.palette.common.alto}`,
  },
  buttonLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(11),
    textTransform: "none",
    color: theme.palette.common.inputGrey,
  },
  submitBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 180,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(5),
    outline: "none",
    border: "none",
  },
  crossImage: {
    marginTop: -22,
    marginLeft: -22,
    color: theme.palette.common.regularBlue
  },
  note: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.inputGrey,
    padding: "5px 0",
  },
  preview: {
    width: 160,
    height: 160,
    objectFit: "contain",
    border: `1px solid ${theme.palette.common.nobel}`,
  },
  coverPhotoPreview: {
    width: 320,
    height: 160,
    objectFit: "contain",
    border: `1px solid ${theme.palette.common.nobel}`,
  },
  actionButtonsContainer: {
    marginTop: theme.spacing(1),
  },
  actionButton: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 100,
    height: 40,
    borderRadius: 30,
  },
  actionButtonDisabled: {
    backgroundColor: theme.palette.common.nobel,
    color: theme.palette.common.white,
    width: 100,
    height: 40,
    borderRadius: 30,
  },
  error: {
    borderColor: theme.palette.common.cinnabar,
  },
  progress: {
    color: theme.palette.common.white,
  },
  flex: {
    display: "flex",
    width: "160px",
    justifyContent: "space-around",
  },
  removeButton: {
    color: theme.palette.common.red,
    fontWeight: "bold",
    marginRight: "5px",
    cursor: "pointer",
  },
  changeButton: {
    color: theme.palette.common.regularBlue,
    fontWeight: "bold",
    marginRight: "5px",
    cursor: "pointer",
  },
  imageWrapper: {
    position: "relative",
  },
  header: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    width: "100%",
    marginBottom: "10px",
  },
  description: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    width: "100%",
    "&:nth-child(3)": {
      marginBottom: 10,
    },
  },
  errorLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.cinnabar,
    marginLeft: 10,
  },
  errorInput: {
    borderColor: theme.palette.common.cinnabar,
  },
  floorPlan: {
    width: 434,
    height: 369,
    objectFit: "contain",
    position: "relative",
  },
  floorPlanLoader: {
    width: 160,
    height: 160,
    objectFit: "contain",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  logo: {
    width: 434,
    display: "flex",
    alignItems: "flex-start",
    // height: 250,
  },
  addFloorIcon: {
    color: theme.palette.common.darkGrey,
    marginBottom: 19,
  },
  blueText: {
    color: theme.palette.common.blue,
  },
  inputLabel: {
    margin: "20px 0 10px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
  },
  childPhotoButton: {
    height: "300px",
    width: "300px",
    backgroundColor: theme.palette.common.InputBg,
    color: theme.palette.common.darkGrey,
    border: `1px dashed ${theme.palette.common.alto}`,
  },
  childPreview: {
    width: 300,
    height: 300,
    objectFit: "contain",
    border: `1px solid ${theme.palette.common.nobel}`,
  },
}));
