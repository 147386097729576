import axios from "axios";
import createRefreshTokenInterceptor from "axios-auth-refresh";

import { APP_DEFAULTS } from "../../common/constants/App";
import authClient from "../services/Auth.service";

const API = axios.create({
  responseType: "json",
  baseURL: APP_DEFAULTS.baseApiUrl,
});

const refreshTokenHandler = (_: any) => authClient.refreshTokens();
createRefreshTokenInterceptor(API, refreshTokenHandler);

export default API;
