import React from "react";
import { useStyles } from "./SideList";
import { Grid, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { ReactComponent as DoorIcon } from "../../../../../common/assets/images/door.svg";
import { Door } from "../../../../../types";

export function DoorListItem({ item }: { item: Door }) {
  const classes = useStyles();

  return (
    <>
      <Grid container={true} className={classes.listItem}>
        <Grid container={true} alignItems="center">
          <Grid
            container={true}
            item={true}
            md={11}
            sm={11}
            alignItems="center"
            justify={"flex-start"}
          >
            <Grid
              sm={2}
              md={2}
              container={true}
              alignItems="center"
              justify="center"
            >
              <DoorIcon className={classes.doorIcon} />
            </Grid>
            <Grid item sm={8}>
              <Typography className={classes.doorNameText}>
                {item.name ? item.name : item.doorUuid}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid item={true} sm={1} >
                        <Edit
                            className={classes.iconStyle}
                            onClick={() => console.log("Edit icon")}
                        />
                    </Grid> */}
        </Grid>
        <Grid container={true}>
          <Grid container={true}>
            <Typography className={classes.blackDescription}>
              {item.doorUuid ? `UUID: ${item.doorUuid}` : null}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
