import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";

import { PageHeader } from "../../../../common/components";
import { editFormFields, SpaceValidationRules } from "./Edit.form";
import { Form } from "../../../../common/components";
import { ROUTES } from "../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { CustomModal } from "../../../../common/components/index";
import { Modal } from "./Modal";
import { Space, SSID } from "../../../../types";
import { useSnackbar } from "notistack";
import { scrollsTop } from "../../../../common/utils/scrollbar.utils";
import { useSpacesState } from "../../../../core/context/containers/Spaces.container";
import moment from "moment";
import _ from "lodash";

export function EditSpace() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, toggleModal] = useState<boolean>(false);
  const { selectedSpace, setSelectedSpace } = useSpacesState();
  const [formDataState, setFormDataState] = useState<Space | undefined>(
    undefined
  );
  const [activeSSIDs, setActiveSSIDs] = useState<any>();
  const [tempData, setTempData] = useState<any>([]);
  const [sortCommonSpaceData, setSortCommonSpaceData] = useState<any>();

  const [getSpaceById, { data: spaceData }] = useLazyQuery(
    GQLService.queries.spaceById,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [getAirSensorByFloorId, { data: airSensorData }] = useLazyQuery(
    GQLService.queries.sensorsByFloorId,
    {
      fetchPolicy: "network-only",
    }
  );

  const [getAccessPointsByFloorId, { data: accessPointData }] = useLazyQuery(
    GQLService.queries.floorAccessPoints,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [getCommonSpaces, { data: commonSpacesData }] = useLazyQuery(
    GQLService.queries.commonSpaces,
    {
      fetchPolicy: "network-only",
    }
  );

  const [getCamerasByFloorId, { data: cameraData }] = useLazyQuery(
    GQLService.queries.camerasByFloorId,
    {
      fetchPolicy: "network-only",
    }
  );

  const [getDoorsByFloorId, { data: doorData }] = useLazyQuery(
    GQLService.queries.doorsByFloor,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    getSpaceById({ variables: { spaceId: selectedSpace?.id } });
    getAccessPointsByFloorId({
      variables: { floorId: selectedSpace?.floor?.id },
    });
    getAirSensorByFloorId({
      variables: { floorId: selectedSpace?.floor?.id },
    });
    getCamerasByFloorId({
      variables: { floorId: selectedSpace?.floor?.id },
    });
    getDoorsByFloorId({
      variables: { floorId: selectedSpace?.floor?.id },
    });
    getCommonSpaces();
  }, []);

  useEffect(() => {
    if (spaceData?.spaceById) {
      setSelectedSpace(spaceData?.spaceById);
      setTempData(spaceData?.spaceById?.ssids);
    }
  }, [setSelectedSpace, spaceData?.spaceById, spaceData?.spaceById.id]);

  useEffect(() => {
    if (commonSpacesData?.allCommonSpace) {
      const sortedList = commonSpacesData?.allCommonSpace?.sort(
        (a: any, b: any) => a.name.localeCompare(b.name)
      );
      setSortCommonSpaceData(sortedList);
    }
  }, [commonSpacesData]);

  const selectedAPids = (tags: any) => {
    let ssidForAP: any[] = [];
    let ssidData: any[] = [];
    if (tags?.length !== 0) {
      //&& selectedSpace?.accessPoints!
      selectedSpace?.accessPoints?.filter((el: any) => {
        if (tags?.includes(el.id)) {
          ssidForAP.push(el);
        }
      });
      accessPointData?.accessPointsByFloorId?.filter((el: any) => {
        if (tags?.includes(el.id)) {
          ssidForAP.push(el);
        }
      });
      ssidForAP.map((item) => {
        item.radio1.ssids.map((i: any) => {
          if (i.active === true) {
            ssidData.push(i);
          }
        });
        item.radio2.ssids.map((i: any) => {
          if (i.active === true) {
            ssidData.push(i);
          }
        });
      });
      let uniqueData = _.uniqBy(ssidData, function (e) {
        return e.id;
      });
      const newSSIDData = uniqueData?.map((el: any) => {
        if (el?.name?.includes("(2.4GHz)")) {
          let newStr = el?.name?.split("(2.4GHz)").join("");
          return { ...el, name: newStr };
        } else if (el?.name?.includes("(5GHz)")) {
          let newStr = el?.name?.split("(5GHz)").join("");
          return { ...el, name: newStr };
        } else {
          return el;
        }
      });
      setActiveSSIDs(newSSIDData);
    } else {
      setActiveSSIDs([]);
    }
  };

  useEffect(() => {
    selectedAPids(selectedSpace?.accessPoints?.map((item) => item.id));
  }, [selectedSpace?.accessPoints]);

  const zonesData: any[] = [];
  const tripWireData: any[] = [];
  if (cameraData) {
    cameraData.cameras?.map((camera: any) => {
      camera.zones?.map((zone: any) => {
        const zoneCopy = { ...zone };
        zoneCopy.cameraName = camera.name;
        zoneCopy.cameraUuid = camera.cameraUuid;
        zonesData.push(zoneCopy);
      });
      camera.tripWires?.map((tripWire: any) => {
        const tripWireCopy = { ...tripWire };
        tripWireCopy.cameraName = camera.name;
        tripWireCopy.cameraUuid = camera.cameraUuid;
        tripWireData.push(tripWireCopy);
      });
    });
  }

  const convertTimeToValue = (_time: any) => {
    let _dt = Number(moment(_time, "hh:mm a").format("H"));
    return isNaN(_dt) ? null : _dt;
  };

  const [updateSpace] = useMutation(GQLService.mutations.updateSpace, {
    refetchQueries: () => [
      {
        query: GQLService.queries.spaces,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = useCallback(
    (formData) => {
      toggleModal(true);
      setFormDataState({
        id: selectedSpace?.id,
        ...formData,
        accessPoints: formData.accessPoints.map((el: any) => {
          if (el.id) {
            return el.id;
          } else {
            return el;
          }
        }),
        airSensors: formData.airSensors.map((el: any) => {
          if (el.id) {
            return el.id;
          } else {
            return el;
          }
        }),
        doors: formData.doors.map((el: any) => {
          if (el.id) {
            return el.id;
          } else {
            return el;
          }
        }),
        ssids: formData.ssids.map((el: any) => {
          if (el.id) {
            return el.id;
          } else {
            return el;
          }
        }),
        tripWires: formData.tripWires.map((el: any) => {
          if (el.id) {
            return el.id;
          } else {
            return el;
          }
        }),
        zones: formData.zones.map((el: any) => {
          if (el.id) {
            return el.id;
          } else {
            return el;
          }
        }),
        propertyId: selectedProperty?.id,
      });
    },
    [formDataState, selectedProperty] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      if (formDataState) {
        let isNetflowActive;
        if (formDataState.hasOwnProperty("isNetflowActive")) {
          isNetflowActive = formDataState.isNetflowActive;
        } else {
          isNetflowActive = false;
        }
        try {
          await updateSpace({
            variables: {
              ...formDataState,
              isNetflowActive: isNetflowActive,
              commonSpaceId:
                formDataState?.commonSpaceId === "None"
                  ? null
                  : formDataState?.commonSpaceId,
            },
          });
          enqueueSnackbar(
            formatMessage({
              id: "space.update.snackbar.success",
              defaultMessage: "Space updated successfully",
            }),
            {
              variant: "success",
            }
          );
          scrollsTop();
          history.push(ROUTES.SPACES.replace(":uid", selectedProperty?.id!));
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          console.log(e);
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => {
    toggleModal(false);
  }, []);

  const removeAPids = () => {
    setTempData([]);
  };

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "edit.Space.header",
          defaultMessage: "Edit Space",
          description: "edit Space label",
        })}
      />
      <Grid container={true} md={12} sm={12}>
        <Form
          defaultValues={{
            active: selectedSpace?.active,
            name: selectedSpace?.name,
            area: selectedSpace?.area,
            isNetflowActive: selectedSpace?.isNetflowActive,
            floorId: selectedSpace?.floor?.id,
            accessPoints: selectedSpace?.accessPoints,
            airSensors: selectedSpace?.airSensors,
            zones: selectedSpace?.zones,
            tripWires: selectedSpace?.tripWires,
            doors: selectedSpace?.doors,
            resetTime: convertTimeToValue(selectedSpace?.resetTime),
            commonSpaceId: selectedSpace?.commonSpace?.id,
          }}
          fields={editFormFields(
            airSensorData?.sensorsByFloorId,
            accessPointData?.accessPointsByFloorId,
            selectedAPids,
            removeAPids,
            zonesData,
            tripWireData,
            doorData?.doorsByFloorId,
            selectedSpace?.isNetflowActive,
            sortCommonSpaceData,
            activeSSIDs,
            tempData
          )}
          validationSchema={SpaceValidationRules}
          onSubmit={handleSubmit}
        />
      </Grid>
      <CustomModal open={showModal}>
        <Modal yes={async () => confirmChanges()} no={rejectChanges} />
      </CustomModal>
    </div>
  );
}
