import { gql } from "@apollo/client";

const typeDefs = gql`
  type CreateReservationConfigurationRequestInput {
    schedulingSlotMinutes: Int!
    passGracePeriodMinutes: Int!
    passExpirationGracePeriodMinutes: Int!
    maxSchedulability: Float!
    maxAheadTimeOfReservationHours: Int!
    maxReservationsPerUser: Int!
  }
  type UpdateReservationConfigurationRequestInput {
    schedulingSlotMinutes: Int!
    passGracePeriodMinutes: Int!
    passExpirationGracePeriodMinutes: Int!
    maxSchedulability: Float!
    maxAheadTimeOfReservationHours: Int!
    maxReservationsPerUser: Int!
  }
`;

export const PROPERTIES_QUERIES = {
  propertyById: gql`
    query($propertyId: String!) {
      propertyById(propertyId: $propertyId) {
        id
        accountBusinessEntity
        ownerCompanyName
        companyAccountNumber
        companyStatus
        companyWebsite
        primaryContactFullName
        primaryContactPhoneNumber
        primaryContactEmailAddress
        siteName
        floor0Excluded
        siteNrOfFloors
        siteNrOfUndergroundFloors
        sitePropertyType
        siteServiceAddress
        spaceLoadMediumMax
        spaceLoadMediumMin
        logoKey
        imageKey
        workingFrom
        workingTo
        timeZone
        timeZoneName
        ntopngDatabaseName
        reservationConfigurations {
          schedulingSlotMinutes
          passGracePeriodMinutes
          passExpirationGracePeriodMinutes
          maxSchedulability
          maxAheadTimeOfReservationHours
          maxReservationsPerUser
        }
        upNextConfigurations {
          dateFormat
          healthSurveyFrequency
          use24HourClock
          surveyActive
        }
        sensorConfigurations {
          attribute
          isCelsius
          maxVal
          minVal
          sensorHealthIndicator
        }
        upNext
        dateCreated
        propertyDateFormat
        use24HourClock
      }
    }
  `,
  allProperties: gql`
    query {
      properties(pageDetails: { sort: { sortBy: "siteName", sortType: ASC } }) {
        id
        accountBusinessEntity
        ownerCompanyName
        companyAccountNumber
        companyStatus
        companyWebsite
        primaryContactFullName
        primaryContactPhoneNumber
        primaryContactEmailAddress
        siteName
        floor0Excluded
        siteNrOfFloors
        sitePropertyType
        siteServiceAddress
        spaceLoadMediumMax
        spaceLoadMediumMin
        logoKey
        imageKey
        workingFrom
        workingTo
        timeZone
        timeZoneName
        ntopngDatabaseName
        siteNrOfUndergroundFloors
        reservationConfigurations {
          schedulingSlotMinutes
          passGracePeriodMinutes
          passExpirationGracePeriodMinutes
          maxSchedulability
          maxAheadTimeOfReservationHours
          maxReservationsPerUser
        }
        upNextConfigurations {
          dateFormat
          healthSurveyFrequency
          use24HourClock
          surveyActive
        }
        sensorConfigurations {
          attribute
          isCelsius
          maxVal
          minVal
          sensorHealthIndicator
        }
        upNext
        dateCreated
        propertyDateFormat
        use24HourClock
      }
    }
  `,
};

export const PROPERTIES_MUTATIONS = {
  removeProperty: gql`
    mutation($propertyId: String!) {
      deleteProperty(propertyId: $propertyId)
    }
  `,
  addProperty: gql`
    mutation(
      $accountBusinessEntity: String
      $companyStatus: Boolean!
      $ownerCompanyName: String!
      $companyAccountNumber: String
      $companyWebsite: String
      $primaryContactFullName: String
      $primaryContactPhoneNumber: String
      $primaryContactEmailAddress: String
      $siteName: String!
      $siteNrOfFloors: Int!
      $sitePropertyType: String!
      $siteServiceAddress: String
      $spaceLoadMediumMax: Int!
      $spaceLoadMediumMin: Int!
      $workingFrom: String!
      $workingTo: String!
      $timeZone: String
      $timeZoneName: String!
      $ntopngDatabaseName: String
      $elevatorReservationConfiguration: CreateReservationConfigurationRequestInput
      $upNext: Boolean!
      $floor0Excluded: Boolean!
      $siteNrOfUndergroundFloors: Int!
      $propertyDateFormat: PropertyDateFormat!
      $use24HourClock: Boolean!
      $sensorConfigurations: [CreateSensorConfigurationRequestInput]
    ) {
      createProperty(
        request: {
          accountBusinessEntity: $accountBusinessEntity
          ownerCompanyName: $ownerCompanyName
          companyStatus: $companyStatus
          companyAccountNumber: $companyAccountNumber
          primaryContactFullName: $primaryContactFullName
          primaryContactPhoneNumber: $primaryContactPhoneNumber
          primaryContactEmailAddress: $primaryContactEmailAddress
          siteName: $siteName
          siteNrOfFloors: $siteNrOfFloors
          sitePropertyType: $sitePropertyType
          siteServiceAddress: $siteServiceAddress
          spaceLoadMediumMax: $spaceLoadMediumMax
          spaceLoadMediumMin: $spaceLoadMediumMin
          companyWebsite: $companyWebsite
          workingFrom: $workingFrom
          workingTo: $workingTo
          timeZone: $timeZone
          timeZoneName: $timeZoneName
          ntopngDatabaseName: $ntopngDatabaseName
          elevatorReservationConfiguration: $elevatorReservationConfiguration
          upNext: $upNext
          floor0Excluded: $floor0Excluded
          siteNrOfUndergroundFloors: $siteNrOfUndergroundFloors
          propertyDateFormat: $propertyDateFormat
          use24HourClock: $use24HourClock
          sensorConfigurations: $sensorConfigurations
        }
      ) {
        id
      }
    }
  `,
  updateBusinessEntity: gql`
    mutation(
      $ownerCompanyName: String
      $companyStatus: Boolean!
      $propertyId: String!
    ) {
      updateBusinessEntity(
        request: {
          ownerCompanyName: $ownerCompanyName
          propertyId: $propertyId
          companyStatus: $companyStatus
        }
      )
    }
  `,
  updateLocationDetails: gql`
    mutation(
      $propertyId: String!
      $siteName: String
      $sitePropertyType: String
      $siteServiceAddress: String
      $upNext: Boolean!
      $primaryContactEmailAddress: String
      $primaryContactFullName: String
      $primaryContactPhoneNumber: String
      $workingFrom: String!
      $siteNrOfFloors: Int!
      $floor0Excluded: Boolean!
      $siteNrOfUndergroundFloors: Int!
      $workingTo: String!
      $timeZone: String
      $timeZoneName: String
      $propertyDateFormat: PropertyDateFormat!
      $use24HourClock: Boolean!
    ) {
      updateLocationDetails(
        request: {
          propertyId: $propertyId
          siteName: $siteName
          sitePropertyType: $sitePropertyType
          siteServiceAddress: $siteServiceAddress
          upNext: $upNext
          primaryContactEmail: $primaryContactEmailAddress
          primaryContactFullName: $primaryContactFullName
          primaryContactPhone: $primaryContactPhoneNumber
          workingFrom: $workingFrom
          siteNrOfFloors: $siteNrOfFloors
          floor0Excluded: $floor0Excluded
          siteNrOfUndergroundFloors: $siteNrOfUndergroundFloors
          workingTo: $workingTo
          timeZone: $timeZone
          timeZoneName: $timeZoneName
          propertyDateFormat: $propertyDateFormat
          use24HourClock: $use24HourClock
        }
      )
    }
  `,
  updateNetworkSettings: gql`
    mutation(
      $propertyId: String!
      $spaceLoadMediumMax: Int
      $spaceLoadMediumMin: Int
      $ntopngDatabaseName: String
    ) {
      updateNetworkSettings(
        request: {
          propertyId: $propertyId
          spaceLoadMediumMax: $spaceLoadMediumMax
          spaceLoadMediumMin: $spaceLoadMediumMin
          ntopngDatabaseName: $ntopngDatabaseName
        }
      )
    }
  `,
  updateReservationSettings: gql`
    mutation(
      $propertyId: String!
      $elevatorReservationConfiguration: CreateReservationConfigurationRequestInput
    ) {
      updateReservationSettings(
        request: {
          propertyId: $propertyId
          elevatorReservationConfiguration: $elevatorReservationConfiguration
        }
      )
    }
  `,
  updateSensorConfiguration: gql`
    mutation(
      $propertyId: String!
      $sensorConfigurations: [CreateSensorConfigurationRequestInput]
    ) {
      updateSensorConfiguration(
        request: {
          propertyId: $propertyId
          sensorConfigurations: $sensorConfigurations
        }
      )
    }
  `,
  updateUpNextSettings: gql`
    mutation(
      $propertyId: String!
      $upNextConfigurationRequest: CreateUpNextConfigurationRequestInput
    ) {
      updateUpNextSettings(
        request: {
          propertyId: $propertyId
          upNextConfigurationRequest: $upNextConfigurationRequest
        }
      )
    }
  `,
};
