import React, { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import {
  Grid,
  Button,
  IconButton,
  Typography,
  InputLabel,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { FileCopyOutlined } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";

import { FormField as FormFieldType } from "../../../../../../types/form";
import { TripWire } from "../../../../../../types";
import { useStyles } from "../../../../Properties/Edit/EditProperty/WorkplaceSettings/ImageInput.styles";
import GQLService from "../../../../../../core/services/GQL.service";
import { COLORS } from "../../../../../../common/styles/colors";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import { useImagesSensorsState } from "../../../../../../core/context/containers/ImageSensor.container";
import { useTripWireState } from "../../../../../../core/context/containers/TripWire.container";
import { ReactComponent as ChangeIcon } from "../../../../../../common/assets/images/change-icon.svg";
import { ReactComponent as TrashIcon } from "../../../../../../common/assets/images/trash-icon.svg";

interface TripWirePhotosProps {
  field?: FormFieldType;
  name: string;
  previewStyle?: string;
  label: string;
  tripWire: TripWire | null;
  currentUrl: string | undefined;
  disabled?: boolean;
  setRemoveImage?: any;
  setFormImageKey?: any;
}

export function TripWirePhoto({
  name,
  field,
  previewStyle,
  label,
  tripWire,
  currentUrl,
  disabled = false,
  setRemoveImage,
  setFormImageKey,
}: TripWirePhotosProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const { selectedProperty } = usePropertiesState();
  const { selectedImagesSensor } = useImagesSensorsState();
  const { selectedTripWire, setSelectedTripWire } = useTripWireState();
  const history = useHistory();

  const [currentTripWireObject, setCurrentTripWireObject] = useState<{
    url: string | undefined;
    data: File | undefined;
    name: string | undefined;
  }>({
    url: undefined,
    data: undefined,
    name: undefined,
  });
  const [error, setError] = useState<boolean>(false);

  useEffect(
    () => {
      (async () => {
        if (currentUrl) {
          setImageLoading(true);
          const response = await GQLService.rest.tripWire.getTripWire(
            selectedProperty!.id,
            selectedImagesSensor!.id,
            selectedTripWire!.id
          );
          setImageLoading(false);
          setCurrentTripWireObject({
            ...currentTripWireObject,
            url: response?.source,
            name: selectedTripWire!.name,
          });
        }
      })();
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "dragover",
        (e) => {
          e.preventDefault();
        },
        false
      );
      window.removeEventListener(
        "drop",
        (e) => {
          e.preventDefault();
        },
        false
      );
    };
  }, []);

  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setError(false);
      if (e.target.files) {
        if (e.target.files[0].size / (1024 * 1024) >= 2) {
          setError(true);
          return;
        }
        setCurrentTripWireObject({
          url: URL.createObjectURL(e.target.files[0]),
          data: e.target.files[0],
          name: e.target.files[0].name,
        });
      }
    },
    [] //eslint-disable-line
  );

  const handleImageChange = useCallback(
    async (e: any) => {
      setCurrentTripWireObject({
        name: undefined,
        url: undefined,
        data: undefined,
      });
    },
    [tripWire, currentTripWireObject] //eslint-disable-line
  );

  const handleRemoveImage = useCallback(
    async (e: any) => {
      await GQLService.rest.tripWire.removeTripWire(
        selectedProperty?.id!,
        selectedImagesSensor?.id!,
        selectedTripWire?.id!
      );
      setRemoveImage(true);
      setCurrentTripWireObject({
        name: undefined,
        url: undefined,
        data: undefined,
      });
    },
    [tripWire, currentTripWireObject] //eslint-disable-line
  );

  const handleFileDrop = useCallback((e: React.DragEvent<HTMLInputElement>) => {
    setError(false);

    if (e.dataTransfer.files?.length) {
      if (e.dataTransfer.files[0].size / (1024 * 1024) >= 2) {
        setError(true);

        return;
      }
      setCurrentTripWireObject({
        url: URL.createObjectURL(e.dataTransfer.files[0]),
        name: e.dataTransfer.files[0].name,
        data: e.dataTransfer.files[0],
      });
    }
  }, []);

  useEffect(
    () => {
      (async () => {
        if (currentTripWireObject?.data) {
          setImageLoading(true);
          const formData = new FormData();
          formData.append("data", currentTripWireObject?.data);
          const tripWireResponse =
            await GQLService.rest.tripWire.uploadTripWire(
              formData,
              selectedProperty!.id,
              selectedImagesSensor!.id,
              selectedTripWire!.id
            );
          if (tripWireResponse.status !== 200) {
            setError(true);
          }
          if (tripWireResponse.status === 200) {
            setFormImageKey(
              `${
                selectedProperty!.id +
                selectedTripWire!.id +
                currentTripWireObject?.name
              }`
            );
            setImageLoading(false);
          }
        }
      })();
    },
    [history, currentTripWireObject] //eslint-disable-line
  );

  return (
    <Grid style={{ height: 360 }}>
      <Grid container>
        {disabled ? (
          <></>
        ) : (
          <>
            {currentTripWireObject?.url ? (
              <InputLabel className={classes.inputLabel}>
                {formatMessage({
                  id: "tripWire.image.text",
                  defaultMessage: "TripWire View",
                  description: "tripWire view",
                })}
              </InputLabel>
            ) : (
              <InputLabel className={classes.inputLabel}>
                {formatMessage({
                  id: "tripWire.imageUpload.text",
                  defaultMessage: "Upload TripWire View",
                  description: "upload tripWire view",
                })}
              </InputLabel>
            )}
          </>
        )}
      </Grid>
      {currentTripWireObject?.url || imageLoading ? (
        imageLoading ? (
          <Grid className={classes.floorPlanLoader}>
            <Loader
              type="Oval"
              color={COLORS.funBlue}
              height={100}
              width={100}
            />
          </Grid>
        ) : (
          <div className={classes.logo}>
            <img
              src={currentTripWireObject?.url}
              alt={currentTripWireObject.name}
              className={clsx(classes.childPreview, previewStyle)}
            />
            {disabled ? (
              <></>
            ) : (
              <IconButton
                aria-label="delete"
                onClick={handleRemoveImage}
                className={classes.crossImage}
              >
                <CancelIcon
                  fontSize="small"
                  style={{ backgroundColor: "white" }}
                />
              </IconButton>
            )}
          </div>
        )
      ) : (
        <Grid item={true} md={10}>
          {disabled ? (
            <></>
          ) : (
            <div>
              <input
                type="file"
                accept="image/jpeg, image/png"
                className={classes.input}
                id={field?.name || name}
                onChange={handleFileSelect}
                name={field?.name}
              />
              <label htmlFor={field?.name || name}>
                <Button
                  className={clsx(
                    classes.childPhotoButton,
                    error ? classes.error : ""
                  )}
                  component="span"
                  fullWidth={true}
                  onDrop={handleFileDrop}
                >
                  <Grid
                    container={true}
                    md={10}
                    direction="column"
                    alignItems="center"
                    justify="center"
                  >
                    <FileCopyOutlined style={{ color: COLORS.inputGrey }} />
                    <Typography className={classes.buttonLabel}>
                      {formatMessage({
                        id: "imageInput.dragAndDrop",
                        defaultMessage: "Select File or Drag and Drop",
                        description: "Image input drag and drop label",
                      })}
                    </Typography>
                  </Grid>
                </Button>
              </label>
              <Typography className={classes.note}>
                {formatMessage({
                  id: "imageInput.dragAndDropRules",
                  defaultMessage:
                    "Your upload must be: JPG or PNG format, less than 2 MB, and 1600px\n(w) by 1200px (h)",
                  description: "Image input drag and drop help text",
                })}
              </Typography>
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
}
