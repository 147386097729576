import { Grid } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { PageHeader } from "../../../../../../common/components";
import { EditTripWireForm } from "./TripWireEdit.form";

export function TripWireEdit() {
    const { formatMessage } = useIntl();

    return (
        <div>
            <PageHeader
                title={formatMessage({
                    id: "editTripWire.header",
                    defaultMessage: "Edit TripWire",
                    description: "edit tripWire label",
                })}
            />
            <Grid item={true} md={12}>
                <EditTripWireForm />
            </Grid>
        </div>
    );
}
