import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";

import { listStyles } from "../../../../../common/styles/lists";
import GQLService from "../../../../../core/services/GQL.service";
import { useUserState } from "../../../../../core/context/containers/User.container";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import { useSearchState } from "../../../../../core/context/containers/Search.container";
import { User } from "../../../../../types";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.common.mediumBlack,
    lineHeight: "36px",
  },
  content: {
    display: "flex",
    minWidth: "900px",
    height: "600px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    padding: "20px",
    borderRadius: "20px",
    boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  selectAll: {
    color: theme.palette.common.regularBlue,
    textTransform: "capitalize",
    paddingLeft: 0,
  },
  divider: {
    marginRight: "10px",
    borderRight: `1px solid ${theme.palette.common.black}`,
    minHeight: "10px",
  },
  filter: {
    paddingLeft: "10px",
  },
  searchText: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontSize: "14px",
    },
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  filterRow: {
    justifyContent: "space-between",
  },
  scrollContent: {
    height: "400px",
    overflowY: "scroll",
    marginTop: "16px",
  },
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
    "&:before": {
      content: "none",
    },
  },
  inputField: {
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  loaderDiv: {
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

interface UserGroupModalProps {
  no: Function;
}

export function UserGroupModal({ no }: UserGroupModalProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { searchValue } = useSearchState();
  const {
    searchUsers,
    users,
    selectedUsers,
    setSelectedUsers,
    setUsers,
    setUsersFromResponse,
  } = useUserState();
  const { selectedProperty } = usePropertiesState();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  let [checkedUserIds, setCheckedUserIds] = useState<string[]>([]);
  let [checkedUsers, setCheckedUsers] = useState<User[]>([]);

  const { data: usersData } = useQuery(GQLService.queries.userByPropertyId, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (selectedUsers) {
      setCheckedUsers([...selectedUsers]);
      const userIds = selectedUsers
        ? (selectedUsers as User[]).map((user) => user.id!)
        : [];
      setCheckedUserIds([...userIds]);
    } else {
      setCheckedUsers([]);
      setCheckedUserIds([]);
    }
  }, [selectedUsers]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 650);

    let isMounted = true;
    if (isMounted) {
      setUsers(usersData?.usersByPropertyId || []);
      setUsersFromResponse(usersData?.usersByPropertyId || []);
    }
    return () => {
      isMounted = false;
    };
  }, [usersData, users]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      searchUsers(searchValue);
    }
    return () => {
      isMounted = false;
    };
  }, [searchValue]);

  const handleUserSelect = useCallback(
    (item: User, e) => {
      const users: User[] = [...checkedUsers];
      const userIds: string[] = [...checkedUserIds];
      if (e.target.checked) {
        users.push(item);
        userIds.push(item.id!);
      } else {
        const itemIndex = users.findIndex((v) => v.id === item.id);
        users.splice(itemIndex, 1);
        const index = userIds.findIndex((v) => v === item.id);
        userIds.splice(index, 1);
      }
      setCheckedUsers([...users]);
      setCheckedUserIds([...userIds]);
    },
    [checkedUserIds, checkedUsers]
  );

  const updateUsers = () => {
    setSelectedUsers(checkedUsers);
    no();
  };

  const load = (
    <div className={classes.loaderDiv}>
      <CircularProgress color="inherit" size={36} />
    </div>
  );

  const usersList = users.length ? (
    <Grid className={classes.scrollContent}>
      {users.map((item, i) => (
        <Grid
          container={true}
          alignItems="center"
          justify="space-between"
          style={{ margin: "8px 0", border: "1px solid #BDCED7" }}
        >
          <Grid
            container={true}
            item={true}
            md={5}
            sm={5}
            justify="flex-start"
            className={classes.headerWrapper}
          >
            <Checkbox
              value={item.id}
              color="primary"
              inputProps={{ "aria-label": "checkbox" }}
              checked={checkedUserIds.includes(item.id!)}
              onClick={(e) => handleUserSelect(item, e)}
            />
            <Person className={classes.icon} />
            <Typography className={classes.name}>{item.fullName}</Typography>
          </Grid>
          <Grid item={true} md={4} sm={4}>
            <Typography className={classes.subheader}>{item.email}</Typography>
          </Grid>
          <Grid item={true} md={3} sm={3}>
            <Typography className={classes.subheader}>{item.role}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  ) : (
    <Typography>
      {formatMessage({
        id: "dataList.noData",
        defaultMessage: "No Data",
        description: "datalist no data info",
      })}
    </Typography>
  );

  return (
    <Grid className={classes.content}>
      <div>
        <Typography className={classes.header}>
          Add Users to the Group
        </Typography>
        {/* Uncomment when filter functionality is implemented */}
        {/* <Grid container className={classes.filterRow}>
          <Grid item={true} md={7}>
            <Button className={classes.selectAll}>
              Select All ({users.length})
            </Button>
            <span className={classes.divider}></span>
            <b>Filters</b>
            <span className={classes.filter}>Assigned to floors: Any</span>
            <span className={classes.filter}>Roles: All</span>
          </Grid>
          <Grid item={true} md={1} className={classes.searchText}>
            <Typography>Search</Typography>
          </Grid>
          <Grid item={true} md={4}>
            <Input
              classes={{
                root: `${classes.input}`,
                input: classes.inputField,
              }}
              value={""}
              placeholder={"Type here..."}
              name={"search"}
              disabled={false}
              onChange={(e) => console.log(+e.target.value)}
              disableUnderline={true}
              fullWidth={true}
              type={"text"}
            />
          </Grid>
        </Grid> */}
        {isLoading ? load : usersList}
      </div>
      <Grid item className={classes.buttonsWrapper}>
        <Button id="cancelBtn" className={classes.cancel} onClick={() => no()}>
          Cancel
        </Button>
        <Button id="addBtn" className={classes.editBtn} onClick={updateUsers}>
          Add Users
        </Button>
      </Grid>
    </Grid>
  );
}
