import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Grid,
  makeStyles,
  Slider,
  Typography,
} from "@material-ui/core";
import { useFloorPlanEditorState } from "../../../../../core/context/containers/FloorPlanEditor/FloorPlanEditor.container";
import { useFloorPlanEditor } from "../../../../../core/context/functions/FloorPlanEditor/FloorPlanEditor.functions";
import {
  AccessPoint,
  Sensor,
  ImagesSensor,
  Zone,
  TripWire,
  Door,
  Space,
} from "../../../../../types";
import GQLService from "../../../../../core/services/GQL.service";
import { useMutation } from "@apollo/client";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import { useToolbarState } from "../../../../../core/context/containers/FloorPlanEditor/Toolbar.container";
import { Tool } from "../../../../../common/constants/FloorPlanEditor";
import { AccessPointListItem } from "./AccessPointListItem";
import { SensorListItem } from "./SensorListItem";
import { ImageSensorListItem } from "./ImageSensorListItem";
import { NoImageSensorAvailable } from "./NoImageSensorAvailable";
import clsx from "clsx";
import { DoorListItem } from "./DoorListItem";
import { SpaceListItem } from "./SpaceListItem";

export const useStyles = makeStyles((theme) => ({
  container: {
    border: `2px solid ${theme.palette.common.concrete}`,
    borderTop: "0px",
    // margin: theme.spacing(1),
  },
  listItem: {
    borderTop: `2px solid ${theme.palette.common.concrete}`,
    borderBottom: `2px solid ${theme.palette.common.concrete}`,
    cursor: "pointer",
  },
  listItemEditMode: {
    borderTop: `2px solid ${theme.palette.common.concrete}`,
    borderBottom: `2px solid ${theme.palette.common.concrete}`,
    backgroundColor: theme.palette.common.polar,
    cursor: "pointer",
  },
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.blackPearl,
    margin: theme.spacing(2),
  },
  statusIndicator: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  doorNameText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  blackDescription: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  greyText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.dustyGray,
    margin: theme.spacing(2),
  },
  spaceLoadBarGreen: {
    fill: "#9BCA60",
  },
  spaceLoadBarOrange: {
    fill: "#F7812C",
  },
  spaceLoadBarRed: {
    fill: "#E25353",
  },
  range: {
    width: "60%",
    left: "3%",
    top: "-20%",
  },
  rangeRail: {
    height: 4,
    borderRadius: 4,
  },
  rangeThumb: {
    marginTop: -4,
  },
  innerCircle: {
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  innerCircleGreen: {
    backgroundColor: theme.palette.common.chateauGreen,
  },
  innerCircleRed: {
    backgroundColor: theme.palette.common.cinnabar,
  },
  deleteIcon: {
    marginRight: 20,
    marginTop: 10,
    cursor: "pointer",
    width: 16,
    height: 16,
  },
  editModeRoot: {
    padding: theme.spacing(2, 0),
  },
  input: {
    width: "100%",
    height: 29,
    backgroundColor: theme.palette.common.polar,
    borderRadius: 8,
    paddingLeft: 10,
    margin: theme.spacing(1),
  },
  saveButton: {
    width: 56,
    height: 25,
    borderRadius: 43,
    backgroundColor: theme.palette.common.funBlue,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
    textTransform: "none",
    margin: theme.spacing(1),
  },
  toolSwitch: {
    background: "#F0F0F0",
    textTransform: "capitalize",
  },
  active: {
    background: theme.palette.common.funBlue,
    color: "#fff",
  },
  radiusLeft: {
    borderRadius: "6px 0px 0px 6px",
  },
  radiusRight: {
    borderRadius: "0px 6px 6px 0px",
  },
  switchWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "25px",
    marginBottom: "25px",
  },
  sideListTitle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.mediumBlack,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(18),
  },
  doorIcon: { color: theme.palette.common.funBlue },
  iconStyle: {
    fontSize: "18px",
    width: "18px",
    height: "18px",
    color: theme.palette.common.darkBlue,
  },
}));

function useDebounce(value: number, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
}

export function SideList({
  accessPoints,
  sensors,
  imageSensors,
  spaces,
  doors,
  drawZone,
  clearZone,
  drawTripwire,
  clearTripwire,
  drawSpace,
  clearSpace,
  selectedTab,
}: {
  accessPoints: AccessPoint[];
  sensors: Sensor[];
  imageSensors: ImagesSensor[];
  spaces: Space[];
  doors: Door[];
  drawZone?: (camera: ImagesSensor, zone: Zone) => void;
  clearZone?: (camera: ImagesSensor, zone: Zone) => void;
  drawTripwire?: (camera: ImagesSensor, tripWire: TripWire) => void;
  clearTripwire?: (camera: ImagesSensor, tripWire: TripWire) => void;
  drawSpace?: (space: Space) => void;
  clearSpace?: (space: Space) => void;
  selectedTab: any;
}) {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { floor } = useFloorPlanEditorState();
  const { selectedProperty } = usePropertiesState();
  const { setAccessPointsRange } = useFloorPlanEditor();
  const [accessPointRangeLocal, setAccessPointRangeLocally] = useState<number>(
    floor?.accessPointsRadius || 0
  );
  const { selectedTool, setSelectedTool } = useToolbarState();
  const debouncedRange = useDebounce(accessPointRangeLocal, 500);
  const [updateFloor] = useMutation(GQLService.mutations.editFloor, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allFloors,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleRangeChange = useCallback((event, v) => {
    setAccessPointRangeLocally(v);
    setAccessPointsRange(v);
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (debouncedRange) {
        updateFloor({
          variables: {
            floorId: floor?.id,
            accessPointsRadius: debouncedRange,
            active: floor?.active,
          },
        });
        setAccessPointsRange(debouncedRange);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [debouncedRange]);

  const setTool = useCallback(
    (tool: Tool) => {
      setSelectedTool(tool);
    },
    [selectedTool] //eslint-disable-line
  );
  return (
    <Grid
      container={true}
      justify={"flex-start"}
      alignContent={"flex-start"}
      className={classes.container}
    >
      <Grid container={true}>
        {/* <Grid className={classes.switchWrapper}>
          <Button
            className={clsx(
              classes.toolSwitch,
              classes.radiusLeft,
              selectedTool === Tool.ADD_ACCESS_POINT ? classes.active : {}
            )}
            onClick={() => setTool(Tool.ADD_ACCESS_POINT)}
          >
            Access Points
          </Button>
          <Button
            className={clsx(
              classes.toolSwitch,
              selectedTool === Tool.ADD_SENSOR ? classes.active : {}
            )}
            onClick={() => setTool(Tool.ADD_SENSOR)}
          >
            Air Quality Sensors
          </Button>
          <Button
            className={clsx(
              classes.toolSwitch,
              classes.radiusRight,
              selectedTool === Tool.ADD_IMAGE_SENSOR ? classes.active : {}
            )}
            onClick={() => setTool(Tool.ADD_IMAGE_SENSOR)}
          >
            Image Sensors
          </Button>
        </Grid> */}
        <Grid style={{ margin: "32px 0 20px 20px" }}>
          {selectedTab === Tool.ADD_ACCESS_POINT ? (
            <span className={classes.sideListTitle}>Access Points</span>
          ) : selectedTab === Tool.ADD_SENSOR ? (
            <span className={classes.sideListTitle}>Air Quality Sensors</span>
          ) : selectedTab === Tool.ADD_IMAGE_SENSOR ? (
            <span className={classes.sideListTitle}>Image Sensors</span>
          ) : selectedTab === Tool.ADD_SPACE ? (
            <span className={classes.sideListTitle}>Spaces</span>
          ) : (
            <span className={classes.sideListTitle}>Doors</span>
          )}
        </Grid>
        {accessPoints?.length && selectedTab === Tool.ADD_ACCESS_POINT ? (
          <Grid container={true} style={{ marginTop: "10px" }}>
            <Typography className={classes.blackDescription}>Range</Typography>
            <Slider
              classes={{
                root: classes.range,
                rail: classes.rangeRail,
                track: classes.rangeRail,
                thumb: classes.rangeThumb,
              }}
              value={accessPointRangeLocal}
              getAriaValueText={(v) => `${v}`}
              valueLabelDisplay="auto"
              min={0}
              max={100}
              onChange={handleRangeChange}
            />
          </Grid>
        ) : null}
      </Grid>
      {selectedTab === Tool.ADD_ACCESS_POINT &&
        (accessPoints.length > 0 ? (
          accessPoints!.map((v) => <AccessPointListItem key={v.id} item={v} />)
        ) : (
          <NoImageSensorAvailable
            message={"There is no Access point added"}
            actionMessage={
              "Click right mouse button on your floor plan to add access point"
            }
          />
        ))}
      {selectedTab === Tool.ADD_SENSOR &&
        (sensors.length > 0 ? (
          sensors!.map((v) => <SensorListItem key={v.id} item={v} />)
        ) : (
          <NoImageSensorAvailable
            message={"There is no Air quality sensor added"}
            actionMessage={
              "Click right mouse button on your floor plan to add air quality sensor"
            }
          />
        ))}
      {selectedTab === Tool.ADD_IMAGE_SENSOR &&
        (imageSensors.length > 0 ? (
          imageSensors!.map((v) => (
            <ImageSensorListItem
              key={v.id}
              item={v}
              drawZone={drawZone}
              clearZone={clearZone}
              drawTripwire={drawTripwire}
              clearTripwire={clearTripwire}
            />
          ))
        ) : (
          <NoImageSensorAvailable
            message={"There is no Image sensor added"}
            actionMessage={
              "Click right mouse button on your floor plan to add image sensor"
            }
          />
        ))}
      {selectedTab === Tool.ADD_SPACE &&
        (spaces.length > 0 ? (
          spaces!.map((v) => (
            <SpaceListItem
              key={v.id}
              item={v} 
              drawSpace={drawSpace}
              clearSpace={clearSpace}
            />
          ))
        ) : (
          <NoImageSensorAvailable
            message={"There is no space added"}
            actionMessage={
              "Click right mouse button on your floor plan to add space"
            }
          />
        ))}
      {selectedTab === Tool.ADD_DOOR &&
        (doors.length > 0 ? (
          doors!.map((v) => <DoorListItem key={v.id} item={v} />)
        ) : (
          <NoImageSensorAvailable
            message={"There is no door added"}
            actionMessage={
              "Click right mouse button on your floor plan to add door"
            }
          />
        ))}
    </Grid>
  );
}
