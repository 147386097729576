import { gql } from "@apollo/client";

export const ELEVATORS_MUTATIONS = {
  createElevator: gql`
    mutation createElevator(
      $active: Boolean!
      $name: String!
      $propertyId: String!
      $floorsIds: [String]
      $startingFloorId: String
    ) {
      createElevator(
        request: {
          active: $active
          name: $name
          propertyId: $propertyId
          floorsIds: $floorsIds
          startingFloorId: $startingFloorId
        }
      ) {
        id
      }
    }
  `,
  updateElevator: gql`
    mutation updateElevator(
      $active: Boolean!
      $id: String!
      $name: String
      $floorsIds: [String]
      $startingFloorId: String
    ) {
      updateElevator(
        request: {
          active: $active
          id: $id
          name: $name
          floorsIds: $floorsIds
          startingFloorId: $startingFloorId
        }
      )
    }
  `,
  deleteElevator: gql`
    mutation($id: String!) {
      deleteElevator(id: $id)
    }
  `,
};

export const ELEVATORS_QUERIES = {
  elevatorsByPropertyId: gql`
    query($propertyId: String!) {
      elevatorsByPropertyId(propertyId: $propertyId) {
        id
        name
        active
        servicedFloors {
          id
          name
          floorNumber
        }
        startingFloor {
          id
          name
          floorNumber
        }
      }
    }
  `,
};
