import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Formik, Form as FormikForm } from "formik";
import { useMutation } from "@apollo/client";
import {
  Grid,
  Button,
  Input,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import { useTripWireState } from "../../../../../../core/context/containers/TripWire.container";
import {
  ActivitySwitch,
  CustomModal,
} from "../../../../../../common/components/index";
import { ModalContent } from "../../ModalContent";
import { TripWire, flowTypeMap } from "../../../../../../types";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import GQLService from "../../../../../../core/services/GQL.service";
import { ROUTES } from "../../../../../../common/constants/Routing";
import { SensorRange } from "../../../../Properties/components/SensorRange/SensorRange";
import { TripWirePhoto } from "./TripWirePhoto";
import { useStyles } from "../../EditImageSensor.styles";
import { useImagesSensorsState } from "../../../../../../core/context/containers/ImageSensor.container";

const FIELD_NAMES = {
  name: "name",
  id: "id",
  area: "area",
  tripWire: "tripWire",
  active: "active",
  inOccupancyMediumMinThreShold: "inOccupancyMediumMinThreShold",
  inOccupancyMediumMaxThreShold: "inOccupancyMediumMaxThreShold",
  inOccupancyMaxThreShold: "inOccupancyMaxThreShold",
  outOccupancyMediumMinThreShold: "outOccupancyMediumMinThreShold",
  outOccupancyMediumMaxThreShold: "outOccupancyMediumMaxThreShold",
  outOccupancyMaxThreShold: "outOccupancyMaxThreShold",
  imageKey: "imageKey",
  flowType: "flowType",
};

export interface tripWireFile {
  name: string | undefined;
  url: string | undefined;
  data: File | undefined;
}

export function EditTripWireForm() {
  const { selectedProperty } = usePropertiesState();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { selectedTripWire, setSelectedTripWire } = useTripWireState();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [selectedFlowType] = useState<string>();
  const { selectedImagesSensor } = useImagesSensorsState();

  const { enqueueSnackbar } = useSnackbar();
  const [updateTripWire] = useMutation(GQLService.mutations.editTripWire);
  const history = useHistory();
  const [formDataState, setFormDataState] = useState<TripWire | false>(false);
  const [removeImage, setRemoveImage] = useState<boolean>(false);
  const [formImageKey, setFormImageKey] = useState<string>("");

  const editTripWireSchema = Yup.object().shape({
    [FIELD_NAMES.tripWire]: Yup.string().nullable(),
    [FIELD_NAMES.name]: Yup.string().nullable(),
    [FIELD_NAMES.area]: Yup.number()
      .typeError(
        formatMessage({
          id: "TripWireEdit.validation.area.number",
          defaultMessage: "You must specify a number",
          description: "Only numbers allowed",
        })
      )
      .nullable(),
    [FIELD_NAMES.inOccupancyMaxThreShold]: Yup.number().min(5).nullable(),
    [FIELD_NAMES.outOccupancyMaxThreShold]: Yup.number().min(5).nullable(),
    [FIELD_NAMES.active]: Yup.boolean().nullable(),
  });

  const handleChangeMaxRangeIn = useCallback(
    async (
      value: number,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue(
        `inOccupancyMediumMinThreShold`,
        Math.round((90 / 100) * value)
      );
      setFieldValue(
        `inOccupancyMediumMaxThreShold`,
        Math.round((95 / 100) * value)
      );
      setFieldValue(`inOccupancyMaxThreShold`, +value);
    },
    []
  );

  const handleChangeMaxRangeOut = useCallback(
    async (
      value: number,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue(
        `outOccupancyMediumMinThreShold`,
        Math.round((90 / 100) * value)
      );
      setFieldValue(
        `outOccupancyMediumMaxThreShold`,
        Math.round((95 / 100) * value)
      );
      setFieldValue(`outOccupancyMaxThreShold`, +value);
    },
    []
  );

  const setSensorRangeIN = useCallback(
    async (
      values: number[],
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue(`inOccupancyMediumMinThreShold`, values[1]);
      setFieldValue(`inOccupancyMediumMaxThreShold`, values[2]);
    },
    []
  );

  const setSensorRangeOUT = useCallback(
    async (
      values: number[],
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue(`outOccupancyMediumMinThreShold`, values[1]);
      setFieldValue(`outOccupancyMediumMaxThreShold`, values[2]);
    },
    []
  );

  const handleSubmit = useCallback(
    async (formData) => {
      toggleModal(true);
      if (formData.flowType === "OUT") {
        const inFormData = { ...formData };
        inFormData.inOccupancyMediumMinThreShold = 0;
        inFormData.inOccupancyMediumMaxThreShold = 0;
        inFormData.inOccupancyMaxThreShold = 0;
        inFormData.outOccupancyMaxThreShold = Math.round(
          inFormData.outOccupancyMaxThreShold
        );
        setFormDataState({ ...inFormData, id: selectedTripWire?.id });
      } else if (formData.flowType === "IN") {
        const outFormData = { ...formData };
        outFormData.outOccupancyMediumMinThreShold = 0;
        outFormData.outOccupancyMediumMaxThreShold = 0;
        outFormData.outOccupancyMaxThreShold = 0;
        outFormData.inOccupancyMaxThreShold = Math.round(
          outFormData.inOccupancyMaxThreShold
        );
        setFormDataState({ ...outFormData, id: selectedTripWire?.id });
      } else {
        const data = { ...formData };
        data.outOccupancyMaxThreShold = Math.round(
          data.outOccupancyMaxThreShold
        );
        data.inOccupancyMaxThreShold = Math.round(data.inOccupancyMaxThreShold);
        setFormDataState({ ...data, id: selectedTripWire?.id });
      }
    },
    [formDataState] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      if (formDataState) {
        if (removeImage) {
          formDataState!.imageKey = null;
          setRemoveImage(false);
        }
        if (formImageKey.length > 0) {
          formDataState!.imageKey = formImageKey;
          setFormImageKey("");
        }
        try {
          await updateTripWire({
            variables: {
              ...formDataState,
              propertyId: selectedProperty?.id,
              id: selectedTripWire?.id,
            },
          }).then(() => {
            setSelectedTripWire(formDataState);
            history.push(
              ROUTES.TRIPWIRE.replace(":uid", selectedProperty?.id!)
                .replace(
                  ":imagesensor",
                  selectedImagesSensor?.id.split(":")[1]!
                )
                .replace(":tripWireid", selectedTripWire?.id.split(":")[1]!)
            );
          });
          enqueueSnackbar("TripWire changed successfully", {
            variant: "success",
          });
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => toggleModal(false), []);

  const [active, toggleActiveState] = useState<boolean>(
    selectedTripWire?.active!
  );

  const toggleSwitchButton = useCallback(
    (setFieldValue) => {
      setFieldValue(FIELD_NAMES.active, !active);
      toggleActiveState(!active);
    },
    [selectedTripWire, active] // eslint-disable-line
  );

  return (
    selectedTripWire && (
      <Grid item={true} md={12}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={Object.keys(FIELD_NAMES).reduce(
            (acc: { [key: string]: string }, curr: any) => {
              if (
                (curr === "inOccupancyMaxThreShold" ||
                  curr === "outOccupancyMaxThreShold") &&
                (selectedTripWire as any)[curr] === 0
              ) {
                acc[curr] = "100";
              } else if (
                (curr === "inOccupancyMediumMinThreShold" ||
                  curr === "outOccupancyMediumMinThreShold") &&
                (selectedTripWire as any)[curr] === 0
              ) {
                acc[curr] = "90";
              } else if (
                (curr === "inOccupancyMediumMaxThreShold" ||
                  curr === "outOccupancyMediumMaxThreShold") &&
                (selectedTripWire as any)[curr] === 0
              ) {
                acc[curr] = "95";
              } else {
                acc[curr] = (selectedTripWire as any)[curr];
              }
              return acc;
            },
            {}
          )}
          validationSchema={editTripWireSchema}
        >
          {({ values, setFieldValue, errors, submitCount, initialValues }) => {
            return (
              <FormikForm className={classes.form}>
                <Grid container={true} spacing={3}>
                  <Grid item={true} sm={4} md={2}>
                    <InputLabel className={classes.inputLabel}>
                      {formatMessage({
                        id: "TripWireEdit.label.id",
                        defaultMessage: "TripWire Id",
                        description: "tripWire id",
                      })}
                      {(submitCount > 0 ||
                        values[FIELD_NAMES.tripWire] !==
                          initialValues[FIELD_NAMES.tripWire]) &&
                        errors[FIELD_NAMES.tripWire] && (
                          <span className={classes.errorLabel}>
                            {errors[FIELD_NAMES.tripWire]}
                          </span>
                        )}
                    </InputLabel>
                    <Input
                      className={clsx(
                        classes.input,
                        classes.readOnlyInput,
                        (submitCount > 0 ||
                          values[FIELD_NAMES.tripWire] !==
                            initialValues[FIELD_NAMES.tripWire]) &&
                          errors[FIELD_NAMES.tripWire]
                          ? classes.errorInput
                          : ""
                      )}
                      disableUnderline={true}
                      fullWidth={true}
                      placeholder={formatMessage({
                        id: "TripWireEdit.placeholder.id",
                        defaultMessage: "TripWire Id",
                        description: "TripWire Id placeholder",
                      })}
                      name={FIELD_NAMES.tripWire}
                      type={"input"}
                      defaultValue={values.tripWire}
                      readOnly
                    />
                  </Grid>
                  <Grid item={true} sm={4} md={2}>
                    <InputLabel className={classes.inputLabel}>
                      {formatMessage({
                        id: "TripWireEdit.label.name",
                        defaultMessage: "TripWire Name",
                        description: "TripWire Name",
                      })}
                      {(submitCount > 0 ||
                        values[FIELD_NAMES.name] !==
                          initialValues[FIELD_NAMES.name]) &&
                        errors[FIELD_NAMES.name] && (
                          <span className={classes.errorLabel}>
                            {errors[FIELD_NAMES.name]}
                          </span>
                        )}
                    </InputLabel>
                    <Input
                      className={clsx(
                        classes.input,
                        (submitCount > 0 ||
                          values[FIELD_NAMES.name] !==
                            initialValues[FIELD_NAMES.name]) &&
                          errors[FIELD_NAMES.name]
                          ? classes.errorInput
                          : ""
                      )}
                      disableUnderline={true}
                      fullWidth={true}
                      placeholder={formatMessage({
                        id: "TripWireEdit.placeholder.name",
                        defaultMessage: "TripWire Name",
                        description: "TripWire Name placeholder",
                      })}
                      name={FIELD_NAMES.name}
                      type={"input"}
                      defaultValue={values.name}
                      onChange={(e) =>
                        setFieldValue(FIELD_NAMES.name, e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item={true} sm={4} md={3}>
                    <InputLabel className={classes.inputLabel}>
                      {formatMessage({
                        id: "TripWireEdit.label.coveredLength",
                        defaultMessage: "Covered Length (ft.)",
                        description: "Covered Length in (ft.)",
                      })}
                    </InputLabel>
                    <Input
                      className={clsx(
                        classes.input,
                        (submitCount > 0 ||
                          values[FIELD_NAMES.area] !==
                            initialValues[FIELD_NAMES.area]) &&
                          errors[FIELD_NAMES.area]
                          ? classes.errorInput
                          : ""
                      )}
                      disableUnderline={true}
                      fullWidth={true}
                      placeholder={formatMessage({
                        id: "TripWireEdit.placeholder.coveredLength",
                        defaultMessage: "Covered Length (ft.)",
                        description: "TripWire Covered Length placeholder",
                      })}
                      name={FIELD_NAMES.area}
                      type={"input"}
                      defaultValue={values.area}
                      onChange={(e) => {
                        const value = e.target.value ? e.target.value : 0;
                        setFieldValue(FIELD_NAMES.area, value);
                      }}
                    />
                    <div>
                      {(submitCount > 0 ||
                        values[FIELD_NAMES.area] !==
                          initialValues[FIELD_NAMES.area]) &&
                        errors[FIELD_NAMES.area] && (
                          <span className={classes.errorLabel}>
                            {errors[FIELD_NAMES.area]}
                          </span>
                        )}
                    </div>
                  </Grid>
                  <Grid item={true} sm={4} md={3}>
                    <InputLabel className={classes.inputLabel}>
                      {formatMessage({
                        id: "TripWireEdit.label.flowType",
                        defaultMessage: "Flow Type",
                        description: "Flow type ",
                      })}
                    </InputLabel>
                    <Select
                      classes={{
                        root: classes.select,
                        select: classes.blackText,
                        icon: classes.icon,
                      }}
                      MenuProps={{
                        classes: {
                          paper: classes.menu,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      value={selectedFlowType || values.flowType.toString()}
                      disableUnderline={true}
                      IconComponent={ExpandMoreSharpIcon}
                      name="flowTypeFilter"
                      onChange={(e) =>
                        setFieldValue(FIELD_NAMES.flowType, e.target.value)
                      }
                    >
                      {Array.from(flowTypeMap.keys()).map((key, i) => {
                        return (
                          <MenuItem
                            key={i + 1}
                            value={key}
                            classes={{
                              root: classes.option,
                              selected: classes.selected,
                            }}
                          >
                            {flowTypeMap.get(key)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
                {(values.flowType === "IN" || values.flowType === "BOTH") && (
                  <Grid container={true} spacing={3}>
                    <Grid item={true} sm={12} className={classes.itemSpace}>
                      <InputLabel className={classes.inputLabel}>
                        {formatMessage({
                          id: "TripWireEdit.label.inFlowThreshold",
                          defaultMessage: "In Flow Threshold",
                          description: "In Flow Threshold",
                        })}
                      </InputLabel>
                      <SensorRange
                        range={[
                          0,
                          parseInt(values.inOccupancyMediumMinThreShold),
                          parseInt(values.inOccupancyMediumMaxThreShold),
                          parseInt(values.inOccupancyMaxThreShold) || 100,
                        ]}
                        indicators={["HEALTHY", "MODERATE", "POOR"]}
                        hideMinHandle={true}
                        min={0}
                        max={parseInt(values.inOccupancyMaxThreShold)}
                        maxLabel={values.inOccupancyMaxThreShold}
                        className={classes.tripWireSliderWidth}
                        onChange={(values) =>
                          setSensorRangeIN(values, setFieldValue)
                        }
                        onMaxLabelChange={(e) =>
                          handleChangeMaxRangeIn(e.target.value, setFieldValue)
                        }
                      />
                    </Grid>
                  </Grid>
                )}
                <br />
                <br />
                {(values.flowType === "OUT" || values.flowType === "BOTH") && (
                  <Grid container={true} spacing={3}>
                    <Grid item={true} sm={12} className={classes.itemSpace}>
                      <InputLabel className={classes.inputLabel}>
                        {formatMessage({
                          id: "TripWireEdit.label.outFlowThreshold",
                          defaultMessage: "Out Flow Threshold",
                          description: "Out Flow Threshold",
                        })}
                      </InputLabel>
                      <SensorRange
                        range={[
                          0,
                          parseInt(values.outOccupancyMediumMinThreShold),
                          parseInt(values.outOccupancyMediumMaxThreShold),
                          parseInt(values.outOccupancyMaxThreShold) || 100,
                        ]}
                        indicators={["HEALTHY", "MODERATE", "POOR"]}
                        hideMinHandle={true}
                        min={0}
                        max={parseInt(values.outOccupancyMaxThreShold)}
                        maxLabel={values.outOccupancyMaxThreShold}
                        className={classes.tripWireSliderWidth}
                        onChange={(values) =>
                          setSensorRangeOUT(values, setFieldValue)
                        }
                        onMaxLabelChange={(e) =>
                          handleChangeMaxRangeOut(e.target.value, setFieldValue)
                        }
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container={true} spacing={3}>
                  <Grid item={true} sm={12} className={classes.itemSpace}>
                    <TripWirePhoto
                      name={"imageKey"}
                      label={"tripWirePhoto"}
                      tripWire={selectedTripWire}
                      currentUrl={selectedTripWire?.imageKey}
                      setRemoveImage={setRemoveImage}
                      setFormImageKey={setFormImageKey}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} spacing={3}>
                  <Grid item={true} sm={12} className={classes.itemSpace}>
                    <InputLabel className={classes.inputLabel}>
                      {formatMessage({
                        id: "TripWireEdit.label.status",
                        defaultMessage: "Status",
                        description: "TripWire status",
                      })}
                    </InputLabel>
                    <ActivitySwitch
                      name={"tripWireStatus"}
                      defaultValue={active}
                      disabled={false}
                      value={active}
                      onChange={(_: any) => toggleSwitchButton(setFieldValue)}
                      onBlur={(_: any) => toggleSwitchButton(setFieldValue)}
                      activeToggleText="Active"
                      inactiveToggleText="Inactive"
                    />
                  </Grid>
                </Grid>
                <Button
                  id="update-btn-tripWireEdit"
                  type="submit"
                  className={classes.submitBtn}
                >
                  {formatMessage({
                    id: "TripWireEdit.label.updateButton",
                    defaultMessage: "Update",
                    description: "TripWire form update button",
                  })}
                </Button>
              </FormikForm>
            );
          }}
        </Formik>
        <CustomModal open={showModal}>
          <ModalContent
            headerText={"Update TripWire Settings ?"}
            yes={confirmChanges}
            no={rejectChanges}
          />
        </CustomModal>
      </Grid>
    )
  );
}
