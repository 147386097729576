import React from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: "#2F56A1",
    textTransform: "initial",
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(28),
    color: "#2F56A1",
    marginBottom: "28px",
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: "0E1D33",
    fontWeight: 600,
    marginBottom: "60px",
    alignSelf: "left",
    width: "90%",
  },
  line: {
    marginBottom: "15px",
  },
  content: {
    display: "flex",
    minWidth: "425px",
    minHeight: "315px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    padding: "29px 37px",
    alignItems: "center",
    borderRadius: "20px",
    boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
}));

interface ModalContentProps {
  no: Function;
  yes: Function | (() => Promise<void>);
}

export function Modal({ no, yes }: ModalContentProps) {
  const classes = useStyles();

  return (
    <Grid className={classes.content}>
      <Typography className={classes.header}>Save New User</Typography>
      <Typography className={classes.description}>
        <div className={classes.line}>New User will be added.</div>
        Please confirm to Save and Add, or Cancel.
      </Typography>
      <Grid item className={classes.buttonsWrapper}>
        <Button className={classes.cancel} onClick={() => no()}>
          Cancel
        </Button>
        <Button className={classes.editBtn} onClick={() => yes()}>
          Save and Add
        </Button>
      </Grid>
    </Grid>
  );
}
