import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import { PageHeader, Form, SwipePanel } from "../../../../common/components";
import { ROUTES } from "../../../../common/constants/Routing";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { createBusinessEntityFields } from "./BusinessEntity.form";
import { createLocationDetailsFields } from "./LocationDetails.form";
import { EditWorkplaceSettings } from "./WorkplaceSettings";
import { useFormWizard } from "../../../../core/context/functions/FormWizard.functions";
import { useSnackbar } from "notistack";
import { toCelcius } from "../../../../common/utils/General.utils";

export const BusinessForm = () => {
  const [updateBusiness] = useMutation(
    GQLService.mutations.updateBusinessEntity,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allProperties,
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { selectedProperty, setSelectedProperty } = usePropertiesState();
  const { reset } = useFormWizard();

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const handleSubmit = useCallback(
    async (formData: any) => {
      try {
        updateBusiness({
          variables: { ...formData, propertyId: selectedProperty?.id! },
        }).then(() => {
          enqueueSnackbar("Property changed successfully", {
            variant: "success",
          });
          setSelectedProperty(formData);
          history.push(
            ROUTES.PROPERTY.replace(":uid", selectedProperty?.id!) + "#0"
          );
        });
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: "error",
          persist: true,
        });
      }
    },
    [history] //eslint-disable-line
  );

  return (
    <Grid item={true} md={6} sm={12}>
      <Form
        defaultValues={selectedProperty || {}}
        fields={createBusinessEntityFields({ property: selectedProperty })}
        // validationSchema={VALIDATION_RULES}
        onSubmit={handleSubmit}
      />
    </Grid>
  );
};

export const LocationDetails = () => {
  const [editLocation] = useMutation(
    GQLService.mutations.updateLocationDetails,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allProperties,
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  const history = useHistory();
  const { selectedProperty, setSelectedProperty } = usePropertiesState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { reset } = useFormWizard();

  const handleSubmit = useCallback(
    async (formData: any) => {
      if (formData.timeZoneName !== null) {
        try {
          editLocation({
            variables: { ...formData, propertyId: selectedProperty?.id! },
          }).then(() => {
            enqueueSnackbar("Property changed successfully", {
              variant: "success",
            });
            setSelectedProperty(formData);
            history.push(
              ROUTES.PROPERTY.replace(":uid", selectedProperty?.id!) + "#1"
            );
          });
          reset();
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
        }
      } else {
        enqueueSnackbar("select timezone", {
          variant: "error",
          persist: true,
        });
      }
    },
    [history] //eslint-disable-line
  );
  return (
    <Grid item={true} md={8} sm={12}>
      <Form
        defaultValues={selectedProperty || {}}
        fields={createLocationDetailsFields({ property: selectedProperty })}
        // validationSchema={VALIDATION_RULES}
        onSubmit={handleSubmit}
      />
    </Grid>
  );
};

const activeUpNext = (tabNames: any) => (
  <SwipePanel tabNames={tabNames}>
    <BusinessForm />
    <LocationDetails />
    <EditWorkplaceSettings />
  </SwipePanel>
);

const inactiveUpNext = (tabNames: any) => (
  <SwipePanel tabNames={tabNames}>
    <BusinessForm />
    <LocationDetails />
    <EditWorkplaceSettings />
  </SwipePanel>
);

const renderTabs = (
  upNext: boolean,
  tabNames: string[],
  withUpnextTabNames: string[]
) => (upNext ? activeUpNext(withUpnextTabNames) : inactiveUpNext(tabNames));

export function EditProperty() {
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();

  const tabNames = [
    formatMessage({
      id: "property.tabNames.businessEntity",
      defaultMessage: "Business Entity",
      description: "Business entity tab label",
    }),
    formatMessage({
      id: "property.tabNames.locationDetails",
      defaultMessage: "Location Details",
      description: "Location Details tab label",
    }),
    formatMessage({
      id: "property.tabNames.workplaceSettings",
      defaultMessage: "Workplace Settings",
      description: "Workplace Settings tab label",
    }),
  ];

  const withUpnextTabNames = [...tabNames];

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addProperty.header",
          defaultMessage: "Edit property details",
          description: "edit property label",
        })}
      />
      {renderTabs(selectedProperty?.upNext!, tabNames, withUpnextTabNames)}
    </div>
  );
}
