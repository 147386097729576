import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { AccessPoint, Sensor } from "../../../../types";
import { SensorsItemList } from "./SensorsItemList";
import { SensorDetailsAirQualityForFloor } from "../../../../types/airQuality";

const useStyles = makeStyles((theme) => ({
  sideList: {
    display: "flex",
    borderRight: `1px solid ${theme.palette.common.lightGray}`,
    flexDirection: "column",
  },
  title: {
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    padding: theme.typography.pxToRem(20),
    color: theme.palette.common.darkBlue,
    borderBottom: `1px solid ${theme.palette.common.lightGray}`,
  },
}));

type SensorToRender = { sensor: SensorDetailsAirQualityForFloor } & {
  active?: boolean;
};

type SideListPropType = {
  data: SensorDetailsAirQualityForFloor[];
  toggleActive: (el: string) => void;
  handleIsPinned?: (el: any) => void;
  selectedTime: number;
};

export const SensorsSideList = ({
  data,
  toggleActive,
  handleIsPinned,
  selectedTime,
}: SideListPropType) => {
  const classes = useStyles();
  return (
    <Grid className={classes.sideList}>
      <Typography className={classes.title}>
        Sensors {`(${data.length})`}
      </Typography>
      {data.map((el, i) => (
        <SensorsItemList
          data={el}
          toggleActive={toggleActive}
          handleIsPinned={handleIsPinned}
          selectedTime={selectedTime}
        />
      ))}
    </Grid>
  );
};
