import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Backdrop,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cell: {
    height: 60,
    [theme.breakpoints.up("sm")]: {
      height: "auto",
    },
    marginBottom: theme.typography.pxToRem(30),
    flexDirection: "row",
    marginLeft: "20px",
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.dustyGray,
    textTransform: "uppercase",
    height: 27,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: theme.typography.pxToRem(14),
    },
  },
  cellValue: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.blackPearl,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(14),
      width: "100%",
    },
  },
}));

interface SSIDDetailLabelProps {
  label: string;
  value?: string;
  children?: React.ReactNode;
}

export function SSIDDetailLabel({
  label,
  value,
  children,
}: SSIDDetailLabelProps) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.cell}
      container={true}
      item={true}
      direction="column"
    >
      <Typography className={classes.cellLabel}>{label}</Typography>
      {children ? (
        children
      ) : (
        <Typography className={classes.cellValue}>{value || "-"}</Typography>
      )}
    </Grid>
  );
}
