import React, { useMemo, useState } from "react";
import { Divider, Grid, Typography, Paper, Button } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import { ClickAwayListener } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { useStyles } from "./SideListItem.styles";
import clsx from "clsx";
import { BarChartForDensity } from "../../../../common/components/Charts/BarCharts/BarChartForDensity";
import { LinearChartForDensity } from "../../../../common/components/Charts/LinearCharts/LinearChartForDensity";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import moment from "moment";
import { useIntl } from "react-intl";
import {
  AccessPointWithSpaceLoad,
  AccessPointWithSpaceLoadTimeline,
  DashboardDeviceType,
} from "../../../../types";
import { useTimeSliderState } from "../../../../core/context/containers/TimeSlider.container";
import { ReactComponent as Pin } from "../../../../common/assets/images/pin.svg";
import { MyPinsModal } from "../../MyPins/MyPinsModal";
import { useFiltersState } from "../../../../core/context/containers/Filters.container";
import { UnpinModal } from "../../MyPins/UnpinModal";

type classNameIndicator =
  | "highIndicator"
  | "lowIndicator"
  | "mediumIndicator"
  | "notConfiguredIndicator"
  | "offlineIndicator";

type APStatisticsPopUpProps = {
  open?: any;
  id?: any;
  anchorEl?: any;
  handleClickAway?: () => void | undefined;
  handleClose?: any;
  data?: AccessPointWithSpaceLoad;
  //dataT?: AccessPointWithSpaceLoadTimeline;
  className?: classNameIndicator | null;
  dataToRender?: any;
  timeRange?: any;
  loading?: any;
  error?: any;
  selectedTime?: number;
  handleIsPinned?: (el: any) => void;
};

export function APStatisticsPopUp({
  open,
  id,
  anchorEl,
  handleClickAway,
  handleClose,
  data,
  //dataT,
  className,
  dataToRender,
  timeRange,
  loading,
  error,
  selectedTime,
  handleIsPinned,
}: APStatisticsPopUpProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty, selectedPropertyTimeZone } = usePropertiesState();
  const { selectedTimeSlider } = useTimeSliderState();
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const { selectedFloor, selectedSpace } = useFiltersState();
  const [toggleUnpinModal, setToggleUnpinModal] = useState<boolean>(false);

  const dwellTimeFormat = useMemo(() => {
    if (dataToRender?.averageDwellTime) {
      if (dataToRender?.averageDwellTime > 3600) {
        return "HH [hrs] mm [min]";
      } else {
        return "mm [min]";
      }
    }
    return "";
  }, [dataToRender]);

  return (
    <>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}
        placement="bottom-end"
        popperOptions={{
          modifiers: {
            flip: { enabled: true },
            offset: {
              enabled: true,
            },
          },
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            handleClickAway!();
            setToggleModal(false);
          }}
        >
          <Paper classes={{ root: classes.paper }}>
            <CancelIcon
              color="primary"
              onClick={(e) => {
                handleClose(e);
                setToggleModal(false);
              }}
              classes={{ root: classes.closeIcon }}
            />
            <Grid className={classes.chartsContainer}>
              <Grid className={classes.popperContainer}>
                <Typography className={classes.popperTitle}>
                  Occupancy
                </Typography>
                <Grid className={classes.currentStatusIndicatorContainer}>
                  <Typography className={classes.statusLabel}>
                    Current status:
                  </Typography>
                  <Typography className={classes.status}>
                    {data?.spaceLoad!}
                  </Typography>
                  {/* <Typography className={classes.status}>
                    {data?.spaceLoad ||
                      dataT?.timeline[selectedTimeSlider!]?.spaceLoad}
                  </Typography> */}
                  {className && (
                    <span
                      className={clsx(
                        classes[className],
                        classes.popperIndicator
                      )}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid className={classes.popperContainer}>
                <Typography className={classes.chartsTitle}>
                  Occupancy during time
                </Typography>
              </Grid>
              <LinearChartForDensity
                data={dataToRender?.densityTimeline?.map(
                  (d: any, i: number) => {
                    return {
                      x:
                        timeRange === "DAY"
                          ? moment
                              .tz(d.from, selectedProperty?.timeZoneName!)
                              .format("DD MMM ddd")
                          : moment
                              .tz(d.from, selectedProperty?.timeZoneName!)
                              .format("LT"),
                      y: d.connectedDevicesCount,
                    };
                  }
                )}
                spaceLoad={{
                  low: selectedProperty?.spaceLoadMediumMin!,
                  high: selectedProperty?.spaceLoadMediumMax!,
                }}
                loading={loading}
                error={error}
              />
              <Typography className={classes.chartsTitle}>
                Occupancy time
              </Typography>
              <BarChartForDensity
                data={[
                  {
                    x: "Low",
                    y: dataToRender?.lowDensityTime,
                    fill: "rgba(79, 158, 83, 0.15)",
                  },
                  {
                    x: "Medium",
                    y: dataToRender?.mediumDensityTime,
                    fill: "rgba(238, 170, 70, 0.2)",
                  },
                  {
                    x: "High",
                    y: dataToRender?.highDensityTime,
                    fill: "rgba(176, 0, 31, 0.2)",
                  },
                ]}
                loading={loading}
                error={error}
              />
              <Divider className={classes.divider} />
              <Grid className={classes.popperContainer}>
                <Typography className={classes.dwellTimeTitle}>
                  {formatMessage({
                    id: "analytics.summaryDashboard.statistics.dwellTime",
                    defaultMessage: "Dwell Time",
                    description: "Average Duration time for the devices",
                  })}
                </Typography>
              </Grid>
              {!loading ? (
                <Grid className={classes.popperContainer}>
                  <Typography className={classes.dwellTime}>
                    {dataToRender?.averageDwellTime
                      ? moment()
                          .startOf("day")
                          .seconds(dataToRender?.averageDwellTime)
                          .format(dwellTimeFormat)
                      : "N/A"}
                  </Typography>
                </Grid>
              ) : null}
              {!loading &&
                (dataToRender?.isPinned ? (
                  <Grid style={{ justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      className={classes.pinItButton}
                      onClick={() => setToggleUnpinModal(true)}
                    >
                      <div style={{ marginLeft: "8px" }}></div>
                      Unpin It
                    </Button>
                  </Grid>
                ) : (
                  <Grid style={{ justifyContent: "flex-end", display: "flex" }}>
                    <Button
                      className={classes.pinItButton}
                      onClick={() => setToggleModal(true)}
                    >
                      <Pin />
                      <div style={{ marginLeft: "8px" }}></div>
                      Pin It
                    </Button>
                  </Grid>
                ))}
            </Grid>
            {toggleModal && (
              <MyPinsModal
                setToggleModal={setToggleModal}
                selectedTime={selectedTime}
                selectedSpace={selectedSpace}
                selectedFloor={selectedFloor}
                deviceType={DashboardDeviceType.ACCESSPOINT}
                deviceId={
                  // selectedTime === 1440
                  //   ? dataT?.accessPoint?.id! :
                  data?.accessPoint?.id!
                }
                timeRange={timeRange}
                handleIsPinned={handleIsPinned}
              />
            )}
            <Grid style={{ left: "17%", position: "absolute", top: "20%" }}>
              {toggleUnpinModal && (
                <UnpinModal
                  setToggleModal={setToggleUnpinModal}
                  deviceType={DashboardDeviceType.ACCESSPOINT}
                  deviceId={
                    // selectedTime === 1440
                    //   ? dataT?.accessPoint?.id! :
                    data?.accessPoint?.id!
                  }
                  handleIsPinned={handleIsPinned}
                  Type="FloorOverview"
                />
              )}
            </Grid>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
