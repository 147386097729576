import React from "react";
import {
  EmptyListView,
} from "../../../../../common/components";
import { useGroupState } from "../../../../../core/context/containers/Groups.container";
import { makeStyles, Grid, InputLabel } from "@material-ui/core";
import { useIntl } from "react-intl";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  content: {
    border: `1px solid ${theme.palette.common.lightGray}`,
    padding: '10px',
  },
  label: {
    margin: "0 0 5px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkGrey,
    lineHeight: "18px",
    whiteSpace: "nowrap",
  },
}));

export function PermissionList() {
  const { startDate, endDate } = useGroupState();

  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div>
      {startDate || endDate ? (
        <Grid container className={classes.content}>
        <Grid item md={4}>
          <InputLabel className={classes.label}>
            {formatMessage({
              id: "permission.startDay.label",
              defaultMessage: "Start Day",
              description: "Start Day",
            })}
          </InputLabel>
          {moment(startDate).format("MM/DD/yyyy")}
        </Grid>
        <Grid item md={4}>
          <InputLabel className={classes.label}>
            {formatMessage({
              id: "permission.startDay.label",
              defaultMessage: "End Day",
              description: "End Day",
            })}
          </InputLabel>
          {moment(endDate).format("MM/DD/yyyy")}
        </Grid>
      </Grid>
      ) : (
        <EmptyListView viewType="permission" />
      )}
    </div>
  );
}
