import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { usePropertiesState } from "../../core/context/containers/Properties.container";
import GQLService from "../../core/services/GQL.service";
import { Summary } from "./Summary/Summary";
import { UserFilterPreferenceType } from "../../types";
import { useUserState } from "../../core/context/containers/User.container";

export default function NetworkAnalytics() {
  const { selectedProperty } = usePropertiesState();
  const { userEmail } = useUserState();
  const [userPreferenceFilter, setUserPreferenceFilter] = useState<
    any | undefined
  >();

  const [
    getUserFilterPrefernce,
    {
      data: userFilterPrefernceResponse,
      loading: loadingUserFilterPrefernce,
      error: userFilterPrefernceError,
    },
  ] = useLazyQuery(GQLService.queries.getUserFilterPrefernce);

  useEffect(() => {
    let isMounted = true;
    getUserFilterPrefernce({
      variables: {
        dataType: UserFilterPreferenceType.DASHBOARD,
        email: userEmail,
        propertyId: selectedProperty?.id,
      },
    });
    return () => {
      isMounted = false;
    };
  }, [selectedProperty]);

  useEffect(() => {
    if (!loadingUserFilterPrefernce && userFilterPrefernceResponse) {
      setUserPreferenceFilter(userFilterPrefernceResponse);
    } else {
      setUserPreferenceFilter(null);
    }
  }, [userFilterPrefernceResponse]);

  return (
    <>
      <Summary
        userPreferenceFilterSummary={userPreferenceFilter}
        loadingUserFilterPrefernceSummary={loadingUserFilterPrefernce}
      />
    </>
  );
}
