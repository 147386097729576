import React, { useState, useCallback } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Room } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { Property } from "../../../../types";
import { StatusIndicator } from "../../../../common/components";
import { PropertyMenu } from "../components/PropertyMenu/PropertyMenu";
import { ROUTES } from "../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { useProperties } from "../../../../core/context/functions/Properties.functions";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { listStyles } from "../../../../common/styles/lists";
import { IsJoinAdmin } from "../../../../common/components/canSee/canSee";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
  extendedListSubheader: {
    ...listStyles.extendedListSubheader,
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  pin: { color: theme.palette.common.funBlue },
  container: {
    border: `0.2px solid ${theme.palette.common.nobel}`,
    marginBottom: 8,
  },
  detailsContainer: {
    margin: "0 44px",
  },
  cell: {
    height: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.funBlue,
  },
  item: {
    margin: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  arrow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

interface PropertyListItemProps {
  item: Property;
}

export function PropertyListItem({ item }: PropertyListItemProps) {
  const classes = useStyles();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const { selectProperty } = useProperties();

  const [updateBusinessEntity] = useMutation(
    GQLService.mutations.updateBusinessEntity,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allProperties,
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const toggleActiveStatus = useCallback(
    async () => {
      let isMounted = true;
      if (isMounted) {
        await updateBusinessEntity({
          variables: {
            ...item,
            propertyId: item?.id!,
            companyStatus: !item.companyStatus,
          },
        });
      }
      return () => {
        isMounted = false;
      };
    },
    [item] //eslint-disable-line
  );

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpand = useCallback(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const handleViewClick = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>,
      id: string
    ) => {
      e.preventDefault();
      e.stopPropagation();
      selectProperty(id);
      history.push(ROUTES.PROPERTY.replace(":uid", id));
    },
    [item]
  );

  return (
    <Accordion key={item.id} expanded={isExpanded}>
      <AccordionSummary onClick={(e) => handleViewClick(e, item.id)}>
        <Grid
          container={true}
          alignItems="center"
          justify="space-between"
          classes={{ root: classes.item }}
        >
          <Grid
            container={true}
            item={true}
            md={4}
            sm={3}
            justify="flex-start"
            onClick={handleExpand}
            className={classes.headerWrapper}
          >
            {isExpanded ? (
              <ExpandLess id={`expandLess-PROPERTY:${item.id}`} className={classes.expandIcon} />
            ) : (
              <ExpandMore id={`expandMore-PROPERTY:${item.id}`} className={classes.expandIcon} />
            )}
            <Room className={classes.pin} />
            <Typography
              className={classes.name}
              onClick={(e) => handleViewClick(e, item.id)}
            >
              {item.siteName}
            </Typography>
          </Grid>
          <Grid
            container={true}
            item={true}
            md={6}
            sm={6}
            // onClick={handleExpand}
          >
            <IsJoinAdmin>
              <Grid item={true} md={5} sm={12}>
                <Typography className={classes.subheader}>
                  {item.ownerCompanyName}
                </Typography>
              </Grid>
            </IsJoinAdmin>
            <Grid item={true} md={5} sm={12}>
              <Typography className={classes.subheader}>
                {item.siteServiceAddress}
              </Typography>
            </Grid>
          </Grid>
          <Grid container={true} item={true} md={1} sm={1} justify="flex-end">
            <Typography className={classes.subheader}>
              {item.companyStatus
                ? formatMessage({
                    id: "properties.active.label",
                    defaultMessage: "Active",
                    description: "Active Label",
                  })
                : formatMessage({
                    id: "properties.inactive.label",
                    defaultMessage: "Inactive",
                    description: "Inactive Label",
                  })}
            </Typography>
          </Grid>
          <Grid
            container={true}
            item={true}
            md={1}
            sm={2}
            alignItems="center"
            justify="space-around"
          >
            <StatusIndicator uid={item.id} isActive={item.companyStatus} />
            <AccordionActions
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              className={classes.arrow}
            >
              <PropertyMenu property={item} index={item.id}/>
            </AccordionActions>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container={true} className={classes.detailsContainer} spacing={2}>
          <Cell
            label={formatMessage({
              id: "properties.legalcompanyname.label",
              defaultMessage: "Owner Company Name",
              description: "Owner Company Name Label",
            })}
            value={item.ownerCompanyName}
          />

          <Cell
            label={formatMessage({
              id: "properties.phonenumber.label",
              defaultMessage: "Phone Number",
              description: "Phone Number Label",
            })}
            value={item.primaryContactPhoneNumber || ""}
          />
          <Cell
            label={formatMessage({
              id: "properties.status.label",
              defaultMessage: "Status",
              description: "Status Name Label",
            })}
          >
            <Grid container={true} alignItems="center">
              <StatusIndicator
                uid={item.id}
                isActive={item.companyStatus}
                canShowStatusLabel={true}
                onChangeFn={toggleActiveStatus}
              />
            </Grid>
          </Cell>
          <Cell
            label={formatMessage({
              id: "properties.website.label",
              defaultMessage: "Website",
              description: "Website Label",
            })}
            value={item.companyWebsite || ""}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

interface CellProps {
  label: string;
  value?: string | number;
  children?: React.ReactNode;
}

function Cell({ label, value, children }: CellProps) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.cell}
      container={true}
      item={true}
      md={4}
      sm={12}
      direction="column"
    >
      <Grid>
        <Typography className={classes.extendedListSubheader}>
          {label}
        </Typography>
        {children ? children : <Typography>{value ? value : "-"}</Typography>}
      </Grid>
    </Grid>
  );
}
