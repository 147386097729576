import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { Grid, makeStyles } from "@material-ui/core";
import { useQuery } from "@apollo/client";

import { Role } from "../../../../types";
import { ROUTES } from "../../../../common/constants/Routing";
import { useDoorState } from "../../../../core/context/containers/Door.container";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import GQLService from "../../../../core/services/GQL.service";
import { useSearchState } from "../../../../core/context/containers/Search.container";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { DoorsListItem } from "./DoorsListItem";

import {
  PageHeader,
  DataList,
  LinkButton,
  EmptyListView,
} from "../../../../common/components";

const useStyles = makeStyles(() => ({
  doorsHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export function Doors() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();
  const { searchDoors, doors, setDoors } = useDoorState();
  const auth = useAuth();

  const { loading, data } = useQuery(GQLService.queries.allDoors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  useEffect(
    () => {
      let isMounted = true;
      if (isMounted) {
        !loading && setDoors(data?.doorsByPropertyId || []);
      }
      return () => {
        isMounted = false;
      };
    },
    [data] //eslint-disable-line
  );

  const createDoorButton = useCallback(() => {
    return (
      <LinkButton id="addNewDoorBtn"
        to={ROUTES.FLOOR_ADD_DOORS.replace(":uid", selectedProperty?.id!)}
        label={formatMessage({
          id: "Doors.button.label",
          defaultMessage: "Add New Door",
          description: "add a Door button label",
        })}
      />
    );
  }, [formatMessage, selectedProperty?.id]);

  const { searchValue } = useSearchState();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      searchDoors(searchValue);
    }
    return () => {
      isMounted = false;
    };
  }, [searchValue, searchDoors]);

  return (
    <div>
      <Grid className={classes.doorsHeader}>
        <PageHeader
          title={formatMessage({
            id: "Doors.header",
            defaultMessage: "Doors",
            description: "manage doors header",
          })}
        />
        {doors.length !== 0
          ? auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "doors:create",
              createDoorButton()
            )
          : null}
      </Grid>
      {doors.length === 0 ? (
        <EmptyListView viewType="doors" />
      ) : (
        <DataList data={doors} loading={loading} ListItem={DoorsListItem} />
      )}
    </div>
  );
}
