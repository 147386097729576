import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { User } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useUserBase() {
  const [users, setUsers] = useState<User[]>([]);
  const [usersFromResponse, setUsersFromResponse] = useState<User[]>([]);
  const {
    state: selectedUser,
    setState: setSelectedUser,
  } = usePersistedState<User | null>("user", null);

  const {
    state: selectedUsers,
    setState: setSelectedUsers,
  } = usePersistedState<User[] | []>("users", []);

  const { search } = useSearch();

  const { state: image, setState: setImage } = usePersistedState<string | null>(
    "userUploadedAvatar",
    null
  );

  const searchUsers = useCallback(
    (searchValue: string) => {
      setUsers(search(usersFromResponse, searchValue) as User[]);
    },
    [search, usersFromResponse]
  );

  const { state: userEmail, setState: setUserEmail } = usePersistedState<
    string
  >("userEmailId", "");

  return {
    searchUsers,
    users,
    usersFromResponse,
    setUsersFromResponse,
    setUsers,
    selectedUser,
    setSelectedUser,
    selectedUsers,
    setSelectedUsers,
    image,
    setImage,
    userEmail,
    setUserEmail,
  };
}

const { Provider, useContainer } = createContainer(useUserBase);

export const useUserState = useContainer;
export const UserProvider = Provider;
