import React, { useCallback, useState } from "react";
import { useStyles } from "./SideList";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Button, Grid, Input, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useFloorPlanEditorState } from "../../../../../core/context/containers/FloorPlanEditor/FloorPlanEditor.container";
import GQLService from "../../../../../core/services/GQL.service";
import { useMutation } from "@apollo/client";
import { Sensor } from "../../../../../types";

export function SensorListItem({ item }: { item: Sensor }) {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { floor } = useFloorPlanEditorState();

  const [updateSensor] = useMutation(GQLService.mutations.updateSensor, {
    refetchQueries: () => [
      {
        query: GQLService.queries.sensorsByFloorId,
        variables: { floorId: floor?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);

  const [sensorData, setSensorData] = useState({
    deviceUuid: item.deviceUuid,
    name: item.name,
    active: item.active,
    commonArea: item.commonArea,
  });

  const handleInputChange = useCallback(
    (e) => {
      setSensorData({ ...sensorData, [e.target.name]: e.target.value });
    },
    [sensorData]
  );

  const handleToggleEditMode = useCallback(() => {
    setIsInEditMode(!isInEditMode);
  }, [isInEditMode]);

  const handleSave = useCallback(() => {
    updateSensor({
      variables: {
        id: item.id,
        floorId: floor?.id,
        ...sensorData,
      },
    });
  }, [item.id, sensorData]); //eslint-disable-line

  return (
    <>
      <Grid
        container={true}
        className={clsx(
          isInEditMode ? classes.listItemEditMode : classes.listItem
        )}
        onClick={handleToggleEditMode}
      >
        <Grid container={true} justify={"space-between"} alignItems="center">
          <Grid
            container={true}
            item={true}
            md={11}
            sm={11}
            alignItems="center"
            justify={"flex-start"}
          >
            <Grid
              className={clsx(
                classes.statusIndicator,
                classes.innerCircle,
                item.active ? classes.innerCircleGreen : classes.innerCircleRed
              )}
              container={true}
              alignItems="center"
              justify="center"
            ></Grid>
            <Typography className={classes.blackText}>
              <b>{item.name}</b>
            </Typography>
          </Grid>
          <Grid item={true} md={1} sm={1}>
            {isInEditMode ? (
              <ExpandLess
                id={`expandLess-${item.id}`}
                className={classes.deleteIcon}
              />
            ) : (
              <ExpandMore
                id={`expandMore-${item.id}`}
                className={classes.deleteIcon}
              />
            )}
          </Grid>
        </Grid>
        <Grid container={true}>
          <Grid container={true}>
            <Typography className={classes.blackDescription}>
              {`Device Uuid: ${item.deviceUuid}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {isInEditMode ? (
        <Grid className={classes.editModeRoot} container={true}>
          <Typography className={classes.blackDescription}>
            {formatMessage({
              id:
                "analytics.floorPlanEditor.accessPointsList.editMode.label.baseMacAddress",
              defaultMessage: "Device UUID",
              description:
                "analytics floor plan editor acces points edit mode mac address field",
            })}
          </Typography>
          <Input
            className={classes.input}
            value={sensorData.deviceUuid}
            name="deviceUuid"
            placeholder="Sensor: device Uuid"
            disableUnderline={true}
            onChange={handleInputChange}
          />
          <Typography className={classes.blackDescription}>
            {formatMessage({
              id:
                "analytics.floorPlanEditor.accessPointsList.editMode.label.name",
              defaultMessage: "Name",
              description:
                "analytics floor plan editor acces points edit mode name field",
            })}
          </Typography>
          <Input
            className={classes.input}
            value={sensorData.name}
            name="name"
            placeholder="Sensor Name"
            disableUnderline={true}
            onChange={handleInputChange}
          />
          <Button
            id="saveBtn"
            className={clsx(classes.saveButton)}
            disabled={!sensorData.deviceUuid.length}
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      ) : null}
    </>
  );
}
