import { v1 as uuidv4 } from "uuid";

import { useFloorPlanEditorState } from "../../containers/FloorPlanEditor/FloorPlanEditor.container";
import { Floor } from "../../../../types";

export const useFloorPlanEditor = () => {
  const { floor, setFloor } = useFloorPlanEditorState();

  const selectFloor = (floor: Floor) => {
    setFloor(floor);
  };

  const addAccessPoint = (ap: any) => {
    setFloor({
      ...floor!,
      accessPoints: [
        ...floor!.accessPoints!,
        { ...ap, isActive: true, uid: uuidv4() },
      ],
    });
  };

  const setAccessPointsRange = (v: number) => {
    setFloor({
      ...floor!,
      accessPointsRadius: v,
    });
  };

  const deleteAccessPoint = (uid: string) => {
    setFloor({
      ...floor!,
      accessPoints: floor?.accessPoints?.filter((v) => v.uid !== uid),
    });
  };

  const editAccessPoint = (uid: string, apData: any) => {
    setFloor({
      ...floor!,
      accessPoints: floor?.accessPoints?.map((v) =>
        v.uid === uid
          ? { ...v, macAddress: apData.baseMacAddress, name: apData.name }
          : v
      ),
    });
  };

  return {
    selectFloor,
    addAccessPoint,
    setAccessPointsRange,
    deleteAccessPoint,
    editAccessPoint,
  };
};
