import React from "react";
import { useIntl } from "react-intl";

import { PageHeader } from "../../common/components";

import { Grid } from "@material-ui/core";
import { useStyles } from "./NetworkSettings.styles";
import Groups from "./Groups/Groups";
import IgnoredList from "./IgnoredLists/IgnoredLists";

export default function NetworkGroups() {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Grid>
      <Grid className={classes.usersHeader}>
        <PageHeader
          title={formatMessage({
            id: "application.classification",
            defaultMessage: "Application Classification",
            description: "manage application classification",
          })}
        />
      </Grid>
      <Grid md={12} sm={12} container direction="row">
        <Groups />
        <IgnoredList />
      </Grid>
    </Grid>
  );
}
