import * as Yup from "yup";

import { FormField } from "../../../../../types/form";
import I18n from "../../../../../core/services/I18n.service";
import {
  ActivitySwitch,
  PropertyDateFormatSelect,
  HealthSurveyFrequencySelect,
  SurveyActiveCheckbox,
} from "../../../../../common/components/Form/FormFields/index";

enum FIELD_NAMES {
  dateFormat = "dateFormat",
  use24HourClock = "use24HourClock",
  surveyActive = "surveyActive",
  healthSurveyFrequency = "healthSurveyFrequency",
}

export const createUpNextAppFormFields = (data: any): FormField[] => [
  {
    name: FIELD_NAMES.dateFormat,
    label: I18n.formatMessage({
      id: "upNextAppFormFields.label.DateFormat",
      defaultMessage: "Date Format",
    }),
    placeholder: I18n.formatMessage({
      id: "upNextAppFormFields.placeholder.dateFormat",
      defaultMessage: "Select Date Format",
    }),
    required: true,
    gridStyle: { sm: 12, md: 8 },
    Component: PropertyDateFormatSelect,
  },
  {
    name: FIELD_NAMES.use24HourClock,
    label: I18n.formatMessage({
      id: "upNextAppFormFields.label.use24HourClock",
      defaultMessage: "Use 24 Hour Clock",
    }),
    placeholder: I18n.formatMessage({
      id: "upNextAppFormFields.placeholder.use24HourClock",
      defaultMessage: "None",
    }),
    required: true,
    Component: ActivitySwitch,
  },
  {
    name: FIELD_NAMES.surveyActive,
    label: I18n.formatMessage({
      id: "upNextAppFormFields.label.surveyActive",
      defaultMessage: " ",
    }),
    placeholder: I18n.formatMessage({
      id: "upNextAppFormFields.placeholder.surveyActive",
      defaultMessage: " ",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: SurveyActiveCheckbox,
    fullWidth: false,
  },
  {
    name: FIELD_NAMES.healthSurveyFrequency,
    label: I18n.formatMessage({
      id: "upNextAppFormFields.label.healthSurveyFrequency",
      defaultMessage: "Health Survey Frequency",
    }),
    placeholder: I18n.formatMessage({
      id: "upNextAppFormFields.placeholder.healthSurveyFrequency",
      defaultMessage: "Select Frequency",
    }),
    required: true,
    gridStyle: { sm: 12, md: 8 },
    Component: HealthSurveyFrequencySelect,
  },
];

export const VALIDATION_RULES = Yup.object().shape({
  [FIELD_NAMES.dateFormat]: Yup.string().test(
    "oneOfRequired",
    I18n.formatMessage({
      id: "upnextSettings.validation.dateFormat.required",
      defaultMessage: "Date Format have to be seleted.",
    }),
    function () {
      return this.parent.dateFormat;
    }
  ),
  [FIELD_NAMES.use24HourClock]: Yup.boolean().required(" "),
  [FIELD_NAMES.surveyActive]: Yup.boolean().required(" "),
  [FIELD_NAMES.healthSurveyFrequency]: Yup.string().test(
    "oneOfRequired",
    I18n.formatMessage({
      id: "upnextSettings.validation.healthSurveyFrequency.required",
      defaultMessage: "Health Survey Frequency have to be selected.",
    }),
    function () {
      return this.parent.healthSurveyFrequency;
    }
  ),
});
