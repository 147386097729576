import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Button, ClickAwayListener, Grid, Typography } from "@material-ui/core";
import { useStyles } from "../NetworkSettings.styles";

interface ModalContentProps {
  no: Function;
  yes: Function | ((value: string) => Promise<void>);
  host: any;
}

export default function RemoveHostModal({ no, yes, host }: ModalContentProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const outSideClick = useCallback(() => {
    no();
  }, []);

  const onCancel = useCallback(() => {
    no();
  }, []);

  const removeHost = useCallback(() => {
    yes(host.id);
  }, [host]);

  return (
    <ClickAwayListener onClickAway={outSideClick}>
      <Grid className={classes.content}>
        <Grid className={classes.alignLeft}>
          <Typography id="modalTitle" className={classes.header}>
            {formatMessage({
              id: "removeHost.settings",
              defaultMessage: "Remove from the list",
              description: "Remove from the list",
            })}
          </Typography>
          <Grid>
            <Typography className={classes.subheader}>
              {formatMessage({
                id: "removeHost.settings",
                defaultMessage: "Do you want remove this Host from the  list?",
                description: "Do you want to remove this Host ?",
              })}
            </Typography>
            <Typography className={classes.hostName}>{host.name}</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.buttonsWrapper}>
          <Button id="cancelBtnEditModal" className={classes.cancel} onClick={onCancel}>
            {formatMessage({
              id: "addGroup.label.settings",
              defaultMessage: "Cancel",
              description: "group name label",
            })}
          </Button>
          <Button id="yesBtnEditModal" className={classes.confirm} onClick={removeHost}>
            {formatMessage({
              id: "addGroup.label.settings",
              defaultMessage: "Yes, Confirm",
              description: "group name label",
            })}
          </Button>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
}
