import React, { useState, useCallback, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { Box, Paper, Tabs, Tab } from "@material-ui/core";
import { useStyles } from "./SwipePanelTP.styles";
import { useHistory } from "react-router-dom";

interface SwipePanelTPProps {
  children: React.ReactNode[];
  filtersComponent?: React.ReactNode;
  tabNames?: string[];
  className?: string;
  stylesNew?: any;
  setSwipeIndex?: any;
  swipeIndex?: any;
}

export function SwipePanelTP({
  children,
  filtersComponent,
  tabNames = [],
  className = "",
  stylesNew = {},
  setSwipeIndex,
  swipeIndex,
}: SwipePanelTPProps) {
  const classes = useStyles();

  const history = useHistory();

  const initialIndex = +history.location.hash.substr(1);
  const [value, setValue] = useState<number>(swipeIndex || initialIndex);

  useEffect(() => {
    if (setSwipeIndex) {
      setSwipeIndex(value);
    }
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
      if (setSwipeIndex) {
        setSwipeIndex(newValue);
      }
    },
    []
  );

  const handleChangeIndex = useCallback((index: number) => {
    setValue(index);
    if (setSwipeIndex) {
      setSwipeIndex(index);
    }
  }, []);

  return (
    <div className={classes.container}>
      <Paper className={stylesNew.tabBar || classes.tabBar} elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            root: stylesNew.root || classes.root,
            indicator: stylesNew.tabIndicator || classes.tabIndicator,
          }}
        >
          {tabNames.map((name) => (
            <Tab
              key={name}
              fullWidth={false}
              classes={{
                root: stylesNew.tab || classes.tab,
                selected: classes.active,
              }}
              label={name}
              className={className}
            />
          ))}
        </Tabs>
      </Paper>
      {filtersComponent}
      <SwipeableViews
        axis="x-reverse"
        containerStyle={{
          transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
          marginTop: 24,
        }}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {children.map((child, index) => (
          <TabPanel key={index} value={value} index={index}>
            {child}
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel({ children, value, index }: TabPanelProps) {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && children}
    </Box>
  );
}
