import React, { useCallback, useState, useEffect } from "react";
import { useStyles } from "./DoorsSideList.styles";
import {
  Grid,
  Typography,
  Popper,
  ClickAwayListener,
  Paper,
  Button,
} from "@material-ui/core";
import { ReactComponent as DoorIcon } from "../../../../common/assets/images/door.svg";
import { Door, Floor } from "../../../../types";
import CancelIcon from "@material-ui/icons/Cancel";
import { ReactComponent as LineChart } from "../../../../common/assets/images/line-chart 2.svg";
import { LinearChartForDensity } from "../../../../common/components/Charts/LinearCharts/LinearChartForDensity";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";

type DoorListItemProps = {
  item: any;
  selectedTime: number;
  floor: Floor;
  timeRange: any;
  toggleActive: (el: Door) => void;
};

export function DoorsListItem({
  item,
  selectedTime,
  floor,
  timeRange,
  toggleActive,
}: DoorListItemProps) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [doorDetails, setDoorDetails] = useState<any>();
  const [dataToRender, setDataToRender] = useState<any>();
  const { selectedProperty, selectedPropertyTimeZone } = usePropertiesState();

  const classes = useStyles();

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>, item: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setOpen(true);
      setDoorDetails(item);
      event.stopPropagation();
    },
    [anchorEl]
  );

  const id = open ? "simple-popper" : undefined;

  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  const handleClose = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(null);
    setOpen(false);
    event.stopPropagation();
  }, []);

  const [
    densityDuringTimeForDoor,
    { loading, error, data: responseData },
  ] = useLazyQuery(GQLService.queries.densityDuringTimeForDoor, {
    fetchPolicy: "no-cache",
  });

  const getTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === 10080 || selectedTime === 43200) {
      if (selectedProperty?.timeZoneName) {
        newTimeTo = moment
          .tz(selectedProperty?.timeZoneName)
          .startOf("day")
          .format(); //ml
        newTimeFrom = moment
          .tz(newTimeTo, selectedProperty?.timeZoneName)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment.utc().startOf("day").format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    } else {
      if (selectedProperty?.timeZoneName) {
        newTimeTo = moment
          .tz(selectedProperty?.timeZoneName)
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedProperty?.timeZoneName)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, selectedProperty?.timeZoneName]);

  useEffect(() => {
    if (doorDetails) {
      densityDuringTimeForDoor({
        variables: {
          request: {
            floorId: floor?.id,
            doorId: doorDetails?.id,
            from: getTimeParams().newTimeFrom,
            to: getTimeParams().newTimeTo,
            timeRangeType: timeRange,
          },
        },
      });
    }
  }, [floor, densityDuringTimeForDoor, timeRange, doorDetails, getTimeParams]);

  useEffect(() => {
    setDataToRender(responseData?.densityDuringTimeForDoor);
  }, [responseData]);

  return (
    <>
      <Grid container={true} className={classes.listItem}>
        {item?.map((a: any) => (
          <div
            className={
              a.active ? classes.sublistItemActive : classes.sublistItem
            }
          >
            <Grid
              container={true}
              alignItems="center"
              onClick={(e) => toggleActive(a)}
            >
              <Grid
                container={true}
                item={true}
                md={9}
                sm={9}
                alignItems="center"
                justify={"flex-start"}
              >
                <Grid
                  sm={2}
                  md={2}
                  container={true}
                  alignItems="center"
                  justify="center"
                >
                  <DoorIcon className={classes.doorIcon} />
                </Grid>
                <Grid item sm={8} style={{ marginLeft: "3px" }}>
                  <Typography className={classes.doorNameText}>
                    {a.name ? a.name : a.doorUuid}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sm={2} md={2}>
                <Button
                  onClick={(event) => handleOpen(event, a)}
                  color="primary"
                >
                  <LineChart width={11} id="open" />
                </Button>
              </Grid>
            </Grid>
            <Grid>
              <Typography className={classes.blackDescription}>
                {a.doorUuid ? `UUID: ${a.doorUuid}` : null}
              </Typography>
            </Grid>
          </div>
        ))}
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={classes.popper}
          placement="bottom-end"
          popperOptions={{
            modifiers: {
              flip: { enabled: true },
              offset: {
                enabled: true,
              },
            },
          }}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper classes={{ root: classes.paper }}>
              <CancelIcon
                color="primary"
                onClick={(e) => handleClose(e)}
                classes={{ root: classes.closeIcon }}
              />
              <Grid className={classes.chartsContainer}>
                <Grid className={classes.popperContainer}>
                  <Typography className={classes.popperTitle}>
                    Door Statistics: Ingress
                  </Typography>
                </Grid>
                <Grid className={classes.popperContainer}>
                  <Typography className={classes.popperName}>
                    Ingress during selected time frame
                  </Typography>
                </Grid>
                <LinearChartForDensity
                  data={
                    dataToRender?.doorDensityTimeline?.map(
                      (d: any, i: number) => {
                        return {
                          x:
                            timeRange === "DAY"
                              ? moment
                                  .tz(d.from, selectedProperty?.timeZoneName!)
                                  .format("DD MMM ddd")
                              : moment
                                  .tz(d.from, selectedProperty?.timeZoneName!)
                                  .format("LT"),
                          y: d.peopleCount,
                        };
                      }
                    )!
                  }
                  spaceLoad={{
                    low: 0,
                    high: 100,
                    limit: 200,
                  }}
                  loading={loading}
                  error={error}
                  showArea={false}
                />
                {/* For future use */}
                {/* <Grid container justify="space-between">
                  <Typography className={classes.popperName}>
                    Top 5 Tenants Ingress
                  </Typography>
                  <Typography className={classes.popperButton}>
                    See All
                  </Typography>
                </Grid> */}
                {/* <BarChartForDensity
                  data={[
                    {
                      x: "Low",
                      y: 10,
                      fill: "rgba(79, 158, 83, 0.15)",
                    },
                    {
                      x: "Medium",
                      y: 10,
                      fill: "rgba(238, 170, 70, 0.2)",
                    },
                    {
                      x: "High",
                      y: 10,
                      fill: "rgba(176, 0, 31, 0.2)",
                    },
                  ]}
                // loading={tripWireLoading}
                // error={tripWireError}
                /> */}
                {/* <Grid
                  style={{ borderBottom: "1px solid", borderColor: "#E0EBF1" }}
                >
                  <Grid
                    container
                    justify="space-between"
                    style={{
                      borderBottom: "1px solid",
                      borderColor: "#E0EBF1",
                    }}
                  >
                    <Typography className={classes.popperTenats}>
                      Motorola
                    </Typography>
                    <Typography className={classes.popperTenats}>41</Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography className={classes.popperTenats}>
                      BXP
                    </Typography>
                    <Typography className={classes.popperTenats}>38</Typography>
                  </Grid>
                </Grid> */}
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Grid>
    </>
  );
}
