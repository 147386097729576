import React, { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import {
  Grid,
  Button,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";

import { FormField as FormFieldType } from "../../../../../types/form";
import { useAuth } from "../../../../../core/context/containers/Auth.container";

const useStyles = makeStyles((theme) => ({
  input: { display: "none" },
  button: {
    height: 80,
    border: `1px dashed ${theme.palette.common.turquoiseBlue30}`,
    backgroundColor: theme.palette.common.turquoiseBlue15,
    color: theme.palette.common.nepal,
  },
  buttonLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "none",
  },
  note: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.nepal,
  },
  preview: {
    minWidth: 240,
    minHeight: 180,
    objectFit: "contain",
    border: `1px solid ${theme.palette.common.nobel}`,
  },
  actionButtonsContainer: {
    marginTop: theme.spacing(1),
  },
  actionButton: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 100,
    height: 40,
    borderRadius: 30,
  },
  actionButtonDisabled: {
    backgroundColor: theme.palette.common.nobel,
    color: theme.palette.common.white,
    width: 100,
    height: 40,
    borderRadius: 30,
  },
  error: {
    borderColor: theme.palette.common.cinnabar,
  },
  progress: {
    color: theme.palette.common.white,
  },
}));

interface ImageInputProps {
  field?: FormFieldType;
  name: string;
  previewStyle?: string;
  currentUrl?: string;
  targetUid?: string;
  onRemoveComplete?: (fieldName: string) => void;
  onSaveComplete?: (fieldName: string, imageUrl: string) => void;
}

export function ImageInput({
  field,
  name,
  previewStyle,
  currentUrl,
  targetUid,
  onRemoveComplete,
  onSaveComplete,
}: ImageInputProps) {
  const classes = useStyles();

  const auth = useAuth();

  const { formatMessage } = useIntl();

  const [url, setUrl] = useState<string | undefined>(currentUrl);

  const [isEditing, setIsEditing] = useState<boolean>(!Boolean(currentUrl));

  const [error, setError] = useState<boolean>(false);

  const [data, setData] = useState<File | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "dragover",
        (e) => {
          e.preventDefault();
        },
        false
      );
      window.removeEventListener(
        "drop",
        (e) => {
          e.preventDefault();
        },
        false
      );
    };
  }, []);

  useEffect(() => {
    setUrl(currentUrl);
  }, [currentUrl]);

  const handleToggleEditMode = useCallback(() => {
    setUrl(undefined);
    setIsEditing(!isEditing);
  }, [isEditing]);

  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setError(false);

      if (e.target.files) {
        if (e.target.files[0].size / (1024 * 1024) >= 2) {
          setError(true);

          return;
        }

        setData(e.target.files[0]);
        setUrl(URL.createObjectURL(e.target.files[0]));
      }
    },
    []
  );

  const handleFileDrop = useCallback((e: React.DragEvent<HTMLInputElement>) => {
    setError(false);

    if (e.dataTransfer.files?.length) {
      if (e.dataTransfer.files[0].size / (1024 * 1024) >= 2) {
        setError(true);

        return;
      }

      setData(e.dataTransfer.files[0]);
      setUrl(URL.createObjectURL(e.dataTransfer.files[0]));
    }
  }, []);

  const handleSave = useCallback(() => {
    setIsLoading(true);
  }, [data, auth?.user, name, targetUid]); // eslint-disable-line

  const handleRemove = useCallback(() => {}, [auth?.user, name, targetUid]); // eslint-disable-line

  return (
    <Grid
      container={true}
      direction="column"
      alignItems="flex-start"
      justify="center"
    >
      {url ? (
        <img
          src={url}
          alt={name}
          className={clsx(classes.preview, previewStyle)}
        />
      ) : (
        <Grid>
          <input
            type="file"
            accept="image/jpeg, image/png"
            className={classes.input}
            id={field?.name || name}
            onChange={handleFileSelect}
          />
          <label htmlFor={field?.name || name}>
            <Button
              className={clsx(classes.button, error ? classes.error : "")}
              component="span"
              fullWidth={true}
              onDrop={handleFileDrop}
            >
              <Grid
                container={true}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <FileCopyOutlined />
                <Typography className={classes.buttonLabel}>
                  {formatMessage({
                    id: "imageInput.dragAndDrop",
                    defaultMessage: "Select File or Drag and Drop",
                    description: "Image input drag and drop label",
                  })}
                </Typography>
              </Grid>
            </Button>
          </label>
          <Typography className={classes.note}>
            {formatMessage({
              id: "imageInput.dragAndDropRules",
              defaultMessage:
                "Your upload must be: JPG or PNG format, less than 2 MB, and 160px\n(w) by 80px (h).",
              description: "Image input drag and drop help text",
            })}
          </Typography>
        </Grid>
      )}
      <Grid className={classes.actionButtonsContainer} container={true}>
        {url && (
          <Button
            className={classes.actionButton}
            onClick={handleToggleEditMode}
          >
            {data ? (
              <Typography className={classes.buttonLabel}>
                {formatMessage({
                  id: "imageInput.button.cancel",
                  defaultMessage: "Cancel",
                  description: "Image input drag and drop cancel button",
                })}
              </Typography>
            ) : (
              <Typography className={classes.buttonLabel}>
                {formatMessage({
                  id: "imageInput.button.change",
                  defaultMessage: "Change",
                  description: "Image input drag and drop change button",
                })}
              </Typography>
            )}
          </Button>
        )}
        <Button
          type="submit"
          className={
            isEditing && !data
              ? classes.actionButtonDisabled
              : classes.actionButton
          }
          disabled={(isEditing && !data) || isLoading}
          onClick={data ? handleSave : handleRemove}
        >
          {!url || data ? (
            isLoading ? (
              <CircularProgress size={24} className={classes.progress} />
            ) : (
              <Typography className={classes.buttonLabel}>
                {formatMessage({
                  id: "imageInput.button.save",
                  defaultMessage: "Save",
                  description: "Image input drag and drop save button",
                })}
              </Typography>
            )
          ) : (
            <Typography className={classes.buttonLabel}>
              {formatMessage({
                id: "imageInput.button.remove",
                defaultMessage: "Remove",
                description: "Image input drag and drop remove button",
              })}
            </Typography>
          )}
        </Button>
      </Grid>
    </Grid>
  );
}
