import React from "react";
import {
  ActivitySwitch,
  Input,
} from "../../../../common/components/Form/FormFields";
import * as Yup from "yup";

import { FormField } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";

const FIELD_NAMES = {
  name: "name",
  active: "active",
  beaconUuid: "beaconUuid",
};

export interface addAccessPointType {
  name: string;
  active: boolean;
  beaconUuid: string;
}

export const createFormFields = (): FormField[] => [
  {
    name: FIELD_NAMES.name,
    label: I18n.formatMessage({
      id: "beacon.add.label.name",
      defaultMessage: "Beacon Name",
    }),
    placeholder: I18n.formatMessage({
      id: "beacon.add.placeholder.name",
      defaultMessage: "Type here",
    }),
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.beaconUuid,
    label: I18n.formatMessage({
      id: "beacon.add.label.name",
      defaultMessage: "Beacon Uuid",
    }),
    placeholder: I18n.formatMessage({
      id: "beacon.add.placeholder.name",
      defaultMessage: "Type here",
    }),
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.active,
    label: I18n.formatMessage({
      id: "beacon.add.label.active",
      defaultMessage: "Active",
    }),
    placeholder: I18n.formatMessage({
      id: "beacon.add.placeholder.active",
      defaultMessage: "Type here",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: ActivitySwitch,
  },
];

export const BeaconValidationRules = Yup.object().shape({
  [FIELD_NAMES.name]: Yup.string()
    .min(
      3,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
  [FIELD_NAMES.beaconUuid]: Yup.string()
    .min(
      1,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .required(" "),
});
