import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { ElevatorBay, Elevator } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useElevatorBaysBase() {
  const [elevatorBays, setElevatorBays] = useState<ElevatorBay[]>([]);
  const [elevatorBaysFromResponse, setElevatorBaysFromResponse] = useState<
    ElevatorBay[]
  >([]);

  const {
    state: selectedElevatorBay,
    setState: setSelectedElevatorBay,
  } = usePersistedState<ElevatorBay | null>("elevatorBay", null);

  const { search } = useSearch();

  const searchElevatorBays = useCallback(
    (searchValue: string) => {
      setElevatorBays(
        search(elevatorBaysFromResponse, searchValue) as ElevatorBay[]
      );
    },
    [elevatorBaysFromResponse]
  );

  return {
    searchElevatorBays,
    elevatorBays,
    setElevatorBays,
    elevatorBaysFromResponse,
    setElevatorBaysFromResponse,
    selectedElevatorBay,
    setSelectedElevatorBay,
  };
}

const { Provider, useContainer } = createContainer(useElevatorBaysBase);

export const useElevatorBaysState = useContainer;
export const ElevatorBaysProvider = Provider;
