import React, { useState, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { Grid, Input } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { useStyles } from "./Search.styles";
import { useSearchState } from "../../../../core/context/containers/Search.container";
import { useLocation } from "react-router-dom";

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

export function Search() {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const [searchLocalState, setSearchLocalState] = useState<string>("");
  const { setSearchValue } = useSearchState();

  const debouncedSearchTerm = useDebounce(searchLocalState, 500);

  const handleSearchChange = useCallback(
    (event) => {
      setSearchLocalState(event.target.value);
    },
    [searchLocalState]
  );

  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setSearchLocalState("");
      setSearchValue("");
    }
    return () => {
      isMounted = false;
    };
  }, [location]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (debouncedSearchTerm) {
        setSearchValue(debouncedSearchTerm.trim());
      } else {
        setSearchValue("");
      }
    }
    return () => {
      isMounted = false;
    };
  }, [debouncedSearchTerm]);

  return (
    <Grid className={classes.search}>
      <Grid className={classes.searchWrapper}>
        <Input
          placeholder={formatMessage({
            id: "search.label",
            defaultMessage: "Search",
          })}
          className={classes.searchInput}
          value={searchLocalState}
          onChange={handleSearchChange}
        />
        <SearchIcon className={classes.searchIcon} />
      </Grid>
    </Grid>
  );
}
