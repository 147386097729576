import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { FieldInputProps, useFormikContext } from "formik";
import { Button, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    borderRadius: 8,
  },
  tile: {
    width: 99,
    height: 28,
    margin: 3,
    backgroundColor: "#F2F2F2",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    cursor: "pointer",
  },
  tileActive: {
    backgroundColor: theme.palette.common.funBlue,
    borderColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
  },
  tileInactive: {
    color: theme.palette.common.dustyGray,
  },
  button: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    cursor: "pointer",
    textTransform: "none",
    background: "#FFFFFF",
    border: "1px solid #2F56A0",
    borderRadius: "35px",
    color: theme.palette.common.funBlue,
    marginRight: "10px",
  },
  label: {
    margin: "20px 0 5px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    lineHeight: "18px",
    whiteSpace: "nowrap",
  },
}));

interface DayDragAndDropSelectProps extends FieldInputProps<any> {
  defaultValue: any;
  placeholder?: string | undefined;
  disabled?: boolean;
  data: any;
}

export function DayDragAndDropSelect({
  name,
  onChange,
  data,
}: DayDragAndDropSelectProps) {
  const classes = useStyles();

  const { values } = useFormikContext<{
    [field: string]: any;
  }>();

  const [selectedDaysIds, setSelectedDaysIds] = useState<string[]>(
    values.availableDays || []
  );

  const handleSelectDay = useCallback(
    (id: string) => {
      if (!selectedDaysIds.find((v) => v === id)) {
        setSelectedDaysIds([...selectedDaysIds, id]);
      } else {
        setSelectedDaysIds(selectedDaysIds.filter((v) => v !== id));
      }
    },
    [selectedDaysIds]
  );

  const handleSelectAll = useCallback(() => {
    setSelectedDaysIds(data?.map((v: any) => v.id) || []);
  }, [data]);

  const handleClear = useCallback(() => {
    setSelectedDaysIds([]);
  }, []);

  useEffect(() => {
    onChange!({
      e: null,
      target: { name, value: selectedDaysIds },
    });
  }, [selectedDaysIds]); // eslint-disable-line

  let buttonLabel = "Select all";
  let toggleMethod = handleSelectAll;
  if (selectedDaysIds.length === 7) {
    buttonLabel = "Clear all";
    toggleMethod = handleClear;
  }

  return (
    <Grid
      className={classes.root}
      container={true}
      onClick={(e) => e.preventDefault()}
    >
      {data.map((v: any) => (
        <Tile
          key={v.id}
          data={v}
          isActive={selectedDaysIds.includes(v.id)}
          onClick={() => handleSelectDay(v.id)}
        />
      ))}
      <Grid item>
        <Button className={classes.button} onClick={toggleMethod}>
          {buttonLabel} 
        </Button>
      </Grid>
    </Grid>
  );
}

interface TileProps {
  data: any;
  isActive: boolean;
  onClick: () => void;
}

function Tile({ data, isActive, onClick }: TileProps) {
  const classes = useStyles();

  const handleMark = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.persist();

      if (e.button === 0 && e.buttons === 1 && e.nativeEvent.shiftKey) {
        onClick();
      }
    },
    [onClick]
  );

  return (
    <div
      className={clsx(
        classes.tile,
        isActive ? classes.tileActive : classes.tileInactive
      )}
      onClick={onClick}
      onMouseDown={handleMark}
      onMouseOver={handleMark}
    >
      {data.id}
    </div>
  );
}
