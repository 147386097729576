import React from "react";
import { useIntl } from "react-intl";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { useFiltersState } from "../../../core/context/containers/Filters.container";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import { useUserState } from "../../../core/context/containers/User.container";
import { ViewType } from "./Occupancy";
import InfoIcon from "@material-ui/icons/Info";
import { UserFilterPreferenceType } from "../../../types";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    backgroundColor: theme.palette.common.regularBlue,
    color: theme.palette.common.white,
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: "#2F56A1",
    textTransform: "initial",
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(28),
    color: "#2F56A1",
    marginBottom: "16px",
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    color: "#0E1D33",
    fontWeight: 600,
    marginBottom: 10,
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(13),
    color: "#B2B2B2",
    fontWeight: 600,
    marginBottom: 10,
  },
  titleValue: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.common.blackText,
    fontWeight: 600,
    marginBottom: 10,
    marginLeft: "5px",
  },
  line: {
    marginBottom: "15px",
  },
  content: {
    display: "flex",
    width: "550px",
    height: "350px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    padding: "29px 37px",
    alignItems: "center",
    borderRadius: "20px",
    boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
}));

export function ModalContent({
  toggleModal,
  selectedLabel,
  viewType,
  handleSaveFilter,
  spaces,
  handleState,
  SSIDs,
}: any) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedTime, selectedFloor, selectedSSID, selectedSpace } =
    useFiltersState();
  const { selectedProperty } = usePropertiesState();
  const { userEmail } = useUserState();

  const handleClose = () => {
    toggleModal(false);
  };

  const handleSave = () => {
    const userPref = {
      dataType: viewType
        ? UserFilterPreferenceType.FLOOR
        : UserFilterPreferenceType.DASHBOARD,
      dataValue: {
        tabType: viewType,
        preferences: {
          floor: selectedFloor?.id,
          spaces: selectedSpace,
          ssid: selectedSSID,
          duration: selectedLabel,
        },
      },
      email: userEmail,
      propertyId: selectedProperty?.id,
    };
    handleSaveFilter(userPref);
    toggleModal(false);
    handleState();
  };

  const selectedSpaceName = spaces.find((v: any) => {
    if (v.id === selectedSpace) {
      return v.name;
    }
  });

  const selectedSSIDsName = SSIDs.find((v: any) => {
    if (v.id === selectedSSID) {
      return v.name;
    }
  });

  return (
    <Grid className={classes.content}>
      <Typography className={classes.header}>
        {formatMessage({
          id: "save.view.modal.header.title",
          defaultMessage: "Save This View",
        })}
      </Typography>
      <Typography className={classes.description}>
        <div className={classes.line}>
          {" "}
          {formatMessage({
            id: "accessPoints.add.modal.message.title",
            defaultMessage: "You have selected",
          })}
        </div>
        <Grid container>
          <Grid item xs={6}>
            <Grid style={{ flexDirection: "row", display: "flex" }}>
              <Typography className={classes.title}>Property : </Typography>
              <Typography className={classes.titleValue}>
                {selectedProperty?.siteName}
              </Typography>
            </Grid>
            <Grid style={{ flexDirection: "row", display: "flex" }}>
              <Typography className={classes.title}>Floor : </Typography>
              <Typography className={classes.titleValue}>
                {selectedFloor?.name
                  ? selectedFloor?.name
                  : selectedFloor?.floorNumber}
              </Typography>
            </Grid>
            <Grid style={{ flexDirection: "row", display: "flex" }}>
              <Typography className={classes.title}>Duration : </Typography>
              <Typography className={classes.titleValue}>
                {selectedLabel ? selectedLabel : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {viewType !== ViewType.DOORS && (
              <Grid style={{ flexDirection: "row", display: "flex" }}>
                <Typography className={classes.title}>Space: </Typography>
                <Typography className={classes.titleValue}>
                  {selectedSpace === "none"
                    ? "Whole Floor"
                    : selectedSpaceName?.name}
                </Typography>
              </Grid>
            )}
            {viewType === ViewType.ACCESS_POINT && (
              <Grid style={{ flexDirection: "row", display: "flex" }}>
                <Typography className={classes.title}>SSIDs: </Typography>
                <Typography className={classes.titleValue}>
                  {selectedSSID === "all" ? "All" : selectedSSIDsName?.name}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.line}>
            <Grid item sm={1}>
              <InfoIcon style={{ color: "skyblue" }} />
            </Grid>
            <Grid item sm={11}>
              <Typography
                style={{ color: "#B2B2B2" }}
                className={classes.description}
              >
                Floors Overview will be filterd to these saved preferences next
                time you login. You can select any other filter to see different
                view.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Typography>
      <Grid item className={classes.buttonsWrapper}>
        <Button
          id="cancelBtnModal"
          onClick={handleClose}
          className={classes.cancel}
        >
          {formatMessage({
            id: "accessPoints.add.modal.button.cancel",
            defaultMessage: "Cancel",
          })}
        </Button>
        <Button
          id="saveAddBtnModal"
          onClick={handleSave}
          className={classes.editBtn}
        >
          {formatMessage({
            id: "accessPoints.add.modal.button.saveAndAdd",
            defaultMessage: "Save",
          })}
        </Button>
      </Grid>
    </Grid>
  );
}
