import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Grid } from "@material-ui/core";
import { PageHeader, SwipePanel } from "../../../../common/components";
import {
  createMainInformationFormFields,
  UserValidationRules,
} from "./MainInformation.form";
import { Form } from "../../../../common/components";
import { ROUTES } from "../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { CustomModal } from "../../../../common/components/index";
import { ConfirmModal } from "./Modal";
import { PropertiesModal } from "./PropertiesModal";
import { Property, Role, User } from "../../../../types";
import { useUserState } from "../../../../core/context/containers/User.container";
import { PropertyList } from "../View/PropertiesList";
import { useStyles } from "./Edit.styles";
import { userRoles } from "../../../../common/constants/AuthTypes";
import { createEditFloorsForm } from "./Tenant/EditFloors";
import { useSnackbar } from "notistack";
import Loader from "react-loader-spinner";
import { COLORS } from "../../../../common/styles/colors";
import _ from "lodash";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../../../common/constants/DummyProperty";

export function EditUser() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const { selectedUser, userEmail } = useUserState();
  const classes = useStyles();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [formDataState, setFormDataState] = useState<User | null>(selectedUser);
  const [showPropertiesModal, togglePropertiesModal] = useState<boolean>(false);
  const [properties, setProperties] = useState<Property[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [editUser] = useMutation(GQLService.mutations.editUser, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allUsersInfo,
      },
    ],
    awaitRefetchQueries: true,
  });

  const { loading, data: propertiesResponse } = useQuery(
    GQLService.queries.allProperties,
    {
      fetchPolicy: "cache-first",
    }
  );

  const handleSubmit = useCallback(
    (formData) => {
      toggleModal(true);
      setFormDataState({
        ...formData,
        name: formData.fullName,
        active: formData.enabled,
        newEmail: formData.email,
        role: userRoles.find((role) => role.id === formData.role)?.id,
      });
    },
    [formDataState, selectedProperty] // eslint-disable-line
  );

  useEffect(() => {
    if (propertiesResponse) {
      if (userEmail === dummyUserEmail) {
        let clonedData = _.cloneDeep(propertiesResponse?.properties);
        const newProperties = clonedData?.map((item: any) => {
          if (item.id === DummyPropertyA.id) {
            const propA = {
              ...item,
              ownerCompanyName: DummyPropertyA.ownerCompanyName,
              siteName: DummyPropertyA.siteName,
              siteServiceAddress: DummyPropertyA.siteServiceAddress,
            };
            return propA;
          } else if (item.id === DummyPropertyB.id) {
            const propB = {
              ...item,
              ownerCompanyName: DummyPropertyB.ownerCompanyName,
              siteName: DummyPropertyB.siteName,
              siteServiceAddress: DummyPropertyB.siteServiceAddress,
            };
            return propB;
          } else if (item.id === DummyPropertyC.id) {
            const propC = {
              ...item,
              ownerCompanyName: DummyPropertyC.ownerCompanyName,
              siteName: DummyPropertyC.siteName,
              siteServiceAddress: DummyPropertyC.siteServiceAddress,
            };
            return propC;
          } else if (item.id === DummyPropertyD.id) {
            const propD = {
              ...item,
              ownerCompanyName: DummyPropertyD.ownerCompanyName,
              siteName: DummyPropertyD.siteName,
              siteServiceAddress: DummyPropertyD.siteServiceAddress,
            };
            return propD;
          } else {
            return item;
          }
        });
        const demoUserProps = newProperties?.filter(
          (item: any) =>
            item.id === DummyPropertyA.id ||
            item.id === DummyPropertyB.id ||
            item.id === DummyPropertyC.id ||
            item.id === DummyPropertyD.id
        );
        setProperties(
          demoUserProps.map((property: Property) => ({
            ...property,
            name: property.siteName,
          }))
        );
      } else {
        setProperties(
          propertiesResponse.properties.map((property: Property) => ({
            ...property,
            name: property.siteName,
          }))
        );
      }
    }
  }, [loading, propertiesResponse, userEmail]);

  const confirmChanges = useCallback(
    async (values) => {
      toggleModal(false);
      if (formDataState) {
        try {
          setLoader(true);
          await editUser({
            variables: {
              ...formDataState,
              properties: values
                ? values
                    .filter((property: any) =>
                      property.checked ? true : false
                    )
                    .map((property: any) => property.id)
                : selectedUser?.properties,
            },
          });
          enqueueSnackbar("User changed successfully", {
            variant: "success",
          });
          //   reset();
          history.push(ROUTES.USERS);
        } catch (e) {
          setLoader(false);
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          console.log(e);
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const renderSecondTab = useCallback(() => {
    if (
      selectedUser?.role?.toUpperCase().split(" ").join("_") ==
      Role.PROPERTY_ADMIN
    ) {
      return (
        <Grid item={true} md={12} sm={12}>
          <PropertyList />
          <Button
            className={classes.submitBtn}
            onClick={() => togglePropertiesModal(true)}
          >
            {formatMessage({
              id: "user.assignedProperties.addProperty",
              defaultMessage: "Add",
              description: "Assign new property",
            })}
          </Button>
        </Grid>
      );
    } else if (selectedUser?.role?.toUpperCase() == Role.TENANT) {
      return (
        <Grid item={true} md={6} sm={12}>
          <Form
            defaultValues={selectedUser}
            fields={createEditFloorsForm({ properties, user: selectedUser })}
            validationSchema={UserValidationRules}
            onSubmit={handleSubmit}
          />
        </Grid>
      );
    }
  }, [selectedUser, properties]);

  const updateProperties = useCallback(
    async (properties) => {
      toggleModal(false);
      try {
        await editUser({
          variables: {
            active: selectedUser?.enabled || false,
            email: selectedUser?.email,
            propertiesIds: properties
              .filter((property: any) => (property.checked ? true : false))
              .map((property: any) => property.id),
          },
        });
        history.push(ROUTES.USERS);
      } catch (e) {
        console.log(e);
      }
    },
    [selectedUser]
  );

  const rejectChanges = useCallback(() => {
    toggleModal(false);
  }, [showModal]);

  const closePropertiesModal = useCallback(() => {
    togglePropertiesModal(false);
  }, [showPropertiesModal]);

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "editUser.header",
          defaultMessage: `Edit User ${selectedUser?.fullName}`,
          description: "edit user label",
        })}
      />
      {loader ? (
        <Grid style={{ display: "flex", justifyContent: "center" }}>
          <Loader type="Oval" color={COLORS.funBlue} height={100} width={100} />
        </Grid>
      ) : (
        <SwipePanel
          tabNames={[
            formatMessage({
              id: "user.tabNames.mainInformations",
              defaultMessage: "Main Informations",
              description: "Main Informations details tab label",
            }),
            formatMessage({
              id: "user.tabNames.assignedSpaces",
              defaultMessage: "Assigned Spaces",
              description: "Assigned Spaces tab label",
            }),
          ]}
        >
          <Grid item={true} md={5} sm={12}>
            <Form
              defaultValues={Object.assign(
                { ...selectedUser },
                {
                  role: userRoles.find((role) => selectedUser?.role === role.id)
                    ?.id,
                }
              )}
              fields={createMainInformationFormFields({ userRoles })}
              validationSchema={UserValidationRules}
              onSubmit={handleSubmit}
            />
          </Grid>
          {renderSecondTab()}
        </SwipePanel>
      )}
      <CustomModal open={showModal}>
        <ConfirmModal
          yes={async () => await confirmChanges(null)}
          no={rejectChanges}
        />
      </CustomModal>
      <CustomModal open={showPropertiesModal}>
        <PropertiesModal
          yes={async (values: any) => await updateProperties(values)}
          no={closePropertiesModal}
        />
      </CustomModal>
    </div>
  );
}
