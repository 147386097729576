import React, { useState, useCallback, ChangeEvent, useEffect } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Room } from "@material-ui/icons";
import { ReactComponent as StarSVG } from "../../../../common/assets/images/star.svg";
import { Property } from "../../../../types";
import { ProfileMenu } from "./Menu";
import { ApolloQueryResult } from "@apollo/client";
import { useUserState } from "../../../../core/context/containers/User.container";
import _ from "lodash";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../../../common/constants/DummyProperty";

const useStyles = makeStyles((theme) => ({
  name: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.funBlue,
    marginLeft: "5px",
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.dustyGray,
    marginRight: "25%",
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  container: {
    margin: "10px 44px",
    justifyContent: "space-between",
  },
  cell: {
    minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  ssidInfo: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.blackPearl,
  },
  subheader: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.dustyGray,
  },
  content: {},
  accordionDetails: {
    paddingBottom: "40px",
    flexDirection: "column",
  },
  list: {
    width: "100%",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  ssidInfoGrid: {
    display: "flex",
    width: "50%",
    justifyContent: "space-between",
  },
  status: {
    flexDirection: "column",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
  },
  pin: { color: theme.palette.common.funBlue, marginRight: "10px" },
  detailsContainer: {
    margin: "0 44px",
  },
  item: {
    margin: 0,
    border: `1px solid #F1F1F1`,
    width: "100%",
    height: "47px",
    marginTop: "7px",
    "&:hover": {
      cursor: "pointer",
    },
    display: "flex",
    alignItems: "center",
  },
  infoWrapper: {
    display: "flex",
    alignItems: "center",
    flexBasis: "100%",
    justifyContent: "space-between",
  },
  arrow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  starWrapper: {
    width: "20px",
    marginLeft: "10px",
  },
}));

interface PropertyListItemProps {
  list: Property[];
  default: string | undefined;
}

export function PropertyList(props: PropertyListItemProps) {
  const { userEmail } = useUserState();
  const [propsData, setPropsData] = useState<any>([]);

  useEffect(() => {
    if (props) {
      if (userEmail === dummyUserEmail) {
        let clonedData = _.cloneDeep(props.list);
        const newProperties = clonedData?.map((item: any) => {
          if (item.id === DummyPropertyA.id) {
            const propA = {
              ...item,
              ownerCompanyName: DummyPropertyA.ownerCompanyName,
              siteName: DummyPropertyA.siteName,
              siteServiceAddress: DummyPropertyA.siteServiceAddress,
            };
            return propA;
          } else if (item.id === DummyPropertyB.id) {
            const propB = {
              ...item,
              ownerCompanyName: DummyPropertyB.ownerCompanyName,
              siteName: DummyPropertyB.siteName,
              siteServiceAddress: DummyPropertyB.siteServiceAddress,
            };
            return propB;
          } else if (item.id === DummyPropertyC.id) {
            const propC = {
              ...item,
              ownerCompanyName: DummyPropertyC.ownerCompanyName,
              siteName: DummyPropertyC.siteName,
              siteServiceAddress: DummyPropertyC.siteServiceAddress,
            };
            return propC;
          } else if (item.id === DummyPropertyD.id) {
            const propD = {
              ...item,
              ownerCompanyName: DummyPropertyD.ownerCompanyName,
              siteName: DummyPropertyD.siteName,
              siteServiceAddress: DummyPropertyD.siteServiceAddress,
            };
            return propD;
          } else {
            return item;
          }
        });
        const demoUserProps = newProperties?.filter(
          (item: any) =>
            item.id === DummyPropertyA.id ||
            item.id === DummyPropertyB.id ||
            item.id === DummyPropertyC.id ||
            item.id === DummyPropertyD.id
        );
        setPropsData(demoUserProps);
      } else {
        setPropsData(props.list);
      }
    }
  }, [props, userEmail]);

  if (props.list.length === 0) {
    return <Typography>No assiged properties</Typography>;
  }
  return (
    <Grid>
      <Typography>Properties assigned to You</Typography>
      {propsData.length > 0 &&
        propsData.map((property: any) => (
          <PropertyListItem
            item={property}
            default={property.id === props.default}
          />
        ))}
    </Grid>
  );
}

export function PropertyListItem(props: { item: Property; default: boolean }) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpand = useCallback((e: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(expanded);
  }, []);

  return (
    <Grid
      container={true}
      alignItems="center"
      justify="space-between"
      className={classes.item}
    >
      <Grid
        container={true}
        item={true}
        md={1}
        sm={3}
        justify="space-around"
        className={classes.iconWrapper}
      >
        {props.default ? (
          <Grid className={classes.starWrapper}>
            <StarSVG />
          </Grid>
        ) : (
          <Grid className={classes.starWrapper}></Grid>
        )}

        <Room className={classes.pin} />
      </Grid>
      <Grid
        container={true}
        item={true}
        md={10}
        sm={7}
        className={classes.infoWrapper}
      >
        <Typography className={classes.name}>{props.item.siteName}</Typography>
        <Typography className={classes.address}>
          {props.item.siteServiceAddress}
        </Typography>
      </Grid>
      <Grid
        container={true}
        item={true}
        md={1}
        sm={2}
        alignItems="center"
        justify="space-around"
      >
        <ProfileMenu property={props.item} />
      </Grid>
    </Grid>
  );
}

interface CellProps {
  label: string;
  value?: string | number;
  children?: React.ReactNode;
}

function Cell({ label, value, children }: CellProps) {
  const classes = useStyles();

  return (
    <Grid
      className={classes.cell}
      container={true}
      item={true}
      md={4}
      sm={12}
      direction="column"
    >
      <Grid>
        <Typography className={classes.cellLabel}>{label}</Typography>
        {children ? children : <Typography>{value ? value : "-"}</Typography>}
      </Grid>
    </Grid>
  );
}
