import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 50,
    minHeight: 500,
  },
  recoverySuccessText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.common.blackPearl,
  },
  catchline: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
  },
  img: {
    height: "100vh",
  },
  button: {
    backgroundColor: theme.palette.common.matisse,
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: "50%",
  },
  logo: {
    marginBottom: 77,
  },
}));
