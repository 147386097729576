import {
  ActivitySwitch,
  Input,
  TextareaAutosize,
  Select,
} from "../../../../common/components/Form/FormFields";
import * as Yup from "yup";

import { FormField } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";

const FIELD_NAMES = {
  fullName: "fullName",
  email: "email",
  role: "role",
  enabled: "enabled",
};

export interface editUserType {
  name: string;
  description: string;
  active: boolean;
}

export const createMainInformationFormFields = (data: any): FormField[] => [
  {
    name: FIELD_NAMES.fullName,
    label: "Name",
    placeholder: "Type here",
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.email,
    label: "Email",
    placeholder: "Type here",
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.role,
    label: "Role",
    placeholder: "Type here",
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Select,
    data: data.userRoles,
  },
  {
    name: FIELD_NAMES.enabled,
    label: "Status",
    placeholder: "Type here",
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: ActivitySwitch,
  },
];

export const UserValidationRules = Yup.object().shape({
  [FIELD_NAMES.fullName]: Yup.string()
    .min(
      3,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
  [FIELD_NAMES.email]: Yup.string()
    .email()
    .min(
      3,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
  [FIELD_NAMES.role]: Yup.string()
    .min(
      3,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
});
