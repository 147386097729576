import React from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../../core/services/GQL.service";
import { ImagesSensor } from "../../../../../types";

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 120,
    height: 42,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 100,
    height: 42,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(28),
    color: theme.palette.common.mediumBlack,
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    paddingTop: "20px",
    paddingRight: "16px",
  },
  content: {
    display: "flex",
    minWidth: "740px",
    minHeight: "484px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    paddingTop: "32px",
    paddingLeft: "23px",
    paddingBottom: "16px",
    borderRadius: "20px",
    boxShadow: "0px 4px 21px rgba(0, 0, 0, 0.38)",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: "16px",
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
  },
  value: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginLeft: "5px",
  },
  assignments: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
  },
  wrapperGrid: {
    marginTop: "26px",
  },
  loaderGrid: { alignSelf: "center", marginTop: "20%" },
  spaceItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.white,
  },
  chipIcon: {
    color: "white",
    marginLeft: "10px",
    "&:hover": {
      color: "white",
    },
  },
  warningMsg: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginTop: "40px",
  },
  spaceItemGrid: {
    padding: "5px 10px 5px 10px",
    backgroundColor: "#2F56A0",
    borderRadius: "6px",
    display: "flex",
    marginBottom: "5px",
  },
  enclosedSpace: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.darkGrey,
  },
  enclosedSpaceGrid: {
    padding: "5px 10px 5px 10px",
    marginBottom: "5px",
    marginLeft: "16px",
  },
  contentGrid: {
    display: "flex",
    marginBottom: "10px",
  },
}));

interface ModalContentProps {
  headerText?: string;
  no: Function;
  yes?: Function;
  data?: any;
  imageSensor?: ImagesSensor;
}

export function DeleteModal({
  headerText = "",
  no,
  yes,
  data,
  imageSensor,
}: ModalContentProps) {
  const classes = useStyles();

  const [deleteCameraZone] = useMutation(
    GQLService.mutations.deleteCameraZone,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.cameraById,
          variables: { cameraId: imageSensor?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const confirmDelete = () => {
    deleteCameraZone({
      variables: {
        cameraZoneId: data.id,
      },
    });
    no();
  };

  return (
    <Grid className={classes.content}>
      <Grid>
        <Grid>
          <Typography className={classes.header}>{headerText}</Typography>
          <Typography className={classes.description}>
            The resouces will be disassociated and images will get deleted after
            this action.
          </Typography>
        </Grid>
        <Grid className={classes.wrapperGrid}>
          <Grid className={classes.contentGrid}>
            <Typography className={classes.title}>
              {headerText === "Delete Tripwire" ? "Tripwire" : "Zone"} ID:
            </Typography>
            <Typography className={classes.value}>
              {headerText === "Delete Tripwire" ? data.tripWire : data.zone}
            </Typography>
          </Grid>
          <Grid className={classes.contentGrid}>
            <Typography className={classes.title}>
              {headerText === "Delete Tripwire" ? "Tripwire" : "Zone"} Name:
            </Typography>
            <Typography className={classes.value}>
              {data?.name?.length > 0 ? data?.name! : "--"}
            </Typography>
          </Grid>
          <Grid className={classes.contentGrid}>
            <Typography className={classes.title}>
              {headerText === "Delete Tripwire"
                ? "Covered Length(ft):"
                : "Covered Area(sq.ft):"}
            </Typography>
            <Typography className={classes.value}>
              {data?.area!
                ? headerText === "Delete Tripwire"
                  ? `${data?.area!} ft`
                  : `${data?.area!} sq.ft`
                : "--"}
            </Typography>
          </Grid>
          <Grid className={classes.contentGrid}>
            <Typography className={classes.title}>Spaces:</Typography>
            <Typography className={classes.value}>
              {data?.spaces?.length! > 0 ? "Yes" : "--"}
            </Typography>
          </Grid>
          <Grid className={classes.contentGrid}>
            <Typography className={classes.title}>Status:</Typography>
            <Typography className={classes.value}>
              {data?.active! ? "Active" : "Inactive"}
            </Typography>
          </Grid>
          <Grid className={classes.contentGrid}>
            <Typography className={classes.title}>
              Configured on Map:
            </Typography>
            <Typography className={classes.value}>
              {data?.placement! === null || data?.placement! === undefined
                ? "No"
                : Object.keys(data?.placement!).length === 0
                ? "No"
                : "Yes"}
            </Typography>
          </Grid>
          <Grid className={classes.contentGrid}>
            <Typography className={classes.title}>Image attached:</Typography>
            <Typography className={classes.value}>
              {data?.imageKey! ? "Yes" : "No"}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: "5px" }}>
          <Typography className={classes.warningMsg}>
            Do you want to proceed?
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <Grid item className={classes.buttonsWrapper}>
          <Button
            id="cancelBtn"
            className={classes.cancel}
            onClick={() => no()}
          >
            Cancel
          </Button>
          <Button
            id="confirmBtn"
            className={classes.deleteBtn}
            onClick={() => confirmDelete()}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
