import { Input } from "@material-ui/core";
import * as Yup from "yup";

import { FormField } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";
import {
  ActivitySwitch,
  Header,
  HourSelect,
} from "../../../../common/components/Form/FormFields/index";

enum FIELD_NAMES {
  legalCompanyName = "ownerCompanyName",
  primaryContactEmailHeader = "primaryContactEmailHeader",
  legalCompanyNameHeader = "legalCompanyNameHeader",
  accountNumber = "companyAccountNumber",
  fullName = "primaryContactFullName",
  primaryContactEmailAddress = "primaryContactEmailAddress",
  phone = "primaryContactPhoneNumber",
  companyInformation = "companyInformation",
  primaryContant = "primaryContact",
  companyWebsite = "companyWebsite",
  workingFrom = "workingFrom",
  workingTo = "workingTo",
  timeZone = "timeZone",
  companyStatus = "companyStatus",
}

export const createBusinessEntityFields = (data: any): FormField[] => {
  return [
    {
      name: FIELD_NAMES.legalCompanyNameHeader,
      label: I18n.formatMessage({
        id: "editPropertyFormFields.label.legalCompanyNameHeader",
        defaultMessage: " ",
      }),
      placeholder: I18n.formatMessage({
        id: "editPropertyFormFields.placeholder.legalCompanyNameHeader",
        defaultMessage: "Company Information",
      }),
      required: false,
      Component: Header,
    },
    {
      name: FIELD_NAMES.legalCompanyName,
      label: I18n.formatMessage({
        id: "editPropertyFormFields.label.legalCompanyName",
        defaultMessage: "Legal Company Name",
      }),
      placeholder: I18n.formatMessage({
        id: "editPropertyFormFields.placeholder.legalCompanyName",
        defaultMessage: "Legal Company name",
      }),
      required: true,
      Component: Input,
    },
    {
      name: FIELD_NAMES.companyStatus,
      label: I18n.formatMessage({
        id: "addPropertyFormFields.label.companyStatus",
        defaultMessage: "Status",
      }),
      required: false,
      Component: ActivitySwitch,
    },
  ];
};

export const VALIDATION_RULES = Yup.object().shape({
  [FIELD_NAMES.legalCompanyName]: Yup.string()
    .min(
      1,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.legalCompanyName.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.legalCompanyName.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
  [FIELD_NAMES.accountNumber]: Yup.string()
    .min(
      6,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.accountNumber.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.accountNumber.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
  [FIELD_NAMES.fullName]: Yup.string()
    .min(
      6,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.fullName.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.fullName.long",
        defaultMessage: "Too long!",
      })
    ),
  [FIELD_NAMES.primaryContactEmailAddress]: Yup.string()
    .min(
      6,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.email.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.email.long",
        defaultMessage: "Too long!",
      })
    ),
  [FIELD_NAMES.phone]: Yup.string()
    .min(
      6,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.phone.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.phone.long",
        defaultMessage: "Too long!",
      })
    ),
});
