import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  clearbutton: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.regularBlue,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(11),
    marginRight: "40px",
  },
  settingicon: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.regularBlue,
    fontSize: theme.typography.pxToRem(18),
  },
  item: {
    border: "1px solid #BDCED7",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
    height: "47px",
    alignItems: "center",
  },
  clearAllGrid: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "40px",
    marginBottom: "16px",
  },
  notificationIconSuccess: {
    color: "#2B7A2F",
    fontSize: theme.typography.pxToRem(24),
    marginLeft: "13px",
    marginRight: "13px",
  },
  notificationIconFault: {
    color: "#B0001F",
    fontSize: theme.typography.pxToRem(24),
    marginLeft: "13px",
    marginRight: "13px",
  },
  notificationIconAlert: {
    color: "#EEAA46",
    fontSize: theme.typography.pxToRem(24),
    marginLeft: "13px",
    marginRight: "13px"
  },
  notificationText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
  },
  durationText: {
    fontStyle: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkGrey,
  },
  notificationIconText: {
    display: "flex",
  },
  notificationTitle: {
    alignSelf: "center",
  },
}));
