import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.blackPearl30,
    height: "100vh",
  },
  box: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    height: 640,
    padding: "30px 40px 40px 40px",
  },
  form: { height: "100%" },
  headerText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(36),
    color: theme.palette.common.funBlue,
  },
  catchline: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.black,
    letterSpacing: 0.8,
  },
}));
