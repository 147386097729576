// Based on https://preview.uxpin.com/styleguide/2de3122b856db58987a40cd7b7b3698d72cb79e8#/colors-guide

import createPalette from "@material-ui/core/styles/createPalette";

export const COLORS = {
  white: "#fff",
  concrete: "#f2f2f2",
  alto: "#d8d8d8",
  turquoiseBlue: "#61d6e2",
  turquoiseBlue15: "#61d6e226",
  turquoiseBlue30: "#61d6e24d",
  turquoiseBlue50: "#61d6e280",
  nobel: "#b3b3b3",
  viking: "#6dcbda",
  nepal: "#8bb0c1",
  carrotOrange: "#ef8c21",
  dustyGray: "#9b9b9b",
  solidGray: "#D2D2D2",
  chateauGreen: "#08B055",
  cinnabar: "#e74635",
  matisse: "#2357a5",
  funBlue: "#2056a6",
  blackPearl: "#091d35",
  blackPearl30: "#091d354d",
  polar: "#e6f8fa",
  chambray: "#325886",
  aquamarineBlue: "#81d3e0",
  regularBlue: "#2F56A0",
  InputBg: "#ECF8FA",
  inputGrey: "#92ACBF",
  inputFrame: "#D9F2F6",
  switchBlue: "#2F56A1",
  blackText: "#122036",
  lightGray: "#e6e6e6",
  lighterGray: "#818181",
  inputBlue: "#ECF8FA",
  ultralightGrey: "#F2F2F2",
  textBlack: "#122036",
  darkGrey: "#ACACAC",
  darkBlue: "#0E1D33",
  lightBlue: "#81D3E0",
  blue: "#2F56A0",
  lightyellow: "rgba(238, 170, 70, 0.2)",
  lightGreen: "rgba(79, 158, 83, 0.15)",
  lightRed: "rgba(176, 0, 31, 0.2)",
  yellow: "rgb(238, 170, 70)",
  green: "rgb(79, 158, 83)",
  red: "rgb(176, 0, 31)",
  lowOccupancy: "rgba(79, 158, 83, 0.15)",
  highOccupancy: "rgba(176, 0, 31, 0.2)",
  mediumOccupancy: "rgba(238, 170, 70, 0.2)",
  mediumBlack: "#464646",
};

export const palette = createPalette({
  common: COLORS,
});

declare module "@material-ui/core/styles/createPalette" {
  interface CommonColors {
    white: string;
    concrete: string;
    alto: string;
    turquoiseBlue: string;
    turquoiseBlue15: string;
    turquoiseBlue30: string;
    turquoiseBlue50: string;
    nobel: string;
    viking: string;
    nepal: string;
    carrotOrange: string;
    dustyGray: string;
    chateauGreen: string;
    cinnabar: string;
    matisse: string;
    funBlue: string;
    blackPearl: string;
    blackPearl30: string;
    polar: string;
    chambray: string;
    aquamarineBlue: string;
    inputGrey: string;
    solidGray: string;
    darkBlue: string;
    inputBlue: string;
    borderBlue: string;
    switchBlue: string;
    blackText: string;
    lightGray: string;
    lighterGray: string;
    InputBg: string;
    inputFrame: string;
    ultralightGrey: string;
    textBlack: string;
    darkGrey: string;
    lightBlue: string;
    blue: string;
    lightyellow: string;
    lightGreen: string;
    lightRed: string;
    yellow: string;
    green: string;
    red: string;
    mediumBlack: string;
    regularBlue: string;
    lowOccupancy: string;
    highOccupancy: string;
    mediumOccupancy: string;
  }
}
