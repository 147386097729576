import React from "react";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from "victory";
import { COLORS } from "../../../styles/colors";
import {
  Box,
  CircularProgress,
  Typography,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { ApolloError } from "@apollo/client";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  noData: {
    color: theme.palette.common.darkBlue,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
  },
}));

type DensityData = {
  x: string;
  y: number;
  fill: string;
};

interface BarChartForDensityProps {
  data: DensityData[];
  loading?: boolean;
  error?: ApolloError | undefined;
}

export const BarChartForDensity = ({
  data,
  loading,
  error,
}: BarChartForDensityProps) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const sum = data?.reduce((acc: number, curr: DensityData) => {
    return (acc += curr.y);
  }, 0);

  const Loading = () => (
    <Grid container alignItems="center" justify="center">
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="indeterminate"
          size={80}
          style={{ color: COLORS.solidGray }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {formatMessage({
              id: "Barchart.loading",
              defaultMessage: "LOADING...",
              description: "barchart loading message",
            })}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );

  const NoData = () => (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: 164 }}
    >
      <Typography className={classes.noData}>
        {formatMessage({
          id: "Barchart.nodata",
          defaultMessage: "No data",
          description: "barchart no data label",
        })}
      </Typography>
    </Grid>
  );

  const CustomLabel = (props: any) => {
    return (
      <g>
        <VictoryLabel
          {...props}
          text={
            props.datum.y === 0
              ? ""
              : props.datum.y < 60
              ? props.datum.y + "min"
              : `${(props.datum.y / 60).toFixed()}hrs${props.datum.y % 60}min`
          }
          textAnchor="start"
        />
        <VictoryLabel
          {...props}
          style={{
            fontSize: 12,
            fontFamily: "Poppins",
            color: COLORS.darkBlue,
            fontWeight: 700,
          }}
          text={((props.datum.y / sum) * 100).toFixed() + "%"}
          x={400}
        />
      </g>
    );
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <NoData />
      ) : (
        <VictoryChart
          horizontal={true}
          domainPadding={{ x: [0, 10], y: [0, 100] }}
          padding={{ top: 0, bottom: 10, left: 80, right: 50 }}
          height={80}
        >
          <VictoryAxis crossAxis={true} style={{ axis: { stroke: "none" } }} />
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: "none" },
              tickLabels: {
                fontFamily: "Poppins",
                fontWeight: 500,
                color: COLORS.darkBlue,
                fontSize: 12,
              },
            }}
            axisLabelComponent={<VictoryLabel x={50} />}
          />
          <VictoryBar
            data={data}
            padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
            style={{
              data: { fill: ({ datum }) => datum.fill },
              labels: {
                color: COLORS.darkBlue,
                fontFamily: "Poppins",
                fontSize: 12,
                fontWeight: 500,
              },
            }}
            horizontal={true}
            labels={({ datum }) => datum.y}
            labelComponent={<CustomLabel />}
            barWidth={16}
          />
        </VictoryChart>
      )}
    </>
  );
};
