import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Grid, makeStyles } from "@material-ui/core";
import { PageHeader, SwipePanel } from "../../../../common/components";
import { useFloorState } from "../../../../core/context/containers/Floor.container";
import { FloorPlanEditor } from "../../../NetworkAnalytics/FloorPlanEditor/FloorPlanEditor";
import { FloorSettings } from "./FloorSettings";
import { EditFloorPlan } from "../Floors/EditFloorPlan/EditFloorPlan";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import GQLService from "../../../../core/services/GQL.service";
import Loader from "react-loader-spinner";
import { COLORS } from "../../../../common/styles/colors";
import { useAuth } from "../../../../core/context/containers/Auth.container";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    textTransform: "none",
    textDecoration: "underline",
    marginBottom: 30,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  editFloorGrid: {
    "& div.react-swipeable-view-container": {
      marginTop: "0 !important",
      "& > div": {
        overflow: "hidden !important",
      },
    },
  },
}));

export function EditFloorView() {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { selectedFloor, floors, setFloors } = useFloorState();
  const { selectedProperty } = usePropertiesState();
  const [imageLoading, setImageLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState<undefined | string>(undefined);
  const auth = useAuth();

  useEffect(() => {
    (async () => {
      const response = await GQLService.rest.images.getFloorPlan(
        selectedProperty?.id!,
        selectedFloor?.id!
      );
      if (response.status === 401) {
        auth?.logout();
      }
      setImageLoading(false);
      const floorsWithPlan = floors.map((floor) => {
        if (floor.id === selectedFloor?.id!) {
          return {
            ...floor,
            floorPlanUrl: response?.source,
          };
        }
        return floor;
      });
      setImageSrc(response?.source);
      setFloors(floorsWithPlan);
    })();
  }, []);

  return (
    selectedFloor && (
      <div>
        <Grid container={true} direction="row" alignItems="center">
          <Grid>
            <PageHeader
              title={formatMessage({
                id: "property.header",
                defaultMessage: "Property Details",
                description: "propery details header",
              })}
            />
          </Grid>
        </Grid>
        <Grid container={true}>
          <Grid className={classes.editFloorGrid}>
            <SwipePanel
              tabNames={[
                formatMessage({
                  id: "property.tabNames.floorPlan",
                  defaultMessage: "Floor Plan",
                  description: "Floor plan tab label",
                }),
                formatMessage({
                  id: "property.tabNames.floorSettings",
                  defaultMessage: "Floor Settings",
                  description: "Floor settings tab label",
                }),
              ]}
            >
              {/* {!imageLoading ? ( */}
              {
                selectedFloor.floorPlanUrl || imageSrc ? (
                  <FloorPlanEditor
                    src={selectedFloor.floorPlanUrl || imageSrc}
                  />
                ) : (
                  <EditFloorPlan
                    floor={selectedFloor!}
                    property={selectedProperty}
                    currentUrl={selectedFloor?.floorPlanUrl || imageSrc}
                    name={"string"}
                    label={""}
                  />
                )
                // ) : (
                //   <Loader
                //     type="Oval"
                //     color={COLORS.funBlue}
                //     height={100}
                //     width={100}
                //   />
                // )}
              }
              <FloorSettings />
            </SwipePanel>
          </Grid>
        </Grid>
      </div>
    )
  );
}
