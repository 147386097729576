import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useIntl } from "react-intl";

import { DevicesConnected } from "./DevicesConnected/DevicesConnected";
import { DevicesTypes } from "./DevicesTypes/DevicesTypes";
import { AccessPointsLoad } from "./AccessPointsLoad/AccessPointsLoad";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { useFloorState } from "../../../../core/context/containers/Floor.container";
import { useFiltersState } from "../../../../core/context/containers/Filters.container";
import { useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import clsx from "clsx";
import "moment-timezone";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useTimeSliderState } from "../../../../core/context/containers/TimeSlider.container";
import { Floor } from "../../../../types";
import Loader from "react-loader-spinner";
import { COLORS } from "../../../../common/styles/colors";

const useStyles = makeStyles((theme) => ({
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.blackText,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  selectLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackText,
    marginRight: 16,
    marginLeft: 40,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackText,
  },
  selectContainer: {
    margin: 0,
    marginRight: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkBlue,
    marginRight: 12,
  },
  select: {
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 12,
    display: "flex",
    alignItems: "center",
    minWidth: 237,
    height: 19,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  floorSelect: {
    minWidth: 100,
  },
  checkbox: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackText,
  },
  icon: {
    color: theme.palette.common.blackText,
    right: 7,
    width: 18,
  },
  option: {
    // width: 275,
    padding: 12,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackText,
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  menu: {
    backgroundColor: theme.palette.common.InputBg,
    boxShadow: "none !important",
    paddingTop: 0,
  },
  containerWithPadding: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
}));

interface AccessPointsStatisticsProps {
  timeFrom?: string;
  timeTo?: string;
  selectedTime?: string;
  selectedFloorNew?: Floor | undefined;
  selectedSpace?: any;
  handleSelectedValue?: any;
  userPreferenceFilterSummary?: any;
  loadingUserFilterPrefernceSummary?: any;
  dropDownValue?: any;
  loadingUserFilterPrefernceT?: any;
  offHoursIsActive?: boolean;
}

export function AccessPointsStatistics({
  timeFrom,
  timeTo,
  selectedTime,
  selectedFloorNew,
  selectedSpace,
  handleSelectedValue,
  userPreferenceFilterSummary,
  loadingUserFilterPrefernceSummary,
  dropDownValue,
  loadingUserFilterPrefernceT,
  offHoursIsActive,
}: AccessPointsStatisticsProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { loading, floors, setFloors } = useFloorState();
  const { selectedProperty } = usePropertiesState();
  const [selectedFloor, setSelectedFloor] = useState<Floor>();
  const [statisticsSelect, setStatisticsSelect] = useState("Occupancy");
  const { selectedTimeSlider, setSelectedTimeSlider } = useTimeSliderState();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!loading && floors.length > 0) {
        setSelectedFloor(floors.find((v) => v.floorNumber === 1) || floors[0]);
      } else if (!loading && floors.length === 0) {
        setSelectedFloor(undefined);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [selectedProperty, floors, loading]);

  useEffect(() => {
    if (userPreferenceFilterSummary) {
      if (userPreferenceFilterSummary.dataValue.preferences.statistics) {
        setStatisticsSelect(
          userPreferenceFilterSummary.dataValue.preferences.statistics
        );
        handleSelectedValue(
          userPreferenceFilterSummary.dataValue.preferences.statistics
        );
      }
    } else {
      setStatisticsSelect("Occupancy");
      handleSelectedValue("Occupancy");
    }
  }, [userPreferenceFilterSummary, loadingUserFilterPrefernceSummary]);

  useEffect(() => {
    setStatisticsSelect(dropDownValue);
  }, [dropDownValue]);

  const handleFloorChange = useCallback(
    (e) => {
      setSelectedFloor(floors.find((v) => v.id === e.target.value));
    },
    [floors]
  );

  const handleChangeStatisticsSelect = useCallback((e) => {
    setStatisticsSelect(e.target.value);
    handleSelectedValue(e.target.value);
  }, []);

  const selectSwitch = useMemo(() => {
    let valueForApi;
    if (statisticsSelect === "Occupancy") {
      valueForApi = "ACCESSPOINT";
    } else if (statisticsSelect === "Flow Through") {
      valueForApi = "CAMERA";
    } else if (statisticsSelect === "Dwell time") {
      valueForApi = "Dwell time";
    } else {
      valueForApi = "AIRQUALITY";
    }

    return (
      <DevicesTypes
        timeFrom={timeFrom}
        timeTo={timeTo}
        selectedTime={selectedTime}
        selectedFloor={selectedFloorNew}
        selectedSpace={selectedSpace}
        statisticsSelect={valueForApi}
        selectedTimeZone={selectedProperty?.timeZoneName || ""}
        offHoursIsActive={offHoursIsActive}
      />
    );
  }, [
    statisticsSelect,
    timeFrom,
    timeTo,
    selectedTime,
    selectedFloor,
    selectedProperty,
    selectedFloorNew,
    selectedSpace,
    offHoursIsActive,
  ]);

  const StaticsDropDownList = [
    { value: "Occupancy", label: "Occupancy" },
    { value: "Average Wellness Index", label: "Average Wellness Index" },
    { value: "Dwell time", label: "Dwell time" },
    { value: "Flow Through", label: "In/Out (Tripwire)" },
  ];

  return (
    <Grid container={true}>
      <Grid container={true} justify="space-between">
        <Grid
          container={true}
          item={true}
          alignItems="baseline"
          className={classes.containerWithPadding}
        >
          <Typography className={classes.blackLabel}>
            {formatMessage({
              id: "analytics.summaryDashboard.statistics.statistics.header",
              defaultMessage: "Statistics",
              description: "network analytics statistics header",
            })}
          </Typography>
          <Typography className={classes.selectLabel}>
            {formatMessage({
              id: "analytics.summaryDashboard.statistics.statistics.label",
              defaultMessage: "Type of graph",
              description: "selectLabel",
            })}
          </Typography>
          {loadingUserFilterPrefernceSummary || loadingUserFilterPrefernceT ? (
            <Loader type="Oval" color={COLORS.funBlue} height={40} width={40} />
          ) : (
            <Select
              classes={{
                root: classes.select,
                select: classes.blackText,
                icon: classes.icon,
              }}
              IconComponent={ExpandMoreSharpIcon}
              onChange={handleChangeStatisticsSelect}
              value={statisticsSelect}
              disableUnderline={true}
              MenuProps={{
                classes: {
                  paper: classes.menu,
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {StaticsDropDownList?.map((v) => (
                <MenuItem
                  key={v.value}
                  value={v.value}
                  classes={{
                    root: classes.option,
                    selected: classes.selected,
                  }}
                >
                  {v.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
      </Grid>
      <Grid container={true} item={true}>
        {selectSwitch}
      </Grid>
    </Grid>
  );
}
