import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.turquoiseBlue30}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.turquoiseBlue15,
    paddingLeft: 10,
  },
  inputFile: { display: "none" },
  inputLabel: {
    margin: "20px 0 5px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    lineHeight: "18px",
    whiteSpace: "nowrap",
  },
  errorLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.cinnabar,
    marginLeft: 10,
    paddingLeft: 30,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
    marginTop: "25px",
  },
  errorInput: {
    borderColor: theme.palette.common.cinnabar,
  },
  button: {
    height: "80%",
    border: `1px dashed ${theme.palette.common.turquoiseBlue30}`,
    backgroundColor: theme.palette.common.turquoiseBlue15,
    color: theme.palette.common.nepal,
  },
  buttonLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "none",
  },
  submitBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 180,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(5),
    outline: "none",
    border: "none",
  },
  note: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.nepal,
  },
  preview: {
    minWidth: 240,
    minHeight: 180,
    width: 434,
    height: 369,
    objectFit: "contain",
    border: `1px solid ${theme.palette.common.nobel}`,
  },
  actionButtonsContainer: {
    marginTop: theme.spacing(1),
  },
  actionButton: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 100,
    height: 40,
    borderRadius: 30,
  },
  actionButtonDisabled: {
    backgroundColor: theme.palette.common.nobel,
    color: theme.palette.common.white,
    width: 100,
    height: 40,
    borderRadius: 30,
  },
  error: {
    borderColor: theme.palette.common.cinnabar,
  },
  progress: {
    color: theme.palette.common.white,
  },
  removeButton: {
    borderRadius: "50%",
    backgroundColor: theme.palette.common.funBlue,
    color: "#fff",
    position: "relative",
    top: "12px",
    left: "calc(100% - 12px);",
    width: "24px",
    height: "24px",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    cursor: "pointer",
  },
  imageWrapper: {
    position: "relative",
  },
  floorPlan: {
    width: 434,
    height: 369,
    objectFit: "contain",
    position: "relative",
  },
  floorPlanLoader: {
    width: 434,
    height: 369,
    objectFit: "contain",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  logo: {
    width: 434,
    height: 600,
  },
  required: {
    color: theme.palette.common.red,
  },
}));
