import React from "react";
import {
  ActivitySwitch,
  Input,
  TextareaAutosize,
} from "../../../../common/components/Form/FormFields";
import * as Yup from "yup";

import { FormField } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";

const FIELD_NAMES = {
  name: "name",
  vlanNumber: "vlanNumber",
  description: "description",
  inCommonNetwork: "inCommonNetwork",
  active: "active",
  ifId: "ifId",
};

export interface addAccessPointType {
  name: string;
  vlanNumber: string;
  description: string;
  inCommonNetwork: boolean;
  status: boolean;
  ifId: string;
}

export const VLanFormFields: FormField[] = [
  {
    name: FIELD_NAMES.name,
    label: "Network Name",
    placeholder: "Type here",
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.vlanNumber,
    label: "VLAN Number",
    placeholder: "Type here",
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.ifId,
    label: "Ifid (ntopng)",
    placeholder: "Type here",
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.description,
    label: "Description",
    placeholder: "Type here",
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: TextareaAutosize,
  },
  {
    name: FIELD_NAMES.inCommonNetwork,
    label: "Part of Common Network",
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: ActivitySwitch,
  },
  {
    name: FIELD_NAMES.active,
    label: "Status",
    placeholder: "Type here",
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: ActivitySwitch,
  },
];
// {
//   name: "Add SSIDs",
//   fieldset: [
//     {
//       label: "Add SSIDs",
//       fields: [
//         {
//           name: FIELD_NAMES.ssids,
//           label: "SSIDs",
//           placeholder: "Choose from list",
//           required: false,
//           gridStyle: { sm: 12, md: 12 },
//           Component: SelectFromList,
//           data: data.ssids,
//         },
//       ],
//     //   validationSchema: addSSID2ValidationSchema(),
//     },
//   ],
// },
export const VLanValidationRules = Yup.object().shape({
  [FIELD_NAMES.name]: Yup.string()
    .min(
      3,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .matches(/^\s*\S[\s\S]*$/, "This field cannot contain blankspaces only")
    .required(" "),
  [FIELD_NAMES.vlanNumber]: Yup.number()
    .typeError(
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.vlanNumber.numberOnly",
        defaultMessage: "You must specify a number",
      })
    )
    .required(" "),
  [FIELD_NAMES.ifId]: Yup.number()
    .typeError(
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.ifid.numberOnly",
        defaultMessage: "You must specify a number",
      })
    )
    .required(" "),
  [FIELD_NAMES.description]: Yup.string()
    .min(
      3,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.long",
        defaultMessage: "Too long!",
      })
    ),
});
