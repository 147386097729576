import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { DataList } from "../../../common/components";

import { Button, Grid, Typography } from "@material-ui/core";
import { useStyles } from "../NetworkSettings.styles";
import GQLService from "../../../core/services/GQL.service";
import Modal from "./Modal";
import { useMutation, useQuery } from "@apollo/client";
import Group from "./Group";

export default function Groups() {
  const [modal, showModal] = useState<boolean>(false);
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [networkGroups, setNetworkGroups] = useState<any[]>([]);

  const [lazyLoaded, setLazyLoaded] = useState(false);

  const { data, loading, error } = useQuery(
    GQLService.queries.allNetworkGroups,
    {
      fetchPolicy: "cache-first",
    }
  );

  const [createNetworkGroup] = useMutation(
    GQLService.mutations.createNetworkGgroup,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allNetworkGroups,
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  useEffect(() => {
    let isMounted = true;
    let timeout: NodeJS.Timeout;
    if (isMounted) {
      timeout = setTimeout(() => {
        if (data.length || !loading) {
          setLazyLoaded(true);
        }
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
      isMounted = false;
    };
  }, [loading, data]);

  const openModal = useCallback(() => {
    showModal(true);
  }, [modal]);

  const outSideClick = useCallback(() => {
    showModal(false);
  }, [modal]);

  const addNewGroup = useCallback(async (value) => {
    try {
      await createNetworkGroup({ variables: { name: value } });
      showModal(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      const networkGroupsFormatted = data.networkGroups.map(
        (networkGroup: any) => {
          const hosts = networkGroup.hosts.map((host: any) => ({
            ...host,
            name: host.hostName,
          }));
          return {
            ...networkGroup,
            hosts,
          };
        }
      );
      setNetworkGroups(networkGroupsFormatted);
    }
  }, [loading, data]);

  return (
    <Grid sm={8} md={8}>
      <Typography className={classes.subheader}>
        {formatMessage({
          id: "groups.settings",
          defaultMessage: "Groups",
          description: "manage groups settings",
        })}
      </Typography>
      <Grid>
        <DataList data={networkGroups} loading={loading} ListItem={Group} />
        {lazyLoaded && (
          <Button className={classes.addButton} onClick={openModal}>
            {formatMessage({
              id: "addGroup.settings",
              defaultMessage: "Add New Group",
              description: "add new group",
            })}
          </Button>
        )}
      </Grid>
      {modal && (
        <Modal
          no={() => showModal(false)}
          yes={(value) => addNewGroup(value)}
        />
      )}
    </Grid>
  );
}
