import React, { useCallback, useMemo, useState } from "react";
import { Grid, Typography, Button, Input as InputUI } from "@material-ui/core";
import { useStyles } from "./MyPinsModal.styles";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import { useUserState } from "../../../core/context/containers/User.container";
import { useMutation } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";

interface MyPinsModalProps {
  setToggleModal?: any;
  selectedTime?: any;
  selectedSpace?: any;
  selectedFloor?: any;
  deviceType?: any;
  deviceId?: any;
  timeRange?: any;
  handleIsPinned?: any;
  subType?: any;
  cameraId?: any;
  title?: string;
}

export function MyPinsModal({
  setToggleModal,
  selectedTime,
  selectedSpace,
  selectedFloor,
  deviceType,
  deviceId,
  timeRange,
  handleIsPinned,
  subType,
  cameraId,
  title,
}: MyPinsModalProps) {
  const classes = useStyles();
  const { selectedProperty } = usePropertiesState();
  const { userEmail } = useUserState();
  const [cardName, setCardName] = useState<string>("");

  const duration =
    selectedTime === 15
      ? "Last 15 minutes"
      : selectedTime === 60
      ? "Last hour"
      : selectedTime === 1440
      ? "Last 24 hours"
      : selectedTime === 10080
      ? "Last 7 days"
      : "Last 30 days";

  const [createUserPin] = useMutation(GQLService.mutations.createUserPin);

  const confirmPinIt = () => {
    createUserPin({
      variables: {
        deviceId: deviceId,
        deviceType: deviceType,
        duration: duration,
        floorId: selectedFloor.id,
        propertyId: selectedProperty?.id,
        queryData: {
          request: {
            deviceId: deviceId,
            floorId: selectedFloor.id,
            timeRangeType: timeRange,
            selectedTime: selectedTime,
            subType: subType,
            cameraId: cameraId,
          },
        },
        spaceId: selectedSpace === "none" ? null : selectedSpace,
        userEmail: userEmail,
        title: cardName ? cardName : "Assign Card Name",
      },
    });
    setToggleModal(false);
    handleIsPinned(true);
  };

  const handleChange = useCallback((e) => {
    setCardName(e.target.value);
  }, []);

  return (
    <Grid
      className={classes.pinItModal}
      style={{
        left: title === "Dashboard" ? "40%" : "17%",
        top: title === "Dashboard" ? "30%" : "20%",
      }}
    >
      <Grid>
        <Typography className={classes.pinItModalHeader}>Pin it</Typography>
        <Grid>
          <Typography className={classes.pinItModalDescription}>
            The selected card will be added to My Pins Page. Provide a custome
            name to this pinned card.
          </Typography>
        </Grid>
      </Grid>
      <Grid style={{ paddingRight: "10px" }}>
        <Typography className={classes.inputLable}>Pinned Card Name</Typography>
        <InputUI
          classes={{
            root: classes.input,
            input: classes.inputField,
          }}
          value={cardName}
          placeholder={"Type name"}
          disabled={false}
          onChange={handleChange}
          disableUnderline={true}
          fullWidth={true}
          inputProps={{ maxLength: "15" }}
        />
        <Typography className={classes.charError}>
          {15 - cardName.length}/15 characters are remaining
        </Typography>
      </Grid>
      <Grid className={classes.pinItModalButtonsWrapper}>
        <Button
          id="cancelBtnEditModal"
          onClick={() => setToggleModal(false)}
          className={classes.pinItModalButtonCancel}
        >
          Cancel
        </Button>
        <Button
          id="yesBtnEditModal"
          onClick={() => confirmPinIt()}
          className={classes.pinItModalButtonPinIt}
        >
          Pin it
        </Button>
      </Grid>
    </Grid>
  );
}
