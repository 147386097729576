import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { ImagesSensor } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useImagesSensorsBase() {
  const [ImagesSensors, setImagesSensors] = useState<ImagesSensor[]>([]);
  const [ImagesSensorsFromResponse, setImagesSensorsFromResponse] = useState<
    ImagesSensor[]
  >([]);

  const {
    state: selectedImagesSensor,
    setState: setSelectedImagesSensor,
  } = usePersistedState<ImagesSensor | null>("imageSensor", null);

  const { search } = useSearch();

  const searchImagesSensors = useCallback(
    (searchValue: string) => {
      setImagesSensors(
        search(ImagesSensorsFromResponse, searchValue) as ImagesSensor[]
      );
    },
    [ImagesSensorsFromResponse, search]
  );

  return {
    searchImagesSensors,
    ImagesSensors,
    setImagesSensors,
    ImagesSensorsFromResponse,
    setImagesSensorsFromResponse,
    selectedImagesSensor,
    setSelectedImagesSensor,
  };
}

const { Provider, useContainer } = createContainer(useImagesSensorsBase);

export const useImagesSensorsState = useContainer;
export const ImagesSensorsProvider = Provider;
