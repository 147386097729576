import React, { useState, useCallback } from "react";
import { useIntl } from "react-intl";
import {
  Input,
  InputLabel,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
} from "@material-ui/core";

import { PasswordResetFormData } from "../../../../../types";
import I18n from "../../../../../core/services/I18n.service";
import { useStyles } from "./PasswordResetForm.styles";
import { useHistory } from "react-router-dom";

interface PasswordResetFormProps {
  onSubmit: (newPassword: string) => Promise<void>;
}

export function PasswordResetForm({ onSubmit }: PasswordResetFormProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [formData, setFormData] = useState<PasswordResetFormData>({
    newPassword: "",
    retypedPassword: "",
    hasAcceptedCustomerAgreement: false,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
    },
    [formData]
  );

  const handleCheckboxClick = useCallback(() => {
    setFormData({
      ...formData,
      hasAcceptedCustomerAgreement: !formData.hasAcceptedCustomerAgreement,
    });
  }, [formData]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const error = validateForm(formData);

      if (error) {
        setError(error);

        return;
      }

      setIsLoading(true);
      onSubmit(formData.newPassword)
        .catch((e) => {
          setError(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [formData]
  );

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <>
        <InputLabel className={classes.inputLabel} error={Boolean(error)}>
          {formatMessage({
            id: "passwordResetForm.input.typeNewPasswordLabel",
            defaultMessage: "Type New Password*",
            description: "new password input label",
          })}
          {`${error ? `- ${error}` : ""}`}
        </InputLabel>
        <Input
          className={classes.input}
          margin="dense"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          autoFocus={true}
          placeholder={formatMessage({
            id: "passwordResetForm.input.typeNewPasswordPlaceholder",
            defaultMessage: "New password",
            description: "type new password input placeholder",
          })}
          name="newPassword"
          type="password"
          onChange={handleInputChange}
        />
      </>
      <>
        <InputLabel className={classes.inputLabel}>
          {formatMessage({
            id: "passwordResetForm.input.retypeNewPasswordLabel",
            defaultMessage: "Retype New Password*",
            description: "password reset input label",
          })}
        </InputLabel>
        <Input
          className={classes.input}
          margin="dense"
          disableUnderline={true}
          required={true}
          fullWidth={true}
          autoFocus={true}
          placeholder={formatMessage({
            id: "passwordResetForm.input.retypeNewPasswordPlaceholder",
            defaultMessage: "Retype password",
            description: "retype new password input placeholder",
          })}
          name="retypedPassword"
          type="password"
          onChange={handleInputChange}
        />
      </>
      <Grid>
        <InputLabel className={classes.agreementLabel}>
          {formatMessage({
            id: "passwordResetForm.input.customerAgreement",
            defaultMessage: "Join Customer Agreement*",
            description: "customer agreement input label",
          })}
        </InputLabel>
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkbox}
              name="agreement"
              color="default"
              onClick={handleCheckboxClick}
            />
          }
          label={formatMessage({
            id: "passwordResetForm.checkbox.label",
            defaultMessage:
              "I have read and agree to the Join Terms of Use and Privacy Policy.",
            description: "terms of use and privacy policy checkbox label",
          })}
        />
      </Grid>
      <Button
        className={classes.submit}
        type="submit"
        variant="contained"
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          formatMessage({
            id: "passwordResetForm.button.updateAccount",
            defaultMessage: "Update Account",
            description: "update account button label",
          })
        )}
      </Button>
    </form>
  );
}

function validateForm(formData: PasswordResetFormData): string | null {
  if (formData.newPassword === "" || formData.retypedPassword === "") {
    return I18n.formatMessage({
      id: "passwordResetForm.validateForm.typeNewPassword",
      defaultMessage: "Please type new password",
      description: "type new password hint",
    });
  }
  if (formData.newPassword !== formData.retypedPassword) {
    return I18n.formatMessage({
      id: "passwordResetForm.validateForm.notMatchPassword",
      defaultMessage: "Passwords does not match",
      description: "passwords not matching hint",
    });
  }
  if (formData.hasAcceptedCustomerAgreement === false) {
    return I18n.formatMessage({
      id: "passwordResetForm.validateForm.acceptCustomerAgreement",
      defaultMessage: "Please accept customer agreement",
      description: "accept customer agreement hint",
    });
  }

  return null;
}
