import { parseImageResponse } from "../../../common/utils/Http.utils";
import API from "../../api/api";

const ImagesRestServiceCreate = () => {
  const uploadFloorPlan = async (
    floorPlan: FormData,
    propertyId: string,
    floorId: string
  ) => {
    const response = await API.post(
      `properties/${propertyId}/floors/${floorId}`,
      floorPlan
    );
    return response;
  };

  const removeFloorPlan = async (
    propertyId: string,
    floorId: string
  ): Promise<void> => {
    await API.delete(`properties/${propertyId}/floors/${floorId}/plan`);
    return;
  };

  const getFloorPlan = async (propertyId: string, floorId: string) => {
    try {
      const response = await API.get(
        `properties/${propertyId}/floors/${floorId}/plan`,
        {
          responseType: "blob",
        }
      );
      return await parseImageResponse(response);
    } catch (err) {
      return {
        exists: false,
        source: undefined,
        status: 404,
      };
    }
  };

  return {
    uploadFloorPlan,
    removeFloorPlan,
    getFloorPlan,
  };
};

export const ImagesRestService = ImagesRestServiceCreate();
