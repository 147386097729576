import { gql } from "@apollo/client";

export const ACCESS_CONTROL_QUERIES = {
  associatedCardReaders: gql`
    query associatedCardReaders {
      associatedCardReaders {
        noOfPages
        cardReaderResponse {
          active
          dateCreated
          dateUpdated
          description
          floor {
            id
            name
            floorNumber
          }
          floorNo
          id
          lastSeen
          name
          property {
            id
            siteName
            timeZoneName
            timeZone
          }
          vendorBuildingId
          vendorName
          vendorPortalId
        }
      }
    }
  `,
  unAssociatedCardReaders: gql`
    query unassociatedCardReaders {
      unassociatedCardReaders {
        noOfPages
        cardReaderResponse {
          active
          dateCreated
          dateUpdated
          description
          floor {
            id
            name
          }
          floorNo
          id
          lastSeen
          name
          property {
            id
            siteName
          }
          vendorBuildingId
          vendorName
          vendorPortalId
        }
      }
    }
  `,
  cardReadersByPropertyId: gql`
    query cardReadersByPropertyId($propertyId: String) {
      cardReadersByPropertyId(propertyId: $propertyId) {
        noOfPages
        cardReaderResponse {
          active
          dateCreated
          dateUpdated
          description
          floor {
            id
            name
          }
          floorNo
          id
          lastSeen
          name
          property {
            id
            siteName
          }
          vendorBuildingId
          vendorName
          vendorPortalId
        }
      }
    }
  `,
};

export const ACCESS_CONTROL_MUTATIONS = {
  bulkUpdateCardReaders: gql`
    mutation bulkUpdateCardReaders($request: [UpdateCardReaderRequestInput]) {
      bulkUpdateCardReaders(request: $request) {
        id
      }
    }
  `,
};
