import { makeProcessedFieldsMerger } from "@apollo/client/cache/inmemory/helpers";
export const APP_DEFAULTS = {
  baseApiUrl: process.env.REACT_APP_BASE_API_URL || "https://localhost:8080",
  graphQlApiUrl:
    process.env.REACT_APP_GRAPHIQL_BASE_API_URL ||
    "https://localhost:8080/api/graphql",
  env: process.env.REACT_APP_ENV,
  gtmKey: process.env.REACT_APP_GTM_KEY || "",
};

export enum ENV {
  PRODUCTION = "PRODUCTION",
  DEVELOPMENT = "DEVELOPMENT",
  STAGE = "STAGE",
}
