import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";
import { Device } from "../../../types/device";

function useDevicesBase() {
  const [devices, setDevices] = useState<Device[]>([]);
  const [devicesFromResponse, setDevicesFromResponse] = useState<Device[]>([]);

  const {
    state: selectedDevice,
    setState: setSelectedDevice,
  } = usePersistedState<Device | null>("device", null);

  const { search } = useSearch();

  const searchDevices = useCallback(
    (searchValue: string) => {
      setDevices(search(devicesFromResponse, searchValue) as Device[]);
    },
    [devicesFromResponse]
  );

  return {
    searchDevices,
    devices,
    setDevices,
    devicesFromResponse,
    setDevicesFromResponse,
    selectedDevice,
    setSelectedDevice,
  };
}

const { Provider, useContainer } = createContainer(useDevicesBase);

export const useDevicesState = useContainer;
export const DevicesProvider = Provider;
