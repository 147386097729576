import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { Beacon } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useBeaconsBase() {
  const [beacons, setBeacons] = useState<Beacon[]>([]);
  const [beaconsFromResponse, setBeaconsFromResponse] = useState<Beacon[]>([]);

  const {
    state: selectedBeacon,
    setState: setSelectedBeacon,
  } = usePersistedState<Beacon | null>("beacon", null);

  const { search } = useSearch();

  const searchBeacons = useCallback(
    (searchValue: string) => {
      setBeacons(search(beaconsFromResponse, searchValue) as Beacon[]);
    },
    [beaconsFromResponse]
  );

  return {
    searchBeacons,
    beacons,
    setBeacons,
    beaconsFromResponse,
    setBeaconsFromResponse,
    selectedBeacon,
    setSelectedBeacon,
  };
}

const { Provider, useContainer } = createContainer(useBeaconsBase);

export const useBeaconsState = useContainer;
export const BeaconsProvider = Provider;
