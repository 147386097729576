import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";

import { usePropertiesState } from "../../../../../../../core/context/containers/Properties.container";
import GQLService from "../../../../../../../core/services/GQL.service";
import { Property } from "../../../../../../../types";
import { useStyles } from "../WorkplaceSettings.styles";
import NoCoverPhoto from "../../../../../../../common/assets/images/no-image.png";
import { useUserState } from "../../../../../../../core/context/containers/User.container";
import DummyLogo from "../../../../../../../common/assets/images/grandSquareLogo.png";
import { dummyUserEmail } from "../../../../../../../common/constants/DummyProperty";

interface CompanyLogoProps {
  currentUrl?: string | undefined;
  property: Property | null;
}

export function CompanyLogo({ currentUrl, property }: CompanyLogoProps) {
  const { userEmail } = useUserState();
  const { selectedProperty, currentLogoObject, setCurrentLogoObject } =
    usePropertiesState();
  const classes = useStyles();

  useEffect(
    () => {
      (async () => {
        const response = await GQLService.rest.logo.getLogo(
          selectedProperty!.id
        );
        if (response.source !== undefined) {
          setCurrentLogoObject({
            ...currentLogoObject,
            url: response.source,
          });
        } else {
          setCurrentLogoObject({
            url: undefined,
            data: undefined,
            name: undefined,
          });
        }
      })();
    },
    [property, selectedProperty] // eslint-disable-line
  );

  return (
    <Grid className={classes.logo}>
      {userEmail === dummyUserEmail ? (
        <img src={DummyLogo} alt="company logo" className={classes.img} />
      ) : (
        <img
          src={currentLogoObject?.url || NoCoverPhoto}
          alt={currentLogoObject.name || "cover photo"}
          className={classes.img}
        />
      )}
    </Grid>
  );
}
