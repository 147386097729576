import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Loader from "react-loader-spinner";
import { COLORS } from "../../../../../common/styles/colors";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import GQLService from "../../../../../core/services/GQL.service";
import RemoveSharpIcon from "@material-ui/icons/RemoveSharp";
import { BorderBottom, MoreHorizSharp } from "@material-ui/icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  DashboardDeviceType,
  DashboardTimeRangeType,
} from "../../../../../types";
import moment from "moment";
import { LineChartAnalyze } from "./LineChartAnalyze";
import * as _ from "lodash";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme: any) => ({
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(28),
    color: theme.palette.common.mediumBlack,
    marginBottom: "10px",
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    paddingTop: "20px",
    paddingRight: "16px",
  },
  content: {
    display: "flex",
    width: "1020px",
    height: "635px",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    paddingTop: "24px",
    paddingLeft: "23px",
    paddingBottom: "16px",
    borderRadius: "20px",
    boxShadow: "0px 4px 21px rgba(0, 0, 0, 0.38)",
  },
  listItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.common.mediumBlack,
  },
  assignments: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
  },
  wrapperGrid: {
    display: "flex",
    marginRight: "23px",
  },
  chartGrid: {
    border: "1px solid #E0EBF1",
    marginRight: "16px",
    display: "flex",
    paddingTop: "16px",
    paddingRight: "10px",
    justifyContent: "space-between",
  },
  loaderGrid: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  infoIcon: {
    fontSize: 16,
    marginLeft: "16px",
    color: "#2F56A0",
  },
  toolTipDiv: {
    backgroundColor: "#F2F2F2",
    border: "1px solid #81D3E0",
    borderRadius: "3px",
    boxShadow: "0px 6px 6px 2px rgba(0, 0, 0, 0.25)",
    paddingLeft: "12px",
    width: "180px",
  },
  toolTipContent: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkBlue,
  },
  resetTime: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.common.darkBlue,
  },
  solidBorder: {
    borderBottom: "1px solid #000000",
    borderStyle: "none none solid",
  },
  dashedBorder: {
    borderBottom: "1px dashed #000000",
    borderStyle: "none none dashed",
  },
  headerGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
  cancelIcon: {
    fontSize: 32,
    color: "#2056a6",
  },
  iconButton: {
    marginTop: "-22px",
    marginRight: "0px",
    height: "24px",
    width: "24px",
  },
}));

interface AnalyzeModalProps {
  yes: Function;
  timeFrom?: string;
  timeTo?: string;
  selectedTime?: string;
  selectedSpace?: any;
  tripWireCount?: any;
  selectedFloor?: any;
  selectedTimeZone: string;
}

export function AnalyzeModal({
  yes,
  timeFrom,
  timeTo,
  selectedTime,
  selectedSpace,
  tripWireCount,
  selectedFloor,
  selectedTimeZone,
}: AnalyzeModalProps) {
  const classes = useStyles();
  const [tripWireResponseData, setTripWireResponseData] = useState<any>(null);
  const { selectedPropertyTimeZone } = usePropertiesState();
  const [timeFormat, setTimeFormat] = useState("");
  const [inConnectedPeople, setInConnectedPeople] = useState([]);
  const [outConnectedPeople, setOutConnectedPeople] = useState([]);
  const [statisticsTimelineData, setStatisticsTimelineData] = useState<any>([]);
  const [syntheticIn, setSyntheticIn] = useState([]);
  const [syntheticOut, setSyntheticOut] = useState([]);
  const [netOccupancy, setNetOccupancy] = useState([]);
  const [timeSetter, setTimeSetter] = useState<number>(15);

  const confirmDelete = () => {
    yes();
  };

  const timeRange = useMemo(() => {
    switch (selectedTime) {
      case "15m":
        setTimeFormat("hh:mm A");
        setTimeSetter(15);
        return DashboardTimeRangeType.MINUTE;
      case "1h":
        setTimeFormat("hh:mm A");
        setTimeSetter(60);
        return DashboardTimeRangeType.QUARTER;
      case "24h":
        setTimeFormat("hh A");
        setTimeSetter(1440);
        return DashboardTimeRangeType.HOUR;
      case "7d":
        setTimeFormat("DD MMM ddd");
        setTimeSetter(10080);
        return DashboardTimeRangeType.DAY;
      case "30d":
        setTimeFormat("DD MMM ddd");
        setTimeSetter(43200);
        return DashboardTimeRangeType.DAY;
      default:
        setTimeFormat("hh A");
        return DashboardTimeRangeType.HOUR;
    }
  }, [selectedTime]);

  const getTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === "7d" || selectedTime === "30d") {
      if (selectedTimeZone) {
        newTimeTo = moment.tz(selectedTimeZone).startOf("day").format(); //ml
        newTimeFrom = moment
          .tz(newTimeTo, selectedTimeZone)
          .subtract(timeSetter, "minutes")
          .format();
      } else {
        newTimeTo = moment.utc().startOf("day").format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(timeSetter, "minutes")
          .format();
      }
    } else {
      if (selectedTimeZone) {
        newTimeTo = moment
          .tz(selectedTimeZone)
          .startOf(selectedTime === "24h" ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedTimeZone)
          .subtract(timeSetter, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf(selectedTime === "24h" ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(timeSetter, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, selectedTimeZone, timeSetter]);

  const [getStatisticTimelineForAnyTripwires, { loading, error, data }] =
    useLazyQuery(GQLService.queries.statisticTimelineForAnyTripwires, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    getStatisticTimelineForAnyTripwires({
      variables: {
        from: getTimeParams().newTimeFrom,
        spaceId: selectedSpace,
        timeRangeType: timeRange,
        to: getTimeParams().newTimeTo,
        floorId: selectedFloor?.id!,
        deviceType: DashboardDeviceType.CAMERA,
      },
    });
  }, [
    getStatisticTimelineForAnyTripwires,
    selectedSpace,
    timeRange,
    selectedFloor,
    getTimeParams,
  ]);

  useEffect(() => {
    if (data) {
      setTripWireResponseData(data?.statisticTimelineForAnyTripwires!);
    }
  }, [data]);

  function colorGenerator() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  useEffect(() => {
    if (tripWireResponseData) {
      let inDataArray: any = [];
      let outDataArray: any = [];

      var filteredDataIn = _.map(
        tripWireResponseData.tripwires,
        "tripwireInData"
      );
      var filteredDataOut = _.map(
        tripWireResponseData.tripwires,
        "tripwireOutData"
      );

      for (let i = 0; i < filteredDataIn?.length!; i++) {
        if (filteredDataIn[i] !== null) {
          const inData = filteredDataIn[i]?.map(
            (v: { from: moment.MomentInput; value: any }) => {
              return {
                date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
                people: v.value,
              };
            }
          );
          inDataArray.push(inData);
        }
      }
      for (let i = 0; i < filteredDataOut?.length!; i++) {
        if (filteredDataIn[i] !== null) {
          const outData = filteredDataOut[i]?.map(
            (v: { from: moment.MomentInput; value: any }) => {
              return {
                date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
                people: v.value,
              };
            }
          );
          outDataArray.push(outData);
        }
      }
      setInConnectedPeople(inDataArray);
      setOutConnectedPeople(outDataArray);

      const syntheticInData = tripWireResponseData?.netflowTimeLine?.map(
        (v: { from: moment.MomentInput; netFlow: any }) => {
          return {
            date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
            people: v.netFlow.synthIns,
          };
        }
      );

      const syntheticOutData = tripWireResponseData?.netflowTimeLine?.map(
        (v: { from: moment.MomentInput; netFlow: any }) => {
          return {
            date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
            people: v.netFlow.synthOuts,
          };
        }
      );

      const netOccupancyData = tripWireResponseData?.netflowTimeLine?.map(
        (v: { from: moment.MomentInput; netFlow: any }) => {
          return {
            date: moment.tz(v.from, selectedTimeZone!).format(timeFormat),
            people: v.netFlow.correctedNetOccupancy,
          };
        }
      );

      setSyntheticIn(syntheticInData);
      setSyntheticOut(syntheticOutData);
      setNetOccupancy(netOccupancyData);

      const statisticsData = tripWireResponseData?.tripwires?.map(
        (item: any) => {
          return {
            id: item?.cameraZone?.id!,
            name: item?.cameraZone?.name
              ? item?.cameraZone?.name
              : item?.cameraZone?.zone,
            flowType: item?.cameraZone?.flowType!,
            inColor:
              item?.cameraZone?.flowType === "IN" ||
              item?.cameraZone?.flowType === "BOTH"
                ? colorGenerator()
                : "#ffffff",
            outColor:
              item?.cameraZone?.flowType === "OUT" ||
              item?.cameraZone?.flowType === "BOTH"
                ? colorGenerator()
                : "#ffffff",
            inData:
              item?.cameraZone?.flowType === "IN" ||
              item?.cameraZone?.flowType === "BOTH"
                ? item?.tripwireInData?.map(
                    (v: { from: moment.MomentInput; value: any }) => {
                      return {
                        date: moment
                          .tz(v.from, selectedTimeZone!)
                          .format(timeFormat),
                        people: v.value,
                      };
                    }
                  )
                : null,
            outData:
              item?.cameraZone?.flowType === "OUT" ||
              item?.cameraZone?.flowType === "BOTH"
                ? item?.tripwireOutData?.map(
                    (v: { from: moment.MomentInput; value: any }) => {
                      return {
                        date: moment
                          .tz(v.from, selectedTimeZone!)
                          .format(timeFormat),
                        people: v.value,
                      };
                    }
                  )
                : null,
          };
        }
      );
      setStatisticsTimelineData(statisticsData);
    }
  }, [
    selectedPropertyTimeZone,
    selectedTimeZone,
    timeFormat,
    tripWireResponseData,
  ]);

  const netFlowTime = ["24h", "15m", "1h"];

  const showTime = (time: number) => {
    return time === 0
      ? `12:00 AM`
      : time < 12
      ? `${time}:00 AM`
      : time === 12
      ? `12:00 PM`
      : `${time - 12}:00 PM`;
  };

  return (
    <Grid className={classes.content}>
      <Grid className={classes.headerGrid}>
        <Typography className={classes.header}>
          Analysis of Tripwires{" "}
          <Tooltip
            enterNextDelay={500}
            placement="right"
            title={
              <div>
                <Typography className={classes.toolTipContent}>
                  Click on the tripwire name to see the graph{" "}
                </Typography>
              </div>
            }
            classes={{ tooltip: classes.toolTipDiv }}
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </Tooltip>
        </Typography>
        <IconButton aria-label="delete" className={classes.iconButton}>
          <CancelIcon
            onClick={(e) => {
              confirmDelete();
            }}
            className={classes.cancelIcon}
          />
        </IconButton>
      </Grid>
      {!loading ? (
        <Grid className={classes.chartGrid}>
          <LineChartAnalyze
            selectedTime={selectedTime}
            inConnectedPeople={inConnectedPeople}
            outConnectedPeople={outConnectedPeople}
            statisticsTimelineData={statisticsTimelineData}
            syntheticIn={syntheticIn}
            syntheticOut={syntheticOut}
            netOccupancy={netOccupancy}
            colorSyntheticIn={colorGenerator()}
            colorSyntheticOut={colorGenerator()}
            colorNetOccupancy={"#000000"}
            resetTime={tripWireResponseData?.spaceDevice?.resetTime}
          />
          <Grid direction="column">
            <Grid style={{ display: "flex" }}>
              <Grid
                style={{
                  width: "90px",
                  marginTop: "2px",
                }}
              >
                <hr className={classes.solidBorder} />
              </Grid>
              <Grid>
                <Typography
                  className={classes.resetTime}
                  style={{ marginLeft: "4px" }}
                >
                  INs
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ display: "flex" }}>
              <Grid
                style={{
                  width: "80px",
                  marginTop: "2px",
                }}
              >
                <hr className={classes.dashedBorder} />
              </Grid>
              <Grid>
                <Typography
                  className={classes.resetTime}
                  style={{ marginLeft: "4px" }}
                >
                  OUTs
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ display: "flex", marginTop: "4px" }}>
              <Grid
                style={{
                  width: "10px",
                  height: "10px",
                  marginTop: "2px",
                  backgroundColor: "#000000",
                  borderRadius: "10px",
                }}
              ></Grid>
              <Grid>
                <Typography
                  className={classes.resetTime}
                  style={{
                    marginLeft: "4px",
                    color: "#2056a6",
                    fontWeight: 600,
                  }}
                >
                  Net Occupancy
                </Typography>
              </Grid>
            </Grid>
            {netFlowTime.includes(selectedTime!) &&
            tripWireResponseData?.spaceDevice?.resetTime ? (
              <Grid style={{ display: "flex", marginTop: "4px" }}>
                <Grid>
                  <Typography className={classes.resetTime}>
                    Reset Time:
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    className={classes.resetTime}
                    style={{ fontWeight: 700, marginLeft: "2px" }}
                  >
                    {showTime(
                      +tripWireResponseData?.spaceDevice?.resetTime?.split(
                        /[.:]/
                      )[0]
                    )}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid className={classes.loaderGrid}>
          <Loader type="Oval" color={COLORS.alto} height={150} width={150} />
        </Grid>
      )}
    </Grid>
  );
}
