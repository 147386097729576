import React, { useState, useCallback } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  AccordionActions,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Videocam } from "@material-ui/icons";

import { Role, ImagesSensor } from "../../../../types";
import { StatusIndicator } from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import GQLService from "../../../../core/services/GQL.service";
import { useMutation } from "@apollo/client";
import { ImagesSensorsMenu } from "./Menu";
import { useImagesSensorsState } from "../../../../core/context/containers/ImageSensor.container";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { listStyles } from "../../../../common/styles/lists";
import { useAuth } from "../../../../core/context/containers/Auth.container";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
  extendedListSubheader: {
    ...listStyles.extendedListSubheader,
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  container: {
    margin: "10px 30px",
  },
  cell: {
    minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  imagesSensorsInfo: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.regularBlue,
  },
  content: {},
  accordionDetails: {
    paddingBottom: "40px",
    flexDirection: "column",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
}));

interface ImagesSensorsItemProps {
  item: ImagesSensor;
}

export function ImagesSensorsListItem({ item }: ImagesSensorsItemProps) {
  const classes = useStyles();
  const { selectedProperty } = usePropertiesState();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const auth = useAuth();
  const handleExpand = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const { setSelectedImagesSensor } = useImagesSensorsState();
  const history = useHistory();

  const [updateImagesSensors] = useMutation(
    GQLService.mutations.editImagesSensors,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allCameras,
          variables: { propertyId: selectedProperty?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const toggleActiveStatus = useCallback(
    async () => {
      let isMounted = true;
      if (isMounted) {
        const zones = item.zones?.map((zone) => {
          const zoneCopy = { ...zone };
          delete zoneCopy.imageKey;
          delete zoneCopy.spaces;
          return zoneCopy;
        });
        const tripWires = item.tripWires?.map((tripWire) => {
          const tripWireCopy = { ...tripWire };
          delete tripWireCopy.imageKey;
          delete tripWireCopy.spaces;
          return tripWireCopy;
        });
        await updateImagesSensors({
          variables: { ...item, zones, tripWires, active: !item.active },
        });
      }
      return () => {
        isMounted = false;
      };
    },
    [item] //eslint-disable-line
  );

  const handleViewClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedImagesSensor(item);
      history.push(
        ROUTES.IMAGES_SENSORS.replace(":uid", selectedProperty!.id).replace(
          ":imageSensor",
          item.name
        )
      );
    },
    [history, item, selectedProperty, setSelectedImagesSensor]
  );

  return (
    <Accordion key={item.id} expanded={isExpanded}>
      <AccordionSummary onClick={(e) => handleViewClick(e, item.id)}>
        <Grid
          container={true}
          alignItems="center"
          justify="space-between"
          style={{ margin: 0 }}
        >
          <Grid
            container={true}
            item={true}
            md={3}
            sm={3}
            justify="flex-start"
            onClick={handleExpand}
            className={classes.headerWrapper}
          >
            {isExpanded ? (
              <ExpandLess
                id={`expandLess-${item.id}`}
                className={classes.expandIcon}
              />
            ) : (
              <ExpandMore
                id={`expandMore-${item.id}`}
                className={classes.expandIcon}
              />
            )}
            <Videocam className={classes.icon} />
            <Typography className={classes.name}>
              {item.name ? item.name : item.cameraUuid}
            </Typography>
          </Grid>
          <Grid item={true} md={1} sm={3}>
            <Typography className={classes.subheader}>
              {item.floor?.name}
            </Typography>
          </Grid>
          <Grid item={true} md={5} sm={3}>
            <Typography className={classes.subheader}>
              {item.cameraUuid}
            </Typography>
          </Grid>
        </Grid>

        <Grid item className={classes.flex}>
          {selectedProperty && (
            <StatusIndicator
              uid={selectedProperty?.id}
              isActive={item.active}
              canShowStatusLabel={false}
            />
          )}
        </Grid>
        <AccordionActions
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <ImagesSensorsMenu imageSensor={item} index={item.id} />
        </AccordionActions>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container={true} className={classes.container}>
          <Grid item sm={3} md={4}>
            <Typography className={classes.subheader}>Floor Name</Typography>
            <Grid className={classes.flex}>
              <Typography className={classes.imagesSensorsInfo}>
                {item.floor?.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={3} md={4}>
            <Typography className={classes.subheader}>Camera ID</Typography>
            <Typography className={classes.imagesSensorsInfo}>
              {item.cameraUuid}
            </Typography>
          </Grid>
          <Grid item sm={3} md={4}>
            <Typography className={classes.subheader}>STATUS</Typography>
            <Grid className={classes.flex}>
              {selectedProperty && (
                <StatusIndicator
                  uid={item?.id}
                  isActive={item.active}
                  canShowStatusLabel={true}
                  onChangeFn={toggleActiveStatus}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container={true} className={classes.container}>
          <Grid item sm={3} md={4}>
            <Typography className={classes.subheader}>Description</Typography>
            <Typography className={classes.imagesSensorsInfo}>
              {item.description}
            </Typography>
          </Grid>
          <Grid item sm={3} md={4}>
            <Typography className={classes.subheader}>
              Number of Zones
            </Typography>
            <Typography className={classes.imagesSensorsInfo}>
              {item.zones.length}
            </Typography>
          </Grid>
          <Grid item sm={3} md={4}>
            <Typography className={classes.subheader}>
              Number of TripWires
            </Typography>
            <Typography className={classes.imagesSensorsInfo}>
              {item.tripWires.length}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
