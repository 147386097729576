import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  select: {
    width: "100% !important",
    backgroundColor: theme.palette.common.InputBg,
    marginBottom: "10px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 28,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    width: "100% !important",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
    marginTop: theme.spacing(1),
  },
  placeholder: {
    color: theme.palette.common.inputGrey,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: 14,
    opacity: 1,
  },
  icon: {
    color: "#2F56A1",
    right: 7,
    width: 18,
  },
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    width: 20,
    fontSize: "14px",
    marginBottom: 14,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
    "&:before": {
      content: "none",
    },
  },
  inputField: {
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
    height: 10,
    width: 30,
  },
  error: {
    width: "100% !important",
    backgroundColor: theme.palette.common.InputBg,
    marginBottom: "10px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 28,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
    borderColor: theme.palette.common.cinnabar,
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    width: 135,
    borderRadius: "0 0 8px 8px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  dash: {
    margin: 10,
  },
  status: {
    color: "#122036",
    marginLeft: "24px",
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 3,
  },
  errorAutoComplete: {
    width: "100% !important",
    backgroundColor: theme.palette.common.InputBg,
    marginBottom: "10px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
    borderColor: theme.palette.common.cinnabar,
    marginTop: "10px",
  },
  textField: {
    borderRadius: 8,
    paddingLeft: "10px",
  },
  inputLabel: {
    margin: "20px 0 5px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    whiteSpace: "nowrap",
  },
  infoIcon: {
    fontSize: 16,
    marginLeft: "16px",
    color: "#2F56A0",
    cursor: "pointer",
    zIndex: 9999,
  },
  toolTipDiv: {
    backgroundColor: "#F2F2F2 !important",
    border: "1px solid #81D3E0",
    borderRadius: "3px !important",
    boxShadow: "0px 6px 6px 2px rgba(0, 0, 0, 0.25)",
    paddingLeft: "12px !important",
    width: "180px",
  },
  toolTipContent: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkBlue,
  },
  alignment: {
    display: "flex",
    marginTop: "-23px",
    marginLeft: "110px",
  },
}));
