import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  label: {
    margin: "20px 0 5px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    lineHeight: "18px",
    whiteSpace: "nowrap",

    "& ~ div > label": {
      margin: "20px 0 5px 0",
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.common.mediumBlack,
      lineHeight: "18px",
      whiteSpace: "nowrap",
    },
  },
  datepicker: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    marginTop: 0,
    marginBottom: 14,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
    "& > div:before": {
      borderBottom: 0,
      "&:hover, &:focus": {
        borderBottom: 0,
      },
    },
  },
  placeholder: {
    color: "#92ACBF",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: "14px",
  },
  error: {
    width: "100% !important",
    backgroundColor: theme.palette.common.InputBg,
    marginBottom: "10px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 28,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
    borderColor: theme.palette.common.cinnabar,
  },
}));
