import React from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";

import { ReactComponent as NoDataSVG } from "../../assets/images/no-data.svg";
import { useStyles } from "./NoData.styles";

interface NoDataProps {
  style?: string;
}

export function NoData({ style = "" }: NoDataProps) {
  const classes = useStyles();

  return (
    <Grid className={clsx(classes.nodata, style)}>
      <NoDataSVG className={classes.nodata} />
    </Grid>
  );
}
