import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Formik, Form as FormikForm } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import {
  Grid,
  makeStyles,
  Button,
  Input,
  InputLabel,
  Switch,
  Select,
  TextareaAutosize,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";

import { useSSIDState } from "../../../../core/context/containers/SSID.container";
import { CustomModal } from "../../../../common/components/index";
import { ModalContent } from "./ModalContent";
import { SSID, VLAN } from "../../../../types";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import GQLService from "../../../../core/services/GQL.service";
import { ROUTES } from "../../../../common/constants/Routing";
import { AuthTypesBase } from "../../../../common/constants/AuthTypes";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
  },
  textArea: {
    minHeight: "200px",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: "inherit",
    fontFamily: "inherit",
    fontWeight: "inherit",
    fontSize: theme.typography.pxToRem(16),
    width: "100%",
  },
  inputLabel: {
    margin: "20px 0 10px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.funBlue,
  },
  errorLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.cinnabar,
    marginLeft: 10,
  },
  errorInput: {
    borderColor: theme.palette.common.cinnabar,
  },
  form: {
    width: "100%",
    position: "relative",
  },
  submitBtn: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
    width: 180,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(5),
    outline: "none",
    border: "none",
  },
  switch: {
    color: `${theme.palette.common.blue} !important`,
    border: `1px solid ${theme.palette.common.blue}`,
    borderRadius: "30px",
    height: 17,
    width: 34,
    padding: 8,
    marginRight: 5,
  },
  switchInner: {
    color: `${theme.palette.common.blue} !important`,
  },
  track: {
    display: "none",
  },
  thumb: {
    color: `${theme.palette.common.blue} !important`,
    width: 8,
    height: 8,
  },
  checked: {
    color: "transparent !important",
  },
  base: {
    padding: 3,
  },
  status: {
    color: theme.palette.common.textBlack,
    marginLeft: 8,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
  },
  progress: {
    color: `${theme.palette.common.blue} !important`,
  },
  img: {
    width: 860,
    height: 657,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    width: "100% !important",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    marginTop: theme.spacing(1),
  },
  select: {
    width: "100% !important",
    backgroundColor: theme.palette.common.InputBg,
    marginBottom: "10px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 28,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: "15px",
  },
}));

const FIELD_NAMES = {
  name: "name",
  description: "description",
  authenticationType: "authenticationType",
  active: "active",
  vlanId: "vlanId",
};

export function EditSSIDForm() {
  const { selectedProperty } = usePropertiesState();
  const classes = useStyles();
  const { selectedSSID } = useSSIDState();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [vlans, setVlans] = useState<VLAN[]>([]);
  const [authType, setNewAuthType] = useState<string>(
    selectedSSID?.authenticationType!
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateSsid] = useMutation(GQLService.mutations.editSsid);
  const history = useHistory();
  const [formDataState, setFormDataState] = useState<SSID | false>(false);
  const [vlanId, setVLanId] = useState(selectedSSID?.vlanId);

  const { data } = useQuery(GQLService.queries.vlanByProperty, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  useEffect(
    () => {
      setVlans(data?.vlansByPropertyId || []);
    },
    [data] //eslint-disable-line
  );

  const handleSelectedAuthType = useCallback(
    (e, setFieldValue) => {
      setNewAuthType(e.target.value);
      setFieldValue(FIELD_NAMES.authenticationType, e.target.value);
    },
    [selectedProperty, authType] // eslint-disable-line
  );

  const handleSubmit = useCallback(
    async (formData) => {
      toggleModal(true);
      setFormDataState({ ...formData, id: selectedSSID?.id });
    },
    [formDataState] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      if (formDataState) {
        try {
          await updateSsid({
            variables: { ...formDataState, propertyId: selectedProperty?.id },
          });
          enqueueSnackbar("SSID changed successfully", {
            variant: "success",
          });
          history.push(ROUTES.SSIDS.replace(":uid", selectedProperty?.id!));
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          console.log(e);
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const handleSelectedVLan = useCallback(
    (e, setFieldValue) => {
      setVLanId(e.target.value);
      setFieldValue(FIELD_NAMES.vlanId, e.target.value);
    },
    [selectedProperty, authType] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => toggleModal(false), []);

  const [active, toggleActiveState] = useState<boolean>(selectedSSID?.active!);

  const toggleSwitchButton = useCallback(
    (setFieldValue) => {
      setFieldValue(FIELD_NAMES.active, !active);
      toggleActiveState(!active);
    },
    [selectedProperty, active] // eslint-disable-line
  );

  return (
    selectedSSID && (
      <Grid item={true} md={6} sm={12}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={Object.keys(FIELD_NAMES).reduce(
            (acc: { [key: string]: string }, curr: string) => {
              acc[curr] = (selectedSSID as any)[curr];
              return acc;
            },
            {}
          )}
          validationSchema={editSSIDSchema}
        >
          {({ values, setFieldValue, errors, submitCount, initialValues }) => (
            <FormikForm className={classes.form}>
              <Grid container={true} spacing={1}>
                <Grid item={true} sm={12} md={12}>
                  <InputLabel className={classes.inputLabel}>
                    SSID Name*
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.name] !==
                        initialValues[FIELD_NAMES.name]) &&
                      errors[FIELD_NAMES.name] && (
                        <span className={classes.errorLabel}>
                          {errors[FIELD_NAMES.name]}
                        </span>
                      )}
                  </InputLabel>
                  <Input
                    className={clsx(
                      classes.input,
                      (submitCount > 0 ||
                        values[FIELD_NAMES.name] !==
                          initialValues[FIELD_NAMES.name]) &&
                        errors[FIELD_NAMES.name]
                        ? classes.errorInput
                        : ""
                    )}
                    disableUnderline={true}
                    fullWidth={true}
                    placeholder={"SSID name"}
                    name={FIELD_NAMES.name}
                    type={"input"}
                    defaultValue={values.name}
                    onChange={(e) =>
                      setFieldValue(FIELD_NAMES.name, e.target.value)
                    }
                  />
                </Grid>
                <Grid item={true} sm={12} md={12}>
                  <InputLabel className={classes.inputLabel}>
                    Description
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.description] !==
                        initialValues[FIELD_NAMES.description]) &&
                      errors[FIELD_NAMES.description] && (
                        <span className={classes.errorLabel}>
                          {errors[FIELD_NAMES.description]}
                        </span>
                      )}
                  </InputLabel>
                  <TextareaAutosize
                    className={clsx(
                      classes.textArea,
                      (submitCount > 0 ||
                        values[FIELD_NAMES.description] !==
                          initialValues[FIELD_NAMES.description]) &&
                        errors[FIELD_NAMES.description]
                        ? classes.errorInput
                        : ""
                    )}
                    placeholder={"SSID description"}
                    name={FIELD_NAMES.description}
                    defaultValue={values.description}
                    onChange={(e) =>
                      setFieldValue(FIELD_NAMES.description, e.target.value)
                    }
                  />
                </Grid>
                <Grid item={true} sm={12} md={12}>
                  <InputLabel className={classes.inputLabel}>
                    VLan*
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.vlanId] !==
                        initialValues[FIELD_NAMES.vlanId]) &&
                      errors[FIELD_NAMES.vlanId] && (
                        <span className={classes.errorLabel}>
                          {errors[FIELD_NAMES.vlanId]}
                        </span>
                      )}
                  </InputLabel>
                  <Select
                    classes={{
                      root: classes.select,
                      select: classes.blackText,
                    }}
                    value={vlanId}
                    disableUnderline={true}
                    fullWidth={true}
                    required={true}
                    onChange={(e) => handleSelectedVLan(e, setFieldValue)}
                    name={FIELD_NAMES.vlanId}
                  >
                    <MenuItem key={-1} value={"Select floor"} disabled>
                      Select VLan
                    </MenuItem>
                    {selectedProperty &&
                      (vlans || []).map((vlan: VLAN, i) => (
                        <MenuItem key={i + 1} value={vlan.id} disabled={false}>
                          {vlan?.name!}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item={true} sm={12} md={12}>
                  <InputLabel className={classes.inputLabel}>
                    Authentication type*
                  </InputLabel>
                  <Select
                    classes={{
                      root: classes.select,
                      select: classes.blackText,
                    }}
                    fullWidth={true}
                    value={authType}
                    disableUnderline={true}
                    onChange={(e) => handleSelectedAuthType(e, setFieldValue)}
                    name={FIELD_NAMES.authenticationType}
                    autoWidth={true}
                  >
                    {AuthTypesBase.map((authType, i) => (
                      <MenuItem key={i} value={authType}>
                        {authType}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item={true} sm={12} md={12}>
                  <InputLabel className={classes.inputLabel}>Active</InputLabel>
                  <Grid
                    container={true}
                    item={true}
                    direction="row"
                    alignItems="center"
                  >
                    <Switch
                      placeholder={"Active"}
                      name={FIELD_NAMES.active}
                      type={"checkbox"}
                      onChange={(_) => toggleSwitchButton(setFieldValue)}
                      checked={active}
                      classes={{
                        root: classes.switch,
                        checked: classes.checked,
                        thumb: classes.thumb,
                        track: classes.track,
                        switchBase: classes.base,
                      }}
                    />
                    <div className={classes.status}>{!active && "No"}</div>
                    <div className={classes.status}>{active && "Yes"}</div>
                  </Grid>
                </Grid>
              </Grid>
              <Button type="submit" className={classes.submitBtn}>
                Save
              </Button>
            </FormikForm>
          )}
        </Formik>
        <CustomModal open={showModal}>
          <ModalContent yes={confirmChanges} no={rejectChanges} />
        </CustomModal>
      </Grid>
    )
  );
}

export const editSSIDSchema = Yup.object().shape({
  [FIELD_NAMES.name]: Yup.string().required(" "),
  [FIELD_NAMES.authenticationType]: Yup.string().required(""),
  [FIELD_NAMES.active]: Yup.boolean().required(" "),
  [FIELD_NAMES.vlanId]: Yup.string().required(" "),
});
