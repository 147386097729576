import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { Group } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useGroupBase() {
  const [group, setGroup] = useState<Group[]>([]);
  const [groupFromResponse, setGroupFromResponse] = useState<Group[]>([]);

  const [userGroup, setUserGroup] = useState<Group[]>([]);
  const [userGroupFromResponse, setUserGroupFromResponse] = useState<Group[]>(
    []
  );

  const {
    state: selectedGroup,
    setState: setSelectedGroup,
  } = usePersistedState<Group | null>("group", null);

  const {
    state: selectedUsersGroup,
    setState: setSelectedUsersGroup,
  } = usePersistedState<Group[] | []>("addGroups", []);

  const {
    state: selectedEditUsersGroup,
    setState: setSelectedEditUsersGroup,
  } = usePersistedState<Group[] | []>("editGroups", []);

  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const { search } = useSearch();

  const searchGroup = useCallback(
    (searchValue: string) => {
      setGroup(search(groupFromResponse, searchValue) as Group[]);
    },
    [groupFromResponse, search]
  );

  return {
    searchGroup,
    group,
    setGroup,
    groupFromResponse,
    setGroupFromResponse,
    selectedGroup,
    setSelectedGroup,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    loading,
    setLoading,
    selectedUsersGroup,
    setSelectedUsersGroup,
    selectedEditUsersGroup,
    setSelectedEditUsersGroup,
    userGroup,
    setUserGroup,
    userGroupFromResponse,
    setUserGroupFromResponse,
  };
}

const { Provider, useContainer } = createContainer(useGroupBase);

export const useGroupState = useContainer;
export const GroupProvider = Provider;
