import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: any) => ({
  select: {
    width: "100% !important",
    minWidth: "120px",
    backgroundColor: theme.palette.common.InputBg,
    marginBottom: "10px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 28,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    width: "100% !important",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    marginTop: theme.spacing(1),
    "&::placeholder": {
      color: theme.palette.common.textBlack,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  placeholder: {
    color: "#92ACBF",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: "14px",
  },
  icon: {
    color: "#2F56A1",
    right: 7,
    width: 18,
  },
  error: {
    width: "100% !important",
    backgroundColor: theme.palette.common.InputBg,
    marginBottom: "10px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 28,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
    borderColor: theme.palette.common.cinnabar,
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    borderRadius: "0 0 8px 8px",
  },
  disabled: {
    color: theme.palette.common.lighterGray,
  },
  spaceText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    marginRight: "8px",
  },
  propertyTextGrid: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
    marginRight: "3px",
    alignItems: "center",
  },
  propertyText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.mediumBlack,
  },
  newWrapperGrid: {
    border: "1px solid #E0EBF1",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "50px",
    transition: "height 0.2s",
  },
  transitionWrapperGrid: {
    border: "1px solid #E0EBF1",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "230px",
    transition: "height 0.2s",
  },
  innerWrapperGrid: {
    display: "flex",
    height: "48px",
    alignItems: "center",
  },
  lableGrid: {
    display: "flex",
    alignItems: "center",
  },
  labelText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.textBlack,
    marginRight: "25px",
  },
  valueText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(24),
    marginRight: "25px",
  },
  box: {
    border: "1px solid",
    borderColor: "#E0EBF1",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",
  },
  boxContent: {
    paddingRight: "10px",
    paddingLeft: "16px",
    paddingBottom: "16px",
    paddingTop: "16px",
  },
  blackTextBold: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.darkBlue,
    marginBottom: theme.spacing(1),
    padding: "9 0",
  },
  number: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(30),
    color: theme.palette.common.darkBlue,
    marginTop: "-8px",
    marginBottom: theme.spacing(1),
    padding: "9px 0",
  },
  textValue: {
    color: "#2F56A0",
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(28),
  },
  marginRight10: {
    marginLeft: "56px",
  },
  loaderGrid: {
    marginTop: "150px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  pointerStyle: {
    cursor: "pointer",
  },
  expandedWrapperGrid: {
    paddingTop: "20px",
    paddingBottom: "20px",
    justifyContent: "space-between",
    display: "flex",
  },
  iconGrid: {
    display: "flex",
    justifyContent: "end",
  },
}));
