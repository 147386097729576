import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { PageHeader } from "../../../common/components";
import { useStyles } from "./CardReaders.styles";
import clsx from "clsx";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import { COLORS } from "../../../common/styles/colors";
import Loader from "react-loader-spinner";
import moment from "moment";
import { CardReaderMenu } from "./CardReadersMenu";

export function CardReaders() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();

  const [cardReaderData, setCardReaderData] = useState<any>();
  const [isSwitchActive, setIsSwitchActive] = useState<boolean>(true);

  const [getCardReadersByPropertyId, { loading, data }] = useLazyQuery(
    GQLService.queries.cardReadersByPropertyId,
    {
      variables: { propertyId: selectedProperty?.id },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    getCardReadersByPropertyId();
  }, []);

  useEffect(() => {
    if (!loading && data) {
      setCardReaderData(data?.cardReadersByPropertyId);
    }
  }, [loading, data]);

  const handleChange = useCallback(
    (e) => {
      setIsSwitchActive(!isSwitchActive);
    },
    [isSwitchActive]
  );

  const getLastSeen = (time: any) => {
    let currentUTCTime = moment.utc();
    let lastSeenUTCTime = moment(time).utc();
    let secondsDiff = currentUTCTime.diff(lastSeenUTCTime, "seconds");

    if (secondsDiff >= 86400) {
      let days = currentUTCTime.diff(lastSeenUTCTime, "days");
      return days === 1 ? `${days} day ago` : `${days} days ago`;
    } else if (secondsDiff >= 3600) {
      let hours = currentUTCTime.diff(lastSeenUTCTime, "hours");
      return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
    } else if (secondsDiff >= 60) {
      let minutes = currentUTCTime.diff(lastSeenUTCTime, "minutes");
      return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
    } else {
      return secondsDiff === 1
        ? `${secondsDiff} second ago`
        : `${secondsDiff} seconds ago`;
    }
  };

  return (
    <div>
      <Grid className={classes.header}>
        <PageHeader
          title={formatMessage({
            id: "card.readers.header",
            defaultMessage: "Associated Card Readers",
            description: "Associated Card Readers header",
          })}
        />
        <Button className={classes.button} id="associateLocation">
          Associate with Location
        </Button>
      </Grid>
      {loading ? (
        <Grid className={classes.loaderGrid}>
          <Loader type="Oval" color={COLORS.alto} height={150} width={150} />
        </Grid>
      ) : cardReaderData?.cardReaderResponse?.length > 0 ? (
        <TableContainer>
          <Table className={classes.table}>
            <TableHead style={{ backgroundColor: "#E0EBF1" }}>
              <TableRow className={classes.tableRowData}>
                {/* Commented for future use */}
                {/* <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                inputProps={{
                  "aria-label": "Select All",
                }}
              />
            </TableCell> */}
                <TableCell>
                  <Typography className={classes.tableTH}>
                    Card Reader ID
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableTH}>
                    Card Reader Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableTH}>Property</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableTH}>Location</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableTH}>
                    Vendor Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableTH}>Status</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableTH}>Last Seen</Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableTH}> </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cardReaderData?.cardReaderResponse?.map((item: any) => (
                <TableRow>
                  {/* Commented for future use */}
                  {/* <TableCell padding="checkbox" className={classes.tableRowData}>
                <Checkbox color="primary" />
              </TableCell> */}
                  <TableCell className={classes.tableRowData}>
                    <Typography className={classes.tableTD}>
                      {item?.id}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableRowData}>
                    <Typography className={classes.tableTD}>
                      {item?.name}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableRowData}>
                    <Typography className={classes.tableTD}>
                      {item?.property?.siteName}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableRowData}>
                    <Typography className={classes.tableTD}>
                      {item?.floor === null
                        ? `- -`
                        : item?.floor?.name
                        ? item?.floor?.name
                        : `Floor ${item?.floor?.floorNumber}`}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableRowData}>
                    <Typography className={classes.tableTD}>
                      {item?.vendorName}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableRowData}>
                    <div style={{ display: "flex" }}>
                      <Switch
                        name={"acsStatus"}
                        value={item?.active}
                        checked={item?.active}
                        classes={{
                          root: classes.switch,
                          checked: classes.checked,
                          thumb: classes.thumb,
                          track: classes.track,
                          switchBase: classes.base,
                        }}
                        disabled={true}
                        onChange={handleChange}
                      />
                      <div className={classes.status}>
                        {item?.active ? "Active" : "Inactive"}
                      </div>
                      <div
                        className={clsx(
                          classes.outerCircle,
                          item?.active
                            ? classes.outerCircleGreen
                            : classes.outerCircleRed
                        )}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "5px",
                        }}
                      >
                        <div
                          className={clsx(
                            classes.innerCircle,
                            item?.active
                              ? classes.innerCircleGreen
                              : classes.innerCircleRed
                          )}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableRowData}>
                    <Typography className={classes.tableTD}>
                      {getLastSeen(item?.lastSeen)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <CardReaderMenu index={item?.vendorPortalId} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
    </div>
  );
}
