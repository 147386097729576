import React, { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import {
  Grid,
  Button,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";

// import { FormField as FormFieldType } from "../../../../../types/form";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { useUserState } from "../../../../core/context/containers/User.container";

import { FormField } from "../../../../types";
import GQLService from "../../../../core/services/GQL.service";

const useStyles = makeStyles((theme) => ({
  input: { display: "none" },
  button: {
    width: "160px",
    height: "160px",
    border: `1px dashed ${theme.palette.common.turquoiseBlue30}`,
    backgroundColor: theme.palette.common.turquoiseBlue15,
    color: theme.palette.common.nepal,
    marginTop: "25px",
  },
  buttonLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(11),
    textTransform: "none",
    textAlign: "center",
  },
  note: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.nepal,
    maxWidth: "250px",
  },
  preview: {
    width: "160px",
    height: "160px",
    objectFit: "contain",
    border: `1px solid ${theme.palette.common.nobel}`,
  },
  actionButtonsContainer: {
    marginTop: theme.spacing(1),
  },
  actionButton: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 100,
    height: 40,
    borderRadius: 30,
  },
  actionButtonDisabled: {
    backgroundColor: theme.palette.common.nobel,
    color: theme.palette.common.white,
    width: 100,
    height: 40,
    borderRadius: 30,
  },
  error: {
    borderColor: theme.palette.common.cinnabar,
  },
  progress: {
    color: theme.palette.common.white,
  },
  removeButton: {
    borderRadius: "50%",
    backgroundColor: theme.palette.common.funBlue,
    color: "#fff",
    position: "relative",
    top: "12px",
    left: "calc(100% - 12px);",
    width: "24px",
    height: "24px",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    cursor: "pointer",
  },
}));

interface ImageInputProps {
  field?: FormField;
  name: string;
  previewStyle?: string;
  currentUrl?: string;
  targetUid?: string;
}

export function AvatarUpload({
  field,
  name,
  previewStyle,
  currentUrl,
  targetUid,
}: ImageInputProps) {
  const classes = useStyles();

  const auth = useAuth();
  const { formatMessage } = useIntl();
  const { image, setImage } = useUserState();
  const [url, setUrl] = useState<string | undefined>(image || undefined);

  const [isEditing, setIsEditing] = useState<boolean>(!Boolean(currentUrl));

  const [error, setError] = useState<boolean>(false);

  const [data, setData] = useState<File | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "dragover",
        (e) => {
          e.preventDefault();
        },
        false
      );
      window.removeEventListener(
        "drop",
        (e) => {
          e.preventDefault();
        },
        false
      );
    };
  }, []);

  const handleToggleEditMode = useCallback(() => {
    setUrl(undefined);
    setIsEditing(!isEditing);
  }, [isEditing]);

  const handleFileSelect = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setError(false);

      if (e.target.files) {
        if (e.target.files[0].size / (1024 * 1024) >= 2) {
          setError(true);

          return;
        }
        setData(e.target.files[0]);
        const formData = new FormData();
        formData.append("data", e.target.files[0]);
        setImage(URL.createObjectURL(e.target.files[0]));
        const email = (auth?.user?.email || auth?.user?.fullName!).replace(
          "+",
          "%2B"
        );
        const coverPhotoResponse = await GQLService.rest.userAvatar.uploadUserAvatar(
          formData,
          email
        );
        if (coverPhotoResponse?.status !== 200) {
          setError(true);
        }
      }
    },
    [image]
  );

  const handleRemoveImage = useCallback(
    async (e: any) => {
      const email = (auth?.user?.email || auth?.user?.fullName!).replace(
        "+",
        "%2B"
      );

      await GQLService.rest.userAvatar.removeUserAvatar(email);
      setImage(null);
    },
    [image] //eslint-disable-line
  );

  const handleFileDrop = useCallback(
    async (e: React.DragEvent<HTMLInputElement>) => {
      setError(false);

      if (e.dataTransfer.files?.length) {
        if (e.dataTransfer.files[0].size / (1024 * 1024) >= 2) {
          setError(true);
          return;
        }

        setData(e.dataTransfer.files[0]);
        const formData = new FormData();
        formData.append("data", e.dataTransfer.files[0]);
        const email = (auth?.user?.email || auth?.user?.fullName!).replace(
          "+",
          "%2B"
        );
        await GQLService.rest.userAvatar.uploadUserAvatar(formData, email);
        setImage(URL.createObjectURL(e.dataTransfer.files[0]));
      }
    },
    [image]
  );

  const handleSave = useCallback(() => {
    setIsLoading(true);
  }, [data, auth?.user, name, targetUid]); // eslint-disable-line

  const handleRemove = useCallback(() => {}, [auth?.user, name, targetUid]); // eslint-disable-line

  return (
    <Grid
      container={true}
      direction="column"
      alignItems="flex-start"
      justify="center"
    >
      {image ? (
        <Grid item>
          <span className={classes.removeButton} onClick={handleRemoveImage}>
            &#10005;
          </span>
          <img
            src={image}
            alt={name}
            className={clsx(classes.preview, previewStyle)}
          />
        </Grid>
      ) : (
        <Grid>
          <input
            type="file"
            accept="image/jpeg, image/png"
            className={classes.input}
            id={field?.name || name}
            onChange={handleFileSelect}
          />
          <label htmlFor={field?.name || name}>
            <Button
              className={clsx(classes.button, error ? classes.error : "")}
              component="span"
              fullWidth={true}
              onDrop={handleFileDrop}
            >
              <Grid
                container={true}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <FileCopyOutlined />
                <Typography className={classes.buttonLabel}>
                  {formatMessage({
                    id: "imageInput.dragAndDrop",
                    defaultMessage: "Select File or Drag and Drop",
                    description: "Image input drag and drop label",
                  })}
                </Typography>
              </Grid>
            </Button>
          </label>
          <Typography className={classes.note}>
            {formatMessage({
              id: "imageInput.dragAndDropRules",
              defaultMessage:
                "Your upload must be: JPG or PNG format, less than 2 MB, and 160px\n(w) by 80px (h).",
              description: "Image input drag and drop help text",
            })}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
