import { SelectFloorFromProperty } from "../../../../../common/components";
import { FormField } from "../../../../../types";

const FIELD_NAMES = {
  floorsIds: "floorsIds",
  properties: "properties",
};

export const createEditFloorsForm = (data: any): FormField[] => [
  {
    name: FIELD_NAMES.floorsIds,
    label: "",
    placeholder: "Type here",
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: SelectFloorFromProperty,
    data: data.properties,
    customProps: {
      selectFloor: "Floor",
      selectProperty: "Property",
      noTagsMessage: "There are no Spaces added",
      title: "Added Spaces",
      assigned: {
        floors: data.user.floors,
        properties: data.user.properties,
      },
    },
  },
];
