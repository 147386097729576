import React, { useCallback, useEffect } from "react";
import clsx from "clsx";
import { useField, useFormikContext } from "formik";
import { Button, Grid, InputLabel, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";

import { FormField as FormFieldType } from "../../../../../types/form";
import { useStyles } from "./FormField.styles";
import { useFormWizardState } from "../../../../../core/context/containers/FormWizard.container";
import { FileCopyOutlined } from "@material-ui/icons";

interface FormFieldProps {
  field: FormFieldType;
}

export function FormField({ field }: FormFieldProps) {
  const classes = useStyles();
  const { errors, values, initialValues, isSubmitting } = useFormikContext<{
    [key: string]: string;
  }>();

  const { formatMessage } = useIntl();
  const { formData, setFormData } = useFormWizardState();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setFormData({
        ...formData,
        ...values,
      });
    }
    return () => {
      isMounted = false;
    };
  }, [values]);

  const [formField, meta, helpers] = useField(field.name);

  const handleChange = field.type === "file" ? () => {} : formField.onChange;

  const isTouched =
    isSubmitting || values[field.name] !== initialValues[field.name];

  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        if (e.target.files[0]?.size / (1024 * 1024) >= 2) {
          return;
        }
        helpers.setValue({
          url: URL.createObjectURL(e.target.files[0]),
          data: e.target.files[0],
          name: e.target.files[0]?.name,
        });
      }
    },
    [] // eslint-disable-line
  );
  return field.type !== "file" ? (
    <Grid
      item={true}
      {...(field.gridStyle ? field.gridStyle : { sm: 12, md: 12 })}
    >
      {field.title && (
        <Typography className={classes.blackText}>
          {formatMessage({
            id: `${field.title}.section.title`,
            defaultMessage: field.title,
            description: `${field.title} header`,
          })}
        </Typography>
      )}
      {field.label !== "" && (
        <InputLabel className={classes.inputLabel}>
          {`${field.label}`}
          {field.required && <span className={classes.required}>*</span>}
          {isTouched && errors[field.name] && (
            <span className={classes.errorLabel}>{errors[field.name]}</span>
          )}
        </InputLabel>
      )}
      <field.Component
        className={clsx(
          classes.input,
          isTouched && errors[field.name] ? classes.errorInput : ""
        )}
        disableUnderline={true}
        fullWidth={field.fullWidth ? field.fullWidth : true}
        disabled={field.disabled}
        placeholder={field.placeholder}
        name={formField.name}
        type={field.type}
        defaultValue={initialValues[field.name]}
        onChange={handleChange}
        data={field.data}
        customProps={field.customProps || {}}
        resetField={field.resetField}
      />
    </Grid>
  ) : formField?.value?.url ? (
    <Grid item className={classes.imageWrapper}>
      <span
        className={classes.removeButton}
        onClick={() =>
          helpers.setValue({
            url: undefined,
            data: undefined,
            name: undefined,
          })
        }
      >
        &#10005;
      </span>
      <img
        src={formField?.value?.url}
        alt={formField?.value?.name}
        className={clsx(classes.preview)} //previewStyle
      />
    </Grid>
  ) : (
    <Grid>
      <InputLabel className={classes.inputLabel}>{field.label}</InputLabel>
      <input
        type="file"
        accept="image/jpeg, image/png"
        className={classes.inputFile}
        id={field?.name}
        onChange={handleFileSelect}
        name={field?.name}
      />
      <label htmlFor={field?.name}>
        <Button
          className={clsx(classes.button)}
          component="span"
          fullWidth={true}
        >
          <Grid
            container={true}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <FileCopyOutlined />
            <Typography className={classes.buttonLabel}>
              {formatMessage({
                id: "imageInput.dragAndDrop",
                defaultMessage: "Select File or Drag and Drop",
                description: "Image input drag and drop label",
              })}
            </Typography>
          </Grid>
        </Button>
      </label>
      <Typography className={classes.note}>
        {formatMessage({
          id: "imageInput.dragAndDropRules",
          defaultMessage:
            "Your upload must be: JPG or PNG format, less than 2 MB.",
          description: "Image input drag and drop help text",
        })}
      </Typography>
    </Grid>
  );
}
