import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.textBlack,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(5),
  },
  button: {
    backgroundColor: theme.palette.common.matisse,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: 115,
    height: 42,
  },
  main: {
    marginTop: theme.spacing(3),
  },
}));
