import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";

import { ROUTES } from "../../common/constants/Routing";
import { AddElevator } from "../../pages/Dashboard/Elevators/Add/Add";
import { EditElevator } from "../../pages/Dashboard/Elevators/Edit/Edit";
import { ElevatorView } from "../../pages/Dashboard/Elevators/View/View";
import { Role } from "../../types";
import { useAuth } from "../context/containers/Auth.container";

export const ELEVATORS_ROUTES = {
  ELEVATORS: "/dashboard/properties/:uid/elevators",
  ADD_ELEVATOR: "/dashboard/properties/:uid/elevators/add",
  EDIT_ELEVATOR: "/dashboard/properties/:uid/elevators/:elevatorId/edit",
  ELEVATOR_VIEW: "/dashboard/properties/:uid/elevators/:elevatorId/view",
};

const Elevators = lazy(
  () => import("../../pages/Dashboard/Elevators/List/List")
);

export default function ElevatorsRouter() {
  const auth = useAuth();

  return (
    <Route path={ROUTES.ELEVATORS}>
      <Route
        exact={true}
        path={ROUTES.ELEVATORS}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "elevators:list",
              <Elevators />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.ELEVATOR_VIEW}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "elevators:view",
              <ElevatorView />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.EDIT_ELEVATOR}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "elevators:edit",
              <EditElevator />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.ADD_ELEVATOR}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "elevators:create",
              <AddElevator />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
    </Route>
  );
}
