import { gql } from "@apollo/client";

export const USER_MUTATIONS = {
  editUser: gql`
    mutation updateUser(
      $active: Boolean!
      $email: String!
      $name: String
      $role: UserRole
      $newEmail: String
      $propertiesIds: [String]
      $floorsIds: [String]
    ) {
      updateUser(
        request: {
          active: $active
          email: $email
          name: $name
          role: $role
          propertiesIds: $propertiesIds
          newEmail: $newEmail
          floorsIds: $floorsIds
        }
      ) {
        email
        enabled
        fullName
        id
        role
        creationDate
        properties {
          id
          accountBusinessEntity
          ownerCompanyName
          companyAccountNumber
          companyStatus
          companyWebsite
          primaryContactFullName
          primaryContactPhoneNumber
          primaryContactEmailAddress
          siteName
          floor0Excluded
          siteNrOfFloors
          siteNrOfUndergroundFloors
          sitePropertyType
          siteServiceAddress
          spaceLoadMediumMax
          spaceLoadMediumMin
          logoKey
          imageKey
          workingFrom
          workingTo
          timeZone
          ntopngDatabaseName
          reservationConfigurations {
            schedulingSlotMinutes
            passGracePeriodMinutes
            passExpirationGracePeriodMinutes
            maxSchedulability
            maxAheadTimeOfReservationHours
            maxReservationsPerUser
          }
          upNextConfigurations {
            dateFormat
            healthSurveyFrequency
            use24HourClock
            surveyActive
          }
          sensorConfigurations {
            attribute
            maxVal
            minVal
            sensorHealthIndicator
          }
          upNext
          dateCreated
          propertyDateFormat
          use24HourClock
        }
        floors {
          id
          floorNumber
          name
          propertyId
        }
      }
    }
  `,
  deleteUser: gql`
    mutation($email: String!) {
      deleteUser(email: $email)
    }
  `,
  setDefaultProperty: gql`
    mutation($email: String!, $propertyId: String!) {
      setDefaultProperty(request: { email: $email, propertyId: $propertyId })
    }
  `,
  createUser: gql`
    mutation createUser(
      $active: Boolean!
      $email: String!
      $floorsIds: [String]
      $name: String!
      $password: String!
      $propertiesIds: [String]
      $role: UserRole!
    ) {
      createUser(
        request: {
          active: $active
          email: $email
          floorsIds: $floorsIds
          name: $name
          password: $password
          propertiesIds: $propertiesIds
          role: $role
        }
      ) {
        id
      }
    }
  `,
  createResetPasswordToken: gql`
    mutation($email: String!) {
      createResetPasswordToken(email: $email)
    }
  `,
  resetUserPassword: gql`
    mutation resetUserPassword($token: String!, $newPassword: String!) {
      resetUserPassword(request: { token: $token, newPassword: $newPassword })
    }
  `,
  changeUserPassword: gql`
    mutation changeUserPassword(
      $email: String!
      $oldPassword: String!
      $newPassword: String!
    ) {
      changeUserPassword(
        request: {
          email: $email
          newPassword: $newPassword
          oldPassword: $oldPassword
        }
      )
    }
  `,
  updateUserSettings: gql`
    mutation updateUserSettings($email: String!, $name: String) {
      updateUserSettings(request: { email: $email, name: $name }) {
        email
        fullName
      }
    }
  `,
};
// defaultProperty
// floors
// properties

export const USER_QUERIES = {
  allUsers: gql`
    query {
      users {
        email
        enabled
        fullName
        id
        role
        creationDate
        properties {
          id
          accountBusinessEntity
          ownerCompanyName
          companyAccountNumber
          companyStatus
          companyWebsite
          primaryContactFullName
          primaryContactPhoneNumber
          primaryContactEmailAddress
          siteName
          floor0Excluded
          siteNrOfFloors
          siteNrOfUndergroundFloors
          sitePropertyType
          siteServiceAddress
          spaceLoadMediumMax
          spaceLoadMediumMin
          logoKey
          imageKey
          workingFrom
          workingTo
          timeZone
          ntopngDatabaseName
          reservationConfigurations {
            schedulingSlotMinutes
            passGracePeriodMinutes
            passExpirationGracePeriodMinutes
            maxSchedulability
            maxAheadTimeOfReservationHours
            maxReservationsPerUser
          }
          upNextConfigurations {
            dateFormat
            healthSurveyFrequency
            use24HourClock
            surveyActive
          }
          sensorConfigurations {
            attribute
            maxVal
            minVal
            sensorHealthIndicator
          }
          upNext
          dateCreated
          propertyDateFormat
          use24HourClock
        }
        floors {
          id
          floorNumber
          name
          propertyId
        }
      }
    }
  `,

  allUsersInfo: gql`
    query {
      users {
        email
        enabled
        fullName
        id
        role
        creationDate
        properties {
          id
          ownerCompanyName
          companyStatus
          companyWebsite
          primaryContactFullName
          primaryContactPhoneNumber
          primaryContactEmailAddress
          siteName
          sitePropertyType
          siteServiceAddress
        }
      }
    }
  `,

  user: gql`
    query($email: String!) {
      userByEmail(email: $email) {
        email
        enabled
        fullName
        id
        role
        creationDate
        defaultProperty {
          id
        }
        properties {
          id
          accountBusinessEntity
          ownerCompanyName
          companyAccountNumber
          companyStatus
          companyWebsite
          primaryContactFullName
          primaryContactPhoneNumber
          primaryContactEmailAddress
          siteName
          floor0Excluded
          siteNrOfFloors
          siteNrOfUndergroundFloors
          sitePropertyType
          siteServiceAddress
          spaceLoadMediumMax
          spaceLoadMediumMin
          logoKey
          imageKey
          workingFrom
          workingTo
          timeZone
          ntopngDatabaseName
          reservationConfigurations {
            schedulingSlotMinutes
            passGracePeriodMinutes
            passExpirationGracePeriodMinutes
            maxSchedulability
            maxAheadTimeOfReservationHours
            maxReservationsPerUser
          }
          upNextConfigurations {
            dateFormat
            healthSurveyFrequency
            use24HourClock
            surveyActive
          }
          sensorConfigurations {
            attribute
            maxVal
            minVal
            sensorHealthIndicator
          }
          upNext
          dateCreated
          propertyDateFormat
          use24HourClock
        }
      }
    }
  `,
  userByPropertyId: gql`
    query usersByPropertyId($propertyId: String!) {
      usersByPropertyId(propertyId: $propertyId) {
        fullName
        email
        id
        role
        enabled
      }
    }
  `,
};
