import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryLabel,
  VictoryLegend,
  VictoryArea,
} from "victory";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import clsx from "clsx";
import { ReactComponent as ClockIcon } from "../../../../common/assets/images/clock.svg";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.common.textBlack,
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    position: "relative",
    top: -60,
  },
  keyPosition: {},
  radioBarGreen: {
    backgroundColor: "#79CC38",
  },
  radioBarBlue: {
    backgroundColor: "#2056a6",
  },
  radioBarRed: {
    backgroundColor: "#EC8888",
  },
  radioBarTurquoise: {
    backgroundColor: theme.palette.common.turquoiseBlue,
  },
  rectangle: {
    height: 15,
    width: 4,
    borderRadius: 14,
    margin: "0 24px 0 8px",
  },
  timeZone: {
    backgroundColor: theme.palette.common.InputBg,
    padding: "5px",
    fontSize: "11px",
    height: "25px",
    borderRadius: "2px",
    lineHeight: "11px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  victoryChart: {
    width: "95%",
  },
  timeZoneWrapper: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "25px",
  },
}));

interface LineChartDTimeDashboardProps {
  devices?: boolean;
  selectedTime?: string;
  statisticsSelect?: any;
  averageDwellTime?: any[];
}

export function LineChartDTimeDashboard({
  devices,
  selectedTime,
  statisticsSelect,
  averageDwellTime,
}: LineChartDTimeDashboardProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();

  const YAxisLabel = "Dwell time (Secs)";

  const xAxis = useMemo(() => {
    switch (selectedTime) {
      case "15m":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 15 minutes"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "1h":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last hour"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "24h":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 24 hours"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "7d":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 7 days"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    if (tArray.length > 2)
                      return (
                        tArray[0] +
                        "\n" +
                        tArray[1] +
                        "\n" +
                        tArray[2]?.charAt(0)
                      );
                    else return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  if (tArray.length > 2)
                    return (
                      tArray[0] + "\n" + tArray[1] + "\n" + tArray[2]?.charAt(0)
                    );
                  else return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "30d":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 30 days"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    if (tArray.length > 2)
                      return (
                        tArray[0] +
                        "\n" +
                        tArray[1] +
                        "\n" +
                        tArray[2]?.charAt(0)
                      );
                    else return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  if (tArray.length > 2)
                    return (
                      tArray[0] + "\n" + tArray[1] + "\n" + tArray[2]?.charAt(0)
                    );
                  else return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
    }
  }, [selectedTime]);

  const highestAvgDwellTime =
    averageDwellTime?.map((v) => v.avgDwellTime) || [];

  const highestValue = Math.max.apply(null, [...highestAvgDwellTime!]);

  return (
    <Grid container={true} item={true}>
      <Grid className={classes.victoryChart}>
        <VictoryChart
          height={180}
          padding={{ top: 10, bottom: 50, left: 40, right: 20 }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) =>
                `${Math.round(datum.avgDwellTime).toFixed(1)}`
              }
              voronoiBlacklist={["offHours"]}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={1}
                  constrainToVisibleArea
                  flyoutStyle={{
                    stroke: "#B5E9EF",
                    fill: "#E6F8FA",
                  }}
                  flyoutWidth={20}
                  flyoutHeight={12}
                  pointerWidth={5}
                  style={{ fontSize: 8 }}
                />
              }
            />
          }
        >
          {xAxis}
          <VictoryAxis
            dependentAxis={true}
            domain={{ y: [0, highestValue + 5] }}
            tickFormat={(t) => t}
            offsetX={40}
            label={YAxisLabel}
            axisLabelComponent={
              <VictoryLabel
                style={{ fontSize: 6, fontFamily: "Poppins", color: "#ccc" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
          />
          <VictoryLine
            data={averageDwellTime}
            x="date"
            y="avgDwellTime"
            style={{
              data: { stroke: "#81D3E0", strokeWidth: 1 },
            }}
          />
        </VictoryChart>
      </Grid>
      <Grid md={4} className={classes.timeZoneWrapper}>
        <Typography className={classes.timeZone}>
          <ClockIcon style={{ marginRight: 2, width: 11, height: 11 }} />
          {selectedProperty?.timeZoneName || selectedProperty?.timeZone}
        </Typography>
      </Grid>
    </Grid>
  );
}
