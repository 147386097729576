import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./Portfolio.styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { ReactComponent as PortfolioIcon } from "../../common/assets/images/portfolio.svg";
import clsx from "clsx";
import { ReactComponent as Internet } from "../../common/assets/images/internet.svg";
import { ReactComponent as FreshAir } from "../../common/assets/images/fresh-air.svg";
import { useUserState } from "../../core/context/containers/User.container";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../common/constants/DummyProperty";

interface PortfolioListProps {
  data: any;
}

export function PortfolioList({ data }: PortfolioListProps) {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const colorSpaceLoad = (spaceLoad: string) => {
    switch (spaceLoad) {
      case "HIGH":
        return "#B0001F";
      case "LOW":
        return "#4F9E53";
      case "MEDIUM":
        return "#EEAA46";
      case "HEALTHY":
        return "#4F9E53";
      case "MODERATE":
        return "#EEAA46";
      case "POOR":
        return "#B0001F";
      case "OFFLINE":
        return "#A0A0A0";
      default:
        return "#122036";
    }
  };

  const getPropertyName = (id: String, name: String) => {
    if (userEmail === dummyUserEmail) {
      if (id === DummyPropertyA.id) {
        return DummyPropertyA.siteName;
      } else if (id === DummyPropertyB.id) {
        return DummyPropertyB.siteName;
      } else if (id === DummyPropertyC.id) {
        return DummyPropertyC.siteName;
      } else {
        return DummyPropertyD.siteName;
      }
    } else {
      return name!;
    }
  };

  return (
    <Grid style={{ marginBottom: "60px" }}>
      <Grid className={classes.propertyTextGrid}>
        <Typography className={classes.propertyText}>
          {getPropertyName(data.property.id, data.property.siteName)}
        </Typography>
        {/* Commented for next sprint */}
        {/* <PortfolioIcon /> */}
      </Grid>
      <Grid
        className={clsx(
          isExpanded ? classes.transitionWrapperGrid : classes.newWrapperGrid
        )}
      >
        {!isExpanded ? (
          <>
            {/* Change this with data condition */}
            {data.apDensity !== null || data.aqDensity !== null ? (
              <Grid md={12} className={classes.innerWrapperGrid}>
                <Grid item md={3} className={classes.lableGrid}>
                  <Typography className={classes.labelText}>
                    Occupancy
                  </Typography>
                  <Typography
                    className={classes.valueText}
                    style={{ color: colorSpaceLoad(data.spaceLoad) }}
                  >
                    {~~Math.round(data.apDensity)}
                  </Typography>
                </Grid>
                <Grid item md={4} className={classes.lableGrid}>
                  <Typography className={classes.labelText}>
                    Average Wellness Index
                  </Typography>
                  <Typography
                    className={classes.valueText}
                    style={{ color: colorSpaceLoad(data.spaceAirQuality) }}
                  >
                    {~~Math.round(data.aqDensity)}
                  </Typography>
                </Grid>
                {/* Commented for next sprint  */}
                {/*  <Grid item md={3} className={classes.lableGrid}>
                  <Typography className={classes.labelText}>
                    Dwell Time
                  </Typography>
                  <Typography
                    className={classes.valueText}
                    style={{ color: "#2F56A0" }}
                  >
                    05{" "}
                    <span
                      className={classes.valueText}
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#000000",
                      }}
                    >
                      min
                    </span>
                  </Typography>
                </Grid> */}
                <Grid
                  item
                  md={5} //need to change once dwell time added md={2}
                  className={classes.iconGrid}
                >
                  <ExpandMore
                    className={classes.pointerStyle}
                    onClick={(e) => setIsExpanded(!isExpanded)}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid md={12} className={classes.innerWrapperGrid}>
                <Typography
                  className={classes.propertyText}
                  style={{ fontWeight: 400 }}
                >
                  The common space is not tagged for this location
                </Typography>
              </Grid>
            )}
          </>
        ) : (
          <Grid md={12} className={classes.expandedWrapperGrid}>
            <Grid md={10} style={{ display: "flex" }}>
              <Grid
                container={true}
                md={3}
                direction="column"
                className={classes.box}
                style={{ marginRight: "32px" }}
              >
                <Grid direction="column" className={classes.boxContent}>
                  <Grid item sm={12}>
                    <Typography className={classes.blackTextBold}>
                      Occupancy
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography
                      className={classes.number}
                      style={{ color: "#2F56A0" }}
                    >
                      {~~Math.round(data.apDensity)}
                    </Typography>
                  </Grid>
                  <Typography className={classes.blackText}>
                    Access Points
                  </Typography>
                  <Grid container={true} sm={12} direction="row">
                    <Typography className={classes.textValue}>
                      {~~Math.round(data.apCount)}
                    </Typography>
                    <Internet className={classes.marginRight10} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container={true}
                md={3}
                direction="column"
                className={classes.box}
                style={{ marginRight: "32px" }}
              >
                <Grid direction="column" className={classes.boxContent}>
                  <Grid item sm={12}>
                    <Typography className={classes.blackTextBold}>
                      Average Wellness Index
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography
                      className={classes.number}
                      style={{ color: "#2F56A0" }}
                    >
                      {~~Math.round(data.aqDensity)}
                    </Typography>
                  </Grid>
                  <Typography className={classes.blackText}>
                    Air Quality Sensors
                  </Typography>
                  <Grid container={true} sm={12} direction="row">
                    <Typography className={classes.textValue}>
                      {~~Math.round(data.aqCount)}
                    </Typography>
                    <FreshAir
                      className={classes.marginRight10}
                      width={36}
                      height={36}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid md={2} className={classes.iconGrid}>
              <ExpandLess
                className={classes.pointerStyle}
                onClick={(e) => setIsExpanded(!isExpanded)}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
