import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: theme.palette.common.concrete,
  },
  button: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 180,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(5),
    outline: "none",
    border: "none",
  },
}));
