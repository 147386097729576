import { makeStyles } from "@material-ui/core/styles";

import { DRAWER_WIDTH, DRAWER_WIDTH_SM } from "./DrawerNav";

export const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#334867",
      borderRadius: "10px !important",
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
  },
  toolbar: {
    height: 100,
    paddingRight: 24,
    backgroundColor: theme.palette.common.white,
  },
  appIcon: {
    marginLeft: "50px",
    marginBottom: "22px",
    marginTop: "23px",
  },
  large: {
    marginTop: "150px",
    marginLeft: "50px",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  iconColor: {
    color: "#92ACBF",
  },
  nestedMenuActive: {
    borderLeft: "5px solid #fff",
    marginLeft: "-5px",
    "& span:first-child": {
      borderLeft: "1px solid #fff",
      color: "#fff",
      fontWeight: 700,
    },
  },
  nestedMenutext: {
    fontSize: "14px",
    padding: "8px",
    width: "100%",
    marginLeft: "16px",
    "&:focus, &:hover, &:active": {
      background: "#455976",
      color: "#fff",
      fontWeight: 500,
    },
  },
  nestedAccordionPaper: {
    backgroundColor: "#1A2E4B",
    color: "#B8DEE2",
    border: 0,
    borderLeft: "5px solid #1A2E4B",
    "&.Mui-expanded": {
      borderLeft: "5px solid #81D3E0",
    },
    "& div.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "48px",
    },
    "& div.MuiAccordionDetails-root": {
      padding: 0,
      "& ul.MuiList-root.MuiList-padding": {
        padding: 0,
        width: "100%",
        "& li": {
          padding: 0,
        },
      },
    },
    "& div.MuiAccordionSummary-expandIcon": {
      padding: "6px",
    },
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    border: 0,
    width: DRAWER_WIDTH,
    minHeight: "100vh",
    backgroundColor: theme.palette.common.blackPearl,
    color: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
      width: DRAWER_WIDTH_SM,
    },
    "& > div.MuiAccordionSummary-root": {
      borderTop: "1px solid #334867",
      marginTop: "20px",
      padding: "16px 0px",
      "& > div.MuiAccordionSummary-content": {
        paddingLeft: "16px",
        borderBottom: "5px solid #1A2E4B",
      },
      "& > div.Mui-expanded": {
        margin: "0 !important",
      },
    },
    "& > div.MuiCollapse-entered": {
      backgroundColor: "#1A2E4B",
    },
  },
  divider: {
    backgroundColor: theme.palette.common.turquoiseBlue50,
  },
  selectedItemIndicator: {
    width: 5,
    height: 40,
    marginRight: 8,
    backgroundColor: theme.palette.common.turquoiseBlue,
  },
  disable: {
    display: "none",
  },
  menuItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItemPadding: {
    paddingLeft: theme.typography.pxToRem(16),
    paddingTop: 6,
    paddingBottom: 6,
    overflow: "visible",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  nestedMenuItemPadding: {
    overflow: "visible",
    justifyContent: "flex-start",
    "& span": {
      color: "#B8DEE2",
      fontSize: 400,
      paddingLeft: theme.typography.pxToRem(5),
    },
    "&:focus, &:hover, &:active": {
      background: "#455976",
      color: "#B8DEE2",
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  spacingAdjustment: {
    left: 0,
    paddingLeft: 0,
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.inputGrey,
    lineHeight: theme.typography.pxToRem(24),

    "& span": {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(16),
    },
  },
  sectionLabel: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.common.turquoiseBlue50,
  },
  label: {
    "& span": {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  itemActive: {
    color: theme.palette.common.turquoiseBlue,
    "& span": {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  menu: {
    padding: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& li": {
      fontSize: "16px",
      "&:focus, &:hover, &:active": {
        color: "#B8DEE2",
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  expandIcon: {
    backgroundColor: "#1A2E4B",
    color: theme.palette.common.inputGrey,
    width: 26,
  },
  expandAdminIcon: {
    backgroundColor: "#1A2E4B",
    width: 34,
    height: 32,
  },
  expandedStyle: {
    margin: "0 !important",
  },
  textSelected: {
    color: theme.palette.common.white,
    "& ~ svg": {
      color: theme.palette.common.white,
    },
  },
  name: {
    marginLeft: theme.typography.pxToRem(4),
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
    display: "flex",
    alignItems: "center",
  },
  address: {
    marginLeft: theme.typography.pxToRem(4),
    paddingBottom: "10px",
    whiteSpace: "pre-wrap",
    width: "185px",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
  },
  option: {
    padding: `12px`,
    height: 33,
    width: 400,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: "#B8DEE2",
    "&:focus, &:hover, &:active": {
      backgroundColor: "#92ACBF",
      color: theme.palette.common.darkBlue,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  selected: {
    background: "#455976 !important",
  },
  selectMenu: {
    boxShadow: "none !important",
    backgroundColor: "#334867",
    minWidth: 185,
    maxWidth: 185,
    maxHeight: 265,
    left: "8px !important",
    marginTop: "35px",
    position: "relative",
    paddingTop: "10px",
    overflow: "hidden",
    "& ul li:last-child": {
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiList-padding": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiListSubheader-root": {
      lineHeight: "30px",
    },
  },
  select: {
    margin: "4px 0",
  },
  icon: {
    color: theme.palette.common.white,
    width: 18,
    bottom: 6,
  },
  whiteText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.white,
    whiteSpace: "pre-wrap",
  },
  allPropertiesButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkBlue,
    backgroundColor: theme.palette.common.inputGrey,
    textDecoration: "underline",
    textTransform: "none",
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    borderRadius: 0,
    "&:focus, &:hover, &:active": {
      backgroundColor: "#92ACBF",
    },
  },
  addPropertyButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: "#B8DEE2",
    textTransform: "none",
    border: "1px solid #81D3E0",
    marginTop: "5px",
    "& svg": {
      fontSize: theme.typography.pxToRem(16),
      color: "#B8DEE2",
    },
  },
  listContainer: {
    backgroundColor: "#455976",
    width: "185px",
    margin: "0 auto",
    border: "1px solid #455976",
    borderTop: 0,
    paddingTop: 0,
    paddingBottom: 0,
    zIndex: 9,
    position: "absolute",
    left: "8px",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    "& .MuiList-padding": {
      borderTop: `1px solid ${theme.palette.common.darkBlue}`,
    },
    "& > li": {
      paddingLeft: 4,
      paddingRight: 4,
      "&:last-child": {
        borderTop: `1px solid ${theme.palette.common.darkBlue}`,
        padding: "4px 0",
      },
    },
  },
  listHead: {
    width: "185px",
    margin: "0 auto",
  },
  open: {
    border: "1px solid #455976",
    borderBottom: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  arrowPointer: {
    cursor: "pointer",
  },
  mainContainer: {
    position: "relative",
    marginBottom: "20px",
  },
  // menuStyle: {
  //   backgroundColor: "#1A2E4B",
  // }
  customWidth: {
    paddingTop: "4px",
    paddingBottom: "4px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackText,
    backgroundColor: "#D9F2F6",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "3px",
  },
}));
