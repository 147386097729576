import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { useStyles } from "./AllNotifications.styles";
import { CheckCircle, Warning, Error } from "@material-ui/icons";
import GQLService from "../../core/services/GQL.service";
import { useLazyQuery } from "@apollo/client";
import { useUserState } from "../../core/context/containers/User.container";
import {
  CurrentNotificationsResponse,
  NotificationConditionType,
} from "../../types/notification";
import _ from "lodash";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../common/constants/DummyProperty";

export function AllNotifications() {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const [notifications, setNotifications] = useState<
    CurrentNotificationsResponse[]
  >([]);
  const [noOfNotifications, setnoOfNotifications] = useState<Number>(0);

  const [getAllNotifications, { loading, data, error }] = useLazyQuery(
    GQLService.queries.getAllNotifications,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    getAllNotifications({ variables: { email: userEmail } });
  }, [userEmail]);

  useEffect(() => {
    if (data) {
      let responseNotification = _.cloneDeep(data.currentNotifications).sort(
        (a: CurrentNotificationsResponse, b: CurrentNotificationsResponse) =>
          Number(a.notificationReceivedMinutesAgo) -
          Number(b.notificationReceivedMinutesAgo)
      );
      if (userEmail === dummyUserEmail) {
        const demoUserNotification = responseNotification.filter(
          (item: any) =>
            item.property.id === DummyPropertyA.id ||
            item.property.id === DummyPropertyB.id ||
            item.property.id === DummyPropertyC.id ||
            item.property.id === DummyPropertyD.id
        );
        setNotifications(demoUserNotification);
      } else {
        setNotifications(responseNotification);
      }
      setnoOfNotifications(data?.currentNotifications?.length);
    }
  }, [data, userEmail]);

  const getPropertyName = (id: String, name: String) => {
    if (userEmail === dummyUserEmail) {
      if (id === DummyPropertyA.id) {
        return DummyPropertyA.siteName;
      } else if (id === DummyPropertyB.id) {
        return DummyPropertyB.siteName;
      } else if (id === DummyPropertyC.id) {
        return DummyPropertyC.siteName;
      } else {
        return DummyPropertyD.siteName;
      }
    } else {
      return name!;
    }
  };

  return (
    <Grid md={12}>
      <Grid item className={classes.clearAllGrid}>
        {/* Commented for future use} */}
        {/* <Typography className={classes.clearbutton}>Clear all</Typography>
        <SettingsIcon className={classes.settingicon} /> */}
      </Grid>
      {notifications.length > 0
        ? notifications.map((item) => (
            <Grid className={classes.item} container md={12}>
              <Grid className={classes.notificationIconText} md={7}>
                <Grid>
                  {item.conditionType === NotificationConditionType.HEALTHY ? (
                    <CheckCircle className={classes.notificationIconSuccess} />
                  ) : item.conditionType ===
                    NotificationConditionType.MODERATE ? (
                    <Warning className={classes.notificationIconAlert} />
                  ) : (
                    <Error className={classes.notificationIconFault} />
                  )}
                </Grid>
                <Grid className={classes.notificationTitle}>
                  {item.dataType === "DWELL_TIME" ? (
                    <Typography className={classes.notificationText}>
                      Dwell time exceeds {item.maxDwellTime} minutes in{" "}
                      {item.deviceName}, Floor {item.floor.floorNumber} at{" "}
                      {getPropertyName(
                        item.property.id,
                        item.property.siteName
                      )}
                    </Typography>
                  ) : item.dataType === "SCORE" ? (
                    <Typography className={classes.notificationText}>
                      Wellness Index shows {item.conditionType.toLowerCase()}{" "}
                      rating in {item.deviceName}, Floor{" "}
                      {item.floor.floorNumber} of{" "}
                      {getPropertyName(
                        item.property.id,
                        item.property.siteName
                      )}
                    </Typography>
                  ) : (
                    <Typography className={classes.notificationText}>
                      {item.dataType.charAt(0).toUpperCase() +
                        item.dataType.slice(1).toLowerCase()}{" "}
                      shows {item.conditionType.toLowerCase()} rating{" "}
                      {item.deviceName ? "in" : "on"}{" "}
                      {item.deviceName ? item.deviceName + "," : ""} Floor{" "}
                      {item.floor.floorNumber} of{" "}
                      {getPropertyName(
                        item.property.id,
                        item.property.siteName
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid>
                <Typography className={classes.durationText}>
                  {item.notificationReceivedMinutesAgo < 60
                    ? `${item.notificationReceivedMinutesAgo} minutes ago`
                    : Math.floor(
                        Number(item.notificationReceivedMinutesAgo) / 60
                      ) === 1
                    ? `1 hour ago`
                    : `${Math.floor(
                        Number(item.notificationReceivedMinutesAgo) / 60
                      )} hours ago`}
                </Typography>
              </Grid>
              <Grid>
                {/* Commented for future use} */}
                {/* <Typography
              className={classes.clearbutton}
              style={{ paddingRight: "30px" }}
            >
              Clear
            </Typography> */}
              </Grid>
            </Grid>
          ))
        : null}
    </Grid>
  );
}
