import React from "react";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryStack,
  VictoryPortal,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { COLORS } from "../../../styles/colors";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ApolloError } from "@apollo/client";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  noData: {
    color: theme.palette.common.darkBlue,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
  },
}));

const densityColors = {
  HIGH: "rgba(176, 0, 31, 0.2)",
  MEDIUM: "rgba(238, 170, 70, 0.2)",
  LOW: "rgba(79, 158, 83, 0.15)",
};

type DensityData = {
  x: string;
  y: number;
};

interface LinearChartForDensityProps {
  data: DensityData[];
  loading?: boolean;
  error?: ApolloError;
  spaceLoad?: { low: number; high: number; limit?: number };
  showArea?: boolean;
}

export const LinearChartForDensity = ({
  data,
  loading,
  error,
  spaceLoad,
  showArea,
}: LinearChartForDensityProps) => {
  const classes = useStyles();
  const maxValue = data?.reduce((acc: number, curr: DensityData) => {
    curr.y > acc && (acc = curr.y);
    return acc;
  }, 0);

  const { formatMessage } = useIntl();
  const Loading = () => (
    <Grid
      container
      alignItems="center"
      justify="center"
      style={{ height: 164 }}
    >
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="indeterminate"
          size={80}
          style={{ color: COLORS.solidGray }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {formatMessage({
              id: "LinearCharts.Density.Loading",
              defaultMessage: "LOADING...",
              description: "Linear chart for density loading message",
            })}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );

  const NoData = () => (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: 164 }}
    >
      <Typography className={classes.noData}>
        {" "}
        {formatMessage({
          id: "LinearCharts.Density.NoData",
          defaultMessage: "No data",
          description: "Linear chart for density no data message",
        })}
      </Typography>
    </Grid>
  );
  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <NoData />
      ) : (
        <VictoryChart
          padding={{
            top: 40,
            bottom: 50,
            left: maxValue > 999 ? 57 : 40,
            right: 15,
          }}
          height={200}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => `${datum.y}`}
              voronoiBlacklist={["AreaLow", "AreaMedium", "AreaHigh"]}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={1}
                  constrainToVisibleArea
                  flyoutStyle={{
                    stroke: "#B5E9EF",
                    fill: "#E6F8FA",
                  }}
                />
              }
            />
          }
        >
          <VictoryAxis
            crossAxis={true}
            style={{
              axis: { stroke: "none" },
              tickLabels: {
                fontFamily: "Poppins",
                fontWeight: 600,
                color: COLORS.darkBlue,
                fontSize: 12,
              },
            }}
            tickValues={data?.map((d) => d.x)}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    if (tArray.length > 2)
                      return (
                        tArray[0] +
                        "\n" +
                        tArray[1] +
                        "\n" +
                        tArray[2]?.charAt(0)
                      );
                    else return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  if (tArray.length > 2)
                    return (
                      tArray[0] + "\n" + tArray[1] + "\n" + tArray[2]?.charAt(0)
                    );
                  else return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
          <VictoryAxis
            dependentAxis={true}
            style={{
              axis: { stroke: "none" },
              tickLabels: {
                fontFamily: "Poppins",
                fontWeight: 500,
                color: COLORS.darkBlue,
                fontSize: 12,
              },
            }}
            domain={[
              0,
              maxValue > spaceLoad?.high! ? maxValue : spaceLoad?.high!,
            ]}
            tickFormat={(t) => t.toFixed()}
          />
          {showArea === false ? null : (
            <VictoryStack
              name="Area"
              style={{
                data: { stroke: "white", strokeWidth: 3 },
              }}
            >
              <VictoryArea
                name="AreaLow"
                style={{ data: { fill: densityColors.LOW } }}
                data={data?.map((value) => {
                  return { x: value.x, y: spaceLoad?.low! };
                })}
              />
              <VictoryArea
                name="AreaMedium"
                style={{ data: { fill: densityColors.MEDIUM } }}
                data={data?.map((value) => {
                  return {
                    x: value.x,
                    y: spaceLoad?.high! - spaceLoad?.low!,
                  };
                })}
              />
              <VictoryArea
                name="AreaHigh"
                style={{ data: { fill: densityColors.HIGH } }}
                data={data?.map((value) => {
                  return {
                    x: value.x,
                    y: spaceLoad?.limit!
                      ? spaceLoad?.limit! - spaceLoad?.high!
                      : maxValue + 1 > spaceLoad?.high!
                      ? maxValue + 1 - spaceLoad?.high!
                      : spaceLoad?.high!,
                  };
                })}
              />
            </VictoryStack>
          )}
          <VictoryPortal>
            <VictoryLine
              name="Line"
              data={data}
              style={{
                data: {
                  stroke: COLORS.blue,
                  strokeWidth: 2,
                },
              }}
            />
          </VictoryPortal>
        </VictoryChart>
      )}
    </>
  );
};
