import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { useIntl } from "react-intl";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";

import clsx from "clsx";
import { Property, Role } from "../../../../../../types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../common/constants/Routing";

import { useAuth } from "../../../../../../core/context/containers/Auth.container";

import {
  DEFAULT_SENSOR_RANGES,
  SensorGrid,
} from "../../../components/SensorRange/SensorRange";
import { mapSensorConfigurationsResponse } from "../../../../../../common/utils/Property.utils";
import { PageHeader } from "../../../../../../common/components";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import { useQuery, useLazyQuery } from "@apollo/client";
import GQLService from "../../../../../../core/services/GQL.service";
import { useSensorsState } from "../../../../../../core/context/containers/Sensors.contrainer";
import { toCelcius } from "../../../../../../common/utils/General.utils";
import _ from "lodash";
import { useUserState } from "../../../../../../core/context/containers/User.container";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../../../../../common/constants/DummyProperty";

const useStyles = makeStyles((theme) => ({
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  blueLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.funBlue,
    marginLeft: "5px",
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
  },
  button: {
    backgroundColor: theme.palette.common.matisse,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: 115,
    height: 42,
  },
  indicatorWrapper: {
    display: "flex",
  },
  valueLabel: {
    borderBottom: "1px solid #D2D2D2",
    paddingRight: "20px",
    width: "fit-content",
    marginLeft: "5px",
    fontSize: "14px",
  },
  columnWrapper: {
    flexDirection: "column",
    display: "flex",
    marginBottom: "25px",
    minHeight: "100px",
    justifyContent: "space-between",
  },
  healthy: {
    backgroundColor: "rgba(79, 158, 83, 0.15)",
    borderRadius: "4px",
    padding: "4px 16px",
    marginRight: "16px",
  },
  green: {
    backgroundColor: "#4F9E53",
    width: "5px",
    height: "30px",
    borderRadius: "4px",
    display: "inline-block",
  },
  moderate: {
    backgroundColor: "rgba(238, 170, 70, 0.2)",
    borderRadius: "4px",
    padding: "4px 16px",
    marginRight: "16px",
  },
  orange: {
    backgroundColor: "#EEAA46",
    width: "5px",
    height: "30px",
    borderRadius: "4px",
    display: "inline-block",
  },
  poor: {
    backgroundColor: "rgba(176, 0, 31, 0.2)",
    borderRadius: "4px",
    padding: "4px 16px",
    marginRight: "16px",
  },
  red: {
    backgroundColor: "#B0001F",
    width: "5px",
    height: "30px",
    borderRadius: "4px",
    display: "inline-block",
  },
  notCovered: {
    backgroundColor: "#81D3E0",
    width: "5px",
    height: "30px",
    borderRadius: "4px",
    display: "inline-block",
    marginRight: "8px",
  },
  airSensorContainer: {
    border: "1px solid #E0EBF1",
    padding: "16px 20px",
    alignItems: "center",
  },
  verticalSpaing: {
    margin: "4px 0",
  },
  marginTop: {
    marginTop: "20px",
  },
}));

const sensorIndicators = [
  {
    classNameBg: "green",
    className: "healthy",
    label: "Healthy",
  },
  {
    classNameBg: "orange",
    className: "moderate",
    label: "Moderate",
  },
  {
    classNameBg: "red",
    className: "poor",
    label: "Poor",
  },
];

export function SpaceLoadSettings() {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const { selectedProperty, setSelectedProperty } = usePropertiesState();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const auth = useAuth();
  const { tempUnit } = useSensorsState();

  const [getPropertyById, { data }] = useLazyQuery(
    GQLService.queries.propertyById,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !data) {
      getPropertyById({ variables: { propertyId: selectedProperty?.id! } });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && data) {
      if (data?.propertyById) {
        if (userEmail === dummyUserEmail) {
          let clonedData = _.cloneDeep(data?.propertyById);
          if (clonedData?.id === DummyPropertyA.id) {
            const propA = {
              ...clonedData,
              ownerCompanyName: DummyPropertyA.ownerCompanyName,
              siteName: DummyPropertyA.siteName,
              siteServiceAddress: DummyPropertyA.siteServiceAddress,
            };
            setSelectedProperty(propA);
          } else if (clonedData?.id === DummyPropertyB.id) {
            const propB = {
              ...clonedData,
              ownerCompanyName: DummyPropertyB.ownerCompanyName,
              siteName: DummyPropertyB.siteName,
              siteServiceAddress: DummyPropertyB.siteServiceAddress,
            };
            setSelectedProperty(propB);
          } else if (clonedData?.id === DummyPropertyC.id) {
            const propC = {
              ...clonedData,
              ownerCompanyName: DummyPropertyC.ownerCompanyName,
              siteName: DummyPropertyC.siteName,
              siteServiceAddress: DummyPropertyC.siteServiceAddress,
            };
            setSelectedProperty(propC);
          } else if (clonedData?.id === DummyPropertyD.id) {
            const propD = {
              ...clonedData,
              ownerCompanyName: DummyPropertyD.ownerCompanyName,
              siteName: DummyPropertyD.siteName,
              siteServiceAddress: DummyPropertyD.siteServiceAddress,
            };
            setSelectedProperty(propD);
          } else {
            setSelectedProperty(clonedData);
          }
        } else {
          setSelectedProperty(data?.propertyById);
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [data, setSelectedProperty, userEmail]);

  const handleEditClick = () => {
    history.push(
      ROUTES.EDIT_SENSOR_THRESHOLD.replace(":uid", selectedProperty?.id!)
    );
  };

  const createPropertyButton = useCallback(() => {
    return (
      <Button className={classes.button} onClick={handleEditClick}>
        Edit
      </Button>
    );
  }, []);

  const sensorData = useCallback(() => {
    const { sensorConfigurations, spaceLoadMediumMax, spaceLoadMediumMin } =
      selectedProperty!;

    const sensorRangeData =
      mapSensorConfigurationsResponse(sensorConfigurations);
    sensorRangeData.OCCUPANCY = [0, spaceLoadMediumMin, spaceLoadMediumMax, 50];
    return sensorRangeData;
  }, [selectedProperty]);

  const sensorConfigurationData = sensorData();
  const temperatureUnit = useCallback(() => {
    const findUnit = selectedProperty?.sensorConfigurations?.filter(
      (el) => el.attribute === "TEMPERATURE"
    );
    return findUnit![0].isCelsius;
  }, [selectedProperty]);

  return (
    <Grid container>
      <Grid container>
        <PageHeader
          title={formatMessage({
            id: "sensors.main.header",
            defaultMessage: "Sensors Thresholds",
            description: "Sensors Thresholds header",
          })}
        />
        <Grid container item xs={12}>
          <Typography className={classes.blackLabel}>
            {formatMessage({
              id: "propertySpaceLoad.spaceLoad",
              defaultMessage: "Indicators",
              description: "Sensor threshold indicators",
            })}
          </Typography>
          {sensorIndicators.map((item, index) => (
            <Grid item key={index} className={classes.indicatorWrapper}>
              <span
                className={classes[item.classNameBg as keyof typeof classes]}
              ></span>
              <div className={classes[item.className as keyof typeof classes]}>
                <Typography className={classes.blackText}>
                  {formatMessage({
                    id: `propertySpaceLoad.spaceLoad.${item.className}`,
                    defaultMessage: item.label,
                    description: `Sensor ${item.label} label`,
                  })}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.occupancy",
            defaultMessage: "Occupancy",
            description: "occupancy indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.OCCUPANCY.min}
          max={DEFAULT_SENSOR_RANGES.OCCUPANCY.max}
          hideMinHandle={true}
          range={sensorConfigurationData["OCCUPANCY"]}
          indicators={DEFAULT_SENSOR_RANGES.OCCUPANCY.indicators}
          disabled
        />
        <Grid container item xs={12} className={classes.marginTop}>
          <Typography className={classes.blackLabel}>
            {formatMessage({
              id: "propertySpaceLoad.airSensorThresholds",
              defaultMessage: "Air sensors thresholds",
              description: "Air sensors thresholds indicators",
            })}
          </Typography>
        </Grid>

        {/* TEMPERATURE */}
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.temperature",
            defaultMessage: `Temperature ${
              temperatureUnit() ? "(°C)" : "(°F)"
            }`,
            description: "temperature indicators",
          })}
          min={0}
          max={temperatureUnit() ? 93 : DEFAULT_SENSOR_RANGES.TEMPERATURE.max}
          range={sensorConfigurationData["TEMPERATURE"]}
          indicators={DEFAULT_SENSOR_RANGES.TEMPERATURE.indicators}
          disabled
        />

        {/* HUMIDITY */}
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.humidity",
            defaultMessage: "Humidity (RH%)",
            description: "humidity indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.HUMIDITY.min}
          max={DEFAULT_SENSOR_RANGES.HUMIDITY.max}
          range={sensorConfigurationData["HUMIDITY"]}
          indicators={DEFAULT_SENSOR_RANGES.HUMIDITY.indicators}
          disabled
        />

        {/* CO2 */}
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.co2",
            defaultMessage: "CO2 (ppm)",
            description: "co2 indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.CO2.min}
          max={DEFAULT_SENSOR_RANGES.CO2.max}
          range={sensorConfigurationData["CO2"]}
          indicators={DEFAULT_SENSOR_RANGES.CO2.indicators}
          disabled
        />

        {/* TVOCs */}
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.tvocs",
            defaultMessage: "VOC (ppb)",
            description: "voc indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.TVOCS.min}
          max={DEFAULT_SENSOR_RANGES.TVOCS.max}
          range={sensorConfigurationData["TVOCS"]}
          indicators={DEFAULT_SENSOR_RANGES.TVOCS.indicators}
          disabled
        />
        {/* PM 2.5 */}
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.pm25",
            defaultMessage: "PM 2.5 (µ/m3)",
            description: "pm2.5 indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.PM.min}
          max={DEFAULT_SENSOR_RANGES.PM.max}
          range={sensorConfigurationData["PM"]}
          indicators={DEFAULT_SENSOR_RANGES.PM.indicators}
          disabled
        />

        {/* LIGHT */}
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.light",
            defaultMessage: "Light (lux)",
            description: "light indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.LIGHT.min}
          max={DEFAULT_SENSOR_RANGES.LIGHT.max}
          range={sensorConfigurationData["LIGHT"]}
          indicators={DEFAULT_SENSOR_RANGES.LIGHT.indicators}
          disabled
        />
        {/* NOISE */}
        <SensorGrid
          label={formatMessage({
            id: "propertySpaceLoad.noise",
            defaultMessage: "Noise  (dbA)",
            description: "noise indicators",
          })}
          min={DEFAULT_SENSOR_RANGES.NOISE.min}
          max={DEFAULT_SENSOR_RANGES.NOISE.max}
          range={sensorConfigurationData["NOISE"]}
          indicators={DEFAULT_SENSOR_RANGES.NOISE.indicators}
          disabled
        />
        <Grid container item xs={12} className={classes.marginTop}>
          <Typography className={classes.blackLabel}>
            {formatMessage({
              id: "propertySpaceLoad.note",
              defaultMessage: "Note: ",
              description: "note",
            })}
          </Typography>
          <span className={classes.notCovered}></span>
          <Typography className={classes.blackLabel}>
            {formatMessage({
              id: "propertySpaceLoad.noteDesc",
              defaultMessage:
                " Any reading not covered in the defined range will be shown in this color.",
              description: "note desc",
            })}
          </Typography>
        </Grid>
        <Grid className={clsx(classes.columnWrapper, classes.marginTop)}>
          <Typography className={classes.blackText}>
            {formatMessage({
              id: "networkSettings.dataTypeHeader",
              defaultMessage: "Data Type",
              description: "Data Type header",
            })}
          </Typography>
          <Typography className={classes.blueLabel}>
            {formatMessage({
              id: "Ntopng.databaseName",
              defaultMessage: "Ntopng database name",
              description: "Ntopng database name",
            })}
          </Typography>
          <Typography className={classes.valueLabel}>
            {formatMessage({
              id: "Ntopng.databaseNameValue",
              defaultMessage: String(
                selectedProperty?.ntopngDatabaseName || "No database added"
              ),
              description: "Ntopng database name",
            })}
          </Typography>
        </Grid>
      </Grid>
      {auth?.canSee(
        auth?.user?.role?.toUpperCase() as Role,
        "properties:edit",
        createPropertyButton()
      )}
    </Grid>
  );
}
