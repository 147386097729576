import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
  Grid,
  Typography,
  Button,
  ClickAwayListener,
  Popper,
  Paper,
  Tooltip,
  MenuItem,
  Select,
} from "@material-ui/core";
import { ReactComponent as LineChart } from "../../../../common/assets/images/line-chart 2.svg";
import {
  ImageSensorWithOccupancy,
  Floor,
  ZoneWithOccupancy,
  ImageDensityData,
  DashboardDeviceType,
} from "../../../../types";
import { useStyles } from "./CameraListItem";
import clsx from "clsx";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { colorSpaceLoad } from "../../../../common/utils/Canvas.utils";
import { LinearChartForDensity } from "../../../../common/components/Charts/LinearCharts/LinearChartForDensity";
import moment from "moment";
import { BarChartForDensity } from "../../../../common/components/Charts/BarCharts/BarChartForDensity";
import CancelIcon from "@material-ui/icons/Cancel";
import { FilterCenterFocus } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { ReactComponent as Pin } from "../../../../common/assets/images/pin.svg";
import { MyPinsModal } from "../../MyPins/MyPinsModal";
import { useFiltersState } from "../../../../core/context/containers/Filters.container";
import { UnpinModal } from "../../MyPins/UnpinModal";
import { ReactComponent as PinBlack } from "../../../../common/assets/images/pin_black.svg";
import { useUserState } from "../../../../core/context/containers/User.container";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import Loader from "react-loader-spinner";

type ZonePopupProps = {
  dataWithSpaceLoad: ImageSensorWithOccupancy;
  floor: Floor;
  timeFrom: any;
  timeTo: any;
  timeRange: any;
  clickZone: (camera: ImageSensorWithOccupancy, zoneId: string) => void;
  handleIsPinned?: (el: any) => void;
};

type classNameIndicator =
  | "highIndicator"
  | "lowIndicator"
  | "mediumIndicator"
  | "notConfiguredIndicator"
  | "offlineIndicator";

export function ZonePopup({
  dataWithSpaceLoad,
  floor,
  clickZone,
  timeFrom,
  timeTo,
  timeRange,
  handleIsPinned,
}: ZonePopupProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();

  const [densityZoneData, setDensityZoneData] = useState<ImageDensityData>();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [className, setClassName] = useState<classNameIndicator | null>(null);
  const [zoneDetails, setZoneDetails] = useState<ZoneWithOccupancy>();
  const { selectedFloor, selectedTime, selectedSpace } = useFiltersState();
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [toggleUnpinModal, setToggleUnpinModal] = useState<boolean>(false);
  const [swipeIndex, setSwipeIndex] = useState<any>(0);
  const { userEmail } = useUserState();
  const [selectedDataType, setSelectedDataType] = useState<any>("");
  const [zoneDataTypeDropDownList, setZoneDataTypeDropDownList] = useState<any>(
    []
  );
  const id = open ? "simple-popper" : undefined;

  const toggleZoneActive = useCallback(
    (event: React.MouseEvent<HTMLElement>, zone: ZoneWithOccupancy) => {
      event.preventDefault();
      event.stopPropagation();
      clickZone(dataWithSpaceLoad, zone?.id);
    },
    [clickZone, dataWithSpaceLoad]
  );

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>, zone: ZoneWithOccupancy) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setOpen(true);
      setZoneDetails(zone);
      clickZone(dataWithSpaceLoad, zone?.id);
      event.stopPropagation();
    },
    [anchorEl, clickZone, dataWithSpaceLoad]
  );

  const handleClose = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(null);
    setOpen(false);
    event.stopPropagation();
  }, []);

  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  const [
    getOccupancyDuringTimeForCameraZone,
    { loading, error, data: responseData },
  ] = useLazyQuery(GQLService.queries.occupancyDuringTimeForCameraZone, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (zoneDetails) {
      getOccupancyDuringTimeForCameraZone({
        variables: {
          request: {
            zoneId: zoneDetails?.id,
            floorId: floor.id,
            cameraId: dataWithSpaceLoad.id,
            from: timeFrom,
            to: timeTo,
            timeRangeType: timeRange,
            userEmail: userEmail,
          },
        },
      });
    }
  }, [
    floor,
    dataWithSpaceLoad.id,
    getOccupancyDuringTimeForCameraZone,
    timeFrom,
    timeRange,
    timeTo,
    zoneDetails,
    userEmail,
  ]);

  useEffect(() => {
    setDensityZoneData(responseData?.occupancyDuringTimeForCameraZone);
  }, [responseData]);

  useEffect(() => {
    if (!loading) {
      setClassName(
        colorSpaceLoad(zoneDetails?.spaceLoad!)?.className as classNameIndicator
      );
    }
  }, [loading, zoneDetails]);

  useEffect(() => {
    if (densityZoneData) {
      setSelectedDataType("Dwell_Time");
    }
  }, [densityZoneData, zoneDetails]);

  const handleDataTypeChange = useCallback((e) => {
    setSelectedDataType(e.target.value);
  }, []);

  useEffect(() => {
    if (densityZoneData) {
      if (densityZoneData?.dataType === "BOTH") {
        setZoneDataTypeDropDownList([
          { value: "Census", label: "Census" },
          { value: "Census_Accumulation", label: "Census Accumulation" },
          { value: "Dwell_Time", label: "Dwell Time" },
        ]);
      } else if (densityZoneData?.dataType === "CENSUS") {
        setZoneDataTypeDropDownList([
          { value: "Census", label: "Census" },
          { value: "Dwell_Time", label: "Dwell Time" },
        ]);
      } else {
        setZoneDataTypeDropDownList([
          { value: "Census_Accumulation", label: "Census Accumulation" },
          { value: "Dwell_Time", label: "Dwell Time" },
        ]);
      }
    }
  }, [densityZoneData, zoneDetails]);

  const dwellTimeCalculation = () => {
    if (densityZoneData?.dwellTime) {
      if (densityZoneData?.dwellTime >= 3600) {
        const hours =
          Math.floor(densityZoneData?.dwellTime! / 3600) < 10
            ? `0${Math.floor(densityZoneData?.dwellTime! / 3600)}`
            : Math.floor(densityZoneData?.dwellTime! / 3600);
        const minutes =
          parseInt(((densityZoneData?.dwellTime! - 3600) / 60).toFixed(0)) < 10
            ? `0${((densityZoneData?.dwellTime! - 3600) / 60).toFixed(0)}`
            : ((densityZoneData?.dwellTime! - 3600) / 60).toFixed(0);
        return parseInt(minutes) > 0
          ? `${hours} hr ${minutes} min`
          : `${hours} hr`;
      } else if (densityZoneData?.dwellTime >= 60) {
        const minutes =
          Math.floor(densityZoneData?.dwellTime! / 60) < 10
            ? `0${Math.floor(densityZoneData?.dwellTime! / 60)}`
            : Math.floor(densityZoneData?.dwellTime! / 60);
        const seconds =
          parseInt((densityZoneData?.dwellTime! - 60).toFixed(0)) < 10
            ? `0${(densityZoneData?.dwellTime! - 60).toFixed(0)}`
            : (densityZoneData?.dwellTime! - 60).toFixed(0);
        return parseInt(seconds) > 0
          ? `${minutes} min ${seconds} sec`
          : `${minutes} min`;
      } else {
        return `${densityZoneData?.dwellTime.toFixed(0)} sec`;
      }
    }
    return "";
  };

  return (
    <div>
      {dataWithSpaceLoad.zones.map((zone: any) => (
        <Grid
          container
          item
          sm={12}
          key={zone.id}
          onClick={(event) => toggleZoneActive(event, zone)}
          className={clsx({
            [classes.zoneGridStyle]: true,
            [classes.selectedZoneGridStyle]: zone.isSelected,
          })}
        >
          <Grid style={{ flexBasis: "100%" }}>
            {zone.isPinned ? (
              <Grid className={classes.pinIconGrid}>
                <PinBlack
                  style={{
                    transform: "rotate(45deg)",
                    opacity: "0.6",
                    height: "16px",
                  }}
                />
              </Grid>
            ) : null}
            <Grid style={{ display: "flex" }}>
              <Grid item sm={2}>
                <Tooltip enterTouchDelay={0} placement="top" title="Zone">
                  <FilterCenterFocus className={classes.iconStyle} />
                </Tooltip>
              </Grid>
              <Grid item sm={8}>
                <Typography className={classes.zoneNameText}>
                  {zone.name ? zone.name : zone.zone}
                </Typography>
              </Grid>
              <Grid
                sm={2}
                item={true}
                aria-describedby={id}
                className={classes.showStatisticsContainer}
              >
                <Button
                  onClick={(event) => handleOpen(event, zone)}
                  color="primary"
                >
                  <LineChart width={11} id="open" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Popper
        id={id}
        open={open}
        anchorEl={null}
        className={classes.popper}
        placement="bottom-end"
        popperOptions={{
          modifiers: {
            flip: { enabled: true },
            offset: {
              enabled: true,
            },
          },
        }}
      >
        {!loading ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper classes={{ root: classes.paper }}>
              <CancelIcon
                color="primary"
                onClick={(e) => handleClose(e)}
                classes={{ root: classes.closeIcon }}
              />
              <Grid className={classes.chartsContainer}>
                <Grid className={classes.popperContainer}>
                  <Typography className={classes.popperName}>
                    {selectedDataType === "Census" ||
                    selectedDataType === "Census_Accumulation"
                      ? "Density"
                      : "Dwell Time"}
                  </Typography>
                  <Grid style={{ display: "flex" }}>
                    <Typography className={classes.statusLabel}>
                      Status:
                    </Typography>
                    <Typography className={classes.status}>
                      {zoneDetails?.spaceLoad}
                    </Typography>
                    {className && (
                      <span
                        className={clsx(
                          classes[className],
                          classes.popperIndicator
                        )}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid className={classes.dropDownContainer}>
                  <Typography
                    className={classes.dropDownTitle}
                    style={{ marginTop: "5px" }}
                  >
                    Data Type
                  </Typography>
                  <Select
                    classes={{
                      root: classes.select,
                      select: classes.blackText,
                      icon: classes.icon,
                    }}
                    id="menu-ZoneDataTypeFilter"
                    name="ZoneDataTypeFilter"
                    value={selectedDataType}
                    disableUnderline={true}
                    onChange={handleDataTypeChange}
                    IconComponent={ExpandMoreSharpIcon}
                    MenuProps={{
                      classes: {
                        paper: classes.newMenu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      disablePortal: true,
                    }}
                  >
                    {zoneDataTypeDropDownList?.map((v: any) => (
                      <MenuItem
                        key={v.value}
                        value={v.value}
                        classes={{
                          root: classes.option,
                          selected: classes.selected,
                        }}
                      >
                        {v.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  className={classes.currentStatusIndicatorContainer}
                  style={{ paddingBottom: "0px" }}
                >
                  <Grid>
                    <Typography className={classes.chartsTitle}>
                      Density during time
                    </Typography>
                  </Grid>
                  {!loading && selectedDataType === "Dwell_Time" ? (
                    <Grid>
                      <Typography
                        className={classes.chartsTitle}
                        style={{ marginBottom: "0px" }}
                      >
                        Avg:{" "}
                        {densityZoneData?.dwellTime
                          ? dwellTimeCalculation()
                          : "N/A"}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
                {selectedDataType === "Census" ? (
                  <>
                    <LinearChartForDensity
                      data={
                        densityZoneData?.censusOccupacyTimeline?.map(
                          (d, i: number) => {
                            return {
                              x:
                                timeRange === "DAY"
                                  ? moment
                                      .tz(
                                        d.from,
                                        selectedProperty?.timeZoneName!
                                      )
                                      .format("DD MMM ddd")
                                  : moment
                                      .tz(
                                        d.from,
                                        selectedProperty?.timeZoneName!
                                      )
                                      .format("LT"),
                              y: d.connectedPeopleCount,
                            };
                          }
                        )!
                      }
                      spaceLoad={{
                        low: densityZoneData?.censusOccupancyMediumMinThreShold!,
                        high: densityZoneData?.censusOccupancyMediumMaxThreShold!,
                        limit: densityZoneData?.censusOccupancyMaxThreShold!,
                      }}
                      loading={loading}
                      error={error}
                    />
                    <Typography className={classes.chartsTitle}>
                      Threshold Distribution
                    </Typography>
                    <BarChartForDensity
                      data={[
                        {
                          x: "Low",
                          y: densityZoneData?.lowDensityTimeForCensus!,

                          fill: "rgba(79, 158, 83, 0.15)",
                        },
                        {
                          x: "Medium",
                          y: densityZoneData?.mediumDensityTimeForCensus!,

                          fill: "rgba(238, 170, 70, 0.2)",
                        },
                        {
                          x: "High",
                          y: densityZoneData?.highDensityTimeForCensus!,
                          fill: "rgba(176, 0, 31, 0.2)",
                        },
                      ]}
                      loading={loading}
                      error={error}
                    />
                  </>
                ) : (
                  <></>
                )}
                {selectedDataType === "Census_Accumulation" ? (
                  <>
                    <LinearChartForDensity
                      data={
                        densityZoneData?.censusAccOccupacyTimeline?.map(
                          (d, i: number) => {
                            return {
                              x:
                                timeRange === "DAY"
                                  ? moment
                                      .tz(
                                        d.from,
                                        selectedProperty?.timeZoneName!
                                      )
                                      .format("DD MMM ddd")
                                  : moment
                                      .tz(
                                        d.from,
                                        selectedProperty?.timeZoneName!
                                      )
                                      .format("LT"),
                              y: d.connectedPeopleCount,
                            };
                          }
                        )!
                      }
                      spaceLoad={{
                        low: densityZoneData?.censusAccOccupancyMediumMinThreShold!,
                        high: densityZoneData?.censusAccOccupancyMediumMaxThreShold!,
                        limit: densityZoneData?.censusAccOccupancyMaxThreShold!,
                      }}
                      loading={loading}
                      error={error}
                    />
                    <Typography className={classes.chartsTitle}>
                      Threshold Distribution
                    </Typography>
                    <BarChartForDensity
                      data={[
                        {
                          x: "Low",
                          y: densityZoneData?.lowDensityTimeForCensusAcc!,

                          fill: "rgba(79, 158, 83, 0.15)",
                        },
                        {
                          x: "Medium",
                          y: densityZoneData?.mediumDensityTimeForCensusAcc!,

                          fill: "rgba(238, 170, 70, 0.2)",
                        },
                        {
                          x: "High",
                          y: densityZoneData?.highDensityTimeForCensusAcc!,
                          fill: "rgba(176, 0, 31, 0.2)",
                        },
                      ]}
                      loading={loading}
                      error={error}
                    />
                  </>
                ) : (
                  <></>
                )}
                {selectedDataType === "Dwell_Time" ? (
                  <>
                    <Grid>
                      <Typography className={classes.dwellTimeLabel}>
                        Secs
                      </Typography>
                    </Grid>
                    <LinearChartForDensity
                      data={
                        densityZoneData?.dwellTimeTimeLine?.map(
                          (d, i: number) => {
                            return {
                              x:
                                timeRange === "DAY"
                                  ? moment
                                      .tz(
                                        d.from,
                                        selectedProperty?.timeZoneName!
                                      )
                                      .format("DD MMM ddd")
                                  : moment
                                      .tz(
                                        d.from,
                                        selectedProperty?.timeZoneName!
                                      )
                                      .format("LT"),
                              y: d.connectedPeopleCount,
                            };
                          }
                        )!
                      }
                      spaceLoad={{
                        low: densityZoneData?.dwellTimeMinThreshold!,
                        high: densityZoneData?.dwellTimeMediumThreshold!,
                        limit: densityZoneData?.dwellTimeMaxThreshold!,
                      }}
                      loading={loading}
                      error={error}
                    />
                    <Typography className={classes.chartsTitle}>
                      Threshold Distribution
                    </Typography>
                    <BarChartForDensity
                      data={[
                        {
                          x: "Low",
                          y: densityZoneData?.lowDensityTimeForDwellTimeAcc!,

                          fill: "rgba(79, 158, 83, 0.15)",
                        },
                        {
                          x: "Medium",
                          y: densityZoneData?.mediumDensityTimeForDwellTimeAcc!,

                          fill: "rgba(238, 170, 70, 0.2)",
                        },
                        {
                          x: "High",
                          y: densityZoneData?.highDensityTimeForDwellTimeAcc!,
                          fill: "rgba(176, 0, 31, 0.2)",
                        },
                      ]}
                      loading={loading}
                      error={error}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              {!loading &&
                (densityZoneData?.isPinned! ? (
                  <Grid
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      marginBottom: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <Button
                      className={classes.pinItButton}
                      onClick={() => setToggleUnpinModal(true)}
                    >
                      <div style={{ marginLeft: "8px" }}></div>
                      Unpin It
                    </Button>
                  </Grid>
                ) : (
                  <Grid
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      marginBottom: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <Button
                      className={classes.pinItButton}
                      onClick={() => setToggleModal(true)}
                    >
                      <Pin />
                      <div style={{ marginLeft: "8px" }}></div>
                      Pin It
                    </Button>
                  </Grid>
                ))}
              {toggleModal && (
                <MyPinsModal
                  setToggleModal={setToggleModal}
                  selectedTime={selectedTime}
                  selectedSpace={selectedSpace}
                  selectedFloor={selectedFloor}
                  deviceType={DashboardDeviceType.ZONE}
                  deviceId={zoneDetails?.id}
                  timeRange={timeRange}
                  handleIsPinned={handleIsPinned}
                  // subType={swipeIndex}
                  subType={selectedDataType}
                  cameraId={dataWithSpaceLoad.id}
                />
              )}
              <Grid style={{ left: "17%", position: "absolute", top: "20%" }}>
                {toggleUnpinModal && (
                  <UnpinModal
                    setToggleModal={setToggleUnpinModal}
                    deviceType={DashboardDeviceType.ZONE}
                    deviceId={zoneDetails?.id}
                    handleIsPinned={handleIsPinned}
                    Type="FloorOverview"
                  />
                )}
              </Grid>
            </Paper>
          </ClickAwayListener>
        ) : (
          <Grid className={classes.loaderGrid}>
            <Loader type="Oval" color="#D2D2D2" height={100} width={100} />
          </Grid>
        )}
      </Popper>
    </div>
  );
}
