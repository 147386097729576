import React, { useCallback, useState } from "react";
import { useStyles } from "./SideList";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Button, Grid, Input, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useFloorPlanEditorState } from "../../../../../core/context/containers/FloorPlanEditor/FloorPlanEditor.container";
import { AccessPoint } from "../../../../../types";
import GQLService from "../../../../../core/services/GQL.service";
import { useMutation } from "@apollo/client";

export function AccessPointListItem({ item }: { item: AccessPoint }) {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { floor } = useFloorPlanEditorState();

  const [editAccessPoint] = useMutation(GQLService.mutations.editAccessPoint, {
    refetchQueries: () => [
      {
        query: GQLService.queries.floorAccessPoints,
        variables: { floorId: floor?.id },
      },
    ],
    awaitRefetchQueries: true,
  });
  const [deleteAccessPointFromFloorPlan] = useMutation(
    GQLService.mutations.deleteAccessPointFromFloorPlan,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.floorAccessPoints,
          variables: { floorId: floor?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);

  const [apData, setApData] = useState({
    baseMacAddress: item.baseMacAddress,
    name: item.name,
  });

  const handleInputChange = useCallback(
    (e) => {
      setApData({ ...apData, [e.target.name]: e.target.value });
    },
    [apData]
  );

  const handleToggleEditMode = useCallback(() => {
    setIsInEditMode(!isInEditMode);
  }, [isInEditMode]);

  const handleSave = useCallback(() => {
    editAccessPoint({
      variables: {
        id: item.id,
        floorId: floor?.id,
        ...apData,
      },
    });
  }, [item.id, apData]); //eslint-disable-line

  return (
    <>
      <Grid
        container={true}
        className={clsx(
          isInEditMode ? classes.listItemEditMode : classes.listItem
        )}
        onClick={handleToggleEditMode}
      >
        <Grid container={true} justify={"space-between"} alignItems="center">
          <Grid
            container={true}
            item={true}
            md={11}
            sm={11}
            alignItems="center"
            justify={"flex-start"}
          >
            <Grid
              className={clsx(
                classes.statusIndicator,
                classes.innerCircle,
                item.active ? classes.innerCircleGreen : classes.innerCircleRed
              )}
              container={true}
              alignItems="center"
              justify="center"
            ></Grid>
            <Typography className={classes.blackText}>
              <b>{item.name ? item.name : item.baseMacAddress.toLowerCase()}</b>
            </Typography>
          </Grid>
          <Grid item={true} md={1} sm={1}>
            {isInEditMode ? (
              <ExpandLess
                id={`expandLess-${item.id}`}
                className={classes.deleteIcon}
              />
            ) : (
              <ExpandMore
                id={`expandMore-${item.id}`}
                className={classes.deleteIcon}
              />
            )}
          </Grid>
        </Grid>
        <Grid container={true}>
          <Grid container={true}>
            <Typography className={classes.blackDescription}>
              {`MAC: ${item.baseMacAddress.toLowerCase()}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {isInEditMode ? (
        <Grid className={classes.editModeRoot} container={true}>
          <Typography className={classes.blackDescription}>
            {formatMessage({
              id:
                "analytics.floorPlanEditor.accessPointsList.editMode.label.baseMacAddress",
              defaultMessage: "Mac Address",
              description:
                "analytics floor plan editor acces points edit mode mac address field",
            })}
          </Typography>
          <Input
            className={classes.input}
            value={apData.baseMacAddress.toLowerCase()}
            name="baseMacAddress"
            placeholder="Access Point"
            disableUnderline={true}
            onChange={handleInputChange}
          />
          <Typography className={classes.blackDescription}>
            {formatMessage({
              id:
                "analytics.floorPlanEditor.accessPointsList.editMode.label.name",
              defaultMessage: "Name",
              description:
                "analytics floor plan editor acces points edit mode name field",
            })}
          </Typography>
          <Input
            className={classes.input}
            value={apData.name}
            name="name"
            placeholder="Access Point Name"
            disableUnderline={true}
            onChange={handleInputChange}
          />
          <Button
            id="saveBtn"
            className={clsx(classes.saveButton)}
            disabled={!apData.baseMacAddress.length}
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      ) : null}
    </>
  );
}
