import React, { useCallback, useState, useMemo, useEffect } from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Videocam } from "@material-ui/icons";
import moment from "moment";
import {
  ImagesSensor,
  Floor,
  ImageSensorWithOccupancy,
  ZoneWithOccupancy,
  TripWireWithOccupancy,
} from "../../../../types";
import { ZonePopup } from "./ZonePopup";
import { TripWirePopup } from "./TripWirePopup";
import { COLORS } from "../../../../common/styles/colors";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    padding: theme.typography.pxToRem(20),
    color: theme.palette.common.darkBlue,
    borderBottom: `1px solid ${theme.palette.common.lightGray}`,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
    paddingLeft: theme.typography.pxToRem(6),
  },
  icon: {
    width: 18,
    height: 18,
  },
  textSmall: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    padding: "0 8px 0 4px",
  },
  expandIcon: {
    color: theme.palette.common.inputGrey,
    width: 22,
  },
  zoneNameText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.mediumBlack,
    wordBreak: "break-all",
  },
  setZoneText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.regularBlue,
  },
  zoneGridStyle: {
    borderTop: "1px solid #E0EBF1",
    cursor: "pointer",
    padding: 8,
    "&$selectedZoneGridStyle": {
      backgroundColor: "#E6F8FA",
    },
  },
  selectedZoneGridStyle: {},
  width100: {
    width: "100%",
  },
  noPadding: {
    padding: 0,
  },
  showStatisticsContainer: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "flex-end",
    flex: "1 1 auto",
    "& button": {
      minWidth: "auto",
      padding: 0,
    },
  },
  link: {
    color: theme.palette.common.blue,
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 6,
    display: "flex",
    flexWrap: "nowrap",
  },
  popper: {
    zIndex: 10001,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EBEBEB",
    boxSizing: "border-box",
    boxShadow: "0px 4px 13px rgba(135, 135, 135, 0.3)",
    width: 432,
    borderRadius: 5,
    position: "relative",
  },
  paper: {
    boxShadow: "none",
  },
  closeIcon: {
    position: "absolute",
    top: -5,
    right: -5,
    color: "blue",
    width: 22,
    "&:hover": {
      cursor: "pointer",
    },
  },
  chartsContainer: {
    padding: "0 30px 30px",
  },
  popperContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  popperTitle: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
  },
  popperName: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    margin: "16px 0 0 0",
  },
  statusLabel: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: theme.typography.fontFamily,
    marginTop: "5px",
  },
  status: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    padding: "0 7px",
    textTransform: "capitalize",
    marginTop: "5px",
  },
  currentStatusIndicatorContainer: {
    display: "flex",
    padding: "19px 0",
    alignItems: "center",
    justifyContent: "space-between",
  },
  popperIndicator: {
    width: "20px",
    height: "20px",
    marginTop: "3px",
  },
  select: {
    height: 13,
    minWidth: 120,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    width: 135,
    borderRadius: "0 0 8px 8px",
    marginLeft: 250,
    marginTop: 90,
  },
  newMenu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    width: 135,
    borderRadius: "0 0 8px 8px",
  },
  chartsTitle: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    margin: "12px 0 8px 0",
  },
  lowIndicator: {
    backgroundColor: "rgb(79, 158, 83)",
    display: "block",
    borderRadius: "50%",
    marginRight: "5px",
  },
  highIndicator: {
    backgroundColor: "#E25353",
    display: "block",
    borderRadius: "50%",
    marginRight: "5px",
  },
  mediumIndicator: {
    backgroundColor: "#FCA642",
    display: "block",
    borderRadius: "50%",
    marginRight: "5px",
  },
  notConfiguredIndicator: {
    backgroundColor: "#0E1D33",
    display: "block",
    borderRadius: "50%",
    marginRight: "5px",
  },
  offlineIndicator: {
    backgroundColor: "#A0A0A0",
    display: "block",
    borderRadius: "50%",
    marginRight: "5px",
  },
  indicatorWrapper: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    // width: "30%",
  },
  borderBottom: {
    border: 0,
    borderBottom: `1px solid ${theme.palette.common.lightGray}`,
  },
  swipePanel: {
    display: "flex",
    width: "50%",
    justifyContent: "center",
    backgroundColor: `${theme.palette.common.ultralightGrey}`,
    color: `${theme.palette.common.mediumBlack}`,
    marginRight: 0,
    fontSize: theme.typography.pxToRem(14),
    "&.Mui-selected": {
      backgroundColor: `${theme.palette.common.regularBlue}`,
      color: "#FFF !important",
    },
  },
  item: {
    "& MuiTabs-indicator": {
      display: "none",
    },
  },
  divider: {
    width: "100%",
    backgroundColor: "#DFDFDF",
    margin: "20px 0",
  },
  dwellTimeTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
  },
  dwellTime: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
  },
  tabBar: {
    backgroundColor: "transparent",
    height: 32,
    borderBottom: `1px solid ${theme.palette.common.ultralightGrey}`,
  },
  tabIndicator: {
    backgroundColor: theme.palette.common.lightBlue,
    height: 1,
    width: "100%",
  },
  root: {
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: "auto",
      margin: 0,
    },
    "& .MuiTab-root": {
      padding: "0 0",
      minWidth: "auto",
      minHeight: 32,
      "&:first-child": {
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
      },
      "&:last-child": {
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
      },
    },
    "& .MuiTabs-scroller": {
      flex: 1,
      position: "static",
      display: "inline-block",
    },
  },
  iconStyle: {
    fontSize: "18px",
    width: "18px",
    height: "18px",
  },
  pinItButton: {
    backgroundColor: theme.palette.common.funBlue,
    borderRadius: 30,
    color: COLORS.white,
    textTransform: "none",
    width: "100px",
    height: "35px",
  },
  pinIconGrid: {
    display: "flex",
    justifyContent: "flex-end",
    transform: "translateX(10px) translateY(-5px)",
  },
  dropDownContainer: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    marginTop: "20px",
  },
  dropDownTitle: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    margin: "0 8px 8px 0",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  dwellTimeLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
    marginBottom: "-16px",
  },
  loaderGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "400px",
  },
}));

type CameraListItemProps = {
  data: ImagesSensor;
  dataWithSpaceLoad: ImageSensorWithOccupancy;
  selectedTime: number;
  floor: Floor;
  toggleActive: (el: ImagesSensor) => void;
  clickZone: (camera: ImageSensorWithOccupancy, zoneId: string) => void;
  clickTripWire: (camera: ImageSensorWithOccupancy, tripWireId: string) => void;
  handleIsPinned?: (el: any) => void;
};

export function CameraListItem({
  data,
  dataWithSpaceLoad,
  selectedTime,
  floor,
  toggleActive,
  clickZone,
  clickTripWire,
  handleIsPinned,
}: CameraListItemProps) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { selectedProperty } = usePropertiesState();

  useEffect(() => {
    setIsExpanded(data.active);
  }, [data.active]);
  const handleExpand = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      toggleActive(data);
      ev.preventDefault();
      ev.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [data, isExpanded, toggleActive]
  );

  const getTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === 10080 || selectedTime === 43200) {
      if (selectedProperty?.timeZoneName) {
        newTimeTo = moment
          .tz(selectedProperty?.timeZoneName)
          .startOf("day")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedProperty?.timeZoneName)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment.utc().startOf("day").format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    } else {
      if (selectedProperty?.timeZoneName) {
        newTimeTo = moment
          .tz(selectedProperty?.timeZoneName)
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedProperty?.timeZoneName)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedProperty?.timeZoneName, selectedTime]);

  const timeRange = useMemo(() => {
    if (selectedTime <= 15) return "MINUTE";
    if (selectedTime <= 60) return "QUARTER";
    if (selectedTime <= 1440) return "HOUR";
    return "DAY";
  }, [selectedTime]);

  return (
    <Grid
      className={classes.item}
      container
      key={data.id}
      style={{ display: "inline-block" }}
    >
      <Accordion
        square
        key={data.id}
        expanded={isExpanded}
        className={classes.borderBottom}
      >
        <AccordionSummary className={classes.content}>
          <Grid
            container={true}
            alignItems="center"
            justify="space-between"
            style={{ margin: 0 }}
          >
            <Grid item sm={1} className={classes.alignCenter}>
              <Videocam className={classes.icon} />
            </Grid>
            <Grid item sm={7}>
              <Typography className={classes.blackText} onClick={handleExpand}>
                <b>{data.name ? data.name : data.cameraUuid}</b>
              </Typography>
            </Grid>
            <Grid
              item
              sm={4}
              className={classes.alignCenter}
              onClick={handleExpand}
            >
              <Typography className={classes.textSmall}>Zones</Typography>
              {isExpanded ? (
                <ExpandLess className={classes.expandIcon} />
              ) : (
                <ExpandMore className={classes.expandIcon} />
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <div className={classes.width100}>
            <ZonePopup
              dataWithSpaceLoad={dataWithSpaceLoad}
              floor={floor}
              timeFrom={getTimeParams().newTimeFrom}
              timeTo={getTimeParams().newTimeTo}
              timeRange={timeRange}
              clickZone={clickZone}
              handleIsPinned={handleIsPinned}
            />
            <TripWirePopup
              dataWithSpaceLoad={dataWithSpaceLoad}
              floor={floor}
              timeFrom={getTimeParams().newTimeFrom}
              timeTo={getTimeParams().newTimeTo}
              timeRange={timeRange}
              clickTripWire={clickTripWire}
              handleIsPinned={handleIsPinned}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
