import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 30,
    minHeight: 30,
    maxHeight: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  noConfigMessage: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
  },
  noConfigWrapper: {
    display: "flex",
    alignItems: "center",
  },
  errorIcon: {
    color: theme.palette.common.red,
    width: 18,
    marginLeft: 7,
    marginRight: 10,
  },
  planNoContainer: {
    display: "flex",
    alignItems: "center",
  },
}));
