import React from "react";
import { Link } from "react-router-dom";

import { useStyles } from "./LinkButton.styles";

interface LinkButtonProps {
  label: string | number;
  to: string;
  id?: string;
}

export function LinkButton({ label, to, id="linkBtn" }: LinkButtonProps) {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.button} id={id}>
      {label}
    </Link>
  );
}
