import { gql } from "@apollo/client";

export const DOORS_MUTATIONS = {
  addDoor: gql`
    mutation createDoor(
      $active: Boolean!
      $doorUuid: String!
      $coordinateX: Int
      $coordinateY: Int
      $name: String!
      $availableDays: String!
      $doorAccessControlVendorId: String
      $workingFrom: String!
      $workingTo: String!
      $floorId: String!
      $groups: [String]
    ) {
      createDoor(
        request: {
          active: $active
          doorUuid: $doorUuid
          coordinateX: $coordinateX
          coordinateY: $coordinateY
          name: $name
          availableDays: $availableDays
          doorAccessControlVendorId: $doorAccessControlVendorId
          workingFrom: $workingFrom
          workingTo: $workingTo
          floorId: $floorId
          groups: $groups
        }
      ) {
        id
      }
    }
  `,
  editDoor: gql`
    mutation updateDoor(
      $id: String!
      $active: Boolean!
      $availableDays: String!
      $coordinateX: Int
      $coordinateY: Int
      $doorAccessControlVendorId: String
      $floorId: String!
      $name: String!
      $workingFrom: String!
      $workingTo: String!
      $groups: [String]
    ) {
      updateDoor(
        request: {
          id: $id
          active: $active
          availableDays: $availableDays
          coordinateX: $coordinateX
          coordinateY: $coordinateY
          doorAccessControlVendorId: $doorAccessControlVendorId
          floorId: $floorId
          name: $name
          workingFrom: $workingFrom
          workingTo: $workingTo
          groups: $groups
        }
      )
    }
  `,
  updateDoorCoordinate: gql`
    mutation updateDoorCoordinates(
      $id: String!
      $coordinateX: Int
      $coordinateY: Int
    ) {
      updateDoorCoordinates(
        request: {
          id: $id
          coordinateX: $coordinateX
          coordinateY: $coordinateY
        }
      )
    }
  `,
};

export const DOORS_QUERIES = {
  allDoors: gql`
    query doorsByPropertyId($propertyId: String!) {
      doorsByPropertyId(
        pageDetails: {
          page: 0
          pageSize: 0
          sort: { sortBy: "id", sortType: ASC }
        }
        propertyId: $propertyId
      ) {
        active
        availableDays
        floorId
        name
        id
        workingFrom
        workingTo
        floorNumber
        floorName
        doorAccessControlVendorId
        groups {
          active
          id
          name
        }
      }
    }
  `,
  doorsByFloor: gql`
    query doorsByFloorId($floorId: String!) {
      doorsByFloorId(floorId: $floorId) {
        active
        availableDays
        coordinateX
        coordinateY
        doorAccessControlVendorId
        doorUuid
        floorId
        floorName
        floorNumber
        id
        name
        workingFrom
        workingTo
        groups {
          active
          id
          name
        }
      }
    }
  `,
  densityDuringTimeForDoor: gql`
    query densityDuringTimeForDoor(
      $request: TimeRangedDashboardDoorRequestInput
    ) {
      densityDuringTimeForDoor(request: $request) {
        doorName
        doorUuid
        doorDensityTimeline {
          peopleCount
          from
          to
        }
      }
    }
  `,
};
