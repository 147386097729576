import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Grid, Typography, Button } from "@material-ui/core";

import { Property, Role } from "../../../../../../types";
import { CoverPhoto } from "./components/CoverPhoto";
import { CompanyLogo } from "./components/CompanyLogo";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../common/constants/Routing";
import { useAuth } from "../../../../../../core/context/containers/Auth.container";
import { useStyles } from "./WorkplaceSettings.styles";

interface WorkplaceSetingsProps {
  property: Property;
}

export function WorkplaceSetings({ property }: WorkplaceSetingsProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const history = useHistory();

  const auth = useAuth();

  const handleEditClick = () => {
    history.push(ROUTES.EDIT_PROPERTY.replace(":uid", property.id) + "#2");
  };

  const createPropertyButton = useCallback(() => {
    return (
      <Button className={classes.button} onClick={handleEditClick}>
        {formatMessage({
          id: "propertyWorkplace.button.edit",
          defaultMessage: "Edit",
          description: "edit button label",
        })}
      </Button>
    );
  }, []);

  return (
    <>
      <Grid item={true} md={12} sm={12}>
        <Typography className={classes.blackLabel}>
          {formatMessage({
            id: "propertyWorkplace.companyLogo",
            defaultMessage: "Company Logo",
            description: "company logo label",
          })}
        </Typography>
        <CompanyLogo property={property} />

        <Typography className={classes.blackLabel}>
          {formatMessage({
            id: "propertyWorkplace.coverPhoto",
            defaultMessage: "Cover Photo",
            description: "cover photo label",
          })}
        </Typography>
        <CoverPhoto property={property} />
      </Grid>
      {auth?.canSee(
        auth?.user?.role?.toUpperCase() as Role,
        "properties:edit",
        createPropertyButton()
      )}
    </>
  );
}
