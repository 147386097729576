import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  Checkbox,
  Input,
  CircularProgress,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { listStyles } from "../../../../common/styles/lists";
import GQLService from "../../../../core/services/GQL.service";
import { useGroupState } from "../../../../core/context/containers/Groups.container";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useSearchState } from "../../../../core/context/containers/Search.container";
import { useDoorState } from "../../../../core/context/containers/Door.container";
import { Group } from "../../../../types";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.common.mediumBlack,
    lineHeight: "36px",
  },
  content: {
    display: "flex",
    minWidth: "900px",
    height: "600px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    padding: "20px",
    borderRadius: "20px",
    boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  selectAll: {
    color: theme.palette.common.regularBlue,
    textTransform: "capitalize",
    paddingLeft: 0,
  },
  divider: {
    marginRight: "10px",
    borderRight: `1px solid ${theme.palette.common.black}`,
    minHeight: "10px",
  },
  filter: {
    paddingLeft: "10px",
  },
  searchText: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontSize: "14px",
    },
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  filterRow: {
    justifyContent: "space-between",
  },
  scrollContent: {
    height: "400px",
    overflowY: "scroll",
    marginTop: "16px",
  },
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
    "&:before": {
      content: "none",
    },
  },
  inputField: {
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  loaderDiv: {
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

interface DoorUserGroupModalProps {
  no: Function;
}

export function DoorUserGroupModal({ no }: DoorUserGroupModalProps) {
  const classes = useStyles();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const {
    userGroup,
    setUserGroup,
    setUserGroupFromResponse,
    selectedUsersGroup,
    setSelectedUsersGroup,
    selectedEditUsersGroup,
    setSelectedEditUsersGroup,
  } = useGroupState();
  const { selectedProperty } = usePropertiesState();
  const { selectedDoor } = useDoorState();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [checkedGroupIds, setCheckedGroupIds] = useState<string[]>([]);
  const [checkedUsersGroup, setCheckedUsersGroup] = useState<Group[]>([]);

  const isAdd = history.location.pathname.split("/").includes("add");

  const queryGQL = isAdd
    ? GQLService.queries.groupsByPropertyId
    : GQLService.queries.availableGroupsByDoor;
  const variables = isAdd
    ? { propertyId: selectedProperty?.id }
    : { doorId: selectedDoor?.id, propertyId: selectedProperty?.id };

  const { data: userGroupData } = useQuery(queryGQL, {
    variables,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!isAdd && selectedEditUsersGroup) {
      setCheckedUsersGroup([...selectedEditUsersGroup]);
      const groupIds = selectedEditUsersGroup
        ? (selectedEditUsersGroup as Group[]).map((group) => group.id!)
        : [];
      setCheckedGroupIds([...groupIds]);
    } else {
      setCheckedUsersGroup([...selectedUsersGroup]);
      const groupIds = selectedUsersGroup
        ? (selectedUsersGroup as Group[]).map((group) => group.id!)
        : [];
      setCheckedGroupIds([...groupIds]);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (isAdd) {
        setUserGroup(userGroupData?.groupsByPropertyId || []);
        setUserGroupFromResponse(userGroupData?.groupsByPropertyId || []);
      } else {
        setUserGroup(userGroupData?.availableGroupsByDoor || []);
        setUserGroupFromResponse(userGroupData?.availableGroupsByDoor || []);
      }
      setTimeout(() => setIsLoading(false), 600);
    }
    return () => {
      isMounted = false;
    };
  }, [userGroupData]);

  const handleGroupSelect = useCallback(
    (item: Group, e) => {
      const group: Group[] = [...checkedUsersGroup];
      const groupIds: string[] = [...checkedGroupIds];
      if (e.target.checked) {
        group.push(item);
        groupIds.push(item.id!);
      } else {
        const itemIndex = group.findIndex((v) => v.id === item.id);
        group.splice(itemIndex, 1);
        const index = groupIds.findIndex((v) => v === item.id);
        groupIds.splice(index, 1);
      }
      setCheckedUsersGroup([...group]);
      setCheckedGroupIds([...groupIds]);
    },
    [checkedGroupIds, checkedUsersGroup]
  );

  const updateGroups = useCallback(() => {
    if (isAdd) {
      setSelectedUsersGroup([...checkedUsersGroup]);
    } else {
      setSelectedEditUsersGroup([...checkedUsersGroup]);
    }
    no();
  }, [checkedUsersGroup, setSelectedUsersGroup, setSelectedEditUsersGroup]);

  const load = (
    <div className={classes.loaderDiv}>
      <CircularProgress color="inherit" size={36} />
    </div>
  );
  const group = userGroup.length ? (
    <Grid className={classes.scrollContent}>
      {userGroup.map((item: any, i: number) => (
        <Grid
          container={true}
          alignItems="center"
          justify="space-between"
          style={{ margin: "8px 0", border: "1px solid #BDCED7" }}
        >
          <Grid
            container={true}
            item={true}
            md={5}
            sm={5}
            justify="flex-start"
            className={classes.headerWrapper}
          >
            <Checkbox
              value={item.id}
              color="primary"
              inputProps={{ "aria-label": "checkbox" }}
              checked={checkedGroupIds.includes(item.id!)}
              onClick={(e) => handleGroupSelect(item, e)}
            />
            <Person className={classes.icon} />
            <Typography className={classes.name}>{item.name}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  ) : (
    <Typography>
      {formatMessage({
        id: "dataList.noData",
        defaultMessage: "No Data",
        description: "datalist no data info",
      })}
    </Typography>
  );

  return (
    <Grid className={classes.content}>
      <div>
        <Typography className={classes.header}>
          Add Users Groups to the List
        </Typography>
        {isLoading ? load : group}
      </div>
      <Grid item className={classes.buttonsWrapper}>
        <Button id="cancelBtn" className={classes.cancel} onClick={() => no()}>
          Cancel
        </Button>
        <Button id="addBtn" className={classes.editBtn} onClick={updateGroups}>
          Add to the list
        </Button>
      </Grid>
    </Grid>
  );
}
