import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.darkBlue,
    marginBottom: theme.spacing(1.4),
    display: "inline-block",
  },
  container: {
    flexGrow: 1,
    float: "right",
    border: "2px solid",
    borderColor: theme.palette.common.concrete,
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    margin: `${theme.spacing(3)}px 0 0 0`,
    borderRadius: "11px",
    width: "50%",
  },
  containerSummary: {
    flexGrow: 1,
    float: "right",
    // border: "2px solid",
    // borderColor: theme.palette.common.concrete,
    // padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    // margin: `${theme.spacing(3)}px 0 0 0`,
    // borderRadius: "11px",
    marginTop: "20px",
    // width: "75vw",
  },
  headerContainer: {
    marginBottom: 20,
  },
  containerLeft: {
    // marginRight: theme.spacing(3),
  },
  accessPointStatisticsContainer: {
    height: "auto",
    padding: 0,
  },
  airQualityContainer: {
    marginRight: theme.spacing(3),
  },
  selectContainer: {
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  select: {
    height: 13,
    minWidth: 100,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: 9,
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    width: 135,
    borderRadius: "0 0 8px 8px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  buttonTabsWrapper: {
    position: "relative",
    top: -40,
  },
  filters: {
    margin: "0",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkBlue,
    marginRight: 12,
  },
}));
