import { useState } from "react";
import { createContainer } from "unstated-next";

import { TimeSlider } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";

function useTimeSliderBase() {
  const [timeSliders, setTimeSliders] = useState<number>(0);
  const {
    state: selectedTimeSlider,
    setState: setSelectedTimeSlider,
  } = usePersistedState<number | null>("timeSlider", 0);

  return {
    timeSliders,
    setTimeSliders,
    selectedTimeSlider,
    setSelectedTimeSlider,
  };
}

const { Provider, useContainer } = createContainer(useTimeSliderBase);

export const useTimeSliderState = useContainer;
export const TimeSliderProvider = Provider;
