import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import {
  PageHeader,
  DataList,
  LinkButton,
  EmptyListView,
} from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { FloorsListItem } from "./FloorsListItem";

import { Grid, makeStyles } from "@material-ui/core";
import { ROUTES } from "../../../../common/constants/Routing";
import { useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useFloorState } from "../../../../core/context/containers/Floor.container";
import { Role } from "../../../../types";
// import { useAuth } from "../../../../core/context/functions/Auth.functions";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { useSearchState } from "../../../../core/context/containers/Search.container";

const useStyles = makeStyles(() => ({
  floorsHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export function Floors() {
  const { selectedProperty } = usePropertiesState();
  const {
    searchFloors,
    setSelectedFloor,
    floors,
    setFloors,
    floorsFromResponse,
    setFloorsFromResponse,
  } = useFloorState();
  const classes = useStyles();
  const { loading, data } = useQuery(GQLService.queries.allFloors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const auth = useAuth();

  useEffect(
    () => {
      setSelectedFloor(null);
    },
    [] //eslint-disable-line
  );

  useEffect(
    () => {
      let isMounted = true;
      if (isMounted) {
        setFloors(data?.floorsByPropertyId || []);
        setFloorsFromResponse(data?.floorsByPropertyId || []);
      }
      return () => {
        isMounted = false;
      };
    },
    [data] //eslint-disable-line
  );
  const { formatMessage } = useIntl();
  const createFloorButton = useCallback(() => {
    return (
      <LinkButton
        to={ROUTES.PROPERTY_ADD_FLOORS.replace(":uid", selectedProperty?.id!)}
        label={formatMessage({
          id: "floors.button.label",
          defaultMessage: "Add New Floor",
          description: "add a floor button label",
        })}
      />
    );
  }, []);

  const { searchValue } = useSearchState();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      searchFloors(searchValue);
    }
    return () => {
      isMounted = false;
    };
  }, [searchValue, floorsFromResponse]);
  return (
    <div>
      <Grid className={classes.floorsHeader}>
        <PageHeader
          title={formatMessage({
            id: "floors.header",
            defaultMessage: "Manage Floors",
            description: "manage floors header",
          })}
        />
        {floors.length !== 0
          ? auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "floors:create",
              createFloorButton()
            )
          : null}
      </Grid>
      {floors.length === 0 ? (
        <EmptyListView viewType="floors" />
      ) : (
        <DataList data={floors} loading={loading} ListItem={FloorsListItem} />
      )}
    </div>
  );
}
