export const dummyUserEmail = "demo@joindigital.com";

export const DummyPropertyA = {
  id: "0rF8C9Hvt4Iwuyuv",
  siteName: "Grand Square A",
  ownerCompanyName: "Join Digital Demo Investments",
  siteServiceAddress: "100 Demo Avenue, Edmonton, Canada",
};

export const DummyPropertyB = {
  id: "aLcpumiZfbH6SjFD",
  siteName: "Grand Square B",
  ownerCompanyName: "Join Digital Demo Investments",
  siteServiceAddress: "100 Demo Avenue, Edmonton, Canada",
};

export const DummyPropertyC = {
  id: "2Jh2iRfNUjImF8u2",
  siteName: "Grand Square C",
  ownerCompanyName: "Join Digital Demo Investments",
  siteServiceAddress: "100 Demo Avenue, Edmonton, Canada",
};

export const DummyPropertyD = {
  id: "J9f5Fzt3HvfYS7hm",
  siteName: "Grand Square D",
  ownerCompanyName: "Join Digital Demo Investments",
  siteServiceAddress: "100 Demo Avenue, Edmonton, Canada",
};
