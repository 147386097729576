import { AxiosResponse } from "axios";
import { ImageResponseHandled } from "../../types/http";

export const blobToData = (blob: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const parseImageResponse = async (
  response: AxiosResponse
): Promise<ImageResponseHandled> => {
  let image;
  if (response.data) {
    image = {
      exists: true,
      source: (await blobToData(response.data)) as string,
      status: response.status,
    };
  } else {
    image = {
      exists: false,
      source: undefined,
      status: response.status,
    };
  }
  return image;
};

function getOperatingSystem(window: any) {
  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
  if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }
  return operatingSystem;
}

function getBrowserInfo(window: any) {
  let currentBrowser = 'Not known';
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
  else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
  else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
  else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
  else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
  else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
  else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
  else { console.log('Others'); }
  return currentBrowser;
}

export const getOS = (window: any) => {
  return getOperatingSystem(window);
};

export const getBrowser = (window: any) => {
  return getBrowserInfo(window);
};