import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Grid, Typography, Button } from "@material-ui/core";

import { useFiltersState } from "../../../core/context/containers/Filters.container";
import GQLService from "../../../core/services/GQL.service";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { useFloorState } from "../../../core/context/containers/Floor.container";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import {
  ImageSensorWithOccupancy,
  ZoneWithOccupancy,
  TripWireWithOccupancy,
  AccessPointWithSpaceLoad,
  //AccessPointWithSpaceLoadTimeline,
  airQuality,
  SensorDetailsAirQualityForFloor,
  Door,
  Space,
  UserFilterPreferenceType,
} from "../../../types";
import { SideList } from "./APSideList/SideList";
import Loader from "react-loader-spinner";
import { COLORS } from "../../../common/styles/colors";
import { NoFloorPlan } from "../../../common/components";
import { OccupancyLegend } from "./Legend/Legend";
import clsx from "clsx";
import { useStyles } from "./Occupancy.styles";
//import { HourSlider } from "../../../common/components/HourSlider/HourSlider";
import { SensorsLegend } from "./Legend/SensorsLegend";
import { SensorsSideList } from "./SensorsSideList/SensorsSideList";
import { useTimeSliderState } from "../../../core/context/containers/TimeSlider.container";
import { Filters } from "./Filters";
import { CamerasSideList } from "./CamerasSideList/CamerasSideList";
import { Layer, Stage, Image, Group } from "react-konva";
import useImage from "use-image";
import {
  AccessPointShape,
  AccessPointSolidShape,
  ActiveAccessPoint,
  ActiveAirQualitySensorDetails,
  ActiveCameraZone,
  ActiveCameraTripwire,
  AddCameraZoneProps,
  AddCameraTripwireProps,
  AirQualitySensor,
  CameraShape,
  CameraZone,
  CameraTripwire,
  ShapeType,
  DoorShape,
  ActiveDoorDetails,
  SpaceShape,
} from "../components/SensorShapes";
import { DoorsSideList } from "./DoorsSideList/DoorsSideList";
import * as _ from "lodash";
import { useUserState } from "../../../core/context/containers/User.container";
import SmplrViewer from "../../../common/components/SmplrViewer/SmplrViewer";
import useSmplrJs from "../../../common/hooks/useSmplrJs";

export enum ViewType {
  ACCESS_POINT = "ACCESS_POINT",
  SENSORS = "SENSORS",
  IMG_SENSORS = "IMG_SENSORS",
  SPACES = "SPACES",
  DOORS = "DOORS",
}

interface ZoneShapeProps extends AddCameraZoneProps {
  shape: ZoneWithOccupancy;
}

interface TripwireShapeProps extends AddCameraTripwireProps {
  shape: TripWireWithOccupancy;
}

export function Occupancy() {
  // const smplrJsPath = window.location.origin + "/smplr.js"
  // const smplrCssPath = window.location.origin + "/smplr.css"
  // useScript(smplrJsPath)
  // useStylesheet(smplrCssPath)

  // useSmplrJs();
  const classes = useStyles();

  const konvaStageRef = useRef<any>(null);

  const { loading: loadingProperties, selectedProperty } = usePropertiesState();
  const { loading: loadingFloors } = useFloorState();

  const {
    selectedTime,
    selectedFloor,
    selectedSSID,
    selectedSpace,
    setSelectedFloor,
  } = useFiltersState();

  const [viewType, setViewType] = useState<ViewType>(ViewType.ACCESS_POINT);
  const [offHoursIsActive, setOffHoursIsActive] = useState<boolean>(false);
  const [accessPointsWithSpaceLoad, setAccessPointsWithSpaceLoad] = useState<
    AccessPointWithSpaceLoad[]
  >([]);
  // const [
  //   accessPointsWithSpaceLoadTimeline,
  //   setAccessPointsWithSpaceLoadTimeline,
  // ] = useState<AccessPointWithSpaceLoadTimeline[]>([]);

  const [
    accessPointsWithSpaceLoadToRender,
    setAccessPointsWithSpaceLoadToRender,
  ] = useState<AccessPointWithSpaceLoad[]>([]);

  // const [
  //   accessPointsWithSpaceLoadToRenderTimeline,
  //   setAccessPointsWithSpaceLoadToRenderTimeline,
  // ] = useState<AccessPointWithSpaceLoadTimeline[]>([]);

  const [sensorsToRender, setSensorsToRender] = useState<
    SensorDetailsAirQualityForFloor[]
  >([]);

  const [camerasToRender, setCamerasToRender] = useState<
    ImageSensorWithOccupancy[]
  >([]);

  const [doorsToRender, setDoorsToRender] = useState<Door[]>([]);
  const [spacesToRender, setSpacesToRender] = useState<Space>();
  const [spaces, setSpaces] = useState<any>([]);

  const [dataLoading, setDataLoading] = useState(true);
  const [imgLoading, toggleImageLoading] = useState(true);

  const [imageSrc, setImageSrc] = useState<undefined | string>(undefined);

  const [floorMapImage] = useImage(imageSrc || "");

  const [SSIDs, setSSIDSForFloor] = useState<any[]>([]);

  const [spacesForFloor, setSpacesForFloor] = useState<string[]>([]);

  const { selectedTimeSlider, setSelectedTimeSlider } = useTimeSliderState();

  const { userEmail } = useUserState();

  const [userPreferenceFilter, setUserPreferenceFilter] = useState<
    any | undefined
  >();

  const [isPinned, setIsPinned] = useState<any>();

  const selectedAirQualitySensor = useMemo(() => {
    const activeSensor = sensorsToRender.find((sensor) => sensor.active);
    return activeSensor;
  }, [sensorsToRender]);

  const selectedCameraZone = useMemo(() => {
    const selectedCamera = camerasToRender.find((camera) => camera.active);
    if (selectedCamera) {
      return selectedCamera.zones.find((zone) => zone.isSelected);
    }
    return null;
  }, [camerasToRender]);

  const selectedTripwire = useMemo(() => {
    const selectedCamera = camerasToRender.find((camera) => camera.active);
    if (selectedCamera) {
      return selectedCamera.tripWires.find((tripwire) => tripwire.isSelected);
    }
    return null;
  }, [camerasToRender]);

  const selectedDoor = useMemo(() => {
    const activeDoor = doorsToRender.find((door) => door.active);
    return activeDoor;
  }, [doorsToRender]);

  const formatedTime = useMemo(() => {
    return moment()
      .subtract(selectedTime, "minutes")
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      .toString();
  }, [selectedTime]);

  const timeTo = useMemo(
    () => moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ").toString(),
    [selectedTime]
  );

  const timeRange = useMemo(() => {
    if (selectedTime <= 60) return "QUARTER";
    if (selectedTime <= 24 * 7 * 60) return "HOUR";
    return "DAY";
  }, [selectedTime]);

  const [getNetworkOccupancyByFloorId, { loading, data }] = useLazyQuery(
    GQLService.queries.networkOccupancyByFloorId,
    {
      fetchPolicy: "no-cache",
    }
  );

  /* keep this for final testing for NET-2635 */
  // const [
  //   getNetworkOccupancyTimelineByFloorId,
  //   { loading: loadingT, error: errorT, data: dataT },
  // ] = useLazyQuery(GQLService.queries.networkOccupancyByFloorIdTimeline, {
  //   fetchPolicy: "no-cache",
  // });

  const [
    getDetailedAirQualityForFloor,
    { data: sensorsResponse, loading: loadingSensors, error: sensorsError },
  ] = useLazyQuery(GQLService.queries.detailedAirQualityForFloor, {
    fetchPolicy: "no-cache",
  });

  const [
    getCamerasByFloorId,
    { data: camResponse, loading: camLoading, error: camError },
  ] = useLazyQuery(GQLService.queries.camerasByFloorId, {
    fetchPolicy: "no-cache",
  });

  const [
    getCameraOccupancyByFloorId,
    { data: responseDataForFloor, loading: camOccLoading, error: camOccError },
  ] = useLazyQuery<{ cameraOccupancyByFloorId: ImageSensorWithOccupancy[] }>(
    GQLService.queries.cameraOccupancyByFloorId,
    { fetchPolicy: "no-cache" }
  );

  const [
    getDoorsByFloorId,
    { data: doorsResponse, loading: loadingDoors, error: doorsError },
  ] = useLazyQuery(GQLService.queries.doorsByFloor, {
    fetchPolicy: "network-only",
  });

  const [
    getSpaceByFloor,
    { data: spacesResponse, loading: loadingSpaces, error: spacesError },
  ] = useLazyQuery(GQLService.queries.spaces, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getSpaceByFloor({ variables: { floorId: selectedFloor?.id! } });
  }, [getSpaceByFloor, selectedFloor]);

  /* keep this for final testing for NET-2635 */
  // const [
  //   getSpacesByFloorId,
  //   { data: spacesResponse, loading: loadingSpaces, error: spacesError },
  // ] = useLazyQuery(GQLService.queries.spaces, {
  //   fetchPolicy: "cache-and-network",
  // });

  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     if (viewType !== ViewType.DOORS) {
  //       getSpacesByFloorId({
  //         variables: {
  //           floorId: selectedFloor?.id!,
  //         },
  //       });
  //     }
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [
  //   viewType,
  //   formatedTime,
  //   getSpacesByFloorId,
  //   selectedFloor?.id,
  //   timeTo,
  //   timeRange,
  // ]);

  const [
    getSpaceDetails,
    {
      data: spaceDetailsResponse,
      loading: loadingSpaceDetails,
      error: spaceDetailsError,
    },
  ] = useLazyQuery(GQLService.queries.spaceById, {
    fetchPolicy: "cache-and-network",
  });

  const [
    getUserFilterPrefernce,
    {
      data: userFilterPrefernceResponse,
      loading: loadingUserFilterPrefernce,
      error: userFilterPrefernceError,
    },
  ] = useLazyQuery(GQLService.queries.getUserFilterPrefernce, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setOffHoursIsActive(false);
  }, [selectedProperty]);

  useEffect(() => {
    let isMounted = true;
    setSelectedTimeSlider(0);
    getUserFilterPrefernce({
      variables: {
        dataType: UserFilterPreferenceType.FLOOR,
        email: userEmail,
        propertyId: selectedProperty?.id,
      },
    });
    return () => {
      isMounted = false;
      setSelectedFloor(undefined);
    };
  }, [
    getUserFilterPrefernce,
    selectedProperty,
    setSelectedFloor,
    setSelectedTimeSlider,
    userEmail,
  ]); //viewType

  useEffect(() => {
    if (!loadingUserFilterPrefernce && userFilterPrefernceResponse) {
      setUserPreferenceFilter(
        userFilterPrefernceResponse?.userFilterPreference
      );
    }
    if (userFilterPrefernceError) {
      setUserPreferenceFilter(null);
    }
  }, [userFilterPrefernceResponse, userFilterPrefernceError]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (
        selectedProperty &&
        selectedFloor &&
        viewType === ViewType.ACCESS_POINT
      ) {
        setIsPinned(false);
        // if (selectedTime === 1440) {
        //   getNetworkOccupancyTimelineByFloorId({
        //     variables: {
        //       request: {
        //         floorId: selectedFloor?.id!,
        //         from: formatedTime,
        //         to: timeTo,
        //         timeRangeType: timeRange,
        //         userEmail: userEmail,
        //       },
        //     },
        //   });
        // } else {
        getNetworkOccupancyByFloorId({
          variables: {
            request: {
              floorId: selectedFloor?.id!,
              from: formatedTime,
              to: timeTo,
              timeRangeType: timeRange,
              userEmail: userEmail,
            },
          },
        });
        //}
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    viewType,
    formatedTime,
    selectedTimeSlider,
    getNetworkOccupancyByFloorId,
    //getNetworkOccupancyTimelineByFloorId,
    selectedFloor,
    selectedProperty,
    timeRange,
    timeTo,
    isPinned,
    userEmail,
  ]);

  const handleIsPinned = (value: any) => {
    setIsPinned(value);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (selectedProperty && selectedFloor && viewType === ViewType.SENSORS) {
        setIsPinned(false);
        getDetailedAirQualityForFloor({
          variables: {
            floorId: selectedFloor?.id!,
            from: formatedTime,
            to: timeTo,
            userEmail: userEmail,
          },
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    viewType,
    formatedTime,
    getDetailedAirQualityForFloor,
    selectedFloor,
    selectedProperty,
    timeTo,
    isPinned,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (viewType === ViewType.DOORS) {
        getDoorsByFloorId({
          variables: {
            floorId: selectedFloor?.id!,
          },
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    viewType,
    formatedTime,
    getDoorsByFloorId,
    selectedFloor?.id,
    timeTo,
    timeRange,
  ]);

  useEffect(() => {
    const dataLoaded = [
      loadingProperties,
      loadingFloors,
      // loadingT,
      loading,
      imgLoading,
      loadingSensors,
      camLoading,
      camOccLoading,
      loadingDoors,
      loadingSpaces,
      loadingSpaceDetails,
      loadingUserFilterPrefernce,
    ].every((value) => value === false);
    if (dataLoaded) {
      setDataLoading(false);
    } else {
      setDataLoading(true);
    }
  }, [
    loadingProperties,
    loadingFloors,
    loading,
    //loadingT,
    imgLoading,
    loadingSensors,
    camLoading,
    camOccLoading,
    loadingDoors,
    loadingSpaces,
    loadingSpaceDetails,
    loadingUserFilterPrefernce,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && imageSrc && viewType === ViewType.ACCESS_POINT) {
      setAccessPointsWithSpaceLoad(data?.networkOccupancyByFloorId || []);
      setAccessPointsWithSpaceLoadToRender(
        data?.networkOccupancyByFloorId || []
      );
      if (selectedSpace !== "none" && selectedSSID !== "all") {
        let newAPWithSSID: any = [];
        spaceDetailsResponse?.spaceById.accessPoints?.map((el: any) => {
          const r1 = el?.radio1?.ssids?.filter(
            (item: any) => item.id === selectedSSID
          );
          const r2 = el?.radio2?.ssids?.filter(
            (item: any) => item.id === selectedSSID
          );
          if (r1?.length! > 0 || r2?.length! > 0) {
            newAPWithSSID.push(el);
          }
        });
        let newAccessPointArray: any = [];
        for (
          let index = 0;
          index < data?.networkOccupancyByFloorId?.length;
          index++
        ) {
          for (let j = 0; j < newAPWithSSID?.length; j++) {
            if (
              data?.networkOccupancyByFloorId[index]?.accessPoint.id ===
              newAPWithSSID[j].id
            ) {
              newAccessPointArray.push(data?.networkOccupancyByFloorId[index]);
            }
          }
        }
        setAccessPointsWithSpaceLoadToRender(newAccessPointArray || []);
      } else if (selectedSpace === "none" && selectedSSID !== "all") {
        let newAPWithSSID: any = [];
        data?.networkOccupancyByFloorId?.map((el: any) => {
          const r1 = el?.accessPoint?.radio1?.ssids?.filter(
            (item: any) => item.id === selectedSSID
          );
          const r2 = el?.accessPoint?.radio2?.ssids?.filter(
            (item: any) => item.id === selectedSSID
          );
          if (r1?.length! > 0 || r2?.length! > 0) {
            newAPWithSSID.push(el);
          }
        });
        setAccessPointsWithSpaceLoadToRender(newAPWithSSID || []);
      } else if (selectedSpace !== "none") {
        let accessPointArray: any = [];
        for (
          let index = 0;
          index < data?.networkOccupancyByFloorId.length;
          index++
        ) {
          for (
            let j = 0;
            j < spaceDetailsResponse?.spaceById.accessPoints.length;
            j++
          ) {
            if (
              data?.networkOccupancyByFloorId[index].accessPoint.id ===
              spaceDetailsResponse?.spaceById.accessPoints[j].id
            ) {
              accessPointArray.push(data?.networkOccupancyByFloorId[index]);
            }
          }
        }
        setAccessPointsWithSpaceLoadToRender(accessPointArray || []);
      }
    }
    setSpacesToRender(spaceDetailsResponse?.spaceById || undefined);
    return () => {
      isMounted = false;
    };
  }, [
    viewType,
    data,
    loading,
    spaceDetailsResponse,
    selectedSpace,
    imageSrc,
    selectedSSID,
  ]);

  useEffect(() => {
    if (selectedSpace !== "none") {
      const ssidsBySpace = spaceDetailsResponse?.spaceById?.ssids?.map(
        (el: any) => {
          return { name: el.name, id: el.id };
        }
      );
      setSSIDSForFloor(ssidsBySpace);
    } else {
      const uniqueSSIDs = [
        ...new Set(
          data?.networkOccupancyByFloorId
            .map((el: AccessPointWithSpaceLoad) => {
              const SSIDs = [el.accessPoint?.radio1, el.accessPoint?.radio2]
                .reduce((acc, val) => acc.concat(val?.ssids as any), [])
                .map((el: any) => {
                  return { name: el.name, id: el.id };
                });
              return SSIDs;
            })
            .reduce((acc: any, val: any) => acc.concat(val), [])
        ),
      ];
      const uniqData = _.uniqBy(uniqueSSIDs, function (e: any) {
        return e?.id;
      });
      const removeRadioBand = uniqData?.map((el: any) => {
        if (el?.name?.includes("(2.4GHz)")) {
          let newStr = el?.name?.split("(2.4GHz)").join("");
          return { ...el, name: newStr };
        } else if (el?.name?.includes("(5GHz)")) {
          let newStr = el?.name?.split("(5GHz)").join("");
          return { ...el, name: newStr };
        } else {
          return el;
        }
      });
      setSSIDSForFloor(removeRadioBand);
    }
  }, [data, selectedSpace, spaceDetailsResponse]);

  useEffect(() => {
    let isMounted = true;
    if (
      isMounted &&
      viewType !== ViewType.DOORS &&
      !loadingSpaces &&
      spacesResponse
    ) {
      setSpaces(spacesResponse?.spaces || []);
      const spaceName = [
        ...new Set(
          spacesResponse?.spaces.map((el: any) => ({
            id: el.id,
            name: el.name,
          }))
        ),
      ];
      setSpacesForFloor(spaceName as string[]);
    }
    return () => {
      isMounted = false;
    };
  }, [spacesResponse, loadingSpaces]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && viewType === ViewType.SENSORS) {
      !loadingSensors &&
        setSensorsToRender(
          sensorsResponse?.detailedAirQualityForFloor.map((sensor: any) => ({
            ...sensor,
            active: false,
          })) || []
        );
      /**
       * This code implements the functionality of filtering
       * AQ sensors data on the basis of Spaces.
       */
      if (selectedSpace !== "none") {
        let airQualitySensorsArray: any = [];
        let renderAQSensor: any = [];
        if (sensorsResponse) {
          renderAQSensor = sensorsResponse.detailedAirQualityForFloor;
        }
        for (const sensor of renderAQSensor) {
          for (const index in spaceDetailsResponse?.spaceById.airSensors) {
            if (
              spaceDetailsResponse.spaceById.airSensors[index].id ===
              sensor.airSensor.id
            ) {
              airQualitySensorsArray.push(sensor);
            }
          }
        }
        setSensorsToRender(airQualitySensorsArray || []);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    viewType,
    sensorsResponse,
    loadingSensors,
    sensorsError,
    selectedSpace,
    spaceDetailsResponse,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (
      isMounted &&
      selectedFloor &&
      !loadingDoors &&
      viewType === ViewType.DOORS
    ) {
      setDoorsToRender(
        doorsResponse?.doorsByFloorId.map((door: any) => ({
          ...door,
          active: false,
        })) || []
      );
    }
    return () => {
      isMounted = false;
    };
  }, [viewType, doorsResponse, loadingDoors, doorsError, selectedFloor]);

  useEffect(() => {
    let isMounted = true;
    if (
      isMounted &&
      selectedFloor !== undefined &&
      selectedTime &&
      selectedProperty
    ) {
      toggleImageLoading(true);
      (async () => {
        const response = await GQLService.rest.images.getFloorPlan(
          selectedProperty?.id!,
          selectedFloor?.id!
        );
        setImageSrc(response?.source);
        toggleImageLoading(false);
      })();
    }
    return () => {
      setImageSrc(undefined);
      isMounted = false;
      toggleImageLoading(false);
    };
  }, [selectedFloor, selectedProperty, selectedTime]);

  useEffect(() => {
    let isMounted = true;
    if (
      isMounted &&
      selectedFloor &&
      !camLoading &&
      !camOccLoading &&
      viewType === ViewType.IMG_SENSORS
    ) {
      const activeCameras = responseDataForFloor?.cameraOccupancyByFloorId
        ?.filter((camera) => camera.active)
        .map((camera) => {
          return { ...camera, active: false };
        });
      setCamerasToRender(activeCameras || []);
    }

    var newData = responseDataForFloor?.cameraOccupancyByFloorId!;
    var newArray = _.cloneDeep(responseDataForFloor?.cameraOccupancyByFloorId!);

    if (
      selectedSpace !== "none" &&
      newData &&
      spaceDetailsResponse &&
      newArray
    ) {
      var filteredDataSpaceZoneData = _.map(
        spaceDetailsResponse?.spaceById.zones,
        "id"
      );
      var filteredDataSpaceTripWireData = _.map(
        spaceDetailsResponse?.spaceById.tripWires,
        "id"
      );

      for (let index = 0; index < newData.length; index++) {
        const spcaeZoneFilter = _.filter(newData[index].zones, function (p) {
          return _.includes(filteredDataSpaceZoneData, p.id);
        });
        newArray[index].zones = spcaeZoneFilter;
      }

      for (let index = 0; index < newData.length; index++) {
        const spcaeTripWireFilter = _.filter(
          newData[index].tripWires,
          function (p) {
            return _.includes(filteredDataSpaceTripWireData, p.id);
          }
        );
        newArray[index].tripWires = spcaeTripWireFilter;
      }

      const filteredImageSensorData = newArray?.filter(
        (AC) => AC.zones.length > 0 || AC.tripWires.length > 0
      );

      const activeSpaceCameras = filteredImageSensorData
        ?.filter((camera) => camera.active)
        .map((camera) => {
          return { ...camera, active: false };
        });

      setCamerasToRender(activeSpaceCameras || []);
    }
    return () => {
      isMounted = false;
    };
  }, [
    viewType,
    camResponse,
    camLoading,
    camOccLoading,
    camError,
    camOccError,
    selectedFloor,
    responseDataForFloor?.cameraOccupancyByFloorId,
    selectedSpace,
    spaceDetailsResponse,
    loadingSpaceDetails,
  ]);

  useEffect(() => {
    if (selectedSpace === "none") {
      setSpacesToRender(undefined);
      getSpaceDetails({ variables: { spaceId: selectedSpace } });
    } else {
      getSpaceDetails({ variables: { spaceId: selectedSpace } });
    }
  }, [viewType, selectedSpace]);

  const toggleActive = useCallback(
    (toggledAPID) => {
      const newAPs = accessPointsWithSpaceLoadToRender.map((AP) => {
        if (toggledAPID === AP.accessPoint.id) {
          return {
            ...AP,
            active: !AP.active,
          };
        } else {
          return {
            ...AP,
            active: false,
          };
        }
      });
      setAccessPointsWithSpaceLoadToRender(newAPs);
    },
    [accessPointsWithSpaceLoadToRender]
  );

  // const toggleActiveTimeline = useCallback(
  //   (toggledAPID) => {
  //     const newAPs = accessPointsWithSpaceLoadToRenderTimeline.map((AP) => {
  //       if (toggledAPID === AP.accessPoint.id) {
  //         return {
  //           ...AP,
  //           active: !AP.active,
  //         };
  //       } else {
  //         return {
  //           ...AP,
  //           active: false,
  //         };
  //       }
  //     });
  //     setAccessPointsWithSpaceLoadToRenderTimeline(newAPs);
  //   },
  //   [accessPointsWithSpaceLoadToRenderTimeline]
  // );

  const toggleActiveSensors = useCallback(
    (sensorId) => {
      const newSensors = sensorsToRender.map((sensorWithDetails) => {
        if (
          sensorWithDetails.airSensor.id === sensorId
          //&& sensorWithDetails.details.airQuality !== airQuality.NO_DATA
        ) {
          return {
            ...sensorWithDetails,
            active: !sensorWithDetails.active,
          };
        } else {
          return {
            ...sensorWithDetails,
            active: false,
          };
        }
      });
      setSensorsToRender(newSensors);
    },
    [sensorsToRender]
  );

  const toggleActiveCameras = useCallback(
    (camera) => {
      const newCamera = camerasToRender.map((cameraWithDetails: any) => {
        if (cameraWithDetails.id === camera.id) {
          return {
            ...cameraWithDetails,
            active: !camera.active,
          };
        } else {
          return {
            ...cameraWithDetails,
            active: false,
          };
        }
      });
      setCamerasToRender(newCamera);
    },
    [camerasToRender]
  );

  const toggleActiveDoors = useCallback(
    (door) => {
      const newDoors = doorsToRender.map((doorWithDetails) => {
        if (doorWithDetails.id === door.id) {
          return {
            ...doorWithDetails,
            active: !doorWithDetails.active,
          };
        } else {
          return {
            ...doorWithDetails,
            active: false,
          };
        }
      });
      setDoorsToRender(newDoors);
    },
    [doorsToRender]
  );

  const toggleActiveDoorsT = useCallback(
    (door) => {
      const newDoors = doorsToRender.map((doorWithDetails) => {
        if (doorWithDetails.id === door) {
          return {
            ...doorWithDetails,
            active: !doorWithDetails.active,
          };
        } else {
          return {
            ...doorWithDetails,
            active: false,
          };
        }
      });
      setDoorsToRender(newDoors);
    },
    [doorsToRender]
  );

  const [
    updateUserFilterPreference,
    {
      data: userFilterPrefernceResponseT,
      loading: loadingUserFilterPrefernceT,
      error: userFilterPrefernceErrorT,
    },
  ] = useMutation(GQLService.mutations.updateUserFilterPreference);

  useEffect(() => {
    if (!loadingUserFilterPrefernceT && userFilterPrefernceResponseT) {
      setUserPreferenceFilter(
        userFilterPrefernceResponseT?.updateUserFilterPreference
      );
    }
  }, [userFilterPrefernceResponseT]);

  const handleSaveFilter = useCallback((userPref: any) => {
    updateUserFilterPreference({
      variables: userPref,
    });
  }, []);

  useEffect(() => {
    if (viewType === ViewType.IMG_SENSORS) {
      setIsPinned(false);
      getCamerasByFloorId({
        variables: {
          floorId: selectedFloor?.id!,
        },
      });
      getCameraOccupancyByFloorId({
        variables: {
          request: {
            floorId: selectedFloor?.id,
            from: formatedTime,
            to: timeTo,
            timeRangeType: timeRange,
            userEmail: userEmail,
          },
        },
      });
    }
  }, [
    formatedTime,
    getCameraOccupancyByFloorId,
    getCamerasByFloorId,
    selectedFloor?.id,
    timeRange,
    timeTo,
    viewType,
    isPinned,
  ]);

  let list;
  let legendComponent;

  const handleZoneClick = useCallback<
    (
      selectedCamera: ImageSensorWithOccupancy | null,
      selectedZoneId: string
    ) => void
  >(
    (selectedCamera, selectedZoneId) => {
      const camerasToUpdate = camerasToRender.slice().map((camera) => {
        const tripWires = camera.tripWires.slice().map((tripWire) => {
          return { ...tripWire, isSelected: false };
        });
        if (selectedCamera?.cameraUuid === camera.cameraUuid) {
          const zones = camera.zones.slice().map((zone) => {
            return { ...zone, isSelected: zone.id === selectedZoneId };
          });
          return { ...camera, zones, tripWires, active: true };
        } else {
          const zones = camera.zones.slice().map((zone) => {
            return { ...zone, isSelected: false };
          });
          return { ...camera, zones, tripWires, active: false };
        }
      });
      setCamerasToRender(camerasToUpdate);
    },
    [camerasToRender]
  );

  const handleTripWireClick = useCallback<
    (
      selectedCamera: ImageSensorWithOccupancy | null,
      selectedTripWireId: string
    ) => void
  >(
    (selectedCamera, selectedTripWireId) => {
      const camerasToUpdate = camerasToRender.slice().map((camera) => {
        const zones = camera.zones.slice().map((zone) => {
          return { ...zone, isSelected: false };
        });
        if (selectedCamera?.cameraUuid === camera.cameraUuid) {
          const tripWires = camera.tripWires.slice().map((tripWire) => {
            return {
              ...tripWire,
              isSelected: tripWire.id === selectedTripWireId,
            };
          });
          return { ...camera, zones, tripWires, active: true };
        } else {
          const tripWires = camera.tripWires.slice().map((tripWire) => {
            return { ...tripWire, isSelected: false };
          });
          return { ...camera, zones, tripWires, active: false };
        }
      });
      setCamerasToRender(camerasToUpdate);
    },
    [camerasToRender]
  );

  switch (viewType) {
    case ViewType.ACCESS_POINT:
      list = (
        // selectedTime === 1440 ? (
        //   <SideList
        //     selectedTime={selectedTime}
        //     dataT={accessPointsWithSpaceLoadToRenderTimeline}
        //     toggleActiveT={toggleActiveTimeline}
        //     handleIsPinned={handleIsPinned}
        //   />
        // ) :
        <SideList
          selectedTime={selectedTime}
          data={accessPointsWithSpaceLoadToRender}
          toggleActive={toggleActive}
          handleIsPinned={handleIsPinned}
          offHoursIsActive={offHoursIsActive}
        />
      );
      legendComponent = <OccupancyLegend />;
      break;
    case ViewType.SENSORS:
      list = (
        <SensorsSideList
          data={sensorsToRender}
          toggleActive={toggleActiveSensors}
          handleIsPinned={handleIsPinned}
          selectedTime={selectedTime}
        />
      );
      legendComponent = <SensorsLegend />;
      break;
    case ViewType.IMG_SENSORS:
      list = (
        <CamerasSideList
          dataWithSpaceLoad={camerasToRender}
          data={camerasToRender}
          toggleActive={toggleActiveCameras}
          floor={selectedFloor!}
          selectedTime={selectedTime}
          clickZone={handleZoneClick}
          clickTripWire={handleTripWireClick}
          handleIsPinned={handleIsPinned}
        />
      );
      legendComponent = <OccupancyLegend />;
      break;
    case ViewType.DOORS:
      list = (
        <DoorsSideList
          data={doorsToRender}
          selectedTime={selectedTime}
          floor={selectedFloor!}
          toggleActive={toggleActiveDoors}
        />
      );
      legendComponent = <OccupancyLegend />;
      break;
  }

  const temperatureUnit = () => {
    const findUnit = selectedProperty?.sensorConfigurations?.filter(
      (el) => el.attribute === "TEMPERATURE"
    );
    return findUnit![0].isCelsius;
  };

  const renderAP = () => {
    if (!dataLoading) {
      const accessPointShapes = (
        <>
          <Layer>
            {accessPointsWithSpaceLoadToRender?.map(
              ({ accessPoint, spaceLoad, active }, index) => {
                return (
                  <AccessPointShape
                    key={index}
                    radius={selectedFloor?.accessPointsRadius!}
                    accessPoint={accessPoint}
                    coordinateX={
                      accessPoint?.coordinateX! !== "null"
                        ? accessPoint?.coordinateX!
                        : undefined
                    }
                    coordinateY={
                      accessPoint?.coordinateY! !== "null"
                        ? accessPoint?.coordinateY!
                        : undefined
                    }
                    spaceLoad={spaceLoad}
                  />
                );
              }
            )}
          </Layer>
          <Layer>
            {accessPointsWithSpaceLoadToRender?.map(
              ({ accessPoint, spaceLoad, active }, index) => {
                return (
                  <AccessPointSolidShape
                    key={index}
                    coordinateX={
                      accessPoint?.coordinateX! !== "null"
                        ? accessPoint?.coordinateX!
                        : undefined
                    }
                    coordinateY={
                      accessPoint?.coordinateY! !== "null"
                        ? accessPoint?.coordinateY!
                        : undefined
                    }
                    accessPoint={accessPoint}
                    spaceLoad={spaceLoad}
                    toggleActive={toggleActive}
                    clickable={true}
                  />
                );
              }
            )}
          </Layer>
        </>
      );

      const activeAccessPoint = accessPointsWithSpaceLoadToRender.find(
        (accessPoint) => accessPoint.active
      );

      return (
        <>
          {accessPointShapes}
          {activeAccessPoint && (
            <Layer>
              <ActiveAccessPoint accessPoint={activeAccessPoint?.accessPoint} />
            </Layer>
          )}
        </>
      );
      //}
    }
  };

  const renderSpace = () => {
    if (!dataLoading) {
      const spaceShapes = spacesToRender?.placement! &&
        Object.keys(spacesToRender?.placement!).length > 0 && (
          <SpaceShape
            key={spacesToRender?.id}
            shape={{
              shape: spacesToRender!,
              spaceId: spacesToRender!.id,
              shapeType: ShapeType.SPACE,
            }}
          />
        );

      return <Layer>{spaceShapes}</Layer>;
    }
  };

  return (
    <Grid container={true} justify="space-between">
      <Grid>
        <Button
          className={clsx(
            classes.toolSwitch,
            viewType === ViewType.ACCESS_POINT ? classes.active : {}
          )}
          onClick={() => setViewType(ViewType.ACCESS_POINT)}
        >
          Occupancy
        </Button>
        <Button
          className={clsx(
            classes.toolSwitch,
            viewType === ViewType.SENSORS ? classes.active : {}
          )}
          onClick={() => setViewType(ViewType.SENSORS)}
        >
          Wellness Index
        </Button>
        <Button
          className={clsx(
            classes.toolSwitch,
            viewType === ViewType.IMG_SENSORS ? classes.active : {}
          )}
          onClick={() => setViewType(ViewType.IMG_SENSORS)}
        >
          Dwell Time & In/Out
        </Button>
        <Button
          className={clsx(
            classes.toolSwitch,
            viewType === ViewType.DOORS ? classes.active : {}
          )}
          onClick={() => setViewType(ViewType.DOORS)}
        >
          Ingress
        </Button>
      </Grid>
      <Grid
        className={classes.filters}
        container={true}
        item={true}
        justify="center"
        alignItems="flex-start"
      >
        <Filters
          viewType={viewType}
          SSIDs={SSIDs}
          spaces={spacesForFloor}
          userPreferenceFilter={userPreferenceFilter}
          handleSaveFilter={handleSaveFilter}
          loadingUserFilterPrefernce={loadingUserFilterPrefernce}
          setOffHoursIsActive={setOffHoursIsActive}
          offHoursIsActive={offHoursIsActive}
        />
      </Grid>
      <Grid
        container={true}
        justify="space-between"
        className={classes.occupanyWrapper}
      >
        {dataLoading && (
          <Grid className={classes.loader}>
            <Loader
              type="Oval"
              color={COLORS.funBlue}
              height={100}
              width={100}
            />
          </Grid>
        )}
        <Grid
          container
          item={true}
          md={8}
          sm={11}
          className={classes.canvasWrapper}
        >
          {imageSrc && !dataLoading && (
            <>
              <Grid item={true} md={2} style={{ minHeight: 675 }}>
                {list}
              </Grid>
              <Grid item md={10}>
                <Grid
                  item
                  container
                  sm={12}
                  className={classes.nameAndLegendContainer}
                >
                  <Grid item sm={3}>
                    <Typography className={classes.floorName}>
                      {selectedFloor?.name}
                    </Typography>
                  </Grid>
                  <Grid item>{legendComponent}</Grid>
                </Grid>
                {selectedFloor && selectedFloor?.smplrId ? (
                  <SmplrViewer
                    smplrId={selectedFloor?.smplrId}
                    accessPointsWithSpaceLoadToRender={
                      accessPointsWithSpaceLoadToRender
                    }
                    radius={selectedFloor?.accessPointsRadius!}
                    viewType={viewType}
                    sensor={sensorsToRender}
                  />
                ) : (
                  <Stage ref={konvaStageRef} width={900} height={700}>
                    <Layer>
                      {floorMapImage && (
                        <Image
                          x={0}
                          y={0}
                          width={870}
                          height={675}
                          image={floorMapImage}
                        />
                      )}
                    </Layer>
                    {viewType === ViewType.ACCESS_POINT && (
                      <>
                        {renderSpace()}
                        {renderAP()}
                      </>
                    )}
                    {viewType === ViewType.IMG_SENSORS && (
                      <>
                        {renderSpace()}
                        <Layer>
                          {camerasToRender &&
                            camerasToRender?.map((camera, cameraIndex) => (
                              <Group key={cameraIndex}>
                                <CameraShape
                                  coordinateX={camera.coordinateX}
                                  coordinateY={camera.coordinateY}
                                />
                                {camera.zones
                                  .filter(
                                    (zone) =>
                                      zone.placement &&
                                      Object.keys(zone.placement).length > 0
                                  )
                                  .map(
                                    (zone) =>
                                      ({
                                        shape: zone,
                                        isSelected: zone.isSelected,
                                        shapeType: ShapeType.ZONE,
                                      } as ZoneShapeProps)
                                  )
                                  //Sorting to draw zone with higher height and width at the bottom
                                  .sort((a, b) => {
                                    let x =
                                      (a &&
                                        parseInt(
                                          a?.shape?.placement?.height as string
                                        ) *
                                          parseInt(
                                            a?.shape?.placement?.width as string
                                          )) ||
                                      0;
                                    let y =
                                      (b &&
                                        parseInt(
                                          b?.shape?.placement?.height as string
                                        ) *
                                          parseInt(
                                            b?.shape?.placement?.width as string
                                          )) ||
                                      0;

                                    return x < y ? 1 : -1;
                                  })
                                  .map((zone, index) => (
                                    <CameraZone
                                      key={index}
                                      shape={zone}
                                      spaceLoad={zone?.shape?.spaceLoad}
                                      selectedCamera={camera}
                                      toggleActive={handleZoneClick}
                                      clickable={true}
                                    />
                                  ))}
                              </Group>
                            ))}
                          {selectedCameraZone?.placement &&
                            Object.keys(selectedCameraZone?.placement).length >
                              0 && (
                              <ActiveCameraZone shape={selectedCameraZone} />
                            )}
                        </Layer>

                        <Layer>
                          {camerasToRender &&
                            camerasToRender?.map((camera, cameraIndex) => (
                              <Group key={cameraIndex}>
                                {camera.tripWires
                                  .filter(
                                    (tripwire) =>
                                      tripwire.placement &&
                                      Object.keys(tripwire.placement).length > 0
                                  )
                                  .map(
                                    (tripwire) =>
                                      ({
                                        shape: tripwire,
                                        isSelected: tripwire.isSelected,
                                        shapeType: ShapeType.TRIPWIRE,
                                      } as TripwireShapeProps)
                                  )
                                  .map((tripwire, index) => (
                                    <CameraTripwire
                                      key={index}
                                      shape={tripwire}
                                      spaceLoad={tripwire?.shape?.spaceLoad}
                                      selectedCamera={camera}
                                      toggleActive={handleTripWireClick}
                                      clickable={true}
                                    />
                                  ))}
                              </Group>
                            ))}
                          {selectedTripwire?.placement &&
                            Object.keys(selectedTripwire?.placement).length >
                              0 && (
                              <ActiveCameraTripwire shape={selectedTripwire} />
                            )}
                        </Layer>
                      </>
                    )}
                    {viewType === ViewType.SENSORS && (
                      <>
                        {renderSpace()}
                        <Layer>
                          {sensorsToRender &&
                            sensorsToRender.map((sensor, index) => (
                              <AirQualitySensor
                                coordinateX={sensor.airSensor.coordinateX}
                                coordinateY={sensor.airSensor.coordinateY}
                                sensor={sensor}
                                toggleActive={toggleActiveSensors}
                                clickable={true}
                              />
                            ))}
                          {selectedAirQualitySensor && (
                            <ActiveAirQualitySensorDetails
                              sensor={selectedAirQualitySensor}
                              temperatureUnit={temperatureUnit()}
                            />
                          )}
                        </Layer>
                      </>
                    )}
                    {viewType === ViewType.DOORS && (
                      <Layer>
                        {doorsToRender &&
                          doorsToRender.map((door, index) => (
                            <DoorShape
                              coordinateX={
                                door.coordinateX !== "null"
                                  ? door.coordinateX
                                  : undefined
                              }
                              coordinateY={
                                door.coordinateY !== "null"
                                  ? door.coordinateY
                                  : undefined
                              }
                              door={door}
                              toggleActive={toggleActiveDoorsT}
                            />
                          ))}
                        {selectedDoor && (
                          <ActiveDoorDetails door={selectedDoor} />
                        )}
                        {selectedDoor && (
                          <ActiveDoorDetails door={selectedDoor} />
                        )}
                      </Layer>
                    )}
                  </Stage>
                )}
              </Grid>
            </>
          )}
          <Grid md={8} style={{ marginTop: 40 }}>
            {/* {selectedTime === 1440 && viewType === ViewType.ACCESS_POINT ? (
              <HourSlider />
            ) :  */}
            {<></>}
          </Grid>
        </Grid>

        {!imageSrc && !dataLoading && (
          <NoFloorPlan property={selectedProperty} floor={selectedFloor} />
        )}
      </Grid>
    </Grid>
  );
}
