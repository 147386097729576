import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Container,
  Breadcrumbs,
  Link,
  Grid,
  Typography,
} from "@material-ui/core";

import { Avatar } from "../../common/components";
import { DrawerNav } from "./components/DrawerNav/DrawerNav";
import { ContextMenu } from "./components/ContextMenu/ContextMenu";
import { Search } from "./components/Search/Search";
import { usePropertiesState } from "../../core/context/containers/Properties.container";
import { useStyles } from "./Dashboard.styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useUserState } from "../../core/context/containers/User.container";
import GQLService from "../../core/services/GQL.service";
import { useQuery } from "@apollo/client";
import NotificationDropdown from "../../common/components/Notification/NotificationDropdown";
import { AllContainers } from "../../core/context/containers/AllContainers";
import { ROUTES } from "../../common/constants/Routing";
import _ from "lodash";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../common/constants/DummyProperty";

const EXCLUDED_BREADCRUMBS = [
  "",
  "property",
  "bay",
  "edit",
  "view",
  "tripWire",
  "zone",
  "new",
];

interface DashboardProps {
  children: React.ReactNode;
}

export function Dashboard({ children }: DashboardProps) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const {
    selectedProperty,
    setPropertiesFromResponse,
    setProperties,
    setLoading,
  } = usePropertiesState();
  const { selectedUser, userEmail } = useUserState();
  const {
    selectedFloor,
    selectedSpace,
    selectedDoor,
    selectedVLAN,
    selectedSSID,
    selectedAccessPoint,
    selectedSensor,
    selectedImagesSensor,
    selectedTripWire,
    selectedZone,
    selectedBeacon,
    selectedElevator,
    selectedElevatorBay,
  } = AllContainers();

  const { loading, data } = useQuery(GQLService.queries.allProperties, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(
    () => {
      let isMounted = true;
      if (isMounted && data) {
        if (userEmail === dummyUserEmail) {
          let clonedData = _.cloneDeep(data?.properties);
          const newProperties = clonedData?.map((item: any) => {
            if (item.id === DummyPropertyA.id) {
              const propA = {
                ...item,
                ownerCompanyName: DummyPropertyA.ownerCompanyName,
                siteName: DummyPropertyA.siteName,
                siteServiceAddress: DummyPropertyA.siteServiceAddress,
              };
              return propA;
            } else if (item.id === DummyPropertyB.id) {
              const propB = {
                ...item,
                ownerCompanyName: DummyPropertyB.ownerCompanyName,
                siteName: DummyPropertyB.siteName,
                siteServiceAddress: DummyPropertyB.siteServiceAddress,
              };
              return propB;
            } else if (item.id === DummyPropertyC.id) {
              const propC = {
                ...item,
                ownerCompanyName: DummyPropertyC.ownerCompanyName,
                siteName: DummyPropertyC.siteName,
                siteServiceAddress: DummyPropertyC.siteServiceAddress,
              };
              return propC;
            } else if (item.id === DummyPropertyD.id) {
              const propD = {
                ...item,
                ownerCompanyName: DummyPropertyD.ownerCompanyName,
                siteName: DummyPropertyD.siteName,
                siteServiceAddress: DummyPropertyD.siteServiceAddress,
              };
              return propD;
            } else {
              return item;
            }
          });
          const demoUserProps = newProperties?.filter(
            (item: any) =>
              item.id === DummyPropertyA.id ||
              item.id === DummyPropertyB.id ||
              item.id === DummyPropertyC.id ||
              item.id === DummyPropertyD.id
          );
          setProperties(demoUserProps || []);
          setPropertiesFromResponse(demoUserProps || []);
        } else {
          setProperties(data?.properties || []);
          setPropertiesFromResponse(data?.properties || []);
        }
      }
      return () => {
        isMounted = false;
      };
    },
    [data, userEmail] // eslint-disable-line
  );

  const { locations, pathnames } = useMemo(() => {
    const locations = location.pathname
      .split("/")
      .filter((x) => !EXCLUDED_BREADCRUMBS.includes(x));

    const formatUsers = (path: string) => {
      if (location.pathname.includes("new")) {
        return path;
      }
      return selectedUser?.fullName!;
    };

    const pathnames = locations.reduce(
      (acc: string[], curr: string, index: number) => {
        let pathName;
        switch (true) {
          case index === 2:
            locations.includes("users")
              ? (pathName = formatUsers(curr))
              : (pathName = selectedProperty?.ownerCompanyName!);
            break;
          case locations.includes("floors") && index === 4:
            if (locations.includes("add")) {
              pathName = "Add";
            } else {
              pathName = selectedFloor?.name
                ? selectedFloor?.name
                : selectedFloor?.floorNumber;
            }
            break;
          case locations.includes("spaces") && index === 4:
            if (locations.includes("add")) {
              pathName = "Add";
            } else {
              pathName = selectedSpace?.name;
            }
            break;
          case locations.includes("doors") && index === 4:
            if (locations.includes("add")) {
              pathName = "Add";
            } else {
              pathName = selectedDoor?.name;
            }
            break;
          case locations.includes("groups") && index === 4:
            if (locations.includes("add")) {
              pathName = "Add";
            }
            break;
          case locations.includes("network") && index === 4:
            if (locations.includes("add")) {
              pathName = "Add";
            } else {
              pathName = selectedVLAN?.name;
            }
            break;
          case locations.includes("ssids") && index === 4:
            if (locations.includes("add")) {
              pathName = "Add";
            } else {
              pathName = selectedSSID?.name;
            }
            break;
          case locations.includes("access-points") && index === 4:
            if (locations.includes("add")) {
              pathName = "Add";
            } else {
              pathName = selectedAccessPoint?.name;
            }
            break;
          case locations.includes("air-quality-sensors") && index === 4:
            if (locations.includes("add")) {
              pathName = "Add";
            } else {
              pathName = selectedSensor?.name;
            }
            break;
          case locations.includes("image-sensors") && index === 4:
            pathName = selectedImagesSensor?.name
              ? selectedImagesSensor?.name
              : selectedImagesSensor?.cameraUuid;
            break;
          case locations.includes("image-sensors") && index === 5:
            if (selectedTripWire) {
              pathName = selectedTripWire?.name
                ? selectedTripWire?.name
                : selectedTripWire?.tripWire;
            }
            if (selectedZone) {
              pathName = selectedZone?.name
                ? selectedZone?.name
                : selectedZone?.zone;
            }
            break;
          case locations.includes("beacons") && index === 4:
            if (locations.includes("add")) {
              pathName = "Add";
            } else {
              pathName = selectedBeacon?.name;
            }
            break;
          case locations.includes("elevators") && index === 4:
            if (locations.includes("add")) {
              pathName = "Add";
            } else {
              pathName = selectedElevator?.name;
            }
            break;
          case locations.includes("elevatorBays") && index === 4:
            if (locations.includes("add")) {
              pathName = "Add";
            } else {
              pathName = selectedElevatorBay?.name;
            }
            break;
          default:
            pathName = curr.split("-").join(" ");
        }
        acc = pathName === undefined ? acc : acc.concat(pathName);
        return acc;
      },
      [] as string[]
    );
    return { locations, pathnames };
  }, [location.pathname, history]); // eslint-disable-line

  const handleBreadcrumbClick = useCallback(
    (path: string) => {
      const addedPath = path === "new" ? 0 : path.length;
      let redirectedLink = location.pathname.substring(
        0,
        location.pathname.indexOf(path) + addedPath
      );
      if (redirectedLink.includes("image-sensors/" + path)) {
        redirectedLink = redirectedLink + "/edit";
      }
      history.push(redirectedLink);
    },
    [history, location.pathname]
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.headerWrapper}>
            <Typography id="mainPageTitle" className={classes.pageHeader}>
              {pathnames[pathnames.length - 1] === "dashboard" ||
              pathnames[pathnames.length - 1] === "floor overview" ||
              pathnames[pathnames.length - 1] === "all notifications" ||
              pathnames[pathnames.length - 1] === "my pins" ||
              pathnames[pathnames.length - 1] === "portfolio"
                ? pathnames[pathnames.length - 1]
                    .toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")
                : ""}
            </Typography>
          </div>
          {pathnames[pathnames.length - 1] === "portfolio" ? <></> : <Search />}

          <NotificationDropdown />

          <Grid className={classes.gridWrapper}>
            <Avatar />
            <ContextMenu />
          </Grid>
        </Toolbar>
      </AppBar>
      <DrawerNav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Breadcrumbs
            separator={
              <NavigateNextIcon
                fontSize="small"
                className={classes.separator}
              />
            }
            className={classes.breadcrumbs}
          >
            {locations.length > 2 &&
              locations.map((location, index) => {
                const isLast = index === locations.length - 1;
                return isLast ? (
                  <Typography variant="inherit" key={location}>
                    {pathnames[index]}
                  </Typography>
                ) : (
                  <Link
                    key={location}
                    color="inherit"
                    onClick={() => handleBreadcrumbClick(location)}
                  >
                    {pathnames[index]}
                  </Link>
                );
              })}
          </Breadcrumbs>
          {children as React.ReactElement}
        </Container>
      </main>
    </div>
  );
}
