import React from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Grid, CardMedia, Typography, Button } from "@material-ui/core";

import loginImg from "../../../common/assets/images/login.jpg";
import { ROUTES } from "../../../common/constants/Routing";
import { AppLogo } from "../../../common/components";
import { useStyles } from "./PasswordRecoverySuccess.styles";

export function PasswordRecoverySuccess() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleBackButtonClick = () => {
    history.push(ROUTES.SIGN_IN);
  };

  return (
    <Grid container={true} alignItems="center">
      <Grid className={classes.container} item={true} md={3} sm={12}>
        <AppLogo style={classes.logo} />
        <Typography className={classes.recoverySuccessText} variant="inherit">
          {formatMessage({
            id: "passwordRecoverySuccess.resetSent",
            defaultMessage: "We’ve sent your password reset",
            description: "password recovery succes sent information",
          })}
        </Typography>
        <Typography className={classes.catchline}>
          {formatMessage({
            id: "passwordRecoverySuccess.resetInfo",
            defaultMessage:
              "If you have an account with the email address you entered, you should receive the password reset email in your inbox.",
            description: "password recovery succes sent information",
          })}
        </Typography>
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleBackButtonClick}
        >
          {formatMessage({
            id: "passwordRecoverySuccess.button.back",
            defaultMessage: "Back to Sign In",
            description: "password recovery succes back button label",
          })}
        </Button>
      </Grid>
      <Grid item={true} md={9} sm={12}>
        <CardMedia className={classes.img} image={loginImg} component="img" />
      </Grid>
    </Grid>
  );
}
