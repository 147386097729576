import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, makeStyles, Button, Typography, Input } from "@material-ui/core";
import * as _ from "lodash";
import { CustomModal } from "../../../../common/components";
import { ImportBulkAPModal } from "./ImportBulkAPModal";
import Papa from "papaparse";
import { APP_DEFAULTS } from "../../../../common/constants/App";
import * as csvConstants from "../../../../types/csvConstants";

const useStyles = makeStyles((theme) => ({
  okBtn: {
    color: theme.palette.common.funBlue,
    width: 120,
    height: 42,
    borderRadius: 20,
    border: `1px solid ${theme.palette.common.funBlue}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "none",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.common.mediumBlack,
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginBottom: "40px",
    marginRight: "20px",
  },
  content: {
    display: "flex",
    width: "555px",
    height: "411px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    paddingTop: "32px",
    paddingLeft: "23px",
    paddingBottom: "16px",
    borderRadius: "20px",
    boxShadow: "0px 4px 21px rgba(0, 0, 0, 0.38)",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "16px",
  },
  loaderGrid: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  input: {
    width: 368,
    height: 40,
    border: `1px solid ${theme.palette.common.inputGrey}`,
    borderRadius: 10,
    paddingLeft: 10,
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: 20,
    marginRight: "30px",
  },
  templateDescription: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginBottom: "30px",
  },
  cancel: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 100,
    height: 42,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  anchorTag: {
    textDecoration: "none",
    color: "#2056a6",
    fontWeight: 500,
  },
}));

interface BulkUploadAPModalProps {
  yes: Function;
}

export function BulkUploadAPModal({ yes }: BulkUploadAPModalProps) {
  const classes = useStyles();
  const [uploadFile, setUploadFile] = useState(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [csvToJson, setCsvToJson] = useState<any>(null);
  const [duplicateData, setDuplicateData] = useState<boolean>(false);
  const [url, setUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const confirmCancel = () => {
    yes();
  };

  const handleImport = () => {
    if (csvToJson) {
      let validateMacAddressData = csvToJson.map((item: any) => {
        return item[csvConstants.BASE_MAC_ADDRESS];
      });

      let isDuplicateMacAddress =
        validateMacAddressData.length !== _.uniq(validateMacAddressData).length;

      let validateIPAddressData = csvToJson.map((item: any) => {
        return item[csvConstants.IPADDRESS];
      });

      let removeEmptyIP = validateIPAddressData.filter((el: any) => el !== "");
      let isDuplicateIPAddress =
        removeEmptyIP.length !== _.uniq(removeEmptyIP).length;

      let validatePhysicalLocationData = csvToJson.filter((item: any) => {
        return (
          isNaN(item[csvConstants.PHYSICAL_LOCATION]) ||
          item[csvConstants.PHYSICAL_LOCATION] === ""
        );
      });

      let macAddressNotNull = validateMacAddressData.some((item: any) => {
        return item === "";
      });

      let apNameNotNull = csvToJson.filter((item: any) => {
        return item[csvConstants.AP_NAME] === "";
      });

      let validateRadioBands = csvToJson.filter((item: any) => {
        return (
          isNaN(item[csvConstants.RADIO_1_BAND]) ||
          isNaN(item[csvConstants.RADIO_2_BAND])
        );
      });

      let validateRadioBandValue = csvToJson.filter((item: any) => {
        return (
          (item[csvConstants.RADIO_1_BAND] !== "2.4" &&
            item[csvConstants.RADIO_1_BAND] !== "5") ||
          (item[csvConstants.RADIO_2_BAND] !== "2.4" &&
            item[csvConstants.RADIO_2_BAND] !== "5")
        );
      });

      const REGEX = /[^A-Za-z0-9;\s]/;
      let radio1SsidValidation = csvToJson.filter((item: any) => {
        return REGEX.test(item[csvConstants.SSID_RADIO_1]);
      });

      let radio2SsidValidation = csvToJson.filter((item: any) => {
        return REGEX.test(item[csvConstants.SSID_RADIO_2]);
      });

      if (
        isDuplicateMacAddress ||
        isDuplicateIPAddress ||
        validatePhysicalLocationData?.length! > 0 ||
        macAddressNotNull ||
        apNameNotNull?.length > 0 ||
        radio1SsidValidation.length > 0 ||
        radio2SsidValidation.length > 0 ||
        validateRadioBands.length > 0 ||
        validateRadioBandValue.length > 0
      ) {
        setDuplicateData(true);
      } else {
        setShowModal(true);
      }
      if (isDuplicateMacAddress || macAddressNotNull) {
        if (macAddressNotNull) {
          setErrorMessage("The Base mac address could not be empty.");
        } else {
          setErrorMessage(
            "The Base mac address is found duplicate across multiple Access Points."
          );
        }
      } else if (isDuplicateIPAddress) {
        setErrorMessage(
          "The IP address is found duplicate across multiple Access Points."
        );
      } else if (validatePhysicalLocationData?.length! > 0) {
        setErrorMessage(
          "The Physical Location must be a number and could not be empty."
        );
      } else if (apNameNotNull?.length > 0) {
        setErrorMessage("The Access Point name could not be empty.");
      } else if (
        radio1SsidValidation.length > 0 ||
        radio2SsidValidation.length > 0
      ) {
        setErrorMessage("SSID should be separated using ;");
      } else if (validateRadioBands.length > 0) {
        setErrorMessage("Radio Band should be number ");
      } else if (validateRadioBandValue.length > 0) {
        setErrorMessage("Radio Bands should be 2.4 or 5 ");
      }
    }
  };

  const handleFile = (e: any) => {
    if (e.target.files[0].type === "text/csv") {
      setUploadFile(e.target.files[0].name);
      Papa.parse(e.target.files[0], {
        complete: function (results) {
          setCsvToJson(results.data);
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  };

  const rejectChanges = useCallback(() => setShowModal(false), []);

  const handleImportButtonClick = () => {
    setShowModal(false);
    yes();
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken") as string;
    fetch(`${APP_DEFAULTS.baseApiUrl}/ap/template`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        responseType: `blob`,
        "Content-Type": "application/csv",
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        setUrl(url);
      });
  }, []);

  const handleTemplateLink = () => {
    const token = localStorage.getItem("accessToken") as string;
    fetch(`${APP_DEFAULTS.baseApiUrl}/ap/template`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        responseType: `blob`,
        "Content-Type": "application/csv",
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        setUrl(url);
      });
  };

  return (
    <Grid>
      <Grid className={classes.content}>
        <Grid>
          <Typography className={classes.header}>
            Add Multiple Access Points
          </Typography>
        </Grid>
        <Grid>
          <Typography className={classes.description}>
            {duplicateData && errorMessage !== ""
              ? errorMessage
              : "Select CSV file to import."}
          </Typography>
          <Grid style={{ display: "flex" }}>
            <Input
              type="text"
              value={uploadFile}
              className={classes.input}
              disableUnderline={true}
              disabled={true}
              placeholder={"Select the file"}
              style={{
                backgroundColor: duplicateData
                  ? "rgba(255, 0, 0, 0.2)"
                  : "#ffffff",
                color: duplicateData ? "#B0001F" : "#122036",
              }}
            />
            <Button
              component="label"
              className={classes.okBtn}
              style={{ backgroundColor: "#2056a6", color: "#ffffff" }}
            >
              <Input
                type="file"
                style={{ display: "none" }}
                onChange={(e: any) => handleFile(e)}
              />
              Select
            </Button>
          </Grid>
          {duplicateData ? (
            <Typography className={classes.templateDescription}>
              Do the required changes and upload the file again
            </Typography>
          ) : (
            <Typography className={classes.templateDescription}>
              Download{" "}
              <a
                href={url}
                className={classes.anchorTag}
                onClick={handleTemplateLink}
                download={"ap_template.csv"}
              >
                a template file{" "}
              </a>
              to get started
            </Typography>
          )}
        </Grid>
        <Grid>
          <Grid item className={classes.buttonsWrapper}>
            <Button
              id="confirmBtn"
              className={classes.cancel}
              onClick={() => confirmCancel()}
            >
              Cancel
            </Button>
            <Button
              id="confirmBtn"
              className={classes.okBtn}
              onClick={() => handleImport()}
              style={{
                backgroundColor: uploadFile ? "#2056a6" : "transparent",
                color: uploadFile ? "#ffffff" : "#2056a6",
              }}
              disabled={uploadFile ? false : true}
            >
              Import
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CustomModal open={showModal}>
        <ImportBulkAPModal
          yes={rejectChanges}
          data={csvToJson}
          importButtonClick={handleImportButtonClick}
        />
      </CustomModal>
    </Grid>
  );
}
