import React, { useState, useCallback } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  AccordionActions,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, People } from "@material-ui/icons";

import { Group } from "../../../../types";
import { StatusIndicator } from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useGroupState } from "../../../../core/context/containers/Groups.container";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { listStyles } from "../../../../common/styles/lists";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    ...listStyles.subheader,
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
  extendedListSubheader: {
    ...listStyles.extendedListSubheader,
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  container: {
    margin: "10px 30px",
  },
  cell: {
    minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  imagesSensorsInfo: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.regularBlue,
  },
  content: {},
  accordionDetails: {
    paddingBottom: "40px",
    flexDirection: "column",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
}));

interface GroupItemProps {
  item: Group;
}

export function GroupListItem({ item }: GroupItemProps) {
  const classes = useStyles();
  const { selectedProperty } = usePropertiesState();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const handleExpand = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const { setSelectedGroup } = useGroupState();
  const history = useHistory();

  const handleViewClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
      e.preventDefault();
      e.stopPropagation();
      setSelectedGroup(item);
      history.push(
        ROUTES.GROUPS.replace(":uid", selectedProperty!.id).replace(
          ":group",
          item.name
        )
      );
    },
    [history, item, selectedProperty, setSelectedGroup]
  );

  return (
    <Accordion key={item.id} expanded={isExpanded}>
      <AccordionSummary onClick={(e) => handleViewClick(e, item.id)}>
        <Grid container={true} alignItems="center" style={{ margin: 0 }}>
          <Grid
            container={true}
            item={true}
            md={3}
            sm={3}
            justify="flex-start"
            onClick={handleExpand}
            className={classes.headerWrapper}
          >
            {isExpanded ? (
              <ExpandLess
                id={`expandLess-${item.id}`}
                className={classes.expandIcon}
              />
            ) : (
              <ExpandMore
                id={`expandMore-${item.id}`}
                className={classes.expandIcon}
              />
            )}
            <People className={classes.icon} />
            <Typography className={classes.name}>{item.name}</Typography>
          </Grid>
          <Grid item={true} md={1} sm={3}>
            <Typography className={classes.subheader}>
              {item.users.length} Users
            </Typography>
          </Grid>
        </Grid>

        <Grid item className={classes.flex}>
          {selectedProperty && (
            <StatusIndicator
              uid={selectedProperty?.id}
              isActive={item.active}
              canShowStatusLabel={false}
            />
          )}
        </Grid>
        <AccordionActions
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        ></AccordionActions>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container={true} className={classes.container}>
          <Grid item sm={3} md={2}>
            <Typography className={classes.subheader}>Users</Typography>
            <Grid className={classes.flex}>
              <Typography className={classes.imagesSensorsInfo}>
                {item.users.length} Users
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={3} md={3}>
            <Typography className={classes.subheader}>Date Created</Typography>
            <Typography className={classes.imagesSensorsInfo}>
              {moment(item.dateCreated).format("MM/D/YYYY h:mm a")}
            </Typography>
          </Grid>
          <Grid item sm={2} md={2}>
            <Typography className={classes.subheader}>Start Date</Typography>
            <Typography className={classes.imagesSensorsInfo}>
              {moment(item.startDate).format("MM/D/YYYY")}
            </Typography>
          </Grid>
          <Grid item sm={2} md={2}>
            <Typography className={classes.subheader}>End Date</Typography>
            <Typography className={classes.imagesSensorsInfo}>
              {moment(item.lastDate).format("MM/D/YYYY")}
            </Typography>
          </Grid>
          <Grid item sm={2} md={3}>
            <Typography className={classes.subheader}>Status</Typography>
            <Grid item className={classes.flex}>
              {selectedProperty && (
                <>
                  <StatusIndicator
                    uid={selectedProperty?.id}
                    isActive={item.active}
                    canShowStatusLabel={false}
                  />
                  <span style={{ display: "inline-block", paddingLeft: "8px" }}>
                    {item.active ? "Active" : "Inactive"}
                  </span>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
