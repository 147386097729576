import React from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 100,
    height: 42,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 100,
    height: 42,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(28),
    color: theme.palette.common.mediumBlack,
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    paddingTop: "24px",
    paddingRight: "16px",
  },
  content: {
    display: "flex",
    minWidth: "350px",
    minHeight: "250px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    paddingTop: "32px",
    paddingLeft: "23px",
    paddingBottom: "16px",
    borderRadius: "20px",
    boxShadow: "0px 4px 21px rgba(0, 0, 0, 0.38)",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: "16px",
  },
}));

interface ModalContentProps {
  headerText?: string;
  no: Function;
  yes: Function;
}

export function ModalContent({ headerText = "", no, yes }: ModalContentProps) {
  const classes = useStyles();

  return (
    <Grid className={classes.content}>
      <Grid>
        <Typography className={classes.header}>{headerText}</Typography>
        <Typography className={classes.description}>
          The selected notification will be deleted from the list
        </Typography>
      </Grid>
      <Grid item className={classes.buttonsWrapper}>
        <Button id="cancelBtn" className={classes.cancel} onClick={() => no()}>
          Cancel
        </Button>
        <Button
          id="confirmBtn"
          className={classes.deleteBtn}
          onClick={() => yes()}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
}
