import { useToolbarState } from "../../containers/FloorPlanEditor/Toolbar.container";
import { Tool } from "../../../../common/constants/FloorPlanEditor";

export const useToolbar = () => {
  const { setSelectedTool } = useToolbarState();

  const selectTool = (tool: Tool | null) => {
    setSelectedTool(tool);
  };

  return {
    selectTool,
  };
};
