import Rules from "../common/constants/Rules";
import { availableScopes } from "./roles";

export type Credentials = {
  username: string;
  password: string;
};

export type User = {
  id?: string;
  fullName?: string | null;
  email?: string;
  isAnonymous?: boolean;
  phone?: string;
  role?: Role;
  hasAcceptedCustomerAgreement?: boolean;
  canPerformBenchmarks?: boolean;
  enabled?: boolean;
  properties?: any[];
  creationDate?: string;
  checked?: boolean;
  defaultProperty?: {
    id?: string;
  };
  image?: string;
};

export enum Role {
  ADMIN = "JOIN_ADMIN",
  PROPERTY_ADMIN = "PROPERTY_ADMIN",
  TENANT = "TENANT",
}

export type PasswordResetFormData = {
  newPassword: string;
  retypedPassword: string;
  hasAcceptedCustomerAgreement: boolean;
};

export type AccessToken = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  username: string;
  expires_in: number;
};

export type AuthResponse = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  username: string;
  expires_in: number;
  roles: Role[];
};

// Common types and interfaces
export interface IUser {
  id?: string;
  fullName?: string | null;
  email?: string;
  isAnonymous?: boolean;
  phone?: string;
  role?: Role;
  hasAcceptedCustomerAgreement?: boolean;
  canPerformBenchmarks?: boolean;
  enabled?: boolean;
  properties?: any[];
  creationDate?: string;
  defaultProperty?: {
    id?: string;
  };
  image?: string;
}

// Auth Client
export type OnTokenExpiredConfig = {
  minValidity: number;
  onSuccess?: (refreshed: boolean) => void;
  onError?: () => void;
};

export type LoginWithEmailAndPasswordParams = {
  username: string;
  password: string;
};

export type CheckAccessOptions = {
  permissions: string[];
};

export type OnUpdateHandler = () => void;

export interface IAuthClient {
  init(): any;
  loginWithEmailAndPassword(params: LoginWithEmailAndPasswordParams): any;
  logout(): any;
  getIsInitialized(): boolean;
  getIsAuthenticated(): boolean;
  getToken(): string;
  getUser(): IUser | null;
  getFreshToken(): Promise<string>;
  checkAccess(options: CheckAccessOptions): boolean;
  registerOnUpdateHandler(handler: OnUpdateHandler): void;
  canSee(
    role: keyof typeof Rules,
    action: availableScopes[number],
    Component: JSX.Element
  ): JSX.Element | null;
  isAnyScopeAvaialable(
    role: keyof typeof Rules,
    actions: availableScopes[number][]
  ): boolean;
  updateUser(user: IUser): void;
  startCheckingTokens(): void;
}

// Auth Context
export interface IAuthContext {
  isInitialized: boolean;
  isAuthenticated: boolean;
  token: string;
  refreshToken: string;
  user: IUser | null;
  loginWithEmailAndPassword: IAuthClient["loginWithEmailAndPassword"];
  logout: IAuthClient["logout"];
  getToken: IAuthClient["getToken"];
  getFreshToken: IAuthClient["getFreshToken"];
  init: IAuthClient["init"];
  checkAccess: IAuthClient["checkAccess"];
  canSee: IAuthClient["canSee"];
  updateUser: IAuthClient["updateUser"];
  startCheckingTokens: IAuthClient["startCheckingTokens"];
  isAnyScopeAvaialable: IAuthClient["isAnyScopeAvaialable"];
}

export type AuthContextProps = IAuthContext | null;
