import React, { useCallback, useState } from "react";
import { FieldInputProps } from "formik";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./SurveyActiveCheckbox.styles";
import { useIntl } from "react-intl";

interface SurveyActiveProps extends FieldInputProps<any> {
  defaultValue: boolean;
  disabled?: boolean;
}

export function SurveyActiveCheckbox({
  name,
  defaultValue = false,
  disabled = false,
  onChange,
}: SurveyActiveProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const [isSwitchActive, setIsSwitchActive] = useState<boolean>(defaultValue);

  const handleChange = useCallback(
    (e) => {
      setIsSwitchActive(!isSwitchActive);
      onChange!({
        ...e,
        target: { name, value: !isSwitchActive },
      });
    },
    [isSwitchActive, onChange, name]
  );

  return (
    <Grid key={name} container={true} item={true} direction="row">
      <Grid item className={classes.root}>
        <Checkbox
          value={isSwitchActive}
          checked={isSwitchActive}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          classes={{ root: classes.checkbox }}
          disabled={disabled}
          onChange={handleChange}
        />
        <Typography className={classes.skipFloorParagraph}>
          {formatMessage({
            id: "surveyActive.label",
            defaultMessage: 'Survey to be filled by every tenant',
            description: 'Survey to be filled by every tenant',
          })}
        </Typography>
      </Grid>
    </Grid>
  );
}
