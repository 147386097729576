import React, { useCallback } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import { PasswordResetForm } from "./components/PasswordResetForm/PasswordResetForm";
import { useAuth } from "../../../core/context/functions/Auth.functions";
import { ROUTES } from "../../../common/constants/Routing";
import { useStyles } from "./PasswordReset.styles";
import GQLService from "../../../core/services/GQL.service";

export function PasswordReset(props: any) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleSubmit = useCallback(async (newPassword) => {
    const pathname = props.location.pathname.split("/");
    try {
      await GQLService.rest.auth.setNewUserPassword(
        newPassword,
        pathname[pathname.length - 1]
      );
      history.push(ROUTES.SIGN_IN);
    } catch (err) {
      return;
    }
  }, []);

  return (
    <Grid
      className={classes.container}
      container={true}
      alignItems="center"
      justify="center"
    >
      <Grid className={classes.box} item={true} md={3} sm={10}>
        <Grid
          className={classes.form}
          container={true}
          alignItems="center"
          justify="center"
        >
          <Typography className={classes.headerText} variant="inherit">
            {formatMessage({
              id: "passwordReset.header",
              defaultMessage: "Just one more step!",
              description: "password reset header",
            })}
          </Typography>
          <Typography className={classes.catchline}>
            {formatMessage({
              id: "passwordReset.catchline",
              defaultMessage:
                "Before you can start using Join, you must change your password and agree to our terms of service.",
              description: "password reset info",
            })}
          </Typography>
          <PasswordResetForm onSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </Grid>
  );
}
