import React, { useState, useCallback } from "react";
import {
  Menu,
  MenuItem,
  MenuList,
  makeStyles,
  Tooltip,
  Typography,
  Grid,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import { useZoneState } from "../../../../../../core/context/containers/Zone.container";
import { useImagesSensorsState } from "../../../../../../core/context/containers/ImageSensor.container";
import { ImagesSensor, Zone } from "../../../../../../types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../common/constants/Routing";
import { ReactComponent as HamburgerMenu } from "../../../../../../common/assets/images/hamburger.svg";
import { CanSee } from "../../../../../../common/components/canSee/canSee";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useTripWireState } from "../../../../../../core/context/containers/TripWire.container";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 15,
    minHeight: 15,
    maxWidth: 15,
    maxHeight: 15,
    color: theme.palette.common.funBlue,
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    // borderBottom: `1px solid ${theme.palette.common.nobel}`,
    // "&:last-child": {
    //   border: "none",
    // },
  },
  infoIcon: {
    fontSize: 15,
    color: "#2F56A0",
  },
  toolTipDiv: {
    backgroundColor: "#F2F2F2",
    border: "1px solid #81D3E0",
    borderRadius: "3px",
    boxShadow: "0px 6px 6px 2px rgba(0, 0, 0, 0.25)",
    paddingTop: "14px",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  toolTipContent: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkBlue,
    marginBottom: "12px",
  },
}));

interface ZoneMenuProps {
  imageSensor: ImagesSensor;
  zone: Zone;
  setShowModal: (el: any) => void;
  setShowdltModal: (el: any) => void;
}

export function ZoneMenu({
  imageSensor,
  zone,
  setShowModal,
  setShowdltModal,
}: ZoneMenuProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState();

  const { selectedProperty } = usePropertiesState();
  const { setSelectedZone } = useZoneState();
  const { setSelectedImagesSensor } = useImagesSensorsState();
  const { setSelectedTripWire } = useTripWireState();

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const open = Boolean(anchorEl);

  const handleEditClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedTripWire(null);
    setSelectedZone(zone);
    setSelectedImagesSensor(imageSensor);
    history.push(
      ROUTES.ZONE_EDIT.replace(":uid", selectedProperty?.id!)
        .replace(":imagesensor", imageSensor?.id.split(":")[1])
        .replace(":zoneid", zone?.id.split(":")[1])
    );
  };

  const handleViewClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedTripWire(null);
    setSelectedZone(zone);
    setSelectedImagesSensor(imageSensor);
    history.push(
      ROUTES.ZONE.replace(":uid", selectedProperty?.id!)
        .replace(":imagesensor", imageSensor?.id.split(":")[1])
        .replace(":zoneid", zone?.id.split(":")[1])
    );
  };

  const createZoneEditButton = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={handleEditClick}>
        {formatMessage({
          id: "zone.contextMenu.editZone",
          defaultMessage: "Edit Zone",
          description: "zone context menu edit zone label",
        })}
      </MenuItem>
    );
  }, []);

  const createZoneViewButton = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={handleViewClick}>
        {formatMessage({
          id: "zone.contextMenu.viewZone",
          defaultMessage: "Details",
          description: "zone context menu view zone label",
        })}
      </MenuItem>
    );
  }, []);

  const handleUnlinkClick = () => {
    setShowModal(true);
    setAnchorEl(undefined);
  };

  const handleDeleteClick = () => {
    setShowdltModal(true);
    setAnchorEl(undefined);
  };

  return (
    <>
      <HamburgerMenu className={classes.icon} onClick={handleToggleMenu} />
      <Menu
        id="properties-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        style={{ zIndex: 500 }}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          <CanSee action="imageSensor:view">{createZoneViewButton()}</CanSee>
          <CanSee action="imageSensor:edit">{createZoneEditButton()}</CanSee>
          <MenuItem
            className={classes.menuItem}
            onClick={handleUnlinkClick}
            disabled={zone?.spaces?.length! > 0 ? false : true}
          >
            {formatMessage({
              id: "zone.contextMenu.unlinkZone",
              defaultMessage: "Unlink Zone",
              description: "zone context menu unlink zone label",
            })}
          </MenuItem>
          <Grid style={{ display: "flex" }}>
            <MenuItem
              className={classes.menuItem}
              onClick={handleDeleteClick}
              disabled={
                zone?.spaces?.length! > 0 || zone?.active! ? true : false
              }
            >
              {formatMessage({
                id: "zone.contextMenu.deleteZone",
                defaultMessage: "Delete Zone",
                description: "zone context menu delete zone label",
              })}
            </MenuItem>
            <MenuItem>
              <Tooltip
                placement="top-start"
                title={
                  <>
                    <Typography className={classes.toolTipContent}>
                      <span
                        style={{
                          color: "#000000",
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                        }}
                      />{" "}
                      1. Unlink the Zone
                    </Typography>
                    <Typography className={classes.toolTipContent}>
                      2. Deactivate the Zone
                    </Typography>
                  </>
                }
                classes={{ tooltip: classes.toolTipDiv }}
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </Tooltip>
            </MenuItem>
          </Grid>
        </MenuList>
      </Menu>
    </>
  );
}
