import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    margin: theme.spacing(1, 0, 0),
  },
  submit: {
    backgroundColor: theme.palette.common.matisse,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: "50%",
  },
  input: {
    margin: "20px 0",
    height: 40,
    border: `1px solid ${theme.palette.common.turquoiseBlue30}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.turquoiseBlue15,
    paddingLeft: 10,
  },
  inputLabel: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.funBlue,
  },
  checkbox: {
    fontSize: theme.typography.pxToRem(14),
    margin: theme.spacing(1, 0, 3),
  },
  agreementLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.funBlue,
    margin: theme.spacing(2, 0, 0),
  },
}));
