import React, { useState } from "react";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryStack,
  VictoryPortal,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { COLORS } from "../../../../../common/styles/colors";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ApolloError } from "@apollo/client";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import { mapSensorConfigurationsResponse } from "../../../../../common/utils/Property.utils";
import * as _ from "lodash";
import { SensorAttributeDBName } from "../../../../../types";
import { DEFAULT_SENSOR_RANGES } from "../../../../Dashboard/Properties/components/SensorRange/SensorRange";
import { toFahrenheit } from "../../../../../common/utils/General.utils";

const useStyles = makeStyles((theme) => ({
  noData: {
    color: theme.palette.common.darkBlue,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
  },
}));

const densityColors = {
  HEALTHY: "#E4F5D7",
  UNHEALTHY: "#FFE08F",
  POOR: "#F5C1B6",
  MODERATE: "#FEEDB2",
  NO_DATA: "#B2B2B2",
  FAIR: "#FFFDC2",
};

type DensityData = {
  x: string;
  y: number;
  quality: string;
};

interface LinearChartForSensorsProps {
  data: DensityData[];
  loading?: boolean;
  error?: ApolloError;
  selectedAQ?: any;
  sensorConfigurations?: any;
}

export const LinearChartForSensors = ({
  data,
  loading,
  error,
  selectedAQ,
  sensorConfigurations,
}: LinearChartForSensorsProps) => {
  const classes = useStyles();
  const { selectedProperty } = usePropertiesState();
  const [sensorConfigurationData] = useState(() => {
    const sensorRangeData =
      mapSensorConfigurationsResponse(sensorConfigurations);
    return sensorRangeData;
  });

  const selectedPropertySensorData = _.sortedUniq(
    sensorConfigurationData[selectedAQ]
  );

  const temperatureUnit = () => {
    const findUnit = selectedProperty?.sensorConfigurations?.filter(
      (el) => el.attribute === "TEMPERATURE"
    );
    return findUnit![0].isCelsius;
  };

  const newArray =
    selectedAQ === SensorAttributeDBName.SCORE
      ? [0, 0, 0, 0]
      : selectedPropertySensorData.length > 0
      ? selectedPropertySensorData
      : _.get(DEFAULT_SENSOR_RANGES[selectedAQ], "range");

  const Loading = () => (
    <Grid
      container
      alignItems="center"
      justify="center"
      style={{ height: 164 }}
    >
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="indeterminate"
          size={80}
          style={{ color: COLORS.solidGray }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            LOADING...
          </Typography>
        </Box>
      </Box>
    </Grid>
  );

  const NoData = () => {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: 164 }}
      >
        <Typography className={classes.noData}>No data</Typography>
      </Grid>
    );
  };

  const areaData =
    data &&
    data?.map((value, i) => {
      return {
        x: value.x,
        y: 20,
      };
    });

  const dataObj1 = [
    {
      name: "AreaHealthy",
      style: densityColors.HEALTHY,
      data0: 0,
      data1: newArray[1],
    },
    {
      name: "AreaMedium",
      style: densityColors.MODERATE,
      data0: newArray[1],
      data1: newArray[2],
    },
    {
      name: "AreaPoor",
      style: densityColors.POOR,
      data0: newArray[2],
      data1: newArray[3],
    },
  ];

  const dataObj2 = [
    {
      name: "AreaPoor",
      style: densityColors.POOR,
      data0: newArray[0] < 0 ? 0 : newArray[0],
      data1: newArray[1],
    },
    {
      name: "AreaMedium",
      style: densityColors.MODERATE,
      data0: newArray[1],
      data1: newArray[2],
    },
    {
      name: "AreaHealthy",
      style: densityColors.HEALTHY,
      data0: newArray[2],
      data1: newArray[3],
    },
    {
      name: "AreaMedium",
      style: densityColors.MODERATE,
      data0: newArray[3],
      data1: newArray[4],
    },
    {
      name: "AreaPoor",
      style: densityColors.POOR,
      data0: newArray[4],
      data1: newArray[5],
    },
  ];

  const convertedData =
    selectedAQ === SensorAttributeDBName.TEMPERATURE && data
      ? data.map((d) => {
          return {
            x: d.x,
            y:
              d.quality === "NO_DATA"
                ? d.y
                : temperatureUnit()
                ? Math.round(d.y)
                : Math.round(toFahrenheit(d.y)),
          };
        })
      : data;

  return (
    <>
      {loading ? (
        <Loading />
      ) : error || data === undefined ? (
        <NoData />
      ) : (
        <VictoryChart
          padding={{ top: 40, bottom: 50, left: 50, right: 15 }}
          //domainPadding={{ x: 20 }}
          height={300}
          containerComponent={
            <VictoryVoronoiContainer
              //voronoiPadding={{left: 20, right: 20}}
              labels={({ datum }) => `${datum.y}`}
              voronoiBlacklist={[
                "AreaPoor",
                "AreaMedium",
                "AreaUnhealthy",
                "AreaHealthy",
                "AreaFair",
              ]}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={1}
                  constrainToVisibleArea
                  flyoutStyle={{
                    stroke: "#B5E9EF",
                    fill: "#E6F8FA",
                  }}
                />
              }
            />
          }
          domain={{
            y:
              selectedAQ === SensorAttributeDBName.SCORE
                ? [0, 100]
                : [0, Math.round(newArray[newArray.length - 1])],
          }}
        >
          <VictoryAxis
            crossAxis={true}
            style={{
              axis: { stroke: "none" },
              tickLabels: {
                fontFamily: "Poppins",
                fontWeight: 600,
                color: COLORS.darkBlue,
                fontSize: 12,
              },
            }}
            tickValues={data?.map((d) => d.x)}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    if (tArray.length > 2)
                      return (
                        tArray[0] +
                        "\n" +
                        tArray[1] +
                        "\n" +
                        tArray[2]?.charAt(0)
                      );
                    else return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  if (tArray.length > 2)
                    return (
                      tArray[0] + "\n" + tArray[1] + "\n" + tArray[2]?.charAt(0)
                    );
                  else return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
          {/* Y-Axis */}
          <VictoryAxis
            dependentAxis={true}
            style={{
              axis: { stroke: "none" },
              tickLabels: {
                fontFamily: "Poppins",
                fontWeight: 500,
                color: COLORS.darkBlue,
                fontSize: 12,
              },
            }}
            maxDomain={100}
          />
          {selectedAQ === SensorAttributeDBName.SCORE ? (
            <VictoryStack
              name="SensorsArea"
              style={{
                data: { stroke: "white", strokeWidth: 2 },
              }}
            >
              <VictoryArea
                name="AreaPoor"
                style={{ data: { fill: densityColors.POOR } }}
                data={areaData}
              />
              <VictoryArea
                name="AreaUnhealthy"
                style={{ data: { fill: densityColors.UNHEALTHY } }}
                data={areaData}
              />
              <VictoryArea
                name="AreaMedium"
                style={{ data: { fill: densityColors.MODERATE } }}
                data={areaData}
              />
              <VictoryArea
                name="AreaFair"
                style={{ data: { fill: densityColors.FAIR } }}
                data={areaData}
              />
              <VictoryArea
                name="AreaHealthy"
                style={{ data: { fill: densityColors.HEALTHY } }}
                data={areaData}
              />
            </VictoryStack>
          ) : selectedAQ === SensorAttributeDBName.CO2 ||
            selectedAQ === SensorAttributeDBName.TVOCS ||
            selectedAQ === SensorAttributeDBName.PM ||
            selectedAQ === SensorAttributeDBName.NOISE ? (
            <VictoryStack
              name="SensorsArea"
              style={{
                data: { stroke: "white", strokeWidth: 2 },
              }}
            >
              {dataObj1.map((v) => (
                <VictoryArea
                  name={v.name}
                  style={{ data: { fill: v.style } }}
                  data={data.map((value) => {
                    return {
                      x: value.x,
                      y: v.data1 - v.data0,
                    };
                  })}
                />
              ))}
            </VictoryStack>
          ) : (
            <VictoryStack
              name="SensorsArea"
              style={{
                data: { stroke: "white", strokeWidth: 2 },
              }}
            >
              {dataObj2.map((v) => (
                <VictoryArea
                  name={v.name}
                  style={{ data: { fill: v.style } }}
                  data={data.map((value) => {
                    return {
                      x: value.x,
                      y: v.data1 - v.data0,
                    };
                  })}
                />
              ))}
            </VictoryStack>
          )}
          <VictoryPortal>
            <VictoryLine
              name="Line"
              data={convertedData}
              style={{
                data: {
                  stroke: COLORS.blue,
                  strokeWidth: 2,
                },
              }}
            />
          </VictoryPortal>
        </VictoryChart>
      )}
    </>
  );
};
