import React from "react";
import { Grid, IconButton, makeStyles } from "@material-ui/core";

import { useToolbar } from "../../../../../core/context/functions/FloorPlanEditor/Toolbar.functions";
import { ToolbarItem } from "./ToolbarItem";
import { useToolbarState } from "../../../../../core/context/containers/FloorPlanEditor/Toolbar.container";
import { Tool } from "../../../../../common/constants/FloorPlanEditor";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 675,
    width: 60,
    borderRadius: 7,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.common.lightGray,
    padding: "8px 0",
  },
}));

export function Toolbar() {
  const classes = useStyles();

  const { selectedTool, zoomIn, zoomOut } = useToolbarState();
  const { selectTool } = useToolbar();

  return (
    <Grid
      container
      alignItems={"center"}
      justify={"space-between"}
      direction="column"
      className={classes.root}
    >
      <ToolbarItem
        isActive={true}
        onClick={() =>
          selectTool(
            selectedTool === Tool.ADD_ACCESS_POINT
              ? null
              : Tool.ADD_ACCESS_POINT
          )
        }
      />
      <Grid container alignItems={"center"} direction="column">
        <IconButton aria-label="Zoom In" onClick={zoomIn}>
          <ZoomIn />
        </IconButton>
        <IconButton aria-label="Zoom Out" onClick={zoomOut}>
          <ZoomOut />
        </IconButton>
      </Grid>
    </Grid>
  );
}
