import React, { useState } from "react";

import { RootModal } from "../../common/components/Modal/Modal";

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);
  const RenderModal = ({ children }: any) => (
    <React.Fragment>
      {isVisible && <RootModal open={isVisible}>{children}</RootModal>}
    </React.Fragment>
  );

  return {
    show,
    hide,
    RenderModal,
  };
};
