import { createIntl, createIntlCache } from "react-intl";
import * as en_US from "../../common/assets/locales/en_US.json";

export const DEFAULT_LOCALE = "en";

const cache = createIntlCache();

function flattenMessages(nestedMessages: any, prefix = "") {
  if (nestedMessages === null) {
    return {};
  }

  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === "string") {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

export default createIntl(
  { locale: DEFAULT_LOCALE, messages: flattenMessages(en_US) },
  cache
);
