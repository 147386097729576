import { useMutation } from "@apollo/client";
import { makeStyles, Grid, Typography, Divider } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import GQLService from "../../../../../core/services/GQL.service";
import {
  createUpNextAppFormFields,
  VALIDATION_RULES,
} from "./UpNextAppSettings.form";
import { PageHeader, Form } from "../../../../../common/components";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamily,
    borderBottomWidth: "4px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.common.lightBlue,
    width: "fit-content",
  },
}));

export function EditUpNextApp() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const { enqueueSnackbar } = useSnackbar();

  const [editUpNextSettings] = useMutation(
    GQLService.mutations.updateUpNextSettings,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.propertyById,
          variables: { propertyId: selectedProperty?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleSubmit = useCallback(
    async (formData: any) => {
      try {
        editUpNextSettings({
          variables: {
            upNextConfigurationRequest: formData,
            propertyId: selectedProperty?.id!,
          },
        }).then(() => {
          enqueueSnackbar(
            formatMessage({
              id: "upnextapp.generalSettings.change.success",
              defaultMessage: "UpNext Settings changed successfully",
              description: "upnext general settings changed successfully",
            }),
            {
              variant: "success",
            }
          );
          history.push(
            ROUTES.UPNEXT_APP.replace(":uid", selectedProperty?.id!)
          );
        });
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: "error",
          persist: true,
        });
      }
    },
    [history] //eslint-disable-line
  );
  let upNextSettingsConfig = selectedProperty?.upNextConfigurations;

  if (selectedProperty?.upNextConfigurations) {
    const { use24HourClock, dateFormat, healthSurveyFrequency, surveyActive } =
      selectedProperty?.upNextConfigurations;
    upNextSettingsConfig = {
      use24HourClock,
      dateFormat,
      healthSurveyFrequency,
      surveyActive,
    };
  }
  return (
    <Grid container={true} direction="row" alignItems="center">
      <Grid item sm={12}>
        <PageHeader
          title={formatMessage({
            id: "editupnextapp.header",
            defaultMessage: "Edit UpNext Application",
            description: "edit upnext app header",
          })}
        />
      </Grid>
      <Grid item sm={12}>
        <Typography component="div" className={classes.subTitle}>
          {formatMessage({
            id: "upnextapp.generalSettings",
            defaultMessage: "General Settings",
            description: "upnext general settings",
          })}
        </Typography>
        <Divider />
      </Grid>
      <Grid item={true} md={6} sm={12}>
        <Form
          defaultValues={upNextSettingsConfig || ""}
          fields={createUpNextAppFormFields({ property: selectedProperty })}
          validationSchema={VALIDATION_RULES}
          onSubmit={handleSubmit}
        />
      </Grid>
    </Grid>
  );
}
