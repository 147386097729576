import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { Elevator } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";
import { useSearchState } from "./Search.container";

function useElevatorsBase() {
  const [elevators, setElevators] = useState<Elevator[]>([]);
  const [elevatorsFromResponse, setElevatorsFromResponse] = useState<
    Elevator[]
  >([]);

  const { search } = useSearch();

  const searchElevators = useCallback(
    (searchValue: string) => {
      setElevators(search(elevatorsFromResponse, searchValue) as Elevator[]);
    },
    [elevatorsFromResponse]
  );

  const {
    state: selectedElevator,
    setState: setSelectedElevator,
  } = usePersistedState<Elevator | null>("elevator", null);

  return {
    searchElevators,
    elevators,
    setElevators,
    elevatorsFromResponse,
    setElevatorsFromResponse,
    selectedElevator,
    setSelectedElevator,
  };
}

const { Provider, useContainer } = createContainer(useElevatorsBase);

export const useElevatorsState = useContainer;
export const ElevatorsProvider = Provider;
