import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: "20px 0",
    border: "1px solid #BDCED7",
    paddingBottom: "72px",
  },
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.textBlack,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    width: "100%",
    marginTop: "8px",
    marginLeft: "20px",
  },
  button: {
    backgroundColor: theme.palette.common.matisse,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: 115,
    height: 42,
    marginTop: "40px",
  },
  main: {
    marginTop: theme.spacing(3),
  },
  radio2Label: {
    // marginTop: 40,
  },
  ssid: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    marginTop: "16px",
    marginRight: "30px",
    padding: "5px 10px 5px 10px",
    backgroundColor: theme.palette.common.regularBlue,
    borderRadius: "6px",
  },
}));
