import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";

import { ROUTES } from "../../common/constants/Routing";
import { Role } from "../../types";
import { useAuth } from "../context/containers/Auth.container";
import { DeviceView } from "../../pages/Dashboard/Devices/View/View";
import { EditDevice } from "../../pages/Dashboard/Devices/Edit/Edit";

export const DEVICES_ROUTES = {
  DEVICES: "/dashboard/properties/:uid/devices",
  EDIT_DEVICE: "/dashboard/properties/:uid/devices/:deviceId/edit",
  DEVICE_VIEW: "/dashboard/properties/:uid/devices/:deviceId/view",
};

const Devices = lazy(() => import("../../pages/Dashboard/Devices/List/List"));

export default function DevicesRouter() {
  const auth = useAuth();

  return (
    <Route path={ROUTES.DEVICES}>
      <Route
        exact={true}
        path={ROUTES.DEVICES}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "devices:list",
              <Devices />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.DEVICE_VIEW}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "devices:view",
              <DeviceView />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.EDIT_DEVICE}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "devices:edit",
              <EditDevice />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
    </Route>
  );
}
