import React from "react";
import { useIntl } from "react-intl";
import { Grid, makeStyles } from "@material-ui/core";

import { SwipePanel } from "../../../../common/components";
import { MainSettings } from "./MainSettings/MainSettings";
import { useHistory, useLocation } from "react-router-dom";
import { useAccessPointState } from "../../../../core/context/containers/AccessPoints.container";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { RadioConfiguration } from "./RadioConfiguration/RadioConfiguration";
import { SSIDs } from "./SSIDs/SSIDs";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    textTransform: "none",
    textDecoration: "underline",
    marginBottom: 30,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
}));

export function AccessPointDetails() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedAccessPoint } = useAccessPointState();
  const { selectedProperty } = usePropertiesState();
  const location = useLocation();
  const history = useHistory();

  return (
    selectedAccessPoint && (
      <div>
        <SwipePanel
          tabNames={[
            formatMessage({
              id: "accessPoint.tabNames.mainSettings",
              defaultMessage: "Main Settings",
              description: "Main settings tab label",
            }),
            // formatMessage({
            //   id: "accessPoint.tabNames.radioConfiguration",
            //   defaultMessage: "Radio Configuration",
            //   description: "Radio configuration tab label",
            // }),
            formatMessage({
              id: "accessPoint.tabNames.SSIDs",
              defaultMessage: "SSIDs",
              description: "SSIDs tab label",
            }),
          ]}
        >
          <MainSettings
            accessPoint={selectedAccessPoint!}
            property={selectedProperty!}
          />
          {/* <RadioConfiguration
            accessPoint={selectedAccessPoint}
            property={selectedProperty!}
          /> */}
          <SSIDs
            accessPoint={selectedAccessPoint}
            property={selectedProperty!}
          />
          <Grid />
        </SwipePanel>
      </div>
    )
  );
}
