import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Button, Input, InputLabel, Typography, Grid } from "@material-ui/core";
import { useStyles } from "../NetworkSettings.styles";
import GQLService from "../../../core/services/GQL.service";
import { useMutation } from "@apollo/client";
import RemoveHostModal from "./RemoveHostModal";
import { ChipList } from "../../../common/components";

interface GroupProps {
  item: any;
}

export default function Host({ item }: GroupProps) {
  const [modal, showModal] = useState<boolean>(false);
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [createHost] = useMutation(GQLService.mutations.createNetworkHost, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allNetworkGroups,
      },
    ],
    awaitRefetchQueries: true,
  });

  const [removeHost] = useMutation(GQLService.mutations.deleteNetworkHost, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allNetworkGroups,
      },
    ],
    awaitRefetchQueries: true,
  });

  const placeholder = formatMessage({
    id: "groupname.placeholder.settings",
    defaultMessage: "Host name",
    description: "Host name",
  });

  const [hostName, setHostName] = useState<string>("");
  const [hostToDelete, setHostToDelete] = useState<any>({});

  const addHost = useCallback(async () => {
    try {
      await createHost({
        variables: {
          hostName,
          groupId: item.id,
          ignore: false,
        },
      });
    } catch (err) {
      console.log(err);
    }
  }, [hostName]);

  const openDeleteModal = useCallback(
    async (host: any) => {
      try {
        setHostToDelete(host);
        showModal(true);
      } catch (err) {
        console.log(err);
      }
    },
    [hostToDelete]
  );

  const removeHostConfirmed = useCallback(
    async (id: string) => {
      try {
        await removeHost({ variables: { id } });
        showModal(false);
        setHostToDelete(undefined);
      } catch (err) {
        console.log(err);
      }
    },
    [hostToDelete]
  );

  const removeHostRejected = useCallback(() => {
    showModal(false);
    setHostToDelete(undefined);
  }, [hostToDelete]);

  const labelFormattedMessage = formatMessage({
    id: "addHost.label.settings",
    defaultMessage: "Host or IP Address",
    description: "host name label",
  });

  const addNewToListLabel = formatMessage({
    id: "addNewToListLabel.settings",
    defaultMessage: "Add new to list",
    description: "add new to list label",
  });

  const addGroupLabel = formatMessage({
    id: "addGroup.settings",
    defaultMessage: "Add",
    description: "add new host",
  });

  const hostListLabel = formatMessage({
    id: "hostGroup.settings",
    defaultMessage: "Hosts or IP Address added to the Engineering list",
    description: "host list",
  });

  return (
    <Grid>
      <Typography className={classes.info}>{addNewToListLabel}</Typography>
      <InputLabel className={classes.inputLabel}>
        {labelFormattedMessage}
      </InputLabel>

      <Grid className={classes.addHostWrapper}>
        <Input
          value={hostName}
          className={classes.input}
          onChange={(e) => setHostName(e.target.value)}
          placeholder={placeholder}
        />
        <Button className={classes.addHost} onClick={addHost}>
          {addGroupLabel}
        </Button>
      </Grid>
      <Typography className={classes.info}>{hostListLabel}</Typography>
      <ChipList
        list={item.hosts}
        onDelete={openDeleteModal}
        defaultMessage={"There is no Host or Addresses, please add new one."}
      />
      {modal && (
        <RemoveHostModal
          host={hostToDelete}
          yes={(id) => removeHostConfirmed(id)}
          no={() => removeHostRejected()}
        />
      )}
    </Grid>
  );
}
