import React, { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import {
  Grid,
  Button,
  Typography,
  makeStyles,
  InputLabel,
} from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";

import { FormField as FormFieldType } from "../../../../types/form";

import { floorPlanFile } from "./AddFloor";

const useStyles = makeStyles((theme) => ({
  container: { padding: 4 },
  input: { display: "none" },
  button: {
    height: 80,
    border: `1px dashed ${theme.palette.common.turquoiseBlue30}`,
    borderRadius: 6,
    backgroundColor: theme.palette.common.turquoiseBlue15,
    color: theme.palette.common.nepal,
    width: "100%",
  },
  buttonLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "none",
  },
  note: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.nepal,
  },
  preview: {
    minWidth: 240,
    minHeight: 180,
    objectFit: "contain",
    border: `1px solid ${theme.palette.common.nobel}`,
  },
  actionButtonsContainer: {
    marginTop: theme.spacing(1),
  },
  actionButton: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 100,
    height: 40,
    borderRadius: 30,
  },
  actionButtonDisabled: {
    backgroundColor: theme.palette.common.nobel,
    color: theme.palette.common.white,
    width: 100,
    height: 40,
    borderRadius: 30,
  },
  error: {
    borderColor: theme.palette.common.cinnabar,
  },
  progress: {
    color: theme.palette.common.white,
  },
  removeButton: {
    borderRadius: "50%",
    backgroundColor: theme.palette.common.funBlue,
    color: "#fff",
    position: "relative",
    top: "12px",
    left: "calc(100% - 12px);",
    width: "24px",
    height: "24px",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    cursor: "pointer",
  },
  imageWrapper: {
    position: "relative",
  },
  inputLabel: {
    margin: "20px 0 10px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
  },
  errorLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.cinnabar,
    marginLeft: 10,
  },
  errorInput: {
    borderColor: theme.palette.common.cinnabar,
  },
}));

interface AddFloorPlanProps {
  field?: FormFieldType;
  name: string;
  currentFileObject: floorPlanFile | undefined;
  previewStyle?: string;
  currentUrl?: string;
  label: string;
  onRemoveComplete: (e: floorPlanFile) => void;
  onSaveComplete: (floorPlanFile: floorPlanFile) => void;
}

export function AddFloorPlan({
  name,
  field,
  previewStyle,
  currentUrl,
  onRemoveComplete,
  onSaveComplete,
  currentFileObject,
  label,
}: AddFloorPlanProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "dragover",
        (e) => {
          e.preventDefault();
        },
        false
      );
      window.removeEventListener(
        "drop",
        (e) => {
          e.preventDefault();
        },
        false
      );
    };
  }, []);

  useEffect(() => {
    onSaveComplete({
      url: currentUrl,
      data: currentFileObject?.data,
      name: currentFileObject?.name,
    });
  }, [currentUrl]); // eslint-disable-line

  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setError(false);

      if (e.target.files) {
        if (e.target.files[0].size / (1024 * 1024) >= 2) {
          setError(true);

          return;
        }
        onSaveComplete({
          url: URL.createObjectURL(e.target.files[0]),
          data: e.target.files[0],
          name: currentFileObject?.name,
        });
      }
    },
    [] // eslint-disable-line
  );

  const handleRemoveImage = useCallback((e: any) => {
    onRemoveComplete({
      url: undefined,
      name: undefined,
      data: undefined,
    });
  }, []); // eslint-disable-line

  const handleFileDrop = useCallback(
    (e: React.DragEvent<HTMLInputElement>) => {
      setError(false);

      if (e.dataTransfer.files?.length) {
        if (e.dataTransfer.files[0].size / (1024 * 1024) >= 2) {
          setError(true);

          return;
        }
        onSaveComplete({
          url: URL.createObjectURL(e.dataTransfer.files[0]),
          name: currentFileObject?.name,
          data: currentFileObject?.data,
        });
      }
    },
    [currentFileObject] // eslint-disable-line
  );

  return (
    <Grid
      container={true}
      direction="column"
      alignItems="flex-start"
      justify="center"
      classes={{ root: classes.container }}
    >
      {currentFileObject?.url ? (
        <Grid item className={classes.imageWrapper}>
          <span className={classes.removeButton} onClick={handleRemoveImage}>
            &#10005;
          </span>
          <img
            src={currentFileObject?.url}
            alt={currentFileObject.name}
            className={clsx(classes.preview, previewStyle)}
          />
        </Grid>
      ) : (
        <Grid>
          <InputLabel className={classes.inputLabel}>{label}</InputLabel>
          <input
            type="file"
            accept="image/jpeg, image/png"
            className={classes.input}
            id={field?.name || name}
            onChange={handleFileSelect}
            name={field?.name}
          />
          <label htmlFor={field?.name || name}>
            <Button
              className={clsx(classes.button, error ? classes.error : "")}
              component="span"
              fullWidth={true}
              onDrop={handleFileDrop}
            >
              <Grid
                container={true}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <FileCopyOutlined />
                <Typography className={classes.buttonLabel}>
                  {formatMessage({
                    id: "imageInput.dragAndDrop",
                    defaultMessage: "Select File or Drag and Drop",
                    description: "Image input drag and drop label",
                  })}
                </Typography>
              </Grid>
            </Button>
          </label>
          <Typography className={classes.note}>
            {formatMessage({
              id: "imageInput.dragAndDropRules",
              defaultMessage:
                "Your upload must be: JPG or PNG format, less than 2 MB, and 160px\n(w) by 80px (h).",
              description: "Image input drag and drop help text",
            })}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
