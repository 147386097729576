import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
  },
  select: {
    width: "100% !important",
    backgroundColor: theme.palette.common.InputBg,
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 28,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    width: "100% !important",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.textBlack,
  },
  root: {
    marginBottom: 37,
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    marginTop: 25,
    fontWeight: 500,
  },
  chipContainer: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
    outline: "none",
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
    height: 31,
    borderRadius: 6,
    textDecoration: "none",
    position: "relative",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
  },
  chipIcon: {
    color: "white",

    "&:hover": {
      color: "white",
    },
  },
  placeholder: {
    color: theme.palette.common.inputGrey,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
  },
  noTagsMessage: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    color: theme.palette.common.darkGrey,
    margin: "10px 0",
  },
  submitBtn: {
    backgroundColor: "transparent",
    color: theme.palette.common.blue,
    marginBottom: "10px",
    marginLeft: 17,
    border: `1px solid ${theme.palette.common.blue}`,
    height: 40,
    borderRadius: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    outline: "none",
    textTransform: "capitalize",
  },
  error: {
    width: "100% !important",
    backgroundColor: theme.palette.common.InputBg,
    marginBottom: "10px",
    marginLeft: `0px`,
    "&:focus, &:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    alignItems: "center",
    height: 28,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    padding: "5px 10px",
    justifyContent: "left",
    display: "flex",
    fontSize: 14,
    fontWeight: 500,
    borderColor: theme.palette.common.cinnabar,
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    width: 135,
    borderRadius: "0 0 8px 8px",
  },
  selectFloorWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  subTitle: {
    margin: "20px 0 5px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.funBlue,
  },
  generalSelectWrapper: {
    display: "flex",
    flexDirection: "column",
  },
}));
