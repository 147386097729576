import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { Floor } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useFloorBase() {
  const [floors, setFloors] = useState<Floor[]>([]);
  const [floorsFromResponse, setFloorsFromResponse] = useState<Floor[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFloorSummary, setSelectedFloorSummary] = useState<Floor>();

  const {
    state: selectedFloor,
    setState: setSelectedFloor,
  } = usePersistedState<Floor | null>("floor", null);

  const { search } = useSearch();

  const searchFloors = useCallback(
    (searchValue: string) => {
      setFloors(search(floorsFromResponse, searchValue) as Floor[]);
    },
    [floorsFromResponse]
  );

  return {
    searchFloors,
    floors,
    setFloors,
    floorsFromResponse,
    setFloorsFromResponse,
    selectedFloor,
    setSelectedFloor,
    loading,
    setLoading,
    selectedFloorSummary,
    setSelectedFloorSummary,
  };
}

const { Provider, useContainer } = createContainer(useFloorBase);

export const useFloorState = useContainer;
export const FloorProvider = Provider;
