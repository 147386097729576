import { Input } from "@material-ui/core";
import * as Yup from "yup";

import { FormField, FormFieldInputType } from "../../../../types";
import { Select } from "../../../../common/components/Form/FormFields";
import I18n from "../../../../core/services/I18n.service";
import {
  ElevatorReservationConfigurationForm,
  SpaceLoad,
} from "../../../../common/components/Form/FormFields";

enum FIELD_NAMES {
  elevatorReservationConfiguration = "elevatorReservationConfiguration",
  schedulingSlotMinutes = "schedulingSlotMinutes",
  passGracePeriodMinutes = "passGracePeriodMinutes",
  passExpirationGracePeriodMinutes = "passExpirationGracePeriodMinutes",
  maxSchedulability = "maxSchedulability",
  maxAheadTimeOfReservationHours = "maxAheadTimeOfReservationHours",
  maxReservationsPerUser = "maxReservationsPerUser",
}

const slotLengths = [
  { id: 5, name: 5 },
  { id: 10, name: 10 },
  { id: 15, name: 15 },
  { id: 20, name: 20 },
  { id: 30, name: 30 },
  { id: 60, name: 60 },
];

export const ReservationSettingsFields: FormField[] = [
  {
    name: FIELD_NAMES.schedulingSlotMinutes,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.schedulingSlotMinutes",
      defaultMessage: "Slot time in minutes",
    }),
    placeholder: I18n.formatMessage({
      id: "addPropertyFormFields.placeholder.schedulingSlotMinutes",
      defaultMessage: "Slot time in minutes",
    }),
    type: FormFieldInputType.number,
    required: true,
    gridStyle: { sm: 8, md: 7 },
    Component: Select,
    data: slotLengths,
  },
  {
    name: FIELD_NAMES.passExpirationGracePeriodMinutes,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.passExpirationGracePeriodMinutes",
      defaultMessage:
        "Pass expiration grace period before entering elevator bay",
    }),
    placeholder: I18n.formatMessage({
      id: "addPropertyFormFields.placeholder.passExpirationGracePeriodMinutes",
      defaultMessage:
        "Pass expiration grace period before entering elevator bay",
    }),
    type: FormFieldInputType.number,
    required: true,
    gridStyle: { sm: 8, md: 7 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.passGracePeriodMinutes,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.passGracePeriodMinutes",
      defaultMessage: "Additional expiration time after entering elevator bay",
    }),
    placeholder: I18n.formatMessage({
      id: "addPropertyFormFields.placeholder.passGracePeriodMinutes",
      defaultMessage: "Additional expiration time after entering elevator bay",
    }),
    type: FormFieldInputType.number,
    required: true,
    gridStyle: { sm: 8, md: 7 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.maxSchedulability,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.maxSchedulability",
      defaultMessage: "Max schedulability (%) for reservations",
    }),
    placeholder: I18n.formatMessage({
      id: "addPropertyFormFields.placeholder.maxSchedulability",
      defaultMessage: "Max schedulability",
    }),
    type: FormFieldInputType.number,
    required: true,
    gridStyle: { sm: 8, md: 7 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.maxAheadTimeOfReservationHours,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.maxAheadTimeOfReservationHours",
      defaultMessage:
        "Max ahead time of reservation avaialable for claiming (in hours)",
    }),
    placeholder: I18n.formatMessage({
      id: "addPropertyFormFields.placeholder.maxAheadTimeOfReservationHours",
      defaultMessage: "Max ahead time of reservation",
    }),
    type: FormFieldInputType.number,
    required: true,
    gridStyle: { sm: 8, md: 7 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.maxReservationsPerUser,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.maxAheadTimeOfReservationHours",
      defaultMessage: "Maximum Number Of Reservations Per User",
    }),
    placeholder: I18n.formatMessage({
      id: "addPropertyFormFields.placeholder.maxAheadTimeOfReservationHours",
      defaultMessage: "Maximum Number Of Reservations Per User",
    }),
    type: FormFieldInputType.number,
    required: true,
    gridStyle: { sm: 8, md: 7 },
    Component: Input,
  },
];

export const ReservationValidationRules = Yup.object().shape({
  [FIELD_NAMES.schedulingSlotMinutes]: Yup.number().required(
    "All fields required"
  ),
  [FIELD_NAMES.passGracePeriodMinutes]: Yup.number()
    .min(
      0,
      I18n.formatMessage({
        id: "reservationSettings.validation.passGracePeriodMinutes.min",
        defaultMessage: "Can not be less than 0mins",
      })
    )
    .required("All fields required"),
  [FIELD_NAMES.maxAheadTimeOfReservationHours]: Yup.number()
    .required("All fields required")
    .min(
      0,
      I18n.formatMessage({
        id: "reservationSettings.validation.maxAheadTimeOfReservationHours.min",
        defaultMessage: "Can not be less than 0hrs",
      })
    )
    .max(
      24,
      I18n.formatMessage({
        id: "reservationSettings.validation.maxAheadTimeOfReservationHours.max",
        defaultMessage: "Can not be more than 24hrs",
      })
    ),
  [FIELD_NAMES.passExpirationGracePeriodMinutes]: Yup.number()
    .min(
      0,
      I18n.formatMessage({
        id:
          "reservationSettings.validation.passExpirationGracePeriodMinutes.min",
        defaultMessage: "Can not be less than 0mins",
      })
    )
    .required("All fields required"),
  [FIELD_NAMES.maxSchedulability]: Yup.number()
    .min(
      0,
      I18n.formatMessage({
        id: "reservationSettings.validation.maxSchedulability.min",
        defaultMessage: "Can not be less than 0 schedulability",
      })
    )
    .required("All fields required"),
  [FIELD_NAMES.maxReservationsPerUser]: Yup.number()
    .min(
      0,
      I18n.formatMessage({
        id: "reservationSettings.validation.maxReservationsPerUser.min",
        defaultMessage: "Can not be less than 0 reservation",
      })
    )
    .required("All fields required"),
});
