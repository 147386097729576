import React, { useCallback, useState } from "react";
import { useFormikContext, FieldInputProps, FormikState } from "formik";
import { useStyles } from "./Datepicker.styles";
import { useIntl } from "react-intl";
import { Grid, Button, Typography, Input, InputLabel } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

interface DatePickerProps {
  disabled?: boolean;
  placeholder: string;
  label: string;
  name: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
}

export function Datepicker({
  name,
  disabled = false,
  placeholder = "--/--/----",
  label,
  onChange,
  value
}: DatePickerProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [selectedDate, setSelectedDate] = useState<null | Date>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  return (
    <Grid key={"name"} container={true}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid item={true} direction="column">
          <InputLabel className={classes.label}>{label}</InputLabel>
          <KeyboardDatePicker
            placeholder={placeholder}
            className={classes.datepicker}
            autoOk={true}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            minDate={new Date()}
            value={value || selectedDate}
            onChange={onChange || handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "start day",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </Grid>
  );
}
