import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  ClickAwayListener,
  Select,
  MenuItem,
} from "@material-ui/core";
import { LinearChartForDensity } from "../../../common/components/Charts/LinearCharts/LinearChartForDensity";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import moment from "moment";
import { BarChartForDensity } from "../../../common/components/Charts/BarCharts/BarChartForDensity";
import { ReactComponent as PinBlack } from "../../../common/assets/images/pin_black.svg";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";
import { useUserState } from "../../../core/context/containers/User.container";
import { TIMEZONES } from "../../../common/constants/Timezones";
import { UnpinModal } from "./UnpinModal";
import { DashboardDeviceType } from "../../../types";
import { CustomModal, SwipePanel } from "../../../common/components";
import { useIntl } from "react-intl";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { SwipePanelTP } from "../../Dashboard/ImagesSensors/Edit/components/TripWire/SwipePanelTP";
import { CollapsibleMyPin } from "./CollapsibleMyPin";

const useStyles = makeStyles((theme) => ({
  displayGrid: {
    display: "flex",
    marginLeft: "16px",
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "4px",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "8px",
  },
  statisticContainer: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0px 4px 13px rgba(135, 135, 135, 0.3)",
    paddingBottom: "24px",
    paddingRight: "10px",
  },
  statisticContainerTitleGrid: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "14px",
  },
  statisticContainerTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginLeft: "14px",
    marginBottom: "16px",
  },
  pinIcon: {
    transform: "rotate(45deg)",
    height: "16px",
  },
  densityTimeGrid: {
    marginBottom: "16px",
    marginTop: "20px",
  },
  dwellTime: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: "14px",
  },
  swipePanel: {
    display: "flex",
    width: "50%",
    justifyContent: "center",
    backgroundColor: `${theme.palette.common.ultralightGrey}`,
    color: `${theme.palette.common.mediumBlack}`,
    marginRight: 0,
    fontSize: theme.typography.pxToRem(14),
    "&.Mui-selected": {
      backgroundColor: `${theme.palette.common.regularBlue}`,
      color: "#FFF !important",
    },
  },
  item: {
    "& MuiTabs-indicator": {
      display: "none",
    },
  },
  tabBar: {
    backgroundColor: "transparent",
    height: 32,
    borderBottom: `1px solid ${theme.palette.common.ultralightGrey}`,
  },
  tabIndicator: {
    backgroundColor: theme.palette.common.lightBlue,
    height: 1,
    width: "100%",
  },
  root: {
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: "auto",
      margin: 0,
    },
    "& .MuiTab-root": {
      padding: "0 0",
      minWidth: "auto",
      minHeight: 32,
      "&:first-child": {
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
      },
      "&:last-child": {
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
      },
    },
    "& .MuiTabs-scroller": {
      flex: 1,
      position: "static",
      display: "inline-block",
    },
  },
  timeIcon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  timeSelect: {
    height: 16,
    minWidth: 100,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    borderRadius: "0 0 8px 8px",
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
    paddingLeft: theme.typography.pxToRem(6),
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  durationGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export function CameraTripWireMyPin({
  item,
  request,
  duration,
  timeZone,
  loadingMyPin,
  removedCard,
}: any) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty, selectedPropertyTimeZone } = usePropertiesState();
  const [cameraTripWireData, setCameraTripWireData] = useState<any>();
  const { userEmail } = useUserState();
  const [newTimeZone, setNewTimeZone] = useState<any>();
  const [toggleUnpinModal, setToggleUnpinModal] = useState<boolean>(false);

  let parsedRequest = JSON.parse(request).request;
  const [selectedTime, setSelectedTime] = useState(parsedRequest.selectedTime);

  const [
    getOccupancyDuringTimeForTripWire,
    { loading, error, data: responseDataCam },
  ] = useLazyQuery(GQLService.queries.occupancyDuringTimeForTripWire, {
    fetchPolicy: "network-only",
  });

  const timeRange = useMemo(() => {
    if (selectedTime! <= 15) return "MINUTE";
    if (selectedTime! <= 60) return "QUARTER";
    if (selectedTime! <= 1440) return "HOUR";
    return "DAY";
  }, [selectedTime, request]);

  const timeInMinutesFilter = [
    { value: 15, label: "Last 15 minutes" },
    { value: 60, label: "Last hour" },
    { value: 24 * 60, label: "Last 24 hours" },
    { value: 7 * 24 * 60, label: "Last 7 days" },
    { value: 30 * 24 * 60, label: "Last 30 days" },
  ];

  const getTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === 10080 || selectedTime === 43200) {
      if (timeZone) {
        newTimeTo = moment.tz(timeZone).startOf("day").format();
        newTimeFrom = moment
          .tz(newTimeTo, timeZone)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment.utc().startOf("day").format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    } else {
      if (timeZone) {
        newTimeTo = moment
          .tz(timeZone)
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, timeZone)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime, timeZone]);

  useEffect(() => {
    if (selectedTime!) {
      getOccupancyDuringTimeForTripWire({
        variables: {
          request: {
            tripWireId: parsedRequest.deviceId,
            floorId: parsedRequest.floorId,
            cameraId: parsedRequest.cameraId,
            from: getTimeParams().newTimeFrom,
            to: getTimeParams().newTimeTo,
            timeRangeType: timeRange,
            userEmail: userEmail,
          },
        },
      });
    }
  }, [
    request,
    getOccupancyDuringTimeForTripWire,
    selectedProperty,
    timeRange,
    selectedTime,
    parsedRequest.deviceId,
    parsedRequest.floorId,
    parsedRequest.cameraId,
    getTimeParams,
    userEmail,
  ]);

  useEffect(() => {
    if (responseDataCam) {
      setCameraTripWireData(responseDataCam?.occupancyDuringTimeForTripWire!);
    }
  }, [responseDataCam]);

  useEffect(() => {
    const findTimeZone = TIMEZONES.find((item) => item.abbr === timeZone);
    setNewTimeZone(findTimeZone);
  }, [timeZone]);

  const handleTimeChange = useCallback(
    (e) => {
      setSelectedTime(e.target.value);
    },
    [setSelectedTime]
  );

  return (
    <>
      <Grid>
        {!loadingMyPin && <CollapsibleMyPin item={item} />}
        <ClickAwayListener onClickAway={(e) => setToggleUnpinModal(false)}>
          <Grid className={classes.statisticContainer}>
            <Grid className={classes.statisticContainerTitleGrid}>
              <Typography className={classes.statisticContainerTitle}>
                Tripwire: Flow through during time
              </Typography>
              <PinBlack
                fill="#3f51b5"
                className={classes.pinIcon}
                onClick={() => setToggleUnpinModal(true)}
              />
            </Grid>
            <Grid
              className={classes.displayGrid}
              style={{ marginBottom: "20px" }}
            >
              <Typography
                className={classes.title}
                style={{ marginTop: "4px" }}
              >
                Duration:
              </Typography>
              <Select
                classes={{
                  root: classes.timeSelect,
                  select: classes.blackText,
                  icon: classes.timeIcon,
                }}
                name="duration"
                value={selectedTime}
                disableUnderline={true}
                onChange={handleTimeChange}
                IconComponent={ExpandMoreSharpIcon}
                MenuProps={{
                  classes: {
                    paper: classes.menu,
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {timeInMinutesFilter.map((v) => (
                  <MenuItem
                    key={v.value}
                    value={v.value}
                    classes={{
                      root: classes.option,
                      selected: classes.selected,
                    }}
                  >
                    {v.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {(cameraTripWireData?.flowType as unknown as string) === "BOTH" ? (
              <Grid style={{ marginLeft: "10px" }}>
                <SwipePanelTP
                  className={classes.swipePanel}
                  stylesNew={classes}
                  tabNames={[
                    formatMessage({
                      id: "tripWire.showStastistics.tabs.in",
                      defaultMessage: "In",
                    }),
                    formatMessage({
                      id: "tripWire.showStastistics.tabs.out",
                      defaultMessage: "Out",
                    }),
                  ]}
                  swipeIndex={parseInt(parsedRequest.subType)}
                >
                  <>
                    <LinearChartForDensity
                      data={
                        cameraTripWireData?.cameraOccupacyTimelineFlowIN?.map(
                          (d: any, i: number) => {
                            return {
                              x:
                                cameraTripWireData?.timeRangeType! === "DAY"
                                  ? moment
                                      .tz(d.from, timeZone)
                                      .format("DD MMM ddd")
                                  : moment.tz(d.from, timeZone).format("LT"),
                              y: d.connectedPeopleCount,
                            };
                          }
                        )!
                      }
                      spaceLoad={{
                        low: cameraTripWireData?.inOccupancyMediumMinThreShold!,
                        high: cameraTripWireData?.inOccupancyMediumMaxThreShold!,
                        limit: cameraTripWireData?.inOccupancyMaxThreShold!,
                      }}
                      loading={loading}
                      error={error}
                    />
                    <Typography className={classes.statisticContainerTitle}>
                      Density time
                    </Typography>
                    <BarChartForDensity
                      data={[
                        {
                          x: "Low",
                          y: cameraTripWireData?.lowDensityTimeForIn!,
                          fill: "rgba(79, 158, 83, 0.15)",
                        },
                        {
                          x: "Medium",
                          y: cameraTripWireData?.mediumDensityTimeForIn!,
                          fill: "rgba(238, 170, 70, 0.2)",
                        },
                        {
                          x: "High",
                          y: cameraTripWireData?.highDensityTimeForIn!,
                          fill: "rgba(176, 0, 31, 0.2)",
                        },
                      ]}
                      loading={loading}
                      error={error}
                    />
                  </>
                  <>
                    <LinearChartForDensity
                      data={
                        cameraTripWireData?.cameraOccupacyTimelineFlowOUT?.map(
                          (d: any, i: number) => {
                            return {
                              x:
                                cameraTripWireData?.timeRangeType! === "DAY"
                                  ? moment
                                      .tz(d.from, timeZone)
                                      .format("DD MMM ddd")
                                  : moment.tz(d.from, timeZone).format("LT"),
                              y: d.connectedPeopleCount,
                            };
                          }
                        )!
                      }
                      spaceLoad={{
                        low: cameraTripWireData?.outOccupancyMediumMinThreShold!,
                        high: cameraTripWireData?.outOccupancyMediumMaxThreShold!,
                        limit: cameraTripWireData?.outOccupancyMaxThreShold!,
                      }}
                      loading={loading}
                      error={error}
                    />
                    <Typography className={classes.statisticContainerTitle}>
                      Density time
                    </Typography>
                    <BarChartForDensity
                      data={[
                        {
                          x: "Low",
                          y: cameraTripWireData?.lowDensityTimeForOut!,
                          fill: "rgba(79, 158, 83, 0.15)",
                        },
                        {
                          x: "Medium",
                          y: cameraTripWireData?.mediumDensityTimeForOut!,
                          fill: "rgba(238, 170, 70, 0.2)",
                        },
                        {
                          x: "High",
                          y: cameraTripWireData?.highDensityTimeForOut!,
                          fill: "rgba(176, 0, 31, 0.2)",
                        },
                      ]}
                      loading={loading}
                      error={error}
                    />
                  </>
                </SwipePanelTP>
              </Grid>
            ) : (
              <>
                <LinearChartForDensity
                  data={
                    cameraTripWireData?.flowType === "IN"
                      ? cameraTripWireData?.cameraOccupacyTimelineFlowIN?.map(
                          (d: any, i: number) => {
                            return {
                              x:
                                cameraTripWireData?.timeRangeType! === "DAY"
                                  ? moment
                                      .tz(d.from, timeZone)
                                      .format("DD MMM ddd")
                                  : moment.tz(d.from, timeZone).format("LT"),
                              y: d.connectedPeopleCount,
                            };
                          }
                        )!
                      : cameraTripWireData?.cameraOccupacyTimelineFlowOUT?.map(
                          (d: any, i: number) => {
                            return {
                              x:
                                cameraTripWireData?.timeRangeType! === "DAY"
                                  ? moment
                                      .tz(d.from, timeZone)
                                      .format("DD MMM ddd")
                                  : moment.tz(d.from, timeZone).format("LT"),
                              y: d.connectedPeopleCount,
                            };
                          }
                        )!
                  }
                  spaceLoad={{
                    low:
                      cameraTripWireData?.flowType === "IN"
                        ? cameraTripWireData?.inOccupancyMediumMinThreShold!
                        : cameraTripWireData?.outOccupancyMediumMinThreShold!,
                    high:
                      cameraTripWireData?.flowType === "IN"
                        ? cameraTripWireData?.inOccupancyMediumMaxThreShold!
                        : cameraTripWireData?.outOccupancyMediumMaxThreShold!,
                    limit:
                      cameraTripWireData?.flowType === "IN"
                        ? cameraTripWireData?.inOccupancyMaxThreShold!
                        : cameraTripWireData?.outOccupancyMaxThreShold!,
                  }}
                  loading={loading}
                  error={error}
                />
                <Typography className={classes.statisticContainerTitle}>
                  Density time
                </Typography>
                <BarChartForDensity
                  data={[
                    {
                      x: "Low",
                      y:
                        cameraTripWireData?.flowType === "IN"
                          ? cameraTripWireData?.lowDensityTimeForIn!
                          : cameraTripWireData?.lowDensityTimeForOut!,
                      fill: "rgba(79, 158, 83, 0.15)",
                    },
                    {
                      x: "Medium",
                      y:
                        cameraTripWireData?.flowType === "IN"
                          ? cameraTripWireData?.mediumDensityTimeForIn!
                          : cameraTripWireData?.mediumDensityTimeForOut!,
                      fill: "rgba(238, 170, 70, 0.2)",
                    },
                    {
                      x: "High",
                      y:
                        cameraTripWireData?.flowType === "IN"
                          ? cameraTripWireData?.highDensityTimeForIn!
                          : cameraTripWireData?.highDensityTimeForOut!,
                      fill: "rgba(176, 0, 31, 0.2)",
                    },
                  ]}
                  loading={loading}
                  error={error}
                />
              </>
            )}
          </Grid>
        </ClickAwayListener>
      </Grid>
      <CustomModal open={toggleUnpinModal}>
        <UnpinModal
          setToggleModal={setToggleUnpinModal}
          deviceType={DashboardDeviceType.TRIPWIRE}
          deviceId={parsedRequest.deviceId}
          Type="MyPins"
          removedCard={removedCard}
        />
      </CustomModal>
    </>
  );
}
