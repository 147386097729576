import {
  Input,
  ActivitySwitch,
  Select,
} from "../../../../common/components/Form/FormFields";
import { FloorSelect } from "../../../../common/components/Form/FormFields/FloorSelect/FloorSelect.v2";
import * as Yup from "yup";
import { CreateFormField, FormFieldInputType } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";
import { DayDragAndDropSelect } from "../AddDoor/DayDragAndDropSelect";
import { HourSelect } from "../../../../common/components/Form/FormFields/HourSelect/HourSelectWIP";

const FIELD_NAMES = {
  name: "name",
  floorId: "floorId",
  workingFrom: "workingFrom",
  workingTo: "workingTo",
  active: "active",
  availableDays: "availableDays",
  doorAccessControlVendorId: "doorAccessControlVendorId",
};

export const DoorDetails: CreateFormField = (
  disabled,
  data: { [name: string]: [] }
) => {
  return [
    {
      name: FIELD_NAMES.name,
      label: I18n.formatMessage({
        id: "editDoor.doorDetails.label.name",
        defaultMessage: "Door Name",
      }),
      placeholder: I18n.formatMessage({
        id: "editDoor.doorDetails.placeholder.name",
        defaultMessage: "Type here",
      }),
      required: false,
      gridStyle: { sm: 12, md: 7 },
      Component: Input,
    },
    {
      name: FIELD_NAMES.floorId,
      label: I18n.formatMessage({
        id: "editDoor.doorDetails.label.floor",
        defaultMessage: "Floor",
      }),
      placeholder: I18n.formatMessage({
        id: "editDoor.doorDetails.placeholder.floor",
        defaultMessage: "Select floor",
      }),
      required: false,
      gridStyle: { sm: 12, md: 7 },
      Component: FloorSelect,
      data: data?.floors,
    },
    {
      name: FIELD_NAMES.availableDays,
      label: I18n.formatMessage({
        id: "editDoor.doorDetails.label.availableDays",
        defaultMessage: "Available Days",
      }),
      required: false,
      gridStyle: { sm: 12, md: 12 },
      Component: DayDragAndDropSelect,
      data: [
        { id: "MONDAY" },
        { id: "TUESDAY" },
        { id: "WEDNESDAY" },
        { id: "THURSDAY" },
        { id: "FRIDAY" },
        { id: "SATURDAY" },
        { id: "SUNDAY" },
      ],
    },
    {
      name: FIELD_NAMES.workingFrom,
      label: I18n.formatMessage({
        id: "editDoor.doorDetails.label.workingFrom",
        defaultMessage: "Hour From",
      }),
      required: false,
      gridStyle: { sm: 2, md: 2 },
      Component: HourSelect,
      type: FormFieldInputType.string,
      placeholder: "Hour From",
      customProps: {
        min: parseInt(data.propertyWorkingFrom as unknown as string),
        max: parseInt(data.propertyWorkingTo as unknown as string) + 1,
      },
    },
    {
      name: FIELD_NAMES.workingTo,
      label: I18n.formatMessage({
        id: "editDoor.doorDetails.label.workingTo",
        defaultMessage: "Hour To",
      }),
      required: false,
      gridStyle: { sm: 7, md: 7 },
      Component: HourSelect,
      type: FormFieldInputType.string,
      placeholder: "Hour To",
      customProps: {
        min: parseInt(data.propertyWorkingFrom as unknown as string),
        max: parseInt(data.propertyWorkingTo as unknown as string) + 1,
      },
    },
    {
      name: FIELD_NAMES.doorAccessControlVendorId,
      label: I18n.formatMessage({
        id: "editDoor.doorDetails.label.doorAccessControlVendorId",
        defaultMessage: "Access Control Technology",
      }),
      placeholder: I18n.formatMessage({
        id: "editDoor.doorDetails.placeholder.doorAccessControlVendorId",
        defaultMessage: "Select Access Control Technology",
      }),
      required: false,
      gridStyle: { sm: 7, md: 7 },
      Component: Select,
      data: [{ id: "1", name: "Doordeck" }, { id: "2", name: "OpenPath" }],
    },
    {
      name: FIELD_NAMES.active,
      label: I18n.formatMessage({
        id: "editDoor.doorDetails.label.active",
        defaultMessage: "Active",
      }),
      required: false,
      Component: ActivitySwitch,
    },
  ];
};

export const editDoorSchema = Yup.object().shape({
  [FIELD_NAMES.name]: Yup.string()
    .min(
      1,
      I18n.formatMessage({
        id: "editDoor.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "editDoor.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
});
