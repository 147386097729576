import React, { useState, useCallback } from "react";
import { Menu, MenuItem, MenuList, makeStyles } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { Property } from "../../../../types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useUserState } from "../../../../core/context/containers/User.container";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useAuth } from "../../../../core/context/containers/Auth.container";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 30,
    minHeight: 30,
    maxWidth: 30,
    maxHeight: 30,
    color: theme.palette.common.funBlue,

    "&:hover": {
      borderRadius: 15,
      backgroundColor: theme.palette.common.nobel,
    },
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    "&:nth-child(4)": {
      border: "none",
    },
  },
}));

interface ProfileMenuProps {
  property: Property;
}

export function ProfileMenu({ property }: ProfileMenuProps) {
  const classes = useStyles();
  const auth = useAuth();
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState();
  const [setDefautProperty] = useMutation(
    GQLService.mutations.setDefaultProperty,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.user,
          variables: {
            email: auth?.user?.email || auth?.user?.fullName,
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  const { setSelectedProperty } = usePropertiesState();

  const history = useHistory();

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );
  const setAsDefault = useCallback(async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    await setDefautProperty({
      variables: {
        propertyId: property.id,
        email: auth?.user?.email || auth?.user?.fullName,
      },
    });
    auth?.updateUser({ ...auth?.user, defaultProperty: { id: property.id } });
    setSelectedProperty(property);
  }, []);

  const open = Boolean(anchorEl);

  return (
    <>
      <MoreHoriz className={classes.icon} onClick={handleToggleMenu} />
      <Menu
        id="properties-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          <MenuItem className={classes.menuItem} onClick={setAsDefault}>
            {formatMessage({
              id: "user.profile.setPropertyAsDefault",
              defaultMessage: "Set as default",
              description: "Set Property As Default Button",
            })}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
