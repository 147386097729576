import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Divider, Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { useStyles } from "./Summary.styles";
import { ReactComponent as ConnectedNowIcon } from "../../../../../common/assets/images/connected-now.svg";
import { ReactComponent as MobileDevicesIcon } from "../../../../../common/assets/images/smartphone.svg";
import { ReactComponent as ComputerDevicesIcon } from "../../../../../common/assets/images/computer-devices.svg";
import { ReactComponent as OtherDevicesIcon } from "../../../../../common/assets/images/copy-machine.svg";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { useLazyQuery, useQuery } from "@apollo/client";
import GQLService from "../../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import { useIntl } from "react-intl";
import { useFloorState } from "../../../../../core/context/containers/Floor.container";
import { useSpacesState } from "../../../../../core/context/containers/Spaces.container";
import { SummaryNew } from "./SummaryNew";
import {
  DashboardCardAverageAndCount,
  DashboardCardAverageAndCountTripwire,
  DashboardCardAverageAndCountZone,
} from "../../../../../types";

const devicesColors = ["#2F56A1", "#81D3E0", "#08B055"];
const peopleColors = ["#2F56A1", "#ACACAC", "#0E1D33"];

type ResponseData = {
  category: string;
  count: number;
};

type PieChartData = {
  x: string;
  y: number;
};

interface DataUsageProps {
  timeFrom?: string;
  timeTo?: string;
  selectedTime?: string;
  connectedDevicesWithFloor?: any;
  selectedSpace?: any;
  handleIsPinned?: (el: any) => void;
  dataDashboardCardAverageAndCount?: DashboardCardAverageAndCount;
  dataDashboardCardAverageAndCountTripwire?: DashboardCardAverageAndCountTripwire;
  dataDashboardCardAverageAndCountZone?: DashboardCardAverageAndCountZone;
}

export const DevicesUsageSummary = ({
  timeFrom,
  timeTo,
  selectedTime,
  connectedDevicesWithFloor,
  selectedSpace,
  handleIsPinned,
  dataDashboardCardAverageAndCount,
  dataDashboardCardAverageAndCountTripwire,
  dataDashboardCardAverageAndCountZone,
}: DataUsageProps) => {
  const classes = useStyles();

  const [deviceData, setDeviceData] = useState<PieChartData[]>([]);
  const [peopleData, setPeopleData] = useState<PieChartData[]>([]);
  const [loading, setLoading] = useState(true);
  const { selectedProperty } = usePropertiesState();
  const { formatMessage } = useIntl();
  const { floors, selectedFloorSummary, setSelectedFloorSummary } =
    useFloorState();
  const { selectedSpaceSummary } = useSpacesState();
  const [connectedDevicesData, setConnectedDevicesData] = useState<any>(null);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setSelectedFloorSummary(
        floors.find((v) => v.floorNumber === 1) || floors[0]
      );
    }
    return () => {
      isMounted = false;
    };
  }, [floors, selectedProperty]);

  const requestObject = useMemo(() => {
    if (selectedFloorSummary) {
      return {
        request: {
          floorId: selectedFloorSummary?.id!,
          from: timeFrom?.valueOf(),
          to: timeTo?.valueOf(),
        },
      };
    } else {
      return {
        request: {
          propertyId: selectedProperty?.id!,
          from: timeFrom?.valueOf(),
          to: timeTo?.valueOf(),
        },
      };
    }
  }, [selectedFloorSummary, selectedProperty, timeFrom, timeTo]);

  const [
    getConnectedDevicesAndPeopleByFloorId,
    {
      loading: loadingDataWithFloorId,
      error: errorDataWithFloorId,
      data: connectedDataWithFloorId,
    },
  ] = useLazyQuery(GQLService.queries.connectedDevicesAndPeopleByFloorId);

  const [
    getConnectedDevicesAndPeopleByPropertyId,
    {
      loading: loadingDataWithPropertyId,
      error: errorDataWithPropertyId,
      data: connectedDataWithPropertyId,
      called: calledWithPropertyId,
    },
  ] = useLazyQuery(GQLService.queries.connectedDevicesAndPeopleByPropertyId);

  useEffect(() => {
    if (floors.length > 0) {
      if (!loadingDataWithFloorId) {
        if (
          selectedProperty &&
          !selectedFloorSummary &&
          !loadingDataWithPropertyId
        ) {
          getConnectedDevicesAndPeopleByPropertyId({
            variables: requestObject,
          });
        }
        if (selectedProperty && selectedFloorSummary) {
          getConnectedDevicesAndPeopleByFloorId({
            variables: requestObject,
          });
        }
      }
    }
  }, [selectedProperty, selectedFloorSummary, requestObject, floors]);

  useEffect(() => {
    if (!selectedFloorSummary && connectedDataWithPropertyId) {
      setConnectedDevicesData(
        connectedDataWithPropertyId?.connectedDevicesAndPeopleByPropertyId
      );
    } else {
      setConnectedDevicesData(
        connectedDataWithFloorId?.connectedDevicesAndPeopleByFloorId
      );
    }
  }, [
    connectedDataWithPropertyId,
    connectedDataWithFloorId,
    selectedFloorSummary,
  ]);

  useEffect(() => {
    connectedDevicesData &&
      setDeviceData([
        { x: "Mobile devices", y: connectedDevicesData.mobileDevicesCount },
        { x: "Desktop devices", y: connectedDevicesData.desktopDevicesCount },
        {
          x: "Other (Printer, IoT)",
          y: connectedDevicesData.otherDevicesCount,
        },
      ]);
  }, [connectedDevicesData]);

  useEffect(() => {
    connectedDevicesData &&
      setPeopleData([
        {
          x: "Connected",
          y: connectedDevicesData.actuallyConnectedPeopleCount,
        },
        { x: "Not connected", y: connectedDevicesData.offlinePeopleCount },
        { x: "Sensed", y: connectedDevicesData.sensedDevicesCount },
      ]);
  }, [connectedDevicesData]);

  const connectedPeople = useMemo(() => {
    if (connectedDevicesData) {
      return connectedDevicesData.actuallyConnectedPeopleCount;
    }
  }, [connectedDevicesData]);
  const connectedMobile = useMemo(() => {
    if (connectedDevicesData) {
      return connectedDevicesData.mobileDevicesCount;
    }
  }, [connectedDevicesData]);
  const connectedComputer = useMemo(() => {
    if (connectedDevicesData) {
      return connectedDevicesData.desktopDevicesCount;
    }
  }, [connectedDevicesData]);
  const connectedOther = useMemo(() => {
    if (connectedDevicesData) {
      return connectedDevicesData.otherDevicesCount;
    }
  }, [connectedDevicesData]);

  return (
    <>
      {/* <Grid
        md={12}
        container={true}
        justify="space-between"
        alignItems="baseline"
        classes={{ root: classes.headerContainer }}
      >
        <Typography className={classes.blackLabel}>Summary</Typography>
      </Grid> */}
      {/* <Grid
        container={true}
        md={12}
        justify="space-between"
        alignItems="baseline"
      >
        <Grid container={true} md={3} direction="column">
          <Typography className={classes.blackTextBold}>Occupancy</Typography>
          <Grid item sm={11}>
            <ConnectedNowIcon className={classes.marginRight10}/>
            <Typography className={clsx(classes.blackText, classes.paddingLeft5)}>
              {formatMessage({
                id: "summary.connectedNow",
                defaultMessage: `Connected \nNow`,
              })}
            </Typography>
          </Grid>
          <Grid item sm={10}>
            <Typography className={classes.number} style={{ color: "#81D3E0" }}>
              {connectedPeople}
            </Typography>
          </Grid>
        </Grid>

        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Grid container={true} md={3} direction="column">
          <Typography className={classes.blackTextBold}>Occupancy</Typography>
          <Grid item sm={11}>
            <ConnectedNowIcon className={classes.marginRight10}/>
            <Typography className={clsx(classes.blackText, classes.paddingLeft5)}>
              {formatMessage({
                id: "summary.connectedNow",
                defaultMessage: `Connected \nNow`,
              })}
            </Typography>
          </Grid>
          <Grid item sm={10}>
            <Typography className={classes.number} style={{ color: "#81D3E0" }}>
              {connectedPeople}
            </Typography>
          </Grid>
        </Grid>

        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Grid container={true} md={8} item={true} direction="column">
          <Typography className={classes.blackTextBold}>Connected Devices</Typography>
          <Grid container>
              <Grid item sm={4}>
                <MobileDevicesIcon />
                <Typography className={clsx(classes.blackText, classes.paddingLeft5)}>
                  Mobile devices
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <ComputerDevicesIcon className={classes.marginRight10}/>
                <Typography className={clsx(classes.blackText, classes.paddingLeft0)}>
                  Computer devices
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <OtherDevicesIcon className={classes.marginRight10}/>
                <Typography className={clsx(classes.blackText, classes.paddingLeft0)}>
                  Other (Printer, IoT)
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography
                  className={classes.number}
                  style={{ color: "#2F56A0" }}
                >
                  {connectedMobile}
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography
                  className={classes.number}
                  style={{ color: "#2F56A0" }}
                >
                  {connectedComputer}
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography
                  className={classes.number}
                  style={{ color: "#2F56A0" }}
                >
                  {connectedOther}
                </Typography>
              </Grid>
          </Grid>
        </Grid>
      </Grid> */}

      <SummaryNew
        devicesByFloor={connectedDevicesWithFloor}
        timeFrom={timeFrom}
        timeTo={timeTo}
        selectedTime={selectedTime}
        selectedSpace={selectedSpace}
        handleIsPinned={handleIsPinned}
        dataDashboardCardAverageAndCount={dataDashboardCardAverageAndCount}
        dataDashboardCardAverageAndCountTripwire={
          dataDashboardCardAverageAndCountTripwire
        }
        dataDashboardCardAverageAndCountZone={
          dataDashboardCardAverageAndCountZone
        }
      />
    </>
  );
};
