import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { FieldInputProps, useFormikContext } from "formik";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";

import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import { useElevatorBaysState } from "../../../../../core/context/containers/ElevatorBays.container";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    borderRadius: 8,
    padding: "23px 23px 0 0",
    width: "150%",
  },
  tile: {
    height: 28,
    margin: 3,
    backgroundColor: "#F2F2F2",
    borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    padding: 10,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    cursor: "pointer",
  },
  tileActive: {
    backgroundColor: theme.palette.common.funBlue,
    borderColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
  },
  tileInactive: {
    color: theme.palette.common.funBlue,
  },
  button: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    cursor: "pointer",
    textTransform: "none",
    background: "#FFFFFF",
    border: "1px solid #2F56A0",
    borderRadius: "35px",
    color: theme.palette.common.funBlue,
    marginRight: "10px",
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  label: {
    margin: "0 0 5px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.funBlue,
    whiteSpace: "nowrap",
  },
}));

interface ElevatorDragAndDropSelectProps extends FieldInputProps<any> {
  defaultValue: any;
  placeholder?: string | undefined;
  disabled?: boolean;
  data: any;
}

export function ElevatorDragAndDropSelect({
  name,
  onChange,
  data,
}: ElevatorDragAndDropSelectProps) {
  const classes = useStyles();

  const { values } = useFormikContext<{
    [field: string]: any;
  }>();

  const { selectedProperty } = usePropertiesState();
  const [servicedElevators, setServicedElevators] = useState<string[]>([]);
  const [
    selectedServicedElevatorsUids,
    setSelectedServicedElevatorsUids,
  ] = useState<string[]>(
    values.elevators?.map((elevator: any) => elevator.id) || []
  );

  const handleSelectFloor = useCallback(
    (id: string) => {
      if (!selectedServicedElevatorsUids.find((v) => v === id)) {
        setSelectedServicedElevatorsUids([
          ...selectedServicedElevatorsUids,
          id,
        ]);
      } else {
        setSelectedServicedElevatorsUids(
          selectedServicedElevatorsUids.filter((v) => v !== id)
        );
      }
    },
    [selectedServicedElevatorsUids, servicedElevators]
  );

  useEffect(() => {
    onChange!({
      e: null,
      target: { name, value: selectedServicedElevatorsUids },
    });
  }, [selectedServicedElevatorsUids]); // eslint-disable-line

  const handleSelectAll = useCallback(() => {
    setSelectedServicedElevatorsUids(data?.map((v: any) => v.id) || []);
  }, [selectedProperty]);

  const handleClear = useCallback(() => {
    setSelectedServicedElevatorsUids([]);
  }, []);

  return (
    <Grid
      className={classes.root}
      container={true}
      onClick={(e) => e.preventDefault()}
    >
      <Grid className={classes.buttonContainer} item={true} md={12} sm={12}>
        <Typography className={classes.label}>Seviced Elevators</Typography>
        <Grid item>
          <Button className={classes.button} onClick={handleSelectAll}>
            Select all
          </Button>
          <Button className={classes.button} onClick={handleClear}>
            Clear all
          </Button>
        </Grid>
      </Grid>
      {data?.map((v: any) => (
        <Tile
          key={v.id}
          elevator={v}
          isActive={selectedServicedElevatorsUids.includes(v.id)}
          onClick={() => handleSelectFloor(v.id)}
        />
      ))}
    </Grid>
  );
}

interface TileProps {
  elevator: any;
  isActive: boolean;
  onClick: () => void;
}

function Tile({ elevator, isActive, onClick }: TileProps) {
  const classes = useStyles();

  const handleMark = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.persist();

      if (e.button === 0 && e.buttons === 1 && e.nativeEvent.shiftKey) {
        onClick();
      }
    },
    [onClick]
  );

  return (
    <div
      className={clsx(
        classes.tile,
        isActive ? classes.tileActive : classes.tileInactive
      )}
      onClick={onClick}
      onMouseDown={handleMark}
      onMouseOver={handleMark}
    >
      {elevator.name}
    </div>
  );
}
