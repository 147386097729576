import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.darkGrey,
    margin: theme.spacing(2),
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  containerHeight: {
    minHeight: "50vh",
  },
}));

export function NoImageSensorAvailable({
  message,
  actionMessage,
}: {
  message: any;
  actionMessage: any;
}) {
  const classes = useStyles();

  return (
    <Grid container={true} item className={classes.containerHeight}>
      <Typography className={classes.text}>{message}</Typography>
      <Typography className={classes.text}>{actionMessage}</Typography>
    </Grid>
  );
}
