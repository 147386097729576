import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { VictoryPie } from "victory";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    alignSelf: "flex-end",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(28),
    color: theme.palette.common.mediumBlack,
    alignSelf: "flex-start",
  },
  content: {
    display: "flex",
    minWidth: "530px",
    minHeight: "520px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    alignContent: "flex-start",
    padding: "35px",
    alignItems: "center",
    borderRadius: "20px",
    boxShadow: "0 4px 21px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
  },
  marker: {
    width: 5,
    height: 15,
    borderRadius: 14,
    marginRight: theme.spacing(1),
  },
  labelsContainer: {
    flexDirection: "column",
    marginBottom: "30px",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    marginLeft: "12%",
    padding: "5px 0",
  },
  label: {
    flex: 1,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
  },
  percent: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    flex: 0,
    marginRight: "5%",
  },
}));

type PieChartData = {
  x: string;
  y: number;
};

interface ModalContentProps {
  title: string;
  close: Function;
  data?: PieChartData[];
  colors: string[];
}

export function ModalContent({
  close,
  data,
  colors,
  title,
}: ModalContentProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const Data = () => (
    <svg width={220} height={220}>
      <VictoryPie
        standalone={false}
        width={220}
        height={220}
        labelComponent={<span />}
        innerRadius={45}
        padAngle={2}
        data={data?.slice(0, 5)}
        colorScale={colors}
        padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
      />
    </svg>
  );

  const otherValue = useMemo(() => {
    let sumOther = 0;
    data?.slice(5, data.length).map((v) => (sumOther += v.y * 100));
    return sumOther >= 1
      ? sumOther.toFixed()
      : sumOther === 0
      ? sumOther.toFixed()
      : "<1";
  }, [data]);

  const renderLabels = useCallback(() => {
    if (data?.length === 0) return;
    return (
      <>
        {data?.slice(0, 4).map((item, index) => (
          <div
            className={classes.labelContainer}
            style={{ borderBottom: `1px solid #E0EBF1` }}
            key={index}
          >
            <div
              style={{ backgroundColor: `${colors[index]}` || "#D2D2D2" }}
              className={classes.marker}
            />
            <span className={classes.label} style={{ fontWeight: 600 }}>
              {data && item?.x}
            </span>
            {data && (
              <span className={classes.percent}>
                {data[index]?.y * 100 >= 1
                  ? (data[index]?.y * 100).toFixed()
                  : data[index]?.y * 100 === 0
                  ? (data[index]?.y * 100).toFixed()
                  : "<1"}
                %
              </span>
            )}
          </div>
        ))}
        <div className={classes.labelContainer} key={5}>
          <div
            style={{ backgroundColor: `${colors[4]}` || "#D2D2D2" }}
            className={classes.marker}
          />
          <span className={classes.label} style={{ fontWeight: 600 }}>
            Other
          </span>
          {data && <span className={classes.percent}>{otherValue}%</span>}
        </div>
        {data?.slice(5).map((item, index) => (
          <div className={classes.labelContainer} key={index}>
            <div
              style={{ backgroundColor: "#ffffff" }}
              className={classes.marker}
            />
            <span className={classes.label} style={{ paddingLeft: 10 }}>
              {data && item?.x}
            </span>
            {data && (
              <span className={classes.percent}>
                {item?.y * 100 >= 1
                  ? (item?.y * 100).toFixed()
                  : item?.y * 100 === 0
                  ? (item?.y * 100).toFixed(1)
                  : "<0.1"}
                %
              </span>
            )}
          </div>
        ))}
      </>
    );
  }, [data]);

  return (
    <Grid className={classes.content}>
      <Typography className={classes.header}>{title}</Typography>
      <Grid
        container
        item={true}
        md={12}
        justify="flex-start"
        alignItems="center"
      >
        <Grid item style={{ color: "#D2D2D2" }} md="auto">
          <Data />
        </Grid>
        <Grid item className={classes.labelsContainer} md={6}>
          {renderLabels()}
        </Grid>
      </Grid>
      <Grid item className={classes.buttonsWrapper}>
        <Button className={classes.editBtn} onClick={() => close()}>
          {formatMessage({
            id: "pieChart.seeAll.button.close",
            defaultMessage: "Close",
          })}
        </Button>
      </Grid>
    </Grid>
  );
}
