import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";

import { FormWizard, PageHeader } from "../../../../common/components";
import { addDoorFormWizard } from "./Add.form";
import { ROUTES } from "../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useGroupState } from "../../../../core/context/containers/Groups.container";
import { useDoorState } from "../../../../core/context/containers/Door.container";
import { Fieldset } from "../../../../types/form";
import { useFormWizardState } from "../../../../core/context/containers/FormWizard.container";
import { useSnackbar } from "notistack";
import { scrollsTop } from "../../../../common/utils/scrollbar.utils";
import { Group } from "../../../../types";
import { useFormWizard } from "../../../../core/context/functions/FormWizard.functions";

export function AddDoor() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const { selectedUsersGroup, setSelectedUsersGroup } = useGroupState();
  const { selectedDoor } = useDoorState();
  const [steps, setWizardSteps] = useState<Fieldset[] | undefined>(undefined);
  const { setFormData, setIsLoading } = useFormWizardState();
  const { reset } = useFormWizard();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { error, loading, data } = useQuery(GQLService.queries.allFloors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });
  const [addDoor] = useMutation(GQLService.mutations.addDoor);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      reset();
      setSelectedUsersGroup([]);
      setFormData({});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!loading) {
        const steps = addDoorFormWizard({
          floors: data?.floorsByPropertyId,
          workingFrom: selectedProperty?.workingFrom,
          workingTo: selectedProperty?.workingTo,
          doorAccessControlVendorId: selectedDoor?.doorAccessControlVendorId,
        });
        setWizardSteps(steps);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [loading]);

  // FOR ADD FORM
  const handleSubmit = useCallback(
    (formData) => {
      addDoor({
        variables: {
          active: formData.active,
          doorUuid: formData.doorUuid,
          name: formData.name,
          availableDays: formData.availableDays.toString(),
          doorAccessControlVendorId: formData.doorAccessControlVendorId,
          workingFrom: formData.workingFrom,
          workingTo: formData.workingTo,
          floorId: formData.floorId,
          groups: (selectedUsersGroup as Group[]).map((group) => group.id!),
        },
      })
        .then(async () => {
          enqueueSnackbar(
            formatMessage({
              id: "doors.addDoor.snackbar.success",
              defaultMessage: "Door added successfully",
            }),
            {
              variant: "success",
            }
          );
          scrollsTop();
          reset();
          history.push(ROUTES.DOORS.replace(":uid", selectedProperty?.id!));
        })
        .catch((e) => {
          let errorMsg = e.message;
          if (
            e.message ===
            "500\norg.hibernate.exception.ConstraintViolationException: could not execute statement"
          ) {
            errorMsg = "UUID already exists";
          }
          enqueueSnackbar(errorMsg, {
            variant: "error",
            persist: true,
          });
          setIsLoading(false);
        });
      setTimeout(() => closeSnackbar(), 5000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selectedUsersGroup]
  );

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "doors.addDoor.header",
          defaultMessage: "Add New Door",
          description: "add door header",
        })}
      />
      <Grid item={true} md={12} sm={12}>
        {steps && <FormWizard steps={steps} onFulfill={handleSubmit} />}
      </Grid>
    </div>
  );
}
