import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";

import { PageHeader } from "../../../../common/components";
import { EditImageSensorForm } from "./Edit.form";
import { useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useImagesSensorsState } from "../../../../core/context/containers/ImageSensor.container";
import Loader from "react-loader-spinner";
import { COLORS } from "../../../../common/styles/colors";

export function ImageSensorEditView() {
  const { formatMessage } = useIntl();
  const {
    selectedImagesSensor,
    setSelectedImagesSensor,
  } = useImagesSensorsState();

  const { data, loading } = useQuery(GQLService.queries.cameraById, {
    variables: { cameraId: selectedImagesSensor?.id! },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading && data) {
      setSelectedImagesSensor(data?.cameraById!);
    }
  }, [data]);

  return !loading ? (
    <div>
      <PageHeader
        title={formatMessage({
          id: "editImageSensor.header",
          defaultMessage: "Edit Image Sensor",
          description: "edit image sensor label",
        })}
      />
      <Grid item={true} md={12}>
        <EditImageSensorForm />
      </Grid>
    </div>
  ) : (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader type="Oval" color={COLORS.funBlue} height={150} width={150} />
    </Grid>
  );
}
