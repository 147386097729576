import React, { useEffect, useState, useCallback } from "react";
import { formatMessage } from "@formatjs/intl";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { listStyles } from "../../../../common/styles/lists";
import { conditionRange, NotificationDataType } from "../../../../types";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { COLORS } from "../../../../common/styles/colors";
import { CustomModal } from "../../../../common/components";
import { ModalContent } from "../ModalContent";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";

const useStyles = makeStyles((theme) => ({
  name: {
    ...listStyles.header,
  },
  headerWrapper: {
    ...listStyles.headerWrapper,
  },
  subheader: {
    color: "darkGrey",
    font: "Poppins",
    fontWeight: 500,
    style: "normal",
    fontSize: "11px",
    lineHeight: "16.5px",
  },
  expandIcon: {
    ...listStyles.expandIcon,
  },
  extendedListSubheader: {
    ...listStyles.extendedListSubheader,
  },
  address: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.nobel,
  },
  icon: { color: theme.palette.common.funBlue, marginLeft: "5px" },
  container: {
    "&.MuiGrid-spacing-xs-2 > .MuiGrid-item": {},
  },
  cell: {
    // minHeight: 77,
  },
  cellLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.nobel,
  },
  blueContent: {
    font: "Poppins",
    style: "normal",
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.common.blue,
    lineHeight: "21px",
  },
  darkContent: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.blackPearl,
  },
  content: {},
  accordionDetails: {
    flexDirection: "column",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    flexDirection: "column",
  },
  wrapper: {
    // marginBottom: 10,
  },
  accordionContainer: {
    border: "1px solid rgba(224, 235, 241, 1)",
    borderRadius: "0px",
    width: "97%",
  },
  iconButton: {
    color: COLORS.darkBlue,
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export default function ListNotifications({ listItems }) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [showModal, toggleModal] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { selectedProperty } = usePropertiesState();
  const [notificationID, setNotificationID] = useState();

  const [deleteNotificationRule] = useMutation(
    GQLService.mutations.deleteNotificationRule,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allNotificationRules,
          variables: { propertyId: selectedProperty?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const temperatureUnit = () => {
    const findUnit = selectedProperty?.sensorConfigurations?.filter(
      (el) => el.attribute === "TEMPERATURE"
    );
    return findUnit[0].isCelsius;
  };

  const dataTypeForNotifications = [
    { value: NotificationDataType.OCCUPANCY, label: "Occupancy" },
    { value: NotificationDataType.DWELL_TIME, label: "Dwell Time" },
    { value: NotificationDataType.SCORE, label: "Wellness Index" },
    {
      value: NotificationDataType.TEMPERATURE,
      label: `Temperature in ${temperatureUnit() ? "°C" : "°F"}`,
    },
    { value: NotificationDataType.HUMIDITY, label: "Humidity in %" },
    { value: NotificationDataType.CO2, label: "CO2 in ppm" },
    { value: NotificationDataType.TVOCS, label: "VOC in ppb" },
    { value: NotificationDataType.PM, label: "PM in ugm3" },
    { value: NotificationDataType.LIGHT, label: "Light in lux" },
    { value: NotificationDataType.NOISE, label: "Noise in db" },
  ];

  const getDatatypeLabel = (dataType) => {
    let item = dataTypeForNotifications.find(
      (element) => element.value == dataType
    );
    return item ? item.label : "";
  };

  const getConditionTypeLabel = (condition) => {
    let item = conditionRange.find((element) => element.value == condition);
    return item ? item.label : "";
  };

  const handleRemoveNotificationRule = (e) => {
    toggleModal(true);
    setNotificationID(e);
  };

  const confirmChanges = useCallback(async () => {
    toggleModal(false);
    try {
      deleteNotificationRule({ variables: { notificationId: notificationID } });
      enqueueSnackbar(
        formatMessage({
          id: "notificationRule.remove.mainSettings.snackbar.success",
          defaultMessage: "Notification Rule removed successfully",
        }),
        {
          variant: "success",
        }
      );
      setTimeout(() => closeSnackbar(), 2000);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error", persist: true });
    }
  }, [notificationID]);

  const rejectChanges = useCallback(() => toggleModal(false), []);

  return (
    <>
      {listItems &&
        listItems.map((element) => {
          return (
            <Grid style={{ display: "flex" }}>
              <Accordion className={classes.accordionContainer}>
                <AccordionDetails className={classes.accordionDetails}>
                  <Grid
                    container={true}
                    className={classes.container}
                    justify="space-between"
                  >
                    <Grid item md={2} sm={2} className={classes.wrapper}>
                      <Typography className={classes.subheader}>
                        {formatMessage({
                          id: "spaces.listItem.label.area",
                          defaultMessage: "Datatype",
                        })}
                      </Typography>
                      <Typography className={classes.blueContent}>
                        {getDatatypeLabel(element.dataType)}
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={2} className={classes.wrapper}>
                      <Typography className={classes.subheader}>
                        {formatMessage({
                          id: "spaces.listItem.label.area",
                          defaultMessage: "Floor",
                        })}
                      </Typography>
                      <Typography className={classes.blueContent}>
                        {element?.floor?.name
                          ? element?.floor?.name
                          : `Floor No. ${element?.floor?.floorNumber}`}
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={2} className={classes.wrapper}>
                      <Typography className={classes.subheader}>
                        {formatMessage({
                          id: "spaces.listItem.label.assignedAirQuality",
                          defaultMessage: "Resource",
                        })}
                      </Typography>
                      <Typography className={classes.blueContent}>
                        {element.deviceName ? element.deviceName : "--"}
                      </Typography>
                    </Grid>
                    <Grid item md={2} sm={2} className={classes.wrapper}>
                      {element.dataType === "DWELL_TIME" ? (
                        <>
                          <Typography className={classes.subheader}>
                            {formatMessage({
                              id: "spaces.listItem.label.condition",
                              defaultMessage: "If exceeds",
                            })}
                          </Typography>
                          <Typography className={classes.blueContent}>
                            {element.exceedsInMinutes} minutes
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography className={classes.subheader}>
                            {formatMessage({
                              id: "spaces.listItem.label.assignedAccessPoint",
                              defaultMessage: "Condition",
                            })}
                          </Typography>
                          <Typography className={classes.blueContent}>
                            {getConditionTypeLabel(element.conditionType)}
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid
                      className={classes.wrapper}
                      style={{ marginRight: "70px" }}
                    >
                      <Typography className={classes.subheader}>
                        {formatMessage({
                          id: "spaces.listItem.label.assignedZones",
                          defaultMessage: "Notification",
                        })}
                      </Typography>
                      <Typography className={classes.blueContent}>
                        {element.active ? "ON" : "OFF"}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <IconButton
                onClick={() => handleRemoveNotificationRule(element.id)}
                className={classes.iconButton}
              >
                <CancelOutlinedIcon />
              </IconButton>
            </Grid>
          );
        })}
      <CustomModal open={showModal}>
        <ModalContent
          headerText="Delete"
          yes={async () => await confirmChanges()}
          no={rejectChanges}
        />
      </CustomModal>
    </>
  );
}
