import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { Sensor } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useSensorsBase() {
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [sensorsFromResponse, setSensorsFromResponse] = useState<Sensor[]>([]);
  const [tempUnit, setTempUnit] = useState<string>("farenheit");
  const [celsius, setCelsius] = useState<boolean>(false);
  const {
    state: selectedSensor,
    setState: setSelectedSensor,
  } = usePersistedState<Sensor | null>("sensor", null);

  const { search } = useSearch();

  const searchSensors = useCallback(
    (searchValue: string) => {
      setSensors(search(sensorsFromResponse, searchValue) as Sensor[]);
    },
    [sensorsFromResponse]
  );

  return {
    searchSensors,
    sensors,
    setSensors,
    sensorsFromResponse,
    setSensorsFromResponse,
    selectedSensor,
    setSelectedSensor,
    tempUnit,
    setTempUnit,
    celsius,
    setCelsius,
  };
}

const { Provider, useContainer } = createContainer(useSensorsBase);

export const useSensorsState = useContainer;
export const SensorsProvider = Provider;
