import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    border: `1px solid ${theme.palette.common.turquoiseBlue30}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.turquoiseBlue15,
    padding: "23px 23px 0 23px",
  },
  tile: {
    width: 56,
    height: 32,
    margin: 3,
    border: `1px solid ${theme.palette.common.turquoiseBlue30}`,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    cursor: "pointer",
  },
  tileActive: {
    backgroundColor: theme.palette.common.funBlue,
    borderColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
  },
  tileInactive: {
    color: theme.palette.common.aquamarineBlue,
  },
  button: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    cursor: "pointer",
    textTransform: "none",
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
}));
