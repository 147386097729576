import { gql } from "@apollo/client";

export const FLOORS_MUTATIONS = {
  addFloor: gql`
    mutation createFloor(
      $capacity: Int!
      $floorNumber: Int!
      $name: String
      $propertyId: String!
      $accessPointsRadius: Int
      $signalStrength: Int!
      $active: Boolean!
    ) {
      createFloor(
        request: {
          capacity: $capacity
          floorNumber: $floorNumber
          name: $name
          propertyId: $propertyId
          accessPointsRadius: $accessPointsRadius
          signalStrength: $signalStrength
          active: $active
        }
      ) {
        id
      }
    }
  `,
  editFloor: gql`
    mutation editFloor(
      $capacity: Int
      $floorId: String!
      $floorNumber: Int
      $name: String
      $accessPointsRadius: Int
      $signalStrength: Int
      $active: Boolean
    ) {
      editFloor(
        request: {
          capacity: $capacity
          floorId: $floorId
          floorNumber: $floorNumber
          name: $name
          accessPointsRadius: $accessPointsRadius
          signalStrength: $signalStrength
          active: $active
        }
      )
    }
  `,
  removeFloor: gql`
    mutation($floorId: String!) {
      removeFloor(floorId: $floorId)
    }
  `,
};

export const FLOORS_QUERIES = {
  allFloors: gql`
    query($propertyId: String!) {
      floorsByPropertyId(
        propertyId: $propertyId
        pageDetails: { sort: { sortBy: "floorNumber", sortType: ASC } }
      ) {
        id
        capacity
        floorNumber
        smplrId
        name
        floorPlanUploaded
        floorConfigured
        accessPointsRadius
        signalStrength
        active
      }
    }
  `,
  floorById: gql`
    query($floorId: String!) {
      floorById(floorId: $floorId) {
        id
        name
        floorNumber
        signalStrength
        active
      }
    }
  `,
  allActiveFloors: gql`
    query($propertyId: String!) {
      floorsByPropertyId(
        propertyId: $propertyId
        pageDetails: { sort: { sortBy: "floorNumber", sortType: ASC } }
        filter: { active: true }
      ) {
        id
        capacity
        floorNumber
        smplrId
        name
        floorPlanUploaded
        floorConfigured
        accessPointsRadius
        signalStrength
        active
      }
    }
  `,
  allowedFloorNumbers: gql`
    query($propertyId: String!) {
      allowedFloorNumbers(propertyId: $propertyId)
    }
  `,
  existingFloorNumbers: gql`
    query($propertyId: String!) {
      floorsByPropertyId(
        propertyId: $propertyId
        pageDetails: { sort: { sortBy: "floorNumber", sortType: ASC } }
      ) {
        floorNumber
      }
    }
  `,
};
