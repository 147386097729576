import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { SSID } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useSSIDBase() {
  const [SSIDs, setSSIDs] = useState<SSID[]>([]);
  const [SSIDsFromResponse, setSSIDsFromResponse] = useState<SSID[]>([]);

  const {
    state: selectedSSID,
    setState: setSelectedSSID,
  } = usePersistedState<SSID | null>("ssid", null);

  const { search } = useSearch();

  const searchSSIDs = useCallback(
    (searchValue: string) => {
      setSSIDs(search(SSIDsFromResponse, searchValue) as SSID[]);
    },
    [SSIDsFromResponse]
  );

  return {
    searchSSIDs,
    SSIDs,
    setSSIDs,
    SSIDsFromResponse,
    setSSIDsFromResponse,
    selectedSSID,
    setSelectedSSID,
  };
}

const { Provider, useContainer } = createContainer(useSSIDBase);

export const useSSIDState = useContainer;
export const SSIDProvider = Provider;
