import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryLabel,
  VictoryLegend,
  VictoryArea,
} from "victory";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ReactComponent as ClockIcon } from "../../../../common/assets/images/clock.svg";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.common.textBlack,
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    position: "relative",
    top: -60,
  },
  keyPosition: {},
  radioBarGreen: {
    backgroundColor: "#79CC38",
  },
  radioBarBlue: {
    backgroundColor: "#2056a6",
  },
  radioBarRed: {
    backgroundColor: "#EC8888",
  },
  radioBarTurquoise: {
    backgroundColor: theme.palette.common.turquoiseBlue,
  },
  rectangle: {
    height: 15,
    width: 4,
    borderRadius: 14,
    margin: "0 24px 0 8px",
  },
  timeZone: {
    backgroundColor: theme.palette.common.InputBg,
    padding: "5px",
    fontSize: "11px",
    height: "25px",
    borderRadius: "2px",
    lineHeight: "11px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  victoryChart: {
    width: "95%",
  },
  timeZoneWrapper: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "25px",
  },
}));

interface LineChartCameraDashboardProps {
  devices?: boolean;
  selectedTime?: string;
  inConnectedPeople?: any[];
  outConnectedPeople?: any[];
  netFlow?: any[];
  resetTime?: string;
  statisticsSelect?: any;
}

export function LineChartCameraDashboard({
  devices,
  selectedTime,
  statisticsSelect,
  inConnectedPeople,
  outConnectedPeople,
  netFlow,
  resetTime,
}: LineChartCameraDashboardProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();
  const [resetTimeArray, setResetTimeArray] = useState<any>(null);

  const showTime = (time: number) => {
    return time === 0
      ? `12:00 AM`
      : time < 12
      ? `${time}:00 AM`
      : time === 12
      ? `12:00 PM`
      : `${time - 12}:00 PM`;
  };

  const xAxisTime = (time: number) => {
    if (selectedTime === "24h") {
      return time === 0
        ? `12 AM`
        : time < 12
        ? `0${time} AM`
        : time === 12
        ? `12 PM`
        : `0${time - 12} PM`;
    } else {
      return time === 0
        ? `12:00 AM`
        : time < 12
        ? `0${time}:00 AM`
        : time === 12
        ? `12:00 PM`
        : `0${time - 12}:00 PM`;
    }
  };

  const YAxisLabel = "Occupancy";

  const xAxis = useMemo(() => {
    switch (selectedTime) {
      case "15m":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 15 minutes"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "1h":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last hour"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "24h":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 24 hours"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "7d":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 7 days"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    if (tArray.length > 2)
                      return (
                        tArray[0] +
                        "\n" +
                        tArray[1] +
                        "\n" +
                        tArray[2]?.charAt(0)
                      );
                    else return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  if (tArray.length > 2)
                    return (
                      tArray[0] + "\n" + tArray[1] + "\n" + tArray[2]?.charAt(0)
                    );
                  else return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
      case "30d":
        return (
          <VictoryAxis
            crossAxis={true}
            label="Last 30 days"
            axisLabelComponent={
              <VictoryLabel
                y={170}
                style={{ fontSize: 6, fontFamily: "Poppins" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
            tickFormat={(t, index, arr) => {
              if (typeof t === "string") {
                if (arr?.length > 10) {
                  if (index % 2 === 0) {
                    const tArray = t.split(" ");
                    if (tArray.length > 2)
                      return (
                        tArray[0] +
                        "\n" +
                        tArray[1] +
                        "\n" +
                        tArray[2]?.charAt(0)
                      );
                    else return tArray[0] + "\n" + tArray[1];
                  } else {
                    return "";
                  }
                } else {
                  const tArray = t.split(" ");
                  if (tArray.length > 2)
                    return (
                      tArray[0] + "\n" + tArray[1] + "\n" + tArray[2]?.charAt(0)
                    );
                  else return tArray[0] + "\n" + tArray[1];
                }
              } else {
                return "";
              }
            }}
          />
        );
    }
  }, [selectedTime]);

  const netFlowTime = ["24h", "15m", "1h"];

  const highestInPeopleCount = inConnectedPeople?.map((v) => v.people) || [];
  const highestOutPeopleCount = outConnectedPeople?.map((v) => v.people) || [];
  const highestNetFlowCount = netFlow?.map((v) => v.people) || [];

  const highestValue = Math.max.apply(null, [
    ...highestInPeopleCount!,
    ...highestOutPeopleCount!,
    ...highestNetFlowCount!,
  ]);

  useEffect(() => {
    if (resetTime) {
      const newTime = xAxisTime(+resetTime.split(/[.:]/)[0]);
      const index = netFlow?.findIndex((item) => item.date === newTime);
      if (index! > -1) {
        const clonedArray = _.cloneDeep(netFlow);
        clonedArray?.map((item) => (item.people = 0));
        clonedArray?.splice(index!, 0, { date: newTime, people: 0 });
        clonedArray?.splice(index! + 2, 0, {
          date: newTime,
          people: highestValue + 5,
        });
        clonedArray?.splice(index! + 3, 0, { date: newTime, people: 0 });
        setResetTimeArray(clonedArray!);
      } else {
        setResetTimeArray([]);
      }
    }
  }, [highestValue, netFlow, resetTime]);

  return (
    <Grid container={true} item={true}>
      <Grid className={classes.victoryChart}>
        <VictoryChart
          height={180}
          padding={{ top: 10, bottom: 50, left: 40, right: 20 }}
          containerComponent={<VictoryVoronoiContainer />}
        >
          <VictoryLegend
            x={resetTime && netFlowTime.includes(selectedTime!) ? 250 : 310}
            y={-4}
            centerTitle
            colorScale={[
              "#2F56A0",
              "#81D3E0",
              resetTime ? "#0E1D33" : "#FFFFFF",
              "#FFFFFF",
            ]}
            orientation="horizontal"
            gutter={5}
            style={{ labels: { fontSize: 5 } }}
            data={[
              { name: "Total INs" },
              { name: "Total OUTs" },
              { name: resetTime ? "Net Occupancy" : "" },
              {
                name:
                  netFlowTime.includes(selectedTime!) && resetTime
                    ? "Reset Time: " + showTime(+resetTime.split(/[.:]/)[0])
                    : "",
              },
            ]}
          />
          {xAxis}
          <VictoryAxis
            dependentAxis={true}
            domain={{ y: [0, highestValue + 5] }}
            tickFormat={(t) => t}
            offsetX={40}
            label={YAxisLabel}
            axisLabelComponent={
              <VictoryLabel
                style={{ fontSize: 6, fontFamily: "Poppins", color: "#ccc" }}
              />
            }
            style={{
              axis: { stroke: "#ccc", strokeWidth: 1 },
              tickLabels: { fontSize: 6, fontFamily: "Poppins" },
            }}
          />
          {statisticsSelect === "CAMERA" ? (
            [
              <VictoryLine
                name="outConnectedPeople"
                data={outConnectedPeople}
                x="date"
                y="people"
                style={{
                  data: { stroke: "#81D3E0", strokeWidth: 1 },
                }}
                labels={(d) => Math.round(d.datum.people)}
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={1}
                    flyoutWidth={15}
                    flyoutHeight={15}
                    pointerWidth={5}
                    flyoutStyle={{
                      stroke: "#B5E9EF",
                      fill: "#E6F8FA",
                    }}
                    style={{ fontSize: 8 }}
                    //renderInPortal={true}
                  />
                }
              />,
              <VictoryLine
                name="inConnectedPeople"
                data={inConnectedPeople}
                x="date"
                y="people"
                style={{
                  data: { stroke: "#2F56A0", strokeWidth: 1 },
                }}
                labels={(d) => Math.round(d.datum.people)}
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={1}
                    flyoutWidth={15}
                    flyoutHeight={15}
                    pointerWidth={5}
                    flyoutStyle={{
                      stroke: "#325dad",
                      fill: "#E6F8FA",
                    }}
                    style={{ fontSize: 8 }}
                    //renderInPortal={true}
                  />
                }
              />,
              resetTime ? (
                <VictoryLine
                  name="netFlow"
                  data={netFlow}
                  x="date"
                  y="people"
                  style={{
                    data: { stroke: "#0E1D33", strokeWidth: 1 },
                  }}
                  labels={(d) => Math.round(d.datum.people)}
                  labelComponent={
                    <VictoryTooltip
                      cornerRadius={1}
                      flyoutWidth={15}
                      flyoutHeight={15}
                      pointerWidth={5}
                      flyoutStyle={{
                        stroke: "#0E1D33",
                        fill: "#E6F8FA",
                      }}
                      style={{ fontSize: 8 }}
                      //renderInPortal={true}
                    />
                  }
                />
              ) : (
                <></>
              ),
              (selectedTime === "24h" ||
              selectedTime === "15m" ||
              selectedTime === "1h") &&
            resetTime ? (
              <VictoryArea
                style={{ data: { fill: "#ACACAC", strokeWidth: 1.5 } }}
                data={resetTimeArray?.map((value: any) => {
                  return {
                    x: value.date,
                    y: value.people > 0 ? highestValue + 5 : 0,
                  };
                })}
              />
            ) : (
              <></>
            )
            ]
          ) : (
            <></>
          )}
        </VictoryChart>
      </Grid>
      <Grid md={4} className={classes.timeZoneWrapper}>
        <Typography className={classes.timeZone}>
          <ClockIcon style={{ marginRight: 2, width: 11, height: 11 }} />
          {selectedProperty?.timeZoneName || selectedProperty?.timeZone}
        </Typography>
      </Grid>
    </Grid>
  );
}
