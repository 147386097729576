import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  noData: {
    backgroundColor: "#81D3E0",
    width: "5px",
    display: "block",
    height: "16px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  moderate: {
    backgroundColor: "#EEAA46",
    width: "5px",
    display: "block",
    height: "16px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  poor: {
    backgroundColor: "#B0001F",
    width: "5px",
    display: "block",
    height: "16px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  healthy: {
    backgroundColor: "#4F9E53",
    width: "5px",
    display: "block",
    height: "16px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  offline: {
    backgroundColor: "#A0A0A0",
    width: "5px",
    display: "block",
    height: "16px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  indicatorWrapper: {
    display: "flex",
    marginRight: "15px",
  },
  indicatorText: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
    marginRight: theme.typography.pxToRem(5),
    alignItems: "center",
    textAlign: "center",
    display: "flex",
  },
  indicatorHeader: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
    marginRight: theme.typography.pxToRem(15),
    alignItems: "center",
    textAlign: "center",
    display: "flex",
  },
  indicatorRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.typography.pxToRem(10),
  },
  legend: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: "25px",
    marginTop: "20px",
  },
  deviceLegend: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  spaceLoadLegend: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "50px",
  },
}));

const legendItems = [
  {
    className: "healthy",
    label: "Healthy",
  },
  {
    className: "moderate",
    label: "Moderate",
  },
  {
    className: "poor",
    label: "Poor",
  },
  {
    className: "noData",
    label: "No Data",
  },
  {
    className: "offline",
    label: "Offline",
  },
];

export const SensorsLegend = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <Grid className={classes.legend}>
      <Grid item className={classes.spaceLoadLegend}>
        <Typography className={classes.indicatorHeader}>
          {formatMessage({
            id: "legend.occupancy.key",
            defaultMessage: "Air Quality:",
            description: "Space load legend for chart",
          })}
        </Typography>
        {legendItems.map((item) => (
          <Grid item className={classes.indicatorWrapper}>
            <Typography className={classes.indicatorText}>
              {formatMessage({
                id: `propertySpaceLoad.spaceLoad.${item.className}`,
                defaultMessage: item.label,
                description: `Sensor ${item.label} label`,
              })}
            </Typography>
            <span
              className={classes[item.className as keyof typeof classes]}
            ></span>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
