import React, { useCallback, useState, useEffect, useLayoutEffect} from "react";
import { useIntl } from "react-intl";
import {
  Grid,
  Typography,
  //  CardMedia,
  makeStyles,
  Button,
} from "@material-ui/core";

import { Property, Role } from "../../../../../../types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../common/constants/Routing";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useAuth } from "../../../../../../core/context/containers/Auth.container";
import { DetailLabel } from "../DetailLabel";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import { PageHeader } from "../../../../../../common/components";
import GQLService from "../../../../../../core/services/GQL.service";

const useStyles = makeStyles((theme) => ({
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
    marginBottom: theme.spacing(5),
  },
  blueLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.funBlue,
    marginLeft: "5px",
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.blackPearl,
    marginLeft: "5px",
  },
  button: {
    backgroundColor: theme.palette.common.matisse,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: 115,
    height: 42,
  },
  wrapper: {
    display: "flex",
    width: "25%",
    justifyContent: "space-between",
    flexBasis: 0,
  },
  main: {
    marginTop: theme.spacing(3),
  },
  mediumIndicator: {
    backgroundColor: "#FCA642",
    width: "8px",
    display: "block",
    height: "35px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  lowIndicator: {
    backgroundColor: "#9BCA60",
    width: "8px",
    display: "block",
    height: "35px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  highIndicator: {
    backgroundColor: "#E25353",
    width: "8px",
    display: "block",
    height: "35px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  indicatorWrapper: {
    display: "flex",
  },
  numberWrapper: {
    display: "flex",
    height: "fit-content",
    alignItems: "center",
  },
  buttonEdit: {
    backgroundColor: theme.palette.common.matisse,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: 115,
    height: 42,
    alignSelf: "flex-start",
  },
  valueLabel: {
    borderBottom: "1px solid #D2D2D2",
    paddingRight: "20px",
    width: "fit-content",
    marginLeft: "5px",
    fontSize: "14px",
  },
  columnWrapper: {
    flexDirection: "column",
    display: "flex",
    marginBottom: "25px",
    minHeight: "100px",
    justifyContent: "space-between",
  },
}));


export function ReservationSettings() {
  const classes = useStyles();
  const { selectedProperty, setSelectedProperty } = usePropertiesState();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const auth = useAuth();

  const [getPropertyById, { data }] = useLazyQuery(
      GQLService.queries.propertyById, {
        fetchPolicy: "cache-and-network",
      });

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !data) {
      getPropertyById({ variables: { propertyId: selectedProperty?.id! } })
    } else {
      setSelectedProperty(data?.propertyById)
    }
    return () => {
      isMounted = false;
    };
  }, [data]);

  const handleEditClick = () => {
    history.push(ROUTES.RESERVATION_SETTINGS_EDIT.replace(":uid", selectedProperty?.id!));
  };

  const createPropertyButton = useCallback(() => {
    return (
      <Button className={classes.button} onClick={handleEditClick}>
        Update
      </Button>
    );
  }, []);

  return (
    <Grid item={true} md={6} sm={12} className={classes.main}>
      <PageHeader
        title={formatMessage({
          id: "reservationSettings.main.header",
          defaultMessage: "Reservation Settings",
          description: "Reservation Settings header",
        })}
      />
      <Grid>
        <DetailLabel
          label={formatMessage({
            id: "accountInfo.businessEntity.header",
            defaultMessage: "Scheduling Slot Minutes",
            description: "business entity header",
          })}
          value={selectedProperty?.reservationConfigurations[0]?.schedulingSlotMinutes?.toString()}
        />
        <DetailLabel
          label={formatMessage({
            id: "accountInfo.businessEntity.header",
            defaultMessage:
              "Pass expiration grace period before entering elevator bay",
            description: "business entity header",
          })}
          value={selectedProperty?.reservationConfigurations[0]?.passExpirationGracePeriodMinutes?.toString()}
        />
        <DetailLabel
          label={formatMessage({
            id: "accountInfo.businessEntity.header",
            defaultMessage:
              "Additional expiration time after entering elevator bay",
            description: "business entity header",
          })}
          value={selectedProperty?.reservationConfigurations[0]?.passGracePeriodMinutes?.toString()}
        />
        <DetailLabel
          label={formatMessage({
            id: "accountInfo.businessEntity.header",
            defaultMessage: "Max Schedulability For Slot Time",
            description: "business entity header",
          })}
          value={selectedProperty?.reservationConfigurations[0]?.maxSchedulability?.toString()}
        />
        <DetailLabel
          label={formatMessage({
            id: "accountInfo.businessEntity.header",
            defaultMessage: "Max Ahead Time Of Reservation Hours",
            description: "business entity header",
          })}
          value={selectedProperty?.reservationConfigurations[0]?.maxAheadTimeOfReservationHours?.toString()}
        />
        <DetailLabel
          label={formatMessage({
            id: "accountInfo.businessEntity.header",
            defaultMessage: "Maximum Number Of Reservations Per User",
            description: "business entity header",
          })}
          value={selectedProperty?.reservationConfigurations[0]?.maxReservationsPerUser?.toString()}
        />
      </Grid>
      {auth?.canSee(
        auth?.user?.role?.toUpperCase() as Role,
        "properties:edit",
        createPropertyButton()
      )}
    </Grid>
  );
}
