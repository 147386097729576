import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  usersHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  addButton: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    minWidth: 180,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
    border: "1px solid #2F56A0",
  },
  addHost: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    minWidth: 90,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
    border: "1px solid #2F56A0",
    textTransform: "initial",
    marginLeft: "15px",
  },
  subheader: {
    fontSize: "14px",
    lineHeight: "21px",
    textAlign: "left",
    color: "#292929",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  sectionWrapper: {
    display: "flex",
  },
  confirm: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  groupHeader: {
    color: theme.palette.common.funBlue,
    fontSize: "14px",
    lineHeight: "21px",
    textAlign: "right",
    fontWeight: "bold",
    marginLeft: "15px",
  },
  cancel: {
    backgroundColor: "transparent",
    color: "#2F56A1",
    textTransform: "initial",
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(28),
    color: "#2F56A1",
    marginBottom: "28px",
  },
  marginTop: {
    marginTop: "20px",
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: "0E1D33",
    fontWeight: 600,
    marginBottom: "60px",
    alignSelf: "left",
    width: "90%",
  },
  line: {
    marginBottom: "15px",
  },
  alignLeft: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    display: "flex",
    minWidth: "425px",
    minHeight: "315px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    padding: "29px 37px",
    alignItems: "center",
    borderRadius: "20px",
    boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
    zIndex: 1000,
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: 14,
    width: "50%",
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
    "&:before": {
      borderBottom: "none",
    },
  },
  inputFile: { display: "none" },
  inputLabel: {
    margin: "20px 0 5px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.dustyGray,
  },
  expandIcon: {
    color: theme.palette.common.inputGrey,
    width: 26,
  },
  accordionDetails: {
    paddingBottom: "40px",
    flexDirection: "column",
  },
  addHostWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "35px",
  },
  chip: {
    margin: theme.spacing(0.5),
    outline: "none",
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
    height: 31,
    borderRadius: 6,
    textDecoration: "none",
    position: "relative",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
  },
  chipIcon: {
    color: "white",

    "&:hover": {
      color: "white",
    },
  },
  info: {
    width: "100%",
    color: theme.palette.common.blackPearl,
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "18px",
  },
  ignoreListWrapper: {
    minHeight: "750px",
    border: "1px solid #BDCED7",
    padding: "20px",
    marginLeft: "20px",
  },
  marginLeft: {
    marginLeft: "20px",
  },
  hostName: {
    textAlign: "left",
    color: theme.palette.common.funBlue,
    fontSize: "12px",
    fontWeight: "bold",
  },
}));
