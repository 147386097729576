import React, { useCallback, useState } from "react";
import { useStyles } from "./SideList";
import { Grid, Typography, Dialog, DialogTitle, DialogActions, Button, } from "@material-ui/core";
import { Edit, HighlightOff } from "@material-ui/icons";
import { ReactComponent as SpaceIcon } from "../../../../../common/assets/images/Spaces.svg";
import { Space } from "../../../../../types";
import { useIntl } from "react-intl";

export function SpaceListItem({
  item,
  drawSpace,
  clearSpace,
}: {
  item: Space,
  drawSpace?: (space: Space) => void;
  clearSpace?: (space: Space) => void;
}) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [selectedSpace, setSelectedSpace] = useState<Space | null>(null);

  const handleClearSpace = useCallback((item: Space) => {
    setShowConfirmation(true);
    setSelectedSpace(item);
  }, []);

  const clearConfirmation = useCallback(() => {
    setShowConfirmation(false);
    setSelectedSpace(null);
    clearSpace && clearSpace(item);
  }, [clearSpace, item, selectedSpace,]);

  return (
    <Grid container={true} className={classes.listItem}>
      <Grid container={true} alignItems="center">
        <Grid
          sm={2}
          md={2}
          container={true}
          alignItems="center"
          justify="center"
        >
          <SpaceIcon className={classes.doorIcon} />
        </Grid>
        <Grid item sm={8}>
          <Typography className={classes.doorNameText}>
            {item.name}
          </Typography>
        </Grid>
        <Grid container={true} sm={2} 
          alignItems="center"
          justify="center">
          {item.placement && Object.keys(item.placement).length > 0 ? (
            <HighlightOff
              className={classes.iconStyle}
              onClick={() => handleClearSpace!(item)}
            />
          ) : (
            <Edit
              className={classes.iconStyle}
              onClick={() => drawSpace!(item)}
            />
          )}
        </Grid>
      </Grid>
      <Dialog
        open={showConfirmation && (!!selectedSpace)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {formatMessage({
            id: "floorsettings.space.clearSpace.confirmation",
            defaultMessage: "Clear location",
            description: "Option to clear placement.",
          })}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowConfirmation(false)}>
            {formatMessage({
              id: "button.discard",
              defaultMessage: "Discard",
              description: "Discard changes.",
            })}
          </Button>
          <Button onClick={clearConfirmation} color="primary" autoFocus>
            {formatMessage({
              id: "button.confirm",
              defaultMessage: "Yes, Confirm",
              description: "Confirm and clear space placement.",
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
