import React from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";

import { ReactComponent as Logo } from "../../assets/images/app-logo.svg";
import { ReactComponent as LogoWhite } from "../../assets/images/app-logo-white.svg";
import { useStyles } from "./AppLogo.styles";

interface AppLogoProps {
  style?: string;
}

export function AppLogo({ style = "" }: AppLogoProps) {
  const classes = useStyles();

  return (
    <Grid className={clsx(classes.logo, style)}>
      <Logo className={classes.logo} />
    </Grid>
  );
}

export function AppLogoWhite({ style = "" }: AppLogoProps) {
  const classes = useStyles();

  return (
    <Grid className={clsx(classes.logo, style)}>
      <LogoWhite className={classes.logo} />
    </Grid>
  );
}
