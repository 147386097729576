import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
    position: "relative",
  },
  submit: {
    backgroundColor: theme.palette.common.matisse,
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: "50%",
  },
  input: {
    margin: "10px 0",
    height: 40,
    border: `1px solid ${theme.palette.common.turquoiseBlue30}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.turquoiseBlue15,
    paddingLeft: 10,
  },
  inputLabel: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.funBlue,
  },
}));
