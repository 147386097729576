import { useState, useEffect } from "react";

const isJSONString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export function usePersistedState<T>(key: string, defaultValue: T | null) {
  const [state, setState] = useState<T>(() => {
    const canParse = isJSONString(localStorage.getItem(key)!);
    if (canParse) {
      return JSON.parse(localStorage.getItem(key)!);
    } else if (!canParse) {
      return localStorage.getItem(key) || defaultValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(
      key,
      typeof state === "string" ? state : JSON.stringify(state)
    );
  }, [key, state]);

  return { state, setState };
}
