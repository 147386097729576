import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  titleGrid: {
    backgroundColor: "#ECF8FA",
    padding: "10px 0px 10px 0px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    marginTop: "10px",
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInputBase-root": {
      fontSize: theme.typography.pxToRem(12),
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
      paddingLeft: "5px",
    },
  },
  labelGrid: {
    display: "flex",
    marginBottom: "5px",
    paddingLeft: "8px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "4px",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "8px",
  },
  header: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconStyle: {
    fontSize: "1rem",
    padding: "0 5px",
    width: "auto",
    cursor: "pointer",
  },
  hideContent: {
    display: "none",
  },
  showContent: {
    display: "block",
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
  },
  blackText: {
    display: "inline-block",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: "9px 9px 9px 0",
  },
  menu: {
    boxShadow: "none !important",
    borderRadius: "0 0 8px 8px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  select: {
    height: 13,
    minWidth: 100,
    borderRadius: 8,
    paddingLeft: "5px",
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: "#ffffff",
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: "#ffffff",
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  charError: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.common.mediumBlack,
    marginLeft: "4px",
  },
  newInput: {
    width: "180px",
    border: `1px solid #ffffff`,
    borderRadius: 7,
    backgroundColor: "#ffffff",
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    marginBottom: "5px",
    "&:focus": {
      backgroundColor: "#ffffff",
    },
  },
}));
