import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { useMutation, useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { User } from "../../../../types";
import { PropertyList } from "./PropertyList";
import {
  resetPasswordFormFields,
  resetPasswordValidationSchema,
  UpdateUserForm,
} from "./Profile.form";
import { Form } from "../../../../common/components";
import { AvatarUpload } from "./AvatarUpload";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  infoSection: {
    width: "30%",
  },
  propertiesSection: {
    width: "65%",
    marginLeft: "5%",
  },
  header: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "36px",
    color: theme.palette.common.blackText,
    marginBottom: "25px",
  },
  passwordButton: {
    backgroundColor: "transparent",
    color: "#2F56A1",
    textTransform: "initial",
    width: 210,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    border: "1px solid #2F56A1",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "none",
    },
    marginTop: "25px",
    marginBottom: "25px",
  },
  emailHeader: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#2F56A1",
  },
  personalDataHeader: {
    marginBottom: "25px",
  },
  avatarUpload: {
    width: "150px",
    height: "150px",
  },
  hideResetPasswordForm: {
    opacity: 0,
    maxHeight: 0,
    overflow: "hidden",
  },
  resetPasswordForm: {
    transition: "max-height 1s linear, opacity 1s linear",
    opacity: 1,
    maxHeight: "450px",
  },
}));

export interface floorPlanFile {
  name: string | undefined;
  url: string | undefined;
  data: File | undefined;
}

export function Profile() {
  const auth = useAuth();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [account, setAccount] = useState<User | null>(null);

  const { loading, data } = useQuery(GQLService.queries.user, {
    fetchPolicy: "cache-first",
    variables: {
      email: auth?.user?.email || auth?.user?.fullName,
    },
  });
  const [changeUserPassword] = useMutation(
    GQLService.mutations.changeUserPassword
  );
  const [error, setError] = useState<boolean>(false);
  const [showForm, toggleShowForm] = useState<boolean>(false);

  const [updateUserSettings] = useMutation(
    GQLService.mutations.updateUserSettings,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.user,
          variables: {
            email: auth?.user?.email || auth?.user?.fullName,
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const updateUser = useCallback(
    async (formData) => {
      await updateUserSettings({
        variables: {
          name: formData.fullName,
          email: account?.email!,
        },
      });
    },
    [account]
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!loading) {
        setAccount(data.userByEmail);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [loading, account, data]);

  const openForm = useCallback(() => {
    toggleShowForm(true);
  }, [showForm]);

  const resetPassword = useCallback(
    async (formData: any) => {
      try {
        const passwordReset = await changeUserPassword({
          variables: {
            oldPassword: formData.oldPassword,
            newPassword: formData.newPassword,
            email: auth?.user?.email || account?.email!,
          },
        });
        toggleShowForm(false);
      } catch (err) {
        setError(true);
      }
    },
    [error]
  );

  return (
    <Grid container>
      <Typography className={classes.header}>
        {formatMessage({
          id: "profile.header",
          defaultMessage: "User Settings",
          description: "user settings page",
        })}
      </Typography>
      <Grid container>
        <Grid className={classes.infoSection}>
          <Typography className={classes.personalDataHeader}>
            {formatMessage({
              id: "profile.personaldata.section",
              defaultMessage: "Your personal data",
              description: "personal data section",
            })}
          </Typography>
          <Grid>
            <Typography className={classes.emailHeader}>
              {formatMessage({
                id: "profile.personaldata.section",
                defaultMessage: "Your email",
                description: "personal data section",
              })}
            </Typography>
            <Typography>
              {formatMessage({
                id: "profile.personaldata.section",
                defaultMessage: account?.email,
                description: "personal data email section",
              })}
            </Typography>
          </Grid>
          <Grid item={true} md={12} sm={12}>
            {account && (
              <UpdateUserForm account={account} updateUser={updateUser} />
            )}
            <AvatarUpload
              name={"Avatar upload"}
              previewStyle={classes.avatarUpload}
            />
          </Grid>
          <Button className={classes.passwordButton} onClick={openForm}>
            {formatMessage({
              id: "profile.personaldata.changepassword",
              defaultMessage: "Change Password",
              description: "Change password",
            })}
          </Button>
          <Grid
            className={clsx(
              classes.hideResetPasswordForm,
              showForm ? classes.resetPasswordForm : ""
            )}
          >
            <Form
              defaultValues={{}}
              fields={resetPasswordFormFields}
              validationSchema={resetPasswordValidationSchema()}
              onSubmit={resetPassword}
            />
          </Grid>
        </Grid>
        <Grid className={classes.propertiesSection}>
          {account && (
            <PropertyList
              list={account?.properties!}
              default={account.defaultProperty?.id}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
