import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../../common/styles/colors";

export const useStyles = makeStyles((theme) => ({
  sideListElement: {
    borderBottom: `1px solid ${theme.palette.common.lightGray}`,
    cursor: "pointer",
    marginTop: 0,
    marginBottom: 0,
    margin: "0 !important",
    border: 0,
  },
  sideListElementActive: {
    borderBottom: `1px solid ${theme.palette.common.lightGray}`,
    cursor: "pointer",
    backgroundColor: "#E6F8FA",
    marginTop: 0,
    marginBottom: 0,
    margin: "0 !important",
    border: 0,
  },
  name: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    // padding: theme.typography.pxToRem(5),
    marginBottom: theme.typography.pxToRem(5),
    color: theme.palette.common.darkBlue,
  },
  mediumIndicator: {
    backgroundColor: theme.palette.common.carrotOrange,
    width: "12px",
    display: "block",
    height: "12px",
    borderRadius: "50%",
    marginRight: "5px",
  },
  lowIndicator: {
    backgroundColor: theme.palette.common.chateauGreen,
    width: "12px",
    display: "block",
    height: "12px",
    borderRadius: "50%",
    marginRight: "5px",
  },
  notConfiguredIndicator: {
    backgroundColor: "#0E1D33",
    width: "12px",
    display: "block",
    height: "12px",
    borderRadius: "50%",
    marginRight: "5px",
  },
  offlineIndicator: {
    backgroundColor: "#A0A0A0",
    width: "12px",
    display: "block",
    height: "12px",
    borderRadius: "50%",
    marginRight: "5px",
  },
  offline: {
    backgroundColor: "#A0A0A0",
    width: "12px",
    display: "block",
    height: "12px",
    borderRadius: "50%",
    marginRight: "5px",
  },
  highIndicator: {
    backgroundColor: theme.palette.error.main,
    width: "12px",
    display: "block",
    height: "12px",
    borderRadius: "50%",
    marginRight: "8px",
  },
  indicatorWrapper: {
    display: "flex",
  },
  indicatorText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(21),
    marginRight: theme.typography.pxToRem(5),
  },
  indicatorRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    width: "100%",
    position: "relative",
  },
  indicatorAndTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "nowrap",
    flex: "1 1 auto",
  },
  ssid: {
    fontSize: theme.typography.pxToRem(12),
    // lineHeight: theme.typography.pxToRem(18),
    display: "flex",
    fontWeight: theme.typography.fontWeightRegular,
  },
  ssidName: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    marginLeft: "5px",
  },
  chartContainer: {
    marginBottom: theme.spacing(3),
  },
  link: {
    color: theme.palette.common.blue,
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 6,
    display: "flex",
    flexWrap: "nowrap",
  },
  popper: {
    zIndex: 10001,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EBEBEB",
    boxSizing: "border-box",
    boxShadow: "0px 4px 13px rgba(135, 135, 135, 0.3)",
    width: 432,
    // padding: "20px 30px",
    borderRadius: 5,
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    top: -5,
    right: -5,
    color: "blue",
    width: 22,
    "&:hover": {
      cursor: "pointer",
    },
  },
  popperTitle: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
  },
  chartsTitle: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    margin: "12px 0 8px 0",
  },
  showStatisticsContainer: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    flex: "1 1 auto",
  },
  chartsContainer: {
    padding: "0 30px",
  },
  popperContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  statusLabel: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: theme.typography.fontFamily,
  },
  status: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    padding: "0 7px",
    textTransform: "capitalize",
  },
  currentStatusIndicatorContainer: {
    display: "flex",
    padding: "29px 0",
    alignItems: "center",
  },
  popperIndicator: {
    width: 20,
    height: 20,
  },
  img: {
    width: 860,
    height: 657,
  },
  divider: {
    width: "100%",
    backgroundColor: "#DFDFDF",
    margin: "20px 0",
  },
  paper: {
    boxShadow: "none",
  },
  detailsTitle: {
    textTransform: "capitalize",
    fontSize: "12px",
  },
  detailsValue: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  baseForScoreIndicator: {
    width: "30px",
    minWidth: "30px",
    height: "30px",
    borderRadius: "50%",
    color: "#fff",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  baseForOfflineScoreIndicator: {
    width: "30px",
    minWidth: "30px",
    height: "30px",
    borderRadius: "50%",
    color: "#A0A0A0",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  noData: {
    backgroundColor: "#81D3E0",
  },
  moderate: {
    backgroundColor: "#EEAA46",
  },
  poor: {
    backgroundColor: "#B0001F",
  },
  healthy: {
    backgroundColor: "#4F9E53",
  },
  qualityIndicator: {
    height: "8px",
    width: "8px",
    borderRadius: "10px",
    display: "inline-block",
  },
  dropDownContainer: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
  },
  dropDownTitle: {
    color: theme.palette.common.darkBlue,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    margin: "0 8px 8px 0",
  },
  select: {
    height: 13,
    minWidth: 100,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.common.inputFrame}`,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: 8,
    },
    "&:active": {
      backgroundColor: theme.palette.common.InputBg,
      borderRadius: "8px 8px 0 0",
      borderBottom: "none",
    },
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: 9,
  },
  icon: {
    color: theme.palette.common.textBlack,
    right: 7,
    width: 18,
  },
  menu: {
    boxShadow: "none !important",
    backgroundColor: theme.palette.common.InputBg,
    borderRadius: "0 0 8px 8px",
  },
  option: {
    width: "100%",
    padding: 9,
    backgroundColor: theme.palette.common.InputBg,
    height: 33,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: "#292929",
    "&:focus, &:hover, &:active": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  selected: {
    backgroundColor: "#C1E2E6 !important",
  },
  YAxisLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    display: "flex",
    justifyContent: "center",
    width: 45,
  },
  pinItButton: {
    backgroundColor: theme.palette.common.funBlue,
    borderRadius: 30,
    color: COLORS.white,
    textTransform: "none",
    width: "100px",
    height: "35px",
  },
  pinIconGrid: {
    display: "flex",
    justifyContent: "flex-end",
    transform: "translateX(13px) translateY(5px)",
    marginRight: "5px",
  },
  content: {
    display: "block",
  },
  expandIcon: {
    color: theme.palette.common.inputGrey,
  },
  lineChartIcon: {
    marginTop: "5px",
    marginRight: "10px",
    marginLeft: "5px",
  },
  root: {
    padding: "0px 4px",
  },
}));
