import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  Button,
  ClickAwayListener,
  Grid,
  Input,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../NetworkSettings.styles";

interface ModalContentProps {
  no: Function;
  yes: Function | ((value: string, name: string) => Promise<void>);
  group: any;
}

export default function EditGroupNameModal({
  no,
  yes,
  group,
}: ModalContentProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [groupName, setGroupName] = useState<string>(group.name);

  const outSideClick = useCallback(() => {
    no();
  }, []);

  const onCancel = useCallback(() => {
    no();
  }, []);

  const editGroupName = useCallback(() => {
    yes(group.id, groupName);
  }, [group, groupName]);

  return (
    <ClickAwayListener onClickAway={outSideClick}>
      <Grid className={classes.content}>
        <Grid className={classes.alignLeft}>
          <Typography className={classes.header}>
            {formatMessage({
              id: "editGroupName.settings",
              defaultMessage: "Edit name of the network group",
              description: "Remove from the list",
            })}
          </Typography>
          <InputLabel className={classes.inputLabel}>
            {formatMessage({
              id: "editGroupName.label.settings",
              defaultMessage: "Edit name",
              description: "host name label",
            })}
          </InputLabel>
          <Input
            value={groupName}
            className={classes.input}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </Grid>
        <Grid className={classes.buttonsWrapper}>
          <Button className={classes.cancel} onClick={onCancel}>
            {formatMessage({
              id: "editGroupName.label.settings",
              defaultMessage: "Cancel",
              description: "group name label",
            })}
          </Button>
          <Button className={classes.confirm} onClick={editGroupName}>
            {formatMessage({
              id: "editGroupName.label.settings",
              defaultMessage: "Yes, Confirm",
              description: "group name label",
            })}
          </Button>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
}
