import React, { useCallback, useMemo, useState } from "react";
import { useFormikContext, FieldInputProps, FormikState } from "formik";
import { Grid, Select as SelectUI } from "@material-ui/core";
import { useStyles } from "./HourSelect.styles";
import { MenuItem } from "@material-ui/core";
import { ISelectData } from "../../../../../types";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
interface SelectProps extends FieldInputProps<any> {
  defaultValue: boolean;
  disabled?: boolean;
  data?: ISelectData;
  placeholder: string;
  customProps: {
    min: number;
    max: number;
  };
}

export function HourSelect({
  name,
  defaultValue = false,
  disabled = false,
  onChange,
  placeholder,
  data,
  customProps,
}: SelectProps) {
  const classes = useStyles();
  const { errors, values, initialValues, isSubmitting } = useFormikContext<
    FormikState<{ [key: string]: string }>
  >();
  const [selectValue, setSelectValue] = useState(
    values[name as keyof typeof values] || placeholder
  );
  const isTouched =
    isSubmitting ||
    values[name as keyof typeof values] !==
      initialValues[name as keyof typeof values];

  const handleSelectOptClickFrom = useCallback(
    (e) => {
      setSelectValue(e.target.value);
      onChange({ ...e, target: { name, value: e.target.value } });
    },
    [selectValue] // eslint-disable-line
  );

  const timeValues: any[] = [];
  useMemo(() => {
    for (let i = customProps.min; i < customProps.max; i++) {
      const label =
        i === 0
          ? `12:00 AM`
          : i < 12
          ? `${i}:00 AM`
          : i === 12
          ? `12:00 PM`
          : `${i - 12}:00 PM`;
      timeValues.push(label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeValues]);

  const listTimeIn24Hr = (time: string) => {
    const timeArr = time.split(" ");
    const timeInNum = parseInt(timeArr[0]);
    if (timeArr[1] === "AM") {
      if (timeInNum === 12) return timeInNum - 12;
      return timeInNum;
    } else {
      if (timeInNum === 12) return timeInNum;
      return timeInNum + 12;
    }
  };
  return (
    <Grid
      key={name}
      container={true}
      item={true}
      direction="row"
      alignItems="center"
    >
      <SelectUI
        classes={{
          root: `${
            isTouched && errors[name as keyof typeof values]
              ? classes.error
              : classes.select
          }`,
          select: classes.blackText,
          icon: classes.icon,
        }}
        MenuProps={{
          classes: {
            paper: classes.menu,
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        IconComponent={ExpandMoreSharpIcon}
        value={selectValue}
        disableUnderline={true}
        onChange={handleSelectOptClickFrom}
        name={name}
        renderValue={
          selectValue !== placeholder
            ? undefined
            : () => <Placeholder>{placeholder}</Placeholder>
        }
      >
        <MenuItem
          key={-1}
          value={placeholder}
          disabled
          classes={{
            root: classes.option,
            selected: classes.selected,
          }}
        >
          {placeholder}
        </MenuItem>
        {timeValues.map((v: any, i) => (
          <MenuItem
            key={i + 1}
            value={listTimeIn24Hr(v) < 10 ? `0${listTimeIn24Hr(v)}:00` : `${listTimeIn24Hr(v)}:00`}
            disabled={false}
            classes={{
              root: classes.option,
              selected: classes.selected,
            }}
          >
            {v}
          </MenuItem>
        ))}
      </SelectUI>
    </Grid>
  );
}

function Placeholder({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return <div className={classes.placeholder}>{children}</div>;
}
