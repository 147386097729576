import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Grid, Typography, makeStyles, Checkbox } from "@material-ui/core";

import { Property } from "../../../../../../../types";
import { DetailLabel } from "../../DetailLabel";
import { useLazyQuery, useMutation } from "@apollo/client";
import { usePropertiesState } from "../../../../../../../core/context/containers/Properties.container";
import GQLService from "../../../../../../../core/services/GQL.service";
import { StatusIndicator } from "../../../../../../../common/components";
import { useUserState } from "../../../../../../../core/context/containers/User.container";
// import { WorkingHoursDetailLabel } from "../../WorkingHoursDetailLabel";
import _ from "lodash";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../../../../../../common/constants/DummyProperty";

const useStyles = makeStyles((theme) => ({
  blackHeader: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    marginBottom: theme.spacing(5),
    lineHeight: theme.typography.pxToRem(24),
  },
  marginTop: {
    marginTop: theme.typography.pxToRem(45),
  },
  detailsLabel: {
    color: theme.palette.common.solidGray,
  },
  skipFloor: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    right: "12px",
    marginBottom: "20px",
  },
}));

interface SiteInfoProps {
  property: Property;
}

export function SiteInfo({ property }: SiteInfoProps) {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const [propertyData, setPropertyData] = useState<any>({});
  const [updateLocationDetails] = useMutation(
    GQLService.mutations.updateLocationDetails,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allProperties,
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const { selectedProperty, setSelectedProperty } = usePropertiesState();

  const [getPropertyById, { data }] = useLazyQuery(
    GQLService.queries.propertyById
  );

  useEffect(() => {
    if (data?.propertyById) {
      if (userEmail === dummyUserEmail) {
        let clonedData = _.cloneDeep(data?.propertyById);
        if (clonedData?.id === DummyPropertyA.id) {
          const propA = {
            ...clonedData,
            ownerCompanyName: DummyPropertyA.ownerCompanyName,
            siteName: DummyPropertyA.siteName,
            siteServiceAddress: DummyPropertyA.siteServiceAddress,
          };
          setSelectedProperty(propA);
        } else if (clonedData?.id === DummyPropertyB.id) {
          const propB = {
            ...clonedData,
            ownerCompanyName: DummyPropertyB.ownerCompanyName,
            siteName: DummyPropertyB.siteName,
            siteServiceAddress: DummyPropertyB.siteServiceAddress,
          };
          setSelectedProperty(propB);
        } else if (clonedData?.id === DummyPropertyC.id) {
          const propC = {
            ...clonedData,
            ownerCompanyName: DummyPropertyC.ownerCompanyName,
            siteName: DummyPropertyC.siteName,
            siteServiceAddress: DummyPropertyC.siteServiceAddress,
          };
          setSelectedProperty(propC);
        } else if (clonedData?.id === DummyPropertyD.id) {
          const propD = {
            ...clonedData,
            ownerCompanyName: DummyPropertyD.ownerCompanyName,
            siteName: DummyPropertyD.siteName,
            siteServiceAddress: DummyPropertyD.siteServiceAddress,
          };
          setSelectedProperty(propD);
        } else {
          setSelectedProperty(clonedData);
        }
      } else {
        setSelectedProperty(data?.propertyById);
      }
    }
  }, [data, userEmail]);

  useEffect(() => {
    if (property) {
      if (userEmail === dummyUserEmail) {
        let clonedData = _.cloneDeep(property);
        if (clonedData?.id === DummyPropertyA.id) {
          const propA = {
            ...clonedData,
            ownerCompanyName: DummyPropertyA.ownerCompanyName,
            siteName: DummyPropertyA.siteName,
            siteServiceAddress: DummyPropertyA.siteServiceAddress,
          };
          setPropertyData(propA);
        } else if (clonedData?.id === DummyPropertyB.id) {
          const propB = {
            ...clonedData,
            ownerCompanyName: DummyPropertyB.ownerCompanyName,
            siteName: DummyPropertyB.siteName,
            siteServiceAddress: DummyPropertyB.siteServiceAddress,
          };
          setPropertyData(propB);
        } else if (clonedData?.id === DummyPropertyC.id) {
          const propC = {
            ...clonedData,
            ownerCompanyName: DummyPropertyC.ownerCompanyName,
            siteName: DummyPropertyC.siteName,
            siteServiceAddress: DummyPropertyC.siteServiceAddress,
          };
          setPropertyData(propC);
        } else if (clonedData?.id === DummyPropertyD.id) {
          const propD = {
            ...clonedData,
            ownerCompanyName: DummyPropertyD.ownerCompanyName,
            siteName: DummyPropertyD.siteName,
            siteServiceAddress: DummyPropertyD.siteServiceAddress,
          };
          setPropertyData(propD);
        } else {
          setPropertyData(property);
        }
      } else {
        setPropertyData(property);
      }
    }
  }, [property, userEmail]);

  const toggleActiveUpnextStatus = useCallback(async () => {
    let isMounted = true;
    if (isMounted) {
      try {
        await updateLocationDetails({
          variables: {
            ...selectedProperty,
            propertyId: property?.id!,
            upNext: !selectedProperty?.upNext!,
          },
        });
        getPropertyById({ variables: { propertyId: selectedProperty?.id! } });
      } catch (err) {
        console.log(err);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [selectedProperty]);

  const { formatMessage } = useIntl();

  return (
    propertyData && (
      <Grid className={classes.marginTop}>
        <Typography className={classes.blackHeader}>
          {formatMessage({
            id: "siteInfo.header",
            defaultMessage: "Site Information",
            description: "site information header",
          })}
        </Typography>
        <DetailLabel
          label={formatMessage({
            id: "siteInfo.property.name.label",
            defaultMessage: "Site Name",
            description: "site information site name label",
          })}
          value={propertyData.siteName || ""}
        />
        <DetailLabel
          label={formatMessage({
            id: "siteInfo.property.type.label",
            defaultMessage: "Property Type",
            description: "site information property type label",
          })}
          value={propertyData.sitePropertyType || ""}
        />
        <DetailLabel
          label={formatMessage({
            id: "siteInfo.property.address.label",
            defaultMessage: "Service Address",
            description: "site information service address label",
          })}
          value={propertyData.siteServiceAddress || undefined}
        />
        <DetailLabel
          label={formatMessage({
            id: "siteInfo.property.numberOfFloors.label",
            defaultMessage: "Number of floors above ground level",
            description: "site information number of floors label",
          })}
          value={`${propertyData.siteNrOfFloors}`}
        />
        <Grid item className={classes.skipFloor}>
          <Checkbox
            value={property.id}
            color="primary"
            // inputProps={{ "aria-label": "secondary checkbox" }}
            checked={propertyData.floor0Excluded ? true : false}
            disabled={true}
          />
          <Typography>
            {formatMessage({
              id: "siteInfo.property.skip0Floor.label",
              defaultMessage: "skip floor  “0”",
              description: "skip floor '0' ",
            })}
          </Typography>
        </Grid>
        <DetailLabel
          label={formatMessage({
            id: "siteInfo.property.numberOfUndergroundFloors.label",
            defaultMessage: "Number of underground Floors",
            description: "site information number of underground floors label",
          })}
          value={`${propertyData.siteNrOfUndergroundFloors}` || undefined}
        />
        <DetailLabel
          label={formatMessage({
            id: "companyInfo.property.workingHours.label",
            defaultMessage: "Working Hours",
            description: "status header",
          })}
          value={
            propertyData.workingFrom +
            " - " +
            propertyData.workingTo +
            " " +
            propertyData.timeZoneName
          }
        />
        <DetailLabel
          label={formatMessage({
            id: "companyInfo.property.propertyDateFormat.label",
            defaultMessage: "Property Date Format",
            description: "property date format",
          })}
          value={
            propertyData.propertyDateFormat === "US"
              ? formatMessage({
                  id: "companyInfo.property.US.label",
                  defaultMessage: "US (Jan-31-2021)",
                  description: "US (Jan-31-2021)",
                })
              : formatMessage({
                  id: "companyInfo.proprty.International.label",
                  defaultMessage: "International (31.1.2021)",
                  description: "International (31.1.2021)",
                })
          }
        ></DetailLabel>
        <DetailLabel
          label={formatMessage({
            id: "companyInfo.property.use24HourClock.label",
            defaultMessage: "Use 24 Hour Clock",
            description: "use 24 hour clock",
          })}
          value={
            propertyData.use24HourClock
              ? formatMessage({
                  id: "companyInfo.property.yes.label",
                  defaultMessage: "Yes",
                  description: "Yes",
                })
              : formatMessage({
                  id: "companyInfo.proprty.no.label",
                  defaultMessage: "No",
                  description: "No",
                })
          }
        ></DetailLabel>
        {/* <WorkingHoursDetailLabel
        label={formatMessage({
          id: "companyInfo.property.workingHours.label",
          defaultMessage: "Working Hours",
          description: "company information working hours label label",
        })}
        workingFrom={property.workingFrom || ""}
        workingTo={property.workingTo || ""}
        timeZone={property.timeZone || ""}
      /> */}
        <DetailLabel
          label={formatMessage({
            id: "accountInfo.status.header",
            defaultMessage: "UpNext User",
            description: "status header",
          })}
        >
          <Grid container={true} alignItems="center">
            <StatusIndicator
              uid={propertyData.id}
              isActive={selectedProperty?.upNext!}
              canShowStatusLabel={true}
              onChangeFn={toggleActiveUpnextStatus}
            />
          </Grid>
        </DetailLabel>
      </Grid>
    )
  );
}
