import React, { useCallback, useState, useEffect } from "react";
import {
  DataList,
  EmptyListView,
  CustomModal,
} from "../../../../common/components";
import { useGroupState } from "../../../../core/context/containers/Groups.container";
import { Button, makeStyles } from "@material-ui/core";
import { UsersGroupListItem } from "./UsersGroupListItem";
import { DoorUserGroupModal } from "./DoorUserGroupModal";
import { useHistory } from "react-router-dom";
import { useDoorState } from "../../../../core/context/containers/Door.container";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { Door, Group } from "../../../../types";
import { useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { ROUTES } from "../../../../common/constants/Routing";

const useStyles = makeStyles((theme) => ({
  floorsHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
    padding: "0 8px",
    marginRight: "16px",
  },
  buttonContainer: {
    display: "flex",
  },
}));

export function DoorUserGroupList() {
  const classes = useStyles();
  const history = useHistory();
  const [showModal, toggleModal] = useState<boolean>(false);
  const {
    selectedUsersGroup,
    setSelectedUsersGroup,
    selectedEditUsersGroup,
    setSelectedEditUsersGroup,
  } = useGroupState();
  const { selectedDoor, setSelectedDoor } = useDoorState();
  const { selectedProperty } = usePropertiesState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  let isAdd = history.location.pathname.split("/").includes("add");

  const rejectChanges = () => toggleModal(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (
        !isAdd &&
        selectedEditUsersGroup &&
        selectedEditUsersGroup.length === 0 &&
        selectedDoor?.groups &&
        selectedDoor?.groups?.length > 0
      ) {
        let temp = selectedDoor
          ?.groups!.slice()
          .sort((a, b) =>
            a.name.toUpperCase() < b.name.toUpperCase()
              ? -1
              : a.name.toUpperCase() < b.name.toUpperCase()
              ? 1
              : 0
          );
        setSelectedEditUsersGroup(temp);
      }
    }
    return () => {
      //TODO: console.log("Component will Unmount Are you sure popup Main?")
      isMounted = false;
    };
  }, [selectedUsersGroup, selectedEditUsersGroup, selectedDoor]);

  //FOR EDIT FORM
  const [updateDoor] = useMutation(GQLService.mutations.editDoor);

  //FOR EDIT FORM
  const handleSave = useCallback(() => {
    try {
      updateDoor({
        variables: {
          id: selectedDoor?.id,
          active: selectedDoor?.active,
          availableDays: selectedDoor?.availableDays,
          doorAccessControlVendorId: selectedDoor?.doorAccessControlVendorId,
          floorId: selectedDoor?.floorId,
          name: selectedDoor?.name,
          workingFrom: selectedDoor?.workingFrom,
          workingTo: selectedDoor?.workingTo,
          groups: (selectedEditUsersGroup as Group[]).map((group) => group.id!),
        },
      }).then(() => {
        enqueueSnackbar(
          formatMessage({
            id: "door.edit.mainSettings.snackbar.success",
            defaultMessage: "Door changed successfully",
          }),
          {
            variant: "success",
          }
        );
        history.push(ROUTES.DOORS.replace(":uid", selectedProperty?.id!));
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error", persist: true });
    }
  }, [selectedDoor, selectedEditUsersGroup]);

  return (
    <div>
      {(isAdd &&
        ((selectedUsersGroup && selectedUsersGroup.length === 0) ||
          selectedUsersGroup === null ||
          selectedUsersGroup === undefined)) ||
      (!isAdd &&
        ((selectedEditUsersGroup && selectedEditUsersGroup.length === 0) ||
          selectedEditUsersGroup === null ||
          selectedEditUsersGroup === undefined)) ? (
        <EmptyListView viewType="doorUserGroup" />
      ) : (
        <div>
          <CustomModal open={showModal}>
            <DoorUserGroupModal no={rejectChanges} />
          </CustomModal>
          <DataList
            data={isAdd ? selectedUsersGroup : selectedEditUsersGroup}
            loading={false}
            ListItem={UsersGroupListItem}
          />
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              onClick={() => toggleModal(true)}
            >
              Add User Groups
            </Button>
            {history.location.pathname.split("/").includes("edit") && (
              <Button className={classes.button} onClick={handleSave}>
                Save
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
