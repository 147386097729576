import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, MenuItem, Select } from "@material-ui/core";
import { useStyles } from "./AccessControl.styles";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { useMutation, useQuery } from "@apollo/client";
import GQLService from "../../core/services/GQL.service";
import { Property } from "../../types";
import _ from "lodash";

interface ACSModalProps {
  setToggleModal: Function;
  checkedData: any;
  tabType: string;
  setCheckedData: Function;
}

export function ACSModal({
  setToggleModal,
  checkedData,
  tabType,
  setCheckedData,
}: ACSModalProps) {
  const classes = useStyles();
  const [propertiesData, setPropertiesData] = useState<Property[]>([]);
  const [selectedProp, setSelectedProp] = useState<any>("Select Property");
  const [description, setDescription] = useState<boolean>(false);
  const [propName, setPropName] = useState<string>("");

  const { loading, data } = useQuery(GQLService.queries.allProperties, {
    fetchPolicy: "network-only",
  });

  const [bulkUpdateCardReaders] = useMutation(
    GQLService.mutations.bulkUpdateCardReaders,
    {
      refetchQueries: () => [
        {
          query:
            tabType === "Associated"
              ? GQLService.queries.associatedCardReaders
              : GQLService.queries.unAssociatedCardReaders,
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  useEffect(
    () => {
      let isMounted = true;
      if (isMounted && data) {
        setPropertiesData(data?.properties || []);
      }
      return () => {
        isMounted = false;
      };
    },
    [data] // eslint-disable-line
  );

  const handlePropertyChange = (e: any) => {
    setSelectedProp(e.target.value);
    const findProp = propertiesData?.filter(
      (item) => item?.id === e.target.value
    );
    setPropName(findProp[0]?.siteName);
    setDescription(true);
  };

  const confirmPinIt = () => {
    const updateRequest = checkedData?.map((v: any) => {
      return {
        id: v.id,
        propertyId: selectedProp,
      };
    });
    bulkUpdateCardReaders({
      variables: { request: updateRequest },
    }).then(() => {
      setToggleModal(false);
      setCheckedData([]);
    });
  };

  const getPropName = (data: any) => {
    const propertyName =
      data?.length === 1
        ? data[0].propertyName
        : data.map((val: any) => {
            return `${val.propertyName}, `;
          });

    const uniqPropName = _.uniqBy(propertyName, function (e) {
      return e;
    });
    let lastElement: any = uniqPropName[uniqPropName.length - 1];
    let removeLastComma = lastElement?.replace(/,\s*$/, "");
    uniqPropName.splice(uniqPropName.length - 1, 1, removeLastComma);

    return <strong>{uniqPropName}</strong>;
  };

  return (
    <Grid className={classes.modalGrid}>
      <Grid>
        <Typography className={classes.header}>
          Associate Card Readers with Property
        </Typography>
        <Grid className={classes.dropDownGrid}>
          {description ? (
            tabType === "Associated" ? (
              <Typography className={classes.description}>
                The selected {`${checkedData.length}`} Card{" "}
                {checkedData.length === 1 ? "Reader" : "Readers"} from{" "}
                {getPropName(checkedData)}
                <br />
                will now be associated with <strong>{propName}</strong>
              </Typography>
            ) : (
              <Typography className={classes.description}>
                The selected {`${checkedData.length}`} Card{" "}
                {checkedData.length === 1 ? "Reader" : "Readers"} will be
                associated with
                <br />
                <strong>{propName}</strong>
              </Typography>
            )
          ) : (
            <Select
              classes={{
                root: classes.select,
                select: classes.blackText,
                icon: classes.icon,
              }}
              name="property"
              value={selectedProp}
              disableUnderline={true}
              onChange={handlePropertyChange}
              IconComponent={ExpandMoreSharpIcon}
              MenuProps={{
                classes: {
                  paper: classes.menu,
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              renderValue={
                selectedProp !== "Select Property"
                  ? undefined
                  : () => <Placeholder>{"Select Property"}</Placeholder>
              }
            >
              <MenuItem
                key={-1}
                value={""}
                disabled
                classes={{
                  root: classes.option,
                  selected: classes.selected,
                }}
              >
                {"Select Property"}
              </MenuItem>
              {propertiesData.map((v) => (
                <MenuItem
                  key={v.id}
                  value={v.id}
                  classes={{
                    root: classes.option,
                    selected: classes.selected,
                  }}
                >
                  {v.siteName}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.modalButtonsWrapper}>
        <Button
          id="cancelBtnEditModal"
          onClick={() => setToggleModal(false)}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          id="yesBtnEditModal"
          onClick={() => confirmPinIt()}
          className={classes.associateButton}
          disabled={selectedProp === "Select Property" ? true : false}
          style={{
            color: "#FFFFFF",
            opacity: selectedProp === "Select Property" ? 0.6 : 1,
          }}
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
}

type PlaceholderProps = {
  children: React.ReactNode;
};

function Placeholder({ children }: PlaceholderProps) {
  const classes = useStyles();
  return <div className={classes.placeholder}>{children}</div>;
}
