import React from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Grid, Typography, Button } from "@material-ui/core";

import { useProperties } from "../../../core/context/functions/Properties.functions";
import { useStyles } from "./StatusIndicator.styles";

interface StatusIndicatorProps {
  uid: string;
  isActive: boolean;
  canShowStatusLabel?: boolean;
  onChangeFn?: (...args: any[]) => void;
  showOnlyLabel?: boolean;
}

export function StatusIndicator({
  uid,
  isActive,
  canShowStatusLabel = false,
  showOnlyLabel = false,
  onChangeFn,
}: StatusIndicatorProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { changePropertyStatus } = useProperties();

  return (
    <>
      <Grid
        className={clsx(
          classes.outerCircle,
          isActive ? classes.outerCircleGreen : classes.outerCircleRed
        )}
        container={true}
        alignItems="center"
        justify="center"
      >
        <div
          className={clsx(
            classes.innerCircle,
            isActive ? classes.innerCircleGreen : classes.innerCircleRed
          )}
        />
      </Grid>
      {canShowStatusLabel && (
        <>
          <Typography className={classes.statusLabel}>
            {isActive
              ? formatMessage({
                  id: "statusIndicator.active",
                  defaultMessage: "Active",
                  description: "status indicator active",
                })
              : formatMessage({
                  id: "statusIndicator.inactive",
                  defaultMessage: "Inactive",
                  description: "status indicator inactive",
                })}
          </Typography>
          <Button
            className={classes.deactivateBtn}
            onClick={() =>
              onChangeFn ? onChangeFn() : changePropertyStatus(uid, !isActive)
            }
          >
            {isActive
              ? formatMessage({
                  id: "statusIndicator.button.deactivate",
                  defaultMessage: "Deactivate",
                  description: "status indicator deactivate button",
                })
              : formatMessage({
                  id: "statusIndicator.button.activate",
                  defaultMessage: "Activate",
                  description: "status indicator activate button",
                })}
          </Button>
        </>
      )}
      {showOnlyLabel && (
        <>
          <Typography className={classes.statusLabel}>
            {isActive
              ? formatMessage({
                  id: "statusIndicator.active",
                  defaultMessage: "Active",
                  description: "status indicator active",
                })
              : formatMessage({
                  id: "statusIndicator.inactive",
                  defaultMessage: "Inactive",
                  description: "status indicator inactive",
                })}
          </Typography>
        </>
      )}
    </>
  );
}
