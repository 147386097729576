import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Grid,
  MenuItem,
  Typography,
  Select as SelectUI,
} from "@material-ui/core";
import { useStyles } from "./Portfolio.styles";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import GQLService from "../../core/services/GQL.service";
import { useLazyQuery, useQuery } from "@apollo/client";
import { PortfolioList } from "./PortfolioList";
import moment from "moment";
import {
  PortFolio,
  PortFolioAirQuality,
  PortFolioOccupancy,
} from "../../types";
import Loader from "react-loader-spinner";
import { COLORS } from "../../common/styles/colors";
import { useUserState } from "../../core/context/containers/User.container";
import {
  DummyPropertyA,
  DummyPropertyB,
  DummyPropertyC,
  DummyPropertyD,
  dummyUserEmail,
} from "../../common/constants/DummyProperty";

export function Portfolio() {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const [selectCommonSpace, setSelectCommonSpace] = useState("");
  const [spaceData, setSpaceData] = useState([]);
  const [selectedTime, setSelectedTime] = useState(15);
  const [portfolioData, setPortfolioData] = useState<PortFolio[]>([]);
  const [occupancyData, setOccupancyData] = useState<PortFolioOccupancy[]>([]);
  const [airQualityData, setAirQualityData] = useState<PortFolioAirQuality[]>(
    []
  );

  const timeInMinutesFilter = [
    { value: 15, label: "Last 15 minutes" },
    { value: 60, label: "Last hour" },
    { value: 24 * 60, label: "Last 24 hours" },
    { value: 7 * 24 * 60, label: "Last 7 days" },
    { value: 30 * 24 * 60, label: "Last 30 days" },
  ];

  const { data: commonSpacesData, loading: commonSpacesLoading } = useQuery(
    GQLService.queries.commonSpaces,
    {
      fetchPolicy: "network-only",
    }
  );

  const [
    getPortFolioOccupancyByCommonSpaceId,
    {
      loading: loadingOccupancy,
      error: errorOccupancy,
      data: dataPortfolioOccupancy,
    },
  ] = useLazyQuery(GQLService.queries.portFolioOccupancyByCommonSpaceId, {
    fetchPolicy: "network-only",
  });

  const [
    getPortFolioAirQualityByCommonSpaceId,
    {
      loading: loadingAirQuality,
      error: errorAirQuality,
      data: dataPortfolioAirQuality,
    },
  ] = useLazyQuery(GQLService.queries.portFolioAirQualityByCommonSpaceId, {
    fetchPolicy: "network-only",
  });

  const getTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === 10080 || selectedTime === 43200) {
      newTimeTo = moment
        .utc()
        .set({ minutes: 0, seconds: 0 })
        .startOf("day")
        .format();
      newTimeFrom = moment
        .utc()
        .set({ minutes: 0, seconds: 0 })
        .subtract(selectedTime, "minutes")
        .format();
    } else {
      newTimeTo = moment
        .utc()
        .set(
          selectedTime === 1440 ? { minutes: 0, seconds: 0 } : { seconds: 0 }
        )
        .startOf(selectedTime === 1440 ? "hour" : "minute")
        .format();
      newTimeFrom = moment
        .utc()
        .set(
          selectedTime === 1440 ? { minutes: 0, seconds: 0 } : { seconds: 0 }
        )
        .subtract(selectedTime, "minutes")
        .format();
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedTime]);

  const timeRange = useMemo(() => {
    if (selectedTime === 15) return "MINUTE";
    if (selectedTime === 60) return "QUARTER";
    if (selectedTime === 1440) return "HOUR";
    if (selectedTime === 10080) return "DAY";
    if (selectedTime === 43200) return "DAY";
  }, [selectedTime]);

  const requestObjectForDevices = useMemo(() => {
    if (selectCommonSpace) {
      return {
        request: {
          commonSpaceId: selectCommonSpace,
          from: getTimeParams().newTimeFrom,
          to: getTimeParams().newTimeTo,
          timeRangeType: timeRange,
        },
      };
    }
  }, [selectCommonSpace, getTimeParams, timeRange]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getPortFolioOccupancyByCommonSpaceId({
        variables: requestObjectForDevices,
      });
      getPortFolioAirQualityByCommonSpaceId({
        variables: requestObjectForDevices,
      });
    }
    return () => {
      isMounted = false;
    };
  }, [
    getPortFolioAirQualityByCommonSpaceId,
    getPortFolioOccupancyByCommonSpaceId,
    requestObjectForDevices,
    selectCommonSpace,
  ]);

  useEffect(() => {
    if (commonSpacesData) {
      const sortedList = commonSpacesData?.allCommonSpace?.sort(
        (a: any, b: any) => a.name.localeCompare(b.name)
      );
      setSpaceData(sortedList);
      const findSpace = commonSpacesData?.allCommonSpace?.find(
        (v: any) => v.name === "Lobby"
      );
      setSelectCommonSpace(findSpace.id);
    }
  }, [commonSpacesData]);

  useEffect(() => {
    if (dataPortfolioOccupancy) {
      setOccupancyData(dataPortfolioOccupancy?.occupancyByCommonSpaceId);
    }
  }, [dataPortfolioOccupancy]);

  useEffect(() => {
    if (dataPortfolioAirQuality) {
      setAirQualityData(dataPortfolioAirQuality?.airQualityByCommonSpaceId);
    }
  }, [dataPortfolioAirQuality]);

  useEffect(() => {
    if (errorOccupancy || errorAirQuality) {
      setPortfolioData([]);
    } else {
      if (occupancyData.length > 0 && airQualityData.length > 0) {
        let newData: PortFolio[] = [];
        for (let i = 0; i < occupancyData.length; i++) {
          for (let j = 0; j < airQualityData.length; j++) {
            if (
              occupancyData[i].property.id === airQualityData[j].property.id
            ) {
              newData.push({
                apCount: occupancyData[i].apCount,
                apDensity: occupancyData[i].apDensity,
                spaceLoad: occupancyData[i].spaceLoad,
                aqCount: airQualityData[j].aqCount,
                aqDensity: airQualityData[j].aqDensity,
                spaceAirQuality: airQualityData[j].spaceAirQuality,
                property: airQualityData[j].property,
              });
            }
          }
        }
        if (userEmail === dummyUserEmail) {
          const demoUserPortfolio = newData.filter(
            (item: any) =>
              item.property.id === DummyPropertyA.id ||
              item.property.id === DummyPropertyB.id ||
              item.property.id === DummyPropertyC.id ||
              item.property.id === DummyPropertyD.id
          );
          setPortfolioData(demoUserPortfolio);
        } else {
          setPortfolioData(newData);
        }
      }
    }
  }, [
    occupancyData,
    airQualityData,
    errorOccupancy,
    errorAirQuality,
    userEmail,
  ]);

  const handleSelectOptClick = useCallback(
    (e) => {
      setSelectCommonSpace(e.target.value);
    },
    [selectCommonSpace] // eslint-disable-line
  );

  const handleTimeChange = useCallback((e) => {
    setSelectedTime(e.target.value);
  }, []);

  return (
    <Grid container={true} direction="column">
      <Grid style={{ display: "flex", marginBottom: "26px" }}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography className={classes.spaceText}>Space Type</Typography>
          <SelectUI
            classes={{
              root: classes.select,
              select: classes.blackText,
              icon: classes.icon,
              disabled: classes.disabled,
            }}
            MenuProps={{
              classes: {
                paper: classes.menu,
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            IconComponent={ExpandMoreSharpIcon}
            value={selectCommonSpace}
            disableUnderline={true}
            onChange={handleSelectOptClick}
            name="Common Spaces"
          >
            {spaceData ? (
              spaceData?.map((data: any, i: any) => (
                <MenuItem
                  key={i + 1}
                  value={data.id}
                  disabled={false}
                  classes={{
                    root: classes.option,
                    selected: classes.selected,
                  }}
                >
                  {data.name || data.id}
                </MenuItem>
              ))
            ) : (
              <MenuItem></MenuItem>
            )}
          </SelectUI>
        </Grid>
        <Grid
          item={true}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            className={classes.spaceText}
            style={{ marginLeft: "40px" }}
          >
            Duration
          </Typography>
          <SelectUI
            classes={{
              root: classes.select,
              select: classes.blackText,
              icon: classes.icon,
              disabled: classes.disabled,
            }}
            MenuProps={{
              classes: {
                paper: classes.menu,
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            IconComponent={ExpandMoreSharpIcon}
            value={selectedTime}
            disableUnderline={true}
            onChange={handleTimeChange}
            name="Duration"
          >
            {timeInMinutesFilter?.map((data: any, i: any) => (
              <MenuItem
                key={i + 1}
                value={data.value}
                disabled={false}
                classes={{
                  root: classes.option,
                  selected: classes.selected,
                }}
              >
                {data.label}
              </MenuItem>
            ))}
          </SelectUI>
        </Grid>
      </Grid>
      {commonSpacesLoading || loadingOccupancy || loadingAirQuality ? (
        <Grid className={classes.loaderGrid}>
          <Loader type="Oval" color={COLORS.alto} height={150} width={150} />
        </Grid>
      ) : portfolioData?.length > 0 ? (
        portfolioData?.map((item) => <PortfolioList data={item} />)
      ) : (
        <></>
      )}
    </Grid>
  );
}
