import {
  Button,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
  Input,
  InputAdornment,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useCallback, useEffect, useState } from "react";
import {
  NotificationResourceType,
  conditionRange,
  NotificationConditionType,
  NotificationDataType,
} from "../../../../types";
import { useStyles } from "../style/Notifications.styles";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useFloorState } from "../../../../core/context/containers/Floor.container";
import IconButton from "@material-ui/core/IconButton";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ListNotifications from "./ListNotifications";
import { ROUTES } from "../../../../common/constants/Routing";
import { scrollsTop } from "../../../../common/utils/scrollbar.utils";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { PageHeader } from "../../../../common/components";
import * as _ from "lodash";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const FIELD_NAMES = {
  active: "active",
  floorId: "floorId",
  propertyId: "propertyId",
  deviceId: "deviceId",
  conditionType: "conditionType",
  resourceType: "resourceType",
  dataType: "dataType",
  exceedsInMinutes: "exceedsInMinutes",
};

export default function Notifications() {
  const { selectedProperty } = usePropertiesState();
  const { floors, setFloors } = useFloorState();
  const [notificationList, setNotificationList] = useState([]);
  const [ruleComponent, setRuleComponent] = useState(false);
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [createNotification] = useMutation(
    GQLService.mutations.createNotificationRule
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const classes = useStyles();
  const [zonesData, setZonesData] = useState([]);
  const [fieldValue, setFieldValue] = useState(3);

  const defaultValues = {
    active: "",
    floorId: "",
    propertyId: selectedProperty?.id,
    deviceId: "",
    conditionType: "",
    resourceType: NotificationResourceType.SPACE,
    dataType: "",
    exceedsInMinutes: 3,
  };
  const temperatureUnit = () => {
    const findUnit = selectedProperty?.sensorConfigurations?.filter(
      (el) => el.attribute === "TEMPERATURE"
    );
    return findUnit[0].isCelsius;
  };

  const dataTypeForNotifications = [
    { value: NotificationDataType.OCCUPANCY, label: "Occupancy" },
    { value: NotificationDataType.DWELL_TIME, label: "Dwell Time" },
    { value: NotificationDataType.SCORE, label: "Wellness Index" },
    {
      value: NotificationDataType.TEMPERATURE,
      label: `Temperature in ${temperatureUnit() ? "°C" : "°F"}`,
    },
    { value: NotificationDataType.HUMIDITY, label: "Humidity in %" },
    { value: NotificationDataType.CO2, label: "CO2 in ppm" },
    { value: NotificationDataType.TVOCS, label: "VOC in ppb" },
    { value: NotificationDataType.PM, label: "PM in ugm3" },
    { value: NotificationDataType.LIGHT, label: "Light in lux" },
    { value: NotificationDataType.NOISE, label: "Noise in db" },
  ];

  const [allRules, setAllRules] = useState(defaultValues);

  const { loading, data } = useQuery(GQLService.queries.allFloors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const [getAllNotificationRules, { data: notifications }] = useLazyQuery(
    GQLService.queries.allNotificationRules,
    {
      variables: { propertyId: selectedProperty?.id },
      fetchPolicy: "network-only",
    }
  );

  const [
    getSpacesByFloorId,
    { data: spacesResponse, loading: loadingSpaces, error: spacesError },
  ] = useLazyQuery(GQLService.queries.spaces, {
    fetchPolicy: "cache-and-network",
  });

  const [
    getCamerasByFloorId,
    { data: camResponse, loading: camLoading, error: camError },
  ] = useLazyQuery(GQLService.queries.camerasByFloorId, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (camResponse) {
      var cloneZonesResponse = _.cloneDeep(camResponse.cameras);
      var filteredZoneData = _.map(cloneZonesResponse, "zones");
      var flattenZoneArray = _.flatten(filteredZoneData);
      setZonesData(flattenZoneArray);
    }
  }, [camResponse]);

  const changeHandler = (e) => {
    if (e.target.name == "floorId") {
      getCamerasByFloorId({
        variables: {
          floorId: e.target.value,
        },
      });
      getSpacesByFloorId({
        variables: {
          floorId: e.target.value,
        },
      });
      setAllRules({
        ...allRules,
        deviceId: "",
        [e.target.name]: e.target.value,
      });
    } else {
      setAllRules({ ...allRules, [e.target.name]: e.target.value });
    }
    setIsDisabled(false);
  };

  const handlesetRuleButtonClick = () => {
    setIsDisabled(false);
    setRuleComponent(true);
    setAllRules(defaultValues);
    setFieldValue(3);
  };

  const handleRemoveRuleComponent = () => {
    setRuleComponent(false);
    setAllRules(defaultValues);
  };

  const handleSubmit = () => {
    setIsDisabled(true);
    if (
      (allRules.dataType === "OCCUPANCY" &&
        (allRules.deviceId === "" || allRules.deviceId !== "")) ||
      (allRules.dataType !== "" &&
        allRules.active !== "" &&
        allRules.deviceId !== "" &&
        allRules.floorId !== "" &&
        (allRules.conditionType !== "" || fieldValue >= 3))
    ) {
      let requestObj;
      if (allRules.dataType === "DWELL_TIME") {
        requestObj = {
          active: allRules.active,
          floorId: allRules.floorId,
          propertyId: selectedProperty?.id,
          deviceId: allRules.deviceId,
          conditionType: NotificationConditionType.EXCEEDS,
          resourceType: NotificationResourceType.ZONE,
          dataType: allRules.dataType,
          exceedsInMinutes: fieldValue,
        };
      } else if (allRules.dataType === "OCCUPANCY") {
        delete allRules.exceedsInMinutes;
        requestObj = {
          ...allRules,
          resourceType: allRules.deviceId
            ? NotificationResourceType.SPACE
            : NotificationResourceType.FLOOR,
        };
      } else {
        delete allRules.exceedsInMinutes;
        requestObj = allRules;
      }
      createNotification({
        variables: requestObj,
      })
        .then(async () => {
          enqueueSnackbar(
            formatMessage({
              id: "notification.snackbar.success",
              defaultMessage: "Notification created successfully",
            }),
            {
              variant: "success",
            }
          );
          setRuleComponent(false);
          setAllRules(defaultValues);
          scrollsTop();
          getAllNotificationRules();
          history.push(
            ROUTES.NOTIFICATIONS.replace(":uid", selectedProperty?.id)
          );
        })
        .catch((e) => {
          let errorMsg = e.message;
          if (
            e.message ===
            "500\norg.hibernate.exception.ConstraintViolationException: could not execute statement"
          ) {
            errorMsg = "Select required fields";
          }
          enqueueSnackbar(errorMsg, {
            variant: "error",
            persist: true,
          });
        });
    } else {
      enqueueSnackbar("Select required fields", {
        variant: "error",
        persist: true,
      });
    }
    setTimeout(() => closeSnackbar(), 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setFloors(data?.floorsByPropertyId || []);
    }
    return () => {
      isMounted = false;
    };
  }, [data]);

  useEffect(() => {
    getAllNotificationRules();
  }, []);

  useEffect(() => {
    if (notifications?.notificationRules) {
      setNotificationList(notifications?.notificationRules);
    }
  }, [getAllNotificationRules, notifications]);

  const handleChange = useCallback((e) => {
    var parseValue = parseInt(e.target.value);
    if (parseValue >= 1) {
      setFieldValue(parseValue);
      setIsDisabled(false);
    } else {
      setFieldValue(null);
      setIsDisabled(true);
    }
  }, []);

  const timeIncrement = () => {
    if (fieldValue >= 1) {
      let counter = fieldValue + 1;
      setFieldValue(counter);
    }
  };

  const timeDecrement = () => {
    if (fieldValue > 3) {
      let counter = fieldValue - 1;
      setFieldValue(counter);
    }
  };

  return (
    <>
      <PageHeader
        title={formatMessage({
          id: "notifications.header",
          defaultMessage: "Notifications Settings",
          description: "notifications settings header",
        })}
      />
      <Grid
        container
        style={{
          display: "flex",
          alignItems: "center",
          paddingTop: "8px",
          paddingBottom: "16px",
        }}
      >
        <Grid item>
          <Button
            className={classes.addButton}
            color="primary"
            aria-label="add-notification"
            component="span"
            onClick={handlesetRuleButtonClick}
          >
            <AddIcon style={{ color: "black" }} />
          </Button>
        </Grid>
        <Grid item>
          <Typography className={classes.headerTextForSetRule}>
            Set a rule for raising a Notification
          </Typography>
        </Grid>
      </Grid>
      {ruleComponent && (
        <Formik
          onSubmit={handleSubmit}
          validationSchema={createNotificationSchema}
          initialValues={defaultValues}
        >
          {({ values, setFieldValue, submitCount, errors, initialValues }) => (
            <FormikForm className={classes.form}>
              <Grid
                container
                style={{ paddingTop: "16px", paddingBottom: "16px" }}
                justify="space-between"
                md={12}
              >
                <Grid item md={2} sm={2}>
                  <InputLabel className={classes.dropDownTitle}>
                    Select Data Type
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.dataType] ==
                        initialValues[FIELD_NAMES.dataType]) && (
                      <span className={classes.errorLabel}>{"*"}</span>
                    )}
                  </InputLabel>
                  <Select
                    key={FIELD_NAMES.dataType}
                    classes={{
                      root: classes.select,
                      select: classes.blackText,
                      icon: classes.icon,
                    }}
                    id="menu-AQFilter"
                    name="dataType"
                    value={allRules.dataType}
                    disableUnderline={true}
                    onChange={changeHandler}
                    IconComponent={ExpandMoreSharpIcon}
                    MenuProps={{
                      classes: {
                        paper: classes.menu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      disablePortal: true,
                    }}
                  >
                    {dataTypeForNotifications?.map((v) => (
                      <MenuItem
                        key={v.value}
                        value={v.value}
                        classes={{
                          root: classes.option,
                          selected: classes.selected,
                        }}
                      >
                        {v.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sm={2} md={2}>
                  <InputLabel className={classes.dropDownTitle}>
                    Select a Floor
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.floorId] ==
                        initialValues[FIELD_NAMES.floorId]) && (
                      <span className={classes.errorLabel}>{"*"}</span>
                    )}
                  </InputLabel>
                  <Select
                    key={FIELD_NAMES.floorId}
                    classes={{
                      root: classes.select,
                      select: classes.blackText,
                      icon: classes.icon,
                    }}
                    id="id-floorId"
                    name="floorId"
                    value={allRules.floorId ? allRules.floorId : ""}
                    disableUnderline={true}
                    onChange={changeHandler}
                    IconComponent={ExpandMoreSharpIcon}
                    MenuProps={{
                      classes: {
                        paper: classes.menu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      disablePortal: true,
                    }}
                  >
                    {floors?.map(
                      (v) =>
                        v.active && (
                          <MenuItem
                            key={v.id}
                            value={v.id}
                            classes={{
                              root: classes.option,
                              selected: classes.selected,
                            }}
                          >
                            {v.name ? v.name : `Floor No. ${v.floorNumber}`}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </Grid>
                <Grid item sm={2} md={2}>
                  <InputLabel className={classes.dropDownTitle}>
                    Select a Resource
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.deviceId] ==
                        initialValues[FIELD_NAMES.deviceId]) &&
                      (allRules.dataType === "OCCUPANCY" ? (
                        <></>
                      ) : (
                        <span className={classes.errorLabel}>{"*"}</span>
                      ))}
                  </InputLabel>
                  <Select
                    key={FIELD_NAMES.deviceId}
                    classes={{
                      root: classes.select,
                      select: classes.blackText,
                      icon: classes.icon,
                    }}
                    id="id-deviceId"
                    name="deviceId"
                    value={allRules.deviceId ? allRules.deviceId : ""}
                    disableUnderline={true}
                    onChange={changeHandler}
                    IconComponent={ExpandMoreSharpIcon}
                    MenuProps={{
                      classes: {
                        paper: classes.menu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      disablePortal: true,
                    }}
                  >
                    {allRules.dataType === "DWELL_TIME" ? (
                      <ListSubheader style={{ position: "relative" }}>
                        Zones
                      </ListSubheader>
                    ) : (
                      <ListSubheader style={{ position: "relative" }}>
                        Spaces
                      </ListSubheader>
                    )}
                    {allRules.dataType === "DWELL_TIME"
                      ? zonesData.length > 0
                        ? zonesData.map(
                            (v) =>
                              v.active && (
                                <MenuItem
                                  key={v.id}
                                  value={v.id}
                                  classes={{
                                    root: classes.option,
                                    selected: classes.selected,
                                  }}
                                >
                                  {v.name ? v.name : v.zone}
                                </MenuItem>
                              )
                          )
                        : null
                      : spacesResponse?.spaces.map(
                          (v) =>
                            v.active && (
                              <MenuItem
                                key={v.id}
                                value={v.id}
                                classes={{
                                  root: classes.option,
                                  selected: classes.selected,
                                }}
                              >
                                {v.name}
                              </MenuItem>
                            )
                        )}
                  </Select>
                </Grid>
                {allRules.dataType === "DWELL_TIME" ? (
                  <Grid item sm={2} md={2}>
                    <InputLabel className={classes.dropDownTitle}>
                      If exceeds
                      {(submitCount > 0 ||
                        values[FIELD_NAMES.conditionType] ==
                          initialValues[FIELD_NAMES.conditionType]) && (
                        <span className={classes.errorLabel}>{"*"}</span>
                      )}
                    </InputLabel>
                    <Grid style={{ display: "flex" }}>
                      <Grid>
                        <Input
                          classes={{
                            root:
                              fieldValue < 3
                                ? classes.errorInputTimer
                                : classes.input,
                            input: classes.inputField,
                          }}
                          value={fieldValue}
                          id="id-exceedsInMinutes"
                          name="exceedsInMinutes"
                          onChange={handleChange}
                          type="number"
                          disableUnderline={true}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                            >
                              minutes
                            </InputAdornment>
                          }
                        />
                        {fieldValue < 3 ? (
                          <Typography className={classes.errorMSg}>
                            {"value should be >= 3"}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid className={classes.incDecButton}>
                        <ExpandLess
                          className={classes.incDecIcon}
                          onClick={timeIncrement}
                        />
                        <ExpandMore
                          className={classes.incDecIcon}
                          onClick={timeDecrement}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item sm={2} md={2}>
                    <InputLabel className={classes.dropDownTitle}>
                      Set a condition
                      {(submitCount > 0 ||
                        values[FIELD_NAMES.conditionType] ==
                          initialValues[FIELD_NAMES.conditionType]) && (
                        <span className={classes.errorLabel}>{"*"}</span>
                      )}
                    </InputLabel>
                    <Select
                      key={FIELD_NAMES.conditionType}
                      classes={{
                        root: classes.select,
                        select: classes.blackText,
                        icon: classes.icon,
                      }}
                      id="id-conditionType"
                      name="conditionType"
                      value={
                        allRules.conditionType ? allRules.conditionType : ""
                      }
                      disableUnderline={true}
                      onChange={changeHandler}
                      IconComponent={ExpandMoreSharpIcon}
                      MenuProps={{
                        classes: {
                          paper: classes.menu,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        disablePortal: true,
                      }}
                    >
                      {conditionRange?.map((v) => (
                        <MenuItem
                          key={v.id}
                          value={v.value}
                          classes={{
                            root: classes.option,
                            selected: classes.selected,
                          }}
                        >
                          {v.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
                <Grid item sm={2} md={2}>
                  <InputLabel className={classes.dropDownTitle}>
                    Notification
                    {(submitCount > 0 ||
                      values[FIELD_NAMES.active] ==
                        initialValues[FIELD_NAMES.active]) && (
                      <span className={classes.errorLabel}>{"*"}</span>
                    )}
                  </InputLabel>
                  <Select
                    key={FIELD_NAMES.active}
                    classes={{
                      root: classes.select,
                      select: classes.blackText,
                      icon: classes.icon,
                    }}
                    id="id-active"
                    name="active"
                    value={
                      allRules.active || allRules.active == false
                        ? allRules.active
                        : ""
                    }
                    disableUnderline={true}
                    onChange={changeHandler}
                    IconComponent={ExpandMoreSharpIcon}
                    MenuProps={{
                      classes: {
                        paper: classes.menu,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      disablePortal: true,
                    }}
                  >
                    <MenuItem
                      key="on"
                      value={true}
                      classes={{
                        root: classes.option,
                        selected: classes.selected,
                      }}
                    >
                      ON
                    </MenuItem>
                    <MenuItem
                      key="off"
                      value={false}
                      classes={{
                        root: classes.option,
                        selected: classes.selected,
                      }}
                    >
                      OFF
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  sm={1}
                  md={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <IconButton
                    disabled={isDisabled}
                    type="submit"
                    style={{ padding: "0 5px", color: "black" }}
                  >
                    <SaveOutlinedIcon variant="outlined" />
                  </IconButton>
                  <IconButton
                    onClick={handleRemoveRuleComponent}
                    style={{ padding: "0 5px", color: "black" }}
                  >
                    <CancelOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </FormikForm>
          )}
        </Formik>
      )}
      <ListNotifications listItems={notificationList} />
    </>
  );
}

export const createNotificationSchema = Yup.object().shape({
  [FIELD_NAMES.dataType]: Yup.string().required(""),
  [FIELD_NAMES.floorId]: Yup.string().required(""),
  [FIELD_NAMES.deviceId]: Yup.string().when("dataType", {
    is: "Occupancy",
    then: Yup.string().nullable(true),
    otherwise: Yup.string().nullable(false).required(""),
  }),
  [FIELD_NAMES.conditionType]: Yup.string().required(""),
  [FIELD_NAMES.exceedsInMinutes]: Yup.number().required().min(3),
  [FIELD_NAMES.active]: Yup.string().required(""),
});
