import React, { useState, useCallback } from "react";
import { Menu, MenuItem, MenuList, makeStyles } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useAuth } from "../../../core/context/containers/Auth.container";
import GQLService from "../../../core/services/GQL.service";
import { Role } from "../../../types";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 30,
    minHeight: 30,
    maxWidth: 30,
    maxHeight: 30,
    color: theme.palette.common.funBlue,

    "&:hover": {
      borderRadius: 15,
      backgroundColor: theme.palette.common.nobel,
    },
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    borderBottom: `1px solid ${theme.palette.common.nobel}`,

    "&:nth-child(3)": {
      border: "none",
    },
  },
  lastItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    "&:nth-child(3)": {
      border: "none",
    },
  },
}));

interface PropertyMenuProps {
  group: any;
  showModal: () => void;
}

export function NetworkMenu({ group, showModal }: PropertyMenuProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState();

  const auth = useAuth();

  const [removeNetworkGroup] = useMutation(
    GQLService.mutations.deleteNetworkGroup,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allNetworkGroups,
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const history = useHistory();

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const handleEditNetworkClick = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      showModal();
      setAnchorEl(undefined);
    },
    [anchorEl]
  );

  const handleRemoveNetworkClick = useCallback(
    async (ev: any) => {
      ev.preventDefault();
      ev.stopPropagation();
      await removeNetworkGroup({ variables: { id: group.id } });
    },
    [group] //eslint-disable-line
  );

  const renderEditMenuItem = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={handleEditNetworkClick}>
        {formatMessage({
          id: "VLANs.contextMenu.editVLAN",
          defaultMessage: "Edit Name",
          description: "context menu edit name of network group",
        })}
      </MenuItem>
    );
  }, []);

  const renderRemoveMenuItem = useCallback(() => {
    return (
      <MenuItem className={classes.lastItem} onClick={handleRemoveNetworkClick}>
        {formatMessage({
          id: "VLANs.contextMenu.removeVLAN",
          defaultMessage: "Remove",
          description: "context menu remove network group",
        })}
      </MenuItem>
    );
  }, []);

  const open = Boolean(anchorEl);

  return (
    <>
      <MoreHoriz className={classes.icon} onClick={handleToggleMenu} />
      <Menu
        id="properties-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "network:list",
            renderEditMenuItem()
          )}
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "network:list",
            renderRemoveMenuItem()
          )}
        </MenuList>
      </Menu>
    </>
  );
}
