import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    outline: "none",
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
    height: 31,
    borderRadius: 6,
    textDecoration: "none",
    position: "relative",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
  },
  chipIcon: {
    color: "white",

    "&:hover": {
      color: "white",
    },
  },
  inputLabel: {
    margin: "20px 0 5px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.dustyGray,
  },
  chipWrapper: {
    position: "relative",
    right: "4px",
  },
}));
