import React from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Grid, CardMedia, Typography, Button } from "@material-ui/core";

import loginImg from "../../../common/assets/images/login.jpg";
import { useAuth } from "../../../core/context/functions/Auth.functions";
import { AppLogo } from "../../../common/components";
import { PasswordRecoveryForm } from "./components/RecoveryForm/RecoveryForm";
import { ROUTES } from "../../../common/constants/Routing";
import { useStyles } from "./PasswordRecovery.styles";
import GQLService from "../../../core/services/GQL.service";

export function PasswordRecovery() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleSubmit = async (email: string) => {
    await GQLService.rest.auth.sendRecoveryEmail(email);
    history.push(ROUTES.PASSWORD_RECOVERY_SUCCESS);
  };

  const handleBackButtonPress = () => {
    history.push(ROUTES.SIGN_IN);
  };

  return (
    <Grid container={true} alignItems="center">
      <Grid className={classes.container} item={true} md={3} sm={12}>
        <AppLogo style={classes.logo} />
        <Typography className={classes.recoveryText} variant="inherit">
          {formatMessage({
            id: "passwordRecovery.header",
            defaultMessage: "Forgot your password?",
            description: "password recovery header",
          })}
        </Typography>
        <Typography className={classes.catchline}>
          {formatMessage({
            id: "passwordRecovery.catchline",
            defaultMessage:
              "Enter the email address you use for your Join account.",
            description: "password recovery instruction",
          })}
        </Typography>
        <PasswordRecoveryForm onSubmit={handleSubmit} />
        <Button
          className={classes.backButton}
          fullWidth={true}
          variant="text"
          onClick={handleBackButtonPress}
          id="backToSigninBtn"
        >
          {formatMessage({
            id: "passwordRecovery.button.back",
            defaultMessage: "Back to Signin Page",
            description: "password recovery back button",
          })}
        </Button>
      </Grid>
      <Grid item={true} md={9} sm={12}>
        <CardMedia className={classes.img} image={loginImg} component="img" />
      </Grid>
    </Grid>
  );
}
