import React, { useCallback, useMemo, useState } from "react";
import { CircularProgress, Grid, Typography, Box } from "@material-ui/core";
import { VictoryPie, VictoryLabel } from "victory";
import { useStyles } from "./VictoryPieChart.styles";
import { ApolloError } from "@apollo/client";
import { useIntl } from "react-intl";
import { ModalContent } from "./ModalContent";
import { useModal } from "../../../../../core/hooks/Modal";

type PieChartData = {
  x: string;
  y: number;
};

interface VictoryPieChartProps {
  title: string;
  data?: PieChartData[];
  colors: string[];
  loading?: boolean;
  error?: ApolloError;
}

export const VictoryPieChart = ({
  title,
  data,
  colors,
  loading,
  error,
}: VictoryPieChartProps) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { show, hide, RenderModal } = useModal();

  const Loading = () => (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="indeterminate" size={136} color="inherit" />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {formatMessage({
            id: "Charts.Piechart.Loading",
            defaultMessage: "LOADING...",
            description: "Pie Chart Loading message",
          })}
        </Typography>
      </Box>
    </Box>
  );

  const NoData = () => (
    <svg width={136} height={136}>
      <VictoryPie
        standalone={false}
        width={136}
        height={136}
        labelComponent={<span />}
        innerRadius={59}
        data={[{ x: "", y: 100 }]}
        colorScale={["#D2D2D2"]}
        padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{
          fontSize: 14,
          fontWeight: 500,
          fontFamily: "Poppins",
          color: "0E1D33",
        }}
        x={68}
        y={65}
        text={formatMessage({
          id: "Charts.Piechart.NoData",
          defaultMessage: "No Data",
          description: "Pie Chart No Data message",
        })}
      />
    </svg>
  );

  const Data = () => (
    <svg width={136} height={136}>
      <VictoryPie
        standalone={false}
        width={136}
        height={136}
        labelComponent={<span />}
        innerRadius={data ? 21 : 57}
        padAngle={2}
        data={data?.slice(0, 5)}
        colorScale={colors}
        padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
      />
    </svg>
  );

  const otherValue = useMemo(() => {
    let sumOther = 0;
    data?.slice(5).map((v) => (sumOther += v.y * 100));
    return sumOther >= 1
      ? sumOther.toFixed()
      : sumOther === 0
      ? sumOther.toFixed()
      : "<1";
  }, [data]);

  const renderLabels = useCallback(() => {
    if (data?.length === 0) return;
    return (
      <>
        {data!.length <= 5
          ? data?.map((item, index) => (
              <div className={classes.labelContainer} key={index}>
                <div
                  style={{ backgroundColor: `${colors[index]}` || "#D2D2D2" }}
                  className={classes.marker}
                />
                <span className={classes.label}>{data && item?.x}</span>
                {data && (
                  <span className={classes.percent}>
                    {data[index]?.y * 100 >= 1
                      ? (data[index]?.y * 100).toFixed()
                      : data[index]?.y * 100 === 0
                      ? (data[index]?.y * 100).toFixed()
                      : "<1"}
                    %
                  </span>
                )}
              </div>
            ))
          : data!?.slice(0, 4).map((item, index) => (
              <div className={classes.labelContainer} key={index}>
                <div
                  style={{ backgroundColor: `${colors[index]}` || "#D2D2D2" }}
                  className={classes.marker}
                />
                <span className={classes.label}>{data && item?.x}</span>
                {data && (
                  <span className={classes.percent}>
                    {data[index]?.y * 100 >= 1
                      ? (data[index]?.y * 100).toFixed()
                      : data[index]?.y * 100 === 0
                      ? (data[index]?.y * 100).toFixed()
                      : "<1"}
                    %
                  </span>
                )}
              </div>
            ))}
        {data!.length > 5 ? (
          <div className={classes.labelContainer} key={5}>
            <div
              style={{ backgroundColor: `${colors[4]}` || "#D2D2D2" }}
              className={classes.marker}
            />
            <span className={classes.label}>Other</span>
            {data && <span className={classes.percent}>{otherValue}%</span>}
          </div>
        ) : (
          <></>
        )}
        <div className={classes.labelButton}>
          <span onClick={show} className={classes.button}>
            See All
          </span>
        </div>
      </>
    );
  }, [data]);

  return (
    <Grid
      container={true}
      md={12}
      sm={12}
      direction="row"
      alignItems="flex-start"
    >
      <Grid
        container
        item={true}
        md={12}
        alignItems="center"
        direction="row"
        justify="space-between"
      >
        <Grid item style={{ color: "#D2D2D2" }} md={6}>
          {loading ? (
            <Loading />
          ) : error || data?.length === 0 ? (
            <NoData />
          ) : (
            <Data />
          )}
        </Grid>
        <Grid item className={classes.labelsContainer} md={5}>
          {!loading && renderLabels()}
        </Grid>
      </Grid>
      <RenderModal open={show}>
        <ModalContent close={hide} data={data} colors={colors} title={title} />
      </RenderModal>
    </Grid>
  );
};
