import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  img: {
    height: "100%",
  },
  noImg: {
    height: 50,
  },
  logo: {
    width: 150,
    height: 70,
    border: `1px solid ${theme.palette.common.nobel}`,
    borderRadius: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  coverPhoto: {
    width: 360,
    height: 198,
    border: `1px solid ${theme.palette.common.nobel}`,
    borderRadius: 6,
    marginBottom: theme.spacing(8),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  blackLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
    marginTop: theme.spacing(5),
  },
  button: {
    backgroundColor: theme.palette.common.matisse,
    textTransform: "none",
    color: theme.palette.common.white,
    borderRadius: 30,
    width: 115,
    height: 42,
  },
}));
