import {
  ActivitySwitch,
  Input,
  Select,
  SelectFromList,
  TextareaAutosize,
} from "../../../../common/components/Form/FormFields";
import * as Yup from "yup";

import { FormField } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";
import { AuthTypesBase } from "../../../../common/constants/AuthTypes";

export const FIELD_NAMES = {
  name: "name",
  uid: "uid",
  description: "description",
  authType: "authenticationType",
  active: "active",
  vlanID: "vlanId",
};
export interface addAccessPointType {
  name: string;
  active: boolean;
}

export const createFormFields = (data: any): FormField[] => [
  {
    name: FIELD_NAMES.name,
    label: I18n.formatMessage({
      id: "ssids.add.label.name",
      defaultMessage: "Name",
    }),
    placeholder: I18n.formatMessage({
      id: "ssids.add.placeholder.name",
      defaultMessage: "Type here",
    }),
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.description,
    label: I18n.formatMessage({
      id: "ssids.add.label.description",
      defaultMessage: "Description",
    }),
    placeholder: I18n.formatMessage({
      id: "ssids.add.placeholder.description",
      defaultMessage: "Type here",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: TextareaAutosize,
  },
  {
    name: FIELD_NAMES.authType,
    label: I18n.formatMessage({
      id: "ssids.add.label.authType",
      defaultMessage: "Authentication type",
    }),
    placeholder: I18n.formatMessage({
      id: "ssids.add.placeholder.authType",
      defaultMessage: "Type here",
    }),
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Select,
    data: AuthTypesBase.map((authType) => ({ id: authType, name: authType })),
  },
  {
    name: FIELD_NAMES.vlanID,
    label: I18n.formatMessage({
      id: "ssids.add.label.vlanID",
      defaultMessage: "VLAN",
    }),
    placeholder: I18n.formatMessage({
      id: "ssids.add.placeholder.vlanID",
      defaultMessage: "Type here",
    }),
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Select,
    data: data,
  },
  {
    name: FIELD_NAMES.active,
    label: I18n.formatMessage({
      id: "ssids.add.label.active",
      defaultMessage: "Active",
    }),
    placeholder: I18n.formatMessage({
      id: "ssids.add.placeholder.active",
      defaultMessage: "Type here",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: ActivitySwitch,
  },
];

export const SSIDValidationSchema = Yup.object().shape({
  [FIELD_NAMES.name]: Yup.string()
    .min(
      3,
      I18n.formatMessage({
        id: "ssids.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "ssids.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
});
