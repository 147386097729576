import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  nodata: {
    width: "100%",
    height: 600,
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  message: {
    color: theme.palette.common.dustyGray,
    fontSize: "16px",
    lineHeight: "24px",
  },
  icon: {
    width: 150,
    height: 150,
  },
  settingsLink: {
    color: theme.palette.common.funBlue,
    cursor: "pointer",
    fontWeight: "bold",
  },
}));
