import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./SummaryNew.styles";
import moment from "moment";
import { ReactComponent as Internet } from "../../../../../common/assets/images/internet.svg";
import { ReactComponent as FreshAir } from "../../../../../common/assets/images/fresh-air.svg";
import { ReactComponent as SecurityCamera } from "../../../../../common/assets/images/security-camera.svg";
import { ReactComponent as PinBlack } from "../../../../../common/assets/images/pin_black.svg";

import clsx from "clsx";
import { CustomModal } from "../../../../../common/components";
import { AnalyzeModal } from "./AnalyzeModal";
import { MyPinsModal } from "../../../MyPins/MyPinsModal";
import {
  DashboardCardAverageAndCount,
  DashboardCardAverageAndCountTripwire,
  DashboardCardAverageAndCountZone,
  DashboardDeviceType,
} from "../../../../../types";
import { useFloorState } from "../../../../../core/context/containers/Floor.container";
import { UnpinModal } from "../../../MyPins/UnpinModal";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";

const devicesColors = ["#2F56A1", "#81D3E0", "#08B055"];
const peopleColors = ["#2F56A1", "#ACACAC", "#0E1D33"];

interface DataUsageProps {
  devicesByFloor?: any;
  timeFrom?: string;
  timeTo?: string;
  selectedTime?: string;
  selectedSpace?: any;
  handleIsPinned?: (el: any) => void;
  dataDashboardCardAverageAndCount?: DashboardCardAverageAndCount;
  dataDashboardCardAverageAndCountTripwire?: DashboardCardAverageAndCountTripwire;
  dataDashboardCardAverageAndCountZone?: DashboardCardAverageAndCountZone;
}

export const SummaryNew = ({
  devicesByFloor,
  timeFrom,
  timeTo,
  selectedTime,
  selectedSpace,
  handleIsPinned,
  dataDashboardCardAverageAndCount,
  dataDashboardCardAverageAndCountTripwire,
  dataDashboardCardAverageAndCountZone,
}: DataUsageProps) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const rejectChanges = useCallback(() => setShowModal(false), []);
  const [requestData, setRequestData] = useState<any>();
  const [unPinRequestData, setUnPinRequestData] = useState<any>();
  const { floors, selectedFloorSummary } = useFloorState();
  const [toggleUnpinModal, setToggleUnpinModal] = useState<boolean>(false);
  const { selectedProperty } = usePropertiesState();

  const timeRange = useMemo(() => {
    if (selectedTime === "15m") return "MINUTE";
    if (selectedTime === "1h") return "QUARTER";
    if (selectedTime === "24h") return "HOUR";
    if (selectedTime === "7d") return "DAY";
    if (selectedTime === "30d") return "WEEK";
  }, [selectedTime]);

  const renderDwellTime = (dwellTimeParam: any) => {
    const dwellTime = ~~Math.round(dwellTimeParam);
    let newDwellTime;
    let dwellTimeText;

    if (dwellTime) {
      if (dwellTime > 3600) {
        newDwellTime = ~~Math.round(dwellTime / 60);
        dwellTimeText = "Hrs";
      } else if (dwellTime > 60) {
        newDwellTime = ~~Math.round(dwellTime / 60);
        dwellTimeText = "Min";
      } else {
        newDwellTime = dwellTime;
        dwellTimeText = "Sec";
      }
      return (
        <>
          <Typography className={classes.number} style={{ color: "#2F56A0" }}>
            {newDwellTime}
          </Typography>
          <Typography
            className={classes.blackTextBold}
            style={{ marginLeft: "8px", marginTop: "19px" }}
          >
            {dwellTimeText}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography className={classes.number} style={{ color: "#2F56A0" }}>
            {0}
          </Typography>
          <Typography
            className={classes.blackTextBold}
            style={{ marginLeft: "8px", marginTop: "19px" }}
          >
            Sec
          </Typography>
        </>
      );
    }
  };

  const handlePinned = (dashboardDeviceType: DashboardDeviceType) => {
    let newSelectedTime =
      selectedTime === "15m"
        ? 15
        : selectedTime === "1h"
        ? 60
        : selectedTime === "24h"
        ? 1440
        : selectedTime === "7d"
        ? 10080
        : 43200;
    setRequestData({
      selectedTime: newSelectedTime,
      selectedFloor: selectedFloorSummary?.id!,
      selectedSpace: selectedSpace,
      deviceType: dashboardDeviceType,
      deviceId: "",
    });
    setToggleModal(true);
  };

  const handleUnPinned = (dashboardDeviceType: DashboardDeviceType) => {
    setUnPinRequestData({
      selectedFloor: selectedFloorSummary?.id!,
      selectedSpace: selectedSpace,
      deviceType: dashboardDeviceType,
      deviceId: "",
    });
    setToggleUnpinModal(true);
  };

  return (
    <>
      <Grid
        container={true}
        md={12}
        alignItems="baseline"
        direction="row"
        style={{ width: "max-content" }}
      >
        <Grid
          container={true}
          md={2}
          direction="column"
          className={classes.box}
          style={{ marginRight: "16px" }}
        >
          <Grid className={classes.pinGrid}>
            {dataDashboardCardAverageAndCount?.isAPPinned! ? (
              <PinBlack
                fill="#3f51b5"
                className={classes.pinIcon}
                onClick={() =>
                  handleUnPinned(DashboardDeviceType.DASHBOARD_ACCESSPOINT)
                }
              />
            ) : (
              <PinBlack
                fill="#3f51b5"
                className={classes.unpinIcon}
                onClick={() =>
                  handlePinned(DashboardDeviceType.DASHBOARD_ACCESSPOINT)
                }
              />
            )}
          </Grid>
          <Grid direction="column" className={classes.boxContent}>
            <Grid item sm={12}>
              <Typography className={classes.blackTextBold}>
                Occupancy
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography
                className={classes.number}
                style={{ color: "#2F56A0" }}
              >
                {~~Math.round(dataDashboardCardAverageAndCount?.apDensity!)}
              </Typography>
            </Grid>
            <Typography className={classes.blackText}>Access Points</Typography>
            <Grid container={true} sm={12} direction="row">
              <Typography className={classes.textValue}>
                {~~Math.round(dataDashboardCardAverageAndCount?.apCount!)}
              </Typography>
              <Internet className={classes.marginRight10} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container={true}
          md={2}
          direction="column"
          className={classes.box}
          style={{ marginRight: "16px" }}
        >
          <Grid className={classes.pinGrid}>
            {devicesByFloor?.isAQPinned! ? (
              <PinBlack
                fill="#3f51b5"
                className={classes.pinIcon}
                onClick={() =>
                  handleUnPinned(DashboardDeviceType.DASHBOARD_AIRQUALITY)
                }
              />
            ) : (
              <PinBlack
                fill="#3f51b5"
                className={classes.unpinIcon}
                onClick={() =>
                  handlePinned(DashboardDeviceType.DASHBOARD_AIRQUALITY)
                }
              />
            )}
          </Grid>
          <Grid direction="column" className={classes.boxContent}>
            <Grid item sm={12}>
              <Typography className={classes.blackTextBold}>
                Avg Wellness Index
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography
                className={classes.number}
                style={{ color: "#2F56A0" }}
              >
                {~~Math.round(devicesByFloor?.aqDensity)}
              </Typography>
            </Grid>
            <Typography className={classes.blackText}>
              Air Quality Sensors
            </Typography>
            <Grid container={true} sm={12} direction="row">
              <Typography className={classes.textValue}>
                {~~Math.round(devicesByFloor?.aqCount)}
              </Typography>
              <FreshAir
                className={classes.marginRight10}
                width={36}
                height={36}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container={true}
          md={2}
          direction="column"
          className={classes.box}
          style={{ marginRight: "16px" }}
        >
          <Grid className={classes.pinGrid}>
            {dataDashboardCardAverageAndCountZone?.isZonePinned! ? (
              <PinBlack
                fill="#3f51b5"
                className={classes.pinIcon}
                onClick={() =>
                  handleUnPinned(DashboardDeviceType.DASHBOARD_ZONE)
                }
              />
            ) : (
              <PinBlack
                fill="#3f51b5"
                className={classes.unpinIcon}
                onClick={() => handlePinned(DashboardDeviceType.DASHBOARD_ZONE)}
              />
            )}
          </Grid>
          <Grid direction="column" className={classes.boxContent}>
            <Grid container={true} direction="row">
              <Grid>
                <Grid item>
                  <Typography className={classes.blackTextBold}>
                    Zones
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.number}
                    style={{ color: "#2F56A0" }}
                  >
                    {
                      ~~Math.round(
                        dataDashboardCardAverageAndCountZone?.zoneCount!
                      )
                    }
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <Grid item style={{ marginLeft: "16px" }}>
                  <Typography className={classes.blackTextBold}>
                    Dwell Time
                  </Typography>
                </Grid>
                <Grid item style={{ marginLeft: "16px", display: "flex" }}>
                  {renderDwellTime(
                    dataDashboardCardAverageAndCountZone?.zoneCensus!
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Typography className={classes.blackText}>Image Sensors</Typography>
            <Grid container={true} direction="row">
              <Typography className={classes.textValue}>
                {
                  ~~Math.round(
                    dataDashboardCardAverageAndCountZone?.cameraCount!
                  )
                }
              </Typography>
              <SecurityCamera
                className={classes.marginRight10}
                width={36}
                height={36}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container={true}
          md={5}
          direction="column"
          className={classes.box}
          style={{ marginRight: "16px" }}
        >
          <Grid className={classes.pinGrid}>
            {dataDashboardCardAverageAndCountTripwire?.isTripwirePinned! ? (
              <PinBlack
                fill="#3f51b5"
                className={classes.pinIcon}
                onClick={() =>
                  handleUnPinned(DashboardDeviceType.DASHBOARD_TRIPWIRE)
                }
              />
            ) : (
              <PinBlack
                fill="#3f51b5"
                className={classes.unpinIcon}
                onClick={() =>
                  handlePinned(DashboardDeviceType.DASHBOARD_TRIPWIRE)
                }
              />
            )}
          </Grid>
          <Grid direction="column" className={classes.boxContent}>
            <Grid container={true} direction="row">
              <Grid>
                <Grid item>
                  <Typography className={classes.blackTextBold}>
                    Tripwires
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.number}
                    style={{ color: "#2F56A0" }}
                  >
                    {
                      ~~Math.round(
                        dataDashboardCardAverageAndCountTripwire?.tripwireCount!
                      )
                    }
                  </Typography>
                </Grid>
              </Grid>
              {dataDashboardCardAverageAndCountTripwire?.isNetflowActive && (
                <Grid>
                  <Grid item style={{ marginLeft: "16px" }}>
                    <Typography className={classes.blackTextBold}>
                      Start Count
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "16px" }}>
                    <Typography
                      className={classes.number}
                      style={{ color: "#2F56A0" }}
                    >
                      {
                        ~~Math.round(
                          dataDashboardCardAverageAndCountTripwire?.startCount!
                        )
                      }
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid>
                <Grid item style={{ marginLeft: "16px" }}>
                  <Typography className={classes.blackTextBold}>In</Typography>
                </Grid>
                <Grid item style={{ marginLeft: "16px" }}>
                  <Typography
                    className={classes.number}
                    style={{ color: "#2F56A0" }}
                  >
                    {
                      ~~Math.round(
                        dataDashboardCardAverageAndCountTripwire?.tripwireIn!
                      )
                    }
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <Grid item style={{ marginLeft: "16px" }}>
                  <Typography className={classes.blackTextBold}>Out</Typography>
                </Grid>
                <Grid item style={{ marginLeft: "16px" }}>
                  <Typography
                    className={classes.number}
                    style={{ color: "#2F56A0" }}
                  >
                    {
                      ~~Math.round(
                        dataDashboardCardAverageAndCountTripwire?.tripwireOut!
                      )
                    }
                  </Typography>
                </Grid>
              </Grid>
              {dataDashboardCardAverageAndCountTripwire?.isNetflowActive && (
                <Grid>
                  <Grid item style={{ marginLeft: "16px" }}>
                    <Typography className={classes.blackTextBold}>
                      End Count
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "16px" }}>
                    <Typography
                      className={classes.number}
                      style={{ color: "#2F56A0" }}
                    >
                      {
                        ~~Math.round(
                          dataDashboardCardAverageAndCountTripwire?.endCount!
                        )
                      }
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container={true} direction="row" justify="space-between">
              <Grid>
                <Typography className={classes.blackText}>
                  Image Sensors
                </Typography>
                <Grid container direction="row">
                  <Typography className={classes.textValue}>
                    {
                      ~~Math.round(
                        dataDashboardCardAverageAndCountTripwire?.cameraCount!
                      )
                    }
                  </Typography>
                  <SecurityCamera
                    className={classes.marginRight10}
                    width={36}
                    height={36}
                  />
                </Grid>
              </Grid>
              {dataDashboardCardAverageAndCountTripwire?.netFlowSpacesCount ? (
                <Grid style={{ marginLeft: "16px" }}>
                  <Typography className={classes.blackText}>
                    Fully Enclosed Spaces
                  </Typography>
                  <Typography className={classes.textValue}>
                    {
                      ~~Math.round(
                        dataDashboardCardAverageAndCountTripwire?.netFlowSpacesCount!
                      )
                    }
                  </Typography>
                </Grid>
              ) : (
                <Grid></Grid>
              )}
              {dataDashboardCardAverageAndCountTripwire?.isNetflowActive ? (
                <Grid className={classes.analyzeButton}>
                  <Button
                    id="cancelBtn"
                    className={classes.cancel}
                    onClick={() => setShowModal(true)}
                  >
                    Analyze
                  </Button>
                </Grid>
              ) : (
                <Grid></Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomModal open={showModal}>
        <AnalyzeModal
          yes={rejectChanges}
          timeFrom={timeFrom}
          timeTo={timeTo}
          selectedTime={selectedTime}
          selectedSpace={selectedSpace}
          tripWireCount={~~Math.round(devicesByFloor?.tripwireCount)}
          selectedFloor={selectedFloorSummary}
          selectedTimeZone={selectedProperty?.timeZoneName || ""}
        />
      </CustomModal>
      <CustomModal open={toggleModal}>
        <MyPinsModal
          setToggleModal={setToggleModal}
          title={"Dashboard"}
          selectedTime={requestData?.selectedTime!}
          selectedSpace={requestData?.selectedSpace!}
          selectedFloor={selectedFloorSummary}
          deviceType={requestData?.deviceType!}
          deviceId={requestData?.deviceId!}
          timeRange={timeRange}
          handleIsPinned={handleIsPinned}
        />
      </CustomModal>
      <CustomModal open={toggleUnpinModal}>
        <UnpinModal
          setToggleModal={setToggleUnpinModal}
          deviceType={unPinRequestData?.deviceType!}
          deviceId={unPinRequestData?.deviceId!}
          selectedSpace={unPinRequestData?.selectedSpace!}
          selectedFloor={selectedFloorSummary}
          Type="MyPins"
          title={"Dashboard"}
          handleIsPinned={handleIsPinned}
        />
      </CustomModal>
    </>
  );
};
