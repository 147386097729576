import React from "react";
import { Grid } from "@material-ui/core";

import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { EditCoverPhoto } from "./EditProperty/WorkplaceSettings/EditCoverPhoto";
import { EditLogo } from "./EditProperty/WorkplaceSettings/EditLogo";

export function EditWorkplaceSettings() {
  const { selectedProperty } = usePropertiesState();

  return (
    <Grid>
      <EditLogo
        name={"string"}
        label={"Edit Logo"}
        property={selectedProperty}
        currentUrl={selectedProperty!.logoKey}
      />
      <EditCoverPhoto
        name={"string"}
        label={"Edit Cover Photo"}
        property={selectedProperty}
        currentUrl={selectedProperty!.imageKey}
      />
    </Grid>
  );
}
