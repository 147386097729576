import { useFloorState } from "./Floor.container";
import { useSpacesState } from "./Spaces.container";
import { useDoorState } from "./Door.container";
import { useVLANState } from "./VLAN.container";
import { useSSIDState } from "./SSID.container";
import { useAccessPointState } from "./AccessPoints.container";
import { useSensorsState } from "./Sensors.contrainer";
import { useImagesSensorsState } from "./ImageSensor.container";
import { useTripWireState } from "./TripWire.container";
import { useZoneState } from "./Zone.container";
import { useBeaconsState } from "./Beacons.container";
import { useElevatorsState } from "./Elevators.container";
import { useElevatorBaysState } from "./ElevatorBays.container";

export function AllContainers() {
  const { selectedFloor } = useFloorState();
  const { selectedSpace } = useSpacesState();
  const { selectedDoor } = useDoorState();
  const { selectedVLAN } = useVLANState();
  const { selectedSSID } = useSSIDState();
  const { selectedAccessPoint } = useAccessPointState();
  const { selectedSensor } = useSensorsState();
  const { selectedImagesSensor } = useImagesSensorsState();
  const { selectedTripWire } = useTripWireState();
  const { selectedZone } = useZoneState();
  const { selectedBeacon } = useBeaconsState();
  const { selectedElevator } = useElevatorsState();
  const { selectedElevatorBay } = useElevatorBaysState();

  return {
    selectedFloor,
    selectedSpace,
    selectedDoor,
    selectedVLAN,
    selectedSSID,
    selectedAccessPoint,
    selectedSensor,
    selectedImagesSensor,
    selectedTripWire,
    selectedZone,
    selectedBeacon,
    selectedElevator,
    selectedElevatorBay,
  };
}
