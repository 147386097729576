import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";
import { useUserState } from "../../../core/context/containers/User.container";
import { usePropertiesState } from "../../../core/context/containers/Properties.container";
import { ReactComponent as PinBlack } from "../../../common/assets/images/pin_black.svg";
import { ReactComponent as FreshAir } from "../../../common/assets/images/fresh-air.svg";
import { UnpinModal } from "./UnpinModal";
import { CustomModal } from "../../../common/components";
import moment from "moment";
import Loader from "react-loader-spinner";
import { DashboardCollapsibleMyPin } from "./DashboardCollapsibleMyPin";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginRight: "16px",
    width: "220px",
  },
  statisticContainerTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginLeft: "14px",
    marginBottom: "16px",
  },
  pinIcon: {
    transform: "rotate(45deg)",
    height: "16px",
  },
  box: {
    border: "1px solid",
    borderColor: "#E0EBF1",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",
  },
  dashboradBoxContent: {
    // paddingLeft:"10px",
    // paddingRight:"10px"
  },
  pinGrid: {
    marginRight: "4px",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5px",
  },
  number: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(30),
    color: "#2F56A0",
    marginBottom: theme.spacing(1),
    marginLeft: "10px",
  },
  blackText: {
    display: "inline-block",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: "9px 9px 9px 0",
  },
  textValue: {
    color: "#2F56A0",
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(28),
  },
  bottomGrid: {
    display: "flex",
    marginLeft: "10px",
    marginBottom: "20px",
  },
  freshAirIcon: {
    marginLeft: "48px",
  },
  loaderGrid: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
}));

interface DashboardAQMyPinProps {
  item?: any;
  loadingMyPin?: any;
  removedCard?: any;
}

export function DashboardAQMyPin({
  item,
  loadingMyPin,
  removedCard,
}: DashboardAQMyPinProps) {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const [toggleUnpinModal, setToggleUnpinModal] = useState<boolean>(false);
  const [avgWellnessData, setAvgWellnessData] = useState<any>();

  let parsedRequest = JSON.parse(item.queryData).request;
  const [selectedTime, setSelectedTime] = useState(parsedRequest.selectedTime);

  const [getDevicesByFloorId, { loading: loadingAQ, data: dataAQ }] =
    useLazyQuery(GQLService.queries.devicesByFloorId, {
      fetchPolicy: "network-only",
    });

  const timeRange = useMemo(() => {
    if (selectedTime! === 15) return "MINUTE";
    if (selectedTime! === 60) return "QUARTER";
    if (selectedTime! === 1440) return "HOUR";
    if (selectedTime! === 10080) return "DAY";
    if (selectedTime! === 43200) return "WEEK";
  }, [selectedTime, item]);

  useEffect(() => {
    if (selectedTime!) {
      let timeFrom =
        selectedTime! > 1440
          ? moment()
              .utc()
              .subtract(selectedTime!, "minutes")
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
              .toString()
          : moment()
              .utc()
              .startOf(selectedTime! >= 1440 ? "hour" : "minute")
              .subtract(selectedTime!, "minutes")
              .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
              .toString();

      let timeTo =
        selectedTime! > 1440
          ? moment()
              .utc()
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
              .toString()
          : moment()
              .utc()
              .startOf(selectedTime! >= 1440 ? "hour" : "minute")
              .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
              .toString();

      getDevicesByFloorId({
        variables: {
          request: {
            floorId: item?.floor?.id!,
            from: timeFrom?.valueOf(),
            to: timeTo?.valueOf(),
            timeRangeType: timeRange,
            spaceId: item?.space?.id!,
            userEmail: userEmail,
          },
        },
      });
    }
  }, [userEmail, item, timeRange, selectedTime!]);

  useEffect(() => {
    if (dataAQ) {
      setAvgWellnessData(dataAQ?.devicesByFloorId!);
    }
  }, [dataAQ]);

  const timeChange = (time: any) => {
    setSelectedTime(time);
  };

  return (
    <>
      <Grid container={true} direction="column" className={classes.wrapper}>
        {!loadingMyPin && (
          <DashboardCollapsibleMyPin item={item} timeChange={timeChange} />
        )}
        {!loadingAQ ? (
          <Grid
            className={classes.box}
            style={{
              borderRadius: "8px",
              marginTop: "-8px",
              backgroundColor: "#ffffff",
            }}
          >
            <Grid direction="column" className={classes.dashboradBoxContent}>
              <Grid className={classes.pinGrid}>
                <PinBlack
                  fill="#3f51b5"
                  className={classes.pinIcon}
                  onClick={() => setToggleUnpinModal(true)}
                />
              </Grid>
              <Grid>
                <Typography className={classes.statisticContainerTitle}>
                  Avg Wellness Index
                </Typography>
                <Typography className={classes.number}>
                  {~~Math.round(avgWellnessData?.aqDensity)}
                </Typography>
              </Grid>
              <Grid style={{ marginLeft: "10px" }}>
                <Typography className={classes.blackText}>
                  Air Quality Sensors
                </Typography>
              </Grid>
              <Grid className={classes.bottomGrid}>
                <Typography className={classes.textValue}>
                  {~~Math.round(avgWellnessData?.aqCount)}
                </Typography>
                <FreshAir
                  className={classes.freshAirIcon}
                  width={36}
                  height={36}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.loaderGrid}>
            <Loader type="Oval" height={50} width={50} color="#2F56A0" />
          </Grid>
        )}
      </Grid>
      <CustomModal open={toggleUnpinModal}>
        <UnpinModal
          setToggleModal={setToggleUnpinModal}
          deviceType={item?.deviceType!}
          deviceId={parsedRequest?.deviceId!}
          selectedSpace={item?.space?.id!}
          selectedFloor={item?.floor!}
          Type="MyPins"
          removedCard={removedCard}
        />
      </CustomModal>
    </>
  );
}
