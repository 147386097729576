import { useRef, useState } from "react";
import { createContainer } from "unstated-next";

function useFormWizardStateBase() {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const [formData, setFormData] = useState<any>({});
  const currentStepFormRefs = useRef<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return {
    activeIndex,
    setActiveIndex,
    formData,
    setFormData,
    currentStepFormRefs,
    isLoading,
    setIsLoading,
  };
}

const { Provider, useContainer } = createContainer(useFormWizardStateBase);

export const useFormWizardState = useContainer;
export const FormWizardProvider = Provider;
