import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "20px",
  },
  tabButton: {
    backgroundColor: theme.palette.common.concrete,
    display: "block",
    textAlign: "center",
    float: "none",
    position: "absolute",
    borderRadius: `8px`,
    minHeight: `30px`,
    height: `30px`,
    marginLeft: `5px`,
  },
  tabIndicator: {
    display: "none",
    overflow: "hidden",
  },
  tab: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.common.blackPearl,
    textTransform: "none",
    minWidth: `50px`,
  },
  tabSelected: {
    backgroundColor: theme.palette.common.blackPearl,
    color: theme.palette.common.white,
    overflow: "hidden",
  },
  wrapper: {
    marginBottom: `15px`,
  },
  blackText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.blackPearl,
    marginTop: theme.spacing(1),
  },
}));
