import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";

import { PageHeader } from "../../../../common/components";
import { useSSIDState } from "../../../../core/context/containers/SSID.container";
import { ElementView } from "./elementView";
import { ROUTES } from "../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { StatusIndicator } from "../../../../common/components";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { Role } from "../../../../types";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 90,
    height: 40,
    borderRadius: 30,
    display: "flex",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.common.dustyGray,
      textDecoration: "underline",
    },
  },
  swipePanel: {
    marginBottom: 100,
  },
  fullWidth: {
    width: "100%",
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  activeIndicator: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  activeLabel: {
    marginRight: "5px",
  },
  blackHeader: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.blackPearl,
    marginBottom: theme.spacing(5),
    lineHeight: theme.typography.pxToRem(24),
  },
  marginTop: {
    marginTop: theme.typography.pxToRem(45),
  },
  detailsLabel: {
    color: theme.palette.common.solidGray,
  },
}));

export function SSIDView() {
  const classes = useStyles();
  const { selectedSSID } = useSSIDState();
  const { selectedProperty } = usePropertiesState();
  const { formatMessage } = useIntl();

  const auth = useAuth();

  const history = useHistory();

  const handleEditSSIDClick = () => {
    history.push(
      ROUTES.SSID_EDIT.replace(":uid", selectedProperty?.id!).replace(
        ":ssid",
        selectedSSID?.id.split(":")[1]!
      )
    );
  };

  const createSSIDButton = useCallback(() => {
    return (
      <Button className={classes.editBtn} onClick={handleEditSSIDClick}>
        Edit
      </Button>
    );
  }, []);

  return (
    <Grid container={true} direction="row" alignItems="center">
      <Grid item={true} className={classes.fullWidth}>
        <Grid className={classes.header}>
          <PageHeader
            title={formatMessage({
              id: "property.header",
              defaultMessage: `SSID ${selectedSSID?.name}`,
              description: "propery details header",
            })}
          />
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "ssids:edit",
            createSSIDButton()
          )}
        </Grid>
        <Grid item>
          <ElementView content={selectedSSID?.name} name={"SSID Name"} />
          <ElementView
            content={selectedSSID?.description}
            name={"Description"}
          />
          <ElementView
            content={selectedSSID?.authenticationType}
            name={"Authentication Type"}
          />
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "ssids:protected",
            <ElementView content={selectedSSID?.vlanId} name={"VLAN ID"} />
          )}
          <Grid item className={classes.activeIndicator}>
            <Typography className={classes.activeLabel}>Active</Typography>
            <StatusIndicator
              uid={selectedProperty?.id!}
              canShowStatusLabel={false}
              isActive={selectedSSID?.active!}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
