import * as React from "react";

import {
  IAuthClient,
  AuthContextProps,
  IAuthContext,
} from "../../../types/auth";
import RestAuthClient from "../../client/RestApiClient";
import { parseJwt } from "../../../common/utils/Auth.utils";

const AuthContext = React.createContext<AuthContextProps>(null);
const { Provider, Consumer } = AuthContext;
const useAuth = () => React.useContext(AuthContext!);

interface IProps {
  children: React.ReactNode;
  client: RestAuthClient;
}

class StatefulProvider extends React.Component<IProps, IAuthContext> {
  methods = this.prepareMethods();
  constructor(props: IProps) {
    super(props);
    const user = props.client.storage.getItem("authUser")
      ? JSON.parse(props.client.storage.getItem("authUser")!)
      : null;

    const token = props.client.storage.getItem("accessToken");

    const tokenExpired = token
      ? (parseJwt(token) as any)?.exp < Date.now() / 1000
      : true;
    if (tokenExpired) {
      props.client.logout();
    }

    const initialState =
      !tokenExpired &&
      props.client.storage.getItem("refreshToken") !== null &&
      props.client.storage.getItem("authUser") !== null;

    this.state = {
      isInitialized: initialState,
      isAuthenticated: initialState,
      token: props.client.storage.getItem("accessToken") || "",
      refreshToken: props.client.storage.getItem("refreshToken") || "",
      user: user,
      ...this.methods,
    };
    if (initialState) {
      this.methods.startCheckingTokens();
    }
  }

  syncState = () => {
    const { client } = this.props;
    this.setState({
      isInitialized: client.getIsInitialized(),
      isAuthenticated: client.getIsAuthenticated(),
      token: client.getToken(),
      user: client.getUser(),
      ...this.methods,
    });
  };

  prepareMethods() {
    const { client } = this.props;
    return {
      loginWithEmailAndPassword: client.loginWithEmailAndPassword.bind(client),
      logout: client.logout.bind(client),
      init: client.init.bind(client),
      getToken: client.getToken.bind(client),
      getFreshToken: client.getFreshToken.bind(client),
      checkAccess: client.checkAccess.bind(client),
      canSee: client.canSee.bind(client),
      updateUser: client.updateUser.bind(client),
      startCheckingTokens: client.startCheckingTokens.bind(client),
      isAnyScopeAvaialable: client.isAnyScopeAvaialable.bind(client),
    };
  }

  componentDidMount() {
    this.props.client.registerOnUpdateHandler(this.syncState);
  }

  render() {
    const { children } = this.props;

    return <Provider value={this.state}>{children}</Provider>;
  }
}

export {
  AuthContext,
  Consumer as AuthConsumer,
  StatefulProvider as AuthProvider,
  useAuth,
};
