export enum airQuality {
  FAIR = "FAIR",
  HEALTHY = "HEALTHY",
  MODERATE = "MODERATE",
  NO_DATA = "NO_DATA",
  POOR = "POOR",
  UNHEALTHY = "UNHEALTHY",
  OFFLINE = "OFFLINE",
}

export enum SensorAttributeDBName {
  AIR_QUALITY_INDEX = "AIR QUALITY INDEX",
  CO2 = "CO2",
  HUMIDITY = "HUMIDITY",
  LIGHT = "LIGHT",
  NOISE = "NOISE",
  PM = "PM",
  SCORE = "SCORE",
  TEMPERATURE = "TEMPERATURE",
  TVOCS = "TVOCS",
}

export enum NotificationDataType {
  OCCUPANCY = "OCCUPANCY",
  DWELL_TIME = "DWELL_TIME",
  SCORE = "SCORE",
  CO2 = "CO2",
  HUMIDITY = "HUMIDITY",
  LIGHT = "LIGHT",
  NOISE = "NOISE",
  PM = "PM",
  TEMPERATURE = "TEMPERATURE",
  TVOCS = "TVOCS",
}

export const dataTypeForNotifications = [
  { value: NotificationDataType.OCCUPANCY, label: "Occupancy" },
  { value: NotificationDataType.DWELL_TIME, label: "Dwell Time" },
  { value: NotificationDataType.SCORE, label: "Wellness Index" },
  { value: NotificationDataType.TEMPERATURE, label: "Temperature in °F" },
  { value: NotificationDataType.HUMIDITY, label: "Humidity in %" },
  { value: NotificationDataType.CO2, label: "CO2 in ppm" },
  { value: NotificationDataType.TVOCS, label: "VOC in ppb" },
  { value: NotificationDataType.PM, label: "PM in ugm3" },
  { value: NotificationDataType.LIGHT, label: "Light in lux" },
  { value: NotificationDataType.NOISE, label: "Noise in db" },
];

export enum NotificationResourceType {
  FLOOR = "FLOOR",
  SPACE = "SPACE",
  ZONE = "ZONE",
}
export enum NotificationConditionType {
  POOR = "POOR",
  MODERATE = "MODERATE",
  HEALTHY = "HEALTHY",
  EXCEEDS = "EXCEEDS",
}

export const conditionRange = [
  {
    id: "id-poor",
    value: NotificationConditionType.POOR,
    label: "Hits poor range",
  },
  {
    id: "id-moderate",
    value: NotificationConditionType.MODERATE,
    label: "Hits moderate range",
  },
  {
    id: "id-healthy",
    value: NotificationConditionType.HEALTHY,
    label: "Hits healthy range",
  },
];

export type AirQualityForProperty = {
  airQuality: airQuality;
  fairStatusCount: number;
  healthyStatusCount: number;
  moderateStatusCount: number;
  noDataStatusCount: number;
  poorStatusCount: number;
  score: number;
  unhealthyStatusCount: number;
};

export type SensorDetailsAirQualityForFloor = {
  airSensor: {
    active: boolean;
    coordinateX: number;
    coordinateY: number;
    coordinateZ: number;
    deviceUuid: string;
    floor: {
      id: string;
    };
    id: string;
    name: string;
  };
  details: {
    offlineFromInMinutes: number;
    airQuality: airQuality;
    co2: airQualityStatus;
    from: number;
    humidity: airQualityStatus;
    illuminance: airQualityStatus;
    particulateMatter: airQualityStatus;
    score: number;
    soundLevel: airQualityStatus;
    temperature: airQualityStatus;
    to: number;
    volatileOrganicCompounds: airQualityStatus;
    isPinned: boolean;
  };
  active?: boolean;
};

export type airQualityStatus = {
  value: number;
  sensorHealthIndicator: airQuality;
};
