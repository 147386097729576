export const AP_NAME = "AP Name";
export const PHYSICAL_LOCATION = "Physical Location";
export const IPADDRESS = "IP Address";
export const BASE_MAC_ADDRESS = "Base Mac Address";
export const INVENTORY = "Inventory #";
export const RADIO_1_BAND = "Radio 1 Band";
export const RADIO_2_BAND = "Radio 2 Band";
export const STATUS = "Status";
export const SSID_RADIO_1 = "SSID for Radio1";
export const SSID_RADIO_2 = "SSID for Radio2";
