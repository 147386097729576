import React, { useCallback, useState } from "react";
import { FieldInputProps, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import {
  Button,
  Grid,
  Input as InputUI,
  InputLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "21px",
  },
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: 14,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  inputField: {
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  label: {
    margin: "20px 0 10px 0",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.funBlue,
  },
  errorInput: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: 14,
    borderColor: theme.palette.common.cinnabar,
  },
  generate: {
    backgroundColor: "transparent",
    color: "#2F56A1",
    textTransform: "initial",
    border: "1px solid #2F56A1",
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    minWidth: 180,
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    width: "30%",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
    alignSelf: "flex-end",
    marginBottom: "14px",
  },
  passwordWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
}));

interface InputProps extends FieldInputProps<any> {
  defaultValue: string;
  disabled?: boolean;
  placeholder: string;
}

const generatePassword = () => {
  const length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export function Password({
  name,
  defaultValue = "",
  disabled = false,
  placeholder,
  onChange,
}: InputProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { errors, values, initialValues, isSubmitting } = useFormikContext<{
    [key: string]: string;
  }>();

  const isTouched = isSubmitting || values[name] !== initialValues[name];

  const [fieldValue, setFieldValue] = useState<string>(defaultValue);

  const handleChange = useCallback(
    (e) => {
      setFieldValue(e.target.value);
      onChange!({
        ...e,
        target: { name, value: e.target.value },
      });
    },
    [name, fieldValue, onChange]
  );

  const generatePasswordCb = useCallback(
    (e, val) => {
      setFieldValue(val);
      onChange!({
        ...e,
        target: { name, value: val },
      });
    },
    [name, fieldValue]
  );

  return (
    <Grid>
      <Typography className={classes.header} style={{ paddingTop: "16px" }}>
        {formatMessage({
          id: "FormField.Password.Input",
          defaultMessage:
            "Create User Password (Minimum of 8 characters, using a mix of uppercase and lowercase letters, and numerals)",
          description: "Form field Password Input description",
        })}
      </Typography>

      <Grid
        key={name}
        container={true}
        item={true}
        direction="column"
        className={classes.passwordWrapper}
      >
        <Grid className={classes.inputWrapper}>
          <InputLabel className={classes.label}>
            {formatMessage({
              id: "FormField.Password.Input",
              defaultMessage: "Password",
              description: "Form field Password Input Label",
            })}
          </InputLabel>
          <InputUI
            classes={{
              root: `${
                isTouched && errors[name] ? classes.errorInput : classes.input
              }`,
              input: classes.inputField,
            }}
            value={fieldValue}
            placeholder={"Type here"}
            name={name}
            disabled={disabled}
            onChange={handleChange}
            disableUnderline={true}
            fullWidth={false}
          />
        </Grid>
        <Button
          onClick={(e) => generatePasswordCb(e, generatePassword())}
          className={classes.generate}
        >
          {formatMessage({
            id: "FormField.GeneratePassword.Button",
            defaultMessage: "Generate Password",
            description: "Form field Generate Password Button Label",
          })}
        </Button>
      </Grid>
    </Grid>
  );
}
