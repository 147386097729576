import React from "react";

// import { AuthProvider } from "./containers/Auth.container";
import { PropertiesProvider } from "./containers/Properties.container";
import { SSIDProvider } from "./containers/SSID.container";
import { ImagesSensorsProvider } from "./containers/ImageSensor.container";
import { FloorProvider } from "./containers/Floor.container";
import { FloorPlanEditorProvider } from "./containers/FloorPlanEditor/FloorPlanEditor.container";
import { ToolbarProvider } from "./containers/FloorPlanEditor/Toolbar.container";
import { FiltersProvider } from "./containers/Filters.container";
import { ElevatorBaysProvider } from "./containers/ElevatorBays.container";
import { FormWizardProvider } from "./containers/FormWizard.container";
import { AccessPointProvider } from "./containers/AccessPoints.container";
import { VLANProvider } from "./containers/VLAN.container";
import { UserProvider } from "./containers/User.container";
import { TimeSliderProvider } from "./containers/TimeSlider.container";
import { SensorsProvider } from "./containers/Sensors.contrainer";
import { SearchProvider } from "./containers/Search.container";
import { BeaconsProvider } from "./containers/Beacons.container";
import { ElevatorsProvider } from "./containers/Elevators.container";
import { DevicesProvider } from "./containers/Devices.container";
import { ZoneProvider } from "./containers/Zone.container";
import { TripWireProvider } from "./containers/TripWire.container";
import { DoorProvider } from "./containers/Door.container";
import { GroupProvider } from "./containers/Groups.container";
import { LeftPaneProvider } from "./containers/LeftPane.container";
import { SpacesProvider } from "./containers/Spaces.container";
import { NotificationsProvider } from "./containers/Notification.continer";

const providers = [
  // AuthProvider,
  TimeSliderProvider,
  PropertiesProvider,
  FloorPlanEditorProvider,
  ToolbarProvider,
  FiltersProvider,
  SSIDProvider,
  ImagesSensorsProvider,
  AccessPointProvider,
  FloorProvider,
  ElevatorBaysProvider,
  FormWizardProvider,
  VLANProvider,
  UserProvider,
  SensorsProvider,
  SearchProvider,
  ElevatorsProvider,
  BeaconsProvider,
  DevicesProvider,
  ZoneProvider,
  TripWireProvider,
  DoorProvider,
  GroupProvider,
  LeftPaneProvider,
  SpacesProvider,
  NotificationsProvider,
];

export const ProviderGroup: React.FC = ({ children }) => {
  const ReducedProviders = providers.reduceRight((children, Provider) => {
    return <Provider>{children}</Provider>;
  }, children);
  return <>{ReducedProviders}</>;
};

export const RootComponentProvider: React.FC = ({ children }) => {
  return <ProviderGroup>{children}</ProviderGroup>;
};

interface AppContextProviderProps {
  children: React.ReactNode;
}

function AppContextProvider({ children }: AppContextProviderProps) {
  return <RootComponentProvider>{children}</RootComponentProvider>;
}

export default AppContextProvider;
