import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { AccessPoint } from "../../../../types";
import { SideListItem } from "./SideListItem";
import { useTimeSliderState } from "../../../../core/context/containers/TimeSlider.container";

const useStyles = makeStyles((theme) => ({
  sideList: {
    display: "flex",
    borderRight: `1px solid ${theme.palette.common.lightGray}`,
    flexDirection: "column",
  },
  title: {
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    padding: theme.typography.pxToRem(20),
    color: theme.palette.common.darkBlue,
    borderBottom: `1px solid ${theme.palette.common.lightGray}`,
  },
}));

type spaceLoad = "HIGH" | "LOW" | "MEDIUM" | "NOT_CONFIGURED" | "OFFLINE";
type timeRangeType =
  | "DAY"
  | "FIVE_MINUTES"
  | "HOUR"
  | "MINUTE"
  | "QUARTER"
  | "WEEK";

type Timeline = {
  from: string;
  to: string;
  spaceLoad: spaceLoad;
};
type AccessPointWithSpaceLoadTimeline = {
  accessPoint: AccessPoint;
  timeline: Timeline[];
  active?: boolean;
  timeRangeType: timeRangeType;
};
type AccessPointWithSpaceLoad = {
  accessPoint: AccessPoint;
  spaceLoad: spaceLoad;
  active?: boolean;
  applicationUsage: any[];
};

// | AccessPointWithSpaceLoadTimeline[]
// | AccessPointWithSpaceLoadTimeline

type SideListPropType = {
  selectedTime: number;
  data?: AccessPointWithSpaceLoad[];
  //dataT?: AccessPointWithSpaceLoadTimeline[];
  toggleActive?: (el: string) => void;
  toggleActiveT?: (el: string) => void;
  handleIsPinned?: (el: any) => void;
  offHoursIsActive?: boolean;
};

export const SideList = ({
  selectedTime,
  data,
  //dataT,
  toggleActive,
  toggleActiveT,
  handleIsPinned,
  offHoursIsActive,
}: SideListPropType) => {
  const classes = useStyles();

  const { selectedTimeSlider, setSelectedTimeSlider } = useTimeSliderState();

  return (
    <Grid className={classes.sideList}>
      <Typography className={classes.title}>
        Access Points {`(${data?.length})`}
      </Typography>
      {/* {selectedTime === 1440
        ? dataT?.map((el, i) => (
            <SideListItem
              key={i}
              dataT={el}
              selectedTime={selectedTime}
              toggleActiveT={toggleActiveT}
              handleIsPinned={handleIsPinned}
            />
          ))
        : */}
      {data?.map((el, i) => (
        <SideListItem
          key={i}
          data={el}
          selectedTime={selectedTime}
          toggleActive={toggleActive}
          handleIsPinned={handleIsPinned}
          offHoursIsActive={offHoursIsActive}
        />
      ))}
    </Grid>
  );
};
