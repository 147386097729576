import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";

import { ROUTES } from "../../common/constants/Routing";
import { AddAdmin } from "../../pages/Dashboard/User/Add/Admin/Add";
import { AddPropertyAdmin } from "../../pages/Dashboard/User/Add/PropertyAdmin/Add";
import { AddTenant } from "../../pages/Dashboard/User/Add/Tenant/Add";
import { EditUser } from "../../pages/Dashboard/User/Edit/Edit";
import { Profile } from "../../pages/Dashboard/User/Profile/Profile";
import { UserView } from "../../pages/Dashboard/User/View/View";
import { Role } from "../../types";
import { useAuth } from "../context/containers/Auth.container";

export const USER_ROUTES = {
  USERS: "/dashboard/users",
  ADD_USER: "/dashboard/users/new/",
  ADD_ADMIN: "/dashboard/users/new/join_admin",
  ADD_PROPERTY_ADMIN: "/dashboard/users/new/property_admin",
  ADD_TENANT: "/dashboard/users/new/tenant",
  EDIT_USER: "/dashboard/users/:id/edit",
  USER_VIEW: "/dashboard/users/:id/view",
  ACCOUNT: "/dashboard/users/profile",
};

const Users = lazy(() => import("../../pages/Dashboard/User/List/List"));

export default function UsersRouter() {
  const auth = useAuth();

  return (
    <Route path={ROUTES.USERS}>
      <Route
        exact={true}
        path={ROUTES.USERS}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "users:list",
              <Users />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route exact={true} path={ROUTES.ACCOUNT} component={Profile} />
      <Route
        exact={true}
        path={ROUTES.USER_VIEW}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "users:view",
              <UserView />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.EDIT_USER}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "users:edit",
              <EditUser />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.ADD_ADMIN}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "users:create",
              <AddAdmin />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.ADD_PROPERTY_ADMIN}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "users:create",
              <AddPropertyAdmin />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
      <Route
        exact={true}
        path={ROUTES.ADD_TENANT}
        render={(props) => {
          return (
            auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "users:create",
              <AddTenant />
            ) || <Redirect from="*" to={ROUTES.DASHBOARD} />
          );
        }}
      />
    </Route>
  );
}
