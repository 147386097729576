import React, { useCallback } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Grid, makeStyles, Button } from "@material-ui/core";

import { PageHeader } from "../../../../common/components";
import { ElementView } from "./elementView";
import { ROUTES } from "../../../../common/constants/Routing";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { Role } from "../../../../types";
import { useDevicesState } from "../../../../core/context/containers/Devices.container";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 90,
    height: 40,
    borderRadius: 30,
    display: "flex",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.common.dustyGray,
      textDecoration: "none",
    },
  },
  fullWidth: {
    width: "100%",
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  headerInfo: {
    marginTop: theme.typography.pxToRem(25),
  },
}));

export function DeviceView() {
  const classes = useStyles();
  const { selectedDevice } = useDevicesState();
  const { selectedProperty } = usePropertiesState();
  const { formatMessage } = useIntl();

  const history = useHistory();

  const auth = useAuth();

  const handleEditDeviceClick = useCallback(() => {
    history.push(
      ROUTES.EDIT_DEVICE.replace(":uid", selectedProperty?.id!).replace(
        ":deviceId",
        selectedDevice?.id!
      )
    );
  }, []);

  const createDeviceButton = useCallback(() => {
    return (
      <Button className={classes.editBtn} onClick={handleEditDeviceClick}>
        Edit
      </Button>
    );
  }, []);

  return (
    <Grid container={true} direction="row" alignItems="center">
      <Grid item={true} className={classes.fullWidth}>
        <Grid className={classes.header}>
          <PageHeader
            title={formatMessage({
              id: "property.header",
              defaultMessage: `Device: ${selectedDevice?.mac}`,
              description: "propery details header",
            })}
          />
          {auth?.canSee(
            auth?.user?.role?.toUpperCase() as Role,
            "devices:edit",
            createDeviceButton()
          )}
        </Grid>
        <Grid item className={classes.headerInfo}>
          <ElementView content={selectedDevice?.mac} name={"Mac"} />
          <ElementView
            content={selectedDevice?.deviceType}
            name={"Classified As"}
          />
          <ElementView content={selectedDevice?.deviceOs} name={"User Agent"} />
          <ElementView
            content={selectedDevice?.description}
            name={"Description"}
          />
          <ElementView
            content={selectedDevice?.ignore ? "Yes" : "No"}
            name={"Ignore"}
          />
          <ElementView
            content={moment(selectedDevice?.lastConnectionTime).format(
              "MMM D, YYYY hh:mm A"
            )}
            name={"Last Connection Date"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
