import { createContainer } from "unstated-next";

import { Floor } from "../../../../types";
import { usePersistedState } from "../../../hooks/Storage";

function useFloorPlanEditorBase() {
  const { state: floor, setState: setFloor } = usePersistedState<Floor | null>(
    "floor",
    null
  );

  return {
    floor,
    setFloor,
  };
}

const { Provider, useContainer } = createContainer(useFloorPlanEditorBase);

export const useFloorPlanEditorState = useContainer;
export const FloorPlanEditorProvider = Provider;
