import { gql } from "@apollo/client";

export const BEACONS_MUTATIONS = {
  createBeacon: gql`
    mutation createBeacon(
      $active: Boolean!
      $name: String!
      $propertyId: String!
      $beaconUuid: String!
    ) {
      createBeacon(
        request: {
          active: $active
          name: $name
          propertyId: $propertyId
          beaconUuid: $beaconUuid
        }
      ) {
        id
      }
    }
  `,
  updateBeacon: gql`
    mutation updateBeacon(
      $active: Boolean!
      $id: String!
      $name: String
      $propertyId: String!
      $beaconUuid: String
    ) {
      updateBeacon(
        request: {
          active: $active
          id: $id
          name: $name
          propertyId: $propertyId
          beaconUuid: $beaconUuid
        }
      )
    }
  `,
  deleteBeacon: gql`
    mutation($id: String!) {
      deleteBeacon(id: $id)
    }
  `,
};

export const BEACONS_QUERIES = {
  beaconsByPropertyId: gql`
    query($propertyId: String!) {
      beaconsByPropertyId(propertyId: $propertyId) {
        id
        name
        active
        beaconUuid
      }
    }
  `,
};
