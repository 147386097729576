import { gql } from "@apollo/client";

export const SSID_MUTATIONS = {
  addSsid: gql`
    mutation createSsid(
      $active: Boolean!
      $authenticationType: String!
      $description: String
      $name: String!
      $propertyId: String!
      $vlanId: String!
    ) {
      createSsid(
        request: {
          active: $active
          authenticationType: $authenticationType
          description: $description
          name: $name
          propertyId: $propertyId
          vlanId: $vlanId
        }
      ) {
        id
      }
    }
  `,
  editSsid: gql`
    mutation updateSsid(
      $active: Boolean
      $authenticationType: String
      $description: String
      $name: String
      $id: String!
      $vlanId: String
      $propertyId: String!
    ) {
      updateSsid(
        request: {
          active: $active
          authenticationType: $authenticationType
          description: $description
          name: $name
          vlanId: $vlanId
          id: $id
          propertyId: $propertyId
        }
      )
    }
  `,
  removeSsid: gql`
    mutation ($ssidId: String!) {
      deleteSsid(ssidId: $ssidId)
    }
  `,
};

export const SSIDS_QUERIES = {
  allSsid: gql`
    query ($propertyId: String!) {
      ssidsByPropertyId(propertyId: $propertyId) {
        id
        active
        authenticationType
        description
        name
        propertyId
        vlanId
      }
    }
  `,
};
