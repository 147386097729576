import React, { useState, useCallback } from "react";
import {
  Menu,
  MenuItem,
  MenuList,
  makeStyles,
  Tooltip,
  Typography,
  Grid,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { usePropertiesState } from "../../../../../../core/context/containers/Properties.container";
import { useTripWireState } from "../../../../../../core/context/containers/TripWire.container";
import { useImagesSensorsState } from "../../../../../../core/context/containers/ImageSensor.container";
import { ImagesSensor, TripWire } from "../../../../../../types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../common/constants/Routing";
import { ReactComponent as HamburgerMenu } from "../../../../../../common/assets/images/hamburger.svg";
import { CanSee } from "../../../../../../common/components/canSee/canSee";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useZoneState } from "../../../../../../core/context/containers/Zone.container";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: 15,
    minHeight: 15,
    maxWidth: 15,
    maxHeight: 15,
    color: theme.palette.common.funBlue,
  },
  menuList: {
    width: 167,
    borderRadius: 6,
    outline: "none",
    padding: 0,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.funBlue,
    // borderBottom: `1px solid ${theme.palette.common.nobel}`,
    // "&:last-child": {
    //   border: "none",
    // },
  },
  infoIcon: {
    fontSize: 15,
    color: "#2F56A0",
  },
  toolTipDiv: {
    backgroundColor: "#F2F2F2",
    border: "1px solid #81D3E0",
    borderRadius: "3px",
    boxShadow: "0px 6px 6px 2px rgba(0, 0, 0, 0.25)",
    paddingTop: "14px",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  toolTipContent: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.darkBlue,
    marginBottom: "12px",
  },
}));

interface TripWireMenuProps {
  imageSensor: ImagesSensor;
  tripWire: TripWire;
  setShowModal: (el: any) => void;
  setShowdltModal: (el: any) => void;
}

export function TripWireMenu({
  imageSensor,
  tripWire,
  setShowModal,
  setShowdltModal,
}: TripWireMenuProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState();

  const { selectedProperty } = usePropertiesState();
  const { setSelectedTripWire } = useTripWireState();
  const { setSelectedImagesSensor } = useImagesSensorsState();
  const { setSelectedZone } = useZoneState();

  const handleToggleMenu = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const open = Boolean(anchorEl);

  const handleEditClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedZone(null);
    setSelectedTripWire(tripWire);
    setSelectedImagesSensor(imageSensor);
    history.push(
      ROUTES.TRIPWIRE_EDIT.replace(":uid", selectedProperty?.id!)
        .replace(":imagesensor", imageSensor?.id.split(":")[1])
        .replace(":tripWireid", tripWire?.id.split(":")[1])
    );
  };

  const handleUnlinkClick = () => {
    setShowModal(true);
    setAnchorEl(undefined);
  };

  const handleDeleteClick = () => {
    setShowdltModal(true);
    setAnchorEl(undefined);
  };

  const handleViewClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedZone(null);
    setSelectedTripWire(tripWire);
    setSelectedImagesSensor(imageSensor);
    history.push(
      ROUTES.TRIPWIRE.replace(":uid", selectedProperty?.id!)
        .replace(":imagesensor", imageSensor?.id.split(":")[1])
        .replace(":tripWireid", tripWire?.id.split(":")[1])
    );
  };

  const createTripWireEditButton = useCallback(() => {
    return (
      <>
        <MenuItem className={classes.menuItem} onClick={handleEditClick}>
          {formatMessage({
            id: "tripWire.contextMenu.editTripWire",
            defaultMessage: "Edit TripWire",
            description: "tripWire context menu edit tripWire label",
          })}
        </MenuItem>
      </>
    );
  }, []);

  const createTripWireViewButton = useCallback(() => {
    return (
      <MenuItem className={classes.menuItem} onClick={handleViewClick}>
        {formatMessage({
          id: "tripWire.contextMenu.viewTripWire",
          defaultMessage: "Details",
          description: "tripWire context menu view tripWire label",
        })}
      </MenuItem>
    );
  }, []);

  return (
    <>
      <HamburgerMenu className={classes.icon} onClick={handleToggleMenu} />
      <Menu
        id="properties-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        style={{ zIndex: 500 }}
        open={open}
        onClose={handleToggleMenu}
      >
        <MenuList className={classes.menuList}>
          <CanSee action="imageSensor:edit">
            {createTripWireViewButton()}
          </CanSee>
          <CanSee action="imageSensor:edit">
            {createTripWireEditButton()}
          </CanSee>
          <MenuItem
            className={classes.menuItem}
            onClick={handleUnlinkClick}
            disabled={tripWire?.spaces?.length! > 0 ? false : true}
          >
            {formatMessage({
              id: "tripWire.contextMenu.unlinkTripWire",
              defaultMessage: "Unlink TripWire",
              description: "tripWire context menu unlink tripWire label",
            })}
          </MenuItem>
          <Grid style={{ display: "flex" }}>
            <MenuItem
              className={classes.menuItem}
              onClick={handleDeleteClick}
              disabled={
                tripWire?.spaces?.length! > 0 || tripWire?.active!
                  ? true
                  : false
              }
            >
              {formatMessage({
                id: "tripWire.contextMenu.deleteTripWire",
                defaultMessage: "Delete TripWire",
                description: "tripWire context menu delete tripWire label",
              })}
            </MenuItem>
            <MenuItem>
              <Tooltip
                placement="top-start"
                title={
                  <>
                    <Typography className={classes.toolTipContent}>
                      1. Unlink the Tripwire
                    </Typography>
                    <Typography className={classes.toolTipContent}>
                      2. Deactivate the Tripwire
                    </Typography>
                  </>
                }
                classes={{ tooltip: classes.toolTipDiv }}
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </Tooltip>
            </MenuItem>
          </Grid>
        </MenuList>
      </Menu>
    </>
  );
}
