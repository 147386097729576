import {
  Divider,
  Grid,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import { ClickAwayListener } from "@material-ui/core";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DashboardDeviceType, DensityData, Sensor } from "../../../../types";
import CancelIcon from "@material-ui/icons/Cancel";
import { VictoryPieOccupancy } from "../../../../common/components/Charts/PieCharts/VictoryPieOccupancy/VictoryPieOccupancy";
import {
  appTypeColors,
  dataTypeColors,
} from "../../Summary/Statistics/DataUsage/DataUsage";
import { ReactComponent as LineChart } from "../../../../common/assets/images/line-chart 2.svg";
import { useStyles } from "./SensorsSideList.styles";
import clsx from "clsx";
import { BarChartForAirQuality } from "./Charts/BarChartOverTime";
import { LinearChartForDensity } from "../../../../common/components/Charts/LinearCharts/LinearChartForDensity";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import {
  airQuality,
  SensorAttributeDBName,
  SensorDetailsAirQualityForFloor,
} from "../../../../types/airQuality";
import { useIntl } from "react-intl";
import { useFiltersState } from "../../../../core/context/containers/Filters.container";
import { LinearChartForSensors } from "./Charts/LineChartSensors";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { scrollsTop } from "../../../../common/utils/scrollbar.utils";
import { ReactComponent as Pin } from "../../../../common/assets/images/pin.svg";
import { MyPinsModal } from "../../MyPins/MyPinsModal";
import { ReactComponent as PinBlack } from "../../../../common/assets/images/pin_black.svg";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { UnpinModal } from "../../MyPins/UnpinModal";

const appTypeData = [
  { x: "Engineering", y: 0.5 },
  { x: "Sales", y: 0.3 },
  { x: "Admin", y: 0.1 },
  { x: "Marketing", y: 0.05 },
  { x: "Other", y: 0.05 },
];

export const colorSpaceLoad = (type: airQuality) => {
  switch (type) {
    case "HEALTHY":
      return {
        main: "#4F9E53",
        shadow: "rgba(79, 158, 83, 0.15)",
        title: "High",
        className: "healthy",
      };
    case "POOR":
      return {
        main: "#B0001F;",
        shadow: "rgba(176, 0, 31, 0.2)",
        title: "Low",
        className: "poor",
      };
    case "MODERATE":
      return {
        main: " #EEAA46",
        shadow: "rgba(238, 170, 70, 0.2)",
        title: "Offline",
        className: "moderate",
      };
    case "OFFLINE":
      return {
        main: "#A0A0A0",
        shadow: "rgba(231, 231, 231, 0.4)",
        title: "Offline",
        className: "offlineIndicator",
      };
    case "NO_DATA":
    default:
      return {
        main: "#81D3E0;",
        shadow: "rgba(238, 170, 70, 0.2)",
        title: "Medium",
        className: "noData",
      };
  }
};

type spaceLoad = "HIGH" | "LOW" | "MEDIUM" | "NOT_CONFIGURED" | "OFFLINE";

type airQualityIndicatorClassName =
  | "healthy"
  | "moderate"
  | "poor"
  | "noData"
  | "offline";

type classNameIndicator =
  | "highIndicator"
  | "lowIndicator"
  | "mediumIndicator"
  | "notConfiguredIndicator"
  | "offlineIndicator";

type timeInMinutes = 15 | 60 | 1440 | 10080 | 43200;

type SensorToRender = { sensor: SensorDetailsAirQualityForFloor } & {
  active?: boolean;
};

type SensorsItemListProps = {
  data: SensorDetailsAirQualityForFloor;
  toggleActive: (el: string) => void;
  handleIsPinned?: (el: any) => void;
  selectedTime: number;
};

export function SensorsItemList(
  this: any,
  { data, toggleActive, handleIsPinned, selectedTime }: SensorsItemListProps
) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { selectedFloor, selectedSpace } = useFiltersState();
  const [sensorDetails, setSensorDetails] = useState<any>();
  const [dataToRender, setDataToRender] = useState<any>({});
  const { formatMessage } = useIntl();
  const { selectedPropertyTimeZone, selectedProperty } = usePropertiesState();
  const [selectedAQ, setSelectedAQ] = useState<any>();
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [toggleUnpinModal, setToggleUnpinModal] = useState<boolean>(false);

  const temperatureUnit = () => {
    const findUnit = selectedProperty?.sensorConfigurations?.filter(
      (el) => el.attribute === "TEMPERATURE"
    );
    return findUnit![0].isCelsius;
  };

  const AQDropDownList = [
    { value: SensorAttributeDBName.SCORE, label: "Wellness Index" },
    {
      value: SensorAttributeDBName.TEMPERATURE,
      label: `Temperature in ${temperatureUnit() ? "°C" : "°F"}`,
    },
    { value: SensorAttributeDBName.HUMIDITY, label: "Humidity in %" },
    { value: SensorAttributeDBName.CO2, label: "CO2 in ppm" },
    { value: SensorAttributeDBName.TVOCS, label: "VOC in ppb" },
    { value: SensorAttributeDBName.PM, label: "PM in ugm3" },
    { value: SensorAttributeDBName.LIGHT, label: "Light in lux" },
    { value: SensorAttributeDBName.NOISE, label: "Noise in db" },
  ];

  const timeRange = useMemo(() => {
    if (selectedTime <= 15) return "MINUTE";
    if (selectedTime <= 60) return "QUARTER";
    if (selectedTime <= 1440) return "HOUR";
    return "DAY";
  }, [selectedTime]);

  const getTimeParams = useCallback(() => {
    let newTimeTo;
    let newTimeFrom;
    if (selectedTime === 10080 || selectedTime === 43200) {
      if (selectedProperty?.timeZoneName) {
        newTimeTo = moment
          .tz(selectedProperty?.timeZoneName)
          .startOf("day")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedProperty?.timeZoneName)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment.utc().startOf("day").format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    } else {
      if (selectedProperty?.timeZoneName) {
        newTimeTo = moment
          .tz(selectedProperty?.timeZoneName)
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .tz(newTimeTo, selectedProperty?.timeZoneName)
          .subtract(selectedTime, "minutes")
          .format();
      } else {
        newTimeTo = moment
          .utc()
          .startOf(selectedTime === 1440 ? "hour" : "minute")
          .format();
        newTimeFrom = moment
          .utc(newTimeTo)
          .subtract(selectedTime, "minutes")
          .format();
      }
    }
    return { newTimeFrom, newTimeTo };
  }, [selectedProperty?.timeZoneName, selectedTime]);

  const [
    airQualityWellnessDuringTimeForSensor,
    {
      error: errorWellness,
      loading: loadingWellness,
      data: responseDataWellness,
    },
  ] = useLazyQuery(GQLService.queries.airQualityWellnessDuringTimeForSensor);

  useEffect(() => {
    setSelectedAQ(SensorAttributeDBName.SCORE);
  }, []);

  const handleAQChange = useCallback((e) => {
    setSelectedAQ(e.target.value);
  }, []);

  useEffect(() => {
    if (selectedAQ && sensorDetails) {
      airQualityWellnessDuringTimeForSensor({
        variables: {
          request: {
            floorId: selectedFloor?.id,
            sensorId: sensorDetails?.id,
            from: getTimeParams().newTimeFrom,
            to: getTimeParams().newTimeTo,
            timeRangeType: timeRange,
            sensorAttributeName: selectedAQ,
          },
        },
      });
    }
  }, [
    airQualityWellnessDuringTimeForSensor,
    getTimeParams,
    selectedAQ,
    selectedFloor?.id,
    sensorDetails,
    timeRange,
  ]);

  useEffect(() => {
    if (!loadingWellness && responseDataWellness) {
      setDataToRender(
        responseDataWellness?.airQualityWellnessDuringTimeForSensor
      );
    }
    scrollsTop();
  }, [loadingWellness, responseDataWellness]);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpand = useCallback(() => {
    toggleActive(data?.airSensor?.id);
  }, [data]);

  const handleOpen = (
    event: React.MouseEvent<HTMLElement>,
    sensorDetails: any
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen(true);
    setSensorDetails(sensorDetails);
    event.stopPropagation();
  };
  const id = open ? "simple-popper" : undefined;

  const handleClose = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(null);
    setOpen(false);
    event.stopPropagation();
    setSelectedAQ(SensorAttributeDBName.SCORE);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setOpen(false);
    setSelectedAQ(SensorAttributeDBName.SCORE);
    setToggleModal(false);
  };

  const renderLabel = () => {
    if (selectedAQ === SensorAttributeDBName.TEMPERATURE) {
      const unit = temperatureUnit();
      return unit ? "C" : "F";
    } else if (selectedAQ === SensorAttributeDBName.HUMIDITY) {
      return "%";
    } else if (selectedAQ === SensorAttributeDBName.CO2) {
      return "ppm";
    } else if (selectedAQ === SensorAttributeDBName.TVOCS) {
      return "ppb";
    } else if (selectedAQ === SensorAttributeDBName.PM) {
      return "ugm3";
    } else if (selectedAQ === SensorAttributeDBName.LIGHT) {
      return "lux";
    } else if (selectedAQ === SensorAttributeDBName.NOISE) {
      return "db";
    } else {
      return "";
    }
  };

  let classIndicator = colorSpaceLoad(data.details.airQuality)
    ?.className! as classNameIndicator;

  let temperatureIndicator = colorSpaceLoad(
    data.details.temperature.sensorHealthIndicator
  )?.className! as airQualityIndicatorClassName;
  let humidityIndicator = colorSpaceLoad(
    data.details.humidity.sensorHealthIndicator
  )?.className! as airQualityIndicatorClassName;
  let co2Indicator = colorSpaceLoad(data.details.co2.sensorHealthIndicator)
    ?.className! as airQualityIndicatorClassName;
  let volatileOrganicCompoundsIndicator = colorSpaceLoad(
    data.details.volatileOrganicCompounds.sensorHealthIndicator
  )?.className! as airQualityIndicatorClassName;
  let particulateMatterIndicator = colorSpaceLoad(
    data.details.particulateMatter.sensorHealthIndicator
  )?.className! as airQualityIndicatorClassName;
  let illuminanceIndicator = colorSpaceLoad(
    data.details.illuminance.sensorHealthIndicator
  )?.className! as airQualityIndicatorClassName;
  let soundLevelIndicator = colorSpaceLoad(
    data.details.soundLevel.sensorHealthIndicator
  )?.className! as airQualityIndicatorClassName;

  return (
    <Accordion
      key={data?.airSensor?.id}
      className={
        data.active ? classes.sideListElementActive : classes.sideListElement
      }
      expanded={data.active || false}
    >
      <AccordionSummary
        classes={{ content: classes.content, root: classes.root }}
      >
        <Grid style={{ marginBottom: !true ? "0px" : "10px" }}>
          {data?.details.isPinned ? (
            <Grid
              className={classes.pinIconGrid}
              style={{ marginBottom: "5px" }}
            >
              <PinBlack
                style={{
                  transform: "rotate(45deg)",
                  opacity: "0.6",
                  height: "16px",
                }}
              />
            </Grid>
          ) : null}
          <Grid key={data.airSensor?.id}>
            <Grid item className={classes.indicatorRow}>
              <Grid className={classes.indicatorAndTitleContainer}>
                {data?.details.offlineFromInMinutes > 0 ? (
                  <span
                    className={clsx(
                      classes.baseForOfflineScoreIndicator,
                      classes.offline
                    )}
                  >
                    {data.details.airQuality !== airQuality.NO_DATA &&
                      Math.round(data.details.score)}
                  </span>
                ) : (
                  <span
                    className={clsx(
                      classes.baseForScoreIndicator,
                      classes[classIndicator]
                    )}
                  >
                    {data.details.airQuality !== airQuality.NO_DATA &&
                      Math.round(data.details.score)}
                  </span>
                )}
                <Typography
                  className={classes.name}
                  onClick={(ev) => !open && toggleActive(data?.airSensor?.id)}
                >
                  {data.airSensor?.name}
                </Typography>
              </Grid>
              <Grid>
                {data.active ? (
                  <Grid style={{ display: "flex" }}>
                    <Grid
                      onClick={(event) => handleOpen(event, data?.airSensor)}
                    >
                      <LineChart
                        width={11}
                        id="open"
                        className={classes.lineChartIcon}
                      />
                    </Grid>
                    <ExpandLess
                      className={classes.expandIcon}
                      onClick={(ev) =>
                        !open && toggleActive(data?.airSensor?.id)
                      }
                    />
                  </Grid>
                ) : (
                  <Grid style={{ display: "flex" }}>
                    <Grid
                      onClick={(event) => handleOpen(event, data?.airSensor)}
                    >
                      <LineChart
                        width={11}
                        id="open"
                        className={classes.lineChartIcon}
                      />
                    </Grid>
                    <ExpandMore
                      className={classes.expandIcon}
                      onClick={(ev) =>
                        !open && toggleActive(data?.airSensor?.id)
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {data?.active && data.details.airQuality !== airQuality.NO_DATA && (
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.detailsTitle}>
                    {formatMessage({
                      id: "details.awairScore.label",
                      defaultMessage: "Awair Score",
                      description: "Awair SCore",
                    })}
                    :{" "}
                    <span className={classes.detailsTitle}>
                      {data.details.airQuality}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      {data.details.airQuality !== airQuality.NO_DATA && (
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <span
                className={clsx(
                  classes.qualityIndicator,
                  classes[temperatureIndicator]
                )}
              ></span>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.detailsTitle}>
                {formatMessage({
                  id: "details.occupancy.temperature",
                  defaultMessage: "Temperature",
                  description: "Temperature for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.detailsValue}>
                {formatMessage({
                  id: "details.occupancy.temperature",
                  defaultMessage: data.details.temperature.value
                    ? temperatureUnit()
                      ? String(Math.round(data.details.temperature.value)) +
                        "°C"
                      : String(
                          Math.round(
                            (data.details.temperature.value * 9) / 5 + 32
                          )
                        ) + "°F"
                    : "-",
                  description: "Temperature for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <span
                className={clsx(
                  classes.qualityIndicator,
                  classes[humidityIndicator]
                )}
              ></span>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.detailsTitle}>
                {formatMessage({
                  id: "details.occupancy.humidity",
                  defaultMessage: "Humidity",
                  description: "Humidity for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.detailsValue}>
                {formatMessage({
                  id: "details.occupancy.humidity",
                  defaultMessage: data.details.humidity.value
                    ? String(Math.round(data.details.humidity.value)) + "%"
                    : "-",
                  description: "Humidity for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <span
                className={clsx(
                  classes.qualityIndicator,
                  classes[co2Indicator]
                )}
              ></span>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.detailsTitle}>
                {formatMessage({
                  id: "details.occupancy.co2",
                  defaultMessage: "CO2",
                  description: "CO2 for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.detailsValue}>
                {formatMessage({
                  id: "details.occupancy.co2",
                  defaultMessage: data.details.co2.value
                    ? `${String(Math.round(data.details.co2.value))} ppm`
                    : "-",
                  description: "CO2 for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <span
                className={clsx(
                  classes.qualityIndicator,
                  classes[volatileOrganicCompoundsIndicator]
                )}
              ></span>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.detailsTitle}>
                {formatMessage({
                  id: "details.occupancy.voc",
                  defaultMessage: "VOC",
                  description: "VOC for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.detailsValue}>
                {formatMessage({
                  id: "details.occupancy.voc",
                  defaultMessage: data.details.volatileOrganicCompounds.value
                    ? String(
                        Math.round(data.details.volatileOrganicCompounds.value)
                      ) + " ppb"
                    : "-",
                  description: "VOC for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <span
                className={clsx(
                  classes.qualityIndicator,
                  classes[particulateMatterIndicator]
                )}
              ></span>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.detailsTitle}>
                {formatMessage({
                  id: "details.occupancy.pm",
                  defaultMessage: "PM",
                  description: "PM for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.detailsValue}>
                {formatMessage({
                  id: "details.occupancy.pm",
                  defaultMessage: data.details.particulateMatter.value
                    ? String(Math.round(data.details.particulateMatter.value)) +
                      " ugm3"
                    : "-",
                  description: "PM for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <span
                className={clsx(
                  classes.qualityIndicator,
                  classes[illuminanceIndicator]
                )}
              ></span>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.detailsTitle}>
                {formatMessage({
                  id: "details.occupancy.light",
                  defaultMessage: "Light",
                  description: "Light for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.detailsValue}>
                {formatMessage({
                  id: "details.occupancy.light",
                  defaultMessage: data.details.illuminance.value
                    ? String(Math.round(data.details.illuminance.value)) +
                      " lux"
                    : "-",
                  description: "Light for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <span
                className={clsx(
                  classes.qualityIndicator,
                  classes[soundLevelIndicator]
                )}
              ></span>
            </Grid>
            <Grid item xs={7}>
              <Typography className={classes.detailsTitle}>
                {formatMessage({
                  id: "details.occupancy.noise",
                  defaultMessage: "Noise",
                  description: "Noise for sensor",
                })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.detailsValue}>
                {formatMessage({
                  id: "details.occupancy.noise",
                  defaultMessage: data.details.soundLevel.value
                    ? String(Math.round(data.details.soundLevel.value)) + " db"
                    : "-",
                  description: "PM for sensor",
                })}
              </Typography>
            </Grid>
          </Grid>
          <Popper
            id={id}
            open={open}
            anchorEl={null}
            className={classes.popper}
            placement="bottom-end"
            popperOptions={{
              modifiers: {
                flip: { enabled: true },
                offset: {
                  enabled: true,
                },
              },
            }}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper classes={{ root: classes.paper }}>
                <CancelIcon
                  color="primary"
                  onClick={(e) => handleClose(e)}
                  classes={{ root: classes.closeIcon }}
                />
                <Grid className={classes.chartsContainer}>
                  <Grid className={classes.popperContainer}>
                    <Typography className={classes.popperTitle}>
                      {selectedAQ === "SCORE"
                        ? "Wellness Index"
                        : selectedAQ === "TVOCS"
                        ? "VOC"
                        : selectedAQ}
                    </Typography>
                    <Grid className={classes.currentStatusIndicatorContainer}>
                      <Typography className={classes.statusLabel}>
                        Current status:
                      </Typography>
                      <Typography className={classes.status}>
                        {data.details.airQuality}
                      </Typography>
                      <span
                        className={clsx(
                          classes[classIndicator],
                          classes.popperIndicator
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid className={classes.dropDownContainer} container={true}>
                    <Typography className={classes.dropDownTitle}>
                      Select
                    </Typography>
                    <Select
                      classes={{
                        root: classes.select,
                        select: classes.blackText,
                        icon: classes.icon,
                      }}
                      id="menu-AQFilter"
                      name="AQFilter"
                      value={selectedAQ}
                      disableUnderline={true}
                      onChange={handleAQChange}
                      IconComponent={ExpandMoreSharpIcon}
                      MenuProps={{
                        classes: {
                          paper: classes.menu,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        disablePortal: true,
                      }}
                    >
                      {AQDropDownList?.map((v) => (
                        <MenuItem
                          key={v.value}
                          value={v.value}
                          classes={{
                            root: classes.option,
                            selected: classes.selected,
                          }}
                        >
                          {v.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid>
                    <Typography className={classes.YAxisLabel}>
                      {renderLabel()}
                    </Typography>
                  </Grid>
                  {dataToRender?.timeline?.length > 0 && !loadingWellness ? (
                    <LinearChartForSensors
                      data={dataToRender?.timeline?.map((d: any, i: number) => {
                        return {
                          x:
                            timeRange === "DAY"
                              ? moment
                                  .tz(d.from, selectedProperty?.timeZoneName!)
                                  .format("DD MMM ddd")
                              : moment
                                  .tz(d.from, selectedProperty?.timeZoneName!)
                                  .format("LT"),
                          y:
                            selectedAQ === SensorAttributeDBName.TEMPERATURE
                              ? d.score
                              : Math.round(d.score),
                          quality: d.quality,
                        };
                      })}
                      loading={loadingWellness}
                      error={errorWellness}
                      selectedAQ={selectedAQ}
                      sensorConfigurations={
                        selectedProperty?.sensorConfigurations!
                      }
                    />
                  ) : loadingWellness ? null : (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{ height: 164 }}
                    >
                      <Typography>No data</Typography>
                    </Grid>
                  )}
                  <Typography className={classes.chartsTitle}>
                    {selectedAQ === "SCORE"
                      ? "Wellness Index"
                      : selectedAQ === "TVOCS"
                      ? "VOC"
                      : selectedAQ}
                  </Typography>
                  <BarChartForAirQuality
                    data={[
                      {
                        x: "Healthy",
                        y: dataToRender?.healthyTime!,
                        fill: "#E4F5D7",
                      },
                      {
                        x: "Moderate",
                        y: dataToRender?.moderateTime!,
                        fill: "#FEEDB2",
                      },
                      {
                        x: "Poor",
                        y: dataToRender?.poorTime!,
                        fill: "#F5C1B6",
                      },
                    ]}
                    loading={loadingWellness}
                    error={errorWellness}
                  />
                  <Grid
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      marginBottom: "24px",
                      marginTop: "-24px",
                    }}
                  >
                    {data?.details.isPinned ? (
                      <Button
                        className={classes.pinItButton}
                        onClick={() => setToggleUnpinModal(true)}
                      >
                        <div style={{ marginLeft: "8px" }}></div>
                        Unpin It
                      </Button>
                    ) : (
                      <Button
                        className={classes.pinItButton}
                        onClick={() => setToggleModal(true)}
                      >
                        <Pin />
                        <div style={{ marginLeft: "8px" }}></div>
                        Pin It
                      </Button>
                    )}
                  </Grid>
                </Grid>
                {toggleModal && (
                  <MyPinsModal
                    setToggleModal={setToggleModal}
                    selectedTime={selectedTime}
                    selectedSpace={selectedSpace}
                    selectedFloor={selectedFloor}
                    deviceType={DashboardDeviceType.AIRQUALITY}
                    deviceId={sensorDetails?.id}
                    timeRange={timeRange}
                    handleIsPinned={handleIsPinned}
                    subType={selectedAQ}
                  />
                )}
                <Grid style={{ left: "17%", position: "absolute", top: "20%" }}>
                  {toggleUnpinModal && (
                    <UnpinModal
                      setToggleModal={setToggleUnpinModal}
                      deviceType={DashboardDeviceType.AIRQUALITY}
                      deviceId={sensorDetails?.id}
                      handleIsPinned={handleIsPinned}
                      Type="FloorOverview"
                    />
                  )}
                </Grid>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </AccordionDetails>
      )}
    </Accordion>
  );
}
