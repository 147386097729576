import React from "react";
import {
  ActivitySwitch,
  Input,
} from "../../../../common/components/Form/FormFields";
import * as Yup from "yup";

import { FormField } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";
import { FloorSelect } from "../../../../common/components/Form/FormFields/FloorSelect/FloorSelect.v2";

const FIELD_NAMES = {
  name: "name",
  deviceUuid: "deviceUuid",
  active: "active",
  floorId: "floorId",
  commonArea: "commonArea",
};

export interface addAccessPointType {
  name: string;
  floor: string;
  status: boolean;
  deviceUuid: string;
  commonArea: boolean;
}

export const createFormFields = (data: any): FormField[] => [
  {
    name: FIELD_NAMES.name,
    label: I18n.formatMessage({
      id: "airQuality.add.label.name",
      defaultMessage: "Name",
    }),
    placeholder: I18n.formatMessage({
      id: "airQuality.add.placeholder.name",
      defaultMessage: "Type here",
    }),
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.deviceUuid,
    label: I18n.formatMessage({
      id: "airQuality.add.label.deviceUuid",
      defaultMessage: "Device UUID",
    }),
    placeholder: I18n.formatMessage({
      id: "airQuality.add.placeholder.deviceUuid",
      defaultMessage: "Type here",
    }),
    required: true,
    gridStyle: { sm: 12, md: 12 },
    Component: Input,
  },
  {
    name: FIELD_NAMES.floorId,
    label: I18n.formatMessage({
      id: "airQuality.add.label.floorId",
      defaultMessage: "Floor",
    }),
    placeholder: I18n.formatMessage({
      id: "airQuality.add.placeholder.floorId",
      defaultMessage: "Type here",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: FloorSelect,
    data: data.floors,
  },
  {
    name: FIELD_NAMES.active,
    label: I18n.formatMessage({
      id: "airQuality.add.label.active",
      defaultMessage: "Active",
    }),
    placeholder: I18n.formatMessage({
      id: "airQuality.add.placeholder.active",
      defaultMessage: "Type here",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: ActivitySwitch,
  },
  {
    name: FIELD_NAMES.commonArea,
    label: I18n.formatMessage({
      id: "airQuality.add.label.commonArea",
      defaultMessage: "Common Area",
    }),
    placeholder: I18n.formatMessage({
      id: "airQuality.add.placeholder.commonArea",
      defaultMessage: "Type here",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: ActivitySwitch,
  },
];

export const SensorValidationRules = Yup.object().shape({
  [FIELD_NAMES.name]: Yup.string()
    .min(
      3,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
  [FIELD_NAMES.floorId]: Yup.string().required(" "),
  [FIELD_NAMES.deviceUuid]: Yup.string()
    .min(
      3,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "addAccessPointFormFields.validation.name.long",
        defaultMessage: "Too long!",
      })
    ),
});
