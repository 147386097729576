import React, { useCallback, useEffect, useState } from "react";
import { FieldInputProps, useFormikContext } from "formik";
import { Grid, Input as InputUI, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: 14,
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  inputField: {
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  errorInput: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: 14,
    borderColor: theme.palette.common.cinnabar,
  },
}));

interface InputProps extends FieldInputProps<any> {
  defaultValue: string;
  disabled?: boolean;
  placeholder: string;
  type: string;
  resetField?: boolean;
  data?: boolean;
}

export function Input({
  name,
  defaultValue = "",
  disabled = false,
  placeholder,
  type,
  onChange,
  resetField,
  data,
}: InputProps) {
  const classes = useStyles();
  const { errors, values, initialValues, isSubmitting } = useFormikContext<{
    [key: string]: string;
  }>();

  const isTouched = isSubmitting || values[name] !== initialValues[name];

  const [fieldValue, setFieldValue] = useState<string>(
    !initialValues[name] ? "" : initialValues[name]
  );

  const handleChange = useCallback(
    (e) => {
      setFieldValue(e.target.value);
      onChange!({
        ...e,
        target: { name, value: e.target.value },
      });
    },
    [name, fieldValue]
  );

  return (
    <Grid key={name} container={true} item={true} direction="column">
      <InputUI
        classes={{
          root: `${
            isTouched && errors[name] ? classes.errorInput : classes.input
          }`,
          input: classes.inputField,
        }}
        value={data ? fieldValue.toLowerCase() : fieldValue}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        onChange={handleChange}
        type={type}
        disableUnderline={true}
        fullWidth={true}
      />
    </Grid>
  );
}
