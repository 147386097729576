import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Grid, CardMedia, Typography } from "@material-ui/core";

import loginImg from "../../../common/assets/images/login.jpg";
import { useAuth } from "../../../core/context/containers/Auth.container";
import { ROUTES } from "../../../common/constants/Routing";
import { AppLogo } from "../../../common/components";
import { Credentials } from "../../../types";
import { SignInForm } from "./components/SignInForm/SignInForm";
import { useStyles } from "./SignIn.styles";
import { useUserState } from "../../../core/context/containers/User.container";

export function SignIn() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const auth = useAuth();
  const { setUserEmail } = useUserState();

  const handleSubmit = useCallback(async (credentials: Credentials) => {
    await auth?.loginWithEmailAndPassword({
      username: credentials.username.toLowerCase(),
      password: credentials.password,
    });
    setUserEmail(credentials.username.toLowerCase());
    history.push(ROUTES.DASHBOARD);
  }, []);

  return (
    <Grid container={true} alignItems="center">
      <Grid className={classes.container} item={true} md={3} sm={12}>
        <AppLogo style={classes.logo} />
        <Typography className={classes.welcomeText} variant="inherit">
          {formatMessage({
            id: "signIn.welcomeText",
            defaultMessage: "Welcome to Join",
            description: "signin welcome message",
          })}
        </Typography>
        <Typography className={classes.catchline}>
          {formatMessage({
            id: "signIn.catchline",
            defaultMessage: "Sign in using your Join account.",
            description: "signin message",
          })}
        </Typography>
        <SignInForm onSubmit={handleSubmit} />
      </Grid>
      <Grid item={true} md={9} sm={12}>
        <CardMedia className={classes.img} image={loginImg} component="img" />
      </Grid>
    </Grid>
  );
}
