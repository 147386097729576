import { Input } from "@material-ui/core";
import * as Yup from "yup";

import { FormField } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";
import { SpaceLoad } from "../../../../common/components/Form/FormFields";
enum FIELD_NAMES {
  spaceLoadMediumMax = "spaceLoadMediumMax",
  spaceLoadMediumMin = "spaceLoadMediumMin",
  sensorConfigurations = "sensorConfigurations",
  ntopngDatabaseName = "ntopngDatabaseName",
  // minimalProbesCount = "minimalProbesCount",
}

export const SpaceLoadSettingsFields: FormField[] = [
  {
    name: FIELD_NAMES.sensorConfigurations,
    label: "",
    Component: SpaceLoad,
  },
  {
    name: FIELD_NAMES.ntopngDatabaseName,
    label: I18n.formatMessage({
      id: "editPropertyFormFields.label.ntopngDatabaseName",
      defaultMessage: "Ntopng database name",
    }),
    placeholder: I18n.formatMessage({
      id: "editPropertyFormFields.placeholder.ntopngDatabaseName",
      defaultMessage: "Ntopng database name",
    }),
    gridStyle: { sm: 8, md: 6 },
    Component: Input,
    title: "Data Type:",
  },
  // {
  //   name: FIELD_NAMES.minimalProbesCount,
  //   label: I18n.formatMessage({
  //     id: "editPropertyFormFields.label.minimalProbesCount",
  //     defaultMessage: "Minimal Probes Count",
  //   }),
  //   placeholder: I18n.formatMessage({
  //     id: "editPropertyFormFields.placeholder.minimalProbesCount",
  //     defaultMessage: "Type here",
  //   }),
  //   gridStyle: { sm: 8, md: 7 },
  //   Component: Input,
  //   title: "Sensed Devices:",
  // },
];

export const VALIDATION_RULES = Yup.object().shape({
  [FIELD_NAMES.spaceLoadMediumMin]: Yup.number()
    .min(
      1,
      I18n.formatMessage({
        id: "addPropertyFormFields.validation.spaceLoadMediumMin.zero",
        defaultMessage: "Low must be more than 0!",
      })
    )
    .lessThan(
      Yup.ref(FIELD_NAMES.spaceLoadMediumMax),
      "High must be more than Low!"
    )
    .required(" "),
  [FIELD_NAMES.spaceLoadMediumMax]: Yup.number()
    .moreThan(
      Yup.ref(FIELD_NAMES.spaceLoadMediumMin),
      "High must be more than Low!"
    )
    .required(" "),
  // [FIELD_NAMES.minimalProbesCount]: Yup.number().min(1).required(" "),
});
