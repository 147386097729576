import { gql } from "@apollo/client";

export const NOTIFICATION_MUTATIONS = {
  createNotificationRule: gql`
    mutation createNotificationRule(
      $active: Boolean
      $floorId: String!
      $propertyId: String!
      $deviceId: String
      $conditionType: NotificationConditionType
      $resourceType: NotificationResourceType
      $dataType: NotificationDataType
      $exceedsInMinutes: Int
    ) {
      createNotificationRule(
        request: {
          active: $active
          floorId: $floorId
          propertyId: $propertyId
          deviceId: $deviceId
          conditionType: $conditionType
          resourceType: $resourceType
          dataType: $dataType
          exceedsInMinutes: $exceedsInMinutes
        }
      ) {
        id
      }
    }
  `,
  deleteNotificationRule: gql`
    mutation deleteNotificationRule($notificationId: String!) {
      deleteNotificationRule(request: { notificationId: $notificationId }) {
        msg
      }
    }
  `,
};

export const NOTIFICATION_QUERIES = {
  allNotificationRules: gql`
    query notificationRules($propertyId: String!) {
      notificationRules(propertyId: $propertyId) {
        id
        active
        deviceName
        conditionType
        dataType
        exceedsInMinutes
        floor {
          floorNumber
          name
        }
      }
    }
  `,
  getAllNotifications: gql`
    query currentNotifications($email: String!) {
      currentNotifications(email: $email) {
        active
        conditionType
        dataType
        deviceName
        maxDwellTime
        floor {
          accessPointsRadius
          active
          capacity
          floorConfigured
          floorNumber
          floorPlanUploaded
          id
          name
          propertyId
          signalStrength
        }
        property {
          siteName
          id
        }
        id
        lastAvailableCondition
        notificationReceivedMinutesAgo
        resourceType
        triggerDevice
      }
    }
  `,
};
