import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  toolSwitch: {
    textTransform: "capitalize",
    color: theme.palette.common.mediumBlack,
    background: theme.palette.common.lightGray,
    border: 0,
    borderRadius: 8,
    marginRight: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
  },
  active: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.regularBlue,
    borderRadius: 8,
  },
  buttonContainer: {
    marginTop: "24px",
    marginLeft: "12px",
  },
}));
