import React, { useCallback, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useFloorState } from "../../../../core/context/containers/Floor.container";
import { useMutation, useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { Formik, Form as FormikForm } from "formik";
import { Button, Input, Grid } from "@material-ui/core";
import {
  ActivitySwitch,
  CustomModal,
  FormField,
} from "../../../../common/components";
import { useStyles } from "./FormStyles";
import { Floor, FormFieldInputType } from "../../../../types";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { ModalContent } from "./ModalContent";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import I18n from "../../../../core/services/I18n.service";

const FIELD_NAMES = {
  name: "name",
  floorNumber: "floorNumber",
  capacity: "capacity",
  active: "active",
  signalStrength: "signalStrength",
};

export function FloorSettings() {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { selectedFloor } = useFloorState();
  const { selectedProperty } = usePropertiesState();
  const [editFloor] = useMutation(GQLService.mutations.editFloor);
  const history = useHistory();
  const [showModal, toggleModal] = useState<boolean>(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [formDataState, setFormDataState] = useState<Floor | false>(false);
  const rejectChanges = useCallback(() => toggleModal(false), []);
  const [existingFloors, setExistingFloors] = useState<number[]>([]);
  const { loading: loadingExistingFloors, data: existingFloorNumbersData } =
    useQuery(GQLService.queries.existingFloorNumbers, {
      variables: { propertyId: selectedProperty?.id },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    !loadingExistingFloors &&
      setExistingFloors(
        existingFloorNumbersData?.floorsByPropertyId
          .map((a: { floorNumber: number }) => a.floorNumber)
          .filter((f: number) => f !== selectedFloor?.floorNumber)
      );
  }, [loadingExistingFloors, existingFloorNumbersData, selectedFloor]);

  const handleSubmit = useCallback(
    (formData) => {
      toggleModal(true);
      setFormDataState({ ...formData, floorId: selectedFloor?.id });
    },
    [formDataState, showModal] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      if (formDataState) {
        try {
          await editFloor({ variables: formDataState });
          enqueueSnackbar(
            formatMessage({
              id: "floors.editFloor.snackbar.success",
              defaultMessage: "Floor settings changed successfully",
            }),
            {
              variant: "success",
            }
          );
          history.push(ROUTES.FLOORS.replace(":uid", selectedProperty?.id!));
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          console.log(e);
        }
      }
    },
    [formDataState, showModal] // eslint-disable-line
  );

  return (
    selectedFloor && (
      <div>
        <Grid container={true} direction="row" alignItems="center">
          <Grid>
            <Formik
              onSubmit={handleSubmit}
              initialValues={selectedFloor || {}}
              validationSchema={editFloorSchema(existingFloors)}
            >
              {({ values, setFieldValue, errors }) => (
                <FormikForm className={classes.form}>
                  <Grid container={true} spacing={1}>
                    <FormField
                      key={FIELD_NAMES.floorNumber}
                      field={{
                        name: FIELD_NAMES.floorNumber,
                        label: I18n.formatMessage({
                          id: "floors.editFloor.form.label.floorNumber",
                          defaultMessage: "Floor Number",
                        }),
                        placeholder: I18n.formatMessage({
                          id: "floors.editFloor.form.placeholder.floorNumber",
                          defaultMessage: "Type here",
                        }),
                        Component: Input,
                        type: FormFieldInputType.string,
                      }}
                    />
                    <FormField
                      key={FIELD_NAMES.name}
                      field={{
                        name: FIELD_NAMES.name,
                        label: I18n.formatMessage({
                          id: "floors.editFloor.form.label.name",
                          defaultMessage: "Floor Name",
                        }),
                        placeholder: I18n.formatMessage({
                          id: "floors.editFloor.form.placeholder.name",
                          defaultMessage: "Type here",
                        }),
                        Component: Input,
                        type: FormFieldInputType.string,
                      }}
                    />
                    {/* <Grid item={true} sm={12} md={12}>
                      <FormField
                        key={FIELD_NAMES.capacity}
                        field={{
                          name: FIELD_NAMES.capacity,
                          label: I18n.formatMessage({
                            id: "floors.editFloor.form.label.capacity",
                            defaultMessage: "Floor Capacity",
                          }),
                          placeholder: I18n.formatMessage({
                            id: "floors.editFloor.form.placeholder.capacity",
                            defaultMessage: "Type here",
                          }),
                          required: true,
                          Component: Input,
                          type: FormFieldInputType.number,
                          gridStyle: { sm: 2, md: 2 },
                        }}
                      />
                    </Grid> */}

                    {/* <Grid
                      item={true}
                      sm={12}
                      md={12}
                      direction="row"
                      className={classes.flex}
                    >
                      <FormField
                        key={FIELD_NAMES.signalStrength}
                        field={{
                          name: FIELD_NAMES.signalStrength,
                          label: I18n.formatMessage({
                            id: "floors.editFloor.form.label.signalStrength",
                            defaultMessage: "Signal Strength",
                          }),
                          placeholder: I18n.formatMessage({
                            id:
                              "floors.editFloor.form.placeholder.signalStrength",
                            defaultMessage: "-75",
                          }),
                          required: true,
                          Component: Input,
                          type: FormFieldInputType.number,
                          gridStyle: { sm: 2, md: 2 },
                        }}
                      />
                      <Typography className={classes.alignBottom}>
                        {formatMessage({
                          id: "form.label.dBm",
                          defaultMessage: "dBm",
                          description: "dBm",
                        })}
                      </Typography>
                    </Grid> */}

                    <FormField
                      key={FIELD_NAMES.active}
                      field={{
                        name: FIELD_NAMES.active,
                        label: I18n.formatMessage({
                          id: "floors.editFloor.form.label.active",
                          defaultMessage: "Active Floor ",
                        }),
                        placeholder: I18n.formatMessage({
                          id: "floors.editFloor.form.placeholder.active",
                          defaultMessage: "Type here",
                        }),
                        Component: ActivitySwitch,
                        type: FormFieldInputType.number,
                      }}
                    />
                  </Grid>
                  <Button type="submit" className={classes.submitBtn}>
                    {formatMessage({
                      id: "form.button.save",
                      defaultMessage: "Save",
                      description: "form save button label",
                    })}
                  </Button>
                </FormikForm>
              )}
            </Formik>
            <CustomModal open={showModal}>
              <ModalContent yes={confirmChanges} no={rejectChanges} />
            </CustomModal>
          </Grid>
        </Grid>
      </div>
    )
  );
}

export const editFloorSchema = (existingFloors: number[]) =>
  Yup.object().shape({
    floorNumber: Yup.number()
      .typeError(
        I18n.formatMessage({
          id: "floors.editFloor.form.validation.floorNumber.numberOnly",
          defaultMessage: "You must specify a number",
        })
      )
      .notOneOf(
        existingFloors,
        I18n.formatMessage({
          id: "floors.editFloor.form.validation.floorNumber.alreadyExisting",
          defaultMessage: "Floor number already exists.",
        })
      ),
    name: Yup.string()
      .min(
        2,
        I18n.formatMessage({
          id: "floors.editFloor.form.validation.name.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "floors.editFloor.form.validation.name.long",
          defaultMessage: "Too long!",
        })
      )
      .nullable(),
    capacity: Yup.number()
      .min(
        0,
        I18n.formatMessage({
          id: "floors.editFloor.form.validation.name.short",
          defaultMessage: "Too small!",
        })
      )
      .required(" "),
    active: Yup.boolean(),
  });
