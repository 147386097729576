import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  notificationBell: {
    marginRight: "10px",
  },
  notificationDropDown: {
    marginTop: "40px",
    marginLeft: "-30px",
  },
  notificationList: {
    padding: "0px",
    minWidth: theme.typography.pxToRem(280),
    width: theme.typography.pxToRem(280),
  },
  notificationMessageStack: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  notificationListHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
  },
  notificationHeaderTitle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    color: "#455976",
  },
  notificationHeaderLink: {
    color: "#828282",
    fontSize: theme.typography.pxToRem(10),
    cursor: "pointer",
  },
  notificationTime: {
    fontSize: theme.typography.pxToRem(8),
    color: "#828282",
  },
  notificationIconAlert: {
    color: "#EEAA46",
  },
  notificationIconSuccess: {
    color: "#2B7A2F",
  },
  notificationIconFault: {
    color: "#B0001F",
  },
  notificationPrimaryText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    color: "#455976",
    margin: "0px",
  },
  notificationSecondaryText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    margin: "0px",
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(15),
    color: "#455976",
    whiteSpace: "pre-wrap",
    width: "100%",
  },
  customBadge: {
    height: theme.typography.pxToRem(16),
    width: theme.typography.pxToRem(16),
    minWidth: theme.typography.pxToRem(16),
    fontSize: theme.typography.pxToRem(8),
    borderRadius: theme.typography.pxToRem(16),
    padding: "0px",
    paddingTop: "1px",
  },
}));
