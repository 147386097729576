import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { useIntl } from "react-intl";

import { ReactComponent as PlanYes } from "../../assets/images/FloorPlanIndicator/PlanYes.svg";
import { ReactComponent as PlanNo } from "../../assets/images/FloorPlanIndicator/PlanNo.svg";
import { useStyles } from "./ImageUploadedIndicator.style";

interface ImageUplodedIndicatorProps {
  plan: boolean;
  configured: boolean;
}

export function ImageUploadedIndicator({
  plan,
  configured,
}: ImageUplodedIndicatorProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Grid className={classes.container}>
      <Grid
        container
        direction="row"
        classes={{ root: classes.planNoContainer }}
      >
        {!configured && (
          <Grid className={classes.noConfigWrapper}>
            <Typography classes={{ root: classes.noConfigMessage }}>
              {formatMessage({
                id:
                  "FormFields.ImageUploadedIndicator.FloorNotConfigured.label",
                defaultMessage: "Floor not configured",
                description:
                  "Form Fields Image Uploaded Indicator Floor not configured Label",
              })}
            </Typography>
            <ErrorIcon classes={{ root: classes.errorIcon }} />
          </Grid>
        )}
        {plan ? <PlanYes /> : <PlanNo />}
      </Grid>
    </Grid>
  );
}
