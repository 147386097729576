import React from "react";
import {
  ChannelSelect,
  Input,
  RadioSelect,
} from "../../../../common/components/Form/FormFields";
import * as Yup from "yup";

import { CreateFormWizardSteps } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";
import {
  ActivitySwitch,
  TextareaAutosize,
  SelectWithTags,
  Select,
} from "../../../../common/components/Form/FormFields";
import { FloorSelect } from "../../../../common/components/Form/FormFields/FloorSelect/FloorSelect.v2";
import { RadioBand } from "../../../../types";

const FIELD_NAMES = {
  name: "name",
  dataCenter: "dataCenter",
  property: "property",
  physicalLocation: "floorId",
  deviceType: "deviceType",
  active: "active",
  notes: "notes",
  baseMacAddress: "baseMacAddress",
  ipAddress: "ipAddress",
  modelNumber: "modelNumber",
  serialNumber: "serialNumber",
  radio1_macAddress: "radio1_macAddress",
  radio2_macAddress: "radio2_macAddress",
  radio1_band: "radio1_band",
  radio2_band: "radio2_band",
  radio1_channel: "radio1_channel",
  radio2_channel: "radio2_channel",
  radio1_channelWidth: "radio1_channelWidth",
  radio2_channelWidth: "radio2_channelWidth",
  radio1_txPower: "radio1_txPower",
  radio2_txPower: "radio2_txPower",
  radio1_ssids: "radio1_ssids",
  radio2_ssids: "radio2_ssids",
  inventoryNumber: "inventoryNumber",
};

export interface addAccessPointType {
  name: string;
  dataCenter: string;
  property: string;
  physicalLocation: string;
  deviceType: string;
  active: boolean;
  notes: string;
  baseMacAddress: string;
  ipAddress: string;
  modelNumber: string;
  serialNumber: string;
  inventoryNumber: number;
  radio1_macAddress: string;
  radio2_macAddress: string;
  radio1_band: number;
  radio2_band: number;
  radio1_channel: string;
  radio2_channel: string;
  radio1_channelWidth: string;
  radio2_channelWidth: string;
  radio1_txPower: number;
  radio2_txPower: number;
  radio1_ssids: string[];
  radio2_ssids: string[];
}

export const createAccessPointFormWizard: CreateFormWizardSteps = (data: {
  [name: string]: [];
}) => {
  return [
    {
      name: I18n.formatMessage({
        id: "addAccessPointWizard.label.MainInformations",
        defaultMessage: "Main informations",
      }),
      fieldset: [
        {
          label: I18n.formatMessage({
            id: "addAccessPointWizard.label.AccessPointInformations",
            defaultMessage: "Access Point Informations",
          }),
          fields: [
            {
              name: FIELD_NAMES.name,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.AccessPointName",
                defaultMessage: "Access Point Name",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.AccessPointName",
                defaultMessage: "Type here",
              }),
              required: true,
              Component: Input,
            },
            {
              name: FIELD_NAMES.dataCenter,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.DataCenter",
                defaultMessage: "Data Center",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.DataCenter",
                defaultMessage: "Type here",
              }),
              required: false,
              Component: Input,
            },
            {
              name: FIELD_NAMES.physicalLocation,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.PhysicalLocation",
                defaultMessage: "Physical Location",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.PhysicalLocation",
                defaultMessage: "Select Floor",
              }),
              required: true,
              Component: FloorSelect,
              data: data.floors,
            },
            {
              name: FIELD_NAMES.modelNumber,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.ModelNumber",
                defaultMessage: "Model Number",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.ModelNumber",
                defaultMessage: "Type here",
              }),
              required: false,
              gridStyle: { sm: 12, md: 6 },
              Component: Input,
            },
            {
              name: FIELD_NAMES.inventoryNumber,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.inventoryNumber",
                defaultMessage: "Inventory Number",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.inventoryNumber",
                defaultMessage: "Type here",
              }),
              required: false,
              gridStyle: { sm: 12, md: 6 },
              Component: Input,
            },
            {
              name: FIELD_NAMES.deviceType,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.SerialNumber",
                defaultMessage: "Serial Number",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.SerialNumber",
                defaultMessage: "Type here",
              }),
              required: false,
              gridStyle: { sm: 12, md: 6 },
              Component: Input,
            },
            {
              name: FIELD_NAMES.ipAddress,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.IPAddress",
                defaultMessage: "IP Address",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.IPAddress",
                defaultMessage: "Type here",
              }),
              required: false,
              gridStyle: { sm: 12, md: 6 },
              Component: Input,
            },
            {
              name: FIELD_NAMES.radio1_macAddress,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.Radio1MacAddress",
                defaultMessage: "RADIO 1 MAC Address",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.Radio1MacAddress",
                defaultMessage: "Type here",
              }),
              required: false,
              gridStyle: { sm: 12, md: 6 },
              Component: Input,
              data: true,
            },
            {
              name: FIELD_NAMES.radio1_band,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.Radio1Band",
                defaultMessage: "Radio 1 Band",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.Radio1Band",
                defaultMessage: "None",
              }),
              required: false,
              gridStyle: { sm: 12, md: 6 },
              data: data.radio,
              Component: RadioSelect,
            },
            {
              name: FIELD_NAMES.radio2_macAddress,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.Radio2MacAddress",
                defaultMessage: "RADIO 2 MAC Address",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.Radio2MacAddress",
                defaultMessage: "Type here",
              }),
              required: false,
              gridStyle: { sm: 12, md: 6 },
              Component: Input,
              data: true,
            },
            {
              name: FIELD_NAMES.radio2_band,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.Radio2Band",
                defaultMessage: "Radio 2 Band",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.Radio2Band",
                defaultMessage: "None",
              }),
              required: false,
              gridStyle: { sm: 12, md: 6 },
              data: data.radio,
              Component: RadioSelect,
            },
            {
              name: FIELD_NAMES.baseMacAddress,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.BaseMacAddress",
                defaultMessage: "Base MAC Address",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.BaseMacAddress",
                defaultMessage: "Type here",
              }),
              required: true,
              gridStyle: { sm: 12, md: 6 },
              Component: Input,
              data: true,
            },
            {
              name: FIELD_NAMES.active,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.Active",
                defaultMessage: "Active",
              }),
              required: false,
              Component: ActivitySwitch,
            },
            {
              name: FIELD_NAMES.notes,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.Notes",
                defaultMessage: "Notes",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.Notes",
                defaultMessage: "Type here",
              }),
              required: false,
              Component: TextareaAutosize,
            },
          ],
          validationSchema: accessPointInformationsValidationSchema(),
          defaultValues: {
            [FIELD_NAMES.active]: false,
            [FIELD_NAMES.radio1_band]: RadioBand.NONE,
            [FIELD_NAMES.radio2_band]: RadioBand.NONE,
          },
          style: { spacing: 1 },
        },
      ],
    },
    // {
    //   name: I18n.formatMessage({
    //     id: "addAccessPointWizard.label.RadioConfiguration",
    //     defaultMessage: "Radio Configuration",
    //   }),
    //   fieldset: [
    //     {
    //       label: I18n.formatMessage({
    //         id: "addAccessPointWizard.label.Radio1Configuration",
    //         defaultMessage: "RADIO 1 CONFIGURATION",
    //       }),
    //       fields: [
    //         {
    //           name: FIELD_NAMES.radio1_channel,
    //           label: I18n.formatMessage({
    //             id: "addAccessPointWizard.label.Radio1Channel",
    //             defaultMessage: "Channel",
    //           }),
    //           placeholder: I18n.formatMessage({
    //             id: "addAccessPointWizard.placeholder.Radio1Channel",
    //             defaultMessage: "Select",
    //           }),
    //           required: false,
    //           Component: ChannelSelect,
    //           data: data.channel,
    //         },
    //         {
    //           name: FIELD_NAMES.radio1_channelWidth,
    //           label: I18n.formatMessage({
    //             id: "addAccessPointWizard.label.Radio1ChannelWidth",
    //             defaultMessage: "Channel Width",
    //           }),
    //           placeholder: I18n.formatMessage({
    //             id: "addAccessPointWizard.placeholder.Radio1ChannelWidth",
    //             defaultMessage: "Select",
    //           }),
    //           required: false,
    //           Component: ChannelSelect,
    //           data: data.channelWidth,
    //         },
    //         {
    //           name: FIELD_NAMES.radio1_txPower,
    //           label: I18n.formatMessage({
    //             id: "addAccessPointWizard.label.Radio1txPower",
    //             defaultMessage: "Tx Power",
    //           }),
    //           placeholder: I18n.formatMessage({
    //             id: "addAccessPointWizard.placeholder.Radio1txPower",
    //             defaultMessage: "100",
    //           }),
    //           required: false,
    //           Component: Input,
    //         },
    //       ],
    //       validationSchema: radioConfigurationValidationSchema(),
    //     },
    //     {
    //       label: I18n.formatMessage({
    //         id: "addAccessPointWizard.label.Radio2Configuration",
    //         defaultMessage: "RADIO 2 CONFIGURATION",
    //       }),
    //       fields: [
    //         {
    //           name: FIELD_NAMES.radio2_channel,
    //           label: I18n.formatMessage({
    //             id: "addAccessPointWizard.label.Radio2Channel",
    //             defaultMessage: "Channel",
    //           }),
    //           placeholder: I18n.formatMessage({
    //             id: "addAccessPointWizard.placeholder.Radio2Channel",
    //             defaultMessage: "Select",
    //           }),
    //           required: false,
    //           Component: ChannelSelect,
    //           data: data.channel,
    //         },
    //         {
    //           name: FIELD_NAMES.radio2_channelWidth,
    //           label: I18n.formatMessage({
    //             id: "addAccessPointWizard.label.Radio2ChannelWidth",
    //             defaultMessage: "Channel Width",
    //           }),
    //           placeholder: I18n.formatMessage({
    //             id: "addAccessPointWizard.placeholder.Radio2ChannelWidth",
    //             defaultMessage: "Select",
    //           }),
    //           required: false,
    //           Component: ChannelSelect,
    //           data: data.channelWidth,
    //         },
    //         {
    //           name: FIELD_NAMES.radio2_txPower,
    //           label: I18n.formatMessage({
    //             id: "addAccessPointWizard.label.Radio2txPower",
    //             defaultMessage: "Tx Power",
    //           }),
    //           placeholder: I18n.formatMessage({
    //             id: "addAccessPointWizard.placeholder.Radio2txPower",
    //             defaultMessage: "100",
    //           }),
    //           required: false,
    //           Component: Input,
    //         },
    //       ],
    //       validationSchema: radioConfigurationValidationSchema(),
    //     },
    //   ],
    // },
    {
      name: I18n.formatMessage({
        id: "addAccessPointWizard.label.AddSSIDs",
        defaultMessage: "Add SSIDs",
      }),
      fieldset: [
        {
          label: I18n.formatMessage({
            id: "addAccessPointWizard.label.AddSSIDs.Radio1",
            defaultMessage: "Add SSIDs for RADIO 1",
          }),
          fields: [
            {
              name: FIELD_NAMES.radio1_ssids,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.SSIDs.Radio1",
                defaultMessage: "SSIDs",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.SSIDs.Radio1",
                defaultMessage: "Choose from list",
              }),
              required: false,
              gridStyle: { sm: 12, md: 12 },
              Component: SelectWithTags,
              data: data.ssids,
              customProps: {
                title: I18n.formatMessage({
                  id: "addAccessPointWizard.customProps.SSIDs.Radio1.title",
                  defaultMessage: "SSIDs Added to this Access Point:",
                }),
                noTagsMessage: I18n.formatMessage({
                  id: "addAccessPointWizard.customProps.SSIDs.Radio1.noTags",
                  defaultMessage:
                    "There is no SSID related with this Access Point on this Radio. Please add a new one",
                }),
              },
            },
          ],
          validationSchema: addSSID1ValidationSchema(),
        },
        {
          label: I18n.formatMessage({
            id: "addAccessPointWizard.label.AddSSIDs.Radio2",
            defaultMessage: "Add SSIDs for RADIO 2",
          }),
          fields: [
            {
              name: FIELD_NAMES.radio2_ssids,
              label: I18n.formatMessage({
                id: "addAccessPointWizard.label.SSIDs.Radio2",
                defaultMessage: "SSIDs",
              }),
              placeholder: I18n.formatMessage({
                id: "addAccessPointWizard.placeholder.SSIDs.Radio2",
                defaultMessage: "Choose from list",
              }),
              required: false,
              gridStyle: { sm: 12, md: 12 },
              Component: SelectWithTags,
              data: data.ssids,
            },
          ],
          validationSchema: addSSID2ValidationSchema(),
        },
      ],
    },
  ];
};

function accessPointInformationsValidationSchema() {
  return Yup.object().shape({
    [FIELD_NAMES.name]: Yup.string()
      .min(
        3,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.name.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.name.long",
          defaultMessage: "Too long!",
        })
      )
      .required(" "),
    [FIELD_NAMES.dataCenter]: Yup.string()
      .min(
        3,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.dataCenter.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        50,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.dataCenter.long",
          defaultMessage: "Too long!",
        })
      )
      .nullable(),
    [FIELD_NAMES.physicalLocation]: Yup.string()
      .max(
        50,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.physicalLocation.long",
          defaultMessage: "Too long!",
        })
      )
      .required(" "),
    [FIELD_NAMES.deviceType]: Yup.string().nullable(),
    [FIELD_NAMES.active]: Yup.boolean().nullable(),
    [FIELD_NAMES.notes]: Yup.string()
      .min(
        6,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.notes.short",
          defaultMessage: "Too short!",
        })
      )
      .max(
        140,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.notes.long",
          defaultMessage: "Too long!",
        })
      )
      .nullable(),
    [FIELD_NAMES.ipAddress]: Yup.string()
      .max(
        50,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.ipAddress.long",
          defaultMessage: "Too long!",
        })
      )
      .nullable(),
    [FIELD_NAMES.radio1_macAddress]: Yup.string()
      .max(
        50,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.radio1MacAddress.long",
          defaultMessage: "Too long!",
        })
      )
      .nullable(),
    [FIELD_NAMES.radio2_macAddress]: Yup.string()
      .max(
        50,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.radio2MacAddress.long",
          defaultMessage: "Too long!",
        })
      )
      .nullable(),
    [FIELD_NAMES.serialNumber]: Yup.string()
      .max(
        50,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.serialNumber.long",
          defaultMessage: "Too long!",
        })
      )
      .nullable(),
    [FIELD_NAMES.inventoryNumber]: Yup.number()
      .typeError("You must specify a number")
      .nullable(),
    [FIELD_NAMES.baseMacAddress]: Yup.string()
      .max(
        50,
        I18n.formatMessage({
          id: "addAccessPointFormFields.validation.baseMACaddress.long",
          defaultMessage: "Too long!",
        })
      )
      .required(" "),
  });
}

function radioConfigurationValidationSchema() {
  return Yup.object().shape({
    [FIELD_NAMES.radio1_txPower]: Yup.number(),
    [FIELD_NAMES.radio2_txPower]: Yup.number(),
  });
}

function addSSID1ValidationSchema() {
  return Yup.object().shape({
    [FIELD_NAMES.radio1_ssids]: Yup.array(),
  });
}

function addSSID2ValidationSchema() {
  return Yup.object().shape({
    [FIELD_NAMES.radio2_ssids]: Yup.array(),
  });
}
