import React, { useCallback, useState } from "react";
import { useFormikContext, FieldInputProps, FormikState } from "formik";
import { Grid, Select as SelectUI } from "@material-ui/core";
import { useStyles } from "./RadioSelect.styles";
import { MenuItem } from "@material-ui/core";
import { ISelectData } from "../../../../../types";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { RadioBand, radioBandMap } from "../../../../../types";

interface SelectProps extends FieldInputProps<any> {
  defaultValue: boolean;
  disabled?: boolean;
  data?: ISelectData;
  placeholder: string;
}

const radioNames = ["radio1_band", "radio2_band"];

export function RadioSelect({
  name,
  defaultValue,
  disabled = false,
  onChange,
  placeholder,
}: SelectProps) {
  const classes = useStyles();
  const { values } = useFormikContext<FormikState<{ [field: string]: any }>>();
  const [selectValue, setSelectValue] = useState(
    values[name as keyof typeof values] || defaultValue || placeholder
  );
  let secondRadioValue: string =
    values[radioNames.find((n) => n !== name) as keyof typeof values];
  secondRadioValue =
    secondRadioValue === RadioBand.NONE ? "" : secondRadioValue;

  const handleSelectOptClick = useCallback(
    (e) => {
      setSelectValue(e.target.value);
      onChange({
        ...e,
        target: {
          name,
          value: e.target.value,
        },
      });
    },
    [selectValue] // eslint-disable-line
  );

  return (
    <Grid key={name} container={true} item={true} direction="column">
      <SelectUI
        classes={{
          root: classes.select,
          select: classes.blackText,
          icon: classes.icon,
        }}
        MenuProps={{
          classes: {
            paper: classes.menu,
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        disabled={disabled}
        IconComponent={ExpandMoreSharpIcon}
        value={selectValue}
        disableUnderline={true}
        onChange={handleSelectOptClick}
        name={name}
        renderValue={
          selectValue !== placeholder
            ? undefined
            : () => <Placeholder>{placeholder}</Placeholder>
        }
      >
        {Array.from(radioBandMap.keys()).map((key, i) => {
          return (
            <MenuItem
              key={i + 1}
              value={key}
              disabled={key === secondRadioValue}
              classes={{
                root: classes.option,
                selected: classes.selected,
              }}
            >
              {radioBandMap.get(key)}
            </MenuItem>
          );
        })}
      </SelectUI>
    </Grid>
  );
}

type PlaceholderProps = {
  children: React.ReactNode;
};

function Placeholder({ children }: PlaceholderProps) {
  const classes = useStyles();
  return <div className={classes.placeholder}>{children}</div>;
}
