import { APP_DEFAULTS } from "../../../common/constants/App";
import { parseImageResponse } from "../../../common/utils/Http.utils";
import { ImageResponseHandled } from "../../../types/http";
import API from "../../api/api";

const TripWireRestServiceCreate = () => {
    const uploadTripWire = async (image: FormData, propertyId: string, cameraId: string, tripWireId: string) => {
        const response = await API.post(
            `${APP_DEFAULTS.baseApiUrl}/properties/${propertyId}/camera/${cameraId}/tripWire/${tripWireId}/image`,
            image
        );
        return response;
    };

    const removeTripWire = async (propertyId: string, cameraId: string, tripWireId: string): Promise<void> => {
        await API.delete(
            `${APP_DEFAULTS.baseApiUrl}/properties/${propertyId}/camera/${cameraId}/tripWire/${tripWireId}/image`
        );
        return;
    };

    const getTripWire = async (propertyId: string, cameraId: string, tripWireId: string): Promise<ImageResponseHandled> => {
        try {
            const response = await API.get(`/properties/${propertyId}/camera/${cameraId}/tripWire/${tripWireId}/image`, {
                responseType: "blob",
            });
            return await parseImageResponse(response);
        } catch (err) {
            return {
                exists: false,
                source: undefined,
                status: 404,
            };
        }
    };

    return {
        uploadTripWire,
        removeTripWire,
        getTripWire,
    };
};

export const TripWireRestService = TripWireRestServiceCreate();
