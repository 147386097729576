import { Input } from "@material-ui/core";
import * as Yup from "yup";

import { FormField } from "../../../../types/form";
import I18n from "../../../../core/services/I18n.service";
import {
  ActivitySwitch,
  Header,
  HourSelect,
  HourSelectTZ,
  PropertyDateFormatSelect,
} from "../../../../common/components/Form/FormFields/index";
import { FormFieldInputType } from "../../../../types/form";
import { SkipFloorCheckbox } from "../../../../common/components/Form/FormFields/SkipFloorCheckbox/SkipFloorCheckbox";

enum FIELD_NAMES {
  siteName = "siteName",
  siteNrOfUndergroundFloors = "siteNrOfUndergroundFloors",
  floor0Excluded = "floor0Excluded",
  siteNrOfFloors = "siteNrOfFloors",
  propertyType = "sitePropertyType",
  siteServiceAddress = "siteServiceAddress",
  accountBusinessEntity = "accountBusinessEntity",
  siteInformationHeader = "siteInformationHeader",
  accountInformationHeader = "accountInformationHeader",
  upNext = "upNext",
  fullName = "primaryContactFullName",
  primaryContactEmailAddress = "primaryContactEmailAddress",
  phone = "primaryContactPhoneNumber",
  companyInformation = "companyInformation",
  primaryContant = "primaryContact",
  companyWebsite = "companyWebsite",
  workingFrom = "workingFrom",
  workingTo = "workingTo",
  timeZone = "timeZone",
  propertyDateFormat = "propertyDateFormat",
  use24HourClock = "use24HourClock",
  primaryContactEmailHeader = "primaryContactEmailHeader",
}

export const createLocationDetailsFields = (data: any): FormField[] => [
  {
    name: FIELD_NAMES.siteInformationHeader,
    label: I18n.formatMessage({
      id: "editPropertyFormFields.label.accountInformationHeader",
      defaultMessage: " ",
    }),
    placeholder: I18n.formatMessage({
      id: "editPropertyFormFields.placeholder.accountInformationHeader",
      defaultMessage: "Site Information",
    }),
    required: false,
    Component: Header,
  },
  {
    name: FIELD_NAMES.siteName,
    label: I18n.formatMessage({
      id: "editPropertyFormFields.label.siteName",
      defaultMessage: "Site name",
    }),
    placeholder: I18n.formatMessage({
      id: "editPropertyFormFields.placeholder.siteName",
      defaultMessage: "Type here",
    }),
    required: true,
    Component: Input,
  },
  {
    name: FIELD_NAMES.propertyType,
    label: I18n.formatMessage({
      id: "editPropertyFormFields.label.propertyType",
      defaultMessage: "Property Type",
    }),
    placeholder: I18n.formatMessage({
      id: "editPropertyFormFields.placeholder.propertyType",
      defaultMessage: "Type here",
    }),
    required: true,
    Component: Input,
  },
  {
    name: FIELD_NAMES.siteServiceAddress,
    label: I18n.formatMessage({
      id: "editPropertyFormFields.label.siteServiceAddress",
      defaultMessage: "Site Service Address",
    }),
    placeholder: I18n.formatMessage({
      id: "editPropertyFormFields.placeholder.siteServiceAddress",
      defaultMessage: "Type here",
    }),
    required: false,
    Component: Input,
  },
  {
    name: FIELD_NAMES.siteNrOfFloors,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.numberOfFloors",
      defaultMessage: "Number of floors above ground level",
    }),
    placeholder: I18n.formatMessage({
      id: "addPropertyFormFields.placeholder.numberOfFloors",
      defaultMessage: "Type here",
    }),
    required: true,
    type: FormFieldInputType.number,
    gridStyle: { sm: 12, md: 5 },
    Component: Input,
    fullWidth: false,
  },
  {
    name: FIELD_NAMES.floor0Excluded,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.numberOfFloors",
      defaultMessage: " ",
    }),
    placeholder: I18n.formatMessage({
      id: "addPropertyFormFields.placeholder.numberOfFloors",
      defaultMessage: " ",
    }),
    required: false,
    gridStyle: { sm: 12, md: 12 },
    Component: SkipFloorCheckbox,
    fullWidth: false,
  },
  {
    name: FIELD_NAMES.siteNrOfUndergroundFloors,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.numberOfFloors",
      defaultMessage: "Number of underground floors",
    }),
    placeholder: I18n.formatMessage({
      id: "addPropertyFormFields.placeholder.numberOfFloors",
      defaultMessage: "Type here",
    }),
    type: FormFieldInputType.number,
    gridStyle: { sm: 12, md: 5 },
    Component: Input,
    fullWidth: false,
  },
  {
    name: FIELD_NAMES.workingFrom,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.working",
      defaultMessage: "Working Hours",
    }),
    placeholder: I18n.formatMessage({
      id: "addPropertyFormFields.placeholder.website",
      defaultMessage: "From",
    }),
    required: true,
    gridStyle: { sm: 12, md: 12 },
    // Component: HourSelect,
    Component: HourSelectTZ,
    data: data.property,
  },
  {
    name: FIELD_NAMES.propertyDateFormat,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.propertyDateFormat",
      defaultMessage: "Property Date Format",
    }),
    placeholder: I18n.formatMessage({
      id: "addPropertyFormFields.placeholder.propertyDateFormat",
      defaultMessage: "None",
    }),
    required: true,
    gridStyle: { sm: 12, md: 8 },
    Component: PropertyDateFormatSelect,
  },
  {
    name: FIELD_NAMES.use24HourClock,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.use24HourClock",
      defaultMessage: "Use 24 Hour Clock",
    }),
    placeholder: I18n.formatMessage({
      id: "addPropertyFormFields.placeholder.use24HourClock",
      defaultMessage: "None",
    }),
    required: true,
    Component: ActivitySwitch,
  },
  // {
  //   name: FIELD_NAMES.accountInformationHeader,
  //   label: I18n.formatMessage({
  //     id: "editPropertyFormFields.label.accountInformationHeader",
  //     defaultMessage: " ",
  //   }),
  //   placeholder: I18n.formatMessage({
  //     id: "editPropertyFormFields.placeholder.accountInformationHeader",
  //     defaultMessage: "Account Information",
  //   }),
  //   required: false,
  //   Component: Header,
  // },
  // {
  //   name: FIELD_NAMES.accountBusinessEntity,
  //   label: I18n.formatMessage({
  //     id: "editPropertyFormFields.label.businessEntity",
  //     defaultMessage: "Business Entity",
  //   }),
  //   placeholder: I18n.formatMessage({
  //     id: "editPropertyFormFields.placeholder.businessEntity",
  //     defaultMessage: "Type here",
  //   }),
  //   required: false,
  //   Component: Input,
  // },
  {
    name: FIELD_NAMES.upNext,
    label: I18n.formatMessage({
      id: "addPropertyFormFields.label.companyStatus",
      defaultMessage: "UpNext User",
    }),
    required: false,
    Component: ActivitySwitch,
  },
  {
    name: FIELD_NAMES.primaryContactEmailHeader,
    label: I18n.formatMessage({
      id: "editPropertyFormFields.label.primaryContactEmailHeader",
      defaultMessage: " ",
    }),
    placeholder: I18n.formatMessage({
      id: "editPropertyFormFields.placeholder.primaryContactEmailHeader",
      defaultMessage: "Primary Contact",
    }),
    required: false,
    Component: Header,
  },
  {
    name: FIELD_NAMES.fullName,
    label: I18n.formatMessage({
      id: "editPropertyFormFields.label.fullName",
      defaultMessage: "Full Name",
    }),
    placeholder: I18n.formatMessage({
      id: "editPropertyFormFields.placeholder.fullName",
      defaultMessage: "Type here",
    }),
    required: false,
    Component: Input,
  },
  {
    name: FIELD_NAMES.primaryContactEmailAddress,
    label: I18n.formatMessage({
      id: "editPropertyFormFields.label.primaryContactEmailAddress",
      defaultMessage: "Email Address",
    }),
    placeholder: I18n.formatMessage({
      id: "editPropertyFormFields.placeholder.primaryContactEmailAddress",
      defaultMessage: "Type here",
    }),
    required: false,
    Component: Input,
  },
  {
    name: FIELD_NAMES.phone,
    label: I18n.formatMessage({
      id: "editPropertyFormFields.label.phone",
      defaultMessage: "Phone Number",
    }),
    placeholder: I18n.formatMessage({
      id: "editPropertyFormFields.placeholder.phone",
      defaultMessage: "Type here",
    }),
    required: false,
    Component: Input,
  },
];

export const VALIDATION_RULES = Yup.object().shape({
  [FIELD_NAMES.siteName]: Yup.string()
    .min(
      6,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.siteName.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.siteName.long",
        defaultMessage: "Too long!",
      })
    )
    .required(" "),
  [FIELD_NAMES.siteServiceAddress]: Yup.string()
    .min(
      6,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.serviceAddress.short",
        defaultMessage: "Too short!",
      })
    )
    .max(
      50,
      I18n.formatMessage({
        id: "editPropertyFormFields.validation.serviceAddress.long",
        defaultMessage: "Too long!",
      })
    ),
  [FIELD_NAMES.propertyType]: Yup.string().required(" "),
});
