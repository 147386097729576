import React, { useCallback, useEffect, useRef } from "react";
import { useMemo } from "react";
import { useState } from "react";
import {
  Circle,
  Group,
  Rect,
  Shape,
  Text,
  Transformer,
  Image,
} from "react-konva";
import { colorSpaceLoad } from "../../../common/utils/Canvas.utils";
import {
  AccessPoint,
  SpaceLoad,
  TripWire,
  Zone,
  ZoneWithOccupancy,
  ImageSensorWithOccupancy,
  TripWireWithOccupancy,
  Door,
  Space,
  airQuality,
} from "../../../types";
import {
  SensorDetailsAirQualityForFloor,
  AccessPointWithSpaceLoad,
  AccessPointWithSpaceLoadTimeline,
} from "../../../types";
import useImage from "use-image";
import url from "../../../common/assets/images/Upward.svg";
import downUrl from "../../../common/assets/images/Downward.svg";
import bothUrl from "../../../common/assets/images/InOut.svg";
import doorUrl from "../../../common/assets/images/Doors.svg";

const NewShapeColor = "#8cb85a";

const offlineTimeFormat = (time: any) => {
  if (time) {
    if (time >= 43200) {
      return `More than ${Math.floor(time / 24 / 60) + " days "}`;
    } else if (time > 1400) {
      return `Since ${
        Math.floor(time / 24 / 60) +
        " days " +
        Math.floor((time / 60) % 24) +
        " hrs "
      }`;
    } else if (time > 60) {
      return `Since ${
        Math.floor((time / 60) % 24) + " hrs " + Math.floor(time % 60) + " mins"
      }`;
    } else {
      return `Since ${Math.floor(time % 60) + " mins"}`;
    }
  }
};

export function AccessPointShape({
  coordinateX = 0,
  coordinateY = 0,
  accessPoint,
  spaceLoad,
  radius,
}: {
  coordinateX?: number;
  coordinateY?: number;
  accessPoint?: AccessPoint;
  spaceLoad?: SpaceLoad;
  radius?: number;
}) {
  const spaceLoadColor = useMemo(() => spaceLoad || "NO_DATA", [spaceLoad]);

  return coordinateX && coordinateY ? (
    <Group x={coordinateX} y={coordinateY} offsetX={5} offsetY={5}>
      {radius ? (
        <Circle
          radius={radius * 3}
          fill={
            accessPoint ? colorSpaceLoad(spaceLoadColor)?.shadow : undefined
          }
          dash={[10, 10]}
          stroke={
            accessPoint ? colorSpaceLoad(spaceLoadColor)?.main : NewShapeColor
          }
        />
      ) : null}
    </Group>
  ) : null;
}

export function AccessPointSolidShape({
  coordinateX = 0,
  coordinateY = 0,
  accessPoint,
  spaceLoad,
  clickable,
  toggleActive,
}: // toggleActiveT,
{
  coordinateX?: number;
  coordinateY?: number;
  accessPoint?: AccessPoint;
  spaceLoad?: SpaceLoad;
  radius?: number;
  clickable?: boolean;
  toggleActive?: (el: string) => void;
  // toggleActiveT?: (el: string) => void;
}) {
  const spaceLoadColor = useMemo(() => spaceLoad || "NO_DATA", [spaceLoad]);

  const handleAPClick = () => {
    if (clickable) {
      // toggleActiveT
      // ? toggleActiveT!(accessPoint?.id!)
      // :
      toggleActive!(accessPoint?.id!);
    }
  };

  return coordinateX && coordinateY ? (
    <Group x={coordinateX} y={coordinateY} offsetX={5} offsetY={5}>
      {/* if sensor is null then we are adding a new sensor   Below is for solid circle*/}
      <Circle
        onClick={() => handleAPClick()}
        fill={accessPoint ? colorSpaceLoad(spaceLoadColor).main : NewShapeColor}
        radius={10}
      />
    </Group>
  ) : null;
}

export function ActiveAccessPoint({
  accessPoint,
}: {
  accessPoint: AccessPoint;
}) {
  const shapeRef = useRef<any>(null);
  const [shapePr, setShapePr] = useState<{
    x: number;
    y: number;
    height: number;
  }>();
  const x = useMemo(
    () =>
      accessPoint?.coordinateX === "null" ? null : accessPoint?.coordinateX,
    [accessPoint?.coordinateX]
  );
  const y = useMemo(
    () =>
      accessPoint?.coordinateY === "null" ? null : accessPoint?.coordinateY,
    [accessPoint?.coordinateY]
  );

  const ssids = useMemo(() => {
    const SSIDs = [
      ...new Set(
        [accessPoint?.radio1, accessPoint?.radio2]
          .reduce((acc, val) => acc.concat(val?.ssids as any), [])
          .map((el: any) => el?.name)
      ),
    ];

    return SSIDs;
  }, [accessPoint?.radio1, accessPoint?.radio2]);

  useEffect(() => {
    if (x && y) {
      const tooltipX = +x - 50;
      const tooltipY = +y;

      if (shapeRef.current) {
        const height = shapeRef.current?.getStage().height();
        const width = shapeRef.current?.getStage().width();

        const shapeHeight = 30 + ssids.length * 30 + 10;
        const overHeight = tooltipY + shapeHeight > height;
        const overWidth = tooltipX + 160 > width || tooltipX - 160 < 0;
        let startY = tooltipY;
        let startX = tooltipX;
        if (overHeight) {
          startY = +y; // - shapeHeight - 15; commented for future use
        }
        if (overWidth) {
          // WIDHT OF LAYOUT = 900
          // if tooltipX + widhtOfToolTip > 900 ?
          // tooltipX -= toolTipX - width -> get the side inside the boxSizing else
          // set it to start
          startX = tooltipX;
          startX -= width - startX;
          startX = startX < 0 ? 0 : startX;
        }
        setShapePr({ x: startX, y: startY, height: shapeHeight });
      }
    }
  }, [shapeRef, ssids, x, y]);

  if (x && y) {
    return (
      <Group
        ref={shapeRef}
        x={shapePr?.x}
        y={shapePr?.y}
        fontFamily={"Poppins"}
        id={accessPoint?.id}
      >
        <Rect
          x={0}
          y={0}
          width={accessPoint?.offlineFromInMinutes ? 200 : 180}
          height={accessPoint?.offlineFromInMinutes ? 70 : 40}
          fill={"#E6F8FA"}
        ></Rect>
        <Text
          fontSize={14}
          fontStyle={"bold"}
          align={"center"}
          text={accessPoint.name}
          width={180}
          y={8}
          wrap={"char"}
          ellipsis={true}
        />
        {accessPoint.offlineFromInMinutes > 0 && (
          <Text
            fontSize={14}
            fontStyle={"normal"}
            align={"center"}
            text={`Offline: ${
              accessPoint?.offlineFromInMinutes
                ? offlineTimeFormat(accessPoint?.offlineFromInMinutes)
                : ""
            }`}
            y={40}
            width={200}
            wrap={"none"}
            ellipsis={true}
          />
        )}
      </Group>
    );
  } else {
    return <Group></Group>;
  }
}

export function AirQualitySensor({
  coordinateX = 0,
  coordinateY = 0,
  sensor,
  toggleActive,
  clickable,
}: {
  coordinateX?: number;
  coordinateY?: number;
  sensor?: SensorDetailsAirQualityForFloor;
  toggleActive?: (el: string) => void;
  clickable?: boolean;
}) {
  const value = useMemo(
    () =>
      sensor?.details?.score ? Math.round(sensor.details.score).toFixed() : 0,
    [sensor]
  ).toString();

  const spaceLoadColor = useMemo(
    () => sensor?.details?.airQuality || "NO_DATA",
    [sensor]
  );

  return (
    <Group
      x={coordinateX}
      y={coordinateY}
      onClick={() => (clickable ? toggleActive!(sensor?.airSensor?.id!) : {})}
    >
      {/* if sensor is null then we are adding a new sensor  */}
      <Circle
        // fill={sensor ? colorSpaceLoad(spaceLoadColor).main : NewShapeColor}
        fill={
          sensor && sensor?.details.offlineFromInMinutes > 0
            ? "#B2B2B2"
            : colorSpaceLoad(spaceLoadColor).main
        }
        radius={sensor ? 20 : 10}
      />
      {value && (
        <Text
          fill={"white"}
          offsetX={12}
          offsetY={7}
          // text={value || "-"}
          text={sensor?.details.offlineFromInMinutes! > 0 ? "" : value}
          align={"center"}
          fontFamily={"Poppins"}
          fontSize={20}
        />
      )}
    </Group>
  );
}

const WELLNESS_INDEX_TOOLTIP_MAX_WIDTH = 200;
const WELLNESS_INDEX_TOOLTIP_MIN_WIDTH = 160;

const WELLNESS_INDEX_TOOLTIP_MAX_HEIGHT = 210;
const WELLNESS_INDEX_TOOLTIP_MIN_HEIGHT = 70;
const TOOL_TIP_TEXT_PADDING = 15;

export function ActiveAirQualitySensorDetails({
  sensor,
  temperatureUnit,
}: {
  sensor: SensorDetailsAirQualityForFloor;
  temperatureUnit: any;
}) {
  const details = sensor.details;
  const name = sensor.airSensor.name;
  const isNoData =
    sensor?.details?.airQuality === airQuality.NO_DATA ? true : false;
  const shapeRef = useRef<any>(null);

  const [shapePr, setShapePr] = useState<{ x: number; y: number }>();
  useEffect(() => {
    const tooltipX = sensor.airSensor.coordinateX;
    const tooltipY = sensor.airSensor.coordinateY;
    let currToolTipWidth =
      isNoData || sensor.details?.offlineFromInMinutes
        ? WELLNESS_INDEX_TOOLTIP_MAX_WIDTH
        : WELLNESS_INDEX_TOOLTIP_MIN_WIDTH;

    if (shapeRef.current) {
      const height = shapeRef.current?.getStage().height();
      const width = shapeRef.current?.getStage().width();
      const overHeight = tooltipY + 30 + 6 * 30 + 15 > height;
      const overWidth =
        tooltipX + currToolTipWidth > width || tooltipX - currToolTipWidth < 0;
      let startY = tooltipY;
      let startX = tooltipX;
      if (overHeight) {
        startY = tooltipY - (30 + 6 * 30 + 15 + 5);
      }
      if (overWidth) {
        startX = tooltipX;
        startX -= width - startX;
        startX = startX < 0 ? 0 + TOOL_TIP_TEXT_PADDING : startX - width;
      }
      setShapePr({ x: startX, y: startY });
    }
  }, [shapeRef, sensor.airSensor.coordinateX, sensor.airSensor.coordinateY]);

  return (
    <Group
      ref={shapeRef}
      x={shapePr?.x ? shapePr?.x : 0}
      y={
        isNoData || sensor.details?.offlineFromInMinutes
          ? sensor.airSensor.coordinateY
          : shapePr?.y
      }
    >
      {shapePr && (
        <>
          <Rect
            x={0}
            y={0}
            width={
              isNoData || sensor.details?.offlineFromInMinutes
                ? WELLNESS_INDEX_TOOLTIP_MAX_WIDTH
                : WELLNESS_INDEX_TOOLTIP_MIN_WIDTH
            }
            height={
              isNoData || sensor.details?.offlineFromInMinutes
                ? WELLNESS_INDEX_TOOLTIP_MIN_HEIGHT
                : WELLNESS_INDEX_TOOLTIP_MAX_HEIGHT
            }
            fill={"#E6F8FA"}
          ></Rect>
          <Group x={TOOL_TIP_TEXT_PADDING} y={20} fontFamily={"Poppins"}>
            <Text fontSize={14} fontStyle={"bold"} text={name} />
            {isNoData || sensor.details?.offlineFromInMinutes > 0 ? (
              <Text
                fontSize={14}
                fontStyle={"normal"}
                text={
                  isNoData
                    ? `No Data Available`
                    : `Offline: ${
                        sensor.details?.offlineFromInMinutes
                          ? offlineTimeFormat(
                              sensor.details?.offlineFromInMinutes
                            )
                          : ""
                      }`
                }
                y={20}
                width={200}
                wrap={"none"}
                ellipsis={true}
              />
            ) : (
              <>
                <Text
                  y={40}
                  fontSize={12}
                  fontStyle={"bold"}
                  text={"Temperature"}
                />
                <Text
                  y={40}
                  fontSize={12}
                  x={80}
                  text={
                    temperatureUnit
                      ? `${Math.round(details.temperature.value)}°C`
                      : `${Math.round(
                          (details.temperature.value * 9) / 5 + 32
                        )}°F`
                  }
                />
                <Text
                  y={60}
                  fontSize={12}
                  fontStyle={"bold"}
                  text={"Humidity"}
                />
                <Text
                  y={60}
                  fontSize={12}
                  x={80}
                  text={`${Math.round(details.humidity.value)}%`}
                />
                <Text y={80} fontSize={12} fontStyle={"bold"} text={"CO2"} />
                <Text
                  y={80}
                  fontSize={12}
                  x={80}
                  text={`${Math.round(details.co2.value)} ppm`}
                />
                <Text y={100} fontSize={12} fontStyle={"bold"} text={"VOC"} />
                <Text
                  y={100}
                  fontSize={12}
                  x={80}
                  text={`${Math.round(
                    details.volatileOrganicCompounds.value
                  )} ppb`}
                />
                <Text y={120} fontSize={12} fontStyle={"bold"} text={"PM"} />
                <Text
                  y={120}
                  fontSize={12}
                  x={80}
                  text={`${Math.round(details.particulateMatter.value)} ugm3`}
                />
                <Text y={140} fontSize={12} fontStyle={"bold"} text={"Light"} />
                <Text
                  y={140}
                  fontSize={12}
                  x={80}
                  text={`${Math.round(details.illuminance.value)} lux`}
                />
                <Text y={160} fontSize={12} fontStyle={"bold"} text={"Noise"} />
                <Text
                  y={160}
                  fontSize={12}
                  x={80}
                  text={`${Math.round(details.soundLevel.value)} db`}
                />
              </>
            )}
          </Group>
        </>
      )}
    </Group>
  );
}

export function CameraShape({
  coordinateX,
  coordinateY,
}: {
  coordinateX?: number;
  coordinateY?: number;
}) {
  return coordinateX && coordinateY ? (
    <Shape
      sceneFunc={(context, shape) => {
        context.beginPath();

        context.moveTo(coordinateX, coordinateY);
        context.rect(coordinateX! - 5, coordinateY! - 5, 14, 10);
        context.moveTo(coordinateX! + 6, coordinateY);
        context.lineTo(coordinateX! + 12, coordinateY! - 4);
        context.lineTo(coordinateX! + 12, coordinateY! + 4);
        context.closePath();

        // (!) Konva specific method, it is very important
        context.fillStrokeShape(shape);
      }}
      fill="#0E1D33"
    />
  ) : null;
}

export function CameraZone({
  shape,
  onChange,
  spaceLoad,
  onClick,
  editable,
  clickable,
  selectedCamera,
  toggleActive,
}: {
  shape: AddCameraZoneProps;
  spaceLoad?: SpaceLoad;
  editable?: boolean;
  clickable?: boolean;
  onChange?: (shape: AddCameraZoneProps) => void;
  onClick?: (shape: AddCameraZoneProps) => void;
  selectedCamera?: ImageSensorWithOccupancy | null;
  toggleActive?: (
    selectedCamera: ImageSensorWithOccupancy | null,
    selectedZoneId: string
  ) => void;
}) {
  const shapeRef = useRef<any>();
  const trRef = useRef<any>();
  useEffect(() => {
    if (editable && shape.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [shape.isSelected, editable]);

  const onTransformEnd = useCallback(
    (e) => {
      // transformer is changing scale of the node
      // and NOT its width or height
      // but in the store we have only width and height
      // to match the data better we will reset scale on transform end
      const node = shapeRef.current;
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      // we will reset it back
      node.scaleX(1);
      node.scaleY(1);
      onChange &&
        onChange({
          ...shape,
          shape: {
            ...shape.shape,
            placement: {
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
            },
          },
        });
    },
    [onChange, shape]
  );

  const onDragEnd = useCallback(
    (event) => {
      onChange &&
        onChange({
          ...shape,
          shape: {
            ...shape.shape,
            placement: {
              ...shape.shape.placement,
              x: event.target.x(),
              y: event.target.y(),
            },
          },
        });
    },
    [onChange, shape]
  );

  const handleZoneClick = useCallback(() => {
    if (clickable) {
      toggleActive!(selectedCamera!, shape?.shape?.id);
    }
  }, []);

  let shapeFill = colorSpaceLoad();
  if (spaceLoad) {
    shapeFill = colorSpaceLoad(spaceLoad);
  }

  return shape.shape.placement ? (
    <Group>
      <Rect
        ref={shapeRef}
        draggable={clickable ? undefined : shape.isSelected}
        x={+shape.shape.placement.x}
        y={+shape.shape.placement.y}
        width={+shape.shape.placement.width}
        height={+shape.shape.placement.height}
        fill={shapeFill.shadow}
        stroke={shapeFill.main}
        strokeWidth={shape.isSelected ? 3 : 1}
        dash={shape.isSelected ? undefined : [2, 2]}
        onTransformEnd={onTransformEnd}
        onDragEnd={onDragEnd}
        onClick={handleZoneClick}
      />
      {editable && shape.isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          keepRatio={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </Group>
  ) : null;
}

export function CameraTripwire({
  shape,
  onChange,
  spaceLoad,
  onClick,
  editable,
  clickable,
  selectedCamera,
  toggleActive,
  zoomLevel,
}: {
  shape: AddCameraTripwireProps;
  spaceLoad?: SpaceLoad;
  editable?: boolean;
  clickable?: boolean;
  onChange?: (shape: AddCameraTripwireProps) => void;
  onClick?: (shape: AddCameraTripwireProps) => void;
  selectedCamera?: ImageSensorWithOccupancy | null;
  toggleActive?: (
    selectedCamera: ImageSensorWithOccupancy | null,
    selectedTripWireId: string
  ) => void;
  zoomLevel?: number;
}) {
  const shapeRef = useRef<any>();
  const trRef = useRef<any>();
  useEffect(() => {
    if (editable && shape.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [shape.isSelected, editable]);

  const onTransformEnd = useCallback(
    (e) => {
      // transformer is changing scale of the node
      // and NOT its width or height
      // but in the store we have only width and height
      // to match the data better we will reset scale on transform end
      const node = shapeRef.current;
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      // we will reset it back
      node.scaleX(1);
      node.scaleY(1);
      onChange &&
        onChange({
          ...shape,
          shape: {
            ...shape.shape,
            placement: {
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
              rotation: node.rotation(),
            },
          },
        });
    },
    [onChange, shape]
  );

  const onDragEnd = useCallback(
    (event) => {
      onChange &&
        onChange({
          ...shape,
          shape: {
            ...shape.shape,
            placement: {
              ...shape.shape.placement,
              x: event.target.x(),
              y: event.target.y(),
            },
          },
        });
    },
    [onChange, shape]
  );

  const handleTripWireClick = useCallback(() => {
    if (clickable) {
      toggleActive!(selectedCamera!, shape?.shape?.id);
    }
  }, []);

  let shapeFill = colorSpaceLoad();
  if (spaceLoad) {
    shapeFill = colorSpaceLoad(spaceLoad);
  }

  const [image] = useImage(url);
  const [downImage] = useImage(downUrl);
  const [bothImage] = useImage(bothUrl);

  return shape.shape.placement ? (
    <Group>
      {!editable && (
        <Rect
          x={+shape.shape.placement.x}
          y={+shape.shape.placement.y}
          height={+shape.shape.placement.height}
          width={+shape.shape.placement.width}
          rotation={+shape.shape.placement.rotation}
          fill={shapeFill.shadow}
        />
      )}
      <Image
        ref={shapeRef}
        fillPatternImage={
          shape.shape.flowType === "BOTH"
            ? bothImage
            : shape.shape.flowType === "IN"
            ? downImage
            : image
        }
        image={undefined}
        fillPatternRepeat="repeat"
        height={+shape.shape.placement.height}
        width={+shape.shape.placement.width}
        x={+shape.shape.placement.x}
        y={+shape.shape.placement.y}
        rotation={+shape.shape.placement.rotation}
        draggable={clickable ? undefined : shape.isSelected}
        stroke={shapeFill.main}
        strokeWidth={shape.isSelected ? 3 : 1}
        dash={shape.isSelected ? undefined : [2, 2]}
        onTransformEnd={onTransformEnd}
        onDragEnd={onDragEnd}
        onClick={handleTripWireClick}
      />
      {editable && shape.isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={true}
          rotation={+shape.shape.placement.rotation}
          //keepRatio={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            const NewBoxHeight = zoomLevel ? zoomLevel * 22 : 22;
            if (
              Math.abs(newBox.width) < 50 ||
              Math.abs(newBox.height) < 17 ||
              Math.abs(newBox.height) > NewBoxHeight
            ) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </Group>
  ) : null;
}

export function ActiveCameraZone({ shape }: { shape: ZoneWithOccupancy }) {
  const shapeRef = useRef<any>(null);
  const [shapePr, setShapePr] = useState<{ x: number; y: number }>();
  useEffect(() => {
    const placement = shape.placement;
    if (placement) {
      const { x, y, width: shapeWidth, height: shapeHeight } = placement;
      const tooltipX = +x + +shapeWidth / 2 - 80;
      const tooltipY = +y + +shapeHeight;
      // const y = sensor.airSensor.coordinateY;
      if (shapeRef.current) {
        const height = shapeRef.current?.getStage().height();
        const width = shapeRef.current?.getStage().width();
        const overHeight = tooltipY + 30 > height;
        const overWidth = tooltipX + 80 > width || tooltipX - 80 < 0;
        let startY = tooltipY;
        let startX = tooltipX;
        if (overHeight) {
          startY = +y - 30;
        }
        if (overWidth) {
          startX = tooltipX - 80 < 0 ? 0 : width - 80;
        }
        setShapePr({ x: startX, y: startY });
      }
    }
  }, [shapeRef, shape.placement]);
  return shape?.placement && Object.keys(shape?.placement).length > 0 ? (
    <Group ref={shapeRef} x={shapePr?.x} y={shapePr?.y} fontFamily={"Poppins"}>
      <Rect
        x={0}
        y={0}
        width={shape?.offlineFromInMinutes ? 200 : 160}
        height={shape?.offlineFromInMinutes ? 50 : 30}
        fill={"#E6F8FA"}
      ></Rect>
      <Text
        fontSize={14}
        fontStyle={"bold"}
        align={"center"}
        text={shape.name || shape.zone}
        width={160}
        y={8}
      />
      {shape.offlineFromInMinutes > 0 && (
        <Text
          fontSize={14}
          fontStyle={"normal"}
          align={"center"}
          text={`Offline: ${
            shape?.offlineFromInMinutes
              ? offlineTimeFormat(shape?.offlineFromInMinutes)
              : ""
          }`}
          y={30}
          width={200}
          wrap={"none"}
          ellipsis={true}
        />
      )}
    </Group>
  ) : null;
}

export function ActiveCameraTripwire({
  shape,
}: {
  shape: TripWireWithOccupancy;
}) {
  const shapeRef = useRef<any>(null);

  const [shapePr, setShapePr] = useState<{ x: number; y: number }>();
  useEffect(() => {
    const placement = shape.placement;
    if (placement) {
      const { x, y, width: shapeWidth, height: shapeHeight } = placement;
      const tooltipX = +x + +shapeWidth / 2 - 80;
      const tooltipY = +y + +shapeHeight;
      // const y = sensor.airSensor.coordinateY;
      if (shapeRef.current) {
        const height = shapeRef.current?.getStage().height();
        const width = shapeRef.current?.getStage().width();
        const overHeight = tooltipY + 30 > height;
        const overWidth = tooltipX + 80 > width || tooltipX - 80 < 0;
        let startY = tooltipY;
        let startX = tooltipX;
        if (overHeight) {
          startY = +y - 30;
        }
        if (overWidth) {
          startX = tooltipX - 80 < 0 ? 0 : width - 80;
        }
        setShapePr({ x: startX, y: startY });
      }
    }
  }, [shapeRef, shape.placement]);
  return shape?.placement && Object.keys(shape?.placement).length > 0 ? (
    <Group ref={shapeRef} x={shapePr?.x} y={shapePr?.y} fontFamily={"Poppins"}>
      <Rect
        x={0}
        y={0}
        width={shape?.offlineFromInMinutes ? 200 : 160}
        height={shape?.offlineFromInMinutes ? 50 : 30}
        fill={"#E6F8FA"}
      ></Rect>
      <Text
        fontSize={14}
        fontStyle={"bold"}
        align={"center"}
        text={shape.name || shape.tripWire}
        width={160}
        y={8}
      />
      {shape.offlineFromInMinutes > 0 && (
        <Text
          fontSize={14}
          fontStyle={"normal"}
          align={"center"}
          text={`Offline: ${
            shape?.offlineFromInMinutes
              ? offlineTimeFormat(shape?.offlineFromInMinutes)
              : ""
          }`}
          y={30}
          width={200}
          wrap={"none"}
          ellipsis={true}
        />
      )}
    </Group>
  ) : null;
}

export function SpaceShape({
  shape,
  onChange,
  spaceLoad,
  onClick,
  editable,
}: {
  shape: AddSpaceProps;
  spaceLoad?: SpaceLoad;
  editable?: boolean;
  onChange?: (shape: AddSpaceProps) => void;
  onClick?: (shape: AddSpaceProps) => void;
}) {
  const shapeRef = useRef<any>();
  const trRef = useRef<any>();
  const [hover, setHover] = useState(false);
  const [shapePr, setShapePr] = useState<{ x: number; y: number }>();

  useEffect(() => {
    if (editable && shape.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [shape.isSelected, editable]);

  useEffect(() => {
    const placement = shape.shape.placement;
    if (placement) {
      const { x, y, width: shapeWidth, height: shapeHeight } = placement;
      const tooltipX = +x + +shapeWidth / 2 - 80;
      const tooltipY = +y + +shapeHeight;
      if (shapeRef.current) {
        const height = shapeRef.current?.getStage().height();
        const width = shapeRef.current?.getStage().width();
        const overHeight = tooltipY + 30 > height;
        const overWidth = tooltipX + 80 > width || tooltipX - 80 < 0;
        let startY = tooltipY;
        let startX = tooltipX;
        if (overHeight) {
          startY = +y - 30;
        }
        if (overWidth) {
          startX = tooltipX - 80 < 0 ? 0 : width - 80;
        }
        setShapePr({ x: startX, y: startY });
      }
    }
  }, [shapeRef, shape.shape.placement]);

  const onTransformEnd = useCallback(
    (e) => {
      // transformer is changing scale of the node
      // and NOT its width or height
      // but in the store we have only width and height
      // to match the data better we will reset scale on transform end
      const node = shapeRef.current;
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      // we will reset it back
      node.scaleX(1);
      node.scaleY(1);
      onChange &&
        onChange({
          ...shape,
          shape: {
            ...shape.shape,
            placement: {
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
            },
          },
        });
    },
    [onChange, shape]
  );

  const onDragEnd = useCallback(
    (event) => {
      onChange &&
        onChange({
          ...shape,
          shape: {
            ...shape.shape,
            placement: {
              ...shape.shape.placement,
              x: event.target.x(),
              y: event.target.y(),
            },
          },
        });
    },
    [onChange, shape]
  );

  let shapeFill = colorSpaceLoad();
  if (spaceLoad) {
    shapeFill = colorSpaceLoad(spaceLoad);
  }

  const handleZoneClick = useCallback(() => {
    onClick && onClick(shape);
    setHover(true);
  }, [onClick, shape]);

  const mouseOut = () => {
    setHover(false);
  };

  return shape.shape.placement ? (
    <Group>
      {hover ? (
        <Group x={shapePr?.x} y={shapePr?.y} fontFamily={"Poppins"}>
          <Rect x={0} y={0} width={160} height={30} fill={"#E6F8FA"}></Rect>
          <Text
            fontSize={14}
            fontStyle={"bold"}
            fontFamily={"Poppins"}
            text={shape.shape.name}
            align={"center"}
            width={160}
            y={10}
          />
        </Group>
      ) : null}
      <Rect
        ref={shapeRef}
        draggable={shape.isSelected}
        x={+shape.shape.placement.x}
        y={+shape.shape.placement.y}
        width={+shape.shape.placement.width}
        height={+shape.shape.placement.height}
        stroke="#9B51E0"
        strokeWidth={shape.isSelected ? 2 : 5}
        dash={shape.isSelected ? [5, 5] : undefined}
        onTransformEnd={onTransformEnd}
        onDragEnd={onDragEnd}
        onClick={handleZoneClick}
        onMouseOut={mouseOut}
      />
      {editable && shape.isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          keepRatio={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </Group>
  ) : null;
}

export function DoorShape({
  coordinateX,
  coordinateY,
  door,
  toggleActive,
}: {
  coordinateX?: number;
  coordinateY?: number;
  door?: Door;
  toggleActive?: (el: string) => void;
}) {
  const [doorImage] = useImage(doorUrl);

  if (coordinateX && coordinateY) {
    return (
      <Group x={coordinateX} y={coordinateY}>
        <Image
          image={doorImage}
          height={30}
          width={30}
          onClick={() => toggleActive!(door?.id!)}
        />
      </Group>
    );
  } else {
    return <Group></Group>;
  }
}

export function ActiveDoorDetails({ door }: { door: Door }) {
  const name = door.name;
  const doorUuid = door.doorUuid;

  const shapeRef = useRef<any>(null);

  const [shapePr, setShapePr] = useState<{ x: number; y: number }>();

  const x = useMemo(
    () => (door.coordinateX === "null" ? null : door.coordinateX),
    [door.coordinateX]
  );
  const y = useMemo(
    () => (door.coordinateY === "null" ? null : door.coordinateY),
    [door.coordinateY]
  );

  useEffect(() => {
    if (x && y) {
      const tooltipX = +x - 10;
      const tooltipY = +y + 30;

      if (shapeRef.current) {
        const height = shapeRef.current?.getStage().height();
        const width = shapeRef.current?.getStage().width();
        const shapeHeight = 10;
        const overHeight = tooltipY + shapeHeight > height;
        const overWidth = tooltipX + 160 > width || tooltipX - 160 < 0;
        let startY = tooltipY;
        let startX = tooltipX;
        if (overHeight) {
          startY = +y - shapeHeight - 15;
        }
        if (overWidth) {
          startX = tooltipX - 160 < 0 ? 0 : width - 160;
        }
        setShapePr({ x: startX, y: startY });
      }
    }
  }, [shapeRef, x, y]);

  return x !== null && y !== null ? (
    <Group ref={shapeRef} x={shapePr?.x} y={shapePr?.y} fontFamily={"Poppins"}>
      <Rect width={160} height={30} fill={"#E6F8FA"}></Rect>
      <Text
        fontSize={14}
        fontStyle={"bold"}
        text={name}
        align={"center"}
        width={160}
        y={8}
      />
    </Group>
  ) : null;
}

export enum ShapeType {
  CAMERA,
  ZONE,
  TRIPWIRE,
  AIR_SENSOR,
  ACCESS_POINT,
  DOOR,
  SPACE,
}

// we will have a separate layer for adding a new shape
export interface AddShapeProps<T extends ShapeType, P> {
  shapeType: T;
  shape: P;
  isSelected?: boolean;
}
export interface AddCameraProps
  extends AddShapeProps<
    ShapeType.CAMERA,
    { coordinateX?: number; coordinateY?: number }
  > {}
export interface AddAirSensorProps
  extends AddShapeProps<
    ShapeType.AIR_SENSOR,
    { coordinateX?: number; coordinateY?: number }
  > {}

export interface AccessPointProps
  extends AddShapeProps<
    ShapeType.ACCESS_POINT,
    { coordinateX?: number; coordinateY?: number }
  > {}

export interface AddCameraZoneProps
  extends AddShapeProps<ShapeType.ZONE, Zone> {
  cameraId: string;
}
export interface AddCameraTripwireProps
  extends AddShapeProps<ShapeType.TRIPWIRE, TripWire> {
  cameraId: string;
}

export interface AddDoorProps
  extends AddShapeProps<
    ShapeType.DOOR,
    { coordinateX?: number; coordinateY?: number }
  > {}

export interface AddSpaceProps extends AddShapeProps<ShapeType.SPACE, Space> {
  spaceId: string;
}
