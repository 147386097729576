import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import {
  Form,
  FormType,
  PageHeader,
  SwipePanel,
} from "../../../../../common/components";
import { CustomModal } from "../../../../../common/components/index";
import { ModalContent } from "../ModalContent";
import {
  AccessPoint,
  FormField,
  Frequency,
  Radio,
  RadioBand,
  SSID,
} from "../../../../../types";
import { useMutation, useQuery } from "@apollo/client";
import { usePropertiesState } from "../../../../../core/context/containers/Properties.container";
import GQLService from "../../../../../core/services/GQL.service";
import { ROUTES } from "../../../../../common/constants/Routing";
import { useAccessPointState } from "../../../../../core/context/containers/AccessPoints.container";
import { editAccessPointSchema, MainSettings } from "./MainSettings";
import { SSIDsForm, SSIDsSchema } from "./SSIDs";
import {
  RadioConfiguration,
  radioConfigurationSchema,
} from "./RadioConfiguration";
import { useStyles } from "./EditAccessPoint.styles";
import { useFormWizard } from "../../../../../core/context/functions/FormWizard.functions";
import { useSnackbar } from "notistack";

const radio5GHzChannels: { id: number; name: string }[] = [];
for (let i = 32; i <= 68; i += 2) {
  radio5GHzChannels.push({ id: i, name: i.toString() });
}
radio5GHzChannels.push({ id: 96, name: "96" });
for (let i = 100; i <= 144; i += 2) {
  if (i !== 130) {
    radio5GHzChannels.push({ id: i, name: i.toString() });
  }
}
for (let i = 149; i <= 161; i += 2) {
  radio5GHzChannels.push({ id: i, name: i.toString() });
}
const radio24GHzChannels: { id: number; name: string }[] = [];
for (let i = 0; i < 11; i++) {
  radio24GHzChannels.push({ id: i + 1, name: (i + 1).toString() });
}
const radio5GHzWidth = [
  { id: 1, name: "20MHz" },
  { id: 2, name: "40MHz" },
  { id: 3, name: "80MHz" },
  { id: 4, name: "160MHz" },
];
const radio24GHzWidth = [
  { id: 1, name: "20MHz" },
  { id: 2, name: "40MHz" },
];

export const RadioConfigurationForm = () => {
  const { selectedProperty } = usePropertiesState();
  const { selectedAccessPoint } = useAccessPointState();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [updateRadio] = useMutation(GQLService.mutations.editRadio, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allAccessPoints,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [stepsRadio1, setWizardStepsRadio1] = useState<FormField[]>();
  const [stepsRadio2, setWizardStepsRadio2] = useState<FormField[]>();
  const [formData1, setFormData1] = useState<Radio>();
  const [formData2, setFormData2] = useState<Radio>();
  const form1Ref = useRef<any>();
  const form2Ref = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    let isMounted = true;
    const radio1Band = selectedAccessPoint?.radio1?.band;
    const radio2Band = selectedAccessPoint?.radio2?.band;
    if (isMounted) {
      const steps1 = RadioConfiguration(
        !radio1Band || radio1Band === RadioBand.NONE,
        {
          channel:
            radio1Band === RadioBand.G5
              ? radio5GHzChannels
              : radio24GHzChannels,
          width: radio1Band === RadioBand.G5 ? radio5GHzWidth : radio24GHzWidth,
        }
      );
      setWizardStepsRadio1(steps1);
      const steps2 = RadioConfiguration(
        !radio2Band || radio2Band === RadioBand.NONE,
        {
          channel:
            radio2Band === RadioBand.G5
              ? radio5GHzChannels
              : radio24GHzChannels,
          width: radio2Band === RadioBand.G5 ? radio5GHzWidth : radio24GHzWidth,
        }
      );
      setWizardStepsRadio2(steps2);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = () => {
    form1Ref?.current?.handleSubmit();
    form2Ref?.current?.handleSubmit();
  };

  const handleRadio1Submit = useCallback(
    async (formData) => {
      toggleModal(true);
      setFormData1({
        ...formData,
        radioId: selectedAccessPoint?.radio1.id,
        accessPointId: selectedAccessPoint?.id,
      });
    },
    [formData1, selectedProperty] // eslint-disable-line
  );

  const handleRadio2Submit = useCallback(
    async (formData) => {
      toggleModal(true);
      setFormData2({
        ...formData,
        radioId: selectedAccessPoint?.radio2.id,
        accessPointId: selectedAccessPoint?.id,
      });
    },
    [formData2, selectedProperty] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      try {
        formData1 && (await updateRadio({ variables: formData1 }));
        formData2 && (await updateRadio({ variables: formData2 }));
        history.push(
          ROUTES.ACCESS_POINTS.replace(":uid", selectedProperty?.id!)
        );
      } catch (e) {
        console.log(e);
      }
    },
    [formData1, formData2] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => toggleModal(false), []);

  return (
    <Grid item={true} md={12} sm={12}>
      <Typography>
        {formatMessage({
          id: "accessPoints.edit.radio1.header",
          defaultMessage: "RADIO 1 CONFIGURATION",
        })}
      </Typography>
      <Grid item md={6} sm={12}>
        {stepsRadio1 && (
          <Form
            ref={(ref) => (form1Ref.current = ref)}
            defaultValues={selectedAccessPoint?.radio1}
            fields={stepsRadio1}
            validationSchema={radioConfigurationSchema}
            onSubmit={handleRadio1Submit}
            type={FormType.WizardPartialForm}
            style={{ spacing: 0 }}
          />
        )}
      </Grid>
      <Divider classes={{ root: classes.divider }} />
      <Typography>
        {formatMessage({
          id: "accessPoints.edit.radio2.header",
          defaultMessage: "RADIO 2 CONFIGURATION",
        })}
      </Typography>
      <Grid item md={6} sm={12}>
        {stepsRadio2 && (
          <Form
            ref={(ref) => (form2Ref.current = ref)}
            defaultValues={selectedAccessPoint?.radio2}
            fields={stepsRadio2}
            validationSchema={radioConfigurationSchema}
            onSubmit={handleRadio2Submit}
            type={FormType.WizardPartialForm}
            style={{ spacing: 0 }}
          />
        )}
      </Grid>
      <Button
        onClick={handleSubmit}
        color="primary"
        classes={{ root: classes.button }}
      >
        {formatMessage({
          id: "accessPoints.edit.button.update",
          defaultMessage: "Update",
        })}
      </Button>
      <CustomModal open={showModal}>
        <ModalContent
          yes={async () => await confirmChanges()}
          no={rejectChanges}
        />
      </CustomModal>
    </Grid>
  );
};

export const SSIDs = () => {
  const { selectedProperty } = usePropertiesState();
  const { selectedAccessPoint } = useAccessPointState();
  const [stepsRadio1, setWizardStepsRadio1] = useState<FormField[]>();
  const [stepsRadio2, setWizardStepsRadio2] = useState<FormField[]>();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [radio1FormDataState, setRadio1FormDataState] = useState<Radio>();
  const [radio2FormDataState, setRadio2FormDataState] = useState<Radio>();
  const [updateRadio] = useMutation(GQLService.mutations.editRadio, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allAccessPoints,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });
  const form1Ref = useRef<any>();
  const form2Ref = useRef<any>();
  const classes = useStyles();

  const {
    error,
    loading,
    data: ssids,
  } = useQuery(GQLService.queries.allSsid, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let existingSSIDsForRadio1 = selectedAccessPoint?.radio1?.ssids?.map(
      (ssid: any) => ssid.id
    );
    let existingSSIDsForRadio2 = selectedAccessPoint?.radio2?.ssids?.map(
      (ssid: any) => ssid.id
    );
    let isMounted = true;
    if (isMounted) {
      if (!loading) {
        let activeSSIDS: SSID[] = [];
        if (ssids?.ssidsByPropertyId) {
          ssids?.ssidsByPropertyId?.map((i: SSID) => {
            if (i.active === true) {
              activeSSIDS.push(i);
            }
          });
        }
        const stepsRadio1 = SSIDsForm(
          !selectedAccessPoint?.radio1?.band ||
            selectedAccessPoint?.radio1?.band === RadioBand.NONE,
          {
            ssids: activeSSIDS,
          },
          existingSSIDsForRadio1
        );
        setWizardStepsRadio1(stepsRadio1);
        const stepsRadio2 = SSIDsForm(
          !selectedAccessPoint?.radio2?.band ||
            selectedAccessPoint?.radio2?.band === RadioBand.NONE,
          {
            ssids: activeSSIDS,
          },
          existingSSIDsForRadio2
        );
        setWizardStepsRadio2(stepsRadio2);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [loading, ssids]);

  const handleRadio1Submit = useCallback(
    (formData) => {
      const ssidsToAddRadio1: string[] = [];
      const ssidsToDeleteRadio1: string[] = [];
      formData.ssids?.forEach((newSSID: string) => {
        const occurred = selectedAccessPoint?.radio1?.ssids?.find(
          (oldSSID: any) => {
            return oldSSID?.id === newSSID;
          }
        );
        !occurred && ssidsToAddRadio1.push(newSSID);
      });
      selectedAccessPoint?.radio1?.ssids?.forEach((oldSSID: any) => {
        if (
          !formData.ssids?.find((newSSID: string) => newSSID === oldSSID?.id)
        ) {
          ssidsToDeleteRadio1.push(oldSSID?.id);
        }
      });
      toggleModal(true);
      setRadio1FormDataState({
        ...formData,
        radioId: selectedAccessPoint?.radio1.id,
        ssidsToAdd: ssidsToAddRadio1,
        ssidsToDelete: ssidsToDeleteRadio1,
        band: selectedAccessPoint?.radio1?.band,
        accessPointId: selectedAccessPoint?.id,
      });
    },
    [radio1FormDataState, selectedProperty]
  );

  const handleRadio2Submit = useCallback(
    (formData) => {
      const ssidsToAddRadio2: string[] = [];
      const ssidsToDeleteRadio2: string[] = [];
      formData.ssids?.forEach((newSSID: string) => {
        const occurred = selectedAccessPoint?.radio2?.ssids?.find(
          (oldSSID: any) => {
            return oldSSID?.id === newSSID;
          }
        );
        !occurred && ssidsToAddRadio2.push(newSSID);
      });
      selectedAccessPoint?.radio2?.ssids?.forEach((oldSSID: any) => {
        if (
          !formData.ssids?.find((newSSID: string) => newSSID === oldSSID?.id)
        ) {
          ssidsToDeleteRadio2.push(oldSSID?.id);
        }
      });
      toggleModal(true);
      setRadio2FormDataState({
        ...formData,
        radioId: selectedAccessPoint?.radio2.id,
        ssidsToAdd: ssidsToAddRadio2,
        ssidsToDelete: ssidsToDeleteRadio2,
        band: selectedAccessPoint?.radio2?.band,
        accessPointId: selectedAccessPoint?.id,
      });
    },
    [radio2FormDataState, selectedProperty]
  );

  const handleSubmit = () => {
    form1Ref?.current?.handleSubmit();
    form2Ref?.current?.handleSubmit();
  };

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      try {
        radio1FormDataState &&
          (await updateRadio({ variables: radio1FormDataState }));
        radio2FormDataState &&
          (await updateRadio({ variables: radio2FormDataState }));
        history.push(
          ROUTES.ACCESS_POINT_VIEW.replace(
            ":uid",
            selectedProperty!.id
          ).replace(":accesspoint", selectedAccessPoint?.id.split(":")[1]!) +
            "#1"
        );
      } catch (e) {
        console.log(e);
      }
    },
    [radio1FormDataState, radio2FormDataState] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => toggleModal(false), []);

  return (
    <Grid item={true} md={12} sm={12}>
      <Typography>
        {formatMessage({
          id: "accessPoints.edit.SSIDs.radio1",
          defaultMessage: "SSIDs for Radio 1 ",
        })}
        {selectedAccessPoint?.radio1?.band == RadioBand.G5
          ? `(${Frequency.G5})`
          : selectedAccessPoint?.radio1?.band == RadioBand.G2_4
          ? `(${Frequency.G2_4})`
          : `${Frequency.NONE}`}
      </Typography>
      <Grid item md={6} sm={12}>
        {stepsRadio1 && (
          <Form
            ref={(ref) => (form1Ref.current = ref)}
            defaultValues={{
              ssids: selectedAccessPoint?.radio1?.ssids?.map(
                (ssid: any) => ssid.id
              ),
            }}
            fields={stepsRadio1}
            validationSchema={SSIDsSchema}
            onSubmit={handleRadio1Submit}
            type={FormType.WizardPartialForm}
            style={{ spacing: 0 }}
          />
        )}
      </Grid>
      <Divider classes={{ root: classes.divider }} />
      <Typography>
        {formatMessage({
          id: "accessPoints.edit.SSIDs.radio2",
          defaultMessage: "SSIDs for Radio 2 ",
        })}
        {selectedAccessPoint?.radio2?.band == RadioBand.G5
          ? `(${Frequency.G5})`
          : selectedAccessPoint?.radio2?.band == RadioBand.G2_4
          ? `(${Frequency.G2_4})`
          : `${Frequency.NONE}`}
      </Typography>
      <Grid item md={6} sm={12}>
        {stepsRadio2 && (
          <Form
            ref={(ref) => (form2Ref.current = ref)}
            defaultValues={{
              ssids: selectedAccessPoint?.radio2?.ssids?.map(
                (ssid: any) => ssid.id
              ),
            }}
            fields={stepsRadio2}
            validationSchema={SSIDsSchema}
            onSubmit={handleRadio2Submit}
            type={FormType.WizardPartialForm}
            style={{ spacing: 0 }}
          />
        )}
      </Grid>
      <Button
        onClick={handleSubmit}
        color="primary"
        classes={{ root: classes.button }}
      >
        {formatMessage({
          id: "accessPoints.edit.SSIDs.button.update",
          defaultMessage: "Update",
        })}
      </Button>
      <CustomModal open={showModal}>
        <ModalContent
          yes={async () => await confirmChanges()}
          no={rejectChanges}
        />
      </CustomModal>
    </Grid>
  );
};

export const MainSettingsForm = () => {
  const { formatMessage } = useIntl();
  const { selectedProperty } = usePropertiesState();
  const { selectedAccessPoint, setSelectedAccessPoint } = useAccessPointState();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [updateAccessPoint] = useMutation(
    GQLService.mutations.editAccessPoint,
    {
      refetchQueries: () => [
        {
          query: GQLService.queries.allAccessPoints,
          variables: { propertyId: selectedProperty?.id },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  const [updateRadio] = useMutation(GQLService.mutations.editRadio, {
    refetchQueries: () => [
      {
        query: GQLService.queries.allAccessPoints,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });
  const history = useHistory();
  const [steps, setWizardSteps] = useState<FormField[]>();
  const [formDataState, setFormDataState] = useState<AccessPoint>();
  const [radio1Data, setRadio1Data] = useState<any>();
  const [radio2Data, setRadio2Data] = useState<any>();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    error: floorError,
    loading: floorLoading,
    data: floors,
  } = useQuery(GQLService.queries.allFloors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!floorLoading) {
        const steps = MainSettings(false, {
          property: [
            {
              name:
                selectedProperty?.siteName ||
                selectedProperty?.ownerCompanyName,
            },
          ],
          floors: floors?.floorsByPropertyId,
        });
        setWizardSteps(steps);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [floorLoading, floors, selectedProperty]);

  const handleSubmit = useCallback(
    (formData) => {
      toggleModal(true);
      setFormDataState({
        ...formData,
        id: selectedAccessPoint?.id,
      });
      setRadio1Data({
        macAddress: formData?.radio1_macAddress,
        band: formData?.radio1_band,
        radioId: selectedAccessPoint?.radio1?.id,
        accessPointId: selectedAccessPoint?.id,
      });
      setRadio2Data({
        macAddress: formData?.radio2_macAddress,
        band: formData?.radio2_band,
        radioId: selectedAccessPoint?.radio2?.id,
        accessPointId: selectedAccessPoint?.id,
      });
    },
    [formDataState, selectedProperty, radio1Data, radio2Data] // eslint-disable-line
  );

  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      const coordinateX =
        formDataState?.coordinateX === "null" ? 0 : formDataState?.coordinateX; //@ts-ignore
      const coordinateY =
        formDataState?.coordinateY === "null" ? 0 : formDataState?.coordinateY; //@ts-ignore
      const inventoryNumber =
        formDataState?.inventoryNumber === ""
          ? 0
          : formDataState?.inventoryNumber; //@ts-ignore
      try {
        formDataState &&
          (await updateAccessPoint({
            variables: {
              ...formDataState,
              coordinateX,
              coordinateY,
              inventoryNumber,
            },
          }));
        radio1Data && (await updateRadio({ variables: radio1Data }));
        radio2Data && (await updateRadio({ variables: radio2Data }));
        enqueueSnackbar(
          formatMessage({
            id: "accessPoints.edit.mainSettings.snackbar.success",
            defaultMessage: "Access Point changed successfully",
          }),
          {
            variant: "success",
          }
        );
        setSelectedAccessPoint(formDataState!);
        history.push(
          ROUTES.ACCESS_POINT_VIEW.replace(
            ":uid",
            selectedProperty!.id
          ).replace(":accesspoint", formDataState?.id.split(":")[1]!)
        );
      } catch (e) {
        enqueueSnackbar(e.message, { variant: "error", persist: true });
      }
    },
    [formDataState, radio1Data, radio2Data] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => toggleModal(false), []);

  return (
    <>
      <Grid item={true} md={6} sm={12}>
        {steps && (
          <Form
            defaultValues={{
              ...selectedAccessPoint,
              property: selectedProperty?.ownerCompanyName,
              radio1_macAddress: selectedAccessPoint?.radio1?.macAddress,
              radio1_band: selectedAccessPoint?.radio1?.band || RadioBand.NONE,
              radio2_macAddress: selectedAccessPoint?.radio2?.macAddress,
              radio2_band: selectedAccessPoint?.radio2?.band || RadioBand.NONE,
            }}
            fields={steps}
            validationSchema={editAccessPointSchema}
            onSubmit={handleSubmit}
            submitButtonTitle="Update"
          />
        )}
      </Grid>
      <CustomModal open={showModal}>
        <ModalContent
          yes={async () => await confirmChanges()}
          no={rejectChanges}
        />
      </CustomModal>
    </>
  );
};

export function EditAccessPoint() {
  const { reset } = useFormWizard();
  const { formatMessage } = useIntl();

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <div>
      <PageHeader title={""} />
      <SwipePanel
        tabNames={[
          formatMessage({
            id: "accessPoints.edit.swipePanel.tabs.mainSettings",
            defaultMessage: "Main Settings",
          }),
          // formatMessage({
          //   id: "accessPoints.edit.swipePanel.tabs.radioConfiguration",
          //   defaultMessage: "Radio Configuration",
          // }),
          formatMessage({
            id: "accessPoints.edit.swipePanel.tabs.SSIDs",
            defaultMessage: "SSIDs",
          }),
        ]}
      >
        <MainSettingsForm />
        {/* <RadioConfigurationForm /> */}
        <SSIDs />
      </SwipePanel>
    </div>
  );
}
