import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import { usePropertiesState } from "../../../../../../../core/context/containers/Properties.container";
import GQLService from "../../../../../../../core/services/GQL.service";
import { Property } from "../../../../../../../types";
import { useAuth } from "../../../../../../../core/context/functions/Auth.functions";
import { useStyles } from "../WorkplaceSettings.styles";
import NoCoverPhoto from "../../../../../../../common/assets/images/no-image.png";
import { dummyUserEmail } from "../../../../../../../common/constants/DummyProperty";
import { useUserState } from "../../../../../../../core/context/containers/User.container";
import DummyBGImage from "../../../../../../../common/assets/images/grandSquareBG.png";

interface CoverPhotoProps {
  currentUrl?: string | undefined;
  property: Property | null;
}

export function CoverPhoto({ currentUrl, property }: CoverPhotoProps) {
  const { userEmail } = useUserState();
  const {
    selectedProperty,
    currentCoverPhotoObject,
    setCurrentCoverPhotoObject,
  } = usePropertiesState();
  const classes = useStyles();

  useEffect(
    () => {
      (async () => {
        const response = await GQLService.rest.coverPhoto.getCoverPhoto(
          selectedProperty!.id
        );
        if (response.source !== undefined) {
          setCurrentCoverPhotoObject({
            ...currentCoverPhotoObject,
            url: response.source,
          });
        } else {
          setCurrentCoverPhotoObject({
            url: undefined,
            data: undefined,
            name: undefined,
          });
        }
      })();
    },
    [property, selectedProperty] // eslint-disable-line
  );

  return (
    <Grid className={classes.coverPhoto}>
      {userEmail === dummyUserEmail ? (
        <img src={DummyBGImage} alt={"cover"} className={classes.img} />
      ) : (
        <img
          src={currentCoverPhotoObject?.url || NoCoverPhoto}
          alt={currentCoverPhotoObject.name || "cover photo"}
          className={currentCoverPhotoObject?.url ? classes.img : classes.noImg}
        />
      )}
    </Grid>
  );
}
