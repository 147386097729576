import React from "react";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";

import { Poppins } from "./fonts";
import { palette } from "./colors";
import { listStyles } from "./lists";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [Poppins],
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: palette.common.white,
      },
    },
    MuiChip: {
      sizeSmall: {
        fontSize: 12,
      },
      root: {
        "&.LOW": {
          backgroundColor: palette.common.lowOccupancy,
        },
        "&.HIGH": {
          backgroundColor: palette.common.highOccupancy,
        },
        "&.MEDIUM": {
          backgroundColor: palette.common.mediumOccupancy,
        },
      },
    },
    ...listStyles.overrides,
  },
});

interface AppThemeProps {
  children: React.ReactNode;
}

export function AppTheme({ children }: AppThemeProps) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
