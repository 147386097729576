import { SensorConfiguration, SensorRangeConfiguration } from "../../types";
import { toFahrenheit } from "./General.utils";

export const formatProperty = (data: any) => {
  return {
    variables: {
      accountBusinessEntity: data.businessEntity as string,
      accountPropertyId: data.propertyUid as string,
      accountStatus: false,
      companyName: data.legalCompanyName as string,
      companyStatus: false,
      companyAccountNumber: data.accountNumber as string,
      companyWebsite: data.website as string,
      primaryContactFullName: data.fullName as string,
      primaryContactPhoneNumber: data.primaryContactPhoneNumber as string,
      primaryContactEmailAddress: data.primaryContactEmailAddress as string,
      siteName: data.siteName as string,
      siteNrOfFloors: +data.numberOfFloors as number,
      sitePropertyType: data.sitePropertyType as string,
      siteServiceAddress: data.siteServiceAddress as string,
      spaceLoadLow: +data.spaceLoadLow as number,
      spaceLoadHigh: +data.spaceLoadHigh as number,
      spaceLoadMediumMax: +data.spaceLoadMediumMax as number,
      spaceLoadMediumMin: +data.spaceLoadMediumMin as number,
    },
  };
};

export const mapSensorConfigurationsResponse = (
  configurations: SensorConfiguration[]
) => {
  const sensorConfigurationsSorted = configurations
    .slice()
    .sort((a, b) => a.minVal - b.minVal);

  function groupBy(arr: any[], property: string) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo as { [key: string]: SensorConfiguration[] };
    }, {} as { [key: string]: SensorConfiguration[] });
  }
  const sensorConfigValues = groupBy(
    sensorConfigurationsSorted,
    "attribute"
  ) as { [key: string]: SensorConfiguration[] };
  const sensorRangeData = Object.keys(sensorConfigValues).reduce(
    (memo, key) => {
      let ranges = sensorConfigValues[key].map(({ minVal }) => minVal);
      if (sensorConfigValues[key].length > 0) {
        ranges.push(
          sensorConfigValues[key][sensorConfigValues[key].length - 1]["maxVal"]
        );
      }
      if (key === "TEMPERATURE") {
        if (sensorConfigValues?.TEMPERATURE[0].isCelsius) {
          ranges = ranges.map((range) => range);
        } else {
          ranges = ranges.map((range) => toFahrenheit(range));
        }
      }
      memo[key] = ranges;
      return memo;
    },
    {} as SensorRangeConfiguration
  );
  return sensorRangeData;
};
