import { useFormWizardState } from "../containers/FormWizard.container";

export const useFormWizard = () => {
  const {
    currentStepFormRefs,
    activeIndex,
    formData,
    setActiveIndex,
    setFormData,
    setIsLoading
  } = useFormWizardState();

  const next = () => {
    Promise.all(
      currentStepFormRefs.current
        .filter((v: any) => v !== null)
        .map((v: any) => v.handleSubmit())
    )
      .then((resp) => {
        setFormData({
          ...formData,
          ...(resp.reduce((a: any, b: any) => ({ ...a, ...b })) as object),
        });
        setActiveIndex(activeIndex + 1);
      })
      .catch(() => {});
  };

  const submitFormWizard = (
    callback: ((data: any) => Promise<void>) | ((data: any) => void)
  ) => {
    return Promise.all(
      currentStepFormRefs.current
        .filter((v: any) => v !== null)
        .map((v: any) => v.handleSubmit())
    )
      .then((resp) => {
        callback({
          ...formData,
          ...(resp.reduce((a: any, b: any) => ({ ...a, ...b })) as object),
        });
      })
      .catch(() => {});
  };

  const prev = () => {
    setActiveIndex(activeIndex - 1);
  };

  const reset = () => {
    setActiveIndex(0);
    setFormData(null);
    setIsLoading(false);
  };

  return {
    next,
    prev,
    submitFormWizard,
    reset,
  };
};
