import React from "react";
import { Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.black,
  },
}));

interface PageHeaderProps {
  placeholder: string;
}

export function Header({ placeholder }: PageHeaderProps) {
  const classes = useStyles();
  return <Typography className={classes.title}>{placeholder} </Typography>;
}
