import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { TripWire } from "../../../types";
import { usePersistedState } from "../../hooks/Storage";
import { useSearch } from "../functions/Search.functions";

function useTripWireBase() {
  const [TripWire, setTripWire] = useState<TripWire[]>([]);
  const [TripWireFromResponse, setTripWireFromResponse] = useState<TripWire[]>(
    []
  );

  const {
    state: selectedTripWire,
    setState: setSelectedTripWire,
  } = usePersistedState<TripWire | null>("tripwire", null);

  const { search } = useSearch();

  const searchTripWire = useCallback(
    (searchValue: string) => {
      setTripWire(search(TripWireFromResponse, searchValue) as TripWire[]);
    },
    [TripWireFromResponse, search]
  );

  return {
    searchTripWire,
    TripWire,
    setTripWire,
    TripWireFromResponse,
    setTripWireFromResponse,
    selectedTripWire,
    setSelectedTripWire,
  };
}

const { Provider, useContainer } = createContainer(useTripWireBase);

export const useTripWireState = useContainer;
export const TripWireProvider = Provider;
