import { APP_DEFAULTS } from "../../../common/constants/App";
import { parseImageResponse } from "../../../common/utils/Http.utils";
import { ImageResponseHandled } from "../../../types/http";
import API from "../../api/api";

const LogoRestServiceCreate = () => {
  const uploadLogo = async (logo: FormData, propertyId: string) => {
    const response = await API.post(
      `${APP_DEFAULTS.baseApiUrl}/properties/${propertyId}/logo`,
      logo
    );
    return response;
  };

  const removeLogo = async (propertyId: string): Promise<void> => {
    await API.delete(
      `${APP_DEFAULTS.baseApiUrl}/properties/${propertyId}/logo`
    );
    return;
  };

  const getLogo = async (propertyId: string): Promise<ImageResponseHandled> => {
    try {
      const response = await API.get(`/properties/${propertyId}/logo`, {
        responseType: "blob",
      });
      return await parseImageResponse(response);
    } catch (err) {
      return {
        exists: false,
        source: undefined,
        status: 404,
      };
    }
  };

  return {
    uploadLogo,
    removeLogo,
    getLogo,
  };
};

export const LogoRestService = LogoRestServiceCreate();
