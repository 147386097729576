import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import {
  PageHeader,
  DataList,
  LinkButton,
  EmptyListView,
} from "../../../../common/components";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";

import { Grid, makeStyles } from "@material-ui/core";
import { ROUTES } from "../../../../common/constants/Routing";
import { useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { useAuth } from "../../../../core/context/containers/Auth.container";
import { Role } from "../../../../types";
import { useSensorsState } from "../../../../core/context/containers/Sensors.contrainer";
import { SensorsListItem } from "./ListItem";
import { useSearchState } from "../../../../core/context/containers/Search.container";

const useStyles = makeStyles(() => ({
  floorsHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export function AirQualitySensors() {
  const { selectedProperty } = usePropertiesState();
  const {
    searchSensors,
    sensors,
    setSensors,
    setSelectedSensor,
    sensorsFromResponse,
    setSensorsFromResponse,
  } = useSensorsState();
  const { loading, data } = useQuery(GQLService.queries.allSensors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const auth = useAuth();

  const { searchValue } = useSearchState();

  useEffect(
    () => {
      let isMounted = true;

      if (isMounted) {
        setSensors(data?.sensorsByPropertyId || []);
        setSensorsFromResponse(data?.sensorsByPropertyId || []);
      }
      return () => {
        isMounted = false;
      };
    },
    [data] // eslint-disable-line
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      searchSensors(searchValue);
    }
    return () => {
      isMounted = false;
    };
  }, [searchValue, sensorsFromResponse]);

  const createSensorsButton = useCallback(() => {
    return (
      <LinkButton
        to={ROUTES.SENSORS_ADD.replace(":uid", selectedProperty?.id!)}
        label={formatMessage({
          id: "sensors.button.label",
          defaultMessage: "Add New Sensor",
          description: "add a ssid button label",
        })}
      />
    );
  }, []);

  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div>
      <Grid className={classes.floorsHeader}>
        <PageHeader
          title={formatMessage({
            id: "sensors.header",
            defaultMessage: "Air Quality Sensors",
            description: "Air Quality Sensors header",
          })}
        />
        {sensors.length !== 0
          ? auth?.canSee(
              auth?.user?.role?.toUpperCase() as Role,
              "sensors:create",
              createSensorsButton()
            )
          : null}
      </Grid>
      {sensors.length === 0 ? (
        <EmptyListView viewType="sensors" />
      ) : (
        <DataList data={sensors} loading={loading} ListItem={SensorsListItem} />
      )}
    </div>
  );
}
