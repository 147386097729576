import React, { useEffect, useState } from "react";
import { Avatar as MuiAvatar, Typography } from "@material-ui/core";

import { useAuth } from "../../../core/context/containers/Auth.container";
import { useUserState } from "../../../core/context/containers/User.container";

import { useStyles } from "./Avatar.styles";
import GQLService from "../../../core/services/GQL.service";

export function Avatar() {
  const classes = useStyles();

  const auth = useAuth();
  const { image, setImage } = useUserState();
  const alt = auth?.user?.email?.charAt(0).toUpperCase();
  useEffect(() => {
    (async () => {
      if (auth?.user?.email) {
        const email = auth?.user.email!.replace("+", "%2B");
        const image = await GQLService.rest.userAvatar.getUserAvatar(email);
        image?.source && setImage(image?.source);
      }
    })();
  }, [auth, image]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <MuiAvatar alt="avatar" className={classes.avatar}>
        {image ? (
          <img src={image} alt={alt} className={classes.avatarImage} />
        ) : (
          alt
        )}
      </MuiAvatar>
      <Typography className={classes.name}>{auth?.user?.fullName}</Typography>
    </div>
  );
}
