import React from "react";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";

import { PageHeader } from "../../../../common/components";
import { EditAccessPoint } from "./Wizard/EditAccessPoint";

export function AccessPointEditView() {
  const { formatMessage } = useIntl();

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addAccessPoint.header",
          defaultMessage: "Edit AccessPoint",
          description: "edit access point label",
        })}
      />
      <Grid item={true} md={12} sm={12}>
        <EditAccessPoint />
      </Grid>
    </div>
  );
}
