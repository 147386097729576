import React, { useCallback, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";

import { PageHeader } from "../../../../common/components";
import { createFormFields, SpaceValidationRules } from "./Add.form";
import { Form } from "../../../../common/components";
import { ROUTES } from "../../../../common/constants/Routing";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { CustomModal } from "../../../../common/components/index";
import { Modal } from "./Modal";
import { Space, SSID } from "../../../../types";
import { useSnackbar } from "notistack";
import { scrollsTop } from "../../../../common/utils/scrollbar.utils";
import _ from "lodash";

export function AddSpaces() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { selectedProperty } = usePropertiesState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showModal, toggleModal] = useState<boolean>(false);
  const [formDataState, setFormDataState] = useState<Space | undefined>(
    undefined
  );
  const [activeSSIDs, setActiveSSIDs] = useState<any>();
  const [sortCommonSpaceData, setSortCommonSpaceData] = useState<any>();

  const { data } = useQuery(GQLService.queries.allFloors, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  useEffect(() => {}, [data]);

  const [getAirSensorByFloorId, { data: airSensorData }] = useLazyQuery(
    GQLService.queries.sensorsByFloorId,
    {
      fetchPolicy: "network-only",
    }
  );

  const [getAccessPointsByFloorId, { data: accessPointData }] = useLazyQuery(
    GQLService.queries.floorAccessPoints,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [getCommonSpaces, { data: commonSpacesData }] = useLazyQuery(
    GQLService.queries.commonSpaces,
    {
      fetchPolicy: "network-only",
    }
  );
  const {
    error,
    loading,
    data: ssids,
  } = useQuery(GQLService.queries.allSsid, {
    variables: { propertyId: selectedProperty?.id },
    fetchPolicy: "network-only",
  });

  const [getCamerasByFloorId, { data: cameraData }] = useLazyQuery(
    GQLService.queries.camerasByFloorId,
    {
      fetchPolicy: "network-only",
    }
  );
  const zonesData: any[] = [];
  const tripWireData: any[] = [];
  if (cameraData) {
    cameraData.cameras?.map((camera: any) => {
      camera.zones?.map((zone: any) => {
        const zoneCopy = { ...zone };
        zoneCopy.cameraName = camera.name;
        zoneCopy.cameraUuid = camera.cameraUuid;
        zonesData.push(zoneCopy);
      });
      camera.tripWires?.map((tripWire: any) => {
        const tripWireCopy = { ...tripWire };
        tripWireCopy.cameraName = camera.name;
        tripWireCopy.cameraUuid = camera.cameraUuid;
        tripWireData.push(tripWireCopy);
      });
    });
  }

  const [getDoorsByFloorId, { data: doorData }] = useLazyQuery(
    GQLService.queries.doorsByFloor,
    {
      fetchPolicy: "network-only",
    }
  );

  const [createSpace] = useMutation(GQLService.mutations.createSpace, {
    refetchQueries: () => [
      {
        query: GQLService.queries.spaces,
        variables: { propertyId: selectedProperty?.id },
      },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (commonSpacesData?.allCommonSpace) {
      const sortedList = commonSpacesData?.allCommonSpace?.sort(
        (a: any, b: any) => a.name.localeCompare(b.name)
      );
      setSortCommonSpaceData(sortedList);
    }
  }, [commonSpacesData]);

  const handleSubmit = useCallback(
    (formData) => {
      toggleModal(true);
      setFormDataState({ ...formData, propertyId: selectedProperty?.id });
    },
    [formDataState, selectedProperty] // eslint-disable-line
  );

  const [isTimerDisabled, setIsTimerDisabled] = useState(false);
  const confirmChanges = useCallback(
    async () => {
      toggleModal(false);
      if (formDataState) {
        let isNetflowActive;
        if (formDataState.hasOwnProperty("isNetflowActive")) {
          isNetflowActive = formDataState.isNetflowActive;
          setIsTimerDisabled(isNetflowActive);
        } else {
          isNetflowActive = false;
        }
        try {
          await createSpace({
            variables: { ...formDataState, isNetflowActive: isNetflowActive },
          });
          enqueueSnackbar(
            formatMessage({
              id: "space.add.snackbar.success",
              defaultMessage: "Space added successfully",
            }),
            {
              variant: "success",
            }
          );
          scrollsTop();
          history.push(ROUTES.SPACES.replace(":uid", selectedProperty?.id!));
        } catch (e) {
          enqueueSnackbar(e.message, {
            variant: "error",
            persist: true,
          });
          console.log(e);
        }
      }
    },
    [formDataState] // eslint-disable-line
  );

  const rejectChanges = useCallback(() => {
    toggleModal(false);
  }, []);

  const selectedAPids = (tags: any) => {
    let ssidForAP: any[] = [];
    let ssidData: any[] = [];
    if (tags?.length !== 0) {
      accessPointData?.accessPointsByFloorId?.filter((el: any) => {
        if (tags?.includes(el.id)) {
          ssidForAP.push(el);
        }
      });
      ssidForAP.map((item) => {
        item.radio1.ssids.map((i: any) => {
          if (i.active === true) {
            ssidData.push(i);
          }
        });
        item.radio2.ssids.map((i: any) => {
          if (i.active === true) {
            ssidData.push(i);
          }
        });
      });
      let uniqueData = _.uniqBy(ssidData, function (e) {
        return e.id;
      });
      const newSSIDData = uniqueData?.map((el: any) => {
        if (el?.name?.includes("(2.4GHz)")) {
          let newStr = el?.name?.split("(2.4GHz)").join("");
          return { ...el, name: newStr };
        } else if (el?.name?.includes("(5GHz)")) {
          let newStr = el?.name?.split("(5GHz)").join("");
          return { ...el, name: newStr };
        } else {
          return el;
        }
      });
      setActiveSSIDs(newSSIDData);
    } else {
      setActiveSSIDs([]);
    }
  };

  return (
    <div>
      <PageHeader
        title={formatMessage({
          id: "addSpace.header",
          defaultMessage: "Add New Space",
          description: "add Space label",
        })}
      />
      <Grid container={true} md={12} sm={12}>
        <Form
          defaultValues={{ active: false }}
          fields={createFormFields(
            data?.floorsByPropertyId,
            getAirSensorByFloorId,
            airSensorData?.sensorsByFloorId,
            getAccessPointsByFloorId,
            accessPointData?.accessPointsByFloorId,
            selectedAPids,
            getCamerasByFloorId,
            zonesData,
            tripWireData,
            getDoorsByFloorId,
            doorData?.doorsByFloorId,
            formDataState?.isNetflowActive,
            getCommonSpaces,
            sortCommonSpaceData,
            activeSSIDs
          )}
          validationSchema={SpaceValidationRules}
          onSubmit={handleSubmit}
        />
      </Grid>
      <CustomModal open={showModal}>
        <Modal yes={async () => confirmChanges()} no={rejectChanges} />
      </CustomModal>
    </div>
  );
}
