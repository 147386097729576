import React, { useState, useEffect, useMemo } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import GQLService from "../../../core/services/GQL.service";
import { useUserState } from "../../../core/context/containers/User.container";
import { DashboardDeviceType } from "../../../types";
import { ReactComponent as PinBlack } from "../../../common/assets/images/pin_black.svg";
import { ReactComponent as Internet } from "../../../common/assets/images/internet.svg";
import { UnpinModal } from "./UnpinModal";
import { CustomModal } from "../../../common/components";
import moment from "moment";
import Loader from "react-loader-spinner";
import { DashboardCollapsibleMyPin } from "./DashboardCollapsibleMyPin";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginRight: "16px",
    width: "220px",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    marginRight: "8px",
  },
  statisticContainerTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.mediumBlack,
    marginLeft: "14px",
    marginBottom: "16px",
  },
  pinIcon: {
    transform: "rotate(45deg)",
    height: "16px",
  },
  box: {
    border: "1px solid",
    borderColor: "#E0EBF1",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.07)",
  },
  dashboradBoxContent: {
    // paddingLeft:"10px",
    // paddingRight:"10px"
  },
  pinGrid: {
    marginRight: "4px",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5px",
  },
  number: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(30),
    color: "#2F56A0",
    marginBottom: theme.spacing(1),
    marginLeft: "10px",
  },
  blackText: {
    display: "inline-block",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.textBlack,
    padding: "9px 9px 9px 0",
  },
  textValue: {
    color: "#2F56A0",
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(28),
  },
  bottomGrid: {
    display: "flex",
    marginLeft: "10px",
    marginBottom: "20px",
  },
  internetIcon: {
    marginLeft: "48px",
  },
  loaderGrid: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
}));

interface DashboardAPMyPinProps {
  item?: any;
  loadingMyPin?: any;
  removedCard?: any;
}

export function DashboardAPMyPin({
  item,
  loadingMyPin,
  removedCard,
}: DashboardAPMyPinProps) {
  const classes = useStyles();
  const { userEmail } = useUserState();
  const [toggleUnpinModal, setToggleUnpinModal] = useState<boolean>(false);
  const [accessPointData, setAccessPointData] = useState<any>();

  let parsedRequest = JSON.parse(item.queryData).request;
  const [selectedTime, setSelectedTime] = useState(parsedRequest.selectedTime);
  const [timeSetter, setTimeSetter] = useState<number>(
    parsedRequest.selectedTime
  );
  const [
    getDashboardCardAverageAndCount,
    {
      loading: loadingDashboardCardAverageAndCount,
      data: dataDashboardCardAverageAndCount,
    },
  ] = useLazyQuery(GQLService.queries.dashboardCardAverageAndCount, {
    fetchPolicy: "network-only",
  });

  const timeRange = useMemo(() => {
    switch (selectedTime) {
      case 15:
        setTimeSetter(15);
        return "MINUTE";
      case 60:
        setTimeSetter(60);
        return "FIVE_MINUTES";
      case 1440:
        setTimeSetter(1440);
        return "HOUR";
      case 10080:
        setTimeSetter(10080);
        return "DAY";
      case 43200:
        setTimeSetter(43200);
        return "DAY";
      default:
        setTimeSetter(60);
        return "HOUR";
    }
  }, [selectedTime]);

  useEffect(() => {
    if (selectedTime!) {
      let newTimeFrom;
      let newTimeTo;
      if (selectedTime! === 15) {
        if (item.property.timeZoneName) {
          newTimeTo = moment
            .tz(item.property.timeZoneName)
            .startOf("minute")
            .subtract(3, "minutes")
            .format();
          newTimeFrom = moment
            .tz(newTimeTo, item.property.timeZoneName)
            .subtract(timeSetter, "minutes")
            .format();
        } else {
          newTimeTo = moment
            .utc()
            .startOf("minute")
            .subtract(3, "minutes")
            .format();
          newTimeFrom = moment
            .utc(newTimeTo)
            .subtract(timeSetter, "minutes")
            .format();
        }
      } else {
        if (selectedTime! === 10080 || selectedTime === 43200) {
          if (item.property.timeZoneName) {
            newTimeTo = moment
              .tz(item.property.timeZoneName)
              .startOf("day")
              .format();
            newTimeFrom = moment
              .tz(newTimeTo, item.property.timeZoneName)
              .subtract(timeSetter, "minutes")
              .format();
          } else {
            newTimeTo = moment.utc().startOf("day").format();
            newTimeFrom = moment
              .utc(newTimeTo)
              .subtract(timeSetter, "minutes")
              .format();
          }
        } else {
          if (item.property.timeZoneName) {
            newTimeTo = moment
              .tz(item.property.timeZoneName)
              .startOf(selectedTime === 1440 ? "hour" : "minute")
              .format();
            newTimeFrom = moment
              .tz(newTimeTo, item.property.timeZoneName)
              .subtract(timeSetter, "minutes")
              .format();
          } else {
            newTimeTo = moment
              .utc()
              .startOf(selectedTime === 1440 ? "hour" : "minute")
              .format();
            newTimeFrom = moment
              .utc(newTimeTo)
              .subtract(timeSetter, "minutes")
              .format();
          }
        }
      }

      getDashboardCardAverageAndCount({
        variables: {
          request: {
            floorId: item?.floor?.id!,
            from: newTimeFrom,
            to: newTimeTo,
            timeRangeType: timeRange,
            spaceId: item?.space?.id!,
            userEmail: userEmail,
            deviceType: DashboardDeviceType.DASHBOARD_ACCESSPOINT,
          },
        },
      });
    }
  }, [
    userEmail,
    item,
    timeRange,
    selectedTime,
    getDashboardCardAverageAndCount,
    timeSetter,
  ]);

  useEffect(() => {
    if (dataDashboardCardAverageAndCount) {
      setAccessPointData(
        dataDashboardCardAverageAndCount?.dashboardCardAverageAndCount!
      );
    }
  }, [dataDashboardCardAverageAndCount]);

  const timeChange = (time: any) => {
    setSelectedTime(time);
  };

  return (
    <>
      <Grid container={true} direction="column" className={classes.wrapper}>
        {!loadingMyPin && (
          <DashboardCollapsibleMyPin item={item} timeChange={timeChange} />
        )}
        {!loadingDashboardCardAverageAndCount ? (
          <Grid
            className={classes.box}
            style={{
              borderRadius: "8px",
              marginTop: "-8px",
              backgroundColor: "#ffffff",
            }}
          >
            <Grid direction="column" className={classes.dashboradBoxContent}>
              <Grid className={classes.pinGrid}>
                <PinBlack
                  fill="#3f51b5"
                  className={classes.pinIcon}
                  onClick={() => setToggleUnpinModal(true)}
                />
              </Grid>
              <Grid>
                <Typography className={classes.statisticContainerTitle}>
                  Occupancy
                </Typography>
                <Typography className={classes.number}>
                  {~~Math.round(accessPointData?.apDensity!)}
                </Typography>
              </Grid>
              <Grid style={{ marginLeft: "10px" }}>
                <Typography className={classes.blackText}>
                  Access Points
                </Typography>
              </Grid>
              <Grid className={classes.bottomGrid}>
                <Typography className={classes.textValue}>
                  {~~Math.round(accessPointData?.apCount!)}
                </Typography>
                <Internet className={classes.internetIcon} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.loaderGrid}>
            <Loader type="Oval" height={50} width={50} color="#2F56A0" />
          </Grid>
        )}
      </Grid>
      <CustomModal open={toggleUnpinModal}>
        <UnpinModal
          setToggleModal={setToggleUnpinModal}
          deviceType={item?.deviceType!}
          deviceId={parsedRequest?.deviceId!}
          selectedSpace={item?.space?.id!}
          selectedFloor={item?.floor!}
          Type="MyPins"
          removedCard={removedCard}
        />
      </CustomModal>
    </>
  );
}
