import React, { useState, useCallback, useEffect } from "react";
import { Grid, makeStyles, Button, Typography, Input } from "@material-ui/core";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import GQLService from "../../../../core/services/GQL.service";
import { usePropertiesState } from "../../../../core/context/containers/Properties.container";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../common/constants/Routing";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useFloorState } from "../../../../core/context/containers/Floor.container";

const useStyles = makeStyles((theme) => ({
  removeBtn: {
    backgroundColor: theme.palette.common.red,
    color: theme.palette.common.white,
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.red,
      textDecoration: "underline",
    },
  },
  cancel: {
    backgroundColor: "transparent",
    color: "#2F56A1",
    textTransform: "initial",
    width: 130,
    height: 40,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,

    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(28),
    color: "#2F56A1",
    marginBottom: "28px",
  },
  description: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: "0E1D33",
    fontWeight: 600,
    alignSelf: "left",
    width: "90%",
  },
  line: {
    marginBottom: "15px",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.funBlue,
  },
  content: {
    display: "flex",
    minWidth: "425px",
    minHeight: "315px",
    justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: 100000,
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    flexDirection: "column",
    padding: "29px 37px",
    borderRadius: "20px",
    boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    width: "100%",
  },
  inputWrapper: {
    width: "90%",
  },
}));

interface ModalContentProps {
  hide: (boolean: boolean) => void;
  floorId: string;
  propertyId: string;
}

export function FloorPlanRemoveModal({
  propertyId,
  floorId,
  hide,
}: ModalContentProps) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [name, setNewName] = useState<string>("");
  const { floors, setFloors } = useFloorState();
  const { formatMessage } = useIntl();

  const history = useHistory();

  const confirmChanges = useCallback(
    async () => {
      try {
        await GQLService.rest.images.removeFloorPlan(propertyId, floorId);
        const floorsWithoutPlan = floors.map((floor) => {
          if (floor.id === floorId) {
            return {
              ...floor,
              floorPlanUrl: undefined,
              floorPlanUploaded: false,
            };
          }
          return floor;
        });
        setFloors(floorsWithoutPlan);

        enqueueSnackbar(
          formatMessage({
            id: "elevators.duplicate.snackbar.success",
            defaultMessage: "Floor plan removed",
          }),
          {
            variant: "success",
          }
        );
        hide(true);
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: "error",
          persist: true,
        });
      }
    },
    [name] // eslint-disable-line
  );

  return (
    <Grid className={classes.content}>
      <Grid item>
        <Typography id="modalTitle" className={classes.header}>
          {formatMessage({
            id: "floorplan.remove.header",
            defaultMessage: "Remove Ploor Plan",
          })}
        </Typography>
        <Typography className={classes.description}>
          {formatMessage({
            id: "floorplan.remove.areyousure",
            defaultMessage: "Are you sure you want to remove this floor plan ?",
          })}
        </Typography>
      </Grid>

      <Grid item className={classes.buttonsWrapper}>
        <Button
          id="cancelModalBtn"
          className={classes.cancel}
          onClick={() => hide(false)}
        >
          {formatMessage({
            id: "floorplan.button.cancel",
            defaultMessage: "Cancel",
          })}
        </Button>
        <Button
          id="okModalBtn"
          className={classes.removeBtn}
          onClick={() => confirmChanges()}
        >
          {formatMessage({
            id: "floorplan.button.saveAndAdd",
            defaultMessage: "Remove",
          })}
        </Button>
      </Grid>
    </Grid>
  );
}
