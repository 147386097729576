import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../common/styles/colors";

export const useStyles = makeStyles((theme: any) => ({
  pinItButton: {
    backgroundColor: theme.palette.common.funBlue,
    borderRadius: 30,
    color: COLORS.white,
    textTransform: "none",
    width: "100px",
    height: "35px",
  },
  pinItModal: {
    display: "flex",
    // width: "300px",
    // height: "222px",
    width: "380px",
    height: "280px",
    justifyContent: "space-between",
    position: "absolute",
    left: "17%",
    top: "20%",
    backgroundColor: "#ffffff",
    flexDirection: "column",
    padding: "24px 16px 16px 20px",
    borderRadius: "20px",
    boxShadow: "5px 8px 20px 2px rgba(0, 0, 0, 0.5);",
    zIndex: 1000,
  },
  pinItModalHeader: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.mediumBlack,
  },
  pinItModalDescription: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.mediumBlack,
    paddingTop: "24px",
    paddingRight: "16px",
    // paddingLeft: "20px",
  },
  pinItModalButtonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "16px",
  },
  pinItModalButtonCancel: {
    backgroundColor: "transparent",
    color: theme.palette.common.funBlue,
    textTransform: "initial",
    width: 44,
    height: 18,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: "3px",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  pinItModalButtonPinIt: {
    backgroundColor: theme.palette.common.funBlue,
    color: theme.palette.common.white,
    width: 100,
    height: 26,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "initial",
    "&:hover": {
      backgroundColor: theme.palette.common.funBlue,
      textDecoration: "none",
    },
  },
  unpinItModal: {
    display: "flex",
    width: "300px",
    height: "222px",
    justifyContent: "space-between",
    position: "absolute",
    backgroundColor: "#ffffff",
    flexDirection: "column",
    padding: "24px 16px 16px 20px",
    borderRadius: "20px",
    boxShadow: "5px 8px 20px 2px rgba(0, 0, 0, 0.5);",
    zIndex: 1000,
  },
  inputLable: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.mediumBlack,
    marginBottom: "5px",
  },
  input: {
    height: 40,
    border: `1px solid ${theme.palette.common.inputFrame}`,
    borderRadius: 7,
    backgroundColor: theme.palette.common.InputBg,
    paddingLeft: 10,
    color: theme.palette.common.textBlack,
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: "5px",
    "&:focus": {
      backgroundColor: theme.palette.common.InputBg,
    },
  },
  inputField: {
    "&::placeholder": {
      color: theme.palette.common.inputGrey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 14,
      opacity: 1,
    },
  },
  charError: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.common.mediumBlack,
    marginLeft: "4px",
  },
}));
